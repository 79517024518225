import 'react';

export default {
  add: (key: string, value: string | number | object) => {
    if(!value) {
      throw new Error('value cannot be undefined or null');
    }
    let finalValue: string;
    const valueType = typeof value;
    if(valueType === 'number'){
      finalValue = value.toString();
    } else if(valueType === 'object'){
      finalValue = JSON.stringify(value);
    } else {
      finalValue = value as string;
    }

    window.localStorage.setItem(key, finalValue);
  },

  get: (key: string) => window.localStorage.getItem(key)
}