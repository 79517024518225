import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import LoginTriage from '../../vAppointment/LoginTriage';
import SignIn from '../../vAppointment/SignIn';
import ForgotPassword from '../../vAppointment/ForgotPassword';
import PatientSelection from '../../vAppointment/PatientSelection';
import PatientDetails from '../../vAppointment/PatientDetails';
import Reason from '../../vAppointment/Reason';
import AppointmentConfirmation from '../../vAppointment/AppointmentConfirmation';
import MobileVerification from '../../vAppointment/MobileVerification/connect';
import SendText from '../../vAppointment/MobileVerification/EnterMobile';
import SmsFailure from '../../vAppointment/MobileVerification/components/SmsFailureMobile';
import Processing from '../../vAppointment/Processing';
import BookingUnavailable from '../../vAppointment/ErrorPages/BookingUnavailable';
import GuestBookingError from '../../vAppointment/ErrorPages/GuestBookingError';
import GuestRegister from '../../vAppointment/GuestRegister';
import UrgentAttention from '../../vAppointment/UrgentAttention';
import { push } from '../utils';
import ReasonOld from '../../vAppointment/Reason/ReasonOld';
import Corona from '../../vAppointment/Reason/Corona';
import CoronaMobile from '../../vAppointment/Reason/Corona/CoronaMobile';
import ExpressionInterest from '../../vAppointment/ExpressionInterest'
import { isDesktop } from '../../config';
import PrepaidPage from 'vAppointment/PrepaidPage/PrepaidPage';
import Payment from 'vAppointment/PrepaidPage/AddCard';
import SelectCard from 'vAppointment/PrepaidPage/CardSelection';
import InfoPage from 'vAppointment/PrepaidPage/DesktopPages/InfoPage';
import TermsOfServicePage from 'vAppointment/PrepaidPage/DesktopPages/TermsOfServicePage';

const go = {
  loginTriage: id => push(`/practice-profile/${id}/book/guestorlogin/`),
  signIn: id => push(`/practice-profile/${id}/book/sign-in/`),
  forgotPassword: id => push(`/practice-profile/${id}/book/forgot-password`),
  patientSelection: id => push(`/practice-profile/${id}/book/patient-selection/`),
  patientDetails: (id, type = 'patient') => push(`/practice-profile/${id}/book/details/${type}`),
  reason: (id, consult) => push({ pathname: `/practice-profile/${id}/book/reason`, state: { consult: consult } }),
  expression: (id) => push({ pathname: `/practice-profile/${id}/book/expression-interest` }),
  confirmAppointment: id => push(`/practice-profile/${id}/book/confirmation`),
  urgentAttention: id => push(`/practice-profile/${id}/book/attention`),
  // sendText: id => push(`/practice-profile/${id}/book/verification/txt`),
  verification: id => push(`/practice-profile/${id}/book/verification`),
  smsFailure: id => push(`/practice-profile/${id}/book/sms-failure`),
  processAppointment: id => push(`/practice-profile/${id}/book/processing`),
  bookingUnavailable: id => push(`/practice-profile/${id}/book/unavailable`),
  guestBookingError: id => push(`/practice-profile/${id}/book/guest-error`),
  register: id => push(`/practice-profile/${id}/book/register`),
  reasonOld: id => push(`/practice-profile/${id}/book/reason-old`),
  urgentAttention: (id, practice, sessionId, method, symptoms, note, service) => push({
    pathname: `/practice-profile/${id}/book/attention`,
    state: {
      practice: practice,
      sessionId,
      method,
      symptoms,
      note,
      service
    }
  }),
  corona: (id, practice, allowContinue, message, sessionId, method, symptoms, note, service) => push({
    pathname: `/practice-profile/${id}/book/corona`,
    state: {
      practice: practice,
      message,
      allowContinue,
      sessionId,
      method,
      symptoms,
      note,
      service
    }
  }),
  prepaidPage: (id, session) => push({
    pathname: `/practice-profile/${id}/book/prepaid`,
    state: {
      session
    }
  }),
  appointmentPayments: id => push(`/practice-profile/${id}/book/payment`),
  appointmentCards: id => push(`/practice-profile/${id}/book/cards`),
  appointmentFeeInfo: id => push(`/practice-profile/${id}/book/prepaid/info`),
  appointmentTerms: (id, terms) => push({
    pathname: `/practice-profile/${id}/book/prepaid/terms`,
    state: { terms }
  })
};

const AppointmentRoutes = ({ hasAppointment }) => (
  <Switch>
    {!hasAppointment && (
      <Redirect from="/practice-profile/:id/:type" to="/practice-profile/:id" />
    )}
    <Route
      key={'/practice-profile/:id/book/sign-in'}
      path={'/practice-profile/:id/book/sign-in'}
      component={SignIn}
    />
    <Route
      key={"/practice-profile/:id/book/prepaid/terms"}
      path={"/practice-profile/:id/book/prepaid/terms"}
      component={TermsOfServicePage}
    />
    <Route
      key={"/practice-profile/:id/book/prepaid/info"}
      path={"/practice-profile/:id/book/prepaid/info"}
      component={InfoPage}
    />
    <Route
      component={Payment}
      key="/practice-profile/:id/book/payment"
      path="/practice-profile/:id/book/payment"
    />
    <Route
      component={SelectCard}
      key="/practice-profile/:id/book/cards"
      path="/practice-profile/:id/book/cards"
    />
    <Route
      key={'/practice-profile/:id/book/prepaid'}
      path={'/practice-profile/:id/book/prepaid'}
      component={PrepaidPage}
    />
    <Route
      exact
      key={'/practice-profile/:id/book/guestorlogin/'}
      path={'/practice-profile/:id/book/guestorlogin'}
      component={LoginTriage}
    />
    <Route
      key={'/practice-profile/:id/book/forgot-password/'}
      path={'/practice-profile/:id/book/forgot-password/'}
      component={ForgotPassword}
    />
    <Route
      key={'/practice-profile/:id/book/patient-selection/'}
      path={'/practice-profile/:id/book/patient-selection/'}
      component={PatientSelection}
    />
    <Route
      key={'/practice-profile/:id/book/details/:type'}
      path={'/practice-profile/:id/book/details/:type'}
      component={PatientDetails}
    />
    <Route
      key={'/practice-profile/:id/book/reason/'}
      path={'/practice-profile/:id/book/reason/'}
      component={Reason}
    />
    <Route
      key={'/practice-profile/:id/book/confirmation/'}
      path={'/practice-profile/:id/book/confirmation/'}
      component={AppointmentConfirmation}
    />
    <Route
      key={'/practice-profile/:id/book/processing/'}
      path={'/practice-profile/:id/book/processing/'}
      component={Processing}
    />
    <Route
      key={'/practice-profile/:id/book/verification/txt'}
      path={'/practice-profile/:id/book/verification/txt'}
      component={SendText}
    />
    <Route
      exact
      key={'/practice-profile/:id/book/verification'}
      path={'/practice-profile/:id/book/verification'}
      component={MobileVerification}
    />
    <Route
      key={'/practice-profile/:id/book/sms-failure'}
      path={'/practice-profile/:id/book/sms-failure'}
      component={SmsFailure}
    />
    <Route
      key={'/practice-profile/:id/book/unavailable'}
      path={'/practice-profile/:id/book/unavailable'}
      component={BookingUnavailable}
    />
    <Route
      key={'/practice-profile/:id/book/guest-error'}
      path={'/practice-profile/:id/book/guest-error'}
      component={GuestBookingError}
    />
    <Route
      key={'/practice-profile/:id/book/register'}
      path={'/practice-profile/:id/book/register'}
      component={GuestRegister}
    />
    <Route
      key={"/practice-profile/:id/book/attention"}
      path={"/practice-profile/:id/book/attention"}
      component={UrgentAttention}
    />
    <Route
      key={"/practice-profile/:id/book/reason-old"}
      path={"/practice-profile/:id/book/reason-old"}
      component={ReasonOld}
    />
    <Route
      key={"/practice-profile/:id/book/corona"}
      path={"/practice-profile/:id/book/corona"}
      component={isDesktop() ? Corona : CoronaMobile}
    />
    <Route
      key={"/practice-profile/:id/book/expression-interest"}
      path={"/practice-profile/:id/book/expression-interest"}
      component={ExpressionInterest}
    />
  </Switch>
);

const mapState = state => ({
  hasAppointment: state.practiceAppointmentReducer.selectedTime.time,
});

const AppointmentWrapper = withRouter(connect(mapState)(AppointmentRoutes));

export default AppointmentWrapper;
export { go };
