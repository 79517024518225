import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ReactComponent as CameraIcon } from '../../svgs/camera.svg';
import styles from './altImage.module.css';

const AltImage = ({ className }) => (
  <div className={classnames(styles.container, className)}>
    <CameraIcon className={styles.icon} />
  </div>
);

AltImage.propTypes = {
  className: PropTypes.string,
};

export default AltImage;
