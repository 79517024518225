import React, { PropsWithChildren } from 'react';
import { DEFAULT_SPACE, getSafeCss, css as cssFunc } from '../../styles';
import { getRefProp } from '../../utils/utils';
import { BaseBox } from '../Box/BaseBox';
import { IBaseBoxWithTakeRef } from '../Box/BaseBox.type';
import { IButton } from './Button';
import { getBaseButtonCSS, INoDefaultHoverCSS } from './shared';

export interface IFlatButton
  extends IBaseBoxWithTakeRef,
    INoDefaultHoverCSS,
    IButton {
  noTextCenter?: boolean;
}

export const FlatButton = ({
  noDefaultHoverCSS,
  children,
  css,
  takeRef,
  noTextCenter,
  ...rest
}: PropsWithChildren<IFlatButton>) => (
  <BaseBox
    as="a"
    fontSize="inherit"
    px={DEFAULT_SPACE.xxSmall}
    color="primary"
    css={cssFunc`
      ${getBaseButtonCSS(noDefaultHoverCSS, noTextCenter)};
      ${
        // @ts-ignore
        getSafeCss(css)
      };
    `}
    {...getRefProp(takeRef)}
    {...rest}
  >
    {children}
  </BaseBox>
);
