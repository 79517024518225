import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import document from 'images/document.png';
import { ReactComponent as Arrow } from 'svgs/arrow.svg';
import { ReactComponent as CloseArrow } from 'images/arrow.svg';
import { ReactComponent as Tick } from 'svgs/approveTick.svg';
import { CommentType, IClinicNote, IImmunisation, ILabResult } from 'store/mobx/myHealthStore/myHealthStore.type';
import LabResult from './Content/LabResult';
import { ReactComponent as Labs } from 'svgs/labs.svg';
import { ReactComponent as Imms } from 'svgs/imms.svg';
import { ReactComponent as Clinical } from 'svgs/clinicalNote.svg';
import moment from 'moment';
import LabResultOpen from './Content/LabResultOpen';
import Immunisation from './Content/Immunisation';
import ClinicalNote from './Content/ClinicalNote';
import { useAccountStore, useMyHealthStore } from 'store/mobx/mobx';
import ImmunisationOpen from './Content/ImmunisationOpen';
import ClinicalNoteOpen from './Content/ClinicalNoteOpen';
import { AddComment } from 'MyHealthPage/components/AddComment';

const Container = styled.div`
    width: 700px;
    height: 92.48px;
    flex-direction: row;
    box-shadow: 1px 1px 3px lightgray;
    margin-right: auto;
    background-color: white;
    margin-left: auto;
    display: ${(props: ContainerProps) => props.animate ? "none" : "flex"};
    transition: all 0.5s ease-in-out;
    padding-right: 1rem;
    cursor: pointer;
`
const OpenContainer = styled.div`
    width: 700px;
    height: auto;
    max-height: ${(props: ContainerProps) => props.animate ? "2000px" : "0px"};
    opacity: ${(props: ContainerProps) => props.animate ? "1" : "0"};
    visibility: ${(props: ContainerProps) => props.animate ? "visible" : "hidden"};
    transition: all 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    box-shadow: 1px 1px 3px lightgray;
    background-color: white;
    margin-top: 0.5rem;
    margin-right: auto;
    margin-left: auto;
    position: relative;
`
const Border = styled.div`
    position: absolute;
    border-left: 3px solid #44D375;
    top: 0;
    left 0;
    height: 232px;
`
const ClosedContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    width: 350px;
`
const Banner = styled.div`
    background-color: #44D375;
    border-radius: 25px;
    font-size: 11px;
    color: white;
    margin-bottom: 0.3rem;
    padding: 5px 10px;
`
const Script = styled.img`
    width: 49px;
    height: 49px;
    margin: 5px;
`
const Date = styled.div`
    font-size: 13px;
    color: #585858;
    width: 100%;
`
const Name = styled.div`
    font-size: 15px;
    width: 100%;
    color: #6C6C6C;
    margin: 3px 0px;
`
const Practice = styled.div`
    font-size: 13px;
    color: #6C6C6C;
    font-weight: 300;
    width: 100%;
`
const Expand = styled.div`
    color: #07A3C8;
    font-size: 13px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    z-index: 10;
    width: 100%;
`
const Line = styled.div`
    border-bottom: 1px solid #F0F0F0;
    width: 92%;
    margin: auto;
`
const AddCommentContainer = styled.div`
    color: #07A3C8;
    font-size: 14px;
    cursor: pointer;
    padding: 1rem 0rem;
    width: 92%;
    margin: auto;
`
interface ContainerProps {
    animate: boolean;
}
interface Props {
    type: string;
    item: any;
}

const ExpandableCard: React.FC<Props> = observer(({type, item}) => {
    const [state, setState] = useState(false);
    const [animationState, setAnimationState] = useState(false);

    const { FirstName, LastName } = useAccountStore().profile;
    const date = moment(item.DateTime).format("DD/MM/YYYY");
    const commentType = type === "labs" ? CommentType.LabResult : type === "imms" ? CommentType.Immunisation : CommentType.DoctorNotes;

    const setStatesTrue = () => {
        setState(true);
        setAnimationState(true);   
    }
    const setStatesFalse = () => {
        setState(false);
        setTimeout(() => setAnimationState(false), 500);   
    }
    return (
        <div> 
            <OpenContainer animate={state}>
                    <Expand 
                        onClick={setStatesFalse}
                        style={{
                            position: "absolute", 
                            top: "2.5rem", 
                            right: "1rem",
                            width: "100px",
                        }}>
                            Close Details
                            <CloseArrow style={{ width: "7px", height: "auto", margin: "4px" }} />
                    </Expand>
                
                {type === "labs" && animationState &&
                    <LabResultOpen item={item} />
                }
                {type === "imms" && animationState &&
                    <ImmunisationOpen item={item} />
                }
                {type === "clinical" && animationState &&
                    <ClinicalNoteOpen item={item} />
                }
            </OpenContainer>
            
            <Container 
                animate={state} 
                onClick={setStatesTrue}>
                <ClosedContainer style={{marginTop: item.IsNewInPastMonth ? "0.7rem" : "auto"}}>
                    {item.IsNewInPastMonth && <Banner>New in last month</Banner>}
                    {type === "imms" && item.givenWithLast3Months !== undefined && <Banner>New in last 3 months</Banner>}
                    {type === "labs" && <Labs />}
                    {type === "imms" && <Imms />}
                    {type === "clinical" && <Clinical />}
                </ClosedContainer>

                {type === "labs" && <LabResult item={item} />}
                {type === "imms" && <Immunisation item={item} />}
                {type === "clinical" && <ClinicalNote item={item} firstName={FirstName} lastName={LastName} />}

                <ClosedContainer style={{flexDirection: "row", width: "400px"}}>
                    <Date>{date}</Date>
                    <Expand onClick={setStatesTrue}>
                        <div onClick={setStatesTrue}>View Details</div>
                        <Arrow onClick={setStatesTrue} style={{ cursor: "pointer", width: "7px", height: "auto", margin: "4px" }} />
                    </Expand>
                </ClosedContainer>
            </Container>
        </div>
    );
});

export default ExpandableCard;