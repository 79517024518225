import React, { ChangeEvent, FocusEvent, KeyboardEvent, LegacyRef, RefObject, useEffect, useRef, useState } from 'react';
import styles from './NumberInput.module.scss';
import cn from 'classnames';
import Alert from 'libs/Alert';
import { adapter } from './adapter';
import { Editable, KeepFocusProps } from 'types';
import { debug } from 'console';

type NumberInputProps = {
    id: string;
    placeholder: string;
    onNumberChange: (name: string, value: string) => void;
    value?: string;
    onEmpty?: (name: string) => void;
    maxLength?: number;
    splitter?: string;
    pattern?: string;
    updateRef: (ref: HTMLInputElement) => void;
    touch?: boolean;
} & KeepFocusProps & Editable;

const ALLOWED_KEYS = [
    'Digit1', 'Digit2', 'Digit3', 'Digit4', 'Digit5', 
    'Digit6', 'Digit7', 'Digit8', 'Digit9', 'Digit0', 
    'Numpad1', 'Numpad2', 'Numpad3', 'Numpad4', 'Numpad5',
    'Numpad6', 'Numpad7', 'Numpad8', 'Numpad9', 'Numpad0',
    'Backspace'];

const NumberInput: React.FC<NumberInputProps> = (
    {
        id,
        placeholder,
        maxLength,
        value,
        splitter,
        pattern,
        onNumberChange,
        onEmpty,
        updateRef,
        focusId,
        doFocus,
        readonly,
        touch = false
    }) => {
        const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
            let value = e.currentTarget.value.trim();
            value = value.replace(/[^\d]/g, '');
            onNumberChange(e.currentTarget.id, value);
        };
        
        const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
            if (e.code === 'Backspace') {
                e.currentTarget.value.length === 0 && onEmpty && onEmpty(e.currentTarget.id);
            }
            if(ALLOWED_KEYS.findIndex(key => key === e.code) === -1) {
                //e.preventDefault();
            }
        }
        const length = maxLength ? maxLength : placeholder ? placeholder.length: 10;
        const adapt = adapter(touch);
  return (
    <>
        <input
            id={id}
            type={adapt.inputType}
            required
            value={value}
            className={cn(styles.input, {[styles.touch]: touch})}
            placeholder={placeholder}
            maxLength={length}
            style={{width: `${placeholder.length * adapt.fontFactor}rem`}}
            onKeyDown={handleKeyDown}
            onInput={handleChange}
            onFocus={doFocus}
            pattern={pattern}
            readOnly={readonly}
            ref={input => {
                if(input){
                    updateRef(input);
                    if(focusId === input.id){
                        input.focus();
                    }
                }
            }}/>
        {splitter && <span className={styles.splitter}>{splitter}</span>}
    </>
  )
};

export default NumberInput;