import React, { useEffect, useRef, useState } from 'react';
import { toJS, isObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Box, Text, Textarea, FlatButton } from '../../components/vensaComponents';
import { useMyHealthStore } from '../../store/mobx/mobx';
import {
  IImmunisation,
  IComment,
  ICommentNew,
  CommentType,
  IClinicNote,
  SelectedCommentTypeEnum,
} from '../../store/mobx/myHealthStore/myHealthStore.type';
import { ILabResult } from '../../store/mobx/myHealthStore/myHealthStore.type';
import moment from 'moment';
import { is } from '../../libs/newHttp';
import { MyHealthStore } from '../../store/mobx/myHealthStore/myHealthStore';
import cross from 'images/cross.png';
import { isDesktop } from 'config';
import { ClinicalNotesTab } from 'MyHealthPage/Tabs/ClinicalNotesTab/ClinicalNotesTab';
import Loading from 'components/loadingSpinner';

const getDate = (dt: string) => moment(dt).format('D/MM/YYYY LT');

const getComment = (
  commentType: SelectedCommentTypeEnum,
  labResultsTab: MyHealthStore['labResultsTab'],
  immunisationsTab: MyHealthStore['immunisationsTab'],
  clinicNotesTab: MyHealthStore['clinicNotesTab'],
  itemId: string,
): IComment[] | undefined => {
  const DATA_MAP = {
    [CommentType.LabResult]: labResultsTab.all,
    [CommentType.Immunisation]: immunisationsTab.all,
    [CommentType.DoctorNotes]: clinicNotesTab.all,
  };

  const toFind: IImmunisation[] | ILabResult[] | IClinicNote[] = DATA_MAP[commentType];
  let item1 = toFind
  //@ts-ignore
  .find(v => v.Id === itemId);

    
  let comments: IComment[] | undefined | null = undefined;
  if (
    commentType === CommentType.LabResult ||
    commentType === CommentType.Immunisation
  ) {
    if (item1 === undefined) { return [] }
    if (item1.Comments === null) { return [] }
    comments = (item1 as (ILabResult | IImmunisation)).Comments;
  } else if (commentType === CommentType.DoctorNotes) {
    if (item1 === undefined) { return [] }
    comments = (item1 as IClinicNote).Comments;
  }

  if (!comments) return [];
  const hasComment = false //isObservableArray(comments) && comments.length > 0;

  return comments;
};

export const AddComment: React.FC<{
  commentType: SelectedCommentTypeEnum;
  itemId: string;
  label: string;
  itemToShow?: any;
  refreshList?: () => void;
  onSubmit: (
    commentType: SelectedCommentTypeEnum,
    parentId: string,
    newComment: ICommentNew,
  ) => void;
}> = observer(({ commentType, label, itemId, onSubmit, itemToShow, refreshList }) => {
  const {
    labResultsTab,
    immunisationsTab,
    clinicNotesTab,
    postNewCommentLoadingStatus,
  } = useMyHealthStore();
  const [isInputState, setIsInputState] = useState(false);
  const [text, setText] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  if (is.loading(postNewCommentLoadingStatus)) {
    return (
      <Text fontSize="12px" color="grey3">
        ADDING COMMENT 
        <Loading isFetching={true} style={{fill: "#07A3C8"}} />
      </Text>
    );
  }

  const comment = getComment(
    commentType,
    labResultsTab,
    immunisationsTab,
    clinicNotesTab,
    itemId,
  );

  return (
    <Box display={"block"} borderBottom={"1px solid #FFFFFF"} cursor={"auto"}>
      {comment !== undefined && comment.length > 0 && 
        <Box fontSize={isDesktop() ? "12px" : "16px"} color="grey3" direction="column" mb="8px">
          <Text mb={1}>Comments:</Text>
          
          {comment.map((item, i) => { 
            if (item.Deleted) { return }
            return (
              <div key={i} style={{marginBottom: "4px", display: "flex", flexDirection: "row", position: "relative"}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <Text>{item.Content}</Text>
                  <Text>{getDate(item.DateOfComment)}</Text>
                </div>
                {/*<img src={cross} style={{height: "16px"}} onClick={() => {
                  deleteComment(item.Id)
                }}/>*/}
              </div>
          )})}
        </Box>
      }

      {isInputState ? (
        <Box direction="column">
          <Textarea
            fontSize={isDesktop() ? "15px" : "14px"}
            color="grey3"
            borderColor="grey2"
            takeRef={inputRef}
            value={text}
            onChange={e => {
              setText(e.currentTarget.value);
            }}
          />
          <Box justifyContent="end" mt={3}>
            <FlatButton 
              noTextCenter={true}
              onClick={() => {
                setIsInputState(false);
              }}  
            >
              CANCEL
            </FlatButton>
            <FlatButton
              pr="0"
              onClick={() => {
                setIsInputState(false);
                onSubmit(
                  commentType as SelectedCommentTypeEnum,
                  itemId,
                  {
                    ParentId: itemId,
                    PatientId: itemToShow.PatientId,
                    CommentType: commentType,
                    Content: text,
                  },
                );
                setText("");
                if (refreshList !== undefined) refreshList();
              }}
              noTextCenter={true}
            >
              ADD
            </FlatButton>
          </Box>
        </Box>
      ) : (
        <Box
          direction="column"
          borderBottom={isDesktop() ? 0 : 1}
          borderColor="grey2"
          pb={2}
          color="primary"
          onClick={() => {
            setIsInputState(true);
            setTimeout(() => {
              inputRef.current!.focus();
            }, 50)
          }}
        >
          {label}
        </Box>
      )}
    </Box>
  );
});
