import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from '../../../components/vensaComponents';
import { TabBox } from '../TabBox';
import { ClinicalNoteItem } from './components/ClinicalNoteItem';
import { useAccountStore, useIdentityStore, useMyHealthStore } from '../../../store/mobx/mobx';
import { BaseList } from '../../../MyHealthPage/components/BaseList';
import { useComponentDidMount } from '../../../libs/newHttp';
import BannerEmptyFeedCard from 'HealthFeed/HealthFeedMobile/BannerEmptyFeedCard/BannerEmptyFeedCard';
import { go } from 'routes';

export const ClinicalNotesTab: React.FC = observer(() => {
  const store = useAccountStore();
  const [show, setShow] = useState(true)
  const phrStore = useMyHealthStore();
  useComponentDidMount(phrStore.getClinicNotes.bind(phrStore));

  return (
    <TabBox p={1}>
      {phrStore.clinicalErrorText !== null && show &&
        <BannerEmptyFeedCard 
          bannerText={"Access Restricted"}
          title={""}
          text={phrStore.clinicalErrorText}
          button={true}
          buttonText={"Ok got it"}
          link={true}
          linkText={"Check my privacy"}
          restricted={true}
          linkOnClick={() => go.privacy()}
          buttonOnClick={() => {
            setShow(false);
            store.sendAcknowledgement(0, phrStore.getClinicNotes.bind(phrStore));
          }} // ACK 0}
        />
      }
      <Box direction="column" gap="8px">
        {phrStore.clinicNotesTab.newInLastMonth.map(item => (
          <ClinicalNoteItem key={item.Id} {...item} />
        ))}
      </Box>

      <BaseList
        title={phrStore.clinicNotesTab.past.length !== 0 ? "PREVIOUS CLINICAL NOTES" : "NO PREVIOUS CLINICAL RESULTS FOUND"}
        space={phrStore.clinicNotesTab.newInLastMonth.length === 0}
        render={() =>
          phrStore.clinicNotesTab.past.map(item => <ClinicalNoteItem key={item.Id} {...item} />)
        }
      />
    </TabBox>
  );
});
