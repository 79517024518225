import { call, takeEvery, put } from 'redux-saga/effects';

import moment from 'moment';

import HttpLib from '../../libs/Http';
import Alert from '../../libs/Alert';
import { actions, actionTypes } from '../actions/actions';
import { go } from 'routes';

const LOGIN_FAIL =
  'Oops, it looks like your email or your password is not right.';
const UNEXPECTED_ERROR = 'An Unexpected Error Occurred.<br/>Please Try Again.';
// eslint-disable-next-line max-len
const LOCKED_OUT =
  'You have entered an incorrect username or password too many times.<br/>Please try again later.';

const loginParserHttp = HttpLib(
  ({ status, response: { body } }) => {
    if (status === 400) {
      if (body.error === 'unauthorized') {
        return LOGIN_FAIL;
      }
      if (body.error === 'locked') {
        return LOCKED_OUT;
      }
    }
    return UNEXPECTED_ERROR;
  },
  Alert.error,
  '',
);
console.log(loginParserHttp)
function loginPin(pin) {
  return loginParserHttp
    .post('/consumer/account/pin-code/login', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: pin,
    })
    .then(
      ({
        body: {
          access_token: accessToken,
          expires_in: expiresInSeconds,
          refresh_token: refreshToken,
        },
      }) => {
        window.localStorage.setItem(
          'token',
          JSON.stringify({
            token: accessToken,
            email: "",
          }),
        );

        return { accessToken, expiresInSeconds, refreshToken };
      },
    );
}
function* addUserPin({ payload: { pin, success, fail } }) {
  try {
    const { accessToken, expiresInSeconds, refreshToken } = yield call(
      loginPin,
      pin
    );
    yield put(
      actions.loginSuccess(
        accessToken,
        // we say the token expires two minutes before it actually expires
        moment()
          .add(expiresInSeconds - 120, 'seconds')
          .toDate(),
        "",
        refreshToken,
      ),
    );
    success();
  } catch (err) {
    fail();
    yield put(actions.loginError(err));
  }
}

function login(email, password) {
  return loginParserHttp
    .post('/token', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: {
        grant_type: 'password',
        username: email,
        password,
      },
    })
    .then(
      ({
        body: {
          access_token: accessToken,
          expires_in: expiresInSeconds,
          refresh_token: refreshToken,
          ConsumerUrl: ConsumerUrl,
        },
      }) => {
        window.localStorage.setItem(
          'token',
          JSON.stringify({
            token: accessToken,
            email,
            ConsumerUrl,
          }),
        );

        return { accessToken, expiresInSeconds, refreshToken, ConsumerUrl };
      },
    );
}

function* addUser({ payload: { email, password } }) {
  try {
    const { accessToken, expiresInSeconds, refreshToken, ConsumerUrl } = yield call(
      login,
      email,
      password,
    );
    yield put(
      actions.loginSuccess(
        accessToken,
        // we say the token expires two minutes before it actually expires
        moment()
          .add(expiresInSeconds - 120, 'seconds')
          .toDate(),
        email,
        refreshToken,
        ConsumerUrl,
      ),
    );
  } catch (err) {
    if (err.response?.body?.error === "suspended") {
      go.suspendAccount(email);
    } 
    yield put(actions.loginError(err));
  }
}

function* loginSaga() {
  yield takeEvery(actionTypes.login, addUser);
}
function* loginPinSaga() {
  yield takeEvery(actionTypes.loginPin, addUserPin);
}

export { loginSaga, loginPinSaga };
