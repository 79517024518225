import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import NDigitInput from '../../../../components/nDigitInput/index';
import { ReactComponent as WarningIcon } from '../../../../svgs/warning.svg';
import LoadingSpinner from '../../../../components/loadingSpinner/index';
import { isDesktop } from '../../../../config';
import styles from './inputCodeForm.module.css';

const onChange = (sendCode, sessionId, mobile) => digits =>
  digits.length === 4 && sendCode(sessionId, digits, mobile);

const InputCodeForm = ({
  sendCode,
  sessionId,
  isFetching,
  isError,
  clearError,
  mobile,
}) => (
  <div className={styles.container}>
    <LoadingSpinner
      isFetching={isFetching}
      iconClassName={styles.loadingIcon}
      containerClassName={styles.loadingContainer}
    >
      <NDigitInput
        className={classnames(styles.input, { [styles.error]: isError })}
        digits={4}
        onChange={onChange(sendCode, sessionId, mobile)}
        key="input"
        isError={null}
        clearError={clearError}
        autoFocus={isDesktop()}
      />
      {isError && (
        <div className={styles.errorText}>
          <WarningIcon className={styles.warning} />
          This code is not valid
        </div>
      )}
    </LoadingSpinner>
  </div>
);
InputCodeForm.propTypes = {
  sendCode: PropTypes.func.isRequired,
  mobile: PropTypes.string.isRequired,
  clearError: PropTypes.func.isRequired,
  sessionId: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
};

export default InputCodeForm;
