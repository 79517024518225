import React, { lazy, Suspense } from 'react';
import { Router, Switch, Redirect, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import { debounce } from 'lodash/fp';
import App from '../App';
import AppointmentsRoutes, { go as goAppointments } from './sub/appointments';
import PrivacyRoutes, { go as goPrivacy } from './sub/privacy';
import SettingsRoutes, { go as goSettings } from './sub/settings';
import IdentityRoutes, { go as goIdentity } from './sub/identity';
import DependantsRoutes, { go as goDependants } from './sub/dependants';
import MessagesRoutes, { go as goMessages } from './sub/messages';
import HealthFeedRoutes, { go as goHome } from './sub/healthFeed';
import LoginRoutes, { go as goLogin } from './sub/login';
import PracticeProfileRoutes, {
  go as goPracticeProfile,
} from './sub/practiceProfile';
import PracticesRoutes, { go as goPractices } from './sub/practices';
import PrescriptionsRoutes, {
  go as goPrescriptions,
} from './sub/prescriptions';
import SupportRoutes, { go as goSupport } from './sub/support';
import PaymentsRoutes, { go as goPayments } from './sub/payments';
import UserAccountRoutes, { go as goUserAccount } from './sub/userAccount';
import AddToHomeScreenRoutes, {go as goAddToHomeScreen} from './sub/addToHomeScreen';
import { go as govAppointment } from './sub/vAppointment';
import { go as govScript } from './sub/vScript';
import UIContainer from '../components/UIContainer';
import { history, push } from './utils';
import MyHealthRoutes, { go as goMyHealth } from './sub/myHealth';
import PHRRoutes, { go as goPHR } from './sub/phr';
import SignUpVerificationMobile from 'Identity/SignUpVerification/SignUpVerification';
import SecurityRoutes, { go as goSecurity } from './sub/security';
import SignUpFlow from 'Identity/SignUpVerification/SignUpFlow/SignUpFlow.tsx';
import DesktopSignUpVerification from 'Identity/SignUpVerification/DesktopSignUpVerification/DesktopSignUpVerification.tsx'
import DesktopSignUpFlow from 'Identity/SignUpVerification/DesktopSignUpFlow/DesktopSignUpFlow.tsx';
import { isDesktop } from 'config';

const go = {
  ...goAppointments,
  ...goPHR,
  ...goDependants,
  ...goMessages,
  ...goHome,
  ...goLogin,
  ...goPayments,
  ...goPracticeProfile,
  ...goPractices,
  ...goPrescriptions,
  ...goPrivacy,
  ...goSupport,
  ...goUserAccount,
  ...govAppointment,
  ...govScript,
  ...goSettings,
  ...goIdentity,
  ...goMyHealth,
  ...goSecurity,
  ...goAddToHomeScreen,
  signUpFlow: (t, e) => push('sign-up-flow?t=' + t + '&e=' + e),
  signUpInvitation: (t, e) => push('sign-up-invitation?token=' + t + '&email=' + e),
};

const logPageView = debounce(1000, () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
});

history.listen(logPageView);

const LOADING = () => {
  return (
    <UIContainer isFetching={true}>
      <div></div>
    </UIContainer>
  );
};

const Index = () => (
  <Router history={history}>
    <App>
      <Suspense fallback={<LOADING />}>
        <Switch>
          {AppointmentsRoutes}
          {DependantsRoutes}
          {MessagesRoutes}
          {HealthFeedRoutes}
          {LoginRoutes}
          {PaymentsRoutes}
          {PracticeProfileRoutes}
          {PracticesRoutes}
          {PrescriptionsRoutes}
          {PrivacyRoutes}
          {SupportRoutes}
          {UserAccountRoutes}
          {SettingsRoutes}
          {IdentityRoutes}
          {MyHealthRoutes}
          {SecurityRoutes}
          {PHRRoutes}
          {AddToHomeScreenRoutes}
          <Route
            key="/sign-up-invitation"
            path="/sign-up-invitation"
            component={isDesktop() ? DesktopSignUpVerification : SignUpVerificationMobile}
          />,
          <Route
            key="/sign-up-flow"
            path="/sign-up-flow"
            component={isDesktop() ? DesktopSignUpFlow: SignUpFlow}
          />, 
          <Route
            path="/request-script"
            component={lazy(() =>
              import(/* webpackChunkName: "vScriptAll" */ './sub/vScript'),
            )}
          />
          <Route
            path="/practice-profile"
            component={lazy(() =>
              import(
                /* webpackChunkName: "vAppointmentAll" */ './sub/vAppointment'
              ),
            )}
          />
          <Redirect to={{ pathname: '/home' }} />
        </Switch>
      </Suspense>
    </App>
  </Router>
);

export default Index;
export { go };
