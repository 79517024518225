import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Textarea from 'react-textarea-autosize';

import AutoSuggestText from '../../../../components/AutoSuggestText/index';
import styles from './feelingForm.module.css';
import Dropdown from '../Dropdown';
import { useAppointmentStore } from 'store/mobx/mobx';
import Loading from 'components/loadingSpinner';
import { useComponentDidMount } from 'libs/newHttp';

const FeelingForm = observer(({postService, sessionDetails, Services, isFetching, Symptoms, changeReason, showWarning, warningMessage, note, changeService, onChange, reasons, methods, consult, setMethod}) => {
	const store = useAppointmentStore();
	const [selected, setSelected] = useState(false);
	const [idCount, setIdCount] = useState(2);

	const [symptomValues, setSymptomValues] = useState([]);
	const [inputObjects, setInputObjects] = useState([
		{
			id: 0,
			reasonIndex: 0,
			changeValue: changeReason,
			selected: false,
			items: Symptoms,
			placeholder: "Choose the symptoms you are experiencing",
			removeDisplayNone: removeDisplayNone,
			toggle: addDisplayNone,
			shiftBoxes: shiftBoxes,
			removeSymptomValue: removeSymptomValue,
			addSymptomValue: addSymptomValue
		},
		{
			id: 1,
			reasonIndex: 1, 
			changeValue: changeReason, 
			selected: false, 
			items: Symptoms, 
			placeholder: "+ Add", 
			removeDisplayNone: removeDisplayNone,
			toggle: addDisplayNone,
			selectedState: false,
			removeSymptomValue: removeSymptomValue,
			addSymptomValue: addSymptomValue
		}
	])
  
	const removeDisplayNone = () => {
		setSelected(true);
  	}

  	const addDisplayNone = () => {
		setSelected(false);
  	}

	const addSymptomValue = (val) => {
		let arr = symptomValues;

		arr.push(val);
		setSymptomValues(arr);
	}

	const removeSymptomValue = (element) => {
		let arr = symptomValues;

		element === 0 ? arr.shift() : arr.pop();
		setSymptomValues(arr);
	}

  	const shiftBoxes = () => {
		let boxes = inputObjects
		boxes.shift();
		boxes[0].reasonIndex = 0;
		boxes[0].placeholder = "Choose the symptoms you are experiencing";
		boxes[0].removeDisplayNone = removeDisplayNone;
		boxes[0].toggle = addDisplayNone;
		boxes[0].shiftBoxes = shiftBoxes;

		boxes.push({
			id: idCount,
			reasonIndex: 1, 
			changeValue: changeReason, 
			selected: false, 
			items: Symptoms, 
			placeholder: "+ Add", 
			removeDisplayNone: () => {},
			toggle: () => {},
			selectedState: false,
			removeSymptomValue: removeSymptomValue,
			addSymptomValue: addSymptomValue
		})

		setIdCount(idCount + 1)
		setInputObjects(boxes);
  	}

  	let classnames = symptomValues.length > 0 ? styles.optionVisible : styles.displayNone;
  	return(
      	<div className={styles.container}>
			
        	<form className={styles.feelingForm}>
				<Loading isFetching={isFetching} style={{fill: "#07A3C8", width: "100%"}} />
				{showWarning &&
					<div className={styles.redBorder}>
						{warningMessage.map((item, i) => {
							return (
								<div key={i} className={styles.redColor}>{item}</div>
							)
						})}
					</div>
				}
				{!showWarning &&
					<div><br></br><br></br><br></br>
					</div>
				}
          		<div className={styles.question}>What service do you require today? (You must choose 1)</div>
				<AutoSuggestText 
					changeValue={changeService} 
					_selected={false} 
					items={Services} 
					placeholder={"Choose the service you require from your clinic today"} 
					removeDisplayNone={() => {}}
					toggle={() => { }}
					removeSymptomValue={() => {}}
					addSymptomValue={() => {}}  
					postItem={postService}
					reasonIndex={null}
				/>
          
				<div className={styles.question}>What symptoms are you experiencing (Choose up to 2)</div>
				<div className={styles.questionContainer}>
					{Symptoms && inputObjects.map((item) => 
						<div key={item.id} className={item.reasonIndex === 1 ? classnames : symptomValues.length === 0 ? styles.normal : ""}>            
							<AutoSuggestText 
								key={item.id}
								reasonIndex={item.reasonIndex} 
								changeValue={item.changeValue} 
								_selected={item.selected} 
								items={Symptoms} 
								placeholder={item.placeholder} 
								removeDisplayNone={removeDisplayNone}
								toggle={addDisplayNone}
								shiftBoxes={shiftBoxes}
								addSymptomValue={addSymptomValue}
								removeSymptomValue={removeSymptomValue}   
							/>
						</div>
					)}
          		</div>
		  
				{methods && consult &&
					<div>
						<div className={styles.question}>What type of appointment?</div>
						<Dropdown items={methods} setMethod={(method) => setMethod(method)} />
					</div>
				}
				<Textarea
					placeholder= "Write more information for the reason to visit"
					value={note}
					onChange={onChange}
					className={styles.textarea}
					maxLength={120}
				/>
        	</form>
      </div>
    )
})

export default FeelingForm;