import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useAccountStore } from '../../store/mobx/mobx';
import { go } from '../../routes';
import { formatName } from 'libs/misc';

const Container = styled.div`
    width: 100%;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: fit-content;
    font-size: 15px;
    background-color: white;
`
const Item = styled.div`
    cursor: pointer;
    color: #07A3C8;
    height: fit-content;
`
const PaymentsSub = ["Payment Method", "Transaction History", "Active Outstanding"]
const SettingsSub = ["Home", "Identity", "Privacy", "Notification", "Security"]
const PHRSub = ["My Health Record", "My Health Summary", "Labs/Results", "Immunisations", "Clinical Notes"]

interface Props {
    lowLevel: string;
    lowLink: () => void;
}

const RouteBar: React.FC<Props> = observer(({lowLevel, lowLink}) => {
    const { FirstName } = useAccountStore().profile;
    const { LastName } = useAccountStore().profile;

    let highLevel: string = "";
    let onClick: () => void = () => console.log("none");

    if (PaymentsSub.includes(lowLevel)) { 
        highLevel = "My Wallet"; 
        onClick = SettingsSub[0] !== lowLevel ? () => go.settings() : () => window.location.reload(); 
    } else if (SettingsSub.includes(lowLevel)) { 
        highLevel = "Settings"; 
        onClick = SettingsSub[0] !== lowLevel ? () => go.settings() : () => window.location.reload();  
    } else if (PHRSub.includes(lowLevel)) { 
        highLevel = "My Health Record"; 
        onClick = SettingsSub[0] !== lowLevel ? () => go.PHRSummary() : () => window.location.reload();  
    }

    return (
        <Container>
            <Item onClick={() => onClick()}>{highLevel}</Item>&nbsp;/&nbsp;
            <Item onClick={lowLink}>{lowLevel}</Item>&nbsp;/&nbsp; {formatName(FirstName)}&nbsp;{formatName(LastName)}
        </Container>
    );
});

export default RouteBar;