import { call, takeEvery, put } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';
import { GenericParserHttp } from '../../libs/Http';
import { checkAuth } from 'libs/misc';
import alert from 'libs/Alert';

const api = (id, token) =>
  GenericParserHttp.delete('consumer/practice', {
    data: { PracticeId: id },
    token,
  });

function* unlinkPractice({ payload: { practice, token } }) {
  try {
    yield call(api, practice.PracticeId, token);
    yield put(actions.unlinkPracticeSuccess(practice));
    yield put(actions.getPracticeListings(token));
  } catch (err) {
    checkAuth(err);
    alert.error(err.response.body.Message)
    yield put(actions.unlinkPracticeFailure(err));
  }
}

function* unlinkPracticeSaga() {
  yield takeEvery(actionTypes.unlinkPractice, unlinkPractice);
}

export { unlinkPracticeSaga };
