interface PathResult {
    path: string;
    queries: Record<string, string>
}

export const parseUriPath = (path?: string): PathResult => {
    const back = new URL(path || '', 'http://a.com');
    const backParams = {} as any;
    for(const [key, value] of back.searchParams.entries()) {
        backParams[key] = value;
    }
    return {
        path: back.pathname,
        queries: backParams
    }
}