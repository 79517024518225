import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import moment from 'moment';
import { find } from 'lodash/fp';

import Header from '../../components/UIContainer/MobileUI/header/index';
import AppointmentInfo from '../GuestRegister/components/AppointmentInfo/index';
import styles from './loginTriage.module.css';
import autoRegisterImg from '../../images/autoRegisterImg.png';
import { DoctorProfilePropType } from '../../PracticeProfilePage/BookingPageMobile/propTypes/index';
import { go } from '../../routes/index';
import { isDesktop } from '../../config';

export class LoginTriage extends Component {
  static propTypes = {
    appointment: PropTypes.instanceOf(moment).isRequired,
    doctor: DoctorProfilePropType,
  };
  goSignIn = () => {
    go.signIn(this.props.match.params.id);
  };
  goPatient = () => {
    go.patientDetails(this.props.match.params.id);
  };
  render() {
    return (
      <div className={styles.backgroundShade}>
        <div
          className={styles.container}
          style={
            isDesktop()
              ? null
              : {
                  backgroundImage: `url(${autoRegisterImg})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }
          }
        >
          {!isDesktop() && (
            <Header
              headerClassNames={styles.loginHeader}
              goBackFn={() => go.practiceBooking(this.props.profile.UrlName)}
              secondLine={
                <AppointmentInfo
                  doctor={this.props.doctor}
                  appointment={this.props.appointment}
                />
              }
            />
          )}
          <div className={styles.welcome}>
            <div className={styles.tenakoe}>T&#275;n&#257; koe</div>
            <div>Welcome to Vensa</div>
          </div>
          <div className={styles.buttonContainer}>
            <button
              id="nw_guest"
              className={styles.newButton}
              onClick={this.goPatient}
            >
              I&#39;m new to Vensa
            </button>
            <button
              id="nw_login"
              className={styles.loginButton}
              onClick={this.goSignIn}
            >
              Log In
            </button>
          </div>
          <div className={styles.emergencyWarning}>
            If this is a medical emergency, please call 111 for an ambulance.
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  doctor: find(
    ({ PmsUserId }) =>
      PmsUserId === state.practiceAppointmentReducer.selectedTime.doctorId,
    state.practiceAppointmentReducer.doctorInfo.doctors,
  ),
  appointment: moment(state.practiceAppointmentReducer.selectedTime.time),
  profile: state.practiceProfileReducer.profile
});

export default connect(mapStateToProps)(LoginTriage);
