import React, { lazy } from 'react';
import { isDesktop } from '../../config';
import { ProtectedRoute, push } from '../utils';
import Desktopidentity from 'Identity/DesktopIdentityPage/DesktopIdentityPage';
import Identity from 'Identity/index.js';
import MobileSelectPractice from '../../Identity/AddPractice/index.js';
import DesktopSelectPractice from 'Identity/DesktopIdentityPage/subroutes/SelectPractice';
import DesktopPin from 'Identity/DesktopIdentityPage/subroutes/PinSetup';
import MobilePin from 'Identity/SecurityPin/index.js';
import DesktopPhoto from 'Identity/DesktopIdentityPage/subroutes/PhotoSetup';
import MobilePhoto from '../../Identity/PhotoSecurity/index.js';
import MobilePrivacy from '../../Identity/Privacy/index.js';
import DesktopPrivacy from 'Identity/DesktopIdentityPage/subroutes/PrivacySetup';
import PopUp from 'components/DesktopComponents/PopUpLeavingFlow';
import DesktopSubmit from 'Identity/DesktopIdentityPage/subroutes/Submit';
import MobileSubmit from '../../Identity/SubmitRequest/index.js';
import MobileViewRequest from '../../Identity/ViewIdentityRequest/ViewIdentityRequest';
import DesktopViewRequest from '../../Identity/DesktopIdentityPage/components/ViewIdentityRequestDesktop';
import CompleteVerificationFlow from 'Identity/CompleteVerificationFlow/CompleteVerificationFlow';
import CompleteVerificationFlowDesktop from 'Identity/CompleteVerificationFlow/CompleteVerificationFlowDesktop/CompleteVerificationFlowDesktop';
import CompleteVerificationFlowProcessing from 'Identity/CompleteVerificationFlow/Processing.js';

const go = {
    identity: () => { push('/identity'); },
    verifyAtPractice: () => push('/identity-verify-practice'),
    verifyPrivacy: (startAtState) => push({
		pathname: '/identity-privacy-verification',
		state: { startAtState }
	}),
    choosePractice: () => push('/identity-choose-practice'),
    searchPractice: (onFinishAdd, shouldAdd) => {
        push({
            pathname: '/identity-search-practice',
            state: { onFinishAdd, shouldAdd }
    })},
    identityPin: () => push('/identity-pin'),
    identityPhoto: () => push('/identity-photo'),
    identitySubmit: () => push('/identity-submit'),
    identityProcessing: () => push('/identity-processing'),
    identitySubmitted: (OrgId) => push({
      pathname: '/identity-submitted',
      search: '?id=' + OrgId
    }),
    identityStatus: () => push('/identity-status'),
    identityMobile: () => push('/identity-mobile-verification'),
    identityRegistrationError: () => push('./identity-registration-error'),
    identityViewRequest: (requestId) => push({
      pathname: '/identity/request',
      search: '?id=' + requestId
    }),
	identityCompleteFlow: () => push('/complete-identity'),
	identityCompleteFlowProcessing: () => push('/complete-identity-processing')
};

const IdentityRoutes = [
  <ProtectedRoute
    exact
    key="/identity"
    path="/identity"
    component={(isDesktop() ? Desktopidentity : Identity)}
  />,
  <ProtectedRoute
    key="/identity/request"
    path="/identity/request"
    component={(isDesktop() ? DesktopViewRequest : MobileViewRequest)}
  />,
  <ProtectedRoute
    key="/identity-verify-practice"
    path="/identity-verify-practice"
    component={lazy(() =>
      import(
        /* webpackChunkName: "VerifyAtPracticeCard" */ '../../Identity/VerifyAtPracticeCard/index.js'
      ),
    )}
  />,
  <ProtectedRoute 
	  key="/complete-identity"
	  path="/complete-identity"
	  component={isDesktop() ? CompleteVerificationFlowDesktop : CompleteVerificationFlow}
  />,
  <ProtectedRoute 
	key="/complete-identity-processing"
	path="/complete-identity-processing"
	component={CompleteVerificationFlowProcessing}
	/>,
  <ProtectedRoute
    key="/identity-privacy-verification"
    path="/identity-privacy-verification"
    component={(isDesktop() ? DesktopPrivacy : MobilePrivacy)}
  />,
  <ProtectedRoute
  key="/identity-status"
  path="/identity-status"
  component={lazy(() => import('../../Identity/IdentityCompleted/index.js'))}
/>,
  <ProtectedRoute
    key="/identity-choose-practice"
    path="/identity-choose-practice"
    component={(isDesktop() ? DesktopSelectPractice : MobileSelectPractice)}
  />,
  <ProtectedRoute
    key="/identity-search-practice"
    path="/identity-search-practice"
    component={lazy(() => import('../../Identity/PracticeSearch/index.js'))}
  />,
  <ProtectedRoute
    key="/identity-pin"
    path="/identity-pin"
    onLeave={ PopUp }
    component={(isDesktop() ? DesktopPin : MobilePin)}
  />,
  <ProtectedRoute
    key="/identity-photo"
    path="/identity-photo"
    component={(isDesktop() ? DesktopPhoto : MobilePhoto)}
  />,
  <ProtectedRoute
    key="/identity-submit"
    path="/identity-submit"
    component={(isDesktop() ? DesktopSubmit : MobileSubmit)}
  />,
  <ProtectedRoute
    key="/identity-processing"
    path="/identity-processing"
    component={lazy(() => import('../../Identity/Processing/index.js'))}
  />,
  <ProtectedRoute
    key="/identity-submitted"
    path="/identity-submitted"
    component={lazy(() => import('../../Identity/Submitted/index'))}
  />,
  <ProtectedRoute
    key="/identity-mobile-verification"
    path="/identity-mobile-verification"
    component={lazy(() => import('../../Identity/MobileVerification/MobileVerificationPage'))}
  />,
  <ProtectedRoute
    key="/identity-registration-error"
    path="/identity-registration-error"
    component={lazy(() => import('../../Identity/RegistrationError/RegistrationErrorPage'))}
  />,       
];

export default IdentityRoutes;
export { go };
