import React from 'react';
import styles from './cardButton.module.css';
import { ReactComponent as Arrow } from '../../../svgs/roundArrow.svg';
import { ReactComponent as DisabledArrow } from 'svgs/disabledRoundArrow.svg';

const CardButton = (props) => {
    return(
        <div className={styles.container} onClick={() => props.disabled ? {} : props.onClick()} 
            style={{
                borderTop: props.removeBorder ? "none" : "1px solid #DADADA",
                borderBottom: props.removeBorder ? "none" : "1px solid #DADADA"
            }}>
            <div className={styles.text} style={{marginLeft: "1rem", color: props.disabled ? "#DADADA" : "#07A3C8"}}>{props.text}</div>
            <div className={styles.placeholder} />
            {!props.disabled ? 
                <Arrow style={{overflow: "visible", display: "block", marginRight: "1rem"}} />
                :
                <DisabledArrow style={{overflow: "visible", display: "block", marginRight: "1rem"}} />
            }
        </div>
    );
}

export default CardButton;