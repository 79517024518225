import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';

import CardBanner from '../../components/CardBanner/index';
import styles from './pending.module.css';
import { formatMobile } from '../../../libs/Mobile';
import { detailsPropType } from '../../propTypes';

const getBorderStyle = (isOpen, isMessage, color) => {
  if (isOpen && isMessage) {
    if (color === 'Red') return { borderLeft: '3px solid #FF6A65' };
    if (color === 'Green') return { borderLeft: '3px solid #23C373' };
    return {};
  }
  return {};
};

const Pending = ({ details, status, color, statusCode, isOpen, hasPatientNotes }) => (
  <div
    className={classnames(styles.container, { [styles.openContainer]: isOpen })}
    style={getBorderStyle(isOpen, details.Message, color)}
  >
    {isOpen && details.Charge && (
      <div className={styles.ref}>{`REF: ${details.Charge.Reference}`}</div>
    )}
    <CardBanner status={status} color={color} statusCode={statusCode} hasPatientNotes={!isOpen && hasPatientNotes}/>
    {details && (
      <div className={styles.innerContainer}>
        {isOpen && details.DoctorNote && (
          <div className={styles.description}>{details.DoctorNote}</div>
        )}
        {isOpen && details.DoctorNote && (
          <div className={styles.practice}>
            <div>{`From ${details.OrganisationName} on`}</div>
            <div>{`${moment(details.TextSentAt).format(
              'DD/MM/YY',
            )} to ${formatMobile(details.TextSentTo)}`}</div>
          </div>
        )}
      </div>
    )}
  </div>
);

Pending.propTypes = {
  status: PropTypes.string,
  color: PropTypes.string,
  details: detailsPropType,
  isOpen: PropTypes.bool,
};

export default Pending;
