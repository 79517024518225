import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Cross } from '../../../../svgs/close.svg';
import styles from './error.module.css';

class Error extends Component {
  static propTypes = {
    toggleError: PropTypes.func.isRequired,
    children: PropTypes.string.isRequired,
  };
  componentDidMount() {
    setTimeout(this.handleClose, 5000);
  }
  handleClose = () => {
    this.props.toggleError('');
  };
  render() {
    return (
      <div className={styles.container} onClick={this.handleClose}>
        <div className={styles.text}>{this.props.children}</div>
        <Cross className={styles.cross} />
      </div>
    );
  }
}

export default Error;
