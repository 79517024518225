import { observable, action, makeAutoObservable } from 'mobx';
import {
  generalRequest,
  LOADING_STATUS,
  LoadingStatusType,
} from '../../../libs/newHttp';
import {
  ICard,
  IHealthTransactionItem,
  HealthTransactionItemType,
  IHealthTransaction,
} from './paymentStore.type';
import {
  getPaymentSummaryAPI,
  getTransactionHistoryAPI,
  getTransactionDetailAPI,
  deleteCardAPI,
  setDefaultCardAPI,
  postPaymentCardAPI,
  getCardsAPI,
} from './paymentStore.api';

export class PaymentStore {
  constructor(){
    makeAutoObservable(this);
  }
  @observable paymentSummaryLoadingStatus = LOADING_STATUS.INITIAL;
  @observable cards: ICard[] = [];
  @observable cardsLoadingStatus = LOADING_STATUS.INITIAL;  
  @observable cardList: ICard[] = [];

  @observable healthTransactionHistoryLoadingStatus = LOADING_STATUS.INITIAL;
  @observable healthTransactionHistory: IHealthTransactionItem[] = [];

  @observable healthTransactionLoadingStatus = LOADING_STATUS.INITIAL;
  @observable healthTransaction: IHealthTransaction = {
    Id: '',
    FirstName: '',
    LastName: '',
    OrganisationId: '',
    OrganisationName: '',
    OrganisationPaymentEnabled: false,
    PmsUserId: '',
    PmsUserName: '',
    PmsUserTitle: '',
    ProfilePictureUrl: '',
    DependantId: '',
    CTPP: {},
    FreeFormMedication: {},
    Medications: {},
    Status: '',
    StatusColour: '',
    DateTime: '',
    DoctorNote: '',
    Read: false,
    Type: '',
    Invoice: {
      Items: [],
      Total: 0,
    },
    Charge: {
      Id: '',
      Reference: '',
      Status: '',
      Last4: '',
      Amount: 0,
      Time: '',
      SettleAT: '',
    },
    LocationName: '',
    LocationAddress: '',
    HandledAt: '',
    TextSentAt: '',
    TextSentTo: '',
    PayOnline: false,
  };
  @observable acceptedPrivacyStatement: boolean = false;

  @action.bound
  async createPaymentMethod(token: string, saveCard: boolean) {
    await generalRequest.bind(this)({
      loadingStatusName: 'createPaymentMethodLoadingStatus',
      api: postPaymentCardAPI(token, saveCard),
      dataPropertyName: 'createPaymentMethod',
    });
  }

  @action.bound
  async getCards() {
    await generalRequest.bind(this)({
      loadingStatusName: 'cardsLoadingStatus',
      api: getCardsAPI,
      dataPropertyName: 'cardList',
    });
  }

  @action.bound
  async getPaymentSummary() {
    await generalRequest.bind(this)({
      loadingStatusName: 'paymentSummaryLoadingStatus',
      api: getPaymentSummaryAPI,
      dataPropertyName: 'cards',
    });
  }

  @action.bound
  async getTransactionHistory() {
    if (this.isLoaded(this.healthTransactionHistoryLoadingStatus)) return;

    await generalRequest.bind(this)({
      loadingStatusName: 'healthTransactionHistoryLoadingStatus',
      api: getTransactionHistoryAPI,
      dataPropertyName: 'healthTransactionHistory',
    });
  }

  @action.bound
  async getTransactionItem(id: string, type: HealthTransactionItemType) {
    await generalRequest.bind(this)({
      loadingStatusName: 'healthTransactionLoadingStatus',
      api: getTransactionDetailAPI({
        transactionId: id,
        transactionType: type,
      }),
      dataPropertyName: 'healthTransaction',
    });
  }

  @action.bound
  async deleteCard(token: string) {
    await generalRequest.bind(this)({
      loadingStatusName: 'deleteCardLoadingStatus',
      api: deleteCardAPI({
        cardId: token
      }),
      dataPropertyName: 'deleteCard',
    });
  }

  @action.bound
  async setDefaultCard(token: string) {
    await generalRequest.bind(this)({
      loadingStatusName: 'setDefaultCardLoadingStatus',
      api: setDefaultCardAPI(token),
      dataPropertyName: 'setDefaultCard',
    });
  }

  private isLoaded(status: LoadingStatusType) {
    return status === LOADING_STATUS.LOADED;
  }
}
