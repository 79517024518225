import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';
import SignatureCanvas from 'react-signature-canvas';
import styles from './index.module.scss';
import cn from 'classnames';
import { Touchable } from 'types';

type SignaturePanelProps = {
  canvasRef: React.RefObject<ReactSignatureCanvas>;
  dataUrl?: string;
  onChange: (flag: boolean) => void;
} & Touchable;

const adapter = (touch: boolean) => ({
  tip: touch ? 'fingers' : 'mouse'
});

const SignaturePanel = (
  {
    canvasRef,
    dataUrl,
    onChange,
    touch = false
  }: SignaturePanelProps) => {
    const [{width, height}, setSize] = useState({width: 0, height: 0});
    const [signed, setSigned] = useState(!!dataUrl);

    useEffect(() => {
        canvasRef && 
        canvasRef.current &&
        (dataUrl && canvasRef.current.fromDataURL(dataUrl)) &&
        setSize({width: canvasRef.current.getCanvas().clientWidth, height: canvasRef.current.getCanvas().clientHeight})
    }, [dataUrl]);

    const handleClear = () => {
      canvasRef.current!.clear();
      setSigned(false);
      onChange && onChange(false);
    }

    const handleEnd = (e: MouseEvent) => {
      setSigned(true);
      onChange && onChange(true);
    }
    const tipText = adapter(touch).tip;
  return (
    <>
      <div className={styles.topContainer}>
          <span className={styles.tip}>Please sign below (Use {tipText})</span>
          <span className={cn(styles.clear, {[styles.active]: signed})} onClick={handleClear}>Clear Signature</span>
      </div>
        <SignatureCanvas
            dotSize={5}
            canvasProps={{width: width, height: height, className: styles.pad}}
            onEnd={handleEnd}
            clearOnResize={false}
            ref={canvasRef}/>
    </>
    
  )
}

export default SignaturePanel;