import { call, takeEvery, put } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions'
import { GenericParserHttp } from 'libs/Http';

const apiOld = () =>
    GenericParserHttp.get(`consumer/payment/card`);
const api = (sessionId) => 
    GenericParserHttp.get(`consumer/appointment/session/card?sessionId=${sessionId}`);


function* getCards({payload: { sessionId } }) {
    try {
        const response = yield call(api, sessionId)
        yield put(actions.getCardsSuccess(response.body[0]));
    } catch (err) {
        yield put(actions.getCardsFailure());
    }
}

function* getCardsSaga() {
    yield takeEvery(actionTypes.getCards, getCards);
}

export { getCardsSaga }