import React from 'react';
import { observer } from 'mobx-react-lite'

import * as Styled from '../DesktopSignUpFlow.styled';

interface IProps {
    firstName: string;
    surName: string;
    preferredName: string;
}

const DesktopSignUpFlowCardName: React.FC<IProps>  = observer(({ firstName, surName, preferredName }) => {
    return (
        <Styled.FlexV>
            <Styled.Title>Please confirm your name</Styled.Title>
            <Styled.FlexV style={{marginTop: "1rem"}}>
                <Styled.Label>Legal First Name</Styled.Label>
                <Styled.Input type={"text"} value={firstName} readOnly />
                <Styled.Label style={{marginTop: "1rem"}}>Legal Surname</Styled.Label>
                <Styled.Input type={"text"} value={surName} readOnly/>
                <Styled.Label style={{marginTop: "1rem"}}>Preferred Name (Optional)</Styled.Label>
                <Styled.Input type={"text"} value={preferredName} readOnly/>
            </Styled.FlexV>
            <Styled.TitleSmall style={{ textAlign: "left" }}>Names entered above must exactly match your official identity document (e.g. passport, driver's license etc)</Styled.TitleSmall>
        </Styled.FlexV>
    )
})

export default DesktopSignUpFlowCardName;