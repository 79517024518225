import { call, takeEvery, put } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';
import HttpLib, { GenericParserHttp } from '../../libs/Http';
import Alert from '../../libs/Alert';
import { go } from '../../routes';
import { resolve } from 'dns';

const parseError = ({ status }) => {
  if (status === 409)
    return 'You already have a dependant that matches the details provided.';
  return 'An Unexpected Error Occurred.<br/>Please Try Again.';
};

const alertMethod = error => Alert.error(error);

const DependantParserHttp = HttpLib(parseError, alertMethod);

const api = (dependant, token, isNew) =>
  DependantParserHttp.post(`consumer/carecircle${isNew ? '/add' : '/update'}`, {
    data: dependant,
    token,
  }).then(({ body }) => body);

const avatarApi = (file, token, id) =>
  GenericParserHttp.postImage(
    `consumer/carecircle/${id ? `${id}/` : ''}avatar`,
    {
      file,
      token,
    },
  );

function* addDependant({ payload: { dependant, token, isNew } }) {
  try {
    yield call(api, dependant, token, isNew);
    yield put(actions.addDependantSuccess());
    yield put(actions.fetchDependants(token));
    go.dependants();
  } catch (err) {
    yield put(actions.addDependantFailure(err));
  }
}

function* addAvatar({ payload: { file, token, id } }) {
  try {
    const response = yield call(avatarApi, file, token, id);
    yield put(actions.addAvatarSuccess(response.body));
    yield put(actions.fetchDependants(token));
  } catch (err) {
    yield put(actions.addAvatarFailure(err));
  }
}

function* addDependantSaga() {
  yield takeEvery(actionTypes.addDependant, addDependant);
}

function* addAvatarDependantSaga() {
  yield takeEvery(actionTypes.addAvatar, addAvatar);
}

export { addDependantSaga, addAvatarDependantSaga };
