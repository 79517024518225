import PropTypes from 'prop-types';

const detailsPropType = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  dateOfBirth: PropTypes.string,
  mobile: PropTypes.string,
});

export { detailsPropType };
