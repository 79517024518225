import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Text } from 'components/vensaComponents';

export const BaseList: React.FC<{
  title: string;
  space?: boolean;
  render: () => React.ReactElement[];
}> = observer(({ title, render, space }) => {
  return (
    <Box direction="column" borderRadius={"4px"}>
      <Text color="grey4" mb={4} fontSize="14px" mt={space ? 6 : 4}>
        {title}
      </Text>
      <Box direction="column" boxShadow={6} bg="white" borderRadius={"4px"}>
        {render()}
      </Box>
    </Box>
  );
});
