import { takeLatest } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';
import { NoErrorParserHttp } from '../../../libs/Http';
import createPoll from '../../../libs/PollingScript';
import { go } from '../../../routes';
import delay from '../../../libs/Delay';

// const POLL_TIMEOUT_MILLIS = 300000;
const HANGING_TIMEOUT_MILLIS = 30000;
const POLL_INTERVAL = 3000;

const api = ({ payload: { scriptId, token } }) =>
  NoErrorParserHttp.get(`consumer/prescription/check/${scriptId}`, {
    token,
  }).then(({ body }) => body);

function* finishedPolling() {
  yield delay(2000);
  go.home();
  // TODO: Instead of routing home, send a prop to close scriptRequestPopUp
}

function* errorPolling(err) {
  // TODO: Delete this when refactoring Polling.j and PollingScript.js (shouldn't need both)
}

const { initialisePoll, executePoll } = createPoll({
  pollFn: api, // function to run on each poll
  // action to be fired when a single poll has finished?
  pollRoundtripCompleteActionCreator: actions.confirmationSuccess,
  // action to be fired when processing returns an error
  pollErrorActionCreator: () =>
    actions.confirmationPollEnd(false, { status: 400 }),
  // action to be fired when processing has succeeded
  pollFinishedActionCreator: () => actions.confirmationPollEnd(true, null),
  // function to run if processing succeeded
  pollFinishedGenerator: finishedPolling,
  // function to run if processing errors
  pollErrorGenerator: errorPolling,
  // returns true if processing has not succeeded or errored, and polling should continue
  checkKeepPollingFn: ({ Status }) => Status === 402 || Status === 404,
  // returns true if processing succeeds
  checkFinishedPollingFn: ({ Status }) => Status === 200,
  // returns true if processing has returned an error
  checkErrorPollingFn: ({ Status }) => Status === 406,
  // action to be fired if polling errors
  errorActionCreator: actions.confirmationFailure,
  pollTimeMillis: POLL_INTERVAL, // how often each poll runs
  // sets confirmation state to checking
  // eslint-disable-next-line max-len
  pollActionCreator: ({ payload: { scriptId, token } }) =>
    actions.checkForConfirmation(scriptId, token),
  // indicates a single poll has finished without error
  pollRoundtripCompleteActionType: actionTypes.confirmationSuccess,
  // indicates that polling should end, and how
  endStates: [actionTypes.confirmationPollEnd, actionTypes.confirmationFailure],
  timeoutActionCreator: () =>
    actions.confirmationFailure({ status: -1, message: 'timeout' }),
  hangingTimeoutMillis: HANGING_TIMEOUT_MILLIS, // when user should stop waiting for confirmation
  hangingActionCreator: actions.setIsBusy, // what to do when user should stop waiting
});

function* requestConfirmationSaga() {
  yield takeLatest(actionTypes.checkForConfirmation, executePoll);
}

function* scriptConfirmationSaga() {
  yield takeLatest(actionTypes.confirmationPollStart, initialisePoll);
}

export { scriptConfirmationSaga, requestConfirmationSaga };
