import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';

import styles from './appointmentInfo.module.css';
import { DoctorProfilePropType } from '../../../../PracticeProfilePage/BookingPageMobile/propTypes/index';

const AppointmentInfo = ({ doctor, appointment, confirming }) => (
  <div
    className={classnames(styles.outer, { [styles.confirming]: confirming })}
  >
    <div className={styles.container}>
      <div className={styles.doctorInfo}>
        <img
          className={styles.doctorImg}
          src={doctor ? doctor.ProfilePictureURL : ''}
          alt={doctor && doctor.Name}
        />
        <span className={styles.doctorName}>{doctor ? doctor.Name : ''}</span>
      </div>
      <div className={styles.timeInfo}>
        <span className={styles.time}>
          <span>{appointment ? appointment.format('DD MMM') : ''}</span>
          <span>{appointment ? appointment.format('h:mm a') : ''}</span>
        </span>
      </div>
    </div>
  </div>
);

AppointmentInfo.propTypes = {
  confirming: PropTypes.bool,
  doctor: DoctorProfilePropType,
  appointment: PropTypes.instanceOf(moment),
};

export default AppointmentInfo;
