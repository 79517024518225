import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import * as Styled from './Section.styled';
import Item from '../ClickableItem';
import styled from 'styled-components';

const Label = styled.div`
    font-size: 14px;
    color: #07A3C8;
    width: 90%;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding: 0.5rem 0;
`
interface IProps {
    header: string;
    titles: string[],
    lists: any[],
    medications?: boolean
}
const Section: React.FC<IProps> = observer(({header, titles, lists, medications}) => {
    return (
        <Styled.Container>
            <Styled.Header>{header}</Styled.Header>
            <Styled.Card>
                <Styled.Section>
                    <Styled.Title>{titles[0]}</Styled.Title>
                    {lists[0].length === 0 && <Styled.NoContent>No Records Found</Styled.NoContent>}
                    {
                        lists[0].map((item: any) => 
                            <Item 
                                onClick={(keyword: string) => {
                                    window.open(`https://healthify.nz/search/results#stq=${keyword}&stp=1`)
                                }} 
                                text={item.NameOfAllergy || item.GenericName || item.Name} 
                            />
                        )
                    }
                </Styled.Section>
                {titles.length === 2 &&
                    <Styled.Section style={{marginRight: "0.5rem"}}>
                        <Styled.Title>{titles[1]}</Styled.Title>
                        {lists[1].length === 0 && <Styled.NoContent>No Records Found</Styled.NoContent>}
                        {
                            lists[1].map((item: any) => {
                                if (titles[1] === "Weight/Height") {
                                    return <Label>{item.Name}</Label>
                                }
                                return(
                                    <Item 
                                        onClick={(keyword: string) => {
                                            window.open(`https://healthify.nz/search/results#stq=${keyword}&stp=1`)
                                        }} 
                                        text={item.NameOfAllergy || item.GenericName || item.Name} 
                                    />
                                )
                            })
                        }                    
                    </Styled.Section>
                }
            </Styled.Card>
        </Styled.Container>
    )
})

export default Section;