import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';

import {
  isValidFormat,
  isTooOld,
  isUnderAge,
  isInFuture,
} from '../../libs/Dates';
import Alert from '../../libs/Alert';
import styles from './dateInput.module.css';

const formatMonth = month => {
  if (month.toString().length === 1) return `0${month}`;
  return `${month}`;
};

const formatDay = day => (day.toString().length === 1 ? `0${day}` : `${day}`);

class DateInput extends Component {
  static defaultProps = {
    allowUnderage: false,
  };
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func,
    dob: PropTypes.string,
    containerClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    allowUnderage: PropTypes.bool,
  };
  state = {
    day: this.props.dob
      ? formatDay(moment(this.props.dob, ['DD-MM-YYYY', 'YYYY-MM-DD']).date())
      : '',
    month: this.props.dob
      ? formatMonth(
          moment(this.props.dob, ['DD-MM-YYYY', 'YYYY-MM-DD']).month() + 1,
        )
      : '',
    year: this.props.dob
      ? moment(this.props.dob, ['DD-MM-YYYY', 'YYYY-MM-DD'])
          .year()
          .toString()
      : '',
    inputError: '',
    focused: false,
  };
  componentDidMount() {
    this.validate();
  }
  UNSAFE_componentWillReceiveProps({ dob, error }) {
    if (dob !== this.props.dob) {
      this.setState({
        day: formatDay(moment(dob, ['DD-MM-YYYY', 'YYYY-MM-DD']).date()),
        month: formatMonth(
          moment(dob, ['DD-MM-YYYY', 'YYYY-MM-DD']).month() + 1,
        ),
        year: moment(dob, ['DD-MM-YYYY', 'YYYY-MM-DD'])
          .year()
          .toString(),
      });
    }
    if (error && error !== this.props.error) {
      if (error.message !== 'You must be at least 16 years old') {
        Alert.error(error.message);
      } else Alert.close();
    }
  }
  onItemBlur = key => () => {
    if (this.state[key].length === 1)
      this.setState({ [key]: `0${this.state[key]}` }, this.handleChange);
    this.setState({ focused: false });
  };
  changeFn = (key, value, singleLimit, doubleLimit, focusPoint) => {
    let v = value;
    if (v.length === 1 && parseInt(v, 10) >= singleLimit) v = `0${v}`;
    if (v.length === 2 && parseInt(v, 10) >= doubleLimit)
      v = `0${v.slice(0, 1)}`;
    if (v.length === 2) this.focusInput(focusPoint);
    this.setState({ [key]: v.slice(0, 2) }, this.handleChange);
  };
  changeDay = e => {
    this.changeFn('day', e.target.value, 4, 32, 'month');
  };
  changeMonth = e => {
    this.changeFn('month', e.target.value, 2, 13, 'year');
  };
  changeYear = e =>
    this.setState({ year: e.target.value.slice(0, 4) }, this.handleChange);
  toggleFocus = () => {
    this.setState({ focused: true });
  };
  makeError = inputError => {
    if (this.props.onError) this.props.onError(inputError);
    return false;
  };
  handleChange = () => {
    if (this.validate()) {
      this.props.onChange(
        `${this.state.year}-${this.state.month}-${this.state.day}`,
      );
    }
  };
  validate = () => {
    if (
      this.state.year.length !== 4 ||
      this.state.month.length !== 2 ||
      this.state.day.length !== 2
    ) {
      return this.makeError('Date should be in DD/MM/YYYY format');
    } else {
      const date = `${this.state.year}-${this.state.month}-${this.state.day}`;
      if (!isValidFormat(date) || isInFuture(date))
        return this.makeError('Whoops, this date is invalid!');
      if (!this.props.allowUnderage && isUnderAge(date))
        return this.makeError('You must be at least 16 years old.');
      if (isTooOld(date))
        return this.makeError(
          "Wow! You're older than the oldest New Zealander. Impossible!",
        );
    }
    this.props.onError(null);
    return true;
  };
  handleBackspace = (key, loc) => e => {
    if (e.keyCode === 8) {
      if (this.state[key].length === 0) {
        this[loc].select();
        this.focusInput(loc);
      }
    }
  };
  focusInput = type => this[type].focus();
  render() {
    const { day, month, year } = this.state;

    const { containerClassName, inputClassName } = this.props;
    return (
      <div
        className={classnames(styles.container, containerClassName, {
          [styles.focused]: this.state.focused,
        })}
      >
        <label
          className={styles.label}
          onClick={() => this.focusInput('day')}
          htmlFor="date-input"
        >
          Date of Birth
        </label>
        <div
          id="date-input"
          className={classnames(styles.inputContainer, inputClassName)}
        >
          <div
            className={classnames(styles.inputDay, { [styles.faded]: !day })}
          >
            <input
              value={day}
              readOnly={this.props.readOnly}
              placeholder="DD"
              className={styles.input}
              type="tel"
              onChange={this.changeDay}
              onBlur={this.onItemBlur('day')}
              onFocus={this.toggleFocus}
              ref={c => {
                this.day = c;
              }}
              onKeyDown={this.handleBackspace('day', 'day')}
            />
          </div>
          <div
            className={classnames(styles.inputMonth, {
              [styles.faded]: !month,
            })}
          >
            <input
              value={month}
              placeholder="MM"
              className={styles.input}
              readOnly={this.props.readOnly}
              type="tel"
              onChange={this.changeMonth}
              onBlur={this.onItemBlur('month')}
              onFocus={this.toggleFocus}
              ref={c => {
                this.month = c;
              }}
              onKeyDown={this.handleBackspace('month', 'day')}
            />
          </div>
          <div className={styles.inputYear}>
            <input
              value={year}
              placeholder="YYYY"
              readOnly={this.props.readOnly}
              className={styles.input}
              type="tel"
              onChange={this.changeYear}
              onBlur={this.onItemBlur('year')}
              onFocus={this.toggleFocus}
              ref={c => {
                this.year = c;
              }}
              onKeyDown={this.handleBackspace('year', 'month')}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default DateInput;
