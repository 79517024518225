const actionTypes = {
  fetchDependants: 'FETCH_DEPENDANTS',
  fetchDependantsSuccess: 'FETCH_DEPENDANTS_SUCCESS',
  fetchDependantsFailure: 'FETCH_DEPENDANTS_FAILURE',
  addDependant: 'ADD_DEPENDANT',
  addDependantSuccess: 'ADD_DEPENDANT_SUCCESS',
  addDependantFailure: 'ADD_DEPENDANT_FAILURE',
  removeDependant: 'REMOVE_DEPENDANT',
  removeDependantSuccess: 'REMOVE_DEPENDANT_SUCCESS',
  removeDependantFailure: 'REMOVE_DEPENDANT_FAILURE',
  removeDependantReset: 'REMOVE_DEPENDANT_RESET',
  addAvatar: 'ADD_AVATAR_DEPENDANT',
  addAvatarSuccess: 'ADD_AVATAR_DEPENDANT_SUCCESS',
  addAvatarFailure: 'ADD_AVATAR_DEPENDANT_FAILURE',
  preloadDependantData: 'PRELOAD_DEPENDANT_DATA',
  clearDependantData: 'CLEAR_DEPENDANT_DATA',
};

const actions = {
  fetchDependants: id => ({
    type: actionTypes.fetchDependants,
    payload: {
      id,
    },
  }),
  fetchDependantsSuccess: dependants => ({
    type: actionTypes.fetchDependantsSuccess,
    payload: {
      dependants,
    },
  }),
  fetchDependantsFailure: error => ({
    type: actionTypes.fetchDependantsFailure,
    payload: {
      error,
    },
  }),
  addDependant: (dependant, token, isNew) => ({
    type: actionTypes.addDependant,
    payload: {
      dependant,
      token,
      isNew,
    },
  }),
  addDependantSuccess: () => ({
    type: actionTypes.addDependantSuccess,
  }),
  addDependantFailure: error => ({
    type: actionTypes.addDependantFailure,
    payload: {
      error,
    },
  }),
  removeDependant: (id, token) => ({
    type: actionTypes.removeDependant,
    payload: {
      id,
      token,
    },
  }),
  removeDependantSuccess: (id) => ({
    type: actionTypes.removeDependantSuccess,
    payload: {
      id
    }
  }),
  removeDependantFailure: error => ({
    type: actionTypes.removeDependantFailure,
    payload: {
      error,
    },
  }),
  removeDependantReset: () => ({
    type: actionTypes.removeDependantReset
  }),
  addAvatar: (file, token, id) => ({
    type: actionTypes.addAvatar,
    payload: {
      file,
      token,
      id,
    },
  }),
  addAvatarSuccess: (url) => ({
    type: actionTypes.addAvatarSuccess,
    payload: {
      url
    }
  }),
  addAvatarFailure: error => ({
    type: actionTypes.addAvatarFailure,
    payload: {
      error,
    },
  }),
  preloadDependantData: dependant => ({
    type: actionTypes.preloadDependantData,
    payload: {
      dependant,
    },
  }),
  clearDependantData: () => ({
    type: actionTypes.clearDependantData,
  }),
};
export { actionTypes, actions };
