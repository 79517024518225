import React from 'react';
import { StripeProvider } from 'react-stripe-elements';
import { isIPAddress, extractEnvironment } from 'services/validationByRegex';

const API_PREFIX = '/api/';
const MOBILE_SIZE = 1023;
const DESKTOP_SIZE = 1024;
const GOOGLE_API_KEY = 'AIzaSyDpLxcQ4o7mE7PtFftyRxzauI4xFN81t5s';

const transitionGroupProps = styles => ({
  component: 'div',
  transitionName: {
    enter: styles.enter,
    enterActive: styles.enterActive,
    leave: styles.leave,
    leaveActive: styles.leaveActive,
  },
  transitionEnterTimeout: 300,
  transitionLeaveTimeout: 300,
});

const isMobile = () => window.innerWidth <= MOBILE_SIZE;
const isDesktop = () => !isMobile();

const getStripeKey = () => {
  const env = process.env.NODE_ENV; 
  //const env = 'demo';
  if (env === 'production') return 'pk_live_zz7W38wNGKXbwq9u4gy53Haw';
  // if (env === 'development' || env === 'devuat' || env === 'uat')
  //   return 'pk_test_d8dTdaN9A9BL7rGhpvV2wpbp';
  // if (env === 'devdemo' || env === 'demo')
  //   return 'pk_test_f7QIEqNsskH0Ni52RrNeuAPT';
  // if (env === 'devtest' || env === 'test')
  //   return 'pk_test_d8dTdaN9A9BL7rGhpvV2wpbp';
  // return 'pk_test_6ZpORP94PAT2vnwxtnjqOeMQ';
  return 'pk_test_f7QIEqNsskH0Ni52RrNeuAPT';
};

const ENV = () => process.env.NODE_ENV;

const STRIPE_KEY = getStripeKey();

const VensaStripeProvider = ({ children }) => (
  <StripeProvider apiKey={STRIPE_KEY}>{children}</StripeProvider>
);

const IS_DEVELOPMENT_MODE = window.location.hostname === 'localhost' || isIPAddress(window.location.hostname);
const ENVIRONMENT = extractEnvironment();

export {
  VensaStripeProvider,
  STRIPE_KEY,
  API_PREFIX,
  MOBILE_SIZE,
  DESKTOP_SIZE,
  GOOGLE_API_KEY,
  ENV,
  isMobile,
  isDesktop,
  transitionGroupProps,
  IS_DEVELOPMENT_MODE,
  ENVIRONMENT
};
