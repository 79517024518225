import moment from 'moment';

import { call, takeLatest, put } from 'redux-saga/effects';
import { actions, actionTypes } from '../actions/actions';
import { actionTypes as healthFeedAT } from '../../HealthFeed/actions/actions';
import { actionTypes as appointmentAT } from '../../AppointmentsPage/actions/actions';
import { actionTypes as practiceAT } from '../../PracticesPage/actions/actions';
import { actionTypes as dependantsAT } from '../../DependantsPage/actions/actions';
import { actionTypes as scriptAT } from '../../vScript/actions/actions';
import { actionTypes as submitScriptAT } from '../../vScript/ConfirmRequest/actions/actions';
import { actionTypes as practiceProfileAT } from '../../PracticeProfilePage/actions/appointmentActions';
import { actionTypes as apptSessionAT } from '../../vAppointment/MobileVerification/actions/sessionActions';
import { actionTypes as apptBookAT } from '../../PracticeProfilePage/BookingPageMobile/actions/actions';
import Alert from '../../libs/Alert';
// eslint-disable-next-line max-len
import HttpLib from '../../libs/Http';

const tokenParserHttp = HttpLib(
  () => 'Unabled to retrive your account profile.<br/>Please login again.',
  Alert.info,
  '',
);

const api = token =>
  tokenParserHttp
    .post('token', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: {
        grant_type: 'refresh_token',
        refresh_token: token,
      },
    })
    .then(
      ({
        body: {
          access_token: accessToken,
          expires_in: expiresInSeconds,
          refresh_token: newRefreshToken,
        },
      }) => {
        window.localStorage.setItem(
          'token',
          JSON.stringify({
            token: accessToken,
            expiryTime: moment().add(expiresInSeconds - 120, 'seconds').toDate(),
            refreshToken: newRefreshToken,
          }),
        );

        return { accessToken, expiresInSeconds, newRefreshToken };
      },
    );

function* refresh({ payload: { refreshToken, email, page } }) {
  try {
    const token = yield JSON.parse(window.localStorage.getItem('token'));
    if (
      refreshToken ||
      (token &&
        moment().isAfter(moment(token.expiryTime).subtract(10, 'minutes')))
    ) {
      const { accessToken, expiresInSeconds, newRefreshToken } = yield call(
        api,
        refreshToken || token.refreshToken,
      );

      yield put(
        actions.loginSuccess(
          accessToken,
          moment()
            .add(expiresInSeconds - 120, 'seconds')
            .toDate(),
          email,
          newRefreshToken,
        ),
      );
      if (page) window.location = `${window.location.origin}/${page}`;
    }
  } catch (err) {
    if (err.response.body.error === "invalid_grant") { // TEMP solution for refresh token bug, fix later
      window.localStorage.removeItem('token');
      return;
    }
    yield put(actions.loginError(err));
  }
}

function* refreshTokenSaga() {
  yield takeLatest(
    [
      healthFeedAT.getFeed,
      appointmentAT.getConfirmedAppointments,
      scriptAT.fetchCurrentRequests,
      practiceAT.getPracticeListings,
      dependantsAT.fetchDependants,
      submitScriptAT.submitScriptRequest,
      practiceProfileAT.fetchMultiDayAppointments,
      apptSessionAT.createSession,
      apptBookAT.fetchDoctorAppointments,
      actionTypes.refresh,
    ],
    refresh,
  );
}

export { refreshTokenSaga };
