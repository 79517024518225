import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import TransactionHistoryCard from '../components/TransactionHistoryCard/TransactionHistoryCard';
import { usePaymentsStore } from 'store/mobx/mobx';
import emptyBox from 'images/emptyBox.png';
import LoadingSpinner from 'components/shared/common/LoadingSpinner';
import { LOADING_STATUS } from 'libs/newHttp';

const OuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
    overflow-y: hidden;
`
const Container = styled.div`
    width: 700px;
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
    overflow-x: hidden;
`
const Title = styled.div`
    font-size: 13px;
    font-weight: 480;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 5.2rem;
`
const EmptyContainer = styled.div`
    width: 300px;
    margin: auto;
    margin-top: 7rem;
`
const EmptyText = styled.div`
    text-align: center;
    font-size: 17px;
    color: #939393;
    font-weight: 300;
`
const EmptyBox = styled.img`
    width: 300px;
    height: auto;
    margin-bottom: 1rem;
`

const TransactionHistory: React.FC = observer(() => {
    const store = usePaymentsStore();

    useEffect(() => {
        store.getTransactionHistory();
    }, [store]);

    return (
        <OuterContainer>
        <Container>
            <Title>Transaction History</Title>
            {(store.healthTransactionHistoryLoadingStatus === LOADING_STATUS.LOADING) && <LoadingSpinner animateStyle={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}/>}
            {store.healthTransactionHistory?.length > 0 &&
                store.healthTransactionHistory?.map(transaction =>
                    <TransactionHistoryCard
                        patientName={`${transaction.Item?.FirstName} ${transaction.Item?.LastName}`}
                        service={transaction.Item?.Description}
                        organisationName={transaction.Item?.OrganisationName}
                        date={transaction.Item?.DateTime}
                        amountInCent={transaction.Item?.AmountInCent}
                        transactionType={transaction.Type}
                    />
                )
            }
            {(!store.healthTransactionHistory || store.healthTransactionHistory?.length === 0) &&
                <EmptyContainer>
                    <EmptyBox src={emptyBox} />
                    <EmptyText key={0}>
                        You don't have any transactions yet on your account.
                        <br />
                        <br />
                    </EmptyText>
                </EmptyContainer>}
        </Container>
        </OuterContainer>
    );
});

export default TransactionHistory;

