import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { isDesktop } from '../../../../config';
import MobileTitle from '../MobileTitle/index';
import BasicInfoForm from '../../../../components/BasicInfoForm/index';
import { detailsPropType } from '../../propTypes/index';
import { ReactComponent as Arrow } from '../../../../svgs/rightarrow2.svg';
import styles from './guardianDetails.module.css';

class GuardianDetails extends Component {
  submit = () => this.form.handleSubmit();
  getInfo = () => this.form.getInfo();
  onValid = value =>
    this.form && this.props.onValid(this.form.getInfo(), value);
  render() {
    const { guardianDetails, addDetails, isValid, goBack } = this.props;
    return (
      <div
        className={classnames(styles.container, {
          [styles.completed]: isValid,
        })}
      >
        {isDesktop() ? (
          <div className={styles.title}>
            <Arrow className={styles.arrow} onClick={goBack} />
            Guardian Details
          </div>
        ) : (
          <MobileTitle who="Guardian" isValid={isValid} />
        )}
        <BasicInfoForm
          ref={c => {
            this.form = c;
          }}
          onValid={this.onValid}
          itemClassName={styles.item}
          firstName={guardianDetails.firstName}
          lastName={guardianDetails.lastName}
          birthday={guardianDetails.dateOfBirth}
          phone={guardianDetails.mobile}
          submit={addDetails}
          phoneRequired
          hideButtons
          hideEmail
        />
      </div>
    );
  }
}

GuardianDetails.propTypes = {
  guardianDetails: detailsPropType.isRequired,
  addDetails: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  goBack: PropTypes.func,
};

export default GuardianDetails;
