import moment from 'moment';

const inputFormat = ['DD-MM-YYYY', 'DD/MM/YYYY', 'YYYY-MM-DD'];

const getMoment = date => moment.utc(date, inputFormat, true);

const isValidFormat = date => getMoment(date).isValid();

const getDateString = date => isValidFormat(date) && getMoment(date);

const toServerFormat = date =>
  moment.utc(date, inputFormat).format('YYYY-MM-DD');

const toNormalFormat = date =>
  moment.utc(date, inputFormat).format('DD/MM/YYYY');

const toNormalTime = date => {
  return moment(date).format('hh:mma');
}

const isUnderAge = date =>
  getMoment(date).isAfter(moment().subtract(16, 'years'));

const isTooOld = date =>
  getMoment(date).isBefore(moment().subtract(110, 'years'));

const isInFuture = date => getMoment(date).isAfter(moment());

const isValidDate = (
  date,
  allowUnderage = false,
  onlyUnderAge = false,
  required = true,
) => {
  if (!required) return (isValidFormat(date) && !isInFuture(date)) || !date;
  if (onlyUnderAge)
    return isValidFormat(date) && isUnderAge(date) && !isInFuture(date);
  return (
    isValidFormat(date) &&
    !isInFuture(date) &&
    !isTooOld(date) &&
    (allowUnderage || !isUnderAge(date))
  );
};

const getErrorMessage = (date, onlyUnderAge) => {
  if (!isValidFormat(date) || isTooOld(date) || isInFuture(date)) {
    return 'Invalid Date of Birth';
  }
  if (onlyUnderAge) {
    if (!isUnderAge(date)) {
      return 'Too old to be a minor';
    }
    return '';
  }
  if (isUnderAge(date)) {
    return 'You must be at least 16 years old';
  }
  return '';
};
const tConvert = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export {
  isValidFormat,
  isValidDate,
  isInFuture,
  isUnderAge,
  isTooOld,
  getErrorMessage,
  getDateString,
  getMoment,
  toServerFormat,
  toNormalFormat,
  tConvert,
  toNormalTime
};
