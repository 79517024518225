import { request, HTTP } from '../../../libs/newHttp';
import { HealthTransactionItemType } from './paymentStore.type';

export const getPaymentSummaryAPI = () =>
  request({
    method: HTTP.GET,
    url: `consumer/payment/summary`,
  });

export const getTransactionHistoryAPI = () =>
  request({
    method: HTTP.GET,
    url: `consumer/payment/transaction-history`,
  });

export const getTransactionDetailAPI = ({
  transactionId,
  transactionType,
}: {
  transactionId: string;
  transactionType: HealthTransactionItemType;
}) => () =>
  request({
    method: HTTP.GET,
    url: `consumer/payment/transaction-detail/${transactionId}?type=${transactionType}`,
  });

export const deleteCardAPI = ({cardId}:{cardId: string}) => () =>
  request({
    method: HTTP.DELETE,
    url: `consumer/payment/card?cardId=${cardId}`,
  });

export const setDefaultCardAPI = (cardId: string) => () =>
  request({
    method: HTTP.POST,
    url: `consumer/payment/preferred-card?cardId=${cardId}`,
  });

export const postPaymentCardAPI = (token: string, saveCard: boolean) => () =>
  request({
    method: HTTP.POST,
    url: `consumer/payment/card`,
    data: { Token: token, doNotSaveCard: !saveCard }
  });

export const getCardsAPI = () =>
  request({
    method: HTTP.GET,
    url: `consumer/payment/card`,
  });
