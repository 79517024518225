import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from 'components/vensaComponents';

export const MyHealthNavBarButton: React.FC<{
  label: string;
  isSelected: boolean;
  onClick: () => void;
}> = observer(({ label, isSelected, onClick }) => {
  return (
    <Box
      bg={isSelected ? 'white' : 'unset'}
      color={isSelected ? 'primary' : 'white'}
      p={2}
      py="14px"
      borderRadius="25px"
      alignItems="center"
      justifyContent="center"
      minWidth="150px"
      cursor="pointer"
      onClick={onClick}
    >
      {label}
    </Box>
  );
});
