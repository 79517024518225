import { call, takeEvery, put, select } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';
import { GenericParserHttp } from '../../../libs/Http';
import { getToken } from '../../../selectors/loginSelectors';

const api = (expressionOfInterest) =>
  GenericParserHttp.post('consumer/lab-research/expression-of-interest', {
    data: expressionOfInterest,
  }).then(({ body }) => body);

function* sendExpressionOfInterest({ payload: expressionOfInterest  }) {
  try {
    yield call(api, expressionOfInterest);
  } catch (err) {
    //do something with error, if needed
  }
}

function* submitExpressionOfInterestSaga() {
  yield takeEvery(actionTypes.sendExpressionOfInterest, sendExpressionOfInterest);
}

export { submitExpressionOfInterestSaga };
