import React, { useEffect, useState } from 'react';
import UIContainer from '../components/UIContainer/index';
import * as Styled from './PaymentsPage.styled';
import { PaymentSection } from './components/PaymentSection';
import { AddNewCreditCard } from './components/AddNew';
import { observer } from 'mobx-react-lite';
import { usePaymentsStore } from '../store/mobx/mobx';
import { PaymentsItem } from './components/PaymentsItem';
import { PaymentSectionAction } from './components/PaymentSectionAction';
import { go } from '../routes/sub/payments';
import { ICard } from '../store/mobx/paymentStore/paymentStore.type';
import PrivacyBanner from 'PaymentsPage/DesktopPaymentsPage/components/PrivacyBanner'
import PrivacyModal from './DesktopPaymentsPage/components/PrivacyModal';

const PaymentsPage: React.FC = observer(() => {
  const store = usePaymentsStore();
  const [showPrivacyBanner, setShowPrivacyBanner] = useState(true)
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  useEffect(() => {
    store.getPaymentSummary();
  }, [store]);

  const handleClosePrivacyModal = () => {
    setShowPrivacyBanner(false)
    store.acceptedPrivacyStatement = true;
  };

  return (
    <div>
      {showPrivacyBanner && !store.acceptedPrivacyStatement && 
        <PrivacyBanner
          termsOnClick={() => setShowPrivacyModal(true)} 
          closeOnClick={() => handleClosePrivacyModal()} 
        />
      }
        <UIContainer heading="Payments" isFetching={store.paymentSummaryLoadingStatus === 'LOADING'}>
          <Styled.PaymentsPageContainer>
            {showPrivacyModal && (<PrivacyModal onClose={() => setShowPrivacyModal(false)} />)}
            <PaymentSection
              title="Payment Method"
              elementWhenEmpty={<AddNewCreditCard />}
              items={store.cards}
              renderItem={(data: ICard) => (
                <PaymentsItem
                  key={data.Token}
                  IsDefault={data.IsDefault}
                  Token={data.Token}
                  Brand={data.Brand}
                  Last4={data.Last4}
                  IsExpired={data.IsExpired}
                  ExpMonth={data.ExpMonth}
                  ExpYear={data.ExpYear}
                  DoNotSaveCard={false}
                />
              )}
            />            
            {/*TODO: Hiding for now until design has been completed */}
            {/* <PaymentSection
              title="Transaction History"
              elementWhenEmpty={
                <PaymentSectionAction
                  labelElement="Past transactions"
                  onClick={() => {
                    go.transactionHistory();
                  }}
                />
              }
            /> */}
          </Styled.PaymentsPageContainer>
        </UIContainer>
    </div>
  );
});

export default PaymentsPage;
