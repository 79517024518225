import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 2rem;
`
export const Header = styled.div`
    font-size: 14px;
    color: #585858;
    margin: 0.7rem 0;
    font-weight: bold;
    margin-left: 1rem;
`
export const Card = styled.div`
    background-color: white;
    width: 497px;
    min-height: 170px;
    box-shadow: 0 3px 6px #00000029;
    padding: 0.7rem 0rem;
    display: flex;
    flex-direction: row;
    margin: 1rem 1rem;
`
export const Title = styled.div`
    color: #6C6C6C;
    font-size: 13px;
    border-bottom: 1px solid #DADADA;
    padding-bottom: 0.7rem;
    width: 90%;
    margin-bottom: 0.5rem;
`
export const Section = styled.div`
    width: 100%;
    margin: 0 1rem;
`

export const NoContent = styled.div`
    font-size: 14px;
    width: 100%;
    text-align: center;
    display: inline-block;
    margin: 2rem 0;
    color: #CDCDCD;
    font-style: italic;
`