import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { go } from '../../../../routes';
import { actions as headerActions } from '../../../../components/UIContainer/actions/headerActions';
import { actions } from '../../../actions/actions';
import CallPractice from '../../../../components/CallPractice';
import scriptError from '../../../../svgs/pharmacy-error.svg';
import DrugError from '../DrugError';
import UIContainer from '../../../../components/UIContainer';
import { isDesktop } from '../../../../config';
import styles from './errorWithReason.module.css';
import { useComponentDidMount } from 'libs/newHttp';

const Resolution =
{
  ContactPractice: 0,
  ChangePractitioner: 1,
  ChangeTime: 2,
  BookAppointment: 3,
  None: 4,
  Wait: 5,
  ChangePaymentMethod: 6,
  UpdateDetails: 7,
  CanShowMedications: 8,
  VerifyIdentity: 9
}

const ErrorWithReason = ({ overrideBackArrow, clearAbleToRequest, _error, requestError, practice, doctors,
  token, userId, patient, setInfo, getMedications, checkAbleToRequest, desktopAction }) => {
  const [error, setError] = useState(requestError || _error)
  const [text, setText] = useState('')
  const genericError = { Reason: 'Something wrong happened. Please try again later.', Resolution: 0 }

  useComponentDidMount(() => {
    let reason;
    if (!error || !error.Reason) {
      reason = genericError.Reason
      setError(genericError)
    } else {
      reason = error.Reason
    }
    if (reason && reason.includes('<')) {
      setText(<DrugError string={text} />)
    } else {
      setText(formatString(reason))
    }
    overrideBackArrow(() => { clearAbleToRequest(); go.home(0); })
  });

  const formatString = string => {
    const text = string.split(/\r\n|\r|\n/);
    return text.map(e => <p key={Math.random()}>{e}</p>);
  };

  const BookAppointmentButton = () =>
  (<button
    onClick={() => go.practiceBooking(practice.UrlName)}
    className={styles.button}
  >
    Book an Appointment
  </button>)

  const CallPracticeButton = () => (<CallPractice practice={practice} />)

  const GetPractitioner = () => {
    return doctors.find(d => d.Id === error.Practitioner.Id)
  }

  const patientIsVerified = (patient) => {
    if (!patient) return false;
    if (patient.IdentityStatus && patient.IdentityStatus.StatusLabel) {
        return patient.IdentityStatus.StatusLabel.toLowerCase() === 'verified';
    }
    return patient.IdentityVerified;
  }

  const checkCanRequestMeds = (pmsUserId) => {
    if (userId !== patient.Id) {
      checkAbleToRequest(practice.Id, pmsUserId, token, patient.Id);
    } else checkAbleToRequest(practice.Id, pmsUserId, token);
  }

  const CheckAndContinueWithPractitioner = (practitioner) => {
    setInfo('doctor', practitioner);
    checkCanRequestMeds(practitioner.Id);
    if (isDesktop()) {
      desktopAction();
      return;
    }
    if (!patientIsVerified(patient)) {
      go.medicationSearch();
    } else {
      getMedications(practice.Id, patient.Id, token);
      go.addMedication();
    }
  }

  const ChangePractitionerButton = () => error.Practitioner &&
  (<button
    onClick={() => CheckAndContinueWithPractitioner(GetPractitioner())}
    className={styles.button}
  >
    Continue with {GetPractitioner()?.Name}
  </button>)

  const content = (
    <div>
      <div className={styles.container}>
        {isDesktop() && <div className={styles.heading}>Whoops!</div>}
        <div className={styles.iconContainer}>
          <img
            alt="prescription error"
            src={scriptError}
            className={styles.icon}
          />
        </div>
        <div className={styles.message}>{text}</div>
        {(() => {
          switch (error.Resolution) {
            case Resolution.ContactPractice: return CallPracticeButton();
            case Resolution.BookAppointment: return BookAppointmentButton();
            case Resolution.ChangePractitioner: return ChangePractitionerButton();
            default: return;
          }
        })()}
      </div>
    </div>
  );

  if (isDesktop()) return content;
  return <UIContainer heading="Whoops!" goBackFn={() => go.selectPatient()}>{content}</UIContainer>;
}

const mapStateToProps = state => ({
  token: state.loginReducer.token,
  userId: state.profileReducer.profile.Id,
  patient: state.scriptDetailsReducer.patient,
  practice: state.scriptDetailsReducer.practice,
  doctors: state.scriptMenuReducer.doctors,
  requestError:
    state.ableToRequestReducer.error || state.submitScriptRequestReducer.error,
});
const mapDispatchToProps = dispatch => ({
  overrideBackArrow: bindActionCreators(
    headerActions.overrideBackArrow,
    dispatch,
  ),
  clearAbleToRequest: bindActionCreators(actions.clearAbleToRequest, dispatch),
  setInfo: bindActionCreators(actions.setInfo, dispatch),
  getMedications: bindActionCreators(actions.getMedicationList, dispatch),
  checkAbleToRequest: bindActionCreators(actions.ableToRequest, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorWithReason);
export { styles };
