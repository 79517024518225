import { actionTypes } from '../actions/actions';

const INITIAL_STATE = {
  cardDetails: {},
  isFetching: false,
};

const cardDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.fetchCardDetails: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case actionTypes.fetchCardDetailsSuccess: {
      return {
        ...state,
        isFetching: false,
        cardDetails: action.payload.cardDetails,
      };
    }
    case actionTypes.fetchCardDetailsFailure: {
      return {
        ...state,
        isFetching: false,
        cardDetailsError: action.payload.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default cardDetailsReducer;
