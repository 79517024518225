import React, { useState, useRef } from 'react';
import Webcam from 'react-webcam';
import { connect } from 'react-redux';

import styles from './photoSecurity.module.css';
import UIContainer  from '../../components/UIContainer';
import ProgressBar from '../components/ProgressCard';
import { go } from '../../routes';
import { actions } from '../actions/actions';
import { goBack } from '../../routes/utils';
import Avatar from '../../components/Avatar';
//import { isIOS } from '../../libs/BrowserDetection';

import lock from '../../svgs/identityLock.svg';
import progressBar from '../../svgs/progressBarLvl2.svg';
import { bindActionCreators } from 'redux';
import { Observer } from 'mobx-react-lite';
import { useIdentityStore } from 'store/mobx/mobx';
import Loading from 'components/loadingSpinner';
import privacyIcon from 'svgs/privacy2.svg'
import { useWindowDimensions } from 'libs/hooks';
import { useComponentDidMount } from 'libs/newHttp';
//import { ReactComponent as arrow } from '../../svgs/rightlinearrow.svg';

const PhotoSecurity = () => {
    const [cameraVisible, setCameraVisible] = useState(false); 
    const [imgStr, setImgStr] = useState("");
    const [imageTaken, setImageTaken] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [cardHeight, setCardHeight] = useState(0);
    const webcamRef = useRef();
    const progressRef = useRef();
    const store = useIdentityStore();
    const [heightN, setHeightN] = useState(window.innerHeight);
    const { height, width } = useWindowDimensions();

    useComponentDidMount(() => {
        setCardHeight(progressRef.current.offsetHeight)
		window.addEventListener('resize', updateDimensions);

        window.scrollTo({top: 0, behavior: 'smooth'});
    })


    const onTakePhoto = () => {
        const imageSrc = webcamRef.current.getScreenshot();

        if (imageSrc === null) { return; }

        setImgStr(imageSrc);
        setCameraVisible(false);
        setImageTaken(true);
    }

    const goBack = () => {
        const camera = cameraVisible;
        const imgTaken = imgTaken;

        if (!camera) {
            if (!imgTaken) {
                go.identity();
            } else {
                setCameraVisible(false);
                setImageTaken(false);
            }
        } else {
            setCameraVisible(false);
            setImageTaken(false);
        }
    }

    const onChangeHandler = (e) => {
        let selectedFile = e.currentTarget.files;
        let file = null;
        let fileName = "";
        
        //Check File is not Empty
        if (selectedFile.length > 0) {
            let fileToLoad = selectedFile[0];
            fileName = fileToLoad.name;

            let fileReader = new FileReader();

            fileReader.onload = function(fileLoadedEvent) {
                file = fileLoadedEvent.target.result;
                setImgStr(file);
                store.addPhoto(file);
            };

            fileReader.readAsDataURL(fileToLoad);
            setImageTaken(true);
        }
        window.scrollTo({top: 0, behavior: 'smooth'});
    }
    const updateDimensions = () => {
		setHeightN(window.innerHeight - 70)
	};

    return (
        <Observer>
            {() => {
                return (
                    <UIContainer heading={"Step 2: Photo Security"} isFetching={isFetching} goBackFn={() => imageTaken ? setImageTaken(false) : goBack()}>
                        <input 
                            style={{display: "none"}}
                            type="file" 
                            accept="image/*" 
                            capture="user" 
                            onChange={(e) => onChangeHandler(e)}
                            ref={webcamRef}
                        />
                        {!cameraVisible ? (
                            <div className={styles.container}>   
                                    {!imageTaken ? (
                                        <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
                                            <div ref={progressRef}>
                                                <ProgressBar 
                                                    text={"Would you like to take a selfie for extra added security and speed?"}
                                                    icon={privacyIcon}
                                                    progressBar={progressBar}
                                                    padding={true}
                                                /> 
                                            </div>
                                        <div className={styles.card}>
                                            <p className={styles.para}>This will be used by the practice team for faster handling of your verification request from the practice.</p>
                                            <div style={{margin: "3rem 0rem"}}>
                                                <div className={styles.button} onClick={() => {
                                                    //setCameraVisible(true);
                                                    webcamRef.current.click();
                                                }}>
                                                    Take a selfie picture
                                                </div>
                                                <div className={styles.buttonWhite} onClick={() => go.verifyPrivacy(0)}>Skip this step</div>
                                            </div>
                                        </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <ProgressBar 
                                                icon={privacyIcon}
                                                progressBar={progressBar}
                                                padding={false}
                                                title={"Your Photo"}
                                            />
                                            <div className={styles.card2}>
                                                <div>
                                                    <Loading isFetching={store.loading} />
                                                    
                                                    <div className={styles.checkText}>Your picture will be shared with your health provider.</div>
                                                    
                                                    <div className={styles.takenPhoto} style={{backgroundImage: `url(${imgStr})`, backgroundSize: "cover"}}/>
                                                    <div style={{display: "flex", alignItems: "center", marginBottom: "2rem"}}>
                                                        <input type="checkbox" className={styles.checkbox} checked={store.makeProfilePhoto} onChange={() => {
                                                            store.toggleProfilePhoto()
                                                        }} /><div className={styles.checkText}>Make this picture my Vensa profile photo and share with health providers</div><br />
                                                    </div>
                                                    <div className={styles.button} onClick={() => {
                                                        store.uploadPhoto(() => go.verifyPrivacy(0))
                                                    }}> My selfie is clear →</div>
                                                    
                                                    <div className={styles.buttonWhite} style={{marginTop: "1rem"}} onClick={() => {
                                                        webcamRef.current.click();
                                                        setImageTaken(false);
                                                    }}>
                                                        Take a new selfie
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    

                            </div>
                        ) : (
                        <div style={{display: "flex", flexDirection: "column", flexGrow: "100"}}> 
                                <div style={{ display: "flex", flexDirection: "column", flexGrow: "100", overflow: "scroll" }}>
                                    {<div className={styles.cover}>
                                        <input type="file" accept="image/*" capture="user" />
                                    </div>}
                                    Hi
                                    <div className={styles.buttonContainer}>
                                        <div className={styles.placeholder2} /> 
                                        <div className={styles.placeholder} />
                                        <div className={styles.hole} /> 
                                        <div className={styles.placeholder7} />
                                        <p className={styles.cameraText}>Place your face in the oval</p>   
                                        <div className={styles.placeholder} /> 
                                        <div style={{display: "flex"}}>
                                            <div className={styles.placeholder} /> 
                                            <div onClick={onTakePhoto} className={styles.cameraButton} /> 
                                            <div className={styles.placeholder} /> 
                                        </div>
                                        <div className={styles.placeholder} /> 
                                    </div> 
                                </div>  
                            </div>
                        )}
                </UIContainer>
                )}}
        </Observer>
    )
    
}

const mapStateToProps = state => ({
    isPrivacySetup: state.verifyReducer.isPrivacySetup,
})

const mapDispatchToProps = dispatch => ({
    setPhoto: bindActionCreators(actions.setPhoto, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(PhotoSecurity);