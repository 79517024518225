import { request, HTTP } from '../../../libs/newHttp';
import { IPrivacySettingsUpdate } from './accountStore.type';

export const getProfileAPI = () => 
    request({
        method: HTTP.GET,
        url: `consumer/profile`,
    });
    
export const getIdentityRequestsStatusAPI = () => 
    request({
        method: HTTP.GET,
        url: `consumer/identity/identity-requests-status`,
    });

export const sendVerifyTextAPI = (mobile: string, update: boolean) => () => 
    request({
        method: update ? HTTP.POST : HTTP.GET,
        url: update ? `consumer/verify/mobile` : `consumer/verify`,
        data: { Mobile: mobile }
    })

export const checkVerifyTextAPI = (code: string) => () => 
    request({
        method: HTTP.POST,
        url: `consumer/verify`,
        data: { Code: code }
    })

export const sendVerifyRecoveryEmailAPI = (email: string) => () =>
    request({
        method: HTTP.GET,
        url: `consumer/security/recovery-email/verify?email=${email}`,
    })

export const checkVerifyRecoveryEmailAPI = (email: string, code: string) => () => 
    request({
        method: HTTP.POST,
        url: `consumer/security/recovery-email/verify`,
        data: {
            Code: {
                Code: code
            },
            Email: email
        }
    })

export const sendVerifyEmailAPI = (email: string, update: boolean) => () =>
    request({
        method: HTTP.GET,
        url: update ? `consumer/security/change-email/verify?email=${email}` : `consumer/security/email/verify?email=${email}`,
    })

export const checkVerifyEmailAPI = (email: string, code: string, update: boolean) => () => 
    request({
        method: HTTP.POST,
        url: update ? `consumer/security/change-email/verify` : `consumer/security/email/verify`,
        data: update ? {
            Code: {
                Code: code
            },
            Email: email
        } : {
            Code: code
        }
    })    

export const getUserPrivacySettingsWithOrgAPI = (orgId: string) => () =>
    request({
        method: HTTP.GET,
        url: `consumer/privacy/privacy-settings/${orgId}`
    })
export const updateUserPrivacySettingsWithOrgAPI = (settings: IPrivacySettingsUpdate) => () =>
    request({
        method: HTTP.POST,
        url: `consumer/privacy/privacy-settings`,
        data: settings,
    })


export const getPinCodeEnabledAPI = () => 
    request({
        method: HTTP.GET,
        url: `consumer/account/pin-code-enabled`
    })
export const getPinCodeLengthAPI = () =>
    request({
        method: HTTP.GET,
        url: `consumer/account/pin-code-length`,
    })
export const verifyPinCodeAPI = (pinCode: string) => () =>
    request({
        method: HTTP.POST,
        url: `consumer/account/pin-code/login`,
        data:  pinCode ,
    })

export const sendAcknowledgementAPI = (value: number) => () =>
    request({
        method: HTTP.POST,
        url: `consumer/identity/acknowledgement`,
        data: value
    })

export const uploadPicAPI = (pic: File) => () =>
    request({
        method: HTTP.POST,
        url: `consumer/avatar`,
        file: pic
    })

export const logoutAPI = () => 
    request({
        method: HTTP.POST,
        url: `consumer/logout`,
    })

export const getSignUpInvitationDetailsAPI = (token: string) => () => 
    request({
        method: HTTP.GET,
        url: `consumer/patient-detail?token=${token}`,
    })
export const registerWithSignUpTokenAPI = (data: any) => () => 
    request({
        method: HTTP.POST,
        url: `consumer/register-with-invitation-token`,
        data: data
    })

export const editProfileAPI = (data: any) => () =>
    request({
        method: HTTP.POST,
        url: `consumer/profile`,
        data: data
    })

export const removeRecoveryEmailAPI = () => 
    request({
        method: HTTP.DELETE,
        url: `consumer/security/recovery-email`
    })

// Reset Pin APIs
export const getRecoveryMethodsAPI = () => 
    request({
        method: HTTP.GET,
        url: `consumer/account/reset-pin/recovery-methods`
    })
export const getVerificationCodeSentToRecoveryMethodAPI = (isMobile: boolean, target: string) => () =>
    request({
        method: HTTP.GET,
        url: `consumer/account/reset-pin/verification-code?isMobile=${isMobile}&target=${target}`,
    })
export const postVerificationCodeSentToRecoveryMethodAPI = (code: string, isMobile: boolean, target: string) => () =>
    request({
        method: HTTP.POST,
        url: `consumer/account/reset-pin/verification-code`,
        data: {
            Code: {
                Code: code
            },
            IsMobile: isMobile,
            Target: target
        }
    })

// Recover Suspended Account APIs
export const getRecoveryEmailSentToSuspendedAccountAPI = (email: string) => () =>
    request({
        method: HTTP.GET,
        url: `consumer/account/recover/email?email=${email}`
    })
export const getRecoverSuspendedAccountVerificationCodeAPI = (token: string, isMobile: boolean, target: string) => () =>
    request({
        method: HTTP.GET,
        url: `consumer/account/recover/verification-code?token=${token}&isMobile=${isMobile}&target=${target}`
    })
export const getRecoveryMethodsForSuspendedAccountAPI = (token: string) => () =>
    request({
        method: HTTP.GET,
        url: `consumer/account/recovery-methods?token=${token}`
    })
export const postRecoverAccountCheckActivationCodeAPI = (Token: string, Code: string, IsMobile: boolean, Target: string) => () =>
    request({
        method: HTTP.POST,
        url: `consumer/account/recover/verification-code`,
        data: {
            Code: { Code },
            Token,
            IsMobile,
            Target,
        }
    })
export const postRecoverAccountChangePasswordAPI = (Token: string, NewPassword: string) => () => 
    request({
        method: HTTP.POST,
        url: `consumer/account/recover/password`,
        data: {
            Token,
            NewPassword,
        }
    })
export const postRecoverAccountChangePinAPI = (Token: string, NewPinCode: string) => () => 
    request({
        method: HTTP.POST,
        url: `consumer/account/recover/pin-code`,
        data: {
            Token,
            NewPinCode
        }
    })
export const postRecoverAccountAPI = (token: string) => () => 
    request({
        method: HTTP.POST,
        url: `consumer/account/recover`,
        data: { token } 
    })