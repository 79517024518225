const actionTypes = {
    submitPin: "SUBMIT_PIN",
    submitPinSuccess: "SUBMIT_PIN_SUCCESS",
    submitPinFailure: "SUBMIT_PIN_FAILURE",
    submitVerifyPin: "SUBMIT_VERIFY_PIN",
    submitVerifyPinSuccess: "SUBMIT_VERIFY_PIN_SUCCESS",
    submitVerifyPinFailure: "SUBMIT_VERIFY_PIN_FAILURE",
}

const submitPin = (pin, token) => ({
    type: actionTypes.submitPin,
    payload: { pin, token },
})
const submitPinSuccess = () => ({
    type: actionTypes.submitPinSuccess,
})
const submitPinFailure = (error) => ({
    type: actionTypes.submitPinFailure,
    payload: { error }
})
const submitVerifyPin = (pin, token) => ({
    type: actionTypes.submitVerifyPin,
    payload: { pin, token },
})
const submitVerifyPinSuccess = () => ({
    type: actionTypes.submitVerifyPinSuccess,
})
const submitVerifyPinFailure = (error) => ({
    type: actionTypes.submitVerifyPinFailure,
    payload: { error }
})

const actions = {
    submitPin,
    submitPinSuccess,
    submitPinFailure,
    submitVerifyPin,
    submitVerifyPinSuccess,
    submitVerifyPinFailure,
}

export { actionTypes, actions };