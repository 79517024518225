import React, { useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { useAccountStore, useIdentityStore } from 'store/mobx/mobx';
import { useState } from 'react';
import { useRef } from 'react';
import UIContainer from 'components/UIContainer';
import PinCode from 'Identity/components/PinCode';
import PrivacyControls from 'Identity/Privacy/PrivacyControls/PrivacyControls';
import privacy from 'svgs/privacyPopUpLogo.svg'; 
import { go } from 'routes';
import LearnMorePopUp from 'components/LearnMorePopUp/LearnMorePopUp';

const Container = styled.div`

`
const Card = styled.div`
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 2rem 0rem;
    box-shadow: 0px 3px 6px #00000029;
    position: relative;
`
const Image = styled.img`
    display: block;
    margin: auto;
    height: 77px;
    width: 65px;
`
const Margin = styled.div`
    margin: 5px;
`
const CompleteVerificationFlow = observer(() => {
    const store = useAccountStore();
    const idStore = useIdentityStore();

    const [state, setState] = useState(store.profile.SecurityPinCodeSetup ? 1 : 0);
    const [headerState, setHeaderState] = useState(0);
    const ref = useRef<HTMLDivElement>(null);
    const [popUp, setPopUp] = useState(false);

    // Scroll to top of next page
    useEffect(() => {
        if (ref.current) {
            ref.current.scrollTop = 0;
            return;
        }
    
        idStore.setPracticeOrgId(store.profile.LatestPracticeId);
        
        if (idStore.selectedPracticeOrgId === "" || 
            idStore.selectedPracticeOrgId === null ||
            idStore.selectedPracticeOrgId === undefined) { go.home(); }
    })

    const backFn = () => {
        if (store.pinCodeFlowState !== 4) {
            if (store.pinCodeFlowState !== 0) {
                store.setPinCodeFlowState(store.pinCodeFlowState - 1)
                return;
            }
            store.setPinCodeFlowState(4);
        }
        else go.home(); 
    }
    
    return (
        
            state === 0  ? (
                <UIContainer heading={"Step 1: Security PIN"} goBackFn={() => backFn()}>
                    <div style={{height: "100%", marginBottom: "1rem", overflow: "visible"}}>
                        {popUp &&
                            <LearnMorePopUp type={"SECURITY_FAQ"} hide={() => setPopUp(false)} />    
                        }
                        <PinCode skipPhoto={true} goNext={() => setState(1)} openFAQ={() => setPopUp(true)} blueIcon={true}/>
                    </div>
                </UIContainer>)
            
            : (
                <UIContainer heading={"Step 2: Your privacy controls"} goBackFn={() => { 
                    if (headerState === 1) { setHeaderState(0); return; }
                    if (store.profile.SecurityPinCodeSetup) { go.home(); return; }
                    setState(0);
                }}>
                    <div ref={ref} style={{height: "100%", marginBottom: "1rem"}}>
                        <Card>
                            <Image src={privacy} />
                            <PrivacyControls 
                                headerState={headerState}
                                setHeaderState={(num: number) => setHeaderState(num)}
                                goNext={() => {
                                    if (headerState === 1) { 
                                        idStore.sendPrivacySettings(() => {}, () => {}, idStore.selectedPracticeOrgId);
                                        if (store.profile.MobileVerified) {
                                            go.identityCompleteFlowProcessing();
                                            return;
                                        } else {
                                            go.accountVerification("false")
                                        }
                                    }
                                }}
                            />
                        </Card>
                    </div>
                </UIContainer>
            )
    )
})

export default CompleteVerificationFlow;