import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from 'components/vensaComponents';
import { useLocation } from 'react-router';
import { BaseWhiteNotification } from './BaseWhiteNotification';
import { actions } from 'components/UIContainer/MobileUI/MainShell/actions/actions';

import labs from 'images/labs.png';
import { go } from 'routes';
import { useDispatch } from 'react-redux';
import { useMyHealthStore } from 'store/mobx/mobx';

export const PDHViewResult: React.FC = observer(() => {
  const { pathname } = useLocation();
  const store = useMyHealthStore();
  const [clicked, setClicked] = useState(false);

  const { labResultsTab: { feedback }, getLabResult, labResultNotification, currentLabPage } = useMyHealthStore();
  const dispatch = useDispatch();

  if (!pathname.startsWith('/home')) return null;
  if (feedback.length === 0) return null; 

  useEffect(() => {
    if (currentLabPage !== undefined) {
      if (clicked) {
        go.goLabResult(currentLabPage.Id, labResultNotification.PatientId, labResultNotification.LabWhenReceived);
        setClicked(false);
      }
    } 
  })

  return (
    <BaseWhiteNotification
      paragraph={`Provide feedback on your recent lab results, it is part of the research that you are involved with in your practice at Hauora Medical`}
      svg={labs}
      height={"40px"}
    >
      <Button 
        css={{ width: '150px', display: "block", marginLeft: "auto", height: "200px", fontSize: "16px", borderRadius: "9px"}}
        onClick={() => {
            setClicked(true);
            dispatch((
                actions.setWhiteBoxNotification({
                  isOpen: false,
                })
              ))
            getLabResult(labResultNotification.PatientId, labResultNotification.LabWhenReceived, () => {
              if (currentLabPage === undefined) { return; }
              go.goLabResult(currentLabPage.Id, labResultNotification.PatientId, labResultNotification.LabWhenReceived);
            })
        }}    
    >
        View results
      </Button>
    </BaseWhiteNotification>
  );
});
