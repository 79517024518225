import React from 'react';
import styles from './CalendarButton.module.scss';
import { ReactComponent as CalendarIcon } from 'svgs/addCalendar.svg';
import { ReactComponent as Cross } from 'svgs/crossGrey.svg'
import { CalendarProps, ValueProps } from 'pages/mobile/domain/Appointment/types';

const CalendarButton: React.FC<ValueProps<CalendarProps>> = ({handleClick, flag}) => {
  return (
    <div className={styles.container} onClick={handleClick}>
        {flag ? <Cross /> : 
        (<>
            <CalendarIcon />
            <span>Add to my Calendar</span>
        </>)}
    </div>
  )
};

export default CalendarButton;