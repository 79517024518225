import { call, takeEvery, put } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';
import { GenericParserHttp } from '../../../../libs/Http';

const api = (details, token) =>
  GenericParserHttp.post('consumer/prescription/pharmacy', {
    data: details,
    token,
  }).then(({ body }) => body);

const apiOptions = (token, orgId) =>
  GenericParserHttp.get(`consumer/prescription/${orgId}/pharmacy-options`, {
    token,
  }).then(({ body }) => body);

function* addPharmacy({ payload: { details, token } }) {
  try {
    const newId = yield call(api, details, token);
    yield put(actions.addPharmacySuccess(newId.Id));
  } catch (err) {
    yield put(actions.addPharmacyFailure(err));
  }
}

function* getOptions({ payload: { token, orgId }}) {
  try {
    const options = yield call(apiOptions, token, orgId);
    yield put(actions.getOptionsSuccess(options));
  } catch (err) {
    yield put(actions.getOptionsFailure(err));
  }
}

function* addPharmacySaga() {
  yield takeEvery(actionTypes.addPharmacy, addPharmacy);
}
function* getOptionsSaga() {
  yield takeEvery(actionTypes.getOptions, getOptions);
}

export { addPharmacySaga, getOptionsSaga };
