import React from 'react'
import styled from 'styled-components';
import pic from 'images/care-circle-white.png';

const Container = styled.div`
    width: 100%;
    display: flex;
    background-color: #346672;
    min-height: 100px;
    margin-bottom: 1rem;
    padding: 0.3rem 1rem;
    align-items: center;
`
const Image = styled.img`
    width: 48px;
    height: 40px;
    margin: auto 4rem;
`
const TextContainer = styled.div`
    color: white;
    width: 50%;
    margin-left: 1rem;
`
const ButtonContainer = styled.div`
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
`
const Button = styled.div`
    background-color: #FFFFFF;
    border-radius: 4px;
    color: #346672;
    padding: 0.5rem 1rem;
    font-size: 13px;
    cursor: pointer;
`
const Link = styled.div`
    color: white;
    font-size: 14px;
    text-decoration-line: underline;
    margin-left: 4rem;
    cursor: pointer;
`
const Title = styled.div`
    font-size: 17px;
    margin-bottom: 0.5rem;
`
const Text = styled.div`
    font-size: 13px;
`

interface IProps {
    title: string;
    desc: string;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    buttonText: string;
    buttonOnClick: () => void;
    linkText: string;
    linkOnClick: () => void;
}

const Banner: React.FC<IProps> = ({ title, desc, icon, buttonText, buttonOnClick, linkText, linkOnClick }) => {
    return (
        <Container>
            <Image src={pic} />
            <TextContainer>
                <Title>My Care Circle</Title>
                <Text>
                    Take the stress out of looking after your loved ones. <br />
                    Book appointments, request repeat scripts, and heaps more. Simply add family, Whanau<br />
                    and friends to your Care Circle to get started!
                </Text>
            </TextContainer>
            <ButtonContainer>
                <Button onClick={buttonOnClick}>Learn More</Button>
                <Link onClick={() => linkOnClick()}>Ok got it</Link>
            </ButtonContainer>
        </Container>
    )
}

export default Banner;