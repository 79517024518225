import React from 'react';
import PropTypes from 'prop-types';

import styles from './verifyAccountCard.module.css';
import { go } from 'routes';

const VerifyAccountCard = ({ onClick, isMobileVerified, isEmailVerified }) => (
  <div className={styles.container}>
    <div className={styles.header}>Account verification</div>
    <div className={styles.text}>
      {!isMobileVerified && !isEmailVerified ?
      "To enable full access to Vensa services and receive health updates, please verify your email and mobile."
      :
      isMobileVerified ?
        "To enable full access to Vensa services and receive health updates, please verify your email."
        :
        "To enable full access to Vensa services and receive health updates, please verify your mobile number."
      }
    </div>
    <div className={styles.buttonContainer}>
      {!isMobileVerified && !isEmailVerified ?
      <div>
        <button onClick={() => go.emailVerification(0, true, false)} className={styles.button} style={{margin: "0rem 1rem"}}>
        Verify Email
        </button>      
        <button onClick={() => go.accountVerification("true")} className={styles.button}>
          Verify Mobile
        </button>
      </div>
      :
      <button onClick={onClick} className={styles.button}>
        Verify now
      </button>
      }
    </div>
  </div>
);

VerifyAccountCard.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default VerifyAccountCard;
