import React from 'react';
import styled from 'styled-components';

import PopUpVerifyEmail from './PopUpVerifyEmail';
import PopUpVerifyMobile from './PopUpVerifyMobile';
import { useAccountStore } from 'store/mobx/mobx';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`
const Overlay = styled.div<{show: boolean}>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: ${props => props.show ? 0.6 : 0};
    z-index: 1;
    transition: all 5s;
`

interface Props {
    isMobile: boolean;
    isPrimary: boolean;
    close: () => void;
    isCombo: boolean;
    combo: () => void;
}

const PopUpVerificationNavigator: React.FC<Props> = ({isMobile, isPrimary, close, isCombo, combo}) => {
    const profile = useAccountStore().profile;
    
    return (
        <Container>
            <Overlay show onClick={close} />
            {isCombo ? 
                !profile.MobileVerified && !profile.EmailVerified ? 
                    <PopUpVerifyMobile isCombo={isCombo} combo={combo} close={close}/> //COMBO
                : 
                    profile.EmailVerified ? 
                        <PopUpVerifyMobile isCombo={isCombo} combo={() => {}} close={close} /> //Mobile
                    :
                        <PopUpVerifyEmail isPrimary={true} close={close} /> //Email
            :   
                isMobile ? 
                    <PopUpVerifyMobile isCombo={isCombo} combo={() => {}} close={close} />
                : 
                    <PopUpVerifyEmail isPrimary={isPrimary} close={close} />
                
            }
        </Container>
    )
}

export default PopUpVerificationNavigator;