import { call, put, takeLatest } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';
import { NoErrorParserHttp } from '../../../libs/Http';

const api = (details, token) =>
  NoErrorParserHttp.post('consumer/prescription/retry', {
    data: details,
    token,
  }).then(({ body }) => body);

function* retryRequest({ payload: { details, token } }) {
  try {
    yield call(api, details, token);
    yield put(actions.retrySuccess());
  } catch (err) {
    if (err.response.status === 400) {
      yield put(actions.retryLimitReached());
    } else yield put(actions.retryFailure());
  }
}

function* retryRequestSaga() {
  yield takeLatest(actionTypes.retryRequest, retryRequest);
}

export { retryRequestSaga };
