import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';

import styles from './privacySettings.module.css';
import UIContainer from '../../components/UIContainer';
import PrivacyToggle from '../components/PrivacyToggle';
import { IPractice } from 'store/mobx/accountStore/accountStore.type';
import { go } from '../../routes';
import { useIdentityStore, useAccountStore, usePracticesStore } from 'store/mobx/mobx';
import { useComponentDidMount } from 'libs/newHttp';

const PrivacySettings = observer(() => {
    const accStore = useAccountStore();
    const practiceStore = usePracticesStore();

    const [practiceConditions, setPracticeConditions] = useState(3);
    const [practice, setPractice] = useState({})

    useComponentDidMount(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let orgId = params.get('id');

        practiceStore.getPracticeDetails(orgId, (practice) => {
            setPractice(practice);
        });
        accStore.getUserPrivacySettingsWithOrg(orgId, () => {});
    })
    
    const renderConditions = (conditions) => {
        const fontColor = conditions > 1 ? "#FF6A65" : "#6C6C6C";
        let text = "";
        let reason = "";
        switch(conditions) {
            case 1:
                text = "This practice does not currently have clinical notes available";
                break;
            case 2:
                text = "This facility has restrictions for you to access you’re health structured information. The practice has restricted and provided this reason for the restriction:";
                reason = "Reason";
                break;
            case 3:
                text = "This facility has restrictions for you to access you’re health doctors information. The practice has restricted and provided this reason for the restriction:";
                reason = "Will not release data to you due to your family situation";
                break;
            default:
                return;
        }

        return (
            <div>
                <div className={styles.title} style={{borderBottom: "none", color: fontColor, paddingBottom: "0px"}}>Practice Conditions</div>
                <div className={styles.subtext} style={{fontSize: "10pt", color: fontColor}}>
                    { text }
                    <br />
                    <br />
                    <div style={{fontStyle: "italic"}}>{ reason }</div>
                </div>
            </div>
        )
    }

    return (
        <UIContainer heading={practice.Name} isFetching={accStore.loading || practiceStore.loading} subheading={"Privacy Controls"} goBackFn={() => { go.privacy(); }}>
            <div className={styles.flexV}>
                {practice.Pictures && <img className={styles.pic} src={practice.Pictures[0]} alt="pic" />}
                <div className={styles.container}>
                    <div className={styles.placeholder} />
                    <div className={styles.title}>Privacy Controls</div>
                    <PrivacyToggle 
                        initial={accStore.privacySettingsWithOrg.AllowAccessMyHealthRecords.Allow} 
                        toggle={() => 
                            accStore.updateUserPrivacySettingsWithOrg(
                                practice.Id, 
                                () => accStore.getUserPrivacySettingsWithOrg(practice.Id, () => {}), 
                                accStore.privacySettingsWithOrg.AllowAccessAndStoreMedications.Allow,
                                accStore.privacySettingsWithOrg.AllowAccessMyDependantHealthRecords.Allow,
                                accStore.privacySettingsWithOrg.AllowAccessMyDoctorNotes.Allow,
                                !accStore.privacySettingsWithOrg.AllowAccessMyHealthRecords.Allow,
                                accStore.privacySettingsWithOrg.AllowShareMyHealthDataWithPractices,
                            )} 
                        text={"Vensa will access and store my health summary"} 
                        disable={accStore.privacySettingsWithOrg.AllowAccessMyHealthRecords.Overridden}
                    />
                    <PrivacyToggle 
                        initial={accStore.privacySettingsWithOrg.AllowAccessMyDoctorNotes.Allow} 
                        toggle={() => 
                            accStore.updateUserPrivacySettingsWithOrg(
                                practice.Id, 
                                () => accStore.getUserPrivacySettingsWithOrg(practice.Id, () => {}), 
                                accStore.privacySettingsWithOrg.AllowAccessAndStoreMedications.Allow,
                                accStore.privacySettingsWithOrg.AllowAccessMyDependantHealthRecords.Allow,
                                !accStore.privacySettingsWithOrg.AllowAccessMyDoctorNotes.Allow,
                                accStore.privacySettingsWithOrg.AllowAccessMyHealthRecords.Allow,
                                accStore.privacySettingsWithOrg.AllowShareMyHealthDataWithPractices,
                            )} 
                        text={"Vensa will access and store my consultation and history notes"} 
                        disable={accStore.privacySettingsWithOrg.AllowAccessMyDoctorNotes.Overridden}
                    />
                    <PrivacyToggle 
                        initial={accStore.privacySettingsWithOrg.AllowAccessAndStoreMedications.Allow} 
                        toggle={() => 
                            accStore.updateUserPrivacySettingsWithOrg(
                                practice.Id, 
                                () => accStore.getUserPrivacySettingsWithOrg(practice.Id, () => {}), 
                                !accStore.privacySettingsWithOrg.AllowAccessAndStoreMedications.Allow,
                                accStore.privacySettingsWithOrg.AllowAccessMyDependantHealthRecords.Allow,
                                accStore.privacySettingsWithOrg.AllowAccessMyDoctorNotes.Allow,
                                accStore.privacySettingsWithOrg.AllowAccessMyHealthRecords.Allow,
                                accStore.privacySettingsWithOrg.AllowShareMyHealthDataWithPractices,
                            )} 
                        text={"Vensa will access and store my medications list and history"} 
                        disable={accStore.privacySettingsWithOrg.AllowAccessAndStoreMedications.Overridden}
                    />
                    <div className={styles.subtext} style={{margin: "5px 1rem"}}>Facility that has granted you access to your health records and for Vensa will store your records on your behalf</div>
                    <br />
                    {/* {(accStore.privacySettingsWithOrg.AllowAccessMyDoctorNotes.Overridden ||
                    accStore.privacySettingsWithOrg.AllowAccessMyDependantHealthRecords.Overridden ||
                    accStore.privacySettingsWithOrg.AllowAccessMyHealthRecords.Overridden ||
                    accStore.privacySettingsWithOrg.AllowAccessAndStoreMedications.Overridden) &&                    
                        <div>
                            <div className={styles.title} style={{borderBottom: "none", color: "#6C6C6C", paddingBottom: "0px"}}>Practice Conditions</div>
                            <div className={styles.subtext} style={{fontSize: "10pt", color: "#6C6C6C", margin: "1rem 1rem"}}>
                                {accStore.privacySettingsWithOrg.AllowAccessMyDoctorNotes.Overridden ?
                                accStore.privacySettingsWithOrg.AllowAccessMyDoctorNotes.Reason :
                                accStore.privacySettingsWithOrg.AllowAccessMyHealthRecords.Overridden  ?
                                accStore.privacySettingsWithOrg.AllowAccessMyHealthRecords.Reason :
                                accStore.privacySettingsWithOrg.AllowAccessMyDependantHealthRecords.Overridden ?
                                accStore.privacySettingsWithOrg.AllowAccessMyDependantHealthRecords.Reason : 
                                accStore.privacySettingsWithOrg.AllowAccessAndStoreMedications.Overridden ?
                                accStore.privacySettingsWithOrg.AllowAccessAndStoreMedications.Reason : ""
                                }
                            </div>
                        </div>                    
                    } */}
                </div>
            </div>
        </UIContainer>
    )
    
})

export default PrivacySettings;