import React from 'react';
import { Observer } from 'mobx-react-lite';
import styles from './privacyToggle.module.css';
import { isDesktop } from 'config.js'

class PrivacyToggle extends React.Component {
    constructor(props) {
        super(props)
        
        this.state = {
            switched: this.props.initial ? this.props.initial : false
        }
    }

    componentWillReceiveProps(prevProps) {
        if (this.props.initial !== prevProps.initial) {
            this.setState({switched: prevProps.initial})
        }
    }


    toggleSwitch = () => {
        this.props.toggle();
        /*this.setState(prevState => {
            return {
                switched: !prevState.switched
            };
        });*/
    };

    render() {
        return(
            <Observer>
            {() => 
                <div className={styles.container} style={{borderTop: "none"}}>
                    <div style={{fontSize: isDesktop() ? this.props.small ? "13px" : "15px": "", color: this.props.disable ? this.state.switched ? "#07A3C8" : '#DADADA' : '#07A3C8'}} className={styles.text}>{this.props.text}</div>
                    <div className={styles.toggleContainer} style={{backgroundColor: this.props.disable ? "#DADADA" : this.state.switched ? "#23C373" : "#FF6A65"}} onClick={this.props.disable ? () => {} : this.toggleSwitch}>
                        <div className={styles.toggle} style={{transform: this.state.switched ? "translateX(22px)" : ""}} />
                    </div>
                </div>
            }
            </Observer>

        )
    }
}

export default PrivacyToggle;