import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { IImmunisation } from '../../../../store/mobx/myHealthStore/myHealthStore.type';
import BottleSVG from '../../../../svgs/needle.svg';
import { BaseListItem } from '../../../../MyHealthPage/components/BaseListItem';
import { getPHRDate } from '../../../../libs/newHttp';
import { go } from '../../../../routes';

export const ImmunisationItem: React.FC<IImmunisation> = observer(
  ({
    Id,
    IsNewInPast3Month,
    GivenName,
    FamilyName,
    OrganisationName,
    NameOfImmunisation,
    DateTime,
  }) => {
    const onClick = useCallback(() => {
      go.goImmunisation(Id);
    }, [Id]);

    return (
      <BaseListItem
        isNew={IsNewInPast3Month}
        textWhenNew="Given within last 3 months"
        icon={BottleSVG}
        leftFirstLine={NameOfImmunisation}
        leftSecondLine={OrganisationName}
        leftThirdLine={""}
        rightFirstLine={getPHRDate(DateTime)}
        onClick={onClick}
      />
    );
  },
);
