import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from '../actions/policyActions';
import styles from './privacyPolicy.module.css';

class PrivacyPolicy extends Component {
  componentWillUnmount() {
    if (!this.props.hasAccepted) this.props.acceptPP(this.props.token);
  }
  render() {
    const { url, hasAccepted } = this.props;
    return !hasAccepted ? (
      <div className={styles.pp}>
        We&#39;ve updated our{' '}
        <a rel="noopener noreferrer" target="_blank" href={url}>
          Privacy Policy
        </a>{' '}
        to accomodate the exciting new features we have just launched.{' '}
        <a rel="noopener noreferrer" target="_blank" href="https://vensa.com">
          Click here
        </a>{' '}
        to see our new releases.
      </div>
    ) : null;
  }
}

export default connect(
  state => ({
    token: state.loginReducer.token,
    url: state.profileReducer.profile.PrivacyPolicyUrl,
    hasAccepted: state.profileReducer.profile.HasAcceptedPP,
  }),
  dispatch => ({
    acceptPP: bindActionCreators(actions.acceptPrivacyPolicy, dispatch),
  }),
)(PrivacyPolicy);
