import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import UIContainer from 'components/UIContainer';
import { useParams } from 'react-router';
import { Redirect } from 'react-router-dom';
import {
  BaseDetailBox,
  BaseDetailSectionHeader,
  BaseDetailSectionBottom,
} from 'MyHealthPage/components/BaseDetailSection';
import { useAccountStore, useMyHealthStore } from 'store/mobx/mobx';
import { AddComment } from 'MyHealthPage/components/AddComment';
import {
  ISummaryItem,
  CommentType,
} from 'store/mobx/myHealthStore/myHealthStore.type';
import moment from 'moment';
import rtfToHtml from '@iarna/rtf-to-html';
import { go } from 'routes';

const SummarySection: React.FC<ISummaryItem> = observer(
  ({ ClinicalFinding, Notes }) => {
    if (!ClinicalFinding || !Notes) return null;
    return <BaseDetailSectionBottom bottomTitle={ClinicalFinding} bottomText={Notes} />;
  },
);

const ClinicNotePage: React.FC = observer(() => {
  const store = useAccountStore();
  const { id } = useParams<{ id: string }>();
  const phrStore = useMyHealthStore();
  const itemToShow = phrStore.clinicNotesTab.all.find(item => item.Id === id);

  if (!id || !itemToShow) {
    return <Redirect to={'/home'} />;
  }
  
  const getDate = (dateTime: string) => moment(dateTime).format('D/MM/YY');
  const [subjective, setSubjective] = useState("");
  const [objective, setObjective] = useState("");

  // Check if RTF format then convert to html, otherwise use the string given
  if (itemToShow.SubjectiveNotes.startsWith("{")) rtfToHtml.fromString(itemToShow.SubjectiveNotes, (err: any, html: any) => { setSubjective(html) })
  else if (subjective !== itemToShow.SubjectiveNotes) setSubjective(itemToShow.SubjectiveNotes);

  if (itemToShow.ObjectiveNotes.startsWith("{"))  rtfToHtml.fromString(itemToShow.ObjectiveNotes, (err: any, html: any) => { setObjective(html) })
  else if (objective !== itemToShow.ObjectiveNotes) setObjective(itemToShow.ObjectiveNotes);

  return (
    <UIContainer heading=" " isFetching={false}  phrText={"Documented on " + getDate(itemToShow.DateTime)} goBackFn={() => go.home(3)}>
      <div style={{marginBottom: "1rem"}}>
        <BaseDetailBox>
          <BaseDetailSectionHeader
            itemName="Clinical Notes"
            givenName={store.profile.FirstName}
            familyName={store.profile.LastName}
            organisationName={itemToShow.OrganisationName}
            providerName={itemToShow.ProviderName}
          />
          <div dangerouslySetInnerHTML={{__html: subjective}} />
          <div dangerouslySetInnerHTML={{__html: objective}} />
          {itemToShow.ClinicalNotesSummaries.map((item) => {
            return (
              <SummarySection {...item} />
            )
          })}
          {/*}
          <SummarySection {...itemToShow.Clinical} />
          <SummarySection {...itemToShow.Summary.EncounterSummary} />
    <SummarySection {...itemToShow.Summary.NotesSummary} />*/}
        </BaseDetailBox>
        <AddComment
          onSubmit={phrStore.postNewComment.bind(phrStore)}
          commentType={CommentType.DoctorNotes}
          itemToShow={itemToShow}
          itemId={id}
          label="+ Add comment for yourself"
        />
      </div>
    </UIContainer>
  );
});

export default ClinicNotePage;
