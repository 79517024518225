import { call, takeEvery, put } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';
import { GenericParserHttp } from '../../../libs/Http';

const cardDetailsApi = token =>
  GenericParserHttp.get('consumer/payment/card', { token }).then(
    ({ body }) => body,
  );

function* fetchCardDetails({ payload: { token } }) {
  try {
    const cardDetails = yield call(cardDetailsApi, token);
    yield put(actions.fetchCardDetailsSuccess(cardDetails));
  } catch (err) {
    yield put(actions.fetchCardDetailsFailure(err));
  }
}

function* fetchCardDetailsSaga() {
  yield takeEvery(actionTypes.fetchCardDetails, fetchCardDetails);
}

export { fetchCardDetailsSaga };
