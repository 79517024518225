import React from 'react';

const DEFAULT_GUID = '00000000-0000-0000-0000-000000000000';

export class HttpStatusCode {
    static readonly OK = 200;
    static readonly PARTIAL_CONTENT = 206;
    static readonly BAD_REQUEST = 400;
    static readonly NOTFOUND = 404;
    static readonly CONFLICT = 409;
    static readonly UNKNOWN = 520;
}

// 0 - Unverified
// 1 - Verified
// 2 - Pending
export class VerificationStatus {
    static readonly UNVERIFIED = 0;
    static readonly VERIFIED = 1;
    static readonly PENDING = 2;
}

export class PatientStatus {
    static readonly NONE = -1;                  // Creating a dependant
    static readonly VERIFIED = 2;               // -
}

export {
    DEFAULT_GUID,
};