import { actionTypes } from '../actions/pageActions';

const INITIAL_STATE = {
  onTimeSelectedFn: null,
  dateTimePickerIsShowing: false,
  smokingRecallId: '',
};

const PracticeBookingPageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.setOnTimeSelectedFn: {
      return {
        ...state,
        onTimeSelectedFn: action.payload.onTimeSelectedFn,
      };
    }
    case actionTypes.clearState: {
      return INITIAL_STATE;
    }
    case actionTypes.setDateTimePickerIsShowing: {
      return {
        ...state,
        dateTimePickerIsShowing: action.payload.dateTimePickerIsShowing,
      };
    }
    default: {
      return state;
    }
  }
};

export default PracticeBookingPageReducer;
