import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { ReactComponent as Spinner } from "../../../svgs/spinner.svg";
import {
  DoctorProfilePropType,
  PracticeInfoPropType,
} from "../../../PracticeProfilePage/BookingPageMobile/propTypes/index";
import { detailsPropType } from "../propTypes/index";
import styles from "./confirmAppointmentDesktop.module.css";

const ConfirmAppointmentDesktop = ({
  doctor,
  time,
  practice,
  patient,
  guardian,
  isFetching,
  onConfirmClick,
  onDoctorEditClick,
  onDetailsEditClick,
  onGuardianEditClick,
}) => (
  <div className={styles.container} id="nw_desktop_confirm"> 
      <img alt="" src={doctor.Picture} className={styles.image}/>
      <div className={styles.provider}>
        <div>{doctor.Name}</div>
        <div>{practice.Name}</div>
      </div>
      <div className={styles.appointmentDetails}>
        <div> {moment(time).format("dddd Do MMMM")} </div>
        <div> {moment(time).format("h:mm a")} </div>
      </div>
      <div className={styles.userContainer}>
        <div>
          Booking for {patient.FirstName} {patient.LastName}
        </div>
      </div>
      <button id="nw_confirm" className={styles.button} onClick={onConfirmClick}>
        {isFetching ? <Spinner className={styles.spinner} /> : "Confirm Appointment"}
      </button>
  </div>
);

ConfirmAppointmentDesktop.propTypes = {
  doctor: DoctorProfilePropType.isRequired,
  time: PropTypes.instanceOf(moment),
  practice: PracticeInfoPropType.isRequired,
  patient: detailsPropType.isRequired,
  guardian: detailsPropType,
  onConfirmClick: PropTypes.func.isRequired,
  onDoctorEditClick: PropTypes.func.isRequired,
  onDetailsEditClick: PropTypes.func.isRequired,
  onGuardianEditClick: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default ConfirmAppointmentDesktop;