import { observable, action, makeAutoObservable } from 'mobx';

export class SessionStore {
    constructor(){
        makeAutoObservable(this);
    }
    @observable inFlow: boolean = false;
    @observable leavingPage: boolean = false;
    @observable leavingFunc: (() => void | undefined) = () => { console.log("test") }

    @action setInFlow(val: boolean) {
        this.inFlow = val;
    }
    @action setLeavingPage(val: boolean) {
        this.leavingPage = val;
    }
    @action setLeavingFunc(func: () => void) {
        this.leavingFunc = func;
    }
}