import { combineReducers } from 'redux';

/* eslint-disable max-len */
import confirmCancellationReducer from '../components/Deleter/reducers/confirmCancellationReducer';
import addDependantReducer from '../DependantsPage/reducers/addDependantReducer';
import countdownReducer from '../vAppointment/MobileVerification/reducers/countdownReducer';
import appointmentsCTAReducer from '../AppointmentsPage/reducers/appointmentsCTAReducer';
import checkEnrollmentReducer from '../vAppointment/PatientSelection/reducers/checkEnrollmentReducer';
import doctorProfileReducer from '../PracticeProfilePage/DoctorProfilePage/reducers/doctorProfileReducer';
import deactivationReducer from '../UserAccountPage/reducers/deactivationReducer';
import deletionReducer from '../components/Deleter/reducers/deletionReducer';
import desktopPopUpReducer from '../components/DesktopPopUp/reducers/desktopPopUpReducer';
import feedReducer from '../HealthFeed/reducers/feedReducer';
import fetchDependantsReducer from '../DependantsPage/reducers/fetchDependantsReducer';
import fetchApptDetailsReducer from '../AppointmentsPage/reducers/fetchApptDetailsReducer';
import multiDayAppointmentsReducer from '../PracticeProfilePage/reducers/multiDayAppointmentsReducer';
import practiceAppointmentReducer from '../PracticeProfilePage/BookingPageMobile/reducers/practiceAppointmentReducer';
import practiceBookingPageReducer from '../PracticeProfilePage/BookingPageMobile/reducers/practiceBookingPageReducer';
import loginReducer from '../EntryPage/reducers/loginReducer';
import mainShellReducer from '../components/UIContainer/MobileUI/MainShell/reducers/mainShellReducer';
import profileReducer from '../components/UIContainer/MobileUI/MainShell/reducers/profileReducer';
import editProfileReducer from '../UserAccountPage/reducers/editProfileReducer';
import guardianDetailsReducer from '../vAppointment/PatientDetails/reducers/guardianDetailsReducer';
import patientDetailsReducer from '../vAppointment/PatientDetails/reducers/patientDetailsReducer';
import feelingPageReducer from '../vAppointment/Reason/reducers/feelingPageReducer';
import confirmCodeReducer from '../vAppointment/MobileVerification/reducers/confirmCodeReducer';
import sessionReducer from '../vAppointment/MobileVerification/reducers/sessionReducer';
import submitAppointmentReducer from '../vAppointment/Processing/reducers/submitAppointmentReducer';
import appointmentConfirmationReducer from '../vAppointment/Processing/reducers/appointmentConfirmationReducer';
import checkUsernameReducer from '../vAppointment/GuestRegister/reducers/checkUsernameReducer';
import registerUserCodeReducer from '../vAppointment/GuestRegister/reducers/registerUserCodeReducer';
import requestNewPasswordReducer from '../vAppointment/ForgotPassword/reducers/requestNewPasswordReducer';
import changePasswordReducer from '../UserAccountPage/reducers/changePasswordReducer';
import fetchConfirmedAppointmentsReducer from '../AppointmentsPage/reducers/fetchConfirmedAppointmentsReducer';
import fetchPastAppointmentsReducer from '../AppointmentsPage/reducers/fetchPastAppointmentsReducer';
import practiceListingReducer from '../PracticesPage/reducers/practiceListingReducer';
import practiceSelectionReducer from '../PracticesPage/PracticeSearch/reducers/practiceSearchReducer';
import pickUpOptionsReducer from '../vScript/PickUpLocation/reducers/pickUpOptionsReducer';
import sendMessageToSupportReducer from '../SupportPage/reducers/sendMessageToSupportReducer';
import submitCancellationReducer from '../components/Deleter/reducers/submitCancellationReducer';
import practiceLinkingReducer from '../PracticesPage/reducers/practiceLinkingReducer';
import practiceUnlinkingReducer from '../PracticesPage/reducers/practiceUnlinkingReducer';
import practiceProfileReducer from '../PracticeProfilePage/reducers/practiceProfileReducer';
import paymentFormReducer from '../vScript/Payment/reducers/paymentFormReducer';
import removeDependantReducer from '../DependantsPage/reducers/removeDependantReducer';
import appScrollReducer from '../App/reducers/appScrollReducer';
import appStylesReducer from '../App/reducers/appStyleReducer';
import autoRegisterReducer from '../vAppointment/GuestRegister/reducers/autoRegisterReducer';
import expiredSessionReducer from '../components/ExpiredSession/reducers/expiredSessionReducer';
import headerReducer from '../components/UIContainer/reducers/headerReducer';
import menuReducer from '../components/UIContainer/MobileUI/Menu/reducers/menuReducer';
import preLoadDependantReducer from '../DependantsPage/reducers/preLoadDependantReducer';
import scriptReducer from '../PrescriptionsPage/reducers/scriptReducer';
import scriptMenuReducer from '../vScript/reducers/scriptMenuReducer';
import requestScriptReducer from '../vScript/reducers/requestScriptReducer';
import searchMedsReducer from '../vScript/reducers/searchMedsReducer';
import ableToRequestReducer from '../vScript/reducers/ableToRequestReducer';
import submitScriptRequestReducer from '../vScript/ConfirmRequest/reducers/submitScriptRequestReducer';
import confirmationReducer from '../vScript/ConfirmRequest/reducers/scriptRequestConfirmationReducer';
import retryRequestReducer from '../vScript/ConfirmRequest/reducers/retryRequestReducer';
import addPharmacyReducer from '../vScript/PickUpLocation/AddPharmacy/reducers/addPharmacyReducer';
import cardDetailsReducer from '../vScript/Payment/reducers/cardDetailsReducer';
import verifyReducer from '../HealthFeed/reducers/verifyReducer';
import pinCodeReducer from '../Identity/SecurityPin/reducers/pinCodeReducer';
import identityReducer from '../Identity/reducers/identityReducer';
import getMedicationsReducer from '../vScript/reducers/getMedicationsReducer';
import expressionOfInterestReducer from 'vAppointment/ExpressionInterest/reducers/expressionOfInterestReducer';
import vAppointmentPaymentsReducer from 'vAppointment/PrepaidPage/reducer/vAppointmentPaymentsReducer';
/* eslint-enable max-len */

export default combineReducers({
  vAppointmentPaymentsReducer,
  getMedicationsReducer,
  ableToRequestReducer,
  addDependantReducer,
  addPharmacyReducer,
  appointmentConfirmationReducer,
  appointmentsCTAReducer,
  appScrollReducer,
  appStylesReducer,
  autoRegisterReducer,
  cardDetailsReducer,
  changePasswordReducer,
  checkEnrollmentReducer,
  checkUsernameReducer,
  confirmCancellationReducer,
  confirmCodeReducer,
  countdownReducer,
  deactivationReducer,
  deletionReducer,
  desktopPopUpReducer,
  doctorProfileReducer,
  editProfileReducer,
  expiredSessionReducer,
  feedReducer,
  feelingPageReducer,
  fetchApptDetailsReducer,
  fetchConfirmedAppointmentsReducer,
  fetchDependantsReducer,
  fetchPastAppointmentsReducer,
  guardianDetailsReducer,
  headerReducer,
  identityReducer,
  loginReducer,
  mainShellReducer,
  menuReducer,
  multiDayAppointmentsReducer,
  patientDetailsReducer,
  paymentFormReducer,
  pickUpOptionsReducer,
  practiceAppointmentReducer,
  practiceBookingPageReducer,
  practiceLinkingReducer,
  practiceUnlinkingReducer,
  practiceListingReducer,
  practiceProfileReducer,
  practiceSelectionReducer,
  preLoadDependantReducer,
  profileReducer,
  registerUserCodeReducer,
  removeDependantReducer,
  requestNewPasswordReducer,
  scriptDetailsReducer: requestScriptReducer,
  scriptMenuReducer,
  scriptReducer,
  submitScriptRequestReducer,
  confirmationReducer,
  retryRequestReducer,
  searchMedsReducer,
  sendMessageToSupportReducer,
  sessionReducer,
  submitAppointmentReducer,
  submitCancellationReducer,
  verifyReducer,
  pinCodeReducer,
  expressionOfInterestReducer
});
