import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './logo.module.css';
import { ReactComponent as LogoIcon } from '../../../../../../svgs/logo.svg';

const Logo = ({ onClick, blue, className, style }) => (
  <div style={style} className={classnames(styles.container, className)} onClick={onClick}>
    <LogoIcon className={classnames(styles.icon, { [styles.blue]: blue })} />
  </div>
);
Logo.propTypes = {
  onClick: PropTypes.func,
  blue: PropTypes.bool,
  className: PropTypes.string,
};

export default Logo;
