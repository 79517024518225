import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import styled from 'styled-components';

import UIContainer from 'components/UIContainer';
import { go } from 'routes';
import LabResultsConsent from './Sections/LabResultsConsent';
import FeedbackQuestion from './Sections/FeedbackQuestion';
import { useAccountStore, useMyHealthStore } from 'store/mobx/mobx';
import LearnMorePopUp from 'components/LearnMorePopUp/LearnMorePopUp';
import Alerts from 'components/Alerts';
import Alert from 'libs/Alert';
import { string } from 'prop-types';
import { ISendFeedback } from 'store/mobx/myHealthStore/myHealthStore.type';
import Loading from 'components/loadingSpinner';
import { Redirect } from 'react-router';

const TYPES = {
    star: [
        "1 star - Poor experience",
        "2 star - Below Average",
        "3 star - Average",
        "4 star - Good",
        "5 star - Great"
    ],
    useful: [
        "No, it was not useful",
        "Maybe, somewhat useful",
        "Yes, but it can be improved",
        "Yes, it was helpful",
        "Not Applicable"
    ],
    meaningful: [
        "No, it was not meaningful",
        "Maybe, somewhat meaningful",
        "Yes, but can be improved",
        "Yes, it was helpful",
        "Not Applicable"
    ],
    feel: [
        "I feel more information is needed",
        "I feel information is just about right",
        "I feel this information is too much"
    ],
    happy: [
        "Yes, keep me signed up!",
        "I consent for current data to be used but withdraw from further"
    ]
}

const LabResultFeedbackFlow: React.FC = observer(() => {
    const myHealthStore = useMyHealthStore();
    const { profile } = useAccountStore();

    const [state, setState] = useState(1);
    const [checked, setChecked] = useState(false);

    if (myHealthStore.currentLabPage === undefined) return <Redirect to={'/home'} />;

    const [feedbackObj, setFeedbackObj] = useState<ISendFeedback>({
        PatientId: myHealthStore.currentLabPage!.PatientId,
        NoteId: myHealthStore.currentLabPage!.NoteId,
        Usefulness: 0,
        Meaningfulness: 0,
        Experience: 0,
        LevelOfInformation: 0,
        Reconsent: 0,
        Comments: ""
    })

    const getQuestion = () => {
        const Name = profile.FirstName + " " + profile.LastName;
        if (state === 1) {
            return <FeedbackQuestion 
                        name={Name}
                        practice={myHealthStore.currentLabPage!.OrganisationName}
                        provider={myHealthStore.currentLabPage!.ProviderName}
                        question={"1. How do you rate your results receiving process?"}
                        popUps={true}
                        onNext={(val) => {
                            setFeedbackObj({
                                ...feedbackObj,
                                Experience: val
                            })
                            setState(2);
                        }}
                        answers={TYPES.star}
                        openText={false}
                    />
        } else if (state === 2) {
           return <FeedbackQuestion 
                        name={Name}
                        practice={myHealthStore.currentLabPage!.OrganisationName}
                        provider={myHealthStore.currentLabPage!.ProviderName}
                        question={
                            "2.  Did you understand your lab results from your doctor?"
                        }
                        popUps={false}
                        onNext={(val) => {
                            setFeedbackObj({
                                ...feedbackObj,
                                Usefulness: val
                            })
                            setState(3);
                        }}
                        answers={TYPES.useful}
                        openText={false}
                    />
                        
        } else if (state === 3) {
            return (
                <FeedbackQuestion 
                        name={Name}
                        practice={myHealthStore.currentLabPage!.OrganisationName}
                        provider={myHealthStore.currentLabPage!.ProviderName}
                        question={"3. Were they given enough/too much information with their results"}
                        popUps={false}
                        answers={TYPES.meaningful}
                        onNext={(val) => {
                            setFeedbackObj({
                                ...feedbackObj,
                                Meaningfulness: val
                            })
                            setState(4)
                        }}
                        openText={false}
                    />
            )
        } else if (state === 4) {
            return (
                <FeedbackQuestion 
                    name={Name}
                    practice={myHealthStore.currentLabPage!.OrganisationName}
                    provider={myHealthStore.currentLabPage!.ProviderName}
                    question={"4. How do you feel if this level of information is supplied with all normal results in the future?"}
                    popUps={false}
                    answers={TYPES.feel}
                    onNext={(val) => {
                        setFeedbackObj({
                            ...feedbackObj,
                            LevelOfInformation: val
                        })
                        setState(5);
                    }}
                    openText={false}
                />
            )
        }  else if (state === 5) {
           return (
                <FeedbackQuestion 
                    name={Name}
                    practice={myHealthStore.currentLabPage!.OrganisationName}
                    provider={myHealthStore.currentLabPage!.ProviderName}
                    question={""}
                    popUps={false}
                    answers={TYPES.feel}
                    onNext={() => {
                        if (checked) { setState(6); }
                        else {
                            myHealthStore.postLabResultFeedback(feedbackObj, () => {
                                Alert.success("You have provided your feedback for the practice with improves the quality of patient care, next time you receive lab results you will be asked for more feedback.")
                                go.home();
                            })
                            myHealthStore.setJustGaveLabsFeedback()
                        };
                    }}
                    setText={(val: string) => setFeedbackObj({...feedbackObj, Comments: val })}
                    openText={true}
                    setChecked={setChecked}
                />
            )
        } else if (state === 6) {
            return (
                <FeedbackQuestion 
                    name={Name}
                    practice={myHealthStore.currentLabPage!.OrganisationName}
                    provider={myHealthStore.currentLabPage!.ProviderName}
                    question={"Thank you for participating with our research. We would like the opportunity to ask if you would like to continue in subsequest trials."}
                    popUps={false}
                    answers={TYPES.happy}
                    onNext={(val) => {
                        myHealthStore.postLabResultFeedback({...feedbackObj, Reconsent: val }, () => {
                            Alert.success("You have provided your feedback for the practice with improves the quality of patient care, next time you receive lab results you will be asked for more feedback.")
                            go.home();
                        })
                        myHealthStore.setJustGaveLabsFeedback()
                    }}
                    openText={false}
                />
            )
        }
    }
    
    return (
        <UIContainer heading={"Lab Results Research"} goBackFn={() => state === 1 ? go.home() : setState(state - 1)}>
            <Loading isFetching={myHealthStore.loading}>
                {getQuestion()}
            </Loading>
        </UIContainer>
    )
})

export default LabResultFeedbackFlow;