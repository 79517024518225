import { actionTypes } from '../actions/actions';

const INITIAL_STATE = {
  isRemoving: false,
  error: null,
  removeId: null
};

const removeDependantReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.removeDependant: {
      return {
        isRemoving: true,
        error: null,
      };
    }
    case actionTypes.removeDependantSuccess: {
      return {
        isRemoving: false,
        error: null,
        removeId: action.payload.id
      };
    }
    case actionTypes.removeDependantFailure: {
      return {
        isRemoving: false,
        error: action.payload.error,
      };
    }
    case actionTypes.removeDependantReset: 
      return {
        isRemoving: false,
        removeId:null,
        error: null
      }
    default: {
      return state;
    }
  }
};

export default removeDependantReducer;
