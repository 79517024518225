import React, { useEffect, useState } from "react";
import Textarea from "react-textarea-autosize";

// import AutoSuggestText from "../../../../components/AutoSuggestText/index";
import AutoSuggestText from "../../../../components/AutoSuggestTextTypescript/AutoSuggestText";
import styles from "./feelingFormMobile.module.css";
import Dropdown from "../Dropdown";
import { useAppointmentStore } from "store/mobx/mobx";

const FeelingFormMobile = ({ sessionDetails, Symptoms, postService, Services, changeReason, setShowSearch, stopAllSearch, s1, s2, s3, anySearchOpen, 
  showWarning, warningMessage, note, changeService, methods, consult, setMethod, onChange
}) => {
	const [selected, setSelected] = useState(false);
	const [idCount, setIdCount] = useState(2);

	const [symptomValues, setSymptomValues] = useState([]);

  const [inputObjects, setInputObjects] = useState([
		{
			id: 0,
			reasonIndex: 0,
      searchIndex: 1,
			changeValue: changeReason,
			selected: false,
			items: Symptoms,
			placeholder: "Add Symptoms (choose up to 2)",
			removeDisplayNone: removeDisplayNone,
			toggle: addDisplayNone,
			shiftBoxes: shiftBoxes,
			removeSymptomValue: removeSymptomValue,
			addSymptomValue: addSymptomValue
		},
		{
			id: 1,
			reasonIndex: 1, 
      searchIndex: 2,
			changeValue: changeReason, 
			selected: false, 
			items: Symptoms, 
			placeholder: "Add one more Symptom", 
			removeDisplayNone: removeDisplayNone,
			toggle: addDisplayNone,
			selectedState: false,
			removeSymptomValue: removeSymptomValue,
			addSymptomValue: addSymptomValue
		}
	])

	const removeDisplayNone = () => {
		setSelected(true);
  	}

  	const addDisplayNone = () => {
		setSelected(false);
  	}

	const addSymptomValue = (val) => {
		let arr = symptomValues;

		arr.push(val);
		setSymptomValues(arr);
	}

	const removeSymptomValue = (element) => {
		let arr = symptomValues;

		element === 0 ? arr.shift() : arr.pop();
		setSymptomValues(arr);
	}

  const shiftBoxes = () => {
		let boxes = inputObjects
		boxes.shift();
		boxes[0].reasonIndex = 0;
		boxes[0].placeholder = "Add Symptoms (choose up to 2)";
		boxes[0].removeDisplayNone = removeDisplayNone;
		boxes[0].toggle = addDisplayNone;
		boxes[0].shiftBoxes = shiftBoxes;

		boxes.push({
			id: idCount,
			reasonIndex: 1, 
			changeValue: changeReason, 
			selected: false, 
			items: Symptoms, 
			placeholder: "Add one more Symptom", 
			removeDisplayNone: () => {},
			toggle: () => {},
			selectedState: false,
			removeSymptomValue: removeSymptomValue,
			addSymptomValue: addSymptomValue
		})

		setIdCount(idCount + 1)
		setInputObjects(boxes);
  }
  
  return(
    <div className={styles.container}>
      <form className={styles.feelingForm}>
        {showWarning && !anySearchOpen &&
            <div className={styles.redBorder}>
              {warningMessage && warningMessage.map((item, i) => {
                return (
                  <div key={i} className={styles.redColor}>{item}</div>
                )
              })}
            </div>
          }
          
        <div style={{display: s1 ? "none" : ""}}>
          <AutoSuggestText 
              searchIndex={0}
              setShowSearch={setShowSearch}
              stopAllSearch={stopAllSearch}
              type={"Service: "}
              changeValue={changeService} 
              selected={false} 
              items={Services} 
              placeholder={"Add Service (choose only 1)"} 
              removeDisplayNone={() => {}}
              toggleList={() => {}}
              reasonIndex={null}
              postItem={postService}
              sessionId={sessionDetails.SessionId}
          />
          </div>

          {inputObjects.map((item) => {
            switch(item.reasonIndex) {
              
              case 0:
                return(
                <div key={item.id} style={{display: s2 ? "none" : ""}}>
                  <AutoSuggestText 
                      type={"Symptom: "}
                      setShowSearch={setShowSearch}
                      stopAllSearch={stopAllSearch}
                      reasonIndex={item.reasonIndex} 
                      searchIndex={item.searchIndex}
                      changeValue={item.changeValue} 
                      selected={item.selected} 
                      items={Symptoms} 
                      placeholder={item.placeholder} 
                      removeDisplayNone={removeDisplayNone}
                      addDisplayNone={addDisplayNone}
                      toggleList={() => {}}
                      removeSymptomValue={removeSymptomValue}
                      addSymptomValue={addSymptomValue}
                      sessionId={sessionDetails.SessionId}
                      shiftBoxes={shiftBoxes}
                  />
                </div>)
              case 1:
                return(
                <div key={item.id} className={symptomValues.length > 0 ? styles.display : styles.displayNone } style={{display: s3 ? "none" : ""}}>
                  <AutoSuggestText 
                      type={"Symptom: "}
                      setShowSearch={setShowSearch}
                      stopAllSearch={stopAllSearch}
                      reasonIndex={item.reasonIndex} 
                      searchIndex={item.searchIndex}
                      changeValue={item.changeValue} 
                      selected={item.selected} 
                      items={Symptoms} 
                      placeholder={item.placeholder} 
                      sessionId={sessionDetails.SessionId}
                      removeDisplayNone={removeDisplayNone}
                      addDisplayNone={addDisplayNone}
                      toggleList={() => {}}
                      removeSymptomValue={removeSymptomValue}
                      addSymptomValue={addSymptomValue}
                      shiftBoxes={shiftBoxes}
                  />
                </div>)
              
              default: return null;
            }
          })}
        {methods && !anySearchOpen && consult &&
            <Dropdown items={methods} setMethod={(method) => setMethod(method)} />
        }
        <div style={{display: anySearchOpen ? "none" : ""}}>
          <Textarea
            placeholder= "Add more info in free text"
            value={note}
            onChange={onChange}
            className={styles.textarea}
            maxLength={120}
          />
      </div>
      </form>
    </div>
  )
}

export default FeelingFormMobile;