import React from 'react';

const ADAPT_DATA_TOUCH = {
    fontFactor: 1.3,
    inputType: 'tel'
}

const ADAPT_DATA_DESKTOP = {
    fontFactor: 0.95,
    inputType: 'text'
}

export const adapter = (isTouch: boolean) => isTouch ? ADAPT_DATA_TOUCH : ADAPT_DATA_DESKTOP;