import moment from 'moment';
import React from 'react';
import { CommentType, ILabResult } from 'store/mobx/myHealthStore/myHealthStore.type';
import styled from 'styled-components';

import rtfToHtml from '@iarna/rtf-to-html';
import { useState, useEffect } from 'react';
import { AddComment } from 'MyHealthPage/components/AddComment';
import { useMyHealthStore } from 'store/mobx/mobx';

const Container = styled.div`
    height: 100%;
    padding: 0rem 2rem;
    padding-top: 1.5rem;
    width: 100%;
`
const Header = styled.div`
    display: flex;
    flex-direction:  column;
    margin-bottom: 1rem;
`
const Title = styled.div`
    font-size: 15px;
    font-weight: bold;
    padding-bottom: 0;
    text-decoration: underline;
    text-decoration-color: #DADADA;
`
const Result = styled.div`
    font-weight: 300;
    margin-bottom: 1rem;
`
const AddCommentContainer = styled.div`
    color: #07A3C8;
    font-size: 14px;
    cursor: pointer;
    padding: 1rem 0rem;
    width: 100%;
    margin: auto;
`
const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid #F0F0F0;
    margin: 1rem 0;
    margin-top: 0.5rem;
`
interface IProps {
    item: ILabResult
}
const LabResultOpen: React.FC<IProps> = ({item}) => {
    const [val, setVal] = useState("");
    const rtf = item.Results.startsWith("{");
    const phrStore = useMyHealthStore();
  
    // Check if RTF format then convert to html, otherwise use the string given
    useEffect(()=>{
		if (rtf) rtfToHtml.fromString(item.Results, (err: any, html: any) => { setVal(html) })
        else if (item.Results !== val) setVal(item.Results);
	});

    return (
        <Container>
            <Header>
                <Title>{item.NameOfTest} (Arrived on {moment(item.DateTime).format("DD/MM/YY")})</Title>
            </Header>
            Results:
            <Result>
                {rtf && <div dangerouslySetInnerHTML={{__html: val}} />}
                {!rtf && item.Results.split('\t').map(str => <p>{str}</p>)}
            </Result>
            {item.InternalComment && "Internal Comment:"}
            <Result>{item.InternalComment && item.InternalComment}</Result>
            <Line style={{margin: "0"}}/>
            <AddCommentContainer>
                <AddComment
                    onSubmit={phrStore.postNewComment.bind(phrStore)}
                    refreshList={phrStore.getLabResults.bind(phrStore)}
                    commentType={CommentType.LabResult}
                    itemToShow={item}
                    itemId={item.Id}
                    label="+ Add comment for yourself"
                />
            </AddCommentContainer>
        </Container>
    )
}

export default LabResultOpen;