import { filter, map, compose } from 'lodash/fp';

import { actionTypes } from '../actions/actions';

const findVerifiedStatus = feed => {
  const a = filter(f => f.Type === 'MobileNotVerified')(feed);
  return a.length > 0;
};

const filterFeed = (filterType, feed) =>
  compose(
    map(f => f.Item),
    filter(f => f.Type === filterType),
  )(feed);

const filterVerification = (filterType, feed) =>
  compose(filter(f => f.Type !== filterType))(feed);

const INITIAL_STATE = {
  isFetching: false,
  error: null,
  feed: [],
  appointments: [],
  scripts: [],
  messages: [],
  isMobileVerified: false,
  success: false
};

const feedReducer = (state = INITIAL_STATE, action) => {
  const feed = action.payload && action.payload.feed;
  switch (action.type) {
    case actionTypes.getFeed:
      return {
        ...state,
        error: null,
        isFetching: true,
        success: false,
      };
    case actionTypes.getFeedSuccess:
      return {
        isFetching: false,
        error: null,
        feed: feed,
        appointments: filterFeed('Appointment', feed),
        scripts: filterFeed('Prescription', feed),
        messages: filterFeed('Message', feed),
        isMobileVerified: findVerifiedStatus(feed),
        success: true,
      };
    case actionTypes.getFeedFailure:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
        success: false,

      };
    case actionTypes.getVerificationStatus:
      return {
        ...state,
        error: null,
        isFetching: true,
     };
    case actionTypes.clearFeed:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
};

export default feedReducer;
