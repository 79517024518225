import { call, takeEvery, put } from 'redux-saga/effects';

import HttpLib from '../../../libs/Http';
import Alert from '../../../libs/Alert';
import { toServerFormat } from '../../../libs/Dates';
import { isDesktop } from '../../../config';
import { go } from '../../../routes/index';

import { actions, actionTypes } from '../actions/actions';
import { actions as reasonActions } from '../../Reason/actions/actions';

const checkEnrollmentParserHttp = HttpLib(
  ({ status }) => {
    if (status === 403) {
      return {
        error: 'User is not enrolled with this practice',
        shouldAlert: false,
      };
    }
    return {
      error: 'Unexpected Error Occurred. Please Try Again.',
      shouldAlert: true,
    };
  },
  err => {
    if (err.shouldAlert) {
      Alert.error(err.error);
    }
  },
);

const getServicesApi = (sessionId, orgId) =>
    checkEnrollmentParserHttp
      .get(`consumer/appointment/session/service/${sessionId}?orgId=${orgId}`)
      .then(({ body }) => body);

const ableToBookPatientApi = (InitiatorFirstName, InitiatorLastName, InitiatorMobile, 
  InitiatorDateOfBirth, OrganisationId, PmsUserId, AppointmentId, Time, guest) =>
    checkEnrollmentParserHttp
      .post(guest ? `consumer/appointment/session/abletobook/guest` : `consumer/appointment/session/abletobook`, {
        data: {
          InitiatorFirstName, 
          InitiatorLastName, 
          InitiatorMobile, 
          InitiatorDateOfBirth,
          OrganisationId, 
          PmsUserId, 
          AppointmentId, 
          Time,
          IsBookingForSomeoneElse : false
        }
      })
      .then(({ body }) => body);

const ableToBookDependantApi = (InitiatorFirstName, InitiatorLastName, InitiatorMobile, 
  InitiatorDateOfBirth, OrganisationId, PmsUserId, AppointmentId, Time, DependantId) =>
    checkEnrollmentParserHttp
      .post(`consumer/appointment/session/abletobook`, {
        data: {
          InitiatorFirstName, 
          InitiatorLastName, 
          InitiatorMobile,
          InitiatorDateOfBirth, 
          OrganisationId, 
          PmsUserId, 
          AppointmentId, 
          Time,
          DependantId
        }
      })
      .then(({ body }) => body);

const ableToBookSomeoneElseApi = (InitiatorFirstName, InitiatorLastName, InitiatorMobile, 
  InitiatorDateOfBirth, OrganisationId, PmsUserId, AppointmentId, Time, FirstName, LastName,
  DateOfBirth, Mobile) =>
    checkEnrollmentParserHttp
      .post(`consumer/appointment/session/abletobook`, {
        data:  { 
          InitiatorFirstName: InitiatorFirstName, 
          InitiatorLastName: InitiatorLastName, 
          InitiatorMobile: InitiatorMobile,
          InitiatorDateOfBirth: InitiatorDateOfBirth,
          OrganisationId: OrganisationId, 
          PmsUserId: PmsUserId, 
          AppointmentId: AppointmentId, 
          Time: Time, 
          IsBookingForSomeoneElse : true, 
          FirstName: FirstName, 
          LastName: LastName,
          DateOfBirth: DateOfBirth, 
          Mobile: Mobile
        }
      })
      .then(({ body }) => body);


function* checkEnrolledDependant({
  payload: { practiceURL, InitiatorFirstName, InitiatorLastName, InitiatorMobile, 
    InitiatorDateOfBirth, OrganisationId, PmsUserId, AppointmentId, Time, DependantId },
}) {
  try {
    const session = yield call(
      ableToBookDependantApi,
      InitiatorFirstName, 
      InitiatorLastName, 
      InitiatorMobile, 
      InitiatorDateOfBirth,
      OrganisationId, 
      PmsUserId, 
      AppointmentId, 
      Time, 
      DependantId
    );

    const services = yield call(getServicesApi, session.SessionId, OrganisationId);
    yield [
      put(actions.enrolledPatientSuccess(session)),
      put(reasonActions.getReasonsSuccess(services))
    ]
  } catch (err) {
    if (err.status === 403) {
      yield put(actions.enrolledPatientFailure(err.response.body));
      if (!isDesktop()) go.guestBookingError(practiceURL);
    }
  }
}

function* checkEnrolledPatient({
  payload: { practiceURL, InitiatorFirstName, InitiatorLastName, InitiatorMobile, 
    InitiatorDateOfBirth, OrganisationId, PmsUserId, AppointmentId, Time, guest
  },
}) {
  try {
    const session = yield call(
      ableToBookPatientApi,
      InitiatorFirstName, 
      InitiatorLastName, 
      InitiatorMobile, 
      InitiatorDateOfBirth,
      OrganisationId, 
      PmsUserId, 
      AppointmentId, 
      Time, 
      guest
    );
    
    const services = yield call(getServicesApi, session.SessionId, OrganisationId);

    yield [
      put(actions.enrolledPatientSuccess(session)),
      put(reasonActions.getReasonsSuccess(services))
    ]
  } catch (err) {
    if (err.status === 403) {
      yield put(actions.enrolledPatientFailure(err.response.body));
      if (!isDesktop()) go.guestBookingError(practiceURL);
    }
  }
}

function* checkEnrolledSomeoneElse({
  payload: { practiceURL, InitiatorFirstName, InitiatorLastName, InitiatorMobile, 
    InitiatorDateOfBirth, OrganisationId, PmsUserId, AppointmentId, Time, FirstName, LastName, DateOfBirth, Mobile 
  },
}) {
  try {
    const session = yield call(
      ableToBookSomeoneElseApi,
      InitiatorFirstName, 
      InitiatorLastName, 
      InitiatorMobile,
      InitiatorDateOfBirth, 
      OrganisationId, 
      PmsUserId, 
      AppointmentId, 
      Time, 
      FirstName, 
      LastName,
      toServerFormat(DateOfBirth), 
      Mobile
    );

    const services = yield call(getServicesApi, session.SessionId, OrganisationId);

    yield [
      put(actions.enrolledPatientSuccess(session)),
      put(reasonActions.getReasonsSuccess(services))
    ]
  } catch (err) {
    if (err.status === 403) {
      yield put(actions.enrolledPatientFailure(err.response.body));
      if (!isDesktop()) go.guestBookingError(practiceURL);
    }
  }
}

function* checkPatientEnrollmentSaga() {
  yield takeEvery(actionTypes.checkEnrolledPatient, checkEnrolledPatient);
}
function* checkDependantEnrollmentSaga() {
  yield takeEvery(actionTypes.checkEnrolledDependant, checkEnrolledDependant);
}
function* checkSomeoneElseEnrollmentSaga() {
  yield takeEvery(actionTypes.checkEnrolledSomeoneElse, checkEnrolledSomeoneElse);
}


export { checkPatientEnrollmentSaga, checkDependantEnrollmentSaga, checkSomeoneElseEnrollmentSaga };
