import { Box, Text } from 'components/vensaComponents';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';

const Icon: React.FC = () => (
    <Box
      width="18px"
      height="18px"
      borderRadius="50%"
      bg="white"
      border="1px solid"
      borderColor="primary"
      justifyContent="center"
      alignItems="center"
      ml="auto"
      cursor="pointer"
    >
      <Text font-family={"initial"} color="primary">i</Text>
    </Box>
  );

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding: 0.5rem 0;
`
const Label = styled.div`
    font-size: 14px;
    color: #07A3C8;
    cursor: pointer;
    width: 90%;
`

interface IProps {
    text: string;
    onClick: (keyword: string) => void;
}
const ClickableItem: React.FC<IProps> = ({text, onClick}) => 
    <Container onClick={() => onClick(text)}>
        <Label>{text}</Label>
        <Icon />    
    </Container>

export default ClickableItem;