import React, { Component, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { actions as patientDetailsActions } from '../../actions/actions';
import { actions as guardianActions } from '../../actions/guardianActions';
import { actions as enrollmentActions } from '../../../PatientSelection/actions/actions';
import PatientDetails from '../PatientDetails/index';
import GuardianDetails from '../GuardianDetails/index';
import TnC from '../../../../components/TnC/index';
import CtaButton from '../../../../components/CtaButton/index';
import { toServerFormat, isUnderAge } from '../../../../libs/Dates';
import { isDesktop } from '../../../../config';
import { detailsPropType } from '../../propTypes/index';
import { profilePropType } from '../../../../components/UIContainer/MobileUI/MainShell/propTypes/index';
import { go } from '../../../../routes/sub/vAppointment';
import styles from './detailsPage.module.css';

class DetailsPage extends Component {
  static propTypes = {
    addDetails: PropTypes.func.isRequired,
    addGuardianDetails: PropTypes.func.isRequired,
    showGuardianPopUp: PropTypes.func.isRequired,
    checkEnrollment: PropTypes.func.isRequired,
    clearGuardianDetails: PropTypes.func.isRequired,
    popUpOpen: PropTypes.bool.isRequired,
    time: PropTypes.string.isRequired,
    doctorId: PropTypes.string.isRequired,
    practiceId: PropTypes.string.isRequired,
    token: PropTypes.string,
    patientDetails: detailsPropType.isRequired,
    guardianDetails: detailsPropType.isRequired,
    profile: profilePropType.isRequired,
  };
  state = {
    patientValid: false,
    guardianValid: false,
    isUnderAge: false,
    hideTnC: !!this.props.token,
    acceptsTnC: !!this.props.token || !!this.props.guardianDetails.firstName,
    TnCError: false,
  };

  tempDetails = {};
  patient = React.createRef();

  // TODO: OK, this is a dirty fix for getting patient details
  // somehow, the details won't be there after clicking the button for the 2nd time
  // the bug is:
  // add console.log(this.props.patientDetails) before and after this.patient.submit(); (in the this.submit())
  // the details is always empty even after calling this.patient.submit()
  // but it is there after 2nd click.
  // in the future we should find the true problem and fix it.
  // this is just for now.
  addDetailsPatch = details => {
    this.props.addDetails(details);
    this.tempDetails = details;
  };

  componentDidMount() {
    const guardianDetails = this.getGuardian();
    if (this.props.token) this.setGuardianValid(guardianDetails);

    // opens the 'add guardian details' button when returning to this page
    if (
      this.props.patientDetails.dateOfBirth &&
      !this.props.token &&
      isUnderAge(this.props.patientDetails.dateOfBirth)
    ) {
      if (this.props.match.params.type === 'guardian') {
        // explore if needed
        this.setState({ patientValid: true });
      } else this.setState({ hideTnC: true });
    } else if (!isDesktop()) this.patient.validateForm();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.popUpOpen !== this.props.popUpOpen && !nextProps.popUpOpen) {
      if (!isDesktop()) {
        // set the form valid state upon entering the guardian details on mobile
        this.patient.validateForm();
      }
    }
  }
  // prevents unnecessary rerenders
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props) return true;
    if (nextState !== this.state) return true;
    return false;
  }
  setPatientValid = value => {
    this.setState({ patientValid: value });
  };
  setGuardianValid = (details, value = true) => {
    this.setState({ guardianValid: value });
    if (details.dateOfBirth !== 'Invalid date')
      this.props.addGuardianDetails(details);
  };
  setShowTnC = value => {
    // shows terms and conditions if user is a guest
    if (!this.props.token) this.setState({ hideTnC: value });
  };
  getGuardian = () => {
    if (this.props.token) {
      return {
        firstName: this.props.profile.FirstName,
        lastName: this.props.profile.LastName,
        dateOfBirth: this.props.profile.DateOfBirth,
        mobile: this.props.profile.Mobile,
      };
    }
    return this.props.guardianDetails;
  };
  isValid = () => {
    if (this.patient.current === null) { return; }
    if (!isUnderAge(this.props.patientDetails.dateOfBirth)) {
      return this.state.patientValid;
    }
    return this.state.patientValid && this.state.guardianValid;
  };
  acceptTnC = () =>
    this.setState({
      acceptsTnC: !this.state.acceptsTnC,
      TnCError: false,
    });
  // shows patient details form
  goBack = () => {
    this.props.changePage('patient')();
    this.setState({ hideTnC: true });
  };

  // checks if patient has the right permissions to book an appointment online
  checkEnrollment = () => {
    let { patientDetails } = this.props;
    const isEmptyObject =
      Object.keys(patientDetails).length === 0 &&
      patientDetails.constructor === Object;

    if (isEmptyObject) {
      patientDetails = this.tempDetails;
    }

    const guardianDetails = isUnderAge(patientDetails.dateOfBirth)
      ? this.getGuardian()
      : {};
    if (this.props.token) this.setGuardianValid(guardianDetails);
    const mobile = patientDetails.mobile || guardianDetails.mobile;

    this.props.token ?
    this.props.checkEnrollmentSomeoneElse(
      this.props.practice.UrlName,
      this.props.profile.FirstName,
      this.props.profile.LastName,
      this.props.profile.Mobile,
      this.props.profile.DateOfBirth,
      this.props.practice.Id,
      this.props.doctorId,
      this.props.appointmentId,
      this.props.time,
      patientDetails.firstName,
      patientDetails.lastName,
      patientDetails.dateOfBirth,
      mobile
    )
    :
    this.props.checkEnrollment(
      this.props.practice.UrlName,
      patientDetails.firstName,
      patientDetails.lastName,
      mobile,
      patientDetails.dateOfBirth,
      this.props.practice.Id,
      this.props.doctorId,
      this.props.appointmentId,
      this.props.time,
      true
    )
    ;
  };
  submit = () => {
    // adds details to relevant reducers
    if (this.props.match.params.type === 'patient') {
      this.props.clearGuardianDetails();
      this.patient.submit();
    } else this.guardian.submit();
    if (!isDesktop()) this.patient.submit();
    if (this.isValid()) {
      // informs patient if they still need to accept the Terms and Conditions
      if (!this.state.acceptsTnC) this.setState({ TnCError: true });
      // checks if patient is able to book
      else this.checkEnrollment();
    }
  };
  openGuardian = () => {
    if (isDesktop()) this.props.changePage('guardian')();
    else if (!this.props.guardianDetails.firstName)
      this.props.showGuardianPopUp();
  };
  goSignIn = () => {
    go.signIn(this.props.match.params.id);
  };
  render() {
    const path = `/practice-profile/:id/book/details${
      isDesktop() ? '/patient' : ''
    }`;
    return (
      <div className={styles.container}>
        <Route
          key="/practice-profile/:id/book/details"
          path={path}
          exact={isDesktop()}
          render={props => (
            <PatientDetails
              {...props}
              ref={c => {
                if (c) {
                  this.patient = c
                }
              }}
              onValid={this.setPatientValid}
              validateForm={this.props.validateForm}
              isValid={this.state.patientValid}
              openGuardianDetails={this.openGuardian}
              addDetails={this.addDetailsPatch}
              isLoggedIn={!!this.props.token}
              hideTnC={this.setShowTnC}
              popUpOpen={this.props.popUpOpen}
              hasGuardian={!!this.props.guardianDetails.firstName}
            />
          )}
        />
        <Route
          exact
          key={'/practice-profile/:id/book/details/guardian'}
          path={'/practice-profile/:id/book/details/guardian'}
          render={props => (
            <GuardianDetails
              {...props}
              ref={c => {
                this.guardian = c;
              }}
              onValid={this.setGuardianValid}
              isValid={this.state.guardianValid}
              guardianDetails={this.props.guardianDetails}
              addDetails={this.addGuardianDetails}
              goBack={this.goBack}
            />
          )}
        />
        {!this.state.hideTnC && (
          <TnC
            className={styles.condition}
            checked={this.state.acceptsTnC}
            error={this.state.TnCError}
            onClick={this.acceptTnC}
          />
        )}
        <div className={styles.buttons}>
          {isDesktop() && !this.props.token ? (
            <div className={styles.loginText}>
              Already with us?
              <button className={styles.loginLink} onClick={this.goSignIn}>
                Log In
              </button>
            </div>
          ) : (
            <div />
          )}
          <CtaButton
            onClick={() => {
              this.props.clearValidateForm();
              this.submit();
            }}
            active={this.isValid() && this.state.acceptsTnC}
            isProcessing={this.props.isChecking}
            style={{position: "absolute", bottom: "2rem", right: "1rem"}}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.loginReducer.token,
  profile: state.profileReducer.profile,
  isChecking: state.checkEnrollmentReducer.isChecking,
  practiceId: isDesktop()
    ? state.practiceProfileReducer.profile.Id
    : state.practiceProfileReducer.profile.Id,
  time: state.practiceAppointmentReducer.selectedTime.time,
  appointmentId: state.practiceAppointmentReducer.selectedTime.appointmentId,
  doctorId: state.practiceAppointmentReducer.selectedTime.doctorId,
  patientDetails: state.patientDetailsReducer.patientDetails,
  guardianDetails: state.guardianDetailsReducer,
  practice: state.practiceProfileReducer.profile
});
const mapDispatchToProps = dispatch => ({
  addDetails: bindActionCreators(patientDetailsActions.addDetails, dispatch),
  addGuardianDetails: bindActionCreators(guardianActions.addDetails, dispatch),
  checkEnrollment: bindActionCreators(
    enrollmentActions.checkEnrolledPatient,
    dispatch,
  ),
  checkEnrollmentSomeoneElse: bindActionCreators(enrollmentActions.checkEnrolledSomeoneElse, dispatch),
  clearGuardianDetails: bindActionCreators(
    guardianActions.clearState,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DetailsPage);
