import { actionTypes } from '../actions/actions';
import { getToken } from '../../libs/newHttp.ts';
import moment from 'moment';

const INITIAL_STATE = {
  token: moment().isAfter(JSON.parse(localStorage.getItem('token') ?? '{}')?.expiryTime ?? null) ? null : (JSON.parse(localStorage.getItem('token') ?? '{}')?.token ?? null),
  refreshToken: JSON.parse(localStorage.getItem('token') ?? '{}')?.refresh_token ?? null,
  expiryTime: JSON.parse(localStorage.getItem('token') ?? '{}')?.expiryTime ?? null,
  isFetching: false,
  error: null,
  email: JSON.parse(localStorage.getItem('token') ?? '{}')?.email ?? null,
  success: (localStorage.getItem('token') ?? '{}') !== '{}',
  consumerUrl: JSON.parse(localStorage.getItem('token') ?? '{}')?.ConsumerUrl ?? null,
};

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.login: {
      return {
        ...state,
        success: false,
        isFetching: true,
        error: null,
      };
    }
    case actionTypes.loginPin: {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    }
    case actionTypes.loginSuccess: {
      return {
        isFetching: false,
        error: null,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken || state.refreshToken,
        expiryTime: action.payload.expiryTime,
        email: action.payload.email,
        success: true,
        consumerUrl: action.payload.ConsumerUrl
      };
    }
    case actionTypes.clearAll: {
      return INITIAL_STATE;
    }
    case actionTypes.refresh: {
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    }
    case actionTypes.loginError: {
      return {
        ...state,
        isFetching: false,
        error: action.payload.err,
      };
    }
    case actionTypes.clearLoginError: {
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    }
    case actionTypes.logout: {
      return {
        ...state,
        token: null,
        isFetching: false,
        error: null,
        expiryTime: null,
        email: null,
      };
    }
    case actionTypes.logoutSuccess: {
      return {
        isFetching: false,
        error: null,
        token: null,
        expiryTime: null,
        email: null,
      };
    }
    case actionTypes.logoutFailure: {
      return {
        isFetching: false,
        error: null,
        token: null,
        expiryTime: null,
        email: null,
      };
    }
    default: {
      return state;
    }
  }
};

export default loginReducer;
