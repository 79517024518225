import { actionTypes } from '../actions/actions';

const INITIAL_STATE = {
  whiteBoxNotification: {
    isOpen: false,
    index: 0,
  },
  componentIndex: 0,
};
const mainShellReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.setWhiteBoxNotification: {
      return {
        ...state,
        whiteBoxNotification: {
          ...state.whiteBoxNotification,
          ...action.payload,
        },
      };
    }
    case actionTypes.setComponentIndex: {
      return {
        ...state,
        componentIndex: action.payload.index,
      };
    }
    case actionTypes.clearComponentIndex: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default mainShellReducer;
