import React from 'react';
import { ProtectedRoute, push } from '../utils';
import { isDesktop } from '../../config';
import PrescriptionListDesktop from '../../PrescriptionsPage/PrescriptionListDesktop';
import ScriptDetailsDesktop from '../../PrescriptionsPage/ScriptDetailsDesktop';

const go = {
  prescriptions: () => push('/prescriptions'),
  prescriptionDetails: id => push(`/prescription/${id}`),
};

const PrescriptionsRoutes = isDesktop() && [
  <ProtectedRoute
    key="/prescriptions"
    path="/prescriptions"
    component={PrescriptionListDesktop}
  />,
  <ProtectedRoute
    key="/prescription/:id"
    path="/prescription/:id"
    component={ScriptDetailsDesktop}
  />,
];

export default PrescriptionsRoutes;
export { go };
