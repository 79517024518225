import React from 'react';
import { useLocation } from 'react-router-dom';

export type Params<Key extends string = string> = {
    readonly [key in Key]: string | undefined;
};

export const useQuires = <T extends string | Record<string, string | undefined>, 
    >(): Readonly<[T] extends [string] ? Params<T> : Partial<T>> => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const result = {} as any;
    for(const [key, value] of query.entries()) {
        result[key] = value;
    }
    return result;
}

    