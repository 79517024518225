const actionTypes = {
  fetchDoctorProfile: 'DOCTOR_PROFILE_FETCH',
  fetchDoctorProfileSuccess: 'DOCTOR_PROFILE_FETCH_SUCCESS',
  fetchDoctorProfileFailure: 'DOCTOR_PROFILE_FETCH_FAILURE',
  clearDoctorProfile: 'DOCTOR_PROFILE_CLEAR',
};

const fetchDoctorProfile = id => ({
  type: actionTypes.fetchDoctorProfile,
  payload: {
    id,
  },
});
const fetchDoctorProfileSuccess = doctor => ({
  type: actionTypes.fetchDoctorProfileSuccess,
  payload: {
    doctor,
  },
});
const fetchDoctorProfileFailure = error => ({
  type: actionTypes.fetchDoctorProfileFailure,
  payload: {
    error,
  },
});
const clearDoctorProfile = () => ({
  type: actionTypes.clearDoctorProfile,
});
const actions = {
  fetchDoctorProfile,
  fetchDoctorProfileSuccess,
  fetchDoctorProfileFailure,
  clearDoctorProfile,
};

export { actions, actionTypes };
