import React from 'react';
import styled from 'styled-components';
import { go } from '../../routes/sub/payments';
import { usePaymentsStore } from 'store/mobx/mobx';
import alert from 'libs/Alert';

const Button = styled.button`
  color: #0da5c9;
  background-color: white;
  border: none;
  margin: 0;
  padding: 0;
  font-size: 16px;
  cursor: pointer;
`;

export const AddNewCreditCard: React.FC = () => {
  const store = usePaymentsStore();

  const onClickAddPaymentMethod = () => {
      if (store.cards?.length >= 10){
          alert.error("We're sorry, you can't add more than 10 payment methods. Remove an existing payment method to proceed.");
          return;
      }
      go.addCreditCard()
  }

  return <Button onClick={()=> onClickAddPaymentMethod()}>Add Payment Method</Button>;
};
