import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ReactComponent as Arrow } from '../../svgs/rightarrow2.svg';
import { ReactComponent as Spinner } from '../../svgs/spinner.svg';
import styles from './ctaButton.module.css';

const CtaButton = ({
  id,
  onClick,
  className,
  type,
  white,
  active,
  isProcessing,
  style,
}) => (
  <button
    id={id}
    className={classnames(
      styles.container,
      className,
      { [styles.white]: white },
      { [styles.inactive]: !active && !white },
    )}
    type={type}
    onClick={active ? onClick : () => {}}
    style={style ? style : {}}
  >
    {isProcessing ? (
      <Spinner className={styles.spinner} />
    ) : (
      <Arrow className={styles.arrow} style={{ fill: white ? "#07a3c8" : "white"}} />
    )}
  </button>
);

CtaButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  white: PropTypes.bool,
  isProcessing: PropTypes.bool,
  style: PropTypes.any,
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};
export default CtaButton;
