import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import worriedKiwiURL from '../../images/worriedKiwi.png';
import phone from '../../svgs/call.svg'
import styles from './urgentAttention.module.css';
import Header from '../../components/UIContainer/MobileUI/header/index';
import { go } from '../../routes/index';
import { isDesktop } from '../../config';
import { ReactComponent as RightArrow } from '../../svgs/rightarrow.svg';
import { actions as feelingActions } from '../Reason/actions/actions';


const UrgentAttention = ({ updateSession, ...props }) => {
    const practice = props.location.state.practice;
    const sessionId = props.location.state.sessionId;
    const method = props.location.state.method;
    const symptoms = props.location.state.symptoms;
    const note = props.location.state.note;
    const service = JSON.parse(props.location.state.service);

    return (
        <div className={styles.feelingPage}>
            {!isDesktop() && <Header goBackFn={() => go.patientSelection(practice.UrlName)} headerClassNames={styles.header} showHelp />}

            {isDesktop() ? (
                <div className={styles.container}>
                    <img src={worriedKiwiURL} className={styles.feelingIcon} alt='I am feeling' />
                    <div className={styles.labelSmall}>
                        You have selected a symptom which may require urgent attention.
                        Please call 111 and tell them what you are experiencing. The 111 call handler will help determine what to do.
                    </div>
                    <div className={styles.labelSmall}><b> Call 111 NOW</b></div>
                    <div className={styles.labelSmall}>
                        Not an emergency? Then please continue with your booking.

                    </div>
                </div>
            ) : (
                <div className={styles.container}>
                    <img src={worriedKiwiURL} className={styles.feelingIcon} alt='I am feeling' />
                    <div className={styles.labelSmall}>
                        You have selected a symptom which may require urgent attention.
                    </div>
                    <div className={styles.labelSmall}>
                        Please call 111 and tell them what you are experiencing. The 111 call handler will help determine what to do.
                    </div>
                    <div className={styles.labelSmall}>
                        Not an emergency? Then please continue with your booking.
                    </div>

                    {/* eslint-disable-next-line */}
                    <a onClick={() => { window.location.href = 'tel:111'; }}>
                        <img style={{ margin: '20px' }} src={phone} alt='phone' />
                    </a>
                </div>
            )}


            {isDesktop() ? (
                <div className={styles.nextContainer}>
                    {/* eslint-disable-next-line */}
                    <a className={styles.navLinkLarge} style={{ visibility: "hidden" }} onClick={() => { go.patientSelection(practice.UrlName) }}>
                        <RightArrow className={styles.backwardArrow} />
                        <span>Back</span>
                    </a>
                    {/* eslint-disable-next-line */}
                    <a className={styles.navLinkLarge} onClick={() => {
                        updateSession(sessionId, method, symptoms, note, service, practice.UrlName);
                    }}>
                        <span>Continue Booking</span>
                    </a>
                </div>
            ) : (
                <div className={styles.footer}>
                    {/* eslint-disable-next-line */}
                    <a onClick={() => { go.patientSelection(practice.UrlName); }}>
                        <div className={styles.back}><u>or edit details (go back)</u></div>
                    </a>
                </div>
            )}
            {isDesktop() && <div className={styles.bottomNote}></div>}
        </div>
    )
}

const mapStateToProps = state => ({
    practice: state.practiceProfileReducer.profile
})

const mapDispatchToProps = dispatch => ({
    updateSession: bindActionCreators(feelingActions.updateSession, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UrgentAttention);