import React, { useState } from 'react';
import styled from 'styled-components';

import UIContainer from 'components/UIContainer';
import RouteBar from 'components/DesktopComponents/RouteBar';
import { go } from 'routes';
import TabBar from 'components/DesktopComponents/TabBar';
import Patient from 'svgs/patientSilhouette.svg';
import { ReactComponent as Back } from 'svgs/circleRightArrow.svg';
import { useIdentityStore, useAccountStore } from 'store/mobx/mobx';
import Processing from '../components/Processing';
import Submitted from '../components/Submitted';

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #F9F9F9;
    overflow-y: auto;
`
const Container = styled.div`
    width: 533px;
    margin 1rem auto;
`
const Title = styled.div`
    text-transform: uppercase;
    font-size: 13px;
    margin-top: 1rem;
    text-align: left;
    margin-bottom: 1rem;
`
const Inner = styled.div`
    height: 600px;
    width: 534px;
    background-color: white;
    margin: auto;
    margin-left: 7px;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 1px 1px 3px lightgray;
    padding: 0px 3rem;
`
const TextConfirmBold = styled.div`
    font-size: 17px;
    font-weight: 600;
    text-align: left;
    padding: 3px;
`
const TextConfirm = styled.div`
    font-size: 17px;
    font-weight: 500;
    text-align: left;
    padding: 3px;
`
const Button = styled.div`
    border-radius: 4px;
    background-color: #07A3C8;
    color: white;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin: 4rem auto;
    padding: 0.6rem 1rem;
`
const FlexH = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    margin: 0.5rem 0rem;
`
const Pic = styled.img`
    width: 75px;
    height: 70px;
    border-radius: 50%;
`
const FlexV = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0rem 1rem;
`

const Submit: React.FC = () => {
    const [state, setState] = useState(0);
    const storeId = useIdentityStore();
    const storeAcc = useAccountStore();
    return (
        <UIContainer>
            <Outer>
                <RouteBar 
                    lowLevel={"Identity"}
                    lowLink={() => go.identity()}
                />
                <TabBar type={0} id={1} />
                <Container>
                    <Title>Identity Status</Title>
                        {state === 0 &&
                            <Inner>
                                <Back onClick={() => go.verifyPrivacy(3)} style={{
                                    transform: "rotate(180deg)",
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: "30px",
                                    left: "30px",
                                    height: "48px",
                                    width: "48px",
                                }}/>
                                <FlexH style={{margin: "7rem auto 1rem", alignItems: "center", width: "auto"}}>
                                    <Pic src={storeId.photo === "" ? Patient : storeId.photo} alt="img" />
                                    <FlexV>
                                        <TextConfirmBold>{storeAcc.profile.FirstName + " " + storeAcc.profile.LastName}</TextConfirmBold>
                                        <TextConfirm>{storeAcc.profile.Mobile}</TextConfirm>
                                        <TextConfirm>{storeAcc.profile.Email}</TextConfirm>
                                    </FlexV>
                                </FlexH>
                                <Button style={{padding: "0.6rem 2rem"}} onClick={() => setState(1)}>
                                    Submit
                                </Button>
                            </Inner>
                        }
                        {state === 1 &&
                            <Inner>
                                <Processing onSuccess={() => setState(2)} onFailure={() => setState(0)} />
                            </Inner>
                        }
                        {state === 2 &&
                            <Inner style={{height: "734px"}}>
                                <Submitted />
                            </Inner>
                        }
                </Container>
            </Outer>
        </UIContainer>
    )
}

export default Submit;