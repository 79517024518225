import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { map } from 'lodash/fp';
import { CSSTransitionGroup } from 'react-transition-group';

import { actions } from '../components/UIContainer/actions/headerActions';
import { actions as listingActions } from './actions/actions';
import AddPractice from './AddPractice';
import PracticeListing from './PracticeListing';
import NoPracticesAdded from './NoPracticesAdded';
import UIContainer from '../components/UIContainer';
import CtaMenuButton from '../components/CtaMenuButton';
import DesktopCta from '../components/DesktopCta';
import DesktopPopUp from '../components/DesktopPopUp';
import { ReactComponent as PlusIcon } from '../svgs/plus.svg';
import { clinicPropType } from './propTypes';
import { go } from '../routes';
import { isDesktop, transitionGroupProps } from '../config';
import Alert from '../libs/Alert';
import styles from './practices.module.css';
import { getToken, useComponentDidMount } from 'libs/newHttp';
import { opacity } from 'styled-system';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useAccountStore, usePracticesStore } from 'store/mobx/mobx';
import PracticeSearch from 'Identity/DesktopIdentityPage/components/PracticeSearch';

const Outer = styled.div`
    width: 100%;
    background-color: #F9F9F9;
    align-items: center;
    display: flex;
    align-items: center;
    flex-direction: column;  
    overflow: scroll;
    &::-webkit-scrollbar {
     width: 0px;
    }
`
const Inner = styled.div`
	animation: appear 0.3s forwards;
	overflow-y: auto;
	overflow-x: hidden;
  flex-direction: column;
	-ms-overflow-x: hidden;
	padding: 0 0 40px;
	width: 620px;
	margin: auto;
  margin-top: 100px;
	height: 700px;
	margin-bottom: auto;
	padding: 0rem 1rem;
   &::-webkit-scrollbar {
		width: 10px;
	 }
	   
	 &::-webkit-scrollbar-track {
		background: #F0F0F0;
		border-radius: 25px;
		margin-left: 1rem;
	 }
	 
	 &::-webkit-scrollbar-thumb {
		 background: #AFAFAF;
		 border-radius: 25px;
	 }
	 
	 &::-webkit-scrollbar-thumb:hover {
		 background: #999; 
	 }
  }
`

const Practices = observer(({ setSideHeaderItem }) => {
	const store = usePracticesStore();
	const accStore = useAccountStore();
  const [removed, setRemoved] = useState(false);
  const [search, setSearch] = useState(false);
	const [menuActive, setMenuActive] = useState(false);
	const [showOverlay, setShowOverlay] = useState(false);
	const [showAddPractice, setShowAddPractice] = useState(false);
	const [clinic, setClinic] = useState({});
	const [position, setPosition] = useState({});
	
	
	/*
  state = {
    menuActive: false,
    showOverlay: false,
    showAddPractice: false,
    clinic: {},
    position: {},
  };*/
	useComponentDidMount(() => {
		store.getUserPractices(() => console.log("got practices"));
		if (isDesktop()) setSideHeaderItem('practiceListings');
	})

  useEffect(() => {
    //store.getUserPractices();
    if (store.refreshList) { store.getUserPractices(); }
  })
  /*
  UNSAFE_componentWillReceiveProps(nextProps) {
    const token = getToken();
    if (
      nextProps.linkingSuccess &&
      nextProps.linkingSuccess !== this.props.linkingSuccess
    ) {
      this.props.getClinics(token);
      Alert.success(`${nextProps.linkingName} has been added.`);
      if (isDesktop()) this.toggleAddPractice();
    }
    if (
      nextProps.unlinkingSuccess &&
      nextProps.unlinkingSuccess !== this.props.unlinkingSuccess
    ) {
      Alert.success(`${nextProps.unlinkingName} has been removed.`);
    }
  }*/
  
	const setClinicToRemove = (clinic, position) => {
		setClinic(clinic);
		setPosition(position);
	};

	const toggleMenuActive = () => {
		setMenuActive(!menuActive);
	};
	const toggleAddPractice = () => {
		setShowAddPractice(!showAddPractice);
	};

	const handleRemove = () => {
		store.unlinkPractice(clinic.Id, toggleMenuActive);
	};
/*
    const { clinics, isFetching } = this.props;
    const token = getToken();*/
    const showRemove = menuActive && !isDesktop();
    const removePosition = {
      top: `${position.top}px`,
      left: `${position.left + (position.width - 190)}px`,
    };
    return (
      <UIContainer
        heading="Practices"
        navKey='Practices'
        isFetching={store.loading || accStore.loading}
        goBackFn={() => go.home()}
      >
        <Outer>
          <div className={styles.overlay} 
            style={{
              visibility: showRemove ? "visible" : "hidden",
              opacity: showRemove ? "1" : "0",
              padding: isDesktop() ? "6rem 0rem" : "1rem 1rem" 
            }} 
            onClick={() => toggleMenuActive()}
          />
          
          {showRemove && (
            <div className={styles.removeContainer} style={removePosition}>
              <div onClick={handleRemove}>Remove this practice</div>
            </div>
          )}

          <Inner style={{ width: isDesktop() ? "620px" : "auto", display: isDesktop() ? "flex" : "contents"}}>
          {store.userPractices.Practices.map((c) => {
            return(
            <PracticeListing
              clinic={c}
              goToProfile={() => go.practiceBooking(c.UrlName)}
              key={c.PracticeId}
              toggleMenuActive={toggleMenuActive}
              parentIsActive={menuActive}
              setClinicToRemove={setClinicToRemove}
              unlinkPractice={() =>
                store.unlinkPractice(c)
              }
              goToSupport={go.support}
            />  
			  )
		  })}
          </Inner>
          {!store.userPractices.Practices.length && !store.loading && <NoPracticesAdded />}
          {!isDesktop() &&
            <CtaMenuButton
              text="Add Practice"
              icon={PlusIcon}
              textIconStyles={styles.buttonIcon}
              onClick={go.addPractice}
            />
          }
        </Outer>
        {isDesktop() && 
          <DesktopCta
            text="Add a Practice"
            background
            absolute
            className={styles.addPracticeButton}
            onClick={toggleAddPractice}
          />
        }
          {showAddPractice && (
            <PracticeSearch userHasPractices={store.userPractices.length !== 0} onClose={() => setShowAddPractice(false)} />
          )}
      </UIContainer>
    );
})

const mapStateToProps = state => ({
  isFetching:
    state.practiceListingReducer.isFetching ||
    state.practiceLinkingReducer.isFetching ||
    state.practiceUnlinkingReducer.isFetching,
  clinics: state.practiceListingReducer.practices,
  token: state.loginReducer.token,
  linkingSuccess: state.practiceLinkingReducer.success,
  linkingName: state.practiceLinkingReducer.practice.Name,
  unlinkingSuccess: state.practiceUnlinkingReducer.success,
  unlinkingName: state.practiceUnlinkingReducer.practice.Name,
  profile: state.profileReducer.profile
});
const mapDispatchToProps = dispatch => ({
  setSideHeaderItem: bindActionCreators(actions.setSideHeaderItem, dispatch),
  getClinics: bindActionCreators(listingActions.getPracticeListings, dispatch),
  unlinkPractice: bindActionCreators(listingActions.unlinkPractice, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Practices);
