import React from 'react';

import styles from './noDependantsCard.module.css';
import careCircle from '../../images/care-circle.png';
import { isDesktop } from '../../config';

const NoDependantsCard = ({hasDependants, learnMoreOnClick}) => {  
  return (
    !isDesktop() && !hasDependants ?
      <div className={styles.container}>
        <div>
          <div className={styles.imageContainer}>
            <img  src={careCircle} alt="Care circle logo" className={styles.imageStyle}/>
          </div>    
          <div className={styles.text}>
              <div>
                <p className={styles.textParagraph}>Take the stress out of looking after your loved ones.</p>
                <p className={styles.textParagraph}>Book appointments, request repeat scripts, and heaps more. Simply add family, Whanau and friends to your Care Circle to get started!</p>
                <p className={styles.textParagraph}><span onClick={learnMoreOnClick} style={{color: "#07A3C8", cursor: "pointer"}}>Learn more.</span></p>
              </div>
          </div>
        </div>
      </div>
    : 
    <div>
    </div>
    );
}

export default NoDependantsCard;

