import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { go } from '../../routes';
import { actions as scriptActions } from '../actions/actions';
import { actions as popUpActions } from '../../components/DesktopPopUp/actions/actions';
import { ReactComponent as Plus } from '../../svgs/plus.svg';
import { ReactComponent as Spinner } from '../../svgs/spinner.svg';
import { ReactComponent as Arrow } from '../../svgs/rightarrow2.svg';
import CtaButton from '../../components/CtaButton';
import SelectCard from '../../components/PaymentCardSelection';
import Alert from '../../libs/Alert';
import { cardDetailsPropType } from '../propTypes';
import { isDesktop } from '../../config';
import styles from './payment.module.css';
import alert from 'libs/Alert';
import { usePaymentsStore } from '../../store/mobx/mobx'
import { observer } from 'mobx-react-lite';
import { LOADING_STATUS } from '../../libs/newHttp';
import { ICard } from 'store/mobx/paymentStore/paymentStore.type';


interface IPaymentProps {
  setInfo: Function,
  isFetching: boolean,
  cardDetails: Array<typeof cardDetailsPropType>,
  setError: Function,
  paymentMethod: number,
  collectionOption: number,
  practiceName: string,
  addPaymentDetails: Function,
  goToConfirm: Function
}

const Payment = observer(({ paymentMethod,setError,practiceName,setInfo,addPaymentDetails,cardDetails,isFetching,goToConfirm }: IPaymentProps) => {
  const [payingOnline, setPayingOnline] = useState(true);
  const [sortedCards, setSortedCards] = useState<(ICard | null)[]>([]);
  const [selectedCard, setSelectedCard] = useState<ICard | null>();
  const [updatingCard, setUpdatingCard] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const paymentStore = usePaymentsStore();

  useEffect(() => {
    if (!isLoaded) {
      paymentStore.getCards();
      setIsLoaded(true)
    }
  }, [paymentStore, isLoaded]);

  useEffect(() => {
    if (paymentStore.cardList.length > 0) {
      const otherCards = paymentStore.cardList.filter(card => !card.IsDefault)
      const cards = [defaultCard(paymentStore.cardList), ...otherCards]
      setSortedCards(cards.filter(c => c))
    }
    if (!selectedCard){
      setSelectedCard(defaultCard(paymentStore.cardList))
    }
  }, [paymentStore, paymentStore.cardList, selectedCard, paymentStore.cardList.length, paymentStore.cardsLoadingStatus]);

  const handleOptionClick = (isPayingOnline, card) => () => {
    if (card && !card.IsExpired){
      setSelectedCard(card)
    }
    if (paymentMethod === 4 && !isPayingOnline) {
      if (isDesktop()) {
        setError(
          `${
            practiceName
          } only allows payment by Debit/Credit card for your selected pick up location.`,
        );
      } else {
        Alert.error(
          `${
            practiceName
          } only allows payment by Debit/Credit card for your selected pick up location.`,
        );
      }
    } else {
      setPayingOnline(isPayingOnline)
    }
  };

  const handleAddPaymentClick = () => {
    setInfo('payOnline', true);
    if (isDesktop()) {
      addPaymentDetails();
    } else go.addCard();
  };

  const handleCtaClick = async () => {
    if (payingOnline && !(paymentStore.cardList.length > 0)) {
      handleAddPaymentClick();
    } else {
      setUpdatingCard(true)
      await paymentStore.setDefaultCard(selectedCard?.Token ?? '')
      .then(async () => await paymentStore.getCards())
      .then(r => {
        setUpdatingCard(false)
        setInfo('payOnline', payingOnline);
        if (isDesktop()) {
          goToConfirm();
        } else go.confirmScript();
      })
      .catch(e => alert.error("There was an error selecting default card. Please try again."))
    }
  };
  
  const defaultCard = cardList => {
    if (cardList?.length > 0){
      const defaultCard: ICard = cardList.filter(card => card.IsDefault)[0];
      return defaultCard
    }
    return null
  }
  
  const cardSorting = (a, b) => {
    return (b.ExpYear - a.ExpYear) || (b.ExpMonth - a.ExpMonth) || (parseInt(b.Last4) - parseInt(a.Last4))
  }

  const selectedToken = selectedCard ? selectedCard.Token : paymentStore.cardList[0]?.Token;
  const loading = paymentStore.cardsLoadingStatus === LOADING_STATUS.LOADING;

  return (
    <div className={styles.container}>
      {!isDesktop() && (
        <Arrow className={styles.backArrow} onClick={go.confirmScript} />
      )}
      <div className={styles.heading}>How would you like to pay?</div>
      <div className={styles.optionsOuter}>
        <div>
          {(isFetching || loading || updatingCard) && <Spinner className={styles.spinner} />}
          {sortedCards?.length > 0 && sortedCards?.slice().sort(cardSorting).map((card, id) =>
              <SelectCard
                key={id}
                cardDetails={[card]}
                optionClick={handleOptionClick}
                payingOnline={payingOnline}
                selectedCard={(selectedToken === card?.Token)}
              />
            )
          }
        </div>
      </div>
      <div className={styles.buttons}>
        <button
          className={styles.addPaymentButton}
          onClick={handleAddPaymentClick}
        >
          <Plus className={styles.plus} />
          Add a Payment Method
        </button>
        {sortedCards.length ? (
          <CtaButton
            className={styles.cta}
            onClick={handleCtaClick}
            active={selectedCard && !selectedCard?.IsExpired}
          />
        ) : (
          <div />
        )}
      </div>
    </div>
  );
})

const mapStateToProps = state => ({
  practiceName: state.scriptDetailsReducer.practice.Name,
  paymentMethod: state.scriptDetailsReducer.pricing.Option.PaymentMethods,
  collectionOption: state.scriptDetailsReducer.pricing.Option.Option,
  cardDetails: state.cardDetailsReducer.cardDetails,
  isFetching: state.cardDetailsReducer.isFetching,
});
const mapDispatchToProps = dispatch => ({
  setInfo: bindActionCreators(scriptActions.setInfo, dispatch),
  setError: bindActionCreators(popUpActions.setError, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);

export { styles };
