import React from "react";
import styled from 'styled-components';


interface IProps {
    Text: string;
}

const Container = styled.div`
    background-color: #23C373;
    color: white;
    border-radius: 1rem;
    font-size: smaller;
    padding: 0.5rem;
    position: absolute;
    top: -5%;
    right: 5%;
    z-index: 10;
`

export const PatientTag: React.FC<IProps> = ({Text}) => {
    return (
        <Container>
            {Text}
        </Container>
    )
}