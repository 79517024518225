import React from 'react';
import PropTypes from 'prop-types';

import styles from './cardBanner.module.scss';
import { ReactComponent as NotesIcon } from 'svgs/greyPencil.svg';
import { isDesktop } from 'config';

const getColor = color => {
  if (color === 'Red') return '#FF6A65';
  if (color === 'Green') return '#23C373';
  return '#939393';
};

const CardBanner = ({ status, color, statusCode, hasPatientNotes }) => (
  <div className={styles.outerContainer}>
    <div
      className={styles.container}
      style={{ backgroundColor: getColor(color) }}
    >
      <span>{status}</span>
    </div>
    {
      statusCode === 11 && (
      <span className={styles.labelContainer}>
        <svg height="20" width="20">
		      <polygon points="10,5 15,15 5,15" className={styles.triangle} />
	      </svg>
      </span>)
    }
    {
      !isDesktop() && hasPatientNotes &&
        <NotesIcon height="20" width="20" style={{marginLeft:"0.5rem"}} className={styles.patientNotesIcon}/>
    }
  </div>
);

CardBanner.propTypes = {
  status: PropTypes.string,
  color: PropTypes.string,
};

export default CardBanner;
