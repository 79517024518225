import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Note } from 'svgs/noteBentCorner.svg';

const FlexH = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
    margin-bottom: 1rem;
`
const FlexV = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
`
const Title = styled.div`
    font-size: 17px;
    font-weight: 600;
    color: #6C6C6C;
    text-align: left;
    margin-bottom: 0.1rem;
`
const Subtitle = styled.div`
    font-size: 15px;
    font-weight: 300;
    color: #6C6C6C;
    margin: 0.1rem 0rem;
    text-align: left;
    text-transform: capitalize;
`

interface Props {
    name: string;
    practice: string;
    provider: string;
}

const NoteHeader: React.FC<Props> = ({name, practice, provider}) => {
    return (
        <FlexH>
            <Note />
            <FlexV>
                <Title>{name}</Title>
                <Subtitle>{practice}</Subtitle>
                <Subtitle>{provider}</Subtitle>
            </FlexV>
        </FlexH>
    )
}

export default NoteHeader;