import React, { useState } from 'react';
import styled from 'styled-components';

import Card from '../components/Card';
import booking from 'svgs/booking.svg';
import pills from 'svgs/blister-pack.svg';
import report from 'svgs/medical-report.svg';
import { useComponentDidMount } from 'libs/newHttp';
import { isDesktop } from 'config';
import EmptyBookings from 'PracticeProfilePage/BookingPageMobile/EmptyBookings';

const Container = styled.div`
    display: inline-block;
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    margin: auto;
    overflow: hidden;
`

interface Props {
    hide: (e: any) => void;
}
const CareCircleInfo: React.FC<Props> = ({hide}) => {
    const [state, setState] = useState(0);
    const [height, setHeight] = useState(isDesktop() ? "530px" : window.innerHeight - 34);
    
    useComponentDidMount(() => {
        window.addEventListener('resize', () => setHeight(window.innerHeight - 34));    
    })


    let card;
    let injectStyle = {paddingBottom: '2rem'}
    if (state === 0) card = <Card 
                                hide={hide} 
                                totalCards={3} 
                                active={0} 
                                title={"Book Appointments"} 
                                text={"Book appointments for anyone in your Care Circle."} 
                                onNext={() => setState(1)}
                                icon={booking}
                                goBack={(newState) => setState(newState)}
                                injectStyle={injectStyle}
                            />;  
    else if (state === 1) card = <Card 
                                    hide={hide} 
                                    totalCards={3} 
                                    active={1} 
                                    title={"Order Repeat Scripts"} 
                                    text={"View the medication list and order repeat scripts for anyone in your Care Circle."} 
                                    onNext={() => setState(2)}
                                    icon={pills}
                                    goBack={(newState) => setState(newState)}
                                    injectStyle={injectStyle}
                                />
    else if (state === 2) card = <Card 
                                    hide={hide} 
                                    totalCards={3} 
                                    active={2} 
                                    title={"Access Health Records"} 
                                    text={"Access healthcare records for anyone in your Care Circle. (Please note - you must have permission from the patient and the practice to allow this)"} 
                                    onNext={hide}
                                    icon={report}
                                    goBack={(newState) => setState(newState)}
                                    finishButton={true}
                                    buttonText={"Close"}
                                    injectStyle={injectStyle}
                                />
                        
    return (
        <Container style={{height: height, width: isDesktop() ? "400px" : "auto"}}>
            {card}
        </Container>
    )
}

export default CareCircleInfo;