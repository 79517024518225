import useReduxStore from 'hooks/useReduxStore';
import 'react';
import { useLocation } from 'react-router-dom';
import { useCareCircle } from 'store/mobx/mobx';
import { Dependant} from 'types/careCircle';


export const useDependant = () => {
    const path = useLocation().pathname;
    const id = path.substring(path.lastIndexOf('/') + 1);
    // const dependants = useReduxStore('fetchDependantsReducer', 'dependants') as unknown as Dependant[];
    // return dependants.find(d => d.Id === id);
    const store = useCareCircle();
    return store.dependants?.find(d => d.Id === id);
}