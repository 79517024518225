import React, { createRef, PropsWithChildren, ReactEventHandler } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, IBox } from '../../components/vensaComponents';
import { useOnClickOutside } from '../../libs/hooks';

export interface ModalProps {
  cardId: string;
  containerBoxProps?: IBox;
  shouldShow: boolean;
  closeModal: ReactEventHandler;
}

export const Modal = observer(
  ({ 
    closeModal, 
    shouldShow, 
    children, 
    containerBoxProps 
  }: PropsWithChildren<ModalProps>) => {
    const ref = createRef();
    useOnClickOutside(ref, closeModal);

    if (!shouldShow) return null;

    return (
      <Box
        zIndex={99}
        bg="rgba(0, 0, 0, 0.5);"
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        alignItems="center"
        justifyContent="center"
      >
        <Box
          direction="column"
          takeRef={ref}
          bg="white"
          width={0.9}
          p="16px"
          borderRadius="4px"
          {...containerBoxProps}
        >
          {children}
        </Box>
      </Box>
    );
  },
);
