import 'react';

const REGEX_IP = /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/
const isIPAddress = (value: string) => REGEX_IP.test(value);

const REGEX_ENVIRONMENT = /demo|dev/;
const extractEnvironment = () => {
  const environment = window.location.hostname.match(REGEX_ENVIRONMENT);
  
  return environment ? environment[0] : '';
}

export {
  isIPAddress,
  extractEnvironment
}