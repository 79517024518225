import React from 'react';
import styled from 'styled-components';
import pharmacy from 'images/fancyHospital.png';
import { go } from 'routes';

const Card = styled.div`
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
    box-shadow: 0px 3px 6px #00000029;
    position: relative;
    height: 100%;
`
const Pharmacy = styled.img`
    margin: 0 auto;
    height: 100px;
    display: block;
`
const Text = styled.div`
    text-align: center;
    color: #6C6C6C;
    margin: 1rem 0rem;
`
const Button = styled.div`
    background-color: #07A3C8;
    color: white;
    border-radius: 36px;
    padding: 1rem;
    margin: 1rem auto;
    text-align: center;
    font-size: 16px;
    width: 66%;
    margin-top: 2rem;
`

const NoPastMedications = () => {
    return (
        <Card>
            <Pharmacy src={pharmacy} />
            <Text>
                It looks like there are no past medications on your patient profile.
            </Text>
            <Text>
                You may only request repeat prescriptions for 
                medications you have been prescribed in the past
                2 years.
            </Text>
            <Button onClick={() => go.home()}>Go home</Button>
        </Card>
    )
}

export default NoPastMedications;