import React from 'react';
import { observer } from 'mobx-react-lite';
import posed from 'react-pose';
import { useSelector } from 'react-redux';
import { whiteBoxNotificationSettings } from '../MainShell/selectors';
import { PHRPrivacyCard } from './PHRPrivacyCard';
import { PaymentPrivacyCard } from './PaymentPrivacyCard';
import { IdentityPrivacyCard } from './IdentityPrivacyCard';
import { PDHViewResult } from './PDHViewResult';
import { PDHProvideFeedback } from './PDHProvideFeedback';
import { PrescriptionPrivacyCard } from './PrescriptionPrivacyCard';

const NOTIFICATIONS = [PHRPrivacyCard, PaymentPrivacyCard, IdentityPrivacyCard, PDHViewResult, PDHProvideFeedback, PrescriptionPrivacyCard];

const UpInChild = posed.div({
  hide: {
    y: '-100%',
    applyAtEnd: { display: 'none' },
  },
  show: {
    y: '0%',
    applyAtStart: { display: 'block' },
  },
});

export const WhiteNotificationBox: React.FC = observer(() => {
  const { isOpen, index } = useSelector(whiteBoxNotificationSettings);
  const NotificationComponent = NOTIFICATIONS[index];

  return (
    <UpInChild style={{backgroundColor: "white"}} pose={isOpen ? 'show' : 'hide'}>
      <NotificationComponent />
    </UpInChild>
  );
});
