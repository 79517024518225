import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions as appActions } from '../../../../App/actions/actions';
import { actions as guardianActions } from '../../actions/guardianActions';
import BasicInfoForm from '../../../../components/BasicInfoForm/index';
import CtaButton from '../../../../components/CtaButton/index';
import styles from './guardianFormPopUp.module.css';

class GuardianFormPopUp extends Component {
  static propTypes = {
    addAppStyles: PropTypes.func.isRequired,
    clearAppStyles: PropTypes.func.isRequired,
    addGuardianDetails: PropTypes.func.isRequired,
    hide: PropTypes.func.isRequired,
  };
  state = {
    valid: false,
  };
  componentDidMount() {
    this.props.addAppStyles([styles.app]);
  }
  componentWillUnmount() {
    this.props.clearAppStyles();
  }
  isValid = boolean => {
    this.setState({ valid: boolean });
  };
  submit = () => {
    this.form.handleSubmit();
    if (this.state.valid) {
      this.props.addGuardianDetails(this.form.getInfo());
      this.props.hide();
    }
  };
  render() {
    return (
      <div id="nw_guardianDetails" className={styles.container}>
        <div className={styles.content}>
          <div className={styles.heading}>Guardian details</div>
          <div className={styles.subheading}>Fill in with your details</div>
          <BasicInfoForm
            ref={c => {
              this.form = c;
            }}
            onValid={this.isValid}
            phoneRequired
            hideEmail
            allowUnderage={false}
          />
          <div className={styles.buttonContainer}>
            <button
              type="button"
              className={styles.cancel}
              onClick={this.props.hide}
            >
              Cancel
            </button>
            <CtaButton
              type="submit"
              active={this.state.valid}
              onClick={this.submit}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addAppStyles: bindActionCreators(appActions.addAppStyles, dispatch),
  clearAppStyles: bindActionCreators(appActions.clearAppStyles, dispatch),
  addGuardianDetails: bindActionCreators(guardianActions.addDetails, dispatch),
});

export default connect(
  null,
  mapDispatchToProps,
)(GuardianFormPopUp);
export { styles };
