import React, { PropsWithChildren } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';

interface StatusCardProps {
    handleClick?: () => void;
    status: string;
    statusColor?: string;
    disableColor?: boolean;
    disableCard?: boolean;
    className?: string;
    direction?: 'row' | 'column';
}

const status2Color = (status: string) => 
    status === 'NONE' ? '#DADADA' : 
    status === 'CONFIRMED' ? '#64E691' : 
    status === 'CANCELED' ? '#FF6A65' : 
    '#33CCCC';

const StatusCard: React.FC<PropsWithChildren<StatusCardProps>> = (
    {
        children, 
        handleClick,
        status,
        statusColor,
        disableColor,
        className,
        direction = 'column'
    }) => {
        const color = statusColor || status2Color(disableColor ? 'NONE' : status);

        return (
            <div 
                className={cn(styles.container, className)} 
                style={{borderTop: `3px solid ${color}`}}
                onClick={handleClick}>
                <span 
                    className={styles.status} 
                    style={status ? {backgroundColor: color} : {}}>
                        {status || ' '}
                </span>
                <div style={{display: 'flex', flexDirection: direction}}>
                    {children}
                </div>
                
            </div>
        );
};

export default StatusCard;
