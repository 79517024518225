import React from 'react';
import { ProtectedRoute, push } from '../utils';
import DependantsPage from '../../DependantsPage';
// import AdditionMobile from 'pages/mobile/domain/careCircle/Addition';
// import AdditionDesktop from 'pages/desktop/domain/careCircle/Addition';
import {CareCircleHome, DependantCreating, DependantEditing} from 'pages/careCircle/';

const go = {
  dependants: () => push('/careCircle'),
  addDependant: () => {push('/new-dependant')},
  editDependant: id => push(`/dependant/${id}`),
  removeDependant: id => push({
    pathname: '/careCircle',
    search: `?id=${id}`
  }), 
  addDependent: (firstName, lastName, dateOfBirth, phone, sessionId) => 
    push(
      {
        pathname: '/new-dependant', 
        search: `?firstName=${firstName}&lastName=${lastName}&dateOfBirth=${dateOfBirth}&phone=${phone}&sessionId=${sessionId}`
      })
};

const DependantsRoutes = [
  <ProtectedRoute
    key="/careCircle"
    path="/careCircle"
    component={CareCircleHome}
  />,
  <ProtectedRoute
    key="/new-dependant"
    path="/new-dependant"
    component={DependantCreating} 
  />,
  <ProtectedRoute
    key="/dependant/:id"
    path="/dependant/:id"
    component={DependantEditing}
  />,
];

export default DependantsRoutes;
export { go };
