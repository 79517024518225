import React from 'react';
import styles from './PopupData.module.scss';
import {ReactComponent as Privacy} from 'svgs/privacy.svg';
import {ReactComponent as Lock} from 'svgs/blueLock.svg';
import { go } from 'routes';
import { sendPrivacySettingsAPI } from 'store/mobx/identityStore/identityStore.api';
import { generalRequest } from 'libs/newHttp';
import { PopupModel } from 'components/shared/common/AlterablePopup/Template';

interface ProfileVO {
  IdentitySetupPopup: string;
  LatestPracticeId: string;
  FirstName: string;
  LastName: string;
  handleSure: () => void;
  handleSame: () => void;
}

const surePopup = (profile: ProfileVO) => ({
  title: 'Are you sure?',
  content: <span className={styles.content}>A PIN code & privacy view provides the following benefits:</span>,
  listData: [
    {
      title: 'Security', 
      content: 'An added level of protection for your data'
    },
    {
      title: 'Added security for your family',
      content: 'Extra protection for your family’s health information'
    },
    {
      title: 'Privacy',
      content: 'Prevent others in your care circle from accessing your private health data'
    }
  ],
  buttonData: [
    {
      text: <span>I'm sure, skip <br/><span className={styles.note}>(use default privacy setting)</span></span>,
      handleClick: profile.handleSure,
      flavour: 'solid'
    },
    {
      text: 'Set up now',
      handleClick: () => { go.identityCompleteFlow(); },
      flavour: 'plain'          
    }
  ]
});

export const Activity2PopupsSet: (profile: ProfileVO, touch?: boolean) => Record<string, PopupModel[]> = 
(profile: ProfileVO, touch?: boolean) =>(
{
  'Master': [
    {
      icon: <div className={styles.iconContainer}><Privacy /> <Lock /></div>,
      title: 'Privacy & Security',
      content: 
        <div className={styles.content}>
          <p>Your profile is nearly set up!</p> 
          <p>Would you like to complete your privacy and security settings? It's super quick and easy.</p>
        </div>,
      buttonData: [
        {
          text: 'Set up now',
          flavour: 'solid',
          handleClick: () => { go.identityCompleteFlow() }
        },
        {
          text: 'Set up later',
          flavour: 'plain',
        }
      ]
    },
    surePopup(profile)
  ] as PopupModel[],
  'MasterWithAdultDependant': [
      {
        icon: <div className={styles.iconContainer}><Privacy /> <Lock /></div>,
        title: 'Privacy & Security',
        content:
          <div className={styles.content}>
            <p>Someone has been added to your Care Circle - but you haven't set up a PIN.</p> 
            <p className={styles.highlight}>Would you like to set one up now?</p> 
            <p>Without a PIN everyone in your Circle can access your health data and payment details.</p>
          </div>,
        buttonData: [
          {
            text: touch ? 'Set up PIN now' : 'Set up PIN/Privacy Now',
            flavour: 'solid',
            handleClick: () => { go.identityCompleteFlow(); }
          },
          {
            text: 'Set up later',
            flavour: 'plain'
          }
        ]
      },
      surePopup(profile)
  ] as PopupModel[],
  'AdultDependant': [
    {
      icon: <div className={styles.iconContainer}><Privacy /> <Lock /></div>,
      title: 'Privacy & Security',
      content:
      <span className={styles.content}>
        Should we use the same PIN code and privacy settings for&nbsp; 
        <span className={styles.highlight}>{`${profile.FirstName} ${profile.LastName}`}</span>
      </span>,
      buttonData: [
        {
          text: 'Yes, use the same',
          handleClick: profile.handleSame,
          flavour: 'solid'
        },
        {
          text: 'No, set up new',
          handleClick: () => { go.identityCompleteFlow(); },
          flavour: 'hollow'
        },
        {
          text: 'Set up later',
          flavour: 'plain'
        }
      ]
    },
    surePopup(profile)
  ] as PopupModel[],
  'PinSetup': [
    {
      icon: <div className={styles.iconContainer}><Privacy /> <Lock /></div>,
      title: 'Privacy & Security',
      content:
        <div className={styles.content}>
          <p>{touch ? '' : 'It is recommended to setup extra pin code and set privacy for your new member.'}</p> 
          <p className={styles.highlight}>Would you like to set { touch ? 'up a PIN code' : 'one up now'}?</p> 
          <p>Without a PIN everyone in your Circle can access your health data and payment details.</p>
        </div>,
      buttonData: [
        {
          text: touch ? 'Set up PIN now' : 'Set up PIN/Privacy Now',
          flavour: 'solid',
          handleClick: () => { go.identityCompleteFlow(); }
        },
        {
          text: touch ? 'Set up later' : 'Set up later, not now',
          flavour: touch ? 'hollow' : 'plain'
        }
      ]
    },
    surePopup(profile)
  ] as PopupModel[]
});