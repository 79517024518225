import 'react';
import Axios, { AxiosError, AxiosResponse } from "axios";
import { HttpStatusCode } from 'common/constants';
import cache from 'services/cache';

const axios = Axios.create(
  {
      baseURL: '/api',
  }
);

axios.defaults.headers.common['Accept'] = 'application/json';

axios.interceptors.request.use(
  async (config) => {
      const token = cache.get('token');
      if (token) {
        config.headers = {
            ...config.headers,
            authorization: `Bearer ${JSON.parse(token).token}`
        };
      }
      
      return config;
    },
    (error) => Promise.reject(error),
);

export interface AxiosResult<TResponse> {
  data?: TResponse;
  error?: AxiosError<TResponse, any> | null;
  loading: boolean;
}

export interface AxiosResponseResult<TResponse> {
  status: number,
  data?: TResponse
}

export const callApi = async<T>(request: Promise<AxiosResponse<T, any>>) => {
  try{
      const {data, status} = await request;
      return {
          data, 
          status
      }
  }
  catch(err){
      const response = (err as AxiosError).response;
      const status = response?.status;
      return {
          status: status ?? HttpStatusCode.UNKNOWN,
          data: response?.data
      }
  }
}

export default axios;