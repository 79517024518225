import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { ReactComponent as Calendar } from '../../../svgs/mainCalendar.svg';
import styles from './pastAppointment.module.css';
import { appointmentPropType } from '../../propTypes/index';

const PastAppointment = (appt, onClick) => (
  <div key={appt.Id} className={styles.container} onClick={onClick(appt.Id)}>
    <div className={styles.imgContainer}>
      <Calendar className={styles.image} />
    </div>
    <div className={styles.detailsContainer}>
      <div className={styles.appt}>
        {`${appt.ProviderName} - ${moment(appt.Time).format('DD/MM/YY')}`}
      </div>
      <div className={styles.bookedFor}>
        {`Booked for: ${appt.FirstName} ${appt.LastName} at ${
          appt.PracticeName
        }`}
      </div>
    </div>
  </div>
);

PastAppointment.propTypes = {
  onClick: PropTypes.func.isRequired,
  appt: appointmentPropType.isRequired,
};

export default PastAppointment;
