import { observable, action, makeAutoObservable } from 'mobx';
import { IAllPractices, IUserPractices, IDetailedPractice, IPractice } from './practiceStore.type';
import { generalRequest, LOADING_STATUS, LoadingStatusType } from '../../../libs/newHttp';
import { getAllPracticesAPI, unlinkPracticeAPI, getUserPracticesAPI, getPracticeDetailsAPI, linkPracticeAPI } from './practiceStore.api';
import alert from 'libs/Alert';

export class PracticeStore {
    constructor(){
        makeAutoObservable(this);
    }
    @observable allPracticesLoadingStatus = LOADING_STATUS.INITIAL;
    @observable practiceDetailsLoadingStatus = LOADING_STATUS.INITIAL;
    @observable refreshList = false;
    @observable loading = false;
    @observable receivedAllPractices = false;
    @observable errorText = "";
    @observable noOfLinkedPractices: number = 0;
    @observable receivedSelectedPractice = false;
    @observable practices: IAllPractices = {
        Practices: []
    }
    @observable userPractices: IUserPractices = {
        Practices: []
    }
    @observable selectedPractice: IDetailedPractice = {
        Id: "00000000-0000-0000-0000-000000000000",
        PracticeId: "00000000-0000-0000-0000-000000000000",
        Name: "",
        Address: "",
        Description: "",
        Doctors: [
          {
            PmsUserId: "00000000-0000-0000-0000-000000000000",
            Type: "",
            Name: "",
            Picture: "",
            UrlName: ""
          }
        ],
        OpeningHours: {},
        Phone: "",
        Email: "",
        Fax: "",
        UrlName: "",
        Specialties: [],
        Language: [],
        Pictures: [],
        IsOnline: false,
        MaxDays: 0,
        HourNote: "",
        CancellationNote: "",
        AllowsOnlineBooking: false,
        AllowsBookingBy: 1,
        AllowsOnlineScripts: false,
        AllowsScriptsBy: 1,
        ModuleVisibility: [],
    }

    @action.bound
    async getUserPractices(onSuccess?: (userPractices: []) => void) {
        this.loading = true;
        this.refreshList = false;
        await generalRequest.bind(this)({
            api: getUserPracticesAPI,
            afterFailed: (err: any) => {
                this.loading = false;
                this.errorText = err.response.data.Message;
            },
            afterLoaded: (data: any) => {
                this.loading = false;
                this.userPractices.Practices = data;
                this.noOfLinkedPractices = data.length;
                if (onSuccess) onSuccess(data);
            }
        })
    }

    @action.bound
    async getPracticeDetails(orgID: string, onSuccess?: (practice: IDetailedPractice) => void) {
        //if (this.isLoaded(this.practiceDetailsLoadingStatus)) return;
        this.loading = true;
        await generalRequest.bind(this)({
            loadingStatusName: 'practiceDetailsLoadingStatus',
            api: getPracticeDetailsAPI({ 
                orgID: orgID 
            }),
            dataPropertyName: 'selectedPractice',
            afterFailed: (err: any) => {
                this.errorText = err.response.data.Message;
                this.loading = false
            },
            afterLoaded: (data: any) => {
                this.loading = false;
                this.selectedPractice = data;
                this.receivedSelectedPractice = true;
                if (onSuccess) {
                    onSuccess(data);
                }
            }
        })
    }

    @action.bound
    async getAllPractices(onSuccess?: (allPractices: []) => void) {
        if (this.isLoaded(this.allPracticesLoadingStatus)) return;
        this.loading = true;
        await generalRequest.bind(this)({
            loadingStatusName: 'allPracticesLoadingStatus',
            api: getAllPracticesAPI,
            dataPropertyName: 'practices',
            afterFailed: (err: any) => {
                this.receivedAllPractices = false;
                this.loading = false;
                this.errorText = err.response.data.Message;
            },
            afterLoaded: (data: any) => {
                this.receivedAllPractices = true;
                this.loading = false;
                this.practices.Practices = data;
                if (onSuccess) onSuccess(data);
            }
        })
    }

    private isLoaded(status: LoadingStatusType) {
        return status === LOADING_STATUS.LOADED;
      }

    @action.bound
    async linkPractice(PracticeId: string, onSuccess: () => void) {
        this.loading = true;
        this.refreshList = false;
        await generalRequest.bind(this)({
            api: linkPracticeAPI(PracticeId),
            afterLoaded: (data) => {
                this.loading = false;
                this.refreshList = true;
                alert.success(data);
                onSuccess();
            },
            afterFailed: (err: any) => {
                this.loading = false;
                this.refreshList = false;
                alert.error(err.response.data.Message);
            }
        })
    }
    @action.bound
    async unlinkPractice(PracticeId: IPractice, onSuccess?: () => void) {
        this.loading = true;
        this.refreshList = false;
        await generalRequest.bind(this)({
            api: unlinkPracticeAPI(PracticeId),
            afterLoaded: (data) => {
                this.loading = false;
                this.refreshList = true;
                alert.success(data);
                if (onSuccess) {
                    onSuccess();
                }
            },
            afterFailed: (err: any) => {
                this.loading = false;
                this.refreshList = false;
                alert.error(err.response.data.Message);
            }
        })
    }
}