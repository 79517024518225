export const colors = {
  red: '#ff6a65',
  primary: '#07a3c8',
  pelorous50: '#07a3c880',
  pelorous25: ' #07a3c840',
  bluechill: '#177d91',
  turquoise: '#33cccc',
  bluelagoon: '#0e6171',

  green: '#23c373',
  ufogreen: '#4cd964',

  gold: '#fac052',

  white: '#fff',
  white90: '#ffffffe6',
  white75: '#ffffffbf',
  white70: '#ffffffb3',
  white50: '#ffffff80',
  white40: '#ffffff66',
  white25: '#ffffff40',
  white10: '#ffffff1a',

  grey5: '#585858',
  grey4: '#939393',
  grey550: '#58585880',
  grey3: '#afafaf',
  grey2: '#dadada',
  grey1: '#f9f9f9',

  greyLine: '#f0f0f0',

  boxShadow16: '#00000029',
  boxShadow21: '#00000036',
  black50: '#00000080',
  black70: '#000000b3',
  black80: '#000000cc',

  headerGradient: 'lineargradient(to right, #3cb9c0, #07a3c8)',
  desktopHeaderGradient: 'lineargradient(to bottom, #3cb9c0, #07a3c8)',
  topBottomGradient: 'lineargradient(to bottom, #3cb9c0, #07a3c8)',
  fadeGradient: 'lineargradient(to bottom, #07a3c800, #07a3c8ff)',
  backgroundGradientFaded: `lineargradient(to top right,#3cb9c0e5,
#07a3c8e5)`,
  buttonGradient: 'lineargradient(to right, #ffb58d, #f58f8f)',
};

export const DEFAULT_COLORS = { ...colors };
