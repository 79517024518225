import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { IClinicNote } from '../../../../store/mobx/myHealthStore/myHealthStore.type';
import BottleSVG from '../../../../svgs/note.svg';
import { BaseListItem } from '../../../../MyHealthPage/components/BaseListItem';
import { getPHRDate } from '../../../../libs/newHttp';
import { go } from '../../../../routes';

export const ClinicalNoteItem: React.FC<IClinicNote> = observer(
  ({ Id, IsNewInPastMonth, ProviderName, ConsultType, DateTime }) => {
    const onClick = useCallback(() => {
      go.goClinicNote(Id);
    }, [Id]);

    return (
      <BaseListItem
        isNew={IsNewInPastMonth}
        textWhenNew="Given within last 3 months"
        icon={BottleSVG}
        leftFirstLine={ProviderName}
        leftSecondLine={ConsultType}
        leftThirdLine={''}
        rightFirstLine={getPHRDate(DateTime)}
        onClick={onClick}
      />
    );
  },
);
