import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions as cancelApptActions } from '../../../components/Deleter/actions/cancelAppointmentActions';
import { actions as deleterActions } from '../../../components/Deleter/actions/actions';
import Switcheroo from '../../../components/Switcheroo/index';
import Reason from '../../../components/Deleter/desktopComponents/Reason/index';
import Details from '../../../components/Deleter/desktopComponents/Details/index';
import CancelPolicy from '../../../components/Deleter/components/CancelPolicy/index';
import CancelNotAllowed from '../../../components/Deleter/components/CancelNotAllowed/index';
import Checkout from '../../../components/Deleter/components/Checkout/index';
import styles from './cancelAppointment.module.css';
import { appointmentPropType } from '../../propTypes/index';

class CancelAppointment extends Component {
  static propTypes = {
    cancelAppointment: PropTypes.func.isRequired,
    checkAbleToCancel: PropTypes.func.isRequired,
    clearCancelCheckState: PropTypes.func.isRequired,
    toggleCancel: PropTypes.func.isRequired,
    setReason: PropTypes.func.isRequired,
    reason: PropTypes.string,
    hasReason: PropTypes.bool,
    appointmentIsBusy: PropTypes.bool,
    appointmentError: PropTypes.bool,
    appointmentCancelled: PropTypes.bool,
    isAbleToCancel: PropTypes.bool,
    isNotAbleToCancel: PropTypes.bool,
    appointment: appointmentPropType,
    cancelData: PropTypes.shape({
      Name: PropTypes.string,
      Phone: PropTypes.string,
      CancellationNote: PropTypes.string,
      CancellationHour: PropTypes.number,
    }).isRequired,
  };
  state = {
    redReason: false,
    showCheckout: false,
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isAbleToCancel) {
      this.handleSwitcheroo(3, 2)();
      this.props.clearCancelCheckState();
    }
    if (nextProps.isNotAbleToCancel) {
      this.handleSwitcheroo(4, 2)();
      this.props.clearCancelCheckState();
    }
  }
  setRed = bool => {
    this.setState({ redReason: bool });
  };
  toggleCheckout = () => {
    this.setState({ showCheckout: !this.state.showCheckout });
  };
  handleReasonClick = () => {
    if (this.props.hasReason) {
      this.props.checkAbleToCancel(
        this.props.appointment.SessionId,
        this.props.reason,
      );
    } else {
      this.setRed(true);
    }
  };
  handleCancel = () => {
    this.props.cancelAppointment(
      this.props.appointment.SessionId,
      this.props.reason,
    );
    this.toggleCheckout();
  };
  handleSwitcheroo = (next, current) => () => {
    if (this.switcheroo) {
      this.switcheroo.setItemToShow(next, current);
    }
  };
  render() {
    const {
      appointment,
      toggleCancel,
      setReason,
      hasReason,
      cancelData,
      appointmentCancelled,
      appointmentError,
      appointmentIsBusy,
    } = this.props;
    const { redReason } = this.state;
    return (
      <div className={styles.container}>
        {this.state.showCheckout ? (
          <Checkout
            confirmed={appointmentCancelled}
            error={appointmentError}
            isBusy={appointmentIsBusy}
            toggleCheckout={this.toggleCheckout}
          />
        ) : (
          <Switcheroo
            ref={c => {
              this.switcheroo = c;
            }}
            firstItem={
              <Details
                appointment={appointment}
                cancel={this.handleSwitcheroo(2, 1)}
                close={toggleCancel}
              />
            }
            secondItem={
              <Reason
                setRed={this.setRed}
                red={redReason}
                setReason={setReason}
                handleClick={this.handleReasonClick}
                hasReason={hasReason}
                cancel={toggleCancel}
              />
            }
            thirdItem={
              <CancelPolicy
                practiceName={appointment.PracticeName}
                note={cancelData.CancellationNote}
                cancelFn={this.handleCancel}
                togglePolicy={() => () => toggleCancel()}
              />
            }
            fourthItem={
              <CancelNotAllowed data={cancelData} toggleError={toggleCancel} />
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  reason: state.deletionReducer.reason,
  hasReason: true,
  appointmentCancelled: state.confirmCancellationReducer.cancelled,
  appointmentIsBusy: state.confirmCancellationReducer.isBusy,
  appointmentError:
    state.confirmCancellationReducer.error ||
    state.submitCancellationReducer.error,
  appointmentCanceled: state.confirmCancellationReducer.cancelled,
  isAbleToCancel: state.submitCancellationReducer.ableToCancel,
  isNotAbleToCancel: state.submitCancellationReducer.ableToCancelError,
  cancelData: state.submitCancellationReducer.cancelData,
});
const mapDispatchToProps = dispatch => ({
  cancelAppointment: bindActionCreators(
    cancelApptActions.cancelAppointment,
    dispatch,
  ),
  checkAbleToCancel: bindActionCreators(
    cancelApptActions.checkAbleToCancel,
    dispatch,
  ),
  clearCancelState: bindActionCreators(
    cancelApptActions.clearConfirmCancelState,
    dispatch,
  ),
  clearCancelCheckState: bindActionCreators(
    cancelApptActions.clearCancelCheckState,
    dispatch,
  ),
  setReason: bindActionCreators(deleterActions.setReason, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CancelAppointment);
export { styles };
