import { call, put, takeEvery } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/checkUsernameActions';
import HttpLib from '../../../libs/Http';
import Alert from '../../../libs/Alert';

const CheckParserHttp = HttpLib(
  ({ status }) => {
    if (status === 409) {
      return {
        error: 'Email already exists',
        status,
        shouldAlert: false,
      };
    }
    return {
      error: 'Unexpected Error Occurred. Please Try Again.',
      shouldAlert: true,
    };
  },
  err => {
    if (err.shouldAlert) {
      Alert.error(err.error);
    }
  },
);
const api = email => CheckParserHttp.get(`consumer/username/${email}/check`);

function* checkUsername({ payload: { email } }) {
  try {
    yield call(api, email);
    yield put(actions.checkUsernameSuccess());
  } catch (err) {
    yield put(actions.checkUsernameFailure(err));
    if (err.status === 409) {
      yield put(actions.setUsedEmail(email));
    }
  }
}

function* checkUsernameSaga() {
  yield takeEvery(actionTypes.checkUsername, checkUsername);
}

export { checkUsernameSaga };
