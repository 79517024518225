import React from 'react';
import { observer } from 'mobx-react-lite';

import * as Styled from './PinCodePage.styled';
import UIContainer from '../components/UIContainer';
import { ReactComponent as Lock } from 'svgs/pinLock.svg';
import PinCode from './components/PinCode';
import { getToken, useComponentDidMount } from 'libs/newHttp';
import { useAccountStore } from 'store/mobx/mobx';
import { go } from 'routes';
import { useLocation } from 'react-router-dom';
import { getLoginPageURL } from 'libs/utils';

interface LocationState {
    goNext: number
}

const PinCodePage: React.FC  = observer(({...props}) => {
    const store = useAccountStore();
    const location = useLocation<LocationState>();
    let goNext: number = 0;
    
    if (location.state !== undefined) {
        if (location.state.goNext !== undefined) {
            goNext = location.state.goNext;
        }
    }

    // 0 - Home
    // 1 - Home under PHR summary tab
    const NAVIGATION = ["HOME_MAIN", "HOME_PHR_SUMMARY"];
    
    useComponentDidMount(() => {
        if(!getToken()) {
            go.login();
        } else {
            store.getPinCodeEnabled(() => {
                store.getPinCodeLength();
            })
        }

    })

    const goNextFn = () => {
        const goTo = NAVIGATION[goNext];
        if (goTo) {
            switch(goTo) {
                case "HOME_MAIN": 
                    go.home();
                    return;
                case "HOME_PHR_SUMMARY":
                    go.home(3);
                    return;
                default: go.home();
            }
        } else { go.home(); }
    }
    return (
        <UIContainer heading="Security PIN Code" goBackFn={() => store.logout(() => window.location.href = getLoginPageURL())} isFetching={store.loading}>
            <div style={{height: "calc(100vh - 3rem)"}}>
                <Styled.Container>
                    <Lock/>
                    <Styled.Text>
                        Welcome back to Vensa
                        <br /> 
                        Please enter your {store.pinCodeLength} digit code to login to your portal.
                    </Styled.Text>
                    {
                        <PinCode length={store.pinCodeLength} goNext={goNextFn} />
                    }
                    <Styled.Subtext onClick={go.recoverPin}>
                        Forgot my PIN code
                    </Styled.Subtext>
                </Styled.Container>
            </div>
        </UIContainer>
    );
});


export default PinCodePage;

