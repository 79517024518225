import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Line } from 'svgs/blueUnderline.svg';
import InfoDropdown from '../../../components/InfoDropdown';

const Container = styled.div`
    overflow: visible;
`
const Title = styled.div`
    font-size: 25px;
    color: #6C6C6C;
    text-align: center;
    margin: 0rem 1rem;
`
const Subtitle = styled.div`
    font-size: 17px;
    color: #6C6C6C;
    text-align: center;
    margin: 0.5rem;
`
const Text = styled.div`
    text-align: left;
    font-size: 15px;
    color: #6C6C6C;
    margin: 1rem;
`
const FlexH = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`
const Content = styled.div`
    width: 100%;
`

const EnglishConsent: React.FC = observer(() => {
    return (
        <Container>
                <Title>Would you like your lab results sent automatically?</Title>
                <Subtitle>(Expand to view)</Subtitle>
                <Line style={{display: "block", margin: "1rem auto"}} />
                <Text>
                    Study Title: Analysis of Laboratory Result Patterns for automated informed patient notification and filing
                    <br /><br />
                    <FlexH>
                        <Content>Locality: Auckland</Content>
                        <Content>Sponsor: Vensa</Content>
                    </FlexH>
                    <br />
                    <FlexH>
                        <Content>Lead Investigator: <br /> Samuel Wong  </Content>
                        <Content>Contact phone number: <br /> 0800 228 946</Content>
                    </FlexH>
                </Text>
                <InfoDropdown 
                    title={"Participant Information Sheet"}
                    subtitle={""}
                    text={"You have been invited to take part in a study about improving how your doctor provides you with test results.\n\n" + 
                    "It is completely up to you if you want to take part in this study or not. If you don’t wish to be involved,you do not need to give a reason why. The care you receive from your doctor will not be affected, either way. You are also free to leavethe study at any time.\n\n" +
                    "This Participant Information Sheet will help you decide if you’d like to take part. It introduces the study, what your participation would involve, potential benefits and risks to you, and what happens at the completion of the study. \n\n" +
                    "We will discuss all this with you and answer any questions you may have. You do not have to decide today whether or not you will participate in this study. Before you decide,feel free to talk  about  the  study  with  other  people,  such  as  family,  whānau,  friends,  or  healthcare providers.\n\n" +
                    "If you agree to take part in this study, you will be asked to sign the Consent Form found on the final page of this document. You will be given a copy of both the Participant Information Sheet and the Consent Form to keep.\n\n"
                }
                />
                <InfoDropdown 
                    title={"Introducing This Study"}
                    subtitle={"What Happens with My Test Results Already?"}
                    text={"When you have a blood or other test, the results come back from the laboratory to your doctor. The doctor has to go through each test result and decide what to do, and add any comments for you to read about them. That takes a lot of time each day.\n\nIf you have a patient portal, you may be able to see the test results there after the doctor has checked them. Otherwise you might call or email the clinic - or you may have been told you would hear nothing from them if the results were ‘normal’. Sometimes you can see the test result but there is not enough other information to know what it means on your own."}
                />
                <InfoDropdown 
                    title={"Introducing This Study"}
                    subtitle={"How Could We Improve That?"}
                    text={"Your doctor already uses a computer system to hold your medical information. If we can use that information to help check the test results better, we could free up your doctor’s time, add more useful comments, and help save New Zealand’s health system resources. You would also see most of your test results sooner.\n\nTo make sure it works properly in terms of quality and efficiency, your doctor is working with us on a study. This may eventually help us develop ‘algorithms’ that can personalise the safe and efficient delivery of normal test results with appropriate explanations for each patient."}
                />
                <InfoDropdown 
                    title={"Introducing This Study"}
                    subtitle={"Who Is Doing the Study?"}
                    text={"Several doctors’ clinics are working with a New Zealand company called Vensa Health that already provides ‘portal’ systems that patients can use for repeat prescriptions, appointment bookings and other things.\n\nThis research is supported by funding from Precision Driven Health, which is a public-private partnership between Orion Health, the University of Auckland, Ministry of Business Innovation and Employment and the Waitemata District Health Board.\n\nThe study is registered with the HDEC Northern A Ethics Committee and has clinical safeguards in its design including oversight by senior doctors."}
                />
                <InfoDropdown 
                    title={"Taking Part in this Study"}
                    subtitle={"Why Does My Doctor Think I May Be Suitable for the Study?"}
                    text={"We are looking for people to take part who have relatively straightforward health needs, so that the study is kept as safe as possible. People who are pregnant, under 16 years of age, or have certain risks determined by your doctor are excluded from participating.\n\nYou will be asked to express interest to participate in the study if your doctor believes you need (or may usually need) one of the eligible tests."}
                />
                <InfoDropdown 
                    title={"Taking Part in this Study"}
                    subtitle={"What Tests are Included"}
                    text={"The study includes only a handful of standard and ‘low-risk’ blood tests. Your doctor may know that you need one or more of those tests in the next few weeks:\n\n" +
                    "•	HbA1c (for assessment of long term diabetes control)\n" + 
                    "•	Thyroid Stimulating Hormone (for assessment of thyroid function and hormone levels)\n" +
                    "•	Creatinine (for kidney function testing)\n" +
                    "•	Cholesterol (for lipid management to measure cardiovascular risk)\n" +
                    "•	Ferritin (as part of iron panel tests to determine iron storage levels)\n\n" +
                    "Any of those tests that your doctor orders as part of your usual care would be included in the study. The study only examines the test result information, not the blood samples themselves."}
                />
                <InfoDropdown 
                    title={"Taking Part in this Study"}
                    subtitle={"How to Sign Up"}
                    text={"Your doctor has introduced you to this study based on your eligibility, or you may have seen the ads at the practice or portal and registered your interest.\n\nWhen a test is ordered for you, your doctor will email you this Participant Information Sheet and a TXT/SMS reminder a day later. You will have 48 hours after the email to digitally tick the consent form before your test result is expected to arrive back to your doctor."}
                />
                <InfoDropdown
                    title={"Taking Part in this Study"}
                    subtitle={"How is the Study Arranged"}
                    text={"The study is expected to run for 15 months, to understand several different ‘objectives’. You will be sent another copy of this information sheet and asked to consent to take part in each Objective as they come up, and you can say no at any time. The intervention of this study involves how you receive your test results.\n\n" +
                    "Objective One looks at understanding the effectiveness of providing different types of advice to patients. This will involve you answering questions on how you have received your normal results and the advice provided.\n\n" +
                    "Objective Two looks at understanding factors that drives test requests. The study will look at reasons why you needed to visit your doctor, and your doctor’sclinical reason to order tests for you. \n\n" +
                    "Objective  Three looks  at  understanding  clinical  factors ininterpretation  of  the  laboratory results and personalising what isnormal for you. This objective will involve using information in  your  health  record  that  directly  affects test results -such  as  your  current  conditions, medications, and previous test results-to determine if your latest results are within expected normal range. Other questions such as family history of certain heart, liver,or kidney diseases; diet, and lifestyle questions may also be asked due to their potential influence on test results. \n\n" +
                    "Sometimes a test does not end up being ordered when it usually would have been expected for  a  particular  condition  or circumstance–for  example,if  the  same  type  of  test  has  been done recently through a specialist or hospitalinstead. The study also wants to understand thissituation, so you may be added to a ‘comparison group’ of people like you, even if you do not get a result.\n\n" +
                    "Together, having the different objectives and comparison groups helpsus learn and analysea whole range ofpatterns and preferences.\n\n" +
                    "If you have any questions, please feel free to contact the research team"
                }
                />
                <InfoDropdown
                    title={"Taking Part in this Study"}
                    subtitle={"What Would I Need to Do During the Study?"}
                    text={"You will need to read the study information and give your consent to participate in this study. This will be done through your patient portal.\n\n" +
                    "When your test results become available, you will be asked some simple questions about the information provided to you. It should take around two minutes.\n\n" + 
                    "During this study, test results will be released to participants accompanied by information, either:\n" +
                    "•  Like your doctor usually does, using their usual process, or\n" +
                    "•  Through  your  online  portal,  with personalised advice  about  the  results  from  your doctor, or\n" +
                    "•  Through your online portal, with comments containing standard advice given to other people who get the same results and some background information on the tests and your specific conditions.\n\n" +
                    "If  you  participate  in  more  than  one Objective  of  this  study  over  the  15  months,  you will be allocated into a different ‘advice group’ to compare your experience."
                }
                />
                <InfoDropdown
                    title={"Taking Part in this Study"}
                    subtitle={"What About Any Abnormal Test Results"}
                    text={"During the study, if any test result is abnormal and needs attention, your doctor will be alerted as soon as the laboratory releases it. For any abnormal results that do not require immediate action, you will receive detailed information about your options."}
                />
                <InfoDropdown
                    title={"Taking Part in this Study"}
                    subtitle={"Who Sees My Details?"}
                    text={"If you take part in the study, your doctor stays in charge of all your tests and results.\n\n" +
                    "Vensa’s study team will work witha copy of that information, with each person’s name and contact details removed. Only specialised team members who are currently working with your doctor on providing the portal will continue to access your information. At no point does the study team see identifiable information.\n\n" +
                    "The study complies with all standards and protections on working with health information. This include Vensa’s platform adhering to the  government’s  ‘Health   Information   Security Framework’ standardsand  hosting  information  in  approved  health  information-compliant servers in Australia. \n\n" +
                    "All your details will still be kept in your doctor’s own system and you can ask to see them at any  time. Everything  you  add  as  part  of  the  study  will be  available  for  you  to  see  on  your patient portal. Any change or correction requests can be completed through the patient portal.\n\n" +
                    "If  you  decide  to  leave  the  study  before  it  is  finished, you  may  consent  for  us  to  use your information up until you withdraw, or it may be excluded entirely from the research database. Information will still be present with your GP."
                }
                />
                <InfoDropdown
                    title={"Taking Part in this Study"}
                    subtitle={"When the Study is Finished"}
                    text={"When the study is over, your doctor might return to their previous way of managing test results. If the study is successful, they may have the option of using the sort of process developed during the study.\n\n" +
                    "The study data that is part of your individual health records legally has to be kept for at least 10 years."
                }
                />
                <InfoDropdown
                    title={"Weighing Up Whether to Take Part"}
                    subtitle={"Potential Benefits"}
                    text={"The  care  you  receive  from  your  doctor  or  the  rest  of  the  health  system will  not  be directly affected  if  you  decide  to  take  part  in  the  study. You  may  personally  experience more informativetest results during the study, but that is not guaranteed.\n\n" +
                    "Knowing  that  you  are contributing  to  improving  our  health  system for everyone is  a  reason you might want to be part of the study."
                }
                />
                <InfoDropdown
                    title={"Weighing Up Whether to Take Part"}
                    subtitle={"Payments and Costs"}
                    text={"The are no payments or extra costs for people who take part. Your doctor's usual fees still apply.\n\nThe GP clinics involved in this study have been provided some subsidies to cover telecommunication costs for contacting you."}
                />
                <InfoDropdown
                    title={"Weighing Up Whether to Take Part"}
                    subtitle={"Potential Risks"}
                    text={"We have carefully designed the study to minimise the chance of things going wrong. However, there may still be some risksif you take part:\n\n" +
                    "•  You  may  not  fully  understand  the  advice  given  to  you  with  your  test  results.  If  this happens,you can contact your doctor in the usual way to discuss anything you are not sure about. You can also give us feedback on what you found unclear and why. Your doctor   may   change   the   advice   you   receive   in   the   future   to   make   it   more understandable for you.\n" +
                    "•  You might be told that a test is normal, when it is actually abnormal and needs medical attention.  This couldlead  to  a  delay  in  treatment.  We  will  take  all  steps  possible  to prevent this from happening. Before you enter the study,your doctorwill discuss this with youand work out which test results should be considered “normal” or “abnormal” for you personally. For the study, your doctor will decide exactly what results can be released to you automatically and what test results need to be reviewed by a medical professional. This process will be constantly monitored and reviewed by the study’s lead clinical supervisor.\n" +
                    "•  Your data privacy may be breached if someone gains access to it illegally. We have put rigorous safety measures in place to prevent this. In particular,Vensa uses secure computing services which comply with the Ministry of Health’s ‘Health Information Security Framework’ standards -this means your data is kept safe. The copy of your information used in the study only has the local patient ID codethat your clinic uses. Your name, date of birth, and your national health identity number (NHI) are deleted. Only  structured  information  (such  as  codes  used  to  describe  your  problem/themes) are  used  by  the  study  team.  No  clinical  notes  or  free  text  descriptions  from  your appointment  and  consultation  will  be  available. During  this  study,  if  the study team needs to discuss with your doctor, they will be discussing using a case event ID.\n\n" +
                    "The study team will respond to any other risks that arise. Your safety and privacy is important. During the study, your doctor will look after your interests as usual.\n\n" + 
                    "All your rights under the Code of Health and Disability Services Consumers' Rights will apply during the study."
                }
                />
                <InfoDropdown
                    title={"Weighing Up Whether to Take Part"}
                    subtitle={"Purely Voluntary"}
                    text={"It is up to you whether to take part in this study. Whatever you decide, it will not affect your doctor’s normal care or how you already get test results and advice.\n\n" +
                    "You  may  wish  to  join  for  one  or more  of  the  objectives  each  round  of  test,  postpone  by indicating not now, or decline at any stage where your results will be removed from the study. This can be done by request on the patient portal, and your usual results on the patient portal is not affected."
                }
                />
                <InfoDropdown
                    title={"Who Do I Contact for More Information or If I Have Concerns"}
                    subtitle={""}
                    text={"If  you  have  any  questions,  concerns  or  complaints  about  the  study  at  any  stage,  you  can contact: \n\n" +
                    "Samuel Wong, Principal co-investigator\n" +
                    "0800 228946\n" +
                    "feedback@vensa.com\n\n" +
                    "If you feel you need to raise any clinical concernsor Māori health supportwith the study’s clinical research co-investigator, Dr Tane Taylor, please contact Samuel Wong to arrange a discussion." 
                }
                />
                <InfoDropdown
                    title={"Who Do I Contact for More Information or If I Have Concerns"}
                    subtitle={""}
                    text={"If you want to talk to someone who isn't involved with the study, you can contact an independent health and disability advocate.\n\n" +
                    "Phone: 0800 555 050\n" +
                    "Fax: 0800 2 SUPPORT (0800 2787 7678)\n" +
                    "Email: advocacy@advocacy.org.nz\n" +
                    "Website: https://www.advocacy.org.nz\n\n" +
                    "You can also contact the health and disability ethics committee(HDEC) that approved this study.\n\n" +
                    "Phone:0800 4 ETHICS\n" +
                    "Email:hdecs@moh.govt.nz"
                }
                />
                <InfoDropdown
                    title={"By clicking 'I Agree' you accept the following:"}
                    subtitle={""}
                    text={"•    I have read or have had read to me in my first language, and I understand the Participant Information Sheet.\n\n" +
                    "•    I have been given sufficient time to consider whether to participate in this study.\n\n" +
                    "•  I have had the opportunity to use a legal representative, whānau/ family support or a friend to help me ask questions and understand the study.\n" +
                    "•  I understand the compensation provisions in case of injury during the study.\n\n" +
                    "•  I know who to contact if I have any questions about the study in general\n\n" +
                    "•  I understand my responsibilities as a study participant.\n\n" +
                    "•  I understand that taking part in this study is voluntary (my choice) and that I may withdraw from the study at any time without this affecting my medical care.\n\n" +
                    "•  I understand that my participation in this study is confidential and that no material, which could identify me personally, will be used in any reports on this study."
                }
                />
        </Container>
    )
})

export default EnglishConsent;