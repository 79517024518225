import PropTypes from 'prop-types';
import { userPropType } from '../../components/UIContainer/MobileUI/Menu/propTypes';
import { practicePropType } from '../../PracticesPage/propTypes';
import { doctorPropType } from '../RequestScriptMobile/propTypes';

const optionPropType = PropTypes.shape({
  Option: PropTypes.number,
  Price: PropTypes.number,
  Note: PropTypes.string,
  PMSServiceCode: PropTypes.string,
  PaymentMethods: PropTypes.number,
});

const pricingPropType = PropTypes.shape({
  Name: PropTypes.string.isRequired,
  Address: PropTypes.string,
  PharmacyId: PropTypes.string.isRequired,
  Phone: PropTypes.string,
  Fax: PropTypes.string,
  Options: PropTypes.arrayOf(optionPropType),
  IsDefault: PropTypes.bool,
});

const medicationPropType = PropTypes.shape({
  Id: PropTypes.string,
  Name: PropTypes.string.isRequired,
  TradeName: PropTypes.string,
  MedicinalName: PropTypes.string,
  RecentlyRequested: PropTypes.bool,
});

const scriptDetailsPropType = PropTypes.shape({
  patient: userPropType,
  practice: practicePropType,
  doctor: doctorPropType,
  reason: PropTypes.string,
  medications: PropTypes.arrayOf(medicationPropType),
  pricing: pricingPropType,
  repeatPastRequest: PropTypes.bool.isRequired,
});

const cardDetailsPropType = PropTypes.shape({
  Token: PropTypes.string.isRequired,
  Brand: PropTypes.string.isRequired,
  ExpMonth: PropTypes.number.isRequired,
  ExpYear: PropTypes.number.isRequired,
  Last4: PropTypes.string.isRequired,
});

export {
  pricingPropType,
  scriptDetailsPropType,
  cardDetailsPropType,
  medicationPropType,
  optionPropType,
};
