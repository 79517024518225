import { useLocalObservable, useLocalStore, useObserver } from 'mobx-react-lite';
import React from 'react'
import NumberInput from './NumberInput';
import styles from './index.module.scss';
import { FieldProps } from 'hocs/MakeField';
import cn from 'classnames';
import { Editable, KeepFocusProps, Touchable } from 'types';
import { FocusableProps } from 'components/core/FocusWithin';

export type DateFieldProps = FieldProps & Touchable & FocusableProps & KeepFocusProps & Editable;

const DateField: React.FC<DateFieldProps> = (
    {
        id,
        handleChange,
        value,
        touch,
        isFocus,
        focusId,
        doFocus,
        readonly,
        ...rest
    }
) => {
    const currentYear = new Date().getFullYear();
    const leastYear = currentYear - 100;
    const dateArray = value?.split('-');
    const dateStore = useLocalObservable(() => ({
        dayRef: undefined as unknown as HTMLInputElement,
        monthRef: undefined as unknown as HTMLInputElement,
        yearRef: undefined as unknown as HTMLInputElement,
        year: dateArray ? dateArray[0] : '',
        month: dateArray ? dateArray[1] : '',
        day: dateArray ? dateArray[2] : '',
        updateRef: (ref: HTMLInputElement) => {
            switch(ref.id){
                case 'day':
                    dateStore.dayRef = ref;
                    break;
                case 'month':
                    dateStore.monthRef = ref;
                    break;
                case 'year':
                    dateStore.yearRef = ref;
                    break;
            }
            
        },
        handleDate: (name: string, value: string) => {
            // validate
            let num = parseInt(value);
            let finalValue = value;
            switch(name){
                case 'day':
                    if(finalValue.length === 2) {
                        if(num > 31){
                            finalValue = '31';
                        }
                        dateStore.monthRef && dateStore.monthRef.focus();
                    }
                    break;
                case 'month':
                    if(finalValue.length === 2){
                        if(num > 12) {
                            finalValue = '12';
                        }
                        dateStore.yearRef && dateStore.yearRef.focus();
                    }
                    break;
                case 'year':
                    if(finalValue.length >= 4){
                        finalValue = Math.max(leastYear, Math.min(currentYear, num)).toString();
                    }
                    break;
            }
            
            if(name === 'year' || name === 'month' || name === 'day'){
                dateStore[name] = finalValue;
                handleChange && handleChange(id, `${dateStore['year']}-${dateStore['month']}-${dateStore['day']}`);
            }
            
        },
       handleEmpty: (name: string) => {
           switch(name){
               case 'day':
                   break;
                case 'month':
                    dateStore.dayRef && dateStore.dayRef.focus();
                    break;
                case 'year':
                    dateStore.monthRef && dateStore.monthRef.focus();
                    break;
           }
       }
    }), {dayRef: false, monthRef: false, yearRef: false});
  return useObserver(() => (
    <div className={cn(styles.container, {[styles.touch]: touch, [styles.focus]: isFocus})} {...rest}>
        <NumberInput
            touch={touch}
            id='day' 
            placeholder='DD' 
            splitter='/' 
            onNumberChange={dateStore.handleDate}
            onEmpty={dateStore.handleEmpty}
            pattern='^((0?[1-9])|((1|2)[0-9])|30|31)$'
            value={dateStore.day}
            updateRef={dateStore.updateRef}
            focusId={focusId}
            doFocus={doFocus}
            readonly={readonly} />
        <NumberInput
            touch={touch}
            id = 'month' 
            placeholder='MM' 
            splitter='/' 
            onNumberChange={dateStore.handleDate}
            onEmpty={dateStore.handleEmpty}
            pattern='^(0?[1-9]|1[0-2])$'
            value={dateStore.month}
            updateRef={dateStore.updateRef}
            focusId={focusId}
            doFocus={doFocus}
            readonly={readonly} />
        <NumberInput
            touch={touch} 
            id='year' 
            placeholder='YYYY' 
            onNumberChange={dateStore.handleDate}
            onEmpty={dateStore.handleEmpty}
            pattern="^(19|20)\d{2}$"
            value={dateStore.year}
            updateRef={dateStore.updateRef}
            focusId={focusId}
            doFocus={doFocus}
            readonly={readonly} />
    </div>
  ))
}

export default DateField;
