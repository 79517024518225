import { actionTypes } from '../actions/guardianActions';

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  mobile: '',
};

const guardianDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.addDetails: {
      return {
        ...state,
        ...action.payload.details,
      };
    }
    case actionTypes.changePhone: {
      return {
        ...state,
        mobile: action.payload.mobile,
      };
    }
    case actionTypes.clearState: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default guardianDetailsReducer;
