import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { actions as enrollmentActions } from '../PatientSelection/actions/actions';
import EnrolledErrorPopUp from '../ErrorPages/EnrolledErrorPopUp/index';
import DetailsPage from './components/DetailsPage/index';
import GuardianFormPopUp from './components/GuardianFormPopUp/index';
import Header from '../../components/UIContainer/MobileUI/header/index';
import styles from './patientDetailsPage.module.css';
import { isDesktop } from '../../config';
import { go } from '../../routes/index';
import { practiceProfilePropType } from '../../PracticeProfilePage/propTypes/index';
import { errorPropType } from '../propTypes/index';
import { actions as feelingActions } from '../Reason/actions/actions';
import { goBack } from 'routes/utils';

class PatientDetailsPage extends Component {
  static propTypes = {
    isEnrolled: PropTypes.bool.isRequired,
    enrolledError: errorPropType,
    practice: practiceProfilePropType,
    token: PropTypes.string,
    clearState: PropTypes.func.isRequired,
  };
  state = {
    showGuardianPopUp: false,
    showErrorPopUp: false,
    validateForm: false,
  };


  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.enrolledError &&
      nextProps.enrolledError !== this.props.enrolledError
    ) {
      this.setState({ showErrorPopUp: true });
    } else if (nextProps.isEnrolled && !this.props.isEnrolled) {
      if (this.props.practice.ModuleVisibility !== null) {

        let canExpressInterest = (nextProps.patientId !== null && nextProps.patientId !== '');
        let consultationMethods = this.props.practice.ModuleVisibility.includes("Consultation Method");
        let resonForVisit = this.props.practice.ModuleVisibility.includes("Reason For Visit");

        resonForVisit ? go.reason(this.props.match.params.id, consultationMethods)
          : go.reasonOld(this.props.match.params.id);
      }
    }
  }

  toggleMobileGuardian = () => {
    if (this.state.showGuardianPopUp) {
      this.changePage('guardian')();
    }
    this.setState({ showGuardianPopUp: !this.state.showGuardianPopUp });
  };
  hideErrorPopUp = () => {
    this.setState({ showErrorPopUp: false });
    this.props.clearState();
  };
  changePage = type => () => {
    go.patientDetails(this.props.practice.UrlName, type);
  };
  goToBooking = () => {
    go.practiceProfile(this.props.practice.UrlName);
  };
  render() {
    return (
      <div
        className={classnames(styles.patientDetailsPage, {
          [styles.loggedIn]: this.props.token,
        })}
      >
        {!isDesktop() && <Header goBackFn={() => { this.setState({ validateForm: true }); go.patientSelection(this.props.practice.UrlName); }} headerClassNames={styles.header} showHelp />}
        {this.state.showGuardianPopUp && (
          <GuardianFormPopUp hide={this.toggleMobileGuardian} />
        )}
        <DetailsPage
          reasonEnabled={this.props.practice.ModuleVisibility.includes("Reason For Visit")}
          showGuardianPopUp={this.toggleMobileGuardian}
          changePage={this.changePage}
          popUpOpen={this.state.showGuardianPopUp}
          validateForm={this.state.validateForm}
          clearValidateForm={() => this.setState({ validateForm: false })}
          match={this.props.match}
        />
        {this.state.showErrorPopUp && isDesktop() && (
          <EnrolledErrorPopUp
            closePopUp={this.hideErrorPopUp}
            practice={this.props.practice}
            error={this.props.enrolledError}
            goToBooking={this.goToBooking}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.loginReducer.token,
  enrolledError: state.checkEnrollmentReducer.error,
  isEnrolled: state.checkEnrollmentReducer.isEnrolled,
  practice: state.practiceProfileReducer.profile,
  patientId: state.checkEnrollmentReducer.patientId,
});
const mapDispatchToProps = dispatch => ({
  clearState: bindActionCreators(enrollmentActions.clearState, dispatch),
  getReasons: bindActionCreators(feelingActions.getReasons, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PatientDetailsPage);
export { styles };
