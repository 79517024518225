import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import styles from './privacy.module.css'
import UIContainer from '../components/UIContainer';
import PrivacyCard from '../components/PrivacyCard';
import LearnMorePopUp from 'components/LearnMorePopUp/LearnMorePopUp';
import { go } from '../routes';
import { useComponentDidMount } from 'libs/newHttp';
import { useAccountStore } from 'store/mobx/mobx';
import ImgTextCard from 'components/ImgTextCard';

const Privacy = observer(() => {
    const [showPopUp, setShowPopUp] = useState(false);
    const accStore = useAccountStore();

    useComponentDidMount(() => {

        accStore.getIdentityRequestsStatus();
    })
    const togglePopUp = () => {
        setShowPopUp(!showPopUp)
    }

    return(
        <UIContainer heading={"Privacy"} isFetching={accStore.loadingRequests} goBackFn={() => go.settings()}>
            <div className={styles.container} style={{overflow: showPopUp ? "visible" : "scroll"}}>
                <PrivacyCard showPopUp={() => togglePopUp()} />
                {showPopUp && <LearnMorePopUp hide={() => togglePopUp()} type={"PRIVACY_INFO"}/>}
                <div className={styles.title}>YOUR PRIVACY WITH HEALTH PROVIDERS</div>
                {accStore.identityRequests.map((practice) => {
                    if (practice.IdentityRequest || practice.PracticeVerification) {
                        return <ImgTextCard
                            key={practice.OrganisationId}
                            banner={practice.BlackListed}
                            bannerColour={"#FF6A65"}
                            bannerText={"Restricted Access set by Practice"}
                            isUser={false}
                            title={practice.OrganisationName}
                            text={practice.Address === "" ? "No Address Found" : practice.Address}
                            onClick={() => {
                                go.privacySettings(practice.OrganisationId); 
                            }}
                            imageComp={practice.PictureUrl}
                            crop={true}
                            privacyCard={true}
                        />
                    }
                })}
                <p className={styles.text}>Control your privacy and access rights to your data with the facilities you have chosen to affiliate with.</p>
            </div>
        </UIContainer>
    )
    
})

export default Privacy;