import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';
import is from 'is_js'
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { MobxStoreProvider } from './store/mobx/mobx';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { UAParser } from 'ua-parser-js';

import ReactGA from 'react-ga';
import {store} from './store/configureStore';
import Index from './routes';
import './styles/global.css';
import { isEnv } from './libs/corsAPI.constants';
import { ThemeProvider } from './components/vensaComponents';
import UnsupportedBrowser from './EntryPage/UnsupportedBrowser';
import Mobile from './EntryPage/UnsupportedBrowser';
import { isDesktop } from './config';

// // INIT LUCKY ORANGE
const initLuckyOrange = () => {
  const wa = document.createElement('script');
  wa.type = 'text/javascript';
  wa.async = true;
  wa.src = 'https://tools.luckyorange.com/core/lo.js?site-id=90329eb9';
  const s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(wa, s);
};

if (isEnv.production()) {
  // initialze google analytics and lucky orange in production
  ReactGA.initialize('UA-157135978-1');
  // eslint-disable-next-line no-underscore-dangle
  window.__lo_site_id = 231186;
  initLuckyOrange();
} else {
  window.__lo_site_id = 231186;
  initLuckyOrange();
  // initialze google analytics
  ReactGA.initialize('UA-157135978-1');
}


const renderApp = () => {
  const p = new UAParser();
  const root = ReactDOM.createRoot(document.getElementById("root"));
  if (is.chrome() || is.safari() || is.firefox() || is.edge() || p.getBrowser().name === "Chrome") {
    root.render(
      <Provider store={store}>
        <MobxStoreProvider>
          <ThemeProvider>
            <Index />
          </ThemeProvider>
        </MobxStoreProvider>
      </Provider>
    );
  } else if (isDesktop()){
      root.render(
        <UnsupportedBrowser />
    )} else {
      root.render(<Mobile />);
    }
}

if (isEnv.production()) {
  renderApp();
} else {
  renderApp();
  //setTimeout(renderApp, 250);
}
