const actionTypes = {
  addCustomIcon: 'ADD_CUSTOM_ICON_HEADER',
  clearCustomIcon: 'CLEAR_CUSTOM_ICON_HEADER',
  overrideBackArrow: 'OVERRIDE_BACK_ARROW',
  clearBackArrow: 'CLEAR_BACK_ARROW',
  setSideHeaderItem: 'HEADER_SET_SIDE_HEADER_ITEM',
};

const addCustomIcon = customIcon => ({
  type: actionTypes.addCustomIcon,
  payload: {
    customIcon,
  },
});
const clearCustomIcon = () => ({
  type: actionTypes.clearCustomIcon,
});
const overrideBackArrow = backArrowFn => ({
  type: actionTypes.overrideBackArrow,
  payload: {
    backArrowFn,
  },
});
const clearBackArrow = () => ({
  type: actionTypes.clearBackArrow,
});
const setSideHeaderItem = item => ({
  type: actionTypes.setSideHeaderItem,
  payload: {
    item,
  },
});

const actions = {
  addCustomIcon,
  clearCustomIcon,
  overrideBackArrow,
  clearBackArrow,
  setSideHeaderItem,
};

export { actionTypes, actions };
