import React from 'react';
import PropTypes from 'prop-types';

import styles from './tooManyAppointments.module.css';

const TooManyAppointments = ({ close }) => (
  <div className={styles.container}>
    <div>
      You have reached your booking limit for this phone number. Please try
      again in 6 hours.
    </div>
    <button onClick={close} className={styles.button}>
      Close
    </button>
  </div>
);

TooManyAppointments.propTypes = {
  close: PropTypes.func.isRequired,
};

export default TooManyAppointments;
