import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { throttle } from 'lodash/fp';
import { actions } from './actions/codeConfirmationActions';
import { getAppointmentMobile } from '../../selectors/sessionSelectors';
import MobileVerification from './index';

const throttleForTime = throttle(500);

const mapAppointmentStateToProps = state => ({
  token: state.loginReducer.token,
  mobile: getAppointmentMobile(state),
  sendCodeIsFetching: state.confirmCodeReducer.sendCode.isFetching,
  sessionId: state.checkEnrollmentReducer.session.SessionId,
  urlName: state.practiceProfileReducer.UrlName,
  shouldPush: state.confirmCodeReducer.sendCode.success,
  isError:
    state.confirmCodeReducer.sendCode.error &&
    state.confirmCodeReducer.sendCode.error.status === 400,
});

const mapAppointmentDispatchToProps = dispatch => ({
  sendCode: bindActionCreators(actions.sendCode, dispatch),
  clearError: throttleForTime(
    bindActionCreators(actions.clearSendCodeError, dispatch),
  ),
});

export default connect(
  mapAppointmentStateToProps,
  mapAppointmentDispatchToProps,
)(MobileVerification);
// eslint-enable max-len
