import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';

import { actions as depActions } from '../DependantsPage/actions/actions';
import { actions as apptActions } from '../AppointmentsPage/actions/actions';
import { actions as searchActions } from '../vScript/actions/searchActions';
import { actions as menuActions } from '../vScript/actions/menuActions';
import { actions as profileActions } from '../components/UIContainer/MobileUI/MainShell/actions/profileActions';
import { actions } from './actions/actions';
import CtaMenuButton from '../components/CtaMenuButton/index';
//import MenuContainer from '../vScript/RequestScriptMobile/RequestScriptMobile';
import PrescriptionListMobile from './PrescriptionListMobile/index';
import styles from './prescriptions.module.css';
import { scriptPropType } from './propTypes';
import Alert from '../libs/Alert';
import { getToken } from 'libs/newHttp';
import { go } from '../routes';

class Prescriptions extends Component {
  static propTypes = {
    fetchCurrentRequests: PropTypes.func.isRequired,
    fetchPastRequests: PropTypes.func.isRequired,
    fetchDependants: PropTypes.func.isRequired,
    fetchConnectedPractices: PropTypes.func.isRequired,
    fetchAllPractices: PropTypes.func.isRequired,
    changeCtaFn: PropTypes.func.isRequired,
    toggleCta: PropTypes.func.isRequired,
    ctaFn: PropTypes.func.isRequired,
    showCta: PropTypes.bool.isRequired,
    ctaIcon: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    currentRequests: PropTypes.arrayOf(scriptPropType),
    pastRequests: PropTypes.arrayOf(scriptPropType),
    isFetchingCurrent: PropTypes.bool,
    isFetchingPast: PropTypes.bool,
    removeSuccess: PropTypes.bool.isRequired,
    isRemoving: PropTypes.bool,
  };
  state = {
    showMenu: false,
    ctaText: 'Request Prescription',
    cardIsOpen: false,
  };
  componentDidMount() {
    const token = getToken();
    this.props.fetchCurrentRequests(token);
    this.props.fetchPastRequests(token);
    this.props.fetchDependants(token);
    this.props.fetchConnectedPractices(token);
    this.props.fetchAllPractices(token);
    this.props.changeCtaFn(this.toggleMenu);
    if (!this.props.showCta) this.props.toggleCta();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const token = getToken();
    if (
      nextProps.removeSuccess &&
      nextProps.removeSuccess !== this.props.removeSuccess
    ) {
      Alert.success('The prescription has been removed from your history');
      this.props.fetchCurrentRequests(token);
      this.props.fetchPastRequests(token);
    }
  }
  setCtaText = ctaText => {
    this.setState({ ctaText });
  };
  toggleOpenCardState = () => {
    this.setState({ cardIsOpen: !this.state.cardIsOpen }, this.changeCta);
  };
  changeCta = () => {
    if (!this.state.cardIsOpen) {
      this.props.changeCtaFn(this.toggleMenu);
    }
  };
  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };
  handleNoScriptClick = () => {
    if (this.cta) {
      this.cta.handleClick();
    }
  };
  render() {
    const {
      isFetchingCurrent,
      isFetchingPast,
      isRemoving,
      currentRequests,
      pastRequests,
    } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.inner}>
          <PrescriptionListMobile
            isFetching={isFetchingCurrent || isFetchingPast || isRemoving}
            currentRequests={currentRequests}
            pastRequests={pastRequests}
            setCtaText={this.setCtaText}
            toggleMenu={this.handleNoScriptClick}
            toggleOpenCardState={this.toggleOpenCardState}
            isOpen={this.state.cardIsOpen}
          />
        </div>
        {this.props.showCta && (
          <CtaMenuButton
            ref={c => {
              this.cta = c;
            }}
            buttonStyles={styles.ctaButton}
            iconStyles={styles.buttonIcon}
            textIconStyles={styles.textButtonIcon}
            text={this.state.ctaText}
            onClick={this.props.ctaFn}
            icon={this.props.ctaIcon}
          />
        )}
        <CSSTransitionGroup
          component="div"
          transitionName={{
            leave: styles.leave,
            leaveActive: styles.leaveActive,
            enter: styles.enter,
            enterActive: styles.enterActive,
          }}
          transitionLeaveTimeout={300}
          transitionEnterTimeout={300}
        >
          {this.state.showMenu && go.selectPractice()}
        </CSSTransitionGroup>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.loginReducer.token,
  ctaFn: state.appointmentsCTAReducer.ctaFn,
  ctaIcon: state.appointmentsCTAReducer.ctaIcon,
  showCta: state.appointmentsCTAReducer.showCta,
  currentRequests: state.scriptReducer.currentRequests,
  isFetchingCurrent: state.scriptReducer.isFetchingCurrent,
  pastRequests: state.scriptReducer.pastRequests,
  isFetchingPast: state.scriptReducer.isFetchingPast,
  removeSuccess: state.scriptReducer.removeSuccess,
  isRemoving: state.scriptReducer.isRemoving,
});
const mapDispatchToProps = dispatch => ({
  /* eslint-disable max-len */
  fetchCurrentRequests: bindActionCreators(
    actions.fetchCurrentRequests,
    dispatch,
  ),
  fetchPastRequests: bindActionCreators(actions.fetchPastRequests, dispatch),
  fetchDependants: bindActionCreators(depActions.fetchDependants, dispatch),
  fetchConnectedPractices: bindActionCreators(
    menuActions.fetchConnectedPractices,
    dispatch,
  ),
  fetchAllPractices: bindActionCreators(
    searchActions.fetchAllPractices,
    dispatch,
  ),
  changeCtaFn: bindActionCreators(apptActions.changeCtaFn, dispatch),
  toggleCta: bindActionCreators(apptActions.toggleCta, dispatch),
  getProfile: bindActionCreators(profileActions.getProfile, dispatch)
  /* eslint-enable max-len */
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Prescriptions);
export { styles };
