import { bindActionCreators } from 'redux';
import { find } from 'lodash/fp';
// eslint-disable-next-line max-len
import { actions as sessionActions } from '../vAppointment/MobileVerification/actions/sessionActions';
import { actions as confirmCodeActions } from '../vAppointment/MobileVerification/actions/codeConfirmationActions';
import { actions as feelingActions } from '../vAppointment/Reason/actions/actions';
import { actions as bookingActions } from '../PracticeProfilePage/BookingPageMobile/actions/actions';
import { actions as bookingPageActions } from '../PracticeProfilePage/BookingPageMobile/actions/pageActions';
// eslint-disable-next-line max-len
import { actions as patientDetailsActions } from '../vAppointment/PatientDetails/actions/actions';
import { actions as guardianActions } from '../vAppointment/PatientDetails/actions/guardianActions';
import { actions as checkoutActions } from '../vAppointment/Processing/actions/actions';

const clearAppointmentData = dispatch => () => {
  bindActionCreators(sessionActions.clearSession, dispatch)();
  bindActionCreators(feelingActions.clearFeelings, dispatch)();
  bindActionCreators(confirmCodeActions.clearConfirmCode, dispatch)();
  bindActionCreators(bookingActions.clearSelection, dispatch)();
  bindActionCreators(bookingPageActions.clearState, dispatch)();
  bindActionCreators(patientDetailsActions.clearDetails, dispatch)();
  bindActionCreators(guardianActions.clearState, dispatch)();
  bindActionCreators(checkoutActions.clearConfirmationState, dispatch)();
};

const findDoctor = (id, doctors) =>
  find(({ PmsUserId }) => PmsUserId === id, doctors);

const getUrl = state => state.practiceProfileReducer.profile.UrlName;

export { clearAppointmentData, findDoctor, getUrl };
