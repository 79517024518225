import { addDependantSaga, addAvatarDependantSaga } from "../DependantsPage/sagas/addDependant";
import {
  cancelAppointmentSaga,
  ableToCancelSaga,
} from "../components/Deleter/sagas/cancelAppointment";
import { changePasswordSaga } from "../UserAccountPage/sagas/changePassword";
import { confirmedAppointmentsSaga } from "../AppointmentsPage/sagas/confirmedAppointments";
import { getApptDetailsSaga } from "../AppointmentsPage/sagas/appointmentDetails";
import {
  checkPasswordSaga,
  deactivateAccountSaga,
} from "../UserAccountPage/sagas/deactivateAccount";
import { feedSaga } from "../HealthFeed/sagas/feed";
import { verifySaga } from "../HealthFeed/sagas/verifySaga.js";
import { acceptPrivacyPolicySaga } from "../HealthFeed/sagas/acceptPrivacyPolicy";
import { fetchDependantsSaga } from "../DependantsPage/sagas/fetchDependants";
import { getPracticeListingsSaga } from "../PracticesPage/sagas/practiceListing";
import { logoutSaga } from "../EntryPage/sagas/logout";
import { loginSaga, loginPinSaga } from "../EntryPage/sagas/login";
import { refreshTokenSaga } from "../EntryPage/sagas/refreshToken";
import { profileSaga } from "../components/UIContainer/MobileUI/MainShell/sagas/profile";
import { editProfileSaga, addAvatarSaga } from "../UserAccountPage/sagas/editProfile";
import {
  getProfileSaga,
  clearProfileSaga,
  resetEditProfileSaga,
} from "../components/UIContainer/MobileUI/MainShell/sagas/getProfile";
import {
  confirmCancellationSaga,
  requestConfirmCancelSaga,
} from "../components/Deleter/sagas/checkCancellation";
import { pastAppointmentsSaga } from "../AppointmentsPage/sagas/pastAppointments";
import { removeDependantSaga } from "../DependantsPage/sagas/removeDependant";
import { unlinkPracticeSaga } from "../PracticesPage/sagas/practiceUnlinking";
import { getAllPracticesSaga } from "../PracticesPage/PracticeSearch/sagas/getAllPractices";
import { getMatchingPracticesSaga } from "../PracticesPage/PracticeSearch/sagas/fuzzySearch";
import { linkPracticeSaga } from "../PracticesPage/sagas/practiceLinking";
import { sendMessageToSupportSaga } from "../SupportPage/sagas/support";
import { tokenStorageSaga } from "../EntryPage/sagas/tokenStorage";
import { getReasonsSaga } from "../vAppointment/Reason/sagas/getReasons";
import { pinCodeSaga } from "../Identity/SecurityPin/sagas/pinCode";
import { verifyPinCodeSaga } from "../Identity/SecurityPin/sagas/verifyPinCode";

const consumerInterfaceSagas = [].concat(
  ableToCancelSaga,
  acceptPrivacyPolicySaga,
  addAvatarDependantSaga,
  addAvatarSaga,
  addDependantSaga,
  getReasonsSaga,
  getApptDetailsSaga,
  cancelAppointmentSaga,
  changePasswordSaga,
  checkPasswordSaga,
  clearProfileSaga,
  confirmCancellationSaga,
  confirmedAppointmentsSaga,
  deactivateAccountSaga,
  editProfileSaga,
  feedSaga,
  verifySaga,
  fetchDependantsSaga,
  getMatchingPracticesSaga,
  getPracticeListingsSaga,
  getAllPracticesSaga,
  getProfileSaga,
  linkPracticeSaga,
  loginSaga,
  loginPinSaga,
  logoutSaga,
  pastAppointmentsSaga,
  profileSaga,
  refreshTokenSaga,
  requestConfirmCancelSaga,
  removeDependantSaga,
  resetEditProfileSaga,
  sendMessageToSupportSaga,
  tokenStorageSaga,
  unlinkPracticeSaga,
  pinCodeSaga,
  verifyPinCodeSaga
);

export { consumerInterfaceSagas };
