import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from '../../actions/actions';
import { actions as headerActions } from '../../../components/UIContainer/actions/headerActions';
import CtaButton from '../../../components/CtaButton';
import UIContainer from '../../../components/UIContainer';
import MedInput from '../SearchMedsMobile';
import MedItems from '../SelectedMedsMobile';
import Note from '../AddNote';
import { medicationPropType } from '../../propTypes';
import { go } from '../../../routes';
import styles from '../medications.module.css';

class MedicationsSearchPage extends Component {
  static propTypes = {
    setInfo: PropTypes.func.isRequired,
    checkAbleToRequest: PropTypes.func.isRequired,
    overrideBackArrow: PropTypes.func.isRequired,
    clearBackArrow: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    medications: PropTypes.arrayOf(medicationPropType),
    token: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    patientId: PropTypes.string.isRequired,
    orgId: PropTypes.string.isRequired,
    isChecking: PropTypes.bool.isRequired,
    practiceName: PropTypes.string.isRequired,
    doctorName: PropTypes.string.isRequired,
  };
  state = {
    note: this.props.note || '',
    showList: false,
    showNote: !!this.props.note,
    selectedMeds: this.props.medications,
  };
  componentDidMount() {
    this.props.overrideBackArrow(this.props.goBack);
  }
  componentWillUnmount() {
    this.props.clearBackArrow();
  }
  submitSelection = () => {
    if (this.state.note.length) this.props.setInfo('reason', this.state.note);
    const { selectedMeds } = this.state;
    const { userId, orgId, patientId, token } = this.props;

    if (selectedMeds.length) {
      if (userId !== patientId) {
        this.props.checkAbleToRequest(orgId, userId, token, patientId);
      } else this.props.checkAbleToRequest(orgId, userId, token);
      this.props.setInfo('medications', selectedMeds);
      go.pickUpLocation();
    }
  };
  addMed = medication => {
    const newState = [...this.state.selectedMeds];
    const index = this.state.selectedMeds.findIndex(
      e => medication.Id === e.Id,
    );
    if (index === -1 || !medication.Id) newState.push(medication);
    this.setState({ selectedMeds: newState });
    this.toggleList();
  };
  removeMed = med => () => {
    const index = this.state.selectedMeds.findIndex(e => med.Id === e.Id);
    const newState = [...this.state.selectedMeds];
    newState.splice(index, 1);
    this.setState({ selectedMeds: newState });
  };
  toggleList = () => {
    this.setState({ showList: !this.state.showList });
  };
  openNote = () => {
    this.setState({ showNote: true });
  };
  addNote = e => {
    this.setState({ note: e.target.value });
  };
  render() {
    const medications = this.state.selectedMeds;
    const { showList, showNote } = this.state;
    return (
      <UIContainer
        isFetching={this.props.isChecking}
        heading={this.props.patientName}
        secondaryHeading={
          <div className={styles.patientDetails}>
            <div>{`at ${this.props.practiceName}`}</div>
            <div>{this.props.doctorName}</div>
          </div>
        }
        headerHeight="100px"
        hideArrow={showList}
        blueSpinner
      >
        <div className={styles.outerContainer}>
          <div className={styles.container}>
            {medications[0] && (
              <MedItems medications={medications} onRemove={this.removeMed} />
            )}
            <MedInput
              isOpen={showList}
              toggleList={this.toggleList}
              addMed={this.addMed}
              first={!medications[0]}
            />
            {showNote && !showList && (
              <Note note={this.props.note} addNote={this.addNote} />
            )}
          </div>
          <div className={styles.buttons}>
            {showNote ? (
              <div className={styles.note}>
                Note that practice staff can view this information
              </div>
            ) : (
              <button className={styles.addNote} onClick={this.openNote}>
                {medications[0] ? '+ Add a note to your doctor' : ''}
              </button>
            )}
            {!showList && (
              <CtaButton
                active={!!medications[0]}
                className={styles.cta}
                onClick={this.submitSelection}
              />
            )}
          </div>
        </div>
      </UIContainer>
    );
  }
}

/* istanbul ignore next*/
const mapStateToProps = state => ({
  token: state.loginReducer.token,
  // eslint-disable-next-line max-len
  patientName: `${state.scriptDetailsReducer.patient.FirstName} ${
    state.scriptDetailsReducer.patient.LastName
  }`,
  practiceName: state.scriptDetailsReducer.practice.Name,
  doctorName: state.scriptDetailsReducer.doctor.Name,
  note: state.scriptDetailsReducer.reason,
  medications: state.scriptDetailsReducer.medications,
  patientId: state.scriptDetailsReducer.patient.Id,
  orgId: state.scriptDetailsReducer.practice.Id,
  userId: state.profileReducer.profile.Id,
  isChecking: state.ableToRequestReducer.isChecking,
});
/* istanbul ignore next*/
const mapDispatchToProps = dispatch => ({
  overrideBackArrow: bindActionCreators(
    headerActions.overrideBackArrow,
    dispatch,
  ),
  clearBackArrow: bindActionCreators(headerActions.clearBackArrow, dispatch),
  goBack: () => go.selectDoctor(),
  setInfo: bindActionCreators(actions.setInfo, dispatch),
  checkAbleToRequest: bindActionCreators(actions.ableToRequest, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MedicationsSearchPage);
export { styles };
