import { call, takeEvery, put } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';
import { GenericParserHttp } from '../../libs/Http';
import { go } from '../../routes';

const api = (id, token) =>
  GenericParserHttp.post('consumer/practice', {
    data: { PracticeId: id },
    token,
  }).then(({ status }) => ({
    isMatched: status !== 206,
    isUsingVensa: status !== 204,
  }));

function* linkPractice({ payload: { practice, token, onFinishAdd, shouldAdd } }) {
  try {
    if (shouldAdd) {
      const { isMatched, isUsingVensa } = yield call(
        api,
        practice.PracticeId,
        token,
      );
      yield put(actions.linkPracticeSuccess(practice, isMatched, isUsingVensa));
    }
    if (onFinishAdd === undefined) yield put(go.home());
    if (onFinishAdd === 0) { yield put(go.home()) } 
    if (onFinishAdd === 1) { yield put(go.choosePractice()) }
    if (onFinishAdd === 2) { yield put(go.practices()) }
  } catch (err) {
    yield put(actions.linkPracticeFailure(err));
  }
}

function* linkPracticeSaga() {
  yield takeEvery(actionTypes.linkPractice, linkPractice);
}

export { linkPracticeSaga };
