import { actionTypes } from '../actions/verifyActions';

const INITIAL_STATE = {
  id: null,
  isFetching: false,
  error: null,
  isMobileVerified: false,
  verificationStatus: 0,
  verifyOrgName: "",
  verificationMethod: 0,
  isPinCodeSetup: false,
  isPhotoSetup: false,
  isPrivacySetup: false,
  lastUpdatedOn: null,
  privacySettings: { }
};

const verifyReducer = (state = INITIAL_STATE, action) => {
  const data = action.payload && action.payload.data;
  switch (action.type) {
    case actionTypes.getVerificationStatus:
      return {
        ...state,
        error: null,
        isFetching: true,
     };
    case actionTypes.getVerificationStatusSuccess:
      return {
        id: data.ConsumerId,
        isFetching: false,
        error: null,
        isMobileVerified: data.MobileVerified,
        verificationStatus: data.VerificationStatus,
        verifyOrgName: data.OrganisationName,
        verificationMethod: data.VerificationMethod,
        isPinCodeSetup: data.SecurityPinCodeSetup,
        isPrivacySetup: data.PrivacyPolicySetup,
        privacySettings: data.PrivacySettings,
        lastUpdatedOn: data.VerificationStatusUpdatedOn
      }
    case actionTypes.getVerificationStatusFailure:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
    };
    default: {
      return state;
    }
  }
};

export default verifyReducer;
