import { call, put, takeEvery } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';
import { GenericParserHttp } from '../../../libs/Http';

const api = (pin, token) =>
  GenericParserHttp.post('consumer/identity/pin-code', {
    data: { pinCode: pin },
    token,
  }).then(({ body }) => body);

function* pinCode({ payload: { pin, token } }) {
  try {
    yield call(api, pin, token);
    yield put(actions.submitPinSuccess());
  } catch (err) {
    yield put(actions.submitPinFailure(err)); 
  }
}

function* pinCodeSaga() {
  yield takeEvery(actionTypes.submitPin, pinCode);
}

export { pinCodeSaga };
