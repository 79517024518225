import {InfoWindow, Marker, GoogleApiWrapper, IMapProps} from 'google-maps-react';
import React, { Component, PropsWithChildren } from 'react';
import { IPharmacy } from 'store/mobx/vScriptStore/vScriptStore.type';
import  PharmacyMarker from '../../images/PharmacyMarker.png';
import Map from './Map';

const MapsKey = 'AIzaSyDe88xIN4ChH4SSetkvlodjsRdz1I8fsk4';

interface IProps {
  google: any,
  pharmacies: IPharmacy[],
  clickedPharmacy: Function,
  initialLocation: any,
}

export class PharmacyMapContainer extends React.Component<IProps> {

  getLocations() {
      return(
        this.props.pharmacies.map(pharmacy => <Marker
                                                key={pharmacy.Id}
                                                position={{
                                                  lat: pharmacy.Latitude,
                                                  lng: pharmacy.Longitude,
                                                }}
                                                icon={{
                                                  url: PharmacyMarker,
                                                  scaledSize: new google.maps.Size(24,36)
                                                }}
                                                onClick={() => this.props.clickedPharmacy(pharmacy)}
                                              />
                      )
      );
  };

  render() {
    return (
        <Map google={google} zoom={13} initialCenter={this.props.initialLocation}>
            {this.getLocations()}
        </Map>
         
    );
  }
}

const PharmacyMaps = GoogleApiWrapper({
  apiKey: (MapsKey)
})(PharmacyMapContainer)

export default PharmacyMaps;