import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Arrow } from 'svgs/rightArrowNoLine.svg'

const Container = styled.div`
    height: 70px;
    width: 533px;
    background-color: #3CB9C0;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #00000029;
    margin: 1rem 0rem;
    margin-bottom: 0rem;
    margin: 1rem auto;
`
const Text = styled.div`
    font-size: 15px;
    text-align: center;
    width: 100%;
`
const TextContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    width: 100%;
`

// Takes a list of titles and which number should be active
interface IProps {
    list: string[];
    active: number;
}

const FlowStatus: React.FC<IProps> = ({list, active}) => {
    return (
        <Container>
            {list.map((item, i) => 
                <TextContainer>
                    <Text style={{fontWeight: i === active ? "bold" : "normal"}}>{i + 1}. {item}</Text>
                    <Arrow style={{margin: "auto", display: i !== list.length - 1 ? "block" : "none"}}/>
                </TextContainer>
            )}
        </Container>
    )
}

export default FlowStatus;