import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AltImage from '../AltImage';
import styles from './imageUploader.module.css';

class ImageUploader extends Component {
  static propTypes = {
    avatar: PropTypes.string,
    text: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  state = {
    file: '',
  };
  handleChange(e) {
    e.preventDefault();
    const reader = new window.FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      this.props.onSubmit(file);
    };
    reader.readAsDataURL(file);
    document.getElementById('upload-avatar').value = '';
  }
  upload = () => {
    document.getElementById('upload-avatar').click();
  };
  render() {
    return (
      <div className={styles.container}>
        {this.props.avatar ? (
          <div style={{backgroundImage: `url(${this.props.avatar})`}} className={styles.avatar} onClick={this.upload} />
        ) : (
          <AltImage className={styles.altImg} onClick={this.upload} />
        )}
        <form encType="multipart/form-data">
          <label htmlFor="upload-avatar" className={styles.label}>
            {this.props.text || 'Change picture'}
          </label>
          <input
            type="file"
            className={styles.input}
            id="upload-avatar"
            onChange={this.handleChange}
          />
        </form>
      </div>
    );
  }
}

export default ImageUploader;
