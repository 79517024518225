import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ModalOverlay = styled.div`
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 100000;
    background-color: rgba(0, 0, 0, 0.8);
`

const ModalCard = styled.div`
    height: 95%;
    margin: 13px;
    background-color: white;
    overflow: hidden;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 0.5rem;
`

const ModalHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #e9e9e9;
`

const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: scroll;
`

const ModalFooter = styled.div`
    display: flex;
    flex-direction: column;
    margin: 1rem;
    align-items: center;
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 1rem;
`

interface IProps {
    ModalHeaderContent?: Function;
    ModalBodyContent?: Function;
    ModalFooterContent?: Function;
}


export const BasicModal: React.FC<IProps> = ({
        ModalHeaderContent,
        ModalBodyContent,
        ModalFooterContent
    }) => {

    return(
        <Container> 
            <ModalOverlay>
                <ModalCard>
                    <ModalHeader>
                        {ModalHeaderContent && ModalHeaderContent()}
                    </ModalHeader>
                    <ModalBody>
                        {ModalBodyContent && ModalBodyContent()}
                    </ModalBody>
                    <ModalFooter>
                        {ModalFooterContent && ModalFooterContent()}
                    </ModalFooter>
                </ModalCard>
            </ModalOverlay>
        </Container>
    )
};