import React from 'react';

import styles from './unsupportedBrowser.module.css';
import { ReactComponent as Logo } from '../../svgs/whiteLogoSub.svg';
import Chrome from '../../images/chrome.png';
import Firefox from '../../images/firefox.png';
import Safari from '../../images/safari.png';
import { ReactComponent as LogoF } from '../../svgs/logoUB.svg';
import fb from '../../images/facebook.PNG';
import twitter from '../../images/twitter.PNG';
import li from '../../images/linkedin.PNG';
import { isDesktop } from '../../config';

const UnsupportedBrowser = () => {
    const isDesktopV = isDesktop();
    if (isDesktopV) {
    return (
        <div className={styles.container}>
            <Logo className={styles.logo} />
            <div className={styles.textContainer}>
                <b className={styles.title}>Please Update your browser</b>
                <br />
                It seems that your browser is a little outdated.
                We do not support your current web browser (e.g. Internet Explorer).
                Sorry!
                <br />
                <br />
                To continue getting access to primary care, please download one of the internet browsers below.
            </div>
            <div className={styles.banner}>
                {isDesktopV && <div className={styles.placeholder} />}
                <img src={Chrome} alt="chrome" className={styles.browser} onClick={() => window.open("https://www.google.com/chrome/")} />
                {isDesktopV && <div className={styles.placeholder} />}
                <img src={Firefox} alt="firefox" className={styles.browser} onClick={() => window.open("https://www.mozilla.org/en-US/firefox/new/")} />
                {isDesktopV && <div className={styles.placeholder} />}
                <img src={Safari} alt="safari" className={styles.browser} onClick={() => window.open("https://support.apple.com/downloads/safari/")} />
                {isDesktopV && <div className={styles.placeholder} />}
            </div>
            <div className={styles.footer}>
            {isDesktopV && <div className={styles.placeholder} />}
            {isDesktopV && <div className={styles.placeholder} />}

                <LogoF />
                <div className={styles.placeholder} />

                <div className={styles.smContainer}>
                    <img src={twitter} alt="tw" style={{height: "43px"}} onClick={() => window.open("https://twitter.com/vensahealth?lang=en")} />
                    <img src={fb} alt="tw" style={{height: "40px"}} onClick={() => window.open("https://www.facebook.com/VensaHealth/")} />
                    <img src={li} alt="tw" style={{height: "43px"}} onClick={() => window.open("https://www.linkedin.com/company/vensa-health/")} />
                </div>
                <div className={styles.placeholder} />

            </div>
        </div>
    );
    } else {
        return (
            <div className={styles.containerM}>
                <div className={styles.top}>
                    <Logo className={styles.logoM} />
                    <div className={styles.textContainer}>
                        <div className={styles.titleM}>Please update your browser</div>
                        <div className={styles.textM}>
                            It seems that your browser is a little outdated. we do not support your current web browser (e.g. Internet Explorer). Sorry!
                        </div>
                        <div className={styles.textM}>
                            To continue getting access to primary care, please download one of the internet browsers below.
                        </div>
                    </div>
                </div>
                <div className={styles.bannerM}>
                    <img src={Chrome} alt="chrome" className={styles.browserM} onClick={() => window.open("https://www.google.com/chrome/")} />
                    <img src={Firefox} alt="firefox" className={styles.browserM} onClick={() => window.open("https://www.mozilla.org/en-US/firefox/new/")} />
                    <img src={Safari} alt="safari" className={styles.browserM} onClick={() => window.open("https://support.apple.com/downloads/safari/")} />
                </div>
                <div className={styles.footer}>
                    <LogoF />
                    <div className={styles.placeholder} />

                    <div className={styles.smContainer} style={{alignItems: "center", margin: "0rem 1rem"}}>
                        <img src={twitter} alt="tw" style={{height: "43px"}} onClick={() => window.open("https://twitter.com/vensahealth?lang=en")} />
                        <img src={fb} alt="tw" style={{height: "40px"}} onClick={() => window.open("https://www.facebook.com/VensaHealth/")} />
                        <img src={li} alt="tw" style={{height: "43px"}} onClick={() => window.open("https://www.linkedin.com/company/vensa-health/")} />
                    </div>
                </div>
            </div>
        )
    }
}

export default UnsupportedBrowser;