import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import moment from 'moment'

import { toNormalTime } from 'libs/Dates';
import { ReactComponent as Back } from 'svgs/circleRightArrow.svg';
import { ReactComponent as Green } from 'svgs/approvedTick.svg';
import { ReactComponent as Orange } from 'svgs/pendingTickWarning.svg';
import { ReactComponent as Red } from 'svgs/declinedCrossWarning.svg';
import { ReactComponent as Call } from 'svgs/call.svg';
import { useAccountStore, useIdentityStore, usePracticesStore } from 'store/mobx/mobx';
import { go } from 'routes';
import UIContainer from 'components/UIContainer';
import { useComponentDidMount } from 'libs/newHttp';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
`
const IdentitiyTitle = styled.div`
    color: #F5722C;
    font-size: 21px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
`
const Text = styled.div`
    font-size: 15px;
    text-align: center;
    margin: 1rem auto;
`
const Card = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 0px 0px 4px 4px;
    background-color: white;
    padding: 1rem;
    box-shadow: 0px 3px 6px #00000029;
    margin: 5px;
    margin-top: 0px;
`
const Image = styled.div`
    background-size: cover;
    background-position: center;
    width: 110%;
    height: 200px;
    transform: translateX(-5%);
`
const Button = styled.div`
    background-color: #07A3C8;
    color: white;
    border-radius: 36px;
    padding: 1rem;
    margin: 1rem auto;
    text-align: center;
    font-size: 16px;
    box-shadow: 1px 3px 5px lightgray;
    width: 66%;
    margin-top: 2rem;
`
interface IPractice {
    Address: string;
    AllowResubmit: boolean;
    BlackListed: boolean;
    BlackListedReason: string;
    EnrollmentStatusNotMeetPracticePolicy: boolean;
    ErrorMessage: string | null;
    IdentityRequest: {
        Status: number;
        RequestDate: string;
        UpdatedOn: string;
        Id: string;
    };
    IsOnline: boolean;
    IdentityVerified: boolean;
    MatchedPatientInPms: boolean;
    OnlineVerificationEnabled: boolean;
    OrganisationId: string;
    OrganisationName: string;
    Phone: string;
    PictureUrl: string;
    Pictures: string[]
    PracticeVerification: boolean;
    PracticeVerifiedOn: string;
    AlreadyVerifiedByOtherAccount: boolean;
}
interface IProps {
    practice: IPractice;
}
const ViewIdentitiyRequest: React.FC<IProps> = observer(() => {
    const idStore = useIdentityStore();
    const practiceStore = usePracticesStore();
    const accStore = useAccountStore();
    const [loading, setLoading] = useState(true);
    const [practice, setPractice] = useState<IPractice>({
        OrganisationId: "",
        AllowResubmit: false,
        OrganisationName: "",
        IdentityVerified: false,
        Address: "",
        OnlineVerificationEnabled: false,
        PictureUrl: "",
        IdentityRequest: {
            Status: 3,
            RequestDate: "",
            UpdatedOn: "",
            Id: "",
        },
        Phone: "",
        Pictures: [],
        IsOnline: true,
        MatchedPatientInPms: false,
        ErrorMessage: "",
        EnrollmentStatusNotMeetPracticePolicy: false,
        BlackListed: false,
        BlackListedReason: "",
        PracticeVerification: false,
        PracticeVerifiedOn: "",
        AlreadyVerifiedByOtherAccount: false,
    })
    
    useComponentDidMount(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let orgId = params.get('id');

        accStore.getIdentityRequestsStatus(() => {
            accStore.identityRequests.map((practice) => {
                if (practice.IdentityRequest || practice.PracticeVerification) {
                    if (orgId === practice.OrganisationId) {
                        setPractice(practice);
                        setLoading(false);
                    }
                }
            })
        });
    })

    return(
        <UIContainer heading={practice.OrganisationName} subheading={"Identity Verification"} removePadding={true} isFetching={accStore.loading || loading}>
            <Container>
                <Image style={{backgroundImage: practice.Pictures ? `url(${practice.Pictures[0]})` : ""}} />
                {practice.PracticeVerification ?
                    <Card>
                        <div>
                            <Green style={{display: "block", margin: "0rem auto 1rem"}} />
                            <IdentitiyTitle style={{color: "#23C373"}}>Identity Verified</IdentitiyTitle>
                            <Text>
                                Your identitiy was verified on {moment.utc(practice.PracticeVerifiedOn, 'YYYY-MM-DD').format('DD/MM/YYYY')}.
                            </Text>
                            <Text>
                                Your verification was done offline with {practice.OrganisationName}.
                            </Text>
                            <Text>Verifying your identity gives you more control to your health information across the Vensa site.</Text>
                        </div>
                    </Card>
                :
                    <Card style={{height: "100%", marginBottom: "1rem", overflow: "scroll", padding: "1rem"}}>
                        {practice.IdentityRequest!.Status === 0 && 
                            <div>
                                <Orange style={{display: "block", margin: "0rem auto 1rem", marginTop: "0rem"}} />
                                <IdentitiyTitle style={{color: "#F5722C"}}>Identity Partially Verified</IdentitiyTitle>
                                <Text>
                                    Your identitiy request sent on {moment.utc(practice.IdentityRequest!.RequestDate, 'YYYY-MM-DD').format('DD/MM/YYYY')} at {toNormalTime(practice.IdentityRequest!.RequestDate)} is currently pending with {practice.OrganisationName}.
                                </Text>
                                <Text>
                                    You can follow it up by giving them a call to access the status of your verification.
                                </Text>
                                <Call style={{display: "block", margin: "1rem auto", overflow: "visible"}} onClick={() => window.location.href="tel:" + practice.Phone} />
                            </div>
                        }
                        {practice.IdentityRequest!.Status === 1 && 
                            <div>
                                <Green style={{display: "block", margin: "0rem auto 1rem"}} />
                                <IdentitiyTitle style={{color: "#23C373"}}>Identity Verified</IdentitiyTitle>
                                <Text>
                                    Your identitiy was verified on {moment.utc(practice.IdentityRequest!.UpdatedOn, 'YYYY-MM-DD').format('DD/MM/YYYY')}.
                                </Text>
                                <Text>
                                    Your verification was done offline with {practice.OrganisationName}.
                                </Text>
                                <Text>Verifying your identity gives you more control to your health information across the Vensa site.</Text>
                            </div>
                        }
                        {practice.IdentityRequest!.Status === 2 && practice.AllowResubmit === true &&
                            <div>
                                <Red style={{display: "block", margin: "0rem auto 1rem"}} />
                                <IdentitiyTitle style={{color: "#FF6A65"}}>Identity Verification Previously Declined</IdentitiyTitle>
                                <Text>
                                    Your identitiy request was sent on {moment.utc(practice.IdentityRequest!.RequestDate, 'YYYY-MM-DD').format('DD/MM/YYYY')} at {toNormalTime(practice.IdentityRequest!.RequestDate)} has been declined by {practice.OrganisationName}.
                                </Text>
                                <Text>
                                    The practice {practice.AllowResubmit} does allow for you to resubmit your identity request if you like you can start by following the link below.
                                </Text>
                                <Button onClick={() => {
                                    idStore.setPracticeSingle(practice);
                                    if (accStore.profile.SecurityPinCodeSetup) {
                                        go.identityPhoto();
                                    } else {
                                        go.identityPin();
                                    }
                                }}>Verify Identity Again</Button>
                            </div>
                        }
                        {practice.IdentityRequest!.Status === 2 && practice.AllowResubmit == false &&
                            <div>
                                <Red style={{display: "block", margin: "0rem auto 1rem"}} />
                                <IdentitiyTitle style={{color: "#FF6A65"}}>Identity Verification Declined</IdentitiyTitle>
                                <Text>
                                    Your identitiy request was sent on {moment.utc(practice.IdentityRequest!.RequestDate, 'YYYY-MM-DD').format('DD/MM/YYYY')} at {toNormalTime(practice.IdentityRequest!.RequestDate)} has been declined by {practice.OrganisationName}.
                                </Text>
                                <Text>
                                    You can follow it up by giving them a call and ask if they can provide your verification online. The practice will not allow you to put your identity verification online again.
                                </Text>
                                <Call style={{display: "block", margin: "2rem auto"}} onClick={() => window.location.href="tel:" + practice.Phone} />
                            </div>
                        }
                    </Card>
                }
            </Container>
        </UIContainer>
    )
})

export default ViewIdentitiyRequest;