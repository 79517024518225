import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from '../components/UIContainer/actions/headerActions';
import UIContainer from '../components/UIContainer/index';
import styles from './messages.module.css';
import { isDesktop } from '../config';

class Messages extends Component {
  static propTypes = {
    setSideHeaderItem: PropTypes.func.isRequired,
  };
  componentDidMount() {
    if (isDesktop()) this.props.setSideHeaderItem('messages');
  }
  render() {
    return (
      <UIContainer heading="Messages" navKey='Messages'>
        <div className={styles.empty}>Your inbox is currently empty</div>
      </UIContainer>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setSideHeaderItem: bindActionCreators(actions.setSideHeaderItem, dispatch),
});

export default connect(
  null,
  mapDispatchToProps,
)(Messages);
