import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

const Container = styled.div`
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    padding: 0rem 0.6rem 1rem;
    text-align: center;
    border-top: 4px solid #FC7B7B;
    margin-bottom: 1rem;
`
const Banner = styled.div`
    background-color: #FF6A65;
    color: white;
    font-size: 11px;
    padding: 3px 6px;
    margin: 1rem 0rem;
    margin-bottom: 1.5rem;
    border-radius: 24px;
    text-align: center;
    width: 60%;
`
const Title = styled.div`
    font-weight: 600;
    margin: 0 0 0.588rem;
    font-size: 17px;
`
const Text = styled.div`
    font-size: 17px;
    color: #6C6C6C;
    white-space: pre-wrap;
    margin: 1rem 0rem
`
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`
const BlueButton = styled.div`
    background-color: #07A3C8;
    border: none;
    font-size: 15px;
    color: white;
    padding: 7px 20px;
    border-radius: 7px;
    width: 40%;
    margin: 1rem;
    margin-left: auto;
`
const Link = styled.div`
    color: #07A3C8;
    font-size: 15px;
    border: none;
    margin: auto;
`

interface Props {
    title: string;
    bannerText: string;
    button: boolean;
    link: boolean;
    text: string;
    buttonText: string;
    linkText: string;
    linkOnClick: () => void;
    buttonOnClick: () => void;
    restricted?: boolean;
    bannerColor?: string;
}

const BannerEmptyFeedCard: React.FC<Props> = observer(({title, bannerColor, restricted, bannerText, button, link, text, buttonText, linkText, linkOnClick, buttonOnClick}) => {
    return (
        <div>
            {restricted ? 
                <Container style={{position: "relative", padding: "0rem 1rem", margin: "0px", marginTop: "10px", borderColor: bannerColor ? bannerColor : "#FC7B7B"}}>
                    <Banner style={{width: "112px", backgroundColor: bannerColor ? bannerColor : "#FC7B7B"}}>{bannerText}</Banner>
                    <Title>{title}</Title>
                    <Text style={{textAlign: "left", fontSize: "17px", paddingLeft: "6px"}}>{text}</Text>
                    <ButtonContainer>
                        {link && <Link onClick={linkOnClick}>{linkText}</Link>}
                        {button && <BlueButton onClick={buttonOnClick}>{buttonText}</BlueButton>}
                    </ButtonContainer>
                </Container>
            :
            <Container style={{borderColor: bannerColor ? bannerColor : "#FC7B7B"}}>
                <Banner style={{backgroundColor: bannerColor ? bannerColor : "#FC7B7B"}}>{bannerText}</Banner>
                <Title>{title}</Title>
                <Text>{text}</Text>
                <ButtonContainer>
                    {link && <Link onClick={linkOnClick}>{linkText}</Link>}
                    {button && <BlueButton onClick={buttonOnClick}>{buttonText}</BlueButton>}
                </ButtonContainer>
            </Container>
        }
        </div>

    )
})

export default BannerEmptyFeedCard;