import { request, HTTP } from 'libs/newHttp';

export const getSecuritySettingsAPI = () => 
    request({
        method: HTTP.GET,
        url: `consumer/security/security-settings`
    });

export const editSecuritySettingsAPI = (settings: any) => () =>
    request({
        method: HTTP.POST,
        url: `consumer/security/control-security-settings`,
        data: settings,
    })