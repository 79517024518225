import React from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { BaseWhiteNotification } from './BaseWhiteNotification';

export const PaymentPrivacyCard: React.FC = observer(() => {
  const { pathname } = useLocation();

  if (!pathname.startsWith('/payment')) return null;

  return (
    <BaseWhiteNotification
      paragraph={`Card related information, your account information, 
      will be shared with Vensa's payment providers to setup your payment method on Vensa system.`}
    />
  );
});
