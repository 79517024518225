import { call, takeEvery, put } from 'redux-saga/effects';
import HttpLib from '../../../libs/Http';
import delay from '../../../libs/Delay';
import { actions, actionTypes } from '../actions/actions';
import { go } from '../../../routes/index';
import { actions as mobileActions } from '../../../PracticeProfilePage/BookingPageMobile/actions/actions';

const SubmitAppointmentParserHttp = HttpLib(() => {}, () => {});
const api = id =>
  SubmitAppointmentParserHttp.post(`consumer/appointment/session/${id}/submit`);

function* submitAppointment({ payload: { sessionId } }) {
  try {
    yield call(api, sessionId);
    yield put(actions.submitAppointmentSuccess());
    yield put(actions.confirmationPollStart(sessionId));

    // We clear the smoking recall id after submit successfully
    yield put(mobileActions.setSmokingRecallId(''));
  } catch (err) {
    // for animation purposes
    yield call(delay, 3000);
    yield put(actions.submitAppointmentFailure(err));
    yield call(delay, 3000);
    go.bookingUnavailable();
  }
}

function* submitAppointmentSaga() {
  yield takeEvery(actionTypes.submitAppointment, submitAppointment);
}

export { submitAppointmentSaga };
