import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { throttle } from 'lodash/fp';
import moment from 'moment';

import styles from './basicInfoForm.module.css';
import BasicInputField from '../BasicInputField';
import DateInput from '../DateInput';
import CtaButton from '../CtaButton';
import {
  isValidMobile,
  isValidName,
  isValidEmail,
} from '../../libs/ValidBasicInfo';
import Alert from '../../libs/Alert';
import { go } from 'routes';

class BasicInfoForm extends Component {
  static propTypes = {
    isValid: PropTypes.func,
    onValid: PropTypes.func,
    allowUnderage: PropTypes.bool,
    onlyUnderAge: PropTypes.bool,
    submit: PropTypes.func,
    className: PropTypes.string,
    firstName: PropTypes.string,
    preferredName: PropTypes.string,
    lastName: PropTypes.string,
    dob: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    disableEmail: PropTypes.bool,
    firstNameRequired: PropTypes.bool,
    lastNameRequired: PropTypes.bool,
    dobRequired: PropTypes.bool,
    phoneRequired: PropTypes.bool,
    emailRequired: PropTypes.bool,
    hideEmail: PropTypes.bool,
    hideButtons: PropTypes.bool,
    buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    extraField: PropTypes.node,
    extraProp: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
    ]),
    buttonStyles: PropTypes.string,
    itemClassName: PropTypes.string,
  };
  constructor(props) {
    super(props);
    this.changeState = this.changeState.bind(this);
    this.onLabelClick = this.onLabelClick.bind(this);
    this.validateForm = throttle(300, this.validateForm.bind(this), {
      leading: true,
    });
    this.isValidFirstName = this.isValidFirstName.bind(this);
    this.isValidPreferredName = this.isValidPreferredName.bind(this);
    this.isValidLastName = this.isValidLastName.bind(this);
    this.isValidMobile = this.isValidMobile.bind(this);
    this.isValidEmail = this.isValidEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getInfo = this.getInfo.bind(this);
  }
  state = {
    name: this.props.firstName || '',
    preferredName: this.props.preferredName || '',
    surname: this.props.lastName || '',
    dob: this.props.birthday ? this.getDob(this.props.birthday) : '',
    phone: this.props.phone || '',
    email: this.props.email || '',
    nameError: null,
    surnameError: null,
    dobError: null,
    phoneError: null,
    emailError: null,
    hasChanges: false,
    valid: false,
  };
  componentDidMount() {
    this.validateForm();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.firstName !== this.props.firstName) {
      this.setState({ name: nextProps.firstName });
    }
    if (nextProps.preferredName !== this.props.preferredName) {
      this.setState({ preferredName: nextProps.preferredName });
    }
    if (nextProps.lastName !== this.props.lastName) {
      this.setState({ surname: nextProps.lastName });
    }
    if (nextProps.birthday) {
      this.setState({ dob: this.getDob(nextProps.birthday) });
    }
    if (nextProps.phone !== this.props.phone) {
      this.setState({ phone: nextProps.phone });
    }
    if (nextProps.email !== this.props.email) {
      this.setState({ email: nextProps.email });
    }
    //runs validation if the form's extra field is changed
    if (nextProps.extraProp !== this.props.extraProp) this.validateForm();
    if (nextProps.hasChanges)
      this.setState({ hasChanges: nextProps.hasChanges });
  }
  onLabelClick(inputName) {
    return () => {
      this[inputName].focus();
    };
  }
  getDob(date) {
    return moment(date, ['YYYY-MM-DD', 'DD-MM-YYYY']).format('DD-MM-YYYY');
  }
  capitalise(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  getInfo() {
    return {
      firstName: this.capitalise(this.state.name),
      preferredName: this.capitalise(this.state.preferredName),
      lastName: this.capitalise(this.state.surname),
      dateOfBirth: moment(this.state.dob, [
        'DD-MM-YYYY',
        'DD/MM/YYYY',
        'YYYY-MM-DD',
      ]).format('YYYY-MM-DD'),
      mobile: this.state.phone,
      email: this.state.email,
    };
  }
  setDobError = error => {
    this.setState({ dobError: error }, this.validateForm);
  };
  changeState(newState) {
    this.setState(newState, this.validateForm);
  }
  handleChange = key => e => {
    if (!this.state.hasChanges) this.setState({ hasChanges: true });
    let value = e.target ? e.target.value : e;
    this.changeState({ [key]: value });
  };
  clearErrors = () => {
    this.setState(
      {
        nameError: null,
        surnameError: null,
        phoneError: null,
        emailError: null,
      },
      () => this.validateForm(),
    );
  };
  validateForm = () => {
    let isValidForm =
      this.isValidFirstName() &&
      this.isValidLastName() &&
      this.isValidMobile() &&
      this.isValidEmail() &&
      this.state.dobError === null;
    //allows for an additional validation requirement
    if (this.props.isValid) {
      isValidForm = this.props.isValid() && isValidForm;
    }
    //indicates to the parent component whether the form is valid
    if (this.props.onValid) this.props.onValid(isValidForm);
    this.setState({ valid: isValidForm });
    return isValidForm;
  };
  isValidMobile() {
    return isValidMobile(this.state.phone, this.props.phoneRequired);
  }
  isValidFirstName() {
    return isValidName(this.state.name, this.props.firstNameRequired);
  }
  isValidPreferredName() {
    return isValidName(this.state.preferredName, false);
  }
  isValidLastName() {
    return isValidName(this.state.surname, this.props.lastNameRequired);
  }
  isValidEmail() {
    return isValidEmail(this.state.email, this.props.emailRequired);
  }
  handleSubmit(e) {
    if (e) e.preventDefault();
    if (!this.isValidFirstName()) {
      this.changeState({ nameError: 'Invalid First Name.' });
    } else if (!this.isValidLastName()) {
      this.changeState({ surnameError: 'Invalid Last Name.' });
    } else if (this.state.dobError) {
      Alert.error(this.state.dobError);
      return false;
    } else if (!this.isValidMobile()) {
      this.changeState({
        phoneError: 'Invalid Mobile, Please enter a valid NZ mobile number.',
      });
    } else if (!this.isValidEmail()) {
      this.changeState({
        emailError: 'Invalid Email, Please enter a valid email.',
      });
    } else if (this.state.hasChanges) {
      if (this.props.submit) this.props.submit(this.getInfo());
      return true;
    }
    return false;
  }
  render() {
    const { hasChanges, valid } = this.state;
    const isValid = hasChanges && valid;
    let email = this.props.email//.length > 15 ? this.props.email.substring(0, 13) + "..." : this.props.email;

    const button = this.props.buttonText ? (
      <button
        className={classnames(styles.button, this.props.buttonStyles, {
          [styles.active]: isValid,
        })}
        type="submit"
      >
        {this.props.buttonText}
      </button>
    ) : (
      <CtaButton
        className={styles.ctaButton}
        onClick={this.handleSubmit}
        active={isValid}
      />
    );


    return (
      <form
        ref={c => {
          this.form = c;
        }}
        className={styles.form}
        onSubmit={this.handleSubmit}
        noValidate
      >
        {!this.props.dependant ?
        <div className={classnames(styles.container, this.props.className)}>
          <BasicInputField
            value={this.state.name}
            label="First Name"
            onChange={this.handleChange('name')}
            error={this.state.nameError}
            clearError={this.clearErrors}
            className={classnames(this.props.itemClassName, styles.name)}
            mobile={false}
            verified={this.props.mobileVerified}
            readOnly={this.props.identityVerified}
          />
          <BasicInputField
            value={this.state.surname}
            label="Surname"
            onChange={this.handleChange('surname')}
            error={this.state.surnameError}
            clearError={this.clearErrors}
            className={classnames(this.props.itemClassName, styles.surname)}
            mobile={false}
            verified={this.props.mobileVerified}
            readOnly={this.props.identityVerified}
          />
          <BasicInputField
            value={this.state.preferredName}
            label="Preferred Name (Optional)"
            onChange={this.handleChange('preferredName')}
            className={classnames(this.props.itemClassName, styles.preferredName)}
            mobile={false}
          />
          <DateInput
            dob={this.state.dob}
            onChange={this.handleChange('dob')}
            onError={this.setDobError}
            containerClassName={classnames(
              this.props.itemClassName,
              styles.dob,
            )}
            allowUnderage={this.props.allowUnderage}
            readOnly={this.props.identityVerified}
            verified={this.props.mobileVerified}
          />
          <BasicInputField
            value={this.state.phone}
            label={!this.props.phoneRequired ? 'Mobile (optional)' : 'Mobile'}
            type="tel"
            onChange={this.handleChange('phone')}
            error={this.state.phoneError}
            clearError={this.clearErrors}
            className={classnames(this.props.itemClassName, styles.mobile)}
            mobile={true}
            verified={this.props.mobileVerified}
            verifyOnClick={() => go.accountVerification("true")}
            readOnly={this.props.identityVerified}
          />
          {!this.props.hideEmail && (
            <BasicInputField
              value={email}
              label={!this.props.emailRequired ? 'Email (optional)' : 'Email'}
              type="email"
              onChange={this.handleChange('email')}
              error={this.state.emailError}
              clearError={this.clearErrors}
              className={classnames(this.props.itemClassName, styles.email)}
              verified={this.props.emailVerified}
              // verifyOnClick={() => go.emailVerification(3, true, this.props.emailVerified)}
              mobile={true}
              readOnly={this.props.identityVerified}
            />
          )}
          {this.props.extraField && this.props.extraField}
        </div>
        :
        <div className={classnames(styles.container, this.props.className)}>
        <BasicInputField
          value={this.state.name}
          label="First Name"
          onChange={this.handleChange('name')}
          error={this.state.nameError}
          clearError={this.clearErrors}
          className={classnames(this.props.itemClassName, styles.name)}
        />
        <BasicInputField
          value={this.state.surname}
          label="Surname"
          onChange={this.handleChange('surname')}
          error={this.state.surnameError}
          clearError={this.clearErrors}
          className={classnames(this.props.itemClassName, styles.surname)}
        />
        <BasicInputField
          value={this.state.preferredName}
          label="Preferred Name (Optional)"
          onChange={this.handleChange('preferredName')}
          error={this.state.preferredNameError}
          clearError={this.clearErrors}
          className={classnames(this.props.itemClassName, styles.preferredName)}
        />
        <DateInput
          dob={this.state.dob}
          onChange={this.handleChange('dob')}
          onError={this.setDobError}
          containerClassName={classnames(
            this.props.itemClassName,
            styles.dob,
          )}
          allowUnderage={this.props.allowUnderage}
        />
        <BasicInputField
          value={this.state.phone}
          label={!this.props.phoneRequired ? 'Mobile (optional)' : 'Mobile'}
          type="tel"
          onChange={this.handleChange('phone')}
          error={this.state.phoneError}
          clearError={this.clearErrors}
          className={classnames(this.props.itemClassName, styles.mobile)}
        />
        {!this.props.hideEmail && (
          <BasicInputField
            value={this.state.email}
            label={!this.props.emailRequired ? 'Email (optional)' : 'Email'}
            type="email"
            onChange={this.handleChange('email')}
            readOnly={this.props.disableEmail}
            error={this.state.emailError}
            clearError={this.clearErrors}
            className={classnames(this.props.itemClassName, styles.email)}
          />
        )}
        {this.props.extraField && this.props.extraField}
      </div>
  }
        {this.props.submit && !this.props.hideButtons && button}
      </form>
    );
  }
}
export default BasicInfoForm;
export { styles };
