import React, { CSSProperties, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { ReactComponent as Arrow } from 'svgs/blueRightCircleArrow.svg';
import { ReactComponent as Privacy } from 'svgs/privacy.svg';
import { ReactComponent as WhiteArrow } from 'svgs/rightWhiteArrow.svg';
import Toggle from 'Privacy/components/PrivacyToggle';
import { useIdentityStore } from 'store/mobx/mobx';

const Card = styled.div`
    width: 534px;
    height: 677px;
    position: relative;
    background-color: white;
    border-radius: 4px;
    margin: 1rem auto;
    box-shadow: 0px 3px 6px #00000029;
`
const Text = styled.div`
    text-align: center;
    color: #6C6C6C;
    font-size: 17px;
    margin-bottom: 1rem;
    text-align: left;
`
const FlexH = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
`
const FlexV = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin: auto;
`
const Button = styled.div`
    background-color: #07A3C8;
    color: white;
    border-radius: 4px;
    font-size: 13px;
    display: flex;
    height: 35px;
    width: 166px;
    flex-direction: row;
    cursor: pointer;
    text-align: center;
    align-items: center;
`
const Link = styled.div`
    font-size: 13px;
    color: #07A3C8;
    margin: 1.5rem auto;
    cursor: pointer;
`
const ToggleContainer = styled.div`
    margin: 0 2rem;
`
const Line = styled.div`
    position: absolute;
    width: 100%;
    border-bottom: 1px solid #DADADA;
    margin: 0 -2rem; 
`
const SettingsTitle = styled.div`
    font-size: 15px;
    color: #6C6C6C;
    padding: 0.6rem 0rem;
    text-align: left;
    font-weight: 600;
    width: 100%;
    margin-left: 4rem;
`
const backButtonStyle: CSSProperties = {
    position: 'absolute',
    left: '1rem',
    top: '1rem',
    transform: 'rotate(180deg)',
    cursor: 'pointer',
    height: '40px',
    width: '40px',
}

interface IProps {
    learnMoreOnClick: () => void;
    goNext: () => void;
    goBack: () => void;
}

const SetupPrivacySettingsDesktop: React.FC<IProps> = observer(({goNext, goBack, learnMoreOnClick}) => {
    const [state, setState] = useState(0);
    const storeId = useIdentityStore();

    const BackButtonFn = () => state === 0 ? goBack() : setState(state - 1)

    return (
        <Card>
            <Arrow 
                onClick={BackButtonFn} 
                style={backButtonStyle} 
            />
            {state === 0 &&
                <FlexV style={{padding: "0rem 5rem"}}>
                    <Privacy style={{margin: "2rem auto 1.5rem", display: "block"}}/>
                    <Text style={{fontWeight: "bold", color: "#07A3C8", textAlign: "center"}}>Privacy</Text>
                    <Text style={{textAlign: "center", marginBottom: "2rem"}}>Privacy is a human right. The Vensa system is designed to:</Text>
                    <Text>1. Collect your data and store it on your behalf. We will never share it with anyone without your permission.</Text>
                    <Text>2. Provide transparency and control over your information.</Text>
                    <Text>3. Show you the above icon every time an app or feature asks to use your personal information.</Text>
                    <Text>4. Keep your information stored securely to the highest standard.</Text>
                    <FlexV>
                        <Button style={{width: "140px", margin: "2rem"}} onClick={() => setState(1)}>
                            <div style={{marginLeft: "auto"}}>Continue</div>
                            <WhiteArrow style={{margin: "0 auto 0 10px"}}/>
                        </Button>
                       {/*} <Link onClick={learnMoreOnClick}>Learn More</Link>*/}
                    </FlexV>
                </FlexV>
            }
            {state === 1 &&
                <FlexV>
                    <Privacy style={{margin: "1.5rem auto", display: "block"}}/>
                    <Text style={{fontWeight: "bold", color: "#07A3C8", textAlign: "center"}}>Privacy</Text>
                    <Text style={{textAlign: "center", margin: "0rem 1.5rem 2rem"}}>Set your default privacy across all providers you are connected with. You can change these anytime.</Text>
                    <SettingsTitle>Privacy Settings</SettingsTitle>
                    <ToggleContainer>
                        <Line />
                        <Toggle initial={storeId.settings[0].value} toggle={() => storeId.toggleSetting(0)} text={"Vensa will access and store all my health records, such as lab results and conditions"} />
                        <Line />
                        <Toggle initial={storeId.settings[1].value} toggle={() => storeId.toggleSetting(1)} text={"Vensa will access and store my consultation and history notes"} />
                        <Line />
                        <Toggle initial={storeId.settings[4].value} toggle={() => storeId.toggleSetting(4)} text={"Vensa will access and store my medications list and history"} />
                        <Line />
                    </ToggleContainer>
                    <Button style={{width: "140px", margin: "2rem"}} onClick={() => goNext()}>
                        <div style={{marginLeft: "auto"}}>Continue</div>
                        <WhiteArrow style={{margin: "0 auto 0 10px"}}/>
                    </Button>
                </FlexV>
            }
        </Card>
    )
});

export default SetupPrivacySettingsDesktop;