import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import document from 'images/document.png';
import moment from 'moment';

const Container = styled.div`
    width: 600px;
    height: 110px;
    flex-direction: row;
    box-shadow: 0px 3px 5px lightgray;
    margin: 0.5rem 0rem;
    margin-right: auto;
    margin-left: auto;
    cursor: pointer;
    display: flex;
    transition: all 0.5s ease-in-out;
`
const TransactionTypeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    width: 40%;
`

const TransactionDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    width: 60%;
`

const Banner = styled.div`
    background-color: #44D375;
    border-radius: 25px;
    font-size: 11px;
    color: white;
    padding: 5px 10px;
`
const Image = styled.img`
    width: 49px;
    height: 49px;
    margin: 5px;
`
const Date = styled.div`
    font-size: 13px;
    color: #585858;
    width: 100%;
`
const Name = styled.div`
    font-size: 15px;
    width: 100%;
    color: #6C6C6C;
    margin: 3px 0px;
`
const Description = styled.div`
    font-size: 13px;
    width: 100%;
    color: #6C6C6C;
    margin: 3px 0px;
`
const Practice = styled.div`
    font-size: 13px;
    color: #6C6C6C;
    font-weight: 300;
    width: 100%;
`

interface Props {
    patientName: string;
    organisationName: string;
    service: string;
    date: any;
    amountInCent: number;
    transactionType: string;
}

const TransactionHistoryCard: React.FC<Props> = observer(({patientName, organisationName, service, date, amountInCent, transactionType}) => {

    const getColorForTransactionType = (transactionType: string) => {
        return transactionType?.toLowerCase() === "payment"
            ? "#44D375" //green
            : "#ff8c00" //orange
    }
    return (
        <div> 
            <Container style={{ borderTop: `3px solid ${getColorForTransactionType(transactionType)}` }}>
                <TransactionTypeContainer>
                    <Banner style={{ backgroundColor: getColorForTransactionType(transactionType) }}>
                        {transactionType}: NZ ${Math.abs((Number(amountInCent) / 100)).toFixed(2)}
                    </Banner>
                    <Image src={document} />
                </TransactionTypeContainer>
                <TransactionDetailsContainer>
                    <Date>{moment(date).format('dddd DD MMMM, yyyy')}</Date>
                    <Name>{patientName}</Name>
                    <Description>{service}</Description>
                    <Practice>{organisationName}</Practice>
                </TransactionDetailsContainer>
            </Container>
        </div>
    );
});

export default TransactionHistoryCard;