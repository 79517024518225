import { useTransition, animated, TransitionFn, UseTransitionProps } from '@react-spring/web';
import { ValueProps } from 'pages/mobile/domain/Appointment/types';
import React, { ComponentType, ReactNode, useState } from 'react';

interface OpenableProps<S, T> {
    Origin: ComponentType<ValueProps<S>>;
    Target: ComponentType<ValueProps<T>>;
    originData: S;
    targetData: T;
    transitionSProps?: UseTransitionProps;
    transitionTProps?: UseTransitionProps;
}

const OpenableCard = <S,T>(
  {
    Origin, 
    Target, 
    originData, 
    targetData,
    transitionSProps,
    transitionTProps,
  }: OpenableProps<S, T>) => {
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open);
    }
    
    let renderT = <Target handleClick={handleClick} data={targetData} flag={open}/>;
    renderT = useTransition(open, transitionTProps || {})(
      (styles, item) => item && <animated.div style={styles}>{renderT}</animated.div>
    );

  return (
    <>
        <Origin handleClick={handleClick} data={originData} flag={open}/>
        {renderT}
    </>
  );
};

export default OpenableCard;
