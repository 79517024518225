import { VerificationStatus } from 'common/constants';
import React from 'react';

interface VerifiedParams {
    EmailVerified: boolean;
    MobileVerified: boolean;
    IdentityVerified: boolean;
    PrivacyPolicySetup: boolean;
    PendingRequest: boolean;
}

export const verfied4Status = ({
    EmailVerified,
    MobileVerified,
    IdentityVerified,
    PrivacyPolicySetup,
    PendingRequest
}: VerifiedParams) => {
    const verified = MobileVerified && EmailVerified && PrivacyPolicySetup && IdentityVerified;
    return verified ? VerificationStatus.VERIFIED : PendingRequest ? VerificationStatus.PENDING : VerificationStatus.UNVERIFIED;
}