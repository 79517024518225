import React, { useEffect, useState } from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Switcheroo from '../../components/Switcheroo/index';
// eslint-disable-next-line max-len
import AccountVerificationPage from '../../HealthFeed/AccountVerificationPage';
import { actions as headerActions } from '../../components/UIContainer/actions/headerActions';
import { actions as menuActions } from '../actions/menuActions';
import { actions as searchActions } from '../actions/searchActions';
import { actions as listActions } from '../../PracticesPage/PracticeSearch/actions/actions';
import { actions as dependantActions } from '../../DependantsPage/actions/actions';
import { actions as notificationActions } from 'components/UIContainer/MobileUI/MainShell/actions/actions';
import { go } from '../../routes';
import styles from './requestScriptMobile.module.css';
import { clearScriptRequest } from '../../selectors/scriptSelectors';

interface Props {
  clearScriptRequest: any,
  dependants: any,
  fetchDependants: any,
  isFetchingDependants: boolean,
  practices: any,
  fetchConnectedPractices: any,
  fetchAllPractices: any,
  isFetchingConnected: boolean,
  mobileVerified: boolean;
  overrideBackArrow: Function,
  clearBackArrow: Function,
  token: string,
  userId: string,
  orgId: string,
  userMobile: string,
  fixed: boolean,
  arrowFn: Function,
  setNotification: any,
}

const InitialiseRequest: React.FC<Props> = (props) => {
  const [switcheroo, SetSwitcheroo] = useState<Switcheroo | null>(null)

  useEffect(() => {
    if (!props.practices || (props.practices.length === 0 && !props.isFetchingConnected)) {
      props.fetchConnectedPractices(props.token);
    }
    if (!props.dependants || (props.dependants.length === 0 && !props.isFetchingDependants)) {
      props.fetchDependants(props.token);
    }
    if (!props.mobileVerified) {
      handleSwitcheroo(3, 2)();
    }
    return () => {
      // Anything in here is fired on component unmount.
      props.clearScriptRequest();
    }
  }, []);

  const handleSwitcheroo = (next, last) => () => {
    if (switcheroo) {
      // opens mobile verification page
      if (!props.mobileVerified && last !== 2) {
        props.overrideBackArrow(handleSwitcheroo(1, 2));
        switcheroo.setItemToShow(2, last);
      } else switcheroo.setItemToShow(next, last);
      // resets arrow fn when verification page is closed
      if (last === 2) props.clearBackArrow();
    }
  };

  return (
    <CSSTransitionGroup
      component="div"
      className={classnames(styles.container, {
        [styles.fixed]: props.fixed,
      })}
      transitionName={{
        leave: styles.leave,
        leaveActive: styles.leaveActive,
        enter: styles.enter,
        enterActive: styles.enterActive,
      }}
      transitionLeaveTimeout={300}
      transitionEnterTimeout={300}
    >
      <Switcheroo
        ref={c => { SetSwitcheroo(c) }}
        firstItem={go.selectPractice()}
        secondItem={
          <div className={styles.verifyContainer}>
            <AccountVerificationPage
              onVerifySuccess={() => { }}
              cancel={handleSwitcheroo(1, 2)}
            />
          </div>
        }
      />
    </CSSTransitionGroup>
  );
}


const mapStateToProps = state => ({
  token: state.loginReducer.token,
  dependants: state.fetchDependantsReducer.dependants,
  isFetchingDependants: state.fetchDependantsReducer.isFetching,
  isFetchingConnected: state.scriptMenuReducer.isFetchingConnected,
  practices: state.scriptMenuReducer.connectedPractices,
  orgId: state.scriptDetailsReducer.practice.Id,
  userId: state.profileReducer.profile.Id,
  userMobile: state.profileReducer.profile.Mobile,
  mobileVerified: state.profileReducer.profile.MobileVerified,
});
const mapDispatchToProps = dispatch => ({
  /* eslint-disable max-len */
  overrideBackArrow: bindActionCreators(
    headerActions.overrideBackArrow,
    dispatch,
  ),
  clearBackArrow: bindActionCreators(headerActions.clearBackArrow, dispatch),
  fetchDoctors: bindActionCreators(menuActions.fetchDoctors, dispatch),
  fetchAllPractices: bindActionCreators(searchActions.fetchAllPractices, dispatch,),
  clearPracticeSelection: bindActionCreators(listActions.clearPracticeSelection, dispatch,),
  fetchConnectedPractices: bindActionCreators(menuActions.fetchConnectedPractices, dispatch,),
  clearScriptRequest: clearScriptRequest(dispatch),
  fetchDependants: bindActionCreators(dependantActions.fetchDependants, dispatch),
  setNotification: bindActionCreators(notificationActions.setWhiteBoxNotification, dispatch)
  /* eslint-enable max-len */
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InitialiseRequest);
export { InitialiseRequest };
