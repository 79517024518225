import React from 'react';
import PropTypes from 'prop-types';

import styles from './notwithvensa.module.css';
import { ReactComponent as Arrow } from '../../../../svgs/rightarrow2.svg';
import { ReactComponent as Cross } from '../../../../svgs/close.svg';
import icon from '../../../../images/notEnrolled.png';
import CallPractice from '../../../../components/CallPractice/index';
import { practiceProfilePropType } from '../../../../PracticeProfilePage/propTypes/index';
import { isDesktop } from '../../../../config';

const NotWithVensa = ({
  practice,
  arrowClick,
  serviceAction,
  activationService,
}) => (
  <div className={styles.container}>
    {isDesktop() ? (
      <Cross className={styles.cross} onClick={arrowClick} />
    ) : (
      <Arrow className={styles.arrow} onClick={arrowClick} />
    )}
    <div className={styles.whoops}>Whoops</div>
    <div className={styles.popUpContainer}>
      <div className={styles.popUp}>
        <img src={icon} alt="hospital" className={styles.icon} />
        <div className={styles.message}>
          {practice.Name} hasn&#39;t enabled this service for you at this
          moment. <br />
          <br />
          If you wish to {serviceAction} online, you can help us to enable it
          for you by calling your practice and asking them to activate their{' '}
          {activationService}.
        </div>
        <CallPractice practice={practice} />
      </div>
    </div>
  </div>
);
NotWithVensa.propTypes = {
  arrowClick: PropTypes.func.isRequired,
  practice: practiceProfilePropType,
  serviceAction: PropTypes.string.isRequired,
  activationService: PropTypes.string.isRequired,
};

export default NotWithVensa;
