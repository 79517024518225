import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import BasicInputField from '../BasicInputField';
import { ReactComponent as Eye } from '../../svgs/eye.svg';
import { isDesktop } from '../../config';
import styles from './passwordChange.module.css';

class PasswordChange extends Component {
  static propTypes = {
    isWhiteBackground: PropTypes.bool.isRequired,
    label: PropTypes.string,
    isValid: PropTypes.bool,
  };
  state = {
    type: 'password',
  };
  toggleHide = () => {
    if (this.state.type === 'password') this.setState({ type: 'text' });
    else if (this.state.type === 'text') this.setState({ type: 'password' });
  };
  render() {
    const type = this.state.type;
    const inputClass = classnames(styles.field, {
      [styles.bigInput]: !this.props.isWhiteBackground,
    });
    return (
      <div className={styles.container}>
        {isDesktop() && (
          <div
            onClick={this.toggleHide}
            className={classnames(styles.showHide, {
              [styles.slash]: type === 'text',
            })}
          >
            <Eye className={styles.eye} />
            {type === 'text' ? 'Hide' : 'Show'}
          </div>
        )}
        <BasicInputField
          label={this.props.label || 'New Password'}
          type={type}
          value={this.props.password}
          onChange={this.props.setPassword}
          className={inputClass}
        />
      </div>
    );
  }
}

export default PasswordChange;
