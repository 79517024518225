import React from 'react';
import PropTypes from 'prop-types';

import styles from './fullempty.module.css';

const EmptyFull = ({ icon, text }) => (
  <div className={styles.container}>
    <div className={styles.iconContainer}>{icon}</div>
    <div
      className={styles.text}
      style={{ width: text.length > 30 ? '135px' : '115px' }}
    >
      {text}
    </div>
  </div>
);

EmptyFull.propTypes = {
  icon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
};

export default EmptyFull;
