import { generalRequest } from 'libs/newHttp';
import { observable, action, makeAutoObservable } from 'mobx';
import { IAllPharmacies, IData, IPharmacy } from "./vScriptStore.type";
import { getNearbyPharmaciesDefaultAPI, get10NearbyPharmaciesAPI, searchNearbyPharmacies, deletePrescription } from './vScriptStore.api';

export class vScriptStore {
    constructor(){
        makeAutoObservable(this);
    }
    @observable pharmacies: IAllPharmacies = {
        Pharmacies: []
    };

    @observable selectedPharmacy: IPharmacy = {
        Id: "string",
        Name: "string",
        Phone: "string",
        Fax: "string",
        Address: "string",
        Latitude: 0,
        Longitude: 0,
        PhotoUrl: "string",
        DistanceInMeters: -1,
        Featured: false,
        OpeningHours: {}
    };

    @observable data: IData = {
        Lat: 0,
        Long: 0,
        Pharmacies: []
    }

    @action.bound
    async getNearbyPharmaciesDefault(orgId: string, onSuccess?: (data: any) => void) {
        await generalRequest.bind(this) ({
            loadingStatusName: 'nearbyPharmaciesLoadingStatus',
            api: getNearbyPharmaciesDefaultAPI({
                orgId: orgId
            }),
            dataPropertyName: 'nearbyPharmacies',
            afterLoaded: (data: any) => {
                this.data.Pharmacies = data.Pharmacies;
                this.data.Lat = data.Lat;
                this.data.Long = data.Long;
                if (onSuccess) {
                    onSuccess(data);
                }
            }
        })
    }

    @action.bound
    async get10NearbyPharmacies(lat: string, long: string, orgId: string, onSuccess?: (data: any) => void) {
        await generalRequest.bind(this) ({
            loadingStatusName: 'nearbyPharmaciesLoadingStatus',
            api: get10NearbyPharmaciesAPI({
                lat: lat,
                long: long,
                orgId: orgId
            }),
            dataPropertyName: 'pharmacies',
            afterLoaded: (data: any) => {
                this.data.Pharmacies = data.Pharmacies;
                if (onSuccess) {
                    onSuccess(data);
                }
            }
        })
    }

    @action.bound
    async searchNearbyPharmacies(name: string, onSuccess?: (pharmacies: []) => void) {
        await generalRequest.bind(this) ({
            loadingStatusName: 'nearbyPharmaciesLoadingStatus',
            api: searchNearbyPharmacies(
                name
            ),
            dataPropertyName: 'pharmacies',
            afterLoaded: (data: any) => {
                this.pharmacies.Pharmacies = data.Pharmacies;
                if (onSuccess) {
                    onSuccess(data);
                }
            }
        })
    }

    @action.bound
    async deletePrescription(prescriptionId: string) {
      await generalRequest.bind(this)({
        loadingStatusName: 'deletePrescriptionLoadingStatus',
        api: deletePrescription(
          prescriptionId
        ),
        dataPropertyName: 'deletePrescription',
      });
    }
}