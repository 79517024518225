import React from 'react';
import { go } from '../../../routes';
import PaymentForm from '../PaymentForm/index';
import { ReactComponent as Arrow } from '../../../svgs/rightarrow2.svg';
import Alert from '../../../libs/Alert';
import styles from './addCard.module.css';
import styled from 'styled-components';

function handleError(message) {
  Alert.error(message);
}

const CardContainer = styled.div`
    margin: 1rem;
`

const AddCard = (props) => (
  <div className={styles.container}>
    <Arrow className={styles.backArrow} onClick={props.goBack ?? go.paymentScript} />
    <div className={styles.heading}>Card details</div>
    <CardContainer>
      <PaymentForm handleNext={props.handleNext ?? go.confirmScript} onError={handleError} hideSaveCard={props.hideSaveCard} />
    </CardContainer>
  </div>
);

export default AddCard;
