const actionTypes = {
  fetchCardDetails: 'SCRIPT_FETCH_PAYMENT_OPTIONS',
  fetchCardDetailsSuccess: 'SCRIPT_FETCH_PAYMENT_OPTIONS_SUCCESS',
  fetchCardDetailsFailure: 'SCRIPT_FETCH_PAYMENT_OPTIONS_FAILURE',
};

const actions = {
  fetchCardDetails: token => ({
    type: actionTypes.fetchCardDetails,
    payload: {
      token,
    },
  }),
  fetchCardDetailsSuccess: cardDetails => ({
    type: actionTypes.fetchCardDetailsSuccess,
    payload: {
      cardDetails,
    },
  }),
  fetchCardDetailsFailure: error => ({
    type: actionTypes.fetchCardDetailsFailure,
    payload: { error },
    error: true,
  }),
};

export { actions, actionTypes };
