import React from 'react';

import styles from './callPractice.module.css';
import { ReactComponent as Phone } from '../../svgs/phone.svg';
import { PracticeInfoPropType } from '../../PracticeProfilePage/BookingPageMobile/propTypes';

const CallPractice = ({ practice }) => (
  <div className={styles.container}>
    <span className={styles.practiceName}>{practice.Name}</span>
    <span className={styles.phone}>{practice.Phone ? practice.Phone : ''}</span>
    <a href={`tel:${practice.Phone}`} className={styles.phoneLink}>
      <Phone className={styles.icon} />
    </a>
  </div>
);

CallPractice.propTypes = {
  practice: PracticeInfoPropType.isRequired,
};

export default CallPractice;
