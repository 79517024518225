import { Dependant } from 'DependantsPage/types';
import React from 'react';
import styles from './index.module.scss';
import { toNormalFormat } from 'libs/Dates';
import { ReactComponent as ProfileIcon } from 'svgs/profileIcon.svg';

interface DependantPopupProps {
    dependant: Dependant;
    cancelText: string;
    okText: string | React.FunctionComponentElement<any>;
    handleOk: () => void;
    handleCancel: () => void;
}

const DependantPopup: React.FC<DependantPopupProps> = ({
    dependant,
    handleOk,
    handleCancel,
    okText,
    cancelText
}) => {
  return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
            <div className={styles.title}>Remove this person from your Circle?</div>
            <div className={styles.text}>
                All data about this person will be removed from your account. 
                They will need to be added to your Care Circle and verified again by the practice if 
                you want access to their health data in the future. Are you sure?
            </div>

            <div className={styles.card}>
            {dependant.AvatarUrl ?
                <img src={dependant.AvatarUrl} className={styles.avatar} />
                :
                <ProfileIcon className={styles.avatar} style={{ backgroundColor: "lightgray", fill: "white"}} />
            }
            <div className={styles.infoContainer}>
                <div>{dependant.FirstName} {dependant.LastName}</div>
                <div>{toNormalFormat(dependant.DateOfBirth)}</div>
            </div>
            </div>
            
            <div className={styles.buttonContainer}>
                <div className={styles.cancelButton} onClick={handleCancel}>{cancelText}</div>
                <div className={styles.okButton} onClick={handleOk}>
                    {okText}
                </div>
            </div>
        </div>
    </div>
  );
};

export default DependantPopup;
