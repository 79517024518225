import * as CSS from 'csstype';
import { ThemeType } from '../../styles';
import { styled } from '../../styles/styled';
import { getMediaQuery } from '../../styles/utils';
import { is } from '../../utils/utils';
import { Responsive, SingleOrArray } from './BaseBox.type';

type ThemeSpaceIndex = keyof ThemeType['space'];

const getGapStyle = (
  direction: SingleOrArray<CSS.Property.FlexDirection>,
  gap: Responsive,
  theme: ThemeType,
) => {
  const styles = [];

  const themeValue = theme.space[gap as ThemeSpaceIndex];
  const isInTheme = typeof themeValue === 'number';
  const space = isInTheme ? `${themeValue}px` : `${gap}px`;

  if (direction === 'column') {
    styles.push(`height: ${space};` as never);
  } else if (direction === 'row') {
    styles.push(`width: ${space};` as never);
  } else if (Array.isArray(direction) && direction.length > 0) {
    direction.forEach((dir, index) => {
      if (!is.notExist(theme.breakpoints[index])) {
        const breakPoint = theme.breakpoints[index];
        const toAdd = getGapStyle(dir, gap, theme);
        const responsiveCSS = getMediaQuery(breakPoint, toAdd);

        styles.push(`
          ${index === 0 ? toAdd : ''};
          ${responsiveCSS};
        ` as never);
      }
    });
  }

  return styles;
};

// eslint-disable-next-line
export const StyledGapBox = styled.div<{
  direction: SingleOrArray<CSS.Property.FlexDirection>;
  gap: Responsive;
}>`
  flex: 0 0 auto;
  ${({ gap, direction, theme }) => {
    if (gap) {
      return getGapStyle(direction, gap, theme);
    }
  }};
`;
