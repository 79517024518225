import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ReactComponent as Card } from 'svgs/creditcardDesktop.svg';
import Radio from 'vScript/shared/Radio/index';
import { isDesktop } from 'config';
import { cardDetailsPropType } from 'vScript/propTypes/index';
import styles from './PaymentCardSelection.module.css';

const convertDate = (m, y) => {
  const month = `0${m}`.slice(-2);
  const year = y.toString().slice(-2);
  return `${month}/${year}`;
};

const getCardBrand = (brand) => {
  if (brand?.toLowerCase() === 'american express' && !isDesktop()) return 'AmEx';
  return brand;
}

const PaymentCardSelection = ({ cardDetails, optionClick, payingOnline, selectedCard }) => (
  <div
    onClick={optionClick(true, cardDetails[0])}
    className={classnames(styles.container, {
      [styles.selected]: selectedCard,
      [styles.expiredBorder]: cardDetails[0]?.IsExpired
    })}
  >
    <div className={styles.iconContainer}>
      {!isDesktop() && cardDetails[0] && (
        <div className={styles.cardNumber}>{cardDetails[0].Last4}</div>
      )}
      <Card
        className={classnames(styles.card, {
          [styles.selectedIcon]: selectedCard
        })}
      />
    </div>
    {cardDetails.length > 0 ? (
      <div className={styles.details}>
        <div className={styles.title}>
          {getCardBrand(cardDetails[0].Brand)} XXXX {cardDetails[0].Last4}
        </div>
        {cardDetails[0].ExpYear && (
          <div className={!isDesktop() && cardDetails[0]?.IsExpired ? styles.expiredExpiryDate : styles.expiryDate}>
            {`Exp Date: ${convertDate(
              cardDetails[0].ExpMonth,
              cardDetails[0].ExpYear,
            )}`}
          </div>
        )}
      </div>
    ) : (
      <div className={styles.title}>Debit / Credit card</div>
    )}
    {payingOnline && selectedCard && !cardDetails[0]?.IsExpired && <Radio className={styles.radio} selected />}
    {isDesktop() && cardDetails[0]?.IsExpired && <div className={classnames(styles.expiredText)}>Expired</div>}
  </div>
);

PaymentCardSelection.propTypes = {
  cardDetails: PropTypes.arrayOf(cardDetailsPropType),
  optionClick: PropTypes.func.isRequired,
  payingOnline: PropTypes.bool.isRequired,
};

export default PaymentCardSelection;
