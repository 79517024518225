import { request, HTTP } from '../../../libs/newHttp';
import { ICommentNew, ISendFeedback } from './myHealthStore.type';

export const getSummaryAPI = () =>
  request({
    method: HTTP.GET,
    url: `consumer/phr/summary`,
  });

export const getLabResultsAPI = () =>
  request({
    method: HTTP.GET,
    url: `consumer/phr/lab-results`,
  });

export const getImmunisationsAPI = () =>
  request({
    method: HTTP.GET,
    url: `consumer/phr/immunisations`,
  });

export const getClinicNotesAPI = () =>
  request({
    method: HTTP.GET,
    url: `consumer/phr/doctor-notes`,
  });

export const postNewCommentAPI = (newComment: ICommentNew) => () =>
  request({
    method: HTTP.POST,
    url: `consumer/phr/comment/new`,
    data: newComment,
  });

export const deleteCommentAPI = (commentId: string) => () => 
  request({
    method: HTTP.DELETE,
    url: `consumer/phr/comment/${commentId}`
})

export const postLabInviteAPI = (item: {PatientId: string, Consent: boolean, AllowInformationContinueUsedAfterWithdrawn: boolean, ReceiveSummaryAfterResearch: boolean}) => () =>
  request({
    method: HTTP.POST,
    url: `consumer/lab-research/invitation`,
    data: item
  })

export const postLabResultFeedbackAPI = (feedback: ISendFeedback) => () =>
  request({
    method: HTTP.POST,
    url: `consumer/lab-research/feedback`,
    data: feedback,
  })
export const getLabResultAPI = (patientId: string, whenReceived: string) => () => 
  request({
    method: HTTP.GET,
    url: `consumer/phr/lab-result?patientId=${patientId}&whenReceived=${whenReceived}`,
  })
