import React, { useState, MouseEvent, useRef, useEffect } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import { FieldProps } from 'hocs/MakeField';
import { ReactComponent as Arrow } from 'svgs/rightarrow.svg';
import { FocusableProps } from 'components/core/FocusWithin';
import { Editable, Touchable } from 'types';

export type DropdownFieldProps = FieldProps & FocusableProps & {
    header: string;
    items: {[key: number]: string};
} & Touchable & Editable;

const DropdownField: React.FC<DropdownFieldProps> = (
    {
        id,
        header,
        items,
        value,
        handleChange,
        touch = false,
        readonly,
        isFocus
    }) => {
        const [isOpen, setOpen] = useState(false);
        const [selectedItem, setSelectedItem] = useState<string>(value ?? '');
        const toggleOptions = (e: MouseEvent<HTMLDivElement>) => {
            setOpen(!isOpen);
            e.currentTarget.focus();
        }
        const handleClick = (e: MouseEvent<HTMLSpanElement>) => {
            setSelectedItem(e.currentTarget.innerText);
            setOpen(false);
            handleChange && handleChange(id, e.currentTarget.id);
        }

        // scroll to bottom
        const bottomRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
            if(isOpen) {
                if(bottomRef && bottomRef.current){
                    bottomRef.current.scrollIntoView({behavior: "smooth"});
                }
            }
            
        },[isOpen]);
        
        return (
            <div className={cn(styles.container, {[styles.touch]: touch, [styles.focus]: isFocus})} >
                <div
                    className={cn(styles.header, {[styles.touch]: touch})}
                    onClick={readonly ? undefined : toggleOptions}>
                    {
                        selectedItem ? selectedItem : header
                    }
                    <Arrow className={cn(styles.arrow, {[styles.open]: isOpen})} />
                </div>
                <div className={cn(styles.body, {[styles.open]: isOpen, [styles.touch]: touch})}>
                    {
                        Object.entries(items).map((item) => (
                            <span key={item[1]} id={item[1]} onClick={handleClick}>
                                {item[0]}
                            </span>
                        ))
                    }
                </div>
                <div ref={bottomRef}></div>
            </div>
        )
};

export default DropdownField;