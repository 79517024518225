import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { CSSTransitionGroup } from 'react-transition-group';
import { actions } from '../actions/actions';
import { actions as headerActions } from '../../components/UIContainer/actions/headerActions';
import { actions as dependantActions } from '../../DependantsPage/actions/actions';
import UIContainer from '../../components/UIContainer/index';
import DesktopPopUp from '../../components/DesktopPopUp/index';
import AddToCalendar from '../AppointmentListMobile/AddToCalendar/index';
import CancelAppointment from './CancelAppointment/index';
import styles from './appointmentDetails.module.css';
import { GOOGLE_API_KEY, transitionGroupProps } from '../../config';
import { appointmentPropType } from '../propTypes/index';
import { profilePropType } from '../../components/UIContainer/MobileUI/MainShell/propTypes/index';
import { go } from '../../routes';
import styled from 'styled-components';
import { ReactComponent as Arrow } from 'svgs/blueRightCircleArrow.svg';


const Outer = styled.div`
    width: 100%;
    background-color: #F9F9F9;
    align-items: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
`
const isDependant = (profile, appointment) => {
  const isUser =
    `${profile.FirstName} ${profile.LastName}` ===
    `${appointment.FirstName} ${appointment.LastName}`;
  return isUser || appointment.DependantId;
};

const getStyleColor = (status, past) => {
  if (past) return '#939393';
  if (status === 'CONFIRMED') return '#23C373';
  if (status === 'CANCELED') return '#FF6A65';
  return '#33CCCC';
};

class AppointmentDetails extends Component {
  static propTypes = {
    setSideHeaderItem: PropTypes.func.isRequired,
    preloadDependant: PropTypes.func.isRequired,
    fetchAppointment: PropTypes.func.isRequired,
    appointment: appointmentPropType.isRequired,
    profile: profilePropType.isRequired,
  };
  state = {
    showAddCalendar: false,
    showDeleter: false,
  };
  componentDidMount() {
    this.props.fetchAppointment(this.props.match.params.id, this.props.token);
    this.props.setSideHeaderItem('appointments');
  }
  toggleAddCalendar = () => {
    this.setState({ showAddCalendar: !this.state.showAddCalendar });
  };
  toggleDeleter = () => {
    this.setState({ showDeleter: !this.state.showDeleter });
  };
  render() {
    const { appointment, profile, preloadDependant } = this.props;
    const { showAddCalendar, showDeleter } = this.state;
    const notPast = moment().isBefore(appointment.Time);
    return (
      <UIContainer
        heading="Appointments"
        navKey='Appointments'
        secondaryHeading="Appointment Details"
        goBack={go.appointments}
      >
        <Outer>
          <div className={styles.innerContainer}>
            <div className={styles.top}>
              <Arrow onClick={go.appointments} style={{ overflow: "visible", cursor: "pointer", transform: "rotate(180deg)", position: "absolute", top: "1rem", left: "1rem" }} />
              <div style={{ fontWeight: 600 }} className={styles.date}>
                {moment(appointment.Time).format('dddd DD MMMM - h:mm a')}
              </div>
              <div
                className={styles.status}
                style={{
                  backgroundColor: getStyleColor(appointment.Status, !notPast),
                }}
              >
                {appointment.Status && appointment.Status.toLowerCase()}
              </div>
            </div>
            <div className={styles.doctorSection}>
              <img
                src={appointment.ProviderPictureUrl}
                className={styles.image}
                alt={appointment.ProviderName}
              />
              <div className={styles.doctorDetails}>
                <div className={styles.doctorName} style={{ fontWeight: 600 }}>
                  {appointment.ProviderName}
                </div>
                <div className={styles.doctorTitle}>
                  {appointment.ProviderTitle}
                </div>
                <div className={styles.practiceName}>
                  {appointment.PracticeName}
                </div>
              </div>
            </div>
            <div className={styles.patientSection}>
              <div style={{ fontWeight: 600 }} className={styles.label}>Patient</div>
              {appointment.FirstName && (
                <div className={styles.patientName}>
                  {`${appointment.FirstName} ${appointment.LastName}`}
                </div>
              )}

              {/*notPast && !isDependant(profile, appointment) && (
              <button
                className={styles.depButton}
                onClick={() => {
                  go.addDependant();
                  preloadDependant({
                    FirstName: appointment.FirstName,
                    LastName: appointment.LastName,
                    DateOfBirth: appointment.DateOfBirth,
                    Mobile: appointment.PatientPhone,
                    SessionId: appointment.SessionId,
                  });
                }}
              >
                + Add as my dependant
              </button>
              )*/}
            </div>
            <div className={styles.reasonSection}>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ fontWeight: 600 }} className={styles.label}>{appointment.Service ? appointment.Service : "Reason for Consultation"}</div>
                {appointment.Service && <div style={{ marginLeft: "auto", marginRight: "1rem", fontWeight: 600 }} className={styles.label}>{appointment.AmountPaid == 0 ? '' : `$${appointment.AmountPaid / 100}`}</div>}
              </div>
              <div className={styles.reason}>{appointment.Note}</div>
            </div>
            <div className={styles.addressSection}>
              <div className={styles.label}>Address</div>
              <div className={styles.address}>{appointment.Address}</div>
              <a
                href={`https://www.google.co.nz/maps/place/${appointment.Address
                  }`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.directionsButton}
              >
                <span>Get directions</span>
              </a>
              <iframe
                title="Clinic location"
                src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_API_KEY}&q=${appointment.Address
                  }`}
                className={styles.map}
              />
            </div>
            {notPast && appointment.Status !== 'CANCELED' && (
              <div className={styles.buttons}>
                <button className={styles.button} onClick={this.toggleDeleter}>
                  Cancel this appointment
                </button>
                <button
                  className={styles.addButton}
                  onClick={this.toggleAddCalendar}
                >
                  Add to Calendar1
                </button>
              </div>
            )}
          </div>
          <CSSTransitionGroup {...transitionGroupProps(styles)}>
            {showAddCalendar && (
              <DesktopPopUp toggle={this.toggleAddCalendar}>
                <AddToCalendar
                  appointment={appointment}
                  show
                  toggleMenu={this.toggleAddCalendar}
                />
              </DesktopPopUp>
            )}
            {showDeleter && (
              <DesktopPopUp toggle={this.toggleDeleter}>
                <CancelAppointment
                  appointment={appointment}
                  toggleCancel={this.toggleDeleter}
                />
              </DesktopPopUp>
            )}
          </CSSTransitionGroup>
        </Outer>
      </UIContainer>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profileReducer.profile,
  token: state.loginReducer.token,
  appointment: state.fetchApptDetailsReducer.details,
});

const mapDispatchToProps = dispatch => ({
  fetchAppointment: bindActionCreators(actions.fetchApptDetails, dispatch),
  setSideHeaderItem: bindActionCreators(
    headerActions.setSideHeaderItem,
    dispatch,
  ),
  preloadDependant: bindActionCreators(
    dependantActions.preloadDependantData,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppointmentDetails);
