import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import styles from './coronaMobile.module.css';

import worriedKiwiURL from '../../../../images/worriedKiwi.png';
import phone from '../../../../svgs/call.svg'
import { go } from '../../../../routes/index';
import Header from '../../../../components/UIContainer/MobileUI/header/index';
import { actions as feelingActions } from '../../actions/actions';

const CoronaMobile = ({ updateSession, ...props }) => {
    const practice = props.location.state.practice;
    const allowBooking = props.location.state.allowContinue;
    const message = props.location.state.message;
    const sessionId = props.location.state.sessionId;
    const method = props.location.state.method;
    const symptoms = props.location.state.symptoms;
    const note = props.location.state.note;
    const service = JSON.parse(props.location.state.service);

    return (
        <div>
            <Header goBackFn={() => go.patientSelection(props.match.params.id)} headerClassNames={styles.header} showHelp />
            <div className={styles.container}>
                <img src={worriedKiwiURL} className={styles.feelingIcon} alt='I am feeling' />
                {message && message.map((item) => {
                    return (
                        <div key={item} className={styles.labelRed} style={{ color: item.Color, fontWeight: item.Bold ? "600" : "400" }}>
                            {item.Message}
                        </div>
                    )
                })}
                <a onClick={() => { window.location.href = 'tel:' + practice.Phone; }}>
                    <img style={{ margin: '20px' }} src={phone} alt='phone' />
                </a>
            </div>
            <div className={styles.footer}>
                {allowBooking &&
                    <a onClick={() => {
                        updateSession(sessionId, method, symptoms, note, service, practice.UrlName);
                        //go.confirmAppointment(props.match.params.id);
                    }} className={styles.back}><u>Continue booking with practice</u></a>
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    practice: state.practiceProfileReducer.profile
})

const mapDispatchToProps = dispatch => ({
    updateSession: bindActionCreators(feelingActions.updateSession, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CoronaMobile);