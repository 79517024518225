import { useComponentDidMount } from 'libs/newHttp';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import styled from 'styled-components';

import IdentityInfo from './Identity/IdentityInfo';
import PDHDecline from './PDH/DeclineResearch';
import InformationUsage from './PDH/InformationUsage';
import InvolvedInStudy from './PDH/InvoledInStudy';
import { actions } from 'HealthFeed/actions/actions';
import SecurityInfo from './Security/SecurityInfo';
import PrivacyInfo from '../../Privacy/components/PrivacyInfoPopUp';
import TakeATour from './TakeATour/TakeATour';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UnavailableOnDesktop from './Misc/UnavailableOnDesktop/UnavailableOnDesktop';
import MedicationInfo from './vScript/MedicationInfo';
import VScriptInfo from './vScript/vScriptInfo';
import AddToHomeScreen from './AddToHomeScreen/AddToHomeScreen';
import CareCircleInfo from './CareCircle/CareCircleInfo';
import ReactDOM from 'react-dom';

interface OverlayProps {
    show: boolean;
}
interface ComponentProps {
    type: string;
    hide: () => void;
    getFeed: () => void;
    hideOnOverlayClick?: boolean;
    params?: any;
}

const Overlay = styled.div`
    background-color: #0000008A;
    opacity: ${(props: OverlayProps) => props.show ? "1" : "0"};
    transition: all 0.3s linear;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 20;
`
const TYPES = ["IDENTITY_INFO", "SECURITY_FAQ", "PRIVACY_INFO", "PDH_DECLINE", "PDH_STUDY_INVOLVEMENT", "PDH_INFORMATION_USAGE", "TAKE_A_TOUR"]

// Learn more pop up flow for mobile
// - Can create customised learn more pop up flows
// - TODO: Add extra flows
const LearnMorePopUp: React.FC<ComponentProps> = observer(({hide, type, getFeed, hideOnOverlayClick, params}) => {
    const [visible, setVisible] = useState(false);

    useComponentDidMount(() => {
        setTimeout(() => {
            setVisible(true);
        }, 0);
    }) 

    const hideHandler = (e: any) => {
        e.preventDefault();
        if (e.target === e.currentTarget || e.currentTarget.contains(e.target)) {
            setVisible(false);
            setTimeout(hide, 300)
        }
    }
    const renderSwitch = () => {
        switch(type) {
            case "IDENTITY_INFO":
                return <IdentityInfo hide={hideHandler} />;
            case "VSCRIPT_INFO":
                return <VScriptInfo hide={hideHandler}/>;
            case "PDH_DECLINE":
                return <PDHDecline hide={hideHandler} onDecline={() => getFeed()}/>;
            case "PDH_STUDY_INVOLVEMENT":
                return <InvolvedInStudy hide={hideHandler} />;
            case "PDH_INFORMATION_USAGE":
                return <InformationUsage hide={hideHandler} />;
            case "SECURITY_FAQ":
                return <SecurityInfo hide={hideHandler} />;
            case "PRIVACY_INFO":
                return <PrivacyInfo closePopUp={hide} />;
            case "MEDICATION_INFO":
                return <MedicationInfo hide={hide} content={params} />;
            case "TAKE_A_TOUR":
                return <TakeATour hide={hideHandler} />;
            case "ADD_TO_HOME_SCREEN":
                return <AddToHomeScreen handleHide={hideHandler} />;
            case "UNAVAILABLE_DESKTOP":
                return <UnavailableOnDesktop hide={hideHandler}/>;
            case "CARE_CIRCLE_INFO":
                return <CareCircleInfo hide={hideHandler}/>;
            default:
                return
        }
    }
    if(type === 'CARE_CIRCLE_INFO') {
        return  ReactDOM.createPortal(
            <Overlay show={visible} onClick={hideOnOverlayClick ? hideHandler : () => {}}>
                {renderSwitch()}
            </Overlay>, document.getElementById('portal') as Element
        );
    }
    return (
        
        <Overlay show={visible} onClick={hideOnOverlayClick ? hideHandler : () => {}}>
            {renderSwitch()}
        </Overlay>
    )
})

const mapDispatchToProps = (dispatch: any) => ({
    getFeed: bindActionCreators<any, any>(actions.getFeed, dispatch),
})

export default connect(null, mapDispatchToProps)(LearnMorePopUp);
