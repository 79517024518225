import { actionTypes } from '../actions/actions';

const INITIAL_STATE = {
  shouldError: false,
  errorText: '',
};

const desktopPopUpReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.setError: {
      return {
        shouldError: action.payload.errorText.length > 0,
        errorText: action.payload.errorText,
      };
    }
    default: {
      return state;
    }
  }
};

export default desktopPopUpReducer;
