import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';

import styles from './forgotPassword.module.css';
import { actions } from './actions/actions';
import LoadingSpinner from '../../components/loadingSpinner/index';
import Header from '../../components/UIContainer/MobileUI/header/index';
import BasicInputField from '../../components/BasicInputField/index';
import CtaButton from '../../components/CtaButton/index';
import Confirmation from './components/Confirmation/index';
import Alert from '../../libs/Alert';
import { isDesktop } from '../../config';
import { getLoginPageURL } from '../../libs/utils';

class ForgotPassword extends Component {
  static propTypes = {
    clearState: PropTypes.func.isRequired,
    requestNewPassword: PropTypes.func.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    hasSent: PropTypes.bool.isRequired,
    goToLogin: PropTypes.func.isRequired,
    error: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  state = {
    email: '',
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      Alert.error('This email is not registered with Vensa.', {
        onClose: this.props.clearState,
      });
    }
  }
  componentWillUnmount() {
    this.props.clearState();
  }
  onChange(e) {
    this.setState({ email: e.target.value });
  }
  handleClick(e) {
    e.preventDefault();
    this.props.requestNewPassword(this.state.email);
  }
  render() {
    return (
      <LoadingSpinner
        isFetching={this.props.isRequesting}
        iconClassName={styles.spinner}
        containerClassName={styles.spinnerContainer}
      >
        {!isDesktop() && <Header headerClassNames={styles.heading} showHelp />}
        {(() => {
          if (this.props.isRequesting) return <div />;
          if (this.props.hasSent) {
            return (
              <Confirmation
                goToLogin={this.props.goToLogin}
                email={this.state.email}
                iconStyles={styles.dart}
                text="We've sent a password reset link to"
              />
            );
          }
          const canClick = this.state.email.length >= 5;
          return (
            <form className={styles.container} onSubmit={this.handleClick}>
              <span className={styles.topText}>Forgot your password?</span>
              <div className={styles.secondText}>
                Enter your email address below and we&#39;ll send you a link to
                reset your password.
              </div>
              <div className={styles.innerContainer}>
                <BasicInputField
                  label="Email"
                  value={this.state.email}
                  onChange={this.onChange}
                  type="email"
                  clearError={this.clearState}
                  error={this.props.error}
                  className={styles.input}
                />
                {isDesktop() ? (
                  <CtaButton
                    className={styles.cta}
                    onClick={this.handleClick}
                    active={canClick}
                  />
                ) : (
                  <button
                    className={classnames(styles.button, {
                      [styles.faded]: !canClick,
                    })}
                    onClick={this.handleClick}
                  >
                    Send
                  </button>
                )}
              </div>
            </form>
          );
        })()}
      </LoadingSpinner>
    );
  }
}

const setLogin = () => {
  window.location = getLoginPageURL();
};

const mapStateToProps = state => ({
  isRequesting: state.requestNewPasswordReducer.isRequesting,
  hasSent: state.requestNewPasswordReducer.success,
  error: state.requestNewPasswordReducer.error !== null,
});
const mapDispatchToProps = dispatch => ({
  requestNewPassword: bindActionCreators(actions.requestNewPassword, dispatch),
  goToLogin: setLogin,
  clearState: bindActionCreators(actions.clearState, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPassword);
export { styles };
