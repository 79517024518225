import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NavigationBar from '../../RequestScriptDesktop/NavigationBar/index';
import CtaButton from '../../../components/CtaButton/index';
import MedicationList from '../components/MedicationList/MedicationList';
import { actions } from '../../actions/actions';
import Loader from 'components/loadingSpinner';
import styles from './medicationsDesktop.module.css';
import styled from 'styled-components';
import Textarea from 'react-textarea-autosize';

const Inner = styled.div`
  color: #6C6C6C;
  font-size: 12px;
  padding: 0rem 1rem;
`
const Content = styled.div`
  max-height: 190px;
  overflow-y: auto;
  margin: 1rem 0rem;
  &::-webkit-scrollbar {
    width: 10px;
  }
  
  &::-webkit-scrollbar-track {
    background: #F0F0F0;
    border-radius: 25px;
    margin-left: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #AFAFAF;
    border-radius: 25px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #999; 
  }
`
interface Props {
  repeatScriptId: string;
  getMedications: Function;
  medicationList: any;
  goBack: any,
  close: any,
  token: string,
  previousMeds: any;
  userId: string,
  patientId: string,
  orgId: string,
  checkAbleToRequest: Function,
  onClick: Function,
  setInfo: Function,
  note: string,
  onNoMedications: Function,
  isFetching: boolean
}

const MedicationsDesktop: React.FC<Props> = (props) => {

  const [selected, setSelected] = useState(props.previousMeds && props.previousMeds);
  const [showNote, setShowNote] = useState(!!props.note);
  const [note, setNote] = useState(props.note || '');
  const [selectionChanged, setSelectionChanged] = useState(false);

  const changeNote = e => {
    setNote(e.target.value)
  };
  const openNote = () => {
    setShowNote(true);
  };

  const submit = () => {
    const { userId, orgId, patientId, token } = props;
    //submits chosen medications if user is able to request and meds isn't empty
    let meds = selectionChanged ? selected : props.medicationList.LongTerm;
    if (meds.length) {
      // if (userId !== patientId) {
      //   props.checkAbleToRequest(orgId, userId, token, patientId);
      // } else props.checkAbleToRequest(orgId, userId, token);
      if (note.length) props.setInfo('reason', note);
      props.onClick(meds);
    }
  };
  const changeSelectedMeds = meds => {
    setSelectionChanged(true);
    setSelected(meds);
  }
  const getMedsCount = medications => {
    if (medications === null || medications === undefined) return 0;
    const longTerm = (medications.LongTerm === null) ? 0 : medications.LongTerm.length;
    const other = (medications.Other === null) ? 0 : medications.Other.length;
    return longTerm + other;
  }
  return (
    <div className={styles.container}>
      <NavigationBar goBack={props.goBack} close={props.close} />
      <div>
      <div className={styles.title}>1. Which Medication / Item?</div>
      <Inner>
      {props.medicationList !== null && props.medicationList.LongTerm !== null && props.medicationList.LongTerm.length > 0 &&
          <div style={{ fontWeight: "bold" }}>Your long term medications are pre-selected:</div>
      }
        <Content>
            {props.medicationList === null && <Loader iconClassName={styles.spinner} isFetching={true} />}
            {props.medicationList !== null &&
              <MedicationList
                selected={selectionChanged ? selected : [...props.medicationList.LongTerm]}
                setMeds={changeSelectedMeds}
                medications={props.medicationList.LongTerm}
                openMedicationInfo={() => {}}
                onNoMedications={props.onNoMedications}
                medsCount={getMedsCount(props.medicationList)}
              />
            }
        </Content>
        {props.medicationList !== null && props.medicationList.Other !== null && props.medicationList.Other.length > 0 &&
          <div style={{ fontWeight: "bold" }}>Non-long term medications:</div>
        }
        <Content>
            {props.medicationList !== null &&
              <MedicationList
                selected={selectionChanged ? selected : [...props.medicationList.LongTerm]}
                setMeds={changeSelectedMeds}
                medications={props.medicationList.Other}
                openMedicationInfo={() => {}}
                onNoMedications={props.onNoMedications}
                medsCount={getMedsCount(props.medicationList)}
              />
            }
        </Content>
        <div style={{ fontSize: "12px", marginBottom: "1.5rem" }}>Listed above are the medicines your doctor prescribed for you in the last 2 years</div>
        <Textarea
          placeholder= "Write a note for the medical team if you have any questions, or if you would like to request anything not listed above."
          value={note}
          onChange={changeNote}
          className={styles.textarea}
          maxLength={120}
          style={{height: "auto"}}
        />
      </Inner>
      </div>
      <div className={styles.buttons}>
          <button className={styles.addNote} onClick={openNote}>
            {''}
          </button>
        
        {props.medicationList !== null &&
          <CtaButton
            className={styles.submit}
            active={selectionChanged ? selected.length > 0 : [...props.medicationList.LongTerm].length > 0}
            onClick={submit}
          />
        }
      </div>
    </div>
  )
}

/* istanbul ignore next */
const mapStateToProps = state => ({
  note: state.scriptDetailsReducer.reason,
  previousMeds: state.scriptDetailsReducer.medications,
  patientId: state.scriptDetailsReducer.patient.Id,
  orgId: state.scriptDetailsReducer.practice.Id,
  userId: state.profileReducer.profile.Id,
  token: state.loginReducer.token,
  medicationList: state.getMedicationsReducer.medications,
  isFetching: state.getMedicationsReducer.isFetching,
  repeatScriptId: state.scriptReducer.repeatScriptId
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  checkAbleToRequest: bindActionCreators(actions.ableToRequest, dispatch),
  getMedications: bindActionCreators(actions.getMedicationList, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MedicationsDesktop);

