const actionTypes = {
  getProfile: 'GET_PROFILE',
  getProfileSuccess: 'GET_PROFILE_SUCCESS',
  getProfileFailure: 'GET_PROFILE_FAILURE',
  clearProfile: 'CLEAR_PROFILE',
  changePhone: 'CHANGE_PROFILE_PHONE',
  userIsVerified: 'USER_IS_VERIFIED',
  profileUpdated: 'PROFILE_UPDATED',
  setFirstTime: 'PROFILE_SET_FIRST_TIME',
  setIsFromMyHealth: 'SET_IS_FROM_MY_HEALTH',
};

const actions = {
  setIsFromMyHealth: value => ({
    type: actionTypes.setIsFromMyHealth,
    payload: value,
  }),
  getProfile: token => ({
    type: actionTypes.getProfile,
    payload: {
      token,
    },
  }),
  getProfileSuccess: profile => ({
    type: actionTypes.getProfileSuccess,
    payload: {
      profile,
    },
  }),
  getProfileFailure: err => ({
    type: actionTypes.getProfileFailure,
    error: true,
    payload: {
      err,
    },
  }),
  clearProfile: () => ({
    type: actionTypes.clearProfile,
  }),
  setFirstTime: firstTime => ({
    type: actionTypes.setFirstTime,
    payload: {
      firstTime,
    },
  }),
  changePhone: (mobile, updateSession = true) => ({
    type: actionTypes.changePhone,
    payload: {
      mobile,
      updateSession,
    },
  }),
  userIsVerified: mobile => ({
    type: actionTypes.userIsVerified,
    payload: {
      mobile,
    },
  }),
  profileUpdated: (FirstName, LastName, DateOfBirth, Mobile) => ({
    type: actionTypes.profileUpdated,
    payload: {
      FirstName,
      LastName,
      DateOfBirth,
      Mobile,
    },
  }),
};
export { actionTypes, actions };
