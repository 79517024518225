import { call, takeEvery, put, select } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/registerUserCodeActions';
import { GenericParserHttp } from '../../../libs/Http';
import { getToken } from '../../../selectors/loginSelectors';
import Alert from 'libs/Alert';

const api = (mobile, token) =>
  GenericParserHttp.post('consumer/verify/mobile', {
    token,
    data: { Mobile: mobile },
  }).then(({ body }) => body);

function* sendCodeToVerify({ payload: { mobile } }) {
  try {
    const token = yield select(getToken);
    yield call(api, mobile, token);
    yield put(actions.sendCodeToVerifySuccess());
  } catch (err) {

    Alert.error(err.response.statusText);
    
    
    yield put(actions.sendCodeToVerifyFailure(err));
  }
}

function* resendCode({ payload }) {
  try {
    const token = yield select(getToken);
    yield call(api, payload.mobile, token);
    yield put(actions.resendCodeSuccess());
  } catch (err) {
    if (err.response.body.Status === 429) {
      Alert.error("You can only send one verification code per minute")
    } else {
      Alert.error(err.response.body.Message);
    }
    yield put(actions.resendCodeFailure(err));
  }
}

function* sendCodeToVerifySaga() {
  yield takeEvery(actionTypes.sendCodeToVerify, sendCodeToVerify);
}

function* resendCodeSaga() {
  yield takeEvery(actionTypes.resendCode, resendCode);
}

export { sendCodeToVerifySaga, resendCodeSaga };
