import { Appointment } from 'pages/mobile/domain/Appointment/types';
import { GOOGLE_API_KEY } from 'config';
import React from 'react';
import AvatarProfile from 'components/shared/common/AvatarProfile';
import styles from './index.module.scss';
import DetailSection from 'pages/mobile/components/BottomBorderSection';
import StatusCard from 'components/shared/common/StatusCard';
import { useAccountStore } from 'store/mobx/mobx';
import { go } from 'routes/sub/dependants';
import { ReactComponent as Icon } from 'svgs/profileIcon.svg';
interface DetailCardProp {
    data: Appointment;
    disableCard?: boolean;
    disableColor?: boolean;
    disableDependant? : boolean;
    extra?: React.ReactNode;
}

const DetailCard: React.FC<DetailCardProp> = (
    {
        data, 
        disableColor,
        disableDependant = true,
        extra
    }) => {
        const store = useAccountStore();
       
        const isConsumer = 
            store.profile.FirstName === data.FirstName 
            && store.profile.LastName === data.LastName;
        const notDependent =  !data.DependantId && !isConsumer;
        // Add dependant from patient info during appointment cancellation
        const addDependent = () => {
            
            go.addDependent(
                data.FirstName, 
                data.LastName, 
                data.DateOfBirth,
                data.PatientPhone,
                data.SessionId);
        }
        
    return (
        <StatusCard status={data.Status} disableColor={disableColor} >
            <AvatarProfile
                className={styles.avatarContainer}
                diameter='4.5rem'
                imgUrl={data.ProviderPictureUrl}
                imgAlt={data.ProviderName}
                placeHolder={Icon}
                enableBottomLine>
                    <span>{data.ProviderName}</span>
                    <span>{data.ProviderTitle}</span>
                    <span>{`at ${data.PracticeName}`}</span>
            </AvatarProfile>
            <DetailSection 
                title='Patient'
                addition={
                    notDependent && !disableDependant ? 
                    <span 
                        className={styles.addDependent}
                        onClick={addDependent}>
                            + Add as my dependant
                    </span> : undefined
                }>
                <span className={styles.greyText}>{`${data.FirstName} ${data.LastName}`}</span>
            </DetailSection>
            <DetailSection 
            title={`${data.Service} - Prepaid`}
            addition={`$${data.AmountPaid / 100}`}>
                <span className={styles.greyText}>{data.Note}</span>
            </DetailSection>
            <DetailSection 
            title='Address'
            addition={
                <a href={`https://www.google.co.nz/maps/place/${data.Address}`}>
                    <span>Get directions</span>
                </a>}>
                <span className={styles.text}>{data.Address}</span>
            </DetailSection>
            <iframe
                title="Clinic location"
                src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_API_KEY}&q=${
                    data.Address
                }`}
            />
            {extra}
        </StatusCard>
        );
};

export default DetailCard;
