import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CSSTransitionGroup } from "react-transition-group";
import { bindActionCreators } from "redux";

import { actions } from "./actions/actions";
import Inner from "./components/Inner";
import Error from "./components/Error";
import styles from "./desktopPopUp.module.css";

const DesktopPopUp = ({ children, toggle, shouldError, errorText, toggleError }) => (
  <div className={styles.container}>
    <Inner toggle={toggle}>
      {children}
      <CSSTransitionGroup
        transitionName={{
          enter: styles.enter,
          enterActive: styles.enterActive,
          leave: styles.leave,
          leaveActive: styles.leaveActive,
        }}
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        {shouldError && <Error toggleError={toggleError}>{errorText}</Error>}
      </CSSTransitionGroup>
    </Inner>
  </div>
);

DesktopPopUp.propTypes = {

};

const mapStateToProps = state => ({
  shouldError: state.desktopPopUpReducer.shouldError,
  errorText: state.desktopPopUpReducer.errorText,
  hideBack: true
});

const mapDispatchToProps = dispatch => ({
  toggleError: bindActionCreators(actions.setError, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DesktopPopUp);
