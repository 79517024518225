import DetailCard from '../../components/DetailCard';
import { Appointment, IdProp, ValueProps } from 'pages/mobile/domain/Appointment/types';
import moment from 'moment';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppointmentStore } from 'store/mobx/mobx';
import { useQuires } from 'hooks/useQueries';
import { parseUriPath } from '../../services/parse';
import { push } from 'routes/utils';
import HeaderCardUI from 'components/shared/mobile/HeaderCardUI';

const PastDetailsCard = () => {

  const params = useParams<IdProp>();
  const store = useAppointmentStore();
  const data = store.getAppointment(params.id, 'past') as Appointment;

  if(!data) {
    push('/home');
    window.location.reload();
  }

  const query = useQuires<{backUrl: string}>();
  const {path, queries} = parseUriPath(query.backUrl);
  const goBack = () => {
    query.backUrl && push(path, queries);
  }
  
  return (

    <HeaderCardUI 
        handleClick={goBack} 
        text={moment(data.Time).format('dddd, DD MMM h:mmA')}>
        <DetailCard data={data} disableColor/>
    </HeaderCardUI>
    );
};

export default PastDetailsCard;
