import 'react';

export const RELATION_SHIP = {
    10: 'Child',
    11: 'Partner',
    12: 'Dependent Adult',
    2: 'Friend',
    3: 'Other',
}

export const RELATION_SHIP_FOR_RENDER = {
    'Child': 10,
    'Partner': 11,
    'Dependent Adult': 12,
    'Friend': 2,
    'Other': 3
}

export type RelationKeys = keyof typeof RELATION_SHIP;