import React from 'react';
import { observer } from 'mobx-react-lite';
import { SummarySection } from './SummarySection';
import { useMyHealthStore } from 'store/mobx/mobx';
import { BaseSectionItem } from './BaseSectionItem';

export const PastConditionSection: React.FC = observer(() => {
  const { PastConditions } = useMyHealthStore().summaryTab;

  return (
    <SummarySection title="Past Condition" observableData={PastConditions}>
      {PastConditions.map(item => (
        <BaseSectionItem
          key={item.Id}
          label={item.Name}
          description={item.Description}
        />
      ))}
    </SummarySection>
  );
});
