import React, { PropsWithChildren } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';
import CircleAvatar, { CircleAvatarProps } from '../CircleAvatar';

type AvatarProfileProps = {
    enableBottomLine?: boolean;
    className?: string;
    placeHolder: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
} & CircleAvatarProps

const AvatarProfile = ({
  imgUrl, 
  imgAlt,
  diameter,
  enableBottomLine, 
  className,
  placeHolder,
  children}: PropsWithChildren<AvatarProfileProps>) => {
  // const enableBottomLine = props.enableBottomLine === undefined ? false : props.enableBottomLine;
  return (
    <div className={cn(className, styles.container, {[styles.bottomLine]: !!enableBottomLine})}>
        <CircleAvatar 
          imgUrl={imgUrl} 
          imgAlt={imgAlt} 
          diameter={diameter}
          placeHolder={placeHolder} />
        <div className={styles.infoContainer}>
            {children}
        </div>
    </div>
  );
};


export default AvatarProfile;
