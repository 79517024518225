import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import PrivacyPopUpButton from '../../Privacy/components/PrivacyInfoPopUpButton/index'
import { isDesktop } from 'config';
import { ReactComponent as CrossIcon } from '../../svgs/blueCross.svg';
import { ReactComponent as PlusIcon } from '../../svgs/plus.svg';

interface IService {
    Name: string;
    Code: string;
    Description: string;
    Id: number;
}

interface IProps {
    items: any[];
    selected: boolean;
    placeholder: string;
    type: string;
    reasonIndex: number;
    changeValue: Function;
    addSymptomValue: Function;
    removeDisplayNone: Function;
    stopAllSearch: Function;
    toggle: Function;
    addDisplayNone: Function;
    shiftBoxes: Function;
    removeSymptomValue: Function;
    setShowSearch: Function;
    searchIndex: number;
    sessionId: string;
    postItem: (item: any) => void
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Modal = styled.div`
    opacity: 1;
    width: 100%;
    height: 100%;
    align-items: center;
    overflow: scroll;
`

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    border: 2px dashed #e9e9e9;
    border-radius: 3px;
    margin: 1rem;
`

const UnselectedContainer = styled.div`
    width: 90%;
    font-style: italic;
    font-size: 16px;
    padding: 10px;
    margin: 0.25rem;
    cursor: pointer;
    border: 2px dashed #e9e9e9;
    border-radius: 3px;
    align-items: center;
    display: flex;
`

const SelectedContainer = styled.div`
    width: 90%;
    font-style: italic;
    font-size: 16px;
    padding: 10px;
    margin: 0.25rem;
    cursor: pointer;
    border: solid 2px #e9e9e9;;
    border-color: #E9E9E9;
    border-radius: 3px;
    align-items: center;
    display: flex;
    background-color: #e5e5e5;
`

const ModalInput = styled.input`
    width: 100%;
    font-size: 16px;
    padding: 10px;
    border: none;
`

const SuggestionsList = styled.ul`
    width: 100%;
    list-style-type: none;
    text-align: left;
    padding: 0;
    background: white; 
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
`

const ListItems = styled.li`
    padding: 10px 30px;
    left: 0;
    height: 80%;
    width: auto;
`

const CrossSvg = styled(CrossIcon)`
    width: 24px;
    height: 24px;
    margin: 0 10px 0 10px;
    position: absolute;
    right: 5%;

`

const PlusSvg = styled(PlusIcon)`
    width: 12px;
    height: 12px;
    margin: 0 10px 0 10px;
`

const ModalButtonContainer = styled.div`
    position: fixed !important;
    bottom: 5%;
    right: 10%;
    z-index: 4;
`

const AutoSuggestText: React.FC<IProps> = observer(({postItem, sessionId, setShowSearch, searchIndex, removeSymptomValue, shiftBoxes, addDisplayNone, toggle, stopAllSearch, removeDisplayNone, addSymptomValue, items, placeholder, type, reasonIndex, changeValue}) => {
    const [suggestions, setSuggestions] = useState(items);
    const [text, setText] = useState("");
    const [itemSelected, setitemSelected] = React.useState(false);
    const [modal, setModal] = useState(false);

    useEffect(() => {
        if (!suggestions && items) setSuggestions(items);
    })

    const onTextChanged = (e: { target: { value: any; }; }) => {
        const value = e.target.value;
        if (value.length > 0) {
            getSuggestions(value);
        } else {
            setSuggestions(items);
        }
        setText(value);
    }

    const onListclick = (item: any) => {
        setText(type + item.Name)
        setitemSelected(true);
        
        const itemName = isDesktop() ? item.Name : item.Name.split(type).pop();
        changeValue(item, reasonIndex);
        if (reasonIndex !== null) addSymptomValue(itemName);
        removeDisplayNone();
        if (!isDesktop()) { stopAllSearch() };
        if (reasonIndex === null) postItem(item);
        closeModal();
    }

    const getSuggestions = (text: string) => {
        let tempSuggestions = items
        const regex = new RegExp(`^${text}`, 'i');
        tempSuggestions = items.sort().filter(v => regex.test(v.Name));
        if (tempSuggestions.length === 0) {
            tempSuggestions = items.filter(v => v.Name === "Other Reasons (below)");
        }
        setSuggestions(tempSuggestions);
    }

    const renderList = () => {
        if (suggestions.length === 0) return null;
        return (
            <SuggestionsList>
                {suggestions.map((item) => <ListItems onClick={() => {onListclick(item)}} key={item.Id}>{item.Name}</ListItems>)}
            </SuggestionsList>
        );
    }

    const returnYes = () => {
        return(
            <Modal>
                <ModalContent>
                    <ModalInput
                        type="text"
                        value={text}
                        onChange={onTextChanged}
                        placeholder={placeholder}
                        autoFocus
                    >
                    </ModalInput>
                    {renderList()}
                </ModalContent>
                <ModalButtonContainer>
                    <PrivacyPopUpButton
                        onClick={() => closeModal()}
                        noFade={true}
                    />
                </ModalButtonContainer>
            </Modal>
        );
    }

    const showModal = () => {
        if(!modal && !itemSelected)  {            
            if (!isDesktop())  setShowSearch(searchIndex, true);
            setModal(true);
        }
    }

    const closeModal = () => {
        if(!isDesktop() && modal) { setShowSearch(searchIndex, false); }
        if(modal) setModal(false);
    }

    const clickCrossSvg = () => {
        if (isDesktop()) { toggle(); }
        if (!isDesktop() && reasonIndex !== null){ addDisplayNone(); }
        setText("")
        setitemSelected(false)
        setSuggestions(items);
        if(!isDesktop()) { stopAllSearch(); }
        if (reasonIndex === 0) { shiftBoxes(); }
        changeValue("", reasonIndex);
        if (reasonIndex !== null) removeSymptomValue();
    }
    
    const returnNo = () => {
        if (!itemSelected) { 
            return (
                <UnselectedContainer>  
                    <PlusSvg/>
                    {placeholder}
                </UnselectedContainer>
            )
        } else {
            return (
                <SelectedContainer onClick={() => clickCrossSvg()}>
                    {text}
                    <CrossSvg />
                </SelectedContainer>
            )
        }

    }

    return (
        <Container
            onClick = {() => showModal()}
        >    
            {modal ? returnYes() : returnNo()}
        </Container>
    )
});

export default AutoSuggestText;