import LearnMorePopUp from 'components/LearnMorePopUp/LearnMorePopUp';
import React, { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';

import Radio from 'components/CircleCheckbox/index';
import { ReactComponent as Note } from 'svgs/noteBentCorner.svg';

interface Props {
    name: string;
    practice: string;
    provider: string;
    question: string;
    popUps: boolean;
    onNext: (value: boolean) => void;
}

const Container = styled.div`

`
const Card = styled.div`
    border-radius: 4px;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    padding: 1rem;
    height: 500px;
`
const FlexH = styled.div`
    display: flex;
    flex-direction: row;
`
const FlexV = styled.div`
    display: flex;
    flex-direction: column;
`
const Title = styled.div`
    font-size: 17px;
    font-weight: 600;
    color: #6C6C6C;
    text-align: left;
    margin-bottom: 0.1rem;
`
const Subtitle = styled.div`
    font-size: 15px;
    font-weight: 300;
    color: #6C6C6C;
    margin: 0.1rem 0rem;
    text-align: left;
    text-transform: capitalize;
`
const Text = styled.div`
    font-size: 17px;
    color: #6C6C6C;
    text-align: left;
    margin: 2rem auto;
    margin-bottom: 0rem;
    height: 80px;
`
const Link = styled.div`
    color: #07A3C8;
    font-size: 17px;
    text-align: left;
    margin: 0.5rem 0rem;
`

const ConsentQuestion: React.FC<Props> = ({name, practice, provider, question, popUps, onNext}) => {
    const [popUpType, setPopUpType] = useState("");
    const [state, setState] = useState(0);
    const [selected, setSelected] = useState(false);

    useEffect(() => {
        setSelected(false);
    });
    const goNextYes = () => {
        setSelected(true);
        setState(1);
        onNext(true);
    }

    const goNextNo = () => {
        setSelected(true);
        setState(2);
        onNext(false);
    }

    return (
        <Container style={{overflow: popUpType === "" ? "scroll" : "visible"}}>
            <Card>
                <FlexH style={{marginLeft: "1rem"}}>
                    <Note />
                    <FlexV style={{marginLeft: "1rem"}}>
                        <Title>{name}</Title>
                        <Subtitle>{practice}</Subtitle>
                        <Subtitle>{provider}</Subtitle>
                    </FlexV>
                </FlexH>
                <Text>{question}</Text>
                <FlexH style={{padding: "4rem"}}>
                    <FlexH style={{alignItems: "center", width: "100%"}}>
                        <Radio selected={selected && state === 1} onClick={goNextYes} />
                        <div onClick={goNextYes} style={{color: "#07A3C8", fontWeight: "bold", marginLeft: "0.5rem"}}>Yes</div>
                    </FlexH>
                    <FlexH style={{alignItems: "center"}}>
                        <Radio selected={selected && state === 2} onClick={goNextNo} />
                        <div onClick={goNextNo} style={{color: "#07A3C8", fontWeight: "bold", marginLeft: "0.5rem"}}>No</div>
                    </FlexH>
                </FlexH>
                {popUps && <Link onClick={() => setPopUpType("PDH_STUDY_INVOLVEMENT")}>Who's involved in the study?</Link>}
                {popUps && <Link onClick={() => setPopUpType("PDH_INFORMATION_USAGE")}>How is my information being used?</Link>}
                {popUpType !== "" &&
                    <LearnMorePopUp type={popUpType} hide={() => setPopUpType("")} />
                }
            </Card>
        </Container>
    )
}

export default ConsentQuestion;