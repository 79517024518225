import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import UIContainer from 'components/UIContainer';
import RouteBar from 'components/DesktopComponents/RouteBar';
import TabBar from 'components/DesktopComponents/TabBar';
import PinControl from 'Identity/DesktopIdentityPage/components/PinControl';
import { ReactComponent as Back } from 'svgs/circleRightArrow.svg';
import { ReactComponent as Lock } from 'svgs/blueLock.svg';
import { go } from 'routes';
import { useComponentDidMount } from 'libs/newHttp';
import { ReactComponent as Four } from 'svgs/4Pin.svg';
import { ReactComponent as Six } from 'svgs/6Pin.svg';
import { ReactComponent as Arrow } from 'svgs/rightlinearrow2.svg';
import BlueButton from 'components/DesktopComponents/BlueButton';
import { useAccountStore, useIdentityStore, useSecurityStore } from 'store/mobx/mobx';
import PinCodeButton from '../../Identity/components/PinCodeButton';
import fourPin from 'svgs/4Pin.svg'
import sixPin from 'svgs/6Pin.svg';
import { useLocation } from 'react-router-dom';
import Alert from 'libs/Alert';

const Container = styled.div`
    height: 100%;
    margin-bottom: 1rem;
`
const Inner = styled.div`
    background-color: white;
    margin: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0px 3px 6px #00000029
    padding: 0px 25px;
    height: -webkit-fill-available;
    border-radius: 4px;
`
const Text = styled.div`
    text-align: center;
    color: #07A3C8;
    font-size: 17px;
    margin-bottom: 1rem;
`
const Button = styled.div`
    background-color: #07A3C8;
    color: white;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 13px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
`
const Link = styled.div`
    font-size: 13px;
    color: #07A3C8;
    margin: 1.5rem auto;
    cursor: pointer;
`
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const FlexH = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
`
const Line = styled.div`
    border-bottom: 2px solid #DADADA;
    width: 100%;
`
interface LocationState {
    newState: number;
}

const EditPin: React.FC = observer(() => {
    const store = useAccountStore();
    const secStore = useSecurityStore();
    const idStore = useIdentityStore();

    const [length, setLength] = useState(4);
    const location = useLocation<LocationState>();
    const newState = location.state !== undefined ? location.state.newState !== undefined ? location.state.newState : 0 : 0;

    const [state, setState] = useState(newState);

    useComponentDidMount(() => {
        if (newState !== 1) {
            store.getPinCodeLength();
            if(store.pinCodeLength === 0) {
                setState(1);
            }
        }
    })

    return (
        <UIContainer isFetching={store.loading || secStore.loading || idStore.loading} heading={newState === 1 ? "Setup PIN Code" : "Change PIN Code"}>
            <Container>
                <Inner>
                    <Lock style={{margin: "4rem auto", marginBottom: "2rem"}} />
                    {state === 0 &&
                        <div>
                            <Text>Enter your <b>current</b> PIN code</Text>
                            <PinControl 
                                verify={true} 
                                goNext={() => setState(1)} 
                                goBack={() => {}}
                                length={store.pinCodeLength}    
                                ifGuessable={() => {}}
                                submitPin={true}
                                mobile={true}
                            />
                        </div>
                    }
                    {state === 1 &&
                        <div>
                            <Text>Set your security PIN. Two options:</Text>
                            <PinCodeButton 
                                text={"4 digit PIN"}
                                pic={fourPin}
                                onClick={() => { 
                                    setLength(4);
                                    setState(2);
                                }}
                            />
                            <Line />
                            <PinCodeButton 
                                text={"6 digit PIN"}
                                pic={sixPin}
                                onClick={() => { 
                                    setLength(6);
                                    setState(2);
                                }}
                            />
                        </div>
                    }
                    {state === 2 &&
                        <div>
                            <Text>Enter your <b>new</b> PIN code</Text>
                            <PinControl 
                                verify={false} 
                                goNext={() => setState(3)} 
                                goBack={() => {}}
                                length={length}    
                                ifGuessable={() => setState(4)}
                                submitPin={false}
                                mobile={true}
                            />
                        </div>
                    }  
                    {state === 3 &&
                        <div>
                            <Text>Verify your new PIN code</Text>
                            <PinControl 
                                verify={true} 
                                goNext={() => go.security()} 
                                goBack={() => setState(2)}
                                length={length}    
                                ifGuessable={() => {}}
                                submitPin={false}
                                mobile={true}
                            />
                        </div>
                    }  
                    {state === 4 &&
                        <div>
                            <Text style={{margin: "30px 0px", color: "#FC7B7B"}}><b>This PIN code can be easily guessed</b></Text>
                            <Text style={{marginBottom: "6rem", fontWeight: "bold"}}>
                                To keep your data private we strongly recommend you choose a different PIN code.
                            </Text>
                            <ButtonContainer>
                                <BlueButton style={{fontSize: "17px", borderRadius: "36px"}} text={"Change PIN Code"} onClick={() => setState(1)} />
                                <Link onClick={() => setState(3)}>Use Anyway</Link>
                            </ButtonContainer>
                        </div>
                    }      
                </Inner>
            </Container>
        </UIContainer>
    )
})

export default EditPin;