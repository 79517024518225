import alert from "libs/Alert";
import { generalRequest } from "libs/newHttp";
import { Appointment } from "pages/mobile/domain/Appointment/types";
import { action, makeAutoObservable, observable } from "mobx";
import { getConsultMethodsAPI, getReasonsAPI } from "./appointmentStore.api";
import { IReasonForVisit } from './appointmentStore.type';


export class AppointmentStore {
    constructor(){
        makeAutoObservable(this);
    }
    
    @observable loading = false;
    @observable receivedReasons = false;
    @observable consultMethod = null
    @observable Reasons: IReasonForVisit = {
        Services: [],
        Symptoms: [],
        ShowingWarning: false,
        WarningMessage: [],
        AllowContinueWithCode2: false,
        Code2WarningMessage: [],
        NoSymptomDefaultByPassCode: 0
    }

    @action.bound
    async getReasons(orgId: string) {
        this.loading = true;
        await generalRequest.bind(this)({
            api: getReasonsAPI(orgId),
            afterLoaded: (data: any) => {
                this.loading = false;
                this.receivedReasons = true;
                this.Reasons = data;
            },
            afterFailed: (err: any) => {
                this.loading = false;
                alert.error(err.response.data.Message);
                this.receivedReasons = false;
            }
        })
    }

    @action.bound
    async getConsultMethods(orgId: string) {
        this.loading = true;
        await generalRequest.bind(this)({
            api: getConsultMethodsAPI(orgId),
            afterLoaded: (data: any) => {
                this.loading = false;
                this.consultMethod = data;
            },
            afterFailed: (err: any) => {
                this.loading = false;
                alert.error(err.response.data.Message);
            }
        })
    }

    @observable bookedAppointments: Appointment[] = []
    @observable pastAppointments: Appointment[] = []

    @action.bound
    getAppointment(id: string, status: 'booked' | 'past') {
        switch(status){
            case 'booked':
                return this.bookedAppointments.find(a => a.Id === id);
            case 'past':
                return this.pastAppointments.find(a => a.Id === id);
            default:
                throw new Error('No data found');
        }
    }
    @action.bound
    loadAppointments(data: Appointment[], status: 'booked' | 'past'){
        if(!data) {
            return;
        }
        switch(status){
            case 'booked':
                this.bookedAppointments = [...data];
                break;
            case 'past':
                this.pastAppointments = [...data];
                break;
            default:
                throw new Error('No data found');
        }
    }
}