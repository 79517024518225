import { call, takeEvery, put } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';
import { GenericParserHttp } from '../../libs/Http';
import { checkAuth } from 'libs/misc';

const api = id =>
  GenericParserHttp.get('consumer/appointment/booked/past', { token: id }).then(
    ({ body }) => body,
  );
// const delay = (ms) => new Promise(resolve => window.setTimeout(() => resolve(true), ms));

function* getPastAppointments({ payload: { id } }) {
  try {
    // yield delay(3000);
    const appointment = yield call(api, id);
    yield put(actions.getPastAppointmentsSuccess(appointment));
  } catch (err) {
    checkAuth(err);
    yield put(actions.getPastAppointmentsFailure(err));
  }
}

function* pastAppointmentsSaga() {
  yield takeEvery(actionTypes.getPastAppointments, getPastAppointments);
}

export { pastAppointmentsSaga };
