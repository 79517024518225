import React from 'react';
import moment from 'moment';

import styles from './currentAppointment.module.css';

const getStyleColor = status => {
  if (status === 'CONFIRMED') return '#23C373';
  if (status === 'CANCELED') return '#FF6A65';
  return '#33CCCC';
};

const getConsultationMethod = (note) => {
  if (!note) return "";

  const length = note.indexOf('/');
  const consultation = note.substring(0, length).toLowerCase();
  if (consultation.includes("person") || consultation.includes("clinic") || consultation.includes("practice") || consultation.includes("physical")) return "by in Person Consult";
  if (consultation.includes("phone")) return "by Phone Consult";
  if (consultation.includes("video") || consultation.includes("virtual")) return "by Video Consult";

  return "";
}

const CurrentAppointment = (appt, onClick) => (
  <div className={styles.container}>
    <div className={styles.innerContainer} onClick={onClick(appt.Id)}>
      <img
        className={styles.image}
        src={appt.ProviderPictureUrl}
        alt={appt.ProviderName}
      />
      <div>{appt.ProviderName}</div>
      <div className={styles.practiceName}>at {appt.PracticeName}</div>
      <div className={styles.practiceName} style={{fontWeight: "600"}}>{getConsultationMethod(appt.Note)}</div>
      <div className={styles.date}>
        {moment(appt.Time).format('dddd Do MMMM')}
      </div>
      <div className={styles.time}>{moment(appt.Time).format('h:mm a')}</div>
      <div
        className={styles.status}
        style={{ backgroundColor: getStyleColor(appt.Status) }}
      >
        {appt.Status && appt.Status.toLowerCase()}
      </div>
      <div className={styles.bookedFor}>{`Booked for ${appt.FirstName} ${
        appt.LastName
      }`}</div>
    </div>
  </div>
);

export default CurrentAppointment;
