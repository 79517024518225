import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import UIContainer from 'components/UIContainer';
import RouteBar from 'components/DesktopComponents/RouteBar';
import TabBar from 'components/DesktopComponents/TabBar';
import PinControl from 'Identity/DesktopIdentityPage/components/PinControl';
import { ReactComponent as Lock } from 'svgs/blueLock.svg';
import { go } from 'routes';
import { useComponentDidMount } from 'libs/newHttp';
import { ReactComponent as Four } from 'svgs/4Pin.svg';
import { ReactComponent as Six } from 'svgs/6Pin.svg';
import { ReactComponent as Arrow1 } from 'svgs/rightlinearrow2.svg';
import BlueButton from 'components/DesktopComponents/BlueButton';
import { useAccountStore } from 'store/mobx/mobx';
import { ReactComponent as Arrow } from 'svgs/blueRightCircleArrow.svg'; 

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #F9F9F9;
    overflow-y: auto;
    -ms-overflow-style: none; 
    scrollbar-width: none;  
    &::-webkit-scrollbar {
        display: none;
    }
`
const Title = styled.div`
    text-transform: uppercase;
    font-size: 13px;
    margin-top: 1rem;
    text-align: left;
    margin-bottom: 1rem;
`
const Container = styled.div`
    width: 533px;
    margin 1rem auto;
`
const Inner = styled.div`
    height: 630px;
    width: 534px;
    background-color: white;
    margin: auto;
    margin-left: 7px;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 1px 1px 3px lightgray;
    padding: 0px 25px;
`
const Text = styled.div`
    text-align: center;
    color: #07A3C8;
    font-size: 17px;
    margin-bottom: 1rem;
`
const Button = styled.div`
    background-color: #07A3C8;
    color: white;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 13px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
`
const Link = styled.div`
    font-size: 13px;
    color: #07A3C8;
    margin: 1.5rem auto;
    cursor: pointer;
`
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const FlexH = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
`
const Line = styled.div`
    border-bottom: 2px solid #DADADA;
    width: 100%;
    margin: 73px 0px;
`

const EditPin: React.FC = observer(() => {
    const store = useAccountStore();
    const [length, setLength] = useState(4);

    const [state, setState] = useState(0);

    useComponentDidMount(() => {
        store.getPinCodeLength();
        if(store.pinCodeLength === 0) {
            setState(1);
        }
    })
    return (
        <UIContainer isFetching={store.loading}>
            <Outer>
                <RouteBar 
                    lowLevel={"Security"}
                    lowLink={() => go.security()}
                />
                <TabBar type={0} id={4} />
                <Container>
                    <Title>CHANGE PIN CODE</Title>
                    <Inner>
                        <Arrow onClick={go.security} style={{ overflow: "visible", cursor: "pointer", transform: "rotate(180deg)", position: "absolute", top: "1rem", left: "1rem"}} />
                        <Lock style={{margin: "4rem auto", marginBottom: "2rem"}} />
                        {state === 0 &&
                            <div>
                                <Text>Enter your <b>current</b> PIN code</Text>
                                <PinControl 
                                    verify={true} 
                                    goNext={() => setState(1)} 
                                    goBack={() => {}}
                                    length={store.pinCodeLength}    
                                    ifGuessable={() => {}}
                                    submitPin={true}
                                />
                            </div>
                        }
                        {state === 1 &&
                            <div>
                                <Text style={{marginBottom: "70px"}}>Set your security PIN. Two options:</Text>
                                <Text>4 digit PIN</Text>
                                <FlexH style={{marginTop: "15px"}}>
                                    <Four style={{width: "100%", marginLeft: "112px"}} />
                                    <Button data-test="button-4pin" onClick={() => { 
                                        setLength(4);
                                        setState(2);
                                    }}>
                                        Continue
                                        <Arrow1 style={{transform: "rotate(90deg)", marginLeft: "10px"}}/>
                                    </Button>
                                </FlexH>
                                <Line />
                                <Text>6 digit PIN</Text>
                                <FlexH style={{marginTop: "15px"}}>
                                    <Six style={{width: "100%", marginLeft: "112px"}} />
                                    <Button data-test="button-6pin" onClick={() => { 
                                        setLength(6)
                                        setState(2);
                                    }}>
                                        Continue
                                        <Arrow1 style={{transform: "rotate(90deg)", marginLeft: "10px"}}/>
                                    </Button>
                                </FlexH>
                            </div>
                        }
                        {state === 2 &&
                            <div>
                                <Text>Enter your <b>new</b> PIN code</Text>
                                <PinControl 
                                    verify={false} 
                                    goNext={() => setState(3)} 
                                    goBack={() => {}}
                                    length={length}    
                                    ifGuessable={() => setState(4)}
                                    submitPin={false}
                                />
                            </div>
                        }  
                        {state === 3 &&
                            <div>
                                <Text>Verify your new PIN code</Text>
                                <PinControl 
                                    verify={true} 
                                    goNext={() => go.security()} 
                                    goBack={() => {}}
                                    length={length}    
                                    ifGuessable={() => {}}
                                    submitPin={false}
                                />
                            </div>
                        }  
                        {state === 4 &&
                            <div>
                                <Text style={{margin: "30px 0px", color: "#FC7B7B"}}><b>This PIN code can be easily guessed</b></Text>
                                <Text style={{marginBottom: "6rem", fontWeight: "bold"}}>
                                    To keep your data private we strongly recommend you choose a different PIN code.
                                </Text>
                                <ButtonContainer>
                                    <BlueButton style={{fontSize: "17px", borderRadius: "36px"}} text={"Change PIN Code"} onClick={() => setState(1)} />
                                    <Link onClick={() => setState(3)}>Use Anyway</Link>
                                </ButtonContainer>
                            </div>
                        }      
                    </Inner>
                </Container>
            </Outer>
        </UIContainer>
    )
})

export default EditPin;