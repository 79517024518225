import React, { FC, PropsWithChildren } from 'react'
import { RadioGroupContext, useRadioGroupContext } from './useRadioGroupContext';
import styles from './index.module.css';


interface RadioGroupProps{
    checkedValue?: string;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const RadioGroup = ({
  children, 
  checkedValue, 
  handleChange
}: PropsWithChildren<RadioGroupProps>) => {
    
    return (
        <RadioGroupContext.Provider value={{checkedValue, handleChange}}>
            <div className={styles.radioGroup}>
                {children}
            </div>
        </RadioGroupContext.Provider>
    )
};

interface RadioItemProps {
    value: string;
    name: string;
}

export const RadioItem: FC<RadioItemProps> = ({name, value}) => {
    let {checkedValue, handleChange} = useRadioGroupContext();
    return (
        <>
            <input
             id={value}
             type='radio'
             style={{appearance: 'none', display: 'none'}}
             value={value}
             checked={checkedValue === value}
             onChange={handleChange}
             />
             <label htmlFor={value}>{name}</label>
             
        </>
    );
}
