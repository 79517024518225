import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CSSTransitionGroup } from 'react-transition-group';

import styles from './loadingSpinner.module.css';
import { ReactComponent as LoadingIcon } from '../../svgs/spinner.svg';

const Loading = ({
  isFetching,
  children,
  containerClassName,
  iconClassName,
  overlayClassName,
  contentRef,
  component,
  style,
}) => {
  return (
    <CSSTransitionGroup
      style={style}
      component={component && component}
      transitionName={{
        enter: styles.enter,
        enterActive: styles.enterActive,
        leave: styles.leave,
        leaveActive: styles.leaveActive,
      }}
      transitionEnterTimeout={300}
      transitionLeaveTimeout={500}
      className={classnames(styles.loadingContainer, containerClassName)}
      ref={contentRef}
    >
      {isFetching ? (
        <div className={classnames(styles.overlayContainer, overlayClassName)}>
          <LoadingIcon
            key="spinner"
            className={classnames(styles.loadingIcon, iconClassName)}
          />
        </div>
      ) : null}
      {children}
    </CSSTransitionGroup>
  )
};

Loading.propTypes = {
  isFetching: PropTypes.bool,
  children: PropTypes.node,
  containerClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  component: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  contentRef: PropTypes.func,
  overlayClassName: PropTypes.string,
};
export default Loading;
export { styles };
