import React, { useState, useEffect } from 'react';

import styles from './dropdown.module.css';
import { ReactComponent as Arrow } from 'svgs/dropdownArrow.svg';
import { useComponentDidMount } from 'libs/newHttp';

function Dropdown({ items, setMethod }) {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(items[0])
    const toggle = () => setOpen(!open);

    useComponentDidMount(() => {
        setMethod(items[0].Code);
    })

    function handleOnClick(item) {
        setSelected(item);
        setMethod(item.Code);
    }

    return (
        <div className={styles.container}>
            {items[0] &&
                <div 
                    tabIndex={0} 
                    className={styles.dd} 
                    role="button" 
                    onKeyPress={() => toggle(!open)} 
                    onClick={() => toggle(!open)}
                >
                    {open && (
                        <ul className={styles.list}>
                            {items.map(item => {
                                return (
                                    <li className={styles.item} key={item.Code} onClick={() => handleOnClick(item)}>
                                        <button className={styles.button} type="button">
                                            <span>{item.Display}</span>
                                        </button>
                                    </li>
                                )
                            })}
                        </ul>
                    )}
                    <div style={{display: "flex", flexDirection: "row", cursor: "pointer"}}>
                        <div className={styles.selected}>{selected ? selected.Display : items[0].Display}</div>
                        <Arrow style={{width: "10%", alignSelf: "center", cursor: "pointer"}}/>
                    </div>
                </div>
            }
        </div>
    )
}

export default Dropdown;