import React from 'react';

const Avatar = ({iWidth, iHeight, cWidth, cHeight, img, isSelection, isAccount, isHeader, isSettings}) => {

    let cStyles = {
        overflow: "hidden",
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        borderRadius: "50%",
        border: '2px solid white',
        boxSizing: 'border-box',
        width: cWidth,
        height: cHeight,
    }

    if(isAccount) {
        cStyles = {
            overflow: "hidden",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: "50%",
            width: cWidth,
            height: cHeight,
        }
    } else if (isHeader) {
        cStyles = {
            overflow: "hidden",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: "50%",
            width: cWidth,
            height: cHeight,
            margin: "1rem"
        }
    } else if (isSelection) {
        cStyles = {
            overflow: "hidden",
            marginRight: "auto",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: "50%",
            width: cWidth,
            height: cHeight,
            minWidth: "2.471rem",
            marginLeft: "0.3rem",
        }
    } else if (isSettings) {
        cStyles = {
            overflow: "hidden",
            marginLeft: "auto",
            marginRight: "1rem",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: "50%",
            width: cWidth,
            height: cHeight,    
        }
    }
    return (
        <div style={cStyles}>
            <img src={img} alt={"img"} style={{width: iWidth, height: iHeight}}/>
        </div>
    );   
}

export default Avatar;