import { CalendarProps, ValueProps } from 'pages/mobile/domain/Appointment/types';
import React from 'react';
import styles from './CalendarList.module.scss';

const CalendarList: React.FC<ValueProps<CalendarProps>> = ({data}) => {
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.title}>Add to Calendar</div>
        <a 
        className={styles.button}
        href={data.google}
        target="_blank"
        rel="noopener noreferrer">
          Google Calendar
        </a>
        <a
        className={styles.button}
        href={data.general}
        target="_blank"
        rel="noopener noreferrer">
          iCalendar
        </a>
      </div>
    </div>
  )
};

export default CalendarList;