import React from 'react';
import styles from './index.module.scss';
import { ReactComponent as NotFound } from 'svgs/warningCheck.svg';
import { ReactComponent as Verified } from 'svgs/check.svg';
import { ReactComponent as Pending } from 'svgs/orangeTickP.svg';
import { ReactComponent as Phone } from 'svgs/bluePhone.svg';
import { IdentityDetail } from 'types/careCircle';
import { ReactComponent as Tick } from 'svgs/greenTickSmall.svg';
import { PatientStatus } from 'common/constants';

const icons = [
    Verified,
    Pending,
    NotFound
]

type Props = {
    identity: IdentityDetail
}

const IdentityLabel = ({identity, ...rest}: Props) => {
    const { 
        Status: status, 
        StatusIcon: icon, 
        StatusTitle: title, 
        StatusTitleColor: titleColor,
        OrganisationPhone: phone,
        StatusDescription: content,
        StatusDescriptionColor: contentColor,
         } = identity;
    const Icon = icon > 0 ? icons[icon - 1] : '';
  return (
      <>
        {
            Icon && 
            <div className={styles.container} {...rest}>
                <div className={styles.titleRow} style={{color: titleColor}}>
                    <Icon className={styles.icon}/>
                    <span>{title}</span>
                </div>
                <span 
                  className={styles.textRow} 
                  style={{color: contentColor}}>
                    {status === PatientStatus.VERIFIED && <Tick className={styles.tick} />}{content}
                </span>
                {
                    phone && 
                    <a href={`tel:${phone}`} className={styles.phoneNumber}>
                    <Phone />&nbsp;&nbsp;Call {phone}
                    </a>
                }
                
            </div>
        }
      </>
    )
}

export default IdentityLabel;