import React, { lazy } from 'react';
import { isDesktop } from '../../config';
import { ProtectedRoute, push } from '../utils';
import { getSupportPage } from 'libs/utils';

const go = { support: () => !isDesktop() ? window.open(getSupportPage(), "_blank") : push('/support') };

const SupportRoutes = !isDesktop() && [
  <ProtectedRoute
    key="/support"
    path="/support"
    component={lazy(() =>
      import(/* webpackChunkName: "SupportPage" */ '../../SupportPage'),
    )}
  />,
];

export default SupportRoutes;
export { go };
