export const getLoginPageURL = () => {
  const { host } = window.location;
  let result = 'https://login.vensa.com/';

  if (host.includes(':')) {
    result = `//${host}/login`;
  } else if (host.endsWith('uat.vensa.com')) {
    result = 'https://loginuat.vensa.com/';
  } else if (host.endsWith('demo.vensa.com') || host.startsWith('stormdemowebconsumer')) {
    result = 'https://logindemo.vensa.com/';
  } else if (host.endsWith('devtest.vensa.com')) {
    result = 'https://logindevtest.vensa.com/';
  }

  return result;
};

export const getSupportPage = () => 'https://helpme.vensa.com/help-for-patients';