const actionTypes = {
  fetchAllPractices: 'SCRIPT_FETCH_ALL_PRACTICES',
  fetchAllPracticesSuccess: 'SCRIPT_FETCH_ALL_PRACTICES_SUCCESS',
  fetchAllPracticesFailure: 'SCRIPT_FETCH_ALL_PRACTICES_FAILURE',
  search: 'SCRIPT_SEARCH_PRACTICES',
  searchSuccess: '_SCRIPTSEARCH_PRACTICE_SUCCESS',
  searchMeds: 'SCRIPT_SEARCH_MEDICATIONS',
  searchMedsSuccess: 'SCRIPT_SEARCH_MEDICATIONS_SUCCESS',
  searchMedsFailure: 'SCRIPT_SEARCH_MEDICATIONS_FAILURE',
  clearSelection: 'SCRIPT_CLEAR_SEARCH',
};

const actions = {
  fetchAllPractices: token => ({
    type: actionTypes.fetchAllPractices,
    payload: {
      token,
    },
  }),
  fetchAllPracticesSuccess: practices => ({
    type: actionTypes.fetchAllPracticesSuccess,
    payload: {
      practices,
    },
  }),
  fetchAllPracticesFailure: error => ({
    type: actionTypes.fetchAllPracticesFailure,
    payload: { error },
    error: true,
  }),
  search: (items, query) => ({
    type: actionTypes.search,
    payload: {
      query,
      items,
    },
  }),
  searchSuccess: matched => ({
    type: actionTypes.searchSuccess,
    payload: {
      matched,
    },
  }),
  clearSelection: () => ({
    type: actionTypes.clearSelection,
  }),
  searchMeds: (query = '', token) => ({
    type: actionTypes.searchMeds,
    payload: {
      query,
      token,
    },
  }),
  searchMedsSuccess: meds => ({
    type: actionTypes.searchMedsSuccess,
    payload: {
      meds,
    },
  }),
  searchMedsFailure: error => ({
    type: actionTypes.searchMedsFailure,
    payload: {
      error,
    },
    error: true,
  }),
};

export { actions, actionTypes };
