import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import classnames from 'classnames';

import { actions as searchActions } from '../PracticeSearch/actions/actions';
import { actions } from '../actions/actions';
import { ReactComponent as Cross } from '../../svgs/close.svg';
import PracticeSearch from '../PracticeSearch/PracticeSearch';
import UIContainer from '../../components/UIContainer';
import DesktopView from '../PracticePreview/components/DesktopView';
import { go } from '../../routes';
import { isDesktop } from '../../config';
import styles from './addPractice.module.css';
import { useComponentDidMount } from 'libs/newHttp';
import { usePracticesStore } from 'store/mobx/mobx';

const AddPractice = observer((goToPreview, toggle) => {
    const [practicePreviewUrl, setPracticePreviewUrl] = useState(null);
	const store = usePracticesStore();

	useComponentDidMount(() => {
	})

	const handleClick = practice => {
		if (isDesktop()) setPracticePreviewUrl(practice.UrlName);
		else goToPreview(practice);
	};
  	const clearPractice = () => {
    	practicePreviewUrl(practicePreviewUrl);
  	};

    const list = (
		<PracticeSearch	userHasPractices={store.userPractices.length !== 0} onClose={() => go.practices()}/>
    );
    return practicePreviewUrl ? (
		<DesktopView
			practiceId={practicePreviewUrl}
			goBack={clearPractice}
			linkToPractice={store.linkPractice}
			toggle={toggle}
			getAllPractices={store.getAllPractices}
		/>
    ) : (
		<div className={styles.listContainer}>
			{/* <div className={styles.headerContainer}>
				<Cross className={styles.cross} onClick={toggle} />
				<div className={styles.heading}>Add a Practice</div>
			</div> */}
			<div
				className={classnames({
					[styles.overlay]: store.loading
				})}
			>
				{isDesktop() ? (
					list
				) : (
					<UIContainer heading="Add a Practice" goBackFn={go.practices}>{list}</UIContainer>
				)}
			</div>
		</div>
    );
})

const mapStateToProps = state => ({
  token: state.loginReducer.token,
  isFetching:
    state.practiceSelectionReducer.isFetching ||
    state.practiceLinkingReducer.isFetching,
});
const mapDispatchToProps = dispatch => ({
  getAllPractices: bindActionCreators(searchActions.getAllPractices, dispatch),
  // eslint-disable max-len
  linkToPractice: token => (practice, onFinishAdd, shouldAdd ) =>
    bindActionCreators(actions.linkPractice, dispatch)(practice, onFinishAdd, shouldAdd, token),
  clearPracticeSelection: bindActionCreators(
    searchActions.clearPracticeSelection,
    dispatch,
  ),
  // eslint-enable- max-len
  goToPreview: e => go.practicePreview(e.UrlName),
});


export default connect(
	mapStateToProps,
	mapDispatchToProps,
  )(AddPractice);
