import React, { useState } from 'react';
import styled from 'styled-components';
import Webcam from 'react-webcam';

import over from 'images/faceOverlay.png';
import { useIdentityStore } from 'store/mobx/mobx';
import { ReactComponent as Spinner } from 'svgs/spinner.svg';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`
const Button = styled.div`
    background-color: white;
    color: #07A3C8;
    border: 1px solid #07A3C8;
    border-radius: 4px;
    width: 218px;
    height: 66px;
    margin: auto;
    font-size: 16px;
    text-align: center;
    line-height: 34px;
    padding: 1rem 0rem;
    cursor: pointer;
    position: relative;
    z-index: 10;
    transform: translateY(-80px);
`
const Text = styled.div`
    color: white;
    position: absolute;
    font-size: 16px;
    text-align: center;
    width: 100%;
    z-index: 10;
    margin-top: 25px;
`
const Image = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
`

interface Props {
    onClick: () => void;
    onError: () => void;
}
const Camera: React.FC<Props> = ({onClick, onError}) => {
    const store = useIdentityStore();
    const cam = React.createRef<Webcam>();
    const [loading, setLoading] = useState(true);
    return (
        <Container>
            {!loading && <Text>Take a selfie</Text>}
            {!loading && <Image src={over} alt="img" />}
            {loading && <Spinner  style={{position: "absolute", margin: "auto", width: "100%", height: "100%"}}/>}
            <Webcam 
                onUserMedia={() => {
                    setLoading(false);
                }}
                onUserMediaError={() => {
                    if (typeof onError === 'function') {
                        onError();
                    }
                }}
                style={{
                    width: "100%",
                }}
                screenshotFormat={"image/jpeg"} 
                ref={cam} 
            />
            {!loading && 
                <Button onClick={() => {
                    store.addPhoto(cam.current!.getScreenshot());
                    onClick();
                }}>Take Photo</Button>
            }
        </Container>
    )
}

export default Camera;