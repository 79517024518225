import React from 'react';
import { observer } from 'mobx-react-lite';
import AddCard from 'vScript/Payment/AddCard';
import { go } from 'routes';

const AddWalletCreditCardPage: React.FC = observer(() => {
  return <AddCard hideSaveCard={true} goBack={go.payments} handleNext={go.payments} />;
});

export default AddWalletCreditCardPage;
