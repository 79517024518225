import React, { useState } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';
import { KeepFocusProps } from 'types';

export type InputFieldProps = {
  id: string;
  title?: string;
  onChange: (name: string, value: string) => void;
  initialValue?: string;
  touch?: boolean;
  readonly?: boolean;
  placeholder?: string;
} & KeepFocusProps;

const InputField = (
  {
    id,
    title,
    onChange,
    doFocus,
    doBlur,
    focusId,
    initialValue,
    touch = false,
    readonly,
    placeholder,
    ...rest
  }: InputFieldProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(id, e.target.value);
      setValue(e.target.value);
    };
    const [value, setValue] = useState(initialValue);

  return (
    <input 
      ref={input => {
          if(input && focusId === input.id) {
            input.focus();
          }
        }}
      className={cn(styles.input, {[styles.touch]: touch})} 
      onChange={handleChange} id={id}
      onFocus={doFocus}
      onBlur={doBlur}
      value={value}
      readOnly={readonly}
      placeholder={placeholder}
      {...rest}/>
  )
};

export default InputField;