import { actionTypes } from '../actions/paymentActions';

const INITIAL_STATE = {
  isProcessing: false,
  success: false,
  error: null,
};

const paymentFormReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.sendPaymentInfo: {
      return {
        isProcessing: true,
        success: false,
        error: null,
      };
    }
    case actionTypes.sendPaymentInfoSuccess: {
      return {
        isProcessing: false,
        success: true,
        error: null,
      };
    }
    case actionTypes.sendPaymentInfoFailure: {
      return {
        isProcessing: false,
        success: false,
        error: action.payload.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default paymentFormReducer;
