import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import UIContainer from 'components/UIContainer';
import { go } from 'routes';

import styles from './recoverPin.module.css';
import lock from 'images/lockred.png'
import { useState } from 'react';
import { replaceMiddle } from 'libs/misc';
import { useComponentDidMount } from 'libs/newHttp';
import { useAccountStore } from 'store/mobx/mobx';
import NDigitInput from 'components/nDigitInput/index';
import moment from 'moment';
import ResendButton from 'HealthFeed/AccountVerificationPage/ResendButton';
import Alert from '../../libs/Alert';
import PinCodeFlow from './PinCodeFlow';

const Container = styled.div`
    height: 100%;
    margin-bottom: 1rem;
`
const Card = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #00000029;
    padding-top: 1rem;
    background-color: white;
    overflow-y: scroll;
    position: relative;
`
const Lock = styled.img`
    display: block;
    margin: auto;
    height: 132px;
    width: 140px;
`
const Text = styled.div`
    font-size: 17px;
    text-align: center;
    color: #6C6C6C;
    margin: 2rem 0rem;
    padding: 0rem 1.5rem;
`
const BlueButton = styled.div`
    width: 60%;
    font-size: 17px;
    color: white;
    background-color: #07A3C8;
    padding: 1.5rem 0rem;
    text-align: center;
    display: block;
    margin: 2rem auto;
    border-radius: 50px;
`
const Link = styled.div`
    color: #07A3C8;
    font-size: 15px;
    margin: 1.3rem 0rem;
    text-align: center;
`
const ButtonContainer = styled.div`
    position: absolute;
    bottom: 1rem;
    width: 100%;
`
const MethodsContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const Label = styled.span`
    margin: 0rem 1rem;
    font-size: 17px;
    font-style: italic;
    color: #07A3C8;
`

const Container2 = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #00000029;
    padding-bottom: 3rem;
`
const Title2 = styled.div`
    text-align: center;
    font-size: 21px;
    font-weight: 300;
    padding-top: 1rem;
`
const UserEmail = styled.div`
    font-weight: 500;
`

const RecoverPin: React.FC = observer(() => {
    const store = useAccountStore();
    const [state, setState] = useState(0);
    const [method, setMethod] = useState("");
    const [isMobile, setIsMobile] = useState(false);

    const resendCode = (method: string) => {
        store.getVerificationCodeSentToRecoveryMethod(isMobile, method, () => {})
    }

    const onChangeDigit = (checkCode: (value: string) => void) => (digits: string) =>
        digits.length === 4 && checkCode(digits);


    useComponentDidMount(() => {
        store.getRecoveryMethods();
    })

    return (
        <UIContainer heading={state < 3 ? "Reset PIN Code" : "Enter new PIN code"} goBackFn={state === 0 ? () => go.pin(0) : () => setState(0)} isFetching={store.loading}>
            <Container>
                {state === 0 &&
                    <Card>
                        <Lock src={lock} />
                        <Text>We can reset your PIN code based on a verification code that we can send to your preferred contact method by email/mobile.</Text>
                        <ButtonContainer>
                            <BlueButton onClick={() => setState(1)}>Reset my PIN Code</BlueButton>
                            <Link onClick={() => go.pin(0)}>Cancel</Link>
                        </ButtonContainer>
                    </Card>
                }
                {state === 1 &&
                    <Card>
                        <Lock src={lock} />
                        <Text>We can send you a verification code to re-activate your account. Please select the recovery method:</Text>
                        <MethodsContainer>
                            <div className={styles.container}>
                                {store.recoveryMethods.MobileNumber !== "" &&
                                    <div className={styles.round} onClick={() => {
                                        setMethod(store.recoveryMethods.MobileNumber)
                                        setIsMobile(true);
                                    }}>
                                        <input type="checkbox" id="checkbox0" checked={method === store.recoveryMethods.MobileNumber}/> 
                                        <label htmlFor="checkbox0"></label>
                                        <Label>{replaceMiddle(store.recoveryMethods.MobileNumber, store.recoveryMethods.MobileNumber.length - store.recoveryMethods.MobileNumber.length/2 )}</Label>
                                    </div>
                                }
                                {store.recoveryMethods.Email !== "" &&
                                    <div className={styles.round} onClick={() => {
                                        setMethod(store.recoveryMethods.Email)
                                        setIsMobile(false);
                                    }}> 
                                        <input type="checkbox" id="checkbox1" checked={method === store.recoveryMethods.Email}/>
                                        <label htmlFor="checkbox1"></label>
                                        <Label>{replaceMiddle(store.recoveryMethods.Email, store.recoveryMethods.Email.length - store.recoveryMethods.Email.length/2)}</Label>
                                    </div>
                                }
                                {store.recoveryMethods.RecoveryEmail !== "" && store.recoveryMethods.RecoveryEmail !== null &&
                                    <div className={styles.round} onClick={() => {
                                        setMethod(store.recoveryMethods.RecoveryEmail)
                                        setIsMobile(false);
                                    }}> 
                                        <input type="checkbox" id="checkbox2" checked={method === store.recoveryMethods.RecoveryEmail}/>
                                        <label htmlFor="checkbox2"></label>
                                        <Label>{replaceMiddle(store.recoveryMethods.RecoveryEmail, store.recoveryMethods.RecoveryEmail.length - store.recoveryMethods.RecoveryEmail.length/2)}</Label>
                                    </div>
                                }
                            </div>
                        </MethodsContainer>
                        <BlueButton onClick={() => {
                            if (method === "") { return; }
                            store.getVerificationCodeSentToRecoveryMethod(isMobile, method, () => setState(2))
                        }}>Send Activation code</BlueButton>
                    </Card>
                }
                {state === 2 &&
                    <Container2>
                        <Title2>
                            Enter the 4-digit code sent to you at 
                            <UserEmail>{method}</UserEmail>
                        </Title2>
                        <NDigitInput 
                            digits={4}
                            isError={null}
                            clearError={() => console.log("cleared")}
                            onChange={onChangeDigit((value: string) => {
                                store.postVerificationCodeSentToRecoveryMethod(value, isMobile, method, () => setState(3));
                                //store.setVerifyCode(value, 1);
                                //store.checkVerifyEmail(email, isPrimary, goBack, () => Alert.error("Oops! It looks like you've typed the wrong number."), update)
                            })}
                        />
            
                        <ResendButton
                            onClick={() => resendCode(method)}
                            countdownStartTime={moment()}
                            milliSecondsToWait={61000}
                            isFetching={store.loading}
                        />
                    </Container2>
                }
                
                {state === 3 &&
                    <PinCodeFlow />
                }
            </Container>
        </UIContainer>
    )
})

export default RecoverPin;