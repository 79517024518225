import React from 'react';
import { isDesktop } from '../../config';
import { ProtectedRoute, push } from '../utils';
import SecurityPage from 'Security/SecurityPage';
import PinCodePage from 'PinCode/PinCodePage';
import RecoverPinMobile from 'PinCode/components/RecoverPin';
import DesktopPinCode from 'PinCode/DesktopPinCode/DesktopPinCode';
import EditPin from 'Security/DesktopSecurity/EditPin';
import EditPinMobile from 'Security/components/EditPin';
import { Route } from 'react-router';

import DesktopSecurity from 'Security/DesktopSecurity/DesktopSecurity';
import AccountSuspended from 'PinCode/components/AccountSuspended';
import RecoverAccountFlow from 'PinCode/components/RecoverAccountFlow';

const PATHS = {
  SECURITY: '/security',
  PIN: '/pin',
  EDIT_PIN: '/edit-pin',
  RECOVER_PIN: '/recover-pin',
  SUSPENDED_ACCOUNT: '/suspended-account',
  RECOVER_SUSPENDED_ACCOUNT: '/recover-account'
};

const go = {
  security: () => push(PATHS.SECURITY),
  pin: (goNext) => push({
    pathname: PATHS.PIN,
    state: { goNext }
  }),
  editPin: (newState) =>  {
    push({
      pathname: PATHS.EDIT_PIN,
      state: { newState } 
    })
  },
  recoverSuspendedAccount: (email) => push({
    pathname: PATHS.RECOVER_SUSPENDED_ACCOUNT,
    state: { email }
  }),
  recoverPin: () => push(PATHS.RECOVER_PIN),
  suspendAccount: (email) => push({
    pathname: PATHS.SUSPENDED_ACCOUNT,
    state: { email }
  }),
  searchPractice: (onFinishAdd, shouldAdd) => {
    push({
        pathname: '/identity-search-practice',
        state: { onFinishAdd, shouldAdd }
})},
};

const SecurityRoutes = [
  <Route
    exact
    key={PATHS.SUSPENDED_ACCOUNT}
    path={PATHS.SUSPENDED_ACCOUNT}
    component={AccountSuspended}
  />, 
  <Route
    exact
    key={PATHS.RECOVER_SUSPENDED_ACCOUNT}
    path={PATHS.RECOVER_SUSPENDED_ACCOUNT}
    component={RecoverAccountFlow}
  />, 
  <ProtectedRoute
    exact
    key={PATHS.SECURITY}
    path={PATHS.SECURITY}
    component={!isDesktop() ? SecurityPage : DesktopSecurity}
  />,
  <Route
    exact
    key={PATHS.PIN}
    path={PATHS.PIN}
    component={!isDesktop() ? PinCodePage : DesktopPinCode}
  />,
  <Route
    exact
    key={PATHS.RECOVER_PIN}
    path={PATHS.RECOVER_PIN}
    component={!isDesktop() ? RecoverPinMobile : RecoverPinMobile}
  />,
  <ProtectedRoute
    exact
    key={PATHS.EDIT_PIN}
    path={PATHS.EDIT_PIN}
    component={!isDesktop() ? EditPinMobile : EditPin}
  />,
]

export default SecurityRoutes;
export { go };
