import { actionTypes } from '../actions/actions';

const INITIAL_STATE = {
  isFetching: false,
  options: [],
  message: null,
  error: null,
  terms: null,
  acBalance: 0
};

const pickUpOptionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.fetchPickUpOptions: {
      return {
        isFetching: true,
        options: [],
        error: null,
        acBalance: 0,
      };
    }
    case actionTypes.fetchPickUpOptionsDependant: {
      return {
        isFetching: true,
        options: [],
        error: null,
        acBalance: 0,
      };
    }
    case actionTypes.fetchPickUpOptionsSuccess: {
      return {
        isFetching: false,
        options: action.payload.options.PricingOptions,
        message: action.payload.options.PickupMessage,
        terms: action.payload.options.TransactionTerms,
        error: null,
        acBalance: action.payload.options.AcBalance,
      };
    }
    case actionTypes.fetchPickUpOptionsFailure: {
      return {
        isFetching: false,
        options: [],
        error: action.payload.error,
        acBalance: 0,
      };
    }
    default: {
      return state;
    }
  }
};

export default pickUpOptionsReducer;
