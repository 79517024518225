import moment from 'moment';
import React from 'react';
import { CommentType, IImmunisation, ILabResult } from 'store/mobx/myHealthStore/myHealthStore.type';
import styled from 'styled-components';

import rtfToHtml from '@iarna/rtf-to-html';
import { useState } from 'react';
import { ReactComponent as Empty } from 'svgs/patientSilhouette.svg';
import { AddComment } from 'MyHealthPage/components/AddComment';
import { useMyHealthStore } from 'store/mobx/mobx';

const Container = styled.div`
    height: 100%;
    padding: 0rem 2rem;
    padding-top: 1.5rem;
    width: 100%;
`
const Header = styled.div`
    display: flex;
    flex-direction:  column;
    position: relative;
    width: 100%;
`
const Title = styled.div`
    font-size: 15px;
    font-weight: bold;
    padding-bottom: 0;
    text-decoration-color: #DADADA;
    margin-bottom: 0.3rem;
`
const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid #F0F0F0;
    margin: 1rem 0;
    margin-top: 0.5rem;
`
const Result = styled.div`
    font-weight: 300;
`
const Subheading = styled.div`
    font-style: italic;
    font-size: 11px;
    margin: 2px 0;
`
const Pic = styled.img`
    position: absolute;
    left: 0; 
    right: 0;
    top: 0;
    bottom: 0; 
    margin: auto;
    border-radius: 50%; 
    width: 30px; 
`
const NameOfImm = styled.div`
    font-size: 19px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    margin: 0.5rem;
`
const AddCommentContainer = styled.div`
    color: #07A3C8;
    font-size: 14px;
    cursor: pointer;
    padding: 1rem 0rem;
    width: 100%;
    margin: auto;
`

interface IProps {
    item: IImmunisation
}
const ImmunisationOpen: React.FC<IProps> = ({item}) => {
    const immunText = 
    `Batch Number: ${item.BatchNumber}\t` + `Expiry: ${moment(item.Expiry).format("DD/MM/YYYY")}\t` + `Route: ${item.Route}\t` + `Site: ${item.Site}`  
    const myHealthStore = useMyHealthStore();
    return (
        <Container>
            <Header>
                <Title>Vaccination details</Title>
                <Subheading>Recorded by {item.OrganisationName}</Subheading>
                <Subheading>{moment(item.DateTime).format("DD/MM/YYYY")} - {moment(item.DateTime).format("h:mm a")}</Subheading>
                {item.ProfilePictureURL ?
                    <Pic src={item.ProfilePictureURL} />
                    :
                    <Empty style={{ 
                        position: "absolute", 
                        left: "0", 
                        right: "0", 
                        top: "0",
                        bottom: "0",
                        margin: "auto",
                        width: "30px" 
                    }}/>
                }
            </Header>
            <Line />
            <NameOfImm>{item.NameOfImmunisation}</NameOfImm>
            Summary:
            <Result>
                {immunText.split('\t').map(str => <p>{str}</p>)}
            </Result>
            <Line style={{margin: "0"}}/>
            <AddCommentContainer>
                <AddComment
                    onSubmit={myHealthStore.postNewComment.bind(myHealthStore)}
                    refreshList={myHealthStore.getImmunisations.bind(myHealthStore)}
                    commentType={CommentType.Immunisation}
                    itemToShow={item}
                    itemId={item.Id}
                    label="+ Add comment for yourself"
                />
            </AddCommentContainer>
        </Container>
    )
}

export default ImmunisationOpen;