import { useAxios } from 'apiHooks/useAxios';
import { AxiosResult } from 'apis/axios';
import React from 'react';

export interface CancelValidation {
    CancellationNote: string;
    iconUrl: string;
    CancellationHour: number;
    Phone: string;
    Name: string;
}

export const useValidation = (id: string): AxiosResult<CancelValidation> => {
    const [{data, loading, error}] = useAxios<CancelValidation>(
        {
            url: `/consumer/appointment/session/${id}/abletocancel`,
            method: 'POST'
        }
    );
    return {
        data,
        loading,
        error
    }
}

export const useCancelAppointment = (id: string, reason: string): AxiosResult<void> => {
    const [{data, loading, error}] = useAxios<void>(
        {
            url: `consumer/appointment/session/${id}`,
            data: { reason },
            method: 'DELETE'
        }
    );
    return {
        data,
        loading,
        error
    }
}