import React from 'react';
import OpenableCard from '../components/OpenableCard';
import RedirectableCard from '../components/RedirectableCard';
import { Appointment, AppointmentProps } from '../types';
import BookedCard from './BookedCard';
import BookedDetailCard from './BookedDetailCard';

const index: React.FC<AppointmentProps & {from: string}> = ({data, from}) => {
    return (
        <RedirectableCard<Appointment> 
            Origin={BookedCard} 
            data={data}
            originPath={from}
            targetPath={`/appointment/booked/${data.Id}`}/>)
};

export default index;
