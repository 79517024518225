import React from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2rem 4rem;
    font-size: 17px;
    color: #585858;
    width: 100%;
    text-align: center;
`
const Title = styled.div`
    font-weight: 600;
    margin: 1rem 0rem;
`
const Text = styled.div`
    font-weight: 300;
    line-height: 30px;
`
interface ILocation {
    terms: string
}

const TermsOfServicePage: React.FC = () => {
    const location = useLocation<ILocation> ();

    const terms = location.state.terms;
    return (
        <Container>
            <Title>Practice Service Terms</Title>
            <Text>
                {terms}
            </Text>
        </Container>
    )
}

export default TermsOfServicePage;