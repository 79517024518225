import AvatarProfile from 'components/shared/common/AvatarProfile';
import StatusCard from 'components/shared/common/StatusCard';
import React, { MouseEvent } from 'react';
import { format2EnDate } from 'services/Format/formatDate';
import { Dependant } from 'types/careCircle';
import { RelationKeys, RELATION_SHIP } from '../constants';
import styles from './index.module.scss';
import { ReactComponent as Menu } from 'svgs/3dotsVertical.svg';
import { ReactComponent as Icon } from 'svgs/profileIcon.svg';
import { Touchable } from 'types';
import cn from 'classnames';

const adapter = (touch?: boolean) => ({
  avatar: {
    diameter: touch ? '80px' : '50px'
  }
});

type Props = {
    data: Dependant;
    handleClick: () => void;
    handleMenuClick?: (e: MouseEvent<SVGSVGElement>) => void;
} & Touchable;

const DependantItem = ({
  data, 
  handleClick, 
  handleMenuClick,
  touch
}: Props) => {
  const adapt = adapter(touch);
  return (
      <StatusCard 
        status={data.IdentityStatus.StatusLabel}
        statusColor={data.IdentityStatus.StatusLabelColor || "#585858"}
        className={cn(styles.container, {[styles.touch]: touch})}
        direction='row'
        handleClick={handleClick}>
        <AvatarProfile
          diameter={adapt.avatar.diameter}
          imgUrl={data.AvatarUrl}
          imgAlt={data.FirstName}
          placeHolder={Icon}>
            <span className={cn(styles.nameRow, {[styles.touch]: touch})}>{`${data.FirstName} ${data.LastName}`}</span>
            <span className={cn(styles.row, {[styles.touch]: touch})}>{format2EnDate(data.DateOfBirth)}</span>
            <span className={cn(styles.row, {[styles.touch]: touch})}>{RELATION_SHIP[data.Relationship as RelationKeys]}</span>
        </AvatarProfile>
        <Menu className={cn(styles.menu, {[styles.touch]: touch})} onClick={handleMenuClick} id={data.Id}/>
      </StatusCard>
  )
}

export default DependantItem;