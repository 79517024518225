import React, { useState } from 'react';
import {ReactComponent as Icon} from 'svgs/declareTick.svg';
import styles from './index.module.scss';
import withModal from 'hocs/withModal';
import ReasonPopup from 'components/domain/careCircle/ReasonPopup';
import { Touchable } from 'types';
import { format2EnDate, formatDate } from 'services/Format/formatDate';

export type StatementProps = {
    fullName: string;
    dateOfBirth: string;
    organisation: string;
} & Touchable

const Statement = ({
    touch,
    fullName,
    dateOfBirth,
    organisation
}: StatementProps) => {

    const [flag, setFlag] = useState(false);
    const Popup = withModal(ReasonPopup, true);
    const handleClick = () => {
        setFlag(true);
    }
    
    const Title = () => touch ? (
        <span>Declaration of Access for {fullName}</span>
    ): (
      <>
        <span>
          Declaration of Access for {fullName}
        </span>
        <span className={styles.birthOfDate}>
          {`DOB: ${format2EnDate(dateOfBirth)}`}
        </span>
      </>
    );
    const date = formatDate(new Date().toDateString());
    return (
        <>
            {flag && <Popup setFlag={setFlag} />}
            <div className={styles.title}>
                <Icon />
                <Title />
            </div>
            <span className={styles.content}>
            I declare that I have been authorised to represent and access health information for {fullName} at {organisation} on {date}. 
            </span>
            <span className={styles.link} onClick={handleClick}>Why do we need this ⓘ</span>
        </>);
};

export default Statement;