import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as scriptActions } from '../../actions/actions';
import { actions } from '../actions/actions';
import { ReactComponent as Plus } from '../../../svgs/plus.svg';
import CtaButton from '../../../components/CtaButton/index';
import PickUpOption from '../PickUpOption/index';
import NavigationBar from '../../RequestScriptDesktop/NavigationBar/index';
import { ReactComponent as Spinner } from '../../../svgs/spinner.svg';
import styles from './pickUpLocationDesktop.module.css';
import { go } from '../../../routes';
import { pricingPropType } from '../../propTypes/index';
import { practiceProfilePropType } from '../../../PracticeProfilePage/propTypes/index';
import { actions as pharmacyActions } from '../AddPharmacy/actions/actions';
import styled from 'styled-components';

const Content = styled.div`
  margin: 0px;
  padding-right: 15px;
  min-width: 340px;
  align-self: center;
  max-height: 249px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }
  
  &::-webkit-scrollbar-track {
    background: #F0F0F0;
    border-radius: 25px;
    margin-left: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #AFAFAF;
    border-radius: 25px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #999; 
  }
`

class PickUpLocationDesktop extends Component {
  static propTypes = {
    setInfo: PropTypes.func.isRequired,
    fetchPickUpOptions: PropTypes.func.isRequired,
    fetchPickUpOptionsDependants: PropTypes.func.isRequired,
    goToConfirm: PropTypes.func.isRequired,
    pickUpOptions: PropTypes.arrayOf(pricingPropType),
    token: PropTypes.string.isRequired,
    dateOfBirth: PropTypes.string.isRequired,
    isFetching: PropTypes.bool,
    practice: practiceProfilePropType.isRequired,
    goToAddPharmacyDesktop: PropTypes.func.isRequired,
    arrowFn: PropTypes.func.isRequired,
    crossFn: PropTypes.func.isRequired,
    goToInfo: PropTypes.func.isRequired
  };
  state = {
    selectedOption: this.props.prevLocation.Option
      ? {
        ...this.props.prevLocation,
        Options: [this.props.prevLocation.Option],
      }
      : {},
    selectedUrgency: 0,
  };
  componentDidMount() {
    if (this.props.userId === this.props.patientId) {
      this.props.fetchPickUpOptions(
        this.props.dateOfBirth,
        this.props.practice.Id,
        this.props.token,
      );
    } else {
      this.props.fetchPickUpOptionsDependants(
        this.props.dateOfBirth,
        this.props.practice.Id,
        this.props.token,
        this.props.patientId
      )
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const pickUpOptions = nextProps.pickUpOptions;
    if (pickUpOptions[0]) {
      if (this.props.prevLocation.Option) {
        if (this.props.prevLocation.PharmacyId) {
          // gets both pharmacy options from the previously selected option
          const option = pickUpOptions.filter(o => {
            return o.PharmacyId === this.props.prevLocation.PharmacyId;
          });

          this.setState({
            selectedOption: option[0],
            selectedUrgency: (this.props.prevLocation.Option.Option + 1) % 2,
          });
        } else {
          // gets both pharmacy options from the previously selected option

          if (this.props.newPharmId) {
            const option = pickUpOptions.filter(o => {
              return o.PharmacyId === this.props.newPharmId;
            });

            this.setState({
              selectedOption: option[0],
              selectedUrgency: (this.props.prevLocation.Option.Option + 1) % 2,
            });
          } else {
            this.setState({
              selectedOption: pickUpOptions[0],
              selectedUrgency: 0,
            })
          }
        }
      } else if (pickUpOptions.length > 0) {
        var defaultOption = pickUpOptions.filter(o => {
          return o.IsDefault;
        });
        if (defaultOption.length > 0) {
          this.setState({ selectedOption: defaultOption[0] });
        } else {
          this.setState({ selectedOption: pickUpOptions[0] });
        }
      }
    }
  }
  handleOrganisationClick = org => () => {
    this.setState({
      selectedOption: org,
    });
  };
  handleUrgencyClick = index => () => {
    this.setState({ selectedUrgency: index });
  };
  handleCtaClick = () => {
    const { selectedOption, selectedUrgency } = this.state;
    if (selectedOption.IsActive) {
      this.props.setInfo('pricing', {
        Name: selectedOption.Name,
        Address: selectedOption.Address,
        PharmacyId: selectedOption.PharmacyId,
        Option: selectedOption.Options[selectedUrgency],
      });
      if (selectedOption.IsPlaceHolder)
        this.props.goToAddPharmacyDesktop();
      else
        this.props.goToConfirm();
    }
  };
  handleAddPharmacy = () => {
    const { selectedOption, selectedUrgency } = this.state;
    if (selectedOption.Name) {
      this.props.setInfo('pricing', {
        Name: selectedOption.Name,
        Address: selectedOption.Address,
        PharmacyId: selectedOption.PharmacyId,
        Option: selectedOption.Options[selectedUrgency],
      });
      this.props.goToAddPharmacyDesktop();
    }
  };
  handleDeletePharmacy = org => {
    this.props.deletePharmacy(org, this.props.token);
    this.props.fetchPickUpOptions(
      this.props.dateOfBirth,
      this.props.practice.Id,
      this.props.token);
    this.setState({ selectedOption: this.props.pickUpOptions[0] });
  };
  render() {
    const { isFetching, pickUpOptions, pickupMessage } = this.props;
    const { selectedOption, selectedUrgency } = this.state;
    return (
      <div className={styles.container}>
        <NavigationBar goBack={this.props.arrowFn} close={this.props.crossFn} />
        <div className={styles.heading}>
          2. How would you like to collect your prescription?
        </div>
        <Content>
          {isFetching && <Spinner className={styles.spinner} />}
          {pickUpOptions.length > 0 ? pickUpOptions.map((o, i) => {
            return (
              <PickUpOption
                key={o.PharmacyId}
                isActive={o.IsActive}
                isPlaceHolder={o.IsPlaceHolder}
                option={o}
                selectedOrg={selectedOption.PharmacyId === o.PharmacyId}
                selectedUrgency={selectedUrgency}
                onOrgClick={this.handleOrganisationClick(o)}
                onUrgencyClick={this.handleUrgencyClick}
                deletePharmacy={this.handleDeletePharmacy}
                onAddPharmClick={this.handleAddPharmacy}
                first={i === 0}
              />
            )
          },
            pickUpOptions,
          )
            :
            <div></div>
          }
        </Content>
        <div className={styles.reasonForCost} onClick={() => this.props.goToInfo()}>
          Why is there a charge?<br />I thought Prescriptions are FREE from 1st July 2023
        </div>
        {pickupMessage &&
          <div className={styles.pickupMessage}>{this.props.pickupMessage}</div>
        }
        <div className={styles.buttons}>
          <button
            className={styles.addPharmacy}
            onClick={this.handleAddPharmacy}
          >
            <Plus className={styles.plus} />
            {pickUpOptions.length > 1 &&
              pickUpOptions[0].PharmacyId === pickUpOptions[1].PharmacyId
              ? 'Collect at my Pharmacy'
              : 'Add a Pharmacy'}
          </button>
          <CtaButton
            active={this.state.selectedOption?.IsActive}
            className={styles.cta}
            onClick={this.handleCtaClick}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.loginReducer.token,
  patientId: state.scriptDetailsReducer.patient.Id,
  dateOfBirth: state.scriptDetailsReducer.patient.DateOfBirth,
  practice: state.scriptDetailsReducer.practice,
  pickUpOptions: state.pickUpOptionsReducer.options,
  pickupMessage: state.pickUpOptionsReducer.message,
  isFetching: state.pickUpOptionsReducer.isFetching || state.addPharmacyReducer.isFetching,
  prevLocation: state.scriptDetailsReducer.pricing,
  newPharmId: state.addPharmacyReducer.Id,
  userId: state.profileReducer.profile.Id,
});
const mapDispatchToProps = dispatch => ({
  setInfo: bindActionCreators(scriptActions.setInfo, dispatch),
  fetchPickUpOptions: bindActionCreators(actions.fetchPickUpOptions, dispatch),
  fetchPickUpOptionsDependants: bindActionCreators(actions.fetchPickUpOptionsDependant, dispatch),
  /* eslint-disable max-len */
  goToAddPharmacy: () => go.addPharmacy(),
  deletePharmacy: bindActionCreators(pharmacyActions.deletePharmacy, dispatch),
  /* eslint-enable max-len */
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PickUpLocationDesktop);
export { styles };
