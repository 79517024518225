import React from 'react';
import styles from './index.module.scss';

type Props = {
  children?: React.ReactNode;
}

const PageWrapper: React.FC<Props> = ({children, ...rest}) => {
  return (
    <div className={styles.container} {...rest}>
      {children}
    </div>
  )
}

export default PageWrapper;