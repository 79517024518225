import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import UIContainer from 'components/UIContainer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as actions }  from 'components/UIContainer/actions/headerActions';
import { useComponentDidMount } from 'libs/newHttp';
import RouteBar from 'components/DesktopComponents/RouteBar';
import TabBar from 'components/DesktopComponents/TabBar';
import { go } from 'routes';
import { useAccountStore, useMyHealthStore } from 'store/mobx/mobx';
import ExpandableCard from './components/ExpandableCard/ExpandableCard';
import { getFinalStatus } from 'Identity/components/IdentityLabel';
import Notifications from 'HealthFeed/HealthFeedDesktop/Notifications/Notifications';

const Outer = styled.div`
    width: 100%;
    height: 100%;
    background-color: #F9F9F9;
    overflow-y: auto;
    align-items: center;
    -ms-overflow-style: none; 
    scrollbar-width: none;  
    &::-webkit-scrollbar {
        display: none;
    }
    overflow: auto;
`
const Container = styled.div`
    padding: 0rem 0rem;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    width: 700px;
    padding-top: 2rem;
`
const Column = styled.div`
    margin: auto;
    margin-top: 0;
`
const Title = styled.div`
    font-size: 13px;
    color: #6C6C6C;
    margin: 1rem 0;
`
interface IProps {
    setHeader: (item: string) => void;
    feed: any;
}

const ImmunisationsPage: React.FC<IProps> = observer(({setHeader, feed}) => {
    const healthStore = useMyHealthStore();
    const store = useAccountStore();
    const [showNotes, setShowNotes] = useState(false);

    useComponentDidMount(() => {
        setHeader("PHR")
        healthStore.getImmunisations();
        healthStore.getClinicNotes();
    })

    useEffect(() => {
        if (healthStore.clinicalErrorText === null) { setShowNotes(true); }
        if (healthStore.clinicalErrorText !== "" && healthStore.clinicalErrorText !== null) {
            setShowNotes(false);
        }
    })
    return(
        <UIContainer isFetching={healthStore.loading} navKey='My Health Record'>
            <Outer>
                <RouteBar 
                    lowLevel={"Immunisations"}
                    lowLink={() => go.PHRSummary()}
                />
                <TabBar type={1} id={2} hideNotes={!showNotes} />
                {getFinalStatus(store) !== 1 && !store.profile.PrivacyPolicySetup &&
                    <Notifications feed={feed} getFeed={() => {}} hide={() => {}} />          
                }
                <Container>
                    {healthStore.immunisationsTab.givenWithLast3Months.map(item => 
                        <ExpandableCard item={item} type={"imms"} />    
                    )}
                    {healthStore.immunisationsTab.past.length > 0 || healthStore.immunisationsTab.givenWithLast3Months.length > 0 ?
                        <Title>PREVIOUS VACCINATIONS</Title>
                        :
                        <Title>NO VACCINATIONS FOUND</Title>
                    }
                    {healthStore.immunisationsTab.past.map(item => 
                        <ExpandableCard item={item} type={"imms"} />    
                    )}
                </Container>
            </Outer>
        </UIContainer>
    )
})

const mapDispatchToProps = (dispatch: any) => ({
    setHeader: bindActionCreators<any, any>(actions.setSideHeaderItem, dispatch),
})

const mapStateToProps = (state: any) => ({
    feed: state.feedReducer.feed,
})
  
export default connect(mapStateToProps, mapDispatchToProps)(ImmunisationsPage);
