import React from 'react';

import * as Styled from '../DesktopSignUpFlow.styled';

interface IProps {
    DoB: string;
}
const Months = ["January","February","March","April","May","June","July","August","September","October","November","December"];

const DesktopSignUpFlowCardDoB: React.FC<IProps> = ({ DoB }) => {
    const DoBArr = DoB.split('-')
    const Year = DoBArr[0];
    const Month = Months[parseInt(DoBArr[1]) - 1];
    const Day = DoBArr[2];
    return (
        <Styled.FlexV>
            <Styled.Title>Please confirm your date of birth</Styled.Title>
            <Styled.Subtitle>You must be 18 or older to create a patient profile with Vensa.</Styled.Subtitle>
            <Styled.FlexH style={{marginTop: "2rem"}}>
                <Styled.DoBInput style={{width: "78px"}} type={"day"} value={Day} readOnly />
                <Styled.DoBInput style={{width: "142px", margin: "0rem 1rem"}} type={"text"} value={Month} readOnly/>   
                <Styled.DoBInput style={{width: "78px"}} type={"year"} value={Year} readOnly/>            
            </Styled.FlexH>
        </Styled.FlexV>
    )
}

export default DesktopSignUpFlowCardDoB