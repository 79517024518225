import React from 'react';
import { connect } from 'react-redux';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import warning from '../../../svgs/redIdentityCrossWarning.svg';
import orangeCheck from 'svgs/orangeTickWarning.svg';
import cross from '../../../svgs/redCross.svg';
import tick from '../../../svgs/greenTickSmall.svg';
import { ReactComponent as Tick } from 'svgs/greenTickSmall.svg';
import warningSymbol from 'svgs/orangeWarning.svg';
import check from 'svgs/check.svg';
import { useAccountStore, useCareCircle } from 'store/mobx/mobx';
import { useComponentDidMount } from 'libs/newHttp';
import { go } from 'routes';
import { isDesktop } from 'config';
import DeclarationLabel from 'components/shared/common/DeclarationLabel';

const Container = styled.div`
    width: 100%;
    margin-bottom: 1rem;
`
const FlexR = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`
const FlexC = styled.div`
    display: flex;
    flex-direction: column;
`
const Title = styled.div`
    font-size: 21px;
    color: #7B7B7B;
    margin: 1rem;
    width: 63%;
    @media (min-width: 1024px) {
        font-size: 16px;
        font-weight: bold;
    }
`
const Subtext = styled.div`
    color: #989898;
    font-size: 15px;
    @media (min-width: 1024px) {
        font-size: 13px;
    }
`
// const DeclarationLabel = styled.span`
//     color: #07A3C8;
//     font-size: 15px;
//     margin-top: 1rem;
//     margin-left: calc(1rem + 12px);
// `;

const IdentityLabel = observer(() => {
    const accStore = useAccountStore();
    const careCircle = useCareCircle();

    useComponentDidMount(() => {
        accStore.getIdentityRequestsStatus();
        careCircle.getDeclareDate(accStore.profile.Id);
    })

    const renderTitle = () => {
        let status = getFinalStatus(accStore);
        let nhiLabel = (accStore.profile.NHI && accStore.profile.NHI !== '') ? ` - NHI: ${accStore.profile.NHI}` : ''; 
        switch(status) {
            case VerificationStatus.Pending:
                return (
                    <FlexR>
                        <img src={orangeCheck} alt="tick" style={{marginLeft: "auto"}}/>
                        <Title>Partially Verified</Title>
                    </FlexR>
                );
            case VerificationStatus.Verified:
                return (
                    <FlexR>
                        <img src={check} alt="tick" style={{marginLeft: "auto"}}/>
                        <Title>Identity Verified {nhiLabel}</Title>
                    </FlexR>
                );
            case VerificationStatus.Unverified:
                return (
                    <FlexR>
                        <img src={warning} alt="tick" style={{marginLeft: "auto"}}/>
                        <Title style={{color: "#FF6A65", marginBottom: "0px"}}>
                            Identity <b>Not</b> Verified 
                            <Title onClick={() => go.identity()} style={{color: "#FF6A65", margin: "0px", marginBottom: "1rem", textDecoration: "underline", cursor: "pointer"}}>Verify now!</Title>
                        </Title>
                     </FlexR>
                );
            default:
                return (
                    <FlexR>
                        <img src={warning} alt="tick" style={{marginLeft: "auto"}}/>
                        <Title style={{color: "#FF6A65", marginBottom: "0px"}}>
                            Identity <b>Not</b> Verified 
                            <Title onClick={() => go.identity()} style={{color: "#FF6A65", margin: "0px", marginBottom: "1rem", textDecoration: "underline"}}>Verify now!</Title>
                        </Title>
                     </FlexR>
                );
        }
    }
    const isDependant = !!careCircle.declareDate;
    const downloadDocument = () => careCircle.downloadDeclarationDocument(accStore.profile.Id, accStore.profile.LatestPracticeId);
    return(
        <Container>
            {renderTitle()}
            <FlexC style={{width: "72%", marginLeft: "auto"}}>
                {accStore.profile.VerificationStatusDetails.length > 0 &&
                    accStore.profile.VerificationStatusDetails.map((item) => {
                        if (item.VerificationStatus === 0) {
                            return (
                                <FlexR key={item} style={{marginBottom: "0.5rem"}}>
                                    <img src={warningSymbol} alt="pic" style={{margin: "1rem 0.5rem auto", marginTop: "0.2rem"}}/>
                                    <Subtext style={{color: "#F5722C"}}>
                                        Identity partially verified, wating on response from {item.OrganisationName}.
                                        <span onClick={() => go.identityViewRequest(item.OrganisationId)} style={{color: "#07A3C8", cursor: "pointer"}}> View Status.</span>
                                    </Subtext>
                                </FlexR>
                            )
                        } else if (item.IdentityVerified) {
                            return (
                                <FlexR key={item} style={{marginBottom: "0.2rem"}}>
                                    <Tick style={{margin: "0.5rem", width: isDesktop() ? "13px" : "24px", height: isDesktop() ? "13px" : "24px"}}/>
                                    <Subtext style={{marginRight: "1rem"}}>
                                        {accStore.profile.FirstName} has been successfully verified by {item.OrganisationName}
                                    </Subtext>
                                </FlexR>
                            )
                        }
                    })
                }
                <FlexR>
                    <img src={accStore.profile.MobileVerified ? tick : cross} alt="pic" style={{margin: "auto 0.5rem", width: "12px"}}/>
                    <Subtext style={{color: accStore.profile.MobileVerified  ? "#989898" : "#FF6A65"}}>
                        Mobile verified
                    </Subtext>
                </FlexR>
                <FlexR>
                    <img src={accStore.profile.EmailVerified ? tick : cross} alt="pic" style={{margin: "auto 0.5rem", width: "12px"}}/>
                    <Subtext style={{color: accStore.profile.EmailVerified  ? "#989898" : "#FF6A65"}}>
                        Email verified
                    </Subtext>
                </FlexR>
                <FlexR>
                    <img src={accStore.profile.SecurityPinCodeSetup ? tick : cross} alt="pic" style={{margin: "auto 0.5rem", width: "12px"}}/>
                    <Subtext style={{color: accStore.profile.SecurityPinCodeSetup  ? "#989898" : "#FF6A65"}}>
                        PIN code created
                        {!accStore.profile.SecurityPinCodeSetup &&
                            <span onClick={() => go.home()} style={{textDecoration: "underline", fontWeight: "bold", cursor: "pointer", marginLeft: "0.3rem"}}>Set-up Now</span>                        
                        }
                    </Subtext>
                </FlexR>
                <FlexR>
                    <img src={accStore.profile.PrivacyPolicySetup ? tick : cross} alt="pic" style={{margin: "auto 0.5rem", width: "12px"}}/>
                    <Subtext style={{color: accStore.profile.PrivacyPolicySetup  ? "#989898" : "#FF6A65"}}>
                        Privacy settings selected
                        {!accStore.profile.PrivacyPolicySetup &&
                            <span onClick={() => go.home()} style={{textDecoration: "underline", fontWeight: "bold", cursor: "pointer", marginLeft: "0.3rem"}}>Set-up Now</span>
                        }
                    </Subtext>
                </FlexR>
                
            {
                isDependant && 
                <FlexR style={{marginTop: '1rem', marginLeft: 'calc(1rem + 12px)'}}>
                    <DeclarationLabel date={careCircle.declareDate} handleClick={downloadDocument} />
                </FlexR>
            }
                
            </FlexC>
        </Container>
    );
})

const mapStateToProps = state => ({
    isMobileVerified: state.verifyReducer.isMobileVerified,
    verificationStatus: state.verifyReducer.verificationStatus,
    verificationMethod: state.verifyReducer.verificationMethod,
    isPinSetup: state.verifyReducer.isPinCodeSetup,
})

const VerificationStatus = {
    Unverified: 0,
    Verified: 1,
    Pending: 2
}

// 0 - Unverified
// 1 - Verified
// 2 - Pending
export const getFinalStatus = (accStore) => {
    const profile = accStore.profile;
    if (profile.IdentityVerified) {
        if (profile.PrivacyPolicySetup) {
            if (profile.EmailVerified) {
                if (profile.MobileVerified) {
                    return VerificationStatus.Verified;
                } else return VerificationStatus.Pending;
            } else return VerificationStatus.Pending
        } else return VerificationStatus.Pending;
    }
    if (accStore.profile.PendingRequest) return VerificationStatus.Pending;
    
    return VerificationStatus.Unverified;
    /*
    let verifiedArr = accStore.userAccountIdentityLabelArr.map((item) => { return item.IdentityRequest.Status === 1 });
    let pendingArr = accStore.userAccountIdentityLabelArr.map((item) => { return item.IdentityRequest.Status === 0 });

    if (!accStore.userAccountIdentityLabelArr.length) {
        return 0;
    } else if (verifiedArr.includes(true)) {
        return 1;
    } else if (pendingArr.includes(true)) {
        return 2;
    } else {
        return 0
    }*/
}

export default connect(mapStateToProps)(IdentityLabel);
