import React, { FocusEvent, PropsWithChildren, useState } from 'react';
import Upload from 'components/core/Upload';
import { withStyles } from 'hocs/withStyles';
import CircleAvatar from 'components/ClickableLabelAvatar';
import { file2Base64, validateDate, validateNonEmpty } from './services';
import styles from './RegisterPanel.module.scss';
import InputField, { InputFieldProps } from 'components/shared/common/fields/InputField';
import DateField, { DateFieldProps } from 'components/shared/common/fields/DateField';
import DropdownField from 'components/shared/common/fields/DropdownField';
import MakeField from 'hocs/MakeField';
import { HttpStatusCode, PatientStatus } from 'common/constants';
import { Touchable } from 'types';
import cn from 'classnames';
import IdentityInfo from './IdentityLabel';
import { withFocusWithin } from 'hocs/withFocusWithin';
import { useDomainStore } from 'pages/careCircle/context';
import { useObserver } from 'mobx-react-lite';
import DeclarationInfo from 'components/shared/common/DeclarationLabel';
import { RELATION_SHIP, RELATION_SHIP_FOR_RENDER } from './constants';
import ConfirmButton from 'components/shared/common/MultiFlavourButton';
import Divider from 'components/shared/common/Divider';
import ButtonGroup from './ButtonGroup';

const adapter = (touch: boolean) => ({
  avatar: {
      style: {
          diameter: touch ? '6.25rem' : '5.5rem',
          text: cn(styles.avatarText, {[styles.touch]: touch}),
      },
      text: touch ? 'Change picture' : 'Change profile picture'
  }
});

type Props = {
  values: Record<string, any>;
  doNext: () => void;
  doCheck: () => void;
} & Touchable

const RegisterPanel = ({
  values,
  doNext,
  doCheck,
  touch = false
}: Props) => {

  // avatar
  const [avatar, setAvatar] = useState(values.avatarBase64 || values.avatar);
  const handleAvatarChange = async (key: string, file: File) => {
    values.avatarBlob = file;
    values.avatarBase64 = await file2Base64(file);
    setAvatar(values.avatarBase64);
    store.uploadAvatar && store.uploadAvatar(values.avatarBlob);
  }

  const store = useDomainStore();
  // identity label status
  const label = store.label;
  const registrition = store.registration;
  // states for UI logic
  const [valid, setValid] = 
        useState(
            validateNonEmpty(values.firstName, values.lastName, values.relationship) && 
            validateDate(values.dateOfBirth));
  const [modified, setModified] = useState(label?.Status === PatientStatus.NONE);
  
  const handleInputChange = (name: string, value: string) => {
    const capitalValue = value ? value.charAt(0).toUpperCase() + value.slice(1) : '';
    values[name] = capitalValue;
    setValid(
      validateNonEmpty(values.firstName, values.lastName, values.relationship) && 
      validateDate(values.dateOfBirth));
    store.checkStatus !== -1 && setModified(
      registrition?.firstName !== values.firstName || 
      registrition?.lastName !== values.lastName ||
      registrition?.dateOfBirth !== values.dateOfBirth);
  }

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    store.focusInputId = e.currentTarget.id;
  }
  const handleBlur = () => {
    store.focusInputId = undefined;
  }
  // allways check if being modified
  // const handleNext = (store.canNext && !modified) ? doNext : doCheck;

  let UploadAvatar: typeof Upload;
  let TextInputField = MakeField(InputField, touch);
  let DateInputField = MakeField(DateField, touch);
  let RelationshipField = MakeField(DropdownField, touch);
  let IdentityLabel = IdentityInfo;
  let DeclarationLabel = DeclarationInfo;
  let PlaceHolder = ({children, ...rest}: PropsWithChildren<{}>) => <div className={styles.placeHolder} {...rest}>{children}</div>;
  const handleEdit = () => {
    store.checkStatus = -1; 
    setModified(true)
  }

  let CheckGroup = ({...rest}) => (
  <ButtonGroup touch={touch} {...rest}>
    <ConfirmButton 
      clickable={store.canEdit === undefined ? (valid && modified || store.canNext) : store.canEdit && valid } 
      handleClick={doCheck} 
      text={checkText}
      flavour='solid'
      touch={touch} />
  </ButtonGroup>);

  let ConfirmGroup = ({...rest}) => (
    <ButtonGroup touch={touch} {...rest}>
      <ConfirmButton 
        text={nextText} 
        flavour='hollow'
        handleClick={doNext}
        touch={touch}/>
      <Divider orientation={touch ? 'horizontal' : 'vertical'} size='20px'/>
      <ConfirmButton 
        text='Change Details' 
        flavour='solid'
        handleClick={handleEdit}
        touch={touch} />
    </ButtonGroup>
  );
  if(!touch){
    UploadAvatar = withStyles({width: '100%', margin: '2rem 0'})(Upload);
    const injectStyle = withStyles({width: 'calc(50% - 37.5px)', height: '4rem', margin: '10px 0 10px 25px'});
    TextInputField = injectStyle(TextInputField);
    DateInputField = injectStyle(DateInputField);
    RelationshipField = withStyles({width: 'calc(50% - 37.5px)', margin: '10px 0 10px 25px'})(RelationshipField);
    PlaceHolder = injectStyle(PlaceHolder);
  } else {
    UploadAvatar = withStyles({width: '100%', marginBottom: '1rem', order: 1})(Upload);
    IdentityLabel = withStyles({order: 2})(IdentityLabel);
    PlaceHolder = withStyles({order: 3, marginTop: '1rem', marginLeft: 'calc(50% - 9rem)'})(PlaceHolder);
    TextInputField = withStyles({order: 4})(TextInputField);
    DateInputField = withStyles({order: 5})(DateInputField);
    RelationshipField = withStyles({order: 6})(RelationshipField);
    CheckGroup = withStyles({margin: '1rem', alignSelf: 'flex-end', order: 7, position: 'inherit'})(CheckGroup);
    ConfirmGroup = withStyles({bottom: `${Math.max(window.innerHeight-800, 0)}px`, right: '0'})(ConfirmGroup); // calc(10% + 30px)
  }
  
  // deal with focus
  const FocusableDateInput = withFocusWithin(DateInputField);
  const adapt = adapter(touch);
  const {checkText, nextText} = store.textSet && store.textSet(touch);
  
  const RegisterField = (props: Pick<InputFieldProps, 'id' | 'title' |'initialValue' | 'readonly'>) => 
    <TextInputField 
      {...props as InputFieldProps}
      focusId={store.focusInputId} 
      doFocus={handleFocus}
      doBlur={handleBlur}
      onChange={handleInputChange} />;
  const DateOfBirthField = (props: Pick<DateFieldProps, 'id' | 'title' | 'value' | 'readonly'>) => 
    useObserver(() => <FocusableDateInput
      {...props}
      focusId={store.focusInputId}
      doFocus={handleFocus}
      handleChange={handleInputChange}
      onBlur={handleBlur} />);
  const downloadDocument = () => store.downloadDeclarationDocument();
  
  let declaration = useObserver(() => <DeclarationLabel date={store.declareDate} handleClick={downloadDocument} />);
 
  return ( 
    <>
      <UploadAvatar
            key='avatar'
            onChange={handleAvatarChange}
            acceptType={['jpg', 'png']}>
            {
                ({onFileUpload}) => (
                    <CircleAvatar
                        handleAction={onFileUpload}
                        text={adapt.avatar.text}
                        textClassName={adapt.avatar.style.text}
                        imgUrl={avatar}
                        diameter={adapt.avatar.style.diameter}
                     />
                )
            }
      </UploadAvatar>
      
      {!modified && <IdentityLabel identity={label!} />}
      <RegisterField id='firstName' title='Legal First Name' initialValue={values.firstName} readonly={store.readonly} />
      <RegisterField id='lastName' title='Legal Surname' initialValue={values.lastName} readonly={store.readonly}/>
      <RegisterField id='nickName' title='Preferred / Nickname' initialValue={values.nickName} readonly={store.readonly}/>
      <DateOfBirthField id='dateOfBirth' title='Date of Birth' value={values.dateOfBirth} readonly={store.readonly}/>
      <RelationshipField 
            touch
            header='- Relationship not set -'
            items={RELATION_SHIP_FOR_RENDER}
            id='relationship'
            title='Relationship'
            handleChange={handleInputChange}
            value={RELATION_SHIP[values.relationship]}
            readonly={store.readonly} />
      <PlaceHolder>{declaration}</PlaceHolder>
      {
        store.checkStatus === HttpStatusCode.PARTIAL_CONTENT ? <ConfirmGroup /> : <CheckGroup />
      }
      
    </>
  )
}

export default RegisterPanel;