import React from 'react';
import { observer } from 'mobx-react-lite';
import { FlatButton } from 'components/vensaComponents';
import { useLocation } from 'react-router';
import { BaseWhiteNotification } from './BaseWhiteNotification';
import { isDesktop } from 'config';

export const PHRPrivacyCard: React.FC = observer(() => {
  const { pathname } = useLocation();

  if (!isDesktop() && !pathname.startsWith('/home')) return null;

  return (
    <BaseWhiteNotification
      paragraph={`Vensa uses your stored health information, your
      dependants' data, and data supplied by your health 
      providers to personalise your experience.`}
      snooze={true}
    >
    </BaseWhiteNotification>
  );
});
