import React from 'react';
import useWizard from 'components/core/Wizard/useWizard';
import { AppointmentProps } from '../types';
import styles from './ReasonStep.module.scss';
import moment from 'moment';
import HeaderCardUI from 'components/shared/mobile/HeaderCardUI';
import StatusCard from 'components/shared/common/StatusCard';
import AvatarProfile from 'components/shared/common/AvatarProfile';
import DetailSection from 'pages/mobile/components/BottomBorderSection';
import { ReactComponent as Arrow } from 'svgs/rightWhiteArrow.svg';
import { ReactComponent as Icon } from 'svgs/profileIcon.svg';

const ReasonStep: React.FC<AppointmentProps & {policyNote: string}> = ({data, policyNote}) => {

    const {nextStep, close, valueObject} = useWizard();
    const title = moment(data.Time).format('dddd, DD MMM h:mmA');

  return (
    
    <div className={styles.container}>
      <HeaderCardUI text={title} handleClick={close}>
        <StatusCard status={data.Status} disableCard className={styles.bigSpacing}>
            <AvatarProfile
              diameter='4.5rem'
              imgUrl={data.ProviderPictureUrl}
              imgAlt={data.ProviderName}
              placeHolder={Icon}
              enableBottomLine
              className={styles.avatarContainer}>
              <span>{data.ProviderName}</span>
              <span>{data.ProviderTitle}</span>
              <span>{`at ${data.PracticeName}`}</span>
            </AvatarProfile>
            <DetailSection title='Patient'>
                <span className={styles.greyText}>{`${data.FirstName} ${data.LastName}`}</span>
            </DetailSection>
            <DetailSection 
            title={data.Service}
            addition={`$${data.AmountPaid / 100}`} />
            <DetailSection title='Practice Cancellation Policy'>
            {policyNote && <span className={styles.policyNote}>{policyNote}</span>}
            </DetailSection>
            <DetailSection title='Reason for cancellation:' enableBottomLine={false}>
            <textarea 
            className={styles.reason} 
            placeholder='Type your reason for cancelling this appointment'
            value={valueObject['reason']}
            onChange={ e => valueObject['reason'] = e.target.value.trim()}/>
            </DetailSection>
        </StatusCard>
      </HeaderCardUI>
      <div className={styles.next} onClick={() => nextStep()}>
           <Arrow />
       </div>
    </div>
  )
};

export default ReasonStep;
