import { getShort } from 'libs/misc';
import React from 'react';
import { ILabResult } from 'store/mobx/myHealthStore/myHealthStore.type';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    width: 100%;
    color: #6C6C6C;
    font-size: 13px;
    flex-direction: row;
    align-items: center;
`
const Comment = styled.div`
    width: 100%;
`
const Subject = styled.div`
    font-weight: bold;
    width: 100%;
`
interface IProps {
    item: any
}
const LabResult: React.FC<IProps> = ({ item }) => {
    return (
        <Container>
            <Subject>{getShort(item.NameOfTest ?? '', 21)}</Subject>
            <Comment>{item.DoctorNotes !== null ? getShort(item.DoctorNotes ?? '', 20) : getShort(item.InternalComment ?? '', 20)}</Comment>
        </Container>
    )
}

export default LabResult