import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find, compose, concat } from 'lodash/fp';
import { Redirect } from 'react-router-dom';

import UIContainer from '../../components/UIContainer/index';
import ScriptDetails from '../ScriptDetailsMobile/index';
import PersonInfo from './PersonInfo/index';
import Message from './Message/index';
import styles from './scriptPage.module.css';
import { scriptPropType, detailsPropType } from '../propTypes';
import styled from 'styled-components';

const getFullScript = (details, current, past) => ({
  ...compose(find(({ Id }) => Id === details.Id))(concat(current, past)),
  ...details,
});
const Outer = styled.div`
    width: 100%;
    background-color: #F9F9F9;
    align-items: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
`
const Inner = styled.div`
	animation: appear 0.3s forwards;
	overflow-y: auto;
	overflow-x: hidden;
	-ms-overflow-x: hidden;
	padding: 0 0 40px;
	width: 665px;
	margin: auto;
	height: 855px;
	margin-bottom: auto;
	padding: 0rem 1rem;
  margin-bottom: 0.5rem;
    &::-webkit-scrollbar {
		width: 10px;
	 }
	   
	 &::-webkit-scrollbar-track {
		background: #F0F0F0;
		border-radius: 25px;
		margin-left: 1rem;
	 }
	 
	 &::-webkit-scrollbar-thumb {
		 background: #AFAFAF;
		 border-radius: 25px;
	 }
	 
	 &::-webkit-scrollbar-thumb:hover {
		 background: #999; 
	 }
  }
`
class ScriptDetailsDesktop extends Component {
  static propTypes = {
    currentScripts: PropTypes.arrayOf(scriptPropType),
    pastScripts: PropTypes.arrayOf(scriptPropType),
    scriptDetails: detailsPropType,
    isFetching: PropTypes.bool,
    isRemoving: PropTypes.bool,
  };
  state = {
    script: {},
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.scriptDetails !== this.props.scriptDetails) {
      const newState = getFullScript(
        nextProps.scriptDetails,
        nextProps.currentScripts,
        nextProps.pastScripts,
      );
      this.setState({ script: newState });
    }
  }
  render() {
    if (!this.props.scriptDetails) return <Redirect to={'/prescriptions'} />;
    const { isFetching, isRemoving } = this.props;
    const script = this.state.script;
    return (
      <UIContainer
        heading="Prescriptions"
        navKey='Prescriptions'
        secondaryHeading="Prescription Details"
        isFetching={isFetching || isRemoving}
      >
        <Outer>
        <div className={styles.container}>
          <div className={styles.page}>
            {script.Id && (
              <div className={styles.inner}>
                <Message
                  message={script.DoctorNote}
                  color={script.StatusColour}
                  status={script.Status}
                  date={script.HandledAt}
                  mobile={script.TextSentTo}
                  statusCode={script.StatusNumber}
                />
                <PersonInfo script={script} />
                <div className={styles.medications}>
                  {script.Id &&
                    !isFetching &&
                    Object.values(script.Medications).map(m => (
                      <div className={styles.med} key={m}>
                        {m}
                      </div>
                    ))}
                </div>
                <ScriptDetails script={script} />
              </div>
            )}
          </div>
        </div>
        </Outer>
      </UIContainer>
    );
  }
}

const mapStateToProps = state => ({
  currentScripts: state.scriptReducer.currentRequests,
  pastScripts: state.scriptReducer.pastRequests,
  scriptDetails: state.scriptReducer.scriptDetails,
  isFetching: state.scriptReducer.isFetchingDetails,
  isRemoving: state.scriptReducer.isRemoving,
});

export default connect(mapStateToProps)(ScriptDetailsDesktop);
