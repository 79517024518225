import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';

import NDigitInput from '../../../components/nDigitInput/index';
import ResendButton from '../ResendButton/index';
import styles from './codeInput.module.css';
import { ReactComponent as Spinner } from '../../../svgs/spinner.svg';
import { ReactComponent as Arrow } from '../../../svgs/rightarrow2.svg';
import { ReactComponent as DesktopMobile } from '../../../svgs/mainMobile.svg';

const onChange = (sendCode, sessionId, mobile) => digits =>
  digits.length === 4 && sendCode(sessionId, digits, mobile);

class CodeInput extends Component {
  static propTypes = {
    switcheroo: PropTypes.func.isRequired,
    overrideBackArrow: PropTypes.func.isRequired,
    clearBackArrow: PropTypes.func.isRequired,
    mobile: PropTypes.string.isRequired,
    clearError: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    verifyCode: PropTypes.func.isRequired,
    sendCode: PropTypes.func.isRequired,
    countdownStartTime: PropTypes.instanceOf(moment).isRequired,
    milliSecondsToWait: PropTypes.number.isRequired,
    isError: PropTypes.bool,
    isFetching: PropTypes.bool,
  };
  state = {
    hasClickedResend: false,
  };
  componentDidMount() {
    this.props.overrideBackArrow(() => this.props.switcheroo());
  }
  componentWillUnmount() {
    this.props.clearBackArrow();
  }
  setClicked(bool) {
    this.setState({ hasClickedResend: bool });
  }
  render() {
    return (
      <div className={styles.container}>
        <Arrow className={styles.arrow} onClick={this.props.switcheroo} />
        <div className={styles.phoneContainer}>
          <DesktopMobile className={styles.phone} />
        </div>
        {this.props.isFetching && (
          <div className={styles.spinnerContainer}>
            <Spinner className={styles.spinner} />
          </div>
        )}
        <div className={styles.text}>
          {`Enter the 4-digit code sent to you at ${this.props.mobile}`}
        </div>
        <NDigitInput
          className={classnames(styles.input, {
            [styles.error]: this.props.isError,
          })}
          digits={4}
          onChange={onChange(
            this.props.verifyCode,
            this.props.token,
            this.props.mobile,
          )}
          key="input"
          isError={null}
          clearError={this.props.clearError}
        />
        {this.props.isError && (
          <div className={styles.errorText}>
            Oops, looks like you&#39;ve typed the wrong number
          </div>
        )}
        <ResendButton
          onClick={() => this.props.sendCode(this.props.mobile)}
          countdownStartTime={this.props.countdownStartTime}
          milliSecondsToWait={this.props.milliSecondsToWait}
          isFetching={this.props.isFetching}
        />
      </div>
    );
  }
}

export default CodeInput;
