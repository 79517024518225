import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ReactComponent as Tick } from '../../../svgs/tick.svg';
import styles from './dynamicTick.module.css';

const DynamicTick = ({ selected, onClick }) => (
  <div
    className={classnames(styles.circleIcon, { [styles.selected]: selected })}
    onClick={onClick}
  >
    {selected && <Tick className={styles.tick} />}
  </div>
);

DynamicTick.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default DynamicTick;
