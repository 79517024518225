import { actionTypes } from '../actions/actions';

const INITIAL_STATE = {
  selectedPractice: [],
  photo: null,
  verifyObj: { },
  error: null,
  success: false,
  isFetching: false,
  privacySettings: {},
  pin: null,
};

const identityReducer = (state = INITIAL_STATE, action) => {
  const data = action.payload;
  switch (action.type) {
    case actionTypes.setPractice:
      return {
        selectedPractice: [data.practice],
     };
    case actionTypes.setPhoto:
      return {
        ...state,
        photo: data.photo
      };
    case actionTypes.sendVerifyRequest:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case actionTypes.sendVerifyRequestFailure:
      return {
        ...state,
        isFetching: false,
        error: data.err
      };
    case actionTypes.sendVerifyRequestSuccess:
      return {
        ...state,
        isFetching: false,
        success: true
      };
    case actionTypes.setSettings:
      return {
        ...state,
        privacySettings: data.settings
      };
    case actionTypes.setPin:
      return {
        ...state,
        pin: data.pin
      };
    default: {
      return state;
    }
  }
};

export default identityReducer;
