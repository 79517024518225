import React from 'react';

import { pricingPropType } from '../../../propTypes';
import styles from './pickUpDetails.module.css';

const formatNumber = number =>
  number
    ? `${number.substr(0, 3)} ${number.substr(3, 3)} ${number.substr(6)}`
    : '';

const PickUpDetails = ({ details }) => (
  <div className={styles.container}>
    <div className={styles.name}>{details.Name}</div>
    <div className={styles.location}>{details.Address}</div>
    {details.Phone && (
      <div className={styles.phone}>{formatNumber(details.Phone)}</div>
    )}
  </div>
);

PickUpDetails.propTypes = {
  details: pricingPropType.isRequired,
};

export default PickUpDetails;
