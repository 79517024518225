import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingEllipsis from '../LoadingEllipsis/index';
import { ReactComponent as Tick } from '../../../../svgs/tick.svg';
import { ReactComponent as Cross } from '../../../../svgs/close.svg';
import styles from './heading.module.css';

const Heading = ({ confirming, error }) => {
  if (confirming)
    return (
      <div className={styles.notConfirmed}>
        <LoadingEllipsis className={styles.loader} />
        <span className={styles.text}>Confirming Appointment</span>
      </div>
    );
  else if (error)
    return (
      <div className={styles.notConfirmed}>
        <Cross className={styles.cross} />
        <span className={styles.text}>Booking Failed</span>
      </div>
    );
  else
    return (
      <div className={styles.confirmed}>
        <Tick className={styles.tick} />
        <span className={styles.text}>Appointment Confirmed</span>
      </div>
    );
};

const mapStateToProps = state => ({
  confirming: state.appointmentConfirmationReducer.checking,
  error: state.appointmentConfirmationReducer.error,
});

Heading.propTypes = {
  confirming: PropTypes.bool.isRequired,
  error: PropTypes.bool,
};

export default connect(mapStateToProps)(Heading);
