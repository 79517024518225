import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import SetupPinFlowDesktop from './components/SetupPinFlowDesktop/SetupPinFlowDesktop';
import SetupPrivacySettingsDesktop from './components/SetupPrivacySettingsDesktop/SetupPrivacySettingsDesktop';
import UIContainer from 'components/UIContainer';
import RouteBar from 'components/DesktopComponents/RouteBar';
import TabBar from 'components/DesktopComponents/TabBar';
import { go } from 'routes';
import LearnMorePopUp from 'components/LearnMorePopUp/LearnMorePopUp';
import { useAccountStore, useIdentityStore } from 'store/mobx/mobx';
import MobileVerificationDesktop from './components/MobileVerificationDesktop/MobileVerificationDesktop';
import CompleteVerificationFlowProcessing from 'Identity/CompleteVerificationFlow/Processing.js';
import FlowStatus from './components/FlowStatus/FlowStatus';

const Outer = styled.div`
    width: 100%;
    background-color: #F9F9F9;
    overflow-y: auto;
    align-items: center;
    -ms-overflow-style: none; 
    scrollbar-width: none;  
    &::-webkit-scrollbar {
        display: none;
    }
    overflow: auto;
`
const Title = styled.div`
    text-transform: uppercase;
    font-size: 13px;
    margin-top: 1rem;
    text-align: left;
    width: 100%;
`
const Text = styled.div`
    font-size: 13px;
    margin-top: 0.5rem;
    width: 100%;
    text-align: left;
`
const Card = styled.div`
    width: 534px;
    margin: 1rem auto;
    height: 677px;
    position: relative;
    background-color: white;
    border-radius: 4px;
    padding-top: 2rem;
    margin: auto;
    box-shadow: 0px 3px 6px #00000029;
`
const Container = styled.div`
    width: 534px;
    margin: auto;
`

const CompleteVerificationFlowDesktop = observer(() => {
    const [state, setState] = useState(0);
    const [popUp, setPopUp] = useState(false);
    const [learnMore, setLearnMore] = useState(false);
    
    const { MobileVerified } = useAccountStore().profile;
    const { patientVerification } = useAccountStore();
    // const { sendPrivacySettings, selectedPracticeOrgId, loading } = useIdentityStore();
    const store = useIdentityStore();
    const storeAc = useAccountStore();
    const pageRef = useRef<HTMLDivElement>(null);

    const BackButtonFn = () => state === 0 ? go.home() : setState(state - 1)

    const SubmitPrivacySettings = () => 
        store.sendPrivacySettings(() => 
            setState(MobileVerified ? 3 : 2), 
            () => setState(1), 
            store.selectedPracticeOrgId
        )

    const changeState = (newState: number) => {
        setState(newState);
        if (pageRef.current !== null) {
            const { y } = pageRef.current.getBoundingClientRect();
            pageRef.current.scrollTo(y, 0)
        }
    }
    
    store.setPracticeOrgId(storeAc.profile.LatestPracticeId);

    useEffect(() => {
        if (store.selectedPracticeOrgId === "" || 
            store.selectedPracticeOrgId === null ||
            store.selectedPracticeOrgId === undefined) { go.home(); }
    })
    return (
        <UIContainer isFetching={patientVerification.loading || store.loading}>
            {popUp &&
                <LearnMorePopUp hide={() => setPopUp(false)} type={"SECURITY_FAQ"} hideOnOverlayClick={true} />            
            }
            {learnMore &&
                <LearnMorePopUp hide={() => setLearnMore(false)} type={"IDENTITY_INFO"}hideOnOverlayClick={true} />            
            }
            <Outer>
                <RouteBar 
                    lowLevel={"Identity"}
                    lowLink={() => go.identity()}
                />
                <TabBar type={0} id={1} />
                
                <Container>
                    <Title>Identity Status</Title>
                    <Text>
                        Local identity verification is free. You must verify your identity with each medical centre you wish to use.
                        <span onClick={() => setLearnMore(true)} style={{color: "#07A3C8", cursor: "pointer"}}> Learn more.</span>
                    </Text>
                </Container>

                <FlowStatus list={["Security PIN", "Privacy Settings", MobileVerified ? "Finish" : "Mobile Verification"]} active={state === 3 ? 2 : state} />
                
                {state === 0 && <SetupPinFlowDesktop learnMoreOnClick={() => setPopUp(true)} goBack={BackButtonFn} goNext={() => changeState(1)} />}
                {state === 1 && <SetupPrivacySettingsDesktop learnMoreOnClick={() => setPopUp(true)} goBack={BackButtonFn} goNext={SubmitPrivacySettings} />}
                {state === 2 && <MobileVerificationDesktop goBack={BackButtonFn} goNext={() => setState(3)} />}
                {state === 3 && 
                    <Card>
                        <CompleteVerificationFlowProcessing />
                    </Card>
                }
            </Outer>
        </UIContainer>
    )
})

export default CompleteVerificationFlowDesktop;