import React, { Component } from 'react';
import { connect } from 'react-redux';

import Header from '../../../components/UIContainer/MobileUI/header/index';
import NotEnrolled from '../NotEnrolled/index';
import CallPractice from '../../../components/CallPractice/index';
import { ReactComponent as Logo } from '../../../svgs/logo.svg';
import { isBookingForSomeoneElse as checkBookingForSomeoneElse } from '../../../selectors/sessionSelectors';
import { PracticeInfoPropType } from '../../../PracticeProfilePage/BookingPageMobile/propTypes/index';
import { errorPropType } from '../../propTypes/index';
import { go } from '../../../routes/index';
import { isDesktop } from '../../../config';
import styles from './guestBookingError.module.css';

class GuestBookingError extends Component {
  static propTypes = {
    practice: PracticeInfoPropType.isRequired,
    error: errorPropType,
  };
  backFn = () => {
    go.practiceBooking(this.props.practice.UrlName);
  };
  render() {
    return (
      <div className={styles.container}>
        {!isDesktop() && (
          <Header
            headerClassNames={styles.header}
            goBackFn={this.backFn}
            showHelp
            firstLine={
              <div className={styles.check} onClick={this.backFn}>
                Details
                <Logo className={styles.logo} />
              </div>
            }
          />
        )}
        <div className={styles.inner}>
          <span className={styles.whoops}>Whoops</span>
          <NotEnrolled goToBooking={this.backFn} error={this.props.error} />
          <CallPractice practice={this.props.practice} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  error:
    state.sessionReducer.guestBookingError ||
    state.checkEnrollmentReducer.error,
  practice: state.practiceProfileReducer.profile,
  isBookingForSomeoneElse: checkBookingForSomeoneElse(state),
});

export default connect(mapStateToProps)(GuestBookingError);
