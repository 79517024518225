import React from 'react';
import Textarea from 'react-textarea-autosize';

import styles from './addNote.module.css';

const Note = ({ addNote, note }) => (
  <div className={styles.container}>
    <div className={styles.title}>Note:</div>
    <Textarea
      className={styles.input}
      defaultValue={note}
      placeholder="What's the reason for your request?"
      onChange={addNote}
    />
  </div>
);

export default Note;
