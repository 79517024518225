import React from 'react';
import PropTypes from 'prop-types';

import styles from './noScripts.module.css';

const NoScripts = ({ toggle }) => (
  <div className={styles.container}>
    <div className={styles.bold}>Repeat Scripts are now available!</div>
    <div className={styles.textContainer}>
      You can now request a repeat script online without calling your practice.
    </div>
    <button className={styles.tryButton} onClick={toggle}>
      Try it out.
    </button>
  </div>
);

const NewScripts = ({ toggle }) => (
  <div className={styles.container}>
    <div className={styles.bold} style={{fontWeight: "600"}}>Do you need a repeat prescription?</div>
    <div className={styles.textContainer}>
      You can now request a repeat prescription online - just tap the button below.
    </div>
    <div className={styles.textContainer}>
      It should only take 2 minutes. <br />
      Repeat Script services may have a charge.
    </div>
    <button className={styles.tryButton} onClick={toggle}>
      Learn why
    </button>
  </div>
);

NoScripts.propTypes = {
  toggle: PropTypes.func.isRequired,
};

export default NoScripts;
export { NewScripts }
