import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { ReactComponent as Check } from 'svgs/whiteTick.svg'
import { useAccountStore } from 'store/mobx/mobx';
import { ReactComponent as Tick } from 'svgs/whiteTickSmall.svg';
import { ReactComponent as Cross } from 'svgs/whiteCrossSmall.svg';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #346672;
    align-items: center;
    padding: 0rem 1rem;
    position: relative;
`
const Button = styled.div`
    background-color: white;
    color: #346672;
    padding: 6px 17px;
    margin: 0rem 11%;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 600;
    box-shadow: 0px 2px 4px black;
    white-space: nowrap;
    cursor: pointer;
`
const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    flex-grow: 2;
    margin: 1rem;
`
const Title = styled.div`
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 0.5rem;
`
const Text = styled.div`
    font-size: 13px;
`
const List = styled.div`
    margin-top: 1rem;
`
const Item = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 13px;
    color: white;
    align-items: center;
    margin: 0.1rem 0;
`
const Link = styled.div`
    font-size: 14px;
    text-decoration: underline;
    color: white;
    text-align: left;
    cursor: pointer;
`
const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 440px;
    align-items: center;
`

interface Props {
    onClick: () => void;
    hide: () => void;
}

const IdentityChecklistBanner: React.FC<Props> = observer(({onClick, hide}) => {
    const { MobileVerified, EmailVerified, IdentityVerified } = useAccountStore().profile;
    const store = useAccountStore();
    const imgStyle = { margin: "0 0.5rem" };

    return(
        <Container>
            <Check style={{width: "200px"}} /> 
            {MobileVerified && EmailVerified && IdentityVerified && 
                <Cross style={{position: "absolute", top: "1rem", right: "1rem", cursor: "pointer"}} onClick={() => store.sendAcknowledgement(1, () => hide())}/>
            }
            <TextContainer>
                <Title>Verify your Identity</Title>
                <Text>Verifying your identity puts you in full control of your health information.</Text>

                <List>
                    <Item>
                        {MobileVerified ? <Tick style={imgStyle} /> : <Cross style={imgStyle}/>}
                        Mobile Verification
                    </Item>
                    <Item>
                        {EmailVerified ? <Tick style={imgStyle}/> : <Cross style={imgStyle}/>}
                        Email Verification
                    </Item>
                    <Item>
                        {IdentityVerified ? <Tick style={imgStyle}/> : <Cross style={imgStyle}/>}
                        Identity Verification
                    </Item>
                </List>
            </TextContainer>
            <ButtonsContainer>
                <Button onClick={onClick}>Learn More</Button>
                {MobileVerified && EmailVerified && IdentityVerified && 
                    <Link onClick={() => store.sendAcknowledgement(1, () => hide())}>Ok got it</Link>
                }
            </ButtonsContainer>
        </Container>
    );
});

export default IdentityChecklistBanner;