import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import styles from 'HealthFeed/HealthFeedDesktop/healthFeedDesktop.module.css';
import { useComponentDidMount } from 'libs/newHttp';
import { useAccountStore } from 'store/mobx/mobx';
import { useLocation } from 'react-router-dom';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #346672;
    padding: 0.3rem 3rem;
    height: 100px;
    font-size: 14px;
`
const Banner = styled.div`
    background-color: #FF6A65;
    color: white;
    border: 2px solid white;
    padding: 2px 15px;
    border-radius: 15px;
    text-align: center;
    width: 185px;
    font-size: 11px;
    margin-top: auto;
`
const FlexV = styled.div`
    display: flex;
    flex-direction: column;
`
const Text = styled.div`
    color: white;
    white-space: pre-wrap;
    margin: 1rem 0rem;

`
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
`
const Link = styled.div`
    color: white;
    text-decoration: underline;
    margin: auto;
    margin-left: 0px;
    cursor: pointer;
`
const Button = styled.div`
    color: #346672;
    background-color: white;
    border-radius: 4px;
    margin: auto;
    padding: 5px 25px;
    margin-left: 70px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0px 2px 4px black;
`

interface IProps {
    bannerText: string;
    text: string;
    linkText: string;
    linkOnClick: () => void;
    buttonText: string;
    buttonOnClick: () => void;
    height?: string;
    bannerColour?: string;
}

const HalfBanner: React.FC<IProps> = observer(({bannerColour, bannerText, height, text, linkText, linkOnClick, buttonText, buttonOnClick}) => {
    const store = useAccountStore();
    const { pathname } = useLocation();

    return (
        <FlexV>
            {pathname.startsWith('/home') && 
                <div className={styles.heading}>
                    <div className={styles.kiaora}>
                        {store.profile.FirstName && `Kia ora, ${store.profile.FirstName || ''}!`}
                    </div>
                    <div>
                        {store.profile.FirstName &&
                        "Here's a summary of what's happening on your patient profile"}
                    </div>
                </div>
            }
            <Container style={{borderTop: bannerText ? bannerColour ? "2px solid " + bannerColour : "2px solid #FF6A65" : "none", height: height ? height : "100px"}}>
                <FlexV style={{width: "70%"}}>
                    {bannerText && <Banner style={bannerColour ? {backgroundColor: bannerColour} : {}}>{bannerText}</Banner>}
                    <Text>{text}</Text>
                </FlexV>
                <ButtonContainer>
                    {linkText && <Link onClick={() => linkOnClick()}>{linkText}</Link>}
                    {buttonText && <Button onClick={() => buttonOnClick()}>{buttonText}</Button>}
                </ButtonContainer>
            </Container>
        </FlexV>
    )
});

export default HalfBanner;