const actionTypes = {
  setInfo: 'SCRIPT_SET_INFO',
  repeatScript: 'SCRIPT_FILL_REPEATED_INFO',
  ableToRequest: 'CHECK_ABLE_TO_REQUEST',
  isAbleToRequest: 'IS_ABLE_TO_REQUEST',
  notAbleToRequest: 'NOT_ABLE_TO_REQUEST',
  clearAbleToRequest: 'CLEAR_ABLE_TO_REQUEST',
  clearScriptInfo: 'CLEAR_SCRIPT_REQUEST',
  getMedicationList: 'GET_MEDICATION_LIST',
  getMedicationListSuccess: 'GET_MEDICATION_LIST_SUCCESS',
  getMedicationListFailure: 'GET_MEDICATION_LIST_FAILURE',
  resetMedicationSelection: 'RESET_MEDICATION_SELECTION',
};

const actions = {
  getMedicationList: (orgId, consumerId, token, scriptId) => ({
    type: actionTypes.getMedicationList,
    payload: {
      orgId,
      consumerId,
      token,
      scriptId
    }
  }),
  getMedicationListSuccess: (data) => ({
    type: actionTypes.getMedicationListSuccess,
    payload: {
      data
    }
  }),
  getMedicationListFailure: (err) => ({
    type: actionTypes.getMedicationListFailure,
    payload: {
      err
    }
  }),
  setInfo: (id, value) => ({
    type: actionTypes.setInfo,
    payload: {
      id,
      value,
    },
  }),
  repeatScript: details => ({
    type: actionTypes.repeatScript,
    payload: {
      details,
    },
  }),
  ableToRequest: (orgId, userId, token, dependantId) => ({
    type: actionTypes.ableToRequest,
    payload: {
      orgId,
      userId,
      token,
      dependantId,
    },
  }),
  isAbleToRequest: () => ({
    type: actionTypes.isAbleToRequest,
  }),
  notAbleToRequest: error => ({
    type: actionTypes.notAbleToRequest,
    payload: {
      error,
    },
  }),
  clearAbleToRequest: () => ({
    type: actionTypes.clearAbleToRequest,
  }),
  clearScriptInfo: () => ({
    type: actionTypes.clearScriptInfo,
  }),
};

export { actions, actionTypes };
