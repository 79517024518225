import { IdentityService, service } from "./service";

const createIdentityStore = (service: IdentityService) =>({
  async skipPinCode(organisationId: string) {
    await service.updatePrivacySettings(organisationId,{
      allowRecords: true,
      allowDoctorNotes: true,
      allowDependantRecords: true,
      allowHealthData: true,
      allowMedications: true,
      isDefault: true
    });
    window.location.reload();
  },
  async employPinCode() {
    await service.employParentPinCode();
    window.location.reload();
  }
});
const store = createIdentityStore(service);
export default store;