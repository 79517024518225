import React, { PropsWithChildren, ReactElement } from 'react';
import styles from './Template.module.scss';
import Section from './Section';
import FlavourButton, { ButtonFlavour } from '../MultiFlavourButton';
import { Touchable } from 'types';
import { withStyles } from 'hocs/withStyles';
import Divider from '../Divider';

export type PopupItems =
  {
    title: string; 
    content: string
  }[];

interface PopupButton {
  text: string;
  handleClick?: () => void;
  flavour: ButtonFlavour;
};

export type PopupModel = {
  icon?: ReactElement;
  title: string | ReactElement;
  content?: string | ReactElement;
  listData?: PopupItems;
  buttonData: PopupButton[];
}

const adapter = (touch: boolean) => ({
  OkBtton: {
    style: {
      width: touch ? '80%' : '45%'
    }
  },
  CancelButton: {
    style: {
      width: touch ? '80%' : '45%'
    }
  }
});

type Props = {
  model: PopupModel
} & Touchable;

const Popup = ({
  model,
  touch = false,
}: PropsWithChildren<Props>) => {
  const adapt = adapter(touch);
  const PopupButton = withStyles({alignSelf: 'center', width: adapt.CancelButton.style.width})(FlavourButton);
  return (
    <>
      {model.icon}
      <span className={styles.title}>{model.title}</span>
      {model.content && typeof(model.content) === "string" ? <p>{model.content}</p> : model.content}
      {model.content && <Divider orientation='horizontal' size='2rem' />}
      {
        model.listData &&
        model.listData.map((value, index) => {
          return (
            <Section 
              key={index} 
              sn={(index + 1).toString()} 
              title={value.title} 
              content={value.content}  />)
        })
      }
      <Divider orientation='horizontal' size='1rem' />
      {
        model.buttonData &&
        model.buttonData.map((item, index) => (
          <>
            <PopupButton
              key={index}
              flavour={item.flavour}
              text={item.text}
              handleClick={item.handleClick!}
              touch={touch}
            />
            <Divider orientation='horizontal' size='1rem' />
          </>
          ))
      }
    </>
  )
}

export default Popup;