import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import styles from 'Identity/Processing/processing.module.css';
import { go } from 'routes/index';
import Transition from 'components/Transition';
import loadingGif from '../../images/LoadingGif.gif';
import Success from '../Processing/components/Success';
import { useComponentDidMount } from 'libs/newHttp';
import { useIdentityStore, useAccountStore } from 'store/mobx/mobx';
import Alert from 'libs/Alert';
import { goBack } from 'routes/utils';

const Processing = observer(() => {
    const [success, setSuccess] = useState(false);

    useComponentDidMount(() => {
        setTimeout(() => {
            setSuccess(true);
        }, 5000)

    })

    return (
        <Transition className={styles.container}>
            {!success && (
            <div className={styles.container}>
                <img
                    key="0"
                    alt="confirming appointment"
                    className={styles.loadingGif}
                    src={loadingGif}
                />
                <div className={styles.title}>Hold on</div>
                <div className={styles.text}>Finalising your identity settings</div>
            </div>
            )}
            {success && (
                <Success incompleteId={true} redirect={() => {
                    setTimeout(() => {
                        go.home();
                    }, 5000);
                }}/>
            )}
        </Transition>
    );
    
})

export default Processing;