import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { map } from 'lodash/fp';

import styles from './medications.module.css';

const getMedsString = meds => `${meds.map(m => ` ${m}`)}`;

const Medications = ({ meds, isOpen }) => {
  const medsString = getMedsString(Object.values(meds));
  return (
    <div
      className={classnames(styles.container, {
        [styles.openContainer]: isOpen,
      })}
    >
      {!isOpen && (
        <div className={styles.medication}>
          {medsString.substr(0, 80).trim()}
          {medsString.length > 80 && (
            <span className={styles.ellipsis}>...</span>
          )}
        </div>
      )}
      {isOpen &&
        map(
          m => (
            <div className={styles.openMedication} key={m}>
              {m}
            </div>
          ),
          Object.values(meds),
        )}
    </div>
  );
};

Medications.propTypes = {
  meds: PropTypes.objectOf(PropTypes.string),
  isOpen: PropTypes.bool.isRequired,
};

export default Medications;
