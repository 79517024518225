import React, { Children, memo, PropsWithChildren} from 'react';
import { Handler, WizardProps } from './types';
import styles from './Wizard.module.scss';
import WizardContext from './WizardContext';
import {WizardValues} from './types';
import { useLocalObservable, useLocalStore, useObserver } from 'mobx-react-lite';
import cn from 'classnames';

type WizardStore = {
    values: WizardValues,
    stepCount: number,
    current: number,
    stepHandler: Handler,
    currentContent: () => React.ReactElement,
}

const Wizard = memo((
    {
        header, 
        children,
        footer, 
        close, 
        complete,
        initialValues,
        nested
    }: PropsWithChildren<WizardProps>) => { // performance tag: enable shallow comparison
    const store = useLocalObservable<WizardStore>((): WizardStore => ({
            values: {
                nextStep: async () => {
                    store.stepHandler && await store.stepHandler();
                    store.current = Math.min(store.current + 1, store.stepCount - 1);
                },
                previousStep: () => {
                    store.stepHandler = null;
                    store.current = Math.max(0, store.current - 1);
                },
                jumpStep: (step: number) => {
                    store.current = Math.max(0, Math.min(step, store.stepCount - 1));
                },
                handleStep: handler => {store.stepHandler = handler},
                close: close,
                complete: complete,
                classes: {},
                valueObject: {...initialValues}
            },
            stepCount: Children.count(children),
            current: 0,
            stepHandler: null,
            currentContent: () => Children.toArray(children)[store.current],
            
        } as WizardStore));
    return useObserver(() => (
        <WizardContext.Provider value={store.values}>
            <div className={cn(styles.container, {[styles.nested]: nested})}>
                {header}
                {store.currentContent()}
                {footer}
            </div>
        </WizardContext.Provider>
    ));
});

export default Wizard;
