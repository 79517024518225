import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Textarea from "react-textarea-autosize";

import styles from "./feelingForm.module.css";

const FeelingForm = ({ note, name, isBookingForSomeoneElse, onChange }) => {
  const tooLong = note.length >= 120;
  const barLength = (note.length / 120) * 100;
  return (
    <div className={styles.container}>
      <form className={styles.feelingForm}>
        <Textarea
          placeholder={
            isBookingForSomeoneElse ? `${name} is visiting because...` : "I'm visiting because..."
          }
          value={note}
          onChange={onChange}
          className={styles.textarea}
          maxLength={120}
        />
        <div
          style={{ width: `${barLength}%` }}
          className={classnames(styles.countBar, { [styles.redBorder]: tooLong })}
        >
          &nbsp;
        </div>
      </form>
      <div className={styles.lengthCounter}>
        <span className={classnames(styles.length, { [styles.redColor]: tooLong })}>
          {120 - note.length}
        </span>
        {" / 120 characters remaining"}
      </div>
    </div>
  );
};

FeelingForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  isBookingForSomeoneElse: PropTypes.bool,
  name: PropTypes.string.isRequired,
  note: PropTypes.string,
};

export default FeelingForm;
export { styles };
