import { actionTypes } from '../actions/actions';

const INITIAL_STATE = {
    isFetching: false,
    card: {},
    success: false
}

const vAppointmentPaymentsReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case actionTypes.getCards: {
            return {
                isFetching: true,
                card: {},
                success: false
            };
        }
        case actionTypes.getCardsSuccess: {
            return {
                isFetching: false,
                card: action.payload,
                success: true
            };
        }
        case actionTypes.getCardsFailure: {
            return {
                isFetching: false,
                success: false
            };
        }
        default: {
            return state;
        }
    }
}

export default vAppointmentPaymentsReducer;