import React from 'react';
import { ProtectedRoute, push } from '../utils';
import { isDesktop } from '../../config';
import HealthSummary from 'PHR/PHRDesktop/HealthSummary';
import LabsPage from 'PHR/PHRDesktop/LabsPage';
import ImmunisationsPage from 'PHR/PHRDesktop/ImmunisationsPage';
import ClinicalNotesPage from 'PHR/PHRDesktop/ClinicalNotesPage';

const go = {
  PHRSummary: () => push('/personal-health-record'),
  LabsPage: () => push('/personal-health-record/labs'),
  ImmsPage: () => push('/personal-health-record/immunisations'),
  NotesPage: () => push('/personal-health-record/clinical-notes')
};

const PHRRoutes = isDesktop() && [
  <ProtectedRoute
    exact
    key="/personal-health-record"
    path="/personal-health-record"
    component={HealthSummary}
  />,
  <ProtectedRoute
    key="/personal-health-record/labs"
    path="/personal-health-record/labs"
    component={LabsPage}
  />,
  <ProtectedRoute
    key="/personal-health-record/immunisations"
    path="/personal-health-record/immunisations"
    component={ImmunisationsPage}
  />,
  <ProtectedRoute
    key="/personal-health-record/clinical-notes"
    path="/personal-health-record/clinical-notes"
    component={ClinicalNotesPage}
  />
];

export default PHRRoutes;
export { go };
