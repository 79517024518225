import PropTypes from 'prop-types';

const scriptPropType = PropTypes.shape({
  Id: PropTypes.string.isRequired,
  Status: PropTypes.string.isRequired,
  FirstName: PropTypes.string.isRequired,
  LastName: PropTypes.string.isRequired,
  OrganisationName: PropTypes.string.isRequired,
  OrganisationId: PropTypes.string.isRequired,
  DateTime: PropTypes.string.isRequired,
  Medications: PropTypes.objectOf(PropTypes.string).isRequired,
  StatusColour: PropTypes.string.isRequired,
  Invoice: PropTypes.shape({
    Total: PropTypes.number.isRequired,
    Items: PropTypes.arrayOf(
      PropTypes.shape({
        Amount: PropTypes.number.isRequired,
        Name: PropTypes.string.isRequired,
      }),
    ),
  }),
  Read: PropTypes.bool,
});

const fullScriptPropType = PropTypes.shape({
  Id: PropTypes.string.isRequired,
  Status: PropTypes.string,
  FirstName: PropTypes.string.isRequired,
  LastName: PropTypes.string.isRequired,
  OrganisationName: PropTypes.string.isRequired,
  OrganisationId: PropTypes.string.isRequired,
  DateTime: PropTypes.string.isRequired,
  Medications: PropTypes.objectOf(PropTypes.string).isRequired,
  StatusColour: PropTypes.string.isRequired,
  PmsUserName: PropTypes.string,
  PmsUserId: PropTypes.string,
  DependantId: PropTypes.string,
  Message: PropTypes.string,
  PmsUserTitle: PropTypes.string,
  PmsUserProfilePictureUrl: PropTypes.string,
  LocationName: PropTypes.string,
  LocationAddress: PropTypes.string,
  Invoice: PropTypes.shape({
    Total: PropTypes.number.isRequired,
    Items: PropTypes.arrayOf(
      PropTypes.shape({
        Amount: PropTypes.number.isRequired,
        Name: PropTypes.string.isRequired,
      }),
    ),
  }),
  PharmacyId: PropTypes.string,
  Read: PropTypes.bool,
});

const detailsPropType = PropTypes.shape({
  Id: PropTypes.string,
  DependantId: PropTypes.string,
  Message: PropTypes.string,
  PmsUserName: PropTypes.string,
  PmsUserId: PropTypes.string,
  PmsUserTitle: PropTypes.string,
  PmsUserProfilePictureUrl: PropTypes.string,
  Invoice: PropTypes.shape({
    Total: PropTypes.number.isRequired,
    Items: PropTypes.arrayOf(
      PropTypes.shape({
        Amount: PropTypes.number.isRequired,
        Name: PropTypes.string.isRequired,
      }),
    ),
  }),
  PharmacyId: PropTypes.string,
  Status: PropTypes.string,
  StatusColour: PropTypes.string,
  DateTime: PropTypes.string,
});

export { scriptPropType, detailsPropType, fullScriptPropType };
