import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CSSTransitionGroup } from 'react-transition-group';

import Radio from '../../shared/Radio/index';
import CircleCheckbox from '../../../components/CircleCheckbox/index';
import DynamicTick from '../../shared/DynamicTick/index';
import styles from './pickUpOption.module.css';
import { isDesktop } from '../../../config';
import { pricingPropType } from '../../propTypes/index';
import { PatientTag } from '../../components/PatientTag.tsx'
import threeDotsVertical from '../../../svgs/3dotsVertical.svg';
import { Image } from '../../../components/vensaComponents/vensaComponents/components/Image'
import { ReactComponent as Cross } from 'svgs/closePrimary.svg';

const formatPrice = price => {
  const dollars = Math.floor(price / 100);
  const cents = price % 100;
  return `${dollars}.${cents}`;
};



const PickUpOption = ({
  option,
  selectedOrg,
  onOrgClick,
  selectedUrgency,
  onUrgencyClick,
  isActive,
  isPlaceHolder,
  onAddPharmClick,
  pricingCategory,
  deletePharmacy,
  showMenu,
  first
}) => {

  const [clickedDelete, setClickDelete] = useState(false);
  const [position, setPosition] = useState({})
  const removePosition = {
    top: `${position.top}px`,
    left: `${position.left + (position.width - 190)}px`,
  };
  return (
    <div
      onClick={isActive && !isPlaceHolder && onOrgClick}
      className={classnames(styles.container, {
        [styles.selectedContainer]: selectedOrg,
        [styles.inactive]: !isActive
      })}
      style={{ cursor: "pointer", position: "relative" }}
    >
      {isDesktop() ? (
        <div
          onClick={onOrgClick}
          className={classnames(styles.name, {
            [styles.selectedName]: selectedOrg
          })}
        >
          {selectedOrg && !first && <Cross onClick={() => deletePharmacy(option.PharmacyId)} style={{ position: "absolute", height: "10px", width: "10px", right: "10px", top: "10px" }} />}
          {option.Name}
        </div>
      ) : (
        <div className={styles.details}>
          <div className={styles.removeOverlay}
            style={{
              visibility: clickedDelete ? "visible" : "hidden",
              opacity: clickedDelete ? "1" : "0",
            }}
            onClick={() => setClickDelete(false)}
          />

          {clickedDelete && (
            <div className={styles.removeContainer} style={removePosition}>
              <div onClick={() => deletePharmacy(option.PharmacyId)}>Remove pharmacy</div>
            </div>
          )}
          <CircleCheckbox
            onClick={isActive && onOrgClick}
            selected={selectedOrg}
            className={styles.checkbox}
            selectedClassName={styles.selectedCheckbox}
          />
          <div onClick={isActive && onOrgClick} className={styles.name}>
            {option.Name}
          </div>
          <div onClick={isActive && onOrgClick} className={classnames(styles.disableBox, styles.addressBase, styles.address)}>
            {option.Address}
          </div>
          {!isActive &&
            <div className={styles.disabledContainer}>
              <div className={classnames(styles.disableBox, styles.disabledBase, styles.disabled)}>
                Pharmacy info updated - remove and
              </div>
              <div className={classnames(styles.disableBox, styles.disabledNewPharmacyBase, styles.disabledNewPharmacy)} onClick={onAddPharmClick}>
                Add new pharmacy
              </div>
            </div>
          }
          {showMenu &&
            <div onClick={() => setClickDelete(true)} className={styles.threeDots}>
              <Image src={threeDotsVertical} />
            </div>
          }

        </div>
      )}
      <CSSTransitionGroup
        component="div"
        transitionName={{
          leave: styles.leave,
          leaveActive: styles.leaveActive,
          enter: styles.enter,
          enterActive: styles.enterActive,
        }}
        transitionLeaveTimeout={200}
        transitionEnterTimeout={200}
        className={styles.urgencyContainer}
      >
        {
          selectedOrg && isDesktop() && !isActive && (
            <div>
              <div className={classnames(styles.disableBox, styles.addressBase, styles.address)}>
                {option.Address}
              </div>
              <div className={styles.disabledContainer}>
                <span className={classnames(styles.disableBox, styles.disabledBase, styles.disabled)}>
                  Pharmacy info updated - remove and&nbsp;
                </span>
                <span className={classnames(styles.disableBox, styles.disabledNewPharmacyBase, styles.disabledNewPharmacy)} onClick={onAddPharmClick}>
                  Add new pharmacy
                </span>
              </div>
            </div>

          )
        }
        {selectedOrg &&
          option.Options.map((o, index) => (
            <div key={o.Option} onClick={onUrgencyClick(index)}>
              {isDesktop() ? isActive ? (
                <div className={styles.urgency}>
                  <Radio
                    className={styles.radio}
                    selected={selectedUrgency === index}
                  />
                  <div className={styles.priceTime}>
                    {`${o.Note} - $${formatPrice(o.Price.toString())}`}
                  </div>
                </div>
              ) : <></> : (
                <div
                  className={classnames(styles.urgency, {
                    [styles.selectedUrgency]: selectedUrgency === index,
                  })}
                >
                  {pricingCategory &&
                    <PatientTag
                      Text={pricingCategory}
                    />
                  }
                  <DynamicTick selected={selectedUrgency === index} />
                  <div className={styles.note}>{`${o.Note}`}</div>
                  <div className={styles.price}>{`$${formatPrice(
                    o.Price.toString(),
                  )}`}</div>
                </div>
              )}
            </div>
          ))}
      </CSSTransitionGroup>
    </div>
  )
};

PickUpOption.propTypes = {
  option: pricingPropType,
  selectedOrg: PropTypes.bool,
  onUrgencyClick: PropTypes.func.isRequired,
  onOrgClick: PropTypes.func.isRequired,
  selectedUrgency: PropTypes.number,
  isActive: PropTypes.bool.isRequired,
  onAddPharmClick: PropTypes.func.isRequired,
  pricingCategory: PropTypes.string,
  deletePharmacy: PropTypes.func.isRequired,
};

export default PickUpOption;
