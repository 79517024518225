import { call, takeEvery, put } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';
import { GenericParserHttp } from '../../libs/Http';

const api = (id, token) =>
  GenericParserHttp.delete(`consumer/carecircle/${id}`, { token });

function* removeDependant({ payload: { id, token } }) {
  try {
    yield call(api, id, token);
    yield put(actions.removeDependantSuccess(id));
    yield put(actions.fetchDependants(token));
    yield put(actions.removeDependantReset());
  } catch (err) {
    yield put(actions.removeDependantFailure(err));
  }
}

function* removeDependantSaga() {
  yield takeEvery(actionTypes.removeDependant, removeDependant);
}

export { removeDependantSaga };
