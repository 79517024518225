import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './circleCheckbox.module.css';

const CircleCheckbox = ({
  selected,
  className,
  selectedClassName,
  onClick,
}) => (
  <div
    className={classnames(
      styles.circleIcon,
      className,
      { [styles.selected]: selected },
      { [selectedClassName]: selected },
    )}
    style={{borderColor: selected ? "#07a3c8" : ""}}
    onClick={onClick}
  >
    &nbsp;
  </div>
);

CircleCheckbox.propTypes = {
  selected: PropTypes.bool,
  className: PropTypes.string,
  selectedClassName: PropTypes.string,
  onClick: PropTypes.func,
};

export default CircleCheckbox;
