import React from 'react';

type Props = {
    size: string;
    orientation: 'horizontal' | 'vertical'
}

const Divider = ({size, orientation}: Props) => {
  const sizeStyle = orientation === 'horizontal' ? {height: size} : {width: size};
  return (
    <span style={{display: 'block', flexShrink: 0, ...sizeStyle}}/>
  )
}

export default Divider;