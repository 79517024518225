import React from 'react';
import hospitalIcon from 'images/notEnrolled.png';
import styles from './CancelError.module.scss';
import HeaderCardUI from 'components/shared/mobile/HeaderCardUI';
import { ReactComponent as Phone } from 'svgs/phone.svg';
import cn from 'classnames';

type ErrorProps = {
  practiceName: string;
  cancellationHour: number;
  phone: string;
  handleClick?: () => void;
};

const CancelError: React.FC<ErrorProps> = ({
  handleClick, 
  cancellationHour,
  practiceName,
  phone
}) => {
  return (
    <HeaderCardUI text='Whoops' align='center' handleClick={handleClick}>
      <img src={hospitalIcon} alt="hospital" className={cn(styles.icon, styles.center)} />
      <div className={styles.center}>
        {`This practice does not allow bookings to be cancelled
          online within ${
            cancellationHour
          } hours of the appointment time.`}
        <br />
        <br />
        {'Please call your practice to cancel this appointment.'}
      </div>
      <span className={cn(styles.practice, styles.center, styles.bigSpacing)}>{practiceName}</span>
      <span className={cn(styles.center, styles.normalSpacing)}>{phone ? phone : ''}</span>
      <a href={`tel:${phone}`} className={cn(styles.center, styles.normalSpacing)}>
        <Phone className={styles.smallIcon} />
      </a>
    </HeaderCardUI>
  )
};

export default CancelError;