import React from 'react';
import moment from 'moment';
import { map } from 'lodash/fp';

import { ReactComponent as ScriptIcon } from '../../../svgs/mainScript.svg';
import styles from './pastScript.module.css';

const PastScript = (script, onClick) => (
  <div
    role="presentation"
    key={script.Id}
    className={styles.container}
    onClick={onClick(script.Id)}
  >
    <div className={styles.date}>
      {moment(script.DateTime).format('DD/MM/YY')}
    </div>
    <div className={styles.iconContainer}>
      <ScriptIcon className={styles.icon} />
    </div>
    <div className={styles.detailsContainer}>
      <div className={styles.name}>{`${script.FirstName} ${
        script.LastName
      }`}</div>
      <div className={styles.practice}>{`at ${script.OrganisationName}`}</div>
      <div className={styles.meds}>{`${map(m => ` ${m}`)(
        Object.values(script.Medications),
      )}`}</div>
    </div>
  </div>
);

export default PastScript;
