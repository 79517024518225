import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import logo from 'images/logoName.png';
import PinControl from 'Identity/DesktopIdentityPage/components/PinControl';
import { ReactComponent as Back } from 'svgs/circleRightArrow.svg';
import { ReactComponent as Lock } from 'svgs/pinLock.svg';
import bgImage from 'images/bgImage.png';
import { go } from 'routes';
import { getToken, useComponentDidMount } from 'libs/newHttp';
import { useAccountStore } from 'store/mobx/mobx';
import Loading from 'components/loadingSpinner';
import LearnMorePopUp from 'components/LearnMorePopUp/LearnMorePopUp';
import { getLoginPageURL } from 'libs/utils';

const Container = styled.div`
    width: 100%;
    height: 100%;
    background: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
`
const Overlay = styled.div`
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.5;
    background-color: black;
    z-index: 5;
`
const Inner = styled.div`
    height: 650px;
    width: 534px;
    background-color: white;
    margin: auto;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    position: relative;
    padding: 0px 25px;
    z-index: 6;
`
const Text = styled.div`
    text-align: center;
    color: #07A3C8;
    font-size: 17px;
    margin-bottom: 1rem;
`
const Link = styled.div`
    text-align: center;
    color: #07A3C8;
    font-size: 15px;
    margin: 1rem 0rem;
    cursor: pointer;
`

const DesktopPinCode: React.FC = observer(() => {
    const store = useAccountStore();
    const [unAvailable, setUnAvailable] = useState(false);

    useComponentDidMount(() => {
        if(!getToken()) {
            go.login();
        } else {
            store.getPinCodeLength();
        }
    })
    return (
        <Container style={{backgroundImage: `url(${bgImage})`}}>
            <Loading isFetching={store.loading} style={{fill: "#07A3C8"}}>
                <Overlay />
                {unAvailable &&
                    <LearnMorePopUp hide={() => setUnAvailable(false)} type={"UNAVAILABLE_DESKTOP"} />
                }
                <img src={logo} alt="logo" style={{position: "absolute", top: "30px", left: "35px", width: "100px"}} />
                <Inner>
                    <Back data-test="button-back" onClick={() => { 
                        store.logout(() => window.location.href=getLoginPageURL())
                    }} style={{
                            transform: "rotate(180deg)",
                            cursor: "pointer",
                            position: "absolute",
                            top: "30px",
                            left: "30px",
                            height: "48px",
                            width: "48px",
                        }}/>
                    <Lock style={{margin: "1rem auto", marginBottom: "2rem"}} />
                    <Text>Welcome back to Vensa. Please enter your {store.pinCodeLength} digit code to login to your portal.</Text>
                    <PinControl 
                        verify={true} 
                        goNext={() => go.home()} 
                        goBack={() => {}}
                        length={store.pinCodeLength}    
                        ifGuessable={() => {}}
                        submitPin={true}
                    />
                    <Link onClick={() => setUnAvailable(true)}>Forgot my pin code</Link>
                </Inner>
            </Loading>
        </Container>
    )
})

export default DesktopPinCode;