import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Arrow } from 'svgs/whiteRighArrow.svg';
import { ReactComponent as GreenTick } from 'svgs/greenIdentityTick.svg';
import redCross from 'images/redIdentityCross.png';
import { ReactComponent as OrangeTick } from 'svgs/orangeTick.svg';
import { IIdentityRequestsStatus } from 'store/mobx/accountStore/accountStore.type';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    background-color: white;
    height: 100px;
    margin-left: 8px;
    box-shadow: 0px 3px 6px lightgray;
    border-radius: 5px;
    margin: 1rem;
    height: 100px;
    position: relative;
`
const FlexC = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto 0rem;
`
const Image = styled.img`
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin: auto 2rem;
`
const Name = styled.div`
    color: #07A3C8;
    font-size: 15px;
`
const Address = styled.div`
    color: #585858;
    font-size: 13px;
    margin: 0.5rem 0rem;
`
const Status = styled.div`
    color: #64E691;
    font-weight: 600;
    font-size: 14px;
`
const Button = styled.div`
    color: white;
    background-color: #07A3C8;
    border-radius: 4px;
    margin: auto 0rem;
    font-size: 13px;
    padding: 0.5rem 1rem;
    margin-left: auto;
    margin-right: 2rem;
    cursor: pointer;
`

interface IPractice {
    OrganisationId: string;
    OrganisationName: string;
    Address: string;
    OnlineVerificationEnabled: boolean;
    PictureUrl: string;
    IdentityRequest: {
        Status: number;
        RequestDate: string;
        Id: string
    } | null;
    Phone: string;
    Pictures: string[];
}
interface Props {
    practice: IIdentityRequestsStatus;
    onContinue: () => void;
    status: number;
    onViewClick: (practice: IPractice) => void;
}

const States = [
    "Online for Verification", 
    "Identity verification is pending from practice", 
    "The practice does not provide online verifications",
    "Online for verification for your profile is not possible",
    "Identity verification declined by the practice",
    "Identity verified by the practice",
    "Identity already verified with a different Vensa account"
]


// Identity status codes: 0 - Online, 1 - Pending, 2 - Offline, 3 - Unsupported, 4 - Declined, 5 - Verified, 6 - Already Verified
const PracticeCard: React.FC<Props> = ({practice, onContinue, status, onViewClick}) => {
    const address = practice!.Address.length > 40 ? practice!.Address.substring(0, 40) + "..." : practice!.Address;

    return (
        <Container style={{borderTop: status === 0 || status === 5 ? "3px solid #64E691" : status === 1 ? "3px solid #F5722C" : "3px solid #FC7B7B"}}>
            <Image src={"https://stormdevtest.blob.core.windows.net/organisation/184c1b3a-6887-ede1-17c4-d9028e9e7f32/184c1b3a-6887-ede1-17c4-d9028e9e7f32.jpg"} />
            {status === 1 && <OrangeTick style={{position: "absolute", top: "10px", left: "70px", width: "35.98px", height: "36.49px", overflow: "visible"}} />}
            {status === 4 && <img src={redCross} alt={" "} style={{position: "absolute", top: "10px", left: "70px", width: "35.98px", overflow: "visible", height: "36.49px"}}/>}
            {status === 5 && <GreenTick style={{position: "absolute",overflow: "visible", top: "10px", left: "70px"}}/>}
            {status !== 1 && status !== 4 && status !== 5 ?
                <FlexC>
                    <Name>{practice!.OrganisationName}</Name>
                    <Address>{address}</Address>
                    <Status style={{color: status === 0 ? "#64E691" : "#FC7B7B"}}>{States[status]}</Status>
                </FlexC>
                :
                <FlexC>
                    <Status style={{color: status === 5 ? "#64E691" : status === 1 ? "#F5722C" : "#FC7B7B", margin: "5px 0px"}}>{States[status]}</Status>                
                    <Name>{practice!.OrganisationName}</Name>
                    <Address>{address}</Address>
                </FlexC>
            }
            {status === 0 && practice && <Button onClick={() => onContinue()}>Continue<Arrow style={{transform: "translate(2px, 1px)", marginLeft: "10px"}} /></Button>}
            {status === 1 && practice && <Button onClick={() => onViewClick(practice)}>View<Arrow style={{transform: "translate(2px, 1px)", marginLeft: "10px"}} /></Button>}
            {status === 4 && practice && <Button onClick={() => onViewClick(practice)}>View<Arrow style={{transform: "translate(2px, 1px)", marginLeft: "10px"}} /></Button>}
            {status === 5 && practice && <Button onClick={() => onViewClick(practice)}>View<Arrow style={{transform: "translate(2px, 1px)", marginLeft: "10px"}} /></Button>}
        </Container>
    );
}

export default PracticeCard;