import React, { ComponentType, useState } from 'react';
import Avatar from 'components/ClickableLabelAvatar';
import { withStyles } from 'hocs/withStyles';
import styles from './DeclarePanel.module.scss';

import { useVerification } from './useVerification';
import SignaturePanel from 'components/shared/common/SignaturePanel';
import ReactSignatureCanvas from 'react-signature-canvas';
import Upload from 'components/core/Upload';
import Attachment from 'components/shared/common/FileIndicator';
import { ConfirmProps, Touchable } from 'types';
import { Handler } from 'components/core/Wizard/types';
import { generateDeclarationFile } from './services';
import alert from 'libs/Alert';
import { StatementProps } from './Statement';
import RightSign from 'components/shared/desktop/RightSign';
import cn from 'classnames';
import { RelationKeys, RELATION_SHIP } from './constants';

const adapter = (touch: boolean) => ({
    attachment: {
        style: {
            width: touch ? '30%' : '25%'
        }
    }
});

type Props = {
    values: Record<string, any>;
    statement: ComponentType<StatementProps>;
    confirm: ComponentType<ConfirmProps>;
    doNext: () => void;
    doError?: () => void;
    handleStep: (handler: Handler) => void;
    canvas: React.RefObject<ReactSignatureCanvas>;
} & Touchable;

const DeclarePanel = ({
    touch = false,
    values,
    statement: Statement,
    confirm: Confirm,
    doNext,
    doError,
    handleStep,
    canvas
}: Props) => {
    const fullName = `${values.firstName} ${values.lastName}`;
    const [practiceName, consumerName, dateOfBirth, error] = useVerification();
    const StyledAvatar = withStyles({width: '100%', margin: '2rem 0'})(Avatar);

    const [next, setNext] = useState(!!values.signatureURL);
    const handleNext = () => {
        if(canvas && canvas.current) {
          canvas.current.getCanvas().toBlob(blob => {
            values.signatureBlob = blob;
            doNext();
          });
        }
      }

    // handle callback after a signing action
    const handleSigned = (flag: boolean) => {
        setNext(flag);
    }

    const UploadDocument = withStyles({width: adapter(touch).attachment.style.width, marginRight: '20px'})(Upload);
    const [documents, setDocuments] = useState({'Document 1': !!values['Document 1'], 'Document 2': !!values['Document 2'], 'Document 3': !!values['Document 3']});
    type Document = keyof typeof documents;
    const handleDocumentChange = async (key: string, file: File) => {
        values[key] = file;
        setDocuments({...documents, [key]: true});
    }
    
    const handleDocumentRemove = (key: string) => {
        delete values[key];
        setDocuments({...documents, [key]: false});
    }

  // show error and complete
  error && alert.error(error);
  error && doError && doError();

  practiceName && consumerName && 
  handleStep(async() => {
    const pdf = await generateDeclarationFile({
      firstName: values.firstName,
      lastName: values.lastName,
      signature: values.signatureBlob,
      relationship: RELATION_SHIP[parseInt(values.relationship) as RelationKeys]
    }, 
    Object.keys(values).filter(k => k.startsWith('Document')).map(k => values[k]),
    practiceName, consumerName);
    values.pdf = pdf;
  });

  let RedSign;
  if(!touch) {
    RedSign = withStyles({position: 'absolute', top: '49%', left: 'calc(50% - max(20%, 250px) - 60px)', zIndex: 10})(RightSign);
  }

  return (
    <>
        <StyledAvatar
            imgUrl={values.avatarBase64}
            text={fullName}
            textClassName={cn(styles.avatarText, {[styles.touch]:touch})} />
        <Statement
            touch={touch}
            fullName={fullName}
            dateOfBirth={values.dateOfBirth}
            organisation={practiceName!}
         />
         {
            RedSign && 
            <RedSign width={85} height={50} fill='#FF6A65'>
                <text className={styles.sign} y={20}>
                    <tspan x={10}>Sign</tspan>
                    <tspan x={10} dy={20}>Here</tspan>
                </text>
            </RedSign>
         }
         <p />
         <SignaturePanel
            touch={touch}
            canvasRef={canvas} 
            dataUrl={values.signatureURL}
            onChange={handleSigned} />
        <span className={styles.warning}>False declarations are punishable by law.</span>
        <span className={styles.subtitle}>Examples of acceptable supporting documents (jpeg, png and pdf)</span>
        <p className={styles.note}>
            - Passport<br/>
            - Birth Certificate<br/>
            - Guardianship documents<br/>
            - Oranga Tamariki documents
        </p>
        {
            Object.keys(documents).map((k, index, keys) =>(
            <UploadDocument
                key={index}
                id={k}
                onChange={handleDocumentChange}
                onRemove={handleDocumentRemove}
                acceptType={['jpg', 'png', 'pdf']}>
                {
                    ({
                        onFileUpload,
                        onFileRemove
                    }) => (
                        <Attachment
                            touch={touch}
                            title={k}
                            handleAction={documents[k as Document] ? onFileRemove : onFileUpload}
                            styleClass={styles}
                            hasFile={documents[k as Document]}
                        />
                    )
                }
            </UploadDocument>
            ))
        }
        <Confirm handleClick={handleNext} clickable={next} />
    </>
  )
};

export default DeclarePanel;