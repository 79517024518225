import CtaButton from 'components/CtaButton';
import LearnMorePopUp from 'components/LearnMorePopUp/LearnMorePopUp';
import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as Note } from 'svgs/noteBentCorner.svg';

interface Props {
    name: string;
    practice: string;
    provider: string;
    question: string;
    answers: string[];
    popUps: boolean;
    onNext: (val: number) => void;
    openText: boolean;
    setChecked?: (checked: boolean) => void;
    setText?: (val: string) => void;
}

const Container = styled.div`
    height: 100%;
    margin-bottom: 1rem;
`
const Card = styled.div`
    border-radius: 4px;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    padding: 1rem;
    position: relative;
    height: 100%;
`
const FlexH = styled.div`
    display: flex;
    flex-direction: row;
`
const FlexV = styled.div`
    display: flex;
    flex-direction: column;
`
const Title = styled.div`
    font-size: 17px;
    font-weight: 600;
    color: #6C6C6C;
    text-align: left;
    margin-bottom: 0.1rem;
`
const Subtitle = styled.div`
    font-size: 15px;
    font-weight: 300;
    color: #6C6C6C;
    margin: 0.1rem 0rem;
    text-align: left;
`
const Text = styled.div`
    font-size: 17px;
    color: #6C6C6C;
    text-align: left;
    margin: 2rem auto;
    margin-bottom: 0rem;
    height: 80px;
`
const BigTitle = styled.div`
    color: #6C6C6C;
    font-size: 21px;
    text-align: left;
    margin: 0.5rem 0rem;
    font-weight: 500;
    margin-bottom: 2rem;
`
const TextArea = styled.textarea`
    width: 100%;
    height: 283px;
    border: 1px solid #95989A;
    font-size: 17px;
    caret-color: #07A3C8;
    font-family: Roboto;
    padding: 1rem;
`

const FeedbackQuestion: React.FC<Props> = ({name, practice, provider, question, onNext, answers, openText, setChecked, setText}) => {
    const [toggle, setToggle] = useState(false);
    const [freeText, setFreeText] = useState("");

    return (
        <Container>
            {!openText ?
                <Card>
                    <FlexH style={{marginLeft: "1rem"}}>
                        <Note />
                        <FlexV style={{marginLeft: "1rem"}}>
                            <Title>{name}</Title>
                            <Subtitle>{practice}</Subtitle>
                            <Subtitle>{provider}</Subtitle>
                        </FlexV>
                    </FlexH>
                    <Text>{question}</Text>
                    <FlexV style={{padding: "1rem"}}>
                        {answers.map((item, i) => {
                            return (
                                <FlexH key={i} style={{alignItems: "center", width: "100%", margin: "0.5rem 0rem"}}>
                                    <input 
                                        type={"radio"} 
                                        name={item} 
                                        style={{
                                            height: "25px",
                                            width: "25px",
                                        }}
                                        onClick={(e: any) => {
                                            e.target.checked = false;
                                            onNext(i);
                                        }}
                                    />
                                    <div style={{color: "#6C6C6C", marginLeft: "0.5rem", width: "300px"}}>{item}</div>
                                </FlexH>
                            )
                        })}
                    </FlexV>
                </Card>
                :
                <Card>
                    <BigTitle>
                        What improvements do you think are important?
                    </BigTitle>
                    <TextArea value={freeText} placeholder={"Any improvements we can make at the practice?"} onChange={(e: any) => setFreeText(e.target.value)}/>
                    <FlexH style={{alignItems: "center", width: "100%", margin: "1rem 0rem"}}>
                        <input 
                            type={"checkbox"} 
                            name={"check"} 
                            checked={toggle}
                            style={{
                                height: "25px",
                                width: "25px"
                            }}
                            onClick={() => {
                                setToggle(!toggle);
                                if (setChecked === undefined) { return }
                                setChecked(!toggle);
                            }}
                        />
                        <div style={{color: "#6C6C6C", marginLeft: "0.5rem"}}>I would like to review my options for further participation</div>
                    </FlexH>
                    <div style={{position: "absolute", bottom: "1rem", right: "1rem"}}>
                        <CtaButton 
                            active={true}
                            onClick={() => {
                                if (setText === undefined) { return }
                                setText(freeText);
                                onNext(0);
                            }}
                        />
                    </div>
                </Card>
            }
        </Container>
    )
}

export default FeedbackQuestion;