import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Envelope } from '../../../svgs/envelope.svg';
import { ReactComponent as Lock } from '../../../svgs/lock.svg';
import { isDesktop } from '../../../config';
import styles from './signInForm.module.css';

class SignInForm extends Component {
  static propTypes = {
    login: PropTypes.func.isRequired,
    goForgotPassword: PropTypes.func.isRequired,
    goToPatientDetails: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.login(this.email.value, this.password.value);
  }
  forgotPassword() {
    this.props.goForgotPassword();
  }
  render() {
    return (
      <form className={styles.form} onSubmit={this.handleSubmit}>
        <div className={styles.innerContainer}>
          {isDesktop() && <span className={styles.label}>Email</span>}
          <div className={styles.inputContainer}>
            {!isDesktop() && <Envelope className={styles.envelope} />}
            <input
              className={styles.input}
              type="email"
              ref={c => {
                this.email = c;
              }}
              placeholder={!isDesktop() ? 'Email' : null}
            />
          </div>
          {isDesktop() && <span className={styles.label}>Password</span>}
          <div className={styles.inputContainer}>
            {!isDesktop() && <Lock className={styles.lock} />}
            <input
              className={styles.input}
              type="password"
              ref={c => {
                this.password = c;
              }}
              placeholder={!isDesktop() ? 'Password' : null}
            />
          </div>
          <div className={styles.forgotPassword} onClick={this.forgotPassword}>
            Forgot my password
          </div>
        </div>
        <div className={styles.footer}>
          <div
            className={styles.guestLink}
            onClick={this.props.goToPatientDetails}
          >
            I don't have an account
          </div>
          <button type="submit" className={styles.buttonLogIn}>
            Log In
          </button>
        </div>
      </form>
    );
  }
}

export default SignInForm;
export { styles };
