import React from 'react';
import styled from 'styled-components';
import { useIdentityStore } from '../../../store/mobx/mobx';

import pic from '../../../images/practicepic.png';
import { ReactComponent as Tick } from '../../../svgs/orangeTick.svg';
import Button from '../../../components/DesktopComponents/BlueButton';
import { go } from '../../../routes';
import { useComponentDidMount } from '../../../libs/newHttp';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`
const Title = styled.div`
    width: 100%;
    font-weight: 600;
    color: #585858;
    font-size: 17px;
    text-align: center;
    padding: 1.5rem 0rem;
`
const Subtitle = styled.div`
    font-size: 13px;
    width: 100%;
    text-align: center;
`
const Pic = styled.img`
    width: 300px;
    margin: 1rem auto;
    display: block;
`
const Text = styled.div`
    font-size: 16px;
    color: #6C6C6C;
    text-align: center;
    padding: 0.5rem;
    margin: 0.5rem auto;
`

const Submitted = () => {
    const store = useIdentityStore();

    useComponentDidMount(store.resetIdentityStore);
    
    return (
        <Container>
            <Title>{store.selectedPracticeName}</Title>
            <Subtitle>{store.selectedAddress}</Subtitle>
            <Pic src={pic} alt="img" />
            <Tick style={{display: "block", margin: "1rem auto"}} />
            <Text>The team at {store.selectedPracticeName} have been asked to verify your identity.</Text>
            <Text>We recommend you give them a call when you can to complete the process.</Text>
            <Text>You should receive an email confirming that your identity has been successfully verified.</Text>
            <Text>You can call the practice on {store.selectedPhone}</Text>
            <Button style={{fontSize: "13px", borderRadius: "4px", fontWeight: "600", marginTop: "2rem"}} text={"View Identity Page"} onClick={() => go.identity()}/>
        </Container>
    )
}

export default Submitted;