import withModal from 'hocs/withModal';
import { Observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { Touchable } from 'types';
import Template, { PopupModel } from './Template';

type Props = {
  modelData: PopupModel[];
} & Touchable;

interface Store {
  model: PopupModel,
  setCurrent: (index: number) => void;
}

const AlterablePopup = ({
  touch,
  modelData
}: Props) => {
  // Default action is jumping to next
  const handleAlteration = () => {
    store.setCurrent(modelData.findIndex(a => a.title === store.model.title) + 1);
  };

  modelData.forEach(m => {
    const nextButton = m.buttonData.filter(b => !b.handleClick);
    nextButton.forEach(b => b.handleClick = handleAlteration);
  });

  const store = useLocalObservable((): Store => ({
    model: modelData[0],
    setCurrent: (index: number) => {
      const validIndex = Math.max(0, Math.min(index, modelData.length - 1));
      store.model = modelData[validIndex];
    }
  } as Store));
  const Popup = withModal(Template, touch, 
    {
      width: touch ? '90%' : '35%', 
      padding: touch ? '1rem' : '1rem 6%', 
      margin: touch ? '5% auto 5%' : '10% auto 10%'});
  
  return (
    <Observer>
      {
        () => (
          <Popup
            model={store.model}
            touch={touch}
          />
        )
      }
    </Observer>
    
  )
}

export default AlterablePopup;