import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';

import CardBanner from '../../components/CardBanner/index';
import { formatMobile } from '../../../libs/Mobile';
import styles from './message.module.css';

const getMessageColor = color => {
  if (color === 'Red') return '#fff0ef';
  if (color === 'Green') return '#d3f3e3';
  return '#F9F9F9';
};

const getMessage = (message, date, mobile) => {
  const formattedDate = moment(date).format('DD/MM/YY');
  return `Sent to ${formatMobile(mobile)} on ${formattedDate}`;
};

const Message = ({ message, color, status, date, mobile, statusCode}) => (
  <div
    className={classnames(styles.container, { [styles.noMessage]: !message })}
  >
    <div
      className={styles.topInner}
      style={{ backgroundColor: message ? getMessageColor(color) : '' }}
    >
      <CardBanner status={status} color={color} statusCode={statusCode}/>
      <div className={styles.message}>{message}</div>
      {message && <div>{getMessage(message, date, mobile)}</div>}
    </div>
  </div>
);

Message.propTypes = {
  message: PropTypes.string,
  status: PropTypes.string,
  date: PropTypes.string,
  mobile: PropTypes.string,
  color: PropTypes.string,
};

export default Message;
