import React from 'react';
import styled from 'styled-components';

import PracticeCard from './PracticeCard';
import { useIdentityStore } from 'store/mobx/mobx';
import { IIdentityRequestsStatus } from 'store/mobx/accountStore/accountStore.type';
import { go } from 'routes';

const Container = styled.div`
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    height: 480px;
    margin: 1rem 0rem;
    width: 560px;
    align-items: center;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 10px;
     }
       
     &::-webkit-scrollbar-track {
         background: #F0F0F0;
         border-radius: 25px;
     }
     
     &::-webkit-scrollbar-thumb {
         background: #AFAFAF;
         border-radius: 25px;
     }
     
     &::-webkit-scrollbar-thumb:hover {
         background: #999; 
     }
`

interface IPractice {
    Address: string;
    BlackListed: boolean;
    BlackListedReason: string;
    EnrollmentStatusNotMeetPracticePolicy: boolean;
    IdentityRequest: {
        Status: number;
        RequestDate: string;
    } | null;
    IsOnline: boolean;
    MatchedPatientInPms: boolean;
    OnlineVerificationEnabled: boolean;
    OrganisationId: string;
    OrganisationName: string;
    Phone: string;
    PictureUrl: string;
    Pictures: string[];
}

interface IPracticeShort {
    OrganisationId: string;
    OrganisationName: string;
    Address: string;
    OnlineVerificationEnabled: boolean;
    PictureUrl: string;
    IdentityRequest: {
        Status: number;
        RequestDate: string;
        Id: string;
    } | null;
    Phone: string;
    Pictures: string[];
}

interface Props {
    practices: IIdentityRequestsStatus[];
    onGoNext: () => void;
    lengthOfList?: number;
    showLabel: () => void;
    onViewClick: (practice: IPracticeShort) => void;
}

// returns: 0 - Online
//          1 - Pending
//          2 - Not Online
//          3 - Not Allowed Call Practice
//          4 - Declined
//          5 - Approved   
//          6 - Already Verified by another account
export const getStatus = (practice: IIdentityRequestsStatus) => {
    if (practice.IdentityVerified) return 5;
    if (practice.IdentityRequest) {
        const status = practice.IdentityRequest.Status;
        // 0 = pending, 1 = approved, 2 = declined, 3 = none;
        return status === 0 ? 1 : status === 1 ? 5 : 4;
    }

    // If the Patient has already been verified by another Vensa account
    if (practice.AlreadyVerifiedByOtherAccount) {
        return 6;
    }

    // If the Patient is Blacklisted OR if the practice is online & online for verifications but they cant be found in PMS or dont meet enrollment requirements
    if (practice.BlackListed || (practice.IsOnline && practice.OnlineVerificationEnabled && (!practice.MatchedPatientInPms || practice.EnrollmentStatusNotMeetPracticePolicy))) {
        return 3
    }

    // If practice is not online for verification or online on Vensa
    if (!practice.IsOnline || !practice.OnlineVerificationEnabled) {
        return 2;
    }

    // If the practice is online and online for verifications as well as the user found in PMS and they meet enrolment requirements
    if (practice.IsOnline && practice.OnlineVerificationEnabled && practice.MatchedPatientInPms && !practice.EnrollmentStatusNotMeetPracticePolicy) {
        return 0;
    }

    return 2;
}

/*
const TEST_PRACTICES = [
    { 
        Address: "Address 1",
        BlackListed: false,
        BlackListedReason: "",
        EnrollmentStatusNotMeetPracticePolicy: false,
        IdentityRequest: {
            Status: 0,
        },
        IsOnline: true,
        MatchedPatientInPms: true,
        OnlineVerificationEnabled: true,
        OrganisationId: "0",
        OrganisationName: "Test Practice 0",
        Phone: "021 000 0000",
        PictureUrl: "",
    },
    { 
        Address: "Address 2",
        BlackListed: false,
        BlackListedReason: "",
        EnrollmentStatusNotMeetPracticePolicy: false,
        IdentityRequest: {
            Status: 1,
        },
        IsOnline: true,
        MatchedPatientInPms: true,
        OnlineVerificationEnabled: true,
        OrganisationId: "1",
        OrganisationName: "Test Practice 1",
        Phone: "021 111 1111",
        PictureUrl: "",
    },
    { 
        Address: "Address 2",
        BlackListed: false,
        BlackListedReason: "",
        EnrollmentStatusNotMeetPracticePolicy: false,
        IdentityRequest: {
            Status: 2,
        },
        IsOnline: true,
        MatchedPatientInPms: true,
        OnlineVerificationEnabled: true,
        OrganisationId: "0",
        OrganisationName: "Test Practice 1",
        Phone: "021 222 222",
        PictureUrl: "",
    }
]*/

const PracticeSelection: React.FC<Props> = ({practices, onGoNext, lengthOfList, showLabel, onViewClick}) => {
    const store = useIdentityStore();
    let check = false;
    /*if (practices.length < 8) {
        practices.unshift(TEST_PRACTICES[0], TEST_PRACTICES[1], TEST_PRACTICES[2]);
    }*/
    
    return (
        <Container>
            {lengthOfList === 1 &&
                <PracticeCard 
                    onContinue={() => {
                        store.setPractice(practices[practices.length - 1].OrganisationId, practices[practices.length - 1].OrganisationName, practices[practices.length - 1].Address, practices[practices.length - 1].Phone, practices[practices.length - 1].PictureUrl);
                        // TODO: Add Identity request status condition for view/continue buttons
                        onGoNext();
                    }} 
                    key={practices[practices.length - 1].OrganisationId}
                    practice={practices[practices.length - 1]}
                    status={getStatus(practices[practices.length - 1])}
                    onViewClick={() => {}}
                />                       
            }
            {lengthOfList !== 1 && practices.map((practice, i) => {
                let status = getStatus(practice);

                if (status === 1 || status === 4 || status === 5) { 
                    check = true;
                }
                if (i === practices.length && !check) {
                    showLabel();
                }

                return (
                    <PracticeCard 
                        onContinue={() => {
                            store.setPractice(practice!.OrganisationId, practice!.OrganisationName, practice!.Address, practice!.Phone, practice!.PictureUrl);
                            // TODO: Add Identity request status condition for view/continue buttons
                            onGoNext();
                        }} 
                        practice={practice}
                        status={status}
                        onViewClick={(practice: IPracticeShort) => {
                            if (status === 1 || status === 4 || status === 5) {
                                go.identityViewRequest(practice.OrganisationId);
                                return;
                            }
                        }}
                    />
                )
            })}
        </Container>
    );
}

export default PracticeSelection;