import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import styled from 'styled-components';
import UIContainer from 'components/UIContainer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as actions }  from 'components/UIContainer/actions/headerActions';
import { useComponentDidMount } from 'libs/newHttp';
import RouteBar from 'components/DesktopComponents/RouteBar';
import TabBar from 'components/DesktopComponents/TabBar';
import { go } from 'routes';
import { useAccountStore, useMyHealthStore } from 'store/mobx/mobx';
import ExpandableCard from './components/ExpandableCard/ExpandableCard';
import { actions as feedActions } from 'HealthFeed/actions/actions';
import { getFinalStatus } from 'Identity/components/IdentityLabel';
import Notifications from 'HealthFeed/HealthFeedDesktop/Notifications/Notifications';
import HalfBanner from 'components/DesktopComponents/HalfBanner';

const Outer = styled.div`
    width: 100%;
    background-color: #F9F9F9;
    overflow-y: auto;
    align-items: center;
    -ms-overflow-style: none; 
    height: 100%;
    scrollbar-width: none;  
    &::-webkit-scrollbar {
        display: none;
    }
    overflow: auto;
`
const Container = styled.div`
    padding: 0rem 0rem;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    width: 700px;
    padding-top: 2rem;
`
const Column = styled.div`
    margin: auto;
    margin-top: 0;
`
const Title = styled.div`
    font-size: 13px;
    color: #6C6C6C;
    margin: 1rem 0;
`
interface IProps {
    setHeader: (item: string) => void;
    feed: any;
    getFeed: () => void
}

const ClinicalNotesPage: React.FC<IProps> = observer(({setHeader, feed, getFeed}) => {
    const healthStore = useMyHealthStore();
    const store = useAccountStore();
    const [show, setShow] = useState(true)

    useComponentDidMount(() => {
        setHeader("PHR")
        healthStore.getClinicNotes();
        getFeed();
    })
    return(
        <UIContainer isFetching={healthStore.loading} navText='My Health Record'>
            <Outer>
                <RouteBar 
                    lowLevel={"Clinical Notes"}
                    lowLink={() => go.NotesPage()}
                />
                <TabBar type={1} id={3} />
                {getFinalStatus(store) !== 1 && !store.profile.PrivacyPolicySetup &&
                    <Notifications feed={feed} getFeed={() => {}} hide={() => {}} />          
                }
                {healthStore.clinicalErrorText !== null && show && getFinalStatus(store) === 1 &&
                    <HalfBanner 
                        bannerText={"Access Restricted"}
                        text={healthStore.clinicalErrorText}
                        linkText={"Check my privacy"}
                        linkOnClick={() => go.privacy()}
                        buttonText={"Ok got it"}
                        buttonOnClick={() => {
                            setShow(false);
                            store.sendAcknowledgement(0, () => {});
                        }}
                    />
                }
                <Container>
                    {healthStore.clinicNotesTab.newInLastMonth.map(item => 
                        <ExpandableCard item={item} type={"clinical"} />    
                    )}
                    {healthStore.clinicNotesTab.past.length > 0 || healthStore.clinicNotesTab.newInLastMonth.length > 0 ?
                        <Title>PREVIOUS CLINICAL NOTES</Title>
                        :
                        <Title>NO CLINICAL NOTES FOUND</Title>
                    }
                    {healthStore.clinicNotesTab.past.map(item => 
                        <ExpandableCard item={item} type={"clinical"} />    
                    )}
                </Container>
            </Outer>
        </UIContainer>
    )
})

const mapDispatchToProps = (dispatch: any) => ({
    setHeader: bindActionCreators<any, any>(actions.setSideHeaderItem, dispatch),
    getFeed: bindActionCreators(feedActions.getFeed, dispatch),
})
const mapStateToProps = (state: any) => ({
    feed: state.feedReducer.feed,
})

export default connect(mapStateToProps, mapDispatchToProps)(ClinicalNotesPage);
