import React, { useState, RefObject, useEffect } from 'react';
import styled from 'styled-components';

import Alert from '../../../libs/Alert';
import { ReactComponent as Arrow } from '../../../svgs/blueLeftLineArrow.svg';
import empty from '../../../svgs/emptyCircle.svg'
import { useIdentityStore, useAccountStore, useSecurityStore } from '../../../store/mobx/mobx';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`
const Row = styled.div`
    display: flex;
    flex-direction: row;
`
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem auto;
    margin-top: 1rem;
`
const Button = styled.div`
    width: 48px;
    height: 48px;
    border: 2px solid #07A3C8;
    color: #07A3C8;
    background-color: white;
    border-radius: 50%;
    font-size: 20px;
    line-height: 42px;
    margin: 6px;
    cursor: pointer;
    text-align: center;
    &:hover {
        background-color: #07A3C8;
        color: white;
    }
    transition: all 0.3s;
`
const CodeContainer = styled.form`
    display: flex;
    flex-direction: row;
    margin: auto;
    width: 100px;
`
const Code = styled.input`
    background-image: url(${empty});
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    outline: none;
    caret-color: transparent;
    font-size: 30pt;
    color: #07A3C8;
    width: 25px;
    text-align: center;
    font-family: "text-security-disc";
    --webkit-text-security: disc;
    background-position-y: 65%;
    padding: 0rem;
`

interface Props {
    verify: boolean;
    goNext: () => void;
    goBack: () => void;
    ifGuessable: () => void;
    length: number;
    submitPin?: boolean;
    mobile?: boolean;
    useRecoverAccountAPI?: boolean;
}

const guessable = [
    "1111", "2222", "3333", "4444", "5555", 
    "6666", "7777", "8888", "9999", "0123",
    "1234", "2345", "3456", "4567", "5678",
    "6789", "012345", "123456", "234567",
    "345678", "456789", "111111", "222222", 
    "333333", "444444", "555555", "666666", 
    "777777", "888888","999999", "0000", "000000"
]

const PinControl: React.FC<Props> = ({verify, goNext, goBack, length, ifGuessable, submitPin, mobile, useRecoverAccountAPI}) => {
    const store = useIdentityStore();
    const secStore = useSecurityStore();
    const accStore = useAccountStore();
    const refArr: RefObject<HTMLInputElement>[] = [];
    for (let i: number = 0; i < length; i++) {
        refArr.push(React.createRef<HTMLInputElement>());
    }
    const [current, setCurrent] = useState(0);
    // const [disable, setDisable] = useState(false);

    const getFocus = () => {
        refArr[current].current!.focus();
    }

    useEffect(() => {
        if (refArr[current]) {
            if (refArr[current].current) {
                getFocus();
            }
        }
    })
    const onClick = (value: string) => {
        const isNum = /^\d+$/.test(value)
        if (!isNum) { return; }

        refArr[current].current!.value = value;
        if (current !== length - 1) { 
            refArr[current + 1].current!.focus(); 
            setCurrent(current + 1);
        } else { 
            if (!verify) { 
                if (length === 4) { 
                    store.addPin(refArr[0].current!.value + refArr[1].current!.value + refArr[2].current!.value + refArr[3].current!.value) 
                }
                if (length === 6) { 
                    store.addPin(refArr[0].current!.value + refArr[1].current!.value + refArr[2].current!.value + 
                        refArr[3].current!.value + refArr[4].current!.value + refArr[5].current!.value) 
                }
                
                let pin: string = length === 4 ?
                refArr[0].current!.value + refArr[1].current!.value + refArr[2].current!.value + refArr[3].current!.value :
                refArr[0].current!.value + refArr[1].current!.value + refArr[2].current!.value + 
                    refArr[3].current!.value + refArr[4].current!.value + refArr[5].current!.value;
            
                if (guessable.includes(pin)) { 
                    ifGuessable();
                    return;
                }
            }
            if (verify) {
                if (length === 4) {
                    if (submitPin) {
                        refArr[length - 1].current!.blur()
                        refArr[0].current!.autofocus = false;
                            let result = accStore.verifyPinCode(
                                refArr[0].current!.value + refArr[1].current!.value + refArr[2].current!.value + refArr[3].current!.value,
                                () => goNext(),
                                () => goBack(),
                                accStore.emailForRecoveringSuspendedAccount    
                            )
                            if (!result) refArr[0].current!.focus();
                            reset();
                            return;
                        }
                    if (store.pin === refArr[0].current!.value + refArr[1].current!.value + refArr[2].current!.value + refArr[3].current!.value) {
                        if (useRecoverAccountAPI) {
                            let search = window.location.search;
                            let params = new URLSearchParams(search);
                            let t = params.get('token');

                            if (t === null) { return }
                            accStore.postRecoverAccountChangePin(t, 
                                refArr[0].current!.value + refArr[1].current!.value + refArr[2].current!.value + refArr[3].current!.value,
                                () => {},
                                () => goBack()
                            )
                        } else {
                            store.setPin(() => {
                                setTimeout(() => {
                                    secStore.editSecuritySettings({
                                        SignIn2StepsVerification: true,
                                        NotifyUserAfter5FailedAttempts: false,
                                        SuspendAccountAfter10FailedAttempts: false,
                                    }, (verify && !submitPin))
                                    goNext();
                                }, 2000);
                            });
                        }
                    } else {
                        store.setError(true);
                        reset();
                        Alert.error("The Pin code did not match. Try again.");
                        goBack();
                    } 
                    return;
                } else {
                    if (submitPin) {
                        refArr[length - 1].current!.blur()
                            accStore.verifyPinCode(
                                refArr[0].current!.value + refArr[1].current!.value + refArr[2].current!.value + refArr[3].current!.value + refArr[4].current!.value + refArr[5].current!.value,
                                () => goNext(),
                                () => goBack(),
                                accStore.emailForRecoveringSuspendedAccount
                            )
                            reset();
                            return;
                    }
                    

                    if (store.pin === refArr[0].current!.value + refArr[1].current!.value + 
                        refArr[2].current!.value + refArr[3].current!.value + refArr[4].current!.value + refArr[5].current!.value) {
                            if (useRecoverAccountAPI) {
                                let search = window.location.search;
                                let params = new URLSearchParams(search);
                                let t = params.get('token');
    
                                if (t === null) { return }
                                accStore.postRecoverAccountChangePin(t, 
                                    refArr[0].current!.value + refArr[1].current!.value + refArr[2].current!.value + refArr[3].current!.value + refArr[4].current!.value + refArr[5].current!.value,
                                    () => {},
                                    () => goBack()
                                )
                            } else {
                                store.setPin(() => {
                                    setTimeout(() => {
                                        secStore.editSecuritySettings({
                                            SignIn2StepsVerification: true,
                                            NotifyUserAfter5FailedAttempts: false,
                                            SuspendAccountAfter10FailedAttempts: false,
                                        }, false)
                                        goNext();
                                    }, 2000);
                                });
                                return;
                            }
                    } else {
                        store.setError(true);
                        reset();
                        Alert.error("The PIN code did not match. Try again.");
                        goBack();
                        return;
                    }                     
                }
            }
            reset();
            goNext();
        }
    }
    const backClick = () => {
        if (refArr[current].current!.value !== "") {
            refArr[current].current!.value = "";
            return
        }

        if (current !== 0) {
            refArr[current - 1].current!.value = ""; 
            refArr[current - 1].current!.focus(); 
            setCurrent(current - 1); 
        }
    }
    /*
    const onChange = (e: any) => {
        const value = e.target.value;
        let isNum = /^\d+$/.test(value)

        if (!isNum) { return; }
        refArr[current].current!.value = value;
        if (current !== length - 1) { refArr[current + 1].current!.focus(); }
    }*/
    const onKeyDown = (e: any) => {
        if (e.key === "Backspace") {
            backClick();
        }
    }

    const reset = () => {
        if (length === 4) {
            refArr[0].current!.value = "";
            refArr[1].current!.value = "";
            refArr[2].current!.value = "";
            refArr[3].current!.value = "";
            setCurrent(0);   
        } else {
            refArr[0].current!.value = "";
            refArr[1].current!.value = "";
            refArr[2].current!.value = "";
            refArr[3].current!.value = "";
            refArr[4].current!.value = "";
            refArr[5].current!.value = "";
            setCurrent(0);
            
        }
    }

    return (
        <Container>
            {length === 4 &&
                <CodeContainer onClick={() => {getFocus()}}>
                    <Code autoFocus disabled={store.loading || accStore.loading} onKeyDown={(e) => onKeyDown(e)} onChange={(e) => onClick(e.target.value)} ref={refArr[0]} maxLength={1} type="tel"></Code>
                    <Code disabled={store.loading || accStore.loading} onKeyDown={(e) => onKeyDown(e)} onChange={(e) => onClick(e.target.value)} ref={refArr[1]} maxLength={1} type="tel"></Code>
                    <Code disabled={store.loading || accStore.loading} onKeyDown={(e) => onKeyDown(e)} onChange={(e) => onClick(e.target.value)} ref={refArr[2]} maxLength={1} type="tel"></Code>
                    <Code disabled={store.loading || accStore.loading} onKeyDown={(e) => onKeyDown(e)} onChange={(e) => onClick(e.target.value)} ref={refArr[3]} maxLength={1} type="tel"></Code>
                </CodeContainer>
            }
            {length === 6 &&
                <CodeContainer style={{width: "150px"}}>
                    <Code autoFocus disabled={store.loading || accStore.loading}onKeyDown={(e) => onKeyDown(e)} onChange={(e) => onClick(e.target.value)} ref={refArr[0]} maxLength={1} type="tel"></Code>
                    <Code disabled={store.loading || accStore.loading} onKeyDown={(e) => onKeyDown(e)} onChange={(e) => onClick(e.target.value)} ref={refArr[1]} maxLength={1} type="tel"></Code>
                    <Code disabled={store.loading || accStore.loading} onKeyDown={(e) => onKeyDown(e)} onChange={(e) => onClick(e.target.value)} ref={refArr[2]} maxLength={1} type="tel"></Code>
                    <Code disabled={store.loading || accStore.loading} onKeyDown={(e) => onKeyDown(e)} onChange={(e) => onClick(e.target.value)} ref={refArr[3]} maxLength={1} type="tel"></Code>
                    <Code disabled={store.loading || accStore.loading} onKeyDown={(e) => onKeyDown(e)} onChange={(e) => onClick(e.target.value)} ref={refArr[4]} maxLength={1} type="tel"></Code>
                    <Code disabled={store.loading || accStore.loading} onKeyDown={(e) => onKeyDown(e)} onChange={(e) => onClick(e.target.value)} ref={refArr[5]} maxLength={1} type="tel"></Code>
                </CodeContainer>
            }
            {!mobile &&
                <ButtonContainer>
                    <Row>
                        <Button onClick={() => onClick("1")}>1</Button>
                        <Button onClick={() => onClick("2")}>2</Button>
                        <Button onClick={() => onClick("3")}>3</Button>
                    </Row>
                    <Row>
                        <Button onClick={() => onClick("4")}>4</Button>
                        <Button onClick={() => onClick("5")}>5</Button>
                        <Button onClick={() => onClick("6")}>6</Button>
                    </Row>
                    <Row>
                        <Button onClick={() => onClick("7")}>7</Button>
                        <Button onClick={() => onClick("8")}>8</Button>
                        <Button onClick={() => onClick("9")}>9</Button>
                    </Row>
                    <Row>
                        <Button onClick={() => reset()}>C</Button>
                        <Button onClick={() => onClick("0")}>0</Button>
                        <Button onClick={() => backClick()}><Arrow style={{marginTop: "12px"}} /></Button>
                    </Row>
                </ButtonContainer>
            }
        </Container>
    )
}

export default PinControl;