import { bindActionCreators } from 'redux';
// eslint-disable-next-line max-len

import { actions } from '../vScript/actions/actions';
import { actions as checkoutActions } from '../vScript/ConfirmRequest/actions/actions';

const clearScriptRequest = dispatch => () => {
  bindActionCreators(actions.clearScriptInfo, dispatch)();
  bindActionCreators(checkoutActions.clearConfirmationState, dispatch)();
  bindActionCreators(checkoutActions.clearRetryState, dispatch)();
};

export { clearScriptRequest };
