import { actionTypes } from '../actions/searchActions';

const INITIAL_STATE = {
  isSearching: false,
  medications: [],
  error: null,
};

const searchMedsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.searchMeds: {
      return {
        ...state,
        isSearching: true,
        error: null,
      };
    }
    case actionTypes.searchMedsSuccess: {
      return {
        isSearching: false,
        medications: action.payload.meds,
        error: null,
      };
    }
    case actionTypes.searchMedsFailure: {
      return {
        ...state,
        isSearching: false,
        error: action.payload.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default searchMedsReducer;
