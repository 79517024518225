import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import debounce from 'lodash/debounce';
import classnames from 'classnames';

import LoadingSpinner from '../../../../components/loadingSpinner/index';
import { actions } from '../../../actions/searchActions';
import { medicationPropType } from '../../../propTypes/index';
import styles from './searchMeds.module.css';

class SearchMeds extends Component {
  static propTypes = {
    add: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    first: PropTypes.bool.isRequired,
    matched: PropTypes.arrayOf(medicationPropType),
    isSearching: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
  };
  constructor(props) {
    super(props);
    this.onChange = debounce(this.onChange.bind(this), 500, { leading: true });
    this.closeList = this.closeList.bind(this);
    this.addInput = this.addInput.bind(this);
  }
  state = {
    open: false,
  };
  onChange() {
    this.props.search(this.input.value, this.props.token);
    this.setState({ open: !!this.input.value });
  }
  closeList() {
    this.setState({ open: false });
  }
  addInput() {
    this.props.add({ Name: this.input.value });
    this.input.value = '';
  }
  addMed = med => () => {
    this.props.add(med);
    this.input.value = '';
  };
  render() {
    const { matched, isSearching, first } = this.props;
    const open = this.state.open && !isSearching;
    return (
      <div className={styles.container}>
        <input
          ref={c => {
            this.input = c;
          }}
          className={classnames(styles.input, { [styles.open]: open })}
          placeholder={first ? 'Type the medication name' : 'Add another item'}
          onChange={this.onChange}
          onBlur={this.closeList}
        />
        <LoadingSpinner
          iconClassName={styles.spinner}
          containerClassName={styles.loading}
          isFetching={isSearching && !!this.input.value}
        />
        {open && (
          <Options
            items={matched}
            addMed={this.addMed}
            addInput={this.addInput}
            value={this.input.value}
          />
        )}
      </div>
    );
  }
}

const Options = ({ items, addMed, addInput, value }) => (
  <div className={styles.list}>
    <div className={styles.cantFind} onMouseDown={addInput}>
      <span>
        Can&#39;t find &#34;
        {value}
        &#34; ?
      </span>
      <span className={styles.add}>+ Add</span>
    </div>
    {items[0] &&
      items.map(med => (
        <div key={med.Id} className={styles.item} onMouseDown={addMed(med)}>
          {med.Name}
        </div>
      ))}
  </div>
);

/* istanbul ignore next */
const mapStateToProps = state => ({
  token: state.loginReducer.token,
  isSearching: state.searchMedsReducer.isSearching,
  matched: state.searchMedsReducer.medications,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  search: bindActionCreators(actions.searchMeds, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchMeds);
