/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Observer, observer } from 'mobx-react-lite';

import UIContainer from '../components/UIContainer/index';
import CardMobile from './components/CardMobile';
import { go } from '../routes';
import { actions } from '../HealthFeed/actions/verifyActions';
import { bindActionCreators } from 'redux';
import { useAccountStore, useIdentityStore } from 'store/mobx/mobx';
import IdentityStatusLabelMobile from './components/IdentityStatusLabelMobile/IdentityStatusLabelMobile.tsx';
import EmptyPic from 'svgs/patientSilhouette.svg';
import VerifyAtPracticeCard from './VerifyAtPracticeCard';
import PracticeCard from './components/PracticeCard';
import { getStatus } from 'Identity/DesktopIdentityPage/components/PracticeSelection';
import { zIndex } from 'styled-system';
import ViewIdentityRequest from './ViewIdentityRequest/ViewIdentityRequest';
import { useComponentDidMount } from 'libs/newHttp';
import CtaButton from 'components/CtaMenuButton';
import PopupNoIdentity from 'components/DesktopComponents/PopupNoIdentity';
import LearnMorePopUp from 'components/LearnMorePopUp/LearnMorePopUp';


const Identity = observer(({...props}) => {
	const store = useAccountStore();
	const identityStore = useIdentityStore();
	const [isPinSetup, setIsPinSetup] = useState(false); 
	const [viewPractice, setViewPractice] = useState({})
	const [state, setState] = useState(0);
	const [showLabel, setShowLabel] = useState(false);
	const [showCard, setShowCard] = useState(true);
	const [popUp, setPopUp] = useState(false);
	const [learnMorePopUp, setLearnMorePopUp] = useState(false);
	const [height, setHeight] = useState("820px")
	let check = false;

	useComponentDidMount(() => {
		store.getIdentityRequestsStatus(() => {});
		
		if (store.profile.MobileVerified && store.profile.EmailVerified) {
			setPopUp(false);
		} else setPopUp(true);

		// store.getProfile(() => {
		// 	if (store.profile.MobileVerified && store.profile.EmailVerified) {
		// 		setPopUp(false);
		// 	} else setPopUp(true);
		// });
	})

	const capitalise = (str) => {
		return str ? str[0].toUpperCase() + str.slice(1) : ""
	}
    return (
		<Observer>
			{() => 
			<UIContainer 
				pic={state == 0 ? store.profile.AvatarUrl ? store.profile.AvatarUrl : EmptyPic : false} 
				goBackFn={() => state === 1 ? setState(0) : go.home()} 
				heading={state === 0 ? capitalise(store.profile.FirstName) + "'s Identity" : "Identity Verification"}
				isFetching={props.isSending || store.loadingRequests || store.loadingAcknowledgement} 
				showCross={state === 0}
				hideArrow={state === 0}
				noAfter={store.profile.AcknowledgeOfAdditionalSecuritySettings || !showCard}
			>
  
			  {state === 0 &&
				  <div style={{height: "100%", overflow: popUp ? "visible" : "scroll" }}>
				  <Observer>
					  {() => 
						  <div>
							  {popUp &&
								<PopupNoIdentity mobile={true} />
							  }
							  {!store.profile.AcknowledgeOfAdditionalSecuritySettings && showCard &&
								  <CardMobile 
									  mobile={store.profile.MobileVerified}
									  email={store.profile.EmailVerified}
									  identityVerified={store.profile.IdentityVerified}
									  onClick={() => setShowCard(false)}
									  learnMoreClick={() => setLearnMorePopUp(true)}
								  />
							  }
							  <div style={{color: "#6C6C6C", textTransform: "uppercase", fontSize: "15px", margin: "1.5rem 1.5rem", marginBottom: "5px", fontWeight: "600"}}>Local Identity</div>
							  <div style={{color: "#6C6C6C", fontSize: "12px", margin: "0rem 1.5rem", width: "277px"}}>
								  You must verify your identity with every medical centre you wish to use.
								  <span style={{color: "#07A3C8"}} onClick={() => setLearnMorePopUp(true)}> Learn more.</span>
							  </div>
							  <div style={{margin: "1rem 0rem"}}>
							  {store.identityRequestsStatusComplete && !store.profile.IdentityVerified && !store.profile.PendingRequest && (showLabel || store.identityRequests.length < 1) &&
								  <IdentityStatusLabelMobile
									  identityVerified={store.profile.IdentityVerified} 
									  patientHasPractices={store.identityRequests.length !== 0} 
									  onClick={() => setState(1)}
								  />
							  }
							  </div>
							  <div style={{marginBottom: "8rem"}}>
								{/*IDENTITY REQUESTS*/}
							  {store.identityRequestsStatusComplete && store.identityRequests.map((item, i) => {
								  let status = getStatus(item);
								  if (status === 1 || status === 4 || status === 5) { 
									  check = true;
								  }
								  if (i === store.identityRequests.length - 1 && !check) {
									  setShowLabel(true);
								  }
								  
								  return <PracticeCard 
											  key={item.PracticeId}
											  practice={item}
											  OrganisationId={item.PracticeId}
											  practiceName={item.OrganisationName}
											  practiceAddress={item.Address}
											  practicePic={item.PictureUrl}
											  phone={item.Phone}
											  item={item}
											  dots={false}
											  practiceOnClick={() => {
												  if (status === 1 || status === 4 || status === 5) {
													  go.identityViewRequest(item.OrganisationId);
													  return;
												  }
												  identityStore.setPractice(item.OrganisationId, item.OrganisationName, item.Address, item.Phone, item.PictureUrl, item.Pictures);
												  if (store.profile.SecurityPinCodeSetup) {
													  go.identityPhoto();
												  } else go.identityPin();
												  //this.props.isMobileVerified ? go.identityPin() : go.identityMobile();
												  //is.props.isPinSetup ? go.identityPhoto() : go.identityPin();
											  }}
										  />
							  })}
							  </div>
							  {store.identityRequests.length !== 0 &&
								  <CtaButton 
									  onClick={() => {/*go.searchPractice*/}}
									  isFetching={store.loading}
									  text={"+ Verify with New Practice"}
									  isUnActive={true}
								  />
							  }
						  </div>
					  }
				  </Observer>
				  </div>
			  }
			  {state === 1 &&
				  <VerifyAtPracticeCard learnMoreClick={() => setLearnMorePopUp(true)}/>
			  }
			{learnMorePopUp &&
				<LearnMorePopUp hide={() => setLearnMorePopUp(false)} type={"IDENTITY_INFO"}/>
			}
			  
		  </ UIContainer>
			}
		</Observer>
        
    );

})

const mapStateToProps = state => ({
  token: state.loginReducer.token,
  profile: state.profileReducer.profile,
  isVerified: state.verifyReducer.verificationMethod !== 0,
})

const mapDispatchToProps = dispatch => ({
  getVerificationStatus: bindActionCreators(actions.getVerificationStatus, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Identity);