import { ENVIRONMENT, IS_DEVELOPMENT_MODE } from 'config';
import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import cache from 'services/cache';
import { replace } from '../utils';

const go = {
  login: () => replace({ pathname: '/login' }),
  loginWithToken: () => {
    let token = cache.get('token');
    const target = IS_DEVELOPMENT_MODE ? '/login' : `https://login${ENVIRONMENT}.vensa.com`;
    window.location.href = `${target}?token=${JSON.parse(token).token}`;
    // replace({pathname: target, search:`?token=${JSON.parse(token).token}`});
  }
};

const LoginRoutes = (
  <Route
    path={'/login'}
    component={lazy(() =>
      import('pages/entry'),
    )}
  />
);

export default LoginRoutes;

export { go };
