import React, { Children, CSSProperties, PropsWithChildren } from 'react';

import Header, { HeaderProps } from './Header';
import styles from './index.module.scss';

type HeaderCardUIProps = HeaderProps & 
{
  style?: React.CSSProperties
  cardStyle?: CSSProperties,
  outsideChildren?: React.ReactNode
};

const HeaderCardUI: React.FC<PropsWithChildren<HeaderCardUIProps>> = (
    {
        text, 
        align, 
        handleClick,
        icon,
        style,
        cardStyle,
        children,
        outsideChildren
    }) => {
  return (
    <div className={styles.container} style={style}>
        <Header 
          text={text} 
          align={align} 
          handleClick={handleClick}
          icon={icon}/>
        <div className={styles.card} style={cardStyle}>
              {children}
        </div>
        
        <div className={styles.outside}>
          {outsideChildren}
        </div>
        
    </div>
  )
};

export default HeaderCardUI;