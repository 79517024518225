import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import debounce from 'lodash/debounce';
import LoadingSpinner from '../../../../components/loadingSpinner/index';
import { actions } from '../../../actions/searchActions';
import { medicationPropType } from '../../../propTypes/index';
import { ReactComponent as Cross } from '../../../../svgs/close.svg';
import styles from './medicationList.module.css';

class MedicationList extends Component {
  static propTypes = {
    isSearching: PropTypes.bool.isRequired,
    matchedMeds: PropTypes.arrayOf(medicationPropType),
    addMedication: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
  };
  constructor(props) {
    super(props);
    this.onChange = debounce(this.onChange.bind(this), 500, { leading: true });
    this.addInput = this.addInput.bind(this);
  }
  componentDidMount() {
    //sets browser back fn to close the list instead of exiting flow
    // remove = this.props.router.setRouteLeaveHook(
    //   last(this.props.router.routes), this.close
    // )
    this.input.focus();
  }
  componentWillUnmount() {
    // remove();
  }
  close = () => {
    this.props.close();
    return false;
  };
  onChange() {
    this.props.search(this.input.value, this.props.token);
  }
  addInput() {
    this.props.addMedication({ Name: this.input.value });
  }
  render() {
    const meds = this.props.matchedMeds;
    const open = this.input && this.input.value && !this.props.isSearching;
    return (
      <div className={styles.container} style={{height: window.innerHeight}}>
        <input
          ref={c => {
            this.input = c;
          }}
          className={styles.input}
          placeholder="Type the medication name"
          onChange={this.onChange}
          onBlur={this.close}
        />
        {this.input && (
          <Cross onMouseDown={this.close} className={styles.cross} />
        )}
        {open && (
          <div className={styles.cantFind} onMouseDown={this.addInput}>
            <span>
              Can&#39;t find &#34;
              {this.input.value}
              &#34; ?
            </span>
            <span className={styles.add}>+ Add</span>
          </div>
        )}
        <LoadingSpinner
          iconClassName={styles.spinner}
          containerClassName={styles.loading}
          isFetching={
            this.props.isSearching && this.input && !!this.input.value
          }
        >
          <div className={styles.list}>
            {meds[0] &&
              open &&
              meds.map(e => (
                <div
                  key={e.Id}
                  className={styles.med}
                  onMouseDown={() => this.props.addMedication(e)}
                >
                  {e.Name}
                </div>
              ))}
          </div>
        </LoadingSpinner>
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => ({
  token: state.loginReducer.token,
  isSearching: state.searchMedsReducer.isSearching,
  matchedMeds: state.searchMedsReducer.medications,
});
/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  search: bindActionCreators(actions.searchMeds, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MedicationList);
// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MedicationList));
