import React from 'react';
import styles from './index.module.scss';

type Props = {
    color: string,
    text: string
}

const StadiumTag = ({color, text}: Props) => {

  return (
    <div className={styles.container} style={{backgroundColor:color}}>
        {text}
    </div>
  )
}

export default StadiumTag;

/*
.container {
    padding: 1.5rem;
    background-color: grey(AF);
    pointer-events: none;
    border: none;
    color: var(--color-white);
    z-index: 18;
    border-radius: 2.5rem;

    &.enable{
        background-color: $primary-color;
        pointer-events: auto;
    }

    & > svg {
        height: 1rem;
        width: 1rem;
        margin: .2rem;
    }
}
*/