const getToken = state => state.loginReducer.token;
const getLoginObject = state => ({
    consumerUrl: state.loginReducer.consumerUrl,
    token: state.loginReducer.token,
    expiryTime: state.loginReducer.expiryTime,
    email: state.loginReducer.email,
    refreshToken: state.loginReducer.refreshToken,
})
const getLoginSuccess = state => state.loginReducer.success;

export { getToken, getLoginObject, getLoginSuccess };
