import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions as scriptActions } from '../../../actions/actions';
import { actions as pickUpActions } from '../../actions/actions';
import { actions as popUpActions } from '../../../../components/DesktopPopUp/actions/actions';
import Radio from '../../../shared/Radio/index';
import CtaButton from '../../../../components/CtaButton/index';
import BasicInputField from '../../../../components/BasicInputField/index';
import { pricingPropType } from '../../../propTypes/index';
import { practicePropType } from '../../../../PracticesPage/propTypes/index';
import { isDesktop } from '../../../../config';
import styles from './addPharmacyForm.module.css';
import inputStyles from 'components/BasicInputField/basicInputField.module.css';
import PharmacySearch from './components/PharmacySearch';

const formatPrice = price => {
  const dollars = price.slice(0, -2);
  const cents = price.slice(-2);
  return `${dollars}.${cents}`;
};

class AddPharmacyForm extends Component {
  static propTypes = {
	addPharmacy: PropTypes.func.isRequired,
	goToConfirm: PropTypes.func.isRequired,
	fetchPickUpOptions: PropTypes.func.isRequired,
	dateOfBirth: PropTypes.string.isRequired,
	token: PropTypes.string.isRequired,
	setInfo: PropTypes.func.isRequired,
	setError: PropTypes.func.isRequired,
	newPharmacyId: PropTypes.string,
	isFetchingPharmId: PropTypes.bool,
	success: PropTypes.bool.isRequired,
	practice: practicePropType.isRequired,
	pickUpOptions: PropTypes.arrayOf(pricingPropType),
  };
  state = {
	name: '',
	address: '',
	fax: '',
	phone: '',
	newId: '',
	nameError: null,
	addressError: null,
	faxError: null,
	phoneError: null,
	selectedUrgency: 0,
	newPharm: {}
  };
  componentDidMount() {
	this.props.fetchPickUpOptions(
	  this.props.dateOfBirth,
	  this.props.practice.Id,
	  this.props.token,
	);
  }
  componentWillReceiveProps(nextProps) {
	if (nextProps.success !== this.props.success) {
	  this.props.setInfo('pricing', {
		Option: this.props.pickUpOptions.length > 1 ? this.props.pickUpOptions[1].Options[this.state.selectedUrgency] : this.props.pickUpOptions[0].Options[this.state.selectedUrgency],
		Name: this.state.name,
		Address: this.state.address,
		Phone: this.state.phone,
		Fax: this.state.fax,
		PharmacyId: nextProps.newPharmacyId,
	  });
	  this.props.goToConfirm();
	}
  }
  setError = (key, message) =>
	this.setState({ [`${key}Error`]: message }, this.fireAlert(message));
  fireAlert = message => () => {
	if (isDesktop()) {
	  this.props.setError(message);
	}
  };
  clearErrors = () => {
	this.setState({
	  nameError: null,
	  addressError: null,
	  faxError: null,
	  phoneError: null,
	});
  };
  handleChange = id => e => {
	this.setState({
	  error: null,
	  [id]: e.target.value,
	});
  };
  handleRadioClick = index => () => {
	this.setState({ selectedUrgency: index });
  };
  activateCta = () => {
	if (!this.isValidPharmacyName()) {
	  return false;
	} else if (!this.isValidLocation()) {
	  return false;
	} else if (this.state.fax && !this.isValidPhone(this.state.fax, false)) {
		return false;
	} else if (this.state.phone && !this.isValidPhone(this.state.phone, true)) {
	  return false;
	}
	return true;
  };
  formComplete = () => {
	if (!this.isValidPharmacyName()) {
	  this.setError('name', 'Invalid Pharmacy Name.');
	} else if (!this.isValidLocation()) {
	  this.setError('address', 'Invalid Location');
	} else if (this.state.phone && !this.isValidPhone(this.state.phone, true)) {
	  this.setError('phone', 'Invalid Phone Number');
	} else if (this.state.fax && !this.isValidPhone(this.state.fax, false)) {
		this.setError('fax', 'Invalid Fax Number');
	} else {
	  this.props.addPharmacy({
		Name: this.state.name,
		Address: this.state.address,
		Phone: this.state.phone,
		Fax: this.state.fax,
		Id: this.state.newId
	  });
	}
  };
  isValidPharmacyName = () => this.state.name;
  isValidLocation = () => this.state.address;
  isValidPhone = (number, allowMobile) =>
	/^(0[9364758]{1}[0-9]{7,9})$/.test(number.replace("(", "").replace(")", "").split(/[- ]/g).join('')) ||
	(allowMobile && isMobilePhone(number.split(/[- ]/g).join(''), 'en-NZ'));

  render() {
	return (
	  <div className={styles.container}>
		<form className={styles.form}>
		  <div className={inputStyles.label} style={{margin: "0.5rem 0rem"}}>Name</div>
		  <PharmacySearch onSelect={(pharm) => this.setState({ newPharm: pharm, address: pharm.Address, name: pharm.Name, phone: pharm.Phone, fax: pharm.Fax, newId: pharm.Id })} />
		  <BasicInputField
			label="Address"
			value={this.state.address}
			onChange={this.handleChange('address')}
			className={styles.field}
			error={!isDesktop() && this.state.addressError}
			clearError={this.clearErrors}
		  />
		  <div className={styles.numbersInput}>
			<BasicInputField
			  label="Phone number (optional)"
			  value={this.state.phone}
			  onChange={this.handleChange('phone')}
			  error={!isDesktop() && this.state.phoneError}
			  clearError={this.clearErrors}
			/>
			<BasicInputField
			  label="Fax number"
			  value={this.state.fax}
			  onChange={this.handleChange('fax')}
			  error={!isDesktop() && this.state.faxError}
			  clearError={this.clearErrors}
			/>
		  </div>
		</form>
		<div className={styles.buttons}>
		  <div className={styles.optionsContainer}>
			{this.props.options && this.props.options.map((o, index) => (
				<div
				  key={o.Option}
				  onClick={this.handleRadioClick(index)}
				  className={styles.option}
				>
				  <Radio
					className={styles.radio}
					selected={this.state.selectedUrgency === index}
				  />
				  <div className={styles.priceTime}>
					{`${o.Note}`}
					<br />
					{`$${formatPrice(o.Price.toString())}`}
				  </div>
				</div>
			  ))}
		  </div>
		  <div>
			<CtaButton
			  active={this.activateCta()}
			  className={styles.cta}
			  onClick={this.formComplete}
			  isProcessing={this.props.isFetchingPharmId}
			/>
		  </div>
		</div>
	  </div>
	);
  }
}

const mapStateToProps = state => ({
  token: state.loginReducer.token,
  dateOfBirth: state.scriptDetailsReducer.patient.DateOfBirth,
  practice: state.scriptDetailsReducer.practice,
  pickUpOptions: state.pickUpOptionsReducer.options,
  newPharmacyId: state.addPharmacyReducer.Id,
  success: state.addPharmacyReducer.success,
  isFetchingPharmId: state.addPharmacyReducer.isFetching,
});
const mapDispatchToProps = dispatch => ({
  setInfo: bindActionCreators(scriptActions.setInfo, dispatch),
  fetchPickUpOptions: bindActionCreators(
	pickUpActions.fetchPickUpOptions,
	dispatch,
  ),
  setError: bindActionCreators(popUpActions.setError, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddPharmacyForm);
export { styles };
