import React, { useState } from 'react';
import styled from 'styled-components';

import UIContainer from 'components/UIContainer';
import RouteBar from 'components/DesktopComponents/RouteBar';
import TabBar from 'components/DesktopComponents/TabBar';
import proImage from 'images/progresslvl3.png';
import { ReactComponent as Back } from 'svgs/circleRightArrow.svg';
import { ReactComponent as BackWhite } from 'svgs/whiteRoundLeftLineArrow.svg'
import { ReactComponent as Privacy } from 'svgs/privacy.svg';
import over from 'images/faceOverlay.png';
import { useIdentityStore } from 'store/mobx/mobx';
import Camera from '../components/Camera';
import { go } from 'routes';
import { observer } from 'mobx-react-lite';

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #F9F9F9;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
`
const Container = styled.div`
    width: 533px;
    margin 1rem auto;
`
const Title = styled.div`
    text-transform: uppercase;
    font-size: 13px;
    margin-top: 1rem;
    text-align: left;
    margin-bottom: 1rem;
`
const Inner = styled.div`
    height: 630px;
    width: 534px;
    background-color: white;
    margin: auto;
    margin-left: 7px;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 1px 1px 3px lightgray;
    padding: 0px 96px;
`
const Heading = styled.div`
    font-size: 17px;
    color: #07A3C8;
    font-weight: bold;
    width: 100%;
    margin: 1rem auto;
    text-align: center;
`
const Text = styled.div`
    font-size: 17px;
    color: #07A3C8;
    width: 100%;
    text-align: center;
    margin: 1rem auto;
`
const Button = styled.div`
    background-color: #07A3C8;
    color: white;
    border-radius: 4px;
    width: 218px;
    height: 50px;
    margin: 3rem auto;
    font-size: 16px;
    text-align: center;
    padding: 1rem 0rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
`
const WhiteButton = styled.div`
    background-color: white;
    color: #07A3C8;
    border: 1px solid #07A3C8;
    border-radius: 4px;
    width: 218px;
    height: 50px;
    margin: auto;
    font-size: 16px;
    text-align: center;
    padding: 1rem 0rem;
    margin-top: 0.5rem;
    cursor: pointer;
    font-weight: 600;
`
const Image = styled.img`
    width: 400px;
    height: 300px;
    margin: 0px auto;
`
const FlexH = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0px 4rem;
`
const Checkbox = styled.input`
    margin: auto;
    width: 20%;
    cursor: pointer;
`
const Overlay = styled.img`
    width: 400px;
    height: 300px;
    position: absolute;
`
const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 400px;
    margin: 0px auto;
`

const PhotoSetup: React.FC = observer(() => {
    const [state, setState] = useState(0);
    const store = useIdentityStore();
    
    return (
        <UIContainer isFetching={store.loading}>
            <Outer>
                <RouteBar 
                    lowLevel={"Identity"}
                    lowLink={() => go.identity()}
                />
                <TabBar type={0} id={1} />
                <Container>
                    <Title>Identity Status</Title>
                    <img src={proImage} alt="progress" />
                    {state === 0 &&
                        <Inner>
                            <Back onClick={() => go.identity()} style={{
                                transform: "rotate(180deg)",
                                cursor: "pointer",
                                position: "absolute",
                                top: "30px",
                                left: "30px",
                                height: "48px",
                                width: "48px",
                            }}/>
                            <Privacy style={{margin: "2rem auto", marginBottom: "2rem", width: "65px", height: "77px"}} />
                            <Heading>Take a selfie from your PC/Tablet</Heading>
                            <Text>This picture will be used by the practice team to help with your verification.</Text>
                            <Button onClick={() => setState(1)}>Take a selfie picture</Button>
                            <WhiteButton onClick={() => go.verifyPrivacy()}>Skip this step</WhiteButton>
                        </Inner>
                    }
                    {state === 1 && 
                        <Inner style={{padding: "0px", height: "400px"}}>
                            <BackWhite onClick={() => setState(0)} style={{
                                cursor: "pointer",
                                position: "absolute",
                                top: "30px",
                                left: "30px",
                                height: "48px",
                                zIndex: 10,
                                width: "48px",
                            }}/>
                            <Camera onError={() => setState(3)} onClick={() => setState(2)} />
                        </Inner>
                    }
                    {state === 2 &&
                        <Inner style={{padding: "0px", height: "826px"}}>
                                <Back onClick={() => setState(1)} style={{
                                    transform: "rotate(180deg)",
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: "30px",
                                    left: "30px",
                                    height: "48px",
                                    width: "48px",
                                }}/>
                                <Privacy style={{margin: "2rem auto", marginBottom: "1rem", width: "65px", height: "77px"}} />
                                <Text style={{width: "430px"}}><b>Make sure your picture shows your face. This picture will be shared with your health provider.</b></Text>
                                <ImageContainer>
                                    <Overlay style={{position: "absolute"}} src={over} alt="overlay" />
                                    <Image src={store.photo!} alt="img"/>
                                </ImageContainer>
                                <FlexH>
                                    <Checkbox defaultChecked={store.makeProfilePhoto} type={"checkbox"} onChange={() => store.toggleProfilePhoto()}/>
                                    <Text style={{fontSize: "16px", textAlign: "left"}}>Make this picture my Vensa profile photo</Text>
                                </FlexH>
                                <Button style={{marginTop: "1rem"}} onClick={() => store.uploadPhoto(() => go.verifyPrivacy())}>My picture is clear</Button>
                                <WhiteButton onClick={() => setState(1)}>Take a new selfie</WhiteButton>
                        </Inner>
                    }
                    {state === 3 &&
                        <Inner style={{padding: "0px 68px"}}>
                            <Back onClick={() => setState(0)} style={{
                                transform: "rotate(180deg)",
                                cursor: "pointer",
                                position: "absolute",
                                top: "30px",
                                left: "30px",
                                height: "48px",
                                width: "48px",
                            }}/>
                            <Privacy style={{margin: "2rem auto", marginBottom: "2rem", width: "65px", height: "77px"}} />
                            <Heading>Take a selfie from your PC/Tablet</Heading>
                            <Text>
                                You have selected the "don't allow" option to allow Vensa will access your camera. You can skip this step or choose to take a photo of yourself again:
                            </Text>
                            <Button onClick={() => setState(1)}>Take a selfie picture</Button>
                            <WhiteButton onClick={() => go.verifyPrivacy()}>Skip this step</WhiteButton>
                        </Inner>
                    }
                </Container>
            </Outer>
        </UIContainer>
    )
})

export default PhotoSetup;