import Alert from 'libs/Alert';
import React, { useState } from 'react';
import { useAccountStore, useMyHealthStore } from 'store/mobx/mobx';
import styled from 'styled-components';

//import Card from '../components/Card';
import { ReactComponent as BlueCross } from 'svgs/blueCircleButton.svg';

const Container = styled.div`
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    background-color: white;
    border-radius: 4px;
    padding: 0.8rem;
`
const Title = styled.div`
    font-size: 21px;
    font-weight: medium;
    color: #07A3C8;
    text-align: center;
    margin: 1rem;
`
const Text = styled.div`
    font-size: 17px;
    color: #585858;
    width: 100%;
    text-align: left;
`
const Link = styled.div`
    font-size: 17px;
    color: #07A3C8;
    text-align: left;
    width: 100%;
    margin: 0.1rem 0rem;
`

interface Props {
    hide: (e: any) => void;
}
const InvolvedInStudy: React.FC<Props> = ({hide}) => {
    return (
        <Container>
            <Title>Who's involved in the study</Title>
            <Text>If you have any questions, concerns, or complaints about the study at any state, you can contact:</Text>
            <br />
            <Text>Samuel Wong, Principal co-investigator</Text>
            <Link onClick={() => window.location.href="tel:0800228946"}>0800 228 946</Link>
            <Link onClick={() => window.location.href="mailto:feedback@vensa.com"}>feedback@vensa.com</Link>
            <br />
            <Text>
                If you feel you need to raise any clinical concerns or Māori health support with the study's clinical research co-investigator, 
                Dr Tane Taylor, please contact Samuel Wong to arrange a discussion.
            </Text>
            <div style={{position: "relative", marginTop: "2rem"}}>
                <BlueCross style={{display: "block", margin: "auto"}} onClick={hide} />
            </div>
        </Container>
    )
}

export default InvolvedInStudy;