import { generalRequest } from "libs/newHttp";
import { action, makeAutoObservable, observable } from "mobx";
import { getDependantsAPI } from "./dependantsStore.api";
import { IDependant } from "./dependantsStore.type";
import alert from "libs/Alert";

export class DependantsStore {
    constructor(){
        makeAutoObservable(this);
    }
    @observable loading: boolean = false;
    @observable dependants: IDependant[] = [];

    @action.bound
    async getDependants() {
        this.loading = true;
        await generalRequest.bind(this)({
            api: getDependantsAPI,
            afterLoaded: (data: any) => {
                this.loading = false;
                this.dependants = data;
            }, afterFailed: (err: any) => {
                this.loading = false;
                alert.error(err.response.data.Message);
            }
        })
    }
}