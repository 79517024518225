import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Arrow } from 'svgs/roundArrow.svg';

const Container = styled.div`
    width: 100%;
    height: 96px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    background-color: white;
    box-shadow: 3px 2px 5px lightgray;
    margin: 1rem 0rem;
    cursor: pointer;
`
const Image = styled.img`
    width: 50px;
    height: 50px;
    margin: auto 1.5rem;
    border-radius: 50%;
`
const FlexV = styled.div`
    display: flex;
    flex-direction: column;
    width: 63%;
    margin: auto;
`
const Name = styled.div`
    font-size: 15px;
    color: #07A3C8;
    text-align: left;
`
const Address = styled.div`
    font-size: 13px;
    text-align: left;
`
const Banner = styled.div`
background-color: #FF6A65;
    color: white;
    padding: 5px;
    font-size: 11px;
    width: 174px;
    border-radius: 20px;
    margin-bottom: 10px;
`

interface Props {
    practice: {
        OrganisationName: string,
        Address: string,
        PictureUrl: string,
        BlackListed: boolean,
    }
    buttonClick: () => void;
}

const PracticeCard: React.FC<Props> = ({practice, buttonClick}) => {
    return (
        <Container style={{borderTop: practice.BlackListed ? "4px solid #FF6A65" : "none"}} onClick={buttonClick}>
            <Image src={practice.PictureUrl} />
            <FlexV>
                {practice.BlackListed && <Banner>Restricted Access set by Practice</Banner>}
                <Name>{practice.OrganisationName}</Name>
                <Address>{practice.Address}</Address>
            </FlexV>
            <Arrow style={{margin: "auto 1.5rem", cursor: "pointer"}} />
        </Container>
    )
}

export default PracticeCard;