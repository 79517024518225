import { isDesktop } from 'config';
import React from 'react';
import RegisterStep from './RegisterStep';
import facade from './hocs';
import { useDependant } from './hooks';
import { normalizeDate } from 'services/Format/formatDate';
import DeclareStep from './DeclareStep';
import RedirectStep from './RedirectStep';
import { go } from 'routes/sub/dependants';
import { base64FromUri } from 'services/convertImage';

const Editing = () => {
    
    const touchFlag = !isDesktop();
    // Deliver dependant info from redux to mobx
    const dependant = useDependant();
    if(!dependant){
        go.dependants();
    }
    const values = {
        id: dependant?.Id,
        firstName: dependant?.FirstName,
        lastName: dependant?.LastName,
        dateOfBirth: normalizeDate(dependant?.DateOfBirth),
        relationship: dependant?.Relationship,
        avatar: dependant?.AvatarUrl,
        avatarBase64: ''
    };
    const FacadePage = facade(touchFlag, `${values.firstName} ${values.lastName}`, values);
    
    return (
        <FacadePage dependant={dependant}>
            <RegisterStep touch={touchFlag} />
            <DeclareStep touch={touchFlag} />
            <RedirectStep touch={touchFlag} />
        </FacadePage>
    )
}

export default Editing;