import React from 'react';
import PropTypes from 'prop-types';

import AltImage from '../../../../../AltImage/index';
import mobileIcon from '../../../../../../images/miniMobileWhite.png';
import { userPropType } from '../../propTypes/index';
import styles from './userInfo.module.css';
import { capitalise } from 'libs/misc';

const formatMobile = (mobile = '') =>
  mobile && mobile.replace(/(\d{3})(\d{3})(\d)/, '$1 $2 $3');

const UserInfo = ({ user, onClick }) => (
  <div className={styles.container}>
    <div className={styles.innerContainer} onClick={onClick}>
      {user.AvatarUrl ? (
        <div style={{borderRadius: "50%", backgroundImage: `url(${user.AvatarUrl})`, backgroundSize: "cover", width: "5.88rem", height: "5.88rem"}} />
      ) : (
        <AltImage className={styles.altImg} />
      )}
      <div className={styles.name}>
        {user.FirstName ? `${capitalise(user.FirstName)} ${capitalise(user.LastName)}` : ''}
      </div>
      <div className={styles.mobile}>
        <img src={mobileIcon} className={styles.mobileIcon} alt="icon" />
        <span>{formatMobile(user.Mobile)}</span>
      </div>
      {user.NHI &&
        <div className={styles.nhi}>
          <span>NHI: {user.NHI}</span>
        </div>
      }
    </div>
  </div>
);

UserInfo.propTypes = {
  onClick: PropTypes.func.isRequired,
  user: userPropType.isRequired,
};

export default UserInfo;
