import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import classnames from 'classnames';
import { actions as verifyActions } from '../actions/verifyActions';

import { CSSTransitionGroup } from 'react-transition-group';

import { ReactComponent as PlusIcon } from '../../svgs/plus.svg';
import CtaMenuButton from '../../components/CtaMenuButton';
import EmptyFeedCard from './EmptyFeedCard';
import { actions } from '../../AppointmentsPage/actions/actions';
import { appointmentPropType } from '../../AppointmentsPage/propTypes';
import { scriptPropType } from '../../PrescriptionsPage/propTypes';
import { go } from '../../routes';
import MenuContainer from './MenuPages';
import FeedList from './FeedList';
import AccountVerificationCard from './VerifyAccountCard';
import styles from './healthFeedMobile.module.css';
import { actions as feedActions } from '../actions/actions';
import { actions as notificationActions } from 'components/UIContainer/MobileUI/MainShell/actions/actions';
import Notifications from '../HealthFeedDesktop/Notifications/Notifications';
import { ReactComponent as labsIcon } from 'svgs/labs.svg'
import LearnMorePopUp from 'components/LearnMorePopUp/LearnMorePopUp';
import AddToHomeScreenCard from './AddToHomeScreenCard';
import AlterablePopup from 'components/shared/common/AlterablePopup';
import { Activity2PopupsSet } from '../PopupData';
import store from 'store/identity';
import LoadingSpinner from 'components/shared/common/LoadingSpinner';


class HealthFeedMobile extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    changeCtaIcon: PropTypes.func.isRequired,
    changeCtaFn: PropTypes.func.isRequired,
    clearCta: PropTypes.func.isRequired,
    goToAccountVerification: PropTypes.func.isRequired,
    name: PropTypes.string,
    ctaFn: PropTypes.func.isRequired,
    showCta: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isFirstTime: PropTypes.bool.isRequired,
    getVerificationStatus: PropTypes.func.isRequired,
    feed: PropTypes.arrayOf(
      PropTypes.shape({
        Id: PropTypes.string.isRequired,
        Type: PropTypes.string.isRequired,
        Item: PropTypes.oneOfType([appointmentPropType, scriptPropType])
          .isRequired,
      }),
    ),
    isMobileVerified: PropTypes.bool,
  };
  state = {
    showMenu: false,
    cardIsOpen: false,
    ctaText: 'Add to my Calendar',
    showNotification: true,
    showPopUp: false,
    clickClose: false,
    showWaiting: false
  };
  componentDidMount() {
    this.props.clearCta();
    this.props.changeCtaFn(this.toggleMenu);
    //this.props.setNotification({ isOpen: true, index: 0})
    //this.props.getVerificationStatus(this.props.token);

  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state) return true;
    if (nextProps.feed !== this.props.feed) return true;
    if (
      nextProps.showCta !== this.props.showCta ||
      nextProps.ctaFn !== this.props.ctaFn
    )
      return true;
    if (
      nextProps.isFirstTime !== this.props.isFirstTime ||
      nextProps.isMobileVerified !== this.props.isMobileVerified ||
      nextProps.IdentitySetupPopup !== this.props.IdentitySetupPopup
    )
    return true;
    
    return false;
  }
  setCtaText = ctaText => this.setState({ ctaText });
  getCtaStyles = () => {
    if (this.state.cardIsOpen) {
      return {
        iconStyles: styles.ctaIconCard,
        buttonStyles: styles.ctaButtonCard,
        text: this.state.ctaText,
      };
    }
    return {
      buttonStyles: styles.ctaButton,
      iconStyles: styles.buttonIcon,
    };
  };
  toggleOpenCardState = () => {
    this.setState({ cardIsOpen: !this.state.cardIsOpen }, this.changeCta);
  };
  changeCta = () => {
    if (!this.state.cardIsOpen) {
      this.props.changeCtaFn(this.toggleMenu);
      this.props.changeCtaIcon(PlusIcon);
    }
  };
  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };
  handleNoFeedClick = () => {
    if (this.cta) {
      this.cta.handleClick();
    }
  };
  
  handleSure = () => {
    this.setState({showWaiting: true});
    store.skipPinCode(this.props.profile.LatestPracticeId);
  }

  handleSame = () => {
    this.setState({showWaiting: true});
    store.employPinCode();
  }

  render() {
    const onboardingModel = Activity2PopupsSet({...this.props.profile, handleSure: this.handleSure, handleSame: this.handleSame}, true)[this.props.profile.IdentitySetupPopup];
    return (
      <>
        {
          this.state.showWaiting && <LoadingSpinner animateStyle={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}/>
        }
        {
          !this.state.showWaiting && onboardingModel && <AlterablePopup
            touch
            modelData={onboardingModel}
          />
        }
        <div id="nw_healthFeed" className={styles.outer} style={{overflow: this.state.showPopUp || this.state.cardIsOpen || this.state.showMenu ? "visible" : "scroll"}}>
          {this.props.name && !this.props.isFetching && (
            <div
              style={{overflow: this.state.showPopUp || this.props.showCta || this.state.cardIsOpen ? "visible" : "scroll"}}
              className={classnames(styles.container)}
            >
              
              {this.state.showNotification &&
                <Notifications feed={this.props.feed} setShowPopUp={() => this.setState({showPopUp: true})} getFeed={() => this.props.getFeed(this.props.token)} hide={() => this.setState({showNotification: false})}/>
              }

              {this.props.isMobileVerified && (this.props.feed.length || !this.state.showNotification) 
              && !window.localStorage.getItem('add-to-home-screen') && (
                <AddToHomeScreenCard
                  setShowPopUp={() => this.setState({showPopUp: true})}
                  title={"Hey " + this.props.name + ", You are good to go!"}
                  text="We suggest you take a tour to explore what you can do and add to the home screen!"
                  closeHook={() => this.setState({clickClose: true})}
                /> 
              )}
              <FeedList
                feed={this.props.feed}
                setCtaText={this.setCtaText}
                toggleOpenCardState={this.toggleOpenCardState}
              />
            </div>
          )}
          {this.props.showCta && (
            <CtaMenuButton
              ref={c => {
                this.cta = c;
              }}
              {...this.getCtaStyles()}
              onClick={this.props.ctaFn}
              icon={this.props.ctaIcon}
            />
          )}
          <CSSTransitionGroup
            component="div"
            transitionName={{
              leave: styles.leave,
              leaveActive: styles.leaveActive,
              enter: styles.enter,
              enterActive: styles.enterActive,
            }}
            transitionLeaveTimeout={300}
            transitionEnterTimeout={300}
          >
            {this.state.showMenu && (
              <MenuContainer
                key="MenuContainer"
                mobileVerified={this.props.isMobileVerified}
              />
            )}
          </CSSTransitionGroup>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  name: state.profileReducer.profile.FirstName,
  profile: state.profileReducer.profile,
  token: state.loginReducer.token,
  isFirstTime: state.fetchPastAppointmentsReducer.appointments.length < 1,
  ctaFn: state.appointmentsCTAReducer.ctaFn,
  ctaIcon: state.appointmentsCTAReducer.ctaIcon,
  showCta: state.appointmentsCTAReducer.showCta,
  feed: state.feedReducer.feed,
  isFetching: state.feedReducer.isFetching || state.scriptReducer.isRemoving,
  isMobileVerified: state.profileReducer.profile.MobileVerified,
  isEmailVerified: state.profileReducer.profile.EmailVerified,
  verifyMethod: state.verifyReducer.verificationMethod,
  verifyStatus: state.verifyReducer.verificationStatus,
  feedSuccess: state.feedReducer.success
});
const mapDispatchToProps = dispatch => ({
  getVerificationStatus: bindActionCreators(verifyActions.getVerificationStatus, dispatch),
  changeCtaFn: bindActionCreators(actions.changeCtaFn, dispatch),
  changeCtaIcon: bindActionCreators(actions.changeCtaIcon, dispatch),
  clearCta: bindActionCreators(actions.clearCta, dispatch),
  /* eslint-disable max-len */
  goToAccountVerification: () => go.accountVerification("true"),
  getFeed: bindActionCreators(feedActions.getFeed, dispatch),
  setNotification: bindActionCreators(notificationActions.setWhiteBoxNotification, dispatch)
  /* eslint-enable max-len */
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HealthFeedMobile);
export { styles };
