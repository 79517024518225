const actionTypes = {
  acceptPrivacyPolicy: 'ACCEPT_PRIVACY_POLICY',
  acceptPrivacyPolicySuccess: 'ACCEPT_PRIVACY_POLICY_SUCCESS',
  acceptPrivacyPolicyFailure: 'ACCEPT_PRIVACY_POLICY_FAILURE',
};

const actions = {
  acceptPrivacyPolicy: token => ({
    type: actionTypes.acceptPrivacyPolicy,
    payload: { token },
  }),
  acceptPrivacyPolicySuccess: () => ({
    type: actionTypes.acceptPrivacyPolicySuccess,
  }),
  acceptPrivacyPolicyFailure: error => ({
    type: actionTypes.acceptPrivacyPolicyFailure,
    payload: { error },
  }),
};

export { actions, actionTypes };
