import React from 'react';
import { IAllPharmacies, IPharmacy } from 'store/mobx/vScriptStore/vScriptStore.type';
import styled from 'styled-components';
import { PharmacyListCard } from 'vScript/ModalContent/PharmacyContent';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 320px;
    overflow-y: auto;
`
const Item = styled.div`
    cursor: pointer;
    background-color: white;
`
interface IProps {
    onClick: (pharm: any) => void,
    list: any
}

const PharmacyListView: React.FC<IProps> = ({ onClick, list }) => {
    return (
        <Container>
            {list.map((Pharmacy: IPharmacy, i: number) => {
                return (
                    <Item onClick={() => onClick(Pharmacy)}>
                        <PharmacyListCard 
                                    first={i === 0}
                                    key={Pharmacy.Id}
                                    name={Pharmacy.Name} 
                                    photoUrl={Pharmacy.PhotoUrl} 
                                    address={Pharmacy.Address} 
                                    phone={Pharmacy.Phone} 
                                    fax={Pharmacy.Fax}
                                    handleClick={() => {}}
                                />
                    </Item>
                )
            })}
        </Container>
    )
}

export default PharmacyListView;