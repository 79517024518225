import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Textarea from "react-textarea-autosize";
import styled from 'styled-components';
import { actions } from '../actions/actions';
import { actions as headerActions } from '../../components/UIContainer/actions/headerActions';
import CtaButton from '../../components/CtaButton';
import UIContainer from '../../components/UIContainer';
import { go } from '../../routes';
import styles from './medications.module.css';
import NoPastMedications from './ErrorCases/NoPastMedications';
import NoteHeader from './components/NoteHeader/NoteHeader';
import MedicationList from './components/MedicationList/MedicationList';
import { actions as notificationActions } from 'components/UIContainer/MobileUI/MainShell/actions/actions';
import LearnMorePopUp from 'components/LearnMorePopUp/LearnMorePopUp';

const Card = styled.div`
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  padding: 0.8rem;
  padding-top: 1rem;
  position: relative;
  height: 100%;
`
const Text = styled.div`
  color: #6C6C6C;
  font-size: 17px;
  text-align: left;
  width: 100%;
  margin-bottom: 0.5rem;
`

interface MedicationsProps {
  location: any;
  getMedications(orgId: string, patientId: string, token: string, id: any);
  patientName: string;
  setNotification(arg0: { isOpen: boolean; });
  setInfo: Function,
  checkAbleToRequest: Function,
  overrideBackArrow: Function,
  clearBackArrow: Function,
  goBack: Function,
  medications: any,
  token: string,
  userId: string,
  patientId: string,
  orgId: string,
  isChecking: boolean,
  practiceName: string,
  doctorName: string,
  onNoMedications: Function,
  note: string
}

const Medications: React.FC<MedicationsProps> = (props) => {
  const [note, setNote] = useState(props.note || '')
  const [noMedications, setNoMedications] = useState(false)
  const [medicationContent, setMedicationContent] = useState({})
  const [showPopUp, setShowPopUp] = useState(false)
  const [selectionChanged, setSelectionChanged] = useState(false)
  const [selectedMeds, setSelectedMeds] = useState([])

  useEffect(() => {
    props.overrideBackArrow(props.goBack);
    props.setNotification({ isOpen: true })
    if (!props.medications) {
      props.getMedications(props.orgId, props.patientId, props.token, props.location.state.id);
    }
    else {
      if (props.medications.LongTerm.length + props.medications.Other.length === 0) {
        setNoMedications(true)
      }
      else {
        setSelectedMeds(props.medications.LongTerm)
      }
    } 
    return () => { 
      props.clearBackArrow();
      props.setNotification({ isOpen: false });
    }
  }, [props, props.medications]);

  const submitSelection = () => {
    if (note.length) props.setInfo('reason', note);
    props.setNotification({ isOpen: false })
    
    let meds = selectionChanged ? selectedMeds : props.medications.LongTerm;
    if (meds.length) {
      props.setInfo('medications', meds);
      go.pickUpLocation();
    }
  };
  const openMedicationInfo = (name: any, data: any) => {
    setShowPopUp(true)
    setMedicationContent({ name, data })
  }
  const changeSelectedMeds = meds => {
    setSelectedMeds(meds)
    setSelectionChanged(true)
  }
  const getMedsCount = medications => {
    if (medications === null || medications === undefined) return 0;
    const longTerm = (medications.LongTerm === null) ? 0 : medications.LongTerm.length;
    const other = (medications.Other === null) ? 0 : medications.Other.length;
    return longTerm + other;
  }

  return (
    noMedications ?
      <UIContainer
        goBackFn={() => go.selectPatient()}
        isFetching={props.isChecking}
        heading={"No past medications"}
        blueSpinner
      >
        <div style={{ height: "100%" }}>
          <NoPastMedications />
        </div>
      </UIContainer>
      :
      <UIContainer
        goBackFn={() => go.selectPatient()}
        isFetching={props.isChecking}
        heading={"1. Prescription details"}
        blueSpinner
        showCrossRight
      >
        <div>
          {showPopUp &&
            <LearnMorePopUp type={"MEDICATION_INFO"} params={medicationContent} hide={() => setShowPopUp(false)} />
          }
          <Card style={{ maxHeight: "2600px", height: "auto" }}>
            <NoteHeader
              name={props.patientName}
              practice={props.practiceName}
              provider={props.doctorName}
            />
            {props.medications !== null && props.medications.LongTerm !== null && props.medications.LongTerm.length > 0 &&
            <div>
              <Text style={{ fontWeight: "bold" }}>
                Your long term medications are pre-selected:
              </Text>
                <MedicationList
                  selected={selectionChanged ? selectedMeds : [...props.medications.LongTerm]}
                  setMeds={changeSelectedMeds}
                  medications={props.medications.LongTerm}
                  openMedicationInfo={openMedicationInfo}
                  onNoMedications={props.onNoMedications}
                  medsCount={getMedsCount(props.medications)}
                />
            </div>
            }
            {props.medications !== null && props.medications.Other !== null && props.medications.Other.length > 0 &&
            <div>
              <Text style={{ fontWeight: "bold", marginTop: "1.5rem" }}>
                Select other medications you have been prescribed (if you require):
              </Text>
              <MedicationList
                selected={selectionChanged ? selectedMeds : [...props.medications.LongTerm]}
                setMeds={changeSelectedMeds}
                medications={props.medications.Other}
                openMedicationInfo={openMedicationInfo}
                onNoMedications={props.onNoMedications}
                medsCount={getMedsCount(props.medications)}
              />
              <div>
                <Text style={{ fontSize: "15px", marginTop: "1.5rem" }}>
                  Listed above are the medicines your doctor prescribed for you in the last 2 years.
                </Text>
              </div>
            </div>
            }
            <Textarea
              placeholder="Write a note for the medical team if you have any questions, or if you would like to request anything not listed above."
              value={note}
              onChange={(e) => setNote(e.target.value)}
              className={styles.textarea}
              maxLength={120}
            />
            {props.medications !== null &&
              <CtaButton
                onClick={() => submitSelection()}
                active={selectionChanged ? selectedMeds.length > 0 : [...props.medications.LongTerm].length > 0}
                className={styles.cta}
              />
            }
          </Card>
        </div>
      </UIContainer>
  );
}

/* istanbul ignore next*/
const mapStateToProps = state => ({
  token: state.loginReducer.token,
  // eslint-disable-next-line max-len
  patientName: `${state.scriptDetailsReducer.patient.FirstName} ${state.scriptDetailsReducer.patient.LastName
    }`,
  practiceName: state.scriptDetailsReducer.practice.Name,
  doctorName: state.scriptDetailsReducer.doctor.Name,
  note: state.scriptDetailsReducer.reason,
  patientId: state.scriptDetailsReducer.patient.Id,
  orgId: state.scriptDetailsReducer.practice.Id,
  userId: state.profileReducer.profile.Id,
  isChecking: state.ableToRequestReducer.isChecking || state.getMedicationsReducer.isFetching,
  medications: state.getMedicationsReducer.medications,
  details: state.scriptDetailsReducer,
});
/* istanbul ignore next*/
const mapDispatchToProps = dispatch => ({
  overrideBackArrow: bindActionCreators(
    headerActions.overrideBackArrow,
    dispatch,
  ),
  clearBackArrow: bindActionCreators(headerActions.clearBackArrow, dispatch),
  goBack: () => go.home(),
  setInfo: bindActionCreators(actions.setInfo, dispatch),
  getMedications: bindActionCreators(actions.getMedicationList, dispatch),
  checkAbleToRequest: bindActionCreators(actions.ableToRequest, dispatch),
  setNotification: bindActionCreators(notificationActions.setWhiteBoxNotification, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Medications);
export { styles };
