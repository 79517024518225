const actionTypes = {
    getCards: "GET_CARDS",
    getCardsSuccess: "GET_CARDS_SUCCESS",
    getCardsFailure: "GET_CARDS_FAILURE",
  };

  const getCards = (sessionId) => ({
    type: actionTypes.getCards,
    payload: { sessionId }
  });
  const getCardsSuccess = cards => ({
    type: actionTypes.getCardsSuccess,
    payload: { cards }
  });
  const getCardsFailure = () => ({
    type: actionTypes.getCardsFailure,
    error: true,
  });

  const actions = {
    getCards,
    getCardsSuccess,
    getCardsFailure,
  };

  export { actions, actionTypes }