const actionTypes = {
  setError: 'DESKTOP_POP_UP_SET_ERROR',
};

const actions = {
  setError: errorText => ({
    type: actionTypes.setError,
    payload: {
      errorText,
    },
  }),
};

export { actionTypes, actions };
