import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Text, Image } from 'components/vensaComponents';
import worriedKiwi from 'images/wavingKiwi.png';

export const NotificationBox: React.FC<{
  title: string;
  content: string;
  controlArea?: React.ReactNode;
}> = observer(({ title, content, controlArea }) => {
  return (
    <Box
      direction="column"
      bg="white"
      boxShadow={6}
      borderRadius="4px"
      p={4}
      alignItems="center"
    >
      <Image src={worriedKiwi} height="100px" />
      <Text color="grey5" my={3} fontWeight={600} fontSize="17px">
        {title}
      </Text>
      <Text color="grey5" mb={5} textAlign={"center"} fontWeight={300} fontSize="17px">
        {content}
      </Text>
      {controlArea && controlArea}
    </Box>
  );
});
