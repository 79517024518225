import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import UIContainer from 'components/UIContainer';
import { useAccountStore } from 'store/mobx/mobx';
import { useComponentDidMount } from 'libs/newHttp';
import Alert from 'libs/Alert';
import logo from 'images/greyLogo.png';
import { ReactComponent as SpeechBubble } from 'svgs/speechBubble.svg';
import { go } from 'routes';
import kiwi from 'images/worriedKiwi.png';
import Loading from 'components/loadingSpinner';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: linear-gradient(#07A3C8 40%, #F5F5F5 40%) no-repeat;
    padding-bottom: 5rem;
    overflow: scroll;
    position: absolute;
`
const Card = styled.div`
    background-color: white;
    margin: auto 1rem;
    border-radius: 4px;
    padding: 0.5rem;
    box-shadow: 0px 3px 6px darkgray;
`
const Image = styled.div`
    display: block;
    margin: 0rem auto;
    width: 75px;
    height: 75px;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    transform: translateY(38%);
    border-radius: 4px;
    box-shadow: 0px 3px 6px darkgray;
    background-color: white;
    background-size: contain;
`
const Title = styled.div`
    font-size: 19px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    color: #404040;
    margin-top: 2.5rem;
`
const Address = styled.div`
    font-weight: 300;
    font-size: 15px;
    width: 100%;
    text-align: center;
    color: #404040;
    padding: 0rem 1rem;
`
const Text = styled.div`
    font-size: 17px;
    color: #6C6C6C;
    text-align: left;
    margin: 1rem 2.5rem;
`
const Line = styled.div`
    border-bottom: 1px solid #F0F0F0;
    margin: 1rem;
`
const TextBig = styled.div`
    font-size: 21px;
    color: #6C6C6C;
    text-align: center;
    margin: 1rem 1rem;
`
const BlueButton = styled.div`
    background-color: #07A3C8;
    color: white;
    border-radius: 4px;
    font-size: 17px;
    padding: 15px 20px;
    width: 150px;
    margin: 1rem auto;
    text-align: center;
    font-weight: 600;
`
const Link = styled.div`
    color: #2FADC8;
    margin: auto;
    font-size: 17px;
    text-align: center;
`
const BottomNote = styled.div`
    font-size: 15px;
    text-align: left;
    margin: 1rem;
`
const Footer = styled.div`
    font-size: 15px;
    text-align: left;
    margin: 1rem;
    font-weight: 300;
`
const WhoIsVensaTitle = styled.div`
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    margin: 1rem;
    color: #07A3C8;
`
const WhoIsVensa = styled.div`
    font-size: 15px;
    color: #707070;
    margin: 0rem 1rem;
`
const Background = styled.div`
    background-color: #F5F5F5;
    position: absolute;
    height: 100%;
    width: 100%;
    transition: all 0.3s linear;
    z-index: 10;
    visibility: visible;
`

const SignUpVerification = observer(() => {
    const store = useAccountStore();
    const [noContent, setNoContent] = useState(false);
    const [sent, setSent] = useState(false);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let t = params.get('token');
    let e = params.get('email')

    useEffect(() => {
        if (t && e) {
            if (!sent) {
                store.getSignUpInvitationDetails(t, e, () => setNoContent(true));
                setSent(true);
            }
        } else {
            setNoContent(true);
            //Alert.error("Sorry this is an invalid invitation");
        }
    }, [])

    if (noContent) {
        return (
            <Container style={{height: "100%"}}>
                <Card style={{padding: "1rem"}}>
                    <img src={kiwi} style={{display: "block", margin: "auto"}} />
                    <Title style={{margin: "1rem 0rem"}}>Sorry this invitation has been accepted or expired</Title>
                    <BlueButton style={{margin: "1rem auto"}} onClick={() => window.location.href="https://vensa.com"}>
                            Back to Vensa.com
                    </BlueButton>
                </Card>
            </Container>
        )
    } else {
        return ( 
            <Container> 
                <Background style={{visibility: store.loading ? "visible" : "hidden"}} />   
                <Loading isFetching={store.loading} style={{position: "absolute", top: "6rem"}} />
                <Image style={{backgroundImage: `url(${store.signUpInvitationDetails.LogoUrl})`}} />
                <Card>
                    <Title>{store.signUpInvitationDetails.OrganisationName}</Title>
                    <Address>{store.signUpInvitationDetails.Address}</Address>
                    <SpeechBubble style={{transform: "translate(10px, 35px)"}} />
                    <Text>
                        {store.signUpInvitationDetails.OrganisationName} have invited you to join the Vensa System.
                    </Text>
                    <Text>
                        {store.signUpInvitationDetails.NoteForPatient}
                    </Text>
                    <Line />
                    <TextBig>
                        Please confirm that you are 
                        <div><b>{store.signUpInvitationDetails.GivenName + " " + store.signUpInvitationDetails.FamilyName}</b></div>
                        to begin registration. Your details will be loaded.
                    </TextBig>
                    <BlueButton onClick={() => go.signUpFlow(t, e)}>
                        I'm {store.signUpInvitationDetails.GivenName}
                    </BlueButton>
                    <Link onClick={() => window.location.href="https://vensa.com"}>I'm not {store.signUpInvitationDetails.GivenName}</Link>
                    <BottomNote>
                        If this is not you, or you believe you have received this message by mistake,
                        please kindly let {store.signUpInvitationDetails.OrganisationName} know. You can
                        call them on <a href={"tel:" + store.signUpInvitationDetails.OrganisationPhone} style={{color: "#2FADC8"}}>
                            {store.signUpInvitationDetails.OrganisationPhone}
                        </a>, or send them an email on <a href={"mailto:" + store.signUpInvitationDetails.OrganisationEmail} style={{color: "#2FADC8"}}>{store.signUpInvitationDetails.OrganisationEmail}</a> 
                    </BottomNote>
                </Card>
                <Footer>
                </Footer>
                <img src={logo} style={{display: "block", marginRight: "auto", marginLeft: "1rem"}} />
                <WhoIsVensaTitle>Who's Vensa?</WhoIsVensaTitle>
                <WhoIsVensa>
                    Vensa is an IT company that provides a range of digital patient services for medical centres. For more information head over to <a href={"https://vensa.com"}>Vensa.com</a>.
                </WhoIsVensa>
            </Container>
        )
    }
})

export default SignUpVerification