import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// eslint-disable-next-line max-len
import { actions } from './actions/actions';
import { actions as appointmentActions } from '../../../../AppointmentsPage/actions/actions';
import { actions as loginActions } from '../../../../EntryPage/actions/actions';
import { actions as appActions } from '../../../../App/actions/actions';
import UserInfo from './components/UserInfo/index';
import styles from './menu.module.css';
import { userPropType } from './propTypes/index';
import { go } from '../../../../routes';

import dependantsIcon from 'images/dependants.png';
import shareIcon from 'images/share.png';
import practicesIcon from 'images/practices.png';
import settingsIcon from 'images/settings.svg';
import myWalletIcon from 'svgs/my-wallet.svg';
import supportIcon from 'images/support.png';
import { ReactComponent as helpArrowIcon } from 'svgs/helpArrow.svg';
import { getLoginPageURL, getSupportPage } from 'libs/utils';
import changeUserIcon from 'svgs/changeUser.svg';
import logoutIcon from 'images/logout.png';
import { STORE } from 'store/mobx/mobx';
import { DEFAULT_GUID } from 'common/constants';

const handleMenuItemClick = hideFn => itemFn => () => {
  hideFn();
  itemFn();
};

const MENU_ITEMS = (logout) => [
  {
    text: 'My Care Circle',
    className: styles.dependantsIcon,
    handleClick: () => go.dependants(),
    leftIcon: dependantsIcon
  },
  {
    text: 'Practices',
    className: styles.practicesIcon,
    handleClick: () => go.practices(),
    leftIcon: practicesIcon
  },
  {
    text: 'Settings',
    handleClick: () => go.settings(),
    leftIcon: settingsIcon
  },
  {
    text: 'My Wallet',
    handleClick: () => go.payments(),
    leftIcon: myWalletIcon
  },
  {
    text: 'Help',
    handleClick: () => window.open(getSupportPage(), "_blank"),
    leftIcon: supportIcon,
    rightIcon: helpArrowIcon
  },
  {
    text: 'Change User',
    handleClick: () => {
      const token = JSON.parse(localStorage.getItem("token"));
      window.location.href = getLoginPageURL() + `?token=${token.token}`
    },
    leftIcon: changeUserIcon
  },
  {
    text: 'Logout',
    handleClick: logout,
    leftIcon: logoutIcon
  },
  {
    text: 'Add to Home Screen',
    className: styles.shareIcon,
    handleClick: () => go.addToHomeScreen(),
    leftIcon: shareIcon,
    style: { flexGrow: 0.5 }
  }

]

class Menu extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    goToEditProfile: PropTypes.func.isRequired,
    toggleShrinkApp: PropTypes.func.isRequired,
    goToPractices: PropTypes.func.isRequired,
    goToSettings: PropTypes.func.isRequired,
    goToDependants: PropTypes.func.isRequired,
    goToSupport: PropTypes.func.isRequired,
    goToAddToHomeScreen: PropTypes.func.isRequired,
    toggleCta: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    isShowing: PropTypes.bool.isRequired,
    user: userPropType.isRequired,
  };
  constructor(props) {
    super(props);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleProfileClick = this.handleProfileClick.bind(this);
  }
  shouldComponentUpdate(nextProps) {
    if (
      nextProps.isShowing === false &&
      nextProps.isShowing !== this.props.isShowing
    ) {
      return false;
    }
    return true;
  }
  handleToggle() {
    this.props.toggleCta();
    this.props.toggleShrinkApp();
    this.props.toggleMenu();
  }
  handleProfileClick() {
    this.props.goToEditProfile();
    this.handleToggle();
  }
  render() {
    const store = STORE.account;
    const isDependant = !(store.profile.CareGiverId === DEFAULT_GUID);
    const hasVerifiedDependant = store.profile.hasVerifiedDependantLinked;
    const validMenuItems = MENU_ITEMS(this.props.logout)
      .filter(item =>
        (!isDependant || (isDependant && item.text !== 'My Care Circle'))
        && (hasVerifiedDependant || (!hasVerifiedDependant && item.text !== 'Change User')));
    return (
      <div className={styles.container}>
        <UserInfo user={this.props.user} onClick={this.handleProfileClick} />
        <div className={styles.divider}>&nbsp;</div>
        <div className={styles.menuContainer}>
          {
            validMenuItems.map((item, index) => {
              const RightIcon = item.rightIcon;
              return (
                //Please note that left icons are images, while right icons are SVG imported as ReactJs components
                <div key={index} className={styles.menuItemContainer} style={item.style} onClick={handleMenuItemClick(this.handleToggle)(item.handleClick)}>
                  <img src={item.leftIcon} className={item.className} alt={item.text} />
                  {item.text}{RightIcon && <RightIcon className={styles.rightIcon} />}
                </div>)
            })
          }
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  isShowing: state.menuReducer.isShowing,
  token: state.loginReducer.token,
  user: state.profileReducer.profile,
});
const mapDispatchToProps = dispatch => ({
  toggleCta: bindActionCreators(appointmentActions.toggleCta, dispatch),
  toggleShrinkApp: bindActionCreators(appActions.toggleShrinkApp, dispatch),
  logout: bindActionCreators(loginActions.logout, dispatch),
  toggleMenu: bindActionCreators(actions.toggleMenu, dispatch),
  goToPayments: () => go.payments(),
  goToPractices: () => go.practices(),
  goToSettings: () => go.settings(),
  goToSupport: () => go.support(),
  goToDependants: () => go.dependants(),
  // eslint-disable-next-line max-len
  goToEditProfile: () => go.userAccount(),
  goToAddToHomeScreen: () => {go.addToHomeScreen()},
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Menu);
export { Menu };
