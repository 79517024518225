import React from 'react';
import { observer } from 'mobx-react-lite';
import { SummarySection } from './SummarySection';
import { useMyHealthStore } from 'store/mobx/mobx';
import { BaseSectionItem } from './BaseSectionItem';

export const CurrentConditionSection: React.FC = observer(() => {
  const { CurrentConditions } = useMyHealthStore().summaryTab;

  return (
    <SummarySection
      title="Current Condition"
      observableData={CurrentConditions}
    >
      {CurrentConditions.map(item => (
        <BaseSectionItem
          key={item.Id}
          label={item.Name}
          description={item.Description}
        />
      ))}
    </SummarySection>
  );
});
