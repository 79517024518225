import React from 'react';
import styled from 'styled-components';

export type CircleAvatarProps = {
    imgUrl?: string;
    imgAlt?: string;
    diameter: string;
    border?: string;
    placeHolder: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    background?: string;
}

interface CircleProps {
  diameter: string;
  border?: string;
  background?: string;
}

const Container = styled.div`
  position: relative;
  background-color: ${({background}) => background ?? '#DADADA'};
  width: ${({diameter}: CircleProps) => diameter};
  height: ${({diameter}: CircleProps) => diameter};
  border-radius: 50%;
  border: ${({border}: CircleProps) => border};
  overflow: hidden;
  box-sizing: border-box;
`;

const styles = {
  picture: {
    width:'100%', 
    height:'100%'
  }
}

const CircleAvatar = ({
  imgUrl, 
  imgAlt, 
  diameter,
  border,
  background,
  placeHolder: Icon
}: CircleAvatarProps) => (
    <Container diameter={diameter} border={border} background={background}>
        {imgUrl ? <img src={imgUrl} alt={imgAlt} style={styles.picture}/> : <Icon />}
    </Container>
     
);

export default CircleAvatar;