import React, { useEffect, useLayoutEffect, useRef } from 'react';

const useTimeout = (delay: number | null, callback?: ()=> void, condition: boolean = true) => {
  const savedCallback = useRef(callback);

  useLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback]);

  useEffect(() => {
    if (!delay && delay !== 0 || !condition) {
      return;
    }

    const id = setTimeout(() => savedCallback.current && savedCallback.current(), delay)
    return () => clearTimeout(id)
  }, [delay, condition]);
}

export default useTimeout;