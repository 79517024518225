import React, { useState } from 'react';
import styled from 'styled-components';
import { BasicModal } from 'components/BasicModal/BasicModal';
import { Image } from '../../components/vensaComponents/vensaComponents/components/Image'
import { ReactComponent as Cross } from '../../svgs/blueCross.svg'
import HeroImage from '../../svgs/vScript-Pharm-Path.svg';

const Header = styled.div`
    font-size: 1rem;
    margin: 1rem;
`

const StyledCross = styled(Cross)`
    min-width: 1.353rem;
    width: 1.353rem;
    height: 1.353rem;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 0.875rem;
    stroke: none;
`

const BodyContainer = styled.div`
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const BoldText = styled.div`
    font-family: 'Roboto';
    font-size: 1rem;
    font-weight: bold;
    margin: 0.5rem;
`

const Text = styled.div`
    font-family: 'Roboto';
    font-size: 0.875rem;
    font-weight: lighter;
    margin: 0.5rem;
    height: 4.5rem;
`

const FooterContainer = styled.div`
    display: flex;
    padding: 2rem 0 1.5rem 0
    margin: 2.5rem 0.5rem 1rem 0.5rem;
    justify-content: space-between;
`

const InactiveDot = styled.div`
    height: 1rem;
    width: 1rem;
    border-radius: 100%;
    margin: 0.5rem;
    background-color: var(--color-grey-4);
`

const ActiveDot = styled.div`
    height: 1rem;
    width: 1rem;
    border-radius: 100%;
    margin: 0.5rem;
    background-color: var(--color-pelorous);
`

const DotContainer = styled.div`
    display: flex;
`

const NavigatorButton = styled.div`
    display: flex;
    align-items: center;
    color: var(--color-pelorous);
    font-weight: bold;
    margin: 0 0.5rem;
`

interface IProps {
    goBack?: Function,
}

export const CostModal: React.FC<IProps> = ({goBack}) => {
    const [index, setIndex] = useState(0);

    const HeaderContent = () => {
        return(
            <Header>
                Why is there a charge?
                <StyledCross
                    onClick={() => (goBack && goBack())}
                />
            </Header>
        )
    }

    const BodyContent = () => {
        switch(index) {
            case 0:
                return(
                  <BodyContainer>
                        <Image src={HeroImage}/>
                        <ContentContainer>
                            <BoldText>
                                We review your medical history
                            </BoldText>
                            <Text>
                            We review your medical history and make sure that it is appropriate to prescribe. This may take up to 10m.
                            </Text>
                            <FooterContainer>
                                <DotContainer>
                                    <ActiveDot/>
                                    <InactiveDot onClick={() => setIndex(1)}/>
                                    <InactiveDot onClick={() => setIndex(2)}/>
                                </DotContainer>
                                <NavigatorButton onClick={() => setIndex(1)}>
                                    Next
                                </NavigatorButton>
                            </FooterContainer>
                        </ContentContainer>
                  </BodyContainer>  
                );
            case 1:
                return(
                    <BodyContainer>
                        <Image src={HeroImage}/>
                        <ContentContainer>
                            <BoldText>
                                We generate your script for you online
                            </BoldText>
                            <Text>
                            We are now generating your script electronically to save you time from collection at the practice.
                            </Text>
                            <FooterContainer>
                                <DotContainer>
                                    <InactiveDot onClick={() => setIndex(0)}/>
                                    <ActiveDot/>
                                    <InactiveDot onClick={() => setIndex(2)}/>
                                </DotContainer>
                                <NavigatorButton onClick={() => setIndex(2)}>
                                    Next
                                </NavigatorButton>
                            </FooterContainer>
                        </ContentContainer>
                    </BodyContainer>  
                );
            case 2:
                return(
                    <BodyContainer>
                        <Image src={HeroImage}/>
                        <ContentContainer>
                            <BoldText>
                                We send your script to your pharmacy
                            </BoldText>
                            <Text>
                            We handle the billing and send your script to your pharmacy of choice electronically.
                            </Text>
                            <FooterContainer>
                                <DotContainer>
                                    <InactiveDot onClick={() => setIndex(0)}/>
                                    <InactiveDot onClick={() => setIndex(1)}/>
                                    <ActiveDot/>
                                </DotContainer>
                                <NavigatorButton onClick={() => goBack && goBack()}>
                                    Close
                                </NavigatorButton>
                            </FooterContainer>
                        </ContentContainer>
                    </BodyContainer>  
                );
            default:
                return(
                    <BodyContainer onClick={() => goBack && goBack()}>
                        Error
                    </BodyContainer>
                );
        }
    }

    return(
        <BasicModal
            ModalHeaderContent={HeaderContent}
            ModalBodyContent={BodyContent}
        />
    )
}