import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clickOutside from 'react-click-outside';

import styles from './inner.module.css';

class Inner extends Component {
  handleClickOutside = () => {
    //this.props.toggle();
  };
  render() {
    return <div className={styles.container}>{this.props.children}</div>;
  }
}
Inner.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  toggle: PropTypes.func,
};

export default clickOutside(Inner);
export { Inner };
