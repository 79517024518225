import React from 'react';

import styles from './securityPin.module.css';
import UIContainer from '../../components/UIContainer';
import ProgressBar from '../components/ProgressCard';
import lock from '../../svgs/identityLock.svg';
import progressBar from '../../svgs/progressBarLvl2.svg';
import PinCode from '../components/PinCode';
import { go } from 'routes';

const SecurityPin = () => (
    <UIContainer goBackFn={go.identity} heading={"Step 2: Security PIN"}>
        <div className={styles.container} style={{height: "100%", marginBottom: "1rem"}}>
            <ProgressBar 
                text={"The second step is to set up a security PIN code to protect your data. This is an extra level of security that Vensa requires to protect your health data."}
                icon={lock}
                progressBar={progressBar}
            />
            <PinCode scrollToTop={() => window.scrollTo(0,0)} />
        </div>
    </UIContainer>
)

export default SecurityPin;