import Cancelation from 'pages/mobile/domain/Appointment/Cancellation';
import DetailCard from '../../components/DetailCard';
import { Appointment, IdProp } from 'pages/mobile/domain/Appointment/types';
import moment from 'moment';
import React, { useState } from 'react';
import styles from './index.module.scss';
import Calendar from '../../components/Calendar'
import { useValidation } from '../../Cancellation/api';
import CancelError from '../../Cancellation/CancelError';
import HeaderCardUI from 'components/shared/mobile/HeaderCardUI';
import { useAppointmentStore } from 'store/mobx/mobx';
import { useParams } from 'react-router-dom';
import { useQuires } from 'hooks/useQueries';
import { push } from 'routes/utils';
import { parseUriPath } from '../../services/parse';

const BookedDetailCard = () => {
  const [canceling, setCanceling] = useState(false);
  const handleCancel = () => {
    setCanceling(!canceling);
  }

  const params = useParams<IdProp>();
  const store = useAppointmentStore();
  const bookedData = store.getAppointment(params.id, 'booked') as Appointment;
  if(!bookedData) {
    push('/home');
    window.location.reload();
  }
  
  const {data: validationData, error, loading} = useValidation(params.id);
  const policyNote = validationData ? validationData.CancellationNote : '';
  const iconUrl = validationData ? validationData.iconUrl : '';
  
  const query = useQuires<{backUrl: string}>();
  const {path, queries} = parseUriPath(query.backUrl);
  const goBack = () => {
    query.backUrl && push(path, queries);
  }
  const confirmed = bookedData.Status.toLowerCase().includes('confirmed');
  return (
    <>
    {
    canceling ? error ? 
    <CancelError 
      handleClick={handleCancel} 
      practiceName={bookedData.PracticeName}
      phone={bookedData.PatientPhone}
      cancellationHour={error ? error.response ? error.response.data.CancellationHour : 0 : 0} /> 
    : (
      <Cancelation
        policyNote={policyNote}
        iconUrl={iconUrl}
        close={handleCancel} 
        data={bookedData}/>) 
      : (
      <>
      <HeaderCardUI 
        handleClick={goBack} 
        text={moment(bookedData.Time).format('dddd, DD MMM h:mmA')}>
        <DetailCard 
          data={bookedData} 
          disableDependant={false}
          extra={confirmed &&
          <span className={styles.cancelButton} onClick={handleCancel}>Cancel this appointment</span>}/>
      </HeaderCardUI>
      {
        confirmed && <Calendar general={bookedData.CalendarUrl} google={bookedData.GoogleCalendarUrl}/>
      }
      </>
      )
    }
    </>
  )};

export default BookedDetailCard;
