import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// eslint-disable-next-line max-len
import { actions as listingActions } from '../../../../PracticesPage/actions/actions';
import { actions as appointmentActions } from '../../../../AppointmentsPage/actions/actions';
import { actions as feedActions } from '../../../../HealthFeed/actions/actions';
import { actions } from './actions/actions';
import { actions as profileActions } from './actions/profileActions';
import Appointments from '../../../../AppointmentsPage/index';
import UIContainer from '../../index';
import HealthFeed from '../../../../HealthFeed/HealthFeedMobile/index';
import RequestScript from '../../../../PrescriptionsPage/index';
import Slider from './components/Slider/index';
import Alert from '../../../../libs/Alert';
import { clearScriptRequest } from '../../../../selectors/scriptSelectors';
import MyHealthPage from 'MyHealthPage/MyHealthPage';
import { getToken } from 'libs/newHttp';
import { actions as WhiteBoxActions} from 'components/UIContainer/MobileUI/MainShell/actions/actions';
import { useDispatch } from 'react-redux';
import { useMyHealthStore } from 'store/mobx/mobx';

const sliderTabs = [
  {
    text: 'My Health Feed',
    component: HealthFeed,
  },
  {
    text: 'Appointments',
    component: Appointments,
  },
  {
    text: 'Prescriptions',
    component: RequestScript,
  },
  {
    text: 'My Health',
    component: MyHealthPage,
  },
];

class MainShell extends Component {
  constructor(props) {
    super(props);

    const { location } = this.props

    let tab = 0;
    if (location.state !== undefined) {
      if (location.state.goTo !== undefined) {
        tab = location.state.goTo;
      }
    }
    
    this.state = {
      
      renderedComponent: this.props.sliderTabs[tab],
    };
  }
  static propTypes = {
    getConfirmedAppointments: PropTypes.func.isRequired,
    getPastAppointments: PropTypes.func.isRequired,
    getClinics: PropTypes.func.isRequired,
    getFeed: PropTypes.func.isRequired,
    getProfile: PropTypes.func.isRequired,
    setComponentIndex: PropTypes.func.isRequired,
    clearScriptRequest: PropTypes.func.isRequired,
    setFirstTime: PropTypes.func.isRequired,
    id: PropTypes.string,
    index: PropTypes.number.isRequired,
    firstTime: PropTypes.bool,
    removeSuccess: PropTypes.bool,
    sliderTabs: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  };

  componentDidMount() {
    const token = getToken();
    window.scrollTo(0, 0);
    this.props.clearScriptRequest();
    this.props.getProfile(token);
    this.props.getPastAppointments(token);
    this.props.getConfirmedAppointments(token);
    this.props.getFeed(token);
    this.props.getClinics(token);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.firstTime) {
      Alert.success('Welcome! You have successfully registered with Vensa.');
      this.props.setFirstTime(false);
    }
    if (
      nextProps.removeSuccess &&
      nextProps.removeSuccess !== this.props.removeSuccess
    ) {
      this.props.getFeed(this.props.id);
      Alert.success('The prescription has been removed from your history');
    }
  }
  setRenderedComponent = (component, index) => {
    this.props.closeWhiteNotification({ isOpen: false })
    this.setState({
      renderedComponent: component,
    });
    this.props.setComponentIndex(index);
  };

  
  render() {

    const isMyHealthPage = this.state.renderedComponent.text === 'My Health';
    return (
      <UIContainer
        showMenu
        isFetching={this.props.isFetching}
        headerHeight={isMyHealthPage ? '122px' : '140px'}
        secondaryHeading={
          <Slider
            tabs={this.props.sliderTabs}
            selectedTab={this.state.renderedComponent}
            setRenderedComponent={this.setRenderedComponent}
          />
        }
      >
        <this.state.renderedComponent.component />
      </UIContainer>
    );
  }
}

const mapStateToProps = state => ({
  id: state.loginReducer.token,
  index: state.mainShellReducer.componentIndex,
  firstTime: state.profileReducer.firstTime,
  removeSuccess: state.scriptReducer.removeSuccess,
  isFetching:
    state.feedReducer.isFetching ||
    state.scriptReducer.isRemoving ||
    state.scriptReducer.isFetchingCurrent ||
    state.scriptReducer.isFetchingPast,
  sliderTabs,
});
const mapDispatchToProps = dispatch => ({
  getClinics: bindActionCreators(listingActions.getPracticeListings, dispatch),
  // eslint-disable-next-line max-len
  getConfirmedAppointments: bindActionCreators(
    appointmentActions.getConfirmedAppointments,
    dispatch,
  ),
  getFeed: bindActionCreators(feedActions.getFeed, dispatch),
  getPastAppointments: bindActionCreators(
    appointmentActions.getPastAppointments,
    dispatch,
  ),
  setComponentIndex: bindActionCreators(actions.setComponentIndex, dispatch),
  setFirstTime: bindActionCreators(profileActions.setFirstTime, dispatch),
  getProfile: bindActionCreators(profileActions.getProfile, dispatch),
  clearScriptRequest: clearScriptRequest(dispatch),
  closeWhiteNotification: bindActionCreators(WhiteBoxActions.setWhiteBoxNotification, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MainShell);
