import React from 'react';
import PropTypes from 'prop-types';

import styles from './afterHoursNote.module.css';

const AfterHoursNote = ({ name, hours }) => (
  <div className={styles.container}>
    <div>Important:</div>
    <div className={styles.note}>{`${name} is closed on weekends and might not be able to process your request in ${hours}. For urgent enquiries, please call your practice.`}</div>
  </div>
);

AfterHoursNote.propTypes = {
  name: PropTypes.string.isRequired,
  hours: PropTypes.string.isRequired,
};

export default AfterHoursNote;
