import Alert from 'libs/Alert';
import React, { useState } from 'react';
import { useAccountStore, useMyHealthStore } from 'store/mobx/mobx';
import styled from 'styled-components';

//import Card from '../components/Card';
import { ReactComponent as BlueCross } from 'svgs/blueCircleButton.svg';

const Container = styled.div`
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    background-color: white;
    border-radius: 4px;
    padding: 0.8rem;
    overflow: scroll;
`
const Title = styled.div`
    font-size: 21px;
    font-weight: medium;
    color: #07A3C8;
    text-align: center;
    margin: 1rem;
`
const Text = styled.div`
    font-size: 17px;
    color: #585858;
    width: 100%;
    text-align: left;
`

interface Props {
    hide: (e: any) => void;
}
const InformationUsage: React.FC<Props> = ({hide}) => { 
    return (
        <Container>
            <Title>How is my information being used?</Title>
            <Text>
                The study is expected to run for 15 months, to understand several different 'objectives'. You will be sent another copy of this information
                sheet and asked to consent to take part in each Objective as they come up, and you can say no at any time. The intervention of this study 
                involves how you receive your test results.    
            </Text>
            <br />
            <Text>
                <b>Objective One</b> looks at understanding the effectiveness of providing different types of advice to patients. 
                This will involve you answering questions on how you have received your normal results and the advice provided.
            </Text>
            <br />
            <Text>
                <b>Objective Two</b> looks at understanding factors that drives test requests. The study will look at reasons why 
                you needed to visit your doctor, and your doctor’s clinical reason to order tests for you.
            </Text>
            <br />
            <Text>
                <b>Objective Three</b> looks at understanding clinical factors in interpretation of the laboratory results and personalising what is 
                normal for you. This objective will involve using information in your health record that directly affects test results - such as your 
                current conditions, medications, and previous test results - to determine if your latest results are within expected normal range. 
                Other questions such as family history of certain heart, liver, or kidney diseases; diet, and lifestyle questions may also be asked due 
                to their potential influence on test results. Sometimes a test does not end up being ordered when it usually would have been expected for 
                a particular condition or circumstance – for example, if the same type of test has been done recently through a specialist or hospital 
                instead. The study also wants to understand this situation, so you may be added to a ‘comparison group’ of people like you, even if you 
                do not get a result. Together, having the different objectives and comparison groups helps us learn and analyse a whole range of patterns and preferences.
            </Text>
            <div style={{position: "relative", margin: "2rem 0rem"}}>
                <BlueCross style={{display: "block", margin: "auto"}} onClick={hide} />
            </div>
        </Container>
    )
}

export default InformationUsage;