import PropTypes from 'prop-types';
import { doctorPropType } from '../../PracticeProfilePage/propTypes';

const clinicPropType = PropTypes.shape({
  Id: PropTypes.string.isRequired,
  Name: PropTypes.string.isRequired,
  Address: PropTypes.string.isRequired,
  Phone: PropTypes.string,
  IsOnline: PropTypes.bool,
  PictureUrl: PropTypes.string,
  PracticeId: PropTypes.string,
  IsUsingVensa: PropTypes.bool.isRequired,
});

const practicePropType = PropTypes.shape({
  Id: PropTypes.string.isRequired,
  Name: PropTypes.string.isRequired,
  PracticeId: PropTypes.string,
  Address: PropTypes.string.isRequired,
  Phone: PropTypes.string,
  IsUsingVensa: PropTypes.bool,
  IsOnline: PropTypes.bool,
  ScriptEnabled: PropTypes.bool,
  UrlName: PropTypes.string,
  Doctors: PropTypes.arrayOf(doctorPropType),
});

export { clinicPropType, practicePropType };
