import React, { useState } from 'react';
import styled from 'styled-components';

import Card from '../components/Card';
import privacy from 'images/privacy.svg';
import ownership from 'images/ownership.svg';
import personalised from 'images/personalHealthcare.svg';
import getStarted from 'images/high5.gif';
import { useComponentDidMount } from 'libs/newHttp';
import { isDesktop } from 'config';

const Container = styled.div`
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    margin: auto;
    overflow: hidden;
`

interface Props {
    hide: (e: any) => void;
}
const IdentityInfo: React.FC<Props> = ({hide}) => {
    const [state, setState] = useState(0);
    const [height, setHeight] = useState(isDesktop() ? "600px" :window.innerHeight - 34);
    
    useComponentDidMount(() => {
        window.addEventListener('resize', () => setHeight(window.innerHeight - 34));    
    })


    let card;
    if (state === 0) card = <Card 
                                hide={hide} 
                                totalCards={4} 
                                active={0} 
                                title={"Privacy"} 
                                text={"Privacy matters. Keep your health data private and secure"} 
                                onNext={() => setState(1)}
                                icon={privacy}
                                goBack={(newState) => setState(newState)}
                            />;  
    else if (state === 1) card = <Card 
                                    hide={hide} 
                                    totalCards={4} 
                                    active={1} 
                                    title={"Ownership"} 
                                    text={"Your health data belongs to you. Take control of your data"} 
                                    onNext={() => setState(2)}
                                    icon={ownership}
                                    goBack={(newState) => setState(newState)}
                                />
    else if (state === 2) card = <Card 
                                    hide={hide} 
                                    totalCards={4} 
                                    active={2} 
                                    title={"Personalised healthcare"} 
                                    text={"Ensure that you and your whānau receive the best care, at your favourite practice, everytime"} 
                                    onNext={() => setState(3)}
                                    icon={personalised}
                                    goBack={(newState) => setState(newState)}
                                />
    else if (state === 3) card = <Card 
                                    hide={hide} 
                                    totalCards={4} 
                                    active={3} 
                                    title={"Convenience"} 
                                    text={"Take your health data with you, wherever you go, right in your pocket"} 
                                    onNext={hide}
                                    finishButton={true}
                                    goBack={(newState) => setState(newState)}
                                    icon={getStarted}
                                />
                        
    return (
        <Container style={{height: height, width: isDesktop() ? "400px" : "auto"}}>
            {card}
        </Container>
    )
}

export default IdentityInfo