import React from 'react';
import PropTypes from 'prop-types';
import styles from './listingMenu.module.css';

const ListingMenu = ({ unlinkPractice, toggleMenuActive }) => (
  <div onClick={toggleMenuActive} className={styles.outer}>
    <div className={styles.container} onClick={toggleMenuActive}>
      <div onClick={unlinkPractice}>Remove this practice</div>
    </div>
  </div>
);
ListingMenu.propTypes = {
  unlinkPractice: PropTypes.func.isRequired,
  toggleMenuActive: PropTypes.func.isRequired,
};
export default ListingMenu;
