import React from "react";

interface RadioGroupValue {
    checkedValue?: string;
    handleChange(event: React.ChangeEvent<HTMLInputElement>): void;
}

export const RadioGroupContext = React.createContext<RadioGroupValue>({
    checkedValue: '',
    handleChange: () => {}
});

export const useRadioGroupContext = () => {
    let context = React.useContext(RadioGroupContext);
    if(context === undefined){
        throw Error('RadioItem is not working inside of a RadioGroup context.');
    }
    return context;
}