import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from 'components/vensaComponents';
import { MyHealthNavBar } from './components/MyHealthNavBar';
import { ClinicalNotesTab } from './Tabs/ClinicalNotesTab/ClinicalNotesTab';
import { MyHealthTabEnum } from 'store/mobx/myHealthStore/myHealthStore';
import { ImmunisationTab } from './Tabs/ImmunisationsTab/ImmunisationsTab';
import { LabResultsTab } from './Tabs/LabResultsTab/LabResultsTab';
import { useAccountStore, useMyHealthStore } from 'store/mobx/mobx';
import { SummaryTab } from './Tabs/SummaryTab/SummaryTab';
import { useDispatch, connect } from 'react-redux';
import { NoIdentityNotificationBox } from './components/NoIdentityNotificationBox';
import Loading from 'components/loadingSpinner';
import { actions } from 'components/UIContainer/MobileUI/MainShell/actions/actions';
import BannerEmptyFeedCard from 'HealthFeed/HealthFeedMobile/BannerEmptyFeedCard/BannerEmptyFeedCard';
import { go } from 'routes';
import Notifications from '../HealthFeed/HealthFeedDesktop/Notifications/Notifications';
import { useComponentDidMount } from 'libs/newHttp';

const SUB_TABS = {
  [MyHealthTabEnum.Summary]: SummaryTab,
  [MyHealthTabEnum.ClinicalNotes]: ClinicalNotesTab,
  [MyHealthTabEnum.Immunisations]: ImmunisationTab,
  [MyHealthTabEnum.LabResults]: LabResultsTab,
};

const MyHealthPage: React.FC<LinkDispatchProps> = observer(({feed}) => {
  const {
    currentTab,
    hasWhiteBoxOpened,
    loading,
    summaryTab,
  } = useMyHealthStore();
  const store = useAccountStore();
  const phrStore = useMyHealthStore();
  const TabComponent = SUB_TABS[currentTab];
  const profile = useAccountStore().profile;
  const doesUserIdentifyVerified = profile.IdentityVerified;
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const [showNotes, setShowNotes] = useState(false);

  useComponentDidMount(() => {
    // store.getProfile();
    phrStore.getClinicNotes();
    const sec = store.profile.SecurityPinCodeSetup;
    const pend = store.profile.PendingRequest;
    const veri = store.profile.IdentityVerified;

    phrStore.getSummary(() => {
      if (sec && ((pend && veri) || (!pend && veri))) {
        go.pin(1);
      }
    })
  });

  useEffect(() => {
    // console.log(phrStore.clinicalErrorText);
    setShowNotes(phrStore.clinicalErrorText === null) 
    return () => {
      dispatch(
        actions.setWhiteBoxNotification({
          isOpen: false,
          index: 0,
        }),
      );
    };
  }, []);
  
  useEffect(() => {
    if (!window.location.pathname.startsWith('/home')) return;

    // TODO: This hasWhiteBoxOpened property is a temporary solution
    // for only showing the whiteBox one time, it should use a value from server instead
    // when that property is ready, remove this hasWhiteBoxOpened and setHasWhiteBoxOpened from the codebase

    if (!hasWhiteBoxOpened && doesUserIdentifyVerified && profile.PrivacyPolicySetup && profile.MobileVerified && profile.EmailVerified) {
      dispatch(
        actions.setWhiteBoxNotification({
          isOpen: true,
          index: 0,
        }),
      );
      phrStore.setHasWhiteBoxOpened(true);
    }

  }, [
    hasWhiteBoxOpened,
    doesUserIdentifyVerified,
    dispatch,
    // setHasWhiteBoxOpened,
  ]);
  
  return (
    <div style={{overflow: "visible", paddingBottom: "0rem"}}>
    <Box direction="column" overflow="visible">
      {doesUserIdentifyVerified && profile.PrivacyPolicySetup && profile.MobileVerified && profile.EmailVerified ? (
        <div style={{overflow: "visible", marginBottom: "1rem"}}>
          <Loading isFetching={loading || store.loadingAcknowledgement} style={{fill: "#07A3C8", position: "static", overflow: "visible", height: "88%"}}>
            <MyHealthNavBar errorText={phrStore.clinicalErrorText} />
            {summaryTab.BlacklistedCard !== null && summaryTab.BlacklistedCard.Type !== "string" && show &&
                <BannerEmptyFeedCard 
                  bannerText={"Access Restricted"}
                  title={""}
                  text={summaryTab.ErrorMessage}
                  button={true}
                  buttonText={"Ok got it"}
                  link={true}
                  linkText={"Check my privacy"}
                  restricted={true}
                  linkOnClick={() => go.privacy()}
                  buttonOnClick={() => {
                    setShow(false);
                    store.sendAcknowledgement(0, () => setShow(false));
                  }} // ACK 0}
              />
            }
            <TabComponent />
          </Loading>
        </ div>
      ) : (
        <div style={{margin: "1.37rem 0.3rem", overflow: "visible"}}>
          {doesUserIdentifyVerified && !profile.PrivacyPolicySetup ?
            (
              <Notifications feed={feed} getFeed={() => {}} hide={() => {}} />          
            ) 
            : 
            (
              <NoIdentityNotificationBox />
            )
          }
        </div>
      )}
    </Box>
    </div>
  );
});

MyHealthPage.displayName = 'MyHealthPage';

interface LinkDispatchProps {
  feed: any;
}

const mapStateToProps = (state: any) => ({
  feed: state.feedReducer.feed,
})

export default connect(mapStateToProps)(MyHealthPage);