import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';

import Countdown from '../../../../../components/Countdown/index';
import styles from './resendForm.module.css';
import { ReactComponent as Spinner } from '../../../../../svgs/spinner.svg';

class ResendForm extends Component {
  static propTypes = {
    phone: PropTypes.string,
    onPhoneChange: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    milliSecondsToWait: PropTypes.number.isRequired,
    countdownStartTime: PropTypes.instanceOf(moment).isRequired,
  };
  getMilliSecondsRemaining = () =>
    (this.props.countdownStartTime.unix() +
      this.props.milliSecondsToWait / 1000 -
      moment().unix()) *
    1000;
  isAllowedToResend = () => this.getMilliSecondsRemaining() <= 0;
  updatePage = () => {
    this.forceUpdate();
  };
  submit = e => {
    e.preventDefault();
    if (this.isAllowedToResend()) {
      this.props.onPhoneChange(this.input.value);
    }
  };
  render() {
    return (
      <form className={styles.message} onSubmit={this.submit}>
        <div className={styles.inputContainer}>
          <input
            className={classnames(styles.input, {
              [styles.faded]: !this.isAllowedToResend(),
            })}
            defaultValue={this.props.phone}
            required
            size="10"
            type="tel"
            ref={c => {
              this.input = c;
            }}
          />
          <button type="submit" className={styles.resendButton}>
            {!this.props.isFetching ? (
              <span>resend</span>
            ) : (
              <Spinner className={styles.countdownSpinner} />
            )}
          </button>
        </div>
        {!this.isAllowedToResend() && (
          <div className={styles.countdownText}>
            <span>Wait</span>
            <Countdown
              className={styles.countdown}
              milliSecondsRemaining={this.getMilliSecondsRemaining()}
              interval={1000}
              formatTime={t => Math.floor(t / 1000)}
              completedCallback={this.updatePage}
            />
            <span>seconds to resend.</span>
          </div>
        )}
      </form>
    );
  }
}

export default ResendForm;
export { styles };
