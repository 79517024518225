import React, { ComponentType, CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent as Close} from 'svgs/closePrimary.svg';

const styles = {
    container: {
        position: 'fixed' as 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(0,0,0,0.8)',
        overflowY: 'auto' as 'auto'
    },
    card: {
        position:'relative' as 'relative',
        margin: '10% auto 10%',
        backgroundColor: '#FFFFFF',
        width: '35%',
        maxWidth: '600px',
        minHeight: '50%',
        display: 'flex',
        flexDirection: 'column' as 'column',
        borderRadius: '7px',
        padding: '1rem',
        boxSizing: 'border-box'
    } as CSSProperties,
    cross: {
        width: '1rem',
        height: '1rem',
        alignSelf: 'flex-end',
        cursor: 'pointer'
    }
}

interface ModalHolder {
    setFlag?: (flag:boolean) => void,
    style?: CSSProperties,
}

const withModal = <P,>(Component: ComponentType<P>, touch?: boolean, cardStyle?: CSSProperties) => ({setFlag, style, ...rest}: P & ModalHolder) => {
    const handleClose = () => {
        setFlag && setFlag(false)
    }
    const defaultCardStyle: CSSProperties = {...styles.card, width: touch ? '80%' : '35%', ...style}
    return ReactDOM.createPortal(
        <div style={styles.container}>
            <div style={{...defaultCardStyle, ...cardStyle}}>
                {setFlag && <Close style={styles.cross} onClick={handleClose} />}
                <Component {...rest as P}/>
            </div>
        </div>, document.getElementById('portal') as Element
    );
}

export default withModal;