import { request, HTTP } from '../../../libs/newHttp';

interface ISettings {
    OrganisationId: string;
    AllowAccessMyHealthRecords: boolean;
    AllowAccessMyDoctorNotes: boolean;
    AllowAccessMyDependantHealthRecords: boolean;
    AllowShareMyHealthDataWithPractices: boolean;
    AllowAccessAndStoreMedications: boolean;
}

export const setPinAPI = (pinCode: string) => () => 
    request({
        method: HTTP.POST,
        url: `consumer/security/pin-code`,
        data: pinCode
    })

export const uploadPhotoAPI = (pic: string, changeProfilePhoto: boolean) => () => 
    request({
        method: HTTP.POST,
        url: `consumer/identity/selfie`,
        data: { Selfie: pic.split(',')[1], changeProfilePhoto }
    })

export const sendPrivacySettingsAPI = (settings: ISettings) => () =>
    request({
        method: HTTP.POST,
        url: `consumer/privacy/privacy-settings`,
        data: settings
    })

export const submitIdentityRequestAPI = (orgId: string) => () => 
    request({
        method: HTTP.POST,
        url: `consumer/identity/practice-verification-request/${orgId}`
    })