import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    padding:  1rem 2rem;
    color: white;
    background-color: ${(p: ContainerProps) => p.disabled ? "lightgray" : "#07a3c8"};
    font-size: 13px;
    margin: 10px;
    border-radius: 25px;
    cursor: pointer;
    width: fit-content;
    margin: auto;
    box-shadow: 1px 5px 6px lightgray;
`
interface ContainerProps {
    disabled: boolean;
}
interface Props {
    text: string;
    onClick: () => void;
    style?: {};
    disabled?: boolean;
}

const BlueButton: React.FC<Props> = ({text, onClick, style, disabled}) => {
    return (
        <Container 
            style={style}
            onClick={() => {
                if (disabled) { return; }
                onClick();
            }}
            disabled={disabled!}
        > 
            {text}
        </Container>
    );
}

export default BlueButton;