const actionTypes = {
  submitScriptRequest: 'SUBMIT_SCRIPT_REQUEST',
  submitScriptRequestSuccess: 'SUBMIT_SCRIPT_REQUEST_SUCCESS',
  submitScriptRequestFailure: 'SUBMIT_SCRIPT_REQUEST_FAILURE',
  checkForConfirmation: 'CHECK_FOR_SCRIPT_REQUEST_CONFIRMATION',
  confirmationSuccess: 'SCRIPT_REQUEST_CONFIRMATION_SUCCESS',
  confirmationFailure: 'SCRIPT_REQUEST_CONFIRMATION_FAILURE',
  confirmationPollStart: 'SCRIPT_REQUEST_CONFIRMATION_POLL_START',
  confirmationPollContinue: 'SCRIPT_REQUEST_CONFIRMATION_POLL_CONTINUE',
  confirmationPollEnd: 'SCRIPT_REQUEST_CONFIRMATION_POLL_END',
  clearConfirmationState: 'CLEAR_SCRIPT_REQUEST_CONFIRMATION_STATE',
  setIsBusy: 'SCRIPT_REQUEST_SET_IS_BUSY',
  setWillRetry: 'SCRIPT_REQUEST_WILL_RETRY',
  retryRequest: 'RETRY_SCRIPT_REQUEST',
  retrySuccess: 'RETRY_SCRIPT_REQUEST_SUCCESS',
  retryFailure: 'RETRY_SCRIPT_REQUEST_FAILURE',
  retryLimitReached: 'RETRY_LIMIT_REACHED',
  clearRetryState: 'RETRY_SCRIPT_CLEAR_STATE',
};
const setIsBusy = () => ({
  type: actionTypes.setIsBusy,
});
const submitScriptRequest = (details, token) => ({
  type: actionTypes.submitScriptRequest,
  payload: { details, token },
});
const submitScriptRequestSuccess = scriptId => ({
  type: actionTypes.submitScriptRequestSuccess,
  payload: { scriptId },
});
const submitScriptRequestFailure = error => ({
  type: actionTypes.submitScriptRequestFailure,
  error: true,
  payload: { error },
});
const checkForConfirmation = (scriptId, token) => ({
  type: actionTypes.checkForConfirmation,
  payload: { scriptId, token },
});
const confirmationSuccess = () => ({
  type: actionTypes.confirmationSuccess,
});
const confirmationFailure = () => ({
  type: actionTypes.confirmationFailure,
  error: true,
});
const confirmationPollStart = (scriptId, token) => ({
  type: actionTypes.confirmationPollStart,
  payload: { scriptId, token },
});
const confirmationPollContinue = () => ({
  type: actionTypes.confirmationPollContinue,
});
const confirmationPollEnd = (confirmed, error) => ({
  type: actionTypes.confirmationPollEnd,
  // eslint-disable-next-line no-unneeded-ternary
  error: error ? true : false,
  payload: { confirmed, error },
});
const clearConfirmationState = () => ({
  type: actionTypes.clearConfirmationState,
});
const setWillRetry = () => ({
  type: actionTypes.setWillRetry,
});
const retryRequest = (details, token) => ({
  type: actionTypes.retryRequest,
  payload: { details, token },
});
const retrySuccess = () => ({
  type: actionTypes.retrySuccess,
});
const retryFailure = () => ({
  type: actionTypes.retryFailure,
});
const retryLimitReached = () => ({
  type: actionTypes.retryLimitReached,
});
const clearRetryState = () => ({
  type: actionTypes.clearRetryState,
});

const actions = {
  setIsBusy,
  submitScriptRequest,
  submitScriptRequestSuccess,
  submitScriptRequestFailure,
  checkForConfirmation,
  confirmationSuccess,
  confirmationFailure,
  confirmationPollStart,
  confirmationPollContinue,
  confirmationPollEnd,
  clearConfirmationState,
  setWillRetry,
  retryRequest,
  retrySuccess,
  retryFailure,
  retryLimitReached,
  clearRetryState,
};

export { actionTypes, actions };
