import React from 'react';
import styles from './dependantMenu.module.scss';

interface DepandantMenuProps {
  top: number;
  left: number;
  width: number;
  handleClick?: () => void;
  text: string;
}
const DepandantMenu: React.FC<DepandantMenuProps> = ({top, left, width, handleClick, text}) => {
  return (
  <div className={styles.container} style={{
    top: `${top}px`,
    left: `${left + (width - 200)}px`,
    zIndex: 40
  }}>
    <div className={styles.button} onClick={handleClick}>
      {text}
    </div>
  </div>);
};

export default DepandantMenu;
