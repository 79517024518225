import { call, takeEvery, put } from 'redux-saga/effects';

import { GenericParserHttp } from '../../libs/Http';
import { actions, actionTypes } from '../actions/actions';
import { checkAuth } from 'libs/misc';

const api = token =>
  GenericParserHttp.get('consumer/practice', { token }).then(
    ({ body }) => body,
  );

function* getPracticeListings({ payload: { token } }) {
  try {
    const practices = yield call(api, token);
    yield put(actions.getPracticeListingsSuccess(practices));
  } catch (err) {
    checkAuth(err);
    yield put(actions.getPracticeListingsFailure(err));
  }
}

function* getPracticeListingsSaga() {
  yield takeEvery(actionTypes.getPracticeListings, getPracticeListings);
}

export { getPracticeListingsSaga };
