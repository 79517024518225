import React from 'react';

import styles from './pinCodeButton.module.css';
import { ReactComponent as Arrow } from '../../../svgs/roundArrow.svg';

const PinCodeButton = (props) => {
    return(
        <div className={styles.container} onClick={props.onClick}>
            <p>{props.text}</p>
            <div className={styles.flexbox}>
                <img className={styles.image} src={props.pic} alt={"pic"} />
                <Arrow  />
            </div>
        </div>
    );
}

export default PinCodeButton;