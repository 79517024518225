import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from '../actions/passwordActions';
import UIContainer from '../../components/UIContainer';
import Form from './components/Form';
import Alert from '../../libs/Alert';
import { isValidPassword } from '../../libs/ValidBasicInfo';
import { errorPropType } from '../../vAppointment/propTypes';
import { isDesktop } from '../../config';
import { goBack } from '../../routes/utils';

class ChangePassword extends Component {
  static propTypes = {
    isRequesting: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    changePassword: PropTypes.func.isRequired,
    clearPasswordState: PropTypes.func.isRequired,
    dontGoBack: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    error: errorPropType,
  };
  state = {
    oldPassword: '',
    newPassword: '',
    isValid: false,
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      const error = nextProps.error;
      if (error.response) {
        if (error.response.body.Message) {
          Alert.error(error.response.body.Message);
          this.props.clearPasswordState();
          return;
        }
      }
      Alert.error('Unexpected error has occured, please try again later');
      this.props.clearPasswordState();
      return;
    }
    if (nextProps.success) {
      Alert.success('Your new password has been set');
      this.props.clearPasswordState();
      if (this.props.dontGoBack) {
        this.props.close();
        return;
      } 
      goBack();
    }
  }
  setOldPassword = e => {
    this.setState({ oldPassword: e.target.value });
  };
  setNewPassword = e => {
    this.setState({ newPassword: e.target.value }, this.validate);
  };
  validate = () => {
    const valid = isValidPassword(this.state.newPassword);
    this.setState({ isValid: valid });
  };
  handleSubmit = e => {
    e.preventDefault();
    if (!this.state.oldPassword) {
      Alert.error('You must confirm your current password');
    } else if (this.state.isValid) {
      this.props.changePassword(this.state.oldPassword, this.state.newPassword);
    } else {
      Alert.error('Your password needs to be at least 6 characters long.');
    }
  };
  render() {
    const ChangeForm = (
      <Form
        submit={(e) => this.handleSubmit(e)}
        oldPW={this.state.oldPassword}
        newPW={this.state.newPassword}
        setOld={this.setOldPassword}
        setNew={this.setNewPassword}
        isValid={this.state.isValid && this.state.oldPassword}
        desktop={isDesktop()}
        isProcessing={this.props.isRequesting}
      />
    );
    if (isDesktop()) return ChangeForm;
    else
      return (
        <UIContainer
          saveFn={this.handleSubmit}
          heading="Change Password"
          isFetching={this.props.isRequesting}
        >
          <div>
            {ChangeForm}
          </div>
        </UIContainer>
      );
  }
}

const mapStateToProps = state => ({
  isRequesting: state.changePasswordReducer.isRequesting,
  success: state.changePasswordReducer.success,
  error: state.changePasswordReducer.error,
});
const mapDispatchToProps = dispatch => ({
  clearPasswordState: bindActionCreators(actions.clearChangePassword, dispatch),
  changePassword: bindActionCreators(actions.changeUserPassword, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangePassword);
