import React, { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as Warning } from 'svgs/blueWarning.svg';
import Button from 'components/DesktopComponents/BlueButton';
import { go } from 'routes';
import { ReactComponent as Cross } from 'svgs/blueCross.svg';
import { isDesktop } from 'config';

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: ${(props: ContainerProps) => props.show ? "0.6" : "0" };
    z-index: 1;
    transition: all 0.5s;
    overflow: visible;
`
interface ContainerProps {
    show: boolean;
}
const Container = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    margin: 2rem;
    margin-bottom: 6rem;
    left: 0;
    right: 0;
    top: 0; 
    bottom: 0;
    opacity: ${(props: ContainerProps) => props.show ? "1" : "0" };
    position: absolute;
    transition: all 0.5s;
    padding: 0;
    z-index: 10;
`
const Title = styled.div`
    font-size: 21px;
    color: #07A3C8;
    width: 100%;
    text-align: center;
    padding: 1rem;
    margin: 1rem;
    margin-top: 3rem;
`
const Text = styled.div`
    font-size: 17px;
    font-weight: 400;
    text-align: center;
    width: 100%;
    padding: 0px 2rem;
    margin: 2rem 0px;
    color: #585858;
    margin-bottom: 0px;
`
const Link = styled.div`
    width: 100%;
    text-align: center;
    color: #07A3C8;
    padding: 1rem 0rem;
    font-size: 14px;
    cursor: pointer;
`
const FlexV = styled.div`
    display: flex;
    flex-direction: column;
    margin: 5rem auto;
`
interface Props {
    mobile?: boolean;
}
const PopupNoIdentity: React.FC<Props> = ({mobile}) => {
    const [show, setShow] = useState(false)
    setTimeout(() => {
        setShow(true);
    }, 200);
    return (
        <div style={{zIndex: 100}}>
            <Overlay show={show} />
            {!mobile &&
                <Container show={show} style={{width: "400px", height: "536px", margin: "auto"}}>
                    <Cross style={{position: "absolute", top: "15px", right: "15px"}} onClick={() => go.home()}/>
                    <Title>Identity Verification</Title>
                    <Warning style={{margin: "0px auto"}} />
                    <Text>Sorry! You can't view this section until you complete your identity verification first.</Text>
                    <FlexV>
                        <Button style={{fontSize: "14px"}} text={"Start Identity Verification"} onClick={() => go.identity()} />
                        <Link onClick={() => go.home()}>Cancel</Link>
                    </FlexV>
                </Container>
            }
            {mobile &&
                <Container show={show} style={isDesktop() ? {width: "400px", height: "536px", margin: "auto"} : {margin: "2rem", top: "3rem"}}>
                    <Cross style={{position: "absolute", top: "15px", right: "15px"}} onClick={() => go.home()}/>
                    <Title style={isDesktop() ? {margin: "2rem"} : {margin: "auto"}}>Email/Mobile Verification</Title>
                    <Warning style={{margin: "0px auto"}} />
                    <Text>Sorry! You can't view this section until you complete your mobile/email verification first.</Text>
                    <FlexV style={isDesktop() ? {} : {margin: "2rem auto"} }>
                        <Button style={{fontSize: "14px"}} text={"Start Mobile/Email Verification"} onClick={go.userAccount} />
                        <Link onClick={() => go.home()}>Cancel</Link>
                    </FlexV>
                </Container>
            }
        </div>
    )
}

export default PopupNoIdentity;