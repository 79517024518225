import { put, takeEvery } from 'redux-saga/effects';

// eslint-disable-next-line max-len
import { actionTypes as profileActionTypes } from '../../../components/UIContainer/MobileUI/MainShell/actions/profileActions';
import { actionTypes as codeConfirmationActionTypes } from '../actions/codeConfirmationActions';
import { actionTypes as patientDetailsActionTypes } from '../../PatientDetails/actions/actions';
import { actionTypes as guardianActionTypes } from '../../PatientDetails/actions/guardianActions';
import { actionTypes as registerActionTypes } from '../../GuestRegister/actions/registerUserCodeActions';
import { actions } from '../actions/countdownActions';

function* setCountdownTimer() {
  try {
    yield put(actions.setCountdownTimer());
    // eslint-disable-next-line no-empty
  } catch (err) {}
}

function* countdownSaga() {
  yield takeEvery(
    [
      codeConfirmationActionTypes.sendToConfirm,
      patientDetailsActionTypes.changePhone,
      registerActionTypes.sendCodeToVerify,
      profileActionTypes.changePhone,
      guardianActionTypes.changePhone,
    ],
    setCountdownTimer,
  );
}

export { countdownSaga };
