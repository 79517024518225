import 'react';

const base64FromUri = (uri?: string): Promise<string> => 
    new Promise(resolved => {
        if(uri) {
            let img = new Image();
            img.onload = () => {
                let canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                let ctx = canvas.getContext('2d');
                ctx!.drawImage(img, 0, 0, canvas.width, canvas.height);
                resolved(canvas.toDataURL());
            }
            img.src = uri;
        }
    });

export {
  base64FromUri
}