import { compose, isEqual, keys, sortBy } from 'lodash/fp';
import queryString from 'query-string';

const V1_KEYS = ['token', 'expiryTime'];
const V2_KEYS = ['token', 'expiryTime', 'email'];

const isVersion = versionNumberKeys => loginInfo =>
  compose(
    isEqual(sortBy(e => e, versionNumberKeys)),
    sortBy(e => e),
    keys,
  )(loginInfo);

const isV1 = isVersion(V1_KEYS);
const isV2 = isVersion(V2_KEYS);

const getSmokingRecallIdFromURL = () => {
  const { search } = window.location;
  if (!search) {
    return {
      smokingRecallId: '',
      smokingRecallQueryString: '',
      smokingPostBody: {},
    };
  }

  let smokingRecallId = '';

  const queryStringObj = queryString.parse(search);

  if ('smokingRecallId' in queryStringObj) {
    smokingRecallId = queryStringObj.smokingRecallId;
  }

  return {
    smokingRecallId,
    smokingRecallQueryString: smokingRecallId
      ? '?smokingCessationAppointment=true'
      : '',
  };
};

export { isV1, isV2, getSmokingRecallIdFromURL };
