import { put, takeEvery, take } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';

import {
  actions as loginActions,
  actionTypes as loginActionTypes,
} from '../../../EntryPage/actions/actions';

function* expiredSession({ payload: { password, email } }) {
  try {
    yield put(loginActions.login(email, password));
    yield take(loginActionTypes.loginSuccess);
    yield put(actions.expiredSessionSuccess());
    Location.reload();
  } catch (err) {
    yield put(actions.expiredSessionFailure());
  }
}

function* expiredSessionSaga() {
  yield takeEvery(actionTypes.refreshSession, expiredSession);
}

export { expiredSessionSaga };
