import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './sliderTab.module.css';

// stateless shared can't user refs
// eslint-disable-next-line react/prefer-stateless-function
class SliderItem extends Component {
  static propTypes = {
    tab: PropTypes.shape({
      text: PropTypes.string.isRequired,
      component: PropTypes.oneOfType([
        PropTypes.func.isRequired,
        PropTypes.object.isRequired,
      ]),
    }).isRequired,
    selectedTab: PropTypes.shape({
      text: PropTypes.string.isRequired,
      component: PropTypes.oneOfType([
        PropTypes.func.isRequired,
        PropTypes.object.isRequired,
      ]),
    }).isRequired,
    onClick: PropTypes.func.isRequired,
  };
  render() {
    return (
      <button
        className={classnames(styles.item, {
          [styles.active]: this.props.selectedTab.text === this.props.tab.text,
        })}
        onClick={this.props.onClick}
      >
        {this.props.tab.text}
      </button>
    );
  }
}

export default SliderItem;
