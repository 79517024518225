import React, { useEffect } from 'react';
import UIContainer from '../../../components/UIContainer';
import { observer } from 'mobx-react-lite';
import { Box } from '../../../components/vensaComponents';
import { useParams, useLocation } from 'react-router';
import { usePaymentsStore } from '../../../store/mobx/mobx';
import { getDateText } from '../TransactionHistoryPage/HistoryListItem';
import { DoctorNote } from './components/DoctorNote';
import { Status } from './components/StatusText';
import { NameSection } from './components/NameSection';
import { MedicineList } from './components/MedicineList';
import { PickUpLocation } from './components/PickUpLocation';
import { PaymentDetails } from './components/PaymentDetails';
import { LOADING_STATUS } from '../../../libs/newHttp';
import { HealthTransactionItemType } from '../../../store/mobx/paymentStore/paymentStore.type';
import { Redirect } from 'react-router-dom';

const TransactionItemPage: React.FC = observer(() => {
  const store = usePaymentsStore();
  const params = useParams<{ transactionId?: string } | undefined>();
  const { search } = useLocation();
  const {
    healthTransaction,
    healthTransactionLoadingStatus,
  } = store;

  if (!(params && params.transactionId) || !search) {
    return <Redirect to={'/payments'} />;
  }

  const type = (search.replace(
    '?type=',
    '',
  ) as unknown) as HealthTransactionItemType;

  useEffect(() => {
    store.getTransactionItem(params!.transactionId!, type);
  }, [store.getTransactionItem, params, type, store]);

  const Item = healthTransaction;

  return (
    <UIContainer
      heading={`Transaction on ${getDateText(Item.DateTime)}`}
      isFetching={false}
    >
      <div>
        {healthTransactionLoadingStatus === LOADING_STATUS.LOADED ? (
          <Box
            direction="column"
            bg="white"
            borderRadius={0}
            boxShadow={7}
            p={3}
            justifyContent="start"
            gap="18px"
            mb="15px"
          >
            <Status Status={Item.Status} />
            <DoctorNote
              DoctorNote={Item.DoctorNote}
              OrganisationName={Item.OrganisationName}
              TextSentAt={Item.TextSentAt}
              TextSentTo={Item.TextSentTo}
            />
            <NameSection
              FirstName={Item.FirstName}
              LastName={Item.LastName}
              OrganisationName={Item.OrganisationName}
            />
            {Item.Medications && (
              <MedicineList Medications={Item.Medications} />
            )}
            {(Item.LocationName || Item.LocationAddress) && (
              <PickUpLocation
                LocationName={Item.LocationName}
                LocationAddress={Item.LocationAddress}
              />
            )}
            <PaymentDetails Invoice={Item.Invoice} PayOnline={Item.PayOnline} />
          </Box>
        ) : null}
      </div>
    </UIContainer>
  );
});

export default TransactionItemPage;
