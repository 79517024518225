import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import PaymentMethodCard from '../../../components/DesktopComponents/PaymentMethodCard';
import AddPaymentMethod from './AddPaymentMethod';
import { usePaymentsStore } from '../../../store/mobx/mobx';
import emptyBox from 'images/emptyBox.png';
import alert from 'libs/Alert';
import LoadingSpinner from 'components/shared/common/LoadingSpinner';
import { LOADING_STATUS } from 'libs/newHttp';

const OuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
    overflow-y: hidden;
    overflow-x: hidden;
    width: 100%;
`
const Container = styled.div`
    width: 670px;
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
    overflow-x: hidden;
`
const Title = styled.div`
    font-size: 13px;
    font-weight: 480;
    margin-top: 1rem;
    margin-left: 1.2rem;
`
const MethodContainer = styled.div`
    width: 85%;
    height: auto;
    margin: 1rem;
`
const Button = styled.div`
    background-color: #07A3C8;
    color: white;
    font-size: 13px;
    width: fit-content;
    padding: 1rem 2rem;
    border-radius: 28px;
    cursor: pointer;
    position: fixed;
    right: 70px; 
`
const EmptyContainer = styled.div`
    width: 300px;
    margin: auto;
    margin-top: 7rem;
    margin-bottom: 0;
`
const EmptyText = styled.div`
    text-align: center;
    font-size: 17px;
    color: #939393;
    font-weight: 300;
`
const EmptyBox = styled.img`
    width: 300px;
    height: auto;
    margin-bottom: 1rem;
`

const PaymentMethod: React.FC<{ title: string, section: number, changeSection: (id: number) => void }> = observer(({ title, section, changeSection }) => {
    const store = usePaymentsStore();
    const [menus, setMenus] = useState(store.cards.map(_ => false))
    const [needReload, setNeedReload] = useState(false)

    useEffect(() => {
        store.getPaymentSummary();
    }, [store]);

    useEffect(() => {
        if (needReload){
            store.paymentSummaryLoadingStatus = LOADING_STATUS.INITIAL;
            store.getPaymentSummary();
            setNeedReload(false);
        }
    }, [needReload, store]);

    const openMenu = (id: number) => {
        let temp = store.cards.map(_ => false);
        if (id >= 0 && id < temp.length) {
            temp[id] = true;
            setMenus(temp);
            return;
        }
    }

    const closeMenu = () => {
        if (menus.find(x => x === true)) {
            setMenus(store.cards.map(_ => false));
        }
    }

    const onClickAddPaymentMethod = () => {
        if (store.cards?.length >= 10){
            alert.error("We're sorry, you can't add more than 10 payment methods. Remove an existing payment method to proceed.");
            return;
        }
        changeSection(1);
    }

    const cardSorting = (a, b) => {
        return (b.ExpYear - a.ExpYear) || (b.ExpMonth - a.ExpMonth) || (parseInt(b.Last4) - parseInt(a.Last4))
    }

    return (
        <OuterContainer onClick={() => closeMenu()}>
            {section === 0 &&<Button onClick={() => onClickAddPaymentMethod()}>Add Payment Method</Button>}
            {(store.paymentSummaryLoadingStatus === LOADING_STATUS.LOADING) && <LoadingSpinner animateStyle={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}/>}
            <Container>
                <Title>{title}</Title>
                {section === 0 &&
                    <MethodContainer style={{marginBottom: store.cards?.length > 0 ? '100px' : '0px'}}>
                        {store.cards?.length > 0 && store.cards?.slice().sort(cardSorting).map((card, id) =>
                            <PaymentMethodCard
                                key={card.Token}
                                brand={card.Brand}
                                number={`**** **** **** ${card.Last4}`}
                                cardType={card.Brand}
                                expMonth={card.ExpMonth}
                                expYear={card.ExpYear}
                                isExpired={card.IsExpired}
                                isDefault={card.IsDefault}
                                onClick={() => closeMenu()}
                                menuOpen={menus[id]}
                                toggleMenu={() => openMenu(id)}
                                onClickDeleteCard={
                                    async ()=> await store.deleteCard(card.Token)
                                        .then(async () => await store.getPaymentSummary())
                                        .then(() => alert.success("Card deleted successfully"))
                                }
                                onClickDefaultCard={
                                    async ()=> await store.setDefaultCard(card.Token)
                                        .then(async () => await store.getPaymentSummary())
                                        .then(() => alert.success("Default card updated successfully"))
                                }
                            />
                        )}
                        {(!store.cards || store.cards?.length === 0) &&
                            <EmptyContainer>
                                <EmptyBox src={emptyBox} />
                                <EmptyText key={0}>
                                    You don't have any saved payment methods yet.
                                    <br />
                                    <br />
                                    Click the "Add Payment Method" button to add a new payment method.
                                    <br />
                                </EmptyText>
                            </EmptyContainer>}
                    </MethodContainer>
                }
                {section === 1 && 
                    <AddPaymentMethod 
                        onClickNext={() => { changeSection(0); setNeedReload(true); alert.success("Card added successfully"); }}
                        onError={() => alert.error("Card could not be added to your wallet. Please try again.")}
                    />
                }
            </Container>
        </OuterContainer>
    );
})

export default PaymentMethod;