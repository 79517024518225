import React from 'react';
import classnames from 'classnames';

import styles from './mainInfo.module.css';
import { fullScriptPropType } from '../../propTypes';
import Loading from 'components/loadingSpinner';
import { ReactComponent as NotesIcon } from 'svgs/greyPencil.svg';

const formatPrice = price => {
  if (!price) return '$ 0';
  const p = Math.abs(price).toString();
  const dollars = p.slice(0, -2);
  const cents = p.slice(-2);
  return `$ ${dollars}.${cents}`;
};

const MainInfo = ({ script }) => (
  <div className={classnames(styles.container, styles.openContainer)}>
    {script.Invoice && (
      <div>
        {script.PatientNote &&
          <div className={styles.section}>
            <NotesIcon className={styles.patientNotesIcon}/>
            <div className={styles.sectionTitle}>
              Patient Note
            </div>
            <div>{script.PatientNote}</div>
          </div>
        }
        {script ?
        <div className={styles.section}>
          <div className={styles.sectionTitle}>
            Pick up location
            {script.LocationAddress && (
              <a
                className={styles.directions}
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.google.co.nz/maps/place/${
                  script.LocationAddress
                }`}
              >
                Get Directions
              </a>
            )}
          </div>
          <div className={styles.placeName}>{script.LocationName}</div>
          <div className={styles.placeAddress}>{script.LocationAddress}</div>
        </div>
        :
        <Loading isFetching={true} />
        }
        <div className={styles.section}>
          <div className={styles.paymentItems}>
            <div className={styles.paymentHeading}>Payment details:</div>
            {script.Invoice.Items.map(
              item =>
                item.Amount && (
                  <div
                    className={classnames(styles.itemContainer, {
                      [styles.refund]: Math.sign(item.Amount) === -1,
                    })}
                    key={item.Name}
                  >
                    <div className={styles.name}>{item.Name}</div>
                    <div className={styles.price}>{`${
                      Math.sign(item.Amount) === -1 ? '-' : ''
                    } ${formatPrice(item.Amount)}`}</div>
                  </div>
                ),
            )}
          </div>
          <div className={styles.totalPayment}>
            <div>Total</div>
            <div>
              {script.StatusColour === 'Red'
                ? '$ 0.00'
                : `${formatPrice(script.Invoice.Total)}`}
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
);

MainInfo.propTypes = {
  script: fullScriptPropType,
};

export default MainInfo;
