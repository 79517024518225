import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import MedicationList from '../SearchMeds/index';
import SelectedMeds from '../SelectedMeds/index';
import Note from '../../AddNote/index';
import NavigationBar from '../../../RequestScriptDesktop/NavigationBar/index';
import CtaButton from 'components/CtaButton/index';
import { actions } from '../../../actions/actions';
import { medicationPropType } from 'vScript/propTypes';
import styles from './medicationsDesktop.module.css';

class MedicationsDesktop extends Component {
  static propTypes = {
    goBack: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    previousMeds: PropTypes.arrayOf(medicationPropType),
    userId: PropTypes.string.isRequired,
    patientId: PropTypes.string.isRequired,
    orgId: PropTypes.string.isRequired,
    checkAbleToRequest: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    setInfo: PropTypes.func.isRequired,
    note: PropTypes.string,
  };
  state = {
    selected: this.props.previousMeds && this.props.previousMeds,
    showNote: !!this.props.note,
    note: this.props.note || '',
  };
  changeNote = e => {
    this.setState({ note: e.target.value });
  };
  openNote = () => {
    this.setState({ showNote: true });
  };
  addMed = med => {
    const newState = [...this.state.selected];
    newState.push(med);
    this.setState({ selected: newState });
  };
  removeMed = med => {
    const index = this.state.selected.findIndex(e => med.Id === e.Id);
    const newState = [...this.state.selected];
    newState.splice(index, 1);
    this.setState({ selected: newState });
  };
  submit = () => {
    const { userId, orgId, patientId, token } = this.props;
    //submits chosen medications if user is able to request and meds isn't empty
    if (this.state.selected && this.state.selected[0]) {
      if (userId !== patientId) {
        this.props.checkAbleToRequest(orgId, userId, token, patientId);
      } else this.props.checkAbleToRequest(orgId, userId, token);
      if (this.state.note.length) this.props.setInfo('reason', this.state.note);
      this.props.onClick(this.state.selected);
    }
  };
  render() {
    const { selected, showNote } = this.state;
    return (
      <div className={styles.container}>
        <NavigationBar goBack={this.props.goBack} close={this.props.close} />
        <div className={styles.title}>Which Medication/Item?</div>
        <MedicationList add={this.addMed} first={!selected[0]} />
        <div className={styles.content}>
          {selected[0] && (
            <SelectedMeds meds={selected} remove={this.removeMed} />
          )}
          {showNote && (
            <Note addNote={this.changeNote} note={this.props.note} />
          )}
        </div>
        <div className={styles.buttons}>
          {showNote ? (
            <div className={styles.note}>
              Note that practice staff can view this information
            </div>
          ) : (
            <button className={styles.addNote} onClick={this.openNote}>
              {selected[0] ? '+ Add a note to your doctor' : ''}
            </button>
          )}
          <CtaButton
            className={styles.submit}
            active={!!selected[0]}
            onClick={this.submit}
          />
        </div>
      </div>
    );
  }
}
/* istanbul ignore next */
const mapStateToProps = state => ({
  note: state.scriptDetailsReducer.reason,
  previousMeds: state.scriptDetailsReducer.medications,
  patientId: state.scriptDetailsReducer.patient.Id,
  orgId: state.scriptDetailsReducer.practice.Id,
  userId: state.profileReducer.profile.Id,
  token: state.loginReducer.token,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  checkAbleToRequest: bindActionCreators(actions.ableToRequest, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MedicationsDesktop);
