import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { ILabResult } from '../../../../store/mobx/myHealthStore/myHealthStore.type';
import BottleSVG from '../../../../svgs/bottle.svg';
import { BaseListItem } from '../../../../MyHealthPage/components/BaseListItem';
import { getPHRDate } from '../../../../libs/newHttp';
import { go } from '../../../../routes';
import { actions } from 'components/UIContainer/MobileUI/MainShell/actions/actions';
import { useDispatch } from 'react-redux';

export const LabResultsItem: React.FC<
  ILabResult & { IsNewInPastMonth?: boolean }
> = observer(
  ({
    Id,
    IsNewInPastMonth,
    GivenName,
    FamilyName,
    OrganisationName,
    ProviderName,
    NameOfTest,
    DateTime,
  }) => {
    const dispatch = useDispatch();
    const onClick = useCallback(() => {
      dispatch((
        actions.setWhiteBoxNotification({
          isOpen: false,
        })
      ));
      go.goLabResult(Id);
    }, [Id]);

    return (
      <BaseListItem
        isNew={IsNewInPastMonth}
        textWhenNew="New in last month"
        icon={BottleSVG}
        leftFirstLine={NameOfTest}
        leftSecondLine={"Requested by: " + ProviderName}
        leftThirdLine={OrganisationName}
        rightFirstLine={getPHRDate(DateTime)}
        onClick={onClick}
      />
    );
  },
);
