import { Wizard } from 'components/core/Wizard';
import { withDesktop } from 'hocs/withDesktop';
import React from 'react';
import { go } from 'routes/sub/dependants';
import { DependantContainer } from 'types/careCircle';
import { CareCircleStoreProvider } from './context';

const facade = (touch: boolean, title: string, values?: Record<string, any>) => {
    const Page = touch ? Wizard : withDesktop(Wizard);
    const WizardPage = ({children, dependant}: DependantContainer) => 
        (
            <CareCircleStoreProvider dependant={dependant}>
                <Page 
                    close={go.dependants}
                    complete={go.dependants}
                    initialValues={values}
                    crumbs={[
                        {name: "My Care Circle", path: "/careCircle"},
                        {name: title}
                    ]} 
                    idKey="Care Circle"
                    nested>
                        {children}
                </Page>
            </CareCircleStoreProvider>
        );
    return WizardPage;
}
export default facade;