const actionTypes = {
    expressInterest: 'EXPRESS_INTEREST',
    sendExpressionOfInterest: 'EXPRESSION_OF_INTEREST',
    expressionOfInterestSuccess: 'EXPRESSION_OF_INTEREST_SUCCESS',
    expressionOfInterestFailure: 'EXPRESSION_OF_INTEREST_FAILURE',
  };
  
  const expressInterest = (patientId) => ({
    type: actionTypes.expressInterest,
    payload: {
      patientId
    }
  });
  const sendExpressionOfInterest = (expression) => ({
    type: actionTypes.sendExpressionOfInterest,
    payload: expression,
  });
  const expressionOfInterestSuccess = () => ({
    type: actionTypes.expressionOfInterestSuccess,
  });
  const expressionOfInterestFailure = error => ({
    type: actionTypes.expressionOfInterestFailure,
    error: true,
    payload: {
      error,
    },
  });
  
  const actions = {
    expressInterest,
    sendExpressionOfInterest,
    expressionOfInterestSuccess,
    expressionOfInterestFailure,
  };
  
  export { actionTypes, actions };
  