// @ts-ignore
import queryString from 'query-string';
import { useEffect } from 'react';
import * as CSS from 'csstype';
import axios, { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import { isObservableArray, runInAction, action, IObservableArray } from 'mobx';
import Alert from 'react-s-alert';
import { getURLWithDomain } from './corsAPI.constants';
import { EffectCallback } from 'react';
import moment from 'moment';

export type LoadingStatusType = 'INITIAL' | 'LOADING' | 'LOADED' | 'FAILED';

export const LOADING_STATUS: {
  INITIAL: LoadingStatusType;
  LOADING: LoadingStatusType;
  LOADED: LoadingStatusType;
  FAILED: LoadingStatusType;
} = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  FAILED: 'FAILED',
};

export type Nullable<T> = T | null;

export const HTTP = {
  GET: 'get',
  DELETE: 'delete',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
};

export type TAPIFunc = () => AxiosPromise;
export type TEmptyFunc = () => void;
export type TAfterLoaded = (data: AxiosResponse['data']) => void;
export type TAfterFailed = (err: AxiosError) => void;

export interface PlainObject {
  [propertyName: string]: any;
}

export const getAuthInfo = () => {
  return (
    window.localStorage.getItem('token') ||
    '{"token": "","expiryTime": "","email": ""}'
  );
};

export const getToken = () => {
  const auth = getAuthInfo();
  return JSON.parse(auth).token;
};

export const getTokenObj = () => {
  const auth = getAuthInfo();
  return JSON.parse(auth);
}

export const request = ({
  method,
  url,
  urlParams,
  data,
  headers,
  file,
  noAPIPrefix = false,
}: {
  method: string;
  url: string;
  urlParams?: PlainObject;
  data?: PlainObject | string | number;
  headers?: PlainObject;
  file?: string | Blob;
  noAPIPrefix?: boolean;
}) => {
  const path = `${noAPIPrefix ? '' : '/api/'}${url}`;

  const config: PlainObject = {
    method,
    url: getURLWithDomain(path),
    headers: {
      Authorization: `Bearer ${getToken()}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  if (headers) {
    config.headers = {
      ...(config.headers as object),
      ...headers,
    };
  }

  if (urlParams) config.params = urlParams;
  if (data) config.data = data;

  if (file) {
    const formData = new FormData();
    formData.append('avatar', file);
    config.data = formData;
    config.headers['Content-Type'] = 'multipart/form-data';
  }

  return axios(config);
};

export const formRequest = ({
  url,
  data,
  headers,
  noAPIPrefix = true,
}: {
  url: string;
  data?: PlainObject;
  headers?: PlainObject;
  noAPIPrefix?: boolean;
}) => {
  const config: PlainObject = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  const targetUrl = `${noAPIPrefix ? '' : '/api'}${url}`;

  if (headers) {
    config.headers = {
      ...(config.headers as object),
      ...headers,
    };
  }

  return axios.post(targetUrl, queryString.stringify(data), config);
};

type NotExist = null | undefined;

export const is = {
  initial: (status: string) => status.endsWith(LOADING_STATUS.INITIAL),
  loading: (status: string) => status.endsWith(LOADING_STATUS.LOADING),
  loaded: (status: string) => status.endsWith(LOADING_STATUS.LOADED),
  failed: (status: string) => status.endsWith(LOADING_STATUS.FAILED),

  // tslint:disable-next-line:ban-types
  function: (toCheck: unknown): toCheck is Function =>
    typeof toCheck === 'function',
  object: (toCheck: unknown): toCheck is object => typeof toCheck === 'object',
  string: (toCheck: unknown): toCheck is string => typeof toCheck === 'string',
  number: (toCheck: unknown): toCheck is number => typeof toCheck === 'number',
  boolean: (toCheck: unknown): toCheck is boolean =>
    typeof toCheck === 'boolean',
  notExist: (toCheck: unknown): toCheck is NotExist =>
    toCheck === null || toCheck === undefined,
};

export function useValueOrDefault<T>(
  value: T | undefined | null,
  defaultValue: T,
): T {
  return is.notExist(value) ? defaultValue : value;
}

const tryExtractErrorMessage = (
  err: AxiosError,
  propertyName: string,
): string => {
  let result =
    'Something wrong happened, please refresh and try again or contact us.';
  if (
    err.response &&
    err.response.data &&
    is.string(err.response.data[propertyName])
  ) {
    result = err.response.data[propertyName];
  }
  return result;
};

export const handleError = (err: AxiosError) => {
  if (err.response && err.response.status === 401) {
    Alert.error(
      'You are not authorised to perform this action. Please contact your administrator or logout and re-login.',
    );
  } else {
    let errMessage =
      'Something wrong happened, please refresh and try again or contact us.';

    errMessage = tryExtractErrorMessage(err, 'Message');
    errMessage = tryExtractErrorMessage(err, 'error_description');

    //Alert.error(errMessage);
  }
};

const atomicUpdate = (obj: object, path: string, value: unknown) => {
  // @ts-ignore
  if (isObservableArray(obj[path])) {
    // @ts-ignore
    (obj[path] as IObservableArray).replace(value as any[]);
  } else {
    // @ts-ignore
    obj[path] = value;
  }
  //debugger;
};

function setNestedKey(object: object, path: string, newValue: unknown) {
  const stack = path.split('.');

  while (stack.length > 1) {
    // @ts-ignore
    object = object[stack.shift()];
    //debugger;
  }

  // @ts-ignore
  atomicUpdate(object, stack.shift(), newValue);
}

const updateNested = (obj: object, path: string, value: unknown) => {
  //debugger;
  if (path.includes('.')) {
    setNestedKey(obj, path, value);
  } else {
    atomicUpdate(obj, path, value);
  }
};

/*
 * afterBlahBlah: will hand over the steps to the params entirely
 */
export function generalRequest(
  this: PlainObject,
  {
    api,
    loadingStatusName = 'loadingStatus',
    dataPropertyName = 'data',
    afterLoading,
    afterLoaded,
    afterFailed,
    alertMsgAfterLoaded,
  }: {
    api: TAPIFunc;
    loadingStatusName?: string;
    dataPropertyName?: string;
    afterLoading?: TEmptyFunc;
    afterLoaded?: TAfterLoaded;
    afterFailed?: TAfterFailed;
    alertMsgAfterLoaded?: string;
  },
) {
  runInAction(() => {
    updateNested(this, loadingStatusName, LOADING_STATUS.LOADING);
    //debugger;
    if (is.function(afterLoading)) {
      afterLoading();
    }
  });

  const getFailedAction = (actionName: string) =>
    action(actionName, (err: AxiosError) => {
      updateNested(this, loadingStatusName, LOADING_STATUS.FAILED);

      if (is.function(afterFailed)) {
        return afterFailed(err);
      } else {
        return handleError(err);
      }
    });

  return api()
    .then(
      action('HTTP -> ok', (response: AxiosResponse) => {
        let data;
        if (response) {
          data = response.data;
        }

        return data;
      }),
    )
    .then(
      action(`HTTP.response -to-> ${dataPropertyName}`, async data => {
        if (alertMsgAfterLoaded) {
          Alert.success(alertMsgAfterLoaded);
        }

        if (is.function(afterLoaded)) {
          await afterLoaded(data);
        } else {
          if (dataPropertyName && data) {
            updateNested(this, dataPropertyName, data);
          }
        }
      }),
    )
    .then(
      action(`HTTP -> ${loadingStatusName} -to-> LOADED`, () => {
        updateNested(this, loadingStatusName, LOADING_STATUS.LOADED);
      }),
    )
    .catch(getFailedAction('HTTP -> data error'));
}

export async function generalRequestWithoutLoading({
  api,
  afterLoaded,
  afterFailed,
}: {
  api: TAPIFunc;
  afterLoaded?: TAfterLoaded;
  afterFailed?: (err: AxiosError) => void;
}) {
  try {
    const { data } = await api();
    runInAction(() => {
      if (is.function(afterLoaded)) {
        afterLoaded(data);
      }
    });
  } catch (err) {
    runInAction(() => {
      if (is.function(afterFailed)) {
        afterFailed(err as AxiosError);
      } else {
        handleError(err as AxiosError);
      }
    });
  }
}

type ConvertValue = ((value: any) => string) | undefined;

type TGetPropFunc = (props: any) => string;

type TCSSKey = keyof CSS.PropertiesHyphen | keyof CSS.PropertiesHyphenFallback;

export function toggleStyle<T>(
  cssName: TCSSKey,
  pathName: keyof T,
  convertValue?: ConvertValue,
): TGetPropFunc {
  return (props: T) => {
    const value = props[pathName];
    if (!value) return '';

    const result = is.function(convertValue)
      ? ((convertValue(value) as unknown) as T[keyof T])
      : value;

    return `${cssName}: ${result}`;
  };
}

export function checkEmail(email: string): boolean {
  // eslint-disable-next-line
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function useComponentDidMount(
  callback: EffectCallback | (() => Promise<void>),
) {
  // @ts-ignore
  useEffect(() => {
    (async () => {
      await callback();
    })();
    // eslint-disable-next-line
  }, []);
}

export const getPHRDate = (utcDateString: string) =>
  moment(utcDateString).format('DD/MM/YY');
