import { HttpStatusCode, PatientStatus } from 'common/constants';
import 'react';
import { normalizeDate } from 'services/Format/formatDate';
import service from 'store/carecircle/service';
import { CarecircleStore, Dependant } from 'types/careCircle';
import { CareCircleStore } from 'store/carecircle';

const textStore = (isEditing: boolean, status: number) => isEditing ? 
    status === 3 ? 
    {touchText: 'Resubmit', desktopText: 'Resubmit', anywayText: 'Resubmit anyway'} :
    {touchText: 'Save Changes', desktopText: 'Save', anywayText: 'Save anyway'} : 
    {touchText: '+ Add to my Circle', desktopText: 'Add', anywayText: 'Add anyway'};

const downloadAgent = new CareCircleStore(service);

export const createStore = () => ({
    label: {Status: PatientStatus.NONE },
    get canNext(){
        return this.checkStatus === HttpStatusCode.OK || this.checkStatus === HttpStatusCode.PARTIAL_CONTENT;
    },
    get readonly(){
        
        if(this.canEdit === undefined) {    // adding
            if(this.checkStatus == undefined){
                return false;
            }else{
                return this.checkStatus !== -1;
            }
        }else {                             // editing
            if(this.checkStatus == undefined) { 
                return !this.canEdit;       
            }else { // Check for PARTIAL_CONTENT
                return this.canEdit && (this.checkStatus !== -1);
            }
        }
    },

    updateDependant(dependant?: Dependant) {
        this.registration = {
            dependantId: dependant?.Id,
            firstName: dependant?.FirstName ?? '',
            lastName: dependant?.LastName ?? '',
            dateOfBirth: normalizeDate(dependant?.DateOfBirth)
        };
        this.label = dependant?.IdentityStatus ?? this.label;
        this.canEdit = dependant?.CanEdit;
        if(dependant){ // editing dependant
            this.getDeclareDate(dependant.Id);
            this.uploadAvatar = async(avatar: File) => {
                service.updateAvatar(dependant.Id, avatar);
            } 
        }
        
        // this.nextText = (touch: boolean, reset?: boolean) => generateNextText(touch, touchText, desktopText, this.checkStatus, reset);
        this.textSet = (touch: boolean) => {
            const {touchText, desktopText, anywayText} = textStore(!!dependant?.Id, dependant?.IdentityStatus.Status ?? -1);
            return {checkText: touch ? touchText : desktopText, nextText: anywayText}
        };
        this.downloadDeclarationDocument = async() => {
            if(dependant){
                downloadAgent.downloadDeclarationDocument(dependant.Id);
            }
        }
    },

    async getDeclareDate(dependantId: string){
        this.declareDate = await downloadAgent.getDeclareDate(dependantId);
    },

    async checkPatient(firstName: string, lastName: string, dateOfBirth: string){
        this.registration = {
            ... this.registration,
            firstName,
            lastName,
            dateOfBirth
        };
        const {data, status} = await service.checkPatient(this.registration);
        if(status === HttpStatusCode.OK || status === HttpStatusCode.PARTIAL_CONTENT || status === HttpStatusCode.BAD_REQUEST){
            this.label = data!;
        }
        this.checkStatus = status;
    }
    
    
} as CarecircleStore) ;