import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Text } from 'components/vensaComponents';
import { IHealthTransaction } from '../../../../store/mobx/paymentStore/paymentStore.type';
import { TransactionSection } from './TransactionSection';
import { formatPrice } from 'PaymentsPage/subRoutes/TransactionHistoryPage/HistoryListItem';
import { Line } from 'PaymentsPage/components/Line';

const InvoiceItem: React.FC<{ Name: string; Amount: number }> = observer(
  ({ Name, Amount }) => (
    <Box justifyContent="between" mt="5px">
      <Text>{Name}</Text>
      <Text>{formatPrice(Amount)}</Text>
    </Box>
  ),
);

export const PaymentDetails: React.FC<Pick<
  IHealthTransaction,
  'Invoice' | 'PayOnline'
>> = observer(({ Invoice: { Items, Total }, PayOnline }) => (
  <TransactionSection title="Payment details:">
    <Box direction="column">
      {Items.map(item => (
        <InvoiceItem key={item.Name} {...item} />
      ))}
    </Box>
    <Line borderColor="#FAC052" />
    <InvoiceItem
      Name={PayOnline ? '' : 'Pay at the practice:'}
      Amount={Total}
    />
  </TransactionSection>
));
