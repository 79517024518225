import { HTTP, request } from "libs/newHttp";

export const getReasonsAPI = (orgId: string) => () =>
    request({
        method: HTTP.GET,
        url: `consumer/appointment/${orgId}/reasons-for-visit-2`
    })

export const getConsultMethodsAPI = (orgId: string) => () =>
    request({
        method: HTTP.GET,
        url: `consumer/appointment/${orgId}/consultation-method`
    })