import React, { useCallback, useRef } from 'react';

const acceptArray2String = (acceptType?: Array<string>) => 
    acceptType && acceptType.length > 0 ? 
    acceptType.map((item) => `.${item}`).join(', ') : '*/*';
interface UploadProps {
  id?: string;
  acceptType?: Array<string>;
  onChange: (key: string, value: File) => void;
  onRemove?: (key: string) => void;
  children?: (props: Callback) => React.ReactNode;
}

interface Callback {
  onFileUpload: () => void,
  onFileRemove?: () => void
}

const Upload: React.FC<UploadProps> = (
  {
    id,
    acceptType, 
    children,
    onChange,
    onRemove,
    ...rest
  }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickInput = useCallback(() => {
    inputRef.current && inputRef.current.click();
  }, [inputRef]);

  const onFileUpload = useCallback((): void => {
    handleClickInput();
  },[handleClickInput]);

  const onFileRemove = (): void => {
    onRemove && onRemove(id!);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {

    let files = e.target.files;
    const file = files && files.length == 1 && files[0];
    if(file instanceof File) {
      onChange(e.currentTarget.id, file);
    }
    
    if(inputRef.current) {
      inputRef.current.value = '';
    } 
  }

  const acceptTypeString = acceptArray2String(acceptType);
  return (
    <div {...rest}>
      <input
        id={id}
        key={id} 
        type='file'
        accept={acceptTypeString}
        ref={inputRef}
        style={{display: 'none'}}
        onChange={onInputChange} />
      {
        children?.({
          onFileUpload,
          onFileRemove
        })
      }
    </div>
  )
};

export default Upload;