import { observable, action, makeAutoObservable } from 'mobx';

import { ISecuritySettings, ISecurityToggleSettings } from './securityStore.type';
import { getSecuritySettingsAPI, editSecuritySettingsAPI } from './securityStore.api';
import { generalRequest } from 'libs/newHttp';
import Alert from 'libs/Alert';

export class SecurityStore {
    constructor(){
        makeAutoObservable(this);
    }
    @observable securitySettings: ISecuritySettings = {
        SecurityPinCodeSetup: false,
        SignIn2StepsVerification: false,
        NotifyUserAfter5FailedAttempts: false,
        SuspendAccountAfter10FailedAttempts: false,
        Email: "",
        EmailVerified: false,
        MobileNumber: "",
        MobileVerified: false,
        RecoveryEmail: ""
    }
    @observable loading: boolean = false;

    @action.bound
    async getSecuritySettings() {
        this.loading = true;

        await generalRequest.bind(this)({
            api: getSecuritySettingsAPI,
            afterFailed: () => {
                this.loading = false;
                Alert.error("An unexpected error has occured, please try again later");
            },
            afterLoaded: (data: ISecuritySettings) => {
                this.loading = false;
                this.securitySettings = data;
            }
        })
    }

    @action.bound
    async editSecuritySettings(settings: ISecurityToggleSettings, noAlert?: boolean) {
        this.loading = true;
        await generalRequest.bind(this)({
            api: editSecuritySettingsAPI(settings),
            afterFailed: (err: any) => {
                this.loading = false;
                Alert.error(err.response.data.Message);
            },
            afterLoaded: () => {
                this.loading = false;
                //this.securitySettings = settings;
                this.getSecuritySettings();
                if (!noAlert) {
                    Alert.success("Changes Saved");
                }
            }
        })
    }
}