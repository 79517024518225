import { call, takeEvery, put } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';
import HttpLib from '../../libs/Http';
import { isDesktop } from '../../config';
import { go } from '../../routes';

const ParserHttp = HttpLib(() => {}, () => {});

const api = (orgId, pmsUserId, token) =>
  ParserHttp.get(`consumer/prescription/${orgId}/${pmsUserId}/abletorequest`, {
    token,
  }).then(({ body }) => body);

const dependantApi = (orgId, pmsUserId, token, dependantId) =>
  ParserHttp.get(
    `consumer/prescription/${orgId}/${pmsUserId}/abletorequest/${dependantId}`,
    {
      token,
    },
  ).then(({ body }) => body);

function* ableToRequest({ payload: { orgId, userId, token, dependantId } }) {
  try {
    if (dependantId) {
      yield call(dependantApi, orgId, userId, token, dependantId);
    } else yield call(api, orgId, userId, token);
    yield put(actions.isAbleToRequest());
  } catch (err) {
    yield put(actions.notAbleToRequest(err.response.body));
    if (!isDesktop()) go.errorScript();
  }
}

function* ableToRequestSaga() {
  yield takeEvery(actionTypes.ableToRequest, ableToRequest);
}

export { ableToRequestSaga };
