import React from 'react';
import { observer } from 'mobx-react-lite';

import styles from './ImgTextCard.module.css';
import { ReactComponent as ClickImage } from 'svgs/circleRightArrow.svg';
import Empty from 'svgs/patientSilhouette.svg';
import { useAccountStore } from 'store/mobx/mobx';
import { useComponentDidMount } from 'libs/newHttp';
import { getFinalStatus } from 'Identity/components/IdentityLabel';

export const ImgTextCard = observer(({...props}) => {
	const store = useAccountStore();
  const PropsImage = props.imageComp;
	const crop = props.crop;
  const privacy = props.privacyCard;

  let color = store.profile.IdentityVerified ? "#23C373" : "#FF6A65"
  let status = store.profile.IdentityVerified ? 1 : getFinalStatus(store);
  let bannerColour = status === 0 ? "#FF6A65" : status === 1 ? "#23C373" : "#F5722C";
	
	useComponentDidMount(() => {
    store.getIdentityRequestsStatus();
		// if (props.isUser) store.getProfile();
	})

    return (
        <div className={props.isUser ? styles.containerUser : styles.container} onClick={props.onClick} style={
          {position: "relative", borderTop: privacy ? (props.banner ? "4px solid " +  "#FF6A65" : "none") : (props.banner ? "4px solid " +  bannerColour : "none")}
        }>

          {props.banner && !privacy &&
            <div className={styles.banner} style={{backgroundColor: bannerColour}}>
              {status === 0 ? "Identity Not Verified" : status === 1 ? "Identity Verified" : "Identity Verification Pending"}
            </div>
          }  
          {props.banner && privacy &&
            <div className={styles.banner} style={{backgroundColor: props.bannerColour}}>
              {props.bannerText}
            </div>
          }

            <div className={styles.contentContainer}>
                <div className={styles.verticleContainer}>
                  {!props.isUser ? ( 
                    <div className={styles.verticleContainer}>
                      {typeof(PropsImage) === "string" ? <img src={PropsImage} className={styles.image} alt="img" style={{borderRadius: crop ? "50%" : "0%"}} /> : 
                        PropsImage === null ? <img src={Empty} className={styles.image} alt="img"/> :
                        <PropsImage className={styles.image}  />}
                    </div>
                  ) : (
                    <div className={styles.image} alt="img" style={{borderRadius: "50%", backgroundImage: `url(${props.picUrl})`, backgroundSize: "cover", height: "60px"}} />
                  )}
                </div>
                
                <div className={styles.verticleContainer} style={{width: "100%"}}>
                  <div className={styles.title} style={{fontWeight: privacy ? 600 : 500, width: "100%", marginLeft: props.isUser ? "0px" : "5px"}}>{props.title}</div>
                  {!props.isUser ? (
                    <div className={styles.text}>{props.text}</div>
                  ) : (
                    <div>
                      <div className={styles.userDetails}>{props.mobile}</div>
				  		        <div className={styles.userDetails}>{	store.profile.Email.length > 24 ? store.profile.Email.substring(0, 22) + "..." : store.profile.Email}</div>
                    </div>
                  )}
                </div>
                {props.isUser ? (
                
                  

                <ClickImage className={styles.arrowImage} style={{right: "0px"}} />

                
                   
                ) : (
                  

                    <ClickImage className={styles.arrowImage} onClick={props.onClick} style={{right: "0px"}} /> 
                )}        
            </div>
        </div>
    );
})

export default ImgTextCard;