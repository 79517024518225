import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash/fp';
import classnames from 'classnames';

import { ReactComponent as Arrow } from '../../../svgs/rightarrow2.svg';
import { ReactComponent as Spinner } from '../../../svgs/spinner.svg';
import { isDesktop } from '../../../config';
import NavigationBar from '../../RequestScriptDesktop/NavigationBar/index';
import styles from './list.module.css';
import { capitalise } from 'libs/misc';

const List = ({
  heading,
  options,
  arrowFn,
  isFetching,
  optionOnClick,
  dontShowArrow,
  lastOption,
  lastOptionFn,
  altOptions,
  styledButtons,
  close,
}) => (
  <div
    className={classnames(styles.container, {
      [styles.centeredList]: styledButtons,
    })}
  >
    {isDesktop() ? (
      <NavigationBar goBack={arrowFn} close={close} />
    ) : (
      !dontShowArrow && <Arrow className={styles.backArrow} onClick={arrowFn} />
    )}
    <div
      className={classnames(styles.heading, {
        [styles.smallerHeading]: !styledButtons,
      })}
    >
      {heading}
    </div>
    <div className={styles.optionsContainer}>
      {isFetching && (
        <div className={styles.spinnerContainer}>
          <Spinner className={styles.spinner} />
        </div>
      )}
      {altOptions ||
        map(o => (
          <div
            className={classnames(styles.button, {
              [styles.styledButton]: styledButtons,
            })}
            key={o.Id}
            onClick={() => optionOnClick(o)}
          >
            {heading === "Which doctor?" && o.FirstName ? `${o.FirstName} ${o.LastName}` : o.Name}
            {heading !== "Which doctor?" && o.FirstName ? `${capitalise(o.FirstName)} ${capitalise(o.LastName)}` : ""}
          </div>
        ))(options)}
      {lastOption && (
        <div
          className={classnames(styles.button, styles.lastButton, {
            [styles.styledLastButton]: styledButtons,
          })}
          onClick={lastOptionFn}
        >
          {lastOption}
        </div>
      )}
    </div>
  </div>
);

List.propTypes = {
  heading: PropTypes.string.isRequired,
  arrowFn: PropTypes.func,
  close: PropTypes.func,
  optionOnClick: PropTypes.func,
  dontShowArrow: PropTypes.bool,
  lastOption: PropTypes.string,
  lastOptionFn: PropTypes.func,
  altOptions: PropTypes.node,
  styledButtons: PropTypes.bool,
  isFetching: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      Name: PropTypes.string,
    }),
  ),
};

export default List;
