import { formatName } from 'libs/misc';
import React from 'react';
import { IClinicNote, IImmunisation, ILabResult } from 'store/mobx/myHealthStore/myHealthStore.type';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    width: 100%;
    color: #6C6C6C;
    flex-direction: row;
    align-items: center;
`
const Name = styled.div`
    font-size: 15px;
`
const Practice = styled.div`
    font-size: 13px;
`
const FlexV = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
const Subject = styled.div`
    font-weight: bold;
    font-size: 13px;
    width: 100%;
`
interface IProps {
    item: IClinicNote
    firstName: string,
    lastName: string,
}
const ClinicalNote: React.FC<IProps> = ({ item, firstName, lastName }) => {
    return (
        <Container>
            <FlexV>
                <Name>{formatName(firstName) + ' ' + formatName(lastName)}</Name>
                <Practice>{item.OrganisationName}</Practice>
                <Practice>{item.ProviderName}</Practice>
            </FlexV>
        </Container>
    )   
}

export default ClinicalNote