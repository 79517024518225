import { put, call, takeEvery } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';
import { actions as profileActions } from '../../UIContainer/MobileUI/MainShell/actions/profileActions';
import { GenericParserHttp } from '../../../libs/Http';

const api = token => GenericParserHttp.post('consumer/acceptTerms', { token });

function* acceptTnC({ payload: { token } }) {
  try {
    yield call(api, token);
    yield put(actions.acceptTnCSuccess());
    yield put(profileActions.getProfile(token));
  } catch (err) {
    yield put(actions.acceptTnCFailure(err));
  }
}

function* acceptTnCSaga() {
  yield takeEvery(actionTypes.acceptTnC, acceptTnC);
}

export { acceptTnCSaga };
