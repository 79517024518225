import PropTypes from 'prop-types';

const appointmentPropType = PropTypes.shape({
  Id: PropTypes.string,
  SessionId: PropTypes.string,
  Time: PropTypes.string,
  ProviderName: PropTypes.string,
  ProviderTitle: PropTypes.string,
  PracticeName: PropTypes.string,
  Address: PropTypes.string,
  ProviderPictureUrl: PropTypes.string,
  CalendarUrl: PropTypes.string,
  GoogleCalendarUrl: PropTypes.string,
  PatientPhone: PropTypes.string,
  DateOfBirth: PropTypes.string,
  Phone: PropTypes.string,
  FirstName: PropTypes.string,
  LastName: PropTypes.string,
  Status: PropTypes.string,
  Note: PropTypes.string,
  DependantId: PropTypes.string,
  CreatedAt: PropTypes.string,
});

const CanlendarPropType = PropTypes.shape({
  CalendarUrl: PropTypes.string,
  GoogleCalendarUrl: PropTypes.string,
});

export { appointmentPropType,  CanlendarPropType};
