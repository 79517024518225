import React from 'react';
import styled from 'styled-components'
import pic from 'svgs/careCircle.svg';
import DesktopCta from 'components/DesktopCta';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 500px;
    margin: 1rem auto;
    align-items: center;
`
const Image = styled.img`
    width: 48px;
    height: 40px;
    margin 1rem 0rem;
`
const Title = styled.div`
    color: #585858;
    font-size: 17px
    text-align: center;
    margin-bottom: 1.5rem;
    font-weight: bold;
`
const Text = styled.div`
    color: #585858;
    font-size: 13px;
    text-align: center;
    margin-bottom: 1rem;
    line-height: 1.5rem;
`
const Link = styled.div`
    color: #07A3C8;
    cursor: pointer;
    text-align: center;
    font-size: 13px;
    margin-bottom: 6rem;
`
interface IProps {
    buttonOnClick: () => void;
    linkOnClick: () => void;
}

const DesktopWelcomeCard: React.FC<IProps> = ({ buttonOnClick, linkOnClick }) => {
    return (
        <Container>
            <Image src={pic} />
            <Title>Welcome to Care Circle</Title>
            <Text>Take the stress out of looking after your loved ones.</Text>
            <Text>Book appointments, request repeat scripts, and heaps more. Simply add family, Whanau, and friends to your Care Circle to get started!</Text>
            <Link onClick={() => linkOnClick()}>Learn More</Link>
            <DesktopCta
              text="+ Add to my Circle"
              onClick={buttonOnClick}
              background={true}
              absolute={false} />
        </Container>
    )
}

export default DesktopWelcomeCard