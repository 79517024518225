import React from 'react';
import { ProtectedRoute, push } from '../utils';
import Messages from '../../MessagesPage';

const go = { messages: () => push('/messages') };

const MessagesRoutes = [
  <ProtectedRoute key="/messages" path="/messages" component={Messages} />,
];

export default MessagesRoutes;
export { go };
