import { observable, action, makeAutoObservable } from 'mobx';
import { IProfile, IPrivacySettingsWithOrg, IIdentityRequestsStatus, ISignUpInvitationDetails, IRegisterWithSignUpToken } from './accountStore.type';
import { generalRequest, getToken, getTokenObj } from '../../../libs/newHttp';
import { getProfileAPI, sendVerifyEmailAPI, checkVerifyEmailAPI, removeRecoveryEmailAPI, getSignUpInvitationDetailsAPI, uploadPicAPI, sendVerifyRecoveryEmailAPI, checkVerifyRecoveryEmailAPI, getPinCodeEnabledAPI, sendAcknowledgementAPI,
    getIdentityRequestsStatusAPI, sendVerifyTextAPI, updateUserPrivacySettingsWithOrgAPI, checkVerifyTextAPI, getUserPrivacySettingsWithOrgAPI, getPinCodeLengthAPI, verifyPinCodeAPI, logoutAPI, registerWithSignUpTokenAPI, editProfileAPI, getRecoveryMethodsAPI, getVerificationCodeSentToRecoveryMethodAPI, postVerificationCodeSentToRecoveryMethodAPI, getRecoveryEmailSentToSuspendedAccountAPI, getRecoverSuspendedAccountVerificationCodeAPI, getRecoveryMethodsForSuspendedAccountAPI, postRecoverAccountCheckActivationCodeAPI, postRecoverAccountChangePasswordAPI, postRecoverAccountChangePinAPI, postRecoverAccountAPI } from './accountStore.api';
import Alert from 'libs/Alert';
import { go } from 'routes';
import moment from 'moment';
import { DEFAULT_GUID } from '../../../common/constants';
    

export class AccountStore {
    constructor(){
        makeAutoObservable(this);
    }
    userAccountIdentityLabelArr: IIdentityRequestsStatus[] = [];
    receivedIDRequests: boolean = false;
    profileErrorText = '';
    loading = false;
    showPopUp = false; // used to indicate show pop up
    pinCodeFlowState = 4; // used to indicated what state of the pin code flow the user is in
    successChecker = false; // Used to indicate if api call was finished
    consumerUrl = "";
    loadingAcknowledgement = false;
    loadingRequests = false;
    identityRequestsStatusComplete = false;
    identityRequests: IIdentityRequestsStatus[] = [];
    mobileVerifyCodeChecked = false;
    pinCodeEnabled = false;
    emailForRecoveringSuspendedAccount = "";
    chosenIdentityRequest: IIdentityRequestsStatus = {
        Address: "",
        AllowResubmit: false,
        BlackListed: false,
        BlackListedReason: "",
        EnrollmentStatusNotMeetPracticePolicy: false,
        ErrorMessage: "",
        IdentityVerified: false,
        IdentityRequest: {
            Status: 0,
            RequestDate: "",
            UpdatedOn: "",
            Id: "",
        },
        IsOnline: false,
        MatchedPatientInPms: false,
        OnlineVerificationEnabled: false,
        OrganisationId: "",
        OrganisationName: "",
        Phone: "",
        PictureUrl: "",
        PracticeVerification: false,
        PracticeVerifiedOn: "",
        AlreadyVerifiedByOtherAccount: false,
        Pictures: []
    } 
    userVerifyCode = "";
    privacySettingsWithOrg: IPrivacySettingsWithOrg = {
        OrganisationId: DEFAULT_GUID,
        OrganisationName: "",
        LogoUrl: "",
        AllowAccessMyHealthRecords: {
          Allow: true,
          Overridden: true,
          Reason: ""
        },
        AllowAccessMyDoctorNotes: {
          Allow: true,
          Overridden: true,
          Reason: ""
        },
        AllowAccessMyDependantHealthRecords: {
          Allow: true,
          Overridden: true,
          Reason: ""
        },
        AllowShareMyHealthDataWithPractices: true,
        AllowAccessAndStoreMedications: {
            Allow: true,
            Overridden: true,
            Reason: ""
          },
    }
    pinCodeLength = 0;
    
    patientVerification = {
        userEnteredCode: ["", ""],
        codeChecked: [false, false],
        loading: false,
        codeMatched: [false, false]
    }
    profile: IProfile = {
        Id: '00000000-0000-0000-0000-000000000000',
        FirstName: "",
        LastName: "",
        PreferredName: "",
        DateOfBirth: "",
        Email: "",
        Mobile: "",
        MobileVerified: false,
        PatientProfiles: {},
        AvatarUrl: "",
        HasPractice: false,
        HasAcceptedTC: false,
        PendingRequest: false,
        HasAcceptedPP: false,
        TermsAndConditionsUrl: "",
        PrivacyPolicyUrl: "",
        IdentityVerified: false,
        VerificationStatus: 2,
        RestrictiveStatus: {
            Summary: [
                {
                    IsAccessRestricted: false,
                    OrganisationName: "",
                    OrganisationId: "",
                }
            ],
            Immunisations: [
                {
                    IsAccessRestricted: false,
                    OrganisationName: "",
                    OrganisationId: "",
                }
            ],
            Labs: [
                {
                    IsAccessRestricted: false,
                    OrganisationName: "",
                    OrganisationId: "",
                }
            ],
            ClinicalNotes: [
                {
                    IsAccessRestricted: false,
                    OrganisationName: "",
                    OrganisationId: "",
                }
            ]
        },
        EmailVerified: false,
        SecurityPinCodeSetup: false,
        RecoveryEmail: "",
        VerificationStatusDetails: [],
        SelfieTaken: false,
        AcknowledgeOfAdditionalSecuritySettings: true,
        AcknowledgeOfIdentityVerification: false,
        PrivacyPolicySetup: false,
        hasVerifiedDependantLinked: false,
        CareGiverId: DEFAULT_GUID,
        LatestPracticeId: '',
    }


    // Sign-up invitation
    signUpInvitationToken: string = "";
    signUpInvitationEmail: string = "";
    signUpInvitationDetails: ISignUpInvitationDetails = {
        Email: "",
        GivenName: "",
        FamilyName: "",
        PreferredName: "",
        MobileNumber: "",
        DateOfBirth: "",
        OrganisationName: "",
        Address: "",
        LogoUrl: "",
        NoteForPatient: "Log online and create your password, you will have full access.",
        OrganisationPhone: "",
        OrganisationEmail: "",
    }
    signUpInvitationSuccess: boolean = false;

    // Recover Pin
    recoveryMethods: any = {
        MobileNumber: "",
        Email: "",
        RecoveryEmail: "",
    }

    getProfileLoading: boolean = false;

    @action.bound
    async getProfile(onSuccess?: () => void) {
        this.loading = true;
        this.getProfileLoading = true;
      await generalRequest.bind(this)({
        loadingStatusName: 'profileLoadingStatus',
        api: getProfileAPI,
        dataPropertyName: 'profile',
        afterFailed: (err: any) => {
            if(err.response) {
                if (err.response.status === 401) {
                    //window.localStorage.removeItem('token');
                    //go.login();
                    return;
                }
                this.profileErrorText = err.response.data.Message;
            }
          this.loading = false;
          this.getProfileLoading = false;
        },
        afterLoaded: (data: any) => {
            this.profile = data;
            this.loading = false;
            this.getProfileLoading = false;
            if (onSuccess) {
                onSuccess();
            }
        }
      });
    }
    @action.bound 
    async uploadPic(pic: File, onSuccess?: () => void) {
        this.loading = true;
        await generalRequest.bind(this)({
            api: uploadPicAPI(pic),
            afterLoaded: () => {
                this.loading = false;
                if (onSuccess) {
                    onSuccess();
                }
            },
            afterFailed: (err: any) => {
                this.loading = false;
                Alert.error(err.response.data.Message);
                
            }
        })
    }
    @action.bound
    async getIdentityRequestsStatus(onSuccess?: () => void) {
        this.loadingRequests = true;
        await generalRequest.bind(this)({
            api: getIdentityRequestsStatusAPI,
            afterFailed: (err: any) => {
                this.loadingRequests = false;
            },
            afterLoaded: (data: any) => {
                this.identityRequests = data;
                this.receivedIDRequests = true;
                this.loadingRequests = false;
                this.identityRequestsStatusComplete = true;
                if (data !== null) {
                    if (onSuccess) {
                        onSuccess();
                    }  
                };
                this.userAccountIdentityLabelArr = [];
                data.map((item: IIdentityRequestsStatus) => {
                    if (item.IdentityRequest) {
                        if (item.IdentityRequest.Status === 1 || item.IdentityRequest.Status === 0) {
                            this.userAccountIdentityLabelArr.push(item);
                        }
                    }
                })
            }
        })
    }
    @action.bound 
    async setEmailTemp(email: string) {
        this.emailForRecoveringSuspendedAccount = email;
    }

    @action.bound 
    async setShowPopUp(value: boolean) {
        this.showPopUp = value;
    }
    @action.bound 
    async setPinCodeFlowState(value: number) {
        this.pinCodeFlowState = value;
    }

    @action.bound
    async setEmailVerified() {
        this.profile.EmailVerified = true;
    }
    @action.bound
    async setConsumerUrl(url: string) {
        this.consumerUrl = url;
    }
    @action.bound
    async setMobileVerified(mobile: string) {
        this.profile.MobileVerified = true;
        this.profile.Mobile = mobile;
    }

    @action.bound
    async setVerifyCode(code: string, element: number) {
        this.patientVerification.userEnteredCode[element] = code;
    }

    @action.bound
    async sendVerifyText(mobile: string, nextScreen: () => void, errorMessage: (val: string) => void, update: boolean) {
        this.patientVerification.loading = true;
        await generalRequest.bind(this)({
            api: sendVerifyTextAPI(mobile, update),
            afterLoaded: () => {
                this.patientVerification.loading = false;
                nextScreen();
            },
            afterFailed: (err: any) => {
                this.patientVerification.loading = false;
                // TODO: ADD ERROR LIBARARY HERE
                if (err.response.status === 429) { 
                    errorMessage("You may only send one text every minute") 
                } else if (err.response.status === 400) { 
                    errorMessage(err.response.data.Message); 
                } else {
                    errorMessage("An unexpected error has occurred")
                }
                setTimeout(() => errorMessage(""), 4000);
            }
        })
    }
    @action.bound
    async checkVerifyText(isCombo: boolean, combo: () => void, close: () => void, setIncorrect: (value: boolean) => void) {
        this.patientVerification.loading = true;
        await generalRequest.bind(this) ({
            api: checkVerifyTextAPI(this.patientVerification.userEnteredCode[0]),
            afterLoaded: () => {
                this.patientVerification.loading = false;
                
                if (isCombo) { 
                    close();
                    combo(); 
                } else {
                    close();
                }               
            },
            afterFailed: (err: any) => {
                this.patientVerification.loading = false;
                setIncorrect(true);
                setTimeout(() => setIncorrect(false), 4000);
                // TODO ADD ERROR LIBARARY HERE
            }
        })
    }

    @action.bound
    async removeRecoveryEmail(onSuccess: () => void) {
        this.loading = true;
        await generalRequest.bind(this) ({
            api: removeRecoveryEmailAPI,
            afterLoaded: () => {
                this.loading = false;
                if (onSuccess) {
                    onSuccess();
                }
            },
            afterFailed: () => {
                this.loading = false;
                Alert.error("Unexpected error has occured, please try again later")
            }
        })
    }

    @action.bound
    async sendVerifyEmail(email: string, isPrimary: boolean, goNext: () => void, errorMessage: (val: string) => void, update: boolean) {
        this.patientVerification.loading = true;
        await generalRequest.bind(this) ({
            api: isPrimary ? sendVerifyEmailAPI(email, update) : sendVerifyRecoveryEmailAPI(email),
            afterLoaded: () => {
                this.patientVerification.loading = false;
                goNext();
            },
            afterFailed: (err: any) => {
                this.patientVerification.loading = false;
                // TODO: ADD ERROR LIBARARY HERE
                if (err.response.status === 429) { 
                    errorMessage("You may only send one email every minute") 
                } else if (err.response.status === 400) { 
                    errorMessage(err.response.data.Message);
                } else {
                    errorMessage("An unexpected error has occurred")
                }
            }
        })
    }
    @action.bound
    async checkVerifyEmail(email: string, isPrimary: boolean, close: () => void, setIncorrect: (val: boolean) => void, update: boolean) {
        this.patientVerification.loading = true;
        await generalRequest.bind(this) ({
            api: isPrimary ? 
                checkVerifyEmailAPI(email, this.patientVerification.userEnteredCode[1], update) 
                : 
                checkVerifyRecoveryEmailAPI(email, this.patientVerification.userEnteredCode[1]),
            afterLoaded: () => {
                this.patientVerification.loading = false;
                if (!isPrimary) { this.getProfile(); }
                close();
            },
            afterFailed: (err: any) => {
                this.patientVerification.loading = false;
                // TODO: ADD ERROR LIBARARY HERE
                if (err) { 
                    setIncorrect(true);
                }
                setTimeout(() => setIncorrect(false), 4000);
            }
        })
    }

    @action.bound
    async getUserPrivacySettingsWithOrg(orgId: string, onSuccess?: () => void) {
        this.loading = true;
        await generalRequest.bind(this) ({
            api: getUserPrivacySettingsWithOrgAPI(orgId),
            afterLoaded: (data: any) => {
                this.loading = false;
                this.privacySettingsWithOrg = data;
                if (onSuccess) {
                    onSuccess();
                }
            },
            afterFailed: (err: any) => {
                //TODO RESPONSE CODES
                this.loading = false;
            }
        })
    }
    @action.bound
    async updateUserPrivacySettingsWithOrg(OrganisationId: string, onSuccess: () => void, med: boolean, dep: boolean, dr: boolean, rec: boolean, data: boolean) {
        this.loading = true;
        await generalRequest.bind(this) ({
            api: updateUserPrivacySettingsWithOrgAPI({
                OrganisationId,
                AllowAccessAndStoreMedications: med,
                AllowAccessMyDependantHealthRecords: dep,
                AllowAccessMyDoctorNotes: dr,
                AllowAccessMyHealthRecords: rec,
                AllowShareMyHealthDataWithPractices: data,
            }),
            afterLoaded: () => {
                this.loading = false;
                onSuccess();
            },
            afterFailed: (err: any) => {
                this.loading = false;
                Alert.error(err.response.data.Message);
            }
        })
    }

    @action.bound
    async getPinCodeLength() {
        this.loading = true;
        await generalRequest.bind(this) ({
            api: getPinCodeLengthAPI,
            afterLoaded: (data: any) => {
                this.loading = false;
                this.pinCodeLength = data;
            },
            afterFailed: (err: any) => {
                this.loading = false;
                Alert.error(err.response.data.Message);
            } 
        })
    }

    @action.bound
    async verifyPinCode(pinCode: string, onSuccess: (data: any) => void, onFail: (err: any) => void, email?: string): Promise<boolean> {
        this.loading = true;
        await generalRequest.bind(this) ({
            api: verifyPinCodeAPI(pinCode),
            afterLoaded: (data: {
                access_token: string,
                token_type: string,
                expires_in:  number,
                refresh_token: "string"
              }) => {
                this.loading = false;
                if (data) {
                    window.localStorage.setItem('token', JSON.stringify({
                        token: data.access_token,
                        expiryTime:  moment().add(data.expires_in - 120, 'seconds').toDate(),
                        refreshToken: data.refresh_token,
                    }));
                }
                onSuccess(data);
                return true;
            },
            afterFailed: (err: any) => {
                this.loading = false;
                onFail(err);
                if (err.response.status === 429) { 
                    Alert.error("Too many failed attempts please try again later") 
                } else if (err.response.status === 400) { 
                    Alert.error(err.response.data.Message);
                } else if (err.response.status === 401) {
                    go.suspendAccount(email);
                } else {
                    Alert.error("An unexpected error has occurred")
                }
                return false
                //TODO RESPONSE CODES
            }
        })
        return false;
    }
    
    @action.bound
    async getPinCodeEnabled(onSuccess: () => void) {
        this.loading = true;
        await generalRequest.bind(this) ({
            api: getPinCodeEnabledAPI,
            afterLoaded: (data: any) => {
                this.loading = false;
                this.pinCodeEnabled = data;
                if (data) {
                    onSuccess();
                } else {
                    go.home();
                }
            },
            afterFailed: (err: any) => {
                this.loading = false;
                Alert.error("Unexpected error has occured. Please try again later");
            }
        })

    }

    @action.bound
    async sendAcknowledgement(type: number, onSuccess: () => void) {
        this.loadingAcknowledgement = true;
        await generalRequest.bind(this) ({
            api: sendAcknowledgementAPI(type),
            afterLoaded: () => {
                this.loadingAcknowledgement = false;
                onSuccess();
            },
            afterFailed: (err: any) => {
                this.loadingAcknowledgement = false;
                Alert.error(err.response);
            }
        })
    }

    @action.bound
    async logout(onSuccess?: () => void) {
        this.loading = true;
        await generalRequest.bind(this) ({
            api: logoutAPI,
            afterLoaded: () => {
                this.loading = false;
                window.localStorage.removeItem('token');
                if (onSuccess) {
                    onSuccess();
                }
            },
            afterFailed: (err: any) => {
                this.loading = false;
                Alert.error(err.response.data.Message);
            }
        })
    }

    @action.bound
    async getSignUpInvitationDetails(token: string, email: string, onFail: () => void) {
        this.loading = true;
        this.signUpInvitationToken = token;
        if (email) {
            this.signUpInvitationEmail = email;
        }
        await generalRequest.bind(this) ({
            api: getSignUpInvitationDetailsAPI(token),
            afterLoaded: (data: any) => {
                this.loading = false;
                this.signUpInvitationDetails = data;
                this.signUpInvitationSuccess = true;
            },
            afterFailed: (err: any) => {
                this.loading = false;
                onFail();
                Alert.error(err.response.data.Message);
            }
        })
    }
    @action.bound
    async registerWithSignUpToken(data: IRegisterWithSignUpToken, onSuccess: () => void) {
        this.loading = true;
        await generalRequest.bind(this) ({
            api: registerWithSignUpTokenAPI(data),
            afterLoaded: () => {
                this.loading = false;
                Alert.success("Registration Complete");
                onSuccess();
            },
            afterFailed: (e: any) => {
                this.loading = false;
                Alert.error(e.response.data.Message);
            }
        })
    }

    @action.bound
    async editProfile(data: any) {
        this.loading = true;
        await generalRequest.bind(this) ({
            api: editProfileAPI(data),
            afterLoaded: () => {
                this.loading = false;
                Alert.success("Changes saved successfully");
            },
            afterFailed: (err: any) => {
                this.loading = false;
                Alert.error(err.response.data.Message);
            }
        })
    }

    @action.bound
    async getRecoveryMethods() {
        this.loading = true;
        await generalRequest.bind(this) ({
            api: getRecoveryMethodsAPI,
            afterLoaded: (data: any) => {
                this.loading = false;
                this.recoveryMethods = data;
            },
            afterFailed: (err: any) => {
                this.loading = false;
                Alert.error(err.response.data.Message);
            }
        })
    }
    @action.bound
    async getVerificationCodeSentToRecoveryMethod(isMobile: boolean, method: string, onSuccess: () => void) {
        this.loading = true;
        await generalRequest.bind(this) ({
            api: getVerificationCodeSentToRecoveryMethodAPI(isMobile, method),
            afterLoaded: (data: any) => {
                this.loading = false;
                onSuccess();
            },
            afterFailed: (err: any) => {
                this.loading = false;
                Alert.error(err.response.data.Message);
            }
        })
    }
    @action.bound
    async postVerificationCodeSentToRecoveryMethod(code: string, isMobile: boolean, method: string, onSuccess: () => void) {
        this.loading = true;
        await generalRequest.bind(this) ({
            api: postVerificationCodeSentToRecoveryMethodAPI(code, isMobile, method),
            afterLoaded: (data: any) => {
                this.loading = false
                onSuccess();
            },
            afterFailed: (err: any) => {
                this.loading = false;
                Alert.error(err.response.data.Message);
            }
        })
    }

    @action.bound
    async getRecoveryEmailSentToSuspendedAccount(email: string, onSuccess: () => void) {
        this.loading = true;
        await generalRequest.bind(this) ({
            api: getRecoveryEmailSentToSuspendedAccountAPI(email),
            afterLoaded: (data: any) => {
                this.loading = false;
                onSuccess();
            },
            afterFailed: (err: any) => {
                this.loading = false;
                Alert.error(err.response.data.Message);
            }
        })
    }

    @action.bound
    async getRecoverSuspendedAccountVerificationCode(token: string, isMobile: boolean, target: string, onSuccess: () => void) {
        this.loading = true;
        await generalRequest.bind(this) ({
            api: getRecoverSuspendedAccountVerificationCodeAPI(token, isMobile, target),
            afterLoaded: (data: any) => {
                this.loading = false;
                onSuccess();
            },
            afterFailed: (err: any) => {
                this.loading = false;
                Alert.error(err.response.data.Message);
            }
        })
    }
    @action.bound
    async getRecoveryMethodsWithToken(token: string) {
        this.loading = true;
        await generalRequest.bind(this) ({
            api: getRecoveryMethodsForSuspendedAccountAPI(token),
            afterLoaded: (data: any) => {
                this.loading = false;
                this.recoveryMethods = data;

            },
            afterFailed: (err: any) => {
                this.loading = false;
                Alert.error(err.response.data.Message);
            }
        })
    }
    
    @action.bound
    async postRecoverAccountCheckActivationCode(token: string, code: string, isMobile: boolean, method: string, onSuccess: () => void) {
        this.loading = true;
        await generalRequest.bind(this) ({
            api: postRecoverAccountCheckActivationCodeAPI(token, code, isMobile, method),
            afterLoaded: (data: any) => {
                this.loading = false
                onSuccess();
            },
            afterFailed: (err: any) => {
                this.loading = false;
                Alert.error(err.response.data.Message);
            }
        })
    }
    @action.bound
    async postRecoverAccountChangePassword(token: string, newPassword: string, onSuccess: () => void) {
        this.loading = true;
        await generalRequest.bind(this) ({
            api: postRecoverAccountChangePasswordAPI(token, newPassword),
            afterLoaded: (data: any) => {
                this.loading = false;
                onSuccess();
            },
            afterFailed: (err: any) => {
                this.loading = false;
                Alert.error(err.response.data.Message);
            }
        })
    }
    @action.bound
    async postRecoverAccountChangePin(token: string, newPin: string, onSuccess: () => void, goBack: () => void) {
        this.loading = true;
        await generalRequest.bind(this) ({
            api: postRecoverAccountChangePinAPI(token, newPin),
            afterLoaded: (data: any) => {
                this.loading = false;
                this.successChecker = true;
                onSuccess();
            },
            afterFailed: (err: any) => {
                this.loading = false;
                goBack();
                Alert.error(err.response.data.Message);
            }
        })
    }
    @action.bound
    async postRecoverAccount(token: string, onSuccess: () => void) {
        this.loading = true;
        this.successChecker = false;
        await generalRequest.bind(this) ({
            api: postRecoverAccountAPI(token),
            afterLoaded: (data: any) => {
                this.loading = false;               
                Alert.success("Successfully recovered your account! Please log in with your new credentials");
                onSuccess();
            },
            afterFailed: (err: any) => {
                this.loading = false;
                Alert.error(err.response.data.Message);
            }
        })
    }
}