import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import NDigitPin from './nDigitPin';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0rem 0rem;
`
interface Props {
    length: number;
    goNext: () => void;
}
const PinCode: React.FC<Props> = observer(({length, goNext}) => {
    return (
        <Container>
            <NDigitPin
                n={length}
                goNext={goNext}
            />
        </Container>
    );
})

export default PinCode