import { call, takeEvery, put } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/deactivateActions';
import { actions as desktopPopUpActions } from '../../components/DesktopPopUp/actions/actions';
import HttpLib, { GenericParserHttp } from '../../libs/Http';

const passwordParserHttp = HttpLib(() => {}, () => {});

const checkPasswordApi = (password, token) =>
  passwordParserHttp
    .post('consumer/password/verify', { data: { password }, token })
    .then(({ body }) => body);

const api = (token, accessToken, Reason) =>
  GenericParserHttp.delete('consumer', {
    data: { Reason, token: accessToken },
    token,
  }).then(({ body }) => body);

function* checkPassword({ payload: { password, token } }) {
  try {
    const accessToken = yield call(checkPasswordApi, password, token);
    yield put(actions.checkPasswordSuccess(accessToken));
  } catch (err) {
    yield put(actions.checkPasswordFailure(err));
    const errorText =
      err.status === 401
        ? 'Oops, it looks like your password is incorrect.'
        : 'Unexpected Error Occurred. Please Try Again';
    yield put(desktopPopUpActions.setError(errorText));
  }
}
function* deactivateAccount({ payload: { token, accessToken, reason } }) {
  try {
    yield call(api, token, accessToken, reason);
    yield put(actions.deactivateAccountSuccess());
  } catch (err) {
    yield put(actions.deactivateAccountFailure(err));
  }
}

function* checkPasswordSaga() {
  yield takeEvery(actionTypes.checkPassword, checkPassword);
}
function* deactivateAccountSaga() {
  yield takeEvery(actionTypes.deactivateAccount, deactivateAccount);
}

export { checkPasswordSaga, deactivateAccountSaga };
