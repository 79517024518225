import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import { findDoctor } from '../../selectors/appointmentSelectors';
import ConfirmAppointmentDesktop from './ConfirmAppointmentDesktop/index';
import ConfirmAppointmentMobile from './ConfirmAppointmentMobile/index';
// eslint-disable-next-line max-len
import {
  DoctorProfilePropType,
  PracticeInfoPropType,
} from "../../PracticeProfilePage/BookingPageMobile/propTypes/index";
import { detailsPropType } from "./propTypes/index";
import { go } from "../../routes/index";
import { isDesktop } from "../../config";
import { goBackPages } from "../../routes/utils";
import { bindActionCreators } from 'redux';
import { actions as verifyActions } from '../MobileVerification/actions/codeConfirmationActions';

class AppointmentConfirmation extends Component {
  onConfirmClick = () => {    
    if (this.props.token) 
      go.processAppointment(this.props.practice.UrlName);
    else {
        this.props.sendCode(this.props.session.SessionId);
        go.verification(this.props.practice.UrlName);
    }
  };
  editDoctor = () => {
    go.practiceProfile(this.props.practice.UrlName);
  };
  editPatient = () => {
    if (!this.props.token && this.props.isBookingForSomeoneElse) {
      goBackPages(-3);
    } else goBackPages(-2);
  };
  editGuardian = () => {
    goBackPages(-2);
  };
  render() {
    const { appointment, doctor, practice, patient, guardian, session, isFetching } = this.props;
    return isDesktop() ? (
      <ConfirmAppointmentDesktop
        isFetching={isFetching}
        doctor={doctor}
        practice={practice}
        time={appointment}
        patient={patient}
        guardian={guardian}
        onDetailsEditClick={this.editPatient}
        onGuardianEditClick={this.editGuardian}
        onConfirmClick={this.onConfirmClick}
        onDoctorEditClick={this.editDoctor}
      />
    ) : (
      <ConfirmAppointmentMobile
        isFetching={isFetching}
        doctor={doctor}
        practice={practice}
        time={appointment}
        patient={patient}
        onDetailsEditClick={this.editGuardian}
        onConfirmClick={this.onConfirmClick}
      />
    );
  }
}

AppointmentConfirmation.propTypes = {
  token: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  doctor: DoctorProfilePropType.isRequired,
  appointment: PropTypes.instanceOf(moment),
  practice: PracticeInfoPropType.isRequired,
  patient: detailsPropType.isRequired,
  guardian: detailsPropType,
  sendCode: PropTypes.func.isRequired,
  session: PropTypes.any,
};

// get the patient whom the appointment is booked for
const getPatientDetails = (isLoggedIn, profile, session) => {
  if (isLoggedIn && !session.IsBookingForSomeoneElse) {
    return profile;
  }
  return {
    DependantId: session.DependantId,
    FirstName: session.FirstName,
    LastName: session.LastName,
    Mobile: session.Mobile,
    DateOfBirth: session.DateOfBirth,
    MobileVerified: profile.MobileVerified,
  };
};
const getGuardianDetails = (token, session) => {
  if (session.IsBookingForSomeoneElse && !token) {
    return {
      FirstName: session.InitiatorFirstName,
      LastName: session.InitiatorLastName,
      Mobile: session.InitiatorMobile,
      DateOfBirth: session.InitiatorDateOfBirth,
    };
  }
  return null;
};

const mapStateToProps = state => {
  const session = state.checkEnrollmentReducer.session;
  const token = state.loginReducer.token;
  const profile = state.profileReducer.profile;
  const patient = getPatientDetails(
    token,
    profile,
    session,
  );
  const guardian = getGuardianDetails(token, session);
  return {
    token,
    patient,
    guardian,
    isFetching: state.sessionReducer.isFetching,
    // @TODO: put this in a selector
    doctor: findDoctor(
      state.practiceAppointmentReducer.selectedTime.doctorId,
      state.practiceAppointmentReducer.doctorInfo.doctors.length
        ? state.practiceAppointmentReducer.doctorInfo.doctors
        : state.practiceProfileReducer.profile.Doctors,
    ),
    appointment: moment(state.practiceAppointmentReducer.selectedTime.time),
    practice: state.practiceProfileReducer.profile || {},
    session,
  };
};
const mapDispatchToProps = dispatch => ({
  sendCode: bindActionCreators(verifyActions.sendToConfirm, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppointmentConfirmation);
