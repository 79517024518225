import React, { PropsWithChildren } from 'react';

type Props = {
  width:number;
  height:number;
  fill: string;
  children?: React.ReactNode;
}

const RightSign = ({
  width,
  height,
  fill,
  children,
  ...rest
}: Props) => {
  const width6 = width * 0.6;
  const height5 = height * 0.5;
  return (
    <svg height={height} width={width} {...rest}>
      <polygon points={`0 0,${width6} 0,${width} ${height5},${width6} ${height},0 ${height}`} fill={fill} />
      {children}
    </svg>
  )
}

export default RightSign