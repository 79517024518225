import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import classnames from 'classnames';

import { actions as feelingActions } from '../actions/actions';
import { sessionState, sessionDispatch, allSessionProps } from '../../../selectors/sessionSelectors';
import { findDoctor } from '../../../selectors/appointmentSelectors';
import styles from './reasonOld.module.css';
import FeelingForm from './FeelingForm/index';
import CtaButton from '../../../components/CtaButton/index';
import Header from '../../../components/UIContainer/MobileUI/header/index';
import IAmFeelingUrl from '../../../images/Iamfeeling.png';
import {
  DoctorProfilePropType,
  PracticeInfoPropType,
} from '../../../PracticeProfilePage/BookingPageMobile/propTypes/index';
import { detailsPropType } from '../../AppointmentConfirmation/propTypes/index';
import { goBack } from '../../../routes/utils';
import { go } from '../../../routes/index';
import { isDesktop } from '../../../config';
import { ReactComponent as RightArrow } from '../../../svgs/rightarrow.svg';
import cssVars from 'css-vars-ponyfill';

class ReasonOld extends Component {
  static propTypes = {
    addFeelings: PropTypes.func.isRequired,
    skipFeelings: PropTypes.func.isRequired,
    createSession: PropTypes.func.isRequired,
    clearFeelings: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    viewPatientDetails: detailsPropType.isRequired,
    practice: PracticeInfoPropType.isRequired,
    appointment: PropTypes.instanceOf(moment).isRequired,
    doctor: DoctorProfilePropType.isRequired,
    viewGuardianDetails: detailsPropType,
    token: PropTypes.string,
    note: PropTypes.string,
    firstName: PropTypes.string,
    isBookingForSomeoneElse: PropTypes.bool,
    isFormValid: PropTypes.bool,
  };
  state = {
    note: this.props.note,
  };
  componentDidMount() {
    cssVars();
    // this.props.clearFeelings();
    if (!isDesktop()) {
      window.scrollTo(0, 0);
    }
  }
  onConfirmClick = () => {
    this.props.createSession(this.props)();
  };
  onChange = e => {
    this.setState({ note: e.target.value });
  };
  handleCta = () => {
    go.confirmAppointment(this.props.match.params.id);
    if (!this.state.note.length) this.props.skipFeelings();
    else this.props.addFeelings(this.state.note);
  };
  render() {
    return (
      <div className={styles.feelingPage}>
        {!isDesktop() && <Header headerClassNames={styles.header} showHelp />}
        <div className={styles.formContainer}>
          {isDesktop() && (
            <div>
              <div className={styles.desktopLabel}>What&#39;s the reason for the consultation?</div>
              <div className={styles.bottomNote}>
                Note that practice staff can view this information.
              </div>
            </div>
          )}
          <img src={IAmFeelingUrl} className={styles.feelingIcon} alt="I am feeling" />
          <span className={styles.label}>What&#39;s your reason for the consultation?</span>
          <div className={styles.formInnerContainer}>
            <FeelingForm
              isBookingForSomeoneElse={this.props.isBookingForSomeoneElse}
              onChange={this.onChange}
              name={this.props.firstName}
              note={this.state.note}
            />
          </div>
        </div>
        {isDesktop() ? (
          <div className={styles.nextContainer}>
            {/* eslint-disable-next-line */}
            <a className={styles.navLinkLarge} onClick={goBack}>
              <RightArrow className={styles.backwardArrow} />
              <span>Back</span>
            </a>
            <button
              id="nw_feelingNext"
              className={classnames(styles.navLinkLarge, {
                [styles.faded]: !this.props.isFormValid,
              })}
              onClick={this.handleCta}
            >
              <span>Next</span>
              <RightArrow className={styles.forwardArrow} />
            </button>
          </div>
        ) : (
          <div className={styles.footer}>
            <div className={styles.bottomNote}>
              Note that practice staff can view this information.
            </div>
            <CtaButton className={styles.cta} onClick={this.handleCta} active />
          </div>
        )}
      </div>
    );
  }
}

// get the patient whom the appointment is booked for
const getPatientDetails = (isLoggedIn, isBookingForSomeoneElse, profile, sessionDetails) => {
  if (isLoggedIn && !isBookingForSomeoneElse) {
    return profile;
  }
  return {
    DependantId: sessionDetails.dependantId,
    FirstName: sessionDetails.firstName,
    LastName: sessionDetails.lastName,
    Mobile: sessionDetails.mobile,
    DateOfBirth: sessionDetails.dateOfBirth,
    MobileVerified: profile.MobileVerified,
  };
};
const getGuardianDetails = (isBookingForSomeoneElse, token, sessionDetails) => {
  if (isBookingForSomeoneElse && !token) {
    return {
      FirstName: sessionDetails.initiatorFirstName,
      LastName: sessionDetails.initiatorLastName,
      Mobile: sessionDetails.initiatorMobile,
      DateOfBirth: sessionDetails.initiatorDateOfBirth,
    };
  }
  return null;
};
const mapStateToProps = state => {
  const sessionProps = sessionState([])(state);
  const token = state.loginReducer.token;
  const profile = state.profileReducer.profile;
  const viewPatientDetails = getPatientDetails(
    token,
    sessionProps.isBookingForSomeoneElse,
    profile,
    sessionProps,
  );
  const viewGuardianDetails = getGuardianDetails(
    sessionProps.isBookingForSomeoneElse,
    token,
    sessionProps,
  );
  return {
    token,
    ...sessionProps,
    viewPatientDetails,
    viewGuardianDetails,
    isFetching: state.sessionReducer.isFetching,
    // @TODO: put this in a selector
    doctor: findDoctor(
      state.practiceAppointmentReducer.selectedTime.doctorId,
      state.practiceAppointmentReducer.doctorInfo.doctors.length
        ? state.practiceAppointmentReducer.doctorInfo.doctors
        : state.practiceProfileReducer.profile.Doctors,
    ),
    appointment: moment(state.practiceAppointmentReducer.selectedTime.time),
    practice: isDesktop()
      ? state.practiceProfileReducer.profile
      : state.practiceProfileReducer.profile || {},
    note: state.feelingPageReducer.feelings,
  };
};

const mapDispatchToProps = dispatch => ({
  createSession: sessionDispatch(dispatch)(allSessionProps),
  addFeelings: bindActionCreators(feelingActions.addFeelings, dispatch),
  skipFeelings: bindActionCreators(feelingActions.skipFeelings, dispatch),
  clearFeelings: bindActionCreators(feelingActions.clearFeelings, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReasonOld);
export { styles };
