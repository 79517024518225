import { call, takeEvery, put } from 'redux-saga/effects';

import HttpLib from '../../../libs/Http';
import delay from '../../../libs/Delay';
import { actions, actionTypes } from '../actions/actions';
import { go } from '../../../routes';
import { isDesktop } from '../../../config';

const SubmitScriptRequestParserHttp = HttpLib(() => {}, () => {});
const api = (details, token) =>
  SubmitScriptRequestParserHttp.post('consumer/prescription/submit', {
    data: details,
    token,
  }).then(({ body: { ScriptId } }) => ScriptId);

function* submitScriptRequest({ payload: { details, token } }) {
  try {
    const scriptId = yield call(api, details, token);
    yield put(actions.submitScriptRequestSuccess(scriptId));
  } catch (err) {
    // for animation purposes
    yield call(delay, 3000);
    yield put(actions.submitScriptRequestFailure(err));
    if (err.response && (err.response.body?.Reason ?? true) && !isDesktop()) {
      go.errorScript();
    } else {
      yield delay(2000);
      if (!isDesktop()) go.home();
      else go.prescriptions();
    }
  }
}

function* submitScriptRequestSaga() {
  yield takeEvery(actionTypes.submitScriptRequest, submitScriptRequest);
}

export { submitScriptRequestSaga };
