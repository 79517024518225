import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Image, Text } from 'components/vensaComponents';

export const BaseListItem: React.FC<{
  isNew?: boolean;
  textWhenNew?: string;
  icon: string;
  leftFirstLine: string;
  leftSecondLine: string;
  leftThirdLine: string;
  rightFirstLine: string;
  onClick?: () => void;
}> = observer(
  ({
    isNew,
    textWhenNew,
    icon,
    leftFirstLine,
    leftSecondLine,
    leftThirdLine,
    rightFirstLine,
    onClick,
  }) => {
    const style = isNew
      ? {
          bg: 'white',
          boxShadow: 6,
        }
      : {
          borderBottom: '1px solid #E7E7E7',
        };

    return (
      <Box
        p={3}
        gap="16px"
        direction="column"
        borderRadius="4px"
        {...style}
        onClick={onClick}
      >
        {isNew && (
          <Box
            bg="#44D375"
            flexGrow={0}
            alignSelf="start"
            color="white"
            px={2}
            py={1}
            fontSize="12px"
            borderRadius="25px"
          >
            {textWhenNew}
          </Box>
        )}
        <Box justifyContent="between" fontSize="14px">
          <Box gap="16px">
            <Image src={icon}/>
            <Box width={'10px'} />
            <Box direction="column">
              <Text>{leftFirstLine}</Text>
              <Text fontWeight={100} width={200}>{leftSecondLine}</Text>
              <Text fontWeight={100}>{leftThirdLine}</Text>
            </Box>
          </Box>
          <Text>{rightFirstLine}</Text>
        </Box>
      </Box>
    );
  },
);
