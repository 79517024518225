import { actionTypes } from '../actions/headerActions';

const INITIAL_STATE = {
  backArrowFn: null,
  customIcon: null,
  sideHeaderItem: '',
};

const headerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.addCustomIcon: {
      return {
        ...state,
        customIcon: action.payload.customIcon,
      };
    }
    case actionTypes.clearCustomIcon: {
      return {
        ...state,
        customIcon: null,
      };
    }
    case actionTypes.overrideBackArrow: {
      return {
        ...state,
        backArrowFn: action.payload.backArrowFn,
      };
    }
    case actionTypes.clearBackArrow: {
      return {
        ...state,
        backArrowFn: null,
      };
    }
    case actionTypes.setSideHeaderItem: {
      return {
        ...state,
        sideHeaderItem: action.payload.item,
      };
    }
    default: {
      return state;
    }
  }
};

export default headerReducer;
