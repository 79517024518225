import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Arrow } from 'svgs/roundArrow.svg';
import { ReactComponent as DisabledArrow } from 'svgs/disabledRoundArrow.svg';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    border: none;
    border-top: 2px solid #DADADA;
    border-bottom: 2px solid #DADADA;
    padding: 0.7rem 0rem;
    cursor: pointer;
    align-items: center;
`
const Text = styled.div`
    color: #07A3C8;
    text-align: left;
    width: 100%;
    font-size: 15px;
`

interface Props {
    text: string;
    onClick: () => void;
    noBorder?: boolean;
    disabled?: boolean;
}

const FullWidthButton: React.FC<Props> = ({text, onClick, noBorder, disabled}) => {
    return (
        <Container onClick={!disabled ? onClick : () => {}} style={{
            borderTop: noBorder ? "none" : "2px solid #DADADA;",
            borderBottom: noBorder ? "none" : "2px solid #DADADA;"
        }}>
            <Text style={{color: disabled ? "#DADADA" : "#07A3C8"}}>{text}</Text>
            {disabled ? 
                <DisabledArrow />
                :
                <Arrow />
            }
        </Container>
    )
}

export default FullWidthButton;