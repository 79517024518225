import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { go } from '../../routes/index';
import { isDesktop } from '../../config';
import UIContainer from '../../components/UIContainer';
import styles from './expressioninterest.module.css';
import Logo from '../../svgs/research_logo.svg';
import { usePatientStore } from '../../store/mobx/mobx'
import { observer } from 'mobx-react-lite'
import { actions } from './actions/actions'
import { ReactComponent as Close } from '../../svgs/close.svg';


const ExpressionOfInterest = observer((props) => {
    const [interested, setInterested] = useState(false);
    const store = usePatientStore();

    useEffect(() => {
        if (isDesktop()) continueNext();

        if (interested) {
            store.setPatientCanExpressInterest(props.patientId, true);
            props.expressInterest(props.patientId);

            continueNext();
        }
    })

    const continueNext = () => go.reason(props.match.params.id, true);

    return(
        <UIContainer>
            <div className={styles.container}>
                <div className={styles.inner}>
                    <Close className={styles.close} style={{display: "inline"}} onClick={()=>continueNext()} />
                    <div className={styles.topHeaderText}>Lab results study invitation</div>
                    <div className={styles.topLogo}>
                        <img src={Logo} alt="study invitation" className={styles.logo} />
                    </div>
                    <div className={styles.subHeaderText}>Do you want access to your lab test results?</div>
                    <div className={styles.textContent}>

                        <div className={styles.textArea}>
                            <div className={styles.number}>1</div>
                            <div className={styles.textLine}>View your results on your Vensa portal as soon as they are available</div>
                        </div> 

                        <div className={styles.textArea}>
                            <div className={styles.number}>2</div>
                            <div className={styles.textLine}>Receive personalised health advice from your doctor with your results</div>
                        </div> 

                        <div className={styles.textArea}>
                            <div className={styles.number}>3</div>
                            <div className={styles.textLine}>Provide feedback about the advice you were given</div>
                        </div> 
                    </div>

                    <div className={styles.buttonSection}>
                        <button className={styles.blueButton} onClick={()=>setInterested(true)} >Sign me up!</button>
                        <button className={styles.whiteButton} onClick={()=>continueNext()}>No thanks</button>
                    </div>
                </div>
            </div>
        </UIContainer>
    );
})

const mapStateToProps = state => ({
    patientId: state.checkEnrollmentReducer.patientId,
    consult: state.consult
  });

const mapDispatchToProps = dispatch => ({
    expressInterest: bindActionCreators(actions.expressInterest, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ExpressionOfInterest);