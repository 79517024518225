import React, { FC, useState } from 'react'

import { ReactComponent as WavingKiwi } from './wavingKiwi.svg'
import styles from './index.module.css';
import LearnMorePopUp from 'components/LearnMorePopUp/LearnMorePopUp';
import { ReactComponent as Cross } from 'svgs/blueCross.svg';

interface IAddToHomeScreeCardProps {
    title: string;
    text: string;
    setShowPopUp: (show: boolean) => void;
    closeHook: () => void;
}

const AddToHomeScreenCard : FC<IAddToHomeScreeCardProps> = ({title, text, setShowPopUp, closeHook}) => {
    const [popupType, setPopupType] = useState('');
    const buttonOnClick = () => {
        setPopupType('ADD_TO_HOME_SCREEN')
        if (setShowPopUp) { setShowPopUp(true);
    }};
    const linkOnClick = () => {
        setPopupType("TAKE_A_TOUR")
        if (setShowPopUp) { setShowPopUp(true); }
    };
    const handleClose = (event: React.MouseEvent<SVGSVGElement>) => {
        event.stopPropagation();
        window.localStorage.setItem('add-to-home-screen', 'close');
        closeHook();
    };
   
    return (
        <>
            <div style={{width: '100%', position: 'relative'}}>
                <Cross onClick={handleClose} style={{position: "absolute", top: "1rem", right: "1rem", cursor: "pointer"}} />
                <div className={styles.container}>
                    <WavingKiwi className={styles.kiwi} />
                    <div className={styles.welcome}>
                        {title}
                    </div>
                    <div className={styles.text}>{text}</div>
                    <div style={{display: "flex", flexDirection: "row", marginTop: "1rem", width: "100%"}}>
                    <button className={styles.linkButton} onClick={linkOnClick} style={{margin: "auto"}}>
                        Take a Tour
                    </button>
                    <div className={styles.buttonDiv} style={{margin: "auto", marginRight: "auto"}}>
                        <div className={styles.button} onClick={buttonOnClick}>Add to Home Screen</div>
                    </div>
                    </div>
                </div>
            </div>
            {
                popupType && 
                <LearnMorePopUp type={popupType}
                    hide={() => {
                            if (setShowPopUp) { setShowPopUp(false); } 
                            setPopupType('');
                        }}
                    />
            }
        </>
    )
}

export default AddToHomeScreenCard;
