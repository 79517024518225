import React from 'react';
import { observer } from 'mobx-react-lite';
import masterPNG from '../../images/master-card.png';
import visaPNG from '../../images/visa-card-new-logo.png';
import amexPNG from '../../images/amex-card.png';
import { Image } from 'components/vensaComponents';
import { ICard } from 'store/mobx/paymentStore/paymentStore.type';

const IMAGES = {
  AmEx: amexPNG,
  Mastercard: masterPNG,
  Visa: visaPNG,
};

const cardShortName = (name) => {
  switch(name?.toLowerCase()){
    case 'mastercard': return 'Mastercard';
    case 'american express': return 'AmEx';
  }
  return 'Visa';
}

const getCardImage = (cardBrand: ICard['Brand']): string => {
  let result = IMAGES[cardShortName(cardBrand)];
  if (!result) result = visaPNG;
  return result;
};

export const CardImage: React.FC<{
  brand: ICard['Brand'];
}> = observer(({ brand }) => <Image src={getCardImage(brand)} height="30px" />);
