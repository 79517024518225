import React from 'react';
import CalendarList from './CalendarList';
import CalendarButton from './CalendarButton';
import OpenableCard from '../OpenableCard';
import { CalendarProps } from 'pages/mobile/domain/Appointment/types';

const index: React.FC<CalendarProps> = (props) => {
    
    return (
        <OpenableCard<CalendarProps, CalendarProps> 
            Origin={CalendarButton} 
            Target={CalendarList} 
            originData={props}
            targetData={props}
            transitionTProps={{
                from: { position: 'fixed', opacity: 0, transform: 'translate3d(100vw, 100vh, 0)' },
                enter: { position: 'fixed', opacity: 1, transform: 'translate3d(0,0,0)' },
                leave: { position: 'fixed', opacity: 0, transform: 'translate3d(100vw,100vw,0)' },
            }}
        />);
}
    

export default index;