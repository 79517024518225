import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import loading from '../../../images/loadingGifPerma.gif';
import { useIdentityStore } from '../../../store/mobx/mobx';
import { useComponentDidMount } from '../../../libs/newHttp';
import tick from '../../../svgs/submitTick.svg';

const Loader = styled.img`
    margin: auto;
    display: block;
    opacity: ${(p: LoadingProps) => p.submitted ? '0' : '1'};
    transition: all 1s linear;
`
const Tick = styled.img`
    position: absolute;
    opacity: 1;
    top: 220px; 
    left: 223px;
    opacity: ${(p: TickProps) => p.state ? '1' : '0'};
    transform: ${(p: TickProps) => p.state ? 'translateY(-70px)' : 'none'};
    transition: all 1s ease-in-out;
`
const Container = styled.div`
    margin: 4rem 0rem;
`
const Title = styled.div`
    margin-top: 1rem;
    text-align: center;
    font-size: 23px;
    color: #6C6C6C;
`
const Text = styled.div`
    text-align: center;
    font-size: 17px;
    color: #6C6C6C;
    padding: 0.5rem 3rem;
    font-weight: 300;
`

interface LoadingProps {
    submitted: boolean;
}
interface TickProps {
    state: boolean
}
interface Props {
    onSuccess: () => void;
    onFailure: () => void;
}

const Processing: React.FC<Props> = observer(({onSuccess, onFailure}) => {
    const store = useIdentityStore();
    const [show, setShow] = useState(false);

    useComponentDidMount(() => {
        setTimeout(() => store.submitIdentityRequest(() => setTimeout(onSuccess, 7000), () => setTimeout(onFailure)), 3000);
        setTimeout(() => setShow(true), 4000);
    });

    return (
        <Container>
                <div>
                    <Loader submitted={store.submitSuccess} src={loading} />
                    <Tick state={show} src={tick} />
                    <div style={{opacity: show ? '1' : '0', transition: "all 0.5s linear", transform: "translateY(-30px)"}}>
                        <Title style={{padding: "1rem 3rem"}}>Identity request submitted successfully</Title>
                        <Title>Redirecting...</Title>
                    </div>
                    <div style={{opacity: show ? '0' : '1', transition: "all 0.5s linear", transform: "translateY(-160px)"}}>
                        <Title>Hold on</Title>
                        <Text>We're submitting your identity request to {store.selectedPracticeName}</Text>
                    </div>
                </div>
        </Container>
    )
})

export default Processing