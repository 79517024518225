import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as DesktopMobile } from '../../../svgs/mainMobile.svg';
import styles from './verifyAccountCard.module.css';

const AccountVerify = ({ onClick }) => (
  <div className={styles.container}>
    <div className={styles.iconContainer}>
      <DesktopMobile className={styles.icon} />
    </div>
    <div className={styles.welcome}>Welcome to Vensa!</div>
    <div className={styles.verifyNote}>
      Please verify your account to fully enable your patient portal
    </div>
    <button className={styles.button} onClick={onClick}>
      Verify my account
    </button>
  </div>
);

AccountVerify.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default AccountVerify;
