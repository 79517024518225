import React, { useState } from 'react';
import styled from 'styled-components';

import Card from '../components/Card';
import review from 'svgs/review.svg';
import meds from 'svgs/meds.svg';
import pharm from 'svgs/pharm.svg';
import pharmacy from 'svgs/pharmacy.svg'
import { useComponentDidMount } from 'libs/newHttp';
import { isDesktop } from 'config';
import NavigationBar from 'vScript/RequestScriptDesktop/NavigationBar';

//left: 1rem;
//right: 1rem;

const Container = styled.div`
    position: absolute;
    top: 1rem;
    bottom: 1rem;
    margin: auto;
    overflow: hidden;
`

interface Props {
    hide: (e: any) => void;
    noX?: boolean;
}
const VScriptInfo: React.FC<Props> = ({hide, noX}) => {
    const [state, setState] = useState(0);
    const [height, setHeight] = useState(isDesktop() ? "600px" :window.innerHeight - 34);
    const TOTAL_CARDS = 4;
    
    useComponentDidMount(() => {
        window.addEventListener('resize', () => setHeight(window.innerHeight - 34));    
    })


    let card;
    switch(state) {
      case 0:
        card = <Card 
                  hide={hide} 
                  totalCards={TOTAL_CARDS} 
                  active={0} 
                  title={"Free Prescriptions for pharmacy"} 
                  text={"The government funding for $5 prescription co-payment applies only to pharmacy charges. Your medical Centre may still charge you to issue your script."} 
                  linkText='Find out more here'
                  linkTarget='https://www.health.govt.nz/your-health/conditions-and-treatments/treatments-and-surgery/medications/prescription-charges-and-prescription-subsidy-scheme'
                  onNext={() => setState(1)}
                  icon={pharmacy}
                  buttonText='Next'
                  goBack={(newState) => setState(newState)}
                  // noX={noX}
              />;  
        break;
      case 1:
        card = <Card 
                  hide={hide} 
                  totalCards={TOTAL_CARDS} 
                  active={1} 
                  title={"At practice, we review your medical history"} 
                  text={"We review your medical history and make sure that it is appropriate to prescribe. This may take up to 10m."} 
                  onNext={() => setState(2)}
                  icon={review}
                  buttonText='Next'
                  goBack={(newState) => setState(newState)}
                  // noX={noX}
              />;  
        break;
      case 2:
        card = <Card 
                  hide={hide} 
                  totalCards={TOTAL_CARDS} 
                  active={2} 
                  title={"We generate your script"} 
                  text={"We generate your script and change your medications if required, we schedule a follow up for your next visit"} 
                  onNext={() => setState(3)}
                  icon={meds}
                  buttonText='Next'
                  goBack={(newState) => setState(newState)}
                  // noX={noX}
              />
        break;
      case 3:
        card = <Card 
                  hide={hide} 
                  totalCards={TOTAL_CARDS} 
                  active={3} 
                  title={"We send your script to your pharmacy"} 
                  text={"We handle the billing and send your script to your pharmacy of choice electronically."} 
                  onNext={hide}
                  buttonText='Close'
                  icon={pharm}
                  finishButton={true}
                  goBack={(newState) => setState(newState)}
                  // noX={noX}
              />
        break;
    }
    // if (state === 0) card = <Card 
    //                             hide={hide} 
    //                             totalCards={TOTAL_CARDS} 
    //                             active={0} 
    //                             title={"We review your medical history"} 
    //                             text={"We use all available health data to ensure you are prescribed medicines safely"} 
    //                             onNext={() => setState(1)}
    //                             icon={review}
    //                             buttonText='Next'
    //                             goBack={(newState) => setState(newState)}
    //                             // noX={noX}
    //                         />;  
    // else if (state === 1) card = <Card 
    //                                 hide={hide} 
    //                                 totalCards={TOTAL_CARDS} 
    //                                 active={1} 
    //                                 title={"Better care planning"} 
    //                                 text={"We can schedule follow up appointments and medicine refill reminders to help you stay in charge of your health"} 
    //                                 onNext={() => setState(2)}
    //                                 icon={meds}
    //                                 buttonText='Next'
    //                                 goBack={(newState) => setState(newState)}
    //                                 // noX={noX}
    //                             />
    // else if (state === 2) card = <Card 
    //                                 hide={hide} 
    //                                 totalCards={TOTAL_CARDS} 
    //                                 active={2} 
    //                                 title={"We save you time"} 
    //                                 text={"We do the work for you and send your script to the pharmacy of your choice"} 
    //                                 onNext={hide}
    //                                 buttonText='Close'
    //                                 icon={pharm}
    //                                 finishButton={true}
    //                                 goBack={(newState) => setState(newState)}
    //                                 // noX={noX}
    //                             />
                        
    return (
        <Container style={{
            height: height, 
            width: isDesktop() ? "100%" : "calc(100% - 1.764rem)", 
            position: noX ? "relative" : "absolute",
            overflow: noX ? "visible" : "hidden",
            boxSizing: "border-box"
        }}>
            {/* {!isDesktop() && <div style={{height: "40px"}}/>} */}
            {card}
            {
              state > 0 &&
              <div style={{
                position: "absolute",
                top: isDesktop() ? "-1rem" : "1rem", 
                left: isDesktop() ? "0" :  "1rem"}}>
                <NavigationBar goBack={(e) =>{
                  setState(state - 1);
                  }} />
            </div>}
        </Container>
    )
}

export default VScriptInfo;