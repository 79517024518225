import { call, takeEvery, put } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';
import { GenericParserHttp } from '../../../../libs/Http';

const api = (id, token) =>
  GenericParserHttp.delete(`consumer/prescription/pharmacy/${id}`, { token });

function* deletePharmacy({ payload: { id, token } }) {
  try {
    yield call(api, id, token);
    yield put(actions.deletePharmacySuccess());
  } catch (err) {
    yield put(actions.deletePharmacyFailure(err));
  }
}

function* deletePharmacySaga() {
  yield takeEvery(actionTypes.deletePharmacy, deletePharmacy);
}

export { deletePharmacySaga };
