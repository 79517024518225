import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Alert from '../../libs/Alert';
import styles from './basicInputField.module.css';

class BasicInputField extends Component {
  static propTypes = {
    value: PropTypes.string,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func,
    onBlur: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    className: PropTypes.string,
    type: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    clearError: PropTypes.func,
  };
  static defaultProps = {
    value: '',
    type: 'text',
    clearError: () => {},
  };
  state = {
    focused: false,
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      Alert.error(nextProps.error, {
        onClose: this.props.clearError,
      });
    }
  }
  toggleFocus = () => {
    if (!this.state.focused) {
      Alert.close();
    }
    this.setState({ focused: !this.state.focused });
  };
  handleBlur = e => {
    this.toggleFocus();
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  render() {
    const {
      required,
      value,
      type,
      className,
      label,
      onChange,
      readOnly,
      onKeyDown,
      error,
      mobile,
      email,
      verified
    } = this.props;
    return (
      <div
        className={classnames(styles.item, className, {
          [styles.focused]: this.state.focused,
        })}
      >
        <label className={styles.label} htmlFor="basic-input">
          {label}
        </label>
        {!mobile ? (
          <input
            required={required}
            readOnly={readOnly}
            className={classnames(
              styles.input,
              { [styles.email]: type === 'email' },
              { [styles.error]: error },
            )}
            type={type}
            onChange={onChange}
            autoCapitalize={"off"}
            value={value}
            onBlur={this.handleBlur}
            onFocus={this.toggleFocus}
            ref={c => {
              this.inputField = c;
            }}
            onKeyDown={onKeyDown}
          />
        ) : (
          <div className={styles.container} onClick={this.props.verifyOnClick}>
            <input
              required={required}
              readOnly={true}
              autoCapitalize={"off"}
              className={classnames(
                styles.input,
                { [styles.email]: type === 'email' },
                { [styles.error]: error },
              )}
              type={type}
              onChange={onChange}
              value={value}
              onBlur={this.handleBlur}
              onFocus={this.toggleFocus}
              ref={c => {
                this.inputField = c;
              }}
              onKeyDown={onKeyDown}
            />
            <div className={verified ? styles.verified : styles.unverified}>{verified ? "Verified >" : "Not Verified >"}</div>
          </div>
        )}
      </div>
    );
  }
}

export default BasicInputField;
