import React from 'react';
import { ProtectedRoute, push } from '../utils';
import AddToHomeScreen from '../../components/LearnMorePopUp/AddToHomeScreen/AddToHomeScreen';
import styled from 'styled-components';
import WithContainer from 'libs/WithContainer';

const go = { addToHomeScreen: () => push('/addToHomeScreen') };

const HealthFeedContainer = styled.div`
  position: relative;
  width:100vw;
  height:100vh;
  background: #FFF;
`;
const FakeHeader = styled.div`
  position: absolute;
  top: 0;
  background: var(--header-gradient);
  color: var(--color-white);
  fill: var(--color-white);
  stroke: var(--color-white);
  width: 100%;
  height: 30vh;
  z-index:1;
`;

const FakeContent = styled.div`
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding: 1rem;
  background-color: #0000008A;
  z-index:2;
`;

const PopUpContainer = ({children}) => (
  <HealthFeedContainer>
    <FakeHeader />
    <FakeContent>
      {children}
    </FakeContent>
    
  </HealthFeedContainer>
);

const AddToHomeScreenRoutes = [
  <ProtectedRoute key="/addToHomeScreen" path="/addToHomeScreen" component={WithContainer(PopUpContainer, AddToHomeScreen)} />,
];

export default AddToHomeScreenRoutes;
export { go };