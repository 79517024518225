import { useComponentDidMount } from 'libs/newHttp';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as Line } from 'svgs/blueUnderline.svg';
import InfoDropdown from '../components/InfoDropdown';
import EnglishConsent from './components/Consent/EnglishConsent';
import SimplifiedChineseConsent from './components/Consent/SimplifiedChineseConsent';

interface ButtonProps {
    enabled: boolean;
}
interface Props {
    onNext: () => void;
}

const Container = styled.div`
    overflow: scroll;
`
const Card = styled.div`
    border-radius: 4px;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    padding-top: 1rem;
    overflow: hidden;
    padding-bottom: 100px;
`
const AgreeButtonContainer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: white;
    border: 1px solid #DADADA;
    height: 100px;
    width: 100%;
    padding: 1rem 1.3rem;
`
const AgreeButton = styled.div`
    color: white;
    font-size: 21px;
    background-color: ${(props: ButtonProps) => props.enabled ? "#07A3C8" : "#DADADA"};
    height: 100%;
    border-radius: 4px;
    line-height: 3;
    text-align: center;
    transition: all 0.3s;
`
const LanguageContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin: 0rem 0.5rem;
    margin-bottom: 0.5rem;
`
const Language = styled.div`
    font-size: 15px;
    color: #07A3C8;
    text-align: left;
    margin: 0rem 0.5rem;
`

const LabResultsConsent: React.FC<Props> = observer(({onNext}) => {
    const [agreeEnabled, setAgreeEnabled] = useState(false)
    const [language, setLanguage] = useState(0);

    const handleScroll = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop <= (e.target.clientHeight + 70);
        if (bottom) {
            setAgreeEnabled(true);
        } else {
            setAgreeEnabled(false);
        }
    }

    return (
        <Container onScroll={(e: any) => handleScroll(e)}>
            <Card>
                <LanguageContainer>
                    <Language 
                        style={{opacity: language === 0 ? "1" : "0.5" }}
                        onClick={() => setLanguage(0)}    
                    >
                        English
                    </Language>
                    <Language 
                        style={{opacity: language === 1 ? "1" : "0.5" }}
                        onClick={() => setLanguage(1)}
                    >
                        中文
                    </Language>
                </LanguageContainer>
                {language === 0 &&
                    <EnglishConsent />
                }
                {language === 1 &&
                    <SimplifiedChineseConsent />
                }
            </Card>
            <AgreeButtonContainer>
                <AgreeButton enabled={agreeEnabled} onClick={agreeEnabled ? onNext : () => { return }}>{language === 0 ? "I Agree" : "我同意"}</AgreeButton>
            </AgreeButtonContainer>
        </Container>
    )
})

export default LabResultsConsent;