import { takeEvery } from 'redux-saga/effects';
// eslint-disable-next-line max-len
import { actionTypes as profileActionTypes } from '../../../components/UIContainer/MobileUI/MainShell/actions/profileActions';
import { actionTypes as registerActionTypes } from '../../PatientDetails/actions/actions';
import { actionTypes as guardianActionTypes } from '../../PatientDetails/actions/guardianActions';

function* changePhone(action) {
  // const session = yield select(sessionState([]));
  // const token = yield select(getToken);
  // const isBookingForOther = yield select(isBookingForSomeoneElse);
  // const key = isBookingForOther ? 'intiatorMobile' : 'mobile';
  // const sessionData = { ...session, token, [key]: action.payload.mobile };
}

function* changePhoneSaga() {
  yield takeEvery(
    [
      registerActionTypes.changePhone,
      profileActionTypes.changePhone,
      guardianActionTypes.changePhone,
    ],
    changePhone,
  );
}

export { changePhoneSaga };
