import React, { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as DownArrow } from 'svgs/dropdownArrowDown.svg';
import { ReactComponent as UpArrow } from 'svgs/dropdownArrowUp.svg';

const Container = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;
`
const TitleContainer = styled.div`
    border: 1px solid #F0F0F0;
    padding: 0.3rem 1rem;
    position: relative;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`
const Title = styled.div`
    font-size: 15px;
    color: #585858;
    text-align: left;
`
const Subtitle = styled.div`
    font-size: 12px;
    color: #939393;
    text-align: left;
`
const TextContainer = styled.div`
    opacity: ${(props: TextProps) => props.visible ? "1" : "0"}
    max-height: ${(props: TextProps) => props.visible ? "1000px" : "0px"}
    float: left;
    margin: 0.3rem;
    transition: max-height 0.3s ease-in-out;
`
const Text = styled.div`
    color: #6C6C6C;
    text-align: left;
    margin: 0.5rem 1rem;
    font-size: 15px;
    font-family: inherit;
    white-space: pre-line;
`

interface TextProps {
    visible: boolean;
    length: number;
}
interface Props {
    title: string;
    subtitle: string;
    text: string;
}

const InfoDropdown: React.FC<Props> = ({title, subtitle, text}) => {
    const [showText, setShowText] = useState(false);

    return (
        <Container>
            <TitleContainer onClick={() => setShowText(!showText)}>
                <div style={{display: "flex", flexDirection: "column", width: "90%"}}>
                    <Title>{title}</Title>
                    <Subtitle>{subtitle}</Subtitle>
                </div>
                {!showText ? 
                    <DownArrow style={{marginLeft: "auto", height: "22px", width: "22px", flexShrink: 0}} /> 
                :
                    <UpArrow style={{marginLeft: "auto", height: "22px", width: "22px", flexShrink: 0}} /> 
                }
            </TitleContainer>
            <TextContainer visible={showText} length={text.length}>
                <Text>{text}</Text>
            </TextContainer>
        </Container>
    )
}

export default InfoDropdown;