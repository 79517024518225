import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { ReactComponent as Cross } from 'svgs/identityCrossRed.svg';
import { ReactComponent as Arrow } from 'svgs/roundArrow.svg';
import CardButton from '../CardButton';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    overflow: visible;
`

const Card = styled.div`
    width: 100%;
    height: 160px;
    background-color: white;
    margin-top: 1rem;
    box-shadow: 0px 3px 6px lightgrey;
`
const FlexH = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 2rem;
`
const Text = styled.div`
    font-size: 21px;
    color: #FF6A65;
    margin-left: 10px;
    width: 200px;
`

interface IProps {
    identityVerified: boolean,
    patientHasPractices: boolean,
    onClick: () => void
}

const IdentityStatusLabelMobile: React.FC<IProps> = observer(({identityVerified, patientHasPractices, onClick}) => {
    //  Decide state based on verification status and patient practices.
    const state = identityVerified ? 2 : patientHasPractices ? 1 : 0
    return(
        <Container data-test={"identity-status-label-component"}>
            { state === 0 &&
                <Card data-test={"identity-not-verified-component"}>
                    <FlexH>
                        <Cross />
                        <Text><b>Local</b> Identity at practice <b>Not</b> Verified</Text>
                    </FlexH>
                    <CardButton text={"Start Local Identity Verification"} onClick={() => onClick()} />
                </Card>
            }
            { state === 1 &&
                <FlexH data-test={"identity-not-verified-empty-component"}>
                    <Cross />
                    <Text><b>Local</b> Identity at practice <b>Not</b> Verified</Text>
                </FlexH>
            }
        </Container>
    )
})

export default IdentityStatusLabelMobile;