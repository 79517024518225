import { Header } from 'components/UIContainer/MobileUI/header';
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import pic from 'svgs/patientSilhouette.svg'
import { ReactComponent as Card } from 'svgs/creditcardDesktop.svg';
import { ReactComponent as Arrow } from 'svgs/blueLeftLineArrow.svg';
import styles from './../../vScript/ConfirmRequest/confirmRequest.module.css';
import { motion } from "framer-motion"
import { ReactComponent as HeartIcon } from 'svgs/heart.svg';
import { actions as verifyActions } from '../MobileVerification/actions/codeConfirmationActions';
import Csc from 'images/csc.png';
import { actions } from './actions/actions';

import Loading from 'components/loadingSpinner';
import { go } from 'routes';
import { bindActionCreators } from 'redux';
import { isDesktop } from 'config';

import { actions as interestActions } from '../ExpressionInterest/actions/actions'
import { usePaymentsStore } from '../../store/mobx/mobx'
import { LOADING_STATUS } from '../../libs/newHttp';
import { observer } from 'mobx-react-lite';

const Container = styled.div`
    background-color: white;
    overflow: scroll;
    position: relative;
`
const InnerContainer = styled.div`
    color: #6C6C6C;
    margin: 1rem;
    height: 100vh;
`
const PicContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 100px;
`
const DoctorName = styled.div`
    width: 100%;
    text-align: center;
    font-size: 21px;
`
const PracticeName = styled.div`
    font-size: 15px;
    font-weight: lighter;
    text-align: center;
    width: 100%;
    margin: 0.5rem 0rem;
    margin-bottom: 1.5rem;
`
const ServiceTitle = styled.div`
    font-size: 15px;
    font-weight: normal;
    text-align: left;
    margin: 10px 0px;
    width: 100%;
`
const DetailsContainer = styled.div`
    background-color: #F9F9F9;
    color: #585858;
    padding: 1rem;
    margin-bottom: 2rem;
`
const SelectPayment = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-grey-2);
    border-top: 1px solid var(--color-grey-2);
    cursor: pointer;
    height: 3.824rem;
`
const PaymentText = styled.div`
    margin-left: 1rem;
`
const IconContainer = styled.div`
    margin: 0 1.176rem 0 auto;
    position: relative;
`
const CardNumber = styled.div`
    font-size: 15px;
    margin-top: 0.824rem;
    margin-left: 0.5rem;
    position: absolute;
`
const Pricing = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 15px;
    padding: 15px 0;
`
const PriceItem = styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: lighter;
    margin: 0.5rem 0;
`
const Price = styled.div`
    margin-right: 1rem;
`
const Service = styled.div`
    margin-left: 1rem;
`
const Policy = styled.div`
    font-size: 11px;
    color: #939393;
    test-align: left;
    margin: 1rem 0rem;
    cursor: pointer;
`
const ButtonBox = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    height: 5.294rem;
    width: 100%;
    background-color: white;
    box-shadow: 0 -3px 6px #00000029;
`
const Button = styled.button`
    height: 3.529rem;
    width: 14.118rem;
    margin: 0.882rem auto;
    font-size: 1rem;
    background-color: #23C373;
    position: relative;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    appearance: none;
    box-shadow: none;
    outline: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: white;
`
const FeeSubsidy = styled.div`
    font-size: 10px;
    display: flex;
    color: #939393;
    font-weight: normal;
    margin-left: 1rem;
    cursor: pointer;
`
const PrepaidPage = observer(({ session, practiceProfile, isFetching, token, sendCode }) => {
    const {
        AmountToPay,
        ServiceName,
        ProviderName,
        PmsUserId,
        FormattedTimeLong,
        ServiceDescription,
        TransactionFee,
        TransactionFeeSubsidised,
        ServicePrice,
        TermsOfService,
        IsCscHolder,
        HasCredit,
        SessionId,
    } = session;

    const {
        Doctors,
        Name,
        UrlName,
    } = practiceProfile

    const [subsidised, setSubsidised] = useState(false);
    const [modal, setModal] = useState(false);
    const [policy, setPolicy] = useState(false);
    const paymentStore = usePaymentsStore();

    const defaultCardDigits = useCallback(() => {
      if (paymentStore.cardList?.length > 0) {
        const card = paymentStore.cardList[0];
        return card?.Last4 ?? ''
      }
      return ''
    }, [paymentStore])

    useEffect(() => {
        paymentStore.getCards();
    },[]);

    useEffect(() => {
        defaultCardDigits();
    }, [defaultCardDigits, paymentStore, paymentStore.cardList, paymentStore.cardsLoadingStatus]);

    const Modal = (subsidised) => {
        let content;
        let heading;
        if (subsidised) {
            heading = "Why is the fee subsidised?";
            content = "Your practice has subsidised this fee for you to make it easier and more affordable for you to order your repeat scripts online.";
        } else {
            heading = "Why is there a transaction fee?";
            content = "The transaction fee is a one-time fee that is required to process this transaction and is generally 3.5% of the total value. It is refunded if the practice refunds your payment.";
        }

        if (policy) {
            heading = "Terms of Service";
            content = TermsOfService;
        }

        return (
            <motion.div
                className={styles.modalOverlay}
                transition={{ ease: "easeOut", duration: 0.5 }}
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
            >
                <motion.div
                    className={styles.modal}
                    style={{ width: "95%", marginBottom: "1.5rem" }}
                    transition={{ ease: "easeOut", duration: 1 }}
                    animate={{ opacity: 1, y: "0" }}
                    initial={{ opacity: 0, y: "100%" }}
                >
                    <div className={styles.modalContent}>
                        {/* Header container */}
                        {subsidised && (
                            <div className={styles.modalHeart} style={{ margin: "auto", marginBottom: "1rem" }}>
                                <HeartIcon />
                            </div>
                        )}
                        <div className={styles.modalHeader} style={{ padding: "0rem 1rem", paddingBottom: "0.6rem", fontSize: "15px", textAlign: "center" }}>
                            {heading}
                        </div>
                        {/* Content and button container */}
                        <div className={styles.modalBody}>
                            {content}
                        </div>
                        <button onClick={() => setModal(false)} className={styles.closeButton}>
                            Close
                        </button>
                    </div>
                </motion.div>
            </motion.div>
        )
    }

    const DoctorImage = Doctors.find((dr) => dr.PmsUserId === PmsUserId).Picture;
    let isFetchingPaymentCard = paymentStore.cardsLoadingStatus === LOADING_STATUS.LOADING;
    let hasPaymentCard = paymentStore.cardList?.length > 0;
    return (
        <Container style={{ height: isDesktop() ? "auto" : "calc(100vh - 5.294rem)", overflow: isDesktop() ? "auto" : "scroll" }}>
            {!isDesktop() && <Header firstLine={" "} goBackFn={() => go.reason(UrlName)} showHelp noAfter />}
            <Loading isFetching={isFetching || isFetchingPaymentCard} />
            <InnerContainer style={{ height: isDesktop() ? "auto" : "100vh", overflow: isDesktop() ? "auto" : "scroll" }}>
                {modal && Modal(subsidised)}
                {!isDesktop() &&
                    <div>
                        <PicContainer>
                            <img src={DoctorImage ? DoctorImage : pic} style={{ width: "89px", height: "89px", position: "absolute", borderRadius: "50px" }} alt="doctor" />
                        </PicContainer>
                        <DoctorName>{ProviderName}</DoctorName>
                        <PracticeName>{Name}</PracticeName>
                    </div>
                }
                <ServiceTitle>Service:</ServiceTitle>
                <DetailsContainer>
                    <div style={{ fontWeight: "bold" }}>{ServiceName}</div>
                    <div style={{ fontWeight: "bold", margin: "5px 0px" }}>{FormattedTimeLong}</div>
                    <div style={{ fontWeight: "lighter" }}>{ServiceDescription}</div>
                </DetailsContainer>
                {AmountToPay !== 0 && (<SelectPayment onClick={() => { 
                    hasPaymentCard
                    ? go.appointmentCards(UrlName)  //open patient cards
                    : go.appointmentPayments(UrlName);  //add new card
                }}>
                    <PaymentText>
                        {hasPaymentCard ? "Payment Method" : "Select Payment Method"}
                    </PaymentText>
                    {hasPaymentCard &&
                        <IconContainer>
                            <CardNumber>{defaultCardDigits()}</CardNumber>
                            <Card style={{
                                height: "33px",
                                width: "50px",
                                fill: "white",
                                stroke: "#C9C9C9"
                            }} />
                        </IconContainer>
                    }
                    <Arrow style={{ stroke: "none", height: "1.117rem", transform: "rotate(180deg)", marginRight: "1rem" }} />
                </SelectPayment>)}

                <Pricing>
                    <PriceItem>
                        <Service style={{ position: "relative" }}>
                            {ServiceName}
                            {IsCscHolder && <img style={{ height: "15px", marginLeft: "0.5rem", position: "absolute" }} src={Csc} alt="CSC holder" />}
                        </Service>
                        <Price>${ServicePrice / 100}</Price>
                    </PriceItem>
                    <PriceItem style={{ marginTop: "0rem" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Service>Transaction Fee</Service>
                            {TransactionFeeSubsidised &&
                                <FeeSubsidy
                                    onClick={() => {
                                        if (isDesktop()) {
                                            go.appointmentFeeInfo(UrlName);
                                            return;
                                        }
                                        setModal(true);
                                        setPolicy(false);
                                        setSubsidised(true);
                                    }}
                                >
                                    Fee is subsidised.
                                    <div style={{ color: "#07A3C8", marginLeft: "5px" }}>
                                        Learn why
                                    </div>
                                    <div style={{ position: "relative", transform: "translate(6px, -2px)" }}>
                                        <HeartIcon style={{ width: "19px", height: "16px", position: "absolute" }} />
                                    </div>
                                </FeeSubsidy>
                            }
                        </div>
                        <Price>${TransactionFee / 100}</Price>
                    </PriceItem>
                    <PriceItem>
                        <Service style={{ fontSize: "19px", fontWeight: "normal" }}>Total</Service>
                        <Price style={{ fontSize: "19px", fontWeight: "normal" }}>${AmountToPay / 100}</Price>
                    </PriceItem>
                    {HasCredit &&
                        <div style={{ margin: "0rem 1rem", color: "#23C373" }}>Your appointment will be paid with your account credit</div>
                    }
                </Pricing>
                <Policy>
                    This payment will be credited to your account with this health centre.
                    Additional fees may be incurred during your visit, according to practice policies
                </Policy>
                <Policy>
                    Note: This practice may charge a fee if you do not attend.
                </Policy>
                <Policy style={{ color: "#07A3C8" }} onClick={() => {
                    if (!isDesktop()) {
                        setPolicy(true);
                        setModal(true);
                        setSubsidised(false);
                        return;
                    }
                    go.appointmentTerms(UrlName, TermsOfService);
                    return;
                }}>
                    View the practice's Terms of Service here.
                </Policy>
            </InnerContainer>
            {!isDesktop() && !modal &&
                <ButtonBox>
                    <Button
                        style={{ backgroundColor: (hasPaymentCard || AmountToPay === 0) ? "#23C373" : "#C9C9C9" }}
                        onClick={() => {
                            if (hasPaymentCard || AmountToPay === 0) {

                                //If patient is interested in participating in research, submit interest
                                //const isInterested = patientStore.canExpressInterest;
                                //var expression = { "sessionId": SessionId, "interested": isInterested };
                                //sendExpressionOfInterest(expression);

                                if (token) go.processAppointment(UrlName);
                                else {
                                    sendCode(SessionId);
                                    go.verification(UrlName);
                                }
                            }
                        }}
                    >
                        Confirm Booking - ${AmountToPay / 100}
                    </Button>
                </ButtonBox>
            }
            {isDesktop() &&
                <Button
                    style={{
                        backgroundColor: (hasPaymentCard || AmountToPay === 0) ? "#23C373" : "#C9C9C9",
                        margin: isDesktop() ? "0rem auto" : "0.882rem auto",
                    }}
                    onClick={() => {
                        if (hasPaymentCard || AmountToPay === 0) {
                            if (token) go.processAppointment(UrlName);
                            else {
                                sendCode(SessionId);
                                go.verification(UrlName);
                            }
                        }
                    }}
                >
                    Confirm Booking - ${AmountToPay / 100}
                </Button>

            }
        </Container>
    )
})

const mapStateToProps = state => ({
    session: state.checkEnrollmentReducer.session,
    practiceProfile: state.practiceProfileReducer.profile,
    card: state.vAppointmentPaymentsReducer.card,
    isFetching: state.vAppointmentPaymentsReducer.isFetching,
    token: state.loginReducer.token,
})

const mapDispatchToProps = dispatch => ({
    getCards: bindActionCreators(actions.getCards, dispatch),
    sendCode: bindActionCreators(verifyActions.sendToConfirm, dispatch),
    sendExpressionOfInterest: bindActionCreators(interestActions.sendExpressionOfInterest, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(PrepaidPage);
