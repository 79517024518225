import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import throttle from 'lodash/throttle';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isValidDate, isUnderAge } from '../../../../libs/Dates';
import { isValidMobile } from '../../../../libs/ValidBasicInfo';
import Alert from '../../../../libs/Alert';
import { isDesktop } from '../../../../config';
import BasicInputField from '../../../../components/BasicInputField/index';
import MobileTitle from '../MobileTitle/index';
import DobInput from '../DobInput/index';
import styles from './patientDetails.module.css';
import { actions as patientDetailsActions } from '../../actions/actions';
import { capitalise } from 'libs/misc';

class PatientDetails extends Component {
  static propTypes = {
    addDetails: PropTypes.func.isRequired,
    openGuardianDetails: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    dateOfBirth: PropTypes.string,
    mobile: PropTypes.string,
    onValid: PropTypes.func.isRequired,
    hasGuardian: PropTypes.bool,
    hideTnC: PropTypes.func,
    smokingRecallId: PropTypes.string,
    patientDetails: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      dateOfBirth: PropTypes.string,
      mobile: PropTypes.string,
    }),
    fetchPatientDetailForSmokingAppointment: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.validateForm = throttle(this.validateForm.bind(this), 300, {
      leading: true,
    });
  }

  state = {
    firstName: this.props.firstName || '',
    lastName: this.props.lastName || '',
    dateOfBirth: this.props.dateOfBirth
      ? moment(this.props.dateOfBirth, ['DD-MM-YYYY', 'YYYY-MM-DD']).format(
          'YYYY-MM-DD',
        )
      : '',
    mobile: this.props.mobile || '',
    firstNameError: null,
    lastNameError: null,
    dobError: null,
    mobileError: null,
  };

  componentDidMount() {
    this.validateForm();
    if (this.props.dateOfBirth && isDesktop()) {
      if (!this.props.isLoggedIn && isUnderAge(this.props.dateOfBirth)) {
        this.setState({ dobError: 'You must be at least 16 years old.' });
      }
    }
    if (this.props.smokingRecallId && !this.props.patientDetails.firstName) {
      this.props.fetchPatientDetailForSmokingAppointment();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.validateForm) { this.validateForm(); }
    if (
      nextProps.hasGuardian === true &&
      nextProps.hasGuardian !== this.props.hasGuardian
    ) {
      this.validateForm();
    }
    if (
      nextProps.popUpOpen !== this.props.popUpOpen &&
      nextProps.popUpOpen === false &&
      !nextProps.hasGuardian
    ) {
      if (isUnderAge(this.props.dateOfBirth) && !this.props.isLoggedIn) {
        this.setState({ dobError: 'You must be at least 16 years old.' });
      }
    }
  }

  componentDidUpdate(prevProps) {
    // This part is for handling this:
    // We want auto-populate the fields for smoking cessation
    if (
      this.props.smokingRecallId &&
      this.props.patientDetails &&
      this.props.patientDetails !== prevProps.patientDetails
    ) {
      this.setState(
        {
          firstName: this.props.firstName || '',
          lastName: this.props.lastName || '',
          dateOfBirth: this.props.dateOfBirth || '',
          mobile: this.props.mobile || '',
          // if we don't set the dobError to null
          // then the validateForm() will always be false
          dobError: null,
        },
        () => {
          this.validateForm();
        },
      );
    }
  }

  componentWillUnmount() {
    this.validateForm.cancel();
  }
  getInfo = () => ({
    firstName: capitalise(this.state.firstName),
    lastName: capitalise(this.state.lastName),
    dateOfBirth: this.state.dateOfBirth,
    mobile: this.isValidMobile() ? this.state.mobile : '',
  });
  setDobError = error => this.setState({ dobError: error }, this.validateForm);
  clearErrors = () => {
    this.setState({
      firstNameError: null,
      lastNameError: null,
      mobileError: null,
    });
  };
  showGuardian = () => {
    if (!this.isValidFirstName()) {
      this.setState({ firstNameError: 'Invalid First Name.' });
    } else if (!this.isValidLastName()) {
      this.setState({ lastNameError: 'Invalid Last Name.' });
    } else if (isValidDate(this.state.dateOfBirth, true)) {
      this.props.onValid(true);
      this.props.addDetails(this.getInfo());
      this.setState({ dobError: null });
      if (!this.props.isLoggedIn) {
        this.props.openGuardianDetails();
        this.props.hideTnC(false);
      }
    }
  };
  handleChange = key => e => {
    this.setState({ [key]: e.target.value }, () => this.validateForm());
  };
  handleDateChange = dateOfBirth => {
    this.setState({ dateOfBirth });
    if (!this.props.isLoggedIn && isUnderAge(dateOfBirth)) {
      this.setState({ dobError: 'You must be at least 16 years old.' });
      if (isDesktop()) this.props.hideTnC(true);
    } else if (isDesktop()) {
      this.props.hideTnC(false);
    }
    this.validateForm();
  };
  validateForm() {
    const isValid =
      this.isValidMobile() &&
      (this.state.dobError === null ||
        (this.props.hasGuardian &&
          this.state.dobError === 'You must be at least 16 years old.')) &&
      this.isValidFirstName() &&
      this.isValidLastName();
    this.props.onValid(isValid);
    return isValid;
  }
  isValidMobile = () =>
    isValidMobile(this.state.mobile, !isUnderAge(this.state.dateOfBirth));
  isValidDate = date =>
    isValidDate(date, !!this.props.isLoggedIn || this.props.hasGuardian);
  isValidFirstName = () => this.state.firstName.length > 0;
  isValidLastName = () => this.state.lastName.length > 0;
  checkErrors = () => {
    if (!this.isValidFirstName()) {
      this.setState({ firstNameError: 'Invalid First Name.' });
    } else if (!this.isValidLastName()) {
      this.setState({ lastNameError: 'Invalid Last Name.' });
    } else if (this.state.dobError) {
      Alert.error(this.state.dobError);
    } else if (!this.isValidMobile()) {
      this.setState({
        mobileError: 'Invalid Mobile, Please enter a valid NZ mobile number.',
      });
    } else return true;
    return false;
  };
  submit = () => {
    if (this.checkErrors()) {
      this.props.addDetails(this.getInfo());
      if (isUnderAge(this.state.dateOfBirth) && !this.props.isLoggedIn)
        this.props.openGuardianDetails();
    }
  };
  render() {
    return (
      <form
        ref={c => {
          this.form = c;
        }}
        className={classnames(styles.form, {
          [styles.completed]: this.props.isValid,
        })}
        name="detailsForm"
        noValidate
      >
        {!isDesktop() ? (
          <MobileTitle who="Patient" isValid={this.props.isValid} />
        ) : (
          <div className={styles.title}>
            Patient Details
          </div>
        )}
        <BasicInputField
          label="First Name"
          value={this.state.firstName}
          className={styles.item}
          onChange={this.handleChange('firstName')}
          error={this.state.firstNameError}
          clearError={this.clearErrors}
        />
        <BasicInputField
          value={this.state.lastName}
          label="Last Name"
          className={styles.item}
          onChange={this.handleChange('lastName')}
          error={this.state.lastNameError}
          clearError={this.clearErrors}
          required
        />
        <DobInput
          containerClassName={styles.item}
          value={this.state.dateOfBirth}
          onChange={this.handleDateChange}
          setError={this.setDobError}
          isUnderAge={
            this.state.dobError === 'You must be at least 16 years old.'
          }
          onClick={this.showGuardian}
          guardianOpen={!isDesktop() && this.props.hasGuardian}
        />
        {(isDesktop() ||
          this.state.dobError !== 'You must be at least 16 years old.') && (
          <BasicInputField
            value={this.state.mobile}
            label={
              isUnderAge(this.state.dateOfBirth)
                ? 'Mobile number (optional)'
                : 'Mobile number'
            }
            type="tel"
            className={styles.item}
            onChange={this.handleChange('mobile')}
            error={this.state.mobileError}
            clearError={this.clearErrors}
          />
        )}
      </form>
    );
  }
}

const mapStateToProps = state => ({
  firstName: state.patientDetailsReducer.patientDetails.firstName,
  lastName: state.patientDetailsReducer.patientDetails.lastName,
  mobile: state.patientDetailsReducer.patientDetails.mobile,
  dateOfBirth: state.patientDetailsReducer.patientDetails.dateOfBirth,
  smokingRecallId: state.practiceAppointmentReducer.smokingRecallId,
  patientDetails: state.patientDetailsReducer.patientDetails,
});
const mapDispatchToProps = dispatch => ({
  fetchPatientDetailForSmokingAppointment: bindActionCreators(
    patientDetailsActions.fetchPatientDetailForSmokingAppointment,
    dispatch,
  ),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true },
)(PatientDetails);
