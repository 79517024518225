import React from 'react';
import styles from './cardBanner.module.css';
import tick from '../../../svgs/identityTick.svg';

const CardBanner = (props) => {
    return(
        <div className={styles.container}>
            <img src={tick} alt={"tick"} />
            <br />

            <div className={styles.initialText}>
                {props.text}
            </div><br />< br/>

            {props.onClick && 
            <div>
                <div className={styles.learnButton}>Learn More</div>.
                <br />
            </div>}
        </div>
    );
}

export default CardBanner;