import { call, takeEvery, put } from 'redux-saga/effects';
import { keys, map, compose } from 'lodash/fp';
import moment from 'moment';
import { getSmokingRecallIdFromURL } from '../../App/helpers/tokenHelper';
import { actions, actionTypes } from '../actions/appointmentActions';
import { GenericParserHttp } from '../../libs/Http';

const api = (orgId, startDay, days, smokingRecallQueryString) => {
  const dayString = moment(startDay).format('YYYY-MM-DD');
  return GenericParserHttp.get(
    `consumer/appointment/${orgId}/${dayString}/${days}${smokingRecallQueryString}`,
  )
    .then(({ body }) => body)
    .then(
      map(doctor => {
        const parseSlots = slots =>
          compose(
            map(s => ({ Guid: s, Time: slots[s] })),
            keys,
          )(slots);
        const multiDays = compose(
          map(m => ({ Day: m, Slots: parseSlots(doctor.AvailableSlots[m]) })),
          keys,
        )(doctor.AvailableSlots);
        return { ...doctor, AvailableSlots: multiDays };
      }),
    );
};

function* getMultiDayAppointments({
  payload: { practiceId, startDay, days, doctorName },
}) {
  const { smokingRecallQueryString } = yield getSmokingRecallIdFromURL();

  try {
    const doctors = yield call(
      api,
      practiceId,
      startDay,
      days,
      smokingRecallQueryString,
    );
    yield put(actions.fetchMultiDayAppointmentsSuccess(doctors, doctorName));
  } catch (err) {
    yield put(actions.fetchMultiDayAppointmentsFailure(err));
  }
}

function* getMultiDayAppointmentsSaga() {
  yield takeEvery(
    actionTypes.fetchMultiDayAppointments,
    getMultiDayAppointments,
  );
}

export { getMultiDayAppointmentsSaga };
