import React, { useEffect } from 'react';
import { useAxios } from 'apiHooks/useAxios';
import { AxiosResult } from 'apis/axios';

export interface CreateDependantOutDto {
    
}

interface CreateDependantInDto {
    id?: string;
    document?: File;
    firstName: string;
    lastName: string;
    nickName?: string;
    relationship: string;
    dateOfBirth: string;
    fileCount?: string;
    avatar?: File;
}

export const useAddDependant = (
    {
        id,
        firstName,
        lastName,
        nickName,
        document,
        dateOfBirth,
        relationship,
        fileCount,
        avatar,
    }: CreateDependantInDto): AxiosResult<CreateDependantOutDto> => {
        
    const sendData = new FormData();
    id && sendData.append('id', id);
    sendData.append('firstName', firstName);
    sendData.append('lastName', lastName);
    nickName && sendData.append('nickName', nickName);
    sendData.append('dateOfBirth', dateOfBirth);
    document && sendData.append('document', document, document.name);
    sendData.append('relationship', relationship);
    sendData.append('fileCount', fileCount!);
    avatar && sendData.append('avatar', avatar, avatar.name);
    
    const [{data, loading, error}, execute] = useAxios<CreateDependantOutDto>(
        {
            url: '/consumer/carecircle/dependants/',
            method: 'POST',
        },
        {
            manual: true
        }
    );

    useEffect(() => {
        execute({
            data: sendData,
            headers: {
                ContentType: 'multipart/form-data'
            }
        });
    },[firstName, lastName, dateOfBirth])
    return {
        data,
        loading,
        error
    }
}