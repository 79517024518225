import React, { useState, useRef, useEffect } from 'react';
import { Observer } from 'mobx-react-lite';

import styles from './pinCode.module.css';
import PinCodeButton from '../PinCodeButton';
import fourPin from '../../../svgs/4Pin.svg'
import sixPin from '../../../svgs/6Pin.svg';
import greyLine from '../../../svgs/greyLine.svg';
import Alert from '../../../libs/Alert';
import Loading from '../../../components/loadingSpinner';
import { go } from '../../../routes/index';

import "../../../../node_modules/text-security/dist/text-security.css";
import { useAccountStore, useIdentityStore, useSecurityStore } from 'store/mobx/mobx';
import { ReactComponent as Lock } from 'svgs/blueLock.svg';
import { useComponentDidMount } from 'libs/newHttp';


const PinCode = (...props) => {
    const store = useIdentityStore();
    const accStore = useAccountStore();
    const secStore = useSecurityStore();
    const [whatToRender, setWhatToRender] = useState(4);
    const [pin, setPin] = useState("");
    const [verify, setVerify] = useState(false);
    const [is4Pin, setIs4Pin] = useState(false);
    const [is6Pin, setIs6Pin] = useState(false);

    const boxOne = useRef();
    const boxTwo = useRef();
    const boxThree = useRef();
    const boxFour = useRef();
    const boxFive = useRef();
    const boxSix = useRef();
    const myRef = useRef();

    const guessable = [
        "1111", "2222", "3333", "4444", "5555", 
        "6666", "7777", "8888", "9999", "0123",
        "1234", "2345", "3456", "4567", "5678",
        "6789", "012345", "123456", "234567",
        "345678", "456789", "111111", "222222", 
        "333333", "444444", "555555", "666666", 
        "777777", "888888","999999", "0000", "000000"
    ]
    const [boxesV, setBoxesV] = useState(["", "", "", "", "", ""]);
    const [boxes, setBoxes] = useState([boxOne, boxTwo, boxThree, boxFour, boxFive, boxSix]);
    
    const verifyCheck = (_pin) => {
        if (pin === _pin) { return true; }
        return false;
    }
      
    const onChange = (id, e) => {
        let value = e.target.value;
        let _boxes = boxesV;
        let isNum = /^\d+$/.test(value)

        _boxes[id] = isNum ? value : "";

        if (id < 3 && isNum && is4Pin) { boxes[id + 1].current.focus() }
        else { e.target.blur(); }
        if (id < 5 && isNum && is6Pin) { boxes[id + 1].current.focus() }
        else { e.target.blur(); }

        setBoxesV(_boxes);

        if (id === 3 && isNum && is4Pin) { 
            let pinCode = _boxes[0] + _boxes[1] + _boxes[2] + value;

            if(verify) { 
                if (verifyCheck(pinCode)) {
                    //GO NEXT
                    //if (this.props.isVerifiedAtPractice) { console.log("1")/* go privacy */ }
                    //else if (this.props.isPhotoSetUp) { console.log("2")/* go privacy */ }
                    //this.props.setPin(pinCode);
                    if (props[0].skipPhoto && props[0].goNext) {
                        store.setPin(() => {
                            setTimeout(() => {
                                secStore.editSecuritySettings({
                                    SignIn2StepsVerification: true,
                                    NotifyUserAfter5FailedAttempts: false,
                                    SuspendAccountAfter10FailedAttempts: false,
                                }, true)
                                props[0].goNext();
                            }, 2000);
                        });
                        return;
                    } else store.setPin(() => {
                        secStore.editSecuritySettings({
                            SignIn2StepsVerification: true,
                            NotifyUserAfter5FailedAttempts: false,
                            SuspendAccountAfter10FailedAttempts: false,
                        }, true)
                        go.identityPhoto()
                    });
                    return;
                } else {
                    //error
                    Alert.error("The PIN code did not match. Try again.");
                    setWhatToRender(1);
                    accStore.setPinCodeFlowState(1);
                    setBoxesV(["", "", "", "", "", ""]);
                    setVerify(false);
                    setPin("");
                    setIs6Pin(false);
                    setIs4Pin(true);

                    
                    boxOne.current.value = "";
                    boxTwo.current.value = "";
                    boxThree.current.value = ""; 
                    boxFour.current.value = ""; 
                    

                    boxOne.current.focus()

                    return;
                }
            }

            if (pinCode.length < 4) { return; }
            if (isPinValid(pinCode))  {
                store.addPin(pinCode);
                setWhatToRender(3);
                accStore.setPinCodeFlowState(3);

                setPin(pinCode);
                setVerify(true);
                setBoxesV(["", "", "", "", "", ""])

                boxOne.current.value = "";
                boxTwo.current.value = "";
                boxThree.current.value = ""; 
                boxFour.current.value = ""; 

                boxOne.current.focus()
            } else {
                setWhatToRender(2);
                accStore.setPinCodeFlowState(2);

                setPin(pinCode);
                boxOne.current.focus();
                store.addPin(pinCode);
            }
        }

        if (id === 5 && isNum && is6Pin) { 
            let pinCode = boxesV[0] + boxesV[1] + boxesV[2] + boxesV[4] + boxesV[5] + value;

            if(verify) { 
                if (verifyCheck(pinCode)) {
                    //GO NEXT
                    //if (this.props.isVerifiedAtPractice) { /* go privacy */ }
                    //else if (this.props.isPhotoSetUp) { /* go privacy */ }
                    //this.props.setPin(pinCode);
                    if (props[0].skipPhoto && props[0].goNext) {
                        store.setPin(() => {
                            setTimeout(() => {
                                secStore.editSecuritySettings({
                                    SignIn2StepsVerification: true,
                                    NotifyUserAfter5FailedAttempts: false,
                                    SuspendAccountAfter10FailedAttempts: false,
                                }, true)
                                props[0].goNext();
                            }, 2000);
                        });
                        return;
                    } else store.setPin(() => {
                        setTimeout(() => {
                            secStore.editSecuritySettings({
                                SignIn2StepsVerification: true,
                                NotifyUserAfter5FailedAttempts: false,
                                SuspendAccountAfter10FailedAttempts: false,
                            }, true)
                            go.identityPhoto()
                        }, 2000);
                    });
                    return;
                } else {
                    Alert.error("The PIN code did not match. Try again.");
                    //error
                    setWhatToRender(1);
                    accStore.setPinCodeFlowState(1);

                    setBoxesV(["", "", "", "", "", ""]);
                    setVerify(false);
                    setPin("");
                    setIs6Pin(true);
                    setIs4Pin(false);

                    boxOne.current.value = "";
                    boxTwo.current.value = "";
                    boxThree.current.value = ""; 
                    boxFour.current.value = ""; 
                    boxFive.current.value = ""; 
                    boxSix.current.value = "";
                    
                    boxOne.current.focus();

                    return;
                }
            }
            if (pinCode.length < 6) { return; }
            if (isPinValid(pinCode))  {
                store.addPin(pinCode);
                setWhatToRender(3);
                accStore.setPinCodeFlowState(3);

                setPin(pinCode);
                setVerify(true);
                setBoxesV(["", "", "", "", "", ""]);

                boxOne.current.value = "";
                boxTwo.current.value = "";
                boxThree.current.value = ""; 
                boxFour.current.value = ""; 
                boxFive.current.value = ""; 
                boxSix.current.value = "";

                boxOne.current.focus();
            } else {
                store.addPin(pinCode);
                setWhatToRender(2);
                accStore.setPinCodeFlowState(2);

                setPin(pinCode);
                boxOne.current.focus();
            }
        }
    }

    const onKeyDown = (id, e) => {
        if (e.key === "Backspace" && id > 0) {
            if (boxesV[id] === "") boxes[id - 1].current.focus()
        }
    }

    const isPinValid = (pin) => {
        if (guessable.find(x => x === pin)) { return false; }
        else { return true }
    }

    const renderBoxes = () => {
        let number = is4Pin ? 4 : 6;
        let arr = [];
        let autofocus = true;
        for (let i = 0; i < number; i++) {
            arr.push(
                <input
                    className={styles.input}
                    autoFocus={autofocus}
                    key={i}
                    type="tel"
                    maxLength="1" 
                    onKeyDown={(e) => { onKeyDown(i, e) }} 
                    ref={boxes[i]}
                    onChange={(e) => { onChange(i, e) }}
                    pattern="[0-9]*" 
                    inputMode="numeric"
                />
            );
            autofocus = false;
        }

        return (
            <form style={{padding: "0rem 3rem"}}>
                {arr.map((item) => item)}
            </form>
        )
    }

    useEffect(() => {
        setWhatToRender(accStore.pinCodeFlowState);
    })
    
    const renderComponent = () => {
        switch(accStore.pinCodeFlowState) {
            case 0:
                return(
                    <div className={styles.container} ref={myRef}>
                        {props[0].blueIcon &&
                            <Lock style={{margin: "2rem auto"}} />
                        }
                        <p>Set your security PIN. Two options:</p>
                        <PinCodeButton
                            text={"4 digit PIN"}
                            pic={fourPin}
                            onClick={() => {
                                setWhatToRender(1);
                                accStore.setPinCodeFlowState(1);

                                setIs4Pin(true);
                                setIs6Pin(false);
                            }}
                        />
                        <img src={greyLine} alt="line" style={{display: "inline-block", marginTop: "0rem"}} />
                        <PinCodeButton
                            text={"6 digit PIN"}
                            pic={sixPin}
                            onClick={() => {
                                setWhatToRender(1);
                                accStore.setPinCodeFlowState(1);

                                setIs4Pin(false);
                                setIs6Pin(true);
                            }}
                        />
                    </div>
                );
            case 1:
                return(   
                    <div ref={myRef} className={styles.container} style={{padding: "2rem"}}>
                        <Loading
                            iconClassName={styles.spinner}
                            containerClassName={styles.loading}
                            isFetching={store.loading}
                        >
                            <p>Enter security PIN code</p>
                            {renderBoxes()}                            
                        </Loading>
    
                    </div>                                 
                );
            case 2: 
                return (
                    <div className={styles.container} style={{padding: "2rem"}}>
                        <div className={styles.guess}>This PIN Code Can Be Easily Guessed</div>
                        <p className={styles.text}>Your PIN code will be used to unlock your health information and to protect access to your data stored in Vensa.</p><br />
                        <div className={styles.button} onClick={() => { 
                            setWhatToRender(0);
                            accStore.setPinCodeFlowState(0);

                            setBoxesV(["", "", "", "", "", ""]);
                        }}>
                            Change PIN Code
                        </div>
    
                        { /* eslint-disable-next-line*/ }
                        <div className={styles.textButton} onClick={() => { 
                            setWhatToRender(3);
                            accStore.setPinCodeFlowState(3);

                            setVerify(true);
                            setBoxesV(["", "", "", "", "", ""]);
                        }}>
                            Use Anyway
                        </div>
                    </div>
                );
            case 3:          
                return (
                    <div ref={myRef} className={styles.container} style={{padding: "2rem"}}>
                        <Loading
                            iconClassName={styles.spinner}
                            containerClassName={styles.loading}
                            isFetching={store.loading || secStore.loading}
                        >
                            <p>Verify your new PIN</p>
                            {renderBoxes()}                            
                        </Loading>
                    </div>
                );
                case 4:
                    return (
                        <div className={styles.containerPin}>
                            <div className={styles.pinTitle}>Create a PIN code</div>
                            <div className={styles.pinText}>A PIN code provides extra security to protect your health data.</div>
                            <div className={styles.pinText}>On login your PIN code will be required for validation. <span onClick={() => props[0].openFAQ()} className={styles.learnMore}>Learn more.</span></div>
                            {/* <img src={fourPin} style={{display: "block", margin: "auto", marginBottom: "2rem", marginTop: "1rem"}} /> */}
                            <div className={styles.blueButton} onClick={() => {
                                setWhatToRender(0);
                                accStore.setPinCodeFlowState(0);
                            }}>Setup PIN Code</div>
                            {/* <div className={styles.link} onClick={() => props[0].skipPhoto ? props[0].goNext() : go.identityPhoto()}>Set up later in security settings</div> */}
                        </div>
                    )
            default: 
                    return(null);
        }
    }    
    
    return (
        <Observer>
            {() => renderComponent()}
        </Observer>
    )
        
        
    
}

export default PinCode;