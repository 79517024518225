import { useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { CarecircleStore, DependantContainer } from 'types/careCircle';
import { createStore } from './store';

export const StoreContext = React.createContext<CarecircleStore | null>(null);

export const CareCircleStoreProvider = ({children, dependant}: DependantContainer) => {
    const store = useLocalObservable(createStore);
    store.updateDependant(dependant);
    return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

export const useDomainStore = () => {
    const store = React.useContext(StoreContext);
    if(!store) throw new Error('store for carecircle not defined');
    return store;
}