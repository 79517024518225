import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { ReactComponent as Cross } from 'svgs/whiteCrossSmall.svg';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #346672;
    align-items: center;
    padding: 0rem 1rem;
    position: relative;
`
const Button = styled.div`
    background-color: white;
    color: #346672;
    padding: 6px 17px;
    margin: 0rem 11%;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 600;
    box-shadow: 0px 2px 4px black;
    white-space: nowrap;
    cursor: pointer;
`
const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    flex-grow: 2;
    margin: 1rem;
`
const Title = styled.div`
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 0.5rem;
`
const Text = styled.div`
    font-size: 13px;
`

interface Props {
    Image: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    title: string | null;
    text: string | null;
    buttonText: string | null;
    onClick: () => void | null;
    lineColor: string | null;
    hide?: () => {};
}

const PartialBanner: React.FC<Props> = observer(({Image, title, text, buttonText, onClick, lineColor, hide}) => {
    return(
        <Container style={lineColor !== null ? {borderTop: "2px solid" + lineColor} : {}}>
            {/*<Cross style={{position: "absolute", top: "1rem", right: "2rem", cursor: "pointer"}} onClick={hide}/>*/}
            <Image style={{width: "150px", margin: "0.5rem 0"}} /> 
            <TextContainer>
                {title !== null && <Title>{title}</Title>}
                <Text>{text}</Text>
            </TextContainer>
            <Button onClick={onClick}>{buttonText}</Button>
        </Container>
    );
});

export default PartialBanner;