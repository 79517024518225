import { isDesktop } from 'config';
import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import MedicationItem from '../MedicationItem/MedicationItem';

const Container = styled.div`
&::-webkit-scrollbar {
    width: 10px;
 }
   
 &::-webkit-scrollbar-track {
    background: #F0F0F0;
    border-radius: 25px;
    margin-left: 1rem;
 }
 
 &::-webkit-scrollbar-thumb {
     background: #AFAFAF;
     border-radius: 25px;
 }
 
 &::-webkit-scrollbar-thumb:hover {
     background: #999; 
 }
`

interface Medication {
    Id: number;
    CTPP: string;
    ExternalId: string;
    FreeFormMedication: string;
    InfoAvailable: boolean;
    Name: string;
}
interface Props {
    medications: Medication[];
    setMeds: (meds: Medication[]) => void;
    selected: Medication[];
    openMedicationInfo: (name: any, data: any) => void;
    onNoMedications: Function;
    isFetching: boolean;
    medsCount: number;
}
const MedicationList: React.FC<Props> = ({medications, setMeds, selected, openMedicationInfo, onNoMedications, isFetching, medsCount}) => {
    const addToSelected = (item: Medication) => {
        let arr = selected;
        arr.push(item);
        setMeds(arr);
    }

    useEffect(() => {
        if (!isFetching && medsCount === 0) {
            if (onNoMedications) onNoMedications();
        }          
    }, [medsCount, isFetching, onNoMedications]);

    const removeFromSelected = (item: Medication) => {
        let arr = selected;
        const index = arr.indexOf(item);
        arr.splice(index, 1);
        setMeds(arr);
    }

    const isItemSelected = (item: Medication) => {
        if (selected.find((_item) => item.Id === _item.Id)) return true;
        else return false;
    }
    const handleOnClick = (item: Medication) => {
        if (isItemSelected(item)) { 
            removeFromSelected(item)
        } else{
            addToSelected(item);
        } 
    }

    if (medications !== null && medications.length === 0) return <div></div>
    return (
        <Container style={{margin: isDesktop() ? "0rem 0.5rem" : "auto"}}>
            {medications !== null && medications.length > 0 && 
                medications.map((item: Medication, i: number) => {
                    const selected = isItemSelected(item);
                    return (
                        <MedicationItem
                            key={i}
                            label={item.Name}
                            selected={selected}
                            onClick={() => handleOnClick(item)}
                            infoAvailable={item.InfoAvailable}
                            onInfoClick={() => openMedicationInfo(item.Name, "data")}
                        />
                    )
                })
            }       
        </Container>
    )
}

const mapStateToProps = state => ({
    isFetching: state.getMedicationsReducer.isFetching,
  });

export default connect(
    mapStateToProps,
  )(MedicationList);