import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { CSSTransitionGroup } from 'react-transition-group';

import window from '../../libs/Window';
import { actions as loginActions } from '../../EntryPage/actions/actions';
import styles from './expiredSession.module.css';
import { getToken } from 'libs/newHttp';

class ExpiredSession extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
  };
  logout = () => {
    const token = getToken();
    this.props.logout(token);
    if (window.location.pathname.startsWith('/practice-profile/')) {
      const id = window.location.pathname.substring(18);
      // eslint-disable-next-line max-len
      window.location = `${
        window.location.origin
      }/login?page=practice-profile/${id}`;
    }
  };
  render() {
    return (
      <div className={styles.page}>
        <CSSTransitionGroup
          transitionName={{
            leave: styles.leave,
            leaveActive: styles.leaveActive,
            enter: styles.enter,
            enterActive: styles.enterActive,
            appear: styles.enter,
            appearActive: styles.enterActive,
          }}
          transitionLeaveTimeout={500}
          transitionEnterTimeout={500}
          transitionAppearTimeout={500}
          transitionAppear
          className={styles.innerContainer}
          onSubmit={this.onSubmit}
          component="div"
        >
          <div className={styles.title}>Whoops, your session has expired</div>
          <button
            className={styles.loginButton}
            type="button"
            onClick={this.logout}
          >
            Log in
          </button>
        </CSSTransitionGroup>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.loginReducer.token,
});

const mapDispatchToProps = dispatch => ({
  logout: bindActionCreators(loginActions.logout, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExpiredSession);
export { ExpiredSession };
