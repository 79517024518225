import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from '../actions/actions';
import { actions as profileActions } from '../../components/UIContainer/MobileUI/MainShell/actions/profileActions'
import { actions as addPharmActions } from '../PickUpLocation/AddPharmacy/actions/actions';
import { actions as menuActions } from '../actions/menuActions';
import { actions as searchActions } from '../actions/searchActions';
import { actions as listActions } from '../../PracticesPage/PracticeSearch/actions/actions';
import { actions as dependantActions } from '../../DependantsPage/actions/actions';
import List from "vScript/RequestScriptMobile/List";
import styles from 'vScript/RequestScriptMobile/requestScriptMobile.module.css';
import { clearScriptRequest } from 'selectors/scriptSelectors';
import { go } from '../../routes';
import Switcheroo from 'components/Switcheroo';
import ScriptDisabled from 'vScript/RequestScriptMobile/ScriptDisabled';
import { CSSTransitionGroup } from 'react-transition-group';
import classnames from 'classnames';
import PracticeSearch from 'PracticesPage/PracticeSearch';
import { ReactComponent as Arrow } from '../../svgs/rightarrow2.svg';
import { ReactComponent as Spinner } from '../../svgs/spinner.svg';
import PracticeOffline from 'vScript/RequestScriptMobile/PracticeOffline';

interface Props {
    getProfile();
    userId: any;
    fixed: any;
    clearScriptRequest: any,
    dependants: any;
    fetchDependants: any,
    isFetchingDependants: boolean,
    practices: any,
    fetchConnectedPractices: any,
    isFetchingConnected: boolean,
    fetchAllPractices: any,
    isFetchingAll: boolean,
    token: string,
    setInfo: Function,
}

const PracticeSelectionMobile: React.FC<Props> = (props) => {
    const [showError, setShowError] = useState(false)
    const [notOnlineError, setNotOnlineError] = useState(false);
    const [practiceNoScript, setPracticeNoScript] = useState<any>()
    const [switcheroo, SetSwitcheroo] = useState<Switcheroo | null>(null)

    useEffect(() => {
        props.clearScriptRequest();
        if (!props.userId) props.getProfile();
        if (!props.practices || (props.practices.length === 0 && !props.isFetchingConnected)) {
            props.fetchConnectedPractices(props.token);
        }
        if (!props.dependants || (props.dependants.length === 0 && !props.isFetchingDependants)) {
            props.fetchDependants(props.token);
        }
    }, []);

    const handleSwitcheroo = (next, last) => () => {
        if (switcheroo) {
            switcheroo.setItemToShow(next, last);
        }
    };

    const toggleErrorPopUp = () => {
        setShowError(!showError);
    };

    const toggleNotOnlinePopUp = () => {
        setNotOnlineError(!notOnlineError);
    };

    const handleNoScript = practice => {
        toggleErrorPopUp();
        setPracticeNoScript(practice);
    };

    const handleNotOnline = practice => {
        toggleNotOnlinePopUp();
        setPracticeNoScript(practice);
        props.fetchConnectedPractices(props.token);
    };

    return (
        <div className={styles.scriptContainer}>
            <CSSTransitionGroup
                component="div"
                className={classnames(styles.container, {
                    [styles.fixed]: props.fixed,
                })}
                transitionName={{
                    leave: styles.leave,
                    enter: styles.enter,
                }}
                transitionLeaveTimeout={300}
                transitionEnterTimeout={300}
            >
                {props.isFetchingConnected && (
                    <div className={styles.spinnerContainer}>
                        <Spinner className={styles.spinner} />
                    </div>
                )}
                <Switcheroo
                    ref={c => { SetSwitcheroo(c) }}
                    firstItem={
                        <div className={styles.listContainer}>
                            <List
                                heading="Where?"
                                options={props.practices}
                                arrowFn={() => go.home()}
                                optionOnClick={o => {
                                    if (!o.ScriptEnabled) handleNoScript(o);
                                    else if (!o.IsAvailableForScriptRequests) handleNotOnline(o);
                                    else {
                                        props.setInfo('practice', o);
                                        go.selectPatient();
                                    }
                                }}
                                lastOption="Other Practice"
                                lastOptionFn={handleSwitcheroo(2, 1)}
                            />
                        </div>
                    }
                    secondItem={
                        <div className={styles.otherPracticeContainer}>
                            <Arrow
                                className={styles.backArrow}
                                onClick={handleSwitcheroo(1, 2)}
                            />
                            <div className={styles.where}>Where?</div>
                            <PracticeSearch
                                errorAction={handleNoScript}
                                successAction={o => {
                                    props.setInfo('practice', o);
                                    go.selectPatient();
                                }}
                                condition="ScriptEnabled"
                            />
                        </div>
                    }
                />
                {showError && (
                    <ScriptDisabled
                        practice={practiceNoScript}
                        arrowClick={toggleErrorPopUp}
                    />
                )}
                {notOnlineError && (
                    <PracticeOffline
                        practice={practiceNoScript}
                        arrowClick={toggleNotOnlinePopUp}
                    />
                )}
            </CSSTransitionGroup>
        </div>
    );
}

const mapStateToProps = state => ({
    userId: state.profileReducer.profile.Id,
    token: state.loginReducer.token,
    dependants: state.fetchDependantsReducer.dependants,
    isFetchingDependants: state.fetchDependantsReducer.isFetching,
    isFetchingConnected: state.scriptMenuReducer.isFetchingConnected,
    isFetchingAll: state.scriptMenuReducer.isFetchingAll,
    practices: state.scriptMenuReducer.connectedPractices,
});
const mapDispatchToProps = dispatch => ({
    /* eslint-disable max-len */
    getProfile: bindActionCreators(profileActions.getProfile, dispatch),
    fetchDoctors: bindActionCreators(menuActions.fetchDoctors, dispatch),
    setInfo: bindActionCreators(actions.setInfo, dispatch),
    goToAddMedications: () => go.addMedication(),
    getOptions: bindActionCreators(addPharmActions.getOptions, dispatch),
    checkAbleToRequest: bindActionCreators(actions.ableToRequest, dispatch),
    fetchAllPractices: bindActionCreators(searchActions.fetchAllPractices, dispatch,),
    clearPracticeSelection: bindActionCreators(listActions.clearPracticeSelection, dispatch,),
    fetchConnectedPractices: bindActionCreators(menuActions.fetchConnectedPractices, dispatch,),
    clearScriptRequest: clearScriptRequest(dispatch),
    fetchDependants: bindActionCreators(dependantActions.fetchDependants, dispatch),
    /* eslint-enable max-len */
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PracticeSelectionMobile);
export { PracticeSelectionMobile };