import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import UIContainer from '../../../components/UIContainer';
import RouteBar from '../../../components/DesktopComponents/RouteBar';
import TabBar from '../../../components/DesktopComponents/TabBar';
import { go } from '../../../routes';
import { ReactComponent as Progress } from '../../../svgs/progress2.svg';
import BlueButton from '../../../components/DesktopComponents/BlueButton';
import PracticeSearch from '../components/PracticeSearch';
import PracticeSelection from '../components/PracticeSelection';
import { usePracticesStore, useAccountStore } from '../../../store/mobx/mobx';
import { useComponentDidMount } from '../../../libs/newHttp';

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: scroll;
    background-color: #F9F9F9;
`
const Container = styled.div`
    width: 533px;
    margin 1rem auto;
`
const Title = styled.div`
    text-transform: uppercase;
    font-size: 13px;
    margin-top: 1rem;
    text-align: left;
    margin-bottom: 1rem;
`
const Empty = styled.div`
    background-color: white;
    font-size: 17px;
    color: #939393;
    width: 100%;
    border-radius: 4px;
    height: 500px;
    box-shadow: 1px 3px 4px lightgray;
    margin-left: 10px;
    text-align: center;
    padding: 10rem 5rem;
`
const FlexR = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
`

const SelectPractice: React.FC = observer(() => {
    const [popup, setPopup] = useState(false);
    const store = usePracticesStore();
    const accStore = useAccountStore();

    useComponentDidMount(() => {
        store.getAllPractices();
        store.getUserPractices();
        accStore.getIdentityRequestsStatus(() => { });
    });

    return (
        <UIContainer isFetching={accStore.loadingRequests || store.allPracticesLoadingStatus === "LOADING" || store.practiceDetailsLoadingStatus === "LOADING"}>
            <Outer>
                <RouteBar
                    lowLevel={"Identity"}
                    lowLink={() => go.identity()}
                />
                <TabBar type={0} id={1} />
                <Container>
                    <Title>Identity Status</Title>
                    {popup && <PracticeSearch userHasPractices={store.userPractices.Practices.length !== 0} onClose={() => go.identity()} />}
                    <Progress />
                    <FlexR>
                        {accStore.identityRequests.length > 0 ?
                            <PracticeSelection onViewClick={() => { }} showLabel={() => { }} key={accStore.identityRequests.length} practices={accStore.identityRequests} onGoNext={() => {
                                if (accStore.profile.SecurityPinCodeSetup) {
                                    go.identityPhoto();
                                } else {
                                    go.identityPin();
                                }
                            }} lengthOfList={1}
                            />
                            :
                            <Empty>
                                You are not verified at any practice yet.
                                <br />
                                <br />
                                click the "Add Practice" button to get started.
                            </Empty>
                        }
                        <BlueButton style={{ fontSize: "13px", position: "absolute", right: "-200px" }} text={accStore.identityRequests.length > 0 ? "+ Change Practice" : "+  Add Practice"} onClick={() => setPopup(true)} />
                    </FlexR>
                </Container>
            </Outer>
        </UIContainer>
    );
});

export default SelectPractice;