import { styled } from 'components/vensaComponents';
import { prop } from 'styled-tools';

export const Line = styled.hr`
  margin-top: ${prop('my', '16px')};
  margin-bottom: ${prop('my', '16px')};
  border: 1px solid;
  border-color: ${prop('borderColor', '#e3e3e3')};
  width: ${prop('width', '100%')};
` as React.FC<{ width?: string; borderColor?: string; my?: string }>;
