import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Tick } from '../../../../svgs/tick.svg';
import styles from './medications.module.css';

const Medications = ({ medications }) => (
  <div className={styles.medications}>
    {medications.map(med => (
      <div key={med.Id} className={styles.item}>
        <Tick className={styles.tick} />
        <div className={styles.name}>
          {med.TradeName ? med.TradeName : med.Name}
        </div>
      </div>
    ))}
  </div>
);

Medications.propTypes = {
  medications: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Medications;
