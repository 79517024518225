import React from 'react';
import useWizard from 'components/core/Wizard/useWizard';
import { useCancelAppointment } from './api';
import Alert from 'libs/Alert';
import Waiting from 'components/WaitingTransition';

interface ProcessStepProps {
  id: string;
}
const ProcessStep: React.FC<ProcessStepProps> = ({id}) => {
  const {valueObject, previousStep, complete} = useWizard();
  const {error, loading} = useCancelAppointment(id, valueObject['reason']);

  const handleError = () => {
    if(error){
      previousStep();
      Alert.error(error.message);
    }
  };
  return (
    <Waiting
      title='Your appointment has been cancelled'
      tip='Cancelling your appointment'
      error={error?.message}
      condition={loading}
      complete={complete}
      onError={handleError}
     />)
};

export default ProcessStep;