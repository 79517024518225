import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Cross } from 'svgs/blueCross.svg';
import Kiwi from 'images/wavingKiwi.png';
import { go } from 'routes';

const Container = styled.div`
    display: block;
    margin: 4rem auto;
    height: 400px;
    width: 500px;
    position: relative;
    background-color: white;
    border-radius: 4px;
    padding: 2rem 1rem;
`
const Title = styled.div`
    font-size: 21px;
    color: #07A3C8;
    text-align: center;
    margin-bottom: 2rem;
`
const FlexH = styled.div`
    display: flex;
    flex-direction: row;
    margin: 1rem 0rem;
`
const FlexV = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 0.8rem;
`
const Bullet = styled.div`
    height: 35px;
    width: 35px;
    text-align: center;
    background-color: #07A3C8;
    color: white;
    line-height: 2;
    flex-shrink: 0;
    border-radius: 50%;
`
const Image = styled.img`
    display: block;
    margin: 1rem auto;
`
const Text = styled.div`
    font-size: 17px;
    color: #6C6C6C;
    text-align: center;
    margin: 1rem 0;
    padding: 0 3rem;
`
const Button = styled.div`
    color: white;
    background-color: #07A3C8;
    font-size: 17px;
    padding: 1rem 2rem;
    border-radius: 4px;
    margin: 2rem auto;
    width: 200px;
    text-align: center;
    cursor: pointer;
`

interface Props {
    hide: (e: any) => void;
}
const UnavailableOnDesktop: React.FC<Props> = ({hide}) => {    
    return (
        <Container>
            <Title>Unavailable on Desktop</Title>
            <Image src={Kiwi} alt="img" />
            <Text>Sorry this service is currently only available on mobile. Please try again from your mobile device's web browser.</Text>
            <Button onClick={hide}>Close</Button>
        </Container>
    )
}

export default UnavailableOnDesktop;