import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Countdown from '../../../../components/Countdown/index';
import { actions } from '../../actions/codeConfirmationActions';
import { isValidMobile } from '../../../../libs/ValidBasicInfo';
import { isDesktop } from '../../../../config';
import { isBookingForSomeoneElse } from '../../../../selectors/sessionSelectors';
import Alert from '../../../../libs/Alert';
import styles from './resendOptions.module.css';

const timeFormatFn = time => {
  const t = Math.floor(time / 1000);
  if (t < 10) return `0${t}`;
  return t;
};

class ResendOptions extends Component {
  static propTypes = {
    mobile: PropTypes.string,
    milliSecondsToWait: PropTypes.number.isRequired,
    countdownStartTime: PropTypes.instanceOf(moment).isRequired,
    sendToConfirm: PropTypes.func.isRequired,
    changeVerificationMobile: PropTypes.func.isRequired,
    sessionId: PropTypes.string,
    isBookingForSomeoneElse: PropTypes.bool,
    setResendMade: PropTypes.func,
  };
  getMilliSecondsRemaining = () =>
    (this.props.countdownStartTime.unix() +
      this.props.milliSecondsToWait / 1000 -
      moment().unix()) *
    1000;
  isAllowedToResend = () => this.getMilliSecondsRemaining() <= 0;
  updatePageDelay = () => {
    setTimeout(this.updatePage, 1000);
  };
  updatePage = () => {
    this.forceUpdate();
  };
  handleClick = () => {
    if (isDesktop() && !isValidMobile(this.input.value)) {
      Alert.error('Invalid Mobile');
    } else if (this.isAllowedToResend()) {
      //sends the code to a new mobile
      if (this.input && this.props.mobile !== this.input.value) {
        this.props.changeVerificationMobile(
          this.props.sessionId,
          this.input.value,
          this.props.isBookingForSomeoneElse,
        );
      }
      //resends the code to the existing mobile
      else this.props.sendToConfirm(this.props.sessionId);
    }
    //indicates to the parent component that a resend attempt has been made
    if (this.props.setResendMade) this.props.setResendMade();
  };
  render() {
    const canResend = this.isAllowedToResend();
    return (
      <div
        className={classnames(styles.resendContainer, {
          [styles.canResend]: canResend,
        })}
      >
        {isDesktop() && (
          <input
            className={styles.mobile}
            defaultValue={this.props.mobile}
            type="tel"
            ref={c => {
              this.input = c;
            }}
            disabled={!canResend}
          />
        )}
        {canResend ? (
          <div className={styles.resendCodeText} onClick={this.handleClick}>
            Resend code
          </div>
        ) : (
          <div className={styles.countdownText}>
            <span>Resend code in 0:</span>
            <Countdown
              className={styles.countdown}
              milliSecondsRemaining={this.getMilliSecondsRemaining()}
              interval={1000}
              formatTime={timeFormatFn}
              completedCallback={this.updatePageDelay}
            />
          </div>
        )}
      </div>
    );
  }
}

const getAppointmentMobile = state => {
  if (state.loginReducer.token) {
    return state.profileReducer.profile.Mobile;
  }
  if (isBookingForSomeoneElse(state)) {
    return state.guardianDetailsReducer.mobile;
  }
  return state.patientDetailsReducer.patientDetails.mobile;
};

const mapStateToProps = state => ({
  mobile: getAppointmentMobile(state),
  countdownStartTime: state.countdownReducer.startTime,
  milliSecondsToWait: state.countdownReducer.milliSecondsToWait,
  sessionId: state.checkEnrollmentReducer.session.SessionId,
  isBookingForSomeoneElse: isBookingForSomeoneElse(state),
});

const mapDispatchToProps = dispatch => ({
  changeVerificationMobile: bindActionCreators(
    actions.changeVerificationMobile,
    dispatch,
  ),
  sendToConfirm: bindActionCreators(actions.sendToConfirm, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResendOptions);
export { styles };
