import React from 'react';
import PropTypes from 'prop-types';

import appointmentsIcon from '../../../../images/appointments.png';
import addDependant from '../../../../svgs/ctaCareCircle.svg';
import heart from '../../../../svgs/ctaPhr.svg';
import addPractice from '../../../../images/addPractice.png';
import { ReactComponent as Script } from '../../../../svgs/scripts.svg';
import styles from './userActionsMenu.module.css';

const createMenuButton = (icon, label, onClickFn) => (
  <button key={label} className={styles.button} onClick={onClickFn}>
    {icon}
    <span className={styles.buttonText}>{label}</span>
  </button>
);

const UserActionsMenu = ({
  appointmentsFn,
  dependantFn,
  practiceListingFn,
  scriptFn,
  phrFn
}) => (
  <div className={styles.container}>
    {createMenuButton(
      <img src={appointmentsIcon} className={styles.icon} alt="img" />,
      'Make an Appointment',
      appointmentsFn,
    )}
    {createMenuButton(
      <Script className={styles.scriptIcon} />,
      'Order a Repeat Script',
      scriptFn,
    )}
    {createMenuButton(
      <img src={heart} className={styles.icon} alt="img" />,
      'My Health Records',
      phrFn,
    )}
    {createMenuButton(
      <img src={addPractice} className={styles.icon} alt="img" />,
      'Add a Practice',
      practiceListingFn,
    )}
    {createMenuButton(
      <img src={addDependant} className={styles.icon} alt="img" />,
      'Add to my Care Circle',
      dependantFn,
    )}
  </div>
);

UserActionsMenu.propTypes = {
  appointmentsFn: PropTypes.func.isRequired,
  dependantFn: PropTypes.func.isRequired,
  practiceListingFn: PropTypes.func.isRequired,
  scriptFn: PropTypes.func.isRequired,
};

export default UserActionsMenu;
