import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { throttle } from 'lodash/fp';
import moment from 'moment';

import { actions as headerActions } from '../../components/UIContainer/actions/headerActions';
import { actions as accountVerifyActions } from '../../vAppointment/GuestRegister/actions/registerUserCodeActions';
import { actions as editActions } from '../../UserAccountPage/actions/actions';
import { go } from '../../routes/index';
import { isDesktop } from '../../config';
import MobileInput from './MobileInput/index';
import CodeInput from './CodeInput/index';
import Switcheroo from '../../components/Switcheroo/index';
import UIContainer from '../../components/UIContainer/index';
import smartPhoneIcon from '../../images/smartPhone.png';
import { useLocation } from 'react-router-dom';

import styles from './accountVerificationPage.module.css';

class AccountVerificationPage extends Component {
  static propTypes = {
    overrideBackArrow: PropTypes.func.isRequired,
    clearBackArrow: PropTypes.func.isRequired,
    sendCode: PropTypes.func.isRequired,
    verifyCode: PropTypes.func.isRequired,
    clearError: PropTypes.func.isRequired,
    cancel: PropTypes.func,
    token: PropTypes.string.isRequired,
    mobile: PropTypes.string.isRequired,
    countdownStartTime: PropTypes.instanceOf(moment).isRequired,
    milliSecondsToWait: PropTypes.number.isRequired,
    verifyError: PropTypes.bool,
    isFetching: PropTypes.bool,
    saveMobile: PropTypes.func.isRequired,
    verifySuccess: PropTypes.bool,
    onVerifySuccess: PropTypes.func,
    goToHome: PropTypes.func.isRequired,
  };
  state = {
    mobile: this.props.mobile || '',
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let cont = params.get('container');

    if (nextProps.verifySuccess) {
      if (this.props.onVerifySuccess) {
        this.props.onVerifySuccess();
      } else {
          if (cont === "false") {
            go.identityCompleteFlowProcessing();
          } else {
            go.home();
          }
        
      }
    }
  }
  setMobile = mobile => {
    this.setState({ mobile });
  };
  handleSwitcheroo = (next, last) => {
    if (this.switcheroo) {
      this.switcheroo.setItemToShow(next, last);
    }
  };
  clearBackArrow = () => {
    if (this.props.cancel) {
      this.props.overrideBackArrow(this.props.cancel);
    } else {
      this.props.clearBackArrow();
    }
  };
  render() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let cont = params.get('container');
    const content = (
      <div className={styles.outerContainer}>
        {cont === "true" &&
          <img
            src={smartPhoneIcon}
            className={styles.icon}
            key="icon"
            alt="SMS code"
          />
        } 
        <div className={styles.container}>
          <Switcheroo
            ref={c => {
              this.switcheroo = c;
            }}
            firstItem={
              <MobileInput
                switcheroo={() => this.handleSwitcheroo(2, 1)}
                sendCode={this.props.sendCode}
                setMobile={this.setMobile}
                mobile={this.state.mobile}
                cancel={this.props.cancel}
                saveMobile={this.props.saveMobile}
                countdownStartTime={this.props.countdownStartTime}
                milliSecondsToWait={this.props.milliSecondsToWait}
              />
            }
            secondItem={
              <CodeInput
                switcheroo={() => this.handleSwitcheroo(1, 2)}
                overrideBackArrow={this.props.overrideBackArrow}
                clearBackArrow={this.clearBackArrow}
                mobile={this.state.mobile}
                clearError={this.props.clearError}
                token={this.props.token}
                verifyCode={this.props.verifyCode}
                sendCode={this.props.sendCode}
                isError={this.props.verifyError}
                countdownStartTime={this.props.countdownStartTime}
                milliSecondsToWait={this.props.milliSecondsToWait}
                isFetching={this.props.isFetching}
              />
            }
          />
        </div>
      </div>
    );
    if (isDesktop()) return content;
    return cont === "false" ? <UIContainer heading="Step 3: Verify your mobile">{content}</UIContainer> : <UIContainer heading=" ">{content}</UIContainer>;
  }
}
const throttleForTime = throttle(500);
const mapStateToProps = state => ({
  token: state.loginReducer.token,
  isFetching: state.registerUserCodeReducer.verifyCode.isFetching,
  verifyError:
    state.registerUserCodeReducer.verifyCode.error &&
    state.registerUserCodeReducer.verifyCode.error.status === 400,
  countdownStartTime: state.countdownReducer.startTime,
  milliSecondsToWait: state.countdownReducer.milliSecondsToWait,
  mobile: state.profileReducer.profile.Mobile || '',
  verifySuccess: state.registerUserCodeReducer.verifyCode.success,
  sendCodeError: state.registerUserCodeReducer.sendCodeToVerify.error,
  isIdentityID: state.verifyReducer.id
});
const mapDispatchToProps = dispatch => ({
  overrideBackArrow: bindActionCreators(
    headerActions.overrideBackArrow,
    dispatch,
  ),
  clearBackArrow: bindActionCreators(headerActions.clearBackArrow, dispatch),
  sendCode: bindActionCreators(accountVerifyActions.sendCodeToVerify, dispatch),
  verifyCode: bindActionCreators(accountVerifyActions.verifyCode, dispatch),
  // eslint-disable-next-line max-len
  clearError: throttleForTime(
    bindActionCreators(accountVerifyActions.clearVerifyCodeError, dispatch),
  ),
  saveMobile: bindActionCreators(editActions.setMobile, dispatch),
  goToHome: () => go.home(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountVerificationPage);
export { styles };
