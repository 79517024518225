import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { map } from 'lodash/fp';
import { CSSTransitionGroup } from 'react-transition-group';

import { actions as clinicActions } from '../../../PracticesPage/actions/actions';
import { actions as listActions } from '../../../PracticesPage/PracticeSearch/actions/actions';
import DesktopCta from '../../../components/DesktopCta/index';
import LoadingSpinner from '../../../components/loadingSpinner/index';
import NotWithVensa from '../../../HealthFeed/HealthFeedMobile/MenuPages/NotWithVensa/index';
import PracticeSearch from '../../../PracticesPage/PracticeSearch/index';
import { ReactComponent as Arrow } from '../../../svgs/rightarrow2.svg';
import { ReactComponent as Cross } from '../../../svgs/close.svg';
import { clinicPropType } from '../../../PracticesPage/propTypes/index';
import styles from './bookAppointment.module.css';
import { go } from '../../../routes';

class BookAppointment extends Component {
  static propTypes = {
    fetchClinics: PropTypes.func.isRequired,
    getAllPractices: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    clearPracticeSelection: PropTypes.func.isRequired,
    name: PropTypes.string,
    token: PropTypes.string.isRequired,
    practices: PropTypes.arrayOf(clinicPropType),
  };
  state = {
    component: 'spinner',
    practiceNotWithVensa: {},
  };
  componentDidMount() {
    this.props.clearPracticeSelection();
    this.props.getAllPractices();
    // skips loading screen if connected practices have already been fetched
    if (this.props.practices.length > 0) {
      this.toggleComponent('list')();
    } else this.props.fetchClinics(this.props.token);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.isFetching !== this.props.isFetching &&
      !nextProps.isFetching
    ) {
      // skips straight to 'Other practice' list if user has no connected practices
      if (nextProps.practices.length > 0) {
        this.toggleComponent('list')();
      } else this.toggleComponent('all')();
    }
  }
  toggleComponent = component => () => {
    this.setState({ component });
  };
  goToPractice = e => {
    go.practiceProfile(e.UrlName);
  };
  handleAddedListClick = practice => () => {
    if (practice.IsUsingVensa) go.practiceProfile(practice.UrlName);
    else this.handleNotWithVensa(practice);
  };
  handleNotWithVensa = practice => {
    this.toggleComponent('error')();
    this.setState({
      practiceNotWithVensa: practice,
    });
  };
  render() {
    const { practices, close, name, isFetching } = this.props;
    const components = {
      spinner: (
        <LoadingSpinner
          containerClassName={styles.loadingContainer}
          iconClassName={styles.spinner}
          isFetching={isFetching}
          key="0"
        />
      ),
      list: (
        <div className={styles.listContainer} key="1">
          <Cross className={styles.cross} onClick={close} />
          <div className={styles.heading}>
            {`Hey ${name ||
              ''}, where would you like to book this appointment?`}
          </div>
          <div className={styles.practiceButtonContainer}>
            {map(p => (
              <DesktopCta
                key={p.PracticeId}
                onClick={this.handleAddedListClick(p)}
                text={p.Name}
              />
            ))(practices)}
            <button
              className={styles.otherButton}
              onClick={this.toggleComponent('all')}
            >
              Other
            </button>
          </div>
        </div>
      ),
      all: (
        <div className={styles.autoCompleteContainer} key="2">
          {this.props.practices.length > 0 && (
            <Arrow
              className={styles.arrow}
              onClick={this.toggleComponent('list')}
            />
          )}
          <div className={styles.heading}>Which practice?</div>
          <PracticeSearch
            successAction={this.goToPractice}
            errorAction={this.handleNotWithVensa}
            condition="IsUsingVensa"
          />
        </div>
      ),
      error: (
        <NotWithVensa
          practice={this.state.practiceNotWithVensa}
          arrowClick={this.toggleComponent('all')}
          serviceAction="book an appointment "
          activationService="Vensa Online Booking System"
        />
      ),
    };
    return (
      <CSSTransitionGroup
        component="div"
        className={styles.container}
        transitionName={{
          leave: styles.leave,
          leaveActive: styles.leaveActive,
          enter: styles.enter,
          enterActive: styles.enterActive,
        }}
        transitionLeaveTimeout={300}
        transitionEnterTimeout={300}
      >
        {components[this.state.component]}
      </CSSTransitionGroup>
    );
  }
}

const mapStateToProps = state => ({
  token: state.loginReducer.token,
  practices: state.practiceListingReducer.practices,
  isFetching: state.practiceListingReducer.isFetching,
  name: state.profileReducer.profile.FirstName,
});
const mapDispatchToProps = dispatch => ({
  fetchClinics: bindActionCreators(clinicActions.getPracticeListings, dispatch),
  getAllPractices: bindActionCreators(listActions.getAllPractices, dispatch),
  clearPracticeSelection: bindActionCreators(
    listActions.clearPracticeSelection,
    dispatch,
  ),
  // eslint-disable-next-line max-len
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BookAppointment);
