import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import styled from 'styled-components';

import UIContainer from 'components/UIContainer';
import { go } from 'routes';
import LabResultsConsent from './Sections/LabResultsConsent';
import ConsentQuestion from './Sections/ConsentQuestion';
import { useAccountStore, useMyHealthStore } from 'store/mobx/mobx';
import LearnMorePopUp from 'components/LearnMorePopUp/LearnMorePopUp';
import Loading from 'components/loadingSpinner';
import { Redirect } from 'react-router';

const LabResultsResearchFlow: React.FC = observer(() => {
    const [state, setState] = useState(0);
    const [popUp, setPopUp] = useState(false);
    const store = useMyHealthStore();
    const { FirstName, LastName } = useAccountStore().profile;

    const name = FirstName + " " + LastName;
    const info = store.labInviteGeneralInfo;

    if (info.PatientId === "") return <Redirect to={'/home'} />

    let question;
    if (state === 0) {
        question = <LabResultsConsent onNext={() => setState(1)} />
    } else if (state === 1) {
        question = <ConsentQuestion 
                        name={name}
                        practice={info.OrgName}
                        provider={info.ProviderName}
                        question={"I consent to the study staff collecting and processing my information including information about my health."}
                        popUps={true}
                        onNext={(value: boolean) => {
                            if (value === false) {
                                setPopUp(true);
                            }
                             else {
                                store.setConsent(value);
                                setState(2);
                             }
                        }}
                    />
    } else if (state === 2) {
        question = <ConsentQuestion 
                        name={name}
                        practice={info.OrgName}
                        provider={info.ProviderName}
                        question={
                            "If I decide to withdraw from the study, I agree that the information collected about me up to the point when I withdraw may continue to be processed."
                        }
                        popUps={false}
                        onNext={(value: boolean) => {
                            store.setAllowInformationContinueUsedAfterWithdrawn(value);
                            setState(3);
                        }}
                    />
                    
    } else if (state === 3) {
        question = <ConsentQuestion 
                        name={name}
                        practice={info.OrgName}
                        provider={info.ProviderName}
                        question={"I wish to receive a summary of the results from the study."}
                        popUps={false}
                        onNext={(value: boolean) => {
                            store.setReceiveSummaryAfterResearch(value);
                            store.postLabInvite(() => go.labResultsResearchProcessing());
                        }}
                    />
    }
    
    return (
        <UIContainer isFetching={store.loading} heading={"Lab Results Research"} goBackFn={() => state === 0 ? go.home() : setState(state - 1)}>
            {question}
            {popUp &&
              <LearnMorePopUp type={"PDH_DECLINE"} hide={() => setPopUp(false)} />
            }
        </UIContainer>
    )
})

export default LabResultsResearchFlow;