import styled from 'styled-components';
import bg from 'images/bgImage.png';
import arrow from "svgs/dropDownArrowGrey.svg";


export const Container = styled.div`
    width: 100%;
    height: 100vh;
    background-image: url(${bg});
    background-repeat: no-repeat; 
    background-position: top;
    background-size: cover;
`
export const ContentContainer = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
`
export const Subtitle = styled.div`
    color: #585858;
    font-size: 13px;
    margin: 0.7rem 0rem;
    text-align: center;
    padding: 0rem 2rem;
`
export const Card = styled.div`
    width: 405px;
    display: block;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    padding: 2rem 2.5rem;
    position: relative;
`
export const DotsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem auto;
`
export const Title = styled.div`
    font-size: 17px;
    color: #585858;
    width: 100%;
    text-align: center;
    font-weight: 600;
    margin-top: 0.5rem;
`
export const TitleSmall = styled.div`
    font-size: 13px;
    color: #585858;
    width: 100%;
    text-align: center;
    margin-top: 0.5rem;
`
export const FlexV = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    display: inline-block;
`
export const FlexH = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`
export const Input = styled.input`
    width: 100%;
    border: 1px solid #DADADA;
    border-radius: 4px;
    padding: 0.5rem;
    font-size: 15px;
    color: #585858;
    -webkit-box-shadow: 0 0 0px 1000px var(--color-white) inset; //Remove autofill background
`
export const DoBInput = styled.input`
    width: 100%;
    border: 1px solid #DADADA;
    border-radius: 4px;
    padding: 0.5rem;
    font-size: 15px;
    background-repeat: no-repeat;
    background-image: url(${arrow});
    color: #585858;
    background-position: center right 0.5rem;
`
export const Label = styled.div`
    color: #585858;
    font-size: 13px;
    margin: 0.7rem 0rem;
`
export const LogIn = styled.div`
    color: #07A3C8;
    font-size: 13px;
    cursor: pointer;
    width: 50px;
`
export const Logo = styled.img`
    position: absolute;
    top: 20px;
    left: 30px;
`
export const ShowContainer = styled.div`
    color: #07A3C8;
`
export const Link = styled.span`
    color: #585858;
    cursor: pointer;
    text-decoration: underline;
`