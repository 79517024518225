import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash/fp';
import classnames from 'classnames';

import CtaButton from '../../../CtaButton/index';
import styles from './reason.module.css';

class Reason extends Component {
  static propTypes = {
    handleClick: PropTypes.func.isRequired,
    setRed: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    setReason: PropTypes.func.isRequired,
    hasReason: PropTypes.bool,
    red: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.setReason = debounce(500, this.setReason.bind(this));
  }
  onReasonChange = e => {
    this.setReason(e.target.value);
    this.props.setRed(false);
  };
  setReason(reason) {
    this.props.setReason(reason);
  }
  render() {
    const { handleClick, red, setRed, hasReason, cancel } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.heading}>
          What&#39;s your reason for cancelling this appointment?
        </div>
        <form className={classnames(styles.form, { [styles.redBorder]: red })}>
          <textarea
            onChange={this.onReasonChange}
            onFocus={() => setRed(false)}
            className={styles.textarea}
            maxLength={120}
          />
        </form>
        <div className={styles.buttonContainer}>
          <button className={styles.button} onClick={cancel}>
            Keep Appointment
          </button>
          <CtaButton onClick={handleClick} active={hasReason} />
        </div>
      </div>
    );
  }
}

export default Reason;
