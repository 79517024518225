import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Arrow from 'svgs/rightarrow2.svg';
import { go } from 'routes';
import alert from "libs/Alert";
import PaymentForm from 'vScript/Payment/PaymentForm';
import { isDesktop } from 'config';
import { usePaymentsStore } from 'store/mobx/mobx';

const Container = styled.div`
`
const Heading = styled.div`
    font-size: var(--font-19);
    color: var(--color-grey-5);
    text-align: center;
    margin: 0.882rem auto 2.353rem;
`
const BackArrow = styled.img`
    position: absolute;
    top: 0.8rem;
    width: 1.353rem;
    height: 1.353rem;
    fill: #C9C9C9;
    left: 1.471rem;
    stroke: none;
`
const CardContainer = styled.div`
    margin: 1rem;
`
const AddCard = ({ urlName, sessionId }) => {
    const paymentStore = usePaymentsStore();

    const handleCtaClick = async () => {
        paymentStore.getCards();
        go.prepaidPage(urlName)
    };  

    return (
        <Container>
            <Heading>Card Details</Heading>
            {!isDesktop() && (
                <BackArrow src={Arrow} onClick={() => go.prepaidPage(urlName)} />
            )}
            <CardContainer>
                <PaymentForm appointment={sessionId} handleNext={handleCtaClick} handleError={() => alert.error("Sorry card was unable to be added, please try again.")}/>
            </CardContainer>
        </Container>
    )
}

const mapStateToProps = state => ({
    urlName: state.practiceProfileReducer.profile.UrlName,
    sessionId: state.checkEnrollmentReducer.session.SessionId
})

export default connect(mapStateToProps, null)(AddCard);
