import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import masterPNG from '../../images/master-card.png';
import visaPNG from '../../images/visa-card-new-logo.png';
import amexPNG from '../../images/amex-card.png';
import { ReactComponent as Dots } from 'svgs/3dotsVertical.svg';
import { ReactComponent as Tick } from 'svgs/tick.svg';
import { ICard } from 'store/mobx/paymentStore/paymentStore.type';
import moment from 'moment';

const Container = styled.div`
    height: 100px;
    box-shadow: 0px 3px 6px lightgray;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem 2rem;
    margin: 1rem 0rem;
    position: relative;
    overflow: visible;
`
const Image = styled.img`
    min-width: 65px;
    max-width: 65px;
`
const Number = styled.div`
    font-size: 16px;
    font-weight: 480;
    color: #7B7B7B;
    width: 100%;
    text-align: center;
    min-width: 200px;
    margin-left: 20px;
`
const ExpiryDate = styled.div`
    font-size: 16px;
    font-weight: 480;
    color: #7B7B7B;
    width: 75%;
    text-align: left;
    padding-left: 20px;
`
const Status = styled.div`
    font-size: 16px;
    font-weight: 480;
    width: 100%;
`
const RemoveCardMenu = styled.div`
    width: 180px;
    height: 60px;
    position: absolute;
    top: 80px;
    right: 65px;
    transform: translateX(140px);
    background-color: white;
    box-shadow: 1px 5px 8px lightgray;
    font-size: 13px;
    padding: 2rem;
    z-index: 4;
    cursor: pointer;
`
const DefaultCardMenu = styled.div`
    width: 180px;
    height: 60px;
    position: absolute;
    top: 35px;
    right: 65px;
    transform: translateX(140px);
    background-color: white;
    box-shadow: 0px -6px 6px #f4f4f4;
    font-size: 13px;
    padding: 2rem;
    z-index: 10;
    cursor: pointer;
    padding-top: 1.66rem;
`
const MenuLine = styled.div`
    width: 168px;
    border-bottom: 2px solid #f4f4f4;
    margin-left: -26px;
    margin-top: 14px;
`
const GreenTickContainer = styled.div`
    width: 30%;
`
const SelectedCard = styled.div`
    margin-right: auto;
    position: relative;
    width: 30px;
    height: 30px;
    color: #23C373;
    background-color: #23C373;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const IMAGES = {
    AmEx: amexPNG,
    Mastercard: masterPNG,
    Visa: visaPNG,
  }
  
const cardShortName = (name: string) => {
    switch(name?.toLowerCase()) {
        case 'mastercard': return 'Mastercard';
        case 'american express': return 'AmEx';
    }
    return 'Visa';
}
  
const getCardImage = (cardBrand: ICard['Brand']): string => {
    let result = IMAGES[cardShortName(cardBrand)];
    if (!result) result = visaPNG;
    return result;
}

const expiryDateColor = (month: number, year: number) => {
    if (hasExpired(month, year)) return '#FC7B7B'; //red
    const today = moment(new Date())
    const date = moment(`${year}-${month}`)
    if (today.month() === date.month() && today.year() === date.year()) return 'orange';
    return '#7B7B7B'; //gray
}

const hasExpired = (month: number, year: number) => {
    return moment(new Date()).isAfter(moment(`${year}-${month}`).add(1, 'months'));
};

const getContainerExpiredStyle = (month: number, year: number) => {
    const expired = hasExpired(month, year)
    return {
        backgroundColor: expired ? '#f0f0f0' : '#ffffff',
        backgroundSize: expired ? 'cover' : ''
    }
}

const getRemoveCardMenuStyle = (month: number, year: number) => {
    const expired = hasExpired(month, year)
    return {
        top: expired ? '35px': '80px', 
        zIndex: expired ? '10': '4',
        paddingTop: expired ? '1.4rem': '2rem'
    }
}


interface Props {
    menuOpen: boolean;
    brand: ICard['Brand']
    number: string;
    cardType: string;
    isExpired: boolean;
    expMonth: number;
    expYear: number;
    isDefault: boolean;
    onClick: () => void;
    toggleMenu: () => void;
    onClickDeleteCard: () => void;
    onClickDefaultCard: () => void;
};

const PaymentMethodCard: React.FC<Props> = observer(({brand, number, cardType, expMonth, expYear, isExpired, isDefault, onClick, menuOpen, toggleMenu, onClickDeleteCard, onClickDefaultCard}) => {
    const formatMonth = (month) => ("0" + month).slice(-2);
    const formatYear = (year) => parseInt(year.toString().substr(2,2), 10);
    const cardExpired = hasExpired(expMonth, expYear);
    return (
        <Container style={getContainerExpiredStyle(expMonth, expYear)} onClick={onClick}>
            <Image src={getCardImage(brand)} />
            <div style={{ position: "absolute", top: "40px", right: "5px", height: "auto", margin: "0.5rem", zIndex: 10, marginTop: "0", cursor: "pointer" }} onClick={toggleMenu}>
                <Dots style={{ width: "auto", height: "20px", zIndex: 10 }} />
            </div>
            { menuOpen && 
                <div>
                    {!cardExpired && <DefaultCardMenu onClick={onClickDefaultCard}>Set Card as Default<MenuLine/></DefaultCardMenu>}
                    <RemoveCardMenu style={getRemoveCardMenuStyle(expMonth, expYear)} onClick={onClickDeleteCard}>Remove this Card</RemoveCardMenu>
                </div> 
            }
            <Number>{number}</Number>
            <ExpiryDate style={{color: expiryDateColor(expMonth, expYear)}}>{formatMonth(expMonth)}/{formatYear(expYear)}</ExpiryDate>
            {isDefault && !hasExpired(expMonth, expYear)
                ?(<GreenTickContainer>
                    <SelectedCard>
                        <Tick style={{width: '10px', height: '10px'}}/>
                    </SelectedCard>
                  </GreenTickContainer>
                 )
                : <Status style={{color: "#FC7B7B"}}>{cardExpired ? "Card Expired" : ""}</Status>
            }
        </Container>
    );
});

export default PaymentMethodCard;

