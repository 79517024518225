import React from 'react';
import styles from './index.module.scss';
import cn from 'classnames';
import { ConfirmProps } from 'types';

type StadiumButtonProps = {
    Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
} & ConfirmProps

const StadiumButton = (
    {
        handleClick,
        Icon,
        text,
        clickable = false,
        ...rest
    }: StadiumButtonProps) => {
  return (
    <div className={cn(styles.container, {[styles.enable]:clickable})} onClick={handleClick} {...rest}>
        {Icon && <Icon />}
        {text && <span>{text}</span>}
    </div>
  )
};

export default StadiumButton;