import React from 'react';
import PropTypes from 'prop-types';

import styles from './someoneElse.module.css';

const SomeoneElse = ({ onClick }) => (
  <div className={styles.container} onClick={onClick}>
    Someone else
  </div>
);

SomeoneElse.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SomeoneElse;
