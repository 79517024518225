import React from 'react';
import PropTypes from 'prop-types';

import NotEnrolled from '../NotEnrolled/index';
import CallPractice from '../../../components/CallPractice/index';
import { PracticeInfoPropType } from '../../../PracticeProfilePage/BookingPageMobile/propTypes/index';
import { ReactComponent as Close } from '../../../svgs/close.svg';
import { errorPropType } from '../../propTypes/index';
import styles from './enrolledErrorPopUp.module.css';

const EnrolledErrorPopUp = ({ closePopUp, practice, error, goToBooking }) => (
  <div className={styles.container}>
    <div className={styles.button} onClick={closePopUp}>
      <Close className={styles.icon} />
    </div>
    <div className={styles.innerContainer}>
      <div className={styles.whoops}>Whoops</div>
      <NotEnrolled error={error} goToBooking={goToBooking} />
      <CallPractice practice={practice} />
    </div>
  </div>
);

EnrolledErrorPopUp.propTypes = {
  closePopUp: PropTypes.func.isRequired,
  practice: PracticeInfoPropType.isRequired,
  error: errorPropType,
  goToBooking: PropTypes.func,
};

export default EnrolledErrorPopUp;
