import React, { CSSProperties, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { ReactComponent as Lock } from 'svgs/blueLock.svg';
import { ReactComponent as Four } from 'svgs/4Pin.svg';
import { ReactComponent as Six } from 'svgs/6Pin.svg';
import PinControl from 'Identity/DesktopIdentityPage/components/PinControl';
import { ReactComponent as Arrow } from 'svgs/blueRightCircleArrow.svg';
import { ReactComponent as WhiteArrow } from 'svgs/rightWhiteArrow.svg';
import BlueButton from 'components/DesktopComponents/BlueButton';

interface IProps {
    goBack: () => void;
    goNext: () => void;
    learnMoreOnClick: () => void;
}

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const Card = styled.div`
    width: 534px;
    height: 677px;
    position: relative;
    background-color: white;
    border-radius: 4px;
    margin: 1rem auto;
    box-shadow: 0px 3px 6px #00000029;
`
const Text = styled.div`
    text-align: center;
    color: #07A3C8;
    font-size: 17px;
    margin-bottom: 1rem;
`
const FlexH = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
`
const FlexV = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin: auto;
`
const Button = styled.div`
    background-color: #07A3C8;
    color: white;
    border-radius: 4px;
    font-size: 13px;
    display: flex;
    height: 35px;
    width: 166px;
    flex-direction: row;
    cursor: pointer;
    text-align: center;
    margin-top: 1rem;
    align-items: center;
`
const Link = styled.div`
    font-size: 14px;
    color: #07A3C8;
    margin: 1.5rem auto;
    cursor: pointer;
`
const LineL = styled.div`
    width: 90%;
    border-bottom: 1px solid #DADADA;
    margin: 4rem 0rem;
`

const backButtonStyle: CSSProperties = {
    position: 'absolute',
    left: '1rem',
    top: '1rem',
    transform: 'rotate(180deg)',
    cursor: 'pointer',
    height: '40px',
    width: '40px',
}

// Isolated Desktop Component for setting up a users pincode (Just flow in the card)
const SetupPinFlowDesktop: React. FC<IProps> = observer(({ goBack, goNext, learnMoreOnClick }) => {
    const [state, setState] = useState(0);
    const [pinLength, setPinLength] = useState(0);
    
    const BackButtonFn = () => state === 0 ? goBack() : setState(state - 1)

    const renderState = () => {
        if (state === 0) {
            return (
                <FlexV style={{width: "315px"}}>
                    <Lock style={{margin: "2rem auto"}} />
                    <Text style={{color: "#6C6C6C", fontWeight: "bold", marginBottom: "1.5rem"}}>Create a PIN code</Text>
                    <Text style={{color: "#6C6C6C", textAlign: "left", marginBottom: "1rem"}}>A PIN code provides another layer of security to keep your data safe.</Text>
                    <Text style={{color: "#6C6C6C", textAlign: "left", marginBottom: "2rem"}}>Your PIN code will be required everytime you log in. <span onClick={learnMoreOnClick} style={{color: "#07A3C8", cursor: "pointer"}}>Learn more.</span></Text>

                    <Four style={{width: "100%"}} />

                    <FlexH style={{flexDirection: "column", marginTop: "3rem"}}>
                        <Button onClick={() => setState(1)}>
                            <div style={{marginLeft: "auto"}}>Setup PIN Code</div>
                            <WhiteArrow style={{margin: "0 auto 0 10px"}}/>
                        </Button>
                        {/* <Link onClick={() => goNext()}>Do it later</Link> */}
                    </FlexH>
                </FlexV>
            )
        } else if (state === 1) {
            return (
                <FlexV>
                    <Lock style={{margin: "2rem auto"}} />
                    <Text>Set your security PIN. Two options:</Text>
        
                    <FlexH style={{alignItems: "flex-end", marginTop: "2rem"}}>
                        <FlexV>
                            <Text style={{ marginLeft: "150px", marginBottom: "1.3rem"}}>4 digit PIN</Text>
                            <Four style={{width: "100%", marginLeft: "146px"}} />
                        </FlexV>
                        <Button style={{width: "178px", marginTop: "0px", marginRight: "2rem"}} onClick={() => {
                            setPinLength(4);
                            setState(2);
                        }}>
                            <div style={{marginLeft: "auto", fontWeight: "bold"}}>Continue</div>
                            <WhiteArrow style={{margin: "0 auto 0 10px"}}/>
                        </Button>
                    </FlexH>
                    <LineL />
                    <FlexH style={{alignItems: "flex-end"}}>
                        <FlexV>
                            <Text style={{color: "#07A3C8", marginLeft: "150px", marginBottom: "1.3rem"}}>6 digit PIN</Text>
                            <Six style={{width: "100%", marginLeft: "146px"}} />
                        </FlexV>
                        <Button style={{width: "178px", marginTop: "0px", marginRight: "2rem"}} onClick={() => {
                            setPinLength(6);
                            setState(2);
                        }}>
                            <div style={{marginLeft: "auto", fontWeight: "bold"}}>Continue</div>
                            <WhiteArrow style={{margin: "0 auto 0 10px"}}/>
                        </Button>
                    </FlexH>
                </FlexV>
            )
        } else if (state === 2) {
            return (
                <FlexV>
                    <Lock style={{margin: "2rem auto"}} />
                    <Text>Enter your new PIN code</Text>
                    <PinControl 
                        verify={false} 
                        goNext={() => setState(3)} 
                        goBack={() => setState(1)}
                        length={pinLength}    
                        ifGuessable={() => setState(4)}
                        submitPin={false}
                    />
                </FlexV>
            )
        } else if (state === 3) {
            return (
                <FlexV>
                    <Lock style={{margin: "2rem auto"}} />
                    <Text>Verify your new PIN code</Text>
                    <PinControl 
                        verify={true} 
                        goNext={() => goNext()} 
                        goBack={() => setState(2)}
                        length={pinLength}    
                        ifGuessable={() => {}}
                        submitPin={false}
                    />
                </FlexV>
            )
        } else if (state === 4) {
            return (
                <FlexV style={{width: "322px"}}>
                    <Lock style={{margin: "2rem auto"}} />
                    <Text style={{margin: "1rem 0rem", color: "#FC7B7B"}}><b>This PIN code can be easily guessed</b></Text>
                    <Text style={{margin: "2rem 0px"}}>Your PIN code is used to unlock your health information and to protect your data stored by Vensa.</Text>
                    <Text style={{margin: "0rem 0rem 3rem 0rem"}}>We recommend trying a different PIN code.</Text>
                    <ButtonContainer>
                        <BlueButton style={{fontSize: "17px", borderRadius: "36px"}} text={"Change PIN Code"} onClick={() => setState(1)} />
                        <Link style={{marginBottom: "0rem"}} onClick={() => setState(3)}>Use anyway</Link>
                        <Link style={{margin: "0 auto"}} onClick={() => setState(3)}>(not recommended)</Link>
                    </ButtonContainer>
                </FlexV>
            )
        }
    }

    return (
        <Card>
            <Arrow 
                onClick={BackButtonFn} 
                style={backButtonStyle} 
            />
            {renderState()}
        </Card>
    )
})

export default SetupPinFlowDesktop;