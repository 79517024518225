import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from 'components/vensaComponents';
import { MyHealthNavBarButton } from './MyHealthNavBarButton';
import { useMyHealthStore } from 'store/mobx/mobx';
import { MyHealthTabEnum } from 'store/mobx/myHealthStore/myHealthStore';
import { useComponentDidMount } from 'libs/newHttp';
import { useEffect } from 'react';

interface Props {
  errorText: string | null;
}

export const MyHealthNavBar: React.FC<Props> = observer(({ errorText }) => {
  const myHealthStore = useMyHealthStore();
  const [showNotes, setShowNotes] = useState(false);

  useEffect(() => {
    if (errorText === "") { myHealthStore.getClinicNotes(); }
    if (errorText === null) { setShowNotes(true); }
    if (errorText !== "" && errorText !== null) { setShowNotes(false); }
  })
  return (
    <Box overflowX="auto" gap="4px">
      <MyHealthNavBarButton
        label="Summary"
        isSelected={myHealthStore.currentTab === MyHealthTabEnum.Summary}
        onClick={() => {
          myHealthStore.goToTab(MyHealthTabEnum.Summary);
        }}
      />
      <MyHealthNavBarButton
        label="Lab Results"
        isSelected={myHealthStore.currentTab === MyHealthTabEnum.LabResults}
        onClick={() => {
          myHealthStore.goToTab(MyHealthTabEnum.LabResults);
        }}
      />
      <MyHealthNavBarButton
        label="Immunisations"
        isSelected={myHealthStore.currentTab === MyHealthTabEnum.Immunisations}
        onClick={() => {
          myHealthStore.goToTab(MyHealthTabEnum.Immunisations);
        }}
      />
      {showNotes &&
        <MyHealthNavBarButton
          label="Clinical Notes"
          isSelected={myHealthStore.currentTab === MyHealthTabEnum.ClinicalNotes}
          onClick={() => {
            myHealthStore.goToTab(MyHealthTabEnum.ClinicalNotes);
          }}
        />
      }
    </Box>
  );
});
