import { bindActionCreators } from "redux";
import { pick, difference, compose, keys, map, reduce } from "lodash/fp";

import { actions as sessionActions } from "../vAppointment/MobileVerification/actions/sessionActions";

const isBookingForSomeoneElse = state =>
  state.loginReducer.token
    ? state.patientDetailsReducer.patientDetails.dateOfBirth !==
      state.profileReducer.profile.DateOfBirth
    : !!state.guardianDetailsReducer.firstName;

const getAppointmentMobile = state => {
  if (state.loginReducer.token && state.profileReducer.profile.Mobile) {
    return state.profileReducer.profile.Mobile;
  }
  if (isBookingForSomeoneElse(state)) {
    return state.guardianDetailsReducer.mobile;
  }
  return state.patientDetailsReducer.patientDetails.mobile;
};

const allSessionProps = [
  "token",
  "firstName",
  "lastName",
  "dateOfBirth",
  "mobile",
  "dependantId",
  "organisationId",
  "pmsUserId",
  "appointmentId",
  "time",
  "note",
  "sessionId",
  "initiatorFirstName",
  "initiatorLastName",
  "initiatorDateOfBirth",
  "initiatorMobile",
  "isBookingForSomeoneElse",
];

const sessionState = excludeKeys => state => {
  const session = {
    firstName: state.patientDetailsReducer.patientDetails.firstName,
    lastName: state.patientDetailsReducer.patientDetails.lastName,
    dateOfBirth: state.patientDetailsReducer.patientDetails.dateOfBirth,
    mobile: state.patientDetailsReducer.patientDetails.mobile,
    dependantId: state.patientDetailsReducer.patientDetails.dependantId,
    organisationId: state.practiceProfileReducer.profile.Id,
    pmsUserId: state.practiceAppointmentReducer.selectedTime.doctorId,
    appointmentId: state.practiceAppointmentReducer.selectedTime.appointmentId,
    time: state.practiceAppointmentReducer.selectedTime.time,
    note: state.feelingPageReducer.feelings,
    service: state.feelingPageReducer.service,
    symptoms: state.feelingPageReducer.symptoms,
    sessionId: state.sessionReducer.sessionId,
    initiatorFirstName:
      state.guardianDetailsReducer.firstName || state.profileReducer.profile.FirstName,
    initiatorLastName:
      state.guardianDetailsReducer.lastName || state.profileReducer.profile.LastName,
    initiatorDateOfBirth:
      state.guardianDetailsReducer.dateOfBirth || state.profileReducer.profile.DateOfBirth,
    initiatorMobile: state.guardianDetailsReducer.mobile || state.profileReducer.profile.Mobile,
    isBookingForSomeoneElse: isBookingForSomeoneElse(state),
  };
  const keysToAdd = compose(
    difference,
    keys,
  )(session)(excludeKeys);
  return pick(keysToAdd)(session);
};

const sessionDispatch = dispatch => {
  const createSession = bindActionCreators(sessionActions.createSession, dispatch);
  const createFn = propsToAdd => props => () => {
    compose(
      createSession,
      reduce((sum, { key, value }) => ({ ...sum, [key]: value }), {}),
      map(prop => ({ key: prop, value: props[prop] })),
    )(propsToAdd);
  };
  return createFn;
};

export {
  sessionState,
  sessionDispatch,
  allSessionProps,
  getAppointmentMobile,
  isBookingForSomeoneElse,
};
