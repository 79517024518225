import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { map } from 'lodash/fp';
import { CSSTransitionGroup } from 'react-transition-group';
import { actions as profileActions } from '../../components/UIContainer/MobileUI/MainShell/actions/profileActions';
import { actions as depActions } from '../../DependantsPage/actions/actions';
import { actions as headerActions } from '../../components/UIContainer/actions/headerActions';
import { actions } from '../actions/actions';
import DesktopCta from '../../components/DesktopCta';
import DesktopCard from '../../components/DesktopCard';
import DesktopPopUp from '../../components/DesktopPopUp';
import UIContainer from '../../components/UIContainer';
import AccountVerificationPage from '../../HealthFeed/AccountVerificationPage';
import ScriptRequestPopUp from '../../vScript/RequestScriptDesktop/RequestScriptDesktop';
import desktopScript from './CurrentScript';
import pastScript from './PastScript';
import ConfirmCancelPopUp from '../../vScript/RequestScriptDesktop/ConfirmCancelPopUp';
import styles from './prescriptionListDesktop.module.css';
import { scriptPropType } from '../propTypes';
import { go } from '../../routes';
import { transitionGroupProps } from '../../config';
import Alert from '../../libs/Alert';
import styled from 'styled-components';

const Outer = styled.div`
    width: 100%;
    background-color: #F9F9F9;
    align-items: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
`
const Inner = styled.div`
	animation: appear 0.3s forwards;
	overflow-y: auto;
	overflow-x: hidden;
	-ms-overflow-x: hidden;
	padding: 0 0 40px;
	width: 665px;
	margin: auto;
	height: 855px;
	margin-bottom: auto;
	padding: 0rem 1rem;
  margin-bottom: 0.5rem;
    &::-webkit-scrollbar {
		width: 10px;
	 }
	   
	 &::-webkit-scrollbar-track {
		background: #F0F0F0;
		border-radius: 25px;
		margin-left: 1rem;
	 }
	 
	 &::-webkit-scrollbar-thumb {
		 background: #AFAFAF;
		 border-radius: 25px;
	 }
	 
	 &::-webkit-scrollbar-thumb:hover {
		 background: #999; 
	 }
  }
`

class PrescriptionListDesktop extends Component {
  static propTypes = {
    setSideHeaderItem: PropTypes.func.isRequired,
    fetchScript: PropTypes.func.isRequired,
    fetchCurrentRequests: PropTypes.func.isRequired,
    fetchDependants: PropTypes.func.isRequired,
    fetchPastRequests: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    currentRequests: PropTypes.arrayOf(scriptPropType).isRequired,
    pastRequests: PropTypes.arrayOf(scriptPropType).isRequired,
    token: PropTypes.string.isRequired,
    repeatPastRequest: PropTypes.bool.isRequired,
    mobileVerified: PropTypes.bool,
    removeSuccess: PropTypes.bool.isRequired,
  };
  state = {
    showPopUp: !!this.props.repeatPastRequest,
    showConfirmCancel: false,
  };
  componentDidMount() {
    const token = this.props.token;
    this.props.setSideHeaderItem('scripts');
    this.props.fetchDependants(token);
    this.props.fetchCurrentRequests(token);
    this.props.fetchPastRequests(token);
    this.props.getProfile(token);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.removeSuccess &&
      nextProps.removeSuccess !== this.props.removeSuccess
    ) {
      Alert.success('The prescription has been removed from your history');
      this.props.fetchCurrentRequests(this.props.token);
      this.props.fetchPastRequests(this.props.token);
    }
  }
  onVerifySuccess = () => {
    this.setState({
      showPopUp: true,
    });
  };
  cancel = () => {
    this.setState({ showConfirmCancel: false, showPopUp: false });
  };
  handleScriptClick = id => () => {
    go.prescriptionDetails(id);
    this.props.fetchScript(id, this.props.token);
  };
  togglePopUp = () => {
    if (!this.state.showPopUp) {
      this.setState({ showPopUp: true });
    } else if (this.props.mobileVerified) {
      this.setState({ showConfirmCancel: true });
    } else this.setState({ showPopUp: false });
  };
  render() {
    const { currentRequests, pastRequests, isFetching } = this.props;
    return (
      <UIContainer heading="Prescriptions" navKey='Prescriptions' isFetching={isFetching}>
        <Outer>
        <DesktopCta
          text="Request a Repeat Prescription"
          onClick={this.togglePopUp}
          background
          absolute
        />
        <Inner style={{height: window.innerHeight, marginTop: "80px"}}>
          {currentRequests.length > 0 && (
            <div className={styles.label}>
              {`Prescription requests (${currentRequests.length})`}
            </div>
          )}
          <div className={styles.currentRequests}>
            {map(a => (
              <DesktopCard key={a.Id} width="300">
                {desktopScript(a, this.handleScriptClick)}
              </DesktopCard>
            ))(currentRequests)}
          </div>
          {pastRequests.length > 0 && (
            <div className={styles.label}>Past Prescriptions</div>
          )}
          <div className={styles.pastRequests}>
            {map(a => pastScript(a, this.handleScriptClick))(pastRequests)}
          </div>
        </Inner>
        <CSSTransitionGroup {...transitionGroupProps(styles)}>
          {this.state.showPopUp && (
            <DesktopPopUp key="0" toggle={this.togglePopUp}>
              {this.state.showConfirmCancel && (
                <ConfirmCancelPopUp
                  key="3"
                  noFn={() => this.setState({ showConfirmCancel: false })}
                  yesFn={this.cancel}
                />
              )}
              {this.props.mobileVerified ? (
                <ScriptRequestPopUp
                  key="1"
                  hide={this.state.showConfirmCancel}
                  close={this.togglePopUp}
                  cancel={this.cancel}
                />
              ) : (
                <AccountVerificationPage
                  key="2"
                  cancel={this.togglePopUp}
                  onVerifySuccess={this.onVerifySuccess}
                />
              )}
            </DesktopPopUp>
          )}
        </CSSTransitionGroup>
        </Outer>
      </UIContainer>
    );
  }
}

const mapStateToProps = state => ({
  token: state.loginReducer.token,
  currentRequests: state.scriptReducer.currentRequests,
  pastRequests: state.scriptReducer.pastRequests,
  removeSuccess: state.scriptReducer.removeSuccess,
  isFetching:
    state.scriptReducer.isFetchingCurrent ||
    state.scriptReducer.isFetchingPast ||
    state.scriptReducer.isRemoving,
  repeatPastRequest: state.scriptDetailsReducer.repeatPastRequest,
  mobileVerified: state.profileReducer.profile.MobileVerified,

});

const mapDispatchToProps = dispatch => ({
  setSideHeaderItem: bindActionCreators(
    headerActions.setSideHeaderItem,
    dispatch,
  ),
  fetchScript: bindActionCreators(actions.fetchScriptDetails, dispatch),
  fetchCurrentRequests: bindActionCreators(
    actions.fetchCurrentRequests,
    dispatch,
  ),
  fetchPastRequests: bindActionCreators(actions.fetchPastRequests, dispatch),
  fetchDependants: bindActionCreators(depActions.fetchDependants, dispatch),
  getProfile: bindActionCreators(profileActions.getProfile, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrescriptionListDesktop);
