import React, { Component } from 'react';
import PropTypes from 'prop-types';
import makeAsyncScriptLoader from 'react-async-script';

import Form from './Form';
import { STRIPE_KEY } from '../../../config';

class PaymentForm extends Component {
  static propTypes = {
    Stripe: PropTypes.func,
    handleNext: PropTypes.func,
    onError: PropTypes.func,
    hideSaveCard: PropTypes.bool
  };
  state = {
    stripe: null,
  };
  componentDidMount() {
    if (this.props.Stripe) {
      this.createStripe(this.props.Stripe);
    }
  }
  UNSAFE_componentWillReceiveProps({ Stripe }) {
    if (Stripe && !this.props.Stripe) {
      this.createStripe(Stripe);
    }
  }
  createStripe = Stripe => {
    this.setState({ stripe: Stripe(STRIPE_KEY) });
  };
  render() {
    if (this.props.Stripe) {
      return (
        <Form
          stripe={this.state.stripe}
          handleNext={this.props.handleNext}
          onError={this.props.onError}
          appointment={this.props.appointment}
          hideSaveCard={this.props.hideSaveCard}
        />
      );
    }
    return <div>Loading...</div>;
  }
}

const URL = 'https://js.stripe.com/v3/';
const globalName = 'Stripe';

export default makeAsyncScriptLoader(PaymentForm, URL, {
  globalName,
  removeOnUnmount: true,
});
