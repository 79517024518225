import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';

import AppointmentsList from './AppointmentListMobile/index';
import MenuContainer from '../HealthFeed/HealthFeedMobile/MenuPages/index';
import { ReactComponent as PlusIcon } from '../svgs/plus.svg';
import CtaMenuButton from '../components/CtaMenuButton/index';
import styles from './appointments.module.css';
import { actions } from './actions/actions';
import { appointmentPropType } from './propTypes/index';

class Appointments extends Component {
  static propTypes = {
    changeCtaIcon: PropTypes.func.isRequired,
    changeCtaFn: PropTypes.func.isRequired,
    ctaFn: PropTypes.func.isRequired,
    ctaIcon: PropTypes.func.isRequired,
    showCta: PropTypes.bool.isRequired,
    appointments: PropTypes.arrayOf(appointmentPropType).isRequired,
    pastAppointments: PropTypes.arrayOf(appointmentPropType).isRequired,
  };
  state = {
    showMenu: false,
    cardIsOpen: false,
  };
  componentDidMount() {
    this.props.changeCtaFn(this.toggleMenu);
  }
  getCtaStyles = () => {
    if (this.state.cardIsOpen) {
      return {
        iconStyles: styles.ctaIconCard,
        buttonStyles: styles.ctaButtonCard,
        text: 'Add to my Calendar',
      };
    }
    return {
      buttonStyles: styles.ctaButton,
      text: 'New Appointment',
      iconStyles: styles.buttonIcon,
      textIconStyles: styles.textButtonIcon,
    };
  };
  toggleOpenCardState = () => {
    this.setState({ cardIsOpen: !this.state.cardIsOpen }, this.changeCta);
  };
  changeCta = () => {
    if (!this.state.cardIsOpen) {
      this.props.changeCtaFn(this.toggleMenu);
      this.props.changeCtaIcon(PlusIcon);
    }
  };
  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu }, this.toggleStyles);
  };
  handleNoAppointmentClick = () => {
    if (this.cta) {
      this.cta.handleClick();
    }
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.inner} style={{overflow: this.state.cardIsOpen ? "visible" : "scroll"}}>
            <AppointmentsList
              key={1}
              currentAppointments={this.props.appointments}
              pastAppointments={this.props.pastAppointments}
              toggleOpenCardState={this.toggleOpenCardState}
              isOpen={this.state.cardIsOpen}
              noAppointmentClick={this.handleNoAppointmentClick}
            />
        </div>
        {this.props.showCta && (
          <CtaMenuButton
            ref={c => {
              this.cta = c;
            }}
            {...this.getCtaStyles()}
            onClick={this.props.ctaFn}
            icon={this.props.ctaIcon}
          />
        )}
        <CSSTransitionGroup
          component="div"
          transitionName={{
            leave: styles.leave,
            leaveActive: styles.leaveActive,
            enter: styles.enter,
            enterActive: styles.enterActive,
          }}
          transitionLeaveTimeout={300}
          transitionEnterTimeout={300}
        >
          {this.state.showMenu && (
            <MenuContainer key="menuContainer" appointmentOnly />
          )}
        </CSSTransitionGroup>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ctaFn: state.appointmentsCTAReducer.ctaFn,
  ctaIcon: state.appointmentsCTAReducer.ctaIcon,
  showCta: state.appointmentsCTAReducer.showCta,
  appointments: state.fetchConfirmedAppointmentsReducer.appointments,
  pastAppointments: state.fetchPastAppointmentsReducer.appointments,
});
const mapDispatchToProps = dispatch => ({
  changeCtaFn: bindActionCreators(actions.changeCtaFn, dispatch),
  changeCtaIcon: bindActionCreators(actions.changeCtaIcon, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Appointments);
export { styles };
