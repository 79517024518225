import { isDesktop } from 'config';
import React, { CSSProperties } from 'react';
import styled, { StyledComponent } from 'styled-components';

import { ReactComponent as Cross } from 'svgs/blueCross.svg';

const Container = styled.div`
    border-radius: 4px;
    position: relative;
    background-color: white;
    overflow: hidden;
    padding: 2rem 0rem;
    display: flex;
    flex-direction: column;
    margin: auto;
    height: 100%;
`
const Icon = styled.img`
    display: block;
    width: 100%;
    height: 400px;
    z-index: 4
`
// width: 425px;
// position: absolute;
const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0rem 1rem;
    text-align: left;
    margin-top: auto;
`
const Title = styled.div`
    color: #585858;
    font-size: 17px;
    font-weight: 600;
    margin: 0.2rem 0rem;
`
const Text = styled.div`
    font-size: 13px;
    color: #6C6C6C;
`
const FlexH = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`
const Slide = styled.div`
    height: 15px;
    width: 15px;
    background-color: #AFAFAF;
    border-radius: 50%;
    margin: 1rem;
    margin-left: 0px;
    cursor: pointer;
`
const ActiveSlide = styled.div`
    height: 15px;
    width: 15px;
    background-color: #07A3C8;
    border-radius: 50%;
    margin: 1rem;
    margin-left: 0px;

`
const Button = styled.div`
    color: #07A3C8;
    font-size: 17px;
    font-weight: bold;
    margin: 2rem;
    cursor: pointer;
    margin-left: auto;
`
const BlueButton = styled.div`
    background-color: #07A3C8;
    color: white;
    font-size: 17px;
    font-weight: bold;
    padding: 0.75rem 2rem;;
    border-radius: 36px;
    margin: 1rem 2rem;
    cursor: pointer;
    margin-left: auto;
    box-shadow: 0px 3px 6px #00000029;
`;

interface ContentContainerProps {
    paddingBottom?: string | number
}
const ContentContainer = styled.div<ContentContainerProps>`
    margin-top: auto;
    padding-bottom: ${p => p.paddingBottom ?? '0'}
`
interface Props {
    hide: (e: any) => void;
    totalCards: number;
    title: string;
    text: string;
    linkText?: string;
    linkTarget?: string;
    active: number; // Which slide is active
    onNext: (e: any) => void;
    finishButton?: boolean;
    icon: string;
    goBack: (newState: number) => void;
    noX?: boolean;
    buttonText?: string;
    injectStyle?: CSSProperties;
}
const Card: React.FC<Props> = ({
    hide, buttonText, totalCards, title, text, linkText, linkTarget, active,
    onNext, finishButton, icon, goBack, noX, injectStyle }) => {
    let slides: boolean[] = [];

    for (var i = 0; i < totalCards; i++) {
        slides.push(i === active);
    }
    return (
        <Container style={{
            padding: noX ? "0rem" : "2rem 0rem",
            overflow: isDesktop() ? "visible" : "hidden"
        }}>
            {!noX && <Cross onClick={hide}
                style={
                    {
                        position: "absolute",
                        width: "30px",
                        top: "1rem",
                        right: "1rem",
                        cursor: "pointer",
                        zIndex: 5
                    }} />}
            <Icon
                style={
                    {
                        top: isDesktop() ? "-30px" : "0px",
                        // left: isDesktop() ? "-15px" : "-40px"
                        width: "100%"
                    }
                }
                src={icon} />
            <ContentContainer paddingBottom={injectStyle?.paddingBottom}>
                <TextContainer>
                    <Title>{title}</Title>
                    <Text>{text}</Text>
                    {
                        linkText &&
                        <a
                            style={{ color: '#07a3c8', textDecoration: 'none', fontSize: '13px' }}
                            href={linkTarget} target='_blank'>
                            {linkText}
                        </a>}
                </TextContainer>
                <FlexH>
                    <FlexH style={{ marginLeft: noX ? "0rem" : "1rem" }}>
                        {slides.map((active, i) => active ? <ActiveSlide key={i} /> : <Slide onClick={() => goBack(i)} key={i} />)}
                    </FlexH>
                    {/* {finishButton ?
                        <BlueButton onClick={onNext}>{buttonText ? buttonText : "Get Started"}</BlueButton>
                    :
                        <Button onClick={onNext}>Next</Button>
                    } */}
                    <Button onClick={onNext} >{buttonText}</Button>
                </FlexH>
            </ContentContainer>
        </Container>
    )
}

export default Card;
