import {
  fetchConnectedPracticesSaga,
  fetchAllPracticesSaga,
} from './sagas/fetchScriptPractices';
import { getMatchingSaga } from './sagas/fuzzySearch';
import { fetchDoctorsSaga } from './sagas/fetchDoctors';
import {
  getCurrentRequestsSaga,
  getPastRequestsSaga,
} from '../PrescriptionsPage/sagas/getScripts';
import { getScriptDetailsSaga } from '../PrescriptionsPage/sagas/getScriptDetails';
import { searchMedsSaga } from './sagas/searchMeds';
import { ableToRequestSaga } from './sagas/ableToRequest';
import { getPickUpOptionsDependantsSaga, getPickUpOptionsSaga } from './PickUpLocation/sagas/getPickUpOptions';
import { submitScriptRequestSaga } from './ConfirmRequest/sagas/submitScriptRequest';
import {
  scriptConfirmationSaga,
  requestConfirmationSaga,
} from './ConfirmRequest/sagas/checkConfirmation';
import { retryRequestSaga } from './ConfirmRequest/sagas/retryRequest';
import { addPharmacySaga, getOptionsSaga } from './PickUpLocation/AddPharmacy/sagas/addPharmacy';
import { deletePharmacySaga } from './PickUpLocation/AddPharmacy/sagas/deletePharmacy';
import { fetchCardDetailsSaga } from './Payment/sagas/cardDetails';
import { getMedicationsSaga } from './sagas/getMedications';

const vScriptSagas = [].concat(
  getMedicationsSaga,
  ableToRequestSaga,
  fetchAllPracticesSaga,
  fetchConnectedPracticesSaga,
  fetchDoctorsSaga,
  getCurrentRequestsSaga,
  getMatchingSaga,
  getPastRequestsSaga,
  getPickUpOptionsSaga,
  submitScriptRequestSaga,
  scriptConfirmationSaga,
  requestConfirmationSaga,
  retryRequestSaga,
  addPharmacySaga,
  deletePharmacySaga,
  getScriptDetailsSaga,
  searchMedsSaga,
  fetchCardDetailsSaga,
  getOptionsSaga,
  getPickUpOptionsDependantsSaga
);

export { vScriptSagas };
