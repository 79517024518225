import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { go } from '../../routes';
import { useEffect } from 'react';
import { useMyHealthStore } from 'store/mobx/mobx';

const Container = styled.div`
    background-color: #F4F4F4;
    width: 100%;
    height: fit-content;
    padding: 0.6rem 2rem;
`
const TabContainer = styled.div`
    width: fit-content;
    display: flex;
    margin: 0 auto;
`
const Tab = styled.div`
    padding: 0rem 1rem;
    font-size: 14px;
    cursor: pointer;
`

interface Props {
    id: number;
    type: number;
    hideNotes?: boolean;
}
interface Tab {
    id: number;
    name: string;
    onClick: () => void;
}

const SettingsTabs = [
    {
        id: 0,
        name: "Home",
        onClick: () => { go.settings() }
    },
    {
        id: 1,
        name: "Identity",
        onClick: () => { go.identity() }
    },
    {
        id: 2,
        name: "Privacy",
        onClick: () => { go.privacy() }
    },
    {
        id: 3,
        name: "Security",
        onClick: () => { go.security() }
    }
]
const PHRTabs = [
    {
        id: 0,
        name: "Health Summary",
        onClick: () => { go.PHRSummary() }
    },
    {
        id: 1,
        name: "Labs/Results",
        onClick: () => { go.LabsPage(); }
    },
    {
        id: 2,
        name: "Immunisations",
        onClick: () => { go.ImmsPage(); }
    },
    {
        id: 3,
        name: "Clinical Notes",
        onClick: () => { go.NotesPage() }
    }
]
const Tabs = [SettingsTabs, PHRTabs];

const TabBar: React.FC<Props> = observer(({id, type, hideNotes}) => {
    if (hideNotes) {
        if (PHRTabs.length === 4) {
            PHRTabs.pop();
        }
    } else {
        if (PHRTabs.length === 3) {
            PHRTabs.push(
                {
                    id: 3,
                    name: "Clinical Notes",
                    onClick: () => { go.NotesPage() }
                }   
            )
        }
    }
    return (
        <Container>
            <TabContainer>
                {Tabs[type].map((tab) => {
                        return (
                            <Tab 
                                key={tab.id}
                                onClick={tab.onClick}
                                style={{
                                    borderRight: Tabs[type].length - 1 !== tab.id ? "2px solid #DADADA" : "none",
                                    color: tab.id === id ? "#07A3C8" : "#939393"
                            }}>
                                {tab.name}
                            </Tab>
                    )}
                )}
            </TabContainer>
        </Container>
    );
});

export default TabBar;