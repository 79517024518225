import { call, select, takeEvery, put } from 'redux-saga/effects';
import { GenericParserHttp } from '../../../libs/Http';
import Alert from '../../../libs/Alert';
import { actions, actionTypes } from '../actions/actions';

const api = recallId =>
  GenericParserHttp.get(
    `smoking-cessation/recall/detail/appointment/${recallId}`,
  ).then(({ body }) => body);

function* fetchPatientDetailForSmokingAppointment() {
  const smokingRecallId = yield select(
    state => state.practiceAppointmentReducer.smokingRecallId,
  );

  try {
    const { FirstName, LastName, Mobile, DateOfBirth } = yield call(
      api,
      smokingRecallId,
    );

    yield put(
      actions.addDetails({
        firstName: FirstName || '',
        lastName: LastName || '',
        mobile: Mobile || '',
        dateOfBirth: DateOfBirth || '',
      }),
    );
  } catch (err) {
    if (err.status === 403) {
      Alert.warning('Something wrong is happening.');
    }
  }
}

export function* fetchPatientDetailForSmokingAppointmentSaga() {
  yield takeEvery(
    actionTypes.fetchPatientDetailForSmokingAppointment,
    fetchPatientDetailForSmokingAppointment,
  );
}
