import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash/fp';
import classnames from 'classnames';

import DesktopCard from '../../../components/DesktopCard/index';
import EmptyFull from '../EmptyFeedCardFull/index';
import styles from './fullsectionContainer.module.css';

const SectionContainerFull = ({
  heading,
  onClick,
  elements,
  fullemptyIcon,
  fullemptyText,
  activeHeader = true,
}) => (
  <div className={styles.fullcontainer}>
    <div
      className={classnames(styles.heading, { [styles.blue]: activeHeader })}
      onClick={onClick}
    >
      {heading}
      {elements.length > 0 && activeHeader && ` (${elements.length})`}
    </div>
    {elements.length ? (
      map(e => (
        <DesktopCard key={Math.random()} width="620">
          {e}
        </DesktopCard>
      ))(elements)
    ) : (
      <EmptyFull icon={fullemptyIcon} text={fullemptyText} />
    )}
  </div>
);

SectionContainerFull.propTypes = {
  heading: PropTypes.string.isRequired,
  elements: PropTypes.arrayOf(PropTypes.node).isRequired,
  onClick: PropTypes.func,
  fullemptyIcon: PropTypes.node,
  fullemptyText: PropTypes.string,
  activeHeader: PropTypes.bool,
};

export default SectionContainerFull;
