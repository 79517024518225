import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from '../actions/actions';
import { actions as menuActions } from '../actions/menuActions';
import List from "vScript/RequestScriptMobile/List";
import styles from 'vScript/RequestScriptMobile/requestScriptMobile.module.css';
import { go } from '../../routes';
import { CSSTransitionGroup } from 'react-transition-group';
import classnames from 'classnames';
import { ReactComponent as Spinner } from '../../svgs/spinner.svg';

interface Props {
    getMedications(orgId: string, patientId: string, token: string);
    doctors: any,
    isFetchingDoctors: boolean;
    fetchDoctors: Function,
    fixed: any;
    token: string,
    userId: string,
    setInfo: Function,
    orgId: string,
    patient: any,
    checkinfIfCanRequest: boolean,
    patientCanRequest: boolean,
    checkAbleToRequest: Function,
}

const DoctorSelectionMobile: React.FC<Props> = (props) => {

    useEffect(() => {
        if (!props.patient) go.selectPatient();
        if (!props.orgId) go.selectPractice();
        if (!props.doctors || (props.doctors.length === 0 && !props.isFetchingDoctors)) {
            props.fetchDoctors(props.orgId, props.token);
        }
    }, []);

    const patientIsVerified = (patient) => {
        if (!patient) return false;
        if (patient.IdentityStatus && patient.IdentityStatus.StatusLabel) {
            return patient.IdentityStatus.StatusLabel.toLowerCase() === 'verified';
        }
        return patient.IdentityVerified;
    }

    const checkCanRequestMeds = (pmsUserId) => {
        const { patient, orgId, token } = props;
        if (props.userId !== patient.Id) {
            props.checkAbleToRequest(orgId, pmsUserId, token, patient.Id);
        } else props.checkAbleToRequest(orgId, pmsUserId, token);
    }

    return (
        <div className={styles.scriptContainer}>
            <CSSTransitionGroup
                component="div"
                className={classnames(styles.container, {
                    [styles.fixed]: props.fixed,
                })}
                transitionName={{
                    leave: styles.leave,
                    enter: styles.enter,
                }}
                transitionLeaveTimeout={300}
                transitionEnterTimeout={300}
            >
                {(props.isFetchingDoctors || props.checkinfIfCanRequest) && (
                    <div className={styles.spinnerContainer}>
                        <Spinner className={styles.spinner} />
                    </div>
                )}
                <div className={styles.listContainer}>
                    <List
                        heading="Which doctor?"
                        options={props.doctors}
                        arrowFn={() => go.selectPatient()}
                        optionOnClick={o => {
                            props.setInfo('doctor', o);
                            checkCanRequestMeds(o.Id);
                            if (!patientIsVerified(props.patient)) {
                                go.medicationSearch();
                              } else {
                                props.getMedications(props.orgId, props.patient.Id, props.token);
                                go.addMedication();
                              }
                        }}
                    />
                </div>
            </CSSTransitionGroup>
        </div>
    );
}

const mapStateToProps = state => ({
    token: state.loginReducer.token,
    userId: state.profileReducer.profile.Id,
    doctors: state.scriptMenuReducer.doctors,
    patient: state.scriptDetailsReducer.patient,
    orgId: state.scriptDetailsReducer.practice.Id,
    isFetchingDoctors: state.scriptMenuReducer.isFetchingDoctors,
    checkinfIfCanRequest: state.ableToRequestReducer.isChecking,
    patientCanRequest: state.ableToRequestReducer.canRequest,
});
const mapDispatchToProps = dispatch => ({
    /* eslint-disable max-len */
    fetchDoctors: bindActionCreators(menuActions.fetchDoctors, dispatch),
    setInfo: bindActionCreators(actions.setInfo, dispatch),
    getMedications: bindActionCreators(actions.getMedicationList, dispatch),
    checkAbleToRequest: bindActionCreators(actions.ableToRequest, dispatch),
    /* eslint-enable max-len */
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DoctorSelectionMobile);
export { DoctorSelectionMobile };