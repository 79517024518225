import React, { useRef } from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';

import styles from './autoSuggestText.module.css';
// import { isDesktop } from '../../config';
import { ReactComponent as Close } from './close.svg';
import { connect } from 'react-redux';
import { useComponentDidMount } from 'libs/newHttp';
import classnames from 'classnames';

const AutoSuggestText = observer(({postItem, searchIndex, addSymptomValue, toggle, items, _selected, reasonIndex, addDisplayNone, removeDisplayNone, stopAllSearch, shiftBoxes, changeValue, removeSymptomValue, setShowSearch, type, hide, placeholder}) => {
    
    const suggest = useLocalStore(() => {
        return {
            suggestItems: [],
            selected: false,
            text: '',
            width: '100%',
            loadSuggestions: (data, value) => {
                const sortedData = data.sort();
                const remained = sortedData.filter(a => !!value ? a.Name.match(new RegExp(`^${value}`, 'i')) : true);
                if(remained.length > 0) {
                    suggest.suggestItems = [...remained];
                }else{ // show Other Reasons option
                    suggest.suggestItems = [...(sortedData.filter(a => a.Name.startsWith("Other reasons")))];
                }
            },
            clearSuggestions: () => {
                suggest.suggestItems = [];
            },
            selectSuggestion: (item) => {
                suggest.text = item.Name;
                suggest.width = (item.Name.length + 6) * 8 + "px";
                suggest.selected = true;
                suggest.clearSuggestions();
            },
            changeText: (value) => {
                suggest.text = value;
            },
            reset: () => {
                suggest.selected = false;
                suggest.width = '100%';
                suggest.text = '';
                suggest.clearSuggestions();
            },
            status: () => suggest.selected ? 1 : suggest.text ? 2 : 0
        }
    });

    const wrapperRef = useRef(HTMLDivElement);

    useComponentDidMount(() => {
        document.addEventListener('mousedown', handleClickOutside);
    })

    const onTextChanged = (e) => {
        const value = e.target.value;
        suggest.loadSuggestions(items, value);
        suggest.changeText(value);
    }
    
    const handleClickOutside = (event) => {
        if(wrapperRef.current && !wrapperRef.current.contains(event.target)) { // click outside
            switch(suggest.status()){
                case 0:  // text from input
                    suggest.clearSuggestions();
                    break;
                case 2:  // text from select
                    suggest.reset();
                    break;
            }
        }
    }

    const onInputClicked = (e) => {
        if(suggest.selected){
            return;
        }

        if (suggest.text === '') { // show all suggestion items
            suggest.loadSuggestions(items);
        } 
    }

    const renderSuggestions = () => {
        const heightOfSingleItem = 38;
        const maxHeightOfList = 200; 
        let heightOfList = heightOfSingleItem * suggest.suggestItems.length;

        if (heightOfList > maxHeightOfList) {
            heightOfList = maxHeightOfList;
        }

        return (
            <ul className={classnames(styles.list, {[styles.displayNone]: suggest.suggestItems.length === 0})} style={{height: heightOfList, width: reasonIndex == 1 ? "242px" : "446px"}}>
                {
                    suggest.suggestItems.map(
                        (item) => <li key={item.Id} onClick={() => {
                            suggest.selectSuggestion(item);
                            if (changeValue) {
                                changeValue(item, reasonIndex);
                            }
                            if (reasonIndex !== null) addSymptomValue(item);
                            if (reasonIndex === null) postItem(item);
                            removeDisplayNone();
                        }} >{item.Name}</li>)
                }
            </ul>
        )
    }

    return (
        <div className={styles.autoSuggestText} style={{width: suggest.width}} ref={wrapperRef}>
            {renderSuggestions()}
            <div className={styles.inputContainer}>
                <input
                    disabled={suggest.selected}
                    className={classnames({[styles.displayNone]: hide, [styles.selected]: suggest.selected, [styles.unselected]: !suggest.selected})}
                    style={{width: (placeholder.length < 6 && !suggest.selected) ? "83px" : "100%"}} 
                    value={suggest.text} 
                    type="text" 
                    onClick={onInputClicked} 
                    onChange={onTextChanged} 
                    placeholder={placeholder}
                />
                <Close
                    className={classnames(styles.close, {[styles.displayNone]: !suggest.selected})}
                    onClick= {() => {
                        if (reasonIndex === 0) {
                            shiftBoxes(); 
                        }
                        removeSymptomValue(reasonIndex);
                        suggest.reset();
                    }}
                 />
            </div>
        </div>
    );
})

const mapStateToProps = state => {
    return {
        isFetching: state.feelingPageReducer.isFetching,
        error: state.feelingPageReducer.error,
        received: state.feelingPageReducer.received,
    }
}

export default connect(mapStateToProps, null)(AutoSuggestText);
