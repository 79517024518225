import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from '../../../components/vensaComponents';
import { TabBox } from '../TabBox';
import { LabResultsItem } from './components/LabResultsItem';
import { useAccountStore, useMyHealthStore } from '../../../store/mobx/mobx';
import { BaseList } from '../../../MyHealthPage/components/BaseList';
import { useComponentDidMount } from '../../../libs/newHttp';
import BannerEmptyFeedCard from 'HealthFeed/HealthFeedMobile/BannerEmptyFeedCard/BannerEmptyFeedCard';
import { go } from 'routes';
import { useDispatch } from 'react-redux';
import { actions } from 'components/UIContainer/MobileUI/MainShell/actions/actions';

export interface ILabResult {
  Id: string;
  NameOfTest: string;
  ConsumerId: string;
  PatientId: string;
  PatientPmsId: string;
  GivenName: string;
  FamilyName: string;
  AttentionStaffCode: string;
  Abnormal: number;
  OrganisationId: string;
  OrganisationName: string;
  ProfilePictureURL: string;
  ProviderId: string;
  ProviderName: string;
  DoctorNotes: string;
  Confidential: boolean;
  DateTime: string;
  IsNewInPastMonth: boolean;
  Results: string;
  Comments: [];
  ErrorMessage: string;
  NeedFeedBackForLabResearch: boolean;
  NoteId: string;
}


export const LabResultsTab: React.FC = observer(() => {
  const store = useAccountStore();
  const [show, setShow] = useState(true);
  const phrStore = useMyHealthStore();

  const dispatch = useDispatch();
  useComponentDidMount(phrStore.getLabResults.bind(phrStore));

  useEffect(() => {
    if (phrStore.labResultsTab.feedback.length > 0) {
      dispatch(actions.setWhiteBoxNotification({
        isOpen: true,
        index: 3
      }))
    }
  })

  return (
    <TabBox p={1}>
        {phrStore.labsErrorText !== null && show &&
          <BannerEmptyFeedCard 
            bannerText={"Access Restricted"}
            title={""}
            text={phrStore.labsErrorText}
            button={true}
            buttonText={"Ok got it"}
            link={true}
            linkText={"Check my privacy"}
            restricted={true}
            linkOnClick={() => go.privacy()}
            buttonOnClick={() => {
              setShow(false);
              store.sendAcknowledgement(0, phrStore.getLabResults.bind(phrStore));
            }} // ACK 0}
          />
        }
      <Box direction="column" gap="8px">
        {phrStore.labResultsTab.newInLastMonth.map(item => (
          <LabResultsItem key={item.Id} {...item} />
        ))}
      </Box>

      <BaseList
        title={phrStore.labResultsTab.past.length !== 0 ? "PREVIOUS RESULTS" : "NO PREVIOUS RESULTS FOUND"}
        space={phrStore.labResultsTab.newInLastMonth.length === 0}
        render={() =>
          phrStore.labResultsTab.past.map(item => <LabResultsItem key={item.Id} {...item} />)
        }
      />
    </TabBox>
  );
});
