import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { ReactComponent as Cross } from '../../../svgs/identityCrossRed.svg';
import { ReactComponent as Arrow } from '../../../svgs/roundArrow.svg';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
`

const Card = styled.div`
    width: 527.26px;
    height: 247.2px;
    padding-top: 25px;
    background-color: white;
    box-shadow: 1px 1px 1px 0px lightgrey;
    margin-top: 1rem;
`
const FlexH = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 3rem;
    height: 120px;
`
const Text = styled.div`
    font-size: 21px;
    color: #FF6A65;
    margin-left: 10px;
`
const Button = styled.div`
    display: flex;
    flex-direction: row;
    color: #07A3C8;
    font-size: 17px;
    width: 100%;
    padding-left: 63px;
    padding-top: 15px;
    line-height: 29px;
    cursor: pointer;
`
const Line = styled.div`
    width: 94%;
    margin: 0rem auto;
    border-bottom: 2px solid #DADADA;
    padding: 1px;
    margin-top: 36px;
`

interface IProps {
    identityVerified: boolean,
    patientHasPractices: boolean,
    onClick: () => void
}

const IdentityStatusLabel: React.FC<IProps> = observer(({identityVerified, patientHasPractices, onClick}) => {
    //  Decide state based on verification status and patient practices.
    const state = identityVerified ? 2 : patientHasPractices ? 1 : 0
    return(
        <Container data-test={"identity-status-label-component"}>
            { state === 0 &&
                <Card data-test={"identity-not-verified-component"}>
                    <FlexH>
                        <Cross />
                        <Text><b>Local</b> Identity at practice <b>Not</b> Verified</Text>
                    </FlexH>
                    <Line />
                    <Button onClick={() => onClick()}>Start Identity Verification <Arrow style={{margin: "auto", marginLeft: "180px"}} /></Button>
                </Card>
            }
            { state === 1 &&
                <FlexH data-test={"identity-not-verified-empty-component"}>
                    <Cross />
                    <Text><b>Local</b> Identity at practice <b>Not</b> Verified</Text>
                </FlexH>
            }
        </Container>
    )
})

export default IdentityStatusLabel;