import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Text } from 'components/vensaComponents';
import { useMyHealthStore } from 'store/mobx/mobx';
import moment from 'moment';

export const SummaryHeader: React.FC = observer(() => {
  const { GivenName, FamilyName, DateOfBirth } = useMyHealthStore().summaryTab;

  const birthOfDate = moment(DateOfBirth).format('D MMMM YYYY');
  const age = moment().diff(DateOfBirth, 'years');
  const ageText = `( ${age} years old )`;

  return (
    <Box direction="column" gap="4px" mb="32px" m={3}>
      <Text fontWeight={700} fontSize="22px">
        Medical Summary
      </Text>
      <Text fontWeight={600} fontSize={"17px"}>{`${GivenName} ${FamilyName}`}</Text>
      <Text fontSize={"17px"}>{`${birthOfDate} ${ageText}`}</Text>
    </Box>
  );
});
