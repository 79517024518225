import { actionTypes } from '../actions/actions';

const INITIAL_STATE = {
  isChecking: false,
  error: null,
  isEnrolled: false,
  session: {}
};

const checkEnrollmentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.checkEnrolledPatient: {
      return {
        isChecking: true,
        error: null,
        isEnrolled: false,
        session: {}
      };
    }
    case actionTypes.enrolledPatientSuccess: {
      return {
        isChecking: false,
        error: null,
        isEnrolled: true,
        session: action.payload.data
      };
    }
    case actionTypes.enrolledPatientFailure: {
      return {
        isChecking: false,
        error: action.payload.error,
        isEnrolled: false,
        session: {},
      };
    }
    case actionTypes.clearState: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default checkEnrollmentReducer;
