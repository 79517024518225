import React from 'react';
import { observer } from 'mobx-react-lite';
import UIContainer from 'components/UIContainer';
import { useParams } from 'react-router';
import { Redirect } from 'react-router-dom';
import { BaseDetailSection } from 'MyHealthPage/components/BaseDetailSection';
import { useMyHealthStore } from 'store/mobx/mobx';
import { AddComment } from 'MyHealthPage/components/AddComment';
import { CommentType } from 'store/mobx/myHealthStore/myHealthStore.type';
import { go } from 'routes';
import moment from 'moment';

const ImmunisationPage: React.FC = observer(() => {
  const { id } = useParams<{ id: string }>();
  const phrStore = useMyHealthStore();
  const itemToShow = phrStore.immunisationsTab.all.find(item => item.Id === id);

  if (!id || !itemToShow) {
    return <Redirect to={'/home'} />;
  }

  const immunText = 
    `Batch Number: ${itemToShow.BatchNumber}\t` + `Expiry: ${itemToShow.Expiry}\t` + `Route: ${itemToShow.Route}\t` + `Site: ${itemToShow.Site}`
  const getDate = (dateTime: string) => moment(dateTime).format('D/MM/YY');
  
  return (
    <UIContainer heading=" " isFetching={false}  phrText={"Performed on " + getDate(itemToShow.DateTime)} goBackFn={() => go.home(3)}>
      <div style={{marginBottom: "1rem"}}>
        <BaseDetailSection
          internalComment={""}
          isLabResult={false}
          topText={itemToShow.DoctorNotes}
          providerName={itemToShow.ProviderName}
          providerImageUrl={itemToShow.ProfilePictureURL}
          dateTime={itemToShow.DateTime}
          itemName={itemToShow.NameOfImmunisation}
          givenName={itemToShow.GivenName}
          familyName={itemToShow.FamilyName}
          organisationName={itemToShow.OrganisationName}
          bottomTitle="Summary:"
          bottomText={immunText}
          doctorNote={itemToShow.DoctorNotes}
        />
        <AddComment
          onSubmit={phrStore.postNewComment.bind(phrStore)}
          commentType={CommentType.Immunisation}
          itemId={id}
          itemToShow={itemToShow}
          label="+ Add comment for yourself"
        />
      </div>
    </UIContainer>
  );
});

export default ImmunisationPage;
