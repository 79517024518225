import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import DateInput from '../../../../components/DateInput/index';
import styles from './dobInput.module.css';
import { errorPropType } from '../../../propTypes/index';

const DobInput = ({
  onClick,
  value,
  onChange,
  setError,
  containerClassName,
  guardianOpen,
  isUnderAge,
}) => (
  <div id="nw_dateInput" className={classnames(containerClassName)}>
    <DateInput
      dob={value}
      onChange={onChange}
      onError={setError}
      allowUnderage
    />
    {isUnderAge && !guardianOpen && (
      <div className={styles.info}>
        <div className={styles.text}>
          <span>You must be 16+ to proceed.</span> If you&#39;re a guardian
          booking an appointment for a minor, please add your own details.
        </div>
        <div className={styles.center}>
          <button className={styles.button} type="button" onClick={onClick}>
            Add guardian details
          </button>
        </div>
      </div>
    )}
  </div>
);

DobInput.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: errorPropType,
  containerClassName: PropTypes.string,
  guardianOpen: PropTypes.bool,
};

export default DobInput;
