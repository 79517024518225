import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Arrow } from '../../svgs/rightarrow2.svg';
import styles from './popUpHeader.module.css';

const PopUpHeader = ({ heading, subheading, backFn }) => (
  <div className={styles.headerContainer}>
    <Arrow className={styles.arrow} onClick={backFn} />
    <div className={styles.textContainer}>
      <div className={styles.heading}>{heading}</div>
      <div className={styles.subheading}>{subheading}</div>
    </div>
  </div>
);

PopUpHeader.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  backFn: PropTypes.func.isRequired,
};

export default PopUpHeader;
export { styles };
