import { call, takeEvery, put } from 'redux-saga/effects';

import { NoErrorParserHttp } from '../../../libs/Http';
import { actions, actionTypes } from '../actions/paymentActions';
import { isDesktop } from '../../../config';
import { go } from '../../../routes';

const api = (data, token, appointment, saveCard) =>
  NoErrorParserHttp.post(appointment ? 'consumer/appointment/session/card' : 'consumer/payment/card', {
    data: appointment ? 
      {  
        PaymentCardToken: data,
        SessionId: appointment,
        doNotSaveCard: !saveCard
      } : { Token: data, doNotSaveCard: !saveCard },
    token,
  });

function* sendPaymentInfo({ payload: { data, token, appointment, saveCard } }) {
  try {
    yield call(api, data, token, appointment, saveCard);
    yield put(actions.sendPaymentInfoSuccess());
  } catch (err) {
    yield put(actions.sendPaymentInfoFailure(err));
    if (!isDesktop() && !appointment) go.cardDeclined();
  }
}

function* sendPaymentInfoSaga() {
  yield takeEvery(actionTypes.sendPaymentInfo, sendPaymentInfo);
}

export { sendPaymentInfoSaga };
