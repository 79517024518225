import React, { useEffect } from 'react';
import styles from './index.module.scss';
import { ReactComponent as Logo } from 'svgs/logo.svg';
import UserProfile from 'pages/desktop/components/UserProfile';
import BarItem from './BarItem';
import { useBarData } from './services/data';
import ColoredLine from 'components/ColoredLine';
import { useAccountStore } from 'store/mobx/mobx';
import { verfied4Status } from './services/status';
import { DEFAULT_GUID } from 'common/constants';
import { withStyles } from 'hocs/withStyles';
import { formatPhone } from 'services/Format';
import { observer } from 'mobx-react-lite';

type Props = {
    value: string;
}

const SideBar = ({value}: Props) => {
  const store = useAccountStore();
  useEffect(() => {
    if(store.profile.Id === DEFAULT_GUID) {
      if (localStorage.getItem("token")) {
        store.getProfile();
      }
    }
  },[]);
  const {profile} = store;

  const status = verfied4Status({
    MobileVerified: profile.MobileVerified,
    EmailVerified: profile.EmailVerified,
    IdentityVerified: profile.IdentityVerified,
    PrivacyPolicySetup: profile.PrivacyPolicySetup,
    PendingRequest: profile.PendingRequest
  });
  const maskItems = [] as string[];
  profile.CareGiverId !== DEFAULT_GUID && maskItems.push('Care Circle');
  !profile.hasVerifiedDependantLinked && maskItems.push('Change User');
  const data = useBarData(...maskItems);
  
  const Profile = withStyles({marginBottom: '1.2rem'})(UserProfile);

  return (
      <div className={styles.container}>
          <div className={styles.logoContainer}>
            <Logo className={styles.logo} />
          </div>
          <Profile 
            name={`${profile.FirstName} ${profile.LastName}`} 
            imgUrl={profile.AvatarUrl}
            status={status}
            nhi={profile.NHI}
            phone={formatPhone(profile.Mobile)} />
          {
            data.map((item, index) => item.name === 'splitter' ? 
            <ColoredLine key={index} color='rgba(255, 255, 255, 0.2)' width='80%'/> : 
            <BarItem key={index} {...item} highlight={value === item.name} />)
          }
      </div>
  )
};

export default observer(SideBar);