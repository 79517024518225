import { call, takeEvery, put } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';
import { GenericParserHttp, NoErrorParserHttp } from '../../../libs/Http';

const api = () =>
  NoErrorParserHttp.get('consumer/practice/all').then(({ body }) => body);

function* getPractices() {
  try {
    const practices = yield call(api);
    yield put(actions.getAllPracticesSuccess(practices));
  } catch (err) {
    yield put(actions.getAllPracticesError(err));
  }
}

function* getAllPracticesSaga() {
  yield takeEvery(actionTypes.getAllPractices, getPractices);
}

export { getAllPracticesSaga };
