import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmail from 'validator/lib/isEmail';
import moment from 'moment';

import styles from './registerForm.module.css';
import CtaButton from '../../../../components/CtaButton/index';
import Alert from '../../../../libs/Alert';
import { isValidPassword, isValidPasswordText } from '../../../../libs/ValidBasicInfo';
import DesktopForm from '../DesktopForm/index';
import { DoctorProfilePropType } from '../../../../PracticeProfilePage/BookingPageMobile/propTypes/index';
import { isDesktop } from '../../../../config';

class RegisterForm extends Component {
  static propTypes = {
    registerUser: PropTypes.func.isRequired,
    checkEmail: PropTypes.func.isRequired,
    goToLogin: PropTypes.func.isRequired,
    isRegistering: PropTypes.bool.isRequired,
    isEmailError: PropTypes.bool.isRequired,
    appointment: PropTypes.instanceOf(moment).isRequired,
    doctor: DoctorProfilePropType.isRequired,
  };
  constructor(props) {
    super(props);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onEmailBlur = this.onEmailBlur.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  state = {
    email: '',
    emailError: null,
    password: '',
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isEmailError) {
      this.setState({
        emailError: 'This email is already registered with Vensa.',
      });
    }
  }
  onSubmit(e) {
    e.preventDefault();
    if (this.state.emailError) Alert.error(this.state.emailError);
    else if (!isValidPassword(this.state.password)) {
      // Alert.error("Your password needs to be at least 8 characters long, include both uppercase and lowercase characters, at least one special character and at least one digit.")
      Alert.error(isValidPasswordText(this.state.password));
    } else if (!this.props.isChecking) {
      this.props.registerUser(this.state.email, this.state.password);
    }
  }
  onEmailChange(e) {
    this.setState({ email: e.target.value, emailError: null });
  }
  onEmailBlur(e) {
    if (!isEmail(e.target.value)) {
      this.setState({ emailError: 'Please enter a valid email address.' });
    } else {
      this.props.checkEmail(e.target.value);
    }
  }
  onPasswordChange(e) {
    this.setState({ password: e.target.value });
  }
  render() {
    const { emailError, email, password } = this.state;
    const isValid =
      !emailError &&
      isValidPassword(password) &&
      !this.props.isChecking &&
      !this.props.isEmailError;
    return (
      <form className={styles.container} onSubmit={this.onSubmit}>
        {isDesktop() ? (
          <DesktopForm
            blurEmail={this.onEmailBlur}
            changeEmail={this.onEmailChange}
            changePassword={this.onPasswordChange}
            password={password}
            email={email}
            emailError={emailError}
            goToLogin={this.props.goToLogin}
            doctor={this.props.doctor}
            appointment={this.props.appointment}
            orgId={this.props.orgId}
          />
        ) : (
          <div>
            <div className={styles.heading}>Create your log in...</div>
            <div className={styles.secondaryHeading}>
              You&#39;re just one step away from your patient profile...
            </div>
            <div className={styles.inputContainer}>
              <input
                type="email"
                placeholder="Email"
                value={email}
                className={styles.input}
                onChange={this.onEmailChange}
                onBlur={this.onEmailBlur}
              />
            </div>
            <div className={styles.inputContainer}>
              <input
                type="password"
                placeholder="Password"
                className={styles.input}
                value={password}
                onChange={this.onPasswordChange}
              />
            </div>
          </div>
        )}
        <div className={styles.footer}>
          <div className={styles.secondaryCta}>
            <div>Already have one?</div>
            <button
              className={styles.loginButton}
              onClick={this.props.goToLogin}
            >
              Log in
            </button>
          </div>
          <CtaButton
            type="submit"
            active={isValid}
            isProcessing={this.props.isRegistering}
            white={!isDesktop()}
            className={styles.button}
          />
        </div>
      </form>
    );
  }
}

export default RegisterForm;
