import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import UIContainer from 'components/UIContainer';
import { useAccountStore } from 'store/mobx/mobx';
import { useComponentDidMount } from 'libs/newHttp';
import styles from './recoverPin.module.css';
import lock from 'images/lockred.png'
import { isValidPassword, replaceMiddle } from 'libs/misc';
import alert from 'libs/Alert';
import { go } from 'routes';
import NDigitInput from 'components/nDigitInput';
import ResendButton from 'HealthFeed/AccountVerificationPage/ResendButton';
import moment from 'moment';
import CtaButton from 'components/CtaButton';
import PinCodeFlow from './PinCodeFlow';
import { isDesktop } from 'config';
import LearnMorePopUp from 'components/LearnMorePopUp/LearnMorePopUp';

const Container = styled.div`
    height: 100%;
    margin-bottom: 1rem;
`
const Card = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #00000029;
    padding-top: 1rem;
    background-color: white;
    overflow-y: scroll;
    position: relative;
    transition: all 0.3s linear;
`
const Lock = styled.img`
    display: block;
    margin: auto;
    height: 132px;
    width: 140px;
`
const Text = styled.div`
    font-size: 17px;
    text-align: center;
    color: #6C6C6C;
    margin: 2rem 0rem;
    padding: 0rem 1.5rem;
`
const BlueButton = styled.div`
    width: 60%;
    font-size: 17px;
    color: white;
    background-color: #07A3C8;
    padding: 1.5rem 0rem;
    text-align: center;
    display: block;
    margin: 2rem auto;
    border-radius: 50px;
    transition: background-color 0.3s linear;
`
const MethodsContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const Label = styled.span`
    margin: 0rem 1rem;
    font-size: 17px;
    font-style: italic;
    color: #07A3C8;
`
const Container2 = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #00000029;
    padding-bottom: 3rem;
`
const Title2 = styled.div`
    text-align: center;
    font-size: 21px;
    font-weight: 300;
    padding-top: 1rem;
`
const UserEmail = styled.div`
    font-weight: 500;
`
const PasswordTitle = styled.div`
    color: #696969;
    text-align: left;
    width: 100%;
    font-size: 21px;
`
const PasswordInput = styled.input`
    font-size: 40px;
    border: none;
    border-bottom: 1px solid #07A3C8;
    color: #6C6C6C;
    width: 100%;
    outline: none;
    margin-top: 1rem;
    caret-color: #07A3C8;
`
const PasswordText = styled.div`
    font-size: 15px;
    color: #6C6C6C;
    text-align: left;
    opacity: 0.5;
    margin: 0.5rem 0rem;
`

const RecoverAccountFlow: React.FC = observer(() => {
    const [state, setState] = useState(0);
    const [method, setMethod] = useState("");
    const [isMobile, setIsMobile] = useState(false);
    const [isUsingDesktop] = useState(isDesktop());
    const [newPassword, setNewPassword] = useState("");

    const store = useAccountStore();

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let t = params.get('token');

    useComponentDidMount(() => {
        if (t === null) { 
            alert.error("Invalid link. Redirecting to login page...");
            go.login();
            return; 
        }
        store.getRecoveryMethodsWithToken(t);
    })

    useEffect(() => {
        if (store.successChecker) { 
            if (t === null) { return; }
            store.postRecoverAccount(t, () => go.login()); 
        }
    })

    const resendCode = (method: string) => {
        if (t === null) { return; }
        store.getRecoverSuspendedAccountVerificationCode(t, isMobile, method, () => setState(1))
    }
    
    const onChangeDigit = (checkCode: (value: string) => void) => (digits: string) =>
        digits.length === 4 && checkCode(digits);


    return (
        <UIContainer heading={"Recover Account"} isFetching={store.loading} goBackFn={state === 0 ? () => go.login() : () => setState(state - 1)}>
            {isUsingDesktop &&
                <LearnMorePopUp hide={() => window.location.href = "https://login.vensa.com"} type={"UNAVAILABLE_DESKTOP"} />
            }
            <Container>
                {state === 0 &&
                    <Card>
                        <Lock src={lock} />
                        <Text>We can send you a activation code to re-activate your account. Please select the recovery method:</Text>
                        <MethodsContainer>
                            <div className={styles.container}>
                                {store.recoveryMethods.MobileNumber !== "" &&
                                    <div className={styles.round} onClick={() => {
                                        setMethod(store.recoveryMethods.MobileNumber)
                                        setIsMobile(true);
                                    }}>
                                        <input type="checkbox" id="checkbox0" checked={method === store.recoveryMethods.MobileNumber}/> 
                                        <label htmlFor="checkbox0"></label>
                                        <Label>{replaceMiddle(store.recoveryMethods.MobileNumber, store.recoveryMethods.MobileNumber.length - store.recoveryMethods.MobileNumber.length/2 )}</Label>
                                    </div>
                                }
                                {store.recoveryMethods.Email !== "" &&
                                    <div className={styles.round} onClick={() => {
                                        setMethod(store.recoveryMethods.Email)
                                        setIsMobile(false);
                                    }}> 
                                        <input type="checkbox" id="checkbox1" checked={method === store.recoveryMethods.Email}/>
                                        <label htmlFor="checkbox1"></label>
                                        <Label>{replaceMiddle(store.recoveryMethods.Email, store.recoveryMethods.Email.length - store.recoveryMethods.Email.length/2)}</Label>
                                    </div>
                                }
                                {store.recoveryMethods.RecoveryEmail !== "" && store.recoveryMethods.RecoveryEmail !== null &&
                                    <div className={styles.round} onClick={() => {
                                        setMethod(store.recoveryMethods.RecoveryEmail)
                                        setIsMobile(false);
                                    }}> 
                                        <input type="checkbox" id="checkbox2" checked={method === store.recoveryMethods.RecoveryEmail}/>
                                        <label htmlFor="checkbox2"></label>
                                        <Label>{replaceMiddle(store.recoveryMethods.RecoveryEmail, store.recoveryMethods.RecoveryEmail.length - store.recoveryMethods.RecoveryEmail.length/2)}</Label>
                                    </div>
                                }
                            </div>
                        </MethodsContainer>
                        <BlueButton style={{backgroundColor: method === "" ? "#AFAFAF" : "#FC7B7B"}} onClick={() => {
                            if (method === "") { return; }
                            if (t === null) { return }
                            store.getRecoverSuspendedAccountVerificationCode(t, isMobile, method, () => setState(1))
                        }}>Send activation code</BlueButton>
                    </Card>
                } 
                {state === 1 &&
                    <Container2>
                        <Title2>
                            Enter the 4-digit code sent to you at 
                            <UserEmail>{method}</UserEmail>
                        </Title2>
                        <NDigitInput 
                            digits={4}
                            isError={null}
                            clearError={() => console.log("cleared")}
                            onChange={onChangeDigit((value: string) => {
                                if (t === null) { return; }
                                store.postRecoverAccountCheckActivationCode(t, value, isMobile, method, () => setState(2));
                            })}
                        />
            
                        <ResendButton
                            onClick={() => resendCode(method)}
                            countdownStartTime={moment()}
                            milliSecondsToWait={61000}
                            isFetching={store.loading}
                        />
                    </Container2>
                }
                {state === 2 &&
                    <Card style={{padding: "1rem"}}>
                        <PasswordTitle>Create your new password</PasswordTitle>
                        <PasswordInput 
                            type={"password"}
                            value={newPassword}
                            onChange={(e: any) => setNewPassword(e.target.value)} 
                        />
                        <PasswordText>
                            The password needs to be a minimum of 8 characters long, and include at least one number, and one capital letter.
                        </PasswordText>
                        <CtaButton 
                            className={styles.ctaButton}
                            onClick={() => {
                                if (t === null) { return; }
                                store.postRecoverAccountChangePassword(t, newPassword, () => setState(3));
                            }}
                            active={isValidPassword(newPassword)}
                        />
                    </Card>
                }
                {state === 3 &&
                    <PinCodeFlow useRecoverAccountAPI={true} />
                }
            </Container>
        </UIContainer>
    )
});

export default RecoverAccountFlow;

