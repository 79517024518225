import isMobilePhone from 'validator/lib/isMobilePhone';
import isEmail from 'validator/lib/isEmail';
import { isValidDate } from './Dates';

const isValidPassword = (password) => ( password.length >= 8 && //is long enough
  /[a-z]/.test(password) && // has lowercase letters
  /[A-Z]/.test(password) && // has uppercase letters
  /\d/.test(password) // has digits
)

const isValidPasswordText = (password) => {
  const text = [
    "Your password must be 8 characters long",
    "Your password must contain at least 1 lower case character",
    "Your password must contain at least 1 upper case character",
    "Your password must contain 1 numeric character"
  ]
  if (password.length < 8) return text[0];
  if (!/[a-z]/.test(password)) return text[1];
  if (!/[A-Z]/.test(password)) return text[2];
  if (!/\d/.test(password)) return text[3];

  return ""
}
//const isValidPassword = password => password.length > 7;

const isValidMobile = (mobile, required) =>
  isMobilePhone(mobile.replace(/\s/g, ''), 'en-NZ') || (!required && !mobile);

const isValidEmail = (email, required) =>
  isEmail(email) || (!required && !email);

const isValidName = (name, required = true) => name.length > 0 || !required;

const isValidBasicInfo = (
  firstName,
  surname,
  dob,
  mobile,
  email,
  allowUnderage,
  onlyUnderAge,
  firstNameRequired = true,
  surnameRequired = true,
  dobRequired = true,
  mobileRequired = false,
  emailRequired = false,
) =>
  isValidName(firstName, firstNameRequired) &&
  isValidName(surname, surnameRequired) &&
  isValidMobile(mobile, mobileRequired) &&
  isValidDate(dob, allowUnderage, onlyUnderAge, dobRequired) &&
  isValidEmail(email, emailRequired);

export {
  isValidMobile,
  isValidName,
  isValidBasicInfo,
  isValidEmail,
  isValidPassword,
  isValidPasswordText
};
