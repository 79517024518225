import React from 'react';
import styles from './index.module.scss';
import { ReactComponent as Arrow } from 'svgs/rightarrow2.svg';
import cn from 'classnames';

interface TitleStyle {
  center: string;
  right: string;
}

const TitlePosition: TitleStyle = {
  center: styles.center,
  right: styles.right
}

export interface HeaderProps {
  handleClick?: () => void;
  text: string;
  align?: 'center' | 'right';
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const Header: React.FC<HeaderProps> = ({
  handleClick, 
  text, 
  align = 'right',
  icon: Icon = Arrow
}) => {
  return (
    <div className={cn(styles.container, TitlePosition[align])}>
      {handleClick && <Icon onClick={handleClick} className={styles.arrow} />}
      {text}
    </div>
  );
};

export default Header;
