import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect, } from 'react-redux';
import { select } from 'redux-saga/effects';

import { bindActionCreators } from 'redux';

import moment from 'moment';
import classnames from 'classnames';

import { actions as registerActions } from './actions/actions';
import RegisterForm from './components/RegisterForm/index';
import AppointmentInfo from './components/AppointmentInfo/index';
import Heading from './components/Heading/index';
import MobileHeader from '../../components/UIContainer/MobileUI/header/index';
import autoRegisterImg from '../../images/autoRegisterImg.png';
import styles from './guestRegisterPage.module.css';
// eslint-disable-next-line max-len
import { actions as checkActions } from './actions/checkUsernameActions';
import { findDoctor } from '../../selectors/appointmentSelectors';
import { DoctorProfilePropType } from '../../PracticeProfilePage/BookingPageMobile/propTypes/index';
import { go } from '../../routes/index';
import { isDesktop } from '../../config';
import { getLoginPageURL } from '../../libs/utils';
import { getLoginObject, getLoginSuccess } from '../../selectors/loginSelectors';

const setLogin = () => {
  window.location = getLoginPageURL();
};

export class GuestRegisterPage extends Component {
  static propTypes = {
    autoRegisterUser: PropTypes.func.isRequired,
    home: PropTypes.func.isRequired,
    goToLogin: PropTypes.func.isRequired,
    sessionId: PropTypes.string.isRequired,
    registerSuccess: PropTypes.bool.isRequired,
    isRegistering: PropTypes.bool.isRequired,
    confirmingAppointment: PropTypes.bool.isRequired,
    checkEmail: PropTypes.func.isRequired,
    clearCheckUserState: PropTypes.func.isRequired,
    appointment: PropTypes.instanceOf(moment).isRequired,
    doctor: DoctorProfilePropType.isRequired,
    isEmailError: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.register = this.register.bind(this);
  }

  state = {
    height: document.body.clientHeight,
  };

  componentDidUpdate() {
    if (this.props.registerSuccess) {
      if (this.props.loginSuccess) {
          if (this.props.consumerUrl !== undefined) { 
            window.open(
              this.props.consumerUrl + "login?token=" +
              this.props.refreshToken+ "&expires=" + 
              this.props.expiryTime + "&email=" + 
              this.props.email + "&firstTime=false",
              "_self"
            );
          } else go.login();
        } else { go.home(); }
      }
    }
  componentWillUnmount() {
    this.props.clearCheckUserState();
  }
  register(email, password) {
    this.props.autoRegisterUser(email, this.props.sessionId, password);
  }
  render() {
    const style = !isDesktop()
      ? {
          backgroundImage: `url(${autoRegisterImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: this.state.height - 120,
        }
      : {};
    return (
      <div className={styles.background}>
        {!isDesktop() && (
          <MobileHeader
            headerClassNames={styles.heading}
            firstLine={
              <Heading confirming={this.props.confirmingAppointment} />
            }
            secondLine={
              <AppointmentInfo
                doctor={this.props.doctor}
                appointment={this.props.appointment}
                confirming={this.props.confirmingAppointment}
              />
            }
            hideArrow
          />
        )}
        <div
          id="nw_guestReg"
          className={classnames(styles.container, {
            [styles.flexContainer]: this.props.isFetching,
          })}
          style={style}
        >
          <RegisterForm
            registerUser={this.register}
            checkEmail={this.props.checkEmail}
            isEmailError={this.props.isEmailError}
            goToLogin={this.props.goToLogin}
            doctor={this.props.doctor}
            isRegistering={this.props.isRegistering}
            appointment={this.props.appointment}
            isChecking={this.props.isChecking}
            orgId={this.props.match.params.id}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  confirmingAppointment: state.appointmentConfirmationReducer.checking,
  sessionId: state.sessionReducer.sessionId,
  isRegistering: state.autoRegisterReducer.isFetching,
  loginSuccess: state.loginReducer.success,
  consumerUrl: state.loginReducer.consumerUrl,
  refreshToken: state.loginReducer.refreshToken,
  expiryTime: state.loginReducer.expiryTime,
  email: state.loginReducer.email,
  registerSuccess: state.autoRegisterReducer.success,
  isEmailError: state.checkUsernameReducer.error,
  isChecking: state.checkUsernameReducer.checking,
  doctor: findDoctor(
    state.practiceAppointmentReducer.selectedTime.doctorId,
    isDesktop()
      ? state.practiceProfileReducer.profile.Doctors
      : state.practiceAppointmentReducer.doctorInfo.doctors,
  ),
  appointment: moment(state.practiceAppointmentReducer.selectedTime.time),
});

const mapDispathToProps = dispatch => ({
  autoRegisterUser: bindActionCreators(
    registerActions.autoRegisterUser,
    dispatch,
  ),
  home: () => go.home(),
  checkEmail: bindActionCreators(checkActions.checkUsername, dispatch),
  clearCheckUserState: bindActionCreators(
    checkActions.clearCheckUserState,
    dispatch,
  ),
  goToLogin: isDesktop() ? setLogin : () => go.login(),
});

export default connect(
  mapStateToProps,
  mapDispathToProps,
)(GuestRegisterPage);
