import React, { PropsWithChildren } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Image, Paragraph, FlatButton } from 'components/vensaComponents';
import privacyPopUpLogoSVG from 'svgs/privacyPopUpLogo.svg';
import { WhiteNotificationWrapper } from './WhiteNotificationWrapper';
import { actions } from '../MainShell/actions/actions';
import { useDispatch } from 'react-redux';

type Props = {
  svg?: string;
  paragraph: string;
  height?: string;
  snooze?: boolean;
}

export const BaseWhiteNotification = observer(({ 
  svg = privacyPopUpLogoSVG, 
  paragraph, children, 
  height = "70px", 
  snooze = false 
}: PropsWithChildren<Props>) => {
  const dispatch = useDispatch();

  return (
    <WhiteNotificationWrapper>
      <Box direction="column" fontSize="15px">
        <Image src={svg} height={height} />
        <Paragraph color="#07A3C8" textAlign="center">
          {paragraph}
        </Paragraph>
        {children}
        <Box justifyContent="end">
        {snooze &&
          <FlatButton
            fontWeight={700}
            css={{ textDecoration: 'underline' }}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              dispatch(actions.setWhiteBoxNotification({ isOpen: false }));
            }}
          >
            Snooze
          </FlatButton>
        }
        </Box>
      </Box>
    </WhiteNotificationWrapper>
  );
});
