import React from 'react';
import styles from './index.module.scss';
import {ReactComponent as Picture} from 'svgs/picture.svg';
import {ReactComponent as Tick} from 'svgs/greenTickThin.svg';
import { Touchable } from 'types';

type Props = {
    title: string;
    hasFile?: boolean;
    handleAction?: () => void;
    styleClass?: {[key:string]: string};
} & Touchable

const FileIndicator = (
    {
        touch = false,
        title, 
        hasFile = false,
        handleAction,
        styleClass
    }: Props) => {
  return (
    <div className={styles.container} onClick={handleAction}>
        <p className={styleClass && styleClass.tip}>{hasFile ? `${title} Uploaded!` : touch ? '' : '\u00A0'}</p>
        {
            <div className={styles.iconContainer}>{hasFile ? <Tick /> : <Picture />}</div>
        }
        
        <span className={styleClass && styleClass.action}>{hasFile ? 'remove' : title}</span>
    </div>
  )
};

export default FileIndicator;