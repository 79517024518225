import React from 'react';
import PropTypes from 'prop-types';

import styles from './scriptdisabled.module.css';
import { ReactComponent as Arrow } from '../../../svgs/close.svg';
import icon from '../../../images/notEnrolled.png';
import CallPractice from '../../../components/CallPractice/index';
import { practicePropType } from '../../../PracticesPage/propTypes/index';

const ScriptDisabled = ({ practice, arrowClick }) => (
  <div className={styles.container}>
    <Arrow className={styles.arrow} onClick={arrowClick} />
    <div className={styles.whoops}>Whoops!</div>
    <div className={styles.popUpContainer}>
      <div className={styles.popUp}>
        <img src={icon} alt="hospital" className={styles.icon} />
        <div className={styles.message}>
          {practice.Name} hasn&#39;t enabled this service for you at this
          moment. <br />
          <br />
          If you wish to request your prescriptions online, you can help us to
          enable it for you by calling your practice and asking them to activate
          their Vensa Online Script Request.
          <br />
        </div>
        <CallPractice practice={practice} />
      </div>
    </div>
  </div>
);

ScriptDisabled.propTypes = {
  practice: practicePropType,
  arrowClick: PropTypes.func.isRequired,
};

export default ScriptDisabled;
