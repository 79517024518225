import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import MobileInput from './MobileInput';
import { isDesktop } from '../../../config';
import {
  isBookingForSomeoneElse,
  getAppointmentMobile,
} from '../../../selectors/sessionSelectors';
import { go } from '../../../routes';
import { actions } from '../actions/codeConfirmationActions';
import smartPhoneIcon from '../../../images/smartPhone.png';
import styles from './enterMobile.module.css';
import UIContainer from '../../../components/UIContainer';

class EnterMobile extends Component {
  static propTypes = {
    mobile: PropTypes.string,
    sessionId: PropTypes.string,
    changePhone: PropTypes.func.isRequired,
    isBookingForSomeoneElse: PropTypes.bool.isRequired,
  };
  sendText = value => {
    this.props.changePhone(
      this.props.sessionId,
      value,
      this.props.isBookingForSomeoneElse,
    );
    go.verification(this.props.match.params.id);
  };
  // toggleErrorPopUp() {
  //   this.setState({ showErrorPopUp: !this.state.showErrorPopUp });
  // }
  render() {
    if (!isDesktop())
      return (
        <UIContainer heading=" ">
          <div>
            <img
              src={smartPhoneIcon}
              className={styles.icon}
              key="icon"
              alt="SMS code"
            />
            <MobileInput mobile={this.props.mobile} sendText={this.sendText} />
          </div>

        </UIContainer>
      );
    else
      return (
        <MobileInput mobile={this.props.mobile} sendText={this.sendText} />
      );
  }
}

const mapStateToProps = state => ({
  mobile: getAppointmentMobile(state),
  sessionId: state.sessionReducer.sessionId,
  isBookingForSomeoneElse: isBookingForSomeoneElse(state),
});

const mapDispatchToProps = dispatch => ({
  changePhone: bindActionCreators(actions.changeVerificationMobile, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnterMobile);
