import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { bindActionCreators } from "redux";

/* eslint-disable max-len */
import PatientSelection from "./components/PatientSelection/index";
import EnrolledErrorPopUp from "../ErrorPages/EnrolledErrorPopUp/index";
import Header from "../../components/UIContainer/MobileUI/header/index";
import { actions } from "./actions/actions";
import { actions as feelingActions } from "../Reason/actions/actions";
import { actions as profileActions } from 'components/UIContainer/MobileUI/MainShell/actions/profileActions';
import { actions as loginActions } from "../../EntryPage/actions/actions";
import { actions as patientDetailsActions } from "../PatientDetails/actions/actions";
import { actions as guardianDetailsActions } from "../PatientDetails/actions/guardianActions";
import { actions as fetchDependantsActions } from "../../DependantsPage/actions/actions";
import { profilePropType } from "../../components/UIContainer/MobileUI/MainShell/propTypes/index";
import { practiceProfilePropType } from "../../PracticeProfilePage/propTypes/index";
import { errorPropType } from "../propTypes/index";
import { go } from "../../routes/index";
import { isDesktop } from "../../config";
import styles from "./patientSelectionPage.module.css";
import { getToken } from 'libs/newHttp';
import { capitalise } from 'libs/misc';
/* eslint-enable max-len */

class PatientSelectionPage extends Component {
  static propTypes = {
    clearGuardianDetails: PropTypes.func.isRequired,
    clearPatientDetails: PropTypes.func.isRequired,
    clearState: PropTypes.func.isRequired,
    addPatientDetails: PropTypes.func.isRequired,
    checkEnrolledPatient: PropTypes.func.isRequired,
    fetchDependants: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    dependants: PropTypes.arrayOf(PropTypes.object),
    token: PropTypes.string,
    time: PropTypes.string.isRequired,
    doctorId: PropTypes.string.isRequired,
    practice: practiceProfilePropType.isRequired,
    profile: profilePropType,
    isEnrolled: PropTypes.bool.isRequired,
    isChecking: PropTypes.bool.isRequired,
    urlName: PropTypes.string.isRequired,
    enrolledError: errorPropType,
  };
  state = {
    showErrorPopUp: false,
  };

  componentDidMount() {
    const token = getToken();
    if (token) {
      this.props.getProfile(token);
      this.props.clearGuardianDetails();
      this.props.clearPatientDetails();
      this.props.clearState();
      this.props.fetchDependants(token);
    } else go.loginTriage(this.props.match.params.id);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isEnrolled && !this.props.isEnrolled) {

      if (this.props.practice.ModuleVisibility !== null) {

        let canExpressInterest = (nextProps.patientId !== null && nextProps.patientId !== '');
        let reasonForVisit = this.props.practice.ModuleVisibility.includes("Reason For Visit");
        let consultationMethods = this.props.practice.ModuleVisibility.includes("Consultation Method");

        reasonForVisit ? go.reason(this.props.match.params.id, consultationMethods)
          : go.reasonOld(this.props.match.params.id);
      }
    }

    if (isDesktop() && nextProps.enrolledError && !this.props.enrolledError) {
      this.setState({ showErrorPopUp: true });
    }
  }
  patientOnClick = patient => {
    const info = patient.FirstName ? patient : this.props.profile;
    this.props.addPatientDetails({
      firstName: info.FirstName,
      lastName: info.LastName,
      dateOfBirth: info.DateOfBirth,
      mobile: info.Mobile,
      dependantId: patient.Id || null,
    });

    if (!patient.FirstName) {
      this.props.checkEnrolledPatient(
        this.props.practice.UrlName,
        info.FirstName,
        info.LastName,
        info.Mobile,
        this.props.profile.DateOfBirth,
        this.props.practice.Id,
        this.props.doctorId,
        this.props.appointmentId,
        this.props.time,
      )
    } else {
      this.props.checkEnrolledDependant(
        this.props.practice.UrlName,
        this.props.profile.FirstName,
        this.props.profile.LastName,
        this.props.profile.Mobile,
        this.props.profile.DateOfBirth,
        this.props.practice.Id,
        this.props.doctorId,
        this.props.appointmentId,
        this.props.time,
        patient.Id
      )
    }
  };
  hideErrorPopUp = () => {
    this.setState({ showErrorPopUp: false }, () => {
      this.props.clearState();
      this.props.clearGuardianDetails();
      this.props.clearPatientDetails();
    });
  };
  handleLogOut = () => {
    this.props.logout(this.props.token);
    go.loginTriage(this.props.match.params.id);
  };
  goSomeoneElse = () => {
    go.patientDetails(this.props.match.params.id);
  };
  goToBooking = () => {
    go.practiceProfile(this.props.match.params.id);
  };
  render() {
    const firstName = this.props.profile.FirstName;
    return (
      <div className={styles.page}>
        {!isDesktop() && <Header goBackFn={() => go.practiceBooking(this.props.practice.UrlName)} headerClassNames={styles.header} showHelp />}
        <div className={styles.innerContainer}>
          <div className={styles.title}>
            <div className={styles.titleMain}>{`Hey ${capitalise(firstName)}!`}</div>
            <div className={styles.titleSecondary}>{"Who are you booking an appointment for?"}</div>
          </div>
          <PatientSelection
            patientOnClick={this.patientOnClick}
            someoneElseOnClick={this.goSomeoneElse}
            userProfile={this.props.profile}
            dependantProfiles={this.props.dependants}
            isFetching={this.props.isChecking}
          />
          <div className={styles.logoutText} onClick={this.handleLogOut}>
            <div className={styles.notUserText}>{`Not ${capitalise(firstName)}?`}</div>
            <span>Log out</span>
          </div>
        </div>
        {this.state.showErrorPopUp &&
          isDesktop() && (
            <EnrolledErrorPopUp
              closePopUp={this.hideErrorPopUp}
              practice={this.props.practice}
              error={this.props.enrolledError}
              goToBooking={this.goToBooking}
            />
          )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.loginReducer.token,
  dependants: state.fetchDependantsReducer.dependants,
  profile: state.profileReducer.profile,
  practice: state.practiceProfileReducer.profile,
  doctorId: state.practiceAppointmentReducer.selectedTime.doctorId,
  time: state.practiceAppointmentReducer.selectedTime.time,
  isChecking: state.checkEnrollmentReducer.isChecking || state.fetchDependantsReducer.isFetching,
  isEnrolled: state.checkEnrollmentReducer.isEnrolled,
  patientId: state.checkEnrollmentReducer.patientId,
  urlName: state.practiceProfileReducer.profile.UrlName,
  enrolledError: state.checkEnrollmentReducer.error,
  appointmentId: state.practiceAppointmentReducer.selectedTime.appointmentId
});
const mapDispatchToProps = dispatch => ({
  clearGuardianDetails: bindActionCreators(guardianDetailsActions.clearState, dispatch),
  clearPatientDetails: bindActionCreators(patientDetailsActions.clearDetails, dispatch),
  addPatientDetails: bindActionCreators(patientDetailsActions.addDetails, dispatch),
  clearState: bindActionCreators(actions.clearState, dispatch),
  checkEnrolledPatient: bindActionCreators(actions.checkEnrolledPatient, dispatch),
  checkEnrolledDependant: bindActionCreators(actions.checkEnrolledDependant, dispatch),
  checkEnrolledSomeoneElse: bindActionCreators(actions.checkEnrolledSomeoneElse, dispatch),
  fetchDependants: bindActionCreators(fetchDependantsActions.fetchDependants, dispatch),
  logout: bindActionCreators(loginActions.logout, dispatch),
  getProfile: bindActionCreators(profileActions.getProfile, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PatientSelectionPage);
