import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { actions } from '../../actions/paymentActions';
import { actions as popUpActions } from '../../../../components/DesktopPopUp/actions/actions';
import { ReactComponent as MCIcon } from '../../../../svgs/mastercard.svg';
import { ReactComponent as VisaIcon } from '../../../../svgs/visa.svg';
import { ReactComponent as CreditCard } from '../../../../svgs/creditcard.svg';
import { ReactComponent as Arrow } from '../../../../svgs/rightarrow2.svg';
import { ReactComponent as Spinner } from '../../../../svgs/spinner.svg';
import { isDesktop } from '../../../../config';
import styles from './form.module.css';

class Form extends Component {
  static propTypes = {
    sendDataToServer: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    error: PropTypes.string,
    success: PropTypes.bool.isRequired,
    stripe: PropTypes.objectOf(PropTypes.func),
    handleNext: PropTypes.func,
    isProcessing: PropTypes.bool,
    onError: PropTypes.func,
    hideSaveCard: PropTypes.bool
  };
  state = {
    submitDisabled: false,
    paymentProcessing: false,
    paymentComplete: false,
    cardNumber: null,
    cardExpiry: null,
    cardCvc: null,
    numberComplete: false,
    expiryComplete: false,
    cvcComplete: false,
    brand: 'unknown',
    name: '',
    focusInput: '',
    saveCard: false,
  };
  componentDidMount() {
    if (this.form && this.props.stripe) {
      this.createElements(this.props.stripe);
    }
  }
  UNSAFE_componentWillReceiveProps({ stripe, error, success }) {
    if (stripe && !this.props.stripe) {
      this.createElements(stripe);
    }
    if (error !== this.props.error) {
      this.props.setError(
        'Whoops, we were unable to process this card. Please confirm that your details are correct, or try using a different payment method.',
      );
    }
    if (success && !this.props.success && this.props.handleNext) {
      this.props.handleNext();
    }
  }
  toggleInputState = (input, isComplete) => {
    this.setState({ [input]: isComplete });
  };
  toggleSaveCard = () => {
    this.setState({ saveCard: !this.state.saveCard });
  };
  createElements = stripe => {
    const style = {
      base: {
        color: '#585858',
        fontSize: isDesktop() ? '15px' : '16px',
        lineHeight: '38px',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#DADADA',
        },
      },
    };
    const elements = stripe.elements();
    const cardNumber = elements.create('cardNumber', {
      hidePostalCode: true,
      placeholder: '0000 0000 0000 0000',
      style,
    });
    const cardExpiry = elements.create('cardExpiry', {
      hidePostalCode: true,
      style,
    });
    const cardCvc = elements.create('cardCvc', {
      hidePostalCode: true,
      placeholder: '000',
      style,
    });
    this.setState({ cardNumber, cardExpiry, cardCvc });
    cardNumber.on('change', e => {
      if (e.brand) this.setState({ brand: e.brand });
      if (e.error && this.props.onError) this.props.onError(e.error.message);
      this.toggleInputState('numberComplete', e.complete);
    });
    cardExpiry.on('change', e => {
      if (e.error && this.props.onError) this.props.onError(e.error.message);
      this.toggleInputState('expiryComplete', e.complete);
    });
    cardCvc.on('change', e => {
      if (e.error && this.props.onError) this.props.onError(e.error.message);
      this.toggleInputState('cvcComplete', e.complete);
    });
    cardNumber.on('focus', this.handleFocus('number'));
    cardExpiry.on('focus', this.handleFocus('expiry'));
    cardCvc.on('focus', this.handleFocus('cvc'));
    cardNumber.mount('#card-number');
    cardExpiry.mount('#card-expiry');
    cardCvc.mount('#card-cvc');
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.props.hideSaveCard) this.setState({ saveCard: true });
    this.setState({ submitDisabled: true, paymentProcessing: true });
    this.props.stripe
      .createToken(this.state.cardNumber, { name: this.state.name })
      .then(res => {
        if (res.error) {
          this.props.onError(res.error.message);
        } else {
          this.setState(
            {
              paymentComplete: true,
              submitDisabled: false,
              paymentProcessing: false,
              // token: res.token,
            },
            this.handleToken(res.token),
          );
        }
      });
  };
  handleToken = token => () => {
    this.props.sendDataToServer(token.id, this.props.token, this.props.appointment, this.state.saveCard);
  };
  handleFocus = input => () => {
    this.setState({ focusInput: input });
  };
  handleNameChange = e => {
    this.setState({ name: e.target.value.toUpperCase() });
  };
  render() {
    const { isProcessing } = this.props;
    const { submitDisabled, paymentProcessing, brand, name } = this.state;
    const getCardIcon = () => {
      if (brand === 'mastercard') return <MCIcon className={styles.cardIcon} />;
      if (brand === 'visa') return <VisaIcon className={styles.cardIcon} />;
      return <CreditCard className={styles.cardIcon} />;
    };
    const {
      numberComplete,
      expiryComplete,
      cvcComplete,
      focusInput,
      saveCard,
    } = this.state;
    const valid = numberComplete && expiryComplete && cvcComplete;
    return (
      <form
        className={styles.container}
        onSubmit={this.handleSubmit}
        id="payment-form"
        ref={c => {
          this.form = c;
        }}
      >
        <div className="form-row">
          <div className={styles.inputContainer}>
            <label
              className={classnames(styles.label, {
                [styles.focused]: focusInput === 'number',
              })}
              htmlFor="card-number"
            >
              Card number
            </label>
            <div id="card-number" className={styles.numberInput} />
            {getCardIcon()}
          </div>
          <div className={styles.inputContainer}>
            <label
              className={classnames(styles.label, {
                [styles.focused]: focusInput === 'name',
              })}
              htmlFor="card-name"
            >
              Name on the card
            </label>
            <input
              id="card-name"
              className={styles.input}
              value={name}
              onChange={this.handleNameChange}
              onFocus={this.handleFocus('name')}
            />
          </div>
          <div className={styles.doubleContainer}>
            <div className={styles.expContainer}>
              <label
                className={classnames(styles.label, {
                  [styles.focused]: focusInput === 'expiry',
                })}
                htmlFor="card-expiry"
              >
                Exp Date
              </label>
              <div id="card-expiry" className={styles.input} />
            </div>
            <div className={styles.cvcContainer}>
              <label
                className={classnames(styles.label, {
                  [styles.focused]: focusInput === 'cvc',
                })}
                htmlFor="card-cvc"
              >
                CVC
              </label>
              <div id="card-cvc" className={styles.input} />
            </div>
          </div>
          {!this.props.hideSaveCard &&
          <div className={styles.inputContainer}>
            <input id="save-card"
                type="checkbox"
                checked={saveCard}
                onChange={this.toggleSaveCard} 
                />
            <label
              className={classnames(styles.cardLabel)}
              htmlFor="save-card">
              Save my card details to make
              <br/>
              future payments faster
            </label>
          </div>}
          <br />
        </div>
        <button
          disabled={submitDisabled || !valid}
          type="submit"
          className={classnames(styles.submit, {
            [styles.activeButton]: valid,
          })}
        >
          {paymentProcessing || isProcessing ? (
            <Spinner className={styles.spinner} />
          ) : (
            <Arrow className={styles.arrow} />
          )}
        </button>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  token: state.loginReducer.token,
  error: state.paymentFormReducer.error,
  success: state.paymentFormReducer.success,
  isProcessing: state.paymentFormReducer.isProcessing,
});
const mapDispatchToProps = dispatch => ({
  sendDataToServer: bindActionCreators(actions.sendPaymentInfo, dispatch),
  setError: bindActionCreators(popUpActions.setError, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Form);
