import React from 'react';
import { observer } from 'mobx-react-lite';
import { SummarySection } from './SummarySection';
import { useMyHealthStore } from 'store/mobx/mobx';
import { BaseSectionItem } from './BaseSectionItem';

export const AllergiesReactionsSection: React.FC = observer(() => {
  const { Allergies } = useMyHealthStore().summaryTab;

  return (
    <SummarySection title="Allergies & Reactions" observableData={Allergies}>
      {Allergies.map(item => (
        <BaseSectionItem
          key={item.Id}
          label={item.NameOfAllergy}
          description={item.OrganisationName}
        />
      ))}
    </SummarySection>
  );
});
