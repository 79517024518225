import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Tick } from 'svgs/smallWhiteTick.svg';
import { ReactComponent as Cross } from 'svgs/smallWhiteCross.svg';
import { useAccountStore, useIdentityStore } from 'store/mobx/mobx';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #3CB9C0;
  border: 1px solid #F5F5F5; 
  position: relative;
  box-shadow: 0px 3px 6px #00000029;
`
const CardText = styled.div`
    color: white;
    text-align: center;
    font-size: 17px;
    margin: auto 1rem;
`
const Button = styled.div`
    border-radius: 29px;
    background-color: #F5F5F5;
    color: #3CB9C0;
    font-size: 13px;
    text-align: center;
    padding: 1rem 3rem;
    margin: auto;
    font-weight: 600;
`
const Text = styled.div`
    font-size: 15px;
`
const List = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 4.5rem;
`
const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    margin: 1rem;
`
const Title = styled.div`
    font-size: 21px;
    margin-left: 3.5rem;
`
const AckButton = styled.div`
    border-radius: 5px;
    background-color: #F5F5F5;
    color: #3CB9C0;
    font-size: 13px;
    text-align: center;
    padding: 0.5rem 1rem;
    margin: auto;
    font-weight: 600;
    margin-bottom: 1rem;
    box-shadow: 0px 1px 3px lightgray;
`


const CardMobile = (props) => {
    const store = useAccountStore();
    return(
        <Card>
            {props.mobile && props.email && props.identityVerified && 
                <Cross style={{position: "absolute", top: "10px", right: "10px"}} onClick={() => store.sendAcknowledgement(1, () => props.onClick())} />
            }
            <CardText style={{margin: "3rem 1rem"}}>
                Verifying your identity puts you in control of your health information. 
            </CardText>
            <Button onClick={() => props.learnMoreClick()}>Learn more</Button>
            <TextContainer>
                <Title>Additional Setup</Title>
                <List style={{marginTop: "1rem"}}>
                    { props.mobile ? <Tick data-test={"email-mobile-tick"}/> : <Cross /> }
                    <Text style={{margin: "0px 5px"}}>Mobile verification</Text>
                </List>
                <List>
                    { props.email ? <Tick data-test={"security-setup-tick"} /> : <Cross /> }
                    <Text style={{margin: "0px 5px"}}>Email verification</Text>
                </List>
                <List>
                    { props.identityVerified ? <Tick data-test={"security-setup-tick"} /> : <Cross/> }
                    <Text style={{margin: "0px 5px"}}>Identity verification</Text>
                </List>
            </TextContainer>
        </Card>
    )}

export default CardMobile;