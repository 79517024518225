import React, { ChangeEvent, MouseEvent, useState } from 'react';
import styled from 'styled-components';
import Timer from 'react-compound-timer';

import { ReactComponent as EmailSymbol } from 'svgs/email.svg';
import { ReactComponent as Close } from 'svgs/close.svg';
import { ReactComponent as Arrow } from 'svgs/blueRightCircleArrow.svg';
import { useAccountStore } from 'store/mobx/mobx';
import Loading from 'components/loadingSpinner';
import { isValidEmail } from 'libs/ValidBasicInfo';
import { observer } from 'mobx-react-lite';

const Container = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    width: 400px;
    height: 425px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 0; 
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
    z-index: 2;
    transition: "all 0.3s linear";
`
const Title = styled.div`
    width: 100%;
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    margin: 1rem 0rem;
    color: #585858;
`
const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 1rem 2rem;
    margin-top: 0px;
`
const Text = styled.div`
    font-size: 13px;
    color: #585858;
    text-align: left;
    margin: 0.5rem 0rem;
`
const Input = styled.input`
    width: 100%;
    font-size: 20px;
    padding: 0.5rem 0.7rem;
    border: none;
    border-radius: 4px;
    border: 2px solid #DADADA;
`
const Inner = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2rem 0rem;
`
const Footer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 2rem;
    width: 100%;
`
const Link = styled.div`
    color: #07A3C8;
    font-size: 13px;
    margin-right: 14rem;
    text-align: left;
    cursor: pointer;
`
const Num = styled.input`
    border: 1.5px solid #DADADA;
    padding: 10px;
    font-size: 18px; 
    width: 40px;
    margin: 0rem 0.5rem;
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    text-align: center;
    border-radius: 4px;
    transition: all 0.3s linear;
`
const NumContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem auto;
`
const TimerContainer = styled.div`
    width: 100%;
    margin: auto;
    font-size: 13px;
    margin: 2rem auto;
    text-align: center;
`

interface Props {
    close: () => void;
    isPrimary: boolean;
}

const PopUpVerifyEmail: React.FC<Props> = observer(({close, isPrimary}) => {
    const store = useAccountStore();
    const [state, setState] = useState(0);
    const [email, setEmail] = useState(isPrimary ? store.profile.Email : store.profile.RecoveryEmail ? store.profile.RecoveryEmail : "")
    const [resent, setResent] = useState(false);
    const [update, setUpdate] = useState(false);
    const [err, setErr] = useState("");
    let ref1: React.RefObject<HTMLInputElement> = React.createRef();
    let ref2: React.RefObject<HTMLInputElement> = React.createRef();
    let ref3: React.RefObject<HTMLInputElement> = React.createRef();
    let ref4: React.RefObject<HTMLInputElement> = React.createRef();
    let refArr: React.RefObject<HTMLInputElement>[] = [ref1, ref2, ref3, ref4];
    const [incorrect, setIncorrect] = useState(false);

    const onChange = (e: React.FormEvent<HTMLInputElement>, id: number) => {
        const val: string = e.currentTarget.value;

        let isNum = /^\d+$/.test(val)
        if (!isNum) { return; }

        if (refArr === null) { return; } else {
            refArr[id]!.current!.value = val;
        }

        if (id < 3) { refArr[id + 1].current!.focus(); }

        if (id === 3) {
            let code: string = "";
            
            code += refArr.map((item) => {
                return item!.current!.value;
            })

            // eslint-disable-next-line
            store.setVerifyCode(code.replace(/\,/g, ''), 1);
            store.checkVerifyEmail(email, isPrimary, () => close(), (val) => setIncorrect(val), update);
        }
    }

    const onKeyDown = (e: any, id: number) => {
        if (e.key === "Backspace") {
            if (refArr[id].current!.value !== "") {
                refArr[id].current!.value = "";
                return
            }
    
            if (id !== 0) {
                refArr[id - 1].current!.value = ""; 
                refArr[id - 1].current!.focus(); 
            }
        }
    }

    return (
        <Container>
            {state === 0 && <Close onClick={() => close()} style={{position: "absolute", top: "30px", right: "30px", width: "11px", cursor: "pointer"}} />}
            {state === 0 &&
             <Loading isFetching={store.patientVerification.loading}>
                    <Inner>
                        <EmailSymbol style={{margin: "0rem auto", width: "70px"}} />
                        {isPrimary ?
                            <Title>Enter your email address</Title>
                            : 
                            <Title>Enter your recovery email address</Title>
                        }
                        <ContentContainer>
                            {isPrimary ?
                                <Text>Email Address</Text>
                                : 
                                <Text>Recovery Email Address</Text>
                            }
                            <Input value={email} onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setEmail(e.target.value) 
                                if (e.target.value !== store.profile.Email) setUpdate(true); else setUpdate(false);
                            }}
                            />
                            <Text>A verification code will be sent to this email address.</Text>
                        </ContentContainer>
                        <div style={{padding: "0 2rem", color: "#FC7B7B", textAlign: "center", width: "100%", fontSize: "13px", opacity: err !== "" ? "1" : "0", visibility: err !== "" ? "visible" : "hidden", transition: "all 0.3s linear"}}>
                            {err}
                        </div>
                        <Footer>
                            <Link onClick={close}>Cancel</Link>
                            <Arrow 
                                onClick={() => {
                                    if (isValidEmail(email, true)) {
                                        store.sendVerifyEmail(email, isPrimary, () => setState(1), (val) => setErr(val), update);
                                    } else {
                                        setErr("Please enter a valid email address")
                                        setTimeout(() => setErr(""), 4000)
                                    }
                                }} 
                                style={{
                                    width: "50px", 
                                    height: "50px", 
                                    cursor: "pointer"
                                }} />
                        </Footer>
                    </Inner>
                </Loading>
            }
            {state === 1 &&
                <Loading isFetching={store.patientVerification.loading}>
                    <Inner>
                        <Arrow onClick={() => setState(0)} style={{position: "absolute", transform: "rotate(180deg)", height: "50px", width: "50px", cursor: "pointer", top: "30px", left: "30px"}} />
                        <EmailSymbol style={{margin: "0rem auto", width: "70px"}} />
                        <Title>Enter the 4-digit code sent to you at {email}</Title>
                        <NumContainer>
                            <Num autoFocus={true} style={{borderColor: incorrect ? "#FC7B7B" : "#DADADA"}} ref={ref1} onKeyDown={(e) => onKeyDown(e, 0)} onChange={(e) => onChange(e, 0)} type={"number"} maxLength={1} />
                            <Num style={{borderColor: incorrect ? "#FC7B7B" : "#DADADA"}} ref={ref2} onKeyDown={(e) => onKeyDown(e, 1)} onChange={(e) => onChange(e, 1)} type={"number"} maxLength={1} />
                            <Num style={{borderColor: incorrect ? "#FC7B7B" : "#DADADA"}} ref={ref3} onKeyDown={(e) => onKeyDown(e, 2)} onChange={(e) => onChange(e, 2)} type={"number"} maxLength={1} />
                            <Num style={{borderColor: incorrect ? "#FC7B7B" : "#DADADA"}} ref={ref4} onKeyDown={(e) => onKeyDown(e, 3)} onChange={(e) => onChange(e, 3)} type={"number"} maxLength={1} />
                        </NumContainer>
                        
                        <div style={{color: "#FC7B7B", textAlign: "center", width: "100%", fontSize: "13px", opacity: incorrect ? "1" : "0", visibility: incorrect ? "visible" : "hidden", transition: "all 0.3s linear"}}>
                            Oops, looks like you've typed the wrong number.
                        </div>
                        
                            {!resent ? 
                                (<Link onClick={() => { 
                                    store.sendVerifyEmail(email, isPrimary, () => {}, (val) => setErr(val), update); 
                                    setResent(true)
                                }} 
                                style={{textAlign: "center", margin: "auto", marginTop: "2rem"}}>Resend Code</Link>) 
                                :
                                (
                                    <TimerContainer>
                                        <Timer 
                                            initialTime={30000}
                                            direction={"backward"}
                                            checkpoints={[
                                                {
                                                    time: 0,
                                                    callback: () => setResent(false),
                                                }
                                            ]}
                                        >
                                            Resend code in <Timer.Minutes />:<Timer.Seconds />
                                        </Timer>
                                    </TimerContainer>
                                )
                            }
                    </Inner>
                </Loading>
            }
        </Container>
    )
})

export default PopUpVerifyEmail;