import React from 'react';
import { isDesktop } from '../../config';
import { ProtectedRoute, push } from '../utils';
import PaymentsPage from 'PaymentsPage/PaymentsPage';
import DesktopPaymentsPage from 'PaymentsPage/DesktopPaymentsPage/DesktopPaymentsPage';
import CardPage from 'PaymentsPage/subRoutes/CardPage/CardPage';
import TransactionHistoryPage from 'PaymentsPage/subRoutes/TransactionHistoryPage/TransactionHistory';
import TransactionItemPage from 'PaymentsPage/subRoutes/TransactionItemPage/TransactionItemPage';
import AddWalletCreditCardPage from 'PaymentsPage/subRoutes/AddWalletCreditCardPage/AddWalletCreditCardPage';
import VouchersPage from 'PaymentsPage/subRoutes/VouchersPage/VouchersPage';

const PATHS = {
  PAYMENTS: '/payments',
  VOUCHERS: '/payments/vouchers',
  ADD_CREDIT_CARD: '/payments/add-credit-card',
  CARD: '/payments/card/:cardToken',
  TRANSACTION_HISTORY: '/payments/transaction-history',
  TRANSACTION_ITEM: '/payments/transaction-item/:transactionId',
};

const go = {
  addCreditCard: () => push(PATHS.ADD_CREDIT_CARD),
  payments: () => push(PATHS.PAYMENTS),
  card: cardId => push(`/payments/card/${cardId}`),
  transactionHistory: () => push(PATHS.TRANSACTION_HISTORY),
  transactionItem: transactionId =>
    push(`/payments/transaction-item/${transactionId}`),
  vouchers: () => push(PATHS.VOUCHERS),
};

// change all to ProtectedRoute once finished
const PaymentsRoutes = [
  <ProtectedRoute
    exact
    key={PATHS.PAYMENTS}
    path={PATHS.PAYMENTS}
    component={isDesktop() ? DesktopPaymentsPage : PaymentsPage}
  />,
  <ProtectedRoute key={PATHS.CARD} path={PATHS.CARD} component={CardPage} />,
  <ProtectedRoute
    key={PATHS.ADD_CREDIT_CARD}
    path={PATHS.ADD_CREDIT_CARD}
    component={AddWalletCreditCardPage}
  />,
  <ProtectedRoute
    key={PATHS.TRANSACTION_HISTORY}
    path={PATHS.TRANSACTION_HISTORY}
    component={TransactionHistoryPage}
  />,
  <ProtectedRoute
    key={PATHS.TRANSACTION_ITEM}
    path={PATHS.TRANSACTION_ITEM}
    component={TransactionItemPage}
  />,
  <ProtectedRoute
    key={PATHS.VOUCHERS}
    path={PATHS.VOUCHERS}
    component={VouchersPage}
  />,
];

export default PaymentsRoutes;
export { go };
