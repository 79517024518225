import React, { PropsWithChildren } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';
import { Touchable } from 'types';

type Props = Touchable;

const ButtonGroup = ({
  children,
  touch,
  ...rest
}: PropsWithChildren<Props>) => {
  return (
    <div className={cn(styles.buttonGroup, {[styles.touch]: touch})} {...rest}>
      {children}
    </div>
  )
}

export default ButtonGroup;