import React from 'react';

import styles from './privacy.module.css'
import UIContainer from '../../components/UIContainer';
import ProgressCard from '../components/ProgressCard';
import privacyIcon from '../../svgs/privacy2.svg';
import progressBar from '../../svgs/progressBarLvl3.svg';
import PrivacyControls from './PrivacyControls/PrivacyControls';
import shield from 'svgs/whiteShield.svg';
import { go } from '../../routes';

class Privacy extends React.Component {
    constructor(props) {
        super(props);

        this.state = { privacyInfo: true, state: this.props.location.state ? this.props.location.state.startAtState : 0 }
        this.myRef = React.createRef();
    }

    goNext = () => {
        this.myRef.current.scrollTop = 0;
        this.setState({ privacyInfo : false })
    }

    render() {
        return (
            <UIContainer heading={"Step 3: Photo & Privacy"} goBackFn={() => {
                this.myRef.current.scrollTop = 0;
                if (this.state.state === 0) go.identityPhoto() 
                else if (this.state.state === 1) this.setState({state: 0})
                else this.setState({state: 1})}
            }> 
                <div ref={this.myRef}>
                    {this.state.state !== 2 ?
                        <ProgressCard 
                            icon={privacyIcon} 
                            progressBar={progressBar} 
                            title={"Privacy"}
                        />
                        : 
                        <ProgressCard
                            icon={shield}
                            progressBar={progressBar}
                            title={"Security"}
                        />
                    }
                    <div className={styles.inner}>
                        <PrivacyControls goNext={() => this.goNext()} setHeaderState={(state) => this.setState({state})} headerState={this.state.state}/>
                    </div>
                </div>
            </UIContainer>
        );
    }
}

export default Privacy;