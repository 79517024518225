import React from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { BaseWhiteNotification } from './BaseWhiteNotification';

export const IdentityPrivacyCard: React.FC = observer(() => {
  const { pathname } = useLocation();

  if (!pathname.startsWith('/identity')) return null;

  return (
    <BaseWhiteNotification
      paragraph={`Vensa will use your first name, last name and date of birth to verify your status with the 
        medical centre and match your identity.\n\n You will see this logo every time your information is requested for use.`}
      snooze={true}
    />
  );
});
