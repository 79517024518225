import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './ctaMenuButton.module.css';

class CtaMenuButton extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    iconStyles: PropTypes.string,
    textIconStyles: PropTypes.string,
    buttonStyles: PropTypes.string,
    text: PropTypes.string,
    isOpen: PropTypes.bool,
    isUnActive: PropTypes.bool,
    hideComingSoon: PropTypes.bool,
    notToggleOnClick: PropTypes.bool
  };
  constructor(props) {
    super(props);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  state = {
    isOpen: this.props.isOpen,
  };
  toggleOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }
  handleClick(e) {
    if (!this.props.notToggleOnClick) {
      this.toggleOpen();
    }
    this.props.onClick(e);
  }
  render() {
    return (
      <div className={classnames(styles.container, this.props.buttonStyles)}>
        <button
          className={classnames(styles.button, {
            [styles.open]: this.state.isOpen,
          })}
          onClick={!this.props.isUnActive ? this.handleClick : () => {}}
          style={{ backgroundColor: !this.props.isUnActive ? "#07a3c8" : "#DADADA", flexDirection: this.props.isUnActive ? "column" : "row"}}
        >
          {this.props.icon && <this.props.icon
            className={classnames(
              this.props.iconStyles,
              { [styles.openIcon]: this.state.isOpen },
              {
                [this.props.textIconStyles]:
                  this.props.text && !this.state.isOpen,
              },
            )}
          />}
          {!this.state.isOpen && this.props.text ? (
            <div className={styles.text}>{this.props.text}</div>
          ) : null}
          {this.props.isUnActive && !this.props.hideComingSoon && <div style={{width: "100%", textAlign: "center", color: "#6C6C6C"}}>Coming Soon</div>}
          {this.props.iconRight && <this.props.iconRight style={{margin: "0px 10px"}}
            className={classnames(
              this.props.iconStyles,
              { [styles.openIcon]: this.state.isOpen },
              {
                [this.props.textIconStyles]:
                  this.props.text && !this.state.isOpen,
              },
            )}
          />}
        </button>
      </div>
    );
  }
}

export default CtaMenuButton;
