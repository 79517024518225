import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from '../../../components/vensaComponents';
import { TabBox } from '../TabBox';
import { ImmunisationItem } from './components/ImmunisationItem';
import { useAccountStore, useMyHealthStore } from '../../../store/mobx/mobx';
import { BaseList } from '../../../MyHealthPage/components/BaseList';
import { useComponentDidMount } from '../../../libs/newHttp';
import BannerEmptyFeedCard from 'HealthFeed/HealthFeedMobile/BannerEmptyFeedCard/BannerEmptyFeedCard';
import { go } from 'routes';

export const ImmunisationTab: React.FC = observer(() => {
  const store = useAccountStore();
  const [show, setShow] = useState(true);
  const phrStore = useMyHealthStore();

  useComponentDidMount(phrStore.getImmunisations.bind(phrStore));

  return (
    <TabBox p={1}>
      {phrStore.immunisationsErrorText !== null && show &&
        <BannerEmptyFeedCard 
          bannerText={"Access Restricted"}
          title={""}
          text={phrStore.immunisationsErrorText}
          button={true}
          buttonText={"Ok got it"}
          link={true}
          linkText={"Check my privacy"}
          restricted={true}
          linkOnClick={() => go.privacy()}
          buttonOnClick={() => {
            setShow(false);
            store.sendAcknowledgement(0, phrStore.getImmunisations.bind(phrStore));
          }} // ACK 0}
        />
      }
      <Box direction="column" gap="8px">
        {phrStore.immunisationsTab.givenWithLast3Months.map(item => (
          <ImmunisationItem key={item.Id} {...item} />
        ))}
      </Box>

      <BaseList
        title={phrStore.immunisationsTab.past.length !== 0 ? "PREVIOUS VACCINATIONS" : "NO PREVIOUS VACCINATIONS FOUND"}
        space={phrStore.immunisationsTab.givenWithLast3Months.length === 0}
        render={() =>
          phrStore.immunisationsTab.past.map(item => <ImmunisationItem key={item.Id} {...item} />)
        }
      />
    </TabBox>
  );
});
