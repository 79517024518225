import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Right } from 'svgs/rightarrow3.svg';
import { ReactComponent as Left } from 'svgs/leftarrow2.svg';
import placeholder from 'images/placeholder.png'

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    transform: translateY(-30px);
`
const Placeholder = styled.img`
    width: 70px;
    height: 70px;
    margin: 0px 5px;
    border-radius: 50%;
    opacity: 0;
`
const ContentContainer = styled.div`
    margin: 0px 10px;
    transform: translateY(25px);
`
const Image = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0px 5px;
`

interface Item {
    PmsUserId: string;
    Type: string;
    Picture: string;
    UrlName: string;
}
interface Props {
    list: Item[];
}

const PicScroller: React.FC<Props> = ({list}) => {
    const [index, setIndex] = useState(1);
    let arr: any[] = [];
    if (list.length > 3) {
        arr = [
            index !== 0 ? list[index - 1] : null,
            list[index],
            index !== list.length - 1 ? list[index + 1] : null,
        ]
    }

    return (
        <Container>
            <Left style={{width: "100%", cursor: "pointer"}} onClick={() => setIndex(index === 0 ? 0 : index - 1)} />
            {arr.map((item, index) => {
                if (item === null) { return <Placeholder src={placeholder} key={index}/> }
                return (
                    <ContentContainer>
                        <Image key={item.PmsUserId} src={item.Picture} alt="img" />
                        <div style={{height: "28px", margin: "6px", fontSize: "11px", textAlign: "center"}}>{item.UrlName}</div>
                    </ContentContainer>
                )
            })}
            <Right style={{width: "100%", cursor: "pointer"}} onClick={() => setIndex(index === list.length - 1 ? index : index + 1)} />
        </Container>
    )
}

export default PicScroller;