import React from 'react';
import { ConfirmProps, Touchable } from 'types';
import styles from './index.module.scss';
import cn from 'classnames';

export type ButtonFlavour = 'hollow' | 'solid' | 'plain';

type Props = {
  flavour: ButtonFlavour;
} & ConfirmProps & Touchable;

const MultiFlavourButton = ({
  text,
  touch,
  flavour,
  clickable = true,
  handleClick,
  ...rest
}: Props) => {
  return (
    <span
      className={cn(styles.button, styles[flavour], {[styles.touch]: touch, [styles.enable]: clickable})}
      onClick={handleClick}
      {...rest}>
      {text}
    </span>
  )
}

export default MultiFlavourButton;