import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import styles from './header.module.css';
import { ReactComponent as Arrow } from '../../../../svgs/rightarrow2.svg';
import { ReactComponent as Cross } from '../../../../svgs/crossGrey.svg';
import Messages from './components/Messages/index';
import Logo from './components/Logo/index';
import MenuButton from '../MenuButton/index';
import Menu from '../Menu/index';
import { go } from '../../../../routes';
// eslint-disable-next-line
import { goBack } from '../../../../routes/utils';
import { isDesktop } from '../../../../config';
import { isWhiteBoxNotificationOpen } from '../MainShell/selectors';

const getStyleForShowingWhiteBox = isWhiteBoxNotificationOpen => {

  if (window.location.pathname.startsWith('/home')) {
    if (isWhiteBoxNotificationOpen) return { position: 'static' };
  } else {
    if (isWhiteBoxNotificationOpen) return {  top: "initial" } ;
  }
  
  return { }
}

const Header = ({
  isWhiteBoxNotificationOpen,
  firstLine,
  isWhite,
  headerClassNames,
  secondLine,
  showMenu,
  showHelp,
  showCancel,
  hideArrow,
  goBackFn,
  backArrowFn,
  saveFn,
  customIcon,
  pic,
  token,
  subheading,
  showCross,
  phrText,
  noAfter,
  showCrossRight
}) => {
  return (
    <div
      className={classnames(
        isWhite ? styles.headerContainerWhite : noAfter ? styles.headerContainerNoAfter : styles.headerContainer,
        headerClassNames,
      )}
      style={getStyleForShowingWhiteBox(isWhiteBoxNotificationOpen)}
    >
      {isDesktop() && token && <Menu />}
      <div
        className={styles.header}
        style={getStyleForShowingWhiteBox(isWhiteBoxNotificationOpen)}
      >
        <div className={styles.titleContainer}>
          {!showMenu && !hideArrow &&(
            <Arrow
              className={styles.backArrow}
              onClick={goBackFn || backArrowFn || goBack}
              style={isWhite ? { fill: '#6C6C6C' } : {}}
            />
          )}
          {showCross && (
            <Cross
              className={styles.backArrow}
              onClick={goBackFn || backArrowFn || goBack}
              style={isWhite ? { fill: '#6C6C6C' } : {}}
            />
          )}
          {showMenu && <MenuButton />}
          <div className={styles.container} style={{flexDirection: subheading !== undefined && subheading !== "" ? "column" : "row"}}>
            {pic && (
              <div style={{width: "40px", margin: "0rem 0.6rem", backgroundImage: `url(${pic})`}} className={styles.pic} alt={"pic"} />
            )}
            {firstLine ? 
             	firstLine : 
              	<Logo 
					onClick={() => {
						if (window.location.pathname.endsWith("home")) { window.location.reload() }
						else { go.home(); }
					}} 
					style={{
						width: "80px",
						margin: "auto"
					}} 
				/>
			}
            {subheading !== undefined && subheading !== "" && <p className={styles.subheading}>{subheading}</p>}
          </div>
        </div>
        {showMenu && <Messages onClick={go.messages} />}
        {customIcon && customIcon}
        {phrText &&
          <div style={{color: "white", textAlign: "left", position: "absolute", right: "1rem"}}>
            {phrText}
          </div>
        }
        {showHelp && (
          <button className={styles.button} onClick={go.support}>
            HELP
          </button>
        )}
        {showCancel && (
          <button className={styles.button} onClick={() => go.home(0)}>
            Cancel
          </button>
        )}
        {showCrossRight && (
          <Cross
            className={styles.button}
            onClick={() => go.home(2)}
            style={isWhite ? { fill: '#6C6C6C', zIndex: "1" } : { zIndex: "1" }}
          />
        )}
        {saveFn && (
          <button className={styles.button} onClick={saveFn}>
            SAVE
          </button>
        )}
      </div>
      {secondLine && <div className={styles.secondLine} style={{overflow: "visible"}}>{secondLine}</div>}
    </div>
  );
};
Header.propTypes = {
  backArrowFn: PropTypes.func, // mapped from state
  goBackFn: PropTypes.func, // passed through component
  saveFn: PropTypes.func,
  customIcon: PropTypes.node,
  firstLine: PropTypes.node,
  secondLine: PropTypes.node,
  hideArrow: PropTypes.bool,
  showMenu: PropTypes.bool,
  showHelp: PropTypes.bool,
  showCancel: PropTypes.bool,
  isWhite: PropTypes.bool,
  token: PropTypes.string,
  headerClassNames: PropTypes.string,
  isWhiteBoxNotificationOpen: PropTypes.bool,
};

const mapStateToProps = state => ({
  token: state.loginReducer.token,
  backArrowFn: state.headerReducer.backArrowFn,
  customIcon: state.headerReducer.customIcon,
  isWhiteBoxNotificationOpen: isWhiteBoxNotificationOpen(state),
});

export default connect(mapStateToProps)(Header);
export { styles, Header };
