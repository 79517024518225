import { actionTypes } from '../actions/actions';

const INITIAL_STATE = {
  feelings: "",
  reasons: { },
  methods: [],
  skip: false,
  error: null,
  received: false,
  showWarning: false,
  warningMessage: [],
  allowContinueWithCode2: false,
  code2WarningMessage: [],
  Symptoms: [],
  noSymptomDefaultByPassCode: 0,
};

const feelingPageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.updateSession: {
      return {
        ...state,
      }
    }
    case actionTypes.postService: {
      return {
        ...state,
      }
    }
    case actionTypes.addFeelings: {
      return {
        ...state,
        skip: false,
        feelings: action.payload.feelings,
      };
    }
    case actionTypes.getReasons: {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    }
    case actionTypes.skipFeelings: {
      return {
        ...state,
        skip: true,
        feelings: "",
      };
    }
    case actionTypes.getReasonsSuccess: {
      return {
        ...state,
        isFetching: false,
        Symptoms: action.payload.reasons.Symptoms,
        reasons: action.payload.reasons,
        showWarning: action.payload.reasons.ShowingWarning,
        warningMessage: action.payload.reasons.WarningMessage,
        allowContinueWithCode2: action.payload.reasons.AllowContinueWithCode2,
        code2WarningMessage: action.payload.reasons.Code2WarningMessage,
        noSymptomDefaultByPassCode: action.payload.reasons.NoSymptomDefaultByPassCode,
        received: true,
        error: null,
      }
    }
    case actionTypes.getReasonsFailure: {
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      }
    }
    case actionTypes.getConsultMethods: {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    }
    case actionTypes.getConsultMethodsSuccess: {
      return {
        ...state,
        isFetching: false,
        methods: action.payload.methods,
        error: null,
      }
    }
    case actionTypes.getConsultMethodsFailure: {
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      }
    }
    default: {
      return state;
    }
    case actionTypes.clearFeelings: {
      return INITIAL_STATE;
    }    
  }
};

export default feelingPageReducer;
