import React, { useState } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';
import { ConfirmProps } from 'types';

type Props = {
  additionalText?: string;
  additionalHandleClick?: () => void;
  additionalClickable?: boolean;
} & ConfirmProps

const ClickableButton = ({
    handleClick,
    clickable = false,
    text,
    additionalHandleClick,
    additionalClickable,
    additionalText,
    ...rest
}: Props) => {
  return (
    <div className={styles.buttonContainer} {...rest}>
        <span className={cn({[styles.additionalDisable]: !additionalClickable})} onClick={additionalHandleClick}>{additionalText}</span>
        <span className={cn(styles.normal, {[styles.enable]: clickable})} onClick={handleClick}>{text}</span>
    </div>
  )
}

export default ClickableButton;