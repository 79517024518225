const actionTypes = {
  acceptTnC: 'ACCEPT_TNC',
  acceptTnCSuccess: 'ACCEPT_TNC_SUCCESS',
  acceptTnCFailure: 'ACCEPT_TNC_FAILURE',
};

const actions = {
  acceptTnC: token => ({
    type: actionTypes.acceptTnC,
    payload: { token },
  }),
  acceptTnCSuccess: () => ({
    type: actionTypes.acceptTnCSuccess,
  }),
  acceptTnCFailure: error => ({
    type: actionTypes.acceptTnCFailure,
    payload: { error },
  }),
};

export { actions, actionTypes };
