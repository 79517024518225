import React, { PropsWithChildren } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Text } from 'components/vensaComponents';
import { Line } from 'PaymentsPage/components/Line';

export const TransactionSection = observer(
  ({ title, children }: PropsWithChildren<{ title: string }>) => (
    <Box direction="column" fontWeight={100} gap="10px">
      <Line />
      <Text fontWeight={700} fontSize="14px">
        {title}
      </Text>
      {children}
    </Box>
  ),
);
