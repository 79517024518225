import React, { createRef, PropsWithChildren, ReactEventHandler } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Text, FlatButton } from '../../../components/vensaComponents';
import { useOnClickOutside } from '../../../libs/hooks';
import { usePaymentsStore } from 'store/mobx/mobx';
import alert from 'libs/Alert';
import { go } from 'routes/sub/payments';

const RemoveCardMenuButton = ({
  children,
  onClick,
}: PropsWithChildren<{ onClick: ReactEventHandler }>) => (
  <FlatButton onClick={onClick} noTextCenter color="black50">
    <Text minHeight="32px" alignItems="center" display="flex">
      {children}
    </Text>
  </FlatButton>
);

const DefaultCardMenuButton = ({
  children,
  onClick,
}: PropsWithChildren<{ onClick: ReactEventHandler }>) => (
  <FlatButton onClick={onClick} noTextCenter color="black50">
    <Text minHeight="32px" alignItems="center" display="flex">
      {children}
    </Text>
  </FlatButton>
);

export const CardMenu: React.FC<{
  Token: string;
  isExpired: boolean;
  setShowModal: (v: boolean) => void;
  setShowMenu: (v: boolean) => void;
}> = observer(({ Token, isExpired, setShowModal, setShowMenu }) => {
  const ref = createRef();
    
  const store = usePaymentsStore();
    
  const setDefaultCard = async () => {
    await store.setDefaultCard(Token).then(async () => await store.getPaymentSummary()).then(() => {alert.success("Default card updated successfully"); go.card()});
  }

  useOnClickOutside(ref, () => {
    setShowMenu(false);
  });

  return (
    <Box
      takeRef={ref}
      direction="column"
      gap="8px"
      borderRadius="4px"
      position="absolute"
      top={0}
      right={0}
      boxShadow={4}
      px="8px"
      py="8px"
      zIndex={2}
      justifyContent="center"
    >
    {!isExpired && 
      <DefaultCardMenuButton
        onClick={() => {
          setDefaultCard();
        }}
      >
        Set as my default card
      </DefaultCardMenuButton>
    }  
      <RemoveCardMenuButton
        onClick={() => {
          setShowMenu(false);
          setShowModal(true);
        }}
      >
        Remove this card
      </RemoveCardMenuButton>
    </Box>
  );
});
