import React from 'react';
import styles from './practiceCardMenu.module.css';

const PracticeCardMenu = (props) => {
  let classnames = styles.fadeIn;
  classnames += props.showOverlay ? " " + styles.overlay : "";
  return(
    <div>
      <div className={classnames} onClick={props.toggle} />
      <div className={styles.text} onClick={props.removePractice}>Remove this practice</div>
    </div>    
  );
}

export default PracticeCardMenu;
