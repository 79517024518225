import AvatarProfile from 'components/shared/common/AvatarProfile';
import React from 'react';
import styles from './index.module.scss';
import { ReactComponent as Icon } from 'svgs/profileIcon.svg';
import {ReactComponent as Go} from 'svgs/blueRightCircleArrow.svg';
import { Touchable } from 'types';
import { Dependant } from 'types/careCircle';
import { format2EnDate } from 'services/Format/formatDate';

const adapter = (touch: boolean) => ({
  cancelButton: {
      text: touch ? "No, don't remove" : "Keep this dependant"
  },
  okButton: {
    text: touch ?  'Yes, remove from my Circle' : <Go style={{width:'48px', height: '48px'}} />
  }
});

type Props = {
  handleCancel: () => void;
  handleOk: () => void;
  dependant?: Dependant;
} & Touchable;

const RemovePopup = ({
  touch = false,
  handleCancel,
  handleOk,
  dependant,
  ...rest}: Props) => {
    const adapt = adapter(touch);
  return (
    <div className={styles.container} {...rest}>
      <div className={styles.title}>Remove this person from your Circle?</div>
      <div className={styles.description}>
          All data about this person will be removed from your account. 
          They will need to be added to your Care Circle and verified again by the practice if 
          you want access to their health data in the future. Are you sure?
      </div>
      <AvatarProfile 
        diameter='50px'
        className={styles.avatarCard}
        placeHolder={Icon}>
        <span>{`${dependant?.FirstName} ${dependant?.LastName}`}</span>
        <span>{format2EnDate(dependant?.DateOfBirth)}</span>
      </AvatarProfile>
      <div className={styles.buttonContainer}>
          <span 
            className={styles.cancelButton} 
            onClick={handleCancel}>
              {adapt.cancelButton.text}
          </span>
          <span 
            onClick={handleOk}>
              {adapt.okButton.text}
          </span>
      </div>
    </div>
  )
}

export default RemovePopup