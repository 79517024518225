import { config, useTransition } from '@react-spring/web';
import LoadingSpinner from 'components/shared/common/LoadingSpinner';
import React, { ComponentType, memo, useLayoutEffect } from 'react';
import useLoading from './useLoading';
import { ReactComponent as Spinner } from 'svgs/spinner.svg';

const useLoadingWithSpinner = <P,>(func: (...args: any) => Promise<any>, Component: ComponentType<P>, ...params: any): ComponentType<P> => {
  const [loadingFunc, loading, error] = useLoading(func, params);
  useLayoutEffect(() => {          // This hook ensures the call occuring after rendering
    loadingFunc(...params);
  }, [...params]);

  const transitions = useTransition(loading, {
    from: { opacity: 0 },
    enter: { opacity: 0.5 },
    leave: { opacity: 0 },
  });
  return (props: P) =>  (
    <>
      {transitions((styles, item) => item && <LoadingSpinner animateStyle={styles}/>)}
      <Component {...props}/>
    </>
  )};

export default useLoadingWithSpinner;