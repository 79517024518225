import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import EmptyFeedCard from '../../HealthFeedMobile/EmptyFeedCard';
import BannerEmptyFeedCard from '../../HealthFeedMobile/BannerEmptyFeedCard/BannerEmptyFeedCard';
import FullBanner from 'components/DesktopComponents/FullBanner';
import { useAccountStore, useIdentityStore, useMyHealthStore } from 'store/mobx/mobx';
import { go } from 'routes';
import { isDesktop } from 'config';
import HalfBanner from 'components/DesktopComponents/HalfBanner';
import AccountVerificationCard from 'HealthFeed/HealthFeedMobile/VerifyAccountCard';
import styles from 'HealthFeed/HealthFeedDesktop/healthFeedDesktop.module.css'
import { ReactComponent as labsIcon } from 'svgs/labs2.svg'
import LearnMorePopUp from 'components/LearnMorePopUp/LearnMorePopUp';
import Loading from 'components/loadingSpinner';
import { useLocation } from 'react-router';

const Container = styled.div`
    width: 100%;
`

const FeedPriorityList = [
    "NeedUserSetUpToCompleteIdentity",
    "MobileNotVerified", 
    "EmailNotVerified", 
    "IdentityNotVerified",
    "IdentityDeclined",
    "IdentityApproved",
    "IdentityInProgress",
    "PhotoIdentityNotVerified",
    "ConsumerNotAcknowledgedOfBlacklisted",
    "InvitedForLabResearch",
    "ConsentedForLabResearch",
    "NeedFeedBackForLabResultDoctorComments",
]

interface IFeed {
    Type: string;
    Item: any ;
}
interface IProps {
    feed: IFeed[]
    getFeed: () => void;
    hide: () => void;
    setShowPopUp?: (value: boolean) => void;
}
const Notifications: React.FC<IProps> = observer(({feed, getFeed, hide, setShowPopUp}) => {
    const notificationOrder: number[] = [];
    const [declinePopUp, setDeclinePopUp] = useState(false);
    const [viewResearch, setViewResearch] = useState(false);
    const [takeATour, setTakeATour] = useState(false);
    const store = useAccountStore();
    const idStore = useIdentityStore();
    const phrStore = useMyHealthStore();
    const { pathname } = useLocation();

    feed.map((item) => {
        if (FeedPriorityList.findIndex(x => x === item.Type) !== -1) {
            notificationOrder.push(FeedPriorityList.findIndex(x => x === item.Type))
        }
    })
    notificationOrder.sort();

    // useComponentDidMount(() => {
    //     store.getProfile();
    // })

    const renderSwitchMobile = () => {
        switch(FeedPriorityList[notificationOrder[0]]) {
            case "NeedUserSetUpToCompleteIdentity":
                let completeItems = feed.find(x => x.Type === "NeedUserSetUpToCompleteIdentity")
                return (
                    <BannerEmptyFeedCard 
                        bannerText={"Identity Verification Pending"}
                        bannerColor={"#f8742c"}
                        title={"Hey " + store.profile.FirstName + " it's really you!"}
                        text={"Congratulations, your practice has confirmed your identity. There are 3 steps to protect your security and privacy. Let's start."}
                        button={true}
                        buttonText={"Start"}
                        link={true}
                        linkText={"Take a Tour"}
                        linkOnClick={() => {
                            setTakeATour(true)
                            if (setShowPopUp) { setShowPopUp(true); }
                        }}
                        buttonOnClick={() => {
                            idStore.setPracticeOrgId(completeItems!.Item);
                            go.identityCompleteFlow();
                        }} //ACK 1
                    />
                );
            case "MobileNotVerified":
                return ( 
                    <AccountVerificationCard
                        onClick={!store.profile.MobileVerified ? () => go.accountVerification("true") : () => go.emailVerification(0, true, false)}
                        isMobileVerified={store.profile.MobileVerified}
                        isEmailVerified={store.profile.EmailVerified}
                    />
                )
            case "EmailNotVerified":
                return ( 
                    <AccountVerificationCard
                        onClick={!store.profile.MobileVerified ? () => go.accountVerification("true") : () => go.emailVerification(0, true, false)}
                        isMobileVerified={store.profile.MobileVerified}
                        isEmailVerified={store.profile.EmailVerified}
                    />
                )
            case "IdentityNotVerified":
                return (
                    <EmptyFeedCard
                        link={false}
                        Icon={false}
                        linkText={""}
                        linkOnClick={() => {}}
                        isFirstTime={true}
                        call={false}
                        callNumber={""}
                        buttonClick={go.identity}
                        title={"Hey " + store.profile.FirstName + ", welcome to Vensa!"}
                        text={"We have now simplified the way you book appointments, access your repeat medications, and enabled access to your personal health record.\n\nVerify your identity and you’re health information can travel with you on Vensa."}
                        buttonText={"Lets verify"}
                        button={true}
                    />
                );
            case "IdentityDeclined": 
                let declinedItems = feed.find(x => x.Type === "IdentityDeclined")
                return (
                    <BannerEmptyFeedCard 
                        bannerText={"Identity Verification Declined"}
                        title={"Hey " + store.profile.FirstName + ","}
                        text={"We're sorry to hear that you're identity verification was declined by " + declinedItems!.Item![0] + ". \n\n You can view your identity verification status online."}
                        button={true}
                        buttonText={"View"}
                        link={false}
                        linkText={""}
                        linkOnClick={() => go.identity()}
                        buttonOnClick={() => {
                            store.sendAcknowledgement(3, () => go.identity())
                        }} // ACK 3
                    />
                );
            case "IdentityApproved": 
                return (
                    <EmptyFeedCard
                        link={true}
                        Icon={false}
                        linkText={"Check my privacy"}
                        call={false}
                        callNumber={""}
                        linkOnClick={() => go.privacy()}
                        isFirstTime={true}
                        buttonClick={() => {
                            store.sendAcknowledgement(4, () => hide())
                        }} // ACK 4
                        title={"Hey " + store.profile.FirstName + ", it's really you!"}
                        text={"Congratulations on completing your identity. You can now do more on the Vensa platform to access your health information."}
                        buttonText={"Ok, got it"}
                        button={true}
                    />
                );
            case "IdentityInProgress":
                let items = feed.find(x => x.Type === "IdentityInProgress")
                return (
                    <EmptyFeedCard
                        link={false}
                        linkText={""}
                        Icon={false}
                        call={true}
                        callNumber={items!.Item![0].m_Item2}
                        linkOnClick={() => {}}
                        isFirstTime={true}
                        buttonClick={go.identity}
                        title={"Hey " + store.profile.FirstName + ", we just need to check..."}
                        text={"Your identity verification is in progress. You can call " + items!.Item![0].m_Item1 + " to check on your identity status"}
                        buttonText={""}
                        button={false}
                    />
                );
            case "ConsumerNotAcknowledgedOfBlacklisted":
                return  (
                    <BannerEmptyFeedCard 
                        bannerText={"Restricted Access Note"}
                        title={"Hey " + store.profile.FirstName + ", welcome to Vensa!"}
                        text={"One or more medical centre(s) has restricted access for your health information. \n\nPlease check the privacy section to explore the reason for this"}
                        button={true}
                        buttonText={"Ok got it"}
                        link={true}
                        linkText={"Check my privacy"}
                        linkOnClick={() => go.privacy()}
                        buttonOnClick={() => {
                            store.sendAcknowledgement(0, () => hide());
                        }} // ACK 0}
                    />
                );
            case "ConsentedForLabResearch":
                return (
                    <EmptyFeedCard
                        link={true}
                        linkText={"View Research"}
                        linkOnClick={() => {
                            if (setShowPopUp) { setShowPopUp(true); }
                            setViewResearch(true);
                        }}
                        isFirstTime={true}
                        call={false}
                        callNumber={""}
                        buttonClick={() => {store.sendAcknowledgement(5, () => hide())}}
                        title={"Hey " + store.profile.FirstName  + ", welcome to Vensa!"}
                        text={"Congratulations! You are now successfully registered, and will take part in our research study. We will let you know as soon as your lab test results are available for you to view in your Vensa Portal."}
                        buttonText={"Ok got it"}
                        button={true}
                        Icon={labsIcon}
                    />
                );
            case "InvitedForLabResearch":
                let inviteItem = feed.find(x => x.Type === "InvitedForLabResearch")
                phrStore.setPatientId(inviteItem!.Item.patientId);
                phrStore.setLabInviteGeneralInfo(inviteItem!.Item.patientId, inviteItem!.Item.orgName, inviteItem!.Item.providerName);
                return (
                    <EmptyFeedCard
                        link={true}
                        linkText={"Decline"}
                        linkOnClick={() => {
                            if (setShowPopUp) { setShowPopUp(true); }
                            setDeclinePopUp(true)}}
                        isFirstTime={true}
                        call={false}
                        callNumber={""}
                        buttonClick={go.labResultsResearch}
                        title={"Hey " + store.profile.FirstName  + ", welcome to Vensa!"}
                        text={"You have been invited by Hauora Medical to join the lab results project. This research will result in you receiving your lab results more promptly and will require you to enter your feedback after you receive your lab results."}
                        buttonText={"I consent"}
                        button={true}
                        Icon={labsIcon}
                    />
                );
            case "NeedFeedBackForLabResultDoctorComments":
                let item = feed.find(x => x.Type === "NeedFeedBackForLabResultDoctorComments");
                return (
                    <EmptyFeedCard
                        link={false}
                        isFirstTime={false}
                        linkText={""}
                        linkOnClick={() => {}}
                        call={false}
                        callNumber={""}
                        buttonClick={() => {
                            phrStore.getLabResult(item!.Item.PatientId, item!.Item.LabWhenReceived, () => {
                                if (phrStore.currentLabPage === undefined) { return; }
                                go.goLabResult(phrStore.currentLabPage.Id, item!.Item.PatientId, item!.Item.LabWhenReceived)
                            })
                        }}
                        title={"Hey " + store.profile.FirstName + " welcome to Vensa!"}
                        text={"You can view your new lab results recently uploaded for your view with doctors comments. You can provide feedback after you review these results."}
                        buttonText={"View Results"}
                        button={true}
                        Icon={labsIcon}
                    />
                );
            default:
                return;
        }
    }
    const renderSwitch = () => {
        switch(FeedPriorityList[notificationOrder[0]]) {
            /*case "MobileNotVerified":
                return <div>Mobile Not Verified</div>;
            case "EmailNotVerified":
                return <div>Email Not Verified</div>;
            case "MobileNotVerified":
                return (
                    <HalfBanner 
                        bannerText={"Mobile verification"}
                        text={"We need to verify your mobile number to give you full access to Vensa services. Verification is simple and only takes a minute to complete. We will send you important notifications relating to your health on your verified accounts."}
                        linkText={""}
                        linkOnClick={() => {}}
                        buttonText={"Verify Mobile"}
                        buttonOnClick={() => {
                            go.accountVerification();
                        }}
                    />
                );*/
            case "IdentityNotVerified":
                return (
                    <FullBanner 
                        title={"Hey " + store.profile.FirstName + ", welcome to Vensa!"}
                        text={"We have simplified how you book appointments, request repeat medications," + 
                            " and you now have access to you personal health record. \n\n Verify your identity and your health information can travel with you on Vensa."}
                        bannerTitle={"Verify your Identity"}
                        bannerText={"Start by Verifying your identity"}
                        buttonText={"Lets verify"}
                        onClick={() => {
                            store.sendAcknowledgement(2, () => getFeed());
                            go.identity();
                        }} 
                  />
                );
            case "IdentityDeclined": 
                return (
                    <HalfBanner 
                        bannerText={"Identity Verification Declined"}
                        text={"We’re sorry to hear that your identity verification was declined by Hauora Medical Centre. You can verify your identity again using self-verification option. Verifying your identity will simplify the way you book appointments, request repeat medications, and access your personal health record."}
                        linkText={""}
                        linkOnClick={() => {}}
                        buttonText={"View"}
                        buttonOnClick={() => {
                            store.sendAcknowledgement(3, () => getFeed());
                            go.identity();
                        }}
                    />
                );
            case "IdentityApproved": 
                return (
                    <FullBanner 
                        title={"Hey " + store.profile.FirstName + ", it's really you!"}
                        text={"Congratulations on confirming your identitiy.\nCheck out the Vensa platform to view your health information and access a wide range of services."}
                        bannerTitle={"Check your verification"}
                        bannerText={" "}
                        buttonText={"Ok got it"}
                        linkText={"Check my privacy"}
                        link={() => go.privacy()}
                        onClick={() => {store.sendAcknowledgement(4, () => getFeed())}}  
                    />
                );
            case "IdentityInProgress":
                let items = feed.find(x => x.Type === "IdentityInProgress")
                return (
                    <HalfBanner 
                        bannerText={""}
                        text={"Your identity verification is in progress."}
                        linkText={""}
                        buttonText={""}
                        buttonOnClick={() => {}} 
                        linkOnClick={() => {}}  
                    />
                );
            case "PhotoIdentityNotVerified":
                return <div>Photo Identity Not Verified</div>;
            case "ConsumerNotAcknowledgedOfBlacklisted":
                return (
                    <HalfBanner 
                        bannerText={"Restricted Access Note"}
                        text={"One or more medical centre(s) has restricted your access to your health information.\nThe reason for this can be found in the Privacy section of your settings."}
                        linkText={"Check my privacy settings"}
                        linkOnClick={() => go.privacy()}
                        buttonText={"Ok got it"}
                        buttonOnClick={() => store.sendAcknowledgement(0, () => getFeed())}
                    />
                );
            case "NeedUserSetUpToCompleteIdentity":
                let completeItems = feed.find(x => x.Type === "NeedUserSetUpToCompleteIdentity")
                if (window.location.pathname.startsWith('/home')) {
                    return (
                        <HalfBanner 
                            bannerText={"Identity verification pending"}
                            text={"Congratulations, your practice has confirmed your identity! There are a few more steps to complete to keep you safe. \n1. Create a passcode PIN\n2. Set your default privacy settings\n3. Verify your mobile number"}
                            linkText={""}
                            bannerColour={"#f8742c"}
                            linkOnClick={() => go.identity()}
                            buttonText={"Let's complete it"}
                            buttonOnClick={() => {
                                idStore.setPracticeOrgId(completeItems!.Item);
                                go.identityCompleteFlow();
                            }}
                            height={"140px"}
                        />
                    );
                } else {
                    return (
                        <HalfBanner 
                            bannerText={"Identity verification pending"}
                            text={"Complete your identity verification to access your Health Data! There are a few more steps to complete to keep you safe. \n1. Create a passcode PIN\n2. Set your default privacy settings\n3. Verify your mobile number"}
                            linkText={""}
                            bannerColour={"#f8742c"}
                            linkOnClick={() => go.identity()}
                            buttonText={"Let's complete it"}
                            buttonOnClick={() => {
                                idStore.setPracticeOrgId(completeItems!.Item);
                                go.identityCompleteFlow();
                            }}
                            height={"140px"}
                        />   
                    )
                }
            default:
                return (
                    <div className={styles.heading}>
                        <div className={styles.kiaora}>
                            {store.profile.FirstName && `Kia ora, ${store.profile.FirstName || ''}!`}
                        </div>
                        <div>
                            {store.profile.FirstName &&
                            "Here's a summary of what's happening on your patient profile"}
                        </div>
                    </div>
                )

        }
    }

    return (
        <Container style={{marginBottom: "1rem"}}>
            {pathname.startsWith('/home') &&
                <Loading isFetching={phrStore.loading} />
            }
            {isDesktop() ? renderSwitch() : renderSwitchMobile()}
            {declinePopUp &&
                <LearnMorePopUp type={"PDH_DECLINE"} 
                    hide={() => {
                        if (setShowPopUp) { setShowPopUp(false); }     
                        setDeclinePopUp(false);
                    }}
                />
            }
            {viewResearch &&
                <LearnMorePopUp type={"PDH_INFORMATION_USAGE"} 
                    hide={() => {
                        if (setShowPopUp) { setShowPopUp(false); }     
                        setViewResearch(false);
                    }}
                />
            }
            {takeATour &&
                <LearnMorePopUp type={"TAKE_A_TOUR"} 
                    hide={() => {
                        if (setShowPopUp) { setShowPopUp(false); }   
                        setTakeATour(false)
                    }} 
                />
            }
        </Container>
    )
})

export default Notifications;