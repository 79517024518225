import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Text, Image } from 'components/vensaComponents';
import styled from 'styled-components';
import Iframe from 'react-iframe';
import { useMyHealthStore } from 'store/mobx/mobx';
import blueCircleButtonSVG from 'svgs/blueCircleButton.svg';
import posed from 'react-pose';

export const DownInChild = styled(
  posed.div({
    hide: {
      y: '+100%',
      transition: { duration: 500 },
      applyAtEnd: { display: 'none' },
    },
    show: {
      y: '0%',
      transition: { duration: 500 },
      applyAtStart: { display: 'flex' },
    },
  }),
)`
  z-index: 9999;
  position: relative;
  top: 0px;
  left: 0;
  right: 0;
  border-radius: 4px;
  background: white;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 105px);
`;

const StyledIFrame = styled(Iframe)`
  width: 100vw;
  height: 100%;
  border-radius: 4px;
`;

const Overlay = styled.div`
  position: absolute;
  z-index: 10;
  padding-top: 105px;
  top: 0px;
  -webkit-transition: background-color 0.3s linear;
  -ms-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
`

export const HealthNavigatorIFrame: React.FC = observer(() => {
  const myHealthStore = useMyHealthStore();
  const { keywords, isOpen, link } = myHealthStore.healthNavigator;

  const escapedKeywords = escape(keywords);
  const url = keywords === '' ? link : `https://healthify.nz/search/results#stq=${escapedKeywords}&stp=1`;

  return (
    <Overlay style={{backgroundColor: isOpen ? "#00000055" : "transparent"}} onClick={() => myHealthStore.setHealthNavigator({ isOpen: false })}>
      <DownInChild pose={isOpen ? 'show' : 'hide'}>
        <Box alignItems="center" p={2}>
          <Image
            height="28px"
            src={blueCircleButtonSVG}
            onClick={() => {
              myHealthStore.setHealthNavigator({ isOpen: false });
            }}
          />
          <Text
            ml="8px"
            fontSize="11px"
            color="grey3"
            css={{ textOverflow: 'ellipsis' }}
          >
            {url}
          </Text>
        </Box>
        <StyledIFrame url={url} />
      </DownInChild>
    </Overlay>
  );
});
