import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import { push } from '../utils';
import { isDesktop } from '../../config';

const go = {
  practiceProfile: id => push(`/practice-profile/${id}`),
  doctorProfile: (orgId, doctorId) => {
    if (isDesktop()) push(`/practice-profile/${orgId}/doctor/${doctorId}`);
    else push(`/doctor-profile/${orgId}/${doctorId}`);
  },
  scriptTriage: orgId => {
    if (isDesktop()) push(`/practice-profile/${orgId}/request-prescription`);
    else push('/request-script/new');
  },
  practiceBooking: id => push(`/practice-profile/${id}/booking`),
};

const PracticeProfileRoutes = [
  <Route
    exact={!isDesktop()}
    key="/practice-profile/:id"
    path="/practice-profile/:id"
    component={lazy(() =>
      import(
        /* webpackChunkName: "PracticeProfilePage" */ '../../PracticeProfilePage'
      ),
    )}
  />,
  <Route
    key="/doctor-profile/:orgId/:id"
    path="/doctor-profile/:orgId/:id"
    component={lazy(() =>
      import(
        /* webpackChunkName: "DoctorProfilePage" */ '../../PracticeProfilePage/DoctorProfilePage'
      ),
    )}
  />,
  <Route
    key="/request-script/new"
    path="/request-script/new"
    component={lazy(() =>
      import(
        /* webpackChunkName: "ScriptTriagePopUp" */ '../../PracticeProfilePage/ScriptTriagePopUp'
      ),
    )}
  />,
  <Route
    key="/practice-profile/:id/booking"
    path="/practice-profile/:id/booking"
    component={lazy(() =>
      import(
        /* webpackChunkName: "BookingPageMobile" */ '../../PracticeProfilePage/BookingPageMobile'
      ),
    )}
  />,
];

export default PracticeProfileRoutes;
export { go };
