import { takeLatest, select } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';
import { GenericParserHttp } from '../../../libs/Http';
import createPoll from '../../../libs/Polling';
import { getConsumerUrl, getLoginObject, getToken } from '../../../selectors/loginSelectors';
import { getUrl } from '../../../selectors/appointmentSelectors';
import { go } from '../../../routes/index';
import delay from '../../../libs/Delay';
import alert from 'libs/Alert';

const POLL_TIMEOUT_MILLIS = 300000;
const HANGING_TIMEOUT_MILLIS = 30000;
const POLL_INTERVAL = 3000;

const api = ({ payload: { sessionId } }) =>
  GenericParserHttp.get(`consumer/appointment/session/${sessionId}/check`).then(
    ({ body }) => body,
  );

function* finishedPolling() {
  yield delay(3000);
  const token = yield select(getToken);
  const o = yield select(getLoginObject);
  if (token) {
    if (o) {
      if (o.consumerUrl === undefined) { go.home(); return; }
      go.home();
    }
  } else {
    const url = yield select(getUrl);
    go.register(url);
  }
}

function* errorPolling(item) {
  const url = yield select(getUrl);
  if(item.Status === 11) { 
    //go.appointmentPayments(url);
    alert.error("Payment failed, please try again");
    go.prepaidPage(url)
    return;
  };

  go.bookingUnavailable(url);
}

const { initialisePoll, executePoll } = createPoll({
  pollFn: api, // function to run on each poll
  // action to be fired when a single poll has finished
  pollRoundtripCompleteActionCreator: actions.checkConfirmationSuccess,
  // action to be fired when processing returns an error
  pollErrorActionCreator: () =>
    actions.confirmationPollEnd(false, { status: 400 }),
  // action to be fired when processing has succeeded
  pollFinishedActionCreator: () => actions.confirmationPollEnd(true, null),
  // function to run if processing succeeded
  pollFinishedGenerator: finishedPolling,
  // function to run if processing errors
  pollErrorGenerator: errorPolling,
  // returns true if processing has not succeeded or errored, and polling should continue
  checkKeepPollingFn: ({ Status }) => Status === 0,
  // returns true if processing succeeds
  checkFinishedPollingFn: ({ Status }) => Status === 1,
  // returns true if processing has returned an error
  checkErrorPollingFn: ({ Status }) => Status === 2 || Status === 4,
  // action to be fired if polling errors
  errorActionCreator: actions.checkConfirmationFailure,
  pollTimeMillis: POLL_INTERVAL, // how often each poll runs
  // sets confirmation state to checking
  pollActionCreator: ({ payload: { sessionId } }) =>
    actions.checkForConfirmation(sessionId),
  // indicates a single poll has finished without error
  pollRoundtripCompleteActionType: actionTypes.checkConfirmationSuccess,
  // indicates that polling should end, and how
  endStates: [
    actionTypes.confirmationPollEnd,
    actionTypes.checkConfirmationFailure,
  ],
  timeoutMillis: POLL_TIMEOUT_MILLIS, // when polling process should end if a end state has not beem returned
  timeoutActionCreator: () =>
    actions.checkConfirmationFailure({ status: -1, message: 'timeout' }),
  hangingTimeoutMillis: HANGING_TIMEOUT_MILLIS, // when user should stop waiting for confirmation
  hangingActionCreator: actions.setIsBusy, // what to do when user should stop waiting
});

function* requestConfirmationSaga() {
  yield takeLatest(actionTypes.checkForConfirmation, executePoll);
}

function* appointmentConfirmationSaga() {
  yield takeLatest(actionTypes.confirmationPollStart, initialisePoll);
}

export { appointmentConfirmationSaga, requestConfirmationSaga };
