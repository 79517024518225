import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import PaymentForm from 'vScript/Payment/PaymentForm';

const Container = styled.div`
    width: 460px;
    height: 430px;
    margin: 2rem 0rem;
    box-shadow: 2px 4px 7px lightgray;
    align-self: center;
    padding: 0px 30px;
`
const Title = styled.div`
    text-align: center;
    font-weight: 600;
    font-size: 17px;
    color: #585858;
    margin: 2rem 0rem;
`
interface Props {
    onClickNext: () => void;
    onError: () => void;
}

const AddPaymentMethod: React.FC<Props> = observer(({onClickNext, onError}) => {
    return (
        <Container>
            <Title>New Payment Method</Title>
            <PaymentForm hideSaveCard={true} handleNext={onClickNext} onError={onError} />
        </Container>
    );
});

export default AddPaymentMethod;