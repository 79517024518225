import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { getLoginPageURL } from '../libs/utils';
import { getToken } from '../libs/newHttp';
import { isDesktop } from '../config';

const history = createBrowserHistory();
const { push, replace, goBack, go, block } = history;

const RouteWrapper = ({ tokenExists, ...props }) => {
  let isTokenInMemory = tokenExists;

  if (!isTokenInMemory) {
    // if token is in local storage
    // we set it to true
    // because <App> will dispatch loginSuccess with
    // token from localStorage to re-create auth info in Redux
    if (getToken()) {
      isTokenInMemory = true;
    }
  }

  if (props.computedMatch.path.includes("identity")) {
    if (!isDesktop()) { 
      return <Route {...props} /> 
    } else { return <Route {...props} /> }
      /*
      return props.canGoIdentity ?
      <Route {...props} /> 
      :
      <Redirect to={{path: "/home"}} />
    } else {
      return <Route {...props} />
    }*/
  }

  return isTokenInMemory ? (
    <Route {...props} />
  ) : (
    (window.location = getLoginPageURL())
  );
};

RouteWrapper.propTypes = {
  tokenExists: PropTypes.bool.isRequired,
};

const ProtectedRoute = connect(state => ({
  tokenExists: state.loginReducer.token !== null,
  canGoIdentity: state.verifyReducer.id !== null,
}))(RouteWrapper);

const goBackPages = noOfPages => go(noOfPages);

export { ProtectedRoute, push, replace, goBack, goBackPages, history };
