import React, { useState } from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: ${(props: ContainerProps) => props.show ? "0.6" : "0" };
    z-index: 1;
    transition: all 0.5s;
`
interface ContainerProps {
    show: boolean;
}
const Container = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 0; 
    bottom: 0;
    opacity: ${(props: ContainerProps) => props.show ? "1" : "0" };
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
    transition: all 0.5s;
    z-index: 2;
`
interface Props {
    func: () => void;
    cancel: () => void;
}

const PopUpLeavingFlow: React.FC<Props> = ({func, cancel}) => {
    const [show, setShow] = useState(false)
    setTimeout(() => {
        setShow(true);
    }, 1000);
    return (
        <div>
            <Overlay show={show} onClick={cancel}/>
            <Container show={show}>

            </Container>
        </div>
    )
}

export default PopUpLeavingFlow