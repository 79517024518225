const actionTypes = {
  selectTime: 'SELECT_TIME',
  clearSelection: 'CLEAR_SELECT_TIME',
  selectDay: 'SELECT_DAY',
  fetchDoctorAppointments: 'FETCH_DOCTOR_APPOINTMENTS',
  fetchDoctorAppointmentsSuccess: 'FETCH_DOCTOR_APPOINTMENTS_SUCCESS',
  fetchDoctorAppointmentsFailure: 'FETCH_DOCTOR_APPOINTMENTS_FAILURE',
  setSmokingRecallId: 'SET_SMOKING_RECALL_ID_FOR_APPOINTMENT',
};

const actions = {
  setSmokingRecallId: smokingRecallId => ({
    type: actionTypes.setSmokingRecallId,
    payload: {
      smokingRecallId,
    },
  }),
  selectTime: (doctorId, appointmentId, time) => ({
    type: actionTypes.selectTime,
    payload: {
      doctorId,
      appointmentId,
      time,
    },
  }),
  clearSelection: () => ({
    type: actionTypes.clearSelection,
  }),
  selectDay: (practiceId, time, dayNum, doctorName) => ({
    type: actionTypes.selectDay,
    payload: {
      time,
      practiceId,
      dayNum,
      doctorName,
    },
  }),
  fetchDoctorAppointments: (day, practiceId, dayNum, doctorName) => ({
    type: actionTypes.fetchDoctorAppointments,
    payload: {
      day,
      practiceId,
      dayNum,
      doctorName,
    },
  }),
  fetchDoctorAppointmentsSuccess: (doctors, dayNum, doctorName) => ({
    type: actionTypes.fetchDoctorAppointmentsSuccess,
    payload: {
      doctors,
      dayNum,
      doctorName,
    },
  }),
  fetchDoctorAppointmentsFailure: error => ({
    type: actionTypes.fetchDoctorAppointmentsFailure,
    error: true,
    payload: {
      error,
    },
  }),
};

export { actions, actionTypes };
