import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dependants from './Dependants';

import { actions as headerActions } from '../components/UIContainer/actions/headerActions';
import { actions } from './actions/actions';
import styles from './dependants.module.css';
import { go } from '../routes';
import { keyframes } from 'styled-components';
import { useLocalStore } from 'mobx-react-lite';
import Alert from 'libs/Alert'

const fadeIn = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    visibility: visible;
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    visibility: visible;
    opacity: 1;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
`;

const DependantsPage = ({
  setSideHeaderItem, 
  fetchDependants,
  token,
  removeId,
  ...rest
}) => {
  const store = useLocalStore(() => {
    return {
      menuFlag: false,
      menuPosition: {top: 0, left: 0, width: 0 },
      toggleMenu: (flag, p) => {
        store.menuFlag = flag;
        if(p){
          store.menuPosition.top = p.top;
          store.menuPosition.left = p.left;
          store.menuPosition.width = p.width;
        }
      },
      removeFlag: false,
      toggleRemove: (flag) => store.removeFlag = flag,
      target: {},
      setTarget: (target) => store.target = target
    };
  })
  if(removeId) {
    const remove = rest.dependants.find(d => d.Id === removeId);
    remove && Alert.success(`${remove.FirstName} has been removed from your Care Circle`);
  }
  
  const onMenuClick = (position, dependant) => {
    store.toggleMenu(true, position);
    store.setTarget(dependant);
  }


  useEffect(() => {
    setSideHeaderItem("dependants");
    fetchDependants(token);
  }, []);

  return <Dependants {...rest} token={token} store={store} onMenuClick={onMenuClick} />
}

const mapStateToProps = state => ({
  isFetching: state.fetchDependantsReducer.isFetching,
  token: state.loginReducer.token,
  dependants: state.fetchDependantsReducer.dependants,
  updateError: state.addDependantReducer.error,
  removeId: state.removeDependantReducer.removeId,
  isWaiting: state.addDependantReducer.isAdding ||
             state.addDependantReducer.isProcessingFile ||
             state.removeDependantReducer.isRemoving ||
             state.fetchDependantsReducer.isFetching
});
const mapDispatchToProps = dispatch => ({
  setSideHeaderItem: bindActionCreators(
    headerActions.setSideHeaderItem,
    dispatch,
  ),
  fetchDependants: bindActionCreators(actions.fetchDependants, dispatch),
  removeDependant: bindActionCreators(actions.removeDependant, dispatch),
  goToDependant: id => () => go.editDependant(id),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DependantsPage);
export { styles };
