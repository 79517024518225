import React from 'react';
import PropTypes from 'prop-types';

import styles from './practiceoffline.module.css';
import { ReactComponent as Arrow } from '../../../svgs/close.svg';
import icon from '../../../svgs/pharmacy-error.svg';
import CallPractice from '../../../components/CallPractice/index';
import { practicePropType } from '../../../PracticesPage/propTypes/index';

const PracticeOffline = ({ practice, arrowClick }) => (
  <div className={styles.container}>
    <Arrow className={styles.arrow} onClick={arrowClick} />
    <div className={styles.whoops}>Whoops!</div>
    <div className={styles.popUpContainer}>
      <div className={styles.popUp}>
        <img src={icon} alt="hospital" className={styles.icon} />
        <div className={styles.message}>
          We couldn't complete your request.
          <br />
          <br />
          There has been a communication problem with the medical centre and we won't be able to send your Repeat Prescription Request.
          <br />
          <br />
          It's best you call the practice on their number.
          <br />
        </div>
        <CallPractice practice={practice} />
      </div>
    </div>
  </div>
);

PracticeOffline.propTypes = {
  practice: practicePropType,
  arrowClick: PropTypes.func.isRequired,
};

export default PracticeOffline;
