import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import moment from 'moment'

import { toNormalTime } from 'libs/Dates';
import { ReactComponent as Back } from 'svgs/circleRightArrow.svg';
import { ReactComponent as Green } from 'svgs/approvedTick.svg';
import { ReactComponent as Orange } from 'svgs/pendingTickWarning.svg';
import { ReactComponent as Red } from 'svgs/declinedCrossWarning.svg';

const Container = styled.div`
    width: 533px;
    height: 650px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 4px;
    margin: 2rem 0rem;
    padding: 2rem;
    position: relative;
`
const Title = styled.div`
    font-size: 17px;
    color: #585858;
    text-align: center;
    width: 100%;
`
const Subtitle = styled.div`
    font-size: 13px;
    text-align: center;
    color: #585858;
    width: 100%;
`
const IdentitiyTitle = styled.div`
    color: #F5722C;
    font-size: 21px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
`
const Text = styled.div`
    font-size: 15px;
    width: 300px;
    text-align: left;
    margin: 0.5rem auto;
`

interface IPractice {
    OrganisationId: string;
    OrganisationName: string;
    Address: string;
    OnlineVerificationEnabled: boolean;
    PictureUrl: string;
    IdentityRequest: {
        Status: number;
        RequestDate: string;
    } | null;
    Phone: string;
    Pictures: string[];
}
interface IProps {
    practice: IPractice;
    goBack: () =>  void;
}
const ViewIdentitiyRequest: React.FC<IProps> = observer(({practice, goBack}) => {
    return(
        <Container>
            <Back onClick={() => goBack()} style={{
                transform: "rotate(180deg)",
                cursor: "pointer",
                position: "absolute",
                top: "30px",
                left: "30px",
                height: "48px",
                width: "48px",
            }}/>
            <Title>{practice.OrganisationName}</Title>
            <Subtitle>{practice.Address}</Subtitle>
            {practice.Pictures[0] && 
                <img src={practice.Pictures[0]} alt="practiceImage" style={{display: "block", margin: "2rem auto", width: "300px"}}/>
            }
            {practice.IdentityRequest!.Status === 0 && 
                <div>
                    <Orange style={{display: "block", margin: "0rem auto 1rem"}} />
                    <IdentitiyTitle style={{color: "#F5722C"}}>Identity Partially Verified</IdentitiyTitle>
                    <Text>
                        Your identitiy request sent on {moment.utc(practice.IdentityRequest!.RequestDate, 'YYYY-MM-DD').format('DD/MM/YYYY')} at {toNormalTime(practice.IdentityRequest!.RequestDate)} is currently pending with {practice.OrganisationName}.
                    </Text>
                    <Text>
                        You can call them on <span style={{color: "#07A3C8"}} >{practice.Phone}</span> to find out more.
                    </Text>
                </div>
            }
            {practice.IdentityRequest!.Status === 1 && 
                <div>
                    <Green style={{display: "block", margin: "0rem auto 1rem"}} />
                    <IdentitiyTitle style={{color: "#23C373"}}>Identity Verified</IdentitiyTitle>
                    <Text>
                        Your identitiy was verified on {moment.utc(practice.IdentityRequest!.RequestDate, 'YYYY-MM-DD').format('DD/MM/YYYY')} by {practice.OrganisationName}.
                    </Text>
                </div>
            }
            {practice.IdentityRequest!.Status === 2 && 
                <div>
                    <Red style={{display: "block", margin: "0rem auto 1rem"}} />
                    <IdentitiyTitle style={{color: "#FF6A65"}}>Identity Verification Declined</IdentitiyTitle>
                    <Text>
                        Your identitiy request on {moment.utc(practice.IdentityRequest!.RequestDate, 'YYYY-MM-DD').format('DD/MM/YYYY')} at {toNormalTime(practice.IdentityRequest!.RequestDate)} has been declined by {practice.OrganisationName}.
                    </Text>
                    <Text>
                        You can find out more by calling them on <span style={{color: "#07A3C8"}}>{practice.Phone}</span>.
                    </Text>
                </div>
            }
        </Container>
    )
})

export default ViewIdentitiyRequest;