const actionTypes = {
	addPharmacy: 'SCRIPT_ADD_PHARMACY_INFO',
	addPharmacySuccess: 'SCRIPT_ADD_PHARMACY_INFO_SUCCESS',
	addPharmacyFailure: 'SCRIPT_ADD_PHARMACY_INFO_FAILURE',
	deletePharmacy: 'SCRIPT_DELETE_PHARMACY_INFO',
	deletePharmacySuccess: 'SCRIPT_DELETE_PHARMACY_INFO_SUCCESS',
	deletePharmacyFailure: 'SCRIPT_DELETE_PHARMACY_INFO_FAILURE',
	getOptions: 'GET_OPTIONS',
	getOptionsSuccess: 'GET_OPTIONS_SUCCESS',
	getOptionsFailure: 'GET_OPTIONS_FAILURE',
};

const addPharmacy = (details, token) => ({
	type: actionTypes.addPharmacy,
	payload: {
		details,
		token,
	},
});
const addPharmacySuccess = Id => ({
	type: actionTypes.addPharmacySuccess,
	payload: {
		Id,
	},
});
const addPharmacyFailure = err => ({
	type: actionTypes.addPharmacyFailure,
	error: true,
	payload: {
		err,
	},
});

const deletePharmacy = (id, token) => ({
	type: actionTypes.deletePharmacy,
	payload: {
		id,
		token,
	},
});
const deletePharmacySuccess = () => ({
	type: actionTypes.deletePharmacySuccess,
});
const deletePharmacyFailure = err => ({
	type: actionTypes.deletePharmacyFailure,
	error: true,
	payload: {
		err,
	},
});

const getOptions = (token, orgId) => ({
	type: actionTypes.getOptions,
	payload: {
		token,
		orgId,
	}
});
const getOptionsSuccess = (options) => ({
	type: actionTypes.getOptionsSuccess,
	payload: {
		options: options
	}
});
const getOptionsFailure = err => ({
	type: actionTypes.getOptionsFailure,
	payload: {
		err
	}
});

const actions = {
	addPharmacy,
	addPharmacySuccess,
	addPharmacyFailure,
	deletePharmacy,
	deletePharmacySuccess,
	deletePharmacyFailure,
	getOptions,
	getOptionsSuccess,
	getOptionsFailure,
};

export { actions, actionTypes };
