import { call, takeEvery, put } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/searchActions';
import { GenericParserHttp } from '../../libs/Http';

const api = (query, token) =>
  GenericParserHttp.get(
    `consumer/prescription/medication/search?terms=${query}`,
    { token },
  ).then(({ body }) => body);

function* searchMeds({ payload: { query, token } }) {
  try {
    const medications = yield call(api, query, token);
    yield put(actions.searchMedsSuccess(medications));
  } catch (err) {
    yield put(actions.searchMedsFailure(err));
  }
}

function* searchMedsSaga() {
  yield takeEvery(actionTypes.searchMeds, searchMeds);
}

export { searchMedsSaga };
