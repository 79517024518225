import React from 'react';

import styles from './privacyInfoPopUpButton.module.css';
import { ReactComponent as Button } from '../../../svgs/blueCircleButton.svg'

const PrivacyInfoPopUpButton = ({onClick, noFade}) => {
    return(
        <div className={noFade ? styles.noFade : styles.container}>
            <Button onClick={onClick} className={styles.button} />
        </div>
    );
}

export default PrivacyInfoPopUpButton;