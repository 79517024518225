import React from 'react';
import styles from './BarItem.module.scss';
import cn from 'classnames';

interface Props {
    name: string;
    LeftIcon?: React.FC<React.SVGProps<SVGSVGElement>>,
    RightIcon?: React.FC<React.SVGProps<SVGSVGElement>>,
    onClick?: () => void;
    highlight?: boolean;
}



const BarItem = (
    {
        name, 
        LeftIcon,
        RightIcon, 
        onClick, 
        highlight = false
    }: Props) => {
  return (
    <div className={cn(styles.container, {[styles.highlight]: highlight})} onClick={onClick}>
        {LeftIcon && <LeftIcon className={styles.leftIcon}/>}
        {name}{RightIcon && <RightIcon className={styles.rightIcon}/>}
    </div>
  )
}

export default BarItem;