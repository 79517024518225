import React from 'react';
import RegisterStep from 'pages/careCircle/RegisterStep';
import DeclareStep from 'pages/careCircle/DeclareStep';
import RedirectStep from 'pages/careCircle/RedirectStep';
import { isDesktop } from 'config';
import facade from './hocs';

const Creating = () => {

    const touchFlag = !isDesktop();
    const FacadePage = facade(touchFlag, 'Add to My Care Circle');

    return (
        <FacadePage>
            <RegisterStep touch={touchFlag}/>
            <DeclareStep touch={touchFlag} />
            <RedirectStep touch={touchFlag} />
        </FacadePage>
    )
};

export default Creating;