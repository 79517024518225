import { animated } from '@react-spring/web';
import { AnimateSharedLayout } from 'framer-motion';
import withModal from 'hocs/withModal';
import React, { memo, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as Spinner } from 'svgs/spinner.svg';

const styles = {
    container: {
        position: 'fixed' as 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: '#000',
    },
    spinner: {
        height: '4.2rem',
        width: '4.2rem',
        fill: 'white'
    }
}

interface LoadingSpinnerProps {
  animateStyle?: {}
}

const LoadingSpinner = ({animateStyle}: LoadingSpinnerProps) => {
  return ReactDOM.createPortal(
    <animated.div style={{...styles.container, ...animateStyle}}>
      <Spinner style={styles.spinner} />
    </animated.div>
  , document.getElementById('portal') as Element);}

export default LoadingSpinner;