import { IHidable } from 'components/LearnMorePopUp/types';
import React, { Component, PropsWithChildren, ReactNode } from 'react';
import { goBack } from '../routes/utils';

interface ContainerProps {
    hide: () => void;
}

const withContainer = <P extends ContainerProps>(
  ContainerComponent: React.ComponentType<PropsWithChildren<{}>>, 
  WrappedComponent: React.ComponentType<IHidable>) => 
    (props: P) =>
(
    <ContainerComponent {...props}>
        <WrappedComponent handleHide={goBack} />
    </ContainerComponent>
);

export default withContainer;
