const actionTypes = {
  login: 'LOGIN',
  loginSuccess: 'LOGIN_SUCCESS',
  loginError: 'LOGIN_ERROR',
  logout: 'LOGOUT',
  logoutSuccess: 'LOGOUT_SUCCESS',
  logoutFailure: 'LOGOUT_FAILURE',
  clearLoginError: 'CLEAR_LOGIN_ERROR',
  refresh: 'REFRESH_TOKEN',
  loginPin: 'LOGIN_PIN',
  clearAll: 'CLEAR_ALL'
};

const login = (email, password) => ({
  type: actionTypes.login,
  payload: { email, password },
});

const loginPin = (pin, success, fail) => ({
  type: actionTypes.loginPin,
  payload: { pin, success, fail },
});

const clearAll = () => ({
  type: actionTypes.clearAll,
})

const loginSuccess = (token, expiryTime, email, refreshToken, ConsumerUrl) => ({
  type: actionTypes.loginSuccess,
  payload: {
    token,
    expiryTime,
    email,
    refreshToken,
    ConsumerUrl,
  },
});

const refresh = (refreshToken, email, page) => ({
  type: actionTypes.refresh,
  payload: {
    refreshToken,
    email,
    page,
  },
});

const loginError = err => ({
  type: actionTypes.loginError,
  payload: { err },
  error: true,
});

const clearLoginError = () => ({
  type: actionTypes.clearLoginError,
});

const logout = (token, config) => ({
  type: actionTypes.logout,
  payload: {
    token,
    config,
  },
});
const logoutSuccess = () => ({
  type: actionTypes.logoutSuccess,
});

const logoutFailure = err => ({
  type: actionTypes.logoutFailure,
  payload: { err },
  error: true,
});

const actions = {
  login,
  loginSuccess,
  loginError,
  logout,
  logoutSuccess,
  logoutFailure,
  clearLoginError,
  refresh,
  loginPin,
  clearAll
};
export { actionTypes, actions };
