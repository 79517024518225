import React from 'react';
import styled from 'styled-components';
import { Image } from '../../components/vensaComponents/vensaComponents/components/Image'
import HeroImage from '../../svgs/vScript-Pharm-Path.svg';
import { ReactComponent as Cross } from '../../svgs/blueCross.svg'

const Header = styled.div`
    font-size: 1rem;
    margin: 1rem;
`

const BoldText = styled.div`
    font-family: 'Roboto';
    font-size: 1rem;
    font-weight: bold;
    margin: 0.5rem;
`

const Text = styled.div`
    font-family: 'Roboto';
    font-size: 0.875rem;
    font-weight: lighter;
    margin: 0.5rem;
`

const Container = styled.div`
    margin: 0.5rem;
`

const UseLocationButton = styled.button`
    background-color: var(--color-pelorous);
    border: none;
    border-radius: 40px;
    padding: 1.2rem;
    margin-left: 0.5rem;
    fill: var(--color-white);
    color: var(--color-white);
    font-size: 1rem;
    outline: none;
    display: flex;
    align-items: center;
    right: 0;
`

const ButtonContainer = styled.div`
    display: flex;
    padding: 2.5rem 0 1.5rem 0
    margin: 1rem 0.5rem 1rem 0.5rem;
    justify-content: space-between;
    flex-direction: row-reverse;
    box-shadow: #00000029;
`

const StyledCross = styled(Cross)`
    min-width: 1.353rem;
    width: 1.353rem;
    height: 1.353rem;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 0.875rem;
    stroke: none;
`

interface IProps {
    handleClick?: Function,
    skipLocation?: Function,
    goBack?: Function
}

export const LocationHeader: React.FC<IProps> = ({goBack}) => {
    return (
        <Header>
            Collect at my pharmacy
            <StyledCross
                onClick={() => (goBack && goBack())}
            />
        </Header>
    )
}

export const LocationBody: React.FC<IProps> = ({handleClick}) => {

    return (
        <Container>
            <Image src={HeroImage}/>
            <BoldText>
                Use your location
            </BoldText>
            <Text>
                Use Google Maps to find your local pharmacies.
            </Text>
            <ButtonContainer>
                <UseLocationButton 
                    onClick={() => (handleClick && handleClick())}
                >
                    Get Started
                </UseLocationButton>
            </ButtonContainer>
        </Container>
    )
};