import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Alert from 'react-s-alert';
import classnames from 'classnames';
import { debounce } from 'lodash/fp';
import moment from 'moment';
import qs from 'query-string';
import { CSSTransitionGroup } from 'react-transition-group';
import { actions as appointmentActions } from '../PracticeProfilePage/BookingPageMobile/actions/actions';
import { actions } from '../EntryPage/actions/actions';
import { actions as appActions } from './actions/actions';
import { actions as menuActions } from '../components/UIContainer/MobileUI/Menu/actions/actions';
import { actions as scrollActions } from './actions/scrollActions';
import styles from './app.module.css';
import Menu from '../components/UIContainer/MobileUI/Menu';
import ExpiredSession from '../components/ExpiredSession';
import TnCWrapper from '../components/TnCWrapper';
import MyCustomContentTemplate from '../components/Alerts';
import { isDesktop } from '../config';
import { getSmokingRecallIdFromURL } from './helpers/tokenHelper';
import cssVars from 'css-vars-ponyfill';

class App extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    loginSuccess: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    setScrollPosition: PropTypes.func.isRequired,
    shouldTrackScroll: PropTypes.bool.isRequired,
    showExpiredSession: PropTypes.bool.isRequired,
    displayMenu: PropTypes.bool.isRequired,
    shrink: PropTypes.bool.isRequired,
    toggleShrinkApp: PropTypes.func.isRequired,
    toggleMenu: PropTypes.func.isRequired,
    showMobileHeader: PropTypes.bool.isRequired,
    appClassNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    setSmokingRecallId: PropTypes.func.isRequired,
    location: PropTypes.shape({
      query: PropTypes.object,
    }),
    token: PropTypes.string,
  };
  constructor(props) {
    super(props);
    this.onContentScroll = this.onContentScroll.bind(this);
  }
  state = {
    showTnC: false,
    height: window.innerHeight
  };
  componentDidMount() {
    cssVars({});
    const { smokingRecallId } = getSmokingRecallIdFromURL();
    if (smokingRecallId) {
      this.props.setSmokingRecallId(smokingRecallId);
    }
    const loginInfo = JSON.parse(window.localStorage.getItem('token'));
    if (loginInfo && !qs.parse(this.props.location.search).token) {
      if (moment().isAfter(loginInfo.expiryTime)) {
        this.props.logout();
      } else {
        this.props.loginSuccess(
          loginInfo.token,
          loginInfo.expiryTime,
          loginInfo.email,
          loginInfo.refreshToken,
        );
      }
    }
  }
  updateDimensions = () => {
    this.setState({ height: window.innerHeight });
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.id && !nextProps.acceptedTnC) {
      this.setState({ showTnC: true });
    }
    if (
      nextProps.acceptedTnC &&
      nextProps.acceptedTnC !== this.props.acceptedTnC
    ) {
      this.setState({ showTnC: false });
    }
  }
  onContentScroll() {
    if (this.props.shouldTrackScroll) {
      this.props.setScrollPosition(this.content.scrollTop);
    }
  }
  render() {
    const {
      appClassNames,
      shrink,
      displayMenu,
      showExpiredSession,
    } = this.props;
    const showTnC =
      this.state.showTnC && !this.props.acceptedTnC && !this.props.isFetching;
    return (
      <CSSTransitionGroup
        component="div"
        className={styles.outerApp}
        transitionName={{
          leave: styles.leave,
          leaveActive: styles.leaveActive,
          enter: styles.enter,
          enterActive: styles.enterActive,
        }}
        transitionLeaveTimeout={300}
        transitionEnterTimeout={300}
      >
        {!isDesktop() && displayMenu && <Menu key="0" />}
        <div
          key="1"
          className={classnames(styles.app, appClassNames, {
            [styles.shrink]: shrink && !isDesktop(),
          })}
          style={{ height: this.state.height }}
          onClick={() => { if (shrink && !isDesktop()) { this.props.toggleShrinkApp(); setTimeout(this.props.toggleMenu, 400); } }}
        >
          <div
            ref={c => {
              this.content = c;
            }}
            className={styles.content}
            onScroll={debounce(300, this.onContentScroll)}
          >
            <div className={styles.contentInner} style={{ height: this.state.height, backgroundColor: "#F9F9F9" }}>{this.props.children}</div>
            {isDesktop() && (
              <Alert
                contentTemplate={MyCustomContentTemplate}
                position="bottom"
                html
                stack={{ limit: 1 }}
                effect="scale"
              />
            )}
          </div>
          {!isDesktop() && (
            <Alert
              contentTemplate={MyCustomContentTemplate}
              position="bottom"
              html
              stack={{ limit: 1 }}
              effect="scale"
            />
          )}

        </div>

        {showExpiredSession && <ExpiredSession />}
        {showTnC && <TnCWrapper key="2" />}
      </CSSTransitionGroup>
    );
  }
}

const mapStateToProps = state => ({
  appClassNames: state.appStylesReducer.classNames,
  showExpiredSession: state.expiredSessionReducer.showExpiredSession,
  shouldTrackScroll: state.appScrollReducer.shouldTrack,
  shrink: state.appStylesReducer.shrink,
  showMobileHeader: state.appStylesReducer.showMobileHeader,
  displayMenu: state.menuReducer.isShowing,
  token: state.loginReducer.token,
  id: state.profileReducer.profile.Id,
  acceptedTnC: state.profileReducer.profile.HasAcceptedTC,
  isFetching: state.profileReducer.isFetching && state.loginReducer.isFetching,
});
const mapDispatchToProps = dispatch => ({
  setSmokingRecallId: bindActionCreators(
    appointmentActions.setSmokingRecallId,
    dispatch,
  ),
  toggleShrinkApp: bindActionCreators(appActions.toggleShrinkApp, dispatch),
  toggleMenu: bindActionCreators(menuActions.toggleMenu, dispatch),
  loginSuccess: bindActionCreators(actions.loginSuccess, dispatch),
  logout: bindActionCreators(actions.logout, dispatch),
  setScrollPosition: bindActionCreators(
    scrollActions.setScrollPosition,
    dispatch,
  ),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(App),
);
export { styles };
