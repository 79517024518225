const actionTypes = {
  getPracticeListings: 'GET_PRACTICE_LISTINGS',
  getPracticeListingsSuccess: 'GET_PRACTICE_LISTINGS_SUCCESS',
  getPracticeListingsFailure: 'GET_PRACTICE_LISTINGS_FAILURE',
  linkPractice: 'LINK_PRACTICE_TO_USER',
  linkPracticeSuccess: 'LINK_PRACTICE_TO_USER_SUCCESS',
  linkPracticeFailure: 'LINK_PRACTICE_TO_USER_FAILURE',
  unlinkPractice: 'UNLINK_PRACTICE_FROM_USER',
  unlinkPracticeSuccess: 'UNLINK_PRACTICE_FROM_USER_SUCCESS',
  unlinkPracticeFailure: 'UNLINK_PRACTICE_FROM_USER_FAILURE',
};

const actions = {
  getPracticeListings: token => ({
    type: actionTypes.getPracticeListings,
    payload: {
      token,
    },
  }),
  getPracticeListingsSuccess: practices => ({
    type: actionTypes.getPracticeListingsSuccess,
    payload: {
      practices,
    },
  }),
  getPracticeListingsFailure: error => ({
    type: actionTypes.getPracticeListingsFailure,
    error: true,
    payload: {
      error,
    },
  }),
  unlinkPractice: (practice, token) => ({
    type: actionTypes.unlinkPractice,
    payload: {
      practice,
      token,
    },
  }),
  unlinkPracticeSuccess: practice => ({
    type: actionTypes.unlinkPracticeSuccess,
    payload: {
      practice,
    },
  }),
  unlinkPracticeFailure: error => ({
    type: actionTypes.unlinkPracticeFailure,
    error: true,
    payload: {
      error,
    },
  }),
  linkPractice: (practice, token, onFinishAdd, shouldAdd) => ({
    type: actionTypes.linkPractice,
    payload: {
      practice,
      token,
      onFinishAdd,
      shouldAdd,
    },
  }),
  linkPracticeSuccess: (practice, matched, usingVensa) => ({
    type: actionTypes.linkPracticeSuccess,
    payload: {
      practice,
      matched,
      usingVensa,
    },
  }),
  linkPracticeFailure: error => ({
    type: actionTypes.linkPracticeFailure,
    error: true,
    payload: {
      error,
    },
  }),
};

export { actions, actionTypes };
