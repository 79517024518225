import { animated, config, useTransition } from "@react-spring/web";
import React, { useState } from "react";
import useTimeout from "hooks/useTimeout";
import loadingGif from 'images/LoadingGif.gif';
import {ReactComponent as DoneImg} from 'svgs/bigTick.svg';
import styles from './index.module.scss';
import Divider from "components/shared/common/Divider";

// Redirection Page
interface RedirectionProps {
    complete?: () => void;
    title: string;
}
const Redirection: React.FC<RedirectionProps> = (
    {
        complete,
        title
    }) => {
    useTimeout(2000, complete);
  return (
    <>
      <span className={styles.iconContainer}>
        <DoneImg/>
      </span>
      <Divider orientation="horizontal" size="1rem" />
      <span className={styles.tip}>{title}</span>
    </>
  )
};

// Waiting Page
interface WaitingProps {
    title: string;
    tip: string;
    condition?: boolean;
    minimalTimeSpan?: number;
    error?: string;
    onError?: () => void;
    complete?: () => void;
}

const Waiting: React.FC<WaitingProps> = (
    {
        title,
        tip,
        error,
        condition,
        minimalTimeSpan = 5000,
        onError,
        complete,
    }
) => {
    const [waiting, setWaiting] = useState(true);

    const keepWaiting = () => {
        setWaiting(false);
        onError && onError();
    };
    useTimeout(minimalTimeSpan, keepWaiting);
    const transitions = useTransition(!condition && !waiting, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config.molasses
      });

      return transitions(({opacity}, item) => item ? 
      <animated.div 
        className={styles.confirmContainer} 
        style={{
          opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1]})
        }}>
          {!error && <Redirection complete={complete} title={title} />}
      </animated.div> : 
      <animated.div className={styles.container} style={{
        opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0]})
      }}>
        <img
          alt={tip}
          src={loadingGif} />
        <span className={styles.title}>Hold on</span>
        <span className={styles.tip}>{tip}</span>
      </animated.div>
    )
}

export default Waiting;