import React, { PropsWithChildren, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Text, Image } from 'components/vensaComponents';
import { Line } from 'PaymentsPage/components/Line';
// import moment from 'moment';
import rtfToHtml from '@iarna/rtf-to-html';
import { useMyHealthStore } from 'store/mobx/mobx';

// const getDate = (dateTime: string) => moment(dateTime).format('D/MM/YY - LT');

export const BaseDetailBox = observer(({ children }: PropsWithChildren<{}>) => (
  <Box
    bg="white"
    borderRadius="4px"
    boxShadow={6}
    p={3}
    direction="column"
    mb="16px"
    display="block"
  >
    {children}
  </Box>
));

export const BaseDetailSectionHeader: React.FC<{
  itemName: string;
  givenName: string;
  familyName: string;
  organisationName: string;
  providerName: string;
}> = observer(
  ({ itemName, givenName, familyName, organisationName, providerName }) => (
    <Box justifyContent="center" py={"20px"}>
    <Box direction="column" alignItems="center" fontWeight={100}>
      <Text fontWeight={600} my="20px" fontSize={"19px"}>
        {itemName}
      </Text>
      {itemName === "Clinical Notes" && 
        <div>
          <Text textAlign={"center"} fontSize={"17px"}>
            {givenName} {familyName}
          </Text>
          <Text textAlign={"center"} fontSize={"15px"}>{providerName}</Text>
          <Text textAlign={"center"} fontSize={"15px"}>{organisationName}</Text>
        </div>
      }
    </Box>
  </Box>
  ),
);

export const BaseDetailSectionBottom: React.FC<{
  bottomTitle: string;
  bottomText: string;
  doctorNote?: string | undefined;
  lab?: boolean;
  immun?: boolean;
}> = observer(({ bottomText, bottomTitle, lab, doctorNote, immun }) => {
  const [val, setVal] = useState("");
  const rtf = bottomText.startsWith("{");


  // Check if RTF format then convert to html, otherwise use the string given
  if (rtf) rtfToHtml.fromString(bottomText, (err: any, html: any) => { setVal(html) })
  else if (bottomText !== val) setVal(bottomText);

  return (
    <Box direction="column" mt="24px">
      <Text fontWeight={500} mt="8px" mb={2}>
        {bottomTitle}
      </Text>
      <Text fontWeight={100} css={{whiteSpace:"pre-wrap"}}>
        {rtf && <div dangerouslySetInnerHTML={{__html: val}} />}
        {!rtf && bottomText.split('\t').map(str => <p>{str}</p>)}
      </Text>
      {doctorNote &&
        <div>
          <Text fontWeight={500}>Internal Comment:</Text>
          <br />
          <Text fontWeight={300}>{doctorNote}</Text>
        </div>
      }
    </Box>
  )
});

export const BaseDetailSection: React.FC<{
  topText: string;
  isLabResult: boolean;
  providerName: string;
  dateTime: string;
  itemName: string;
  givenName: string;
  familyName: string;
  organisationName: string;
  bottomTitle: string;
  bottomText: string;
  providerImageUrl: string;
  doctorNote?: string;
  articleLinks?: string[] | null
  internalComment: string;
}> = observer(
  ({
    topText,
    isLabResult,
    providerName,
    dateTime,
    providerImageUrl,
    itemName,
    givenName,
    familyName,
    organisationName,
    bottomTitle,
    internalComment,
    articleLinks,
    doctorNote,
    bottomText,
  }) => {
    const verb = isLabResult ? 'From' : 'Performed by';
    const myHealthStore = useMyHealthStore();
    return (
      <BaseDetailBox>
        {isLabResult &&
          <Box direction="column" mt={"0px"} mb={"20px"}>
            {doctorNote && 
            <div>
              <Text fontWeight={100}>
                {doctorNote}
              </Text>
              {articleLinks && articleLinks.map((link) => {
                return (
                  <Text 
                    color={"#07A3C8"}
                    onClick={() => {
                      myHealthStore.setHealthNavigator({
                        link: link,
                        isOpen: true,
                      })
                    }}
                  >
                    {link}
                  </Text>
                )
              })}
            </div>
            }
            <Line my="10px" borderColor="#F3F3F3" />
            <Box direction="column">
              <Text
                display="flex"
                alignItems="center"
                justifyContent="end"
                fontSize="11px"
                flexGrow={0}
              >
                {providerName && 
                  <span style={{fontStyle: "italic"}}>
                    {verb} {providerName}
                  </span>
                }
                {providerImageUrl &&
                  <Image
                    ml="8px"
                    src={providerImageUrl}
                    height="24px"
                    width="24px"
                    display="inline-flex"
                    borderRadius="50%"
                  />
                }
              </Text>
            </Box>
          </Box>
        }
        {!isLabResult &&
          <Box direction="column" pt={2}>
            <Text fontSize={"17px"} fontWeight={100}>Vaccination Details</Text>
            <div style={{display: "flex", flexDirection: "row"}}>
              <Text
                display="flex"
                alignItems="start"
                justifyContent="end"
                direction="column"
                fontSize="11px"
                flexGrow={0}
              >
                <div style={{fontStyle: "italic", marginTop: "10px", marginBottom: "5px"}}>
                  {verb} {organisationName}
                </div>
              </Text>
                {providerImageUrl &&
                  <Image
                    ml="auto"
                    mt="auto"
                    src={providerImageUrl}
                    height="30px"
                    width="30px"
                    display="block"
                    borderRadius="50%"
                  />
                }
            </div>
            <Line my="10px" borderColor="#F3F3F3" />
          </Box>
        }

        <BaseDetailSectionHeader
          itemName={itemName}
          givenName={""}
          familyName={""}
          organisationName={""}
          providerName={""}
        />

        <BaseDetailSectionBottom
          bottomText={bottomText}
          doctorNote={internalComment}
          bottomTitle={bottomTitle}
          lab={isLabResult}
        />
      </BaseDetailBox>
    );
  },
);
