import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import practice from 'images/atPractice.png';
import { ReactComponent as Back } from 'svgs/leftRoundArrow.svg';
import { ReactComponent as Line } from 'svgs/blueUnderline.svg';
import BlueButton from 'components/DesktopComponents/BlueButton';
import Link from 'components/DesktopComponents/LinkButton';
import { go } from 'routes';
import { useAccountStore } from 'store/mobx/mobx';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 527px;
    background-color: white;
    box-shadow: 1px 1px 1px lightgray;
    align-items: center;
    padding: 2rem;
    border-radius: 7px;
    margin-top: 1rem;
    position: relative;
`
const Title = styled.div`
    font-size: 25px;
    color: #07A3C8;
    padding: 0rem 1rem 1rem;
`
const Image = styled.img`
    height: 158px;
    width: 252px;
    margin: 2rem;
`
const Text = styled.div`
    font-size: 15px;
    color: #6C6C6C;
    text-align: left;
    margin: 10px 0px;
    margin-bottom: 1rem;
`

interface IProps {
    goToPin: boolean;
    goBack: () => void;
}
const VerifyAtPracticeCard: React.FC<IProps> = observer(({goToPin, goBack}) => {
    const store = useAccountStore();

    return (
        <Container>
            <Back style={{position: "absolute", left: "30px", top: "25px", cursor: "pointer"}} onClick={goBack}/>
            <Title>Verify At Practice</Title>
            <Line />
            <Image src={practice} />
            <div style={{display: "flex", flexDirection: "column", alignItems: "left", width: "100%", padding: "0px 1rem", marginBottom: "1rem"}}>
                <Text>Vensa currently provides easy access to healthcare appointments.</Text>
                <Text>To access  your private health information, you must first verify and secure your identity with your practice.</Text>
                <Text>This vital step ensures that you have control of your health records.</Text>
            </div>
            <BlueButton 
                style={{
                    fontSize: "13px", 
                    borderRadius: "4px"
                }} 
                text={"Activate at Practice"} 
                onClick={() => {
                    goToPin ? (store.profile.SecurityPinCodeSetup ? go.identityPhoto() : go.identityPin()): go.choosePractice();
                }} />
            <Link text={"Learn More"} onClick={() => console.log("Clicked")} />
        </Container>
    );
});

export default VerifyAtPracticeCard;