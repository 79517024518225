import { isDesktop } from 'config';
import React, { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as Cross } from 'svgs/blueCross.svg';

const Container = !isDesktop() ? styled.div`
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    background-color: white;
    border-radius: 4px;
    padding: 2rem 1rem;
    overflow: scroll;
`
:
styled.div`    
    position: absolute;
    height: 700px;
    width: 400px;
    left: 50%;
    top: 50%;
    background-color: white;
    border-radius: 4px;
    padding: 2rem 1rem;
    overflow: visible;
    transform: translate(-50%, -50%);
`

const Title = styled.div`
    font-size: 21px;
    color: #07A3C8;
    text-align: center;
    margin-bottom: 2rem;
`
const FlexH = styled.div`
    display: flex;
    flex-direction: row;
    margin: 1rem 0rem;
`
const FlexV = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 0.8rem;
`
const Bullet = styled.div`
    height: 35px;
    width: 35px;
    text-align: center;
    background-color: #07A3C8;
    color: white;
    line-height: 2;
    flex-shrink: 0;
    border-radius: 50%;
`
const Point = styled.div`
    font-size: 16px;
    color: #6C6C6C;
    text-align: left;
    margin-left: 0rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
`
const Text = styled.div`
    font-size: 15px;
    color: #989898;
    text-align: left;
`

interface Props {
    hide: (e: any) => void;
}
const IdentityInfo: React.FC<Props> = ({hide}) => {
    const [state, setState] = useState(0);
    
    return (
        <Container>
            <Cross style={{position: "absolute", top: "1rem", right: "1rem", cursor: "pointer" }} onClick={hide}/>
            <Title>Security Features</Title>
            <FlexH>
                <Bullet>1</Bullet>
                <FlexV>
                    <Point>PIN</Point>
                    <Text>PIN code access enables quick and secure access to your account. Choose from either a 4 or 6 number PIN code.</Text>
                </FlexV>
            </FlexH>
            <br />
            <FlexH>
                <Bullet>2</Bullet>
                <FlexV>
                    <Point>Protect your privacy</Point>
                    <Text>A PIN code is safer to identify you than common passwords, so a two-factor security process allows you to have an extra security for those sensitive areas of your records.</Text>
                </FlexV>
            </FlexH>
            <br />
            <FlexH>
                <Bullet>3</Bullet>
                <FlexV>
                    <Point>Get notified when someone attempts to access your account without your permission</Point>
                    <Text>If someone attempts to access your account without your permission (by guessing your PIN code or password) you will receive an instant notification. You may then be advised to change your PIN or password. You can always recover your account using your secondary email.</Text>
                </FlexV>
            </FlexH>
        </Container>
    )
}

export default IdentityInfo