import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button } from 'components/vensaComponents';
import { NotificationBox } from './NotificationBox';
import { go } from 'routes/index';
import { useDispatch } from 'react-redux';
import { actions } from '../../components/UIContainer/MobileUI/MainShell/actions/profileActions';
export const NoIdentityNotificationBox: React.FC = observer(() => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(actions.setIsFromMyHealth(true));
    go.identity();
  };

  return (
    <NotificationBox
      title="Take control of your health data"
      content="Verify your identity and get access to your health records. It's fast and free."
      controlArea={
        <Box width={1} justifyContent="end" mt={2}>
          <Button onClick={onClick}>Let's start</Button>
        </Box>
      }
    />
  );
});
