import React from 'react';

import styled from 'styled-components';
import styles from './privacyInfoPopUp.module.css';
import Button from '../PrivacyInfoPopUpButton';
import Logo from '../../../svgs/privacyPopUpLogo.svg'
import { ReactComponent as DesktopLogo } from 'svgs/privacyPopUpLogo.svg';
import { isDesktop } from 'config';

const Container = styled.div`    
    position: absolute;
    height: 700px;
    width: 400px;
    left: 50%;
    top: 50%;
    background-color: white;
    border-radius: 4px;
    padding: 2rem 1rem;
    overflow: visible;
    transform: translate(-50%, -50%);
`
const Title = styled.div`
    width: 100%;
    text-align: center;
    color: #07A3C8;
    font-size: 21px;
`
const Text = styled.div`
    font-size: 17px;
    color: #585858;
    text-align: left;
    width: 100%;
    margin: 1rem 0;
`

class PrivacyInfoPopUp extends React.Component {
    constructor(props) {
        super(props);

        this.state = { moreInfo: false }
    }

    render() {
        return isDesktop() ? 
        (
            <Container>
                <DesktopLogo style={{ display: "block", margin: "0 auto 0.5rem" }}/>
                <Title>Privacy</Title>
                <Text>Privacy is human right. The Vensa system is designed to:</Text>
                <br />
                <Text>1. Collect your data and store it on your behalf. We will never share it with anyone without your control.</Text>
                <Text>2. Provide transparency and control over your information.</Text>
                <Text>3. Show you the above icon every time an app or feature asks to use your personal information.</Text>
                <Text>4. Keep your information stored securely with the highest standard.</Text>
                <Text>5. Ensure privacy and security best practices are maintained</Text>
                <Button noFade={this.state.moreInfo} onClick={(e) => this.props.closePopUp(e)}/>
            </Container>
        )
        :
        (
            <div className={styles.container}>
                <div className={styles.inner}>
                    <img src={Logo} alt="logo" className={styles.logo} />
                    <div className={styles.title}>Privacy</div>
                    <div className={styles.text}>
                        <div style={{marginBottom: "0%"}}>
                        Vensa believes privacy is a human right, so our Vensa system are designed to:<br /><br />
                        1. Collect your data and store it on your behalf. We will never share it with anyone without your control.<br /><br />
                        2. Provide transparency and control over your information.<br /><br />
                        {/* eslint-disable-next-line */}
                        3. Show you the above icon every time an app or feature asks to use your personal information. <br /><br /> 
                        {!this.state.moreInfo && <div className={styles.placeholder} />}
                        <span role="button" onClick={() => { this.setState({ moreInfo: true }); }} className={this.state.moreInfo ? styles.displayNone : styles.link}> More Info</span>
                        </div>
                        <p className={this.state.moreInfo ? styles.extraText : styles.displayNone}>
                            4. Keep your information stored securely with the highest standard. <br /><br />
                            5. Ensure privacy and security best practices are maintained<br /><br />
                            <span role="button" href={"!#"} onClick={() => { this.setState({ moreInfo: false }); }} className={styles.linkLess}>Less Info</span>
                        </p>
                    </div>
                    <Button noFade={this.state.moreInfo} onClick={(e) => this.props.closePopUp(e)}/>
                </div>
            </div>
        )
    }
}

export default PrivacyInfoPopUp;