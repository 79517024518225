import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Observer } from 'mobx-react-lite';

import { actions as identityActions } from '../actions/actions';

import styles from './addPractice.module.css';
import checkIcon from '../../svgs/checkBlueWhite.svg';
import progressBar from '../../svgs/progressLvl1.svg';
import UIContainer from '../../components/UIContainer';
import ProgressCard from '../components/ProgressCard';
import CtaMenuButton from '../../components/CtaMenuButton';
import { ReactComponent as plus } from '../../svgs/plus.svg';
import PracticeCard from '../components/PracticeCard';
//import PracticeCard from '../DesktopIdentityPage/components/PracticeCard';
import { actions } from '../../PracticesPage/actions/actions.js';
import { actions as searchActions } from '../../PracticesPage/PracticeSearch/actions/actions.js';
import { go } from '../../routes';
import { actions as notificationActions } from 'components/UIContainer/MobileUI/MainShell/actions/actions';
import { goBack } from 'routes/utils';
import { useComponentDidMount } from 'libs/newHttp';
import { useIdentityStore, useAccountStore } from 'store/mobx/mobx';


const AddPractice = ({token, getAllPractices, setNotification, isFetching, selectedPractice, setPractice}) => {
    const identityStore = useIdentityStore();
    const accountStore = useAccountStore();
    const onClick = () => {
        go.searchPractice(1, true);
        
    }

    useComponentDidMount(() => {
        //this.props.getPatientPractices(token);
        accountStore.getIdentityRequestsStatus(() => {});
        getAllPractices();
        setNotification({ isOpen: false, index: 2 })
    })
    let classnames = styles.container;
 
    return(
        <UIContainer heading={"Step 1: Choose Practice"} isFetching={isFetching} goBackFn={go.identity} isWhiteNotificationOpen={false}>
            <div>
            <ProgressCard 
                text={"The first step will help Vensa verify you to ensure fast verification and retrieval of your health data."}
                title={"Your Medical Centres"}
                icon={checkIcon}
                progressBar={progressBar}
                padding={false}
            />
            <Observer>
                {() =>
                    <div className={classnames} style={{overflow: "visible"}}> 
                        {accountStore.identityRequests.map((item) => {
                            let status = 0;
                            if (item.IsOnline && item.IsUsingVensa) { status = 2; }
                            else if (!item.IsOnline && item.IsUsingVensa) { status = 1; }
                            else if (!item.IsOnline && !item.IsUsingVensa) { status = 0; }
                            return <PracticeCard 
                                key={item.PracticeId}
                                practice={item}
                                OrganisationId={item.PracticeId}
                                practiceName={item.OrganisationName}
                                practiceAddress={item.Address}
                                practicePic={item.PictureUrl}
                                status={status}
                                phone={item.Phone}
                                item={item}
                                dots={false}
                                practiceOnClick={() => {
                                    setPractice(item);
                                    identityStore.setPracticeSingle(item);
                                    if (accountStore.profile.SecurityPinCodeSetup) {
                                        go.identityPhoto();
                                    } else go.identityPin();
                                    //this.props.isMobileVerified ? go.identityPin() : go.identityMobile();
                                    //is.props.isPinSetup ? go.identityPhoto() : go.identityPin();
                                }}
                            />
                        })}
                        <CtaMenuButton
                            text={accountStore.identityRequests.length > 0 ? " Change Practice" : "  Add Practice"}
                            icon={plus} 
                            onClick={onClick}
                            iconStyles={styles.buttonIcon}
                            textIconStyles={styles.textAdd} 
                        />
                    </div>
                }
            </Observer>
            </div>
        </UIContainer>
    );
    
}

const mapStateToProps = state => ({
    token: state.loginReducer.token,
    patientPracticeList: state.practiceListingReducer.practices,
    isFetching: state.practiceListingReducer.isFetching ||
        state.practiceUnlinkingReducer.isFetching ||
        state.practiceLinkingReducer.isFetching ||
        state.practiceSelectionReducer.isFetching ||
        state.practiceSelectionReducer.isSearching,
    totalPracticeList: state.practiceSelectionReducer.practices,
    isPinSetup: state.verifyReducer.isPinCodeSetup,
    isMobileVerified: state.verifyReducer.isMobileVerified,
    selectedPractice: state.identityReducer.selectedPractice
});

const mapDispatchToProps = dispatch => ({
    setPractice: bindActionCreators(identityActions.setPractice, dispatch),
    getPatientPractices: bindActionCreators(actions.getPracticeListings, dispatch),
    getAllPractices: bindActionCreators(searchActions.getAllPractices, dispatch),
    setNotification: bindActionCreators(notificationActions.setWhiteBoxNotification, dispatch)
});

export default connect(
    mapStateToProps, 
    mapDispatchToProps,
)(AddPractice);
