import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';

import Switcheroo from '../../../components/Switcheroo/index';
import { ReactComponent as Arrow } from '../../../svgs/rightarrow2.svg';
// eslint-disable max-len
import PracticeSearch from '../../../PracticesPage/PracticeSearch/index';
import AccountVerificationPage from '../../AccountVerificationPage/index';
import ScriptMenuContainer from '../../../vScript/RequestScriptMobile/RequestScriptMobile';
import { actions } from '../../../vScript/actions/searchActions';
import { actions as headerActions } from '../../../components/UIContainer/actions/headerActions';
import { actions as scriptActions } from '../../../vScript/actions/menuActions';
import { actions as depActions } from '../../../DependantsPage/actions/actions';
import { actions as appActions } from '../../../PracticesPage/PracticeSearch/actions/actions';
// eslint-enable max-len
import UserActions from './UserActionsMenu/index';
import PracticeList from './PracticeList/index';
import NotWithVensa from './NotWithVensa/index';
import { go } from '../../../routes';
import { clinicPropType } from '../../../PracticesPage/propTypes/index';
import styles from './menuPages.module.css';
import { actions as notificationActions } from 'components/UIContainer/MobileUI/MainShell/actions/actions';
import { getToken } from 'libs/newHttp';

class MenuPages extends Component {
  static propTypes = {
    practices: PropTypes.arrayOf(clinicPropType).isRequired,
    clearPracticeSelection: PropTypes.func.isRequired,
    getAllPractices: PropTypes.func.isRequired,
    fetchScriptPractices: PropTypes.func.isRequired,
    fetchConnectedPractices: PropTypes.func.isRequired,
    fetchDependants: PropTypes.func.isRequired,
    overrideBackArrow: PropTypes.func.isRequired,
    clearBackArrow: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    appointmentOnly: PropTypes.bool,
    isMobileVerified: PropTypes.bool,
  };
  state = {
    showError: false,
    showAccountVerification: false,
  };
  componentDidMount() {
    const token = getToken();
    this.props.clearPracticeSelection();
    this.props.getAllPractices();
    this.props.fetchConnectedPractices(token);
    if (this.props.appointmentOnly) {
      this.handleSwitcheroo(3, 1)();
    } else {
      this.props.fetchScriptPractices(token);
      this.props.fetchDependants(token);
    }
  }
  handleSwitcheroo = (next, current) => () => {
    if (this.switcheroo) {
      // opens mobile verification if user has not yet verified
      if (!this.props.mobileVerified && next === 5 && current !== 2) {
        this.props.overrideBackArrow(this.handleSwitcheroo(1, 2));
        this.switcheroo.setItemToShow(2, current);
      }
      // skips straight to 'other practice' page if user has no linked practices
      else if (next === 3 && this.props.practices.length < 1) {
        if (current === 1) this.switcheroo.setItemToShow(4, current);
        else if (current === 4) this.switcheroo.setItemToShow(1, current);
      } else this.switcheroo.setItemToShow(next, current);
      // resets arrow fn when closing mobile verification
      if (current === 2) this.props.clearBackArrow();
    }
  };
  toggleErrorPopUp = () => {
    this.setState({
      showError: !this.state.showError,
    });
  };
  handleNotWithVensa = practice => {
    this.toggleErrorPopUp();
    this.setState({
      practiceNotWithVensa: practice,
    });
  };
  goToBooking = e => {
    go.practiceBooking(e.UrlName || e);
  };
  render() {
    return (
      <CSSTransitionGroup
        component="div"
        className={styles.container}
        transitionName={{
          leave: styles.leave,
          leaveActive: styles.leaveActive,
          enter: styles.enter,
          enterActive: styles.enterActive,
        }}
        transitionLeaveTimeout={300}
        transitionEnterTimeout={300}
      >
        <Switcheroo
          ref={c => {
            this.switcheroo = c;
          }}
          firstItem={
            <UserActions
              appointmentsFn={this.handleSwitcheroo(3, 1)}
              scriptFn={this.handleSwitcheroo(5, 1)}
              dependantFn={go.addDependant}
              practiceListingFn={go.addPractice}
              phrFn={() => go.home(3)}
            />
          }
          secondItem={
            <div className={styles.verifyContainer}>
              <AccountVerificationPage
                onVerifySuccess={this.handleSwitcheroo(5, 2)}
                cancel={this.handleSwitcheroo(1, 2)}
              />
            </div>
          }
          thirdItem={
            <PracticeList
              showArrow={!this.props.appointmentOnly}
              arrowClick={this.handleSwitcheroo(1, 3)}
              practices={this.props.practices}
              goToBooking={this.goToBooking}
              otherPractice={this.handleSwitcheroo(4, 3)}
            />
          }
          fourthItem={
            <div className={styles.autoCompleteContainer}>
              {(this.props.practices.length > 0 ||
                !this.props.appointmentOnly) && (
                <Arrow
                  className={styles.arrow}
                  onClick={this.handleSwitcheroo(3, 4)}
                />
              )}
              <div>Choose your practice:</div>
              <PracticeSearch
                successAction={this.goToBooking}
                errorAction={this.handleNotWithVensa}
                condition="IsUsingVensa"
              />
            </div>
          }
          fifthItem={
            <div className={styles.scriptContainer}>
              <ScriptMenuContainer arrowFn={this.handleSwitcheroo(1, 5)} />
            </div>
          }
        />
        {this.state.showError && (
          <NotWithVensa
            practice={this.state.practiceNotWithVensa}
            arrowClick={this.toggleErrorPopUp}
            serviceAction="book an appointment "
            activationService="Vensa Online Booking System"
          />
        )}
      </CSSTransitionGroup>
    );
  }
}

const mapStateToProps = state => ({
  token: state.loginReducer.token,
  practices: state.practiceListingReducer.practices,
  isFetching: state.practiceListingReducer.isFetching,
});

// noinspection JSAnnotator
const mapDispatchToProps = dispatch => ({
  /* eslint-disable max-len */
  fetchScriptPractices: bindActionCreators(actions.fetchAllPractices, dispatch),
  fetchConnectedPractices: bindActionCreators(
    scriptActions.fetchConnectedPractices,
    dispatch,
  ),
  getAllPractices: bindActionCreators(appActions.getAllPractices, dispatch),
  clearPracticeSelection: bindActionCreators(
    appActions.clearPracticeSelection,
    dispatch,
  ),
  fetchDependants: bindActionCreators(depActions.fetchDependants, dispatch),
  overrideBackArrow: bindActionCreators(
    headerActions.overrideBackArrow,
    dispatch,
  ),
  clearBackArrow: bindActionCreators(headerActions.clearBackArrow, dispatch),
  setNotification: bindActionCreators(notificationActions.setWhiteBoxNotification, dispatch)
  /* eslint-enable max-len */
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MenuPages);
