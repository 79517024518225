import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { isDesktop } from 'config';
import { ReactComponent as Tick } from 'svgs/whiteTick2.svg';
import { ReactComponent as TickDesktop } from 'svgs/tick.svg';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 15px;
    margin 1rem 0rem;
`
const Title = styled.div`
    color: white;
    margin-bottom: 0.5rem;
`
const Item = styled.div`
    display: flex;
    flex-direction: row;
`
const Image = styled.div`
    width: 16px;
    height: 15px;
    margin: 0px 5px;
`
const Text = styled.div`
    width: 100%;
    color: white;
    font-size: 15px;
`
const PasswordPolicy = ({ password }) => {
    const [passMinLength, setPassMinLength] = useState(false);
    const [containsCapital, setContainsCapital] = useState(false);
    const [containsLower, setContainsLower] = useState(false);
    const [symbolOrNum, setSymbolOrNum] = useState(false);

    useEffect(() => {
        checkPassword();
    }, [password])

    const checkPassword = () => {
        if (password.length === 0) { return }
        setPassMinLength(password.length >= 8);
        setContainsCapital(hasUpperCase(password));
        setContainsLower(hasLowerCase(password));
        setSymbolOrNum(hasSymbolOrNum(password));
    }
    const hasUpperCase = (str) => {
        let result = str
          .split('')
          .map(letter => /[A-Z]/.test(letter))
          .reduce((a, b) => a + b);
        return result > 0;
    }
    const hasLowerCase = (str) => {
        let result = str
            .split('')
            .map(letter => /[a-z]/.test(letter))
            .reduce((a, b) => a + b);
        return result > 0;
    }
    const hasSymbolOrNum = (str) => {
        let result = str
            .split('')
            .map(letter => /[0-9]/.test(letter))
            .reduce((a, b) => a + b);
        let sResult = str
            .split('')
            .map(letter => /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(letter))
            .reduce((a, b) => a + b);
        return result + sResult > 0;
    }
    const tickStyleDesktop = { width: "15px", fill: "#44D375" };

    return (
        <Container>
            <Title style={{ color: isDesktop() ? "#696969" : "white" }}>The password criteria for your security:</Title>
            <Item>
                <Image>
                    {passMinLength ? isDesktop() ? <TickDesktop style={tickStyleDesktop} /> : <Tick style={{ width: "15px" }}/> : <div></div>}
                </Image>
                <Text style={{ opacity: passMinLength ? "1" : "0.5", color: isDesktop() ? "#696969" : "white" }}>At least 8 characters long</Text>
            </Item>
            <Item>
                <Image>
                    {containsCapital ? isDesktop() ? <TickDesktop style={tickStyleDesktop} /> : <Tick style={{ width: "15px" }} /> : <div></div>}
                </Image>
                <Text style={{ opacity: containsCapital ? "1" : "0.5", color: isDesktop() ? "#696969" : "white" }}>Contains at least 1 capital letter</Text>
            </Item>
            <Item>
                <Image>
                    {containsLower ? isDesktop() ? <TickDesktop style={tickStyleDesktop} /> : <Tick style={{ width: "15px" }} /> : <div></div>}
                </Image>
                <Text style={{ opacity: containsLower ? "1" : "0.5", color: isDesktop() ? "#696969" : "white" }}>Contains at least 1 lower case letter</Text>
            </Item>
            <Item>
                <Image>
                    {symbolOrNum ? isDesktop() ? <TickDesktop style={tickStyleDesktop} /> : <Tick style={{ width: "15px" }} /> : <div></div>}
                </Image>
                <Text style={{ opacity: symbolOrNum ? "1" : "0.5", color: isDesktop() ? "#696969" : "white" }}>Contains a number or special character</Text>
            </Item>
        </Container>
    )
}

export default PasswordPolicy;