import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from 'components/vensaComponents';
import { IHealthTransaction } from '../../../../store/mobx/paymentStore/paymentStore.type';

const Item: React.FC<{ text: string }> = observer(({ text }) => (
  <Box
    bg="grey1"
    fontSize="14px"
    color="#6C6C6C"
    p={3}
    width={1}
    alignItems="center"
    gap="12px"
  >
    <span style={{ color: '#16A8CB' }}>&#10003;</span>
    {text}
  </Box>
));

export const MedicineList: React.FC<Pick<
  IHealthTransaction,
  'Medications'
>> = observer(({ Medications }) => (
  <Box direction="column" alignItems="center" gap="10px">
    {Object.entries(Medications).map(([key, value]) => (
      <Item key={key} text={value} />
    ))}
  </Box>
));
