import React from 'react';
import { WizardValues } from './types';
import WizardContext from './WizardContext';

const useWizard = () => {
    const context = React.useContext(WizardContext);
    if(!context && process.env.NODE_ENV === 'development'){
        throw Error('Set up your wizard with steps');
    } else {
        return context as WizardValues;
    }
};

export default useWizard;
