import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { ProtectedRoute, push } from '../utils';
import { isDesktop } from '../../config';
import PracticesPage from '../../PracticesPage';
import AddPractice from '../../PracticesPage/AddPractice';

const go = {
  practices: () => push('/practices'),
  addPractice: () => push('/add-practice'),
  practicePreview: id => push(`/practice-preview/${id}`),
};

const addRoute = !isDesktop() ? (
  <ProtectedRoute
    key="/add-practice"
    path="/add-practice"
    component={AddPractice}
  />
) : (
  <Redirect from="/add-practice" key="redirect to /practices" to="/practices" />
);

const PracticesRoutes = [
  <ProtectedRoute
    key="/practices"
    path="/practices"
    component={PracticesPage}
  />,
  addRoute,
  <ProtectedRoute
    key="/practice-preview/:id"
    path="/practice-preview/:id"
    component={lazy(() =>
      import(
        /* webpackChunkName: "PracticePreview" */ '../../PracticesPage/PracticePreview'
      ),
    )}
  />,
];

export default PracticesRoutes;
export { go };
