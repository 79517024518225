import React from 'react';
import styles from './ReasonSection.module.scss';

type Props = {
    sn: string;
    title: string;
    content: string;
}

const ReasonSection = ({
    sn,
    title,
    content
}: Props) => {
  return (
    <div className={styles.container}>
        <span className={styles.sn}>{sn}</span>
        <div className={styles.textContainer}>
            <span>{title}</span>
            <p>{content}</p>
        </div>
        
    </div>
  )
}

export default ReasonSection;