import React from 'react';
import { isDesktop } from '../../config';
// import { Route } from 'react-router';
import { ProtectedRoute, push } from '../utils';
import ImmunisationPage from '../../MyHealthPage/subRoutes/ImmunisationPage/ImmunisationPage';
import LabResultPage from '../../MyHealthPage/subRoutes/LabResultPage/LabResultPage';
import ClinicNotePage from '../../MyHealthPage/subRoutes/ClinicNotePage/ClinicNotePage';

const PATHS = {
  IMMUNISATION: '/immunisation/:id',
  LAB_RESULT: '/lab-result/:id',
  CLINIC_NOTE: '/clinic-note/:id',
};

const go = {
  goImmunisation: immunisationId => push(`/immunisation/${immunisationId}`),
  goLabResult: (labResultId, patientId, whenReceived) => push(`/lab-result/${labResultId}`),
  goClinicNote: clinicNoteId => push(`/clinic-note/${clinicNoteId}`),
};

// change all to ProtectedRoute once finished
const MyHealthRoutes = !isDesktop() && [
  <ProtectedRoute
    exact
    key={PATHS.IMMUNISATION}
    path={PATHS.IMMUNISATION}
    component={ImmunisationPage}
  />,
  <ProtectedRoute
    exact
    key={PATHS.LAB_RESULT}
    path={PATHS.LAB_RESULT}
    component={LabResultPage}
  />,
  <ProtectedRoute
    exact
    key={PATHS.CLINIC_NOTE}
    path={PATHS.CLINIC_NOTE}
    component={ClinicNotePage}
  />,
];

export default MyHealthRoutes;
export { go };
