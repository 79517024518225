import Breadcrumbs from 'components/shared/desktop/Breadcrumbs';
import SideBar from 'components/shared/desktop/SideBar';
import { CrumbsField, MenuField } from 'pages/desktop/types';
import React, { ComponentType } from 'react';

const withBreadcrumbs = <P extends Object>(Component: ComponentType<P>): React.FC<P & CrumbsField> => {
    const BreadCrumbsComponent: React.FC<P & CrumbsField> = ({crumbs, ...rest}: P & CrumbsField) => {
        const props = { ...rest } as unknown as P;
        return (
        <div style={{display:'flex', flexDirection:'column',height:'100%', flexGrow: 1, overflowY: 'auto', overflowX: 'hidden'}}>
            {crumbs && crumbs.length > 0 && <Breadcrumbs crumbs={crumbs} />}
            <div style={{width: '100%', display: 'flex', flexFlow: 'wrap', alignItems:'center', justifyContent: 'center', paddingTop: '3.75rem'}}>
                <Component {...props}/>
            </div>
            
        </div>
    )};
    return BreadCrumbsComponent;
};

const withMenu = <P extends Object>(Component: ComponentType<P>): React.FC<P & MenuField> => {
    const MenuComponent: React.FC<P & MenuField> = ({idKey, ...rest}: P & MenuField) => {
        const props = {...rest} as unknown as P;
        return (
            <div style={{display:'flex', height:'100%'}}>
                <SideBar value={idKey} />
                <Component {...props} />
            </div>
        );
    };
    return MenuComponent;
}

const withDesktop = <P extends Object>(Component: ComponentType<P>): React.FC<P & CrumbsField & MenuField> => withMenu(withBreadcrumbs(Component));
export {
    withDesktop
};