import React from 'react';
import UIContainer from 'components/UIContainer';
import { observer } from 'mobx-react-lite';
import { Box, Image, Text } from 'components/vensaComponents';
import voucher from 'svgs/voucher.svg';

const VouchersPage: React.FC = observer(() => {
  return (
    <UIContainer heading="Vouchers" isFetching={false}>
      <Box
        bg="white"
        width={1}
        borderRadius="4px"
        direction="column"
        py={6}
        px={5}
        color="primary"
        alignItems="center"
        gap="40px"
      >
        <Image src={voucher} mr="8px" height="60px" />
        <Text fontWeight={700} fontSize="22px">
          You don't have any vouchers
        </Text>
        <Text textAlign="center">
          Companies, insurance and emergency departments can send you vouchers
          to pay for your healthcare.
        </Text>
      </Box>
    </UIContainer>
  );
});

export default VouchersPage;
