import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as headerActions } from '../components/UIContainer/actions/headerActions';
import { actions as editActions } from './actions/actions';
import UIContainer from '../components/UIContainer';
import BasicInfoForm from '../components/BasicInfoForm';
import styles from './userAccount.module.css';
import { go } from '../routes';
import { isDesktop } from '../config';
import IdentityLabel from '../Identity/components/IdentityLabel';
import ImageUploader from 'components/ImageUploader';
import { useAccountStore } from 'store/mobx/mobx';
import { Observer } from 'mobx-react-lite';
import { getToken } from 'libs/newHttp';

const UserAccount = ({...props}) => {
	const token = getToken();
	let form = useRef(); 

	const store = useAccountStore();

	// useComponentDidMount(() => {
	// 	store.getProfile();
	// })
	// useEffect(() => {
	// 	store.getProfile();
	// })

	const handleDeactivate = () =>
		props.goToDeactivateAccount();

	const handleChangePassword = () =>
		props.goToChangePassword();

	const handleSave = e => {
		if (form) form.handleSubmit(e);
	};

	const verifiedOnClick = () => {
		if (store.profile.MobileVerified) { return; }
		go.accountVerification("true");
	}
	const capitalise = (str) => {
		return str ? str[0].toUpperCase() + str.slice(1) : ""
	}

    return (
		<Observer>
			{() => 
				<UIContainer hideArrow={true} showCross={true} isFetching={props.isFetching} heading={capitalise(store.profile.FirstName) + " " + capitalise(store.profile.LastName)} saveFn={handleSave} goBackFn={() => go.home()}>
				<div>
				<div className={styles.container} style={{padding: "0.882rem"}}>
					
					<ImageUploader avatar={store.profile.AvatarUrl} onSubmit={props.addAvatar(token)} />
					
					<div>
						<IdentityLabel />

						<BasicInfoForm
							ref={c => {
								form = c;
							}}
							firstName={store.profile.FirstName}
							lastName={store.profile.LastName}
							preferredName={store.profile.PreferredName}
							birthday={store.profile.DateOfBirth}
							phone={store.profile.Mobile}
							email={store.profile.Email}
							phoneRequired
							disableEmail
							emailRequired
							hideButtons={!isDesktop()}
							buttonText="SAVE"
							submit={props.changeProfile}
							className={styles.form}
							itemClassName={styles.field}
							mobileVerified={store.profile.MobileVerified}
							emailVerified={store.profile.EmailVerified}
							identityVerified={store.profile.IdentityVerified}
						/>

						<div className={styles.buttonContainer}>
							<button
								className={styles.button}
								onClick={handleChangePassword}
							>
								Change Password
							</button>
		{/*}              		<button className={styles.button} onClick={handleDeactivate}>
								Deactivate Account
						</button>*/}
						</div>
					</div>
				</div>
				</div>
			</UIContainer> 
			}
		</Observer>     
    );
}

const mapStateToProps = state => ({
  token: state.loginReducer.token,
  isFetching:
    state.profileReducer.isFetching ||
    state.editProfileReducer.isFetching ||
    state.editProfileReducer.isProcessingFile,
  changeProfileSuccess: state.editProfileReducer.success,
  avatarSuccess: state.editProfileReducer.avatarSuccess,
  avatarError: state.editProfileReducer.avatarError,
  firstName: state.editProfileReducer.firstName,
  lastName: state.editProfileReducer.lastName,
  preferredName: state.editProfileReducer.preferredName,
  dateOfBirth: state.editProfileReducer.dateOfBirth,
  mobile: state.editProfileReducer.mobile,
  email: state.editProfileReducer.email,
  avatar: state.profileReducer.avatar,
  isMobileVerified: state.verifyReducer.isMobileVerified,
  verificationStatus: state.verifyReducer.verificationStatus,
  verifyOrgName: state.verifyReducer.verifyOrgName,
  verificationMethod: state.verifyReducer.verificationMethod,
});
const mapDispatchToProps = dispatch => ({
  addCustomIcon: bindActionCreators(headerActions.addCustomIcon, dispatch),
  clearCustomIcon: bindActionCreators(headerActions.clearCustomIcon, dispatch),
  setSideHeaderItem: bindActionCreators(
    headerActions.setSideHeaderItem,
    dispatch,
  ),
  clearEditProfile: bindActionCreators(editActions.clearEditProfile, dispatch),
  changeProfile: bindActionCreators(editActions.changeProfile, dispatch),
  addAvatar: token => file =>
    bindActionCreators(editActions.addAvatar, dispatch)(file, token),
  // eslint-disable-next-line max-len
  goToChangePassword: () => go.changePassword(),
  // eslint-disable-next-line max-len
  goToDeactivateAccount: () => go.deactivate(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserAccount);
export { UserAccount };
