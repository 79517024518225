import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import is from 'is_js';

import styles from './tnC.module.css';
import { ReactComponent as Tick } from '../../svgs/tick.svg';
import TermsandConditions from './TermsandConditions';
import PrivacyPolicy from './PrivacyPolicy';

class TnC extends Component {
  static propTypes = {
    className: PropTypes.string,
  };
  state = {
    showTnC: false,
    showPrivacyPolicy: false,
  };
  handleTnC = () => {
    this.setState({ ...this.state, showTnC: !this.state.showTnC });
  };
  handlePrivacyPolicy = () => {
    this.setState({
      ...this.state,
      showPrivacyPolicy: !this.state.showPrivacyPolicy,
    });
  };
  render() {
    return (
      <div
        className={classnames(styles.container, this.props.className, {
          [styles.error]: this.props.error,
        })}
      >
        <div className={styles.checkbox} onClick={this.props.onClick}>
          {this.props.checked ? <Tick className={styles.tick} /> : ''}
        </div>
        <span className={styles.text}>
          I have read and agree to the{' '}
          <span className={styles.underline} onClick={this.handleTnC}>
            terms and conditions
          </span>{' '}
          &{' '}
          <span className={styles.underline} onClick={this.handlePrivacyPolicy}>
            privacy policy
          </span>
        </span>
        {this.state.showTnC && (
          <TermsandConditions isIE={is.ie()} handleClick={this.handleTnC} />
        )}
        {this.state.showPrivacyPolicy && (
          <PrivacyPolicy isIE={is.ie()} handleClick={this.handlePrivacyPolicy} />
        )}
      </div>
    );
  }
}

export default TnC;
