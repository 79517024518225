import { call, takeEvery, put } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';
import HttpLib from '../../libs/Http';

const ParserHttp = HttpLib(() => {}, () => {});

const api = (orgId, consumerId, token, scriptId) =>
  ParserHttp.get(`consumer/prescription/${orgId}/${consumerId}/medications${scriptId ? "?prescriptionId="+ scriptId : ""}`, {
    token,
  }).then(({ body }) => body);

function* getMedications({ payload: { orgId, consumerId, token, scriptId }}) {
  try {
    const data = yield call(api, orgId, consumerId, token, scriptId);
    yield put(actions.getMedicationListSuccess(data));
  } catch (err) {
    yield put(actions.getMedicationListFailure(err));
  }
}

function* getMedicationsSaga() {
  yield takeEvery(actionTypes.getMedicationList, getMedications);
}

export { getMedicationsSaga }