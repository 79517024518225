import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from '../actions/actions';
import { actions as profileActions } from '../../components/UIContainer/MobileUI/MainShell/actions/profileActions'
import { actions as addPharmActions } from '../PickUpLocation/AddPharmacy/actions/actions';
import { actions as menuActions } from '../actions/menuActions';
import { actions as searchActions } from '../actions/searchActions';
import { actions as listActions } from '../../PracticesPage/PracticeSearch/actions/actions';
import { actions as dependantActions } from '../../DependantsPage/actions/actions';
import List from "vScript/RequestScriptMobile/List";
import styles from 'vScript/RequestScriptMobile/requestScriptMobile.module.css';
import { go } from '../../routes';
import { CSSTransitionGroup } from 'react-transition-group';
import classnames from 'classnames';
import { profilePropType } from 'components/UIContainer/MobileUI/MainShell/propTypes';
import { dependantPropType } from 'DependantsPage/propTypes';
import { ReactComponent as Spinner } from '../../svgs/spinner.svg';

interface Props {
    getProfile();
    fixed: any;
    isFetchingDependants: boolean,
    dependants: any;
    fetchDependants: any,
    patients: Array<typeof profilePropType | typeof dependantPropType>,
    token: string,
    setInfo: Function,
    userId: string,
    orgId: string,
    practice: any,
    userMobile: string,
    doctors: any,
    isFetchingDoctors: boolean,
    fetchDoctors: Function,
}

const PatientSelectionMobile: React.FC<Props> = (props) => {

    const [fetchedDependants, setFetchedDependants] = useState(false);
    const [fetchedDoctors, setFetchedDoctors] = useState(false)

    useEffect(() => {
        if (!props.orgId) go.selectPractice();
        if (!props.userId) props.getProfile();
        if (!fetchedDoctors && (!props.doctors || (props.doctors.length === 0 && !props.isFetchingDoctors))) {
            props.fetchDoctors(props.orgId, props.token)
            setFetchedDoctors(true)
        }
    }, [fetchedDoctors]);

    useEffect(() => {
        if (!fetchedDependants && (!props.dependants || (props.dependants.length === 0 && !props.isFetchingDependants))) {
            props.fetchDependants(props.token)
            setFetchedDependants(true)
        }
    }, [fetchedDependants, props, props.dependants, props.isFetchingDependants])

    const patientIsVerified = (patient) => {
        if (!patient) return false;
        if (patient.IdentityStatus && patient.IdentityStatus.StatusLabel) {
            return patient.IdentityStatus.StatusLabel.toLowerCase() === 'verified';
        }
        return patient.IdentityVerified;
    }
    const getAllowedPatients = () => {
        if (!props.practice) return props.patients;
        const onlyVerifiedPatients = !props.practice.UnverifiedPatientsAllowed;
        const allowedPatients = props.patients.filter(p => {
            if (onlyVerifiedPatients) {
                return patientIsVerified(p);
            }
            return true;
        });
        //Catering for the case when a master account is not verified, and has dependants
        if (allowedPatients.length === 0 && props.patients.length > 0){
            return props.patients.filter(p => {
            return Object.hasOwn(p, 'IdentityVerified')
            });
            
        }
        return allowedPatients;
    }

    return (
        <div className={styles.scriptContainer}>
            <CSSTransitionGroup
                component="div"
                className={classnames(styles.container, {
                    [styles.fixed]: props.fixed,
                })}
                transitionName={{
                    leave: styles.leave,
                    enter: styles.enter,
                }}
                transitionLeaveTimeout={300}
                transitionEnterTimeout={300}
            >
                {props.isFetchingDependants && (
                    <div className={styles.spinnerContainer}>
                        <Spinner className={styles.spinner} />
                    </div>
                )}
                <div className={styles.listContainer}>
                    <List
                        heading="Who for?"
                        options={getAllowedPatients()}
                        optionOnClick={o => {
                            const option = o;
                            if (!o.Mobile) option.Mobile = props.userMobile;
                            props.setInfo('patient', option);
                            go.selectDoctor();
                        }}
                        arrowFn={() => go.selectPractice()}
                    />
                </div>
            </CSSTransitionGroup>
        </div>
    );
}

const mapStateToProps = state => ({
    token: state.loginReducer.token,
    patients: [
        state.profileReducer.profile,
        ...state.fetchDependantsReducer.dependants,
    ],
    dependants: state.fetchDependantsReducer.dependants,
    isFetchingDependants: state.fetchDependantsReducer.isFetching,
    practices: state.scriptMenuReducer.connectedPractices,
    practice: state.scriptDetailsReducer.practice,
    orgId: state.scriptDetailsReducer.practice.Id,
    userId: state.profileReducer.profile.Id,
    doctors: state.scriptMenuReducer.doctors,
    isFetchingDoctors: state.scriptMenuReducer.isFetchingDoctors,
});
const mapDispatchToProps = dispatch => ({
    /* eslint-disable max-len */
    getProfile: bindActionCreators(profileActions.getProfile, dispatch),
    fetchDoctors: bindActionCreators(menuActions.fetchDoctors, dispatch),
    setInfo: bindActionCreators(actions.setInfo, dispatch),
    goToAddMedications: () => go.addMedication(),
    getOptions: bindActionCreators(addPharmActions.getOptions, dispatch),
    fetchAllPractices: bindActionCreators(searchActions.fetchAllPractices, dispatch,),
    clearPracticeSelection: bindActionCreators(listActions.clearPracticeSelection, dispatch,),
    fetchConnectedPractices: bindActionCreators(menuActions.fetchConnectedPractices, dispatch,),
    fetchDependants: bindActionCreators(dependantActions.fetchDependants, dispatch),
    /* eslint-enable max-len */
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PatientSelectionMobile);
export { PatientSelectionMobile };