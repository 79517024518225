import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Tick } from '../../../../svgs/tick.svg';
import CircleCheckbox from '../../../../components/CircleCheckbox/index';
import styles from './mobileTitle.module.css';

const CheckBox = ({ isValid }) =>
  isValid ? (
    <Tick className={styles.tick} />
  ) : (
    <CircleCheckbox selected className={styles.icon} />
  );

const MobileTitle = ({ isValid, who }) => (
  <div className={styles.title}>
    <CheckBox isValid={isValid} />
    {who} Details
  </div>
);

MobileTitle.propTypes = {
  isValid: PropTypes.bool,
  who: PropTypes.string.isRequired,
};

CheckBox.propTypes = {
  isValid: PropTypes.bool,
};

export default MobileTitle;
