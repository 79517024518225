import React from 'react';
import { connect } from 'react-redux';
import UIContainer from '../../../../components/UIContainer';
import CallPractice from '../../../../components/CallPractice';
import fancyHospital from '../../../../images/fancyHospital.png';
import { isDesktop } from '../../../../config';
import { practiceProfilePropType } from '../../../../PracticeProfilePage/propTypes';
import styles from './retryLimitReached.module.css';
import { ReactComponent as Cross } from 'svgs/crossClose.svg';
import { useVScriptStore } from 'store/mobx/mobx';

const RetryLimitReached = (props) => {

  const store = useVScriptStore();

  const closeAndDeletePrescrition = () => {
    store.deletePrescription(props.prescriptionId)
    window.location.reload(true);
  }

  const content = (
    <div className={styles.container}>
      {isDesktop() && 
        <>
        <div className={styles.close} onClick={closeAndDeletePrescrition}>
          <Cross/>
        </div>
        <div className={styles.heading}>Whoops</div>
        </>
      }
      <div className={styles.iconContainer}>
        <img
          alt="prescription error"
          src={fancyHospital}
          className={styles.icon}
        />
      </div>
      <div className={styles.text}>
        Your payment has been declined by your card issuer.
      </div>
      <div className={styles.text}>
        Please, contact your bank for more details, or pay with a different
        card.
      </div>
      {props.practice.Phone ? (
        <CallPractice practice={props.practice} />
      ) : (
        <button className={styles.sweetButton} onClick={props.close}>
          Sweet
        </button>
      )}
    </div>
  )
  if (isDesktop()) return content;
  else
    return (
      <UIContainer heading="Whoops!" hideArrow showCancel>
        {content}
      </UIContainer>
    );
}

RetryLimitReached.propTypes = {
  practice: practiceProfilePropType,
};

const mapStateToProps = state => ({
  practice: state.scriptDetailsReducer.practice,
});

export default connect(mapStateToProps)(RetryLimitReached);
