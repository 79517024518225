import { ReactComponent as HealthFeedIcon } from 'svgs/healthFeed.svg';
import { ReactComponent as CareCircleIcon } from 'svgs/dependants.svg';
import { ReactComponent as MessageIcon } from 'svgs/envelope.svg';
import { ReactComponent as PracticesIcon } from 'svgs/practices.svg';
import { ReactComponent as AppointmentIcon } from 'svgs/calendar.svg';
import { ReactComponent as PrescriptionIcon } from 'svgs/scripts.svg';
import { ReactComponent as PHRIcon } from 'svgs/whiteHeart.svg';
import { ReactComponent as SettingsIcon } from 'svgs/gear.svg';
import { ReactComponent as ChangeUserIcon } from 'svgs/changeUser.svg';
import { ReactComponent as LogoutIcon } from 'svgs/logout.svg';
import { ReactComponent as SupportIcon } from 'svgs/question_mark.svg';
import { ReactComponent as HelpArrowIcon } from 'svgs/helpArrow.svg';
import { ReactComponent as MyWalletIcon } from 'svgs/my-wallet.svg';
import { go } from 'routes';
import { logout } from 'apis/entry';
import { ENVIRONMENT, IS_DEVELOPMENT_MODE } from 'config';
import { getSupportPage } from 'libs/utils';

const BARDATA = [
    {
        name: 'My Health Feed',
        onClick: () => { go.home() },
        LeftIcon: HealthFeedIcon
    },
    {
        name: 'Messages',
        onClick: () => { go.messages() },
        LeftIcon: MessageIcon
    },
    {
        name: 'Care Circle',
        onClick: () => { go.dependants() },
        LeftIcon: CareCircleIcon
    },
    {
        name: 'Practices',
        onClick: () => { go.practices() },
        LeftIcon: PracticesIcon
    },
    {
        name: 'splitter'
    },
    {
        name: 'Appointments',
        onClick: () => { go.appointments() },
        LeftIcon: AppointmentIcon
    },
    {
        name: 'Prescriptions',
        onClick: () => { go.prescriptions() },
        LeftIcon: PrescriptionIcon
    }, 
    {
        name: 'My Health Record',
        onClick: () => { go.PHRSummary() },
        LeftIcon: PHRIcon
    },
    {
        name: 'splitter'
    },
    {
        name: 'Help',
        onClick: () => window.open(getSupportPage(), "_blank"),
        LeftIcon: SupportIcon,
        RightIcon: HelpArrowIcon
    },
    {
        name: 'Settings',
        onClick: () => go.settings(),
        LeftIcon: SettingsIcon
    },
    {
        name: 'My Wallet',
        onClick: () => go.payments(),
        LeftIcon: MyWalletIcon
    },
    {
        name: 'Change User',
        onClick: () => { go.loginWithToken() },
        LeftIcon: ChangeUserIcon
    },
    {
        name: 'Log out',
        onClick: async () => {
            await logout();
            localStorage.removeItem('token');
            window.location.href = IS_DEVELOPMENT_MODE ? '/login' : `https://login${ENVIRONMENT}.vensa.com/`
        },
        LeftIcon: LogoutIcon
    }
];

export const useBarData = (...names: string[]) => BARDATA.filter(item => names.indexOf(item.name) === -1);