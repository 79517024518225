import React from 'react';
import { formatDate } from 'services/Format/formatDate';
import styles from './index.module.scss';

type Props = {
    date?: string;
    handleClick: () => void;
}

const DeclarationLabel = ({date, handleClick, ...rest}: Props) => {
  return (date ? 
    <span className={styles.declaration} onClick={handleClick} {...rest}>
        View Identity Declaration <br /> 
        <span className={styles.date}>Uploaded on {formatDate(date)}</span>
    </span> : <></>
  )
}

export default DeclarationLabel;