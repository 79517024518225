import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from 'components/vensaComponents';
import { useLocation } from 'react-router';
import { BaseWhiteNotification } from './BaseWhiteNotification';
import { actions } from 'components/UIContainer/MobileUI/MainShell/actions/actions';

import labs from 'images/labs.png';
import { go } from 'routes';
import { useDispatch } from 'react-redux';
import { useMyHealthStore } from 'store/mobx/mobx';

export const PDHProvideFeedback: React.FC = observer(() => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  if (!pathname.startsWith('/lab-result')) return null;

  return (
    <BaseWhiteNotification
      paragraph={`Provide feedback on your recent lab results, it is part of the research that you are involved with in your practice at Hauora Medical`}
      svg={labs}
      height={"40px"}
    >
      <Button 
        css={{ fontSize: "16px", borderRadius: "9px", marginLeft: "auto"}}
        onClick={() => {
            dispatch((
                actions.setWhiteBoxNotification({
                  isOpen: false,
                })
              ))
            go.labResultsFeedback();
        }}    
    >
        Provide Feedback
      </Button>
    </BaseWhiteNotification>
  );
});
