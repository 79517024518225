import React, { PropsWithChildren, ReactNode } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';

interface SectionProps {
    title: string | ReactNode;
    titleFont?: 'thin' | 'normal';
    addition?: string | ReactNode;
    enableBottomLine?: boolean;
    styleClass?: string;
}

const titleFonts = {
    thin: styles.titleThin,
    normal: styles.titleNormal
}

const BottomBorderSection: React.FC<PropsWithChildren<SectionProps>> = ({
    children, 
    title,
    titleFont = 'normal',
    addition,
    enableBottomLine = true
}) => (
<div className={cn(styles.container, {[styles.bottomLine]: enableBottomLine})}>
    <div className={cn(styles.rowContainer, 
        {[styles.contentSpacing]: !!children})}>
        <span className={cn(styles.title, titleFonts[titleFont])}>{title}</span>
        {typeof addition === 'string' ? <span className={styles.addition}>{addition}</span> : addition}
    </div>
    {children}
</div>);

export default BottomBorderSection;
