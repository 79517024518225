import React from 'react';
import moment from 'moment';
import classnames from 'classnames';

import { ReactComponent as ProfileIcon } from '../../../svgs/profileIcon.svg';
import { fullScriptPropType } from '../../propTypes/index';
import styles from './personInfo.module.css';

const PersonInfo = ({ script }) => (
  <div className={styles.container}>
    <div
      className={classnames(styles.date, { [styles.noCharge]: !script.Charge })}
    >
      <div>{`Requested on ${moment(script.DateTime).format('DD/MM/YY')}`}</div>
      {script.Charge && <div>{`REF: ${script.Charge.Reference}`}</div>}
    </div>
    {script.ProfilePictureUrl ? (
      <img
        src={script.ProfilePictureUrl}
        alt={`${script.FirstName} ${script.LastName}`}
        className={styles.avatar}
      />
    ) : (
      <div className={styles.iconContainer}>
        <ProfileIcon className={styles.icon} />
      </div>
    )}
    <div className={styles.name}>{`${script.FirstName} ${
      script.LastName
    }`}</div>
    <div className={styles.practiceInfo}>{`${script.PmsUserName}, ${
      script.OrganisationName
    }`}</div>
  </div>
);

PersonInfo.propTypes = {
  script: fullScriptPropType,
};

export default PersonInfo;
