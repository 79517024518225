import React from 'react';
import styled from 'styled-components';

import { observer } from 'mobx-react-lite';
import { SummarySection } from './SummarySection';
import { useMyHealthStore } from 'store/mobx/mobx';
import { BaseSectionItem } from './BaseSectionItem';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
const LeftItem = styled.div`
    font-size: 17px;
    color: #6C6C6C;
    margin-bottom: 10px;
`
const RightItem = styled.div`
    font-size: 17px;
    color: #07A3C8;
    margin-bottom: 10px;
`

export const HeightWeightBMISection: React.FC = observer(() => {
    const store = useMyHealthStore().summaryTab;
    const Weight = store.WeightInKg;
    const Height = store.HeightInCm;
    const BMI = store.BMI;
    const BP = store.BloodPressure;

    return (
        <SummarySection title="Height / Weight / BMI" observableData={[Weight, Height, BMI]}>
            <Container>
                <Column style={{marginLeft: "1.5rem", width: "50%"}}>
                    <LeftItem>Weight</LeftItem>
                    <LeftItem>Height</LeftItem>
                    <LeftItem>BMI</LeftItem>
                    <LeftItem style={{marginBottom: "0px"}}>Last BP</LeftItem>
                </Column>
                <Column>
                    <RightItem>{Weight === 0 ? "No record found" : Weight + " kg"}</RightItem>
                    <RightItem>{Height === 0 ? "No record found" : Height + " cm"}</RightItem>
                    <RightItem>{BMI === 0 ? "No record found" : BMI + " kg/m2"}</RightItem>
                    <RightItem style={{marginBottom: "0px"}}>{BP.Diastolic === 0 ? "No record found" : BP.Systolic + "/" + BP.Diastolic + " mm Hg"}</RightItem>
                </Column>
            </Container>
        </SummarySection>
    );
});
