import superagent from 'superagent';
import nocache from 'superagent-no-cache';
import promisePlugin from 'superagent-promise-plugin';
import Alert from './Alert';
import { API_PREFIX, isDesktop } from '../config';
import { getURL } from './corsAPI.constants';
import { getToken } from './newHttp';

const defaultHeaders = { Accept: 'application/json' };
const decorateRequest = (req, parser, config, alertMethod) =>
  req
    .use(promisePlugin)
    .use(nocache)
    .set({
      ...defaultHeaders,
      ...config.headers,
      Authorization: getToken() ? `Bearer ${getToken()}` : undefined,
    })
    .query(config.query)
    .send(config.data)
    .catch(err => {
      alertMethod(parser(err));
      // TODO: Prompt user to login if token is expired
      //console.log(err.status);
      return new Promise((res, rej) => rej(err));
    });
const decorateImageRequest = (req, parser, config, alertMethod) =>
  req
    .use(promisePlugin)
    .use(nocache)
    .set({
      ...defaultHeaders,
      ...config.headers,
      Authorization: getToken() ? `Bearer ${getToken()}` : undefined,
    })
    .attach('avatar', config.file)
    .catch(err => {
      alertMethod(parser(err));
      return new Promise((res, rej) => rej(err));
    });

const get = (parser, alertMethod, prefix) => (url, config = {}) => {
  return decorateRequest(
    superagent.get(getURL(prefix, url)),
    parser,
    config,
    alertMethod,
  );
};

const post = (parser, alertMethod, prefix) => (url, config = {}) =>
  decorateRequest(
    superagent.post(getURL(prefix, url)),
    parser,
    config,
    alertMethod,
  );
  

const postImage = (parser, alertMethod, prefix) => (url, config = {}) =>
  decorateImageRequest(
    superagent.post(getURL(prefix, url)),
    parser,
    config,
    alertMethod,
  );

const destroy = (parser, alertMethod, prefix) => (url, config = {}) =>
  decorateRequest(
    superagent.delete(getURL(prefix, url)),
    parser,
    config,
    alertMethod,
  );

const HttpLib = (parser, alertMethod, prefix = API_PREFIX) => ({
  get: get(parser, alertMethod, prefix),
  post: post(parser, alertMethod, prefix),
  postImage: postImage(parser, alertMethod, prefix),
  delete: destroy(parser, alertMethod, prefix),
});

const GenericParserHttp = HttpLib(
  () =>
    `An Unexpected Error Occurred. Please Try Again.`,
  () => {},
  API_PREFIX,
);
const NoErrorParserHttp = HttpLib(() => {}, () => {}, API_PREFIX);
export default HttpLib;
export { GenericParserHttp, NoErrorParserHttp };
