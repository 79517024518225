import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isMobilePhone from 'validator/lib/isMobilePhone';

import mobilePhone from '../../../../images/mobilePhone.png';
import BasicInputField from '../../../../components/BasicInputField';
import CtaButton from '../../../../components/CtaButton';
import { isDesktop } from '../../../../config';

import styles from './mobileInput.module.css';

class MobileInput extends Component {
  static propTypes = {
    setMobile: PropTypes.func.isRequired,
    mobile: PropTypes.string,
    sendText: PropTypes.func,
  };
  state = {
    mobile: this.props.mobile || '',
    mobileError: null,
  };
  handleChange = e => this.setState({ mobile: e.target.value });
  clearMobileError = () => {
    this.setState({ mobileError: null });
  };
  validateMobile() {
    return isMobilePhone(this.state.mobile, 'en-NZ');
  }
  handleClick = () => {
    if (!this.validateMobile()) {
      this.setState({
        mobileError: 'Invalid Mobile, Please enter a valid NZ mobile number.',
      });
    } else {
      this.props.sendText(this.state.mobile);
    }
  };
  render() {
    return (
      <div className={styles.container}>
        {isDesktop() && (
          <div className={styles.iconContainer}>
            <img src={mobilePhone} alt="mobile" className={styles.icon} />
          </div>
        )}
        <div className={styles.text}>Enter your mobile number</div>
        <BasicInputField
          label="Mobile number"
          className={styles.input}
          type="tel"
          value={this.state.mobile}
          onChange={this.handleChange}
          clearError={this.clearMobileError}
          error={this.state.mobileError}
        />
        <CtaButton
          className={styles.button}
          active={this.validateMobile()}
          onClick={this.handleClick}
          smallArrow
        />
      </div>
    );
  }
}

export default MobileInput;
