import React, { Component, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { observer } from 'mobx-react-lite';

import { map } from 'lodash/fp';
import { CSSTransitionGroup } from 'react-transition-group';

import { actions as headerActions } from '../../components/UIContainer/actions/headerActions';
import { actions } from '../actions/actions';
import DesktopCta from '../../components/DesktopCta/index';
import DesktopCard from '../../components/DesktopCard/index';
import DesktopPopUp from '../../components/DesktopPopUp/index';
import DesktopBooking from './BookAppointment/index';
import desktopAppointment from './CurrentAppointment/index';
import pastAppointment from './PastAppointment/index';
import UIContainer from '../../components/UIContainer/index';
import { appointmentPropType } from '../propTypes/index';
import styles from './appointmentListDesktop.module.css';
import { go } from '../../routes';
import { transitionGroupProps } from '../../config';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { useComponentDidMount } from 'libs/newHttp';
import NoDependantsCard from '../../DependantsPage/NoDependantsCard/index';


const Outer = styled.div`
    width: 100%;
    background-color: #F9F9F9;
    align-items: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
`
const Inner = styled.div`
	animation: appear 0.3s forwards;
	overflow-y: auto;
	overflow-x: hidden;
	-ms-overflow-x: hidden;
	padding: 0 0 40px;
	width: 665px;
	margin: auto;
	height: 600px;
	margin-bottom: auto;
	padding: 0rem 1rem;
    &::-webkit-scrollbar {
		width: 10px;
	 }
	   
	 &::-webkit-scrollbar-track {
		background: #F0F0F0;
		border-radius: 25px;
		margin-left: 1rem;
	 }
	 
	 &::-webkit-scrollbar-thumb {
		 background: #AFAFAF;
		 border-radius: 25px;
	 }
	 
	 &::-webkit-scrollbar-thumb:hover {
		 background: #999; 
	 }
  }
`
const DesktopAppointments = observer(({setSideHeaderItem, fetchAppointments, fetchPastAppointments, token, appointments, pastAppointments, loading}) => {
	const location = useLocation();
	const outerRef = useRef();
	const [height, setHeight] = useState(0);
	const [showPopUp, setShowPopUp] = useState((location.query &&
		location.query.active === 'true') || false
	)

	useComponentDidMount(() => {
		const token = token;
		setSideHeaderItem('appointments');
		fetchAppointments(token);
		fetchPastAppointments(token);
	})

	const togglePopUp = () => {
		setShowPopUp(!showPopUp);
	};
	const handleApptClick = id => () => {
		go.appointmentDetails(id);
	};

	useEffect(() => {
		if (outerRef !== null) setHeight(outerRef.current.getBoundingClientRect().height);
	})

    return (
    	<UIContainer isFetching={loading} heading="Appointments" navKey='Appointments'>
        	<Outer ref={outerRef}>
        		<DesktopCta
					text="Make an Appointment"
					onClick={togglePopUp}
					background
					absolute
				/>
				<Inner style={{height: height, marginTop: "80px"}}>
					{!appointments.length && !pastAppointments.length ? <NoDependantsCard appointments={true} /> : null}
					{appointments.length > 0 && (
						<div className={styles.label}>{`Upcoming Appointments (${
						appointments.length
						})`}</div>
					)}
					<div className={styles.confirmedAppointments}>
						{map(a => (
						<DesktopCard key={a.Id} width="300">
							{desktopAppointment(a, handleApptClick)}
						</DesktopCard>
						))(appointments)}
					</div>
					{pastAppointments.length > 0 && (
						<div className={styles.label}>Past Appointments</div>
					)}
					<div className={styles.pastAppointments}>
						{map(a => pastAppointment(a, handleApptClick))(
						pastAppointments,
						)}
					</div>
				</Inner>
				<CSSTransitionGroup {...transitionGroupProps(styles)}>
					{showPopUp && (
						<DesktopPopUp toggle={togglePopUp}>
						<DesktopBooking close={togglePopUp} />
						</DesktopPopUp>
					)}
				</CSSTransitionGroup>
        	</Outer>
     	</UIContainer>
    );
  
})

const mapStateToProps = state => ({
  token: state.loginReducer.token,
  appointments: state.fetchConfirmedAppointmentsReducer.appointments,
  pastAppointments: state.fetchPastAppointmentsReducer.appointments,
  loading: state.fetchPastAppointmentsReducer.isFetching || state.fetchConfirmedAppointmentsReducer.isFetching,
});
const mapDispatchToProps = dispatch => ({
  setSideHeaderItem: bindActionCreators(
    headerActions.setSideHeaderItem,
    dispatch,
  ),
  fetchAppointments: bindActionCreators(
    actions.getConfirmedAppointments,
    dispatch,
  ),
  fetchPastAppointments: bindActionCreators(
    actions.getPastAppointments,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DesktopAppointments);
