import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import UIContainer from '../../components/UIContainer';
import RouteBar from '../../components/DesktopComponents/RouteBar';
import TabBar from '../../components/DesktopComponents/TabBar';
import PracticeCard from './components/PracticeCard';
import pic from '../../images/practicepic.png';
import Toggle from '../../Privacy/components/PrivacyToggle';
import { ReactComponent as Back } from '../../svgs/circleRightArrow.svg';
import { useIdentityStore, useAccountStore } from 'store/mobx/mobx';
import { useComponentDidMount } from 'libs/newHttp';
import { go } from 'routes';
import { getFinalStatus } from 'Identity/components/IdentityLabel';
import Banner from 'components/DesktopComponents/PartialBanner';
import PrivacyPopUp from 'SettingsPage/components/PrivacyPopUp';
import PopUpNoId from 'components/DesktopComponents/PopupNoIdentity';
import { ReactComponent as privacy } from 'svgs/privacy2.svg';
import LearnMorePopUp from 'components/LearnMorePopUp/LearnMorePopUp';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #F9F9F9;
`
const InnerContainer = styled.div`
    width: 500px;
    margin 1rem auto;
`
const Title = styled.div`
    text-transform: uppercase;
    font-size: 13px;
    margin-top: 1rem;
    text-align: left;
    margin-bottom: 2rem;
    margin 2rem auto;
    width: 500px;
    margin-bottom: 0px;
`
const Inner = styled.div`
    border-radius: 4px;
    background-color: white;
    width: 460px;
    margin: 2rem auto;
    box-shadow: 1px 1px 1px lightgray
    position: relative;
`
const PageTitle = styled.div`
    font-size: 17px;
    width: 100%;
    margin: auto;
    font-weight: 600;
    color: #585858;
    text-align: center;
    margin-bottom: 2px;
`
const Address = styled.div`
    font-size: 13px;
    width: 100%;
    margin: auto;
    font-weight: 300;
    text-align: center;
    color: #585858;
`
const Pic = styled.img`
    margin: auto;
    margin-top: 2rem;
    display: block;
    width: 320px;
`
const SettingsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2rem;
    margin-top: 0.5rem;
`
const SettingsTitle = styled.div`
    font-size: 15px;
    color: #989898;
    padding: 0.6rem 0rem;
    text-align: left;
    border-bottom: 2px solid #DADADA;
    font-weight: 600;
`
const Footer = styled.div`
    text-align: left;
    font-size: 12px;
    color: #585858;
`
const PracticeConditions = styled.div`
    font-size: 15px;
    font-weight: 600;
    color: #989898;
    margin: 2rem 0rem 0.2rem 0rem;
`

const Practice = {
    name: "Hauora Medical Center",
    address: "6 Gold Street, Albany Heights, Auckland",
    pic: pic
}

const DesktopPrivacy: React.FC = observer(() => {
    const accStore = useAccountStore();
    const [practice, setPractice] = useState({
        OrganisationId: "",
        OrganisationName: "",
        Address: "",
        PictureUrl: "",
        Pictures: [""],
        BlackListed: false,
    });
    const [state, setState] = useState(0)
    const [popUp, setPopUp] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false)

    useComponentDidMount(() => {
        // accStore.getProfile();
        accStore.getIdentityRequestsStatus(() => {});
    })

    useEffect(() => {        
        // if (getFinalStatus(accStore) !== 1) {
        //     setPopUp(true);
        // } else { setPopUp(false) }

        setPopUp(getFinalStatus(accStore) !== 1);
    })
    return (
        <UIContainer isFetching={accStore.loadingRequests || accStore.loading}>
            {showPopUp && <LearnMorePopUp hide={() => setShowPopUp(false)} type={"PRIVACY_INFO"} hideOnOverlayClick={true} />}
            <Container>
                <RouteBar 
                    lowLevel={"Privacy"}
                    lowLink={() => setState(0)}
                />
                <TabBar type={0} id={2} />
                {popUp &&
                    <PopUpNoId 
                        mobile={false}
                    />
                }
                <Banner 
                    Image={privacy}
                    title={""}
                    text={"This icon will appear when a Vensa App or feature is asking to use your personal information"}
                    buttonText={"Learn More"}
                    lineColor={""}
                    onClick={() => setShowPopUp(true)}
                />
                <Title>Your Privacy with Health Providers</Title>
                {state === 0 &&
                    <InnerContainer>
                        {accStore.identityRequests.map((
                            item: { 
                                OrganisationName: string;
                                OrganisationId: string;
                                Address: string;
                                PictureUrl: string;
                                Pictures: string[];
                                BlackListed: boolean; 
                                IdentityRequest: {};
                                PracticeVerification: boolean;
                            }) => {
                                if (item.IdentityRequest || item.PracticeVerification) 
                                    {return  (
                                        <PracticeCard
                                            buttonClick={() => {
                                                accStore.getUserPrivacySettingsWithOrg(item.OrganisationId, () => go.privacySettings(item.OrganisationId));
                                                setPractice(item)
                                            }}
                                            practice={item}
                                        />
                                    )}
                        })}
                        <Footer>
                            Control your privacy and access rights to your data with the facilities you have chosen to affiliate with.
                        </Footer>
                    </InnerContainer>
                }
            </Container>
        </UIContainer>
    )
})

export default DesktopPrivacy;