export const formatDate = (date: string) => {
    if(date){
        const options = { year: 'numeric', month: 'long', day: 'numeric' } as Intl.DateTimeFormatOptions;
        return new Date(date).toLocaleDateString('en-US', options);
    }
};

export const format2EnDate = (date?: string) => date && new Date(normalizeDate(date)).toLocaleDateString('en-GB');

export const normalizeDate = (date?: string) => {
    return date ? date.split('T')[0] : '';
}