import React from 'react';
import PropTypes from 'prop-types';

import NamedPatient from '../NamedPatient/index';
import SomeoneElse from '../SomeoneElse/index';
import LoadingSpinner from '../../../../components/loadingSpinner/index';
import { profilePropType } from '../../../../components/UIContainer/MobileUI/MainShell/propTypes/index';
import styles from './patientSelection.module.css';
import styled from 'styled-components';
import { capitalise } from 'libs/misc';

const Scroller = styled.ul`
    &::-webkit-scrollbar {
       width: 10px;
    }
      
    &::-webkit-scrollbar-track {
        background: #F0F0F0;
        border-radius: 25px;
    }
    
    &::-webkit-scrollbar-thumb {
        background: #AFAFAF;
        border-radius: 25px;
    }
    
    &::-webkit-scrollbar-thumb:hover {
        background: #999; 
    }
`

const PatientSelection = ({
  userProfile,
  dependantProfiles,
  patientOnClick,
  someoneElseOnClick,
  isFetching,
}) => (
  <LoadingSpinner
    isFetching={isFetching}
    containerClassName={styles.outerContainer}
    iconClassName={styles.spinner}
    overlayClassName={styles.overlay}
  >
    <Scroller className={styles.container}>
      <NamedPatient
        id="nw_selectSelf"
        name={`${capitalise(userProfile.FirstName)} ${capitalise(userProfile.LastName)}`}
        onClick={patientOnClick}
        avatarLink={userProfile.AvatarUrl}
      />
      {dependantProfiles.length > 0 &&
        dependantProfiles.map(dep => (
          <NamedPatient
            id="nw_selectDependent"
            onClick={() => patientOnClick(dep)}
            name={`${capitalise(dep.FirstName)} ${capitalise(dep.LastName)}`}
            avatarLink={dep.AvatarUrl}
            key={dep.Id}
          />
        ))}
      <SomeoneElse id="nw_selectOther" onClick={someoneElseOnClick} />
    </Scroller>
  </LoadingSpinner>
);

PatientSelection.propTypes = {
  userProfile: profilePropType,
  dependantProfiles: PropTypes.arrayOf(PropTypes.object),
  patientOnClick: PropTypes.func.isRequired,
  someoneElseOnClick: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default PatientSelection;
