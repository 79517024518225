import React, { useState } from 'react';
import styled from 'styled-components';

import UIContainer from 'components/UIContainer';
import RouteBar from 'components/DesktopComponents/RouteBar';
import { go } from 'routes';
import TabBar from 'components/DesktopComponents/TabBar';
import { ReactComponent as Progress } from 'svgs/progress3.svg';
import { ReactComponent as Back } from 'svgs/circleRightArrow.svg';
import { ReactComponent as Lock } from 'svgs/blueLock.svg';
import { ReactComponent as Four } from 'svgs/4Pin.svg';
import { ReactComponent as Six } from 'svgs/6Pin.svg';
import { ReactComponent as Arrow } from 'svgs/rightlinearrow2.svg';
import PinCode from '../components/PinCode';
import BlueButton from 'components/DesktopComponents/BlueButton';
import { observer } from 'mobx-react-lite';
import { useIdentityStore } from 'store/mobx/mobx';

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #F9F9F9;
`
const Container = styled.div`
    width: 533px;
    margin 1rem auto;
`
const Title = styled.div`
    text-transform: uppercase;
    font-size: 13px;
    margin-top: 1rem;
    text-align: left;
    margin-bottom: 1rem;
`
const Inner = styled.div`
    height: 630px;
    width: 534px;
    background-color: white;
    margin: auto;
    margin-left: 7px;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 1px 1px 3px lightgray;
    padding: 0px 25px;
`
const Text = styled.div`
    text-align: center;
    color: #07A3C8;
    font-size: 17px;
`
const FlexH = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
`
const Button = styled.div`
    background-color: #07A3C8;
    color: white;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 13px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
`
const Line = styled.div`
    border-bottom: 2px solid #DADADA;
    width: 100%;
    margin: 73px 0px;
`
const Link = styled.div`
    font-size: 14px;
    color: #07A3C8;
    margin: 1.5rem auto;
    cursor: pointer;
`
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const PinSetup: React.FC = observer(() => {
    const [state, setState] = useState(6);
    const [length, setLength] = useState(4);
    const store = useIdentityStore();

    return (
        <UIContainer isFetching={store.loading} data-test="component-PinSetup">
            <Outer>
                <RouteBar 
                    lowLevel={"Identity"}
                    lowLink={() => go.identity()}
                />
                <TabBar type={0} id={1} />
                <Container>
                    <Title>Identity Status</Title>
                    <Progress />
                    {state === 6 && 
                        <Inner data-test="component-pin-state-0" style={{padding: "0rem 6rem"}}>
                            <Back data-test="button-back" onClick={() => go.identity()} style={{
                                transform: "rotate(180deg)",
                                cursor: "pointer",
                                position: "absolute",
                                top: "30px",
                                left: "30px",
                                height: "48px",
                                width: "48px",
                            }}/>
                            <Lock style={{margin: "4rem auto", marginBottom: "2rem"}} />
                            <Text style={{color: "#6C6C6C", fontWeight: "bold", marginBottom: "1.5rem"}}>Create a PIN code</Text>
                            <Text style={{color: "#6C6C6C", textAlign: "left", marginBottom: "1rem"}}>A PIN code provides another layer of security to keep your data safe.</Text>
                            <Text style={{color: "#6C6C6C", textAlign: "left", marginBottom: "2rem"}}>Your PIN code will be required every time you log in. <span style={{color: "#07A3C8", cursor: "pointer"}}>Learn more.</span></Text>

                            <Four style={{width: "100%"}} />

                            <FlexH style={{flexDirection: "column", marginTop: "3rem"}}>
                                <Button data-test="button-6pin" onClick={() => setState(0)}>
                                    Setup PIN code
                                    <Arrow style={{transform: "rotate(90deg)", marginLeft: "10px"}}/>
                                </Button>
                                <Link onClick={() => go.identityPhoto()}>Set up later in security settings</Link>
                            </FlexH>
                        </Inner>
                    }
                    {state === 0 && 
                        <Inner data-test="component-pin-state-0">
                            <Back data-test="button-back" onClick={() => setState(6)} style={{
                                transform: "rotate(180deg)",
                                cursor: "pointer",
                                position: "absolute",
                                top: "30px",
                                left: "30px",
                                height: "48px",
                                width: "48px",
                            }}/>
                            <Lock style={{margin: "4rem auto", marginBottom: "2rem"}} />
                            <Text style={{marginBottom: "70px"}}>Set your security PIN. Two options:</Text>
                            <Text>4 digit Pin</Text>
                            <FlexH style={{marginTop: "15px"}}>
                                <Four style={{width: "100%", marginLeft: "112px"}} />
                                <Button data-test="button-4pin" onClick={() => { 
                                    setLength(4);
                                    setState(1);
                                }}>
                                    Continue
                                    <Arrow style={{transform: "rotate(90deg)", marginLeft: "10px"}}/>
                                </Button>
                            </FlexH>
                            <Line />
                            <Text>6 digit PIN</Text>
                            <FlexH style={{marginTop: "15px"}}>
                                <Six style={{width: "100%", marginLeft: "112px"}} />
                                <Button data-test="button-6pin" onClick={() => { 
                                    setLength(6)
                                    setState(3);
                                }}>
                                    Continue
                                    <Arrow style={{transform: "rotate(90deg)", marginLeft: "10px"}}/>
                                </Button>
                            </FlexH>
                        </Inner>
                    }
                    {state === 1 && <PinCode data-test="component-pin-state-1" length={4} verify={false} ifGuessable={() => setState(5)} goNext={() => setState(2)} goBack={() => setState(0)} />}
                    {state === 2 && <PinCode data-test="component-pin-state-2" length={4} verify={true} ifGuessable={() => setState(5)} goNext={() => go.identityPhoto()} goBack={() => setState(1)} />}
                    {state === 3 && <PinCode data-test="component-pin-state-3" length={6} verify={false} ifGuessable={() => setState(5)} goNext={() => setState(4)} goBack={() => setState(0)} />}
                    {state === 4 && <PinCode data-test="component-pin-state-4" length={6} verify={true} ifGuessable={() => setState(5)} goNext={() => go.identityPhoto()} goBack={() => setState(3)} />}
                    {state === 5 &&
                        <Inner data-test="component-pin-state-5" >
                            <Back onClick={() => setState(0)} style={{
                                transform: "rotate(180deg)",
                                cursor: "pointer",
                                position: "absolute",
                                top: "30px",
                                left: "30px",
                                height: "48px",
                                width: "48px",
                            }}/>
                                <Lock style={{margin: "4rem auto", marginBottom: "2rem"}} />
                            <Text style={{margin: "30px 0px", color: "#FC7B7B"}}><b>This PIN code can be easily guessed</b></Text>
                            <Text style={{marginBottom: "6rem", fontWeight: "bold"}}>
                                To keep your data private we strongly recommend you choose a different PIN code.
                            </Text>
                            <ButtonContainer>
                                <BlueButton style={{fontSize: "17px", borderRadius: "36px"}} text={"Change PIN Code"} onClick={() => setState(0)} />
                                <Link onClick={() => setState(length === 4 ? 2 : 4)}>Use Anyway</Link>
                            </ButtonContainer>
                            
                        </Inner>
                    }
                </Container>
            </Outer>
        </UIContainer>
    )
})

export default PinSetup;