import { Wizard } from '../../../../../components/core/Wizard';
import React from 'react';
import ReasonStep from './ReasonStep';
import { AppointmentProps } from '../types';
import PolicyStep from './PolicyStep';
import ProcessStep from './ProcessStep';

type CancelationProps = AppointmentProps & {
    close: () => void;
    policyNote: string;
    iconUrl: string;     // practice icon
}

const Cancelation: React.FC<CancelationProps> = (
    {
        data,
        policyNote,
        iconUrl,
        close
    }) => {
    const refresh = () => {
        window.location.reload();
    }


  return (
      <Wizard 
        close={close} 
        complete={refresh}>
          <ReasonStep
            policyNote={policyNote}
            data={data}/>
          <PolicyStep
            name={data.PracticeName} 
            address={data.Address}
            policyNote={policyNote}
            iconUrl={iconUrl}/>
          <ProcessStep id={data.Id} />
      </Wizard>
  );
};

export default Cancelation;
