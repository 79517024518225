import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { map } from 'lodash/fp';

import styles from './practiceSearchList.module.css';
import { clinicPropType } from '../../propTypes';

const SearchList = ({
  practices,
  allPractices,
  isSearching,
  value,
  successAction,
  listItemStyle,
  errorAction,
  condition,
}) => (
  <div className={styles.list}>
    {(() => {
      if (isSearching) {
        return <div className={styles.empty}>Loading...</div>;
      }
      if (practices.length) {
        return map(
          e => (
            <SearchItem
              practice={e}
              onClick={() => (e[condition] ? successAction(e) : errorAction(e.Id))}
              key={e.PracticeId + e.Id}
              style={listItemStyle}
            />
          ),
          practices,
        );
      }
      if (!value) {
        return map(
          e => (
            <SearchItem
              practice={e}
              onClick={() => (e[condition] ? successAction(e) : errorAction(e))}
              key={e.PracticeId + e.Id}
              style={listItemStyle}
            />
          ),
          allPractices,
        );
      }
      return <div className={styles.empty}> No practices with this name.</div>;
    })()}
  </div>
);

const SearchItem = ({ practice, onClick, style }) => (
  <div
    key={practice.Id}
    onClick={() => onClick()}
    className={classnames(styles.item, style)}
  >
    {practice.Name}
  </div>
);

SearchList.propTypes = {
  successAction: PropTypes.func.isRequired,
  errorAction: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  practices: PropTypes.arrayOf(clinicPropType).isRequired,
  allPractices: PropTypes.arrayOf(clinicPropType).isRequired,
  isSearching: PropTypes.bool.isRequired,
  listItemStyle: PropTypes.string.isRequired,
  condition: PropTypes.string.isRequired,
};
SearchItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  practice: clinicPropType.isRequired,
  style: PropTypes.string.isRequired,
};

export default SearchList;
export { SearchItem };
