import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';

import { ReactComponent as Tick } from '../../../../svgs/tick.svg';
import { DoctorProfilePropType } from '../../../../PracticeProfilePage/BookingPageMobile/propTypes/index';
import { errorPropType } from '../../../propTypes/index';
import styles from './desktopHeading.module.css';

const DesktopHeading = ({ confirming, error, doctor, appointment }) => {
  const confirmed = !confirming && !error;
  return (
    <div className={styles.container}>
      <img
        className={styles.doctorImg}
        src={doctor ? doctor.Picture : ''}
        alt={doctor.Name}
      />
      <div className={styles.text}>
        {`${doctor ? doctor.Name : ''} -
          ${appointment ? appointment.format('dddd, DD MMMM') : ''} -
          ${appointment ? appointment.format('h:mm a') : ''}`}
      </div>
      <div
        className={classnames(styles.status, { [styles.confirmed]: confirmed })}
      >
        {confirmed && <Tick className={styles.tick} />}
        {confirmed ? 'Confirmed' : 'Confirming'}
      </div>
    </div>
  );
};
DesktopHeading.propTypes = {
  error: errorPropType,
  confirming: PropTypes.bool,
  doctor: DoctorProfilePropType.isRequired,
  appointment: PropTypes.instanceOf(moment).isRequired,
};

const mapStateToProps = state => ({
  confirming: state.appointmentConfirmationReducer.checking,
  error: state.appointmentConfirmationReducer.error,
});
const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DesktopHeading);
