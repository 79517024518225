import useWizard from 'components/core/Wizard/useWizard';
import HeaderCardUI from 'components/shared/mobile/HeaderCardUI';
import React, { ComponentType, PropsWithChildren, useRef } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';
import { withStyles } from 'hocs/withStyles';
import StadiumButton from 'components/shared/mobile/StadiumButton';
import {ReactComponent as Arrow} from 'svgs/rightlinearrow.svg';

import { withWrapper } from 'hocs/withWrapper';
import DeclarePanel from 'components/domain/careCircle/DeclarePanel';
import Statement from 'components/domain/careCircle/Statement';
import { ConfirmProps, Touchable } from 'types';
import PageWrapper from 'components/core/PanelWrapper';
import ClickableButton from 'components/shared/desktop/ClickableButton';
import {ReactComponent as Close} from 'svgs/blueRightCircleArrow.svg';

const DeclareStep = ({touch}: Touchable) => {
  const {previousStep, nextStep, handleStep, complete, valueObject} = useWizard();
  const canvasRef = useRef<ReactSignatureCanvas>(null);
  
  let DeclarePage;
  let Confirm;
  const handlePrevious = () => {
    if(canvasRef && canvasRef.current) {
      valueObject.signatureURL = canvasRef.current.toDataURL('image/png');
    }
    previousStep();
  } 

  let Wrapper: ComponentType<PropsWithChildren<{}>>;
  if(!touch){
    const BackButton = withStyles(
      {
        position: 'absolute', 
        top: '3rem', 
        left: 'calc(50% - max(20%, 250px) + 1.5rem)', 
        cursor: 'pointer',
        transform: "rotate(180deg)"})(Close);
    Wrapper = ({children}) => <PageWrapper><BackButton onClick={handlePrevious} />{children}</PageWrapper>
    const NextButton = withStyles({marginTop: '25px'})(ClickableButton);
    Confirm = (props: ConfirmProps) => <NextButton text='Next' {...props}  />
  }else{
    Wrapper = ({children}) => <HeaderCardUI 
    text='Add to my Circle' 
    align='center' 
    handleClick={handlePrevious}
    cardStyle={{
        padding: '1rem'
    }}>{children}</HeaderCardUI>;
    const NextButton = withStyles({width:'4.5rem', height: '4.5rem', position: 'fixed', right: '8vmin', bottom: '15%'})(StadiumButton);
    Confirm = (props: ConfirmProps) => <NextButton Icon={Arrow} {...props}/>
  }
  
  DeclarePage = withWrapper(Wrapper, DeclarePanel);
  
  return (
    <DeclarePage
      touch={touch}
      values={valueObject}
      handleStep={handleStep}
      statement={Statement}
      confirm={Confirm}
      doNext={nextStep}
      doError={complete}
      canvas={canvasRef} />
  )
};

export default DeclareStep;