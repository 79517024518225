const actionTypes = {
  addFeelings: 'ADD_FEELINGS',
  getReasons: 'GET_REASONS',
  getReasonsSuccess: 'GET_REASONS_SUCCESS',
  getReasonsFailure: 'GET_REASONS_FAILURE',
  getConsultMethods: 'GET_CONSULT_METHODS',
  getConsultMethodsSuccess: 'GET_CONSULT_METHODS_SUCCESS',
  getConsultMethodsFailure: 'GET_CONSULT_METHODS_FAILURE',
  skipFeelings: 'SKIP_FEELINGS',
  clearFeelings: 'CLEAR_FEELINGS',
  postService: 'POST_SERVICE',
  postServiceSuccess: 'POST_SERVICE_SUCCESS',
  postServiceFailure: 'POST_SERVICE_FAILURE',
  updateSession: 'UPDATE_SESSION',
  updateSessionSuccess: 'UPDATE_SESSION_SUCCESS',
  updateSessionFailure: 'UPDATE_SESSION_FAILURE'
};

const addFeelings = feelings => ({
  type: actionTypes.addFeelings,
  payload: { feelings },
});
const getConsultMethods = id => ({
  type: actionTypes.getConsultMethods,
  payload: { id },
});

const getConsultMethodsSuccess = methods => ({
  type: actionTypes.getConsultMethodsSuccess,
  payload: { methods },
});

const getConsultMethodsFailure = error => ({
  type: actionTypes.getConsultMethodsFailure,
  payload: { error },
  error: true,
});

const getReasons = id => ({
  type: actionTypes.getReasons,
  payload: { id },
});

const getReasonsSuccess = reasons => ({
  type: actionTypes.getReasonsSuccess,
  payload: { reasons },
});

const getReasonsFailure = error => ({
  type: actionTypes.getReasonsFailure,
  payload: { error },
  error: true,
});

const postService = (service, sessionId, urlName) => ({
  type: actionTypes.postService,
  payload: { service, sessionId, urlName },
});

const postServiceSuccess = session => ({
  type: actionTypes.postServiceSuccess,
  payload: { session },
});

const postServiceFailure = error => ({
  type: actionTypes.postServiceFailure,
  payload: { error },
  error: true,
});
const updateSession = (sessionId, consult, symptoms, note, service, urlName) => ({
  type: actionTypes.updateSession,
  payload: { sessionId, consult, symptoms, note, service, urlName },
});

const updateSessionSuccess = session => ({
  type: actionTypes.postServiceSuccess,
  payload: { session },
});

const updateSessionFailure = error => ({
  type: actionTypes.postServiceFailure,
  payload: { error },
  error: true,
});


const skipFeelings = () => ({
  type: actionTypes.skipFeelings,
});

const clearFeelings = () => ({
  type: actionTypes.clearFeelings,
});

const actions = {
  addFeelings,
  getReasons,
  skipFeelings,
  clearFeelings,
  getReasonsFailure,
  getReasonsSuccess,
  getConsultMethods,
  getConsultMethodsFailure,
  getConsultMethodsSuccess,
  postService,
  postServiceSuccess,
  postServiceFailure,
  updateSession
};

export { actions, actionTypes };
