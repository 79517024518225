import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import UIContainer from 'components/UIContainer';
import lock from 'images/lockred.png';
import { useLocation } from 'react-router-dom';
import { replaceMiddle } from 'libs/misc';
import { useAccountStore } from 'store/mobx/mobx';
import Alert from 'libs/Alert';
import { go } from 'routes';

const Container = styled.div`
    height: 100%;
    margin-bottom: 0.8rem;
`
const Card = styled.div`
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #00000029;
    height: 100%;
    padding: 1rem;
`
const Image = styled.img`
    margin: auto;
    height: 132px;
    width: 140px;
    display: block;
`
const Text = styled.div`
    font-size: 17px;
    color: #FC7B7B;
    text-align: center;
    margin: 2rem 0rem;
`
const Button = styled.div`
    background-color: #FC7B7B;
    border-radius: 36px;
    width: 234px;
    color:white;
    text-align: center;
    height: 72px;
    box-shadow: 0px 3px 6px #00000029;
    line-height: 4rem;
    display: block;
    font-size: 17px;
    margin: auto;
`
interface LocationState {
    email: string
}

const AccountSuspended: React.FC = observer(() => {
    const location = useLocation<LocationState>();
    const store = useAccountStore();

    const onSuccess = () => {
        Alert.success("Recovery information sent. Please check your email")
        go.login();
    }

    return (
        <UIContainer heading={"Account Suspended"} isFetching={store.loading}>
            <Container>
                <Card>
                    <Image src={lock} />
                    <Text>
                        Your account has been suspended.
                        <br />
                        We're sorry!
                    </Text>
                    <Text>
                        An email with instructions on how to recover your account has been emailed to you on {replaceMiddle(location.state.email, location.state.email.length/2)} and to any secondary recovery email.
                    </Text>
                    <Button onClick={() => store.getRecoveryEmailSentToSuspendedAccount(location.state.email, onSuccess)}>Resend Instructions</Button>
                </Card>
            </Container>
        </UIContainer>
    )
});

export default AccountSuspended;