import React from 'react';
import styled from 'styled-components';
import { IPharmacy } from 'store/mobx/vScriptStore/vScriptStore.type';
import defaultPharmacyIcon from '../../images/defaultPharmacyIcon.png';
import { ReactComponent as BackArrow } from '../../svgs/WhiteLeftArrow.svg';
import { ReactComponent as SearchIcon } from '../../svgs/searchIcon2.svg';
import { observer } from 'mobx-react-lite';
import { isDesktop } from 'config';

interface IProps {
    pharmacies: IPharmacy[],
    handleClick?: Function
}

interface IPharmacyProps {
    id?: string,
    name?: string,
    phone?: string,
    fax?: string,
    address?: string,
    latitude?: number,
    longitude?: number,
    photoUrl?: string,
    distanceInMeters?: number,
    featured?: boolean,
    openingHours?: any,
    goBack?: Function,
    goSearch?: Function,
    goConfirm?: Function,
    handleClick?: Function,
    first?: boolean,
}

const BackArrowSVG = styled(BackArrow)`
    height: 100%;
    width: 100%;
    fill: white;
`

const SearchIconSVG = styled(SearchIcon)`
    height: 100%;
    width: 100%;
    fill: white;
`

const DarkCircleContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 0;
    height: 10%;
    width: 100%;
    padding: 0 1.5rem;
`

const DarkCircle = styled.div`
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    height: 2rem;
    width: 2rem;
    padding: 0.5rem;
`

const FeaturedContainer = styled.div`
    overflow: scroll;
    padding: 0.25rem;
    display: flex;
    align-items: flex-end;
`

const FeaturedCardContainer = styled.div`
    margin: 0.25rem;
    min-width: 25%;
    align-items: center;
`

const FeaturedCardImageContainer = styled.div`
    width: 100%;
    height: 5rem;
    padding: 0.25rem;
`
const FeaturedCardText = styled.div`
    padding: 0.3rem;
    font-size: small;
    color: var(--color-pelorous);
    height: 2.2rem;
    overflow: hidden;
`

const FeaturedCardImg = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 0.25rem
    object-fit: cover;
`

const ListContainer = styled.div`
    overflow: scroll;
`

const ListItemContainer = styled.div`
    margin: 0.5rem;
    display: flex;
    align-items: center;
    padding: 0.25rem;
    border-bottom: 1px solid #e9e9e9;
`

const ListItemImageContainer = styled.div`
    width: 5rem;
    height: 5rem;
    padding: 0.25rem;
`

const ListItemTextContainer = styled.div`

`

const ListItemImage = styled.img`
    border-radius: 100%;
    width: 100%;
    height: 100%;
`

const ListItemTitle = styled.div`
    font-size: medium;
`

const ListItemText = styled.div`
    font-size: small;
`

const HeroCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
`

const HeroCardImageContainer = styled.div`

`
const HeroCardImage = styled.img`
    height: 100%;
    width: 100%;
`
const HeroCardBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
`
const HeroCardTitle = styled.div`
    font-size: larger;
    margin: 0.25rem;
    font-weight: bold;
`
const HeroCardText = styled.div`
    font-size: small;
    margin: 0.25rem;
    font-weight: lighter;
`
const HeroCardButton = styled.button`
    margin: 2rem;
    border: none;
    background-color: var(--color-pelorous);
    color: white;
    padding: 1rem 0.5rem;
    width: 80%;
    border-radius: 0.25rem;
    font-size: 15px;
`
const HeroCardFooterNote = styled.div`
    font-size: x-small;
    color: var(--color-grey-4);
    margin: 0.5rem 0;
`

const HeroCardHoursContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0.25rem;
    padding: 0.25rem;
    width: 100%;
    justify-content: space-between;
`

const HeroCardHoursSection = styled.div`
    margin: 0.25rem;
    width: 50%;
`

const HeroCardHoursItem = styled.div`
    margin: 0.5rem 0;
`

const FeaturedCard: React.FC<IPharmacyProps> = ({name, photoUrl, handleClick}) => {
    let imageSrc = photoUrl ? photoUrl : defaultPharmacyIcon;
    return(
        <FeaturedCardContainer onClick={() => (handleClick && handleClick())}>
            <FeaturedCardImageContainer>
                <FeaturedCardImg src={imageSrc}/>
            </FeaturedCardImageContainer>
            <FeaturedCardText>
                {name}
            </FeaturedCardText>
        </FeaturedCardContainer>
    )
}

export const PharmacyListCard: React.FC<IPharmacyProps> = ({photoUrl, name, address, phone, fax, handleClick, first}) => {
    let imageSrc = photoUrl ? photoUrl : defaultPharmacyIcon;
    return(
        <ListItemContainer onClick={() => (handleClick && handleClick())} style={{ marginTop: isDesktop() ? !first ? "0rem" : "0.5rem" : "0.5rem", paddingBottom: isDesktop() ? "1rem" : "0rem"}}>
            <ListItemImageContainer style={{ width: isDesktop() ? "46px" : "5rem", height: isDesktop() ? "46px" : "5rem"}}>
                <ListItemImage src={imageSrc}/>
            </ListItemImageContainer>
            <ListItemTextContainer style={{ width: isDesktop() ? "100%" : "auto", fontSize: isDesktop() ? "13px" : "16px"}}>
                <ListItemTitle style={{fontSize: isDesktop() ? "13px" : "16px"}}>
                    {name}
                </ListItemTitle>
                <ListItemText style={{ color: isDesktop() ? "#939393" : "#DADADA", fontWeight: "lighter"}}>
                    {address}
                </ListItemText>
                <ListItemText style={{ color: isDesktop() ? "#939393" : "#DADADA", fontWeight: "lighter"}}>
                    {"Phone: " + phone +", Fax: " + fax}
                </ListItemText>
            </ListItemTextContainer>
        </ListItemContainer>
    )
}

const PharmacyHeroCard: React.FC<IPharmacyProps> = ({name, photoUrl, address, phone, fax, openingHours, goBack, goSearch, goConfirm}) => {
    const getHours = () => {
        if (!openingHours) { return <div></div>}
        return (
            <HeroCardHoursContainer>
                <HeroCardHoursSection>
                    <HeroCardHoursItem>
                        Monday:
                    </HeroCardHoursItem>
                    <HeroCardHoursItem>
                        Tuesday:
                    </HeroCardHoursItem>
                    <HeroCardHoursItem>
                        Wednesday:
                    </HeroCardHoursItem>
                    <HeroCardHoursItem>
                        Thursday:
                    </HeroCardHoursItem>
                    <HeroCardHoursItem>
                        Friday:
                    </HeroCardHoursItem>
                    <HeroCardHoursItem>
                        Saturday:
                    </HeroCardHoursItem>
                    <HeroCardHoursItem>
                        Sunday:
                    </HeroCardHoursItem>
                </HeroCardHoursSection>
                <HeroCardHoursSection>
                    <HeroCardHoursItem>
                        {openingHours.Monday ? (openingHours.Monday.Open + " - " + openingHours.Monday.Close) : "Closed"}
                    </HeroCardHoursItem>
                    <HeroCardHoursItem>
                        {openingHours.Tuesday ? (openingHours.Tuesday.Open + " - " + openingHours.Tuesday.Close) : "Closed"}
                    </HeroCardHoursItem>
                    <HeroCardHoursItem>
                        {openingHours.Wednesday ? (openingHours.Wednesday.Open + " - " + openingHours.Wednesday.Close) : "Closed"}
                    </HeroCardHoursItem>
                    <HeroCardHoursItem>
                        {openingHours.Thursday ? (openingHours.Thursday.Open + " - " + openingHours.Thursday.Close) : "Closed"}
                    </HeroCardHoursItem>
                    <HeroCardHoursItem>
                        {openingHours.Friday ? (openingHours.Friday.Open + " - " + openingHours.Friday.Close) : "Closed"}
                    </HeroCardHoursItem>
                    <HeroCardHoursItem>
                        {openingHours.Saturday ? (openingHours.Saturday.Open + " - " + openingHours.Saturday.Close) : "Closed"}
                    </HeroCardHoursItem>
                    <HeroCardHoursItem>
                        {openingHours.Sunday ? (openingHours.Sunday.Open + " - " + openingHours.Sunday.Close) : "Closed"}
                    </HeroCardHoursItem>
                </HeroCardHoursSection>
            </HeroCardHoursContainer>
        )
    }

    let imageSrc = photoUrl ? photoUrl : defaultPharmacyIcon;
    
    return(
        <HeroCardContainer>
            <DarkCircleContainer>
                <DarkCircle onClick={() => goBack && goBack()}>
                    <BackArrowSVG onClick={() => goBack && goBack()}/>
                </DarkCircle>
                <DarkCircle onClick={() => goSearch && goSearch()}>
                    <SearchIconSVG onClick={() => goSearch && goSearch()}/>
                </DarkCircle>
            </DarkCircleContainer>
            <HeroCardImageContainer>
                <HeroCardImage src={imageSrc}/>
            </HeroCardImageContainer>
            <HeroCardBodyContainer>
                <HeroCardTitle>
                    {name}
                </HeroCardTitle>
                <HeroCardText>
                    {address}
                </HeroCardText>
                <HeroCardText>
                    {phone}
                </HeroCardText>
                {openingHours &&
                <HeroCardTitle>
                    Opening Hours
                </HeroCardTitle>
                }{getHours()}
                <HeroCardButton onClick={() => goConfirm && goConfirm()}>
                    Select Pharmacy
                </HeroCardButton>
                <HeroCardFooterNote>
                    <b>Note</b>: The pharmacy may charge their own fees when dispensing medications.
                </HeroCardFooterNote>
            </HeroCardBodyContainer>
        </HeroCardContainer>
    )
}

export const FeaturedPharmacy: React.FC<IProps> = ({pharmacies, handleClick}) => {
    return(
        <FeaturedContainer>
            {pharmacies.map(Pharmacy => <FeaturedCard 
                                    key={Pharmacy.Id}
                                    name={Pharmacy.Name} 
                                    photoUrl={Pharmacy.PhotoUrl}
                                    handleClick={() => handleClick && handleClick(Pharmacy)}
                                />
                        )
            }
        </FeaturedContainer>
    )
}

export const PharmacyListView: React.FC<IProps> = observer(({pharmacies, handleClick}) => {
    return(
        <ListContainer>
            {pharmacies.map(Pharmacy => <PharmacyListCard 
                                    key={Pharmacy.Id}
                                    name={Pharmacy.Name} 
                                    photoUrl={Pharmacy.PhotoUrl} 
                                    address={Pharmacy.Address} 
                                    phone={Pharmacy.Phone} 
                                    fax={Pharmacy.Fax}
                                    handleClick={() => handleClick && handleClick(Pharmacy)}
                                />
                        )
            }
        </ListContainer>
    )
})

export const PharmacyHeroView: React.FC<IPharmacyProps> = ({name, photoUrl, address, phone, fax, openingHours, goBack, goSearch, goConfirm}) => {
    return(
        <PharmacyHeroCard 
            name={name} 
            photoUrl={photoUrl} 
            address={address} 
            phone={phone} 
            fax={fax}
            openingHours={openingHours}
            goBack={goBack}
            goSearch={goSearch}
            goConfirm={goConfirm}
        />
    )
}