import React from 'react';

import CtaButton from '../../../components/CtaButton';
import PasswordChange from '../../../components/PasswordChange';
import BasicInputField from '../../../components/BasicInputField';
import styles from './form.module.css';

const Form = ({
  submit,
  oldPW,
  newPW,
  setOld,
  setNew,
  isValid,
  desktop,
  isProcessing,
}) => (
  <form className={styles.form} onSubmit={submit}>
    <BasicInputField
      label="Current Password"
      type="password"
      value={oldPW}
      onChange={setOld}
      className={styles.field}
    />
    <PasswordChange setPassword={setNew} password={newPW} isWhiteBackground />
    <div className={styles.buttonContainer}>
      {desktop && (
        <CtaButton type="submit" active={isValid} isProcessing={isProcessing} />
      )}
    </div>
  </form>
);

export default Form;
