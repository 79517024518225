import { actionTypes } from '../actions/actions';

const INITIAL_STATE = {
  isFetching: false,
  error: null,
  details: {},
};

const fetchApptDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.fetchApptDetails:
      return {
        details: {},
        error: null,
        isFetching: true,
      };
    case actionTypes.fetchApptDetailsSuccess:
      return {
        isFetching: false,
        error: null,
        details: action.payload.details,
      };
    case actionTypes.fetchApptDetailsFailure:
      return {
        details: {},
        isFetching: false,
        error: action.payload.error,
      };
    default: {
      return state;
    }
  }
};

export default fetchApptDetailsReducer;
