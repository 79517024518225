import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Avatar from '../../../../components/Avatar';
import { ReactComponent as PatientSilhouette } from '../../../../svgs/patientSilhouette.svg';
import styles from './namedPatient.module.css';

const NamedPatient = ({ id, onClick, name, avatarLink }) => (
  <div id={id} className={classnames(styles.container)} onClick={onClick}>
    {!avatarLink || avatarLink === '' ? (
      <PatientSilhouette className={styles.avatar} />
    ) : (
      <Avatar cHeight={"2.471rem"} cWidth={"auto"} iHeight={"auto"} iWidth={"2.471rem"} img={avatarLink} isSelection={true}  />
    )}
    <div className={styles.name}>{name}</div>
  </div>
);

NamedPatient.propTypes = {
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  avatarLink: PropTypes.string,
  id: PropTypes.string,
};

export default NamedPatient;
