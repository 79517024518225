import React, { useState } from 'react';
import { BasicModal } from '../../components/BasicModal/BasicModal';
import { LocationHeader, LocationBody } from './PermissionsContent';
import { MapBody, SearchBar } from './MapContent';
import { observer } from 'mobx-react-lite';
import { useVScriptStore } from 'store/mobx/mobx';
import { IPharmacy } from 'store/mobx/vScriptStore/vScriptStore.type';
import { PharmacyHeroView, PharmacyListView } from './PharmacyContent';

interface IProps {
    handleClick: Function;
    confirmAddPharmacy: Function;
    defaultOrg: string;
}

export const LocationModal: React.FC<IProps> = observer(({
    handleClick,
    confirmAddPharmacy,
    defaultOrg,
}) => {

    const store = useVScriptStore();
    const allPharmacies = store.data.Pharmacies;
    const curPharmacy = store.selectedPharmacy;
    const [pharmacies, setPharmacies] = useState(allPharmacies);
    const [searchedPharmacies, setSearchedPharmacies] = useState(allPharmacies);
    const [selectedPharmacy, setSelectedPharmacy] = useState(curPharmacy);

    const [index, setIndex] = useState(0);
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    
    const requestLocation = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                async function(position) {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                    await store.get10NearbyPharmacies(String(position.coords.latitude), String(position.coords.longitude), defaultOrg, (data) => {
                        setPharmacies(data.Pharmacies);
                    });
                    increaseModalIndex();
                },
                async function(error) {
                    await store.getNearbyPharmaciesDefault(defaultOrg, (data) => {
                        setPharmacies(data.Pharmacies);
                        setLatitude(data.Lat);
                        setLongitude(data.Long);
                    });
                    increaseModalIndex();
                }
            )
        }
    }

    const searchPharmacies = (name: string) => {
        store.searchNearbyPharmacies(name, (data: any) => {
            setSearchedPharmacies(data);
        });
    }

    const onModalClick = () => {
        handleClick();
    }

    const confirmPharmacy = () => {
        confirmAddPharmacy(selectedPharmacy);
        handleClick();
    }

    const openMapView = () => {
        setIndex(1);
    }

    const openHeroTab = (curPharmacy: IPharmacy) => {
        setSelectedPharmacy(curPharmacy);
        setIndex(2);
    }

    const openSearchView = () => {
        setSearchedPharmacies([]);
        setIndex(3);
    }

    const increaseModalIndex = () => {
        setIndex(index + 1);
    }

    const HeaderContent = () => {
        switch(index) {
            case 0:
                return(<LocationHeader
                            goBack={onModalClick}
                        />);
            case 3:
                return(<SearchBar
                            goBack={openMapView}
                            handleSubmit={searchPharmacies}
                        />);
            default:
                return(<LocationHeader
                            goBack={onModalClick}
                        />);
        }
    }

    const BodyContent = () => {
        switch(index) {
            case 0:
                return(<LocationBody 
                            handleClick={requestLocation}
                            skipLocation={increaseModalIndex}
                        />);
            case 1:
                return(<MapBody 
                            pharmacies={pharmacies}
                            clickedFeature={openHeroTab}
                            clickedSearch={openSearchView}
                            initialLocation={{  lat: latitude,
                                                lng: longitude
                                                }}
                        />);
            case 2:
                return(<PharmacyHeroView
                            name={selectedPharmacy.Name}
                            photoUrl={selectedPharmacy.PhotoUrl}
                            address={selectedPharmacy.Address}
                            phone={selectedPharmacy.Phone}
                            fax={selectedPharmacy.Fax}
                            openingHours={selectedPharmacy.OpeningHours}
                            goBack={openMapView}
                            goSearch={openSearchView}
                            goConfirm={confirmPharmacy}
                        />);
            case 3:
                return(<PharmacyListView
                            pharmacies={searchedPharmacies}
                            handleClick={openHeroTab}
                        />);
            default:
                return(null);
        }
    }

    return(
        <BasicModal
            ModalHeaderContent={HeaderContent}
            ModalBodyContent={BodyContent}
        />
    );
});