import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { CSSTransitionGroup } from 'react-transition-group';

import { actions } from '../actions/actions';
import { actions as searchActions } from '../../vScript/actions/searchActions';
import { actions as requestActions } from '../../vScript/actions/actions';
import BottomMenu from './BottomMenu';
import MainInfo from './MainInfo';
import styles from './scriptDetails.module.css';
import { go } from '../../routes';
import { isDesktop } from '../../config';
import { fullScriptPropType } from '../propTypes';
import Loading from 'components/loadingSpinner';

class ScriptDetailsMobile extends Component {
  static propTypes = {
    removeScript: PropTypes.func.isRequired,
    repeatScript: PropTypes.func.isRequired,
    goToAddMedications: PropTypes.func.isRequired,
    goToScripts: PropTypes.func.isRequired,
    searchMeds: PropTypes.func.isRequired,
    showMenu: PropTypes.bool,
    toggleMenu: PropTypes.func,
    allMeds: PropTypes.arrayOf(fullScriptPropType),
    token: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    script: fullScriptPropType,
    toggleCard: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.handleRepeat = this.handleRepeat.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }
  componentDidMount() {
    this.props.searchMeds('', this.props.token);
  }
  handleRepeat() {
    const script = this.props.script;
    const CTPP = Object.keys(script.CTPP).map(med =>
      this.props.allMeds.find(e => e.Id === med),
    );
    const FreeForm = Object.keys(script.FreeFormMedication).map(med => {
      return { Name: med };
    });
    this.props.repeatScript({
      firstName: script.FirstName,
      lastName: script.LastName,
      avatarUrl: script.ProfilePictureUrl,
      doctorName: script.PmsUserName,
      doctorId: script.PmsUserId,
      orgName: script.OrganisationName,
      orgId: script.OrganisationId,
      paymentEnabled: script.OrganisationPaymentEnabled,
      patientId: script.DependantId ? script.DependantId : this.props.userId,
      medications: CTPP.concat(FreeForm),
    });
    if (!isDesktop()) {
      this.props.goToAddMedications(script.Id);
      this.props.toggleCard();
    } else {
      this.props.setRepeatScriptId(script.Id);
      this.props.goToScripts();
    }
  }
  handleRemove() {
    this.props.removeScript(this.props.script.Id, this.props.token);
    if (!isDesktop()) this.props.toggleCard();
  }
  render() {
    return (
      <div className={styles.container}>
        <Loading isFetching={this.props.isFetching} />
        <MainInfo script={this.props.script} />
        {isDesktop() ? (
          <div className={styles.buttons}>
            <button className={styles.repeat} onClick={this.handleRepeat}>
              Repeat this prescription
            </button>
          </div>
        ) : (
          <CSSTransitionGroup
            component="div"
            transitionName={{
              leave: styles.leave,
              leaveActive: styles.leaveActive,
              enter: styles.enter,
              enterActive: styles.enterActive,
            }}
            transitionLeaveTimeout={300}
            transitionEnterTimeout={300}
          >
            {this.props.showMenu && (
              <div
                className={styles.menuBackground}
                onClick={this.props.toggleMenu}
              />
            )}
            <BottomMenu
              onCancel={this.props.toggleMenu}
              showButtons={this.props.showMenu}
              buttons={[
                { fn: this.handleRepeat, label: 'Repeat this prescription' },
              ]}
            />
          </CSSTransitionGroup>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  allMeds: state.searchMedsReducer.medications,
  userId: state.profileReducer.profile.Id,
  token: state.loginReducer.token,
  isFetching: state.scriptReducer.isFetchingDetails,
});
const mapDispatchToProps = dispatch => ({
  /* eslint-disable max-len */
  repeatScript: bindActionCreators(requestActions.repeatScript, dispatch),
  removeScript: bindActionCreators(actions.removeScript, dispatch),
  goToAddMedications: (scriptId) => go.addMedication(scriptId),
  goToScripts: () => go.prescriptions(),
  searchMeds: bindActionCreators(searchActions.searchMeds, dispatch),
  setRepeatScriptId: bindActionCreators(actions.setRepeatScriptId, dispatch),
  /* eslint-enable max-len */
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScriptDetailsMobile);
