import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { head } from 'lodash/fp';

import PopUpHeader from '../../../../components/PopUpHeader';
import LoadingSpinner from '../../../../components/loadingSpinner';
import DesktopDoctorList from '../DesktopDoctorList';
import { actions } from '../../../../PracticeProfilePage/actions/actions';
import { practiceProfilePropType } from '../../../../PracticeProfilePage/propTypes';
import styles from './desktopView.module.css';

class DesktopView extends Component {
  static propTypes = {
    practiceId: PropTypes.string.isRequired,
    practice: practiceProfilePropType.isRequired,
    goBack: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    linkToPractice: PropTypes.func.isRequired,
    getPracticeProfile: PropTypes.func.isRequired,
  };
  componentDidMount() {
    this.props.getPracticeProfile(this.props.practiceId);
  }
  render() {
    const { practice, goBack, linkToPractice, isFetching } = this.props;
    return (
      <div className={styles.container}>
        <LoadingSpinner iconClassName={styles.spinner} isFetching={isFetching}>
          <div>
            <PopUpHeader
              heading={practice.Name}
              subheading={practice.Address}
              backFn={goBack}
            />
            <div
              className={styles.mainImg}
              style={{
                backgroundImage: `url(${head(practice.Pictures)})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '320px',
                backgroundPosition: 'center',
              }}
            />
          </div>
          <div className={styles.doctorContainer}>
            <DesktopDoctorList doctors={practice.Doctors} />
            <div className={styles.buttonContainer}>
              <button
                onClick={() => {
                  linkToPractice(practice, 2, true)
                  this.props.toggle();
                }}
                className={styles.add}
              >
                Add this practice
              </button>
              <button onClick={goBack} className={styles.cancel}>
                Cancel
              </button>
            </div>
          </div>
        </LoadingSpinner>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  practice: state.practiceProfileReducer.profile,
  isFetching:
    state.practiceLinkingReducer.isFetching ||
    state.practiceProfileReducer.isFetching,
});

const mapDispatchToProps = dispatch => ({
  getPracticeProfile: bindActionCreators(actions.getPracticeProfile, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DesktopView);
export { styles };
