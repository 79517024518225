import { doctorAppointmentsSaga } from '../PracticeProfilePage/BookingPageMobile/sagas/doctorAppointments';
import { getMultiDayAppointmentsSaga } from '../PracticeProfilePage/sagas/getMultiDayAppointments';
import { doctorProfileSaga } from '../PracticeProfilePage/DoctorProfilePage/sagas/doctorProfile';
import { dayChangedSaga } from '../PracticeProfilePage/BookingPageMobile/sagas/dayChanged';
import {
  confirmCodeSaga,
  mobileConfirmSaga,
} from './MobileVerification/sagas/confirmCode';
import { countdownSaga } from './MobileVerification/sagas/countdown';
import { sendCodeSaga } from './MobileVerification/sagas/sendCode';
import { checkSomeoneElseEnrollmentSaga, checkDependantEnrollmentSaga, checkPatientEnrollmentSaga } from './PatientSelection/sagas/checkEnrollmentSaga';
import { submitAppointmentSaga } from './Processing/sagas/submitAppointment';
import {
  appointmentConfirmationSaga,
  requestConfirmationSaga,
} from './Processing/sagas/checkConfirmation';
import { getReasonsSaga, getConsultMethodsSaga, postServiceSaga, updateSessionSaga } from './Reason/sagas/getReasons';
import { requestNewPasswordSaga } from './ForgotPassword/sagas/requestNewPassword';
import { changePhoneSaga } from './MobileVerification/sagas/changePhone';
import { practiceProfileSaga } from '../PracticeProfilePage/sagas/getPracticeProfile';
import { submitExpressionOfInterestSaga } from './ExpressionInterest/sagas/submitExpressionOfInterest';
import { getCardsSaga as vAppointmentPaymentSaga } from './PrepaidPage/saga/vAppointmentPaymentsSaga';

const vAppointmentSagas = [].concat(
  appointmentConfirmationSaga,
  changePhoneSaga,
  checkDependantEnrollmentSaga,
  checkPatientEnrollmentSaga,
  checkSomeoneElseEnrollmentSaga,
  confirmCodeSaga,
  countdownSaga,
  dayChangedSaga,
  doctorAppointmentsSaga,
  doctorProfileSaga,
  getMultiDayAppointmentsSaga,
  getReasonsSaga,
  mobileConfirmSaga,
  practiceProfileSaga,
  requestConfirmationSaga,
  requestNewPasswordSaga,
  sendCodeSaga,
  submitAppointmentSaga,
  getConsultMethodsSaga,
  submitExpressionOfInterestSaga,
  getConsultMethodsSaga,
  postServiceSaga,
  vAppointmentPaymentSaga,
  updateSessionSaga
);

export { vAppointmentSagas };
