import { actionTypes } from '../actions/actions';

const INITIAL_STATE = {
    isFetching: false,
    error: null,
    medications: {
        LongTerm: [],
        Other: [],
    }
}

const getMedicationsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.getMedicationList: {
            return {
                isFetching: true,
                error: null,
                medications: null
            }
        };
        case actionTypes.getMedicationListFailure: {
            return {
                isFetching: false,
                error: action.payload.error,
                medications: null
            }
        };
        case actionTypes.getMedicationListSuccess: {
            return {
                isFetching: false,
                error: null,
                medications: action.payload.data
            }
        }
        default:
            return state;
    }
}

export default getMedicationsReducer;