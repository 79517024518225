import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Text } from 'components/vensaComponents';
import { getDateText } from '../../TransactionHistoryPage/HistoryListItem';
import { IHealthTransaction } from '../../../../store/mobx/paymentStore/paymentStore.type';

export const DoctorNote: React.FC<Pick<
  IHealthTransaction,
  'DoctorNote' | 'OrganisationName' | 'TextSentAt' | 'TextSentTo'
>> = observer(({ DoctorNote, OrganisationName, TextSentAt, TextSentTo }) => (
  <Box direction="column" fontWeight={100} gap="10px">
    <Text>{DoctorNote}</Text>
    <Box
      direction="column"
      alignItems="end"
      fontSize="11px"
      css={{ fontStyle: 'italic' }}
    >
      {OrganisationName && TextSentAt && TextSentTo && (
        <>
          <Text>{`From ${OrganisationName} on ${getDateText(
            TextSentAt,
          )}`}</Text>
          <Text>{`to ${TextSentTo}`}</Text>
        </>
      )}
    </Box>
  </Box>
));
