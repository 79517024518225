import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from '../../../components/vensaComponents';
import { TabBox } from '../TabBox';
import { SummaryHeader } from './components/SummaryHeader';
import { useComponentDidMount } from '../../../libs/newHttp';
import { useMyHealthStore } from '../../../store/mobx/mobx';
import { CurrentConditionSection } from './components/CurrentConditionSection';
import { PastConditionSection } from './components/PastConditionSection';
import { AllergiesReactionsSection } from './components/AllergiesReactionsSection';
import { CurrentMedicationsSection } from './components/CurrentMedicationsSection';
import { PastMedicationsSection } from './components/PastMedicationsSection';
import { ProceduresSection } from './components/ProceduresSection';
import { HeightWeightBMISection } from './components/HeightWeightBMISection';

export const SummaryTab: React.FC = observer(() => {

  return (
    <TabBox bg="white" borderRadius="4px" fontSize="14px" boxShadow={[0, 3, 6]}>
      <SummaryHeader />
      <Box gap="32px" direction="column">
        <CurrentConditionSection />
        <PastConditionSection />
        <AllergiesReactionsSection />
        <CurrentMedicationsSection />
        <PastMedicationsSection />
        <ProceduresSection />
        <HeightWeightBMISection />
      </Box>
    </TabBox>
  );
});
