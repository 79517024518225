import styled from 'styled-components';

export const PaymentsPageContainer = styled.main`
  display: block;
  height: 100%;
  background-color: white;
  width: 100%;
  margin: 0 0.625rem 0.882rem 0.625rem;
  border-radius: 4px;
  padding: 12px;
`;
