import { observable, action, when, makeAutoObservable } from 'mobx';
import {
  ILabResult,
  IImmunisation,
  SummaryAPIResponse,
  IComment,
  CommentType,
  IClinicNote,
  SelectedCommentTypeEnum,
  ICommentNew,
  ISendFeedback,
} from './myHealthStore.type';
import {
  generalRequest,
  LOADING_STATUS,
  LoadingStatusType,
} from '../../../libs/newHttp';
import {
  getLabResultsAPI,
  getImmunisationsAPI,
  getSummaryAPI,
  postNewCommentAPI,
  getClinicNotesAPI,
  deleteCommentAPI,
  postLabInviteAPI,
  postLabResultFeedbackAPI,
  getLabResultAPI,
} from './myHealthStore.api';
import { go } from 'routes';
import Alert from 'libs/Alert';

export enum MyHealthTabEnum {
  LabResults = 'LabResults',
  Immunisations = 'Immunisations',
  ClinicalNotes = 'Clinical Notes',
  Summary = 'Summary',
}

export class MyHealthStore {
  constructor(){
    makeAutoObservable(this);
  }
  // TODO: This property is a temporoary solution for only showing the whiteBox one time, it should use a value from server instead
  @observable hasWhiteBoxOpened = false;
  @observable labSectionFeedbackWhiteBox = {
    show: false,
    noteId: ""
  }
  @observable labPageFeedbackWhiteBox = {
    show: false,
    noteId: ""
  }
  loading = false;

  @observable healthNavigator = {
    keywords: '',
    link: "",
    isOpen: false,
  };

  @observable currentLabPage: ILabResult | undefined = undefined;
  @observable labResultNotification: {
    LabWhenReceived: string;
    NoteId: string;
    PatientId: string;
  } = {
    LabWhenReceived: "",
    NoteId: "",
    PatientId: ""
  }

  @observable currentFeedbackDetails: { patientId: string, noteId: string } | undefined = undefined;

  @observable currentTab: MyHealthTabEnum = MyHealthTabEnum.Summary;

  @observable postNewCommentLoadingStatus = LOADING_STATUS.INITIAL;

  @observable clinicalErrorText: string | null = "";
  @observable labsErrorText: string | null = null;
  @observable immunisationsErrorText: string | null = null;

  @observable summaryTabLoadingStatus = LOADING_STATUS.INITIAL;
  @observable summaryTabErrorText = '';
  @observable summaryTab: SummaryAPIResponse = {
    ConsumerId: '00000000-0000-0000-0000-000000000000',
    GivenName: '',
    FamilyName: '',
    ErrorMessage: '',
    DateOfBirth: '',
    CurrentConditions: [
      {
        Id: '00000000-0000-0000-0000-000000000000',
        PatientId: '00000000-0000-0000-0000-000000000000',
        Name: '',
        Code: '',
        Description:
          '',
        DiagnosisDate: '',
        ConditionType: 0,
        ProviderId: '00000000-0000-0000-0000-000000000000',
        EnteredBy: 'string',
        OrganisationId: '00000000-0000-0000-0000-000000000000',
        OrganisationName: 'string',
        Comments: [
          {
            Id: '00000000-0000-0000-0000-000000000000',
            ParentId: '00000000-0000-0000-0000-000000000000',
            ConsumerId: '00000000-0000-0000-0000-000000000000',
            PatientId: '00000000-0000-0000-0000-000000000000',
            CommentType: 0,
            Content: 'string',
            DateOfComment: '2019-11-07T10:06:41.590Z',
            Deleted: false,
          },
        ],
      },
      {
        Id: '00000000-0000-0000-0000-000000000001',
        PatientId: '00000000-0000-0000-0000-000000000000',
        Name: '',
        Code: 'string',
        Description:
          '',
        DiagnosisDate: '2019-11-07T10:06:41.590Z',
        ConditionType: 0,
        ProviderId: '00000000-0000-0000-0000-000000000000',
        EnteredBy: 'string',
        OrganisationId: '00000000-0000-0000-0000-000000000000',
        OrganisationName: 'string',
        Comments: [
          {
            Id: '00000000-0000-0000-0000-000000000000',
            ParentId: '00000000-0000-0000-0000-000000000000',
            ConsumerId: '00000000-0000-0000-0000-000000000000',
            PatientId: '00000000-0000-0000-0000-000000000000',
            CommentType: 0,
            Content: 'string',
            DateOfComment: '2019-11-07T10:06:41.590Z',
            Deleted: false,
          },
        ],
      },
    ],
    PastConditions: [
      {
        Id: '00000000-0000-0000-0000-000000000000',
        PatientId: '00000000-0000-0000-0000-000000000000',
        Name: '',
        Code: 'string',
        Description: 'string',
        DiagnosisDate: '2019-11-07T10:06:41.590Z',
        ConditionType: 0,
        ProviderId: '00000000-0000-0000-0000-000000000000',
        EnteredBy: 'string',
        OrganisationId: '00000000-0000-0000-0000-000000000000',
        OrganisationName: 'string',
        Comments: [
          {
            Id: '00000000-0000-0000-0000-000000000000',
            ParentId: '00000000-0000-0000-0000-000000000000',
            ConsumerId: '00000000-0000-0000-0000-000000000000',
            PatientId: '00000000-0000-0000-0000-000000000000',
            CommentType: 0,
            Content: 'string',
            DateOfComment: '2019-11-07T10:06:41.590Z',
            Deleted: false,
          },
        ],
      },
    ],
    Allergies: [
      {
        Id: '00000000-0000-0000-0000-000000000000',
        NameOfAllergy: '',
        AllergyType: 0,
        ConsumerId: '00000000-0000-0000-0000-000000000000',
        PatientId: '00000000-0000-0000-0000-000000000000',
        PatientPmsId: 'string',
        OrganisationId: '00000000-0000-0000-0000-000000000000',
        OrganisationName: 'string',
        ProviderId: '00000000-0000-0000-0000-000000000000',
        ProviderName: 'string',
        DoctorNotes: 'string',
        DateTime: '2019-11-07T10:06:41.590Z',
        IsNewInPastMonth: true,
        Comments: [
          {
            Id: '00000000-0000-0000-0000-000000000000',
            ParentId: '00000000-0000-0000-0000-000000000000',
            ConsumerId: '00000000-0000-0000-0000-000000000000',
            PatientId: '00000000-0000-0000-0000-000000000000',
            CommentType: 0,
            Content: 'string',
            Deleted: false,
            DateOfComment: '2019-11-07T10:06:41.590Z',
          },
        ],
      },
      {
        Id: '00000000-0000-0000-0000-000000000001',
        NameOfAllergy: '',
        AllergyType: 0,
        ConsumerId: '00000000-0000-0000-0000-000000000000',
        PatientId: '00000000-0000-0000-0000-000000000000',
        PatientPmsId: 'string',
        OrganisationId: '00000000-0000-0000-0000-000000000000',
        OrganisationName: 'string',
        ProviderId: '00000000-0000-0000-0000-000000000000',
        ProviderName: 'string',
        DoctorNotes: 'string',
        DateTime: '2019-11-07T10:06:41.590Z',
        IsNewInPastMonth: true,
        Comments: [
          {
            Id: '00000000-0000-0000-0000-000000000000',
            ParentId: '00000000-0000-0000-0000-000000000000',
            ConsumerId: '00000000-0000-0000-0000-000000000000',
            PatientId: '00000000-0000-0000-0000-000000000000',
            CommentType: 0,
            Content: 'string',
            DateOfComment: '2019-11-07T10:06:41.590Z',
            Deleted: false,
          },
        ],
      },
    ],
    CurrentMedications: [
      {
        Id: '00000000-0000-0000-0000-000000000000',
        DrugCode: 'string',
        GenericName: '',
        BrandName: 'string',
        CodingSystem: 0,
        ConsumerId: '00000000-0000-0000-0000-000000000000',
        PatientId: '00000000-0000-0000-0000-000000000000',
        PatientPmsId: 'string',
        OrganisationId: '00000000-0000-0000-0000-000000000000',
        OrganisationName: 'string',
        ProviderId: '00000000-0000-0000-0000-000000000000',
        ProviderName: 'string',
        DoctorNotes: 'string',
        DateTime: '2019-11-07T10:06:41.590Z',
        IsNewInPastMonth: true,
        Comments: [
          {
            Id: '00000000-0000-0000-0000-000000000000',
            ParentId: '00000000-0000-0000-0000-000000000000',
            ConsumerId: '00000000-0000-0000-0000-000000000000',
            PatientId: '00000000-0000-0000-0000-000000000000',
            CommentType: 0,
            Content: 'string',
            DateOfComment: '2019-11-07T10:06:41.590Z',
            Deleted: false,
          },
        ],
      },
    ],
    PastMedications: [
      {
        Id: '00000000-0000-0000-0000-000000000000',
        DrugCode: 'string',
        GenericName: '',
        BrandName: 'string',
        CodingSystem: 0,
        ConsumerId: '00000000-0000-0000-0000-000000000000',
        PatientId: '00000000-0000-0000-0000-000000000000',
        PatientPmsId: 'string',
        OrganisationId: '00000000-0000-0000-0000-000000000000',
        OrganisationName: 'string',
        ProviderId: '00000000-0000-0000-0000-000000000000',
        ProviderName: 'string',
        DoctorNotes: 'string',
        DateTime: '2019-11-07T10:06:41.590Z',
        IsNewInPastMonth: true,
        Comments: [
          {
            Id: '00000000-0000-0000-0000-000000000000',
            ParentId: '00000000-0000-0000-0000-000000000000',
            ConsumerId: '00000000-0000-0000-0000-000000000000',
            PatientId: '00000000-0000-0000-0000-000000000000',
            CommentType: 0,
            Content: 'string',
            DateOfComment: '2019-11-07T10:06:41.590Z',
            Deleted: false,
          },
        ],
      },
    ],
    Procedures: [
      {
        Id: '00000000-0000-0000-0000-000000000000',
        PatientId: '00000000-0000-0000-0000-000000000000',
        Name: '',
        Code: 'string',
        Description: 'string',
        DiagnosisDate: '2019-11-07T10:06:41.590Z',
        ConditionType: 0,
        ProviderId: '00000000-0000-0000-0000-000000000000',
        ProviderName: 'string',
        OrganisationId: '00000000-0000-0000-0000-000000000000',
        OrganisationName: 'string',
        Comments: [
          {
            Id: '00000000-0000-0000-0000-000000000000',
            ParentId: '00000000-0000-0000-0000-000000000000',
            ConsumerId: '00000000-0000-0000-0000-000000000000',
            PatientId: '00000000-0000-0000-0000-000000000000',
            CommentType: 0,
            Content: 'string',
            DateOfComment: '2019-11-07T10:06:41.590Z',
            Deleted: false,
          },
        ],
      },
      {
        Id: '00000000-0000-0000-0000-000000000001',
        PatientId: '00000000-0000-0000-0000-000000000000',
        Name: '',
        Code: 'string',
        Description: 'string',
        DiagnosisDate: '2019-11-07T10:06:41.590Z',
        ConditionType: 0,
        ProviderId: '00000000-0000-0000-0000-000000000000',
        ProviderName: 'string',
        OrganisationId: '00000000-0000-0000-0000-000000000000',
        OrganisationName: 'string',
        Comments: [
          {
            Id: '00000000-0000-0000-0000-000000000000',
            ParentId: '00000000-0000-0000-0000-000000000000',
            ConsumerId: '00000000-0000-0000-0000-000000000000',
            PatientId: '00000000-0000-0000-0000-000000000000',
            CommentType: 0,
            Content: 'string',
            DateOfComment: '2019-11-07T10:06:41.590Z',
            Deleted: false,
          },
        ],
      },
    ],
    HeightInCm: 0,
    WhenHeight: "",
    WeightInKg: 0,
    WhenWeight: "",
    BMI: 0,
    BloodType: 0,
    BloodPressure: {
      Diastolic: 0,
      Systolic: 0,
      WhenMeasured: ""
    },
    IdentityVerified: true,
    Identities: [
      {
        PatientId: '00000000-0000-0000-0000-000000000000',
        ConsumerId: '00000000-0000-0000-0000-000000000000',
        Email: 'string',
        OrganisationId: '00000000-0000-0000-0000-000000000000',
        MobileVerified: true,
        IdentityVerified: true,
        VerificationStatusUpdatedOn: '2019-11-07T10:06:41.591Z',
        VerificationMethod: 0,
      },
    ],
    ConsumerPrivacySettingsWithAPractice: [
      {
        OrganisationId: '00000000-0000-0000-0000-000000000000',
        OrganisationName: 'string',
        LogoUrl: 'string',
        AllowAccessMyHealthRecords: {
          Allow: true,
          Overridden: true,
          Reason: 'string',
        },
        AllowAccessMyDoctorNotes: {
          Allow: true,
          Overridden: true,
          Reason: 'string',
        },
        AllowAccessMyDependantHealthRecords: {
          Allow: true,
          Overridden: true,
          Reason: 'string',
        },
        AllowShareMyHealthDataWithPractices: true,
      },
    ],
    BlacklistedCard: {
      Type: 'string',
      Item: [],
      Reason: 'string',
    },
    OrganisationServiceNotEnabledCard: {
      Type: 'string',
      Reason: 'string',
    },
  };

  @observable labResultsTabLoadingStatus = LOADING_STATUS.INITIAL;
  @observable labResultsTab: {
    all: ILabResult[];
    newInLastMonth: ILabResult[];
    past: ILabResult[];
    feedback: ILabResult[];
  } = {
    all: [],
    newInLastMonth: [],
    past: [],
    feedback: [],
  };

  @observable immunisationsTabLoadingStatus = LOADING_STATUS.INITIAL;
  @observable immunisationsTab: {
    all: IImmunisation[];
    givenWithLast3Months: IImmunisation[];
    past: IImmunisation[];
  } = {
    all: [],
    givenWithLast3Months: [],
    past: [],
  };

  @observable clinicNotesTabLoadingStatus = LOADING_STATUS.INITIAL;
  @observable clinicNotesTab: {
    all: IClinicNote[];
    newInLastMonth: IClinicNote[];
    past: IClinicNote[];
  } = {
    all: [],
    newInLastMonth: [],
    past: [],
  };

  @observable labInviteData = {
    PatientId: "",
    Consent: false,
    AllowInformationContinueUsedAfterWithdrawn: false,
    ReceiveSummaryAfterResearch: false
  }
  @observable labInviteGeneralInfo = {
    PatientId: "",
    OrgName: "",
    ProviderName: ""
  }

  @observable justGaveLabsFeedback: boolean = false; 

  @action.bound
  setJustGaveLabsFeedback() {
    this.justGaveLabsFeedback = true;
  }

  @action.bound 
  setLabInviteGeneralInfo(pId: string, org: string, provider: string) {
    this.labInviteGeneralInfo = {
      PatientId: pId,
      OrgName: org,
      ProviderName: provider,
    }
  }
  
  @action.bound
  setHasWhiteBoxOpened(value: boolean) {
    this.hasWhiteBoxOpened = value;
  }

  @action.bound
  setCurrentLabPage(value: ILabResult) {
    this.currentLabPage = value;
  }

  @action.bound
  goToTab(tabToGo: MyHealthTabEnum) {
    this.currentTab = tabToGo;
  }

  @action.bound
  async setAllowInformationContinueUsedAfterWithdrawn(value: boolean) {
    this.labInviteData.AllowInformationContinueUsedAfterWithdrawn = value;
  }
  @action.bound
  async setReceiveSummaryAfterResearch(value: boolean) {
    this.labInviteData.ReceiveSummaryAfterResearch = value;
  }
  @action.bound
  async setConsent(value: boolean) {
    this.labInviteData.Consent = value;
  }
  async setPatientId(value: string) {
    this.labInviteData.PatientId = value;
  }

  @action.bound
  async postLabInvite(onSuccess: () => void) {
    this.loading = true

    await generalRequest.bind(this)({
      api: postLabInviteAPI(this.labInviteData),
      afterLoaded: (data: any) => {
        this.loading = false;
        onSuccess();
      },
      afterFailed: (err: any) => {
        this.loading = false;
        Alert.error(err.response.data.Message)
      }
    })
  }
  @action.bound
  async postLabResultFeedback(feedback: ISendFeedback, onSuccess: () => void) {
    this.loading = true;

    await generalRequest.bind(this)({
      api: postLabResultFeedbackAPI(feedback),
      afterLoaded: (data: any) => {
        this.loading = false
        onSuccess();
      },
      afterFailed: (err: any) => {
        this.loading = false;
        Alert.error(err.response.data.Message);
      }
    })
  }

  @action.bound
  async getSummary(onUnauth: () => void) {
    //if (this.isLoaded(this.summaryTabLoadingStatus)) return;
    
    this.loading = true;

    await generalRequest.bind(this)({
      loadingStatusName: 'summaryTabLoadingStatus',
      api: getSummaryAPI,
      dataPropertyName: 'summaryTab',
      afterLoaded: (data: any) => {
        this.loading = false;
        this.summaryTab = data;
      },
      afterFailed: (err: any) => {
        this.loading = false;
        if (err.response.status === 401) {
          onUnauth();
        }
        this.summaryTabErrorText = err.response.data.Message;
      },
    });
  }

  @action.bound
  async getLabResults() {
    if (this.isLoaded(this.labResultsTabLoadingStatus) && !this.justGaveLabsFeedback) return;
    this.loading = true;
    await generalRequest.bind(this)({
      loadingStatusName: 'labResultsTabLoadingStatus',
      api: getLabResultsAPI,
      afterLoaded: (data: any) => {
        this.loading = false;
        this.updateLabResults(data.LabResults);
        this.labPageFeedbackWhiteBox.show = data.NeedFeedBackForLabResearch;
        this.labResultNotification = data.LabResearchNotifications[0];
        this.labPageFeedbackWhiteBox.noteId = data.NoteId;
        this.labsErrorText = data.ErrorMessage;
        this.justGaveLabsFeedback = false;
      },
      afterFailed: (err: any) => {
        this.loading = false;
      }
    });
  }

  @action.bound
  async getLabResult(patientId: string, whenReceived: string, onSuccess: () => void) {
    this.loading = true;
    await generalRequest.bind(this)({
      api: getLabResultAPI(patientId, whenReceived),
      afterLoaded: (data: any) => {
        this.loading = false;
        this.currentLabPage = data;
        onSuccess();
      },
      afterFailed: (err: any) => {
        this.loading = false;
        Alert.error(err.response.data.Message);
      }
    })
  }
  @action.bound
  async getImmunisations() {
    if (this.isLoaded(this.immunisationsTabLoadingStatus)) return;
    this.loading = true;
    await generalRequest.bind(this)({
      loadingStatusName: 'immunisationsTabLoadingStatus',
      api: getImmunisationsAPI,
      afterLoaded: (data: any) => {
        this.loading = false;
        this.updateImmunisations(data.Immunisations);
        this.immunisationsErrorText = data.ErrorMessage;
      },
    });
  }

  @action.bound
  async getClinicNotes() {
    if (this.isLoaded(this.clinicNotesTabLoadingStatus)) return;
    this.loading = true;
    await generalRequest.bind(this)({
      loadingStatusName: 'clinicNotesTabLoadingStatus',
      api: getClinicNotesAPI,
      afterLoaded: (data: any) => {
        this.loading = false;
        this.updateClinicNotes(data.DoctorNotes);
        this.clinicalErrorText = data.ErrorMessage;
      },
    });
  }

  @action.bound
  async deleteComment(commentId: string) {
    this.loading = true;
    await generalRequest.bind(this)({
      api: deleteCommentAPI(commentId),
      afterLoaded: (data: any) => {
        this.loading = false;
      },
      afterFailed: (err: any) => {
        this.loading = false;
      }
    })
  }
  @action.bound
  async postNewComment(
    commentType: SelectedCommentTypeEnum,
    parentId: string,
    newComment: ICommentNew,
  ) {
    await generalRequest.bind(this)({
      loadingStatusName: 'postNewCommentLoadingStatus',
      api: postNewCommentAPI(newComment),
      afterLoaded: (response: IComment) => {
        const DATA_MAP = {
          [CommentType.LabResult]: {
            data: this.labResultsTab.all,
            update: this.updateLabResults,
          },
          [CommentType.Immunisation]: {
            data: this.immunisationsTab.all,
            update: this.updateImmunisations,
          },
          [CommentType.DoctorNotes]: {
            data: this.clinicNotesTab.all,
            update: this.updateClinicNotes,
          },
        };

        const { data, update } =
          // @ts-ignore
          DATA_MAP[newComment.CommentType];

        if (commentType === CommentType.DoctorNotes) {
          data.forEach((item: IClinicNote) => {
            if (item.Id === response.ParentId) {
              if (item.Comments === null) {
                item.Comments = [response];
              } else {
                item.Comments.unshift(response);
              }
            }
          });
        } else {
          data.forEach((item: IImmunisation | ILabResult) => {
            if (item.Id === parentId) {
              if (item.Comments === null) {
                item.Comments = [response];
              } else {
                item.Comments.unshift(response);
              }
            }
          });
        }

        update(data);
      },
    });
  }

  @action.bound
  setHealthNavigator({
    keywords,
    isOpen,
    link,
  }: {
    link?: string;
    keywords?: string;
    isOpen?: boolean;
  }) {
    if (keywords) {
      this.healthNavigator.keywords = keywords;
      this.healthNavigator.link = "";

    }

    if (link) {
      this.healthNavigator.link = link;
      this.healthNavigator.keywords = '';
    }

    if (isOpen != null) {
      this.healthNavigator.isOpen = isOpen;
    }
  }

  @action.bound
  private updateLabResults(data: ILabResult[]) {
    const newInLastMonthItems: ILabResult[] = [];
    const pastItems: ILabResult[] = [];
    const feedbackItems: ILabResult[] = [];

    data.forEach(item => {
      if (item.NeedFeedBackForLabResearch) { feedbackItems.push(item); }
      if (item.IsNewInPastMonth) {
        newInLastMonthItems.push(item);
      } else {
        pastItems.push(item);
      }
    });

    this.labResultsTab.all = data;
    this.labResultsTab.newInLastMonth = newInLastMonthItems;
    this.labResultsTab.past = pastItems;
    this.labResultsTab.feedback = feedbackItems;
  }

  @action.bound
  private updateImmunisations(data: IImmunisation[]) {
    const givenWithLast3Months: IImmunisation[] = [];
    const pastItems: IImmunisation[] = [];

    data.forEach(item => {
      if (item.IsNewInPast3Month) {
        givenWithLast3Months.push(item);
      } else {
        pastItems.push(item);
      }
    });

    this.immunisationsTab.all = data;
    this.immunisationsTab.givenWithLast3Months = givenWithLast3Months;
    this.immunisationsTab.past = pastItems;
  }

  @action.bound
  private updateClinicNotes(data: IClinicNote[]) {
    const newInLastMonthItems: IClinicNote[] = [];
    const pastItems: IClinicNote[] = [];

    data.forEach(item => {
      if (item.IsNewInPastMonth) {
        newInLastMonthItems.push(item);
      } else {
        pastItems.push(item);
      }
    });

    this.clinicNotesTab.all = data;
    this.clinicNotesTab.newInLastMonth = newInLastMonthItems;
    this.clinicNotesTab.past = pastItems;
  }

  private isLoaded(status: LoadingStatusType) {
    return status === LOADING_STATUS.LOADED;
  }
}
