import React from 'react';
import PropTypes from 'prop-types';

import styles from './desktopCard.module.css';

const DesktopCard = ({ children, width }) => (
  <div className={styles.container} style={{ width: `${width}px` }}>
    {children}
  </div>
);

DesktopCard.propTypes = {
  children: PropTypes.element.isRequired,
  width: PropTypes.string.isRequired,
};

export default DesktopCard;
