import HomePanel from 'components/domain/careCircle/HomePanel';
import Slogan from 'components/domain/careCircle/Slogan';
import StadiumButton from 'components/shared/common/StadiumButton';
import HeaderCardUI from 'components/shared/mobile/HeaderCardUI';
import { isDesktop } from 'config';
import { withDesktop } from 'hocs/withDesktop';
import { withStyles } from 'hocs/withStyles';
import useLoadingWithSpinner from 'hooks/useLoadingWithSpinner';
import React, { ComponentType, PropsWithChildren, useState } from 'react';
import { go } from 'routes';
import { useCareCircle } from 'store/mobx/mobx';
import { Dependant } from 'types/careCircle';
import {ReactComponent as Close} from 'svgs/whiteCrossSmall.svg';

const adapter = (touch: boolean) => ({
  addingButton: {
    height: touch ? '72px' : '50px',
    fontSize: touch ? '17px' : '13px',
  }
});

type Props = {}

const Home = (props: Props) => {
  const touchFlag = !isDesktop();
  const [sloganFlag, setSloganFlag] = useState(localStorage.getItem('sloganFlag') === null);
  const toggleOffSlogan = () => {
    setSloganFlag(false);
    localStorage.setItem('sloganFlag', 'close');
  }
  const adapt = adapter(touchFlag);
  
  let Page: ComponentType<PropsWithChildren<{data?: Dependant[]}>>, AddingButton;
  if(touchFlag) {
    Page =  ({data, children}) => (
      <HeaderCardUI
        text='My Care Circle'
        cardStyle={{borderRadius: '4px'}}
        handleClick={() => {go.home();}}
        align='center'
        icon={Close}
        outsideChildren={<HomePanel data={data} touch/>}>
        {children}
      </HeaderCardUI>);
    AddingButton = withStyles({position: 'absolute', right: '2rem', bottom: '1.875rem'})(StadiumButton);
  }else{
    const DesktopPage = withDesktop(HomePanel);
    Page = ({data, children}) =>
      <>
        <DesktopPage 
          crumbs={[
            {name: "My Care Circle", path: "/careCircle"}
          ]}
          idKey="Care Circle"
          data={data}>
            {children}
          </DesktopPage>
      </>
      
    AddingButton = withStyles({position: 'absolute', right: '2rem', top: '1.875rem'})(StadiumButton);
  }
  
  const store = useCareCircle();
  const RenderPage = useLoadingWithSpinner(store.getDependants.bind(store), Page);
  
  return (
    <>
      <AddingButton 
        height={adapt.addingButton.height}
        fontSize={adapt.addingButton.fontSize}
        text='+ Add to my Circle' 
        handleClick={go.addDependant}/>
      <RenderPage data={store.dependants}>
        {sloganFlag && <Slogan touch={touchFlag} banner={store.dependants && store.dependants.length > 0}  handleClose={toggleOffSlogan}/>}
      </RenderPage>
    </>
  );
}

export default Home;