import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash/fp';

import BookedItem from '../../pages/mobile/domain/Appointment/BookedAppointment/index';
import PastItem from '../../pages/mobile/domain/Appointment/PastAppointment';
import NoAppointmentCard from './NoAppointmentCard';
import styles from './appointmentsListMobile.module.css';
import { appointmentPropType } from '../propTypes';
import classnames from 'classnames';
import { useAppointmentStore } from 'store/mobx/mobx';

const AppointmentsList = ({
  currentAppointments,
  pastAppointments,
  toggleOpenCardState,
  noAppointmentClick,
  isOpen,
}) => {
  const store = useAppointmentStore();
  store.loadAppointments(currentAppointments, 'booked');
  store.loadAppointments(pastAppointments, 'past');

  const bookedData = currentAppointments || store.bookedAppointments;
  const pastData = pastAppointments || store.pastAppointments;

  return (
    <div className={classnames({ [styles.closed]: !isOpen })}>
      {bookedData.length > 0 ? (
        map(a => (
          <BookedItem
            key={a.Id}
            data={a}
            from='/home?goTo=1'
          />
        ))(bookedData)
      ) : (
        <NoAppointmentCard onClick={noAppointmentClick} />
      )}
      {pastData.length > 0 && (
        <div>
          <div className={styles.label}>PAST APPOINTMENTS</div>
          <div className={styles.pastList}>
            {map(a => (
              <PastItem
                key={a.Id}
                data={a}
              />
            ))(pastData)}
          </div>
        </div>
      )}
    </div>
)};

AppointmentsList.propTypes = {
  toggleOpenCardState: PropTypes.func.isRequired,
  currentAppointments: PropTypes.arrayOf(appointmentPropType).isRequired,
  pastAppointments: PropTypes.arrayOf(appointmentPropType).isRequired,
  noAppointmentClick: PropTypes.func.isRequired,
};

export default AppointmentsList;
