import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash/fp';

import AppointmentCard from '../../../pages/mobile/domain/Appointment/BookedAppointment';
import PrescriptionCard from '../../../PrescriptionsPage/PrescriptionListMobile/PrescriptionCard';
import PrivacyPolicy from '../../PrivacyPolicy';
import { appointmentPropType } from '../../../AppointmentsPage/propTypes';
import { scriptPropType } from '../../../PrescriptionsPage/propTypes';
import { ImmunisationItem } from 'MyHealthPage/Tabs/ImmunisationsTab/components/ImmunisationItem.tsx';
import { LabResultsItem } from 'MyHealthPage/Tabs/LabResultsTab/components/LabResultsItem.tsx';
import { ClinicalNoteItem } from 'MyHealthPage/Tabs/ClinicalNotesTab/components/ClinicalNoteItem.tsx';
import styles from './feedList.module.css';
import { useAppointmentStore, useMyHealthStore } from 'store/mobx/mobx';

const FeedList = ({ feed, setCtaText, toggleOpenCardState }) => {
  const store = useMyHealthStore();
  const [requestSent, setRequestSent] = useState({
    labs: false,
    imms: false,
    notes: false
  })
  const appointStore = useAppointmentStore();
  const appointments = feed.filter(a => a.Type === 'Appointment');
  appointments && appointStore.loadAppointments(appointments.map(a => a.Item), 'booked');
  return (
    <div className={styles.container}>
      {map(f => {
        if (f.Type === 'Appointment') {
          return (
            <AppointmentCard
              key={f.Id}
              data={f.Item}
              from='/home?goTo=0'
            />
          );
        }
        if (f.Type === 'LabResults') {
          if (!requestSent.labs) {
            store.getLabResults();
            setRequestSent({labs: true, imms: requestSent.imms, notes: requestSent.notes})
          }
      
          return (
            <div style={{marginBottom: "1rem"}} key={f.Id}>
              <LabResultsItem key={f.Item.Id} {...f.Item} />
            </div>
          ) 
        }
        if (f.Type === 'Immunisations') {
          if (!requestSent.imms) {
            store.getImmunisations();
            setRequestSent({labs: requestSent.labs, imms: true, notes: requestSent.notes})
          }
          return (
            <div style={{marginBottom: "1rem"}} key={f.Id}>
              <ImmunisationItem key={f.Item.Id} {...f.Item} />
            </div>
          ) 
        }
        if (f.Type === 'DoctorNotes') {
          if (!requestSent.notes) {
            store.getImmunisations();
            setRequestSent({labs: requestSent.labs, imms: requestSent.imms, notes: true})
          }
          return (
            <div style={{marginBottom: "1rem"}} key={f.Id}>
              <ClinicalNoteItem key={f.Item.Id} {...f.Item} />
            </div>
          ) 
        }
        if (f.Type === 'Prescription') {
          return (
            <PrescriptionCard
              key={f.Id}
              script={f.Item}
              setCtaText={setCtaText}
              toggleOpenCardState={toggleOpenCardState}
            />
          );
        }
      })(feed)}
      <PrivacyPolicy />
    </div>
  )
};

FeedList.propTypes = {
  feed: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.string.isRequired,
      Type: PropTypes.string.isRequired,
      Item: PropTypes.oneOfType([appointmentPropType, scriptPropType])
        .isRequired,
    }),
  ),
  setCtaText: PropTypes.func.isRequired,
  toggleOpenCardState: PropTypes.func.isRequired,
};

export default FeedList;
