import moment from 'moment';
import React from 'react';
import { Appointment, ClickableProps, ValueProps } from '../../types';
import styles from './index.module.scss';

const index: React.FC<ClickableProps<Appointment>> = ({data, handleClick}) => {
    return (
        <div className={styles.card} onClick={handleClick}>
            <img src={data.ProviderPictureUrl} alt={data.ProviderName} />
            <div className={styles.infoContainer}>
                <span>{data.ProviderName}</span>
                <span>{`at ${data.PracticeName}`}</span>
                <span className={styles.secondaryInfo}>{`for ${data.FirstName} ${data.LastName}`} </span>
            </div>
            <span className={styles.time}>{moment(data.Time).format('DD/MM/YY')}</span>
        </div>);
};

export default index;
