const actionTypes = {
    setPractice: 'SET_PRACTICE',
    setPhoto: 'SET_PHOTO',
    sendVerifyRequest: 'SEND_VERIFY_REQUEST',
    sendVerifyRequestSuccess: 'SEND_VERIFY_REQUEST_SUCCESS',
    sendVerifyRequestFailure: 'SEND_VERIFY_REQUEST_FAILURE',
    setSettings: "SET_SETTINGS",
    setPin: "SET_PIN"
  };
  
  const actions = {  
    setPractice: practice => ({
      type: actionTypes.setPractice,
      payload: {
        practice,
      },
    }),
    setPhoto: photo => ({
      type: actionTypes.setPhoto,
      payload: {
        photo,
      }
    }),
    sendVerifyRequest: (token, user) => ({
      type: actionTypes.sendVerifyRequest,
      payload: {
        token,
        user,
      }
    }),
    sendVerifyRequestSuccess: data => ({
      type: actionTypes.sendVerifyRequestSuccess,
      payload: {
        data,
      }
    }),
    sendVerifyRequestFailure: error => ({
      type: actionTypes.sendVerifyRequestFailure,
      payload: {
        error,
      }
    }),
    setSettings: settings => ({
      type: actionTypes.setSettings,
      payload: {
        settings
      }
    }),
    setPin: pin => ({
      type: actionTypes.setPin,
      payload: {
        pin
      }
    })
  };
  
  export { actions, actionTypes };