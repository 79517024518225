import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './desktopCta.module.css';

const DesktopCta = ({
  text,
  onClick,
  background = false,
  absolute = false,
  className,
}) => (
  <button
    className={classnames(
      styles.container,
      className,
      { [styles.background]: background },
      { [styles.absolute]: absolute },
    )}
    onClick={onClick}
  >
    {text}
  </button>
);

DesktopCta.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  background: PropTypes.bool,
  absolute: PropTypes.bool,
};

export default DesktopCta;
