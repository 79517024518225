import React from 'react';

import styles from './noPracticesCard.module.css';

const NoPracticesCard = () => (
  <div className={styles.container}>
    <div className={styles.title}>No practices added</div>
    <div className={styles.text}>
      Adding your practice gives you quick access to your practice&#39;s profile
      page
    </div>
  </div>
);

NoPracticesCard.propTypes = {};

export default NoPracticesCard;
