import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import * as Styled from './SecurityPage.styled';
import UIContainer from '../components/UIContainer';
import SecuritySection from './components/SecuritySection';
import SecuritySectionAction from './components/SecuritySectionAction';
import Toggle from './components/Toggle';
import CardButton from '../Identity/components/CardButton';
import { go } from 'routes';
import { useComponentDidMount } from 'libs/newHttp';
import { useAccountStore, useIdentityStore, useSecurityStore } from 'store/mobx/mobx';
import ChangePassword from 'UserAccountPage/ChangePassword';
import LearnMorePopUp from 'components/LearnMorePopUp/LearnMorePopUp';

const Title = styled.div`
    color: #6C6C6C;
    font-weight: 600;
    font-size: 15px;
    margin: 1.2rem 1rem;
`
const DarkLine = styled.div`
    width: 100%;
    border-bottom: 1px solid #DADADA;
`
const LightLine = styled.div`
    width: 100%;
    border-bottom: 1px solid #F0F0F0;
`
const Text = styled.div`
    color: #989898;
    font-size: 12px;
    margin: 0.5rem 1rem;
    margin-bottom: 0rem;
`
const Link = styled.div`
    color: #3CB9C0;
    font-size: 12px;
    margin: 2rem 1rem;
    margin-top: 0rem;
`
const FlexR = styled.div`
    display: flex;
    flex-direction: row;
`
const FlexC = styled.div`
    display: flex;
    flex-direction: column;
`
const Contact = styled.div`
    color: #6C6C6C;
    font-size: 15px;
    margin: 0.6rem 1rem;
    width: 55%;
`
const Verified = styled.div`
    color: #23C373;
    font-size: 15px;
    margin: 0.6rem 1rem;
`
const Unverified = styled.div`
    color: #FF6A65;
    font-size: 15px;
    margin: 0.6rem 1rem;
`
const RecoveryButton = styled.div`
    color: #07A3C8;
    font-size: 15px;
    margin: 0.6rem 1rem;
`
const Label = styled.div`
    color: #6C6C6C;
    font-size: 15px;
    margin: 0rem 1rem;
    width: 55%;
`

const SecurityPage: React.FC = observer(() => {
    const accStore = useAccountStore();
    const idStore = useIdentityStore();
    const secStore = useSecurityStore();
    const [changePassword, setChangePassword] = useState(false);
    const [popUp, setPopUp] = useState(false);

    let email = accStore.profile.Email.length > 24 ? accStore.profile.Email.substring(0, 22) + "..." : accStore.profile.Email;
    let emailR = "";

    if (accStore.profile.RecoveryEmail) {
        emailR = accStore.profile.RecoveryEmail.length > 24 ? accStore.profile.RecoveryEmail.substring(0, 22) + "..." : accStore.profile.RecoveryEmail;
    }

    useComponentDidMount(() => {
        // accStore.getProfile();
        secStore.getSecuritySettings();
        window.scrollTo({top: 0, behavior: 'smooth'});
    });

    return (
        <UIContainer heading={"Security"} goBackFn={() => changePassword ? setChangePassword(false) : go.settings()} isFetching={secStore.loading || idStore.loading || accStore.loading}>
                <div style={{overflow: popUp ? "visible" : "scroll"}}>            
                    <Styled.SecurityPageContainer>
                        <Title>Security Settings</Title>
                        <DarkLine />
                        <div style={{margin: "0px 17px", padding: "14px 0px"}}>
                            <Toggle
                                value={secStore.securitySettings.SignIn2StepsVerification}
                                labelElement={accStore.profile.SecurityPinCodeSetup ? "2 step sign in verification" : "Set up PIN Code"}
                                disabled={false}
                                onClick={() => {
                                    if (accStore.profile.SecurityPinCodeSetup) {
                                        secStore.editSecuritySettings({
                                            SignIn2StepsVerification: !secStore.securitySettings.SignIn2StepsVerification,
                                            NotifyUserAfter5FailedAttempts: secStore.securitySettings.NotifyUserAfter5FailedAttempts,
                                            SuspendAccountAfter10FailedAttempts: secStore.securitySettings.SuspendAccountAfter10FailedAttempts,
                                        }, false)
                                    } else {
                                        go.editPin(1);
                                    }
                                }}
                            />
                        </div>
                        <LightLine />
                        <CardButton 
                            text={"Change PIN Code"}
                            onClick={() => go.editPin()}
                            removeBorder={true}
                            disabled={!secStore.securitySettings.SignIn2StepsVerification}
                        />
                        <LightLine />
                        <Text>If you have sign-in 2-step verification on, you will be asked for your PIN code when you sign in to your account. This step is great for protecting your information.</Text>
                        <Link onClick={() => setPopUp(true)}>Learn more why this is important</Link>
                        <LightLine />
                        <div style={{margin: "0px 17px", padding: "14px 0px"}}>
                            <Toggle
                                value={secStore.securitySettings.NotifyUserAfter5FailedAttempts}
                                labelElement={"Failed attempts notification"}
                                disabled={!secStore.securitySettings.SignIn2StepsVerification}
                                onClick={() => {
                                        secStore.editSecuritySettings({
                                            SignIn2StepsVerification: secStore.securitySettings.SignIn2StepsVerification,
                                            NotifyUserAfter5FailedAttempts: !secStore.securitySettings.NotifyUserAfter5FailedAttempts,
                                            SuspendAccountAfter10FailedAttempts: secStore.securitySettings.SuspendAccountAfter10FailedAttempts,
                                        }, false)
                                }}
                            />
                        </div>
                        <LightLine />
                        <Text style={{marginBottom: "2rem"}}>
                            Notify me after 5 failed PIN code attempts on my account
                        </Text>
                        <LightLine />
                        <div style={{margin: "0px 17px", padding: "14px 0px"}}>
                            <Toggle
                                value={secStore.securitySettings.SuspendAccountAfter10FailedAttempts}
                                disabled={!secStore.securitySettings.SignIn2StepsVerification}
                                labelElement={"Suspend account"}
                                onClick={() => {
                                        secStore.editSecuritySettings({
                                            SignIn2StepsVerification: secStore.securitySettings.SignIn2StepsVerification,
                                            NotifyUserAfter5FailedAttempts: secStore.securitySettings.NotifyUserAfter5FailedAttempts,
                                            SuspendAccountAfter10FailedAttempts: !secStore.securitySettings.SuspendAccountAfter10FailedAttempts,
                                        }, false)
                                }}
                            />
                        </div>
                        <LightLine />
                        <Text>Suspend my account after 10 failed PIN code attemps. You will receive an email with an incident report and instructions on how to reactivate your account.</Text>
                        <br />
                        <Title>Trusted Email & Mobile</Title>
                        <DarkLine />
                        <FlexC style={{margin: "0.7rem 0rem"}}>
                            {/* <FlexR onClick={() =>  go.emailVerification(2, true, true)}>
                                {emailR !== "" &&
                                    <FlexC style={{width: "64%"}} onClick={() => go.emailVerification(2, true, true)}>
                                        <Label>Account Email:</Label>
                                        <Contact style={{marginTop: "0px"}}>{email}</Contact>
                                    </FlexC>
                                } {emailR === "" &&
                                    <Contact>{email}</Contact>
                                }
                                {accStore.profile.EmailVerified ? <Verified>Verified &gt;</Verified> : <Unverified>Unverified &gt;</Unverified>}
                            </FlexR> */}
                            <FlexR>
                                {emailR !== "" &&
                                    <FlexC style={{width: "64%"}}>
                                        <Label>Account Email:</Label>
                                        <Contact style={{marginTop: "0px"}}>{email}</Contact>
                                    </FlexC>
                                } {emailR === "" &&
                                    <Contact>{email}</Contact>
                                }
                                {accStore.profile.EmailVerified ? <Verified>Verified &gt;</Verified> : <Unverified>Unverified &gt;</Unverified>}
                            </FlexR>
                            {/* {emailR !== "" &&
                                <FlexR onClick={() => go.emailVerification(2, false, true)}>
                                    <FlexC style={{width: "64%"}}>
                                        <Label>Recovery Email:</Label>
                                        <Contact style={{marginTop: "0px"}}>{emailR}</Contact>
                                    </FlexC>
                                    <Verified>Verified &gt;</Verified>
                                </FlexR>
                            } */}
                            <FlexR onClick={() => go.accountVerification("true")}>
                                <Contact>{accStore.profile.Mobile}</Contact>
                                {accStore.profile.MobileVerified ? <Verified>Verified &gt;</Verified> : <Unverified>Unverified &gt;</Unverified>}
                            </FlexR>
                            {/* {emailR === "" &&
                                <RecoveryButton onClick={() => go.emailVerification(2, false, true)}>Add Additional Recovery Email</RecoveryButton>
                            } */}
                            <DarkLine />
                            <Text>Trusted phone numbers and emails are used to verify your identity when signing in (along with your PIN code) and to help recover your account if you have forgotten your info.</Text>
                            <br />
                            <Title>Password</Title>
                            <DarkLine />
                            <CardButton 
                                text={"Change Password"}
                                onClick={() => go.changePassword()}
                                removeBorder={true}
                            />
                            <DarkLine />
                            <br />
                        </FlexC>
                    </Styled.SecurityPageContainer>
                    {popUp &&
                        <LearnMorePopUp type={"SECURITY_FAQ"} hide={() => setPopUp(false)}/>    
                    }
                </div>
        </UIContainer>
    )

            
        
})

export default SecurityPage;