import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-pose';

import { goBack } from '../../../../routes/utils';
import styles from './desktopHeading.module.css';
import { WhiteNotificationBox } from '../../MobileUI/WhiteNotificationBox/WhiteNotificationBox';


const DesktopHeader = ({ heading, secondaryHeading, }) => (
  <div className={styles.container}>
    {secondaryHeading ? (
      <span onClick={goBack} className={styles.goBack}>{`${heading} /`}</span>
    ) : (
      heading
    )}
    {secondaryHeading && (
      <span style={{ marginLeft: "0.33rem" }}>{secondaryHeading}</span>
    )}
  </div>
);

DesktopHeader.propTypes = {
  heading: PropTypes.string.isRequired,
  secondaryHeading: PropTypes.string,
};

export default DesktopHeader;
