import { actionTypes } from '../actions/actions';

const INITIAL_STATE = {
  patient: {},
  practice: {},
  doctor: {},
  reason: '',
  medications: [],
  pricing: {},
  repeatPastRequest: false,
  payOnline: true,
};

const requestScriptReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.setInfo:
      return {
        ...state,
        [action.payload.id]: action.payload.value,
      };
    case actionTypes.repeatScript: {
      const details = action.payload.details;
      return {
        ...state,
        patient: {
          FirstName: details.firstName,
          LastName: details.lastName,
          Id: details.patientId,
          AvatarUrl: details.avatarUrl,
        },
        practice: {
          Id: details.orgId,
          Name: details.orgName,
          PaymentEnabled: details.paymentEnabled,
        },
        doctor: {
          Id: details.doctorId,
          Name: details.doctorName,
        },
        medications: details.medications,
        repeatPastRequest: true,
      };
    }
    case actionTypes.clearScriptInfo:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default requestScriptReducer;
