import React, { lazy } from 'react';
import { isDesktop } from '../../config';
import { ProtectedRoute, push } from '../utils.js';
import DesktopPrivacy from '../../Privacy/DesktopPrivacy/DesktopPrivacy';
import DesktopPrivacySettings from '../../Privacy/DesktopPrivacy/components/PrivacySettings';
import MobilePrivacySettings from '../../Privacy/PrivacySettings/index';
import MobilePrivacy from '../../Privacy/index.js'

const go = { 
  privacy: () => push('/privacy'),
  privacySettings: (orgId) => push({
    pathname: '/privacy-settings', 
    search: '?id=' + orgId,
  })
}

const PrivacyRoutes = [
  <ProtectedRoute
    exact
    key="/privacy"
    path="/privacy"
    component={(isDesktop() ? DesktopPrivacy : MobilePrivacy)}
  />,

  <ProtectedRoute
  key="/privacy-settings"
  path="/privacy-settings"
  component={(isDesktop() ? DesktopPrivacySettings : MobilePrivacySettings)}
/>
];

export default PrivacyRoutes;
export { go };
