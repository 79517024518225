import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import classnames from "classnames";
import { observer } from 'mobx-react-lite';

import { actions as feelingActions } from './actions/actions';
import { sessionState, sessionDispatch, allSessionProps } from '../../selectors/sessionSelectors';
import { findDoctor } from '../../selectors/appointmentSelectors';
import styles from './reason.module.css';
import FeelingForm from './components/FeelingForm/index';
import CtaButton from '../../components/CtaButton/index';
import Header from '../../components/UIContainer/MobileUI/header/index';
import IAmFeelingUrl from '../../images/Iamfeeling.png';
import {
	DoctorProfilePropType,
	PracticeInfoPropType,
} from '../../PracticeProfilePage/BookingPageMobile/propTypes/index';
import { detailsPropType } from '../AppointmentConfirmation/propTypes/index';
import { goBack } from '../../routes/utils';
import { go } from '../../routes/index';
import { isDesktop } from '../../config';
import { ReactComponent as RightArrow } from '../../svgs/rightarrow.svg';
import FeelingFormMobile from './components/FeelingFormMobile/index';
import cssVars from 'css-vars-ponyfill';
import { useLocation } from 'react-router';
import Loading from 'components/loadingSpinner';
import { useAppointmentStore, usePracticesStore } from 'store/mobx/mobx';
import { useComponentDidMount } from 'libs/newHttp';
import styled from 'styled-components';

const Scroller = styled.ul`

`
const Reason = observer(({
	addFeelings,
	skipFeelings,
	createSession,
	getConsultMethods,
	clearFeelings,
	isFetching,
	viewPatientDetails,
	practice,
	appointment,
	doctor,
	viewGuardianDetails,
	token,
	_note,
	firstName,
	isBookingForSomeoneElse,
	isFormValid,
	sessionDetails,
	reasons,
	postService,
	updateSession
}) => {
	const location = useLocation();
	const store = useAppointmentStore();
	const practiceStore = usePracticesStore();

	const [receivedFlag, setReceivedFlag] = useState(false);
	const [showList, setShowList] = useState(false);
	const [selected, setSelected] = useState(false);
	const [methods, setMethods] = useState([]);
	const [note, setNote] = useState("");
	const [service, setService] = useState("");
	const [clinicalReasons, setClinicalReasons] = useState([{}, {}]);
	//const [reasons, setReasons] = useState(store.Reasons);
	const [showSearch, setShowSearch] = useState([false, false, false]);
	const [Services, setServices] = useState(store.Reasons.Services);
	const [Symptoms, setSymptoms] = useState(store.Reasons.Symptoms);
	const [EmergencySymptoms, setEmergencySymptoms] = useState([]);
	const [ByPassCodeTwoSymptoms, setByPassCodeTwoSymptoms] = useState([]);
	const [selectMethod, setSelectMethod] = useState(""); // selected consult method
	const [selectedService, setSelectedService] = useState({});
	/*

  UNSAFE_componentWillReceiveProps(nextProps) {
	if (reasons === undefined) { window.location.reload(); return; }
	if (nextProps.methods !== undefined && nextProps.methods.length !== 0 && methods !== nextProps.methods) {
	  this.setState({selectedMethod: nextProps.methods[0].Code})
	}
	let emergencyReasons = [];
	let byPassCodeTwo = [];
	let tempList = [];

	if (this.props.reasons.Symptoms !== undefined) {
	  console.log(this.props.reasons)
	  this.props.SymptomsObj.map((element) => {
		if (element.ByPassCode === 1) { emergencyReasons.push(element.Name); }
		if (element.ByPassCode === 2) { byPassCodeTwo.push(element.Name); }

  
		tempList.push(element.Name);
	  });
	  console.log(tempList);
	}


	this.setState({ 
	  reasons: this.props.reasons,
	  Services: this.props.reasons.Services,
	  Symptoms: tempList,
	  methods: this.props.methods,
	  EmergencySymptoms: emergencyReasons,
	  ByPassCodeTwoSymptoms: byPassCodeTwo,
	})
  }*/


	useComponentDidMount(() => {
		cssVars();
		/*if (location.state.consult) {
			store.getConsultMethods(practice.Id);  
		}*/

		//store.getReasons(practice.UrlName);  
		if (!isDesktop()) {
			window.scrollTo(0, 0);
		}
	});

	const onChange = e => {
		setNote(e.target.value);
	};

	const changeService = (service) => {
		setService(service);
	};

	const changeReason = (reason, i) => {
		let reasons = clinicalReasons;
		reasons[i] = reason;

		setClinicalReasons(reasons);
	};

	const handleCta = () => {
		const symptoms = clinicalReasons;
		let urgent = false;
		let corona = false;
		EmergencySymptoms.forEach(function (item) {
			if ((item.localeCompare(symptoms[0]) === 0)) { urgent = true; }
			if ((item.localeCompare(symptoms[1]) === 0)) { urgent = true; }
		});

		ByPassCodeTwoSymptoms.forEach(function (item) {
			if ((item.localeCompare(symptoms[0]) === 0)) { corona = true; }
			if ((item.localeCompare(symptoms[1]) === 0)) { corona = true; }
		});

		/*
		if (note.length) skipFeelings();
		else addFeelings(service);
*//*
										let str = ""
										
										if (location.state.consult) {
											str += selectMethod + "//"
										}
								
										if(service.length > 0) {
											str += service + "//"
										}
								
										for (let i = 0; i < clinicalReasons.length; i++) {
											if (clinicalReasons[i] !== {}) {
												str += clinicalReasons[i].Name + "//"
											}
										}
								
										if (note.length > 0) {
											str += note;
										}
								
										addFeelings(str);*/

		let reasonOne = clinicalReasons[0];
		let reasonTwo = clinicalReasons[1];

		if ((reasonOne === {} && reasonTwo !== {}) || (reasonOne === {} && reasonTwo !== {}) || (reasonOne === {} && reasonTwo === {})) {
			if (reasons.NoSymptomDefaultByPassCode === 1) {
				urgent = true;
			} else if (reasons.NoSymptomDefaultByPassCode === 2) {
				corona = true;
			}
		} else {
			if (reasonOne.ByPassCode === 1 || reasonTwo.ByPassCode === 1) urgent = true;
			if (reasonOne.ByPassCode === 2 || reasonTwo.ByPassCode === 2) corona = true;
		}

		var symptomIds = [];

		if (reasonOne.Id !== undefined) symptomIds.push(reasonOne.Id);
		if (reasonTwo.Id !== undefined) symptomIds.push(reasonTwo.Id);

		if (urgent) { go.urgentAttention(practice.UrlName, practice, sessionDetails.SessionId, selectMethod, symptomIds, note, JSON.stringify(selectedService)) }
		else if (corona) {
			go.corona(practice.UrlName, practice, reasons.AllowContinueWithCode2, reasons.Code2WarningMessage, sessionDetails.SessionId, selectMethod, symptomIds, note, JSON.stringify(selectedService))
		}
		else {
			updateSession(sessionDetails.SessionId, selectMethod, symptomIds, note, selectedService, practice.UrlName);
		}
	};

	const removeDisplayNone = () => {
		setSelected(true);
	}

	const toggleList = (show) => {
		setShowList(show);
	};

	const setSearch = (i, value) => {
		let search = [...showSearch];
		search[i] = value;

		setShowSearch(search);
	}

	const stopAllSearch = () => {
		setShowSearch([false, false, false]);
	}

	let s1 = showSearch.includes(true) && !showSearch[0] ? true : false;
	let s2 = showSearch.includes(true) && !showSearch[1] ? true : false;
	let s3 = showSearch.includes(true) && !showSearch[2] ? true : false;

	return (
		<div className={styles.feelingPage}>

			{!isDesktop() && !showSearch.includes(true) && <Header headerClassNames={styles.header} goBackFn={() => go.patientSelection(practice.UrlName)} showHelp />}
			<Scroller className={styles.formContainer}>
				{isDesktop() && (
					<div>
						<div className={styles.desktopLabel}>What&#39;s your reason for consultation to help us plan your visit better?</div>
					</div>
				)}
				<div className={styles.label} style={{ display: showSearch.includes(true) ? "none" : "" }}>What&#39;s your reason for consultation to help us plan your visit better?</div>
				<div className={styles.formInnerContainer}>
					{isDesktop() ? (
						<FeelingForm
							visible={practiceStore.selectedPractice.ModuleVisibility.includes("Reason For Visit")}
							isFetching={isFetching}
							reasons={reasons}
							Services={reasons.Services}
							Symptoms={reasons.Symptoms}
							changeService={changeService}
							changeReason={changeReason}
							isBookingForSomeoneElse={isBookingForSomeoneElse}
							onChange={onChange}
							note={note}
							name={firstName}
							consult={true}
							methods={reasons.ConsultationMethodes}
							setMethod={(method) => setSelectMethod(method)}
							showWarning={reasons.ShowingWarning}
							warningMessage={reasons.WarningMessage}
							postService={(item) => setSelectedService(item)}
							sessionDetails={sessionDetails}
						/>
					) : (
						<div>
							<FeelingFormMobile
								s1={s1}
								s2={s2}
								s3={s3}
								anySearchOpen={showSearch.includes(true)}
								setShowSearch={setSearch}
								stopAllSearch={stopAllSearch}
								visible={practiceStore.selectedPractice.ModuleVisibility.includes("Reason For Visit")}
								reasons={reasons}
								Services={reasons.Services}
								Symptoms={reasons.Symptoms}
								changeService={changeService}
								changeReason={changeReason}
								isBookingForSomeoneElse={isBookingForSomeoneElse}
								onChange={onChange} note={note}
								name={firstName}
								methods={reasons.ConsultationMethodes}
								consult={true}
								setMethod={(method) => setSelectMethod(method)}
								showWarning={reasons.ShowingWarning}
								warningMessage={reasons.WarningMessage}
								postService={(item) => setSelectedService(item)}
								sessionDetails={sessionDetails}
							/>
						</div>
					)}
				</div>
			</Scroller>
			<div className={styles.footer} style={{ display: showSearch.includes(true) ? "none" : "" }}>
				<div className={styles.bottomNote}>
					Note that practice staff can view this information.
				</div>
				<CtaButton className={styles.cta} onClick={handleCta} active={service !== ""} />
			</div>
		</div>
	);
})

// get the patient whom the appointment is booked for
const getPatientDetails = (isLoggedIn, isBookingForSomeoneElse, profile, sessionDetails) => {
	if (isLoggedIn && !isBookingForSomeoneElse) {
		return profile;
	}
	return {
		DependantId: sessionDetails.dependantId,
		FirstName: sessionDetails.firstName,
		LastName: sessionDetails.lastName,
		Mobile: sessionDetails.mobile,
		DateOfBirth: sessionDetails.dateOfBirth,
		MobileVerified: profile.MobileVerified,
	};
};
const getGuardianDetails = (isBookingForSomeoneElse, token, sessionDetails) => {
	if (isBookingForSomeoneElse && !token) {
		return {
			FirstName: sessionDetails.initiatorFirstName,
			LastName: sessionDetails.initiatorLastName,
			Mobile: sessionDetails.initiatorMobile,
			DateOfBirth: sessionDetails.initiatorDateOfBirth,
		};
	}
	return null;
};
const mapStateToProps = state => {
	const sessionProps = sessionState([])(state);
	const token = state.loginReducer.token;
	const profile = state.profileReducer.profile;
	const viewPatientDetails = getPatientDetails(
		token,
		sessionProps.isBookingForSomeoneElse,
		profile,
		sessionProps,
	);
	const viewGuardianDetails = getGuardianDetails(
		sessionProps.isBookingForSomeoneElse,
		token,
		sessionProps,
	);

	return {
		token,
		...sessionProps,
		viewPatientDetails,
		viewGuardianDetails,
		reasons: state.feelingPageReducer.reasons,
		methods: state.feelingPageReducer.methods,
		isFetching: state.sessionReducer.isFetching || state.feelingPageReducer.isFetching,
		// @TODO: put this in a selector
		doctor: findDoctor(
			state.practiceAppointmentReducer.selectedTime.doctorId,
			state.practiceAppointmentReducer.doctorInfo.doctors.length
				? state.practiceAppointmentReducer.doctorInfo.doctors
				: state.practiceProfileReducer.profile.Doctors,
		),
		appointment: moment(state.practiceAppointmentReducer.selectedTime.time),
		practice: isDesktop()
			? state.practiceProfileReducer.profile
			: state.practiceProfileReducer.profile || {},
		note: state.feelingPageReducer.feelings,
		service: state.service,
		symptoms: state.symptoms,
		SymptomsObj: state.feelingPageReducer.Symptoms,
		received: state.feelingPageReducer.received,
		showWarning: state.feelingPageReducer.showWarning,
		warningMessage: state.feelingPageReducer.warningMessage,
		allowContinueWithCode2: state.feelingPageReducer.allowContinueWithCode2,
		code2WarningMessage: state.feelingPageReducer.code2WarningMessage,
		noSymptomDefaultByPassCode: state.feelingPageReducer.noSymptomDefaultByPassCode,
		patientId: state.checkEnrollmentReducer.patientId,
		sessionDetails: state.checkEnrollmentReducer.session,
		reasons: state.feelingPageReducer.reasons
	};
};

const mapDispatchToProps = dispatch => ({
	createSession: sessionDispatch(dispatch)(allSessionProps),
	getConsultMethods: bindActionCreators(feelingActions.getConsultMethods, dispatch),
	addFeelings: bindActionCreators(feelingActions.addFeelings, dispatch),
	skipFeelings: bindActionCreators(feelingActions.skipFeelings, dispatch),
	clearFeelings: bindActionCreators(feelingActions.clearFeelings, dispatch),
	postService: bindActionCreators(feelingActions.postService, dispatch),
	updateSession: bindActionCreators(feelingActions.updateSession, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Reason);
export { styles };
