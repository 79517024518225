import React, { lazy } from 'react';
import { isDesktop } from '../../config';
import { ProtectedRoute, push } from '../utils';

const go = { settings: () => push('/settings') };

const SettingsRoutes = [
  <ProtectedRoute
    key="/settings"
    path="/settings"
    component={lazy(() =>
      isDesktop() ?
        import('SettingsPage/DesktopSettingsPage/DesktopSettingsPage') 
      :
        import('SettingsPage/index.js')
    )}
  />,
];

export default SettingsRoutes;
export { go };
