import React from 'react';
import { useAccountStore } from 'store/mobx/mobx';

export const useVerification = () => {
    const {profile:{FirstName, LastName, DateOfBirth, VerificationStatusDetails}} = useAccountStore();
    let check = (!!VerificationStatusDetails && VerificationStatusDetails.length > 0); // VerificationStatusDetails is valid, set check to true
    let error = check ? '' : 'The current consumer is not verified normally.';
    const practiceName = VerificationStatusDetails.find(p => p.IdentityVerified)?.OrganisationName;
    error = practiceName ? '' : 'Latest Practice not found.';
    return [practiceName, `${FirstName} ${LastName}`, new Date(DateOfBirth).toLocaleDateString(), error];
}