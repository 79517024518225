import React from 'react';
import { connect } from 'react-redux';
import { observer } from 'mobx-react-lite';
import { bindActionCreators } from 'redux';

import styles from './corona.module.css';
import worriedKiwiURL from '../../../images/worriedKiwi.png';
import { go } from '../../../routes/index';
import { ReactComponent as RightArrow } from '../../../svgs/rightarrow.svg';
import { isDesktop } from '../../../config';
import { useAppointmentStore } from 'store/mobx/mobx';
import { actions as feelingActions } from '../actions/actions';

const Corona = (({ updateSession, ...props }) => {
    //const store = useAppointmentStore();
    const practice = props.location.state.practice;
    const allowBooking = props.location.state.allowContinue;
    const message = props.location.state.message;
    const sessionId = props.location.state.sessionId;
    const method = props.location.state.method;
    const symptoms = props.location.state.symptoms;
    const note = props.location.state.note;
    const service = JSON.parse(props.location.state.service);

    return (
        <div>
            <div className={styles.container}>
                <img src={worriedKiwiURL} className={styles.feelingIcon} alt='I am feeling' />
                {message && message.map((item, i) => {
                    return (
                        <div key={i} className={styles.labelRed} style={{ color: item.Color, fontWeight: item.Bold ? "600" : "400" }}>
                            {item.Message}
                        </div>
                    )
                })}
            </div>
            <div className={styles.nextContainer}>
                {allowBooking &&
                    <a className={styles.navLinkLarge} onClick={() => {
                        updateSession(sessionId, method, symptoms, note, service, practice.UrlName);
                        //go.confirmAppointment(props.match.params.id)
                    }}>
                        <span>Continue Booking</span>
                        <RightArrow className={styles.forwardArrow} />
                    </a>
                }
            </div>
        </div>
    )
})

const mapDispatchToProps = dispatch => ({
    updateSession: bindActionCreators(feelingActions.updateSession, dispatch)
});

export default connect(null, mapDispatchToProps)(Corona);