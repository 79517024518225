import React, { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as Info } from 'svgs/noun_Information_2841887.svg';
import { ReactComponent as InfoGrey } from 'svgs/greyi.svg';
import { ReactComponent as Tick } from 'svgs/whiteTick2.svg';
import { isDesktop } from 'config';

interface ContainerProps {
    selected: boolean;
}
const Container = styled.div`
    width: 100%;
    border-radius: 4px;
    color: ${(props: ContainerProps) => props.selected ? "#FFFFFF" : "#6C6C6C"};
    background-color: ${(props: ContainerProps) => props.selected ? "#07A3C8" : "#F9F9F9"};
    display: flex;
    flex-direction: row;
    font-size: 15px;
    padding: 0.887rem 0.5rem;
    position: relative;
    margin: 0.2rem 0rem;
    align-items: center;
    transition: all 0.3s;
    cursor: pointer;
`
const Placeholder = styled.div`
    width: 17px;
    background-color: transparent;
    margin: 0rem 0.29rem;
`

interface Props {
    selected: boolean;
    onClick: () => void;
    infoAvailable: boolean;
    onInfoClick: () => void;
    label: string;
}

const MedicationItem: React.FC<Props> = ({selected, onClick, onInfoClick, label, infoAvailable}) => {
    const [active, setActive] = useState(selected);

    const handleOnClick = () => {
        setActive(!active);
        onClick();
    }
    return (
        <Container onClick={handleOnClick} selected={active} style={{margin: isDesktop() ? "0.5rem 0rem" : "0.2rem 0rem", padding: isDesktop() ? "0.5rem" : "0.887rem 0.5rem"}}>
            {active ?
                <Tick style={{ width: '17px', margin: '0rem 0.5rem', marginLeft: '0rem'}} />
                : 
                <Placeholder />
            }
            {label}
            
            {/*active ?
                <InfoGrey onClick={onInfoClick} style={{ position: 'absolute', right: '1rem', display: infoAvailable ? 'block' : 'block', overflow: 'visible'}} />
            :
                <Info onClick={onInfoClick} style={{ position: 'absolute', right: '1rem', display: infoAvailable ? 'block' : 'block', overflow: 'visible'}} />
            */}
        </Container>
    )
}

export default MedicationItem;