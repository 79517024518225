import { consumerInterfaceSagas } from './consumerInterfaceSagas';
import { vAppointmentSagas } from '../vAppointment/vAppointmentSagas';
import { vScriptSagas } from '../vScript/vScriptSagas';
import { sendPaymentInfoSaga } from '../vScript/Payment/sagas/paymentInfo';
import { autoRegisterUserSaga } from '../vAppointment/GuestRegister/sagas/autoRegister';
import { checkUsernameSaga } from '../vAppointment/GuestRegister/sagas/checkUsername';
import { confirmUserRegistrationCodeSaga } from '../vAppointment/GuestRegister/sagas/registerCodeLogin';
import { fetchPatientDetailForSmokingAppointmentSaga } from '../vAppointment/PatientDetails/sagas/sagas';
import { identitySaga } from '../Identity/sagas/identitySaga';
import {
  sendCodeToVerifySaga,
  resendCodeSaga,
} from '../vAppointment/GuestRegister/sagas/sendCodeToVerify';
import { expiredSessionSaga } from '../components/ExpiredSession/sagas/expiredSession';
import { acceptTnCSaga } from '../components/TnCWrapper/sagas/acceptTnC';

const rootSaga = [].concat(
  acceptTnCSaga,
  autoRegisterUserSaga,
  checkUsernameSaga,
  confirmUserRegistrationCodeSaga,
  resendCodeSaga,
  sendCodeToVerifySaga,
  expiredSessionSaga,
  sendPaymentInfoSaga,
  consumerInterfaceSagas,
  vAppointmentSagas,
  vScriptSagas,
  fetchPatientDetailForSmokingAppointmentSaga,
  identitySaga,
);

export default rootSaga;
