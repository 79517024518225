import React, { PropsWithChildren } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Image } from 'components/vensaComponents';
import { useDispatch } from 'react-redux';
import closeSVG from 'svgs/blueCross.svg';
import { actions } from '../MainShell/actions/actions';

export const WhiteNotificationWrapper = observer(({ children }: PropsWithChildren<{}>) => {
  const dispatch = useDispatch();

  return (
    <Box display="block" p={4} borderBottom={"2px solid #F9F9F9"}>
      <Box justifyContent="end" position={"absolute"} right={"1rem"}>
        <Image
          src={closeSVG}
          height="15px"
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            dispatch(actions.setWhiteBoxNotification({ isOpen: false }));
          }}
        />
      </Box>
      <div>{children}</div>
    </Box>
  );
});
