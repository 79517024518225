import React, { PropsWithChildren, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import UIContainer from 'components/UIContainer/index';
import { observer } from 'mobx-react-lite';
import { usePaymentsStore } from 'store/mobx/mobx';
import { Box, Text, Image } from 'components/vensaComponents';
import { CardImage } from 'PaymentsPage/components/CardImage';
import threeDots from 'svgs/3dots.svg';
import { CardMenu } from './CardMenu';
import { RemoveCardModal } from 'PaymentsPage/components/RemoveCardModal';
import {
  isExpired,
  getCardMonthText,
  getCardYearText,
} from 'PaymentsPage/components/PaymentsItem';
import { LOADING_STATUS } from 'libs/newHttp';

const CardSection = observer(
  ({ label, children }: PropsWithChildren<{ label: string }>) => (
    <Box direction="column" gap="8px">
      <Text fontSize="12px" color="grey4">
        {label}
      </Text>
      {children}
    </Box>
  ),
);

const CardPage: React.FC = observer(() => {
  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { cards } = usePaymentsStore();
  const { cardToken } = useParams<{ cardToken: string }>();
  const cardToFind = cards.filter(card => card.Token === cardToken);
  const noSuchCardInStore = !cards.length || !cardToFind.length;

  const store = usePaymentsStore();

  if (noSuchCardInStore) {
    return <Redirect to="/payments" />;
  }

  const { Token, Brand, Last4, ExpMonth, ExpYear } = cardToFind[0];
  const cardExpired = isExpired(ExpMonth, ExpYear);

  return (
    <>
      <UIContainer heading="Visa" isFetching={store.paymentSummaryLoadingStatus === LOADING_STATUS.LOADING}>
        <Box
          width="94%"
          borderRadius="4px"
          bg="white"
          p="24px"
          maxHeight="210px"
          boxShadow={2}
          direction="column"
          gap="24px"
          position="relative"
          left="3%"
        >
          <Box alignItems="center" justifyContent="between" minHeight="32px">
            {cardExpired ? (
              <Text color="red" fontSize="12px">
                This card has expired.
              </Text>
            ) : (
              <div />
            )}
            {!showMenu && (
              <Image
                src={threeDots}
                height="32px"
                width="32px"
                onClick={() => {
                  setShowMenu(true);
                }}
              />
            )}
          </Box>
          <CardSection label="Card Number:">
            <Box alignItems="center" justifyContent="between">
              <CardImage brand={Brand} />
              <Text fontWeight="700">{`**** **** **** ${Last4}`}</Text>
            </Box>
          </CardSection>

          <CardSection label=''>
            <br/>
          </CardSection>

          <CardSection label="Expiry Date:">
            <Text>{`${getCardMonthText(ExpMonth)}/${getCardYearText(
              ExpYear,
            )}`}</Text>
          </CardSection>
          {showMenu && (
            <CardMenu
              isExpired={cardExpired}
              Token={Token}
              setShowModal={setShowModal}
              setShowMenu={setShowMenu}
            />
          )}
        </Box>
      </UIContainer>
      <RemoveCardModal
        cardId={Token}
        shouldShow={showModal}
        closeModal={() => {
          setShowModal(false);
        }}
      />
    </>
  );
});

export default CardPage;
