import { useComponentDidMount } from 'libs/newHttp';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as Line } from 'svgs/blueUnderline.svg';
import InfoDropdown from '../../../components/InfoDropdown';

const Container = styled.div`
    overflow: visible;
`
const Title = styled.div`
    font-size: 25px;
    color: #6C6C6C;
    text-align: center;
    margin: 0rem 1rem;
`
const Subtitle = styled.div`
    font-size: 17px;
    color: #6C6C6C;
    text-align: center;
    margin: 0.5rem;
`
const Text = styled.div`
    text-align: left;
    font-size: 15px;
    color: #6C6C6C;
    margin: 1rem;
`
const FlexH = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`
const Content = styled.div`
    width: 100%;
`

const SimplifiedChineseConsent: React.FC = observer(() => {
    return (
        <Container>
                <Title>您想自动发送实验室结果吗？</Title>
                <Subtitle>展开查看</Subtitle>
                <Line style={{display: "block", margin: "1rem auto"}} />
                <Text>
                    研究标题： 分析实验室结果模式，自动通知患者并进行归档
                    <br /><br />
                    <FlexH>
                        <Content>地区：奥克兰市</Content>
                        <Content>赞助商（如果有)： Vensa</Content>
                    </FlexH>
                    <br />
                    <FlexH>
                        <Content>首席研究员: <br /> Samuel Wong  </Content>
                        <Content>联系电话： <br /> 0800 228 946</Content>
                    </FlexH>
                </Text>
                <InfoDropdown 
                    title={"参加者信息表"}
                    subtitle={""}
                    text={"您被邀请参加有关改善医生为您提供实验室检查结果方式的研究。\n\n" +
                    "是否要参加本研究完全取决于您。如果您不愿参与，则无需说明原因，您获得的医疗护理也不会受到任何影响。同时您也可以随时退出该研究。\n\n" +
                    "参与者信息表将帮助您决定是否要参加。它介绍了这项研究，您的参与将涉及什么，对您的潜在利益和风险以及研究完成时会发生什么。\n\n" +
                    "我们将与您讨论所有这些问题，并回答您可能遇到的任何问题。您不必今天决定是否要参加这项研究。在您决定之前，请随时与其他人，例如家人，朋友或医疗保健提供者讨论这项研究。\n\n" +
                    "如果您同意参加本研究，您将被要求签署本文档最后一页上的同意书。您将获得一份参与者信息表和同意书的副本，以备保留。"
                }
                />
                <InfoDropdown 
                    title={"介绍这项研究"}
                    subtitle={"关于我的实验室检查结果，已经发生了什么？"}
                    text={"当您进行血液检查或其他检查时，结果将从实验室返回给您的医生。该医生要检查每个测试结果，并决定做什么，并添加任何评论以便你阅读报告结果。这需要大量的时间。\n\n如果您有一个患者门户网站，那么在医生检查后，您也许可以在其中看到测试结果。否则，您可能会打电话给诊所或给诊所发电子邮件，如果结果“正常”，您可能会被告知您不会被通知。有时你可以看到测试结果，但并没有足够多的其他信息来帮助你理解检查结果。"}
                />
                <InfoDropdown 
                    title={"介绍这项研究"}
                    subtitle={"我们如何能进行改善？"}
                    text={"您的医生已经使用计算机系统来保存您的医疗信息。如果我们可以使用这些信息来帮助更好地检查测试结果，则可以节省您医生的时间，添加更多有用的注释，并帮助节省新西兰的卫生系统资源。您也会更快的看到大多数的测试结果。\n\n为确保其在质量和效率方面 均能正常  工作 ，您的医生正在与我们合作 进行研究。这最终可以帮助我们开发“算法”，从 而可以 针对每 位患者进行适当的说明，个性化安  全，有效地交付 正常测试结果。"}
                />
                <InfoDropdown 
                    title={"介绍这项研究"}
                    subtitle={"谁在进行这项研究？"}
                    text={"几个诊所正与新西兰一家名为Vensa健康的公司合作，该 公司已经提供门户系统使得患者可以在线获得重复处方，预约医生等。\n\n这项研究得到了Precision Driven Health的资助，Precision Driven Health与OrionHealth，奥克兰大学，商 业创 新与 就业部  和Waitemata地区卫生局之间存在公私合作伙伴关系。\n\n 该研究已在HDEC北方A伦理委员会中注册，其设计具有临床保障，包括高级医生的监督。"}
                />
                <InfoDropdown 
                    title={"参加研究"}
                    subtitle={"为什么我的医生认为我可能适合该研究？"}
                    text={"我们正在寻找 有相对直接 的健 康需求的人参加，以使研究保持尽 可能的  安全。 怀孕 ，16岁以下或有您的医生确定的某些风险的人被排除在外。\n\n如果您的医生认为您需要（或可能需要）其中一项合格性  测试，则将会要求您表达参与研究的兴趣。"}
                />
                <InfoDropdown 
                    title={"参加研究"}
                    subtitle={"包括哪些测试？"}
                    text={"该研究仅包括少量标准和“低风险”血液检查。您的医生可能知道您在接下来的几周中需要进行一项或多项测试：\n\n" +
                    "•	HbA1c（用于评估糖尿病的长期控制）\n" + 
                    "•	T甲状腺刺激激素（用于评估甲状腺功能和激素水平）\n" +
                    "•	肌酐（用于肾功能检查），\n" +
                    "•	胆固醇（用于脂质管理以测量心血管风险），\n" +
                    "•	铁蛋白（作为确定铁储存水平的铁板试验的一部分）。\n\n" +
                    "您的医生作为您 日常护理的一部分所 订购 的任何测试都将包括在研究中。研究仅检查测试结果信息，而不检查血液样本本身。"}
                />
                <InfoDropdown 
                    title={"参加研究"}
                    subtitle={"如何注册"}
                    text={"您的医生已根据 您的资  格向 您介绍了此研究，或者您可能已经在诊所或门户网站上看到了广告并注册了您的兴趣。.\n\n当为您  订购 测试时，您的医生会在一天后通过电子邮件向您发 送此 参与者信息表和TXT /SMS提醒。电子邮件发出后的48小时内，您将在同意书上进行电子打勾，然后您的测试结果才能返回给您的医生。"}
                />
                <InfoDropdown 
                    title={"参加研究"}
                    subtitle={"研究是如何安排的？"}
                    text={"该研究  预计进行15个月，以了解几个不同的“目标”。系统会向您发 送此 信息表的另一份副本，并要求您同意在每个目标提出时参与其中，并且您可以随时拒绝 。这项研究的干预 涉及您如何接收测试结果。\n\n" + 
                        "目标之一是了解  向患者提供不同类型建议的有效  性。这将涉及您回答有关如何获得正常结果以及所提供建议的问题。\n\n" +
                        "目标二着眼 于理解  驱动测试请求的因素。该研究将研究您需要去看医生的原因，以及医生为您订购测试的临床原因。\n\n" +
                        "目标三着眼 于理解  临床 因素以解释实验室结果并个性化 您的正常情况 。此目 标将涉及使用健康记录  中会 直接 影响测试结果的信息（例如您当前的状况 ，药物 和以前的测试结果）来确定您的最新结果是否在预期 的正常  范围内  。如 某些心脏 ，肝家族史 的其他问题，或肾脏疾病;饮食和生活方式问题，因为它们可能会对测试结果产生影响。\n\n" +
                        "有时一个测试没有结束的时 候被责令 它通常会被预期 的特定条件或有  情况-例如，如果同一类型的测试已经通过专 家或医  院最近做代替 。该研究还希望 了解这  种情况  ，因 此即 使您没有获得结果，也可能将您添加到像您这样的人的“比较组”中。\n\n" +
                        "总之，不同的目标和对照组的帮助我们学习和分析一个全范围的模式和偏好。\n\n" +
                        "如有任何疑问，请随时与研究团队联系（详细信息，第6页）。    "
                    }
                />
                <InfoDropdown 
                    title={"参加研究"}
                    subtitle={"研究过程中我需要做什么？"}
                    text={"你需要阅读调查信息，并确认你同意参与这项研究。这将通过您的患者门户网站完成。\n\n" +
                        "当您的测试结果可用时，系统将询问您一些有关提供给您的信息的简单 问题。大约需要 两分钟。\n\n" +
                        "在此研究中，测试结果将被伴随着下列某一项信息公布给参与者，无论是：\n" +
                        "•  像您的医生通常所做的那样，使用他们的通常流程，或者\n" +
                        "•  通过您的在线门户网站，获得医生关于该测试结果的个性化建议，或者\n" +
                        "•  通过您的在  线门户网站，获得给同样得到该结果的人群的标准化建议 ，以及有关该测试的背景信息和您自身的特定情况\n\n" +
                        "如果你在这15个月中参加了一个以上目标的研究，你会被分配到不同的“意见集团”，以比较你的经验。"
                }
                />
                <InfoDropdown 
                    title={"参加研究"}
                    subtitle={"异常测试结果怎么办？"}
                    text={"在研究  过程 中，如果任何测试结果异常并需要引起 注意，则实验室发布后会 立即 通知您的医生。对于不需要立即采取措施的任何异常结果，您将收到有关选项的详细信息"}
                />
                <InfoDropdown 
                    title={"参加研究"}
                    subtitle={"谁将看到我的详细信息？"}
                    text={"如果您参加这项研究，您的医生将负责您的所有测试和结果。\n\nVensa的研究  团队 研究这些信息的副本，副本中每个人的姓名 和联系信息奖杯删除。只有正在为你的医生提供在线门户 服务 的专业 的团队 成员将  继续 以访问你的信息。研究团队绝  不会看到任何可识别的信息。\n\n 该研究  符合 有关 处理健康信息的所有标准和保护  措施 。这包括Vensa遵循政府“健康信息安全框架”平台标准，并将信息托管在澳大利亚认可的符合健康信息的服务器中。\n\n我们的所有详细 信息 仍将保留在医生自己的系统中，您可以随时要求查看它们。您在研究中添加的所有内容都  可以在您的患者门户上看到。可以通过患者门户网站完成任何更改或更正请求\n\n 如果您决定在研究结束前离开 研究，则可以同意我们在退出之前使用您的信息，或者可以将其完全排除在研究数据库之外。您的GP仍将保留信息。"}
                />
                <InfoDropdown 
                    title={"参加研究"}
                    subtitle={"研究完成后"}
                    text={"研究结  束后，您的医生可能会恢复 到以前  管理测试结果的方式。如果研究成功，他们可以选择使用研究过程中开发的过程。\n\n这项研究的数据是你的一部分个人健康记录，在法律上必须被保存至少10年。"}
                />
                <InfoDropdown 
                    title={"权衡是否要参与"}
                    subtitle={"潜在利益"}
                    text={"你从你的医生或医疗卫生系统接受的医疗服务 不会受到直接 影响如果你决定参与该研究。在研究过程中，您可能会亲自获得更多有用的测试结果，但这并不能保证。\n\n知道你参与该研究将有助于为每一个人改善我们共同的医疗系统，可能是你想参加这项研究的一部分原因。"}
                />
                <InfoDropdown 
                    title={"权衡是否要参与"}
                    subtitle={"付款和费用"}
                    text={"目前对于参与研究的患者，并没有任何额外费用。您的医生的通常的费用标准仍然适用。\n\n已为参与这项研究的GP诊所提供了一些补贴，以支付与您联系时的电信费用。"}
                />
                <InfoDropdown 
                    title={"权衡是否要参与"}
                    subtitle={"潜在风险"}
                    text={"我们 精心设 计了这项研究，以最大程度地减少出现问题的机会。但是，如果您参与其中，仍然可能会遇到一些风险：\n" +
                    "•  您可能不完全了解测试结果提供的建议 。如果发生这种情况  ，您可以按照通常的方式与您的医生联系，讨论您不确定的任何事情。您还可以向我们提供有关您发现不清楚 的内容 和原因的反馈 。我们的医生可能会改变你在 未来收到的意  见，使之更加方便你理解。\n" +
                    "•  您可能会被告知测试是正常的，而实际上却是异常的并  且需要 就医。这可能会导致治  疗延迟 。我们将采取一 切可能的  措施 来防止 这种情况  的发生。在您进入研究之前，您的医生将与您讨论这点，并确定哪些测试结果对您个人而言应视为“正常”或“异常”。为了进行研究，您的医生将准确决定可以自动向您发 布哪 些结果，以及哪些医 学专 家需要检查哪些测试结果。该研究的首席临床 主管将不 断监控和审查此过程。\n" +
                    "•  如果有人非法访 问您的数据和隐私，则可能会破坏 您的数  据隐私。我们已采取严格的安全措施 来防止 这种情况  。特别 是，Vensa使用 安全的计算服务 ，这 符合卫生 部的信息  安全框架 标准-这意 味着你的数  据将保 持安 全。我们在研究中使用的信息的副本仅包含您的诊所使用的本地患者ID代码，您的 姓名 ，出生  日期 和国民 健康身 份证号码（NHI）都将被 删除 。研究  团队仅  使用结  构化信息（例如用于描述 您的问题或主题的 代码）。从您的预约 和咨询中将不会获得临床 注释的文字描述  。在这项研究中，如果研究小组 需要与您的医生讨论，他们将使用案例事件ID进行讨论。\n\n" +
                    "研究 团队 将对出  现的任何其他风险做出回应。您的  安全和 隐私很重要。在研究期间，您的医生将照常照顾您的兴趣。\n\n" + 
                    "您根据《健康与残障服务守则》享有的所有权利在研究期间将适用。"
                }
                />
                <InfoDropdown 
                    title={"权衡是否要参与"}
                    subtitle={"完全自愿"}
                    text={"是否参加这项研究取决于您。无论您做出什么决定，它都不会影响医生的常规护理或您已经获得测试结果和建议的方式。\n\n 您可能  希望 在每 轮测试中加入一个或多个目标，通  过暂时不进行推迟 进行 指示 ，或者  拒绝 将结果从研究中的任何阶段 删除 。可以通过在患者门户网站上提出请求来完成此操作，您在患者门户网站上的常规结果不会受到影响。"}
                />
                <InfoDropdown 
                    title={"我应该联系谁以便了解更多信息或者如果我有顾虑？"}
                    subtitle={""}
                    text={"如果您在任何阶段对本研究有任何疑问，疑虑或投诉，都可以联系：\n" +
                    "首席联合研究员Samuel Wong\n" +
                    "0800 228946\n" +
                    "feedback@vensa.com\n\n" +
                    "如果您  觉得需要与该研究的临床研究共同研究人员塔恩·泰勒（Tane Taylor）提出任何临床疑虑或是针对毛利人的健康支持，请联系Samuel Wong安排讨论。"
                }
                />
                <InfoDropdown 
                    title={"我应该联系谁以便了解更多信息或者如果我有顾虑？"}
                    subtitle={""}
                    text={"如果您想与一些不在这项研究中的人交谈，可以联系独立的健康与残疾倡导者。\n" +
                    "电话：0800 555 050\n" +
                    "传真：0800 2支持（0800 2787 7678）\n" +
                    "电子邮件：advocacy@advocacy.org.nz\n" +
                    "网站：https：//www.advocacy.org.nz/\n\n"+
                    "您也可以联系批准本研究的健康与残疾道德委员会（HDEC）\n" +
                    "电话：0800 4 ETHICS\n" +
                    "电子邮件：hdecs@moh.govt.nz"
                }
                />
                <InfoDropdown
                    title={"通过单击“同意”，您接受以下内容："}
                    subtitle={""}
                    text={"我已经读过或已经用我的第一语言听到过，并且了解了参与者信息表。\n\n" +
                    "我有足够的时间考虑是否参加这项研究。\n\n" +
                    "我有机会使用一位法律代表，家庭或朋友来帮助我提出问题和理解研究。\n\n"+
                    "我了解研究期间受伤的赔偿规定。\n\n"+ 
                    "如果我对这项研究有任何疑问，我知道该与谁联系。\n\n"+
                    "我了解自己作为研究参与者的责任。\n\n"+
                    "我了解参加这项研究是自愿的（我的选择），我可以随时退出研究而不会影响我的医疗。\n\n" +
                    "我了解我对本研究的参与是保密的，因此本研究的任何报告都不会使用任何可以识别我个人身份的材料"
                }
                />
        </Container>
    )
})

export default SimplifiedChineseConsent;