import React, { PropsWithChildren } from 'react';
import { PaymentStore } from './paymentStore/paymentStore';
import { useLocalStore } from 'mobx-react-lite';
import { MyHealthStore } from './myHealthStore/myHealthStore';
import { AccountStore } from './accountStore/accountStore';
import { PracticeStore } from './practicesStore/practiceStore';
import { IdentityStore } from './identityStore/identityStore';
import { SessionStore } from './sessionStore/sessionStore';
import { SecurityStore } from './securityStore/securityStore';
import { AppointmentStore } from './appointmentStore/appointmentStore';
import { DependantsStore } from './dependantsStore/dependantsStore';
import { vScriptStore } from './vScriptStore/vScriptStore';
import { PatientStore } from './patientStore/patientStore';
import CareCircleStore from '../carecircle';

import {configure} from 'mobx';
configure({
  enforceActions: 'never'
});

export const STORE = {
  payments: new PaymentStore(),
  myHealth: new MyHealthStore(),
  account: new AccountStore(),
  practices: new PracticeStore(),
  identity: new IdentityStore(),
  session: new SessionStore(),
  security: new SecurityStore(),
  appointment: new AppointmentStore(),
  dependants: new DependantsStore(),
  vScript: new vScriptStore(),
  patient: new PatientStore(),
  careCircle: CareCircleStore
};

type StoreType = typeof STORE;

const createStore = () => STORE;

// @ts-ignore
const mobxStoreContext = React.createContext<StoreType>(null);

export const MobxStoreProvider = ({ children }: PropsWithChildren<{}>) => {
  const store = useLocalStore(createStore);
  
  return (
    <mobxStoreContext.Provider value={store}>
      {children}
    </mobxStoreContext.Provider>
  );
};

const throwErrorIfNoStore = (store: StoreType) => {
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider.');
  }
};

export const useStore = () => {
  const store: StoreType = React.useContext(mobxStoreContext);
  throwErrorIfNoStore(store);
  return store;
};

export const usePaymentsStore = () => useStore().payments;

export const useMyHealthStore = () => useStore().myHealth;

export const useAccountStore = () => useStore().account;

export const usePracticesStore = () => useStore().practices;

export const useSecurityStore = () => useStore().security;

export const useIdentityStore = () => useStore().identity;

export const useSessionStore = () => useStore().session;

export const useAppointmentStore = () => useStore().appointment;

export const useDependantsStore = () => useStore().dependants;

export const useVScriptStore = () => useStore().vScript;

export const usePatientStore = () => useStore().patient;

export const useCareCircle = () => useStore().careCircle;
