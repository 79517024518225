import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import styled from 'styled-components';

import bg from 'images/bgImage.png';
import icon from 'images/hauora.png'
import wave from 'images/wave.png';
import { ReactComponent as Line } from 'svgs/dashedLine.svg';
import { useAccountStore } from 'store/mobx/mobx';
import logo from 'images/greyLogo.png';
import logoHeader from 'images/logoName.png';
import { useComponentDidMount } from 'libs/newHttp';
import Alert from 'libs/Alert';
import Loading from 'components/loadingSpinner';
import { go } from 'routes';
import kiwi from 'images/worriedKiwi.png';

const Container = styled.div`
    width: 100%;
    height: 100vh;
    background-image: url(${bg});
    background-repeat: no-repeat; 
    background-position: top;
    background-size: cover;
`
const CardContainer = styled.div`
    width: 600px;
    height: auto;
    margin: 2rem auto;
`
const HeaderContainer = styled.div`
    width: 100%;
    background-color: #FAFBFF;
    border-bottom: 1px #F0F0F0 solid;
`
const Header = styled.div`
    display: flex;
    flex-direction: row;
    margin: auto;
    align-items: center;
    width: 60%;
`
const FlexV = styled.div`
    display: flex;
    flex-direction: column;
`
const FlexH = styled.div`
    display: flex;
    flex-direction: row;
`
const HeaderTitle = styled.div`
    font-size: 17px;
    font-weight: bold;
    text-align: center;
    width: 100%;
`
const HeaderSubtitle = styled.div`
    font-size: 13px;
    font-weight: light;
    text-align: center;
    width: 100%;
`
const Image = styled.div`
    display: block;
    margin: 0.5rem;
    width: 60px;
    height: 60px;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 4px;
    background-size: contain;
`
const Section = styled.div`
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    border-bottom: 1px #F0F0F0 solid;
`
const Title = styled.div`
    color: #727FA5;
    font-size: 21px;
    margin: 0rem 10px;
    font-weight: bold;
`
const Text = styled.div`
    font-size: 13px;
    width: 100%;
    margin-bottom: 1rem;
    color: #585858;
`
const PatientDetailsLabel = styled.div`
    color: #585858;
    font-weight: bold;
    font-size: 13px; 
`
const PatientDetails = styled.div`
    font-size: 13px;
    text-transform: uppercase;
    text-align: left;
    margin-left: auto;
    width: 75%;
`
const ConfirmText = styled.div`
    font-size: 21px;
    color: #6C6C6C;
    display: inline-block;
    width: 100%;
    text-align: center;
`
const ConfirmButton = styled.div`
    color: white;
    background-color: #07A3C8;
    font-size: 17px;
    padding: 1rem 2rem;
    border-radius: 4px;
    cursor: pointer;
`
const Link = styled.div`
    color: #07A3C8;
    font-size: 17px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    margin: 1rem 0rem;
`
const SectionFooter = styled.div`
    width: 100%;
    text-align: center;
    font-size: 13px;
    color: #707070;
`
const Footer = styled.div`
    font-size: 15px;
    text-align: left;
    margin: 1rem;
`
const WhoIsVensaTitle = styled.div`
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    margin: 1rem;
    color: #07A3C8;
`
const WhoIsVensa = styled.div`
    font-size: 15px;
    color: #707070;
    margin: 0rem 1rem;
`
const Background = styled.div`
    background-color: #F5F5F5;
    position: absolute;
    height: 100%;
    width: 100%;
    transition: all 0.3s linear;
    z-index: 10;
    visibility: visible;
`

const DesktopSignUpVerification = observer(() => {
    const store = useAccountStore();
    const [noContent, setNoContent] = useState(false);
    const [sent, setSent] = useState(false);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let t = params.get('token');
    let e = params.get('email')
    
    if (t && e) {
        if (!sent) {
            store.getSignUpInvitationDetails(t, e, () => setNoContent(true));
            setSent(true);
        }
    } else {
        setNoContent(true);
        //Alert.error("Sorry this is an invalid invitation");
    }
    
    if (noContent) {
        return (
            <Container style={{height: "100%"}}>
                <CardContainer style={{padding: "1rem", backgroundColor: "white", borderRadius: "4px", marginTop: "4rem"}}>
                    <img src={kiwi} style={{display: "block", margin: "auto"}} />
                    <Title style={{margin: "1rem 0rem", textAlign: "center"}}>Sorry this invitation has been accepted or expired</Title>
                    <ConfirmButton style={{margin: "1rem auto", width: "220px"}} onClick={() => window.location.href="https://vensa.com"}>
                            Back to Vensa.com
                    </ConfirmButton>
                </CardContainer>
            </Container>
        )
    } else {
        return (
            <Container>
                <Background style={{visibility: store.loading ? "visible" : "hidden"}} />   
                <Loading isFetching={store.loading} style={{position: "absolute", top: "6rem"}} />
                <img src={logoHeader} alt="logo" style={{position: "absolute", top: "20px", left: "30px"}}/>
                <CardContainer>
                    <HeaderContainer>
                        <Header>
                            <Image style={{backgroundImage: `url(${store.signUpInvitationDetails.LogoUrl})`}} />
                            <FlexV style={{marginLeft: "1rem"}}>
                                <HeaderTitle>{store.signUpInvitationDetails.OrganisationName}</HeaderTitle>
                                <HeaderSubtitle>{store.signUpInvitationDetails.Address}</HeaderSubtitle>
                            </FlexV>
                        </Header>
                    </HeaderContainer>
                    <Section style={{border: "none"}}>
                        <FlexH style={{margin: "auto", alignItems: "center"}}>
                            <img src={wave} alt="Hi" style={{display: "block"}} />
                            <Title>Hello {store.signUpInvitationDetails.GivenName}!</Title>
                        </FlexH>
                        <Text style={{marginTop: "2rem"}}>
                            {store.signUpInvitationDetails.OrganisationName} have invited you to join the Vensa System.
                        </Text>
                        <Text>
                            {store.signUpInvitationDetails.NoteForPatient}
                        </Text>
                        <FlexV>
                            <FlexH style={{margin: "4px 0px"}}>
                                <PatientDetailsLabel>Patient:</PatientDetailsLabel>
                                <PatientDetails>{store.signUpInvitationDetails.GivenName} {store.signUpInvitationDetails.FamilyName}</PatientDetails>
                            </FlexH>
                            <FlexH>
                                <PatientDetailsLabel>Mobile:</PatientDetailsLabel>
                                <PatientDetails>{store.signUpInvitationDetails.MobileNumber}</PatientDetails>
                            </FlexH>
                        </FlexV>
                    </Section>
                    <Section>
                        <Line style={{display: "block", margin: "auto"}} />
                        <ConfirmText style={{marginTop: "1rem"}}>Please confirm that you are</ConfirmText>
                        <ConfirmText style={{fontWeight: "bold"}}>{store.signUpInvitationDetails.GivenName} {store.signUpInvitationDetails.FamilyName}</ConfirmText>
                        <ConfirmText style={{marginBottom: "1rem"}}>to begin registration. Your details will be loaded.</ConfirmText>
                        <FlexV style={{margin: "1rem auto"}}>
                            <ConfirmButton onClick={() => go.signUpFlow(t, e)}>I'm {store.signUpInvitationDetails.GivenName}</ConfirmButton>
                            <Link onClick={() => window.location.href="https://vensa.com"}>I'm not {store.signUpInvitationDetails.GivenName}</Link>
                        </FlexV>
                        <SectionFooter>
                            If this is not you, or you believe you have received this message by mistake,
                            please kindly let {store.signUpInvitationDetails.OrganisationName} know. You can
                            call them on <a href={"tel:" + store.signUpInvitationDetails.OrganisationPhone} style={{color: "#2FADC8"}}>
                                {store.signUpInvitationDetails.OrganisationPhone}
                            </a>, or send them an email on <a href={"mailto:" + store.signUpInvitationDetails.OrganisationEmail} style={{color: "#2FADC8"}}>{store.signUpInvitationDetails.OrganisationEmail}</a>                
                        </SectionFooter>
                    </Section>
                    <Section style={{backgroundColor: "#FAFBFF", padding: "1rem"}}>
                        <img src={logo} style={{display: "block", marginRight: "auto", marginLeft: "1rem"}} />
                        <WhoIsVensaTitle>Who's Vensa?</WhoIsVensaTitle>
                        <WhoIsVensa>
                            Vensa is an IT company that provides a range of digital patient services for medical centres. For more information head over to <a href={"https://vensa.com"}>Vensa.com</a>.
                        </WhoIsVensa>
                    </Section>
                </CardContainer>
            </Container>
        )
    }
})

export default DesktopSignUpVerification;