import React from 'react';
import styled from 'styled-components';

import { ReactComponent as HeartIcon } from 'svgs/heart.svg';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2rem 4rem;
    font-size: 17px;
    color: #585858;
    width: 100%;
    text-align: center;
`
const Title = styled.div`
    font-weight: 600;
    margin: 1rem 0rem;
`
const Text = styled.div`
    font-weight: 300;
    line-height: 30px;
`

const InfoPage: React.FC = () => {
    return (
        <Container>
            <HeartIcon style={{ margin: "1rem auto"}} />
            <Title>Why is this fee subsidised?</Title>
            <Text>
                Your practice subsidises this fee to make it 
                easier and more affordable for you to order your 
                repeat scripts online
            </Text>
        </Container>
    )
}

export default InfoPage;