import React from 'react';

type Props = {
    color: string,
    height?: string,
    width?: string
}

const ColoredLine = (
    {
        color,
        height = '1px',
        width = '100%'
    }: Props) => {
  return (
    <hr
        style={{
            border: 0,
            width: `${width}`,
            borderBottom: `${height} solid ${color}`
        }}
     />
  )
}

export default ColoredLine;