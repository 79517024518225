import React, { useState } from 'react';
import styled from 'styled-components';

import Card from '../components/Card';
import privacy from 'images/privacy.svg';
import booking from 'svgs/booking.svg';
import pills from 'svgs/blister-pack.svg';
import baby from 'svgs/baby-boy.svg';
import report from 'svgs/medical-report.svg';
import center from 'svgs/drive-thru.svg';
import { useComponentDidMount } from 'libs/newHttp';

const Container = styled.div`
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    overflow: hidden;
`

interface Props {
    hide: (e: any) => void;
}
const TakeATour: React.FC<Props> = ({hide}) => {
    const [state, setState] = useState(0);
    const [height, setHeight] = useState(window.innerHeight - 34);
    
    useComponentDidMount(() => {
        window.addEventListener('resize', () => setHeight(window.innerHeight - 34));    
    })


    let card;
    if (state === 0) card = <Card 
                                hide={hide} 
                                totalCards={6} 
                                active={0} 
                                title={"Book appointments"} 
                                text={"Book appointments for yourself or your dependants"} 
                                onNext={() => setState(1)}
                                goBack={(newState) => setState(newState)}
                                icon={booking}
                            />;  
    else if (state === 1) card = <Card 
                                    hide={hide} 
                                    totalCards={6} 
                                    active={1} 
                                    title={"Repeat Scripts made easy"} 
                                    text={"With the new release you will be able to easily select your long term medications. Collect from pharmacy near you."} 
                                    onNext={() => setState(2)}
                                    icon={pills}
                                    goBack={(newState) => setState(newState)}
                                />
    else if (state === 2) card = <Card 
                                    hide={hide} 
                                    totalCards={6} 
                                    active={2} 
                                    title={"View your whole record"} 
                                    text={"You can view your whole health record from your practice."} 
                                    onNext={() => setState(3)}
                                    goBack={(newState) => setState(newState)}
                                    icon={report}
                                />
    else if (state === 3) card = <Card 
                                    hide={hide} 
                                    totalCards={6} 
                                    active={3} 
                                    title={"Access your dependants data (Soon)"} 
                                    text={"You will have the ability of seeing your loved one's health information on your account."} 
                                    onNext={() => setState(4)}
                                    goBack={(newState) => setState(newState)}
                                    icon={baby}
                                />
    else if (state === 4) card = <Card 
                                    hide={hide} 
                                    totalCards={6} 
                                    active={4} 
                                    title={"Add multiple clinics to your profile"} 
                                    text={"Add multiple clinics signed on to vensa for you to book appointments with."} 
                                    onNext={() => setState(5)}
                                    goBack={(newState) => setState(newState)}
                                    icon={center}
                                />
    else if (state === 5) card = <Card 
                                    hide={hide} 
                                    totalCards={6} 
                                    active={5} 
                                    title={"Privacy"} 
                                    text={"Privacy matters. Keep your health data private and secure."} 
                                    onNext={hide}
                                    finishButton={true}
                                    icon={privacy}
                                    goBack={(newState) => setState(newState)}
                                />
                        
    return (
        <Container style={{height: height}}>
            {card}
        </Container>
    )
}

export default TakeATour;