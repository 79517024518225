import React from 'react';
import PropTypes from 'prop-types';

import CallPractice from '../../../CallPractice/index';
import hospitalIcon from '../../../../images/notEnrolled.png';
import { ReactComponent as Arrow } from '../../../../svgs/rightarrow2.svg';
import { ReactComponent as Close } from '../../../../svgs/close.svg';
import styles from './cancelNotAllowed.module.css';
import { isDesktop } from '../../../../config';

const CancelNotAllowed = ({ data, toggleError }) => (
  <div className={styles.container}>
    {!isDesktop() ? (
      <Arrow className={styles.arrow} onClick={toggleError} />
    ) : (
      <Close className={styles.close} onClick={toggleError} />
    )}
    {isDesktop() && <div className={styles.practiceName}>{data.Name}</div>}
    <div className={styles.heading}>
      {isDesktop() ? 'Cancellation Error' : 'Whoops!'}
    </div>
    <div className={styles.innerContainer}>
      <img src={hospitalIcon} alt="hospital" className={styles.hospital} />
      <div>
        {`This practice does not allow bookings to be cancelled
          online within ${
            data.CancellationHour
          } hours of the appointment time.`}
        <br />
        <br />
        {'Please call your practice to cancel this appointment.'}
      </div>
      <CallPractice practice={data} />
    </div>
  </div>
);

CancelNotAllowed.propTypes = {
  toggleError: PropTypes.func.isRequired,
  data: PropTypes.shape({
    Name: PropTypes.string,
    Phone: PropTypes.string,
    CancellationHour: PropTypes.number,
  }),
};

export default CancelNotAllowed;
