import { call, takeEvery, put } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';
import { GenericParserHttp } from '../../libs/Http';

const api = (token, user) => {
  GenericParserHttp.post('consumer/identity/practice/verification-request', { 
    token, 
    data: user
  }).then(
    ({ body }) => body,
  );
}

function* sendVerificationRequest({ payload: {token, user} }) {
  try {
    yield call(api, token, user);
    yield put(actions.sendVerifyRequestSuccess());
  } catch (err) {
    yield put(actions.sendVerifyRequestFailure(err));
  }
}

function setPractice({ payload: { data } }) {
    put(actions.setPractice(data));
}
function setPhoto({ payload: { data } }) {
    put(actions.setPhoto(data));
}
function setSettings({ payload: { data }}) {
    put(actions.setSettings(data));
}
function setPin({ payload: {data} }) {
    put(actions.setPin(data));
}

function* identitySaga() {
  yield takeEvery(actionTypes.setPractice, setPractice);
  yield takeEvery(actionTypes.setPhoto, setPhoto);
  yield takeEvery(actionTypes.setSettings, setSettings);
  yield takeEvery(actionTypes.setPin, setPin);
  yield takeEvery(actionTypes.sendVerifyRequest, sendVerificationRequest);
}

export { identitySaga };
