import { call, takeEvery, put } from 'redux-saga/effects';
import { GenericParserHttp } from '../../../libs/Http';
import { actions, actionTypes } from '../actions/codeConfirmationActions';
import { actions as patientActions } from '../../PatientDetails/actions/actions';
import { actions as guardianActions } from '../../PatientDetails/actions/guardianActions';
import { go } from '../../../routes/index';

const api = id =>
  GenericParserHttp.get(`consumer/appointment/session/${id}/verify`);

const verifyMobileApi = (mobile, id) => {
  const url = `consumer/appointment/session/${id}/verify/mobile`;
  GenericParserHttp.post(url, { data: { Mobile: mobile } }).then(
    ({ body }) => body,
  );
};

// creates verification code
function* confirmCode({ payload: { sessionId } }) {
  try {
    yield call(api, sessionId);
    yield put(actions.sendToConfirmSuccess());
  } catch (err) {
    yield put(actions.sendToConfirmFailure());
  }
}

// creates verification code and updates session with new mobile number
function* changeVerificationMobile({
  payload: { sessionId, mobile, isBookingForSomeoneElse },
}) {
  try {
    yield call(verifyMobileApi, mobile, sessionId);
    yield put(actions.changeVerificationMobileSuccess());
    yield put(
      isBookingForSomeoneElse
        ? guardianActions.changePhone(mobile)
        : patientActions.changePhone(mobile),
    );
    go.verification();
  } catch (err) {
    yield put(actions.changeVerificationMobileFailure(err));
  }
}

// sends code to existing number
function* confirmCodeSaga() {
  yield takeEvery(actionTypes.sendToConfirm, confirmCode);
}

// sends code to new number
function* mobileConfirmSaga() {
  yield takeEvery(
    actionTypes.changeVerificationMobile,
    changeVerificationMobile,
  );
}

export { confirmCodeSaga, mobileConfirmSaga };
