export enum CommentType {
  Condition,
  Medication,
  Allergy,
  DoctorNotes,
  LabResult,
  Immunisation,
}

export interface IComment {
  Id: string;
  ParentId: string;
  ConsumerId: string;
  PatientId: string;
  CommentType: CommentType;
  Deleted: boolean;
  Content: string;
  DateOfComment: string; //'2019-11-07T05:06:57.495Z';
}

export interface ICommentNew {
  ParentId: string;
  PatientId: string;
  CommentType: CommentType;
  Content: string;
}

export interface ISendFeedback {
  PatientId: string;
  NoteId: string | null;
  Usefulness: number;
  Meaningfulness: number;
  Experience: number;
  LevelOfInformation: number;
  Reconsent: number;
  Comments: string;
}

export interface ILabResult {
  Id: string;
  NameOfTest: string;
  ArticleLinks: string[] | null;
  ConsumerId: string;
  PatientId: string;
  PatientPmsId: string;
  GivenName: string;
  FamilyName: string;
  AttentionStaffCode: string;
  Abnormal: number;
  OrganisationId: string;
  OrganisationName: string;
  ProfilePictureURL: string;
  ProviderId: string;
  ProviderName: string;
  DoctorNotes: string;
  Confidential: boolean;
  DateTime: string;
  IsNewInPastMonth: boolean;
  Results: string;
  InternalComment: string;
  Comments: IComment[];
  ErrorMessage: string;
  NeedFeedBackForLabResearch: boolean;
  NoteId: string | null;
}

export interface IImmunisation {
  Id: string;
  NameOfImmunisation: string;
  VaccCode: string;
  IsNewInPastMonth: boolean;
  ConsumerId: string;
  PatientId: string;
  PatientPmsId: string;
  OrganisationId: string;
  OrganisationName: string;
  ProviderId: string;
  ProviderName: string;
  DoctorNotes: string;
  DateTime: string;
  IsNewInPast3Month: boolean;
  Summary: string;
  Comments: IComment[];
  FamilyName: string;
  GivenName: string;
  ProfilePictureURL: string;
  BatchNumber: string;
  Expiry: string;
  Route: string;
  Site: string;
}

export enum ConditionTypeEnum {
  Current,
  Past,
}

export enum AllergyTypeEnum {
  Drug,
  Generic,
  NoteOnly,
  UnKnown,
}

export interface ICondition {
  Id: string;
  PatientId: string;
  Name: string;
  Code: string;
  Description: string;
  DiagnosisDate: string;
  ConditionType: ConditionTypeEnum;
  ProviderId: string;
  EnteredBy: string;
  OrganisationId: string;
  OrganisationName: string;
  Comments: IComment[];
}

export interface IAllergy {
  Id: string;
  NameOfAllergy: string;
  AllergyType: AllergyTypeEnum;
  ConsumerId: string;
  PatientId: string;
  PatientPmsId: string;
  OrganisationId: string;
  OrganisationName: string;
  ProviderId: string;
  ProviderName: string;
  DoctorNotes: string;
  DateTime: string; //'2019-11-07T10:06:41.590Z';
  IsNewInPastMonth: boolean;
  Comments: IComment[];
}

export enum MedicationCodingSystemEnum {
  MIMS,
  NZULM,
}

export interface IMedication {
  Id: string;
  DrugCode: string;
  GenericName: string;
  BrandName: string;
  CodingSystem: MedicationCodingSystemEnum;
  ConsumerId: string;
  PatientId: string;
  PatientPmsId: string;
  OrganisationId: string;
  OrganisationName: string;
  ProviderId: string;
  ProviderName: string;
  DoctorNotes: string;
  DateTime: string; //'2019-11-07T10:06:41.590Z';
  IsNewInPastMonth: boolean;
  Comments: IComment[];
}

export interface IProcedure {
  Id: string;
  PatientId: string;
  Name: string;
  Code: string;
  Description: string;
  DiagnosisDate: string; // '2019-11-07T10:06:41.590Z';
  ConditionType: ConditionTypeEnum;
  ProviderId: string;
  ProviderName: string;
  OrganisationId: string;
  OrganisationName: string;
  Comments: IComment[];
}

export interface IIdentity {
  PatientId: string;
  ConsumerId: string;
  Email: string;
  OrganisationId: string;
  MobileVerified: boolean;
  IdentityVerified: boolean;
  VerificationStatusUpdatedOn: string; //'2019-11-07T10:06:41.591Z';
  VerificationMethod: 0;
}

export enum BloodTypeEnum {
  A = 0,
  B = 1,
  O = 2,
  AB = 3,
  UnKnown = 4,
}

export interface SummaryAPIResponse {
  ConsumerId: string;
  GivenName: string;
  FamilyName: string;
  DateOfBirth: string;
  CurrentConditions: ICondition[];
  PastConditions: ICondition[];
  Allergies: IAllergy[];
  CurrentMedications: IMedication[];
  PastMedications: IMedication[];
  Procedures: IProcedure[];
  HeightInCm: number;
  WhenHeight: string;
  WhenWeight: string;
  WeightInKg: number;
  BMI: number;
  BloodType: BloodTypeEnum;
  BloodPressure: {
    Diastolic: number;
    Systolic: number;
    WhenMeasured: string;
  }
  ErrorMessage: string;
  IdentityVerified: boolean;
  Identities: IIdentity[];
  ConsumerPrivacySettingsWithAPractice: [
    {
      OrganisationId: string;
      OrganisationName: string;
      LogoUrl: string;
      AllowAccessMyHealthRecords: {
        Allow: boolean;
        Overridden: boolean;
        Reason: string;
      };
      AllowAccessMyDoctorNotes: {
        Allow: boolean;
        Overridden: boolean;
        Reason: string;
      };
      AllowAccessMyDependantHealthRecords: {
        Allow: boolean;
        Overridden: boolean;
        Reason: string;
      };
      AllowShareMyHealthDataWithPractices: boolean;
    },
  ];
  BlacklistedCard: {
    Type: string;
    Reason: string;
    Item: IRestrictedCardItem[];
  };
  OrganisationServiceNotEnabledCard: {
    Type: string;
    Reason: string;
  };
}

interface IRestrictedCardItem {
  OrganisationName: string;
  Reason: string;
}
export interface ISummaryItem {
  Id: string;
  ClinicalFinding: string;
  Notes: string;
}

export interface IClinicNote {
  ConsultType: string;
  DateTime: string;
  FamilyName: string;
  GivenName: string;
  Id: string;
  IsNewInPastMonth: true;
  OrganisationName: string;
  ProviderName: string;
  ObjectiveNotes: string;
  SubjectiveNotes: string;
  ClinicalNotesSummaries: ISummaryItem[];
  Comments: IComment[] | null;
}

export type SelectedCommentTypeEnum =
  | CommentType.LabResult
  | CommentType.Immunisation
  | CommentType.DoctorNotes;
