import { observable, action, makeAutoObservable } from 'mobx';
import { IPatient } from './patientStore.type';

export class PatientStore {
    constructor(){
        makeAutoObservable(this);
    }

    @observable patient: IPatient = {
        Id: '00000000-0000-0000-0000-000000000000',
    }

    @observable canExpressInterest: boolean = false;

    @action.bound
    async setPatientCanExpressInterest(patientId: string, canExpressInterest: boolean) {
        this.patient.Id = patientId;
        this.canExpressInterest = canExpressInterest;
    }
}