import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import { actions as loginActions } from '../../EntryPage/actions/actions';
import SignInForm from './SignInForm/index';
import Header from '../../components/UIContainer/MobileUI/header/index';
import AppointmentInfo from '../GuestRegister/components/AppointmentInfo/index';
import LoadingSpinner from '../../components/loadingSpinner/index';
import autoRegisterImg from '../../images/autoRegisterImg.png';
import styles from './signIn.module.css';
import { DoctorProfilePropType } from '../../PracticeProfilePage/BookingPageMobile/propTypes/index';
import { go } from '../../routes/index';
import { isDesktop } from '../../config';
import { findDoctor } from '../../selectors/appointmentSelectors';

class SignIn extends Component {
  static propTypes = {
    login: PropTypes.func.isRequired,
    isLoggingIn: PropTypes.bool.isRequired,
    doctor: DoctorProfilePropType.isRequired,
    profileId: PropTypes.string,
    appointment: PropTypes.instanceOf(moment),
  };
  state = {
    height: window.innerHeight,
  };
  componentDidUpdate() {
    if (this.props.profileId) go.patientSelection(this.props.match.params.id);
  }
  goForgotPassword = () => {
    go.forgotPassword(this.props.match.params.id);
  };
  goPatientDetails = () => {
    go.patientDetails(this.props.match.params.id);
  };
  render() {
    return (
      <div className={styles.backgroundShade}>
        <LoadingSpinner
          isFetching={this.props.isLoggingIn}
          containerClassName={styles.container}
          overlayClassName={styles.overlay}
          iconClassName={styles.spinner}
          component="div"
          style={
            isDesktop()
              ? null
              : {
                  backgroundImage: `url(${autoRegisterImg})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  height: this.state.height,
                  backgroundPosition: 'center',
                }
          }
        >
          {isDesktop() ? (
            <div className={styles.welcome}>Welcome back!</div>
          ) : (
            <Header
              headerClassNames={styles.heading}
              secondLine={
                <AppointmentInfo
                  doctor={this.props.doctor}
                  appointment={this.props.appointment}
                />
              }
            />
          )}
          <SignInForm
            login={this.props.login}
            goForgotPassword={this.goForgotPassword}
            goToPatientDetails={this.goPatientDetails}
          />
        </LoadingSpinner>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoggingIn: state.loginReducer.isFetching || state.profileReducer.isFetching,
  profileId: state.profileReducer.profile.Id,
  practiceId: state.practiceProfileReducer.profile.Id,
  appointment: moment(state.practiceAppointmentReducer.selectedTime.time),
  doctor: findDoctor(
    state.practiceAppointmentReducer.selectedTime.doctorId,
    state.practiceAppointmentReducer.doctorInfo.doctors.length
      ? state.practiceAppointmentReducer.doctorInfo.doctors
      : state.practiceProfileReducer.profile.Doctors,
  ),
});
const mapDispatchToProps = dispatch => ({
  login: bindActionCreators(loginActions.login, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignIn);
export { styles };
