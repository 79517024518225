import React, { FC, useState } from 'react'
import styled from 'styled-components';
import { ReactComponent as Cross } from 'svgs/blueCross.svg';
import {device, browser, os} from '../../../libs/DeviceDetector';
import { IHidable } from '../types';
import { RadioGroup, RadioItem } from '../components/RadioGroup';
import {gifs, gifBrands} from './data';

const Container = styled.div`
    border-radius: 4px;
    // display:block;
    display:flex;
    flex-direction: column;
    background-color: white;
    overflow: hidden;
    margin: auto;
    height: 100%;
    text-align: center;
`;

const TitleBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    flex-shrink: 0;
`;

const FlexV = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const BottomContainer = styled.span`
    height: 9rem;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
`;

const Title = styled.span`
    font-size: 1.2rem;
`;

const Picture = styled.img`
    height:50%;
    width:100%;
    object-fit: contain;
    flex:1;
`;

const PlayerTitle = styled.div`
    width: 100%;
    text-align: left;
    font-weight: bold;
    font-size:15pt;
    margin-bottom: 5pt;
`;

interface IDeviceInfo {
    os: string;
    brand: string;
}

const AddToHomeScreen : FC<IHidable> = ({handleHide}) => {
    const [phone, setPhone] = useState<IDeviceInfo>({
        os: os.name,
        brand: gifBrands.find(v => v === device.vendor) ? device.vendor : 'Chrome'
    } as IDeviceInfo);
    return (
        <Container>
            <TitleBar>
                <Title>{phone.os} - {phone.os === 'iOS' ? 'Safari' : phone.brand}</Title>
                <Cross onClick={handleHide} style={{cursor: "pointer"}} />
            </TitleBar>
            <Picture src={phone.os === 'Android' ? gifs[phone.brand]: gifs[phone.os]} />
            <BottomContainer>
                <PlayerTitle>Add Vensa to your home screen</PlayerTitle>
                <span style={{fontSize:'12pt', textAlign: 'left', marginBottom: '5pt' }}>The shortcut will enable you to easily access your App</span>
                
                <RadioGroup
                    checkedValue={phone.os}
                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        let osValue = e.target.value;
                        let brandValue = '';
                        if(osValue === 'Android'){
                            if(device.vendor && gifBrands.find(v => v === device.vendor)){
                                brandValue = device.vendor;
                            }else{
                                brandValue = 'Chrome';
                            }
                        }
                        setPhone({
                            os: e.target.value, 
                            brand: brandValue
                        })}
                    }
                >
                    <RadioItem
                    value='Android'
                    name='Android'/>
                    <RadioItem
                    value='iOS'
                    name='iOS'/>
                </RadioGroup>
                <RadioGroup
                    checkedValue={phone.brand}
                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        let checkedBand =  e.target.value;
                        
                        if(gifBrands.find(v => v=== checkedBand)){
                            setPhone({...phone, os: 'Android', brand: checkedBand});
                        }else {
                            setPhone({...phone, brand: checkedBand});
                        }
                    }
                    }
                        
                >
                    {
                        phone.os === 'Android' ? 
                        <>
                        {
                            !!gifBrands && gifBrands.map((brand, index) => {
                                return <RadioItem key={`brand-${index}`} value={brand} name={brand} />
                            })
                        }
                        </>
                        :
                        <>
                        
                        </>
                    }
                </RadioGroup>
            </BottomContainer>
        </Container>
    )
}

export default AddToHomeScreen;
