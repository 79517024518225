import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Text } from 'components/vensaComponents';
import { IHealthTransaction } from '../../../../store/mobx/paymentStore/paymentStore.type';

export const Status: React.FC<{
  Status: IHealthTransaction['Status'];
}> = observer(({ Status }) => (
  <Box>
    <Text
      borderRadius="18px"
      bg="green"
      color="white"
      px={2}
      py={1}
      fontSize="13px"
    >
      {Status}
    </Text>
  </Box>
));
