import { AxiosResponseResult, callApi } from './axios';
import 'react';
import axios from './axios';

interface LoginResDto {
  access_token: string,
  expires_in: string,
  refresh_token: string,
  ConsumerUrl: string,
}

const login = async(email: string, password: string): Promise<AxiosResponseResult<LoginResDto>> => {
  const data = new URLSearchParams();
  data.append('grant_type', 'password');
  data.append('username', email);
  data.append('password', password);
  return callApi<LoginResDto>(axios.post<LoginResDto>(
    'token',
    data,
    {
      baseURL: '/'
    }
  ));
};

const logout = async(): Promise<AxiosResponseResult<void>> => callApi(axios.post('consumer/logout'));

export interface DependnatResDto {
  Id: string;
  FirstName: string;
  LastName: string;
  CaregiverId: string;
}

const retrieveDepedants = async(): Promise<AxiosResponseResult<DependnatResDto[]>> => callApi(axios.get<DependnatResDto[]>(
  'consumer/carecircle/list?login=true'
));

const dependantLogin = async(id: string) => callApi(axios.post<LoginResDto>(
  `consumer/carecircle/${id}/login`
));

export {
  login,
  logout,
  retrieveDepedants,
  dependantLogin
}