import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';

import { ReactComponent as ScriptIcon } from '../../../../svgs/scripts.svg';
import Medications from '../Medications/index';
import { fullScriptPropType } from '../../../propTypes/index';
import styles from './cardHeading.module.css';

const CardHeading = ({ script, isOpen }) => (
  <div>
    <div className={styles.container}>
      {!isOpen && <ScriptIcon className={styles.scriptIcon} />}
      <div
        className={classnames(styles.mainDetails, {
          [styles.openMainDetails]: isOpen,
        })}
      >
        <div
          className={classnames(styles.patient, {
            [styles.openPatient]: isOpen,
          })}
        >
          {`${script.FirstName} ${script.LastName}`}
        </div>
        {isOpen && <div className={styles.doctor}>{script.PmsUserName}</div>}
        <div
          className={classnames(styles.practice, {
            [styles.openPractice]: isOpen,
          })}
        >
          {`at ${script.OrganisationName}`}
        </div>
      </div>
      {!isOpen && (
        <div className={styles.date}>
          {moment(script.DateTime).format('DD/MM/YY')}
        </div>
      )}
    </div>
    <Medications meds={script.Medications} isOpen={isOpen} />
  </div>
);

CardHeading.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  script: fullScriptPropType,
};

export default CardHeading;
