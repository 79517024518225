import React from 'react';
import { map } from 'lodash/fp';
import moment from 'moment';
import { ReactComponent as ScriptIcon } from 'svgs/mainScript.svg';
import { ReactComponent as NotesIcon } from 'svgs/greyPencil.svg';
import CardBanner from '../../components/CardBanner'
import styles from './currentScript.module.css';

const CurrentScript = (script, onClick) => {
  const medsString = `${map(m => ` ${m}`)(Object.values(script.Medications))}`;
  return (
    <div className={styles.container} onClick={onClick(script.Id)}>
      <div className={styles.date}>
        {moment(script.DateTime).format('DD/MM/YY')}
      </div>
      <div className={styles.iconContainer}>
        <ScriptIcon className={styles.icon} />
      </div>
      <div className={styles.name}>{script.OrganisationName}</div>
      <div className={styles.meds}>
        {medsString.substr(0, 60).trim()}
        {medsString.length > 60 && <span>...</span>}
      </div>
      <CardBanner 
        status={script.Status} 
        color={script.StatusColour} 
        statusCode={script.StatusNumber}
      />
      <div className={styles.scriptFor}>
        {`Prescription for ${script.FirstName} ${script.LastName}`}
      </div>
      {script.HasPatientNotes && 
        <NotesIcon className={styles.patientNotesIcon}/>
      }
    </div>
  );
};

export default CurrentScript;
