import { observable, action, makeAutoObservable } from 'mobx';

import { IPrivacySettings } from './identityStore.type';
import { setPinAPI, uploadPhotoAPI, sendPrivacySettingsAPI, submitIdentityRequestAPI } from './identityStore.api';
import { generalRequest } from 'libs/newHttp';
import Alert from 'libs/Alert';
import { IPractice } from '../practicesStore/practiceStore.type';
import { IIdentityRequestsStatus } from '../accountStore/accountStore.type';

export class IdentityStore {
    constructor(){
        makeAutoObservable(this);
    }
    @observable submitSuccess: boolean = false;
    @observable loadingSubmit: boolean = false;
    @observable showError: boolean = false;
    @observable selectedPracticeName: string = "";
    @observable selectedPracticeOrgId: string = "";
    @observable selectedAddress: string = "";
    @observable selectedPhone: string = "";
    @observable selectedPic: string = "" ;
    @observable selectedPictures: string[] = [""];
    @observable pin: string = "";
    @observable photo: string = ""//File = new File([], "avatar");
    @observable makeProfilePhoto: boolean = true;
    @observable loading = false;
    @observable settings: IPrivacySettings[] = [
        {
            name: "Health Notes",
            value: true
        },
        {
            name: "Doctor Notes",
            value: true
        },
        {
            name: "Dependants",
            value: true
        },
        {
            name: "Health Data",
            value: true
        },
        {
            name: "Medications",
            value: true
        }
    ]

    @action.bound
    addPin(pin: string) {
        this.pin = pin;
    }
    @action.bound
    setError(showError: boolean) {
        this.showError = showError;
    }
    @action.bound
    addPhoto(photo: any) {
        this.photo = photo; //this.dataURLtoFile(photo);
    }
    @action.bound
    toggleProfilePhoto() {
        this.makeProfilePhoto = !this.makeProfilePhoto;
    }
    @action.bound
    toggleSetting(id: number) {
        this.settings[id].value = !this.settings[id].value;
    }
    @action.bound
    setPractice(id: string, name: string, address: string, phone: string, pic: string, pictures?: string[]) {
        this.selectedPracticeName = name;
        this.selectedPracticeOrgId = id;
        this.selectedAddress = address;
        this.selectedPhone = phone;
        this.selectedPic = pic;
        if (pictures) this.selectedPictures = pictures;
    }

    @action.bound
    setPracticeOrgId(id: string) {
        this.selectedPracticeOrgId = id;
    }
    
    @action.bound
    setPracticeSingle(practice: IIdentityRequestsStatus) {
        this.selectedPracticeName = practice.OrganisationName;
        this.selectedPracticeOrgId = practice.OrganisationId;
        this.selectedAddress = practice.Address;
        this.selectedPhone = practice.Phone;
        this.selectedPic = practice.PictureUrl;
        if (practice.Pictures) this.selectedPictures = practice.Pictures;
    }
    @action.bound
    submitIdentity() {
        this.submitSuccess = true;
    }
    @action.bound
    resetIdentityStore() {
        this.submitSuccess = false;
        this.showError = false;
        this.selectedPracticeName = "";
        this.selectedPracticeOrgId = "";
        this.selectedAddress = "";
        this.selectedPhone = "";
        this.selectedPic = "";
        this.pin = "";
        this.makeProfilePhoto = true;
        this.showError = false;
    }
    @action.bound
    async setPin(goNext: () => void) {
        this.loading = true;
        await generalRequest.bind(this)({
            api: setPinAPI(this.pin),
            afterLoaded: () => {
                this.loading = false;
                goNext();
            },
            afterFailed: (err: any) => {
                this.loading = false;
                Alert.error(err);
            }
        })
    }
    @action.bound
    async uploadPhoto(goNext: () => void) {
        this.loading = true;
        await generalRequest.bind(this)({
            api: uploadPhotoAPI(this.photo, this.makeProfilePhoto),
            afterLoaded: () => {
                this.loading = false;
                goNext();
            },
            afterFailed: (err: any) => {
                this.loading = false;
                Alert.error("An unexpected error has occured, please try again later");
            }
        })
    }

    @action.bound
    async sendPrivacySettings(onSuccess: () => void, onFailure: () => void, orgId: string) {
        this.loading = true;
        await generalRequest.bind(this)({
            api: sendPrivacySettingsAPI({
                OrganisationId: orgId,
                AllowAccessMyHealthRecords: this.settings[0].value,
                AllowAccessMyDoctorNotes: this.settings[1].value,
                AllowAccessMyDependantHealthRecords: this.settings[2].value,
                AllowShareMyHealthDataWithPractices: this.settings[3].value,
                AllowAccessAndStoreMedications: this.settings[4].value,
            }),
            afterLoaded: () => {
                this.loading = false;
                onSuccess();
            },
            afterFailed: () => {
                this.loading = false;
                onFailure();
            }
        })
    }

    @action.bound
    async submitIdentityRequest(onSuccess: () => void, onFailure: () => void) {
        this.loadingSubmit = true;
        this.submitSuccess = false;
        await generalRequest.bind(this)({
            api: submitIdentityRequestAPI(this.selectedPracticeOrgId),
            afterLoaded: () => {
                this.loadingSubmit = false;
                this.submitSuccess = true;
                onSuccess();
            },
            afterFailed: (err: any) => {
                this.loadingSubmit = false;
                this.submitSuccess = false;
                Alert.error(err);
                onFailure();
            }
        })
    }
}