import React, { lazy } from 'react';
import { ProtectedRoute, push } from '../utils';
import UserAccount from '../../UserAccountPage';
import DesktopUserAccountPage from 'UserAccountPage/DesktopUserAccountPage/DesktopUserAccountPage';
import { isDesktop } from 'config';

const go = {
  userAccount: () => push('/user-account'),
  changePassword: () => push('/change-password'),
  deactivate: () => push('/deactivate'),
};

const UserAccountRoutes = [
  <ProtectedRoute
    key="/user-account"
    path="/user-account"
    component={isDesktop() ? DesktopUserAccountPage : UserAccount}
  />,
  <ProtectedRoute
    key="/change-password"
    path="/change-password"
    component={lazy(() =>
      import(
        /* webpackChunkName: "ChangePassword" */ '../../UserAccountPage/ChangePassword'
      ),
    )}
  />,
  <ProtectedRoute
    key="/deactivate"
    path="/deactivate"
    component={lazy(() =>
      import(
        /* webpackChunkName: "DeactivateAccount" */ '../../UserAccountPage/DeactivateAccount'
      ),
    )}
  />,
];

export default UserAccountRoutes;
export { go };
