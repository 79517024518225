import React from 'react';
import moment from 'moment';
import { PDFDocument, PDFPageDrawTextOptions, StandardFonts } from 'pdf-lib';
import logo from 'images/logo.png';
import { base64FromUri } from 'services/convertImage';

export const file2Base64 = (file: File): Promise<string> => {
    const reader = new FileReader();
    return new Promise((resolve) => {
        reader.addEventListener('load', () => resolve(String(reader.result)));
        reader.readAsDataURL(file);
    });
}

const dateRegex = /^\d{4}\-\d{1,2}\-\d{1,2}$/;
export const validateDate = (date: string) => dateRegex.test(date);
export const validateNonEmpty = (...values: string[]) => values.filter(a => !a).length === 0;

/**
 * Create Declaration PDF file with attathments
 */
interface DependantParams {
    firstName: string;
    lastName: string;
    signature: Blob;
    relationship: string;
}

export const generateDeclarationFile = async(
    dependant: DependantParams, 
    attachments: File[], 
    practiceName: string,
    consumerName: string) => {
    const pdfDoc = await PDFDocument.create();
    // Declaration page
    const declarationPage = pdfDoc.addPage();
    const { width, height } = declarationPage.getSize()
    const fontSize = 12
    const font = await pdfDoc.embedFont(StandardFonts.TimesRoman);
    const textOptions = {
        x: 50,
        size: fontSize,
        font: font,
    } as PDFPageDrawTextOptions;

    let offset = 4 * fontSize;
    declarationPage.drawText('To', {...textOptions, y: height - offset});
    
    offset += fontSize * 1.5;
    declarationPage.drawText(practiceName, {...textOptions, y: height - offset});
    
    offset += fontSize * 8;
    declarationPage.drawText('I hereby declare that I have been authorised to represent and access health information for ',
        {...textOptions, y: height - offset,});
    
    offset += fontSize * 1.5;
    const time = moment().format('DD-MM-YYYY');
    declarationPage.drawText(`${dependant.firstName} ${dependant.lastName} at ${practiceName} on ${time}`,
        {...textOptions, y: height - offset});
    
    offset += fontSize * 10;
    declarationPage.drawText("Signature", {...textOptions, y: height - offset});
    const signaturePng = await pdfDoc.embedPng(await dependant.signature.arrayBuffer());
    const ratio = signaturePng.height / signaturePng.width;
    const pngWidth = width / 4;
    const pngHeight = pngWidth * ratio;
    offset += fontSize * 6;
    declarationPage.drawImage(signaturePng, {x: 50, y: height - offset, width: pngWidth, height: pngHeight})

    offset += fontSize * 4;
    declarationPage.drawText(`${consumerName}`, {...textOptions, y: height - offset});
    
    offset += fontSize * 1.5;
    declarationPage.drawText(`Relationship declared as: ${dependant.relationship}`, {...textOptions, y: height - offset});

    // Draw footer
    const smallFontSIze = 10;
    declarationPage.drawText('This letter was generated by ', {
        x: width - smallFontSIze * 20,
        y: 30,
        size: smallFontSIze,
        font: font,});
    const logoPng = await pdfDoc.embedPng(await base64FromUri(logo));
    const iconRatio = logoPng.width /logoPng.height;
    const iconHeight = height / 50;
    const iconWidth = iconHeight * iconRatio;
    declarationPage.drawImage(logoPng, {x: width - smallFontSIze * 8.5, y: 27, width: iconWidth, height: iconHeight});

    // Attachments pages
    for(const file of attachments) {
        const bytes = await file.arrayBuffer();
        switch(file.type){
        case 'application/pdf':
            const pdf = await PDFDocument.load(bytes);
            const pages = await pdfDoc.copyPages(pdf, pdf.getPageIndices());
            pages.forEach(p => pdfDoc.addPage(p));
            break;
        case 'image/png':
            const png = await pdfDoc.embedPng(bytes);
            const pngPage = pdfDoc.addPage();
            const [pngW, pngH ] = normalizeImage(width - 100, height - 100, png.width, png.height);
            pngPage.drawImage(png, {x: 50, y: height - 50 - pngH, width: pngW, height: pngH});
            break;
        case 'image/jpeg':
            const jpg = await pdfDoc.embedJpg(bytes);
            const jpgPage = pdfDoc.addPage();
            const [jpgW, jpgH ] = normalizeImage(width - 100, height - 100, jpg.width, jpg.height);
            jpgPage.drawImage(jpg, {x: 50, y: height - 50 - jpgH, width: jpgW, height: jpgH});
            break;
        }
    }

    return new File([await pdfDoc.save()], 'document.pdf')
}

const dimenOfImage = (dataUrl: string): Promise<[number, number]> => 
    new Promise((resolved) => {
        let imageHolder = new Image();
        imageHolder.onload = () => {
            resolved([imageHolder.width, imageHolder.height]);
        }
        imageHolder.src = dataUrl;
});

const normalizeImage = (
    containerWidth: number, 
    containerHeight: number, 
    imageWidth: number, 
    imageHeight: number) => {

        const ratio = imageHeight / imageWidth;
        let maxWidth = Math.min(containerWidth, imageWidth);
        let maxHeight = Math.min(containerHeight, imageHeight);
        
        let outHeight = maxWidth * ratio;
        outHeight = Math.min(outHeight, maxHeight);
        let outWidth = outHeight / ratio;

        return [outWidth, outHeight];
}

