import React, { ComponentType, FocusEvent, useState } from 'react';

export interface FocusableProps {
  isFocus?: boolean;
  onFocus?: (e: FocusEvent) => void;
  onBlur?: (e: FocusEvent) => void;
}

export interface FocusCallback {
  onFocus?: () => void;
  onBlur?: () => void;
}

type Props = {
    children: (props: FocusableProps) => React.ReactElement;
} & FocusCallback;

const FocusWithin = ({children, onFocus, onBlur}: Props) => {
  
  const [isFocus, setFocus] = useState(false);

  const handleFocus = (e: FocusEvent) => {
    setFocus(true);
    onFocus && onFocus();
  }

  const handleBlur = (e: FocusEvent) => {
    setFocus(false);
    onBlur && onBlur();
  }

  return (
    children?.({onFocus: handleFocus, onBlur: handleBlur, isFocus})
  );
}

export default FocusWithin;