import React from 'react';
import { observer } from 'mobx-react-lite';
import { Text, Button, styled } from 'components/vensaComponents';
import { Modal, ModalProps } from './Modal';
import { usePaymentsStore } from 'store/mobx/mobx';
import alert from 'libs/Alert';

const Box = styled.div`
  box-sizing: border-box;
  display: flex;
  transition: all 0.3s cubic-bezier(0.53,0.01,0.36,1.63);
  margin: 30px 0px -10px 0px;
`

export const RemoveCardModal: React.FC<ModalProps> = observer(
  ({ cardId, shouldShow, closeModal }) => {

    const store = usePaymentsStore();
    
    const deleteCard = (event) => {
      store.deleteCard(cardId)
        .then(() => store.getPaymentSummary())
        .then(() => alert.success("Card deleted successfully"));
        
      closeModal(event);
    }

    return (
      <Modal
        cardId={cardId}
        shouldShow={shouldShow}
        closeModal={closeModal}
        containerBoxProps={{
          px: '32px',
          py: '48px',
          gap: '32px',
        }}
      >
        <Text> Are you sure you want to remove this payment method?</Text>

        <Box >
          <Button
            borderColor="grey5"
            bg="white"
            onClick={closeModal}
            color="black70"
          >
            Cancel
          </Button>
          <Button onClick={(e) => deleteCard(e)}>Remove</Button>
        </Box>
      </Modal>
    );
  },
);
