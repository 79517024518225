import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';

/* eslint-disable max-len */
import { actions } from '../actions/actions';
import { actions as depActions } from '../../DependantsPage/actions/actions';
import { actions as addPharmActions } from '../PickUpLocation/AddPharmacy/actions/actions';
import { actions as searchActions } from '../actions/searchActions';
import { actions as menuActions } from '../actions/menuActions';
import { actions as listActions } from '../../PracticesPage/PracticeSearch/actions/actions';
import { actions as paymentActions } from '../Payment/actions/actions';
import { actions as popUpActions } from '../../components/DesktopPopUp/actions/actions';
import { clearScriptRequest } from '../../selectors/scriptSelectors';
import List from '../RequestScriptMobile/List';
import PracticeSearch from '../../PracticesPage/PracticeSearch';
/* eslint-enable max-len */
import LoadingSpinner from '../../components/loadingSpinner';
import PaymentForm from '../Payment/PaymentForm';
import NotWithVensa from '../../HealthFeed/HealthFeedMobile/MenuPages/NotWithVensa';
import PickUpSelectionDesktop from '../PickUpLocation/PickUpLocationDesktop';
import AddPharmacy from '../PickUpLocation/AddPharmacy';
import PaymentSelection from '../Payment/Payment';
import ConfirmRequest from '../ConfirmRequest';
import Processing from '../Processing';
import ErrorWithReason from '../Processing/components/ErrorWithReason';
import NavigationBar from './NavigationBar';
import Medications from '../Medications/MedicationsDesktop/MedicationDesktop';
import styles from './RequestScriptDesktop.module.css';
import VScriptInfo from 'components/LearnMorePopUp/vScript/vScriptInfo';
import { ReactComponent as Heart } from 'svgs/heart.svg';
import { ReactComponent as Info } from 'svgs/info_i.svg';
import MedicationsSearch from 'vScript/Medications/MedicationsDesktop/OldMedicationsDesktop';

//TODO: replace 'any' with correct type for props
interface IRequestScriptProps {
  token?: string,
  name: string,
  clearPracticeSelection: Function,
  patients: Array<object>,
  dependants: Array<object>,
  profile: any,
  patient: any,
  practice: any,
  close: any,
  connectedPractices: Array<object>,
  fetchAllPractices: Function,
  fetchConnectedPractices: Function,
  isSearching: boolean, //searching for practices
  setError: Function,
  doctors: any,
  fetchDoctors: Function,
  setInfo: Function,
  repeatPastRequest: boolean,
  isChecking: boolean,
  canRequest: boolean,
  isFetchingDoctors: boolean,
  isFetchingDependants: boolean,
  error: string,
  hide: boolean,
  fetchCardDetails: Function,
  clearScriptRequest: Function,
  clearAbleToRequest: Function,
  checkAbleToRequest: Function,
  getMedications: Function,
  cancel: Function,
  transactionTerms: any,
  getOptions: Function,
  fetchDependants: Function,
  medicationList: Array<any>,
}

export const RequestScriptDesktop: React.FC<IRequestScriptProps> = (props) => {
  const [component, setComponent] = useState(props.repeatPastRequest ? 'medications' : 'practice')
  const [practiceWithoutScript, setPracticeWithoutScript] = useState({})
  const [noMedsError, setNoMedsError] = useState({});
  const [notOnlineError, setNotOnlineError] = useState({});

  useEffect(() => {
    if ((!props.connectedPractices || props.connectedPractices.length === 0) && !props.isSearching) {
      props.fetchAllPractices(props.token);
      props.fetchConnectedPractices(props.token);
    }
    if ((!props.dependants || props.dependants.length === 0) && !props.isFetchingDependants) {
      props.fetchDependants(props.token);
    }
    return () => {
      // Anything in here is fired on component unmount.
      props.clearScriptRequest();
    }
  }, []);

  useEffect(() => {
    if (props.error) {
      setComponent('error')
    }
  }, [props.error]);

  const toggleComponent = (component: string) => () => setComponent(component);

  const handleOptionOnClick = (o, key: string, next: string) => {
    props.setInfo(key, o);
    toggleComponent(next)();
  };

  const handleNoMeds = () => {
    const patient = props.patient
    setNoMedsError({
      Reason: `${patient.FirstName} ${patient.LastName} does not have any medications on their record available for repeat.`,
      Resolution: 0
    })
    setComponent('error')
  };

  const handleNotOnline = (practice) => {
    props.setInfo('practice', practice)
    setNotOnlineError({
      Reason: `We couldn't complete your request.\r\nThere has been a communication problem with the medical centre and we won't be able to send your Repeat Prescription Request.\r\nIt's best you call the practice on their number.`,
      Resolution: 0
    })
    setComponent('notOnline')
    props.fetchAllPractices(props.token);
    props.fetchConnectedPractices(props.token);
  };

  const handleNoScript = practice => {
    toggleComponent('scriptNotEnabled');
    setPracticeWithoutScript({
      practiceWithoutScript: practice,
    });
  };

  const handleCardError = (message: string) => () => {
    props.setError(message);
  }

  const patientIsVerified = (patient) => {
    if (!patient) return false;
    if (patient.IdentityStatus && patient.IdentityStatus.StatusLabel) {
      return patient.IdentityStatus.StatusLabel.toLowerCase() === 'verified';
    }
    return patient.IdentityVerified;
  }

  const goToOldFlow = () => {
    if (!props.patient) return true;
    return !patientIsVerified(props.patient);
  }

  const handleClose = () => {
    props.clearAbleToRequest();
    props.cancel();
  }

  const getAllowedPatients = () => {
    if (!props.practice) return props.patients;
    const onlyVerifiedPatients = !props.practice.UnverifiedPatientsAllowed;
    const allowedPatients = props.patients.filter(p => {
      if (onlyVerifiedPatients) {
        return patientIsVerified(p);
      }
      return true;
    });
    //Catering for the case when a master account is not verified, and has dependants
    if (allowedPatients.length === 0 && props.patients.length > 0) {
      return props.patients.filter(p => {
        return Object.hasOwn(p, 'IdentityVerified')
      });

    }
    return allowedPatients;
  }

  const {
    name,
    token,
    connectedPractices,
    doctors,
    fetchDoctors,
    isFetchingDoctors,
    close,
    hide,
  } = props;

  const serviceTerms = props.transactionTerms != null
    ? props.transactionTerms.split('\n')
    : [];

  const components = {
    practice: (
      <List
        heading={`Hey ${name}, which Practice are you requesting a prescription with?`}
        options={connectedPractices}
        optionOnClick={o => {
          if (!o.IsAvailableForScriptRequests) {
            handleNotOnline(o)
          }
          else {
            props.clearScriptRequest();
            props.clearPracticeSelection();
            fetchDoctors(o.Id, token);
            handleOptionOnClick(o, 'practice', 'patient');
          }
        }}
        lastOption="Other"
        lastOptionFn={toggleComponent('otherPractice')}
        styledButtons
        dontShowArrow
        close={close}
      />
    ),
    otherPractice: (
      <div className={styles.autoCompleteContainer}>
        <NavigationBar
          goBack={toggleComponent('practice')}
          close={close}
        />
        <div className={styles.heading}>Which Practice?</div>
        <PracticeSearch
          successAction={o => {
            props.clearScriptRequest();
            props.clearPracticeSelection();
            fetchDoctors(o.Id, token);
            handleOptionOnClick(o, 'practice', 'patient');
          }}
          errorAction={handleNoScript}
          condition="ScriptEnabled"
        />
      </div>
    ),
    scriptNotEnabled: (
      <NotWithVensa
        practice={practiceWithoutScript}
        arrowClick={toggleComponent('otherPractice')}
        serviceAction="request a repeat prescription "
        activationService="Vensa Online prescriptions"
      />
    ),
    patient: (
      <List
        heading='For what patient?'
        options={getAllowedPatients()}
        optionOnClick={o => {
          handleOptionOnClick(o, 'patient', 'doctor');
        }}
        styledButtons
        arrowFn={toggleComponent('practice')}
        close={close}
      />
    ),
    doctor: (
      <List
        heading="Which Doctor?"
        options={doctors}
        isFetching={isFetchingDoctors}
        optionOnClick={pmsUser => {
          const pmsUserId = pmsUser.Id;
          const patientId = props.patient.Id;
          const consumerId = props.profile.Id;
          const dependantId = (consumerId !== patientId) ? patientId : null;
          props.checkAbleToRequest(props.practice.Id, pmsUserId, props.token, dependantId);
          props.getMedications(props.practice.Id, props.patient.Id, token);
          handleOptionOnClick(pmsUser, 'doctor', 'medications');
        }}
        arrowFn={toggleComponent('patient')}
        close={close}
      />
    ),
    medications: (
      !goToOldFlow() ?
        <Medications
          // token={props.token}
          goBack={toggleComponent('doctor')}
          close={close}
          onClick={o => handleOptionOnClick(o, 'medications', 'pickUp')}
          setInfo={props.setInfo}
          onNoMedications={handleNoMeds}
        />
        :
        <MedicationsSearch
          // token={props.token}
          goBack={toggleComponent('doctor')}
          close={close}
          onClick={o => handleOptionOnClick(o, 'medications', 'pickUp')}
          setInfo={props.setInfo as (...args: any[]) => any}
        />
    ),
    pickUp: (
      <PickUpSelectionDesktop
        // token={props.token}
        arrowFn={toggleComponent('medications')}
        crossFn={close}
        goToAddPharmacyDesktop={toggleComponent('addPharmacy')}
        goToConfirm={toggleComponent('confirm')}
        goToInfo={toggleComponent('info')}
      />
    ),
    info: (
      <div style={{ display: "flex"}}>
        <VScriptInfo hide={toggleComponent("pickUp")} noX={true} />
      </div>
    ),
    addPharmacy: (
      <AddPharmacy
        goToConfirm={toggleComponent('confirm')}
        arrowFn={toggleComponent('pickUp')}
        crossFn={close}
        // orgId={connectedPractices}
      />
    ),
    confirm: (
      <div className={styles.wrapper}>
        <NavigationBar
          goBack={toggleComponent('pickUp')}
          close={close}
        />
        <ConfirmRequest
          goToPaymentSelectionDesktop={toggleComponent(
            'paymentSelection',
          )}
          goToCheckout={toggleComponent('checkout')}
          // close={close}
          goToSubsidy={toggleComponent('subsidy')}
          goToInfo={toggleComponent('fee')}
          goToServiceTerms={toggleComponent('serviceTerms')}
        />
      </div>
    ),
    subsidy: (
      <div className={styles.wrapper}>
        <NavigationBar
          goBack={toggleComponent('confirm')}
        />
        <Heart style={{ margin: "2rem auto", marginBottom: "1rem" }} />
        <div style={{ width: "100%", fontWeight: "600", textAlign: "center", marginBottom: "1rem" }}>Why is the fee subsidised</div>
        <div style={{ lineHeight: "30px", padding: "0rem 1.5rem", fontWeight: "200" }}>
          Your practice has subsidised this fee for you to make it easier and more affordable for you to order your repeat scripts online.
        </div>
      </div>
    ),
    serviceTerms: (
      <div className={styles.wrapper}>
        <NavigationBar
          goBack={toggleComponent('confirm')}
        />
        <Info style={{ margin: "2rem auto", marginBottom: "1rem", height: "30px", width: "30px" }} />
        <div style={{ width: "100%", fontWeight: "600", textAlign: "center", marginBottom: "1rem" }}>Practice Service Terms</div>
        <div style={{ lineHeight: "30px", padding: "0rem 1.5rem", fontWeight: "200" }}>
          {
            serviceTerms.map((text, index) => {
              return (<div key={index}>{text}<br /></div>)
            })
          }
        </div>
      </div>
    ),
    fee: (
      <div className={styles.wrapper}>
        <NavigationBar
          goBack={toggleComponent('confirm')}
        />
        <Info style={{ margin: "2rem auto", marginBottom: "1rem", height: "30px", width: "30px" }} />
        <div style={{ width: "100%", fontWeight: "600", textAlign: "center", marginBottom: "1rem" }}>
          Why is there a transaction fee
        </div>
        <div style={{ lineHeight: "30px", padding: "0rem 1.5rem", fontWeight: "200" }}>
          The transaction fee is a one-time fee that is required to process this transaction and is generally 3.5% of the total value. It is refunded if the practice refunds your repeat script fee.
        </div>
      </div>
    ),
    checkout: (
      <div className={styles.wrapper}>
        <Processing
          tryDifferentCard={toggleComponent('paymentDetails')}
          tryOtherMethod={toggleComponent('paymentSelection')}
          close={props.close} />
      </div>
    ),
    paymentSelection: (
      <div className={styles.wrapper}>
        <NavigationBar
          goBack={toggleComponent('confirm')}
          close={props.close}
        />
        <PaymentSelection
          goToConfirm={toggleComponent('confirm')}
          addPaymentDetails={toggleComponent('paymentDetails')}
        />
      </div>
    ),
    paymentDetails: (
      <div className={styles.wrapper}>
        <NavigationBar
          goBack={toggleComponent('paymentSelection')}
          close={props.close}
        />
        <div className={styles.paymentDetailsHeading}>
          Add Payment Details
        </div>
        <PaymentForm
          handleNext={toggleComponent('confirm')}
          onError={handleCardError}
        />
      </div>
    ),
    error: (
      <div className={styles.wrapper}>
        <NavigationBar goBack={() => setComponent('patient')} close={handleClose} />
        <ErrorWithReason _error={noMedsError} desktopAction={() => setComponent('medications')} />
      </div>
    ),
    notOnline: (
      <div className={styles.wrapper}>
        <NavigationBar goBack={() => setComponent('practice')} close={handleClose} />
        <ErrorWithReason _error={notOnlineError} desktopAction={() => setComponent('practice')} />
      </div>
    ),
  };


  return (
    <div
      className={classnames(
        styles.container,
        { [styles.hidden]: hide },
        { [styles.smallPopUp]: component === 'checkout' },
      )}
    >
      {props.isChecking ? (
        <LoadingSpinner
          containerClassName={styles.page}
          overlayClassName={styles.overlay}
          iconClassName={styles.spinner}
          isFetching={props.isChecking}
        />
      ) : (
        components[component]
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  token: state.loginReducer.token,
  name: state.profileReducer.profile.FirstName,
  patients: [
    state.profileReducer.profile,
    ...state.fetchDependantsReducer.dependants,
  ],
  dependants: state.fetchDependantsReducer.dependants,
  connectedPractices: state.scriptMenuReducer.connectedPractices,
  doctors: state.scriptMenuReducer.doctors,
  isFetchingDoctors: state.scriptMenuReducer.isFetchingDoctors,
  isFetchingDependants: state.fetchDependantsReducer.isFetching,
  isSearching: state.practiceSelectionReducer.isSearching,
  repeatPastRequest: state.scriptDetailsReducer.repeatPastRequest,
  patient: state.scriptDetailsReducer.patient,
  practice: state.scriptDetailsReducer.practice,
  profile: state.profileReducer.profile,
  isChecking: state.ableToRequestReducer.isChecking,
  error:
    state.ableToRequestReducer.error || state.submitScriptRequestReducer.error,
  canRequest: state.ableToRequestReducer.canRequest,
  transactionTerms: state.pickUpOptionsReducer.terms,
  medicationList: state.getMedicationsReducer.medications,
});

const mapDispatchToProps = dispatch => ({
  fetchAllPractices: bindActionCreators(
    searchActions.fetchAllPractices,
    dispatch,
  ),
  clearPracticeSelection: bindActionCreators(
    listActions.clearPracticeSelection,
    dispatch,
  ),
  fetchConnectedPractices: bindActionCreators(
    menuActions.fetchConnectedPractices,
    dispatch,
  ),
  fetchDoctors: bindActionCreators(menuActions.fetchDoctors, dispatch),
  setInfo: bindActionCreators(actions.setInfo, dispatch),
  fetchCardDetails: bindActionCreators(
    paymentActions.fetchCardDetails,
    dispatch,
  ),
  clearAbleToRequest: bindActionCreators(actions.clearAbleToRequest, dispatch),
  setError: bindActionCreators(popUpActions.setError, dispatch),
  clearScriptRequest: clearScriptRequest(dispatch),
  getOptions: bindActionCreators(addPharmActions.getOptions, dispatch),
  fetchDependants: bindActionCreators(depActions.fetchDependants, dispatch),
  checkAbleToRequest: bindActionCreators(actions.ableToRequest, dispatch),
  getMedications: bindActionCreators(actions.getMedicationList, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestScriptDesktop);
