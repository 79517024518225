import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ReactComponent as Ellipsis } from '../../svgs/ellipsis.svg';
import { ReactComponent as Camera } from '../../svgs/camera.svg';
import ListingMenu from './ListingMenu';
import { clinicPropType } from '../propTypes';
import { isDesktop } from '../../config';
import styles from './practiceListing.module.css';

const getClinicStatus = (isOnline, isUsingVensa, name, phone) => {
  if (!isUsingVensa) {
    const number = phone ? `on ${phone}` : '';
    // eslint-disable-next-line max-len
    return `Please call ${name} ${number} and ask to enable your Vensa online booking.`;
  }
  if (!isOnline) return 'Offline for bookings';
  if (!isDesktop()) return 'Online for bookings';
  return '';
};
class PracticeListing extends Component {
  static propTypes = {
    goToProfile: PropTypes.func.isRequired,
    clinic: clinicPropType.isRequired,
    toggleMenuActive: PropTypes.func.isRequired,
    parentIsActive: PropTypes.bool.isRequired,
    unlinkPractice: PropTypes.func.isRequired,
    setClinicToRemove: PropTypes.func.isRequired,
  };
  state = { menuActive: false };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.parentIsActive) {
      this.setState({ menuActive: false });
    }
  }
  toggleMenuActive = () => {
    this.props.toggleMenuActive();
    if (isDesktop()) {
      this.setState({ menuActive: !this.state.menuActive });
    } else {
      const { offsetTop, offsetLeft, offsetWidth } = this.container;
      this.props.setClinicToRemove(this.props.clinic, {
        top: offsetTop,
        left: offsetLeft,
        width: offsetWidth,
      });
    }
  };
  render() {
    const { clinic, goToProfile, unlinkPractice } = this.props;
    return (
      <div
        className={styles.listing}
        ref={c => {
          this.container = c;
        }}
      >
        <div className={styles.menuContainer}>
          <Ellipsis
            className={styles.ellipsis}
            onClick={this.toggleMenuActive}
          />
        </div>
        {this.state.menuActive && isDesktop() && (
          <ListingMenu
            toggleMenuActive={this.toggleMenuActive}
            unlinkPractice={unlinkPractice}
          />
        )}
        <div
          className={styles.innerContainer}
          onClick={() =>
            clinic.IsOnline &&
            clinic.IsUsingVensa &&
            goToProfile(clinic.UrlName)
          }
        >
          {clinic.IsUsingVensa ? (
            <img
              className={styles.img}
              src={clinic.PictureUrl}
              alt={clinic.Name}
            />
          ) : (
            <div className={styles.notAvailable}>
              <Camera className={styles.notAvailableIcon} />
            </div>
          )}
          <div className={styles.profile}>
            <div
              className={classnames(styles.name, {
                [styles.notClickable]: !clinic.IsUsingVensa,
              })}
            >
              {clinic.Name}
            </div>
            <span className={styles.address}>
              {clinic.Address || 'No address available'}
            </span>
            <span
              className={classnames(
                styles.status,
                { [styles.online]: clinic.IsOnline && clinic.IsUsingVensa },
                {
                  [styles.notOnline]: !clinic.IsOnline || !clinic.IsUsingVensa,
                },
              )}
            >
              {getClinicStatus(
                clinic.IsOnline,
                clinic.IsUsingVensa,
                clinic.Name,
                clinic.Phone,
              )}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default PracticeListing;
