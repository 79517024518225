import React, { ComponentType } from 'react';
import { push } from 'routes/utils';
import { ClickableProps } from '../../types';


interface RedirectableProps<T> {
    Origin: ComponentType<ClickableProps<T>>;
    data: T;
    originPath: string;
    targetPath: string;
}

const RedirectableCard = <T, >(
    {
      Origin,
      data,
      originPath,
      targetPath
    }: RedirectableProps<T>) => {
        const doClick = () => push(`${targetPath}?backUrl=${originPath}`);
  return (
    <Origin handleClick={doClick} data={data}/>
  )
};

export default RedirectableCard;