const actionTypes = {
  checkEnrolledPatient: 'CHECK_PATIENT_IS_ENROLLED',
  checkEnrolledDependant: 'CHECK_DEPENDANT_IS_ENROLLED',
  checkEnrolledSomeoneElse: 'CHECK_SOMEONE_ELSE_IS_ENROLLED',
  enrolledPatientSuccess: 'CHECK_PATIENT_IS_ENROLLED_SUCCESS',
  enrolledPatientFailure: 'CHECK_PATIENT_IS_ENROLLED_FAILURE',
  clearState: 'CHECK_PATIENT_IS_ENROLLED_CLEAR_STATE',
};

const actions = {
  checkEnrolledPatient: (
    practiceUrl,
    InitiatorFirstName, 
    InitiatorLastName, 
    InitiatorMobile,
    InitiatorDateOfBirth, 
    OrganisationId, 
    PmsUserId, 
    AppointmentId, 
    Time,
    guest = false
  ) => ({
    type: actionTypes.checkEnrolledPatient,
    payload: {
      practiceUrl,
      InitiatorFirstName, 
      InitiatorLastName, 
      InitiatorMobile,
      InitiatorDateOfBirth, 
      OrganisationId, 
      PmsUserId, 
      AppointmentId, 
      Time,
      guest
    },
    error: null,
  }),
  checkEnrolledDependant: (
    practiceUrl,
    InitiatorFirstName, 
    InitiatorLastName, 
    InitiatorMobile, 
    InitiatorDateOfBirth,
    OrganisationId, 
    PmsUserId, 
    AppointmentId, 
    Time, 
    DependantId
  ) => ({
    type: actionTypes.checkEnrolledDependant,
    payload: {
      practiceUrl,
      InitiatorFirstName, 
      InitiatorLastName, 
      InitiatorMobile,
      InitiatorDateOfBirth, 
      OrganisationId, 
      PmsUserId, 
      AppointmentId, 
      Time, 
      DependantId
    },
    error: null,
  }),
  checkEnrolledSomeoneElse: (
    practiceUrl,
    InitiatorFirstName, 
    InitiatorLastName, 
    InitiatorMobile, 
    InitiatorDateOfBirth,
    OrganisationId, 
    PmsUserId, 
    AppointmentId, 
    Time, 
    FirstName, 
    LastName,
    DateOfBirth, 
    Mobile
  ) => ({
    type: actionTypes.checkEnrolledSomeoneElse,
    payload: {
      practiceUrl,
      InitiatorFirstName, 
      InitiatorLastName, 
      InitiatorMobile, 
      InitiatorDateOfBirth,
      OrganisationId, 
      PmsUserId, 
      AppointmentId, 
      Time, 
      FirstName, 
      LastName,
      DateOfBirth, 
      Mobile
    },
    error: null,
  }),


  enrolledPatientSuccess: (data) => ({
    type: actionTypes.enrolledPatientSuccess,
    payload: {
      data
    }
  }),
  enrolledPatientFailure: error => ({
    type: actionTypes.enrolledPatientFailure,
    payload: {
      error,
    },
  }),
  clearState: () => ({
    type: actionTypes.clearState,
  }),
};

export { actions, actionTypes };
