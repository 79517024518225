import { Appointment, ClickableProps } from 'pages/mobile/domain/Appointment/types';
import React from 'react';
import styles from './index.module.scss';
import moment from 'moment';
import AvatarProfile from 'components/shared/common/AvatarProfile';
import StatusCard from 'components/shared/common/StatusCard';
import { ReactComponent as Icon } from 'svgs/profileIcon.svg';

const Card: React.FC<ClickableProps<Appointment>> = ({data, handleClick}) => {
  return (
    <StatusCard status={data.Status} handleClick={handleClick} className={styles.container}>
      <AvatarProfile
        diameter='4.5rem' 
        imgUrl={data.ProviderPictureUrl}
        imgAlt={data.ProviderName}
        placeHolder={Icon}>
          <span>{moment(data.Time).format('dddd, DD MMM h:mmA')}</span>
          <span>{data.ProviderName}</span>
          <span>{`at ${data.PracticeName}`}</span>
        </AvatarProfile>
      <span className={styles.patient}>{`Booked for ${data.FirstName} ${data.LastName}`}</span>
    </StatusCard>);
};

export default Card;
