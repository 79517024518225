import React, { lazy } from 'react';
import { isDesktop } from '../../config';
import { ProtectedRoute, push } from '../utils';
import HealthFeedDesktop from '../../HealthFeed/HealthFeedDesktop';
import {HealthFeedHome} from 'pages/healthFeed';
import MainShell from '../../components/UIContainer/MobileUI/MainShell';
import LabResultsResearch from 'PDH/LabResultsResearchFlow';
import LabResultsFeedback from 'PDH/LabResultFeedbackFlow';
import LabResultsProcessing from 'PDH/Sections/Processing';

const go = {
  home: (goTo) => push({
    pathname: '/home',
    state: { goTo } 
  }),
  accountVerification: (container) => push({
    pathname: '/account-verification',
    search: "?container=" + container
  }),
  emailVerification: (goBackTo, isPrimary, isUpdate) => push({
    pathname: '/email-verification',
    state: { goBackTo, isPrimary, isUpdate }
  }),
  labResultsResearch: () => push('/labs-research'),
  labResultsResearchProcessing: () => push('/labs-research-processing'),
  labResultsFeedback: () => push('/labs-feedback')
};

const HomeRoutes = [
  <ProtectedRoute
    key="/home"
    path="/home"
    component={isDesktop() ? HealthFeedDesktop : MainShell}
  />,
  <ProtectedRoute
    key="/account-verification"
    path="/account-verification"
    component={lazy(() =>
      import(
        /* webpackChunkName: "HealthFeedAccountVerificationPage" */ '../../HealthFeed/AccountVerificationPage'
      ),
    )}
  />,
  <ProtectedRoute
    key="/labs-research"
    path="/labs-research"
    component={LabResultsResearch}
  />,
  <ProtectedRoute
    key="/labs-feedback"
    path="/labs-feedback"
    component={LabResultsFeedback}
  />,
  <ProtectedRoute
    key="/labs-research-processing"
    path="/labs-research-processing"
    component={LabResultsProcessing}
  />,
  <ProtectedRoute
    key="/email-verification"
    path="/email-verification"
    component={lazy(() => import('../../EmailVerification/EmailVerificationPage'))}
  />
];

export default HomeRoutes;
export { go };
