import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import styles from './details.module.css';
import { appointmentPropType } from '../../../../AppointmentsPage/propTypes/index';

const Details = ({ appointment, cancel, close }) => (
  <div className={styles.container}>
    <div className={styles.heading}>
      Are you sure you&#39;d like to cancel this appointment?
    </div>
    <div className={styles.details}>
      <img
        src={appointment.ProviderPictureUrl}
        alt={appointment.ProviderName}
        className={styles.img}
      />
      <div>
        <div>{appointment.ProviderName}</div>
        <div className={styles.date}>
          {moment(appointment.Time).format('dddd, DD MMM h:mmA')}
        </div>
      </div>
    </div>
    <button onClick={cancel} className={styles.cancelButton}>
      Cancel Appointment
    </button>
    <button onClick={close} className={styles.closeButton}>
      Keep Appointment
    </button>
  </div>
);

Details.propTypes = {
  appointment: appointmentPropType,
  cancel: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default Details;
