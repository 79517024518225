import Alert from 'libs/Alert';
import React, { useState } from 'react';
import { go } from 'routes';
import { useAccountStore, useMyHealthStore } from 'store/mobx/mobx';
import styled from 'styled-components';

//import Card from '../components/Card';
import { ReactComponent as BlueCross } from 'svgs/blueCross.svg';

const Container = styled.div`
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    background-color: white;
    border-radius: 4px;
    padding: 0.8rem;
`
const Title = styled.div`
    font-size: 21px;
    font-weight: medium;
    color: #07A3C8;
    text-align: center;
    margin: 1rem;
`
const Subtitle = styled.div`
    font-size: 13px;
    color: #6C6C6C;
    text-align: center;
`
const FlexV = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0rem;
`
const FlexH = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0.5rem 0rem;
    align-items: center;
`
const Bullet = styled.div`
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background-color: #07A3C8;
    color: white;
    margin: 0rem 0.5rem;
    line-height: 2;
    text-align: center;
    flex-shrink: 0;
`
const Text = styled.div`
    font-size: 13px;
    color: #6C6C6C;
    width: 100%;
`
const Button = styled.div`
    background-color: #07A3C8;
    border-radius: 29px;
    padding: 1rem 2rem;
    color: white;
    font-size: 13px;
    text-align: center;
`
const Link = styled.div`
    border-radius: 29px;
    margin: auto;
    text-align: center;
    color: #FF6A65;
    font-size: 13px;
    text-align: center;
    font-weight: bold;
`

interface Props {
    hide: (e: any) => void;
    onDecline: () => void;
}
const DeclineResearch: React.FC<Props> = ({hide, onDecline}) => {
    const [state, setState] = useState(0);
    const store = useMyHealthStore();
    
    return (
        <Container>
            <BlueCross style={{position: "absolute", top: "1rem", right: "1rem"}} onClick={hide} />
            <Title>Benefits to you</Title>
            <Subtitle>Are you sure you don't want to get involved?</Subtitle>
            <Subtitle style={{marginBottom: "1rem"}}>Benefits to you include:</Subtitle>
            <FlexV>
                <FlexH>
                    <Bullet>1</Bullet>
                    <Text>Get access your test results much faster, right in your pocket</Text>
                </FlexH>
                <FlexH>
                    <Bullet>2</Bullet>
                    <Text>Get notified when someone attempts to access your account without your permission</Text>
                </FlexH>
                <FlexH>
                    <Bullet>3</Bullet>
                    <Text>Your feedback to the medical team will eventually enable your doctor to give you personal advice with yoru lab results</Text>
                </FlexH>
                <FlexH>
                    <Bullet>4</Bullet>
                    <Text>Your input will help improve the healthcare system</Text>
                </FlexH>
            </FlexV>
            <FlexV>
                <Button onClick={hide}>I would like to continue</Button>
                <br />
                <Link onClick={(e) => {
                    store.setConsent(false);
                    store.postLabInvite(() => {
                        hide(e);
                        onDecline();
                        Alert.success("You have been withdrawn from the research. We will inform your practice.")
                    });
                    go.home();
                }}>Decline Research</Link>
            </FlexV>
        </Container>
    )
}

export default DeclineResearch