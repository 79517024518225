import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { go } from '../../../../routes';
import DesktopHeading from '../DesktopHeading/index';
import BasicInputField from '../../../../components/BasicInputField/index';
import PasswordChange from '../../../../components/PasswordChange/index';
import { DoctorProfilePropType } from '../../../../PracticeProfilePage/BookingPageMobile/propTypes/index';
import { ReactComponent as Close } from '../../../../svgs/close.svg';
import styles from './desktopForm.module.css';

const DesktopForm = ({
  doctor,
  appointment,
  email,
  password,
  blurEmail,
  changeEmail,
  changePassword,
  orgId
}) => (
  <div className={styles.container}>
    <DesktopHeading doctor={doctor} appointment={appointment} />
    <div className={styles.form} style={{position: "relative"}}>
      <Close onClick={() => go.practiceProfile(orgId)} style={{position: "absolute", top: "-30px", right: "-10px", height: "10px", cursor: "pointer"}} />
      <div className={styles.heading}>One last step...</div>
      <div className={styles.secondaryHeading}>
        Create your log in to manage your appointment.
      </div>
      <BasicInputField
        label="Email"
        type="email"
        value={email}
        className={styles.input}
        onChange={changeEmail}
        onBlur={blurEmail}
      />
      <PasswordChange
        label="Password"
        setPassword={changePassword}
        password={password}
        isWhiteBackground
      />
    </div>
  </div>
);

DesktopForm.propTypes = {
  blurEmail: PropTypes.func.isRequired,
  changeEmail: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  email: PropTypes.string,
  password: PropTypes.string,
  appointment: PropTypes.instanceOf(moment).isRequired,
  doctor: DoctorProfilePropType.isRequired,
};

export default DesktopForm;
