import React from 'react';
import { observer } from 'mobx-react-lite';
import circleRightArrow from '../../svgs/circleRightArrow.svg';
import { Box, Image, Text, styled } from 'components/vensaComponents';
import { go } from 'routes/sub/payments';
import { ICard } from 'store/mobx/paymentStore/paymentStore.type';
import { CardImage } from './CardImage';
import moment from 'moment';
import { ReactComponent as Tick } from '../../svgs/tick.svg';

const SelectedCard = styled.div`
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 30px;
    height: 30px;
    color: #23C373;
    background-color: #23C373;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const getCardMonthText = (ExpMonth: number) =>
  ExpMonth >= 10 ? ExpMonth.toString() : `0${ExpMonth}`;
export const getCardYearText = (ExpYear: number) => `${ExpYear}`;

export const isExpired = (ExpMonth: number, ExpYear: number) => {
  const month = getCardMonthText(ExpMonth);
  const year = getCardYearText(ExpYear);
  return moment(new Date()).isAfter(moment(`${year}-${month}`).add(1, 'months'));
};

export const PaymentsItem: React.FC<ICard> = observer(
  ({ Brand, ExpMonth, ExpYear, IsDefault, Last4, Token }) => {
    return (
      <Box
        alignItems="center"
        justifyContent="between"
        onClick={() => {
          go.card(Token);
        }}
      >
        <CardImage brand={Brand} />
        <Text
          textAlign="center"
          width="40%"
          fontWeight="600"
          fontSize="16px"
          >
          {`**** ${Last4}`}
        </Text>
        <Text width={"30%"} color="red">
          {IsDefault && !isExpired(ExpMonth, ExpYear) && (
            <SelectedCard>
              <Tick style={{width: '10px', height: '10px'}}/>
            </SelectedCard>
          )}
          {isExpired(ExpMonth, ExpYear) ? 'Card Expired' : ''}
        </Text>
        <Image src={circleRightArrow} height="30px" />
      </Box>
    );
  },
);
