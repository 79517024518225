import React, { useState } from 'react';
import styles from './index.module.scss';
import {ReactComponent as Icon} from 'svgs/careCircle.svg';
import {ReactComponent as Close} from 'svgs/whiteCrossSmall.svg';
import { Touchable } from 'types';
import cn from 'classnames';
import Divider from 'components/shared/common/Divider';
import StadiumButton from 'components/shared/common/StadiumButton';
import { go } from 'routes/sub/dependants';
import LearnMorePopUp from 'components/LearnMorePopUp/LearnMorePopUp';

type Props = {
  banner?: boolean;
  handleClose: () => void;
} & Touchable;

const Slogan = ({
  touch, 
  banner,
  handleClose,
}: Props) => {
  const dividerHeight = banner ? '2px' : '20px';
  const [popup, setPopup] = useState(false);
  const toggleOffPopup = () => {
    setPopup(false);
  }
  const toggleOnPopup = () => {
    setPopup(true);
  }
  const visible = !touch || (touch && !banner);
  return (
    <>
      {
          visible && 
          <div className={cn(styles.container, {[styles.banner]: banner && !touch, [styles.touch]: touch})}>
            <Close className={styles.close} onClick={handleClose}/>
            <Icon className={styles.icon} />
            <p>
              <span className={styles.title}>Welcome to Care Circle</span> <Divider orientation='horizontal' size={dividerHeight} />
              <span className={styles.content}>
                Take the stress out of looking after your loved ones. <Divider orientation='horizontal' size={dividerHeight} />
                Book appointments, request repeat scripts, and heaps more. Simply add family, Whanau and friends to your Care Circle to get started!
              </span>
            </p>
            <span className={styles.more} onClick={toggleOnPopup}>Learn more</span>
            {banner && !touch &&  <span className={styles.ok} onClick={handleClose}>Ok got it</span>}
            {!banner && !touch && <StadiumButton height='50px' text='+ Add to my Circle' handleClick={go.addDependant} />}
          </div>
      }
      {popup && <LearnMorePopUp type={"CARE_CIRCLE_INFO"} hide={toggleOffPopup} />}
    </>
  )
}

export default Slogan;