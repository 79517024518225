// In the future, everytime we have a cors API, we add the according domain name here
//
// For example:
// if the url for devtest is stormdevtestrecallapi.azurewebsites.net/api/smoking-cessation/blahblahblah
// we add a new entry in devtest property like this:
//
// 'smoking-cessation': '//stormdevtestrecallapi.azurewebsites.net',
//
// so the CROSS_REGION_APIS should look like:
//
// const CROSS_REGION_APIS = {
//   production: {
//     ...
//   },
//   uat: {
//     ...
//   },
//   demo: {
//     ...
//   },
//   devtest: {
//     'smoking-cessation': '//stormdevtestrecallapi.azurewebsites.net',
//   },
// };

const CROSS_REGION_APIS = {
  production: {
    'smoking-cessation': '//stormrecallapi.azurewebsites.net',
  },
  uat: {
    'smoking-cessation': '//stormuatrecallapi.azurewebsites.net',
  },
  demo: {
    'smoking-cessation': '//stormdemorecallapi.azurewebsites.net',
  },
  devtest: {
    'smoking-cessation': '//stormdevtestrecallapi.azurewebsites.net',
    identity: '//stormdevtestwebconsumer.azurewebsites.net',
  },
};

const host = window.location.host;
const NODE_ENV = process.env.REACT_APP_DEV_ENV;

export const isEnv = {
  devtest: () => host.startsWith('consumerdevtest') || NODE_ENV === 'devtest',
  demo: () => host.startsWith('consumerdemo') || NODE_ENV === 'devdemo',
  uat: () => host.startsWith('consumeruat') || NODE_ENV === 'devuat',
  production: () => host.startsWith('consumer.vensa.com'),
};

function getEnvFromURL() {
  let result = 'production';
  if (isEnv.devtest()) {
    result = 'devtest';
  } else if (isEnv.demo()) {
    result = 'demo';
  } else if (isEnv.uat()) {
    result = 'uat';
  }

  return result;
}

// url: `smoking-cessation/recall/detail/appointment/${recallId}`,
function getURLForCorsAPI(url) {
  if (!url) return '';
  const envObj = CROSS_REGION_APIS[getEnvFromURL()];
  const keys = Object.keys(envObj);
  let result = '';

  keys.some(key => {
    if (url.startsWith(key)) {
      result = envObj[key];
      return true;
    }
    return false;
  });

  return result;
}

export const getURLWithDomain = relativePath => {
  let domainURL = '';

  const isAPIInSeparateDomain = Object.keys(CROSS_REGION_APIS).some(key => {
    if (relativePath.startsWith(key)) {
      domainURL = key;
      return true;
    }
    return false;
  });
  return isAPIInSeparateDomain
    ? `https:${domainURL}${relativePath}`
    : relativePath;
};

export const getURL = (prefix, url) => {
  const domainURL = getURLForCorsAPI(url);
  if (domainURL) {
    return `${domainURL}${prefix}${url}`;
  }

  var ret = `${prefix}${url}`;
  if(ret.startsWith('http')) return ret;
  if(ret.startsWith('/')) return `${window.location.origin}${ret}`;
  return `${window.location.origin}/${prefix}${url}`;
};
