import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import { actions } from '../../actions/codeConfirmationActions';
import { PracticeInfoPropType } from '../../../../PracticeProfilePage/BookingPageMobile/propTypes/index';
import NotReceiving from '../NotReceiving/index';
import CallPractice from '../../../../components/CallPractice/index';
import Header from '../../../../components/UIContainer/MobileUI/header/index';
import { ReactComponent as Logo } from '../../../../svgs/logo.svg';
import styles from './smsFailureMobile.module.css';
import { goBack } from '../../../../routes/utils';
import { isBookingForSomeoneElse } from '../../../../selectors/sessionSelectors';

class SmsFailureMobile extends Component {
  static propTypes = {
    changeVerificationMobile: PropTypes.func.isRequired,
    sendToConfirm: PropTypes.func.isRequired,
    mobile: PropTypes.string.isRequired,
    countdownStartTime: PropTypes.instanceOf(moment).isRequired,
    milliSecondsToWait: PropTypes.number.isRequired,
    practice: PracticeInfoPropType.isRequired,
    sessionId: PropTypes.string.isRequired,
    isBookingForSomeoneElse: PropTypes.bool,
    isFetching: PropTypes.bool,
  };
  onPhoneChange = value => {
    //sends the code to a new mobile
    if (value !== this.props.mobile) {
      this.props.changeVerificationMobile(
        this.props.sessionId,
        value,
        this.props.isBookingForSomeoneElse,
      );
    }
    //resends the code to existing mobile
    else this.props.sendToConfirm(this.props.sessionId);
  };
  render() {
    return (
      <div className={styles.container}>
        <Header
          headerClassNames={styles.header}
          showHelp
          firstLine={
            <div onClick={goBack} className={styles.retry}>
              Retry
              <Logo className={styles.logo} />
            </div>
          }
        />
        <span className={styles.whoops}>Whoops</span>
        <NotReceiving
          phone={this.props.mobile}
          onPhoneChange={this.onPhoneChange}
          countdownStartTime={this.props.countdownStartTime}
          milliSecondsToWait={this.props.milliSecondsToWait}
          isFetching={this.props.isFetching}
        />
        <CallPractice practice={this.props.practice} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const phone = state.guardianDetailsReducer.mobile
    ? state.guardianDetailsReducer.mobile
    : state.patientDetailsReducer.patientDetails.mobile;
  return {
    practice: state.practiceProfileReducer.profile,
    mobile: state.loginReducer.token
      ? state.profileReducer.profile.Mobile
      : phone,
    countdownStartTime: state.countdownReducer.startTime,
    milliSecondsToWait: state.countdownReducer.milliSecondsToWait,
    sessionId: state.sessionReducer.sessionId,
    isFetching: state.confirmCodeReducer.requestCode.isFetching,
    isBookingForSomeoneElse: isBookingForSomeoneElse(state),
  };
};
const mapDispatchToProps = dispatch => ({
  // eslint-disable-next-line max-len
  changeVerificationMobile: bindActionCreators(
    actions.changeVerificationMobile,
    dispatch,
  ),
  sendToConfirm: bindActionCreators(actions.sendToConfirm, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SmsFailureMobile);
export { styles };
