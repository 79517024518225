import React, { PropsWithChildren } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Text } from 'components/vensaComponents';
import { Line } from 'PaymentsPage/components/Line';
import { toJS } from 'mobx';
import { BaseSectionItem } from './BaseSectionItem';

type Props = {
  title: string;
  observableData: any[];
}

export const SummarySection = observer(({ 
  title, 
  observableData, 
  children }: PropsWithChildren<Props>) => {
  const toJSData = toJS(observableData);
  const isDataEmpty = !Array.isArray(toJSData) || !toJSData.length;

  return (
    <Box direction="column">
      <Text fontWeight={700} mb="-8px" ml={3} fontSize={"15px"}>
        {title}
      </Text>
      <Line width="100%" borderColor="#ECECEC" />

      {isDataEmpty ? (
        <BaseSectionItem
          label="No records found"
          description=""
          displayIcon={false}
        />
      ) : (
        <Box direction="column" gap="12px">
          {children}
        </Box>
      )}

      <Line width="100%" borderColor="#ECECEC" />
    </Box>
  );
});
