import React, { createRef, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import '../../../node_modules/text-security/dist/text-security.css';

import Alert from '../../libs/Alert';
import bg from '../../svgs/emptyCircle.svg';
import { actions } from 'EntryPage/actions/actions';
import { useComponentDidMount } from '../../libs/newHttp';
import { go } from '../../routes';
import { useAccountStore } from 'store/mobx/mobx';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const Container = styled.form`
    display: flex;
    flex-direction: column;
    width: inherit;
    flex: none;
    width: 58%;
    margin-right: auto;
    margin-left: auto;
`
const Digit = styled.input`
    background: url(${bg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px;
    color: #07A3C8;
    width: 100%;
    text-align: center;
    border: none;
    outline: none;
    caret-color: transparent;
    overflow: visible;
    padding: 0rem;
    margin: 0rem;
    font-family: "text-security-disc";
    font-size: 30pt;
    background-position-y: 65%;
`
const Button = styled.button`
    color: white;
    background-color: #07A3C8;
    border-radius: 30px;
    border: none;
    padding: 1.3rem 0rem;
    margin-top: 2rem;
    font-size: 17px;
`

interface Props{
    n: number;
    goNext: () => void;
    loginPin: (pin: string, success: () => void, fail: () => void) => void;
}
interface Inputs {
    id: number;
    ref: React.RefObject<HTMLInputElement>;
}

const nDigitPin: React.FC<Props> = observer(props => {
    const digits: Inputs[] = [];
    const refs = React.useRef<HTMLInputElement[]>([]);
    for (let i = 0; i < props.n; i++) {
        digits.push({
            id: i,
            ref: createRef<HTMLInputElement>()
        });
    }

    let code: string = "";
    const [errorCount, setErrorCount] = useState(0);
    const [disable, setDisable] = useState(false);
    const store = useAccountStore();

    useComponentDidMount(() => { 
        window.scrollTo(0,0); 
    })

    useEffect(() => {
        if (props.n > 0) {
            if (refs.current && refs.current[0]) {
                getFocus();
            }
        }
        setDisable(store.loading);
        
    }, [props.n, store.loading]) 

    const addToRefs = (el: HTMLInputElement) => {
        if (el && !refs.current.includes(el)) {
            refs.current.push(el);
        }
    }
    const onChange = (e: any, id: number) => {
        const value: string = e.target.value;
        const isNum: boolean = /^\d+$/.test(value.toString())
        if (isNum) {
            code += value;
            if (id + 1 === props.n) {
                refs.current[id].blur();
                store.verifyPinCode(code, () => pinMatch(), (err: any) => {
                }, store.emailForRecoveringSuspendedAccount);
                reset();
                return;
            };
            getFocus();
        }
    }

    const onKeyDown = (e: any, id: number) => {
        if (e.key === 'Backspace') {
            code = code.slice(0, -1);
            getFocus()
        }
    }

    const getFocus = () => {
        let codeLength = code.length;

        refs.current[codeLength].focus()
      
        refs.current[codeLength].value = "";
    }

    const pinMatch = () => {
        refs.current[code.length].blur();
        Alert.success("PIN correct - Redirecting....");
        setErrorCount(0);
        window.scrollTo(0,0);
        setTimeout(props.goNext, 2000)
    }

    const reset = () => {
        for (let i = 0; i < props.n; i++) {
            refs.current[i].value = "";
        }
        window.scrollTo(0,0);
        code = "";
    }

    return (
        <Container>
            <div style={{ display: "flex", flexDirection: "row"}}>
            { 
                digits.map((digit, i) => {         
                    return <Digit
                        ref={addToRefs}
                        key={digit.id}
                        type="tel"
                        maxLength={1}
                        onChange={(e) => onChange(e, i)}
                        onKeyDown={(e) => onKeyDown(e, i)}
                        onClick={(e) => getFocus()}
                        pattern="[0-9]*" 
                        inputMode="numeric"
                        disabled={disable}
                        
                    />
                }) 
            }
            </div>
            <Button onClick={(e) => { 
                e.preventDefault(); 
                refs.current[0].focus();
            }}>
                Enter Pin
            </Button>
        </Container>
    );
})

const mapDispatchToProps = (dispatch: any) => ({
    loginPin: bindActionCreators<any, any>(actions.loginPin, dispatch),
})


export default connect(null, mapDispatchToProps)(nDigitPin);

