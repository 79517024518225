import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash/fp';
import { CSSTransitionGroup } from 'react-transition-group';

import styles from './bottomMenu.module.css';

const BottomMenu = ({ onCancel, buttons, showButtons }) => (
  <CSSTransitionGroup
    component="div"
    transitionName={{
      leave: styles.leave,
      leaveActive: styles.leaveActive,
      enter: styles.enter,
      enterActive: styles.enterActive,
    }}
    transitionLeaveTimeout={300}
    transitionEnterTimeout={300}
  >
    {showButtons && (
      <div className={styles.buttonContainer}>
        {map(b => (
          <button key={Math.random()} className={styles.option} onClick={b.fn}>
            {b.label}
          </button>
        ))(buttons)}
        <button className={styles.cancel} onClick={onCancel}>
          Cancel
        </button>
      </div>
    )}
  </CSSTransitionGroup>
);

BottomMenu.propTypes = {
  onCancel: PropTypes.func.isRequired,
  showButtons: PropTypes.bool,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      fn: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default BottomMenu;
