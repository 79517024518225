import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { ReactComponent as Spinner } from '../../../svgs/spinner.svg';
import UIContainer from '../../../components/UIContainer/index';
// eslint-disable-next-line max-len
import {
  DoctorProfilePropType,
  PracticeInfoPropType,
} from '../../../PracticeProfilePage/BookingPageMobile/propTypes/index';
import { detailsPropType } from '../propTypes/index';
import styles from './confirmAppointmentMobile.module.css';
import { go } from '../../../routes';

const ConfirmAppointmentMobile = ({
  doctor,
  time,
  practice,
  patient,
  isFetching,
  onDetailsEditClick,
  onConfirmClick,
}) => (
  <UIContainer
    containerClass={styles.outer}
    headerClass={styles.header}
    hideArrow
    heading={" "}
  >
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.heading}>Appointment Confirmation</div>
        <div className={styles.appointmentDetailsContainer}>
          <div className={styles.appointmentDetails}>
            <img
              className={styles.image}
              src={doctor.ProfilePictureURL}
              alt={doctor.Name}
            />
            <div>
              <div className={styles.doctorName}>{doctor.Name}</div>
              <div className={styles.practiceName}>{practice.Name}</div>
              <div className={styles.appointmentDate}>
                {moment(time).format('dddd Do MMMM')}
              </div>
              <div className={styles.appointmentTime}>
                {moment(time).format('h:mm a')}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.patientDetailsContainer}>
          <div className={styles.patientLabel}>Patient Details</div>
          <div className={styles.patientDetails}>
            <div className={styles.detailContainer}>
              <div className={styles.label}>Name</div>
              <div id="nw_patientName">{`${patient.FirstName} ${
                patient.LastName
              }`}</div>
            </div>
            <div className={styles.detailContainer}>
              <div className={styles.label}>Date of Birth</div>
              <div id="nw_patientDob">
                {moment(patient.DateOfBirth, [
                  'YYYY-MM-DD',
                  'DD-MM-YYYY',
                ]).format('DD-MM-YYYY')}
              </div>
            </div>
            <div className={styles.detailContainer}>
              <div className={styles.label}>Mobile number</div>
              <div id="nw_patientMobile">{patient.Mobile}</div>
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <button
            id="nw_confirm"
            className={styles.confirmButton}
            onClick={onConfirmClick}
          >
            {isFetching ? (
              <Spinner className={styles.spinner} />
            ) : (
              'Confirm Appointment'
            )}
          </button>
          <button className={styles.changeButton} onClick={() => go.patientSelection(patient.Id)}>
            Change Details
          </button>
        </div>
      </div>
    </div>
  </UIContainer>
);

ConfirmAppointmentMobile.propTypes = {
  onDetailsEditClick: PropTypes.func.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  doctor: DoctorProfilePropType.isRequired,
  time: PropTypes.instanceOf(moment),
  practice: PracticeInfoPropType.isRequired,
  patient: detailsPropType.isRequired,
  guardian: detailsPropType,
};

export default ConfirmAppointmentMobile;
