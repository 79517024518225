import { formatName, getShort } from 'libs/misc';
import React from 'react';
import { IImmunisation } from 'store/mobx/myHealthStore/myHealthStore.type';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    width: 100%;
    color: #6C6C6C;
    flex-direction: row;
    align-items: center;
`
const Name = styled.div`
    font-size: 15px;
`
const Practice = styled.div`
    font-size: 13px;
`
const FlexV = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
const Subject = styled.div`
    font-weight: bold;
    font-size: 13px;
    width: 100%;
`
interface IProps {
    item: IImmunisation
}
const Immunisation: React.FC<IProps> = ({ item }) => {
    return (
        <Container>
            <FlexV>
                <Name>{getShort(formatName(item.GivenName ?? '') + ' ' + formatName(item.FamilyName ?? ''), 18)}</Name>
                <Practice>{getShort(item.OrganisationName ?? '', 21)}</Practice>
            </FlexV>
            <Subject>{getShort(item.NameOfImmunisation ?? item.DoctorNotes ?? '', 20)}</Subject>
        </Container>
    )
}

export default Immunisation