import React from 'react';
import { connect } from 'react-redux';
import { Switch, withRouter } from 'react-router-dom';
import PickUpLocation from '../../vScript/PickUpLocation';
import AddPharmacy from '../../vScript/PickUpLocation/AddPharmacy';
import ConfirmRequest from '../../vScript/ConfirmRequest';
import Processing from '../../vScript/Processing';
import Payment from '../../vScript/Payment/Payment';
import AddCard from '../../vScript/Payment/AddCard';
import Error from '../../vScript/Processing/components/ErrorWithReason';
import PaymentDeclined from '../../vScript/Processing/components/PaymentDeclined';
import { ProtectedRoute, push } from '../utils';
import MedicationsSearchPage from 'vScript/Medications/MedicationsSearchPage';
import Medications from '../../vScript/Medications/Medications';
import PracticeSelectionMobile from 'vScript/PracticeSelectionMobile/PracticeSelectionMobile';
import PatientSelectionMobile from 'vScript/PatientSelectionMobile/PatientSelectionMobile';
import DoctorSelectionMobile from 'vScript/DoctorSelectionMobile/DoctorSelectionMobile';

const go = {
  selectPractice: () => push('/request-script/select-practice'),
  selectPatient: () => push('/request-script/select-patient'),
  selectDoctor: () => push('/request-script/select-doctor'),
  addMedication: (id) => push({ pathname: '/request-script/medications', state: { id }}),
  pickUpLocation: (details) => push({ pathname: '/request-script/location', state: { details }}),
  addPharmacy: () => push('/request-script/add-pharmacy'),
  confirmScript: () => push('/request-script/confirm'),
  paymentScript: () => push('/request-script/payment'),
  addCard: () => push('/request-script/add-card'),
  cardDeclined: () => push('/request-script/card-declined'),
  processScript: () => push('/request-script/processing'),
  errorScript: () => push('/request-script/error'),
  medicationSearch: () => push('/request-script/medications-search')
};

const vScriptRoutes = () => {

  return (
  <Switch>
    <ProtectedRoute
      component={PracticeSelectionMobile}
      key="/request-script/select-practice"
      path="/request-script/select-practice"
    />
    <ProtectedRoute
      component={PatientSelectionMobile}
      key="/request-script/select-patient"
      path="/request-script/select-patient"
    />
    <ProtectedRoute
      component={DoctorSelectionMobile}
      key="/request-script/select-doctor"
      path="/request-script/select-doctor"
    />
    <ProtectedRoute
      component={Medications}
      key="/request-script/medications"
      path="/request-script/medications"
    />
    <ProtectedRoute
      component={MedicationsSearchPage}
      key="/request-script/medications-search"
      path="/request-script/medications-search"
    />
    <ProtectedRoute
      component={PickUpLocation}
      key="/request-script/location"
      path="/request-script/location"
    />
    <ProtectedRoute
      component={AddPharmacy}
      key="/request-script/add-pharmacy"
      path="/request-script/add-pharmacy"
    />
    <ProtectedRoute
      component={ConfirmRequest}
      key="/request-script/confirm"
      path="/request-script/confirm"
    />
    <ProtectedRoute
      component={Payment}
      key="/request-script/payment"
      path="/request-script/payment"
    />
    <ProtectedRoute
      component={AddCard}
      key="/request-script/add-card"
      path="/request-script/add-card"
    />
    <ProtectedRoute
      component={Processing}
      key="/request-script/processing"
      path="/request-script/processing"
    />
    <ProtectedRoute
      component={PaymentDeclined}
      key="/request-script/card-declined"
      path="/request-script/card-declined"
    />
    <ProtectedRoute
      component={Error}
      key="/request-script/error"
      path="/request-script/error"
    />
  </Switch>
)};

const vScriptWrapper = withRouter(
  connect(state => ({ 
    patient: state.scriptDetailsReducer.patient,
    hasRequest: state.scriptDetailsReducer.patient.Id,
    identityVerified: state.profileReducer.profile.IdentityVerified,
    userId: state.profileReducer.profile.Id,
    bookingForId: state.scriptDetailsReducer.patient.Id
  }))(
    vScriptRoutes,
  ),
);

export default vScriptWrapper;
export { go };
