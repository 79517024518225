import React from 'react';

import { ReactComponent as ProfileIcon } from '../../../../svgs/profileIcon.svg';
import { scriptDetailsPropType } from '../../../propTypes';
import styles from './desktopHeader.module.css';

const DesktopHeader = ({ details, reason }) => (
  <div className={styles.container}>
    {details.patient.AvatarUrl ? (
      <img
        src={details.patient.AvatarUrl}
        className={styles.avatar}
        alt={details.patient.FirstName}
      />
    ) : (
      <ProfileIcon className={styles.avatar} />
    )}
    <div className={styles.details}>
      <div className={styles.name}>
        {details.patient.FirstName} {details.patient.LastName}
      </div>
      <div className={styles.doctor}>
        {details.doctor.Name}, {details.practice.Name}
      </div>
      <div className={styles.medications}>
        {details.medications.map(med => med.TradeName || med.Name).join(', ')}.
      </div>
      <div style={{ margin: "5px 0px" }}>
          Note for provider: {reason}
      </div>
    </div>
  </div>
);

DesktopHeader.propTypes = {
  details: scriptDetailsPropType.isRequired,
};

export default DesktopHeader;
