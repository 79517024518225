import React, { useState } from 'react';

import * as Styled from '../DesktopSignUpFlow.styled';
import Radio from 'components/CircleCheckbox';
import { ReactComponent as Eye } from 'svgs/eyeBlue.svg';
import PasswordPolicy from './PasswordPolicy/index';

interface IProps {
    email: string;
    password: string;
    policy: boolean;
    setPassword: (password: string) => void;
    setPolicy: (accepted: boolean) => void;
}

const DesktopSignUpFlowPassword: React.FC<IProps> = ({email, password, setPassword, policy, setPolicy}) => {
    const [inputTypePassword, setInputTypePassword] = useState(true);

    return (
        <Styled.FlexV>
            <Styled.Title>Confirm your email</Styled.Title>
            <Styled.TitleSmall>Confirm your email address and create a secure password (these details can be changed later)</Styled.TitleSmall>
            <Styled.FlexV style={{marginTop: "1rem"}}>
                <Styled.Label>Email</Styled.Label>
                <Styled.Input type={"text"} value={email} readOnly />
                <Styled.FlexH>
                    <Styled.Label>Password</Styled.Label>
                    <div style={{marginLeft: "auto", display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer" }} 
                        onClick={() => setInputTypePassword(!inputTypePassword)}
                    >
                        <Eye style={{margin: "0rem 0.3rem"}} />
                        <Styled.Label style={{color: "#07A3C8"}}>{inputTypePassword ? "Show" : "Hide"}</Styled.Label>
                    </div>
                </Styled.FlexH>
                <Styled.Input type={inputTypePassword ? "password" : "text"} value={password} onChange={(e: any) => setPassword(e.target.value)} />
                <PasswordPolicy password={password}/>
            </Styled.FlexV>
            <Styled.FlexH style={{alignItems: "center", margin: "0.5rem 0rem"}}>
                <Radio selected={policy} onClick={() => setPolicy(!policy)} />
                <Styled.Label style={{margin: "0rem 0.5rem"}}>
                    I agree to the <Styled.Link onClick={() => {
                        window.open("https://vensa.com/consumer/privacy-policy/3", "_blank")
                    }}>privacy policy</Styled.Link> and <Styled.Link onClick={() => {
                        window.open("https://vensa.com/consumer/terms-and-conditions/3", "_blank")
                    }}>terms & conditions.</Styled.Link>
                    </Styled.Label>
            </Styled.FlexH>
        </Styled.FlexV>
    )
}

export default DesktopSignUpFlowPassword