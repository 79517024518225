import React, { useState } from 'react';
import styled from 'styled-components';

import UIContainer from 'components/UIContainer';
import proImage from 'images/progresslvl3.png';
import RouteBar from 'components/DesktopComponents/RouteBar';
import { go } from 'routes';
import TabBar from 'components/DesktopComponents/TabBar';
import { ReactComponent as Privacy } from 'svgs/privacy.svg';
import { ReactComponent as Back } from 'svgs/circleRightArrow.svg';
import { ReactComponent as Arrow } from 'svgs/rightlinearrow2.svg';
import Toggle from 'Privacy/components/PrivacyToggle';
import { useIdentityStore, useAccountStore } from 'store/mobx/mobx';
import { ReactComponent as Shield } from 'svgs/shield.svg';
import PopUpVerificationNavigator from 'components/DesktopComponents/PopUpVerificationNavigator';
import { observer } from 'mobx-react-lite';

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #F9F9F9;
    overflow-y: auto;
    -ms-overflow-style: none; 
    scrollbar-width: none;  
    &::-webkit-scrollbar {
        display: none;
    }
`
const Container = styled.div`
    width: 533px;
    margin 1rem auto;
`
const Title = styled.div`
    text-transform: uppercase;
    font-size: 13px;
    margin-top: 1rem;
    text-align: left;
    margin-bottom: 1rem;
`
const Heading = styled.div`
    font-size: 17px;
    color: #07A3C8;
    font-weight: bold;
    width: 100%;
    text-align: center;
`
const Inner = styled.div`
    height: 630px;
    width: 534px;
    background-color: white;
    margin: auto;
    margin-left: 7px;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 1px 1px 3px lightgray;
    padding: 0px 3rem;
`
const Text = styled.div`
    font-size: 15px;
    text-align: left;
    margin-bottom: 1rem;
`
const Button = styled.div`
    border-radius: 4px;
    background-color: #07A3C8;
    color: white;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin: auto;
    padding: 0.6rem 1rem;
`
const Link = styled.div`
    color: #07A3C8;
    margin: auto;
    text-align: center;
    padding: 1rem;
    cursor: pointer;
    font-size: 13px;
`
const ButtonContainer = styled.div`
    margin: auto;
`
const SettingsContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const SettingsTitle = styled.div`
    font-size: 15px;
    color: #989898;
    padding: 0.6rem 0rem;
    text-align: left;
    font-weight: 600;
    margin: 0rem 1rem;
`
const FlexH = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    margin: 0.5rem 0rem;
`
const Contact = styled.div`
    font-size: 17px;
    color: #07A3C8;
    text-align: left;
    width: 100%;
    cursor: pointer;
`
const Verified = styled.div`
    color: #23C373;
    font-size: 17px; 
    text-align: right;
    width: 100%;
    padding-right: 10px;
`
const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid #DADADA;
    position: absolute;
    left: 0;
`

const PrivacySetup: React.FC = observer(() => {
    const [state, setState] = useState(0);
    const [show, setShow] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isPrimary, setIsPrimary] = useState(false);

    const storeAcc = useAccountStore();
    const storeId = useIdentityStore();
    return (
        <UIContainer isFetching={storeId.loading}>
            <Outer>
                <RouteBar 
                    lowLevel={"Identity"}
                    lowLink={() => go.identity()}
                />
                <TabBar type={0} id={1} />
                {show && 
                    <PopUpVerificationNavigator 
                        combo={() => {}} 
                        close={() => setShow(false)}
                        isCombo={false} 
                        isMobile={isMobile} 
                        isPrimary={isPrimary}
                    />
                }
                <Container>
                    <Title>Identity Status</Title>
                    <img src={proImage} alt="progress" />
                    
                    {state === 0 &&
                        <Inner style={{padding: "0rem 4rem"}}>
                            <Back onClick={() => go.identityPhoto()} style={{
                                transform: "rotate(180deg)",
                                cursor: "pointer",
                                position: "absolute",
                                top: "30px",
                                left: "30px",
                                height: "48px",
                                width: "48px",
                            }}/>
                            <Privacy style={{margin: "2rem auto", marginBottom: "2rem", width: "65px", height: "77px"}} />
                            <Heading>Your Privacy</Heading>
                            <Text style={{textAlign: "center", margin: "2rem 0rem", fontSize: "15px"}}>Privacy is a human right. To protect your privacy Vensa will:</Text>
                            <Text>1. Collect your data and store it on your behalf. We will never share it with anyone.</Text>
                            <Text>2. Provide transparency and control over your information.</Text>
                            <Text>3. Show you the above icon every time an app or feature asks to use your personal information.</Text>
                            <Text>4. Store your information securely, adhering to the highest data safety standards.</Text>
                            <ButtonContainer>
                                <Button onClick={() => setState(1)}>
                                    Continue
                                    <Arrow style={{transform: "rotate(90deg)", margin: "auto", marginLeft: "10px"}} />
                                </Button>
                                <Link>Learn More</Link>
                            </ButtonContainer>
                        </Inner>
                    }
                    {state === 1 &&
                        <Inner>
                            <Back onClick={() => setState(0)} style={{
                                transform: "rotate(180deg)",
                                cursor: "pointer",
                                position: "absolute",
                                top: "30px",
                                left: "30px",
                                height: "48px",
                                width: "48px",
                            }}/>
                            <Privacy style={{margin: "2rem auto", marginBottom: "2rem", width: "65px", height: "77px"}} />
                            <Heading style={{margin: "1.5rem auto", marginTop: "0px"}}>Your Privacy Settings</Heading>
                            <Text style={{textAlign: "center", marginBottom: "1rem"}}>
                                Select your default privacy settings for all providers you are connected with. You can change these settings anytime.
                            </Text>
                            <SettingsContainer>
                                <SettingsTitle>Privacy Settings</SettingsTitle>
                                <div>
                                    <Line />
                                </div>
                                <Toggle initial={storeId.settings[0].value} toggle={() => storeId.toggleSetting(0)} text={"Vensa will access and store all my health records, such as lab results and conditions"} />
                                <div>
                                    <Line />
                                </div>
                                <Toggle initial={storeId.settings[1].value} toggle={() => storeId.toggleSetting(1)} text={"Vensa will access and store my consultation and history notes"} />
                                {/*
                                    // TO BE REALEASED LATER
                                    <Toggle initial={storeId.settings[2].value} toggle={() => storeId.toggleSetting(2)} text={"Vensa will access and store all my dependents' health records"} />
                                    <Toggle initial={storeId.settings[3].value} toggle={() => storeId.toggleSetting(3)} text={"Vensa will share my health data with this facility"} />
                                */}
                                <div>
                                    <Line />
                                </div>
                                <Toggle initial={storeId.settings[4].value} toggle={() => storeId.toggleSetting(4)} text={"Vensa will access and store my medications list and history"} />
                                <div>
                                    <Line />
                                </div>
                            </SettingsContainer>
                            <Button style={{margin: "2rem auto"}} onClick={() => setState(2)}>
                                    Continue
                                    <Arrow style={{transform: "rotate(90deg)", margin: "auto", marginLeft: "10px"}} />
                            </Button>
                        </Inner>
                    }
                    {state === 2 &&
                        <Inner>
                            <Back onClick={() => setState(1)} style={{
                                transform: "rotate(180deg)",
                                cursor: "pointer",
                                position: "absolute",
                                top: "30px",
                                left: "30px",
                                height: "48px",
                                width: "48px",
                            }}/>
                            <Shield style={{margin: "2rem auto", marginBottom: "2rem", width: "65px", height: "77px"}} />
                            <Heading style={{margin: "1.5rem auto", marginTop: "0px"}}>Recovery Method</Heading>
                            <Text style={{textAlign: "center", marginBottom: "1rem"}}>
                                The contact details below can be used to recover your account if you forget your details or if you get locked out of your account.
                            </Text>
                            <SettingsContainer style={{margin: "1rem 3rem"}}>
                                <SettingsTitle style={{borderBottom: "1px solid #DADADA", margin: "0rem"}}>Trusted Email & Mobile</SettingsTitle>
                                {storeAcc.profile.RecoveryEmail && <Contact style={{fontSize: "13px", lineHeight: "15px", marginTop: "1rem"}}>Account Email:</Contact>}
                                <FlexH style={{marginTop: storeAcc.profile.RecoveryEmail ? "0rem" : "1rem", marginBottom: "1rem"}}>
                                    <Contact onClick={() => {
                                        setIsMobile(false);
                                        setIsPrimary(true);
                                        setShow(true);
                                    }}>
                                        {storeAcc.profile.Email}
                                    </Contact>
                                    <Verified>Verified &gt;</Verified>
                                </FlexH>
                                {storeAcc.profile.RecoveryEmail && <Contact style={{fontSize: "13px", lineHeight: "15px"}}>Recovery Email:</Contact>}
                                { storeAcc.profile.RecoveryEmail &&
                                    <FlexH style={{marginTop: "0px", marginBottom: "1rem"}}>
                                        <Contact onClick={() => {
                                            setIsMobile(false);
                                            setIsPrimary(false);
                                            setShow(true);
                                        }}>
                                            {storeAcc.profile.RecoveryEmail}
                                        </Contact>
                                        <Verified>Verified &gt;</Verified>
                                    </FlexH>
                                }
                                <FlexH style={{borderBottom: "1px solid #DADADA", paddingBottom: "1rem"}}>
                                    <Contact onClick={() => {
                                        setIsMobile(true);
                                        setIsPrimary(true);
                                        setShow(true);
                                    }}>
                                        {storeAcc.profile.Mobile}
                                    </Contact>
                                    <Verified>Verified &gt;</Verified>
                                </FlexH>
                            {!storeAcc.profile.RecoveryEmail && <Contact onClick={() => setShow(true)} style={{cursor: "pointer"}}>Add Additional Recovery Email</Contact>}
                            {!storeAcc.profile.RecoveryEmail && <Contact onClick={() => setShow(true)} style={{fontSize: "13px", lineHeight: "15px", cursor: "pointer"}}>(Recommended)</Contact>}
                            
                            </SettingsContainer>
                            <Button style={{margin: "2rem auto"}} onClick={() => storeId.sendPrivacySettings(() => go.identitySubmit(), () => console.log("Error"), storeId.selectedPracticeOrgId)}>
                                Continue
                                <Arrow style={{transform: "rotate(90deg)", margin: "auto", marginLeft: "10px"}} />
                            </Button>
                        </Inner>
                    }
                </Container>    
            </Outer>
        </UIContainer>
    )
})

export default PrivacySetup;