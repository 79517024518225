import { call, takeEvery, put } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';
import { GenericParserHttp } from '../../libs/Http';
import { checkAuth } from 'libs/misc';

const api = id =>
  GenericParserHttp.get('consumer/appointment/booked', { token: id }).then(
    ({ body }) => body,
  );

function* getConfirmedAppointments({ payload: { id } }) {
  try {
    const appointment = yield call(api, id);
    yield put(actions.getConfirmedAppointmentsSuccess(appointment));
  } catch (err) {
    checkAuth(err);
    yield put(actions.getConfirmedAppointmentsFailure(err));
  }
}

function* confirmedAppointmentsSaga() {
  yield takeEvery(
    actionTypes.getConfirmedAppointments,
    getConfirmedAppointments,
  );
}

export { confirmedAppointmentsSaga };
