import React from 'react';
import PropTypes from 'prop-types';

import styles from './content.module.css';

const Content = ({ url, accept }) => (
  <div className={styles.container}>
    <iframe
      title="Terms and Conditions"
      src={url}
      className={styles.textContainer}
    />
    <div className={styles.blend} />
    <button className={styles.button} onClick={accept}>
      Accept
    </button>
  </div>
);
Content.propTypes = {
  accept: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

export default Content;
export { styles };
