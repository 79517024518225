import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import UIContainer from 'components/UIContainer';
import { useParams, Redirect } from 'react-router';
import styled from 'styled-components';
import { useMyHealthStore } from 'store/mobx/mobx';
import { BaseDetailSection } from 'MyHealthPage/components/BaseDetailSection';
import { AddComment } from 'MyHealthPage/components/AddComment';
import { CommentType } from 'store/mobx/myHealthStore/myHealthStore.type';
import { actions } from 'components/UIContainer/MobileUI/MainShell/actions/actions';
import moment from 'moment';
import { go } from 'routes';
import { useDispatch } from 'react-redux';
import { useComponentDidMount } from 'libs/newHttp';
import Loading from 'components/loadingSpinner';

export interface ILabResult {
  Id: string;
  NameOfTest: string;
  ConsumerId: string;
  PatientId: string;
  PatientPmsId: string;
  GivenName: string;
  FamilyName: string;
  AttentionStaffCode: string;
  Abnormal: number;
  OrganisationId: string;
  OrganisationName: string;
  ProfilePictureURL: string;
  ProviderId: string;
  ProviderName: string;
  DoctorNotes: string;
  Confidential: boolean;
  DateTime: string;
  IsNewInPastMonth: boolean;
  Results: string;
  Comments: [];
  ErrorMessage: string;
}

const Background = styled.div`
    background-color: #F5F5F5;
    position: absolute;
    height: 100%;
    width: 100%;
    transition: all 0.3s linear;
    z-index: 10;
    visibility: visible;
`
const LabResultPage: React.FC = observer(() => {
  const { id } = useParams<{ id: string }>();
  const phrStore = useMyHealthStore();
  
  const dispatch = useDispatch();

  useEffect(() => {
    if (phrStore.currentLabPage) {
      if (phrStore.currentLabPage.NeedFeedBackForLabResearch) {
        dispatch(actions.setWhiteBoxNotification({
          isOpen: true,
          index: 4
        }))
      }
    } else if (itemToShow) {
      if (itemToShow.NeedFeedBackForLabResearch) {
        dispatch(actions.setWhiteBoxNotification({
          isOpen: true,
          index: 4
        }))
      }
    }
  })

  // Finding relevant lab result:
  // - From existing list of all results
  // - From health feed notification
  // - From drop down lab tab notification
  let itemToShow = phrStore.labResultsTab.all.find(item => item.Id === id);
  if (!id || !itemToShow) {
    if (phrStore.currentLabPage === undefined) {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let patientId = params.get('p');
      let when = params.get('w');

      if (patientId === null || when === null) {
        return <Redirect to={"/home"} />
      }

      phrStore.getLabResult(patientId, when, () => itemToShow = phrStore.currentLabPage);
    } else {
      itemToShow = phrStore.currentLabPage;
    }
  }
  const getDate = (dateTime: string) => moment(dateTime).format('D/MM/YY');
  if (itemToShow === undefined) {
    return (
      <div>
        <Background />   
        <Loading isFetching={true} style={{position: "absolute", top: "6rem"}} />
      </div>
    )
  }else {
    phrStore.setCurrentLabPage(itemToShow);
    return (
      <UIContainer heading=" " phrText={"Received by practice on " + getDate(itemToShow.DateTime)} isFetching={false} goBackFn={() => go.home(3)}>
        <div style={{height: "calc(100vh - 115px)"}}>
          {!itemToShow ? <Loading isFetching={phrStore.loading}/> :
          <BaseDetailSection
            isLabResult
            articleLinks={itemToShow.ArticleLinks}
            internalComment={itemToShow.InternalComment}
            topText={itemToShow.DoctorNotes}
            providerName={itemToShow.ProviderName}
            providerImageUrl={itemToShow.ProfilePictureURL}
            dateTime={itemToShow.DateTime}
            itemName={itemToShow.NameOfTest}
            givenName={itemToShow.GivenName}
            familyName={itemToShow.FamilyName}
            organisationName={itemToShow.OrganisationName}
            bottomTitle="Results: "
            bottomText={itemToShow.Results}
            doctorNote={itemToShow.DoctorNotes}
          />
          }
          <AddComment
            commentType={CommentType.LabResult}
            onSubmit={phrStore.postNewComment.bind(phrStore)}
            itemToShow={itemToShow}
            itemId={id}
            label="+ Add comment for yourself"
          />
      </div>
      </UIContainer>
    );
  }
});

export default LabResultPage;
