import { call, takeEvery, put } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';
import { GenericParserHttp } from '../../../libs/Http';

const api = (DateOfBirth, orgId, token) =>
  GenericParserHttp.get(`consumer/prescription/${orgId}/pricing-pickup`, {
    data: { DateOfBirth },
    token,
  }).then(({ body }) => body);

const dependantsApi = (DateOfBirth, orgId, token, dependantsId) =>
  GenericParserHttp.get(`consumer/prescription/${orgId}/pricing/${dependantsId}`, {
    data: { DateOfBirth },
    token,
  }).then(({ body }) => body);

function* getPickUpOptions({ payload: { dateOfBirth, orgId, token } }) {
  try {
    const options = yield call(api, dateOfBirth, orgId, token);
    yield put(actions.fetchPickUpOptionsSuccess(options));
  } catch (err) {
    yield put(actions.fetchPickUpOptionsFailure(err));
  }
}

function* getOptionsDependants({ payload: { dateOfBirth, orgId, token, dependantsId } }) {
  try {
    const options = yield call(dependantsApi, dateOfBirth, orgId, token, dependantsId);
    yield put(actions.fetchPickUpOptionsSuccess(options));
  } catch (err) {
    yield put(actions.fetchPickUpOptionsFailure(err));
  }
}

function* getPickUpOptionsSaga() {
  yield takeEvery(actionTypes.fetchPickUpOptions, getPickUpOptions);
}

function* getPickUpOptionsDependantsSaga() {
  yield takeEvery(actionTypes.fetchPickUpOptionsDependant, getOptionsDependants);
}

export { getPickUpOptionsSaga, getPickUpOptionsDependantsSaga };
