import React from 'react';
import { observer } from 'mobx-react-lite';
import { Text } from 'components/vensaComponents';
import { IHealthTransaction } from '../../../../store/mobx/paymentStore/paymentStore.type';
import { TransactionSection } from './TransactionSection';

export const PickUpLocation: React.FC<Pick<
  IHealthTransaction,
  'LocationName' | 'LocationAddress'
>> = observer(({ LocationName, LocationAddress }) => (
  <TransactionSection title="Pick-up location:">
    <Text>{LocationName}</Text>
    <Text mt="10px">{LocationAddress}</Text>
  </TransactionSection>
));
