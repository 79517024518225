import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';

import { ReactComponent as Close } from '../../../svgs/close.svg';
import { appointmentPropType } from '../../propTypes/index';
import styles from './addToCalendar.module.css';
import { isDesktop } from '../../../config';

const AddToCalendar = ({ appointment, show, toggleMenu }) => (
  <CSSTransitionGroup
    component="div"
    transitionName={{
      leave: styles.leave,
      leaveActive: styles.leaveActive,
      enter: styles.enter,
      enterActive: styles.enterActive,
    }}
    transitionLeaveTimeout={300}
    transitionEnterTimeout={300}
  >
    {show && (
      <div className={styles.container}>
        {isDesktop() && <Close className={styles.cross} onClick={toggleMenu} />}
        <div className={styles.title}>Add to Calendar</div>
        <div className={styles.buttonContainer}>
          <a
            className={styles.button}
            href={appointment.GoogleCalendarUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Calendar
          </a>
          <a
            className={styles.button}
            href={appointment.CalendarUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            iCalendar
          </a>
          {isDesktop() && (
            <a
              className={styles.button}
              href={appointment.CalendarUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Microsoft Outlook
            </a>
          )}
        </div>
      </div>
    )}
  </CSSTransitionGroup>
);

AddToCalendar.propTypes = {
  appointment: appointmentPropType.isRequired,
  show: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

export default AddToCalendar;
