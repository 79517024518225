import React from 'react';
import useWizard from 'components/core/Wizard/useWizard';
import Waiting from 'components/WaitingTransition';
import Alert from 'libs/Alert';
import { useAddDependant } from 'apiHooks/carecircle';
import { Touchable } from 'types';
import withModal from 'hocs/withModal';

const RedirectStep = ({touch}: Touchable) => {
    const {previousStep, complete, valueObject} = useWizard();

    const {data, error: resError, loading} = useAddDependant({
      id: valueObject.id,
      firstName: valueObject.firstName,
      lastName: valueObject.lastName,
      nickName: valueObject.nickName,
      dateOfBirth: valueObject.dateOfBirth,
      relationship: valueObject.relationship,
      document: valueObject.pdf as File,
      fileCount: String((Object.keys(valueObject).filter(k => k.startsWith('Document')).length) + (valueObject.pdf ? 1 : 0)),
      avatar: valueObject.avatarBlob
    });
    const fullName = `${valueObject.firstName} ${valueObject.lastName}`;
    const handleError = () => {
      if(resError){
        previousStep();
        Alert.error(resError.message);
      }
    }
    
    const WaitingPage = touch ? Waiting : withModal(Waiting);
    
    return (
      // <a download='a.pdf' href={URL.createObjectURL(valueObject.pdf)} title='Download pdf'>download</a>
        <WaitingPage
          title={`You have added ${fullName} to your Care Circle`}
          tip={`Adding ${fullName} to your Care Circle`}
          error={resError?.message}
          condition={loading}
          complete={complete}
          onError={handleError}
         />
      );
}
export default RedirectStep;