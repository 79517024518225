import { request, HTTP } from '../../../libs/newHttp';
import { IPractice } from './practiceStore.type';

export const getAllPracticesAPI = () => 
    request({
        method: HTTP.GET,
        url: `consumer/practice/all`,
    });

export const getUserPracticesAPI = () => 
    request({
        method: HTTP.GET,
        url: `consumer/practice`,
    });

export const getPracticeDetailsAPI = ({orgID} : {orgID: string}) => () =>
    request({
        method: HTTP.GET,
        url: `consumer/organisation/${orgID}`
    })
export const linkPracticeAPI = (PracticeId: string) => () => 
    request({
        method: HTTP.POST,
        url: `consumer/practice`,
        data: { PracticeId }
    })

export const unlinkPracticeAPI = (transferablePractice: IPractice) => () => 
    request({
        method: HTTP.DELETE,
        url: `consumer/practice`,
        data: transferablePractice
    })