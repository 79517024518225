import React, { useState } from 'react';
import styled from 'styled-components';

import UIContainer from 'components/UIContainer';
import proImage from 'images/progresslvl3.png';
import RouteBar from 'components/DesktopComponents/RouteBar';
import { go } from 'routes';
import TabBar from 'components/DesktopComponents/TabBar';
import { ReactComponent as Privacy } from 'svgs/privacy.svg';
import { ReactComponent as Back } from 'svgs/circleRightArrow.svg';
import { ReactComponent as Arrow } from 'svgs/rightlinearrow2.svg';
import Toggle from 'Privacy/components/PrivacyToggle';
import { useIdentityStore, useAccountStore } from 'store/mobx/mobx';
import { ReactComponent as Shield } from 'svgs/shield.svg';
import PopUpVerificationNavigator from 'components/DesktopComponents/PopUpVerificationNavigator';
import { observer } from 'mobx-react-lite';
import { useComponentDidMount } from 'libs/newHttp';
import Loading from 'components/loadingSpinner';
import { useEffect } from 'react';

const Heading = styled.div`
    font-size: 21px;
    color: #07A3C8;
    font-weight: 600;
    width: 100%;
    text-align: center;
    margin: 1.5rem 0rem;
`
const Inner = styled.div`

`
const Text = styled.div`
    font-size: 17px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 1rem;
`
const Button = styled.div`
    width: 60%;
    text-align: center;
    color: white;
    background-color: #07A3C8; 
    border-radius: 30px;
    padding: 20px 0px;
    font-size: 10pt;
`
const Link = styled.div`
    color: #07A3C8;
    margin: auto;
    text-align: center;
    padding: 1rem;
    cursor: pointer;
    font-size: 13px;
`
const ButtonContainer = styled.div`
    margin: auto;
`
const SettingsContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
`
const SettingsTitle = styled.div`
    font-size: 15px;
    color: #6C6C6C;
    padding: 0.6rem 0rem;
    text-align: left;
    font-weight: 600;
`
const Line = styled.div`
    width: 100%;
    position: absolute;
    border-bottom: 1px solid #DADADA;
    left: 0;
`
const FlexH = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    margin: 0.5rem 0rem;
`
const Contact = styled.div`
    font-size: 17px;
    color: #07A3C8;
    text-align: left;
    width: 100%;
`
const Verified = styled.div`
    color: #23C373;
    font-size: 17px; 
    text-align: right;
    width: 100%;
    padding-right: 10px;
`
const Unverified = styled.div`
    color: #ff6a65;
    font-size: 17px; 
    text-align: right;
    width: 100%;
    padding-right: 10px;
`

const PrivacyControls: React.FC<{headerState: number, setHeaderState: (num: number) => void, goNext: () => void}> = observer(({headerState, setHeaderState, goNext}) => {
    const [show, setShow] = useState(false);
    const storeAcc = useAccountStore();
    const storeId = useIdentityStore();

    let email = storeAcc.profile.Email.length > 24 ? storeAcc.profile.Email.substring(0, 22) + "..." : storeAcc.profile.Email;
    let emailR = "";

    if (storeAcc.profile.RecoveryEmail) {
        emailR = storeAcc.profile.RecoveryEmail.length > 24 ? storeAcc.profile.RecoveryEmail.substring(0, 22) + "..." : storeAcc.profile.RecoveryEmail;
    }

    useComponentDidMount(() => { 
        storeAcc.getProfile();
    });

    return (
            <div>
                    {headerState === 0 &&
                        <Inner style={{paddingLeft: "1rem", paddingRight: "1rem"}}>
                            <Heading>Privacy</Heading>
                            <Text style={{textAlign: "center", margin: "2rem 0rem", fontWeight: "normal", fontSize: "17px"}}>Privacy is a human right. The Vensa system is designed to:</Text>
                            <Text style={{padding: "0rem 0.6rem"}}>1. Collect your data and store it on your behalf. We will never share it with anyone without your permission.</Text>
                            <Text style={{padding: "0rem 0.6rem"}}>2. Provide transparency and control over your information.</Text>
                            <Text style={{padding: "0rem 0.6rem"}}>3. Show you the above icon every time an app or feature asks to use your personal information.</Text>
                            <Text style={{padding: "0rem 0.6rem"}}>4. Keep your information stored securely to the highest standard.</Text>
                            <ButtonContainer>
                                <Button style={{margin: "4rem auto", marginBottom: "0rem"}} onClick={() => {
                                    goNext();
                                    setHeaderState(1);
                                    window.scroll(0, 0);
                                }}>
                                    Continue
                                </Button>
                                {/*<Link>Learn More</Link>*/}
                            </ButtonContainer>
                        </Inner>
                    }
                    {headerState === 1 &&
                        <Inner style={{paddingBottom: "1rem"}}>
                            <Heading>Privacy</Heading>
                            <Text style={{textAlign: "center", marginBottom: "1rem", marginLeft: "1rem", marginRight: "1rem"}}>
                                Set your default privacy across all providers you are connected with. You can change these anytime.
                            </Text>
                            <SettingsContainer>
                                <SettingsTitle style={{marginLeft: "1rem"}}>Privacy</SettingsTitle>
                                <div>
                                    <Line />
                                </div>
                                
                                <Toggle initial={storeId.settings[0].value} toggle={() => storeId.toggleSetting(0)} text={"Vensa will access and store my health summary"} />
                                <Toggle initial={storeId.settings[1].value} toggle={() => storeId.toggleSetting(1)} text={"Vensa will access and store my consultation and history notes"} />
                                <Toggle initial={storeId.settings[4].value} toggle={() => storeId.toggleSetting(4)} text={"Vensa will access and store my medications list and history"} />
                            </SettingsContainer>
                            <Button style={{margin: "2rem auto"}} onClick={() => {
                                goNext();
                                setHeaderState(2);
                                window.scrollTo(0, 0);
                            }}>
                                    Continue
                            </Button>
                        </Inner>
                    }
                    {headerState === 2 &&
                        <Inner style={{paddingBottom: "1rem"}}>
                            <Loading isFetching={storeId.loading}>

                                <Heading>Recovery Method</Heading>
                                <Text style={{textAlign: "center", margin: "1rem"}}>
                                    The contact details below can be used to recover your account if you forget your details or if you get locked out of your account.
                                </Text>
                                <SettingsContainer style={{margin: "1rem 0rem", padding: "0rem 1rem"}}>
                                    <SettingsTitle>Trusted Email & Mobile</SettingsTitle>
                                    <div>
                                        <Line />
                                    </div>
                                    {storeAcc.profile.RecoveryEmail && <Contact style={{fontSize: "13px", lineHeight: "15px", marginTop: "1rem"}}>Account Email:</Contact>}
                                    <FlexH style={{marginTop: storeAcc.profile.RecoveryEmail ? "0rem" : "1rem", marginBottom: "1rem"}}>
                                        <Contact>{email}</Contact>
                                        <Verified>Verified &gt;</Verified>
                                    </FlexH>
                                    {storeAcc.profile.RecoveryEmail && <Contact style={{fontSize: "13px", lineHeight: "15px"}}>Recovery Email:</Contact>}
                                    { storeAcc.profile.RecoveryEmail &&
                                        <FlexH style={{marginTop: "0px", marginBottom: "1rem"}}>
                                            <Contact>{emailR}</Contact>
                                            <Verified>Verified &gt;</Verified>
                                        </FlexH>
                                    }
                                    <FlexH style={{marginBottom: "1rem"}}>
                                        <Contact>{storeAcc.profile.Mobile}</Contact>
                                        {storeAcc.profile.MobileVerified ? <Verified>Verified &gt;</Verified> : <Unverified>Not Verified &gt;</Unverified>}
                                    </FlexH>
                                    <div style={{marginBottom: "1rem"}}>
                                        <Line />
                                    </div>
                                {/* {!storeAcc.profile.RecoveryEmail && <Contact onClick={() => go.emailVerification(1, false, false)} style={{cursor: "pointer"}}>Add Additional Recovery Email</Contact>}
                                {!storeAcc.profile.RecoveryEmail && <Contact onClick={() => go.emailVerification(1, false, false)} style={{fontSize: "13px", lineHeight: "15px", cursor: "pointer"}}>(Recommended)</Contact>} */}
                                
                                </SettingsContainer>
                                <Text style={{fontSize: "12px", fontWeight: "normal", margin: "1rem"}}>
                                    Trusted phone numbers and emails are used to verify your identity wen signing in (along with your PIN code) and to help recover your account if you have forgotten your info.
                                </Text>
                                <Button style={{margin: "2rem auto"}} onClick={() => storeId.sendPrivacySettings(() => go.identitySubmit(), () => console.log("Error"), storeId.selectedPracticeOrgId)}>
                                    Continue
                                    <Arrow style={{transform: "rotate(90deg)", margin: "auto", marginLeft: "10px"}} />
                                </Button>
                            </Loading>

                        </Inner>
                    }
                </div>
    )
})

export default PrivacyControls;