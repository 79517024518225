import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import classnames from 'classnames';

import styles from './processing.module.css';
import Confirmed from './components/Confirmed';
import GenericError from './components/GenericError';
import Transition from '../../components/Transition';
import PaymentDeclined from './components/PaymentDeclined';
import RetryLimitReached from './components/RetryLimitReached';
import loadingGif from '../../images/LoadingGif.gif';
import { actions } from '../ConfirmRequest/actions/actions';

class Processing extends Component {
  static propTypes = {
    submitSuccess: PropTypes.bool.isRequired,
    pushProp: PropTypes.func,
    error: PropTypes.shape({ status: PropTypes.number.isRequired }),
    clearScriptRequest: PropTypes.func,
    pollSuccess: PropTypes.bool.isRequired,
    confirmationPollStart: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    pollError: PropTypes.bool,
    retrySuccess: PropTypes.bool.isRequired,
    retryFailure: PropTypes.bool.isRequired,
    retryLimitReached: PropTypes.bool,
    tryDifferentCard: PropTypes.func.isRequired,
    tryOtherMethod: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired
  };
  state = {
    prescriptionId: ''
  }
  UNSAFE_componentWillReceiveProps = ({
    submitSuccess,
    scriptId,
    pollError,
    retrySuccess,
  }) => {
    this.setState(() => ({
      prescriptionId: scriptId
    }));
    if (submitSuccess !== this.props.submitSuccess && submitSuccess === true) {
      this.props.confirmationPollStart(scriptId, this.props.token);
    }
    if (retrySuccess !== this.props.retrySuccess && retrySuccess === true) {
      this.props.confirmationPollStart(scriptId, this.props.token);
    }
  };
  render() {
    return (
      <Transition
        className={classnames(styles.container, {
          [styles.noBackground]:
            this.props.pollError || this.props.retryLimitReached,
        })}
      >
        {!this.props.pollSuccess &&
          !this.props.pollError &&
          !this.props.retryFailure &&
          !this.props.retrySuccess &&
          !this.props.retryLimitReached &&
          !this.props.error && (
            <div key="0">
              <img
                alt="confirming script request"
                className={styles.loadingGif}
                src={loadingGif}
              />
            </div>
          )}
        {(() => {
          if (
            (this.props.submitSuccess && this.props.pollSuccess) ||
            (this.props.retrySuccess && this.props.pollSuccess)
          ) {
            return <Confirmed key="1" />;
          } else if (this.props.pollError || this.props.retryFailure) {
            return (
              <PaymentDeclined
                key="3"
                tryDifferentCard={this.props.tryDifferentCard}
                tryOtherMethod={this.props.tryOtherMethod}
              />
            );
          } else if (this.props.retryLimitReached) {
            return <RetryLimitReached key="4" prescriptionId={this.state.prescriptionId} />;
          } else if (this.props.error) {
            return <GenericError key="2" />;
          } else return null;
        })()}
      </Transition>
    );
  }
}

const mapStateToProps = state => ({
  token: state.loginReducer.token,
  scriptId: state.submitScriptRequestReducer.scriptId,
  submitSuccess: state.submitScriptRequestReducer.confirmed,
  error: state.submitScriptRequestReducer.error,
  pollSuccess: state.confirmationReducer.confirmed,
  pollError: state.confirmationReducer.error,
  willRetry: state.confirmationReducer.willRetry,
  retrySuccess: state.retryRequestReducer.retrySuccess,
  retryFailure: state.retryRequestReducer.retryFailure,
  retryLimitReached: state.retryRequestReducer.retryLimitReached,
});
const mapDispatchToProps = dispatch => ({
  confirmationPollEnd: bindActionCreators(
    actions.confirmationPollEnd,
    dispatch,
  ),
  confirmationPollStart: bindActionCreators(
    actions.confirmationPollStart,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Processing);
export { styles };
