import React, { useRef } from 'react';
import cn from 'classnames'
import moment from 'moment';
import PropTypes from 'prop-types';

import { ReactComponent as ProfileIcon } from '../../svgs/profileIcon.svg';
import { dependantPropType } from '../propTypes';
import styles from './DependantInfo.module.scss';
import { ReactComponent as Menu } from 'svgs/3dotsVertical.svg';
import { capitalise } from 'libs/misc';

const convertRelationshipToString = num => {
  switch(num) {
    case 2: return 'Friend';
    case 3: return 'Other';
    case 10: return 'Child';
    case 11: return 'Partner';
    case 12: return 'Dependant Adult';
    default: return '- Relationship not set -';
  }
}


const DependantInfo = ({ dependant, onClick, menuClick }) => {
  const ref = useRef(null);

  return(
    <div className={cn(styles.container)} ref={ref} style={{borderTop: `3px solid ${dependant.IdentityStatus.StatusLabelColor}`}}>
        <div onClick={onClick} style={{ width: "100%" }}>
          <div className={styles.banner} style={{backgroundColor: dependant.IdentityStatus.StatusLabelColor}}>{dependant.IdentityStatus.StatusLabel}</div>
          <div className={styles.profileContainer}>
            {
              dependant.AvatarUrl ? 
              <img src={dependant.AvatarUrl} className={styles.avatar} alt={dependant.FirstName}/> : <ProfileIcon className={cn(styles.avatar, styles.default)}/>
            }
            <div className={styles.detailsContainer}>
              <div className={styles.name}>{`${capitalise(dependant.FirstName)} ${
                capitalise(dependant.LastName)
              }`}</div>
              <div className={styles.detailText}>
                {moment(dependant.DateOfBirth).format('DD/MM/YYYY')}
              </div>
              <div className={styles.detailText}>
                    {convertRelationshipToString(dependant.Relationship)}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.menu} onClick={(e) => {
          e.stopPropagation();
          const { offsetLeft, offsetWidth} = ref.current;
          menuClick({
            top: ref.current.getBoundingClientRect().top,
            left: offsetLeft,
            width: offsetWidth,
          }, dependant);
        }}>
          <Menu />
        </div>
    </div>
)};

DependantInfo.propTypes = {
  dependant: dependantPropType.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DependantInfo;
