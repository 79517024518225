import axios, { AxiosResponseResult, callApi } from "apis/axios";

interface PrivacySettings {
  allowRecords: boolean;
  allowDoctorNotes: boolean;
  allowDependantRecords: boolean;
  allowHealthData: boolean;
  allowMedications: boolean;
  isDefault: boolean;
}

export interface IdentityService {
  updatePrivacySettings: (organisationId: string, settings: PrivacySettings) => Promise<AxiosResponseResult<void>>;
  employParentPinCode: () => Promise<AxiosResponseResult<void>>;
}

export const service: IdentityService = {
  updatePrivacySettings(organisationId, settings) {
    const data = new FormData();
    data.append('OrganisationId', organisationId);
    data.append('AllowAccessMyHealthRecords', settings.allowRecords.toString());
    data.append('AllowAccessMyDoctorNotes', settings.allowDoctorNotes.toString());
    data.append('AllowAccessMyDependantHealthRecords', settings.allowDependantRecords.toString());
    data.append('AllowShareMyHealthDataWithPractices', settings.allowHealthData.toString());
    data.append('AllowAccessAndStoreMedications', settings.allowMedications.toString());
    data.append('IsDefault', settings.isDefault.toString());
    return callApi(axios.post<void>(
      'consumer/privacy/privacy-settings',
      data
    ));
  },
  employParentPinCode: () => callApi(axios.post<void>(
    'consumer/security/security-settings/use-parent'
  ))
}