const actionTypes = {
  fetchPickUpOptions: 'SCRIPT_FETCH_PICK_UP_OPTIONS',
  fetchPickUpOptionsSuccess: 'SCRIPT_FETCH_PICK_UP_OPTIONS_SUCCESS',
  fetchPickUpOptionsFailure: 'SCRIPT_FETCH_PICK_UP_OPTIONS_FAILURE',
  fetchPickUpOptionsDependant: 'SCRIPT_FETCH_PICK_UP_OPTIONS_DEPENDANTS',
};

const actions = {
  fetchPickUpOptions: (dateOfBirth, orgId, token) => ({
    type: actionTypes.fetchPickUpOptions,
    payload: {
      dateOfBirth,
      orgId,
      token,
    },
  }),
  fetchPickUpOptionsSuccess: options => ({
    type: actionTypes.fetchPickUpOptionsSuccess,
    payload: {
      options,
    },
  }),
  fetchPickUpOptionsFailure: error => ({
    type: actionTypes.fetchPickUpOptionsFailure,
    payload: {
      error,
    },
  }),
  fetchPickUpOptionsDependant: (dateOfBirth, orgId, token, dependantsId) => ({
    type: actionTypes.fetchPickUpOptionsDependant,
    payload: {
      dateOfBirth,
      orgId,
      token,
      dependantsId
    },
  }),
};

export { actions, actionTypes };
