import { actionTypes } from '../actions/actions';

const INITIAL_STATE = {
  isFetching: false,
  success: false,
  patientId: '',
  sessionId: '',
  isInterested: false,
  error: null,
};

const expressionOfInterestReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.expressInterest: {
      return {
        isFetching: false,
        success: false,
        patientId: action.payload.patientId,
        isInterested: true,
        error: null,
      }
    }
    case actionTypes.sendExpressionOfInterest: {
      return {
        isFetching: true,
        success: false,
        error: null,
      };
    }
    case actionTypes.expressionOfInterestSuccess: {
      return {
        isFetching: false,
        success: true,
        error: null,
      };
    }
    case actionTypes.expressionOfInterestFailure: {
      return {
        isFetching: false,
        success: false,
        error: action.payload.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default expressionOfInterestReducer;