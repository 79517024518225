import 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

const useLoading = <F extends (...args: any) => Promise<any>>(
    func: F,
    dependencies?: readonly any[]
): [F, boolean, Error | undefined] => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<undefined | Error>(undefined);
    const isMounted = useRef(true);
    // update whether the working component mounted or not 
    useEffect(() => () => {
        isMounted.current = false;
    }, []);

    const wrappedFunc = useCallback(async(...args: any) => {
        let ret;
        try{
            ret = await func(...args);
        } catch(e) {
            if(e instanceof Error){
                setError(e);
            }
        } finally{
            if(isMounted.current){ // complete loading only if working component is being mounted
                setLoading(false);
            }
        }
        return ret;
    }, dependencies || [func]);
    return [wrappedFunc as F, loading, error]
};

export default useLoading;