import { call, takeEvery, put } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';
import { GenericParserHttp } from '../../libs/Http';
import { go } from 'routes/sub/appointments';
import { checkAuth } from 'libs/misc';

const api = (id, token) =>
  GenericParserHttp.get(`consumer/appointment/${id}`, { token }).then(
    ({ body }) => body,
  );

function* getApptDetails({ payload: { id, token } }) {
  try {
    const details = yield call(api, id, token);
    yield put(actions.fetchApptDetailsSuccess(details));
  } catch (err) {
    checkAuth(err);
    yield put(actions.fetchApptDetailsFailure(err));
  }
}

function* getApptDetailsSaga() {
  yield takeEvery(actionTypes.fetchApptDetails, getApptDetails);
}

export { getApptDetailsSaga };
