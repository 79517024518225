import React from 'react';

import styles from './success.module.css';
import { ReactComponent as Tick } from '../../../../svgs/bigTick.svg';
import Transition from '../../../../components/Transition/index';

const Success = ({redirect, incompleteId}) => (
    <Transition
    className={styles.container}
    component="div"
    transitionAppearTimeout={1500}
    transitionEnterTimeout={1800}
    transitionLeaveTimeout={1500}
    transitionAppear
    transitionName={{
      enter: styles.enter,
      enterActive: styles.enterActive,
      appear: styles.enter,
      appearActive: styles.enterActive,
      leave: styles.leave,
      leaveActive: styles.leaveActive,
    }}
  >
    <div className={styles.tickContainer}>
      <Tick className={styles.tickIcon} />
    </div>
    {incompleteId ? (
      <div className={styles.content}>
        Identity confirmation completed
        <br />
        <br />
        Redirecting...
      </div>
    ) :
      <div className={styles.content}>
        Identity request submitted successfully
        <br />
        <br />
        Redirecting...
      </div>
    }

    {redirect()}
  </Transition>
)

export default Success;