import React from 'react';
import PropTypes from 'prop-types';

import styles from './emptyFeedCard.module.css';
import { ReactComponent as WavingKiwi } from './wavingKiwi.svg'
import callPic from 'svgs/call.svg';

const EmptyFeedCard = ({link, linkText, linkOnClick, buttonClick, text, buttonText, title, button, call, callNumber, Icon }) => (
  <div className={styles.container}>
    {!Icon && 
      <WavingKiwi className={styles.kiwi} />
    }
    {Icon &&
      <Icon style={{margin: "1rem auto"}}/>
    }
    <div className={styles.welcome}>
    {title} {/*isFirstTime ? `Hey ${name}, welcome to Vensa!` : `Kia ora ${name}!`*/}
    </div>
    <div className={styles.text}>{text}</div>
    <div style={{display: "flex", flexDirection: "row", marginTop: "1rem", width: "100%"}}>
      {link &&
        <button className={styles.bookButton} onClick={linkOnClick} style={{margin: "auto"}}>
          {linkText}
        </button>
      }
      {button &&
        <div className={styles.buttonDiv} style={{margin: "auto", marginRight: link ? "auto" : "1rem"}}>
          <div className={styles.button} onClick={buttonClick}>{buttonText}</div>
        </div>
      }
    </div>
    {call &&
      <img style={{margin: "1rem 0rem"}} src={callPic} onClick={() => window.location.href="tel:" + callNumber} />
    }
  </div>
);

EmptyFeedCard.propTypes = {
  isFirstTime: PropTypes.bool.isRequired,
  buttonClick: PropTypes.func.isRequired,
};

export default EmptyFeedCard;
