import { actionTypes } from '../actions/actions';

const INITIAL_STATE = {
    isFetching: false,
    submitSuccess: false,
    verifySuccess: false,
    error: null,
}

const pinCodeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.submitPin: {
            return {
                isFetching: true,
                submitSuccess: false,
                verifySuccess: false,
                error: null,
            }
        }
        case actionTypes.submitPinSuccess: {
            return {
                isFetching: false,
                submitSuccess: true,
                verifySuccess: false,
                error: null
            }
        }
        case actionTypes.submitPinFailure: {
            return {
                isFetching: false,
                submitSuccess: false,
                verifySucces: false,
                error: action.payload.error
            }
        }
        case actionTypes.submitVerifyPin: {
            return {
                isFetching: true,
                submitSuccess: false,
                verifySuccess: false,
                error: null,
            }
        }
        case actionTypes.submitVerifyPinSuccess: {
            return {
                isFetching: false,
                submitSuccess: false,
                verifySuccess: true,
                error: null
            }
        }
        case actionTypes.submitVerifyPinFailure: {
            return {
                isFetching: false,
                submitSuccess: false,
                verifySuccess: false,
                error: null,
            }
        }
        default: {
            return state;
        }
    }
};

export default pinCodeReducer;