import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from './actions/actions';
import { actions as headerActions } from '../../components/UIContainer/actions/headerActions';
import { actions as scriptActions } from '../actions/actions';
import { actions as pharmacyActions } from './AddPharmacy/actions/actions';
import { ReactComponent as Plus } from '../../svgs/plus.svg';
import CtaButton from '../../components/CtaButton';
import UIContainer from '../../components/UIContainer';
import PickUpOption from './PickUpOption';
import styles from './pickUpLocation.module.css';
import { go } from '../../routes';
import { pricingPropType } from '../propTypes';
import { practiceProfilePropType } from '../../PracticeProfilePage/propTypes';
import { LocationModal } from '../ModalContent/LocationModal';
import { CostModal } from '../ModalContent/ReasonForCostModal';
import LearnMorePopUp from 'components/LearnMorePopUp/LearnMorePopUp';

class PickUpLocation extends Component {
  static propTypes = {
    setInfo: PropTypes.func.isRequired,
    fetchPickUpOptions: PropTypes.func.isRequired,
    fetchPickUpOptionsDependants: PropTypes.func.isRequired,
    goToConfirm: PropTypes.func.isRequired,
    goToAddMeds: PropTypes.func.isRequired,
    overrideBackArrow: PropTypes.func.isRequired,
    clearBackArrow: PropTypes.func.isRequired,
    pickUpOptions: PropTypes.arrayOf(pricingPropType),
    token: PropTypes.string.isRequired,
    dateOfBirth: PropTypes.string.isRequired,
    isFetching: PropTypes.bool,
    practice: practiceProfilePropType.isRequired,
    addPharmacy: PropTypes.func.isRequired,
    success: PropTypes.bool,
    newPharmacyId: PropTypes.string,
    isFetchingPharmId: PropTypes.bool,
  };
  state = {
    //selects the right option when the user is coming from confirmRequest
    selectedOption: this.props.location.state.details ? this.props.location.state.details.pricing : {},
    selectedUrgency: 0,
    showAddPharmOption: false,
    name: '',
    address: '',
    fax: '',
    phone: '',
    pharmacyNameError: null,
    addressError: null,
    faxError: null,
    phoneError: null,
    ShowModal: false,
    ShowCostModal: false,
  };

  componentDidMount() {
    if (this.props.userId === this.props.patientId) {
      this.props.fetchPickUpOptions(
        this.props.dateOfBirth,
        this.props.practice.Id,
        this.props.token,
      );
    } else {
      this.props.fetchPickUpOptionsDependants(
        this.props.dateOfBirth,
        this.props.practice.Id,
        this.props.token,
        this.props.patientId
      )
    }
  }

  componentWillUnmount() {
    this.props.clearBackArrow();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.success !== nextProps.success) {
      if (this.props.userId === this.props.patientId) {
        this.props.fetchPickUpOptions(
          this.props.dateOfBirth,
          this.props.practice.Id,
          this.props.token,
        );
      } else {
        this.props.fetchPickUpOptionsDependants(
          this.props.dateOfBirth,
          this.props.practice.Id,
          this.props.token,
          this.props.patientId
        )
      }
    }
  }

  handleUrgencyClick = index => () => {
    this.setState({ selectedUrgency: index });
  };

  handleCtaClick = () => {
    if (this.state.selectedOption?.IsPlaceHolder) {
      this.setState({
        ShowModal: true,
      });
    } else {
      const {
        selectedOption,
        selectedUrgency,
        id,
      } = this.state;
      let option;

      // Empty selectedOption so need to get Option
      if (!selectedOption.Options) {
        this.props.pickUpOptions.forEach(element => {
          if (id === element.PharmacyId) {
            option = element.Options[selectedUrgency];
          }
        });
      } else {
        option = selectedOption.Options[selectedUrgency];
      }

      if (typeof option === "undefined") {
        option = selectedOption.Option
      }
      this.props.setInfo('pricing', {
        Name: selectedOption.Name,
        Address: selectedOption.Address,
        PharmacyId: selectedOption.Id || selectedOption.PharmacyId,
        Option: option,
      });
      this.props.goToConfirm();
    }
  };

  handleOrganisationClick = org => () => {
    this.setState({
      selectedOption: org,
      id: org.PharmacyId,
    });
  };

  HideModal = () => {
    this.setState({
      ShowModal: false,
    });
  };

  ShowModal = () => {
    this.setState({
      ShowModal: true,
    });
  }

  HideCostModal = () => {
    this.setState({
      ShowCostModal: false,
    });
  };

  ShowCostModal = () => {
    this.setState({
      ShowCostModal: true,
    });
  }

  confirmAddPharmacy = (selectedPharmacy) => {
    this.setState({
      name: selectedPharmacy.Name,
      address: selectedPharmacy.Address,
      phone: selectedPharmacy.Phone,
      fax: selectedPharmacy.Fax,
      selectedPharmacy: selectedPharmacy.Id,
      id: selectedPharmacy.Id,
      selectedOption: selectedPharmacy,
    }, () => {
      // Need to wait for this to return OK.
      this.props.addPharmacy(
        {
          Name: this.state.name,
          Address: this.state.address,
          Fax: this.state.fax,
          Phone: this.state.phone,
          Id: this.state.id
        },
        this.props.token,
      );
    });
  };

  handleDeletePharmacy = org => {
    this.props.deletePharmacy(org, this.props.token);
    this.setState({ selectedOption: this.props.pickUpOptions.length === 1 && Object.keys(this.state.selectedOption).length == 0 ? this.props.pickUpOptions[0] : {} });
  };

  render() {
    const { isFetching, pickUpOptions, isFetchingPharmId, pickupMessage } = this.props;
    const { selectedOption, selectedUrgency, ShowModal, ShowCostModal } = this.state;

    if (pickUpOptions.length > 0 && Object.keys(this.state.selectedOption).length == 0) {

      var defaultOption = pickUpOptions.filter(o => {
        return o.IsDefault;
      });
      if (defaultOption.length > 0) {
        this.setState({ selectedOption: defaultOption[0] });
      } else {
        this.setState({ selectedOption: pickUpOptions[0] });
      }
    }
    return (
      <UIContainer
        heading={
          <div className={styles.heading}>
            2. Where would you like to collect your prescription?
          </div>
        }
        isFetching={isFetching || isFetchingPharmId || selectedOption === undefined}
        goBackFn={() => {
          if (this.props.patientId !== this.props.userId) {
            go.medicationSearch();
          } else {
            go.addMedication();
          }
        }}
        blueSpinner
        showCrossRight
      >
        <div className={styles.container}>
          <div className={styles.orgList}>
            {pickUpOptions.map((o, i) => {
              return <PickUpOption
                className={styles.pickUpOption}
                key={o.PharmacyId}
                option={o}
                selectedOrg={selectedOption.PharmacyId
                  === o.PharmacyId || this.state.id
                  === o.PharmacyId || pickUpOptions.length === 1}
                selectedUrgency={selectedUrgency}
                onOrgClick={this.handleOrganisationClick(o)}
                onUrgencyClick={this.handleUrgencyClick}
                isActive={o.IsActive}
                onAddPharmClick={this.ShowModal}
                pricingCategory={o.PricingCategory}
                deletePharmacy={this.handleDeletePharmacy}
                showMenu={i !== 0}
              />
            }
            )}
          </div>
          <div className={styles.reasonForCost} onClick={this.ShowCostModal}>
            Why is there a charge?<br />I thought Prescriptions are FREE from 1st July 2023
          </div>
          {pickupMessage &&
            <div className={styles.pickupMessage}>{this.props.pickupMessage}</div>
          }
          <div className={styles.buttons}>
            {pickUpOptions.length >= 0 &&
              !this.state.showAddPharmOption &&
              (<button
                className={styles.addPharmacy}
                onClick={this.ShowModal}
              >
                <Plus className={styles.plus} />
                <span>Add a Pharmacy</span>
              </button>
              )}
            <CtaButton
              active={this.state.selectedOption?.IsActive}
              className={styles.cta}
              onClick={this.handleCtaClick}
            />
          </div>
        </div>
        {ShowModal && <LocationModal
          handleClick={this.HideModal}
          confirmAddPharmacy={this.confirmAddPharmacy}
          defaultOrg={this.props.practice.Id}
        />
        }
        {ShowCostModal && <LearnMorePopUp
          hide={this.HideCostModal}
          type={"VSCRIPT_INFO"}
        />
        }
      </UIContainer>
    );
  }
}

const mapStateToProps = state => ({
  token: state.loginReducer.token,
  dateOfBirth: state.scriptDetailsReducer.patient.DateOfBirth,
  practice: state.scriptDetailsReducer.practice,
  pickUpOptions: state.pickUpOptionsReducer.options,
  isFetching: state.pickUpOptionsReducer.isFetching,
  newPharmacyId: state.addPharmacyReducer.Id,
  options: state.addPharmacyReducer.options,
  success: state.addPharmacyReducer.success,
  isFetchingPharmId: state.addPharmacyReducer.isFetching,
  prevLocation: state.scriptDetailsReducer.pricing,
  pickupMessage: state.pickUpOptionsReducer.message,
  newPharmId: state.addPharmacyReducer.Id,
  patientId: state.scriptDetailsReducer.patient.Id,
  userId: state.profileReducer.profile.Id,
});
const mapDispatchToProps = dispatch => ({
  overrideBackArrow: bindActionCreators(
    headerActions.overrideBackArrow,
    dispatch,
  ),
  clearBackArrow: bindActionCreators(headerActions.clearBackArrow, dispatch),
  setInfo: bindActionCreators(scriptActions.setInfo, dispatch),
  fetchPickUpOptions: bindActionCreators(actions.fetchPickUpOptions, dispatch),
  fetchPickUpOptionsDependants: bindActionCreators(actions.fetchPickUpOptionsDependant, dispatch),
  /* eslint-disable max-len */
  goToPrescriptions: () => go.home(),
  goToConfirm: () => go.confirmScript(),
  goToAddMeds: () => go.addMedication(),
  addPharmacy: bindActionCreators(pharmacyActions.addPharmacy, dispatch),
  deletePharmacy: bindActionCreators(pharmacyActions.deletePharmacy, dispatch),
  goToPayment: () => go.paymentScript(),
  /* eslint-enable max-len */
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PickUpLocation);
export { styles };
