import React from 'react';
import { connect } from 'react-redux';

import styles from './verifyAtPracticeCard.module.css';
import CardBanner from '../components/CardBanner/index';
import UIContainer from '../../components/UIContainer';
import blueUnderline from '../../svgs/blueUnderline.svg';
import stockImg from '../../images/atPractice.png';
import { go } from '../../routes/index';

class VerifyAtPracticeCard extends React.Component {    
    constructor(props) {
        super(props);
    }
    onClick = () => {
        go.choosePractice();
    }

    render() {
        return(
            <div>
            <div className={styles.container}>
                <CardBanner text={"Let’s get your identity verification underway. Currently you can only verify your identity at a Practice."}/>
                <div className={styles.title}>Verify at Practice</div> 
                <img src={blueUnderline} className={styles.image} alt="underline" />
                <img src={stockImg} className={styles.image} alt="stock" />  
                <div className={styles.text}>
                    Vensa currently provides easy access to healthcare appointments.
                    <br />
                    <br />
                    To access your private health information, you must first verify and secure your identity with your practice.
                    <br />
                    <br />
                    This vital step ensures that you have control of your health records.
                    <br />
                </div>
                <div className={styles.verifyButton} onClick={this.onClick}>Verify at Practice</div>
                {/* eslint-disable-next-line*/}
                <a className={styles.link} onClick={this.props.learnMoreClick}>Learn More</a>
            </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isMobileVerified: state.verifyReducer.isMobileVerified,
})
  
export default connect(mapStateToProps)(VerifyAtPracticeCard);

