import React from 'react';
import PropTypes from 'prop-types';

import { medicationPropType } from '../../propTypes/index';
import { ReactComponent as Cross } from '../../../svgs/close.svg';
import styles from './SelectedMedsMobile.module.css';

const SelectedMedsMobile = ({ medications, onRemove }) => (
  <div className={styles.container}>
    {medications.map((med, index) => (
      <div key={index} className={styles.item}>
        <div className={styles.name}>{med.Name}</div>
        <div className={styles.closeContainer} onClick={onRemove(med)}>
          <Cross className={styles.cross} />
        </div>
      </div>
    ))}
  </div>
);

SelectedMedsMobile.propTypes = {
  onRemove: PropTypes.func.isRequired,
  medications: PropTypes.arrayOf(medicationPropType),
};

export default SelectedMedsMobile;
