import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import UIContainer from '../../components/UIContainer';
import RouteBar from '../../components/DesktopComponents/RouteBar';
import { go } from '../../routes';
import TabBar from '../../components/DesktopComponents/TabBar';
import Button from '../../components/DesktopComponents/FullWidthButton';
import Toggle from '../../Privacy/components/PrivacyToggle';
import { useAccountStore, useSecurityStore } from '../../store/mobx/mobx';
import { useComponentDidMount } from '../../libs/newHttp';
import PopUpVerificationNavigator from '../../components/DesktopComponents/PopUpVerificationNavigator';
import DesktopPopUp from 'components/DesktopPopUp';
import ChangePassword from 'UserAccountPage/ChangePassword';
import LearnMorePopUp from 'components/LearnMorePopUp/LearnMorePopUp';
import { getFinalStatus } from 'Identity/components/IdentityLabel';
import PopUpNoId from 'components/DesktopComponents/PopupNoIdentity';

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #F9F9F9;
    overflow-y: auto;
    -ms-overflow-style: none; 
    scrollbar-width: none;  
    &::-webkit-scrollbar {
        display: none;
    }
`
const Container = styled.div`
    width: 533px;
    margin 1rem auto;
`
const Inner = styled.div`
    height: 800px;
    width: 462px;
    background-color: white;
    margin: auto;
    margin-left: 7px;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 1px 1px 3px lightgray;
    padding: 0.5rem 3rem;
`
const Heading = styled.div`
    font-size: 17px;
    color: #585858;
    font-weight: bold;
    width: 100%;
    margin: 1.5rem auto;
    text-align: center;
`
const Title = styled.div`
    font-size: 15px;
    color: #6C6C6C;
    margin: 1rem 0rem;
    text-align: left;
    font-weight: 600;
`
const Subtext = styled.div`
    font-size: 12px;
    text-align: left;
    margin-top: 8px;
`
const Link = styled.div`
    color: #3CB9C0;
    text-align: left;
    font-size: 12px;
    cursor: pointer;
    margin-top: 5px;
`
const SettingsContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const SettingsTitle = styled.div`
    font-size: 15px;
    color: #989898;
    padding: 0.6rem 0rem;
    text-align: left;
    font-weight: 600;
    color: #6C6C6C;
`
const FlexH = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    margin: 0.5rem 0rem;
`
const Contact = styled.div`
    font-size: 15px;
    color: #6C6C6C;
    text-align: left;
    width: 100%;
    cursor: pointer;
`
const Verified = styled.div`
    color: #23C373;
    font-size: 15px; 
    text-align: right;
    width: 100%;
    padding-right: 10px;
    cursor: pointer;
`
const Unverified = styled.div`
    color: #FC7B7B;
    font-size: 15px; 
    text-align: right;
    width: 100%;
    padding-right: 10px;
    cursor: pointer;
`
const DarkLine = styled.div`
    border-bottom: 1px solid #DADADA;
    position: absolute;
    width: 100%;
    left: 0px;
`
const LightLine = styled.div`
    border-bottom: 1px solid #F0F0F0;
    position: absolute;
    width: 100%;
    left: 0px;
`
const DesktopSecurity: React.FC = observer(() => {
    const storeAcc = useAccountStore();
    const secStore = useSecurityStore();
    const [isMobile, setIsMobile] = useState(false);
    const [isPrimary, setIsPrimary] = useState(false);
    const [popUp, setPopUp] = useState(false);
    const [noIdentityPopUp, setNoIdentityPopUp] = useState(false);

    const [show, setShow] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    useComponentDidMount(() => { 
        storeAcc.getProfile();
        secStore.getSecuritySettings();
    });

    useEffect(() => {
        if (getFinalStatus(storeAcc) !== 1) {
            setNoIdentityPopUp(true);
        } else { setNoIdentityPopUp(false) }
    })

    return (
        <UIContainer isFetching={secStore.loading}>
            <Outer style={{overflow: noIdentityPopUp ? "visible" : "auto"}}>
                <RouteBar 
                    lowLevel={"Security"}
                    lowLink={() => go.security()}
                />
                <TabBar type={0} id={3} />
                {popUp &&
                    <LearnMorePopUp type={"SECURITY_FAQ"} hide={() => setPopUp(false)}/>    
                }
                {noIdentityPopUp &&
                    <PopUpNoId 
                        mobile={false}
                    />
                }
                {show && 
                    <PopUpVerificationNavigator 
                        combo={() => {}} 
                        close={() => {
                            setShow(false);
                            storeAcc.getProfile();
                        }} 
                        isCombo={false} 
                        isMobile={isMobile} 
                        isPrimary={isPrimary} 
                    />}
                {showPassword &&
                    <DesktopPopUp toggle={() => setShowPassword(!showPassword)}>
                        <div style={{width: "100%", textAlign: "center", margin: "10px 0px 30px"}}>Change Password</div>
                        <ChangePassword dontGoBack={true} close={() => setShowPassword(false)} />
                        <Link
                            onClick={() => setShowPassword(false)}
                            style={{position: "absolute", zIndex: 2, fontSize: "13px", left: "40px", bottom: "50px", padding: "0"}}
                        >
                            Keep my current password
                        </Link>
                    </DesktopPopUp>
                }
                <Container>
                    <Inner>
                        <Title>Security Settings</Title>
                        <div>
                            <DarkLine />
                        </div>
                        <Toggle 
                            initial={secStore.securitySettings.SignIn2StepsVerification} 
                            disabled={false}
                            toggle={() => 
                                secStore.editSecuritySettings({
                                    SignIn2StepsVerification: !secStore.securitySettings.SignIn2StepsVerification,
                                    NotifyUserAfter5FailedAttempts: secStore.securitySettings.NotifyUserAfter5FailedAttempts,
                                    SuspendAccountAfter10FailedAttempts: secStore.securitySettings.SuspendAccountAfter10FailedAttempts,
                                })
                            } 
                            text={storeAcc.profile.SecurityPinCodeSetup ? "2 step sign in verification" : "Setup PIN  code"} 
                        />
                        <div>
                            <LightLine />
                        </div>
                        <Button 
                            noBorder={true} 
                            onClick={() => go.editPin()} 
                            text={"Change PIN Code"} 
                            disabled={!secStore.securitySettings.SignIn2StepsVerification}
                        />
                        <div>
                            <LightLine />
                        </div>
                        <Subtext>
                            You have sign-in 2-step verification on, you will be asked for your PIN code when you sign into your account.
                            This step is great for protecting your information.
                        </Subtext>
                        <Link onClick={() => setPopUp(true)}>Learn more why this is important</Link>
                        <br />
                        <br />
                        {/*}
                        <Toggle 
                            top={true} 
                            initial={secStore.securitySettings.NotifyUserAfter5FailedAttempts} 
                            toggle={() => 
                                secStore.editSecuritySettings({
                                    SignIn2StepsVerification: secStore.securitySettings.SignIn2StepsVerification,
                                    NotifyUserAfter5FailedAttempts: !secStore.securitySettings.NotifyUserAfter5FailedAttempts,
                                    SuspendAccountAfter10FailedAttempts: secStore.securitySettings.SuspendAccountAfter10FailedAttempts,
                                })
                            } 
                            text={"Failed Attempts Notification"} 
                        />
                        <Subtext>Notify me after 5 failed PIN code attempts on my account.</Subtext>
                        <br />
                        <br />
                        <Toggle 
                            top={true} 
                            initial={secStore.securitySettings.SuspendAccountAfter10FailedAttempts} 
                            toggle={() => 
                                secStore.editSecuritySettings({
                                    SignIn2StepsVerification: secStore.securitySettings.SignIn2StepsVerification,
                                    NotifyUserAfter5FailedAttempts: secStore.securitySettings.NotifyUserAfter5FailedAttempts,
                                    SuspendAccountAfter10FailedAttempts: !secStore.securitySettings.SuspendAccountAfter10FailedAttempts,
                                })
                            }
                            text={"Suspend Account"} 
                        />
                        <Subtext>
                            Suspend my account after 10 failed PIN code attempts. You will receive an email with an incident report
                            and instructions on how to re-activate your account.
                        </Subtext>
                        */}
                        <br />
                        <SettingsContainer style={{margin: "1rem 0rem"}}>
                                <SettingsTitle>Trusted Email & Mobile</SettingsTitle>
                                <div>
                                    <DarkLine />
                                </div>
                                {storeAcc.profile.RecoveryEmail && <Contact style={{fontSize: "13px", lineHeight: "15px", marginTop: "1rem"}}>Account Email:</Contact>}
                                <FlexH 
                                    style={{marginTop: storeAcc.profile.RecoveryEmail ? "0rem" : "1rem", marginBottom: "1rem"}}
                                    onClick={() => {
                                        setIsMobile(false);
                                        setIsPrimary(true);
                                        setShow(true);
                                    }}
                                >
                                    <Contact>
                                        {storeAcc.profile.Email}
                                    </Contact>
                                    {storeAcc.profile.EmailVerified ?
                                        <Verified>Verified &gt;</Verified> 
                                        :
                                        <Unverified>Unverified &gt;</Unverified>
                                    }
                                </FlexH>
                                {storeAcc.profile.RecoveryEmail && <Contact style={{fontSize: "13px", lineHeight: "15px"}}>Recovery Email:</Contact>}
                                { storeAcc.profile.RecoveryEmail &&
                                    <FlexH 
                                        style={{marginTop: "0px", marginBottom: "0.5rem"}}
                                        onClick={() => {
                                            setIsMobile(false);
                                            setIsPrimary(false);
                                            setShow(true);
                                        }}
                                    >
                                        <Contact>
                                            {storeAcc.profile.RecoveryEmail}
                                        </Contact>
                                        <Verified>Verified &gt;</Verified>
                                    </FlexH>
                                }
                                <FlexH 
                                    style={{paddingBottom: "1rem"}} 
                                    onClick={() => {
                                        setIsMobile(true);
                                        setIsPrimary(true);
                                        setShow(true);
                                    }}
                                >
                                    <Contact>
                                        {storeAcc.profile.Mobile}
                                    </Contact>
                                    {storeAcc.profile.MobileVerified ?
                                        <Verified>Verified &gt;</Verified> 
                                        :
                                        <Unverified>Unverified &gt;</Unverified>
                                    }
                                </FlexH>
                            {!storeAcc.profile.RecoveryEmail && <Contact onClick={() => setShow(true)} style={{cursor: "pointer", color: "#07A3C8"}}>Add Additional Recovery Email</Contact>}
                            {!storeAcc.profile.RecoveryEmail && <Contact onClick={() => setShow(true)} style={{fontSize: "13px", color: "#07A3C8", lineHeight: "15px", cursor: "pointer"}}>(Recommended)</Contact>}
                                {!storeAcc.profile.RecoveryEmail && <br /> }
                            <div>
                                <LightLine />
                            </div>
                            {!storeAcc.profile.RecoveryEmail &&
                                <Subtext>
                                    Trusted phone number and email is used to verify your identitiy when signing in (along with your PIN code) and to help recover your account if you have forgotten your info.
                                </Subtext>
                            }
                        </SettingsContainer>
                        <br />
                        <br />
                        <Title>Password</Title>
                        <div>
                            <DarkLine />
                        </div>
                        <Button noBorder={true} onClick={() => setShowPassword(true)} text={"Change Password"} />
                        <div>
                            <LightLine />
                        </div>
                    </Inner>
                </Container>
            </Outer>
        </UIContainer>
    )
})

export default DesktopSecurity;