import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import UIContainer from '../../components/UIContainer/index';
import mobilePhone from '../../images/mobilePhone.png';
import ResendOptions from './components/resendOptions/index';
import InputCodeForm from './components/inputCodeForm/index';
import smartPhoneIcon from '../../images/smartPhone.png';
import { isDesktop } from '../../config';
import { go } from '../../routes/index';
import styles from './mobileVerification.module.css';

class MobileVerification extends Component {
  static propTypes = {
    sendCode: PropTypes.func.isRequired,
    clearError: PropTypes.func.isRequired,
    shouldPush: PropTypes.bool.isRequired,
    sendCodeIsFetching: PropTypes.bool.isRequired,
    mobile: PropTypes.string.isRequired,
    sessionId: PropTypes.string,
    isError: PropTypes.bool,
  };
  state = {
    firstResendMade: false,
  };
  componentDidUpdate() {
    if (this.props.shouldPush) {
      go.processAppointment(this.props.urlName);
    }
  }
  setResendMade = () => this.setState({ firstResendMade: true });
  setSmsError = () => go.smsFailure(this.props.urlName);
  render() {
    const content = (
      <div className={styles.page}>
        {!isDesktop() ? (
          <img
            src={smartPhoneIcon}
            className={styles.icon}
            key="icon"
            alt="SMS code"
          />
        ) : (
          <div className={styles.iconContainer}>
            <img src={mobilePhone} alt="mobile" className={styles.icon} />
          </div>
        )}
        <div className={styles.container}>
          <div className={styles.message}>
            {`Enter the 4-digit code sent to you at ${this.props.mobile}`}
          </div>
          <InputCodeForm
            isFetching={this.props.sendCodeIsFetching}
            sendCode={this.props.sendCode}
            sessionId={this.props.sessionId}
            isError={this.props.isError}
            clearError={this.props.clearError}
            mobile={this.props.mobile}
          />
          <div className={styles.resendOptions}>
            <ResendOptions setResendMade={this.setResendMade} />
            {this.state.firstResendMade ? (
              <div onClick={this.setSmsError} className={styles.notReceiving}>
                Not receiving the text?
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
    if (!this.props.mobile)
      return <Redirect to={`${this.props.urlName}/txt`} />;
    else if (isDesktop()) return content;
    else return <UIContainer heading=" ">{content}</UIContainer>;
  }
}

export default MobileVerification;
