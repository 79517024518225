import React from 'react';
import styles from './index.module.scss';
import ReasonSection from './ReasonSection';

const DATA = {
  '1': {
    title: 'Authorised access',
    content: 'Your practice must ensure you have the legal right to access healthcare and information for your dependant(s). \
    Many patients cannot give consent for someone else to access healthcare for them (e.g. babies/children, older patients with memory difficulties, etc). \
    By providing these documents you prove to your practice that you have the legal right to access your dependant\'s healthcare data.'
  },
  '2': {
    title: 'Privacy',
    content: 'Your doctor\'s practice is legally required to prove that they only allow access to healthcare data to approved and authorised people. \
    Your supporting documents prove to the practice, and the law, that you have the legal authority to access healthcare information for your dependant.'
  },
  '3': {
    title: 'Tricky scenarios',
    content: 'Some patients cannot safely look after themselves, and have someone who has been legally appointed to take control of their healthcare needs \
    (patients with Alzheimer\'s, etc). It can be a challenge to make sure the correct people are in charge of a dependant\'s healthcare. \
    By uploading proof that you are legally empowered to take control of a patient\'s health data (Enduring Power of Attorney, etc) \
    you can ensure the best continuity of care for your dependant.'
  }
}

type ReasonKey = keyof typeof DATA;

const ReasonPopup = () => {
  return (
    <>
    <span className={styles.title}>Why do we need a formal declaration?</span>
    {
      Object.keys(DATA).map((k, index, keys) => {
        const reason = DATA[k as ReasonKey];
        return (
          <ReasonSection 
            key={index} 
            sn={k} 
            title={reason.title} 
            content={reason.content}  />)
      })
    }
    </>
  )
}

export default ReasonPopup;