import React, { memo } from 'react';
import CircleAvatar from 'components/shared/common/CircleAvatar';
import { ReactComponent as WarningTick } from 'svgs/identityCross.svg';
import { ReactComponent as WhiteTick } from 'svgs/whiteTick.svg';
import { ReactComponent as OrangeTick } from 'svgs/whiteWarningTick.svg';
import { ReactComponent as Icon } from 'svgs/profileIcon.svg';
import styles from './index.module.scss';
import StadiumTag from '../StadiumTag';
import { go } from 'routes/sub/userAccount';

const TICKS = [
  WarningTick,
  WhiteTick,
  OrangeTick
];


const status2Props = (status: number) => status === 0 ? ['#FF6A65', 'Identity Not Verified'] : 
                                         status === 1 ? ['#23C373', 'Identity Verified'] : 
                                         ['#F5722C', 'Partially Verified'];

type Props = {
    imgUrl?: string;
    name: string;
    phone?: string;
    status: number;
    nhi?: string;
}

const UserProfile = (
    {
        imgUrl,
        name,
        phone,
        nhi,
        status,
        ...rest
    }: Props) => {
      const Tick = TICKS[status];
      const[color, text] = status2Props(status);
      const handleClick = () => {go.userAccount()};
  return (
    <div className={styles.container} onClick={handleClick} {...rest}>
        <CircleAvatar 
          diameter='5rem' 
          imgUrl={imgUrl} 
          border="2px solid white"
          placeHolder={Icon} />
          <span className={styles.name}>{name}</span>
          <div className={styles.details}>
            <span className={styles.phone}>{phone}</span>
            {nhi && <span className={styles.nhi}>NHI: {nhi}</span>}
          </div>
        <Tick className={styles.tick} />
        <StadiumTag color={color} text={text} />
    </div>
  )
};

export default UserProfile;