import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import UIContainer from 'components/UIContainer';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as actions }  from 'components/UIContainer/actions/headerActions';
import { useComponentDidMount } from 'libs/newHttp';
import RouteBar from 'components/DesktopComponents/RouteBar';
import TabBar from 'components/DesktopComponents/TabBar';
import { actions as whiteNotificationActions } from 'components/UIContainer/MobileUI/MainShell/actions/actions';
import { go } from 'routes';
import Section from './components/Section/Section';
import { whiteBoxNotificationSettings } from 'components/UIContainer/MobileUI/MainShell/selectors';
import { useAccountStore, useMyHealthStore } from 'store/mobx/mobx';
import { Transition } from 'react-pose';
import { WhiteNotificationBox } from 'components/UIContainer/MobileUI/WhiteNotificationBox/WhiteNotificationBox';
import moment from 'moment';
import Notifications from 'HealthFeed/HealthFeedDesktop/Notifications/Notifications';
import { getFinalStatus } from 'Identity/components/IdentityLabel';

const Outer = styled.div`
    width: 100%;
    background-color: #F9F9F9;
    overflow-y: auto;
    align-items: center;
    -ms-overflow-style: none; 
    scrollbar-width: none;  
    &::-webkit-scrollbar {
        display: none;
    }
    overflow: auto;
    height: 100%;
`
const Container = styled.div`
    padding: 0rem 0rem;
    display: flex;
    flex-wrap: wrap;
`
const Column = styled.div`
    margin: auto;
    margin-top: 0;
`

interface IProps {
    setHeader: (item: string) => void;
    feed: any;
}

const HealthSummary: React.FC<IProps> = observer(({setHeader, feed}) => {
    
    const healthStore = useMyHealthStore();

    const store = useAccountStore();
    const dispatch = useDispatch();
    const { isOpen, index } = useSelector(whiteBoxNotificationSettings);

    const [showNotes, setShowNotes] = useState(true);

    useComponentDidMount(() => {
        setHeader("PHR");
        healthStore.getSummary(() => go.login());
        healthStore.getClinicNotes();
    });

    useEffect(() => {
        if (healthStore.clinicalErrorText === null) { setShowNotes(true); }
        if (healthStore.clinicalErrorText !== "" && healthStore.clinicalErrorText !== null) {
            setShowNotes(false);
        }
    })
/*
    useEffect(() => {
        return () => {
          dispatch(
            whiteNotificationActions.setWhiteBoxNotification({ isOpen: false }),
          );
          setHasWhiteBoxOpened(false);
        };
      }, []);

    useEffect(() => {    
        // TODO: This hasWhiteBoxOpened property is a temporary solution
        // for only showing the whiteBox one time, it should use a value from server instead
        // when that property is ready, remove this hasWhiteBoxOpened and setHasWhiteBoxOpened from the codebase
        console.log("isOpen: " + isOpen);
        console.log("index: " + index);
        if (!hasWhiteBoxOpened && profile.IdentityVerified && profile.PrivacyPolicySetup && profile.MobileVerified && profile.EmailVerified) {
            console.log("dispatch")
            dispatch(
                whiteNotificationActions.setWhiteBoxNotification({
                isOpen: true,
                index: 0,
                }),
            );
            setHasWhiteBoxOpened(true);
        }
    
      }, [
        hasWhiteBoxOpened,
        profile.IdentityVerified,
        dispatch,
        setHasWhiteBoxOpened,
      ]);
*/

    const whenHeight = healthStore.summaryTab.WhenHeight === null ? " cm" : " cm (as of " + moment(healthStore.summaryTab.WhenHeight).format("DD MMM YYYY") + ")";
    const whenWeight = healthStore.summaryTab.WhenWeight === null ? " kgs" : " kgs (as of " + moment(healthStore.summaryTab.WhenWeight).format("DD MMM YYYY") + ")";
    const whenBP = healthStore.summaryTab.BloodPressure.WhenMeasured === null ? " mm Hg" : " mm Hg (as of " + moment(healthStore.summaryTab.WhenWeight).format("DD MMM YYYY") + ")";

    const WeightHeight = [
        {
            Name: "Weight: " + healthStore.summaryTab.WeightInKg + whenWeight
        }, 
        {
            Name: "Height: " + healthStore.summaryTab.HeightInCm + whenHeight
        },
        {
            Name: "BMI: " + healthStore.summaryTab.BMI + " kg/m2"   
        },
        {
            Name: "Last BP " + healthStore.summaryTab.BloodPressure.Systolic + " / " + healthStore.summaryTab.BloodPressure.Diastolic + whenBP
        } 
    ];

    return(
        <UIContainer isFetching={healthStore.summaryTabLoadingStatus === 'LOADING'} navKey='My Health Record'>
            <Outer>
                <RouteBar 
                    lowLevel={"My Health Summary"}
                    lowLink={() => go.PHRSummary()}
                />
                <TabBar type={1} id={0} hideNotes={!showNotes} />
                    {getFinalStatus(store) !== 1 && !store.profile.PrivacyPolicySetup &&
                        <Notifications feed={feed} getFeed={() => {}} hide={() => {}} />          
                    }
                    <Container>
                        <Column style={{marginRight: "0"}}>
                            <Section 
                                lists={[healthStore.summaryTab.CurrentConditions, healthStore.summaryTab.PastConditions]} 
                                header={"My Conditions"}
                                titles={["Current Conditions", "Past Conditions"]} 
                            />
                            <Section 
                                lists={[healthStore.summaryTab.Allergies, healthStore.summaryTab.WeightInKg === 0 ? [] : WeightHeight]} 
                                titles={["Allergies & adverse reactions", "Weight/Height"]} 
                                header={"Other information"}
                            />
                        </Column>
                        <Column style={{marginLeft: "0"}}>
                            <Section 
                                lists={[healthStore.summaryTab.CurrentMedications, healthStore.summaryTab.PastMedications]} 
                                header={"My Medications"} 
                                titles={["Current Medications", "Past Medications"]}
                            />
                            <Section 
                                lists={[healthStore.summaryTab.Procedures]}
                                titles={["Procedure Name"]} 
                                header={"Procedure/operations"}
                            />
                        </Column>
                    </Container>
                
            </Outer>
        </UIContainer>
    )
})

const mapStateToProps = (state: any) => ({
    feed: state.feedReducer.feed,
})
  
const mapDispatchToProps = (dispatch: any) => ({
    setHeader: bindActionCreators<any, any>(actions.setSideHeaderItem, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(HealthSummary);