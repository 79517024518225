import { call, put, take, fork, cancel } from 'redux-saga/effects';
import { filter } from 'fuzzaldrin';
import { actions, actionTypes } from '../actions/searchActions';

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
const api = (items, searchTerm) =>
  new Promise(res =>
    res(filter(items, searchTerm, { key: 'Name', maxResults: 15 })),
  );

function* getMatching({ payload: { query, items } }) {
  try {
    if (!query) {
      yield put(actions.searchSuccess([]));
    } else {
      yield call(delay, 500);
      const matched = yield call(api, items, query);
      yield put(actions.searchSuccess(matched));
    }
  } catch (err) {
    // this should never happen
  }
}

function* getMatchingSaga() {
  let task;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const action = yield take(actionTypes.search);
    if (task) {
      yield cancel(task);
    }
    task = yield fork(getMatching, action);
  }
}

export { getMatchingSaga };
