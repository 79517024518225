import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { go } from '../../routes';
import { isDesktop } from '../../config';
import { actions } from './actions/actions';
import { actions as paymentActions } from '../Payment/actions/actions';
import { actions as scriptActions } from '../actions/actions';
import { ReactComponent as ProfileIcon } from '../../svgs/profileIcon.svg';
import { ReactComponent as Arrow } from '../../svgs/rightarrow2.svg';
import { ReactComponent as Card } from '../../svgs/creditcardDesktop.svg';
import { ReactComponent as Cash } from '../../svgs/cash.svg';
import { ReactComponent as Spinner } from '../../svgs/spinner.svg';
import DesktopHeader from './components/DesktopHeader';
import PickUpDetails from './components/PickUpDetails';
import Medications from './components/Medications';
import AfterHoursNote from './components/AfterHoursNote';
import { scriptDetailsPropType, cardDetailsPropType } from '../propTypes';
import styles from './confirmRequest.module.css';
import { FineTextConfirmation } from './components/ConfirmFineText/fineTextConfirmation';
import { motion } from "framer-motion"
import { ReactComponent as HeartIcon } from '../../svgs/heart.svg';
import { ReactComponent as InfoIcon } from '../../svgs/bluei.svg';
import { ReactComponent as Cross } from '../../svgs/crossGrey.svg';
import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';

const Content = styled.div`
  overflow-y: auto;
  padding-right: 15px;

  &::-webkit-scrollbar {
    width: 10px;
  }
  
  &::-webkit-scrollbar-track {
    background: #F0F0F0;
    border-radius: 25px;
    margin-left: 2rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #AFAFAF;
    border-radius: 25px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #999; 
  }
`

const Container = styled.div`
    display: flex;
    font-size: var(--font-11);
    font-weight: var(--light-weight);
    color: var(--color-grey-4);
`

export const RadioBox = styled.div`
  display: flex;
  flex: 1;
`;

export const circleCheckCSS = css`
  min-width: 15px;
  max-width: 15px;
  height: 15px;
  margin: 5px 15px 0 0;
  padding: 8px;
`;

const baseCSS = css`
  border-radius: 50%;
  position: relative;
  border: 1px solid var(--color-grey-2);
  min-width: 28px;
  max-width: 28px;
  height: 28px;
  cursor: pointer;
`;

const selectedCSS = css`
  &:after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    background: var(--color-pelorous);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
`;

const CircleIcon = styled.div`
  ${baseCSS};
  ${ifProp('isSelected', selectedCSS, '')};
  ${prop('addCSS', '')};
`;

const CircleCheckbox = ({
  addCSS,
  selected,
  onClick,
}) => (
  <CircleIcon addCSS={addCSS} isSelected={selected} onClick={onClick}>
    &nbsp;
  </CircleIcon>
);

const getButton = (
  paymentEnabled,
  hasCard,
  payOnline,
  confirmFn,
  selectFn,
  price,
) => {
  if (paymentEnabled) {
    if (hasCard || !payOnline) {
      return (
        <button className={styles.button} onClick={confirmFn}>
          {isDesktop() ? 'Confirm Request' : `Confirm Request  -  ${price}`}
        </button>
      );
    }
    return (
      <button className={styles.button} onClick={selectFn}>
        Select Payment
      </button>
    );
  }
  return (
    <button className={styles.button} onClick={confirmFn}>
      {isDesktop() ? 'Confirm Request' : `Confirm Request  -  ${price}`}
    </button>
  );
};

class ConfirmRequest extends Component {

  ModalType = {
    Subsidy: 'Subsidy',
    Info: 'Info',
    ServiceTerms: 'ServiceTerms'
  };

  static propTypes = {
    details: scriptDetailsPropType.isRequired,
    submit: PropTypes.func.isRequired,
    goHome: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    checkout: PropTypes.func.isRequired,
    fetchCardDetails: PropTypes.func.isRequired,
    setInfo: PropTypes.func.isRequired,
    goToPaymentSelection: PropTypes.func.isRequired,
    goToPaymentSelectionDesktop: PropTypes.func.isRequired,
    cardDetails: PropTypes.arrayOf(cardDetailsPropType),
    payOnline: PropTypes.bool.isRequired,
    payLater: PropTypes.bool,
    paymentEnabled: PropTypes.bool.isRequired,
    willRetry: PropTypes.bool,
    scriptId: PropTypes.string,
    retryRequest: PropTypes.func.isRequired,
    goToCheckout: PropTypes.func.isRequired,
    goToSubsidy: PropTypes.func.isRequired,
    goToInfo: PropTypes.func.isRequired,
    goToServiceTerms: PropTypes.func.isRequired
  };
  state = {
    showModal: false,
    modalType: this.ModalType.Subsidy,
    payOnline: false,
    payLater: false
  }

  componentDidMount() {
    if (!this.props.paymentEnabled) {
      this.props.setInfo('payOnline', false);
    } else {
      this.props.fetchCardDetails(this.props.token);
    }
  }
  setPayByCard = () => {
    this.props.setInfo('payOnline', true);
    this.props.setInfo('payLater', false);
  }
  setPayLater = () => {
    this.props.setInfo('payOnline', false);
    this.props.setInfo('payLater', true);
  }
  confirmRequest = () => {
    const details = this.props.details;
    const acBalance = this.props.acBalance;
    const ctpp = [];
    const drugCode = [];
    const FreeForm = [];
    const externalId = [];
    details.medications.forEach(med => {
      if (med.DrugCode) drugCode.push(med.DrugCode);
      if (med.CTPP) ctpp.push(med.CTPP);
      if (med.ExternalId) externalId.push(med.ExternalId);
      if (!med.CTPP && !med.ExternalId) FreeForm.push(med.Name); //Free-form only added if med doesn't have any code
    });
    const card = this.props.cardDetails[0];
    const paymentMethod = card?.DoNotSaveCard ? card?.Token : ''
    const payingWithBalance = (Number(details.pricing.Option.Price) > 0) && (Number(acBalance) >= Number(details.pricing.Option.Price))
    const data = {
      OrganisationId: details.practice.Id,
      PmsUserId: details.doctor.Id,
      DependantId:
        this.props.userId !== details.patient.Id ? details.patient.Id : '',
      CTPP: ctpp,
      DrugCode: drugCode,
      FreeFormMedication: FreeForm,
      ExternalId: externalId,
      PricingOption: details.pricing.Option.Option,
      PayOnline: details.payOnline && !payingWithBalance,
      PayLater: details.pricing.Option.AllowsPayLater && details.payLater,
      Reason: details.reason,
      PaymentMethodId: paymentMethod,
      PayWithBalance: payingWithBalance
    };
    
    if (details.pricing.PharmacyId) {
      data.PharmacyId = details.pricing.PharmacyId;
    }
    if (this.props.willRetry) {
      const retryData = {
        PayOnline: details.payOnline,
        Id: this.props.scriptId,
        PaymentMethodId: paymentMethod
      };
      if (!this.props.scriptId) {
        this.props.submit(data, this.props.token)
      } else this.props.retryRequest(retryData, this.props.token);
      
    } else this.props.submit(data, this.props.token);
    if (isDesktop()) {
      this.props.goToCheckout();
    } else this.props.checkout();
  };
  convertPrice = number => {
    const string = `$${number}`;
    let dollars = string.substring(
      0,
      string.length - 2,
      )
    // Check if the functionality above returns just '$' per legacy code to append
    // a '0' as required.
    if(dollars === '$') dollars = dollars + '0';
    const withCence = `${dollars}.${string.substring(string.length - 2)}`;
    if (withCence[0] === '.') return withCence.substring(1)
    return withCence;
  };
  selectPaymentClick = () => {
    if (isDesktop()) {
      this.props.goToPaymentSelectionDesktop();
    } else this.props.goToPaymentSelection();
  };
  Modal = type => {
    let content;
    let heading;
    switch(type)
    {
      case this.ModalType.Subsidy:
        heading = "Why is the fee subsidised?";
        content = ["Your practice has subsidised this fee for you to make it easier and more affordable for you to order your repeat scripts online."];
        break;
      case this.ModalType.Info:
        heading = "Why is there a transaction fee?";//"Why is the transaction fee non-refundable?";
        content = ["The transaction fee is a one-time fee that is required to process this transaction and is generally 3.5% of the total value. It is refunded if the practice refunds your repeat script fee."];//["The transaction fee is a one-time fee that is required to process this transaction and is generally 3% of the total value. It is not refundable if the practice refunds your repeat script fee."];
        break;
      case this.ModalType.ServiceTerms:
        heading = "Practice Service Terms";
        content = 
          this.props.transactionTerms != null
          ? this.props.transactionTerms.split('\n')
          : [];
        break;
      default:
    }
    return (
      <motion.div 
        className={styles.modalOverlay}
        transition={{ ease: "easeOut", duration: 0.5 }} 
        animate={{ opacity: 1 }}
        initial={{ opacity: 0}}
      >
        <motion.div 
          className={styles.modal}
          transition={{ ease: "easeOut", duration: 1 }} 
          animate={{ opacity: 1, y: "0" }}
          initial={{ opacity: 0, y: "100%" }}
        >
          <div className={styles.modalContent}>
            {/* Header container */}
            {type === this.ModalType.Subsidy && (
              <div className={styles.modalHeart}>
                <HeartIcon/>
              </div>
            )}
            <div className={styles.modalHeader}>
              {heading}
            </div>
            {/* Content and button container */}
            <div className={styles.modalBody}>
              {content.map((text) => {
                return(
                  <div>
                    {text}
                    <br/>
                  </div>
                )
              })}
            </div>
            <button onClick={() => this.handleModalClick()} className={styles.closeButton}>
              Close
            </button>
          </div>
        </motion.div>
      </motion.div>
    )
  }
  
  handleModalClick = (type) => {
    this.setState(() => ({
      modalType: type
    }));

    !isDesktop() ?
    this.setState(prevState => ({
      showModal: !prevState.showModal,
    })) 
    :
    type === this.ModalType.Subsidy 
      ? this.props.goToSubsidy() 
      : type === this.ModalType.ServiceTerms
        ? this.props.goToServiceTerms()
        : this.props.goToInfo();
  }

  defaultCardDigits = () => {
    if (this.props.cardDetails?.length > 0) {
      const card = this.props.cardDetails[0];
      return card?.Last4 ?? ''
    }
    return ''
  }

  detailSection = (details, showModal, goToPickup, paymentEnabled, payOnline, cardDetails, servicePrice, subsidised, TransPrice, adminFee, totalPrice, showAfterHoursNote, isFetching, payWithBalance) => (
    <div>
      {isDesktop() ? (
          <DesktopHeader details={details} reason={this.props.reason}/>
        ) : (
          <div className={styles.header}>
            {showModal && this.Modal(this.state.modalType)}
            <div className={styles.banner}>
              <Arrow className={styles.backArrow} onClick={() => goToPickup(this.props.details)} />
              <Cross
                className={styles.cancel}
                onClick={() => go.home(2)}
                style={{fill: '#6C6C6C'}}
              />
            </div>
            {details.patient.AvatarUrl ? (
              <img
                alt={`${details.patient.FirstName} ${
                  details.patient.LastName
                }`}
                className={styles.avatar}
                src={details.patient.AvatarUrl}
              />
            ) : (
              <ProfileIcon className={styles.avatar} />
            )}
            <div className={styles.main}>
              {details.patient.FirstName} {details.patient.LastName}
            </div>
            <div className={styles.secondary}>{details.practice.Name}</div>
            <div className={styles.secondary}>{details.doctor.Name}</div>
            <Medications
              medications={details.medications} 
              onNoMedications={() => go.home()} //TODO: Need router to no meds component? 
            />
            {this.props.reason &&
            <div style={{ margin: "5px 0px", fontSize: "13px", textAlign: "left" }}>
              Note for provider: {this.props.reason}
            </div>
            }
          </div>
        )}
        <div className={styles.pickUp}>
          <div className={styles.subheading} style={{fontWeight: "600"}}>Pick up location</div>
          {isDesktop() ? (
            <div className={styles.location}>
              {details.pricing.Name}, {details.pricing.Address}
            </div>
          ) : (
            <PickUpDetails details={details.pricing} />
          )}
        </div>
        {(Number(details.pricing.Option.Price) > 0 && !payWithBalance) &&
          <div
            className={styles.selectPayment}
          >
            <RadioBox>
                <CircleCheckbox
                  addCSS={circleCheckCSS}
                  selected={details.payOnline}
                  onClick={() => this.setPayByCard()}
                />
              <div className={styles.cardText}>
                {cardDetails.length || !payOnline
                  ? 'Payment Method'
                  : 'Select Payment Method'}
              </div>
              {isFetching && <Spinner className={styles.spinner} />}
              {cardDetails.length && payOnline ? (
                <div className={styles.iconContainer}>
                  <Card className={styles.icon}/>
                  <div className={styles.cardNumber}>{this.defaultCardDigits(cardDetails)}</div>
                </div>
              ) : null}
              {!payOnline && (
                <div className={styles.iconContainer}>
                  <Cash className={styles.icon} />
                </div>
              )}
            </RadioBox>
            {paymentEnabled && <Arrow onClick={paymentEnabled ? this.selectPaymentClick : () => {}} className={styles.arrow} />}
          </div>
        }
        {details.pricing.Option.AllowsPayLater && !payWithBalance && 
          <div className={styles.selectPayLater}>
            <RadioBox>
              <CircleCheckbox
                addCSS={circleCheckCSS}
                selected={details.payLater}
                onClick={() => this.setPayLater()}
              />
              <div className={styles.payLaterText}>
                Send Invoice for payment
                <Container>
                The practice may charge an additional admin fee for manual processing
              </Container>
              </div>
            </RadioBox>
          </div>
        }
        <div className={styles.pricing}>
          <div className={styles.repeat}>
            <div>
              Repeat Prescription Request
            </div>
            <div className={styles.price}>{servicePrice}</div>
          </div>
          {!details.payLater && !payWithBalance &&
          <div className={styles.repeat}>
            <div>
              Transaction Fee
              {!subsidised || details.pricing.Option.Price === 0 ? (
                <div className={styles.type} style={{display: "flex"}}>
                  
                  <div className={styles.typeButton} onClick={() => this.handleModalClick(this.ModalType.Info)}>
                    Learn why &nbsp;
                  </div>
                  <div className={styles.learnIcon}>
                    <InfoIcon width="100%" height="auto" onClick={() => this.handleModalClick(this.ModalType.Info)}/>
                  </div>
                </div>
              ) : (
                <div className={styles.type} style={{display: "flex"}}>
                  Fee is subsidised. &nbsp;
                  <div className={styles.typeButton} onClick={() => this.handleModalClick(this.ModalType.Subsidy)}>
                    Learn why &nbsp;
                  </div>
                  <div className={styles.learnIcon}>
                    <HeartIcon width="100%" height="auto" onClick={() => this.handleModalClick(this.ModalType.Subsidy)}/>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.price}>{TransPrice}</div>
          </div>}
          {details.payLater && details.pricing.Option.HasAdminFee &&
          <div className={styles.repeat}>
            <div>
              Admin Fee
            </div>
            <div className={styles.price}>{adminFee}</div>
          </div>}
          <div className={styles.total} style={{ fontWeight: "600"}}>
            <div>Total</div>
            <div className={styles.totalPrice}>{totalPrice}</div>
          </div>
        </div>
        {payWithBalance && 
        <div className={styles.credit}>
          Your Repeat Script Fee will be paid with your account credit
        </div>
        }
        {showAfterHoursNote && (
          <AfterHoursNote
            name={details.practice.Name}
            hours={details.pricing.Option.Note.split(' ')[2]}
          />
        )
        }
        {!payWithBalance &&
        <FineTextConfirmation isPayLater={this.props.payLater} width="100%" height="auto" onClick={() => this.handleModalClick(this.ModalType.ServiceTerms)}/>
        }
    </div>
  )

  render() {
    const {
      details,
      cardDetails,
      isFetching,
      payOnline,
      paymentEnabled,
      goToPickup,
      acBalance,
    } = this.props;
    const { showModal } = this.state
    // Price include payment fee + platform fee, whereas ServiceCost includes only platform fee
    // For PayLater prescriptions, we should not be charging payment fee, so, totalPrice is ServiceCost
    const adminFeeToCharge = details.pricing.Option.HasAdminFee ? details.pricing.Option.AdminFee : 0;
    const adminFee = this.convertPrice(adminFeeToCharge);
    const totalPrice = this.convertPrice(details.payLater ? details.pricing.Option.ServiceCost + adminFeeToCharge : details.pricing.Option.Price);
    const servicePrice = this.convertPrice(details.pricing.Option.ServiceCost);
    const TransPrice = this.convertPrice(details.pricing.Option.TransactionFee);
    const subsidised = details.pricing.Option.TransactionFee === 0 ? true : false;
    const showAfterHoursNote = moment().day() > 4;
    const payWithBalance = Number(acBalance) >= Number(details.pricing.Option.Price);
    return (
    <div>
      <div className={styles.container}>
        {
          isDesktop() 
          ?
          <Content>
            {this.detailSection(details, showModal, goToPickup, paymentEnabled, payOnline, cardDetails, servicePrice, subsidised, TransPrice, adminFee, totalPrice, showAfterHoursNote, isFetching, payWithBalance)}
          </Content>
        :
        <div className={styles.inner}>
          {this.detailSection(details, showModal, goToPickup, paymentEnabled, payOnline, cardDetails, servicePrice, subsidised, TransPrice, adminFee, totalPrice, showAfterHoursNote, isFetching, payWithBalance)}
        </div>
        }        
      </div>
      <div className={styles.buttonBox}>
          {getButton(
            paymentEnabled,
            cardDetails.length,
            payOnline,
            this.confirmRequest,
            this.selectPaymentClick,
            totalPrice,
          )}
      </div>
    </div>
    );
  }
}

const mapStateToProps = state => ({
  details: state.scriptDetailsReducer,
  token: state.loginReducer.token,
  userId: state.profileReducer.profile.Id,
  cardDetails: state.cardDetailsReducer.cardDetails,
  isFetching: state.cardDetailsReducer.isFetching,
  payOnline: state.scriptDetailsReducer.payOnline,
  payLater: state.scriptDetailsReducer.payLater,
  paymentEnabled: state.scriptDetailsReducer.practice.PaymentEnabled,
  willRetry: state.confirmationReducer.willRetry,
  scriptId: state.submitScriptRequestReducer.scriptId,
  reason: state.scriptDetailsReducer.reason,
  transactionTerms: state.pickUpOptionsReducer.terms,
  acBalance: state.pickUpOptionsReducer.acBalance,
});
const mapDispatchToProps = dispatch => ({
  submit: bindActionCreators(actions.submitScriptRequest, dispatch),
  retryRequest: bindActionCreators(actions.retryRequest, dispatch),
  checkout: () => go.processScript(),
  goHome: () => go.home(),
  /* eslint-disable max-len */
  goToPaymentSelection: () => go.paymentScript(),
  goToPickup: (details) => go.pickUpLocation(details),
  /* eslint-enable max-len */
  fetchCardDetails: bindActionCreators(
    paymentActions.fetchCardDetails,
    dispatch,
  ),
  setInfo: bindActionCreators(scriptActions.setInfo, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmRequest);
