import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Text } from 'components/vensaComponents';
import { IHealthTransaction } from '../../../../store/mobx/paymentStore/paymentStore.type';

export const NameSection: React.FC<Pick<
  IHealthTransaction,
  'FirstName' | 'LastName' | 'OrganisationName'
>> = observer(({ FirstName, LastName, OrganisationName }) => (
  <Box direction="column" alignItems="center">
    <Text fontWeight={700} fontSize="19px">{`${FirstName} ${LastName}`}</Text>
    {OrganisationName && (
      <Text
        fontWeight={100}
        fontSize="15px"
        css={{ fontStyle: 'italic' }}
      >{`${OrganisationName}`}</Text>
    )}
  </Box>
));
