import { profileAPI } from 'components/UIContainer/MobileUI/MainShell/sagas/profile';
import { put, takeLatest, call } from 'redux-saga/effects';

import { actions as profileActions } from '../../../components/UIContainer/MobileUI/MainShell/actions/profileActions';
import Alert from '../../../libs/Alert';
import HttpLib from '../../../libs/Http';
import {
  actions as verifyCodeActions,
  actionTypes as verifyCodeActionTypes,
} from '../actions/registerUserCodeActions';

const verifyCodeHttpParser = HttpLib(
  ({ status }) => ({
    shouldError: status !== 400,
    message: 'An Unexpected Error Occurred.<br/>Please Try Again.',
  }),
  ({ shouldError, message }) => {
    if (shouldError) Alert.error(message);
  },
);
const verifyCodeApi = (token, code) =>
  verifyCodeHttpParser.post('consumer/verify', {
    token,
    data: { Code: code },
  });
const delay = ms =>
  new Promise(resolve => window.setTimeout(() => resolve(true), ms));

function* verifyCode({ payload }) {
  try {
    yield delay(2000);
    yield call(verifyCodeApi, payload.token, payload.code);
    yield put(verifyCodeActions.verifyCodeSuccess());
    yield put(profileActions.userIsVerified(payload.mobile));
  } catch (err) {
    yield put(verifyCodeActions.verifyCodeFailure(err));
  }
}

function* confirmUserRegistrationCodeSaga() {
  yield takeLatest(verifyCodeActionTypes.verifyCode, verifyCode);
}

export { confirmUserRegistrationCodeSaga };
