import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DoctorItem from '../DoctorItem/index';
import { ReactComponent as Arrow } from '../../../../svgs/rightarrow.svg';
import styles from './desktopDoctorList.module.css';

class DesktopDoctorList extends Component {
  static propTypes = {
    doctors: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  };
  checkLeftScroll = () => this.container && this.container.scrollLeft > 5;
  checkRightScroll = () =>
    // eslint-disable-next-line max-len
    this.container &&
    this.container.scrollWidth -
      (this.container.scrollLeft + this.container.offsetWidth) >
      5;
  scrollContainer = goRight => {
    const stopPoint = goRight
      ? this.container.scrollLeft + 95
      : this.container.scrollLeft - 95;
    const shouldStop = () => {
      if (goRight) {
        return this.container.scrollLeft >= stopPoint;
      }
      return this.container.scrollLeft <= stopPoint;
    };
    const shouldKeepScrolling = goRight
      ? this.checkRightScroll
      : this.checkLeftScroll;
    const intervalId = setInterval(() => {
      if (goRight) {
        this.container.scrollLeft += 1;
      } else this.container.scrollLeft -= 1;
      if (shouldStop() || !shouldKeepScrolling()) {
        clearInterval(intervalId);
      }
    }, 3);
  };
  render() {
    const { doctors } = this.props;
    const doctorItems =
      doctors && doctors.map(d => <DoctorItem key={d.PmsUserId} doctor={d} />);
    return (
      <div className={styles.container}>
        <div
          onClick={() => this.scrollContainer(false)}
          className={styles.arrowContainer}
        >
          {doctors && doctors.length > 3 && (
            <Arrow className={styles.leftArrow} />
          )}
        </div>
        <div
          className={styles.doctorListContainer}
          ref={c => {
            this.container = c;
          }}
        >
          {doctorItems}
        </div>
        <div
          onClick={() => this.scrollContainer(true)}
          className={styles.arrowContainer}
        >
          {doctors && doctors.length > 3 && (
            <Arrow className={styles.rightArrow} />
          )}
        </div>
      </div>
    );
  }
}

export default DesktopDoctorList;
