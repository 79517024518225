import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import styles from './practiceCard.module.css';
import PracticeCardMenu from '../PracticeCardMenu';
import dots from '../../../svgs/3dots.svg';
import noPic from '../../../svgs/patientSilhouette.svg'
import { ReactComponent as Arrow } from '../../../svgs/roundArrow.svg'
import { actions } from '../../../PracticesPage/actions/actions.js';
import { getStatus } from '../../DesktopIdentityPage/components/PracticeSelection';
import green from 'svgs/greenTickP.svg';
import orange from 'svgs/orangeTickP.svg';
import red from 'svgs/redCrossP.svg';

class PracticeCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showOverlay: false, 
        }
        this.states = [
            "Online for Verification", 
            "Identity verification is pending from practice", 
            "The practice does not provide online verifications",
            "Online for verification for your profile is not possible, please call the practice on " + this.props.practice.Phone + ".",
            "Identity verification declined by the practice",
            "Identity verified by the practice",
            "Identity already verified with this practice by another Vensa account"
        ]

        this.status = [
            this.props.phone ? 
                ("Please call the practice on " + this.props.phone  + " and ask them to enable vensa online."
                ) : ( "Please call the practice and ask them to enable vensa online."), 
            "Offline for bookings/verification",
            "Online for verification",
        ]

        this.toggleMenu = this.toggleMenu.bind(this);
        this.render = this.render.bind(this);
    }

    toggleMenu() {
        this.setState({ 
            showOverlay: !this.state.showOverlay
        });
    }

    render() {
        let status = getStatus(this.props.practice);
        return (
            <div className={styles.container} onClick={status === 2 || status === 3 || status === 6 ? () => {} : this.props.practiceOnClick}>
                {this.props.dots &&
                    <img onClick={this.toggleMenu} className={styles.dots} src={dots} alt="dots" />
                }
                {this.state.showOverlay && 
                    <PracticeCardMenu 
                        showOverlay={this.state.showOverlay} 
                        className={styles.PracticeCardMenu} 
                        removePractice={() => this.props.removePractice(this.props.item, this.props.token)}
                        toggle={this.toggleMenu} 
                    />
                }
                {(status === 1 || status === 4 || status === 5) && 
                    <div className={styles.contentContainer}>
                        <img style={{position: "absolute", transform: "translateX(45px) translateY(-37px)"}} src={status === 1 ? orange : status === 4 ? red : green} alt="pic" />
                        <img className={styles.practicePic} src={!this.props.practicePic ? noPic : this.props.practicePic} alt="pic" />
                        <div className={styles.textContainer}>
                            <div className={status === 0 || status === 5 ? styles.greenStatus : status === 1 ? styles.orangeStatus : styles.redStatus}>{this.states[status]}</div>
                            <div className={styles.title}>{this.props.practiceName}</div>
                            <div className={styles.text} style={{margin: "0px"}}>{this.props.practiceAddress}</div>
                        </div>
                        <Arrow style={{margin: "10px", overflow: "visible"}} />                   
                    </div>
                }
                {(status !== 1 && status !== 4 && status !== 5) &&
                    <div className={styles.contentContainer}>
                        <img className={styles.practicePic} src={!this.props.practicePic ? noPic : this.props.practicePic} alt="pic" />
                        <div className={styles.textContainer}>
                            <div className={styles.title}>{this.props.practiceName}</div>
                            <div className={styles.text} style={{margin: "0px"}}>{this.props.practiceAddress}</div>
                            <div style={{fontWeight: status === 0 ? "600" : "400", fontSize: "15px", margin: "0.3rem 0rem"}} className={status === 0 || status === 5 ? styles.greenStatus : status === 1 ? styles.orangeStatus : styles.redStatus}>{this.states[status]}</div>
                        </div>
                        {status === 0 && <Arrow style={{margin: "10px", overflow: "visible"}} />}                    
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    token: state.loginReducer.token,
    list : state.practiceListingReducer.practices,
});

const mapDispatchToProps = dispatch => ({
    removePractice: bindActionCreators(actions.unlinkPractice, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
) (PracticeCard);