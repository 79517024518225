import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash/fp';
import classnames from 'classnames';

import PrescriptionCard from './PrescriptionCard/index';
import NoScripts, { NewScripts } from './NoScripts/index';
import styles from './prescriptionListMobile.module.css';
import { scriptPropType } from '../propTypes/index';
import LearnMorePopUp from 'components/LearnMorePopUp/LearnMorePopUp';

const PrescriptionListMobile = ({
  isFetching,
  currentRequests,
  pastRequests,
  setCtaText,
  toggleMenu,
  toggleOpenCardState,
  isOpen,
}) => {
  const [showPopUp, setShowPopUp] = useState(false);

  if (isFetching) return <div />;
  else
    return (
      <div className={styles.container}>
        {showPopUp &&
          <LearnMorePopUp type={"VSCRIPT_INFO"} hide={() => setShowPopUp(false)} />
        }
      <div
        className={classnames(styles.inner, { [styles.closed]: !isOpen })}
      >
        <NewScripts toggle={() => setShowPopUp(true)} />
        <br />
        {currentRequests.length > 0 &&
          map(s => (
            <PrescriptionCard
              key={s.Id}
              script={s}
              setCtaText={setCtaText}
              toggleOpenCardState={toggleOpenCardState}
            />
          ))(currentRequests)}
        {currentRequests.length > 0 && pastRequests.length > 0 && (
          <div className={styles.pastRequestsTitle}>PAST REQUESTS</div>
        )}
        {pastRequests.length > 0 &&
          map(s => (
            <PrescriptionCard
              key={s.Id}
              past
              script={s}
              setCtaText={setCtaText}
              toggleOpenCardState={toggleOpenCardState}
            />
          ))(pastRequests)}
      </div>
      </div>
    );
};

PrescriptionListMobile.propTypes = {
  isFetching: PropTypes.bool,
  toggleMenu: PropTypes.func.isRequired,
  setCtaText: PropTypes.func.isRequired,
  toggleOpenCardState: PropTypes.func.isRequired,
  pastRequests: PropTypes.arrayOf(scriptPropType),
  currentRequests: PropTypes.arrayOf(scriptPropType),
};

export default PrescriptionListMobile;
