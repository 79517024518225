import React, { useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { ReactComponent as Arrow } from 'svgs/whiteLineLeftArrow.svg';
import { useAccountStore } from 'store/mobx/mobx';
import { useComponentDidMount } from 'libs/newHttp';
import { useState } from 'react';
import Cta from 'components/CtaButton';
import { goBack } from 'routes/utils';
import moment from 'moment';
import { isValidDate, toNormalFormat } from 'libs/Dates';
import { useRef } from 'react';
import { isValidEmail } from 'libs/ValidBasicInfo';
import { actions as loginActions } from "EntryPage/actions/actions";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styles from './sign-up-flow.module.css';
import Loading from 'components/loadingSpinner';
import bg from 'images/polygons.jpg';
import PasswordPolicy from '../DesktopSignUpFlow/components/PasswordPolicy';
import { go } from 'routes';

const Container = styled.div`
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 1rem;
`
const Inner = styled.div`
`
const Header = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
`
const Title = styled.div`
    color: white;
    font-size: 21px;
    width: 100%;
    text-align: center;
`
const InputText = styled.div`
    color: white;
    font-size: 17px;
`
const Input = styled.input`
    font-size: 21px;
    color: white;
    padding: 1rem 0rem;
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    background-color: transparent;
    &::-webkit-autofill {
        box-shadow: 0px 0px 0px 1000px #FFFFFF inset !important;
    }
`
const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 4rem 0rem;
    padding: 0rem 1rem;
`
const PasswordRestrictions = styled.div`
    font-size: 15px;
    color: #FFFFFF;
    opacity: 0.7;
    margin: 0.5rem 0rem;
`
const PolicyText = styled.div`
    color: white;
    font-style: italic; 
    font-size: 17px;
    font-weight: 300;
    width: 100%;
`
const Link = styled.span`
    font-weight: 500;
    text-decoration: underline;
`

const getDob = (date: string) => {
    return moment(date, ['YYYY-MM-DD', 'DD-MM-YYYY']).format('YYYY-MM-DD');
}

const isButtonActive = (state: number, dobRef: React.RefObject<HTMLInputElement>, Email: string, Password: string, PrivacyPolicy: boolean) => {
    if (state === 0) { 
        return true 
    } else if (state === 1 && isValidDate(dobRef.current!.value)) {
        return true;
    } else if (state === 2 && isValidEmail(Email) && Email !== "") {
        return true;
    } else if (state === 3 && isValidPassword(Password) && PrivacyPolicy === true) {
        return true;
    } else return false;
}

const isValidPassword = (password: string) => ( 
    password.length >= 8 && //is long enough
    /[a-z]/.test(password) && // has lowercase letters
    /[A-Z]/.test(password) && // has uppercase letters
    (/\d/.test(password) || // has digits
    /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)) // has symbols
)

const SignUpFlow: React.FC<LinkDispatchProps> = observer(({login, success, consumerUrl, expiryTime, email, refreshToken, loading}) => {
    const store = useAccountStore();

    const [ActiveButton, setActiveButton] = useState(false);
    const [FirstName, setFirstName] = useState(store.signUpInvitationDetails.GivenName)
    const [LastName, setLastName] = useState(store.signUpInvitationDetails.FamilyName);
    const [PreferredName, setPreferredName] = useState(store.signUpInvitationDetails.PreferredName);
    const [Birthday, setBirthday] = useState(getDob(store.signUpInvitationDetails.DateOfBirth))
    const [Email, setEmail] = useState(store.signUpInvitationEmail ? store.signUpInvitationEmail  : "");
    const [Password, setPassword] = useState("")
    const [PrivacyPolicy, setPrivacyPolicy] = useState(false);
    const [state, setState] = useState(0);

    const dobRef = useRef(null);
    let content;

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let e = params.get('e');
        let t = params.get('t');

        if (!store.signUpInvitationSuccess) {
            if (FirstName === "") {
                go.signUpInvitation(t, e); 
            }
        }
    }, [store.signUpInvitationSuccess])

    useEffect(() => {
        setActiveButton(isButtonActive(state, dobRef, Email, Password, PrivacyPolicy));
        if (success) {
                window.open(
                  consumerUrl + "login?token=" +
                  refreshToken + "&expires=" + 
                  expiryTime + "&email=" + 
                  email + "&firstTime=false", 
                  "_self"
                );
        }   
    })

    if (state === 0) {
        content =   <Inner>
                        <Header>
                            <Arrow onClick={() => goBack()} style={{position: "absolute"}} />
                            <Title>What is your name?</Title>
                        </Header>
                        <ContentContainer>
                            <InputText>Legal First Name</InputText>
                            <Input 
                                readOnly
                                type={"text"}
                                value={FirstName}
                                onChange={(e: any) => setFirstName(e.target.value)}
                            />
                            <InputText style={{marginTop: "2rem"}}>Legal Surname</InputText>
                            <Input 
                                readOnly
                                type={"text"}
                                value={LastName}
                                onChange={(e: any) => setLastName(e.target.value)}
                            />
                            <InputText style={{marginTop: "2rem"}}>Preferred Name (Optional)</InputText>
                            <Input 
                                readOnly
                                type={"text"}
                                value={PreferredName}
                                onChange={(e: any) => setPreferredName(e.target.value)}
                            />
                        </ContentContainer>
                    </Inner>
    } else if (state === 1) {
        content = <Inner>
                    <Header>
                        <Arrow onClick={() => setState(0)} style={{position: "absolute"}}  />
                        <Title>{FirstName + " " + LastName}</Title>
                    </Header>
                    <ContentContainer>
                        <InputText>Please confirm your date of birth</InputText>
                        <Input
                            readOnly
                            type={"text"}
                            value={toNormalFormat(Birthday)}
                            ref={dobRef}
                            onChange={(e: any) => setBirthday(e.target.value)}
                        />
                    </ContentContainer>
                </Inner>
    } else if (state === 2) {
        content =   <Inner>
                        <Header>
                            <Arrow onClick={() => setState(1)} style={{position: "absolute"}}  />
                            <Title>{FirstName + " " + LastName}</Title>
                        </Header>
                        <ContentContainer>
                            <InputText>What's your email?</InputText>
                            <Input
                                readOnly 
                                type={"text"}
                                value={Email}
                                onChange={(e: any) => setEmail(e.target.value)}
                            />
                        </ContentContainer>
                    </Inner>
    } else if (state === 3) {
        content = <Inner>
                    <Header>
                        <Arrow onClick={() => setState(2)} style={{position: "absolute"}}  />
                        <Title>{FirstName + " " + LastName}</Title>
                    </Header>
                    <ContentContainer>
                        <InputText>Create your password</InputText>
                        <input className={styles.input} autoFocus type={"password"} value={Password} onChange={(e: any) => setPassword(e.target.value)} />
                        <PasswordPolicy password={Password} />
                    </ContentContainer>
                </Inner>
    }
    
    return (
        <Container>
            <Loading isFetching={state !== 3 && (store.loading || loading)}>
                {content}
                {state === 3 &&
                        <div style={{width: "100%", display: "flex", flexDirection: "row", marginBottom: "1rem", marginTop: "auto", marginLeft: "auto", padding: "0rem 1rem"}}>
                            <div className={styles.round} onClick={(e: any) => {e.preventDefault(); setPrivacyPolicy(!PrivacyPolicy)}}> 
                                <input type="checkbox" id="checkbox" checked={PrivacyPolicy === true} />
                                <label htmlFor="checkbox"></label>
                            </div>
                            <PolicyText>
                                I have read and agree to the&#160;
                                <Link onClick={() => window.open("https://vensa.com/consumer/terms-and-conditions/3", "_blank")}>
                                    terms and conditions
                                </Link> 
                                &#160;&&#160;
                                <Link onClick={() => window.open("https://vensa.com/consumer/privacy-policy/3", "_blank")}>
                                    privacy policy
                                </Link>.
                            </PolicyText>
                        </div>
                    }
                <ContentContainer style={{width: "72px", height: "72px", position: "absolute", right: "3rem", bottom: "1rem"}}>
                    <Cta 
                        white={ActiveButton}
                        isProcessing={store.loading}
                        active={ActiveButton}
                        onClick={() => {
                            if (!ActiveButton) { return }
                            if (state !== 3) {
                                setState(state + 1);
                                return;
                            } else {
                                store.registerWithSignUpToken({
                                    Token: store.signUpInvitationToken,
                                    Email: Email,
                                    GivenName: FirstName,
                                    FamilyName: LastName,
                                    PreferredName: PreferredName,
                                    MobileNumber: "",
                                    DateOfBirth: Birthday,
                                    Password: Password
                                }, () => {
                                    login(Email, Password);
                                })
                            }
                        }}
                    />
                </ContentContainer>
            </Loading>
        </Container>
    )
})

interface LinkDispatchProps {
    login: (Email: string, Password: string) => void;
    success: boolean;
    consumerUrl: string;
    refreshToken: string;
    expiryTime: string;
    email: string;
    loading: boolean;
}

const mapStateToProps = (state: any) => ({
    success: state.loginReducer.success,
    consumerUrl: state.loginReducer.consumerUrl,
    refreshToken: state.loginReducer.refreshToken,
    expiryTime: state.loginReducer.expiryTime,
    email: state.loginReducer.email,
    loading: state.loginReducer.isFetching
})
const mapDispatchToProps = (dispatch: any) => ({
    login: bindActionCreators<any, any>(loginActions.login, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUpFlow);