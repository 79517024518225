import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import UIContainer from '../../components/UIContainer';
import { useAccountStore } from '../../store/mobx/mobx';
import { useComponentDidMount } from '../../libs/newHttp';
import BlueButton from '../../components/DesktopComponents/BlueButton';
import PopUpVerificationNavigator from '../../components/DesktopComponents/PopUpVerificationNavigator';
//import Labels from 'Identity/components/IdentityLabelDesktop/IdentityLabelDesktop';
import ImageUploader from 'components/ImageUploader';
import DesktopPopUp from 'components/DesktopPopUp';
import ChangePassword from 'UserAccountPage/ChangePassword';
import moment from 'moment';
import Labels from 'Identity/components/IdentityLabel';
import { DEFAULT_GUID } from 'common/constants';

const Banner = styled.div`
    text-align: left;
    height: 60px;
    width: 100%;
    margin-bottom: 1rem;
    background-color: white;
    padding: 1rem 2rem;
    font-size: 15px;
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #F9F9F9;
    width: 100%;
    overflow: scroll;
`
const Inner = styled.div`
    width: 500px;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 1px 2px 6px lightgray;
    padding: 1rem 0rem;
`
const Link = styled.div`
    font-size: 13px;
    color: #07A3C8;
    width 100%;
    text-align: center;
    cursor: pointer;
    text-align: left;
    cursor: pointer;
`
const Form = styled.div`
    display: flex;
    flex-direction: row;
    margin: 1rem 2rem;
`
const Section = styled.div`
    display: flex;
    flex-direction: column;
    margin: 1rem;
`
const Text = styled.div`
    font-size: 13px;
    color: #939393;
    text-align: left;
    width: 100%;
`
const Input = styled.input`
    border: none;
    border: 1.5px solid #DADADA;
    border-radius: 4px;
    font-size: 15px;
    padding: 0.5rem;
    color: #585858;
    margin: 10px 0px;
    width: 100%;
`
const Label = styled.div`
    font-size: 13px;
    text-align: left;
    padding-left: 7px;
    width: 100%;
`
const FlexH = styled.div`
    display: flex;
    flex-direction: row;
`

const DesktopUserAccountPage: React.FC = observer(() => {
    const [edited, setEdited] = useState(false);
    const [show, setShow] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [isPrimary, setIsPrimary] = useState(true);
    const [isCombo, setIsCombo] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [mobileChanged, setMobileChanged] = useState(false);

    const storeAcc = useAccountStore();


    // Temp values array represents each of the form fields
    // 0 - Firstname
    // 1 - Surname
    // 2 - Preferred Name
    // 3 - Date Of Birth
    // 4 - Mobile
    // 5 - Email
    const [tempValues, setTempValues] = useState(
           [storeAcc.profile.FirstName, storeAcc.profile.LastName, storeAcc.profile.PreferredName, 
            storeAcc.profile.DateOfBirth, storeAcc.profile.Mobile, storeAcc.profile.Email]
        );
    const pos = {
        firstname: 0,
        surname: 1,
        preferredname: 2,
        dob: 3,
        mobile: 4,
        email: 5
    };
    
    useComponentDidMount(() => {
        storeAcc.getProfile();
    });

    const onChange = (e: React.FormEvent<HTMLInputElement>, id: number) => {
        setEdited(true);

        //If [tempValues] empty, but the profile has data, reload array from profile object
        let arr = (storeAcc.profile.IdentityVerified && (tempValues[0] === '' || tempValues[1] === ''))
            ? [storeAcc.profile.FirstName, storeAcc.profile.LastName, storeAcc.profile.PreferredName, 
                storeAcc.profile.DateOfBirth, storeAcc.profile.Mobile, storeAcc.profile.Email]
            : tempValues;

        arr[id] = e.currentTarget.value;
        if (id === pos.mobile) {
            setMobileChanged(true);
            if (arr[id] === storeAcc.profile.Mobile || arr[id] === '') {
                arr[id] = storeAcc.profile.Mobile;
                setMobileChanged(false);
            } 
        }
        setTempValues(arr);
    }

    const comboFunc = () => {
        if (isCombo) {
            setIsMobile(false);
            setIsAdd(true);
            setIsPrimary(true);
            setShow(true);
            setIsCombo(false);
        }
    }

    return (
        <UIContainer isFetching={storeAcc.loading}>
            <Container>
                {show && 
                    <PopUpVerificationNavigator 
                        combo={() => comboFunc()} 
                        close={() => {
                            storeAcc.getProfile();
                            setShow(false);
                        }} 
                        isCombo={isCombo} 
                        isMobile={isMobile} 
                        isPrimary={isPrimary}
                    />
                }
                <Banner>{storeAcc.profile.FirstName + "  " + storeAcc.profile.LastName + "'s Profile"}</Banner>
                {showPassword &&
                    <DesktopPopUp toggle={() => setShowPassword(!showPassword)}>
                        <div style={{width: "100%", textAlign: "center", margin: "10px 0px 30px"}}>Change Password</div>
                        <ChangePassword dontGoBack={true} close={() => setShowPassword(false)} />
                        <Link
                            onClick={() => setShowPassword(false)}
                            style={{position: "absolute", zIndex: 2, fontSize: "13px", left: "40px", bottom: "50px", padding: "0"}}
                        >
                            Keep my current password
                        </Link>
                    </DesktopPopUp>
                }
                
                <Inner>
                    <ImageUploader avatar={storeAcc.profile.AvatarUrl} onSubmit={(pic: File) => storeAcc.uploadPic(pic, () => storeAcc.getProfile())} />
                    <Labels />
                    <Form>
                        <Section>
                            <Text>Legal First Name</Text>
                            <Input placeholder={storeAcc.profile.FirstName} onChange={(e) => onChange(e, pos.firstname)} readOnly={storeAcc.profile.IdentityVerified} />
                            <Text>Preferred Name (Optional)</Text>
                            <Input placeholder={storeAcc.profile.PreferredName} onChange={(e) => onChange(e, pos.preferredname)} />
                            <FlexH>
                                <Text>Mobile Number</Text>
                                <Label 
                                    onClick={storeAcc.profile.MobileVerified ? () => {} : () => {
                                        setIsMobile(true);
                                        setIsAdd(false);
                                        setShow(true);
                                        setIsCombo(false);
                                    }}
                                    style={{cursor: storeAcc.profile.MobileVerified ? "auto" : "pointer", 
                                            textDecoration: storeAcc.profile.MobileVerified && !mobileChanged
                                                ? "none" 
                                                : "underline", 
                                            color: storeAcc.profile.MobileVerified && !mobileChanged 
                                                ? "#23C373" 
                                                : "#FC7B7B"}}>
                                        {storeAcc.profile.MobileVerified && !mobileChanged 
                                                ? "Verified" 
                                                : " Not Verified >"}
                                </Label>
                            </FlexH>
                            <Input onClick={() => {
                                    setIsMobile(true);
                                    setIsAdd(true);
                                    setShow(true);
                                    setIsCombo(false);
                            }} placeholder={storeAcc.profile.Mobile} onChange={(e) => onChange(e, pos.mobile)} />
                            <Link onClick={() => {
                                    setIsMobile(true);
                                    setIsAdd(true);
                                    setShow(true);
                                    setIsCombo(false);
                            }}>Change Account Mobile</Link>
                        </Section>
                        <Section>
                            <Text>Legal Surname</Text>
                            <Input placeholder={storeAcc.profile.LastName} onChange={(e) => onChange(e, pos.surname)} readOnly={storeAcc.profile.IdentityVerified} />
                            <Text>Date of birth</Text>
                            <Input placeholder={moment(storeAcc.profile.DateOfBirth).format('DD/MM/YYYY')} onChange={(e) => onChange(e, pos.dob)} readOnly={storeAcc.profile.IdentityVerified} />
                            <FlexH>
                                <Text style={{width: "auto"}}>Email</Text>
                                <Label onClick={storeAcc.profile.EmailVerified ? () => {} : () => {
                                        setIsMobile(false);
                                        setIsAdd(false);
                                        setShow(true);
                                        setIsCombo(false);
                                    }}
                                    style={{cursor: storeAcc.profile.EmailVerified 
                                                ? "auto" 
                                                : "pointer", 
                                            textDecoration: storeAcc.profile.EmailVerified 
                                                ? "none" 
                                                : "underline", 
                                            color: storeAcc.profile.EmailVerified 
                                                ? "#23C373" 
                                                : "#FC7B7B"}}>
                                        {storeAcc.profile.EmailVerified ? "Verified" : " Not Verified >"}
                                </Label>
                            </FlexH>
                            <Input readOnly={true} placeholder={storeAcc.profile.Email} onChange={(e) => onChange(e, pos.email)} ></Input>
                            {/*<Link onClick={() => {
                                    setIsMobile(false);
                                    setIsAdd(false);
                                    setIsPrimary(true);
                                    setShow(true);
                                    setIsCombo(false);
                            }}>Change Account Email</Link>*/}
                        </Section>
                    </Form>
                    <Form style={{alignItems: "center", marginBottom: "1rem 0px"}}>
                        {storeAcc.profile.CareGiverId === DEFAULT_GUID && 
                            <Link onClick={() => setShowPassword(true)}>Change Password</Link>
                        }
                        {/*<Link>Deactivate Account</Link>*/}
                        <BlueButton disabled={!edited} style={{borderRadius: "4px", padding: "0.7rem 1.5rem", marginRight: 0}} text={"Save"} onClick={() => storeAcc.editProfile({
                             FirstName: tempValues[pos.firstname],
                             LastName: tempValues[pos.surname],
                             PreferredName: tempValues[pos.preferredname],
                             DateOfBirth: moment(tempValues[pos.dob], ['YYYY-MM-DD', 'DD-MM-YYYY']).format('YYYY-MM-DD'),
                             Mobile: tempValues[pos.mobile]
                        })} />
                    </Form>
                </Inner>
            </Container>
        </UIContainer>
    )
})

export default DesktopUserAccountPage;
