import { call, takeEvery, put } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';
import { actions as enrollmentActions } from '../../PatientSelection/actions/actions';
import { GenericParserHttp } from '../../../libs/Http';
import { go } from 'routes';

const api = id => GenericParserHttp.get(`consumer/appointment/${id}/reasons-for-visit-2`).then(({ body }) => body);
const apiConsult = id => GenericParserHttp.get(`consumer/appointment/${id}/consultation-method`).then(({ body }) => body);
const apiPostService = (service, sessionId) => GenericParserHttp.post(`consumer/appointment/session/service`, {
  data: {
    ServiceId: service.Id,
    ServiceName: service.Name,
    ServiceDescription: service.Description,
    SessionId: sessionId
  }
})
  .then(({ body }) => body)
const apiUpdateSession = (sessionId, consult, symptoms, note, service) => GenericParserHttp.post(`consumer/appointment/session/service`, {
  data: {
    SessionId: sessionId,
    SymptomId: symptoms,
    ConsultationMethod: consult,
    Note: note,
    ServiceId: service.Id,
    ServiceName: service.Name,
    ServiceDescription: service.Description,
  }
})
  .then(({ body }) => body)

function* updateSession({ payload: { sessionId, consult, symptoms, note, service, urlName } }) {

  try {
    const session = yield call(apiUpdateSession, sessionId, consult, symptoms, note, service);
    yield put(enrollmentActions.enrolledPatientSuccess(session))
    session.RequirePayment ? go.prepaidPage(urlName) : go.confirmAppointment(urlName);
  } catch (err) {
    //do something with error, if needed
    if (err.status === 403) {
      yield put(enrollmentActions.enrolledPatientFailure(err.response.body));
      go.guestBookingError(urlName);
    }
    else
      yield put(actions.postServiceFailure(err));
  }
}

function* getReasons({ payload: { id } }) {
  try {
    const reasons = yield call(api, id);
    yield put(actions.getReasonsSuccess(reasons));
  } catch (err) {
    yield put(actions.getReasonsFailure(err));
  }
}

function* getConsultMethods({ payload: { id } }) {
  try {
    const methods = yield call(apiConsult, id);
    yield put(actions.getConsultMethodsSuccess(methods));
  } catch (err) {
    yield put(actions.getConsultMethodsFailure(err))
  }
}

function* postService({ payload: { service, sessionId, urlName } }) {
  try {
    const session = yield call(apiPostService, service, sessionId);
    yield put(enrollmentActions.enrolledPatientSuccess(session));
  } catch (err) {
    if (err.status === 403) {
      yield put(enrollmentActions.enrolledPatientFailure(err.response.body));
      go.guestBookingError(urlName);
    } else {
      yield put(actions.postServiceFailure(err));
    }
  }
}

function* updateSessionSaga() {
  yield takeEvery(actionTypes.updateSession, updateSession);
}
function* getConsultMethodsSaga() {
  yield takeEvery(actionTypes.getConsultMethods, getConsultMethods);
}
function* getReasonsSaga() {
  yield takeEvery(actionTypes.getReasons, getReasons);
}
function* postServiceSaga() {
  //yield takeEvery(actionTypes.postService, postService);
}

export { getReasonsSaga, getConsultMethodsSaga, postServiceSaga, updateSessionSaga };
