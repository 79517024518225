import { call, put, takeLatest } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';
import { NoErrorParserHttp } from '../../../libs/Http';

const api = (pin, token) =>
  NoErrorParserHttp.post('consumer/identity/pin-code', {
    data: pin,
    token,
  }).then(({ body }) => body);

function* verifyPinCode({ payload: { pin, token } }) {
  try {
    yield call(api, pin, token);
    yield put(actions.submitVerifyPinSuccess());
  } catch (err) {
    yield put(actions.submitverifyPinFailure()); 
  }
}

function* verifyPinCodeSaga() {
  yield takeLatest(actionTypes.submitVerifyPin, verifyPinCode);
}

export { verifyPinCodeSaga };