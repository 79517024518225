import React from 'react';
import styled from 'styled-components';
import { ReactComponent as BlueCross } from 'svgs/blueCross.svg';
import { ReactComponent as WhiteCross } from 'svgs/whiteCrossSmall.svg';
import { ReactComponent as Card } from 'svgs/card_tilted.svg';
import { isDesktop } from '../../../config';
import { paddingBottom } from 'styled-system';

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
`
const Modal = styled.div`
    width: 500px;
    height: 600px;
    background-color: white;
    border-radius: 10px;
    padding: 0 20px 20px 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`
const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`
const CloseButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    margin-top: -60px;
`
const ModalContent = styled.div`
    max-height: 390px;
    max-width: 430px;
    overflow-y: auto;
    margin: auto;
    overflow-y: auto;
    font-size: 15px;

    &::-webkit-scrollbar {
		width: 10px;
	 }
	   
	 &::-webkit-scrollbar-track {
		background: #C7DEE2;
		border-radius: 25px;
		margin-left: 1rem;
	 }
	 
	 &::-webkit-scrollbar-thumb {
		 background: #A7C2C7;
		 border-radius: 25px;
	 }
	 
	 &::-webkit-scrollbar-thumb:hover {
		 background: var(--color-pelorous);
	 }
`
const Title = styled.div`
    color: var(--color-pelorous);
    width: 100%;
    text-align: center;
    margin: auto;
    padding-top: 20px;
`
const ActionContainer = styled.div`
    margin-left: auto;
    margin-right: auto;
    position: relative;
    bottom: 40px;
    width: 70px;
    height: 70px;
    color: var(--color-pelorous);
    background-color: var(--color-pelorous);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1000;
`

const ActionButton = ({ onClick }) => {
  return (
    <ActionContainer onClick={onClick}>
      <WhiteCross style={{width: '30px', height: '30px'}}/>
    </ActionContainer>
  );
};

const PrivacyModal = ({ onClose}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <ModalOverlay>
      <Modal style={{width: isDesktop() ? '500px' : '90%'}}>
        <ModalHeader>
          <Title>
            <h2>Your card details</h2>
            <Card style={{width: '59px', height: '48px'}}/>
          </Title>
          {isDesktop() && (
            <CloseButton onClick={handleClose}>
              <BlueCross style={{height: '25px', top: '1rem', right: '2rem', cursor: 'pointer'}}/>
            </CloseButton>
          )}
        </ModalHeader>
        <ModalContent style={{paddingBottom: isDesktop() ? '10px' : '50px'}}>
            <p>Saving your credit card details and other personal information submitted while making requesting a service from your clinic (“Transaction Data”), will mean you won’t have to re-enter them for the purposes of any future purchases or purchase-related enquiries which you may make through this website or any of our sales channels (whether online, through any of our web or telephone support centres or in any of our stores).</p>
            <p>Your card details and personal information (date of birth, address etc) will be saved on the Vensa system. This means you won't have to enter all this information again next time you make a purchase through Vensa.</p>
            <p>Remembering your Transaction Data will involve us retaining, storing, retrieving and otherwise processing the Transaction Data as well as sharing it with carefully selected third parties. These third parties include any service providers whom we may appoint to process payment on our behalf and/or provide credit card tokenisation services.
            (Tokenisation is one of the security measures that we use in order to increase the security of online transactions). Our processing may involve us transferring the Transaction Data outside of your country of residence. The Transaction Data will be stored on secure servers in encrypted files.</p>
            <p>By storing your cards and adding new cards, you consent to Vensa Health Ltd processing your Transaction Data in this way.</p>
            <p>Our use of your personal information is governed by our Privacy Policy and Website Terms and Conditions. By agreeing to save your Transaction Data you acknowledge that you have read the Privacy and Security Policy and Website Terms and Conditions and agree to their terms.</p>
            <p>If you change your mind at any time, you may update your preferences by amending your payment details in the payment section of our system.</p>
        </ModalContent>
        {!isDesktop() && (
          <ActionButton onClick={handleClose} />
        )}
      </Modal>
    </ModalOverlay>
  );
};

export default PrivacyModal;