const actionTypes = {
  sendPaymentInfo: 'PAYMENT_FORM_SEND_INFO',
  sendPaymentInfoSuccess: 'PAYMENT_FORM_SEND_INFO_SUCCESS',
  sendPaymentInfoFailure: 'PAYMENT_FORM_SEND_INFO_FAILURE',
};

const actions = {
  sendPaymentInfo: (data, token, appointment, saveCard) => ({
    type: actionTypes.sendPaymentInfo,
    payload: {
      data,
      token,
      appointment,
      saveCard
    },
  }),
  sendPaymentInfoSuccess: () => ({
    type: actionTypes.sendPaymentInfoSuccess,
  }),
  sendPaymentInfoFailure: error => ({
    type: actionTypes.sendPaymentInfoFailure,
    payload: {
      error,
    },
  }),
};

export { actions, actionTypes };
