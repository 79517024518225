import React, { useEffect } from 'react';
import classnames from 'classnames';
import { CSSTransitionGroup } from 'react-transition-group';
import { Transition } from 'react-pose';
import { connect } from 'react-redux';
import LoadingSpinner from '../loadingSpinner';
import Header from './MobileUI/header';
import DesktopHeader from './DesktopUI/DesktopHeading';
import { isDesktop } from '../../config';
import styles from './pageContainer.module.css';
import { HealthNavigatorIFrame } from '../../MyHealthPage/components/HealthNavigatorIframe';
import { WhiteNotificationBox } from './MobileUI/WhiteNotificationBox/WhiteNotificationBox';
import { isWhiteBoxNotificationOpen } from './MobileUI/MainShell/selectors';
import { useAccountStore } from 'store/mobx/mobx';
import SideBar from 'components/shared/desktop/SideBar';

const getStyle = (isWhite, headerHeight, isWhiteBoxNotificationOpen) => {
  const result = isWhite
    ? { background: 'white', marginTop: headerHeight, display: "flex" }
    : {
        marginTop: headerHeight, 
        display:"flex",
        flexDirection: "row", 
        height: isWhiteBoxNotificationOpen ? "auto" : "100%",
      };

  if (isWhiteBoxNotificationOpen) {
    result.marginTop = `calc( -${headerHeight} + 34px )`;
  } 
  
  return result;
};

const UIContainer = (props) =>
  {
    const {
      isWhite,
      children,
      heading,
      secondaryHeading,
      isFetching,
      containerClass,
      headerClass,
      goBackFn,
      blueSpinner,
      headerHeight,
      showMenu,
      showHelp,
      showCancel,
      hideArrow,
      saveFn,
      subheading,
      showCross,
      card,
      whiteBg,
      isWhiteBoxNotificationOpen,
      removePadding,
      phrText,
      noAfter,
      showCrossRight,
      navKey
    } = props;

    const desktop = isDesktop();
    const store = useAccountStore();
    
    useEffect(() => {
      if (localStorage.getItem("token")) {
        store.getProfile();
      }
    }, [desktop]);

    return (
      <CSSTransitionGroup
        component="div"
        className={styles.outer}
        style={{
          backgroundColor: whiteBg ? "white": "", 
          position: "relative", 
          overflow: isWhiteBoxNotificationOpen ? "scroll" : "hidden", 
          paddingBottom: "0rem"
        }}
        transitionName={{
          leave: styles.leave,
          leaveActive: styles.leaveActive,
          enter: styles.enter,
          enterActive: styles.enterActive,
        }}
        transitionLeaveTimeout={300}
        transitionEnterTimeout={300}
      >
        {!isDesktop() &&
          <Transition>
            <WhiteNotificationBox key="WhiteNotificationBox" />
          </Transition>
        }
        {desktop && heading && (
          <DesktopHeader
            heading={heading}
            secondaryHeading={secondaryHeading}
          />
        )}
        {!desktop && (
          <Header
            isWhite={isWhite}
            pic={props.pic}
            firstLine={heading}
            secondLine={secondaryHeading}
            showMenu={showMenu}
            showHelp={showHelp}
            showCancel={showCancel}
            subheading={subheading}
            saveFn={saveFn}
            goBackFn={goBackFn}
            hideArrow={hideArrow}
            headerClassNames={headerClass}
            showCross={showCross}
            phrText={phrText}
            noAfter={noAfter}
            showCrossRight={showCrossRight}
          />
        )}
        {desktop && <SideBar value={navKey} />}
        <LoadingSpinner
          containerClassName={removePadding ? classnames(styles.inner, styles.noPadding, containerClass) : classnames(styles.inner, containerClass) }
          overlayClassName={styles.overlay}
          // eslint-disable-next-line
          style={getStyle(isWhite, headerHeight, isWhiteBoxNotificationOpen)}
          iconClassName={classnames(styles.spinner, {
            [styles.blue]: blueSpinner,
          })}
          isFetching={isFetching}
        >
        {isDesktop() &&
          <Transition>
            <WhiteNotificationBox key="WhiteNotificationBox" />
          </Transition>
        }
          {!desktop && card}
          {children}
        </LoadingSpinner>
        <Transition>
          <HealthNavigatorIFrame key="1" />
        </Transition>
      </CSSTransitionGroup>
    );
  }

export default connect(state => ({
  isWhiteBoxNotificationOpen: isWhiteBoxNotificationOpen(state),
}))(UIContainer);

export { styles };
