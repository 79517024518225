import { actionTypes } from '../actions/actions';

const INITIAL_STATE = {
  confirmed: false,
  isFetching: false,
  error: null,
  scriptId: null,
};

const submitScriptRequestReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.submitScriptRequest: {
      return {
        isFetching: true,
        confirmed: false,
        error: null,
      };
    }
    case actionTypes.submitScriptRequestSuccess: {
      return {
        isFetching: false,
        confirmed: true,
        error: null,
        scriptId: action.payload.scriptId,
      };
    }
    case actionTypes.submitScriptRequestFailure: {
      return {
        isFetching: false,
        confirmed: false,
        error: action.payload.error,
      };
    }
    case actionTypes.clearConfirmationState: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default submitScriptRequestReducer;
