import { CrumbsField } from 'pages/desktop/types';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';

type Props = CrumbsField;

const Breadcrumbs = ({crumbs}: Props) => {
    
  return (
    <div className={styles.container}>
        {
            crumbs.map(({name, path}, index) => 
            index + 1 === crumbs.length ? (
                <span key={index}>
                    {name}
                </span>
            ): (
                <Link className={styles.link} key={index} to={path!}>
                    {name}<i>/</i>
                </Link>
            ))
        }
    </div>
  )
}

export default Breadcrumbs;