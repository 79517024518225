import React from 'react';
import styles from './privacyCard.module.css';
import privacyIcon from '../../svgs/privacy2.svg';

const PrivacyCard = ({showPopUp}) => {
    return(
        <div className={styles.container}>
            <div className={styles.text}>
                <img src={privacyIcon} className={styles.icon} alt={"icon"} /> <br /><br />
                This icon will appear when a Vensa App or feature is asking to use your personal information 
                <br /><br />
            </div>
            <div className={styles.learnButton} onClick={showPopUp}>Learn More</div>.
        </div>
    );
}

export default PrivacyCard;