import React from 'react';
import PropTypes from 'prop-types';
import { compact } from 'lodash/fp';

import styles from './drugError.module.css';

const DrugError = ({ string }) => {
  const array = compact(string.split(/<|>|></));

  // eslint-disable-next-line max-len
  const medications = array.slice(1, array.length - 1).map(drug => (
    <div key={drug} className={styles.drug}>
      {drug}
    </div>
  ));
  return (
    <div className={styles.content}>
      <div>{array[0]}</div>
      <div className={styles.drugList}>{medications}</div>
      <div>{array[array.length - 1]}</div>
    </div>
  );
};

DrugError.propTypes = { string: PropTypes.string.isRequired };

export default DrugError;
