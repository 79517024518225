import { request, HTTP } from '../../../libs/newHttp';

export const getNearbyPharmaciesDefaultAPI = ({orgId} : {orgId: string} ) => () => 
request({
    method: HTTP.GET,
    url: `consumer/prescription/pharmacy_by_location?organisationId=${orgId}`,
})

export const get10NearbyPharmaciesAPI = ({lat, long, orgId} : {lat: string, long: string, orgId: string} ) => () => 
request({
    method: HTTP.GET,
    url: `consumer/prescription/pharmacy_by_location?lat=${lat}&long=${long}&organisationId=${orgId}`,
})

export const searchNearbyPharmacies = (name: string) => () => 
request({
    method: HTTP.GET,
    url: `consumer/prescription/pharmacy_by_name?name=${name}`,
})

export const deletePrescription = (prescriptionId: string) => () => 
request({
    method: HTTP.DELETE,
    url: `consumer/prescription/${prescriptionId}`,
})