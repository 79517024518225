import { Subtract } from "types/mappedTypes";
import FocusWithin, { FocusableProps, FocusCallback } from "components/core/FocusWithin";
import React, { ComponentType } from "react"

export const withFocusWithin = <P extends FocusableProps>(Component: ComponentType<P>) => {
    const FocusWrapper = ({onFocus, onBlur, ...rest}: Subtract<P, FocusableProps> & FocusCallback) => (
        <FocusWithin onFocus={onFocus} onBlur={onBlur}>
            {(focusProps) => <Component {...{...focusProps, ...rest } as P}/>}
        </FocusWithin>
    );
    return FocusWrapper;
}