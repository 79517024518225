import { put, takeEvery, call } from 'redux-saga/effects';

import Alert from '../../libs/Alert';
import { actions, actionTypes } from '../actions/actions';
import { actions as feedActions } from '../../HealthFeed/actions/actions';
import { actions as apptActions } from '../../AppointmentsPage/actions/actions';
import HttpLib from '../../libs/Http';
import { push } from '../../routes/utils';

const LogoutParserHttp = HttpLib(
  ({ status }) => {
    if (status === 401) {
      return {
        error:
          'Unauthorized logout, the user may have been expired a long time ago.',
        status,
        shouldAlert: false,
      };
    }
    return {
      error: 'Unexpected Error Occurred. Please Try Again.',
      shouldAlert: true,
    };
  },
  err => {
    if (err.shouldAlert) {
      Alert.error(err.error);
    }
  },
);

const api = token => LogoutParserHttp.post('consumer/logout', { token });

function* logout({ payload: { token, config } }) {
  window.localStorage.removeItem('token');
  try {
    yield call(api, token);
    yield put(feedActions.clearFeed());
    yield put(actions.logoutSuccess());
    yield put(apptActions.clearConfirmedAppointments());
    yield put(apptActions.clearPastAppointments());
    if (config) {
      push(config.path);
    } else if (!window.location.pathname.startsWith('/practice-profile/')) {
      window.location = `${window.location.origin}/login`;
    }
  } catch (err) {
    yield put(actions.logoutFailure(err));
  }
}

function* logoutSaga() {
  yield takeEvery(actionTypes.logout, logout);
}

export { logoutSaga };
