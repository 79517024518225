import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import bg from 'images/bgImage.png';
import { ReactComponent as Arrow } from 'svgs/blueRightCircleArrow.svg';
import { useAccountStore } from 'store/mobx/mobx';
import moment from 'moment';
import { goBack, history } from 'routes/utils'
import logoHeader from 'images/logoName.png';
import * as Styled  from './DesktopSignUpFlow.styled';
import DesktopSignUpFlowCardName from './components/DesktopSignUpFlowCardName';
import DesktopSignUpFlowCardDoB from './components/DesktopSignUpFlowCardDoB';
import DesktopSignUpFlowCardPassword from './components/DesktopSignUpFlowCardPassword';
import { ReactComponent as BackArrow } from 'svgs/leftlinearrow.svg';
import CtaButton from 'components/CtaButton';
import { isValidPassword } from 'libs/misc';
import { actions as loginActions } from "EntryPage/actions/actions";
import { go } from 'routes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Loading from 'components/loadingSpinner';

interface IDotProps {
    show: boolean;
}

const Dot = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: white;
    margin: 0rem 4px;
    opacity: ${(props: IDotProps) => props.show ? "1" : "0.5"};
`

const getDob = (date: string) => {
    return moment(date, ['YYYY-MM-DD', 'DD-MM-YYYY']).format('YYYY-MM-DD');
}


const isButtonActive = (state: number, password: string, privacyPolicy: boolean) => {
    if (state !== 2) { return true; }
    if (state === 2 && isValidPassword(password) && privacyPolicy) { return true; } 
    return false;
}

const DesktopSignUpFlow: React.FC<LinkDispatchProps>  = observer(({login, success, consumerUrl, refreshToken, expiryTime}) => {
    const store = useAccountStore();

    const [state, setState] = useState(0);
    const [buttonActive, setButtonActive] = useState(true);
    const [sentDetails, setSentDetails] = useState(store.signUpInvitationDetails.GivenName !== "");
    const [Email] = useState(store.signUpInvitationEmail ? store.signUpInvitationEmail  : "");
    const [Password, setPassword] = useState("");
    const [PolicyAccepted, setPolicyAccepted] = useState(false);

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let t = params.get('t');
    let e = params.get('e')

    const goNext = () => {
        if (state !== 2) {
            setState(state + 1)
        } else {
            if (!e) { return }
            store.registerWithSignUpToken({
                Token: store.signUpInvitationToken,
                Email: e,
                GivenName: store.signUpInvitationDetails.GivenName,
                FamilyName: store.signUpInvitationDetails.FamilyName,
                PreferredName: store.signUpInvitationDetails.PreferredName,
                MobileNumber: "",
                DateOfBirth: getDob(store.signUpInvitationDetails.DateOfBirth),
                Password: Password
            }, () => {
                if (e === null) { return; }
                login(e, Password);
            })
        }
    }
    const goBackFn = () => {
        if (state > 0) setState(state - 1);
        else goBack();
    }

    useEffect(() => {
        setButtonActive(isButtonActive(state, Password, PolicyAccepted));
        if (success) {
            window.open(
              consumerUrl + "login?token=" +
              refreshToken + "&expires=" + 
              expiryTime + "&email=" + 
              e + "&firstTime=false", 
              "_self"
            );
    }  
    })
    
    useEffect(() => {
        if (t && e) {
            if (!sentDetails) {
                store.getSignUpInvitationDetails(t, e, () =>{/* go.home()*/});
                setSentDetails(true);
            }
        } else {
            //go.home();
        }
    })

    return (
        <Styled.Container>
            <Styled.Logo src={logoHeader} alt="logo" />
            <Styled.ContentContainer>
                <Loading style={{fill: "#07A3C8"}} />
                <Styled.Card style={{height: state === 0 ? "455px" : state === 2 ? "520px" : "362px"}}>
                    <BackArrow style={{height: "18px", cursor: "pointer", position: "absolute", top: "2.5rem", left: "2.5rem"}} onClick={goBackFn} /> 
                    <Styled.FlexV>
                        {state === 0 && 
                            <DesktopSignUpFlowCardName 
                                firstName={store.signUpInvitationDetails.GivenName} 
                                surName={store.signUpInvitationDetails.FamilyName}
                                preferredName={store.signUpInvitationDetails.PreferredName}
                            />   
                        }
                        {state === 1 &&
                            <DesktopSignUpFlowCardDoB DoB={getDob(store.signUpInvitationDetails.DateOfBirth)} />
                        }
                        {state === 2 && e &&
                            <DesktopSignUpFlowCardPassword 
                                email={e} 
                                setPassword={(password: string) => setPassword(password)}
                                password={Password}
                                policy={PolicyAccepted}
                                setPolicy={(accepted: boolean) => setPolicyAccepted(accepted)}
                            />
                        }
                        <Styled.FlexH style={{marginTop: "3rem"}}>
                            {state === 0 &&
                                <Styled.FlexV style={{position: "absolute", bottom: "2.5rem"}}>
                                    <Styled.Label style={{margin: "0rem"}}>Have an account?</Styled.Label>
                                    <Styled.LogIn onClick={() => window.open("https://login.vensa.com", "_self")}>Log In</Styled.LogIn>
                                </Styled.FlexV>
                            }
                        </Styled.FlexH>
                    </Styled.FlexV>
                    <CtaButton 
                        onClick={goNext}
                        isProcessing={store.loading}
                        active={buttonActive}
                        style={{position: "absolute", bottom: "2rem", right: "2.5rem"}}
                    />
                </Styled.Card>
                <Styled.DotsContainer>
                    <Dot show={state === 0} />
                    <Dot show={state === 1} />
                    <Dot show={state === 2} />
                </Styled.DotsContainer>
            </Styled.ContentContainer>
        </Styled.Container>
    )
})

interface LinkDispatchProps {
    login: (Email: string, Password: string) => void;
    success: boolean;
    consumerUrl: string;
    refreshToken: string;
    expiryTime: string;
    email: string;
    loading: boolean;
}

const mapStateToProps = (state: any) => ({
    success: state.loginReducer.success,
    consumerUrl: state.loginReducer.consumerUrl,
    refreshToken: state.loginReducer.refreshToken,
    expiryTime: state.loginReducer.expiryTime,
    email: state.loginReducer.email,
    loading: state.loginReducer.isFetching
})
const mapDispatchToProps = (dispatch: any) => ({
    login: bindActionCreators<any, any>(loginActions.login, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(DesktopSignUpFlow);