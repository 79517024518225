import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import miniMobile from '../../../images/miniMobile.png';
import { ReactComponent as DesktopMobile } from '../../../svgs/mainMobile.svg';
import CtaButton from '../../../components/CtaButton/index';
import styles from './mobileInput.module.css';
import { isValidMobile } from '../../../libs/ValidBasicInfo';
import { isDesktop } from 'config';

class MobileInput extends Component {
  static propTypes = {
    switcheroo: PropTypes.func.isRequired,
    sendCode: PropTypes.func.isRequired,
    setMobile: PropTypes.func.isRequired,
    mobile: PropTypes.string.isRequired,
    onStateChange: PropTypes.func,
    cancel: PropTypes.func,
    saveMobile: PropTypes.func.isRequired,
    countdownStartTime: PropTypes.instanceOf(moment).isRequired,
    milliSecondsToWait: PropTypes.number.isRequired,
  };
  state = {
    mobile: this.props.mobile,
    mobileError: null,
  };
  onLabelClick = inputName => () => {
    this[inputName].focus();
  };
  getMilliSecondsRemaining() {
    return (
      (this.props.countdownStartTime.unix() +
        this.props.milliSecondsToWait / 1000 -
        moment().unix()) *
      1000
    );
  }

  handleChange = e => {
    const value = e.target.value;
    const isNum = /^[\d+]/.test(value.toString())
    if (isNum || !e.target.value) {
      this.changeState({ 
        mobile: e.target.value
      })
    }
  };

  clearMobileError = () => {
    this.changeState({ mobileError: null });
  };
  changeState = newState => {
    this.props.setMobile(newState.mobile);
    this.setState(
      newState,
      () => this.props.onStateChange && this.props.onStateChange(),
    );
  };
  isValidMobile = () => isValidMobile(this.state.mobile, true);
  handleClick = () => {
    if (!this.isValidMobile()) {
      this.changeState({
        mobileError: 'Invalid Mobile, Please enter a valid NZ mobile number.',
      });
    } else {

      this.props.sendCode(this.state.mobile);
      
      this.props.saveMobile(this.state.mobile);
      this.props.switcheroo();
    }
  };
  render() {
    const { cancel } = this.props;
    const { mobile } = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.phoneContainer}>
          <DesktopMobile className={styles.phone} style={{display: "block", margin: "auto"}}/>
        </div>
        <div className={styles.text} style={{margin: "1rem 1rem", textAlign: isDesktop() ? "center" : "left"}}>
          {isDesktop() ? "Enter your mobile number" : "Mobile number:"}</div>
        {/*<div className={styles.label} style={{textAlign: "left"}}>Mobile number</div>*/}
        <div className={styles.inputContainer}>
          <img src={miniMobile} alt="mobile" className={styles.icon} />
          <input
            className={styles.input}
            type="tel"
            ref={c => {
              this.mobileInput = c;
            }}
            value={mobile}
            onChange={this.handleChange}
            onFocus={this.clearMobileError}
            maxLength={14}
          />
        </div>
        <div style={{fontSize: "13px", marginBottom: "2rem"}}>
            A verification code will be sent to this number.
        </div>
        <div className={styles.buttonContainer}>
          <button className={styles.cancel} onClick={cancel}>
            Cancel
          </button>
          <CtaButton
            className={styles.button}
            active={this.isValidMobile()}
            onClick={this.handleClick}
          />
        </div>
      </div>
    );
  }
}

export default MobileInput;
