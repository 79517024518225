import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';

import { ReactComponent as Cross } from '../../../svgs/close.svg';
import MedicationList from './MedicationList/index';
import styles from './searchMedsMobile.module.css';

const SearchMedsMobile = ({ toggleList, isOpen, addMed, first }) => (
  <CSSTransitionGroup
    component="div"
    transitionName={{
      leave: styles.leave,
      leaveActive: styles.leaveActive,
      enter: styles.enter,
      enterActive: styles.enterActive,
    }}
    transitionLeave={isOpen && first}
    transitionEnter={isOpen && first}
    transitionLeaveTimeout={200}
    transitionEnterTimeout={300}
  >
    {isOpen ? (
      <MedicationList key="1" close={toggleList} addMedication={addMed} />
    ) : (
      <AddMedButton key="2" toggle={toggleList} first={first} />
    )}
  </CSSTransitionGroup>
);

const AddMedButton = ({ toggle, first }) =>
  first ? (
    <InitialButton toggle={toggle} />
  ) : (
    <div onClick={toggle} className={styles.addBox}>
      <Cross className={styles.add} />
      <div className={styles.button}>Add another item</div>
    </div>
  );

const InitialButton = ({ toggle }) => (
  <div onClick={toggle} className={styles.initialBox}>
    <div className={styles.input}>Type the medication / item</div>
    <div className={styles.plusContainer}>
      <Cross className={styles.plus} />
    </div>
  </div>
);

SearchMedsMobile.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleList: PropTypes.func.isRequired,
  addMed: PropTypes.func.isRequired,
  first: PropTypes.bool.isRequired,
};
AddMedButton.propTypes = {
  toggle: PropTypes.func.isRequired,
  first: PropTypes.bool.isRequired,
};
InitialButton.propTypes = {
  toggle: PropTypes.func.isRequired,
};

export default SearchMedsMobile;
