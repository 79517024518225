import React from 'react';
import PropTypes from 'prop-types';
import styles from './confirmCancelPopUp.module.css';

const ConfirmCancelPopUp = ({ noFn, yesFn }) => (
  <div className={styles.container}>
    <div className={styles.title}>
      Are you sure you want to cancel this request?
    </div>
    <div className={styles.buttons}>
      <button className={styles.no} onClick={noFn}>
        No
      </button>
      <button
        className={styles.yes}
        onClick={e => {
          e.preventDefault();
          yesFn(e);
          // setTimeout(() => {
          //   window.location.reload(true);
          // }, 500);
        }}
      >
        Yes
      </button>
    </div>
  </div>
);

ConfirmCancelPopUp.propTypes = {
  noFn: PropTypes.func.isRequired,
  yesFn: PropTypes.func.isRequired,
};

export default ConfirmCancelPopUp;
