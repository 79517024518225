import { baseSpacing } from './base';

export const DEFAULT_SIZE = {
  xxSmall: `${baseSpacing * 3}px`, // 24
  xSmall: `${baseSpacing * 4.5}px`, // 36
  small: `${baseSpacing * 6}px`, // 48
  medium: `${baseSpacing * 9}px`, // 72
  large: `${baseSpacing * 12}px`, // 96
  xLarge: `${baseSpacing * 18}px`, // 144
  xxLarge: `${baseSpacing * 32}px`, // 256
  xxxLarge: `${baseSpacing * 48}px`, // 384
  xxxxLarge: `${baseSpacing * 96}px`, // 768
};
