import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { ReactComponent as Back } from 'svgs/circleRightArrow.svg';
import { ReactComponent as Lock } from 'svgs/blueLock.svg';
import PinControl from './PinControl';

const Container = styled.div`
    height: 630px;
    width: 534px;
    background-color: white;
    margin: auto;
    margin-left: 7px;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 1px 1px 3px lightgray;
    padding: 0px 25px;
`
const Text = styled.div`
    text-align: center;
    color: #07A3C8;
    font-size: 17px;
`
interface Props {
    verify: boolean;
    goNext: () => void;
    goBack: () => void;
    ifGuessable: () => void;
    length: number;
}

const PinCode: React.FC<Props> = observer(({verify, ifGuessable, goNext, goBack, length}) => {
    return (
        <Container>
            <Back onClick={() => goBack()} style={{
                transform: "rotate(180deg)",
                cursor: "pointer",
                position: "absolute",
                top: "30px",
                left: "30px",
                height: "48px",
                width: "48px",
            }}/>
            <Lock style={{margin: "4rem auto", marginBottom: "2rem"}} />
            <Text style={{marginBottom: "50px"}}>{!verify ? "Enter your security PIN code. Numbers only" : "Verify your new PIN. Numbers only"}</Text>
            <PinControl length={length} ifGuessable={ifGuessable} verify={verify} goNext={() => goNext()} goBack={() => goBack()} />
        </Container>
    )
})

export default PinCode;