import React from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { BaseWhiteNotification } from './BaseWhiteNotification';

export const PrescriptionPrivacyCard: React.FC = observer(() => {
  const { pathname } = useLocation();

  if (!pathname.startsWith('/request-script')) return null;

  return (
    <BaseWhiteNotification
      paragraph={`To safely complete your prescription Vensa will access your account information, medication data stored at your facility, and government data relatied to your past medications.`}
      snooze={true}
    />
  );
});
