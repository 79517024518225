import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from '../../components/UIContainer/actions/headerActions';
import UIContainer from '../../components/UIContainer';
import * as Styled from './DesktopPaymentPage.styled';
import { useComponentDidMount } from '../../libs/newHttp';
import RouteBar from '../../components/DesktopComponents/RouteBar';
import PaymentMethod from './subRoutes/PaymentMethod';
import TransactionHistory from './subRoutes/TransactionHistory';
import styled from 'styled-components';
import PrivacyBanner from './components/PrivacyBanner';
import PrivacyModal from './components/PrivacyModal';

const Container = styled.div`
    background-color: #F4F4F4;
    width: 100%;
    height: fit-content;
    padding: 0.6rem 2rem;
`

const TabContainer = styled.div`
    width: fit-content;
    display: flex;
    margin: 0 auto;
`
const Tab = styled.div`
    padding: 0rem 1rem;
    font-size: 14px;
    cursor: pointer;
`

interface TabOption {
    id: number;
    name: string;
    onClick: () => void;
}

interface Props {
    selectedTab: number;
    tabs: TabOption[];
}

const TabBar: React.FC<Props> = observer(({selectedTab, tabs}) => {
    return (
        <Container>
            <TabContainer>
                {tabs.map((tab) => {
                        return (
                            <Tab 
                                key={tab.id}
                                onClick={tab.onClick}
                                style={{
                                    borderRight: tabs.length - 1 !== tab.id ? "2px solid #DADADA" : "none",
                                    color: tab.id === selectedTab ? "#07A3C8" : "#939393"
                            }}>
                                {tab.name}
                            </Tab>
                    )}
                )}
            </TabContainer>
        </Container>
    );
});

const DesktopPaymentsPage: React.FC<LinkDispatchProps> = observer(({setSideHeaderItem}) => {
    useComponentDidMount(() => setSideHeaderItem("payments"))
    const [tab, setTab] = useState(0)
    const [subSection, setSubSection] = useState(0)
    const [route, setRoute] = useState("Payment Method")
    const [showPrivacyBanner, setShowPrivacyBanner] = useState(true)
    const [showPrivacyModal, setShowPrivacyModal] = useState(false);

    const Tabs = [
        {
            id: 0,
            name: "Payment Method",
            onClick: () => {
                setTab(0);
                setRoute("Payment Method");
                setSubSection(0);
            }
        },
        //TODO: Hiding for now until design has been completed
        // {
        //     id: 1,
        //     name: "Transaction History",
        //     onClick: () => { 
        //         setTab(1);
        //         setRoute("Transaction History");
        //         setSubSection(0);
        //     }
        // },
    ]
    return (
        <UIContainer>
            <Styled.Container>
                {showPrivacyBanner && <PrivacyBanner termsOnClick={() => setShowPrivacyModal(true)} closeOnClick={() => setShowPrivacyBanner(false)} />}
                <RouteBar 
                    lowLevel={route} 
                    lowLink={() => setSubSection(0)} 
                />
                <TabBar selectedTab={tab} tabs={Tabs}></TabBar>
                {showPrivacyModal && <PrivacyModal onClose={() => setShowPrivacyModal(false)} />}
                { tab === 0 && <PaymentMethod title={"Saved Payment Methods"} section={subSection} changeSection={(id) => setSubSection(id)} /> }
                { tab === 1 && <TransactionHistory /> }
            </Styled.Container>
        </UIContainer>
    );
});

interface LinkDispatchProps {
    setSideHeaderItem: (item: string) => void;
}

const mapDispatchToProps = (dispatch: any) => ({
    setSideHeaderItem: bindActionCreators<any, any>(actions.setSideHeaderItem, dispatch),
})

export default connect(null, mapDispatchToProps)(DesktopPaymentsPage);