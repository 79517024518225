import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Image, Text } from 'components/vensaComponents';
import { IHealthTransactionItem } from '../../../store/mobx/paymentStore/paymentStore.type';
import lightScript from 'svgs/lightScript.svg';
import moment from 'moment';

export const getDateText = (dateStr: string) =>
  moment(dateStr).format('D/MM/YY');
const Space: React.FC = () => <span>&nbsp;&nbsp;</span>;

export const formatPrice = (price: number) => {
  if (!price) return '$ 0';
  const p = Math.abs(price).toString();
  const dollars = p.slice(0, -2);
  const cents = p.slice(-2);
  return `$ ${dollars}.${cents}`;
};

const getColorForTransactionType = (transactionType: string) => {
  return transactionType?.toLowerCase() === "payment"
      ? "#44D375" //green
      : "#ff8c00" //orange
}

export const HistoryListItem: React.FC<IHealthTransactionItem> = observer(
  ({
    Id,
    Type,
    Item: { AmountInCent, FirstName, LastName, Description, OrganisationName, DateTime },
  }) => {
    return (
      <Box
        direction="column"
        bg="white"
        boxShadow={7}
        borderRadius={0}
        p={3}
        gap="10px"
      >
        <Box fontSize="13px" justifyContent="between" alignItems="center">
          <Box bg={getColorForTransactionType(Type)} color="white" px={3} py={1} borderRadius="18px">
            <Text>{Type}</Text>
            <Space />
            <Text fontWeight={700}>{` NZ$ ${(Math.abs(AmountInCent)/100.0).toFixed(2)}`}</Text>
          </Box>
          <Text>{getDateText(DateTime)}</Text>
        </Box>
        <Box alignItems="center">
          <Image height="36px" src={lightScript} />
          <Box direction="column">
            <div style={{marginLeft: "10px", marginTop: "10px"}}>{`${FirstName} ${LastName}`}</div>
            <div style={{fontWeight: 100, fontSize: "13px", marginLeft: "10px"}}>{Description}</div>
            <div style={{fontWeight: 100, fontSize: "13px", marginLeft: "10px"}}>{OrganisationName}</div>
          </Box>
        </Box>
      </Box>
    );
  },
);
