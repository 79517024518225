import React from 'react';
import PropTypes from 'prop-types';

import styles from './confirmation.module.css';
import { ReactComponent as Dart } from '../../../../svgs/dart.svg';
import { isDesktop } from '../../../../config';

const Confirmation = ({
  goToLogin,
  email,
  text,
  iconStyles,
  buttonText = 'OK',
}) => (
  <div className={styles.container}>
    <div className={styles.innerContainer}>
      <Dart className={iconStyles} />
      {isDesktop() ? <div className={styles.sweetAs}>Sweet as!</div> : null}
      <div className={styles.confirmation}>
        <div>{text}</div>
        {email ? <span className={styles.email}>{email}</span> : null}
      </div>
    </div>
    <div className={styles.button} onClick={goToLogin}>
      <span className={styles.ok}>{buttonText}</span>
    </div>
  </div>
);
Confirmation.propTypes = {
  goToLogin: PropTypes.func.isRequired,
  // this is a react class
  text: PropTypes.string.isRequired,
  iconStyles: PropTypes.string,
  email: PropTypes.string,
  buttonText: PropTypes.string,
};

export default Confirmation;
