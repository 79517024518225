import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import rtfToHtml from '@iarna/rtf-to-html';
import { useState } from 'react';
import { ReactComponent as Empty } from 'svgs/patientSilhouette.svg';
import { CommentType, IClinicNote, ISummaryItem } from 'store/mobx/myHealthStore/myHealthStore.type';
import { observer } from 'mobx-react-lite';
import { BaseDetailSectionBottom } from 'MyHealthPage/components/BaseDetailSection';
import { useAccountStore, useMyHealthStore } from 'store/mobx/mobx';
import { AddComment } from 'MyHealthPage/components/AddComment';

const Container = styled.div`
    height: 100%;
    padding: 0rem 2rem;
    padding-top: 1.5rem;
    width: 100%;
`
const Header = styled.div`
    display: flex;
    flex-direction:  column;
    position: relative;
    width: 100%;
`
const Title = styled.div`
    font-size: 15px;
    font-weight: bold;
    padding-bottom: 0;
    text-decoration-color: #DADADA;
    margin-bottom: 0.3rem;
`
const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid #F0F0F0;
    margin: 1rem 0;
    margin-top: 0.5rem;
`
const Result = styled.div`
    font-weight: 300;
`
const Subheading = styled.div`
    font-style: italic;
    font-size: 13px;
    margin: 2px 0;
    color: #939393;
`
const Pic = styled.img`
    position: absolute;
    left: 0; 
    right: 0;
    top: 0;
    bottom: 0; 
    margin: auto; 
    width: 30px; 
`
const NameOfPatient = styled.div`
    font-size: 14px;
    color: #6C6C6C;
    width: 100%;
    text-align: center;
`
interface IProps {
    item: IClinicNote
}

const AddCommentContainer = styled.div`
    color: #07A3C8;
    font-size: 14px;
    cursor: pointer;
    padding: 1rem 0rem;
    width: 100%;
    margin: auto;
`

const SummarySection: React.FC<any> = observer(
    ({ ClinicalFinding, Notes }) => {
      if (!ClinicalFinding || !Notes) return null;
      return <BaseDetailSectionBottom bottomTitle={ClinicalFinding} bottomText={Notes} />;
    },
  );
const ClinicalNoteOpen: React.FC<IProps> = ({item}) => {
    const [subjective, setSubjective] = useState("");
    const [objective, setObjective] = useState("");
    const { FirstName, LastName } = useAccountStore().profile;
    const myHealthStore = useMyHealthStore();
  
    // Check if RTF format then convert to html, otherwise use the string given
    if (item.SubjectiveNotes.startsWith("{")) rtfToHtml.fromString(item.SubjectiveNotes, (err: any, html: any) => { setSubjective(html) })
    else if (subjective !== item.SubjectiveNotes) setSubjective(item.SubjectiveNotes);
  
    if (item.ObjectiveNotes.startsWith("{"))  rtfToHtml.fromString(item.ObjectiveNotes, (err: any, html: any) => { setObjective(html) })
    else if (objective !== item.ObjectiveNotes) setObjective(item.ObjectiveNotes);

    return (
        <Container>
            <Header>
                <Title>Clinical Notes</Title>
                <Subheading>Recorded {item.ProviderName ? "by " + item.ProviderName : ""} on {moment(item.DateTime).format("DD/MM/YYYY")}</Subheading>
            </Header>
            <Line />
            <NameOfPatient>{FirstName} {LastName}</NameOfPatient>
            <NameOfPatient>{item.ProviderName}</NameOfPatient>
            <NameOfPatient style={{ fontStyle: "italic", marginBottom: "1rem" }}>{item.OrganisationName}</NameOfPatient>

            {(subjective !== "" || objective !== "" || item.ClinicalNotesSummaries.length > 0) &&
                "Clinical Notes Summary:"
            }
            <Result>

                <div dangerouslySetInnerHTML={{__html: subjective}} />
                <div dangerouslySetInnerHTML={{__html: objective}} />
                {item.ClinicalNotesSummaries.map(item => 
                    <SummarySection {...item} />
                )}
            </Result>
            <Line style={{margin: "0"}}/>
            <AddCommentContainer>
                <AddComment
                    onSubmit={myHealthStore.postNewComment.bind(myHealthStore)}
                    refreshList={myHealthStore.getClinicNotes.bind(myHealthStore)}
                    commentType={CommentType.DoctorNotes}
                    itemToShow={item}
                    itemId={item.Id}
                    label="+ Add comment for yourself"
                />
            </AddCommentContainer>
        </Container>
    )
}

export default ClinicalNoteOpen;