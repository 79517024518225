import { put, takeEvery, call, take } from 'redux-saga/effects';

import { actionTypes, actions } from '../actions/actions';
import {
  actions as loginActions,
  actionTypes as loginActionTypes,
} from '../../../EntryPage/actions/actions';
import { GenericParserHttp } from '../../../libs/Http';

// @TODO extract into common function
const api = user =>
  GenericParserHttp.post('consumer/register', { data: user }).then(
    ({ body }) => body,
  );

function* autoRegisterUser(action) {
  const user = {
    SessionId: action.payload.sessionId,
    Username: action.payload.email,
    Password: action.payload.password,
  };
  try {
    yield call(api, user);
    yield put(loginActions.login(user.Username, user.Password));
    yield take(loginActionTypes.loginSuccess);
    yield put(actions.autoRegisterUserSuccess());
  } catch (err) {
    yield put(actions.autoRegisterUserFailure(err));
  }
}

function* autoRegisterUserSaga() {
  yield takeEvery(actionTypes.autoRegisterUser, autoRegisterUser);
}

export { autoRegisterUserSaga };
