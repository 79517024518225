import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Text } from 'components/vensaComponents';
import { useMyHealthStore } from 'store/mobx/mobx';

const Icon: React.FC = () => (
  <Box
    width="18px"
    height="18px"
    borderRadius="50%"
    bg="white"
    border="1px solid"
    borderColor="primary"
    justifyContent="center"
    alignItems="center"
    ml="auto"
  >
    <Text color="primary">!</Text>
  </Box>
);

export const BaseSectionItem: React.FC<{
  label: string;
  description: string;
  displayIcon?: boolean;
}> = observer(({ label, displayIcon = true, description }) => {
  const myHealthStore = useMyHealthStore();

  return (
    <Box
      gap="16px"
      flexWrap="wrap"
      alignItems="center"
      cursor="pointer"
      ml={3}
      mr={3}
      onClick={label === "No records found" ? () => {} : () => {
        myHealthStore.setHealthNavigator({
          keywords: label,
          isOpen: true,
        });
      }}
    >
      <div style={{display: "flex", alignItems: "center", width: "100%"}}>
        <Text width={"100%"} fontSize={"17px"} color="primary">{label}</Text>
        {displayIcon && <Icon />}
      </div>
    </Box>
  );
});
