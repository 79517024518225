import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import UIContainer from '../../components/UIContainer';
import RouteBar from '../../components/DesktopComponents/RouteBar';
import { go } from '../../routes';
import TabBar from '../../components/DesktopComponents/TabBar';
import VerifyAtPracticeCard from './components/VerifyAtPracticeCard';
import { usePracticesStore, useAccountStore } from '../../store/mobx/mobx';
import { useComponentDidMount } from '../../libs/newHttp';
import IdentityStatusLabel from './components/IdentityStatusLabel';
import PracticeSelection from './components/PracticeSelection';
import PracticeSearch from './components/PracticeSearch';
import ViewIdentityRequest from './components/ViewIdentityRequest';
import PopupNoIdentity from 'components/DesktopComponents/PopupNoIdentity';
import Banner from 'components/DesktopComponents/PartialBanner';
import { ReactComponent as Check } from 'svgs/whiteTick.svg'
import ChecklistBanner from 'Identity/DesktopIdentityPage/components/IdentityChecklistBanner';
import LearnMorePopUp from 'components/LearnMorePopUp/LearnMorePopUp';
import { DEFAULT_GUID } from 'common/constants';
//import HalfBanner from 'components/DesktopComponents/HalfBanner';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #F9F9F9;
    overflow: scroll;
`
const InnerContainer = styled.div`
    width: 527px;
    margin 1rem auto;
`
const Title = styled.div`
    text-transform: uppercase;
    font-size: 13px;
    margin-top: 1rem;
    text-align: left;
`
const Text = styled.div`
    font-size: 13px;
    margin-top: 1rem;
    text-align: left;
`
const Button = styled.div`
    width: 184px;
    height: 50px;
    border-radius: 25px;
    color: white;
    background-color: #07A3C8;
    background-color: #DADADA;
    margin: 1rem 0rem;
    font-size: 13px;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 1px 2px 6px lightgray;
    padding: 1rem 0rem;
    position: absolute;
    top: -20px;
    right: -250px;
`
interface IPractice {
    OrganisationId: string;
    OrganisationName: string;
    Address: string;
    OnlineVerificationEnabled: boolean;
    PictureUrl: string;
    IdentityRequest: {
        Status: number;
        RequestDate: string;
    } | null;
    Phone: string;
    Pictures: string[];
}

const DesktopIdentityPage: React.FC = observer(() => {
    const validStore = usePracticesStore();
    const accountStore = useAccountStore();

    const [state, setState] = React.useState(0);
    const [showLabel, setShowLabel] = React.useState(!accountStore.profile.IdentityVerified && accountStore.identityRequests.length === 0)
    const [popup, setPopup] = React.useState(false);
    const [popup2, setPopup2] = React.useState(false);
    const [identityPopup, setIdentityPopup] = React.useState(false);
    const [showCard, setShowCard] = React.useState(true)
    const [practiceView, setPracticeView] = React.useState<IPractice>({
        OrganisationId: "",
        OrganisationName: "",
        Address: "",
        OnlineVerificationEnabled: false,
        PictureUrl: "",
        IdentityRequest: {
            Status: 3,
            RequestDate: "",
        },
        Phone: "",
        Pictures: []
    })

    useComponentDidMount(() => {
        accountStore.getIdentityRequestsStatus();
        // accountStore.getProfile(() => {
        // if (!accountStore.profile.MobileVerified || !accountStore.profile.EmailVerified) {
		// 	setPopup2(true);
		// }
        // })
        validStore.getUserPractices();
        validStore.getAllPractices();
    });

    useEffect(() => {
        setPopup2(accountStore.profile.Id !== DEFAULT_GUID && (!accountStore.profile.MobileVerified || !accountStore.profile.EmailVerified))
    },[
        accountStore.profile.MobileVerified, 
        accountStore.profile.EmailVerified,
        accountStore.profile.Id
    ]);

    return (
            <UIContainer isFetching={accountStore.loading || validStore.practiceDetailsLoadingStatus === "LOADING" || accountStore.loadingRequests}>
                <Container>         
                    <RouteBar 
                        lowLevel={"Identity"}
                        lowLink={() => go.identity()}
                    />
                    <TabBar type={0} id={1} />
                    {!accountStore.profile.AcknowledgeOfAdditionalSecuritySettings && showCard &&
                        <ChecklistBanner hide={() => setShowCard(false)} onClick={() => setIdentityPopup(true)} />
					}

                    {identityPopup &&
                        <LearnMorePopUp type={"IDENTITY_INFO"} hide={() => setIdentityPopup(false)} />
                    }

                    {popup2 && <PopupNoIdentity mobile={true} />}
                    {popup && <PracticeSearch userHasPractices={validStore.userPractices.Practices.length !== 0} onClose={() => setPopup(false)} />}

                    <InnerContainer data-test="component-desktop-identity-page">
                        <Title>Identity Status</Title>
                        <div style={{display: "flex", flexDirection: "row", width: "100%", position: "relative"}}>
                            <Text>
                                Local identity verification is free. You must verify your identity with each medical centre you wish to use.
                                <span onClick={() => setIdentityPopup(true)} style={{color: "#07A3C8", cursor: "pointer"}}> Learn more.</span>
                            </Text>
                            {accountStore.identityRequests.length !== 0 && state === 0 && <Button style={{display: "flex", flexDirection: "column"}} /*onClick={() => setPopup(true)}*/>
                                + Verify with New Practice
                                <div style={{ color: "#6C6C6C"}}>Coming Soon</div>
                            </Button> }
                        </div>
                        { state === 0 && accountStore.identityRequests && 
                            <div>
                                {showLabel && 
                                    <IdentityStatusLabel 
                                        identityVerified={accountStore.profile.IdentityVerified} 
                                        patientHasPractices={accountStore.identityRequests.length !== 0} 
                                        onClick={() => setState(1)} 
                                    />
                                }
                                {accountStore.identityRequests.length !== 0 && 
                                    <PracticeSelection 
                                        onViewClick={(practice: IPractice) => {
                                            setState(2);
                                            setPracticeView(practice)
                                        }}
                                        showLabel={() => setShowLabel(true)} 
                                        practices={accountStore.identityRequests} 
                                        onGoNext={() => setState(1)}
                                    />
                                }
                            </div>
                        }
                        { state === 1 && <VerifyAtPracticeCard goBack={() => setState(0)} goToPin={accountStore.identityRequests.length !== 0} /> }
                        { state === 2 && <ViewIdentityRequest goBack={() => setState(0)} practice={practiceView}/>}
                    </InnerContainer>
                </Container>
            </UIContainer>
    )
})

export default DesktopIdentityPage;