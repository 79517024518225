import React from 'react';

import styles from './progressCard.module.css'


const ProgressCard = (props) => {
    return(
        <div className={styles.container}>
            <img className={styles.image} src={props.icon} alt={"icon"} />
            {props.progressBar &&
                <img className={styles.image} src={props.progressBar} alt={"bar"} />
            }
            <div className={props.padding ? styles.textPadding : styles.text}>
                {props.title && <div style={{margin: "0.5rem 0rem", fontSize: "21px"}}><b>{props.title}</b><br /></div>}
                {props.text}
            </div>
        </div>
    )
}

export default ProgressCard;