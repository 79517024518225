import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
	Box as NewBox,
  } from 'components/vensaComponents/index';
import { useVScriptStore } from 'store/mobx/mobx';
import PharmacyListView from './PharmacyListView';
import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';

const PharmInput = styled.input`
  width: 100%;
  height: 2rem;
  padding: 0.5rem;
  border: 1px solid #DADADA;
  border-radius: 3px;
  &&::placeholder {
      color: lightgray;
  }
`;
const SearchBox = styled.div`
  box-shadow: 2px 2px 2.5px 2px #d6d6d6;
  width: 100%;
  background: white;
  position: absolute;
  z-index: 10;
`;

interface IProps {
    onSelect: (pharm: any) => {}
}

const PharmacySearch: React.FC<IProps> = observer(({ onSelect }) => {
    const [searchInput, setSearchInput] = useState("");
    const [showPharmList, setShowPharmList] = useState(false);
    const store = useVScriptStore();

    const allPharmacies = store.data.Pharmacies;
    const curPharmacy = store.selectedPharmacy;
    const [pharmacies, setPharmacies] = useState(allPharmacies);
    const [searchedPharmacies, setSearchedPharmacies] = useState(allPharmacies);
    const [selectedPharmacy, setSelectedPharmacy] = useState(curPharmacy);

    const getPharmacies = (e: any) => {
        const value = e.target.value;
        store.searchNearbyPharmacies(value, (pharmacies) => setSearchedPharmacies(pharmacies));
        setShowPharmList(true);
        setSearchInput(value);
    }
    
    return (
        <div style={{marginBottom: "0.5rem"}}>
            <PharmInput
                placeholder={'Type your preferred pharmacy for collection'}
                onFocus={getPharmacies}
                value={searchInput}
                //onBlur={() => setShowPharmList(false)}
                onChange={getPharmacies}
                autoFocus
                style={{
                    background: '',
                    fontSize: '13px',
                    fontFamily: 'Roboto',
                    fontStyle: 'italic',
                    borderColor:'#DADADA',
                    outline: 'none',
                }}
            />

            {showPharmList && searchedPharmacies.slice.length > 0 && (
              <SearchBox>
                <PharmacyListView 
                    onClick={(pharm) => {
                        setSearchInput(pharm.Name);
                        setShowPharmList(false);
                        onSelect(pharm);
                    }}
                    list={searchedPharmacies}
                />
              </SearchBox>
            )}
        </div>
    )
})

export default PharmacySearch;