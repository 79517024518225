import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import is from 'is_js';

import { ReactComponent as WavingKiwi } from '../../../svgs/wavingKiwi.svg';
import { isIE } from '../../../libs/BrowserDetection';
import styles from './makeAppointmentCard.module.css';
import { go } from 'routes';

const MakeAppointmentCard = ({ onClick }) => (
  <div className={styles.container}>
    <WavingKiwi className={styles.kiwi} />
    <div className={classnames(styles.tenaKoe, { [styles.ie]: isIE() })}>
      T&#275;n&#257; koe!
    </div>
    <div className={classnames(styles.what, { [styles.ie]: isIE() })}>What would you llike to do?</div>
    <button className={styles.button} onClick={onClick}>
      Make an Appointment
    </button>
    <button className={styles.button} onClick={go.prescriptions}>
     Order Repeat Script
    </button>
  </div>
);

MakeAppointmentCard.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default MakeAppointmentCard;
