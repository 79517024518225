import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { ReactComponent as BG } from 'svgs/desktopKiwiBg.svg';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: visible;
    width: 100%;
    margin: 2rem 0rem;
    margin-top: 0px;
`
const Top = styled.div`
    padding: 2rem;
    height: 360px;
    display: flex;
    flex-direction: row;
    padding-left: 3rem;
    position: relative;
`
const Title = styled.div`
    font-size: 27px;
    margin-bottom: 1rem;
`
const Text = styled.div`
    font-size: 20px;
    margin: 1rem 0rem;
    white-space: pre-wrap;
    color: #939393;
`
const Banner = styled.div`
    background-color: #346672;
    height: 100px;
    width: 100%;
    position: relative;
    color: white;
    z-index: 10;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2rem;
`
const TopInner = styled.div`
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
`
const BannerHeading = styled.div`
    font-size: 17px;
    font-weight: bold;
    margin: 0.1rem 0rem;
`
const BannerText = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    flex-grow: 2;
    width: 100%;
`
const BannerSub = styled.div`
    font-size: 13px;
    margin: 0.1rem 0rem;
`
const BannerButton = styled.div`
    width: 110px;
    background-color: white;
    color: #346672;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    padding: 0.4rem;
    border-radius: 5px;
    font-weight: 600;
    box-shadow: 0px 3px 1px black;
    cursor: pointer;
`
const Ratio = styled.div`
    color: white;
    font-weight: 600;
    margin: 0rem 1rem 0.3rem 1rem;
    font-size: 15px;
    text-align: center;
`
const Link = styled.a`
    color: white;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    margin: 1rem 4rem;
    font-weight: 400;
`

const BannerButtonContainer = styled.div`
    flex-grow: 2;
`
interface Props {
    title: string;
    text: string;
    bannerTitle?: string;
    bannerText?: string;
    buttonText?: string;
    onClick?: () => void;
    current?: number;
    total?: number;
    link?: () => void;
    linkText?: string;
}

// Full banner at the top of the desktop screen the optional paramateres are for the lower blue banner and if
// not supplied they will not appear in the blue banner.
const FullBanner: React.FC<Props> = observer(({title, text, bannerTitle, bannerText, buttonText, onClick, current, total, link, linkText}) => {
    return (
        <Container>
            <Top>
                <TopInner>
                    <Title>{title}</Title>
                    <Text>{text}</Text>
                </TopInner>
                <BG style={{ position: "absolute", top: "0", right: "0", transform: "translate(233px, -163px)" }} />
            </Top>
            <Banner>
                {bannerTitle && bannerText &&
                    <BannerText>
                        {bannerTitle && <BannerHeading>{bannerTitle}</BannerHeading>}
                        {bannerText && <BannerSub>{bannerText}</BannerSub>}
                    </BannerText>
                }

                <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "85%"}}>
                    {linkText && <Link onClick={link}>{linkText}</Link>}
                    
                    {buttonText &&
                        <div style={{display: "flex", flexDirection: "column"}}>
                            {/*<Ratio>({current}/{total})</Ratio>*/}
                            <BannerButtonContainer>
                                <BannerButton onClick={onClick}>{buttonText}</BannerButton> 
                            </BannerButtonContainer>
                        </div> 
                    }
                </div>              
            </Banner>
        </Container>
    );
});

export default FullBanner;