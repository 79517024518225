import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import classnames from 'classnames';

import { actions } from './actions/actions';
import styles from './processing.module.css';
import Confirmed from './components/Confirmed/index';
import StillConfirming from './components/StillConfirming/index';
import ErrorConfirming from './components/ErrorConfirming/index';
import Transition from '../../components/Transition/index';
import loadingGif from '../../images/LoadingGif.gif';
import { go } from '../../routes/index';

class Processing extends Component {
  static propTypes = {
    confirmed: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    isBusy: PropTypes.bool.isRequired,
    sessionId: PropTypes.string.isRequired,
    submitAppointment: PropTypes.func.isRequired,
    error: PropTypes.shape({ status: PropTypes.number.isRequired }),
  };
  componentDidMount() {
    this.props.submitAppointment(this.props.sessionId);
  }
  nextStep = () => {
    if (this.props.isLoggedIn) {
      go.appointments();
    } else {
      go.register(this.props.match.params.id);
    }
  };
  render() {
    return (
      <Transition
        className={classnames(styles.container, {
          [styles.containerBusy]: this.props.isBusy,
        })}
      >
        {!this.props.confirmed && !this.props.error && (
          <img
            key="0"
            alt="confirming appointment"
            className={classnames(styles.loadingGif, {
              [styles.gifBusy]: this.props.isBusy,
            })}
            src={loadingGif}
          />
        )}
        {(() => {
          if (this.props.confirmed) return <Confirmed key="1" />;
          {/*if (this.props.error) return <ErrorConfirming key="2" />;*/}
          return (
            <StillConfirming
              key="3"
              isBusy={this.props.isBusy}
              onClick={this.nextStep}
            />
          );
        })()}
      </Transition>
    );
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.loginReducer.token !== null,
  isBusy: state.appointmentConfirmationReducer.isBusy,
  confirmed: state.appointmentConfirmationReducer.confirmed,
  error:
    state.appointmentConfirmationReducer.error ||
    state.submitAppointmentReducer.error,
  orgId: state.practiceProfileReducer.profile.Id,
  sessionId: state.checkEnrollmentReducer.session.SessionId
});
const mapDispatchToProps = dispatch => ({
  submitAppointment: bindActionCreators(actions.submitAppointment, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Processing);
export { styles };
