import React from 'react';
import OpenableCard from '../components/OpenableCard';
import RedirectableCard from '../components/RedirectableCard';
import { Appointment, AppointmentProps } from '../types';
import PastCard from './PastCard';
import PastDetailsCard from './PastDetailsCard';

const PastAppointment: React.FC<AppointmentProps> = ({data}) => 
    <RedirectableCard<Appointment> 
        Origin={PastCard} 
        data={data}
        originPath='/home?goTo=1'
        targetPath={`/appointment/past/${data.Id}`}/>;

export default PastAppointment;
