import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { go } from '../../../../routes';
import { actions as confirmActions } from '../../../ConfirmRequest/actions/actions';
import UIContainer from '../../../../components/UIContainer';
import cardFailed from '../../../../images/cardFailed.png';
import { isDesktop } from '../../../../config';
import styles from './paymentDeclined.module.css';

class PaymentDeclined extends Component {
  static propTypes = {
    setWillRetry: PropTypes.func.isRequired,
    tryDifferentCard: PropTypes.func.isRequired,
    goToCardDetails: PropTypes.func.isRequired,
    tryOtherMethod: PropTypes.func.isRequired,
    goToPaymentSelection: PropTypes.func.isRequired,
  };
  handleNewCard = () => {
    this.props.setWillRetry();
    if (isDesktop()) {
      this.props.tryDifferentCard();
    } else this.props.goToCardDetails();
  };
  handleOtherMethod = () => {
    this.props.setWillRetry();
    if (isDesktop()) {
      this.props.tryOtherMethod();
    } else this.props.goToPaymentSelection();
  };
  render() {
    const content = (
      <div className={styles.container}>
        {isDesktop() && <div className={styles.heading}>Oops!</div>}
        <div className={styles.iconContainer}>
          <img
            alt="prescription error"
            src={cardFailed}
            className={styles.icon}
          />
        </div>
        <div className={styles.text}>
          Your payment has been declined by your card issuer.
        </div>
        <div className={styles.text}>
          Please contact your bank for more details, or pay with a different
          card.
        </div>
        <button className={styles.tryNewCard} onClick={this.handleNewCard}>
          Try a different card
        </button>
        <button className={styles.otherMethod} onClick={this.handleOtherMethod}>
          Choose other method
        </button>
      </div>
    );
    if (isDesktop()) return content;
    else
      return (
        <UIContainer heading="Whoops!" hideArrow>
          {content}
        </UIContainer>
      );
  }
}

const mapDispatchToProps = dispatch => ({
  setWillRetry: bindActionCreators(confirmActions.setWillRetry, dispatch),
  /* eslint-disable max-len */
  goToCardDetails: () => go.addCard(),
  goToPaymentSelection: () => go.paymentScript(),
  /* eslint-enable max-len */
});

export default connect(
  null,
  mapDispatchToProps,
)(PaymentDeclined);
