import { go } from "routes";

export 	const capitalise = (str) => {
    return str ? str[0].toUpperCase() + str.slice(1).toLowerCase() : ""
}

export const replaceMiddle = (string, n) => {
    var rest = string.length - n;
    return string.slice(0, Math.ceil(rest / 2)) + '*'.repeat(n) + string.slice(-Math.floor(rest / 2));
};

export const checkAuth = (err) => {
    if (err.response.status === 401) {
      window.localStorage.removeItem('token');
      go.login();
    }
    return;
}

export const isValidPassword = (password) => ( 
    password.length >= 8 && //is long enough
    /[a-z]/.test(password) && // has lowercase letters
    /[A-Z]/.test(password) && // has uppercase letters
    /\d/.test(password) // has digits
)
export const getShort = (text, charLimit) => {
    if (text.length >= charLimit) {
        return text.substring(0, charLimit - 1) + "..."
    }
    return text;
}
export const formatName = (name) => {
    return name.charAt(0).toUpperCase() + name.toLowerCase().slice(1);
}