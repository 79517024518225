import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Line } from './Line';
import { observer } from 'mobx-react-lite';

const Title = styled.div`
  color: #6e6e6e;
  font-size: 16px;
  font-weight: 800;
  margin: 10px 0px;
`;

const Section = styled.section`
  margin-bottom: 56px;
`;

const ItemBox = ({ children }: PropsWithChildren<{}>) => (
  <>
    {children}
    <Line />
  </>
);

const addLinesToItems = (elements: JSX.Element[]) =>
  elements.map(item => [item, <Line />]).flat();

const addItemToArray = (newItem: any, array: any[]) => {
  array.push(newItem);
  return array;
};

const addKeysToChildren = (items: any[]) =>
  items.map((item, index) =>
    React.cloneElement(item, { ...item.props, key: index }),
  );

export const PaymentSection: React.FC<{
  elementWhenEmpty: JSX.Element;
  renderItem?: (item: any, index?: number) => JSX.Element;
  items?: any[];
  title: string;
}> = observer(({ elementWhenEmpty, renderItem, items = [], title }) => {
  
  const cardSorting = (a, b) => {
    return (b.ExpYear - a.ExpYear) || (b.ExpMonth - a.ExpMonth) || (parseInt(b.Last4) - parseInt(a.Last4))
  }
  
  return (
    <Section>
      <ItemBox>
        <Title>{title}</Title>
      </ItemBox>
      {!items.length ? (
        <>
          {elementWhenEmpty}
          <Line />
        </>
      ) : (
        addKeysToChildren(
          addLinesToItems(
            addItemToArray(elementWhenEmpty, items?.slice().sort(cardSorting).map(renderItem!)),
          ),
        )
      )}
    </Section>
  );
});
