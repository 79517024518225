import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { findDOMNode } from 'react-dom';
import SliderTab from '../SliderTab/index';
import { ReactComponent as Arrow } from 'svgs/whiteRighArrow.svg';

const Container = styled.div`
  overflow: scroll;
  height: 35px;
  margin: 15px 15px 30px 15px;
  font-weight: var(--bold-weight);
  border-bottom: solid 1px var(--color-white-25);
  display: flex;
  transition: all 0.5s linear;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
 }
`

class Slider extends Component {
  static propTypes = {
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        component: PropTypes.oneOfType([
          PropTypes.func.isRequired,
          PropTypes.object.isRequired,
        ]),
      }).isRequired,
    ),
    setRenderedComponent: PropTypes.func.isRequired,
    selectedTab: PropTypes.shape({
      text: PropTypes.string.isRequired,
      component: PropTypes.oneOfType([
        PropTypes.func.isRequired,
        PropTypes.object.isRequired,
      ]),
    }).isRequired,
  };
  state = {
    selectedTab: this.props.selectedTab,
	leftArrow: false,
	rightArrow: true,
  };
  componentDidMount() {
    this.scrollToTab(this.state.selectedTab.text)
  }
  
  scrollToTab = tabText => {
    const tab = findDOMNode(this[tabText]);
    const slider = findDOMNode(this.slider);
    const tabDimensions = tab.getBoundingClientRect();
    const sliderDimensions = slider.getBoundingClientRect();

    let difference = 0;
    if (tabDimensions.right > sliderDimensions.right) {
      difference = tabDimensions.right - sliderDimensions.right;
    } else if (tabDimensions.left < sliderDimensions.left) {
      difference = tabDimensions.left - sliderDimensions.left;
    }
    slider.scrollLeft += difference;
  };
  handleClick = (tab, index) => () => {
    this.setState({
      selectedTab: tab,
    });
    this.props.setRenderedComponent(tab, index);
    this.scrollToTab(tab.text);
  };
  
  render() {
    return (
      <div style={{overflow: "visible", position: "relative"}}>
        {this.state.leftArrow &&
          <Arrow onClick={() => this.scrollToTab("My Health Feed")} style={{position: "absolute", left: "1rem", top: "-0.9rem", transform: "rotate(180deg)"}} />
        }
        <Container
          ref={slider => {
            this.slider = slider;
          }}
		  onScroll={(e) => {
			  this.setState({
				  rightArrow: e.target.scrollLeft < (e.target.scrollWidth - e.target.clientWidth - 5),
				  leftArrow: e.target.scrollLeft > 0
			  })
		  }}
        >
          {this.props.tabs.map((tab, i) => (
            <SliderTab
              tab={tab}
              ref={c => {
                this[`${tab.text}`] = c;
              }}
              selectedTab={this.state.selectedTab}
              onClick={this.handleClick(tab, i)}
              key={tab.text}
            />
          ))}
        </Container>
        {this.state.rightArrow &&
          <Arrow onClick={() => this.scrollToTab("My Health")} style={{position: "absolute", right: "1rem", top: "-0.9rem"}} />
        }      
      </div>
    );
  }
}

export default Slider;
