import styled from 'styled-components';

export const SecurityPageContainer = styled.main`
  display: block;
  background-color: white;
  margin: 0rem 0.2rem;
  margin-bottom: 0.882rem;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #00000029;
  overflow-y: scroll;
`;