import React from 'react';
import styled from 'styled-components';
import colors from 'styles/_colors.module.scss';
import { ConfirmProps } from 'types';

interface Sizable {
    height: string;
    background?: string;
    
}

interface TextProps {
  fontSize?: string;
  color?: string;
}

type Props = ConfirmProps & Sizable & TextProps;

const parseSize = (value: string):[number, string] => {
    const numValue = parseFloat(value);
    const match = value.match(/([a-z]*$)/i);
    const strValue = match ? match[0] : 'px';
    return [numValue, strValue];
}

const Container = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    z-index: 18 !important;
    border: none;
    height: ${({height}: Sizable) => height};
    background: ${({background}: Sizable) => background};
    color: ${({color}) => color};
    border-radius: ${({height}: Sizable) => height};
    padding: ${({height}) => { const [num, unit] = parseSize(height); return `0 ${num / 1.25}${unit}`}};
    cursor: pointer;
`;
const Text = styled.span`
    color: ${({color}: TextProps) => color};
    font-size: ${({fontSize}: TextProps) => fontSize};
`;
const StadiumButton = ({
    height,
    background = colors.gradientColorRight,
    color = colors.whiteColor,
    fontSize = '13px',
    text,
    handleClick,
    clickable = false,
    ...rest
}: Props) => {
  return (
    <Container height={height} background={background} {...rest} onClick={handleClick}>
         {text && <Text color={color} fontSize={fontSize}>{text}</Text>}
    </Container>
  )
}

export default StadiumButton;