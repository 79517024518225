import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from '../../../PracticeProfilePage/actions/actions';
import { actions as practiceBookingActions } from '../../../PracticeProfilePage/BookingPageMobile/actions/pageActions';
import { actions as practiceAppointmentActions } from '../../../PracticeProfilePage/BookingPageMobile/actions/actions';
import { actions as confirmationActions } from '../../Processing/actions/actions';
import Header from '../../../components/UIContainer/MobileUI/header/index';
import Whoops from './components/Whoops/index';
import TimeNotAvailable from './components/TimeNotAvailable/index';
import ConnectionIssue from './components/ConnectionIssue/index';
import TooManyAppointments from './components/TooManyAppointments/index';
import { ReactComponent as Logo } from '../../../svgs/logo.svg';
import styles from './bookingUnavailablePage.module.css';
import { go } from '../../../routes/index';
import { goBack } from '../../../routes/utils';
import { isDesktop } from '../../../config';
import { PracticeInfoPropType } from '../../../PracticeProfilePage/BookingPageMobile/propTypes/index';
import {
  sessionState,
  sessionDispatch,
  allSessionProps,
} from '../../../selectors/sessionSelectors';
import { sessionPropType } from '../../MobileVerification/propTypes/index';

class BookingUnavailablePage extends Component {
  static propTypes = {
    createSession: PropTypes.func.isRequired,
    getPracticeProfile: PropTypes.func.isRequired,
    setOnTimeSelectedFn: PropTypes.func.isRequired,
    clearSelectedTime: PropTypes.func.isRequired,
    clearConfirmationState: PropTypes.func.isRequired,
    session: sessionPropType.isRequired,
    clinic: PracticeInfoPropType.isRequired,
    error: PropTypes.shape({ status: PropTypes.number.isRequired }).isRequired,
  };
  componentWillUnmount() {
    this.props.clearConfirmationState();
  }
  onYesClick = () => {
    const sessionId = '';
    if (isDesktop()) this.props.getPracticeProfile(this.props.clinic.UrlName);
    this.props.clearConfirmationState();
    this.props.clearSelectedTime();
    this.props.setOnTimeSelectedFn((pmsUserId, appointmentId, time) =>
      this.props.createSession({
        ...this.props.session,
        pmsUserId,
        appointmentId,
        time,
        sessionId,
      })(),
    );
    this.goBooking();
  };
  goBooking = () => {
    go.practiceBooking(this.props.clinic.UrlName);
  };
  render() {
    // Still waiting for other error scenarios from design
    return (
      <div className={styles.container}>
        {!isDesktop() && (
          <Header
            headerClassNames={styles.header}
            showHelp
            firstLine={
              <div className={styles.retryContainer}>
                <div className={styles.retry} onClick={goBack}>
                  Retry
                </div>
                <Logo className={styles.logo} />
              </div>
            }
          />
        )}
        <Whoops />
        {(() => {
          if (this.props.error.status === 400) {
            return (
              <TimeNotAvailable
                onYesClick={this.onYesClick}
                onNoClick={go.home}
              />
            );
          }
          if (this.props.error.status === 403) {
            return <TooManyAppointments close={this.goBooking} />;
          }
          return (
            <ConnectionIssue
              clinic={this.props.clinic}
              onCancelClick={this.goBooking}
              onRetryClick={go.processing}
            />
          );
        })()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  clinic: state.practiceProfileReducer.profile,
  error:
    state.appointmentConfirmationReducer.error ||
    state.submitAppointmentReducer.error ||
    {},
  session: sessionState([])(state),
});
const mapDispatchToProps = dispatch => ({
  getPracticeProfile: bindActionCreators(actions.getPracticeProfile, dispatch),
  setOnTimeSelectedFn: bindActionCreators(
    practiceBookingActions.setOnTimeSelectedFn,
    dispatch,
  ),
  clearConfirmationState: bindActionCreators(
    confirmationActions.clearConfirmationState,
    dispatch,
  ),
  clearSelectedTime: bindActionCreators(
    practiceAppointmentActions.clearSelection,
    dispatch,
  ),
  createSession: sessionDispatch(dispatch)(allSessionProps),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BookingUnavailablePage);
export { styles };
