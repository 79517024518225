import React, { CSSProperties, useEffect, useState } from 'react';
import styled from 'styled-components';
import Timer from 'react-compound-timer';
import { observer } from 'mobx-react-lite';

import { ReactComponent as Arrow } from 'svgs/blueRightCircleArrow.svg';
import { ReactComponent as Mobile } from 'svgs/phoneVerify.svg';
import { ReactComponent as WhiteArrow } from 'svgs/rightWhiteArrow.svg';
import { useAccountStore } from 'store/mobx/mobx';
import { isValidMobile } from 'libs/ValidBasicInfo';

const Card = styled.div`
    width: 534px;
    height: 677px;
    position: relative;
    background-color: white;
    border-radius: 4px;
    margin: 1rem auto;
    box-shadow: 0px 3px 6px #00000029;
`
const Text = styled.div`
    color: #6C6C6C;
    font-size: 13px;
    margin: 1rem 0rem;
    width: 100%;
    text-align: left;
`
const Title = styled.div`
    text-align: center;
    color: #6C6C6C;
    font-size: 17px;
    width: 100%;
    margin: 3rem 0 2rem;
    font-weight: bold;
`
const FlexV = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin: auto;
`
const Button = styled.div`
    background-color: #07A3C8;
    color: white;
    border-radius: 4px;
    font-size: 13px;
    display: flex;
    height: 35px;
    width: 166px;
    flex-direction: row;
    cursor: pointer;
    text-align: center;
    margin-top: 1rem;
    align-items: center;
`
const Input = styled.input`
    border: 1px solid #DADADA;
    border-radius: 4px;
    width: 100%;
    font-size: 17px;
    padding: 0.5rem 1rem;
    outline: none;
    -moz-appearance: textfield;
    -webkit-appearance: none;
`
const Num = styled.input`
    border: 1.5px solid #DADADA;
    padding: 10px;
    font-size: 18px; 
    width: 40px;
    margin: 0rem 0.5rem;
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    text-align: center;
    border-radius: 4px;
    transition: all 0.3s linear;
`
const NumContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem auto;
`
const Link = styled.div`
    color: #07A3C8;
    font-size: 13px;
    width: 100%;
    text-align: left;
    cursor: pointer;
`
const TimerContainer = styled.div`
    width: 100%;
    margin: auto;
    font-size: 13px;
    margin: 2rem auto;
    text-align: center;
`

const backButtonStyle: CSSProperties = {
    position: 'absolute',
    left: '1rem',
    top: '1rem',
    transform: 'rotate(180deg)',
    cursor: 'pointer',
    height: '40px',
    width: '40px',
}

interface IProps {
    goNext: () => void;
    goBack: () => void;
}

const MobileVerificationDesktop: React.FC<IProps> = observer(({goNext, goBack}) => {
    const storeAcc = useAccountStore();

    const [state, setState] = useState(0);
    const [mobileNumber, setMobileNumber] = useState(storeAcc.profile.Mobile);
    const [isActive, setIsActive] = useState(false);
    const [resent, setResent] = useState(false);

    let ref1: React.RefObject<HTMLInputElement> = React.createRef();
    let ref2: React.RefObject<HTMLInputElement> = React.createRef();
    let ref3: React.RefObject<HTMLInputElement> = React.createRef();
    let ref4: React.RefObject<HTMLInputElement> = React.createRef();
    let refArr: React.RefObject<HTMLInputElement>[] = [ref1, ref2, ref3, ref4];
    const [incorrect, setIncorrect] = useState(false);

    const BackButtonFn = () => state === 0 ? goBack() : setState(state - 1)

    const onKeyDown = (e: any, id: number) => {
        if (e.key === "Backspace") {
            if (refArr[id].current!.value !== "") {
                refArr[id].current!.value = "";
                return
            }
    
            if (id !== 0) {
                refArr[id - 1].current!.value = ""; 
                refArr[id - 1].current!.focus(); 
            }
        }
    }
    const onChange = (e: React.FormEvent<HTMLInputElement>, id: number) => {
        const val: string = e.currentTarget.value;

        let isNum = /^\d+$/.test(val)
        if (!isNum) { return; }

        if (refArr === null) { return; } else {
            refArr[id]!.current!.value = val;
        }

        if (id < 3) { refArr[id + 1].current!.focus(); }

        if (id === 3) {
            let code: string = "";
            
            code += refArr.map((item) => {
                return item!.current!.value;
            })

            // Send code check request
            // eslint-disable-next-line
            storeAcc.setVerifyCode(code.replace(/\,/g, ''), 0);
            storeAcc.checkVerifyText(false, () => {}, () => goNext(), (value: boolean) => setIncorrect(value));
        }
    }

    useEffect(() => {
        setIsActive(isValidMobile(mobileNumber) && mobileNumber !== "");
        if (incorrect && ref1.current) {
            refArr.map((item) => {
                item.current!.value = "";
            })
            ref1.current.focus();
        }
    })

    return (
        <Card>
            <Arrow 
                onClick={BackButtonFn} 
                style={backButtonStyle} 
            />
            {state === 0 &&
                <FlexV style={{padding: "0rem 2rem"}}>
                    <Mobile style={{margin: "2rem auto 1.5rem", display: "block"}}/>
                    <Title>Enter your mobile number</Title>
                    <Text>Mobile number</Text>
                    <Input 
                        value={mobileNumber}
                        onChange={(e: any) => {
                            // TODO: FIX REGEX
                            if (!/^$|^\d+$/.test(e.target.value)) { return; }
                            else { setMobileNumber(e.target.value); }
                        }}
                        type={"tel"}
                    />
                    <FlexV style={{marginTop: "4rem"}}>
                        <Button style={{width: "140px", marginTop: "2rem", backgroundColor: isActive ? "#07A3C8" : "#DADADA"}} onClick={() => {
                            if (!isActive) { return; }
                            storeAcc.sendVerifyText(mobileNumber, 
                                () => { 
                                    setResent(true);
                                    setState(1);
                                }, 
                                () => {}, 
                                mobileNumber !== storeAcc.profile.Mobile
                            );
                        }}>
                            <div style={{marginLeft: "auto"}}>Continue</div>
                            <WhiteArrow style={{margin: "0 auto 0 10px"}}/>
                        </Button>
                    </FlexV>
                </FlexV>
            }
            {state === 1 &&
                <FlexV>
                    <Mobile style={{margin: "2rem auto 1.5rem", display: "block"}}/>
                    <Title style={{padding: "0rem 5rem"}}>Enter the 4-digit code sent to you on {mobileNumber}</Title>
                    <NumContainer>
                        <Num autoFocus={true} style={{borderColor: incorrect ? "#FC7B7B" : "#DADADA"}} ref={ref1} onKeyDown={(e) => onKeyDown(e, 0)} onChange={(e) => onChange(e, 0)} type={"number"} maxLength={1} />
                        <Num style={{borderColor: incorrect ? "#FC7B7B" : "#DADADA"}} ref={ref2} onKeyDown={(e) => onKeyDown(e, 1)} onChange={(e) => onChange(e, 1)} type={"number"} maxLength={1} />
                        <Num style={{borderColor: incorrect ? "#FC7B7B" : "#DADADA"}} ref={ref3} onKeyDown={(e) => onKeyDown(e, 2)} onChange={(e) => onChange(e, 2)} type={"number"} maxLength={1} />
                        <Num style={{borderColor: incorrect ? "#FC7B7B" : "#DADADA"}} ref={ref4} onKeyDown={(e) => onKeyDown(e, 3)} onChange={(e) => onChange(e, 3)} type={"number"} maxLength={1} />
                    </NumContainer>
                    {!resent ? 
                                (<Link onClick={() => {
                                    storeAcc.sendVerifyText(mobileNumber, () => setResent(true), () => {}, mobileNumber !== storeAcc.profile.Mobile);
                                }} style={{textAlign: "center", margin: "auto", marginTop: "2rem"}}>Resend Code</Link>) 
                                :
                                (
                                    <TimerContainer>
                                        <Timer 
                                            initialTime={180000}
                                            direction={"backward"}
                                            lastUnit={"m"}
                                            checkpoints={[
                                                {
                                                    time: 0,
                                                    callback: () => setResent(false),
                                                }
                                            ]}
                                        >
                                            Resend code in <Timer.Minutes />:<Timer.Seconds />
                                        </Timer>
                                    </TimerContainer>
                                )
                            }
                </FlexV>
            }
        </Card>
    )
});

export default MobileVerificationDesktop;