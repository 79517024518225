import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';
import classnames from 'classnames';

import styles from './switcheroo.module.css';

class Switcheroo extends Component {
  static propTypes = {
    firstItem: PropTypes.node.isRequired,
    secondItem: PropTypes.node.isRequired,
    thirdItem: PropTypes.node,
    fourthItem: PropTypes.node,
    fifthItem: PropTypes.node,
    className: PropTypes.string,
  };
  constructor(props) {
    super(props);
    this.setItemToShow = this.setItemToShow.bind(this);
  }
  state = {
    lastShownItem: 0,
    itemToShow: 1,
  };
  setItemToShow(itemToShow, lastShownItem) {
    this.setState({ itemToShow });
    this.setState({ lastShownItem });
  }
  render() {
    const { itemToShow, lastShownItem } = this.state;
    return (
      <CSSTransitionGroup
        component="div"
        className={classnames(styles.container, this.props.className)}
        transitionName={{
          leave: classnames(
            /* eslint-disable max-len */
            { [styles.leave]: itemToShow === 1 },
            {
              [styles.leaveLeft]:
                itemToShow !== 1 &&
                itemToShow !== 5 &&
                itemToShow > lastShownItem,
            },
            {
              [styles.leaveRight]:
                itemToShow !== 1 &&
                itemToShow !== 5 &&
                itemToShow < lastShownItem,
            },
            { [styles.lastLeave]: itemToShow === 5 },
          ),
          leaveActive: classnames(
            { [styles.leaveActive]: itemToShow === 1 },
            {
              [styles.leaveLeftActive]:
                itemToShow !== 1 &&
                itemToShow !== 5 &&
                itemToShow > lastShownItem,
            },
            {
              [styles.leaveRightActive]:
                itemToShow !== 1 &&
                itemToShow !== 5 &&
                itemToShow < lastShownItem,
            },
            { [styles.lastLeaveActive]: itemToShow === 5 },
          ),
          enter: classnames(
            { [styles.enter]: itemToShow === 1 },
            {
              [styles.enterLeft]:
                itemToShow !== 1 &&
                itemToShow !== 5 &&
                itemToShow > lastShownItem,
            },
            {
              [styles.enterRight]:
                itemToShow !== 1 &&
                itemToShow !== 5 &&
                itemToShow < lastShownItem,
            },
            { [styles.lastEnter]: itemToShow === 5 },
          ),
          enterActive: classnames(
            { [styles.enterActive]: itemToShow === 1 },
            {
              [styles.enterLeftActive]:
                itemToShow !== 1 &&
                itemToShow !== 5 &&
                itemToShow > lastShownItem,
            },
            {
              [styles.enterRightActive]:
                itemToShow !== 1 &&
                itemToShow !== 5 &&
                itemToShow < lastShownItem,
            },
            { [styles.lastEnterActive]: itemToShow === 5 },
            /* eslint-enable max-len */
          ),
        }}
        transitionLeaveTimeout={300}
        transitionEnterTimeout={300}
      >
        {itemToShow === 1 ? this.props.firstItem : null}
        {itemToShow === 2 ? this.props.secondItem : null}
        {itemToShow === 3 ? this.props.thirdItem : null}
        {itemToShow === 4 ? this.props.fourthItem : null}
        {itemToShow === 5 ? this.props.fifthItem : null}
      </CSSTransitionGroup>
    );
  }
}

export default Switcheroo;
export { styles };
