import { put, call, takeEvery } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/policyActions';
import { GenericParserHttp } from '../../libs/Http';

const api = token =>
  GenericParserHttp.post('consumer/acceptPrivacyPolicy', { token });

function* acceptPrivacyPolicy({ payload: { token } }) {
  try {
    yield call(api, token);
    yield put(actions.acceptPrivacyPolicySuccess());
  } catch (err) {
    yield put(actions.acceptPrivacyPolicyFailure(err));
  }
}

function* acceptPrivacyPolicySaga() {
  yield takeEvery(actionTypes.acceptPrivacyPolicy, acceptPrivacyPolicy);
}

export { acceptPrivacyPolicySaga };
