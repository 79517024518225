import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import classnames from 'classnames';
import moment from 'moment';

import { actions as apptActions } from '../../../AppointmentsPage/actions/actions';
import { actions } from '../../actions/actions';
import ScriptDetails from '../../ScriptDetailsMobile';
import Pending from '../../ScriptDetailsMobile/Pending';
import CardHeading from './CardHeading';
import { ReactComponent as Arrow } from '../../../svgs/rightarrow2.svg';
import { ReactComponent as Ellipsis } from '../../../svgs/ellipsis.svg';
import styles from './prescriptionCard.module.css';
import { go } from '../../../routes';
import { scriptPropType, detailsPropType } from '../../propTypes';

class PrescriptionCard extends Component {
  static propTypes = {
    fetchScriptDetails: PropTypes.func.isRequired,
    toggleCta: PropTypes.func.isRequired,
    changeCtaFn: PropTypes.func.isRequired,
    goToBooking: PropTypes.func.isRequired,
    toggleOpenCardState: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    setCtaText: PropTypes.func,
    script: scriptPropType,
    scriptDetails: detailsPropType,
    past: PropTypes.bool,
  };
  state = {
    isOpen: false,
    showMenu: false,
  };
  // shouldComponentUpdate(nextProps) {
  //   if (!this.props.past) return true;
  //   return nextProps.scriptDetails && nextProps.scriptDetails.Id === this.props.script.Id;
  // }
  toggleCard = () => {
    this.setState({ isOpen: !this.state.isOpen, showMenu: false });
    this.props.toggleOpenCardState();
    if (this.props.setCtaText) this.props.setCtaText('Request Prescription');
    this.props.toggleCta();
  };
  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };
  handleCardClick = () => {
    if (!this.state.isOpen) {
      this.toggleCard();
      this.props.fetchScriptDetails(this.props.script.Id, this.props.token);
      if (this.props.script.StatusColour === 'Red') {
        this.props.changeCtaFn(
          this.props.goToBooking(this.props.script.OrganisationId),
        );
        this.props.setCtaText('New Appointment');
      }
    }
  };
  render() {
    const { script, past, scriptDetails } = this.props;
    const { isOpen, showMenu } = this.state;
    return (
      <div
        className={classnames(
          styles.outerContainer,
          { [styles.open]: isOpen },
          { [styles.pastOuter]: past && !isOpen },
        )}
      >
        {isOpen && (
          <div className={styles.openHeader} style={{overflow: "visible"}}>
            <Arrow onClick={this.toggleCard} className={styles.arrow} />
            <div className={styles.headerInner}>
              <div>{`Requested on: ${moment(this.props.script.DateTime).format(
                'DD/MM/YY',
              )}`}</div>
              <Ellipsis className={styles.ellipsis} onClick={this.toggleMenu} />
            </div>
          </div>
        )}
        <div
          className={classnames(
            { [styles.container]: !isOpen },
            { [styles.pastContainer]: past && !isOpen },
            { [styles.openContainer]: isOpen },
          )}
          onClick={this.handleCardClick}
        >
          {(!past || isOpen) && (
            <Pending
              status={script.Status}
              color={script.StatusColour}
              statusCode={script.StatusNumber}
              details={{
                ...scriptDetails,
                OrganisationName: script.OrganisationName,
              }}
              isOpen={isOpen}
              hasPatientNotes={script.HasPatientNotes}
            />
          )}
          <CardHeading script={script} isOpen={isOpen} />
          {isOpen && (
            <ScriptDetails
              toggleMenu={this.toggleMenu}
              toggleCard={this.toggleCard}
              showMenu={showMenu}
              script={{ ...script, ...scriptDetails }}
            />
          )}
        </div>
      </div>
    );
  }
}
// menu background is separated from bottom menu for animation purposes

const mapStateToProps = state => ({
  token: state.loginReducer.token,
  scriptDetails: state.scriptReducer.scriptDetails,
});
const mapDispatchToProps = dispatch => ({
  /* eslint-disable max-len */
  fetchScriptDetails: bindActionCreators(actions.fetchScriptDetails, dispatch),
  toggleCta: bindActionCreators(apptActions.toggleCta, dispatch),
  changeCtaFn: bindActionCreators(apptActions.changeCtaFn, dispatch),
  goToBooking: id => () => go.practiceBooking(id),
  /* eslint-enable max-len */
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrescriptionCard);
