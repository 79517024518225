import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { ReactComponent as Cross } from 'svgs/blueCross.svg';
import { ReactComponent as PrivacyLogo } from 'svgs/privacy.svg';
import { isDesktop } from '../../../config';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem 1rem;
    position: relative;
`
const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: var(--color-pelorous);
    flex-grow: 2;
    margin: 1rem;
`
const Text = styled.div`
    font-size: 13px;
    text-align: center;
`
const Link = styled.div`
    font-size: 15px;
    text-align: right;
    text-decoration: underline;
    margin-top: 10px;
    cursor: pointer;
`

interface Props {
    termsOnClick?: () => void | null;
    closeOnClick?: () => void | null;
}

const PrivacyBanner: React.FC<Props> = observer(({termsOnClick, closeOnClick}) => {
    return(
        <Container style={{borderBottom: '2px solid #f4f4f4', padding: isDesktop() ? '0rem 1rem' : '1rem'}}>
            <Cross style={{height: '25px', position: 'absolute', top: '1rem', right: '2rem', cursor: 'pointer'}} onClick={closeOnClick}/>
            <PrivacyLogo style={{height: '80px', width: '150px', margin: '0.5rem 0'}} /> 
            <TextContainer>
                <Text>Your Vensa Pay details (card information, account information, balance, etc.) will be shared with Vensa's payment providers to allow us to process your payments through our system.</Text>
                <Link style={{textAlign: isDesktop() ? 'right' : 'center', marginTop: isDesktop() ? '10px' : '15px'}} onClick={termsOnClick}>View card storage terms</Link>
            </TextContainer>
        </Container>
    );
});

export default PrivacyBanner;