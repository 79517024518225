import { actionTypes } from '../actions/actions';

const INITIAL_STATE = {
  isRetrying: false,
  retrySuccess: false,
  retryFailure: false,
  retryLimitReached: false,
  error: null,
};

const retryRequestReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.retryRequest: {
      return {
        isRetrying: true,
        retrySuccess: false,
        retryFailure: false,
        error: null,
      };
    }
    case actionTypes.retrySuccess: {
      return {
        isRetrying: false,
        retrySuccess: true,
        retryFailure: false,
        error: null,
      };
    }
    case actionTypes.retryFailure: {
      return {
        ...state,
        isRetrying: false,
        retrySuccess: false,
        retryFailure: true,
      };
    }
    case actionTypes.retryLimitReached: {
      return {
        ...state,
        isRetrying: false,
        retrySuccess: false,
        retryFailure: false,
        retryLimitReached: true,
      };
    }
    case actionTypes.clearRetryState: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default retryRequestReducer;
