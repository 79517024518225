import React from 'react';
import { observer } from 'mobx-react-lite'

import styles from './submitRequest.module.css';
import progressBar from '../../svgs/progressBarLvl3.svg';
import tick from '../../svgs/whiteTick.svg';
import { go } from '../../routes';
import UIContainer from '../../components/UIContainer';
import ProgressCard from '../components/ProgressCard';
import emptyPic from '../../svgs/patientSilhouette.svg';
import { useComponentDidMount } from 'libs/newHttp';
import { useAccountStore, useIdentityStore } from 'store/mobx/mobx';

const SubmitRequest = observer(() => {
    const accStore = useAccountStore();
    const idStore = useIdentityStore();

    const email = accStore.profile.Email.length > 24 ? accStore.profile.Email.substring(0, 22) + "..." : accStore.profile.Email;

    useComponentDidMount(() => {
        // accStore.getProfile();
        window.scrollTo(0, 0);
        //this.props.getPractice(this.props.practiceID);
    })

    return (
            <UIContainer heading={"Step 3: Submit Request"} isFetching={idStore.loading || accStore.loading}>
                <div className={styles.container}>
                    <ProgressCard 
                        text={"Your identity request is ready for submission."}
                        icon={tick}
                        progressBar={progressBar}
                        padding={true}
                    />
                    <div className={styles.card}>
                        <div className={styles.flexbox}>
                            <div className={styles.flexboxH}>
                                {idStore.photo ?
                                    <div style={{backgroundImage: `url(${idStore.photo})`}} className={styles.pic} alt="pic"/>
                                    : (
                                    <img className={styles.pic} src={emptyPic} alt="dp"/>
                                )}
                                <div className={styles.flexbox} style={{marginLeft: "10px"}}>
                                    <p className={styles.name} style={{fontWeight: 600}}>{accStore.profile.FirstName} {accStore.profile.LastName}</p>
                                    <p>{accStore.profile.Mobile}</p>
                                    <p>{email}</p>
                                </div>
                            </div>
                            <div className={styles.button} onClick={() => {
                                go.identityProcessing()
                            }
                            }> Submit Request </div>
                        </div>
                    </div>
                </div>
            </UIContainer>
    );    
})

export default SubmitRequest;