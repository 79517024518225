import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import moment from 'moment'

import { toNormalTime } from 'libs/Dates';
import { ReactComponent as Back } from 'svgs/circleRightArrow.svg';
import { ReactComponent as Green } from 'svgs/approvedTick.svg';
import { ReactComponent as Orange } from 'svgs/pendingTickWarning.svg';
import { ReactComponent as Red } from 'svgs/declinedCrossWarning.svg';
import { ReactComponent as Call } from 'svgs/call.svg';
import { useAccountStore, useIdentityStore, usePracticesStore } from 'store/mobx/mobx';
import { go } from 'routes';
import UIContainer from 'components/UIContainer';
import { useComponentDidMount } from 'libs/newHttp';
import { isDesktop } from 'config';
import RouteBar from 'components/DesktopComponents/RouteBar';
import TabBar from 'components/DesktopComponents/TabBar';
import { IIdentityRequestsStatus } from 'store/mobx/accountStore/accountStore.type';
import LearnMorePopUp from 'components/LearnMorePopUp/LearnMorePopUp';
import IdentityChecklistBanner from './IdentityChecklistBanner';
import { ReactComponent as Arrow } from 'svgs/blueRightCircleArrow.svg'; 

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    overflow-y: scroll;
`
const IdentitiyTitle = styled.div`
    color: #F5722C;
    font-size: 21px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
`
const Text = styled.div`
    font-size: 15px;
    margin: 1rem auto;
    width: 300px;
`
const Card = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 0px 0px 4px 4px;
    background-color: white;
    padding: 2rem;
    padding-top: 1rem;
    margin: 1rem auto;
    box-shadow: 1px 2px 5px lightgray;
    width: 460px;
    position: relative;
    height: 700px;
`
const Image = styled.div`
    background-size: cover;
    background-position: center;
    width: 110%;
    height: 200px;
    transform: translateX(-5%);
`
const Button = styled.div`
    background-color: #07A3C8;
    color: white;
    border-radius: 36px;
    padding: 1rem;
    margin: 1rem auto;
    text-align: center;
    font-size: 16px;
    box-shadow: 1px 3px 5px lightgray;
    width: 66%;
    margin-top: 2rem;
    cursor: pointer;
`
const Title = styled.div`
    text-transform: uppercase;
    font-size: 13px;
    margin-top: 1rem;
    text-align: left;
    margin 0.5rem auto;
    margin-top: 1rem;
    width: 460px;
`
const PageTitle = styled.div`
    font-size: 17px;
    width: 100%;
    margin: 0.1rem auto;
    font-weight: 600;
    color: #585858;
    text-align: center;
`
const Address = styled.div`
    font-size: 13px;
    width: 80%;
    margin: 1rem auto;
    text-align: center;
    color: #585858;
`
const TextSmall = styled.div`
    font-size: 13px;
    margin: auto;
    margin-top: 0.2rem;
    text-align: left;
    width: 460px;
`

interface IPractice {
    Address: string;
    AllowResubmit: boolean;
    BlackListed: boolean;
    BlackListedReason: string;
    EnrollmentStatusNotMeetPracticePolicy: boolean;
    ErrorMessage: string | null;
    IdentityRequest: {
        Status: number;
        RequestDate: string;
        UpdatedOn: string;
        Id: string;
    };
    IsOnline: boolean;
    MatchedPatientInPms: boolean;
    OnlineVerificationEnabled: boolean;
    OrganisationId: string;
    OrganisationName: string;
    Phone: string;
    PracticeVerifiedOn: string;
    PictureUrl: string;
    Pictures: string[]
}
interface IProps {
    practice: IPractice;
}
const ViewIdentitiyRequestDesktop: React.FC<IProps> = observer(() => {
    const idStore = useIdentityStore();
    const practiceStore = usePracticesStore();
    const accStore = useAccountStore();
    const [loading, setLoading] = useState(true);
    const [showCard, setShowCard] = useState(true)
    const [identityPopup, setIdentityPopup] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [practice, setPractice] = useState<IIdentityRequestsStatus>({
        OrganisationId: "",
        AllowResubmit: false,
        OrganisationName: "",
        Address: "",
        OnlineVerificationEnabled: false,
        PictureUrl: "",
        PracticeVerifiedOn: "",
        IdentityRequest: {
            Status: 3,
            RequestDate: "",
            UpdatedOn: "",
            Id: "",
        },
        Phone: "",
        IdentityVerified: false,
        Pictures: [],
        IsOnline: true,
        MatchedPatientInPms: false,
        ErrorMessage: "",
        EnrollmentStatusNotMeetPracticePolicy: false,
        BlackListed: false,
        BlackListedReason: "",
        PracticeVerification: false,
        AlreadyVerifiedByOtherAccount: false,
    })
    
    useComponentDidMount(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let orgId = params.get('id');
        // accStore.getProfile();

        accStore.getIdentityRequestsStatus(() => {
            accStore.identityRequests.map((practice) => {
                if (practice.IdentityRequest || practice.PracticeVerification) {
                    if (orgId === practice.OrganisationId) {
                        setPractice(practice);
                        setLoading(false);
                        setLoaded(true);
                    }
                }
            })
        });
    })
    return(
        <UIContainer isFetching={accStore.loading || loading}>
            {identityPopup &&
                <LearnMorePopUp type={"IDENTITY_INFO"} hide={() => setIdentityPopup(false)} />
            }
            <Container style={{backgroundColor: isDesktop() ? "#F9F9F9" : "white"}}>
                <RouteBar 
                    lowLevel={"Identity"}
                    lowLink={() => go.identity()}
                />
                <TabBar type={0} id={1} />

                {!accStore.profile.AcknowledgeOfAdditionalSecuritySettings && showCard &&
                    <IdentityChecklistBanner hide={() => setShowCard(false)} onClick={() => setIdentityPopup(true)} />
				}

                <Title>Identity Status</Title>
                <div style={{display: "flex", flexDirection: "row", width: "100%", position: "relative"}}>
                    <TextSmall>
                        Local identity verification is free. You must verify your identity with each medical centre you wish to use. 
                        <span onClick={() => setIdentityPopup(true)} style={{color: "#07A3C8", cursor: "pointer"}}> Learn more.</span>
                    </TextSmall>
                </div>
                {practice.PracticeVerification ?
                    (<Card>
                        <Arrow onClick={go.identity} style={{ overflow: "visible", cursor: "pointer", transform: "rotate(180deg)", position: "absolute", top: "1rem", left: "1rem"}} />

                        <PageTitle>Identity Verification</PageTitle>
                        <PageTitle>{practice.OrganisationName}</PageTitle>
                        <Address>{practice.Address}</Address>
                        <Image style={{backgroundImage: practice.Pictures ? `url(${practice.Pictures[0]})` : ""}} />
                        <div style={{marginTop: "2rem"}}>
                            
                            <Green style={{display: "block", margin: "0rem auto 1rem"}} />
                            <IdentitiyTitle style={{color: "#23C373"}}>Identity Verified</IdentitiyTitle>
                            <Text>
                                Identity successfuly verified offline by the practice on {moment.utc(practice.PracticeVerifiedOn, 'YYYY-MM-DD').format('DD/MM/YYYY')}.
                            </Text>
                        </div>
                    </Card>)
                :
                    (<Card>
                        <Back onClick={() => go.identity()} style={{
                            transform: "rotate(180deg)",
                            cursor: "pointer",
                            position: "absolute",
                            top: "20px",
                            left: "20px",
                            height: "48px",
                            width: "48px",
                        }}/>
                        <PageTitle>Identity Verification</PageTitle>
                        <PageTitle>{practice.OrganisationName}</PageTitle>
                        <Address>{practice.Address}</Address>
                        <Image style={{backgroundImage: practice.Pictures ? `url(${practice.Pictures[0]})` : ""}} />
                        {practice.IdentityRequest!.Status === 0 && 
                            <div>
                                <Orange style={{display: "block", margin: "2rem auto"}} />
                                <IdentitiyTitle style={{color: "#F5722C"}}>Identity Partially Verified</IdentitiyTitle>
                                <Text>
                                    Your identitiy request sent on {moment.utc(practice.IdentityRequest!.RequestDate, 'YYYY-MM-DD').format('DD/MM/YYYY')} at {toNormalTime(practice.IdentityRequest!.RequestDate)} is currently pending with {practice.OrganisationName}.
                                </Text>
                                <Text>
                                    You can follow it up by giving them a call to on <span style={{color: "#07A3C8"}}>{practice.Phone}</span>access the status of your verification.
                                </Text>
                            </div>
                        }
                        {practice.IdentityRequest!.Status === 1 && 
                            <div>
                                <Green style={{display: "block", margin: "0rem auto 1rem"}} />
                                <IdentitiyTitle style={{color: "#23C373"}}>Identity Verified</IdentitiyTitle>
                                <Text>
                                    Your identitiy was verified on {moment.utc(practice.IdentityRequest!.UpdatedOn, 'YYYY-MM-DD').format('DD/MM/YYYY')}.
                                </Text>
                                <Text>
                                    Your verification was done offline with {practice.OrganisationName}.
                                </Text>
                                <Text>Verifying your identity gives you more control to your health information across the Vensa site.</Text>
                            </div>
                        }
                        {practice.IdentityRequest!.Status === 2 && practice.AllowResubmit &&
                            <div>
                                <Red style={{display: "block", margin: "0rem auto 1rem"}} />
                                <IdentitiyTitle style={{color: "#FF6A65"}}>Identity Verification Declined</IdentitiyTitle>
                                <Text>
                                    Your identitiy request was sent on {moment.utc(practice.IdentityRequest!.RequestDate, 'YYYY-MM-DD').format('DD/MM/YYYY')} at {toNormalTime(practice.IdentityRequest!.RequestDate)} has been declined by {practice.OrganisationName}.
                                </Text>
                                <Text>
                                    You can follow it up by giving them a call and ask if they can provide your verification online. The practice will not allow you to put your identity verification online again.
                                </Text>
                                <Call style={{display: "block", margin: "2rem auto"}} onClick={() => window.location.href="tel:" + practice.Phone} />
                            </div>
                        }
                        {practice.IdentityRequest!.Status === 2 && !practice.AllowResubmit &&
                            <div>
                                <Red style={{display: "block", margin: "0rem auto 1rem"}} />
                                <IdentitiyTitle style={{color: "#FF6A65"}}>Identity Verification Previously Declined</IdentitiyTitle>
                                <Text>
                                    Your identitiy request was sent on {moment.utc(practice.IdentityRequest!.RequestDate, 'YYYY-MM-DD').format('DD/MM/YYYY')} at {toNormalTime(practice.IdentityRequest!.RequestDate)} has been declined by {practice.OrganisationName}.
                                </Text>
                                <Text>
                                    The practice does allow for you to resubmit your identity request if you like you can start by following the link below.
                                </Text>
                                <Button onClick={() => {
                                    idStore.setPracticeSingle(practice);
                                    if (accStore.profile.SecurityPinCodeSetup) {
                                        go.identityPhoto();
                                    } else {
                                        go.identityPin();
                                    }
                                }}>Verify Identity Again</Button>
                            </div>
                        }
                    </Card>)
                }
            </Container>
        </UIContainer>
    )
})

export default ViewIdentitiyRequestDesktop;