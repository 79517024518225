import { actionTypes } from '../actions/actions';

const INITIAL_STATE = {
  isFetching: false,
  Id: '',
  error: null,
  success: false,
  options: [],
};

const addPharmacyReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.addPharmacy: {
	  		return {
				...state,
				isFetching: true,
				error: null,
				success: false,
				Id: action.payload.Id,
	  		};
		}
		case actionTypes.addPharmacySuccess: {
	  		return {
				...state,
				isFetching: false,
				Id: action.payload.Id,
				error: null,
				success: true,
			};
		}
		case actionTypes.addPharmacyFailure: {
			return {
				...state,
				isFetching: false,
				Id: '',
				error: action.payload.error,
				success: false,
			};
		}
		case actionTypes.getOptions: {
			return {
				isFetching: true,
				success: false
			}
		}
		case actionTypes.getOptionsSuccess: {
			return {
				isFetching: false,
				options: action.payload.options
			}
		}
		case actionTypes.deletePharmacy: {
			return {
				isFetching: true,
			}
		}
		case actionTypes.deletePharmacySuccess: {
			return {
				isFetching: false,
				success: true,
			}
		}
		case actionTypes.deletePharmacyFailure: {
			return {
				isFetching: false,
				success: false,
			}
		}
		default: {
			return state;
		}
	}
};

export default addPharmacyReducer;
