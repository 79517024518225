import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    color: #07A3C8;
    font-size: 13px;
    margin: 1.5rem;
    cursor: pointer;
`
interface Props {
    text: string;
    onClick: () => void;
}

const LinkButton: React.FC<Props> = ({text, onClick}) => {
    return (
        <Container onClick={onClick}>{text}</Container>
    );
}

export default LinkButton;