import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import styles from './notReceiving.module.css';
import { ReactComponent as ErrorKiwi } from '../../../../svgs/smsErrorKiwi.svg';
import ResendForm from './resendForm/index';

const NotReceiving = ({
  phone,
  onPhoneChange,
  countdownStartTime,
  milliSecondsToWait,
  isFetching,
}) => (
  <div className={styles.container}>
    <ErrorKiwi className={styles.kiwi} />
    <div className={styles.textContainer}>
      <span className={styles.text}>We apologise for the inconvenience.</span>
      <span className={styles.text}>
        You can try using a different phone number or call your practice to book
        your appointment.
      </span>
    </div>
    <ResendForm
      isWhite={false}
      phone={phone}
      onPhoneChange={onPhoneChange}
      countdownStartTime={countdownStartTime}
      milliSecondsToWait={milliSecondsToWait}
      isFetching={isFetching}
    />
  </div>
);

NotReceiving.propTypes = {
  onPhoneChange: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  // moment
  countdownStartTime: PropTypes.instanceOf(moment).isRequired,
  milliSecondsToWait: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default NotReceiving;
