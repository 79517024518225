const actionTypes = {
  setComponentIndex: 'MAIN_SHELL_SET_COMPONENT_INDEX',
  clearComponentIndex: 'MAIN_SHELL_CLEAR_COMPONENT_INDEX',
  setWhiteBoxNotification: 'MAIN_SHELL_SET_WHITE_BOX_NOTIFICATION',
};

const actions = {
  setComponentIndex: index => ({
    type: actionTypes.setComponentIndex,
    payload: {
      index,
    },
  }),
  clearComponentIndex: () => ({
    type: actionTypes.clearComponentIndex,
  }),
  setWhiteBoxNotification: payload => ({
    type: actionTypes.setWhiteBoxNotification,
    payload,
  }),
};

export { actions, actionTypes };
