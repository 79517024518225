import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { map } from 'lodash/fp';
import Masonry from 'react-masonry-component';
import { CSSTransitionGroup } from 'react-transition-group';

import { actions as appActions } from '../../App/actions/actions';
import { actions as verifyActions } from '../actions/verifyActions';
import { actions as headerActions } from '../../components/UIContainer/actions/headerActions';
import { actions as scriptActions } from '../../PrescriptionsPage/actions/actions';
import { actions as feedActions } from '../actions/actions';
import { actions as profileActions } from '../../components/UIContainer/MobileUI/MainShell/actions/profileActions';
import { actions as apptActions } from '../../AppointmentsPage/actions/actions';
import { ReactComponent as CalendarIcon } from '../../svgs/mainCalendar.svg';
import { ReactComponent as ScriptsIcon } from '../../svgs/mainScript.svg';
import DesktopPopUp from '../../components/DesktopPopUp';
import UIContainer from '../../components/UIContainer';
import desktopAppointment from '../../AppointmentsPage/AppointmentListDesktop/CurrentAppointment';
import desktopScript from '../../PrescriptionsPage/PrescriptionListDesktop/CurrentScript';
import AccountVerificationPage from '../AccountVerificationPage';
import SectionContainer from './SectionContainer';
import SectionContainerFull from './SectionContainerFull';
import styled from 'styled-components';
import MakeAnAppointment from './MakeAppointmentCard';
import AccountVerify from './VerifyAccountCard';
import PrivacyPolicy from '../PrivacyPolicy';
import Alert from '../../libs/Alert';
import { appointmentPropType } from '../../AppointmentsPage/propTypes';
import { userPropType } from '../../components/UIContainer/MobileUI/Menu/propTypes';
import { scriptPropType } from '../../PrescriptionsPage/propTypes';
import styles from './healthFeedDesktop.module.css';
import { go } from '../../routes';
import { transitionGroupProps } from '../../config';
import FullBanner from 'components/DesktopComponents/FullBanner';
import Notifications from './Notifications/Notifications.tsx';
import Loading from 'components/loadingSpinner';
import { Activity2PopupsSet } from '../PopupData';
import AlterablePopup from 'components/shared/common/AlterablePopup';
import store from 'store/identity';
import LoadingSpinner from 'components/shared/common/LoadingSpinner';

const Background = styled.div`
    background-color: #F5F5F5;
    position: absolute;
    height: 100%;
    transition: all 0.3s linear;
    z-index: 10;
    visibility: visible;
`
const createAppointments = (appointments, onApptClick) =>
  appointments.length
    ? map(a => desktopAppointment(a, onApptClick))(appointments)
    : [];
const createScripts = (scripts, onScriptClick) =>
  scripts.length ? map(s => desktopScript(s, onScriptClick))(scripts) : [];
/*const createMessages = messages =>
  messages.length ? map(m => <div>{m.FirstName}</div>)(messages) : [];*/

class HealthFeedDesktop extends Component {
  static propTypes = {
    addAppStyles: PropTypes.func.isRequired,
    removeAppStyles: PropTypes.func.isRequired,
    setSideHeaderItem: PropTypes.func.isRequired,
    fetchFeed: PropTypes.func.isRequired,
    fetchAppt: PropTypes.func.isRequired,
    fetchScript: PropTypes.func.isRequired,
    fetchCurrentRequests: PropTypes.func.isRequired,
    fetchPastRequests: PropTypes.func.isRequired,

    getProfile: PropTypes.func.isRequired,
    setFirstTime: PropTypes.func.isRequired,
    appointments: PropTypes.arrayOf(appointmentPropType),
    profile: userPropType,
    token: PropTypes.string.isRequired,
    userName: PropTypes.string,
    firstTime: PropTypes.bool,
    isFetching: PropTypes.bool,
    scripts: PropTypes.arrayOf(scriptPropType),
    messages: PropTypes.arrayOf(PropTypes.string),
  };
  state = {
    showPopUp: false,
    showWaiting: false,
  };
  componentDidMount() {
    this.props.addAppStyles([styles.app]);
    this.props.fetchFeed(this.props.token);
    this.props.getProfile(this.props.token);
    this.props.setSideHeaderItem('healthFeed');
  }
  
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.profile.MobileVerified) {
      this.setState({ showPopUp: false });
    }
    if (nextProps.firstTime) {
      Alert.success('Welcome! You have successfully registered with Vensa.');
      this.props.setFirstTime(false);
    }
  }
  componentWillUnmount() {
    this.props.removeAppStyles([styles.app]);
  }
  togglePopUp = () => {
    this.setState({ showPopUp: !this.state.showPopUp });
  };
  handleApptClick = id => () => {
    this.props.fetchAppt(id, this.props.token);
    go.appointmentDetails(id, this.props.token);
  };
  handleScriptClick = id => () => {
    const token = this.props.token;
    this.props.fetchScript(id, token);
    this.props.fetchCurrentRequests(token);
    this.props.fetchPastRequests(token);
    go.prescriptionDetails(id);
  };

  handleSure = () => {
    this.setState({showWaiting: true});
    store.skipPinCode(this.props.profile.LatestPracticeId);
  }

  handleSame = () => {
    this.setState({showWaiting: true});
    store.employPinCode();
  }
  render() {
    const {
      userName,
      appointments,
      scripts,
      profile,
      isFetching,
      feed
    } = this.props;
    const onboardingModel = Activity2PopupsSet({
      ...this.props.profile, 
      handleSure: this.handleSure, 
      handleSame: this.handleSame
    })[this.props.profile.IdentitySetupPopup];
    return (
      <>
        {
          this.state.showWaiting && <LoadingSpinner animateStyle={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}/>
        }
        {
          !this.state.showWaiting && onboardingModel && <AlterablePopup
            modelData={onboardingModel}
          />
        }
        <UIContainer isFetching={isFetching} id="nw_healthFeed" navKey='My Health Feed'>
          <Background style={{visibility: isFetching ? "visible" : "hidden"}} />   
          <div className={styles.outerContainer}>
            <Notifications feed={this.props.feed} getFeed={() => this.props.fetchFeed(this.props.token)}/>
            <div className={styles.container}>
              {profile.Id && (
                <Masonry className={styles.feedContainer}>
                  {this.props.feed.length > 1 && this.props.feed[0].Type === "MobileNotVerified" &&
                    <SectionContainer
                      elements={[
                        <AccountVerify onClick={go.userAccount} />
                      ]}
                      heading={
                        'Account Verification'
                      }
                      activeHeader={false}
                    />
                  }
                  <SectionContainer
                    elements={[
                        <MakeAnAppointment onClick={go.appointments} />
                    ]}
                    heading={
                      'Access Better Health and Well-being'
                    }
                    activeHeader={false}
                  />
            
                  <SectionContainer
                    elements={createAppointments(
                      appointments,
                      this.handleApptClick,
                    )}
                    heading="Upcoming Itinerary"
                    emptyIcon={<CalendarIcon className={styles.calIcon} />}
                    emptyText="You have no upcoming Itinerary"
                    onClick={go.appointments}
                  />
                  <div>
                  <SectionContainerFull
                    elements={createScripts(scripts, this.handleScriptClick)}
                    heading="YOUR HEALTH TIMELINE"
                    fullemptyIcon={<ScriptsIcon className={styles.scriptsIcon} />}
                    fullemptyText="No requests have been made"
                    onClick={go.prescriptions}
                  />
                  </div>
                  <PrivacyPolicy />
                </Masonry>
              )}
            </div>
            <CSSTransitionGroup {...transitionGroupProps(styles)}>
              {this.state.showPopUp && (
                <DesktopPopUp toggle={this.togglePopUp}>
                  <AccountVerificationPage cancel={this.togglePopUp} />
                </DesktopPopUp>
              )}
            </CSSTransitionGroup>
          </div>
        </UIContainer>
      </>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profileReducer.profile,
  firstTime: state.profileReducer.firstTime,
  token: state.loginReducer.token,
  userName: state.profileReducer.profile.FirstName,
  appointments: state.feedReducer.appointments,
  scripts: state.feedReducer.scripts,
  messages: state.feedReducer.messages,
  isFetching: state.feedReducer.isFetching && state.profileReducer.isFetching,
  feed: state.feedReducer.feed,
});
const mapDispatchToProps = dispatch => ({
  /* eslint-disable max-len */
  addAppStyles: bindActionCreators(appActions.addAppStyles, dispatch),
  removeAppStyles: bindActionCreators(appActions.removeAppStyles, dispatch),
  setSideHeaderItem: bindActionCreators(
    headerActions.setSideHeaderItem,
    dispatch,
  ),
  getVerificationStatus: bindActionCreators(verifyActions.getVerificationStatus, dispatch),
  fetchFeed: bindActionCreators(feedActions.getFeed, dispatch),
  fetchAppt: bindActionCreators(apptActions.fetchApptDetails, dispatch),
  fetchScript: bindActionCreators(scriptActions.fetchScriptDetails, dispatch),
  fetchCurrentRequests: bindActionCreators(
    scriptActions.fetchCurrentRequests,
    dispatch,
  ),
  fetchPastRequests: bindActionCreators(
    scriptActions.fetchPastRequests,
    dispatch,
  ),
  setFirstTime: bindActionCreators(profileActions.setFirstTime, dispatch),
  getProfile: bindActionCreators(profileActions.getProfile, dispatch),
  /* eslint-enable max-len */
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HealthFeedDesktop);
