import React from 'react';
import { makeAutoObservable } from 'mobx';
import service, { CareCircleService } from './service';
import { DEFAULT_GUID, HttpStatusCode } from 'common/constants';
import download from 'js-file-download';
import { Dependant } from 'types/careCircle';

/**
 * Global store of CareCircle
 */
export class CareCircleStore {
    declareDate?: string;
    // document?: File;
    dependants?: Dependant[];

    service: CareCircleService;
    constructor(service: CareCircleService){
        makeAutoObservable(this, {
            service: false, 
            /*document: false*/});
        this.service = service;
    }

    async getDeclareDate(dependantId: string){
        
        if(dependantId !== DEFAULT_GUID){
            const {data, status} = await this.service.queryDeclareDate(dependantId);
            if(status === HttpStatusCode.OK){
                this.declareDate = data;
            }else{
                this.declareDate = undefined;
            }
        }
        return this.declareDate;
    }

    async downloadDeclarationDocument(dependantId: string){
        // if(!this.document){
        //     const {data, status} = await this.service.downloadDeclarationFile(dependantId);
        //     if(status === HttpStatusCode.OK){
        //         this.document = data;
        //         console.log('download from server ---> ' + this.document?.name);
        //     }
        // }
        // if(this.document) {
        //     console.log('download from client ---> ' + this.document.name);
        //     download(this.document, `${dependantId}.pdf`);
        // }
        const {data, status} = await this.service.downloadDeclarationFile(dependantId);
            if(status === HttpStatusCode.OK && data){
                download(data, `${dependantId}.pdf`);
            }
    }

    async getDependants() {
        const {data, status} = await this.service.queryDependants();
        if(status === HttpStatusCode.OK){
            this.dependants = data;
        }
    }

    async removeDependant(id: string) {
      const {data, status} = await this.service.removeDependant(id);
      if(status !== HttpStatusCode.OK) {
        throw new Error(`Remove dependant(${id}) failed, please retry!`);
      }
    }
     
}

export default new CareCircleStore(service);