import React from 'react';
import PropTypes from 'prop-types';

import { medicationPropType } from '../../../propTypes/index';
import { ReactComponent as Cross } from '../../../../svgs/close.svg';
import styles from './selectedMeds.module.css';

const SelectedMeds = ({ meds, remove }) => (
  <div className={styles.selection}>
    {meds.map(med => (
      <div key={med.Id} className={styles.item}>
        <div className={styles.name}>{med.Name}</div>
        <Cross onClick={() => remove(med)} className={styles.cross} />
      </div>
    ))}
  </div>
);

SelectedMeds.propTypes = {
  meds: PropTypes.arrayOf(medicationPropType),
  remove: PropTypes.func.isRequired,
};

export default SelectedMeds;
