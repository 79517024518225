import React, { PropsWithChildren } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, IBox } from 'components/vensaComponents';

export const TabBox = observer(({ children, ...rest }: PropsWithChildren<IBox>) => (
  <Box
    mt={2}
    as="main"
    display="block"
    p={0}
    height="calc( 100vh - 60px - 35px - 50px - 120px)"
    pb="64px"
    overflowY="auto"
    {...rest}
  >
    {children}
  </Box>
));
