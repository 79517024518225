import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components'

import { Box, Text } from 'components/vensaComponents';


const ToggleContainer = styled.div`
    position: relative;
    width: 42px;
    border-radius: 12px;
    transition: 0.5s;
    height: fit-content;
`;

const Button = styled.div`
    width: 23px;
    height: 23px;
    border-radius: 50%;
    background-color: white;
    transition: 0.5s;
    border: 1px solid lightgray;
`;

const Toggle: React.FC<{
    value: boolean;
    onClick: () => void;
    labelElement: string | JSX.Element;
    disabled?: boolean;
}> = observer(({ value, labelElement, onClick = () => {}, disabled }) => {
    const onChange = () => {
        onClick();
        //setToggle(!toggle);
    }

    return (
        <Box alignItems="center" justifyContent="between" onClick={disabled ? () => {} : onChange}>
            <Text flexGrow={1} color={disabled ? "#DADADA":"primary"}>
                {labelElement}
            </Text>
            {!disabled ?
                (
                    <ToggleContainer style={{backgroundColor: value ? "#23C373" : "#FF6A65"}}>
                        <Button style={{transform: value ? "translateX(22px)" : ""}}/>
                    </ToggleContainer>
                )
                :
                (
                    <ToggleContainer style={{backgroundColor: "#DADADA"}}>
                        <Button />
                    </ToggleContainer>
                )
            }
        </Box>
    );
});

export default Toggle;