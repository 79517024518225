import { call, takeEvery, put } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/verifyActions';
import { GenericParserHttp } from '../../libs/Http';

const api = token =>
  GenericParserHttp.get('consumer/identity/verification-status', { token }).then(
    ({ body }) => body,
  );

function* getVerify({ payload: { id } }) {
  try {
    const data = yield call(api, id);
    yield put(actions.getVerificationStatusSuccess(data));
  } catch (err) {
    yield put(actions.getVerificationStatusFailure(err));
  }
}

function* verifySaga() {
  yield takeEvery(actionTypes.getVerificationStatus, getVerify);
}

export { verifySaga };
