import React, { useEffect } from 'react';
import UIContainer from 'components/UIContainer';
import { observer } from 'mobx-react-lite';
import { Box } from '../../../components/vensaComponents';
import { usePaymentsStore } from '../../../store/mobx/mobx';
import { HistoryListItem } from './HistoryListItem';

const TransactionHistory: React.FC = observer(() => {
  const store = usePaymentsStore();

  useEffect(() => {
      store.getTransactionHistory();
  }, [store]);

  return (
    <UIContainer heading="Transaction History" isFetching={store.healthTransactionHistoryLoadingStatus === 'LOADING'}>
      <Box width={1} direction="column" gap="15px" pb="16px" mb='50px'>
        {store.healthTransactionHistory.map(item => (
          <HistoryListItem key={item.Id} {...item} />
        ))}
      </Box>
    </UIContainer>
  );
});

export default TransactionHistory;
