import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import UIContainer from '../../../components/UIContainer';
import RouteBar from '../../../components/DesktopComponents/RouteBar';
import TabBar from '../../../components/DesktopComponents/TabBar';
import { useAccountStore, usePracticesStore } from 'store/mobx/mobx';
import { useComponentDidMount } from 'libs/newHttp';
import { go } from 'routes';
import { IDetailedPractice } from 'store/mobx/practicesStore/practiceStore.type';
import PrivacyToggle from '../../../Privacy/components/PrivacyToggle';
import { ReactComponent as Arrow } from 'svgs/blueRightCircleArrow.svg'; 

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #F9F9F9;
    overflow: auto;
`
const InnerContainer = styled.div`
    width: 500px;
    margin 1rem auto;
`
const Title = styled.div`
    text-transform: uppercase;
    font-size: 13px;
    margin-top: 1rem;
    text-align: left;
    margin-bottom: 2rem;
    margin 2rem auto;
    width: 460px;
    margin-bottom: 0px;
`
const Inner = styled.div`
    border-radius: 4px;
    background-color: white;
    width: 460px;
    margin: 2rem auto;
    box-shadow: 1px 1px 1px lightgray;
    position: relative;
`
const PageTitle = styled.div`
    font-size: 17px;
    width: 100%;
    margin: auto;
    font-weight: 600;
    color: #585858;
    text-align: center;
    margin-bottom: 0.3rem;
`
const Address = styled.div`
    font-size: 13px;
    width: 80%;
    margin: auto;
    text-align: center;
    color: #585858;
`
const Pic = styled.img`
    margin: auto;
    margin-top: 2rem;
    display: block;
    width: 320px;
`
const SettingsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2rem;
    margin-top: 0.5rem;
`
const SettingsTitle = styled.div`
    font-size: 15px;
    color: #6C6C6C;
    padding: 0.6rem 0rem;
    text-align: left;
    font-weight: 600;
`
const Footer = styled.div`
    text-align: left;
    font-size: 12px;
    color: #585858;
`
const PracticeConditions = styled.div`
    font-size: 15px;
    font-weight: 600;
    color: #989898;
    margin: 2rem 0rem 0.2rem 0rem;
`
const DarkLine = styled.div`
    border-bottom: 1px solid #DADADA;
    position: absolute;
    width: 100%;
    left: 0px;
`
const LightLine = styled.div`
    border-bottom: 1px solid #F0F0F0;
    position: absolute;
    width: 100%;
    left: 0px;
`

const DesktopPrivacy: React.FC = observer(() => {
    const accStore = useAccountStore();
    const practiceStore = usePracticesStore();

    const [practice, setPractice] = useState<IDetailedPractice>(practiceStore.selectedPractice)

    useComponentDidMount(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let orgId = params.get('id');

        if (orgId) {
            practiceStore.getPracticeDetails(orgId, (practice) => {
               setPractice(practice);
            });
            accStore.getUserPrivacySettingsWithOrg(orgId, () => {});
        }
    })

    return (
        <UIContainer isFetching={accStore.loadingRequests || accStore.loading}>
            <Container>
                <RouteBar 
                    lowLevel={"Privacy"}
                    lowLink={() => go.privacy()}
                />
                <TabBar type={0} id={2} />
                <Title>Your Privacy with Health Providers</Title>
                    <Inner>
                        <Arrow onClick={go.privacy} style={{ overflow: "visible", cursor: "pointer", transform: "rotate(180deg)", position: "absolute", top: "1rem", left: "1rem"}} />
                        <PageTitle style={{marginTop: "2rem"}}>Privacy</PageTitle>
                        <PageTitle>{practice.Name}</PageTitle>
                        <Address>{practice.Address}</Address>
                        <Pic src={practice.Pictures[0]} />
                        <SettingsContainer>
                            <SettingsTitle>Privacy Settings</SettingsTitle>
                            <div>
                                <DarkLine />
                            </div>
                            <PrivacyToggle 
                                initial={accStore.privacySettingsWithOrg.AllowAccessMyHealthRecords.Allow} 
                                toggle={() => 
                                    accStore.updateUserPrivacySettingsWithOrg(
                                        practice.Id, 
                                        () => accStore.getUserPrivacySettingsWithOrg(practice.Id, () => {}), 
                                        accStore.privacySettingsWithOrg.AllowAccessAndStoreMedications.Allow,
                                        accStore.privacySettingsWithOrg.AllowAccessMyDependantHealthRecords.Allow,
                                        accStore.privacySettingsWithOrg.AllowAccessMyDoctorNotes.Allow,
                                        !accStore.privacySettingsWithOrg.AllowAccessMyHealthRecords.Allow,
                                        accStore.privacySettingsWithOrg.AllowShareMyHealthDataWithPractices,
                                    )} 
                                text={"Vensa will access and store my health records, such as lab results and conditions"} 
                                disable={accStore.privacySettingsWithOrg.AllowAccessMyHealthRecords.Overridden}
                                small={true}
                            />
                            <div>
                                <LightLine />
                            </div>
                            <PrivacyToggle 
                                initial={accStore.privacySettingsWithOrg.AllowAccessMyDoctorNotes.Allow} 
                                toggle={() => 
                                    accStore.updateUserPrivacySettingsWithOrg(
                                        practice.Id, 
                                        () => accStore.getUserPrivacySettingsWithOrg(practice.Id, () => {}), 
                                        accStore.privacySettingsWithOrg.AllowAccessAndStoreMedications.Allow,
                                        accStore.privacySettingsWithOrg.AllowAccessMyDependantHealthRecords.Allow,
                                        !accStore.privacySettingsWithOrg.AllowAccessMyDoctorNotes.Allow,
                                        accStore.privacySettingsWithOrg.AllowAccessMyHealthRecords.Allow,
                                        accStore.privacySettingsWithOrg.AllowShareMyHealthDataWithPractices,
                                    )} 
                                text={"Vensa will access and store consultation and history notes"} 
                                disable={accStore.privacySettingsWithOrg.AllowAccessMyDoctorNotes.Overridden}
                                small={true}
                            />
                            <div>
                                <LightLine />
                            </div>
                            <PrivacyToggle 
                                initial={accStore.privacySettingsWithOrg.AllowAccessAndStoreMedications.Allow} 
                                toggle={() => 
                                    accStore.updateUserPrivacySettingsWithOrg(
                                        practice.Id, 
                                        () => accStore.getUserPrivacySettingsWithOrg(practice.Id, () => {}), 
                                        !accStore.privacySettingsWithOrg.AllowAccessAndStoreMedications.Allow,
                                        accStore.privacySettingsWithOrg.AllowAccessMyDependantHealthRecords.Allow,
                                        accStore.privacySettingsWithOrg.AllowAccessMyDoctorNotes.Allow,
                                        accStore.privacySettingsWithOrg.AllowAccessMyHealthRecords.Allow,
                                        accStore.privacySettingsWithOrg.AllowShareMyHealthDataWithPractices,
                                    )} 
                                text={"Vensa will access and store my medications list and history"} 
                                disable={accStore.privacySettingsWithOrg.AllowAccessAndStoreMedications.Overridden}
                                small={true}
                            />
                            <div>
                                <LightLine />
                            </div>
                            <Footer style={{marginTop: "5px"}}>
                                The facility have enabled access for their patients to their health records, and permitted Vensa to store the information on your behalf
                            </Footer>
                            {(accStore.privacySettingsWithOrg.AllowAccessMyDoctorNotes.Overridden ||
                                accStore.privacySettingsWithOrg.AllowAccessMyDependantHealthRecords.Overridden ||
                                accStore.privacySettingsWithOrg.AllowAccessMyHealthRecords.Overridden ||
                                accStore.privacySettingsWithOrg.AllowAccessAndStoreMedications.Overridden) &&

                                <div>
                                    {/*}
                                    <PracticeConditions>Practice Conditions</PracticeConditions>
                                    <Footer>                                
                                        {accStore.privacySettingsWithOrg.AllowAccessMyDoctorNotes.Overridden ?
                                        accStore.privacySettingsWithOrg.AllowAccessMyDoctorNotes.Reason :
                                        accStore.privacySettingsWithOrg.AllowAccessMyHealthRecords.Overridden  ?
                                        accStore.privacySettingsWithOrg.AllowAccessMyHealthRecords.Reason :
                                        accStore.privacySettingsWithOrg.AllowAccessMyDependantHealthRecords.Overridden ?
                                        accStore.privacySettingsWithOrg.AllowAccessMyDependantHealthRecords.Reason : 
                                        accStore.privacySettingsWithOrg.AllowAccessAndStoreMedications.Overridden ?
                                        accStore.privacySettingsWithOrg.AllowAccessAndStoreMedications.Reason : ""
                                        }
                                </Footer>
                                    */}
                                </div>
                            }
                        </SettingsContainer>
                    </Inner>
            </Container>
        </UIContainer>
    )
})

export default DesktopPrivacy;