import { FlattenInterpolation } from 'styled-components';
import { CSSType } from '../components/Box/BaseBox.type';
import { is } from '../utils/utils';
import { ThemeType } from './index';

export const getMediaQuery = (
  screenSize: number,
  css: string | FlattenInterpolation<ThemeType>,
) => 
  `@media screen and (min-width: ${screenSize}px}) {
    ${css};
  }`;

export const getTransitionValue = (propertyNames: string = 'transform') =>
  `${propertyNames} 0.3s cubic-bezier(0.53, 0.01, 0.36, 1.63);`;

export function getSafeValue<T>(target: unknown, defaultValue: T) {
  return is.notExist(target) ? defaultValue : target;
}

export function getSafeCss(target?: CSSType) {
  return getSafeValue(target, '') as CSSType;
}
