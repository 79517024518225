import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as Close } from 'svgs/close.svg';
import { ReactComponent as Arrow } from 'svgs/leftlinearrow.svg';
import { usePracticesStore, useAccountStore } from 'store/mobx/mobx';
import { ReactComponent as Search } from 'svgs/glass.svg';
import Loading from 'components/loadingSpinner';
import PicScroller from 'components/DesktopComponents/PicScroller';
import { observer } from 'mobx-react-lite';
import { go } from '../../routes';
import { useComponentDidMount } from 'libs/newHttp';

const Container = styled.div<{show?: string}>`
    display: flex;
    opacity: ${props => props.show ? "1" : "0"}
    transition: all 0.5s;
    background-color: white;
`
const Overlay = styled.div<{show?: string}>`
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left 0;
    width: 100vw;
    height: 100vh;
    opacity: ${props => props.show ? "0.6" : "0"}
    z-index: 999;
    transition: all 0.5s;
    cursor: pointer;
`
const Inner = styled.div`
    margin: auto;
    background-color: white;
    width: 100%;
    height: 100%;
`
const Title = styled.div`
    font-size: 17px;
    text-align: center;
    color: #585858;
`
const Subtitle = styled.div`
    width: 100%;
    font-size: 13px;
    text-align: center;
    margin: 0.4rem 0rem;
`
const Input = styled.input`
    border-radius: 4px;
    border: none;
    border: 2px solid #DADADA
    width: 84%;
    padding: 10px;
    outline: none;
    &::placeholder {
        font-style: italic;
    }
    margin: 0rem 2rem;
    font-size: 1.3rem;
`
const Item = styled.li`
    list-style-type: none;
    font-size: 1.3rem;
    color: #07A3C8;
    text-align: left;
    padding: 1rem;
    cursor: pointer;
`
const Heading = styled.div`
    font-size: 15px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 5px;
`
const Button = styled.div`
    background-color: #07A3C8;
    color: white;
    border-radius: 4px;
    width: 142px;
    cursor: pointer;
    padding: 10px 3px;
    margin: 10px auto;
    text-align: center;
    font-size: 13px;
`
const Link = styled.div`
    color: #07A3C8;
    padding: 10px 3px;
    margin: 10px auto;
    text-align: center;
    cursor: pointer;
    font-size: 13px;
    width: 50px;
`
const FlexH = styled.div`
    display: flex;
    flex-direction: row;
`
const Line = styled.div`
    border: none;
    width: 95%;
    border-bottom: 2px solid #DADADA;
    margin: 0.5rem 0rem;
`
const SearchContainer = styled.ul`
    overflow-y: scroll; 
    height: 100%;
    margin-top: 1rem;
    margin-left: -2rem;
    margin-bottom: 1rem;

    &::-webkit-scrollbar {
       width: 0px;
    }
      
    &::-webkit-scrollbar-track {
        background: #F0F0F0;
        border-radius: 25px;
    }
    
    &::-webkit-scrollbar-thumb {
        background: #AFAFAF;
        border-radius: 25px;
    }
    
    &::-webkit-scrollbar-thumb:hover {
        background: #999; 
    }
`
/*
const list = [
    {
        PmsUserId: "66c8c306-6941-5e45-d3c2-03f65b12f0ba",
        Type: "",
        Name: "Flu Clinic",
        Picture: "https://stormdevtest.blob.core.windows.net/pmsuser/clinic/00000000-0000-0000-0000-000000000003.png",
        UrlName: "Flu Clinic",
    },
    {
        PmsUserId: "7086cecb-5c62-d28d-5ee8-420e585a14ce",
        Type: "General Practitioner",
        Name: "Dr Denesh Jindal",
        Picture: "https://stormdevtest.blob.core.windows.net/pmsuser/184c1b3a-6887-ede1-17c4-d9028e9e7f32/7086cecb-5c62-d28d-5ee8-420e585a14ce.jpg?bb6d84f1-a84b-42b8-9a2d-4e239a626628",
        UrlName: "Dr Denesh Jindal",
    },
    {
        PmsUserId: "5734b8f8-17a6-0c24-d568-4280e65e0470",
        Type: "Practice Nurse",
        Name: "Barbara Williams",
        Picture: "https://stormdevtest.blob.core.windows.net/pmsuser/184c1b3a-6887-ede1-17c4-d9028e9e7f32/5734b8f8-17a6-0c24-d568-4280e65e0470.jpg?bb6d84f1-a84b-42b8-9a2d-4e239a626628",
        UrlName: "Barbara Williams",
    },
    {
        PmsUserId: "556503b3-240a-c507-ccbd-9859525cd01b",
        Type: "General Practitioner",
        Name: "Dr June Morissette",
        Picture: "https://stormdevtest.blob.core.windows.net/pmsuser/184c1b3a-6887-ede1-17c4-d9028e9e7f32/556503b3-240a-c507-ccbd-9859525cd01b.jpg?bb6d84f1-a84b-42b8-9a2d-4e239a626628",
        UrlName: "Dr June Morissette",
    }
]*/

interface Props {
    onClose: () => void;
    userHasPractices: boolean;
}

const PracticeSearch: React.FC<Props> = observer(({onClose, userHasPractices}) => {
    const store = usePracticesStore();
    const accStore = useAccountStore();
    const selectedPractice = store.selectedPractice;
    let allPractices = store.practices.Practices;
    const userPractices = store.userPractices.Practices;

    const [text, setText] = useState("");
    const [allTemp, setAllTemp] = useState(allPractices);
    const [userTemp, setUserTemp] = useState(store.userPractices.Practices);
    const [state, setState] = useState(0);
    const [check, setCheck] = useState(true);
    const [show, setShow] = useState(true);
    
    useComponentDidMount(() => {
		store.getAllPractices(() => console.log("got all practices again"));
    })

    useEffect(() => {
        if (allPractices !== store.practices.Practices) { 
            allPractices = store.practices.Practices;
            setAllTemp(allPractices);
        }

        if (store.receivedAllPractices && check) {
            setAllTemp(allPractices);
            setCheck(false);
        }
    })
    
    setTimeout(() => {
        setShow(true);
    }, 0);

    const searchPractices = (e: any) => {
        let arr1: any = [];
        let arr2: any = [];
        
        if (allPractices.length !== 0) {
            allPractices.map((practice: any) => { // eslint-disable-line array-callback-return
                if (practice.Name === null) { return; }
                if (practice.Name.toLowerCase().includes(e.target.value.toLowerCase())) { arr1.push(practice) }
            });
        }
        userPractices.map((practice: any) => { // eslint-disable-line array-callback-return
            if (practice.Name.toLowerCase().includes(e.target.value.toLowerCase())) { arr2.push(practice) }
        });

        setAllTemp(arr1);
        setUserTemp(arr2);
        setText(e.target.value);
    }
    const onClick = (id: string) => {
       // store.getPracticeDetails(id);
       // setState(1);
       store.linkPractice(id, () => onClose());
    }

    return (
        <Container>
            {state === 0 && 
                <Inner>
                    <Loading style={{fill: "#07A3C8"}} isFetching={store.loading}>
                        <Input autoFocus={true} placeholder={"Type the practice name"} onChange={(e) => searchPractices(e)} value={text}/>
                        <SearchContainer>
                            {userHasPractices && 
                                <FlexH>
                                    <Search style={{width: "25px", transform: "translateX(-9px)"}}/>
                                    <Heading>Your practice(s)</Heading>                        
                                </FlexH>
                            }
                            {userHasPractices && userTemp.map((practice: any) => {
                                if (!practice.IsUsingVensa) {
                                    return (
                                        <Item 
                                            style={{color: "#B3B3B3", cursor: "auto"}} 
                                            key={practice.PracticeId}>
                                                {practice.Name} 
                                                <span style={{color: "#FC7B7B", marginLeft: "5px"}}>
                                                    (Not using Vensa)
                                                </span>
                                        </Item>
                                    )
                                } else if (!practice.IsOnline) {
                                    return (
                                        <Item
                                            onClick={() => onClick(practice.PracticeId)}
                                            key={practice.PracticeId}
                                        >
                                            {practice.Name}
                                            <span style={{color: "#FC7B7B", marginLeft: "5px"}}>
                                                (Offline)
                                            </span>
                                        </Item>
                                    )
                                }
                                return(<Item onClick={() => onClick(practice.PracticeId)} key={practice.PracticeId}>{practice.Name}</Item>)
                            })}
                            {userHasPractices && <Line />}
                            {allTemp.map((practice: any) => {
                                if (!practice.IsUsingVensa) {
                                    return (
                                        <Item 
                                            style={{color: "#B3B3B3", cursor: "auto"}} 
                                            key={practice.PracticeId}>
                                                {practice.Name} 
                                                <span style={{color: "#FC7B7B", marginLeft: "5px"}}>
                                                    (Not using Vensa)
                                                </span>
                                        </Item>
                                    )
                                } else if (!practice.IsOnline) {
                                    return (
                                        <Item
                                            onClick={() => onClick(practice.PracticeId)}
                                            key={practice.PracticeId}
                                        >
                                            {practice.Name}
                                            <span style={{color: "#FC7B7B", marginLeft: "5px"}}>
                                                (Offline)
                                            </span>
                                        </Item>
                                    )
                                }
                                return(<Item onClick={() => onClick(practice.PracticeId)} key={practice.PracticeId}>{practice.Name}</Item>)
                            })}
                        </SearchContainer>
                    </Loading>
                </Inner>
            }
            {state === 1 && store.receivedSelectedPractice &&
                <Inner style={{padding: "3rem", height: "auto"}}>
                    <Loading isFetching={store.loading}>
                        <FlexH>
                            <Arrow style={{width:"20px", cursor: "pointer", position: "absolute", top: "50px", left: "33px"}} onClick={() => setState(0)} />
                            <Title style={{fontWeight: 600, width: "100%"}}>{selectedPractice.Name}</Title>
                        </FlexH>
                        <Subtitle>{selectedPractice.Address}</Subtitle>
                        <img src={selectedPractice.Pictures[0]} alt={"pic"} style={{margin: "1rem 0rem", width: "100%"}} />
                        <PicScroller list={selectedPractice.Doctors} />
                        <Button onClick={() => {  
                            store.linkPractice(selectedPractice.PracticeId, () => { 
                                accStore.getIdentityRequestsStatus(() => {}); 
                                onClose(); 
                            });
                        }}>Verify here</Button>
                        <Link onClick={() => setState(0)}>Cancel</Link>
                    </Loading>
                </Inner>
            }
        </Container>
    );
})

export default PracticeSearch