import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Arrow } from '../../../svgs/rightarrow2.svg';
import { ReactComponent as Cross } from '../../../svgs/close.svg';
import styles from './navigationBar.module.css';

const NavigationBar = ({ goBack, close }) => (
  <div className={styles.nav}>
    {goBack ? <Arrow className={styles.arrow} onClick={goBack} /> : <div /> // to keep cross positioned correctly
    }
    {close &&
      <Cross className={styles.cross}  onClick={close} />
    }
  </div>
);

NavigationBar.propTypes = {
  goBack: PropTypes.func,
  close: PropTypes.func,
};

export default NavigationBar;
