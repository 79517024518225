import React, { ComponentType } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';
import { Subtract } from 'types/mappedTypes';

// type SetDifference<A, B> = A extends B ? never : A;
// type SetComplement<A, A1 extends A> = SetDifference<A, A1>;
// type Subtract<T extends T1, T1 extends object> = Pick<
//   T,
//   SetComplement<keyof T, keyof T1>
// >;

export interface FieldProps {
    id: string;
    handleChange?: (name: string, value: string) => void;
    value?: string;
    title?: string;
}

export interface Touchable {
    touch?: boolean
}

const MakeField = <P extends FieldProps & Touchable>(
    Field: ComponentType<P>,
    touch: boolean = false
): React.FC<P> => {

    const FieldComponent: React.FC<Subtract<P, FieldProps>> = (props) => 
    (<Field {...props as P} touch={touch}></Field>);

    const component: React.FC<P & {style?: React.CSSProperties}> = ({style, title, ...rest}) => (
        <div className={cn(styles.container, {[styles.touch]: touch})} style={style}>
            <FieldComponent {...rest as P} />
            <label htmlFor={title}>{title}</label>
        </div>
    );
    return component;
};

export default MakeField;