import React from 'react';
import { ProtectedRoute, push } from '../utils';
import { isDesktop } from '../../config';
import AppointmentListDesktop from '../../AppointmentsPage/AppointmentListDesktop';
import AppointmentDetailsDesktop from '../../AppointmentsPage/AppointmentDetailsDesktop';
import AppointmentDetailMobile from 'pages/mobile/domain/Appointment/BookedAppointment/BookedDetailCard'
import AppointmentsList from 'AppointmentsPage/AppointmentListMobile';
import BookedDetailCard from 'pages/mobile/domain/Appointment/BookedAppointment/BookedDetailCard';
import PastDetailCard from 'pages/mobile/domain/Appointment/PastAppointment/PastDetailsCard';

const go = {
  appointments: () => push('/appointments'),
  appointmentDetails: id => push(`/appointment/${id}`),
};

const AppointmentsRoutes = [
  <ProtectedRoute
    key="/appointments"
    path="/appointments"
    component={isDesktop() ? AppointmentListDesktop : AppointmentsList}
  />,
  <ProtectedRoute
    key='/appointment/booked/:id'
    path='/appointment/booked/:id'
    component={BookedDetailCard}
   />,
   <ProtectedRoute
    key='/appointment/past/:id'
    path='/appointment/past/:id'
    component={PastDetailCard}
    />,
  <ProtectedRoute
    key="/appointment/:id"
    path="/appointment/:id"
    component={AppointmentDetailsDesktop}
  />
  
];

export default AppointmentsRoutes;
export { go };
