import { call, takeEvery, put } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/actions';
import { GenericParserHttp } from '../../libs/Http';
import { go } from '../../routes';

const api = (type, token) =>
  GenericParserHttp.get(`consumer/prescription/${type}`, { token }).then(
    ({ body }) => body,
  );

const removeApi = (id, token) =>
  GenericParserHttp.delete(`consumer/prescription/${id}`, { token }).then(
    ({ body }) => body,
  );

function* getCurrentRequests({ payload: { token } }) {
  try {
    const requests = yield call(api, 'current', token);
    yield put(actions.fetchCurrentRequestsSuccess(requests));
  } catch (err) {
    yield put(actions.fetchCurrentRequestsFailure(err));
  }
}

function* getPastRequests({ payload: { token } }) {
  try {
    const requests = yield call(api, 'past', token);
    yield put(actions.fetchPastRequestsSuccess(requests));
  } catch (err) {
    yield put(actions.fetchPastRequestsFailure(err));
  }
}

function* removeScript({ payload: { token, id } }) {
  try {
    yield call(removeApi, id, token);
    yield put(actions.removeScriptSuccess());
    yield put(go.prescriptions());
  } catch (err) {
    yield put(actions.removeScriptFailure(err));
  }
}

function* getCurrentRequestsSaga() {
  yield takeEvery(actionTypes.fetchCurrentRequests, getCurrentRequests);
  yield takeEvery(actionTypes.removeScript, removeScript);
}

function* getPastRequestsSaga() {
  yield takeEvery(actionTypes.fetchPastRequests, getPastRequests);
}

export { getCurrentRequestsSaga, getPastRequestsSaga };
