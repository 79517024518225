import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as Arrow } from 'svgs/rightarrow2.svg';
import { go } from 'routes';
import SelectCard from 'components/PaymentCardSelection';
import { usePaymentsStore } from 'store/mobx/mobx';
import styles from './CardSelection.module.css';
import { isDesktop } from 'config';
import { ReactComponent as Plus } from 'svgs/plus.svg';
import CtaButton from 'components/CtaButton';
import { LOADING_STATUS } from 'libs/newHttp';
import { observer } from 'mobx-react-lite';

const CardSelection = observer(({ urlName, sessionId }) => {
    const paymentStore = usePaymentsStore();
    const [selectedCard, setSelectedCard] = useState()
    const [sortedCards, setSortedCards] = useState([])

    let isFetchingCardList = paymentStore.cardsLoadingStatus === LOADING_STATUS.LOADING;

    useEffect(() => {
      if (paymentStore.cardsLoadingStatus === LOADING_STATUS.INITIAL) {
        paymentStore.cardList()
      }
    }, [paymentStore, paymentStore.cardList, paymentStore.cardsLoadingStatus]);

    useEffect(() => {
      if (isFetchingCardList || paymentStore.cardList?.length === 0) return;
      if (sortedCards.length === paymentStore.cardList?.length) return;
      const cardList = paymentStore.cardList;
      const cards = cardList?.length > 0
        ? [defaultCard(cardList), ...cardList?.filter(card => !card.IsDefault).sort((a,b) => (a.ExpMonth - b.ExpMonth) +  (a.ExpYear - b.ExpYear))]
        : []
      setSortedCards(cards)
      setSelectedCard(selectedCard ? selectedCard : defaultCard(cardList));
    }, [sortedCards, paymentStore.cardList, selectedCard, isFetchingCardList]);

    const defaultCard = cardList => {
      if (cardList?.length > 0){
        const defaultCard = cardList.filter(card => card.IsDefault)[0];
        return defaultCard
      }
      return null
    }

    const handleOptionClick = (_, card) => () => {
      if (card && !card.IsExpired){
        setSelectedCard(card)
      }
    };

    const handleCtaClick = () => {
      paymentStore.setDefaultCard(selectedCard?.Token)
      paymentStore.getCards()
      go.prepaidPage(urlName)
    };    

    return (
      <div className={styles.container}>
        {!isDesktop() && (
          <Arrow className={styles.backArrow} onClick={() => go.prepaidPage(urlName)} />
        )}
        <div className={styles.heading}>How would you like to pay?</div>
        <div className={styles.optionsOuter}>
          <div>
            {sortedCards?.length > 0 &&
              sortedCards.map(card =>
                <SelectCard
                  key={card.Token}
                  cardDetails={[card]}
                  optionClick={handleOptionClick}
                  payingOnline={true}
                  selectedCard={selectedCard?.Token === card.Token}
                />
              )
            }
          </div>
        </div>
        <div className={styles.buttons}>
          <button
            className={styles.addPaymentButton}
            onClick={() => go.appointmentPayments(urlName)}
          >
            <Plus className={styles.plus} />
            Add a Payment Method
          </button>
            <CtaButton
              className={styles.cta}
              onClick={handleCtaClick}
              active={selectedCard && !selectedCard?.IsExpired}
            />
        </div>
      </div>
    );
})

const mapStateToProps = state => ({
    urlName: state.practiceProfileReducer.profile.UrlName,
    sessionId: state.checkEnrollmentReducer.session.SessionId
})

export default connect(mapStateToProps, null)(CardSelection);
