import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import PharmacyMap from '../GoogleMaps/PharmacyMap';
import { FeaturedPharmacy, PharmacyListView } from './PharmacyContent';
import PharmListImg from '../../images/ShowPharmList.png';
import { IPharmacy } from 'store/mobx/vScriptStore/vScriptStore.type';
import { ReactComponent as SearchIcon } from '../../svgs/searchIcon2.svg';
import { ReactComponent as BackArrow } from '../../svgs/leftarrow2.svg';
import { relative } from 'path';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`

const MapContainer = styled.div`
    position: relative;
    flex: 20;
`

const SearchContainer = styled.div`
    flex: 1;
    margin: 0.25rem 0.5rem;
    height: 3rem;
    display: flex;
    border: 1px solid #e9e9e9;
    border-radius: 0.25rem;
`

const SearchHeaderContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
`

const PharmacyListContainer = styled.div<{
    testBool: boolean;
}>`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: ${props => props.testBool ? "85%" : "35%"}
    position: absolute;
    bottom: 0;
    background-color: white;
    transition: 0.5s;
`

const FeaturedPharmacyContainer = styled.div`
    overflow: scroll;
`

const StyledInput = styled.input`
    width: 100%;
    height: 100%;
    font-size: medium;
    border: none;
`

const HeaderInput = styled.input`
    border: none;
    width: 100%;
    height: 100%;
    font-size: medium;
    margin: 1rem 0;
`

const FeaturedPharmacyText = styled.div`
    text-align: center;
    margin: 0.5rem;
    color: #07a3c8;
    margin-bottom: 0rem;
`
const FeaturedButton = styled.div`
    background-color: #07a3c8;
    color: white;
    width: 100%;
    border-radius: 24px;
`
const FeaturedCardViewTab = styled.div`
    background-color: var(--color-grey-2);
    border-top: 1px solid var(--color-grey-3);
    text-align: center;
    display: flex;
    width: 100%;
    position: relative;
    bottom 0;
`

const PharmListImage = styled.img`
    margin: 1rem;
    width: 1.5rem;
    height: 1rem;
`

const PharmListText = styled.div`
    color: var(--color-pelorous);
    margin: 1rem;
    font-weight: bold;
    font-size: medium;
`

const SearchSVG = styled(SearchIcon)`
    width: 5%;
    margin: 0 0.5rem;
`

const ArrowSVG = styled(BackArrow)`
    width: 5%;
    margin: 0.7rem;
`

const DraggableDiv = styled.div`
    width: 100%;
    align-items: center;
    text-align: center;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`

const Draggable = styled.div`
    background-color: var(--color-grey-5);
    width: 10%;
    height: 5%;
    border-radius: 25rem;
    padding: 0.1rem;
`

interface IProps {
    pharmacies: IPharmacy[];
    clickedFeature: Function;
    clickedSearch: Function;
    initialLocation: any;
}

interface IProps2 {
    goBack: Function;
    handleSubmit?: Function;
}

interface IFeaturedProps {
    pharmacies: IPharmacy[];
    clickedFeature: Function;
    clickedSearch: Function;
}

const FeaturedContent: React.FC<IFeaturedProps> = ({pharmacies, clickedFeature, clickedSearch}) => {
    const [showList, setShowList] = useState(false);
    return (
        <PharmacyListContainer testBool={showList}>
                <FeaturedPharmacyText onClick={() => {
                    if (!showList) {
                        setShowList(true);
                    } else {
                        setShowList(false);
                    }
                }}>
                    Featured Nearby
                </FeaturedPharmacyText>
                <FeaturedPharmacyContainer>
                    {showList ? 
                    <PharmacyListView 
                        pharmacies={pharmacies} 
                        handleClick={clickedFeature}
                    />
                    :
                    <FeaturedPharmacy 
                        pharmacies={pharmacies}
                        handleClick={clickedFeature}
                    />
                }
                </FeaturedPharmacyContainer>
                <div style={{zIndex: 50}} onClick={() => {
                            if (!showList) {
                                setShowList(true)
                            } else {
                                setShowList(false)
                            }
                        }}>
                    <FeaturedCardViewTab>
                        <PharmListImage src={PharmListImg}/>
                        <PharmListText>
                            {showList ? "Show Pharmacy Map" : "Show Pharmacy List"}
                        </PharmListText>
                    </FeaturedCardViewTab>
                </div>
            </PharmacyListContainer>
    )
};

export const MapBody: React.FC<IProps> = ({pharmacies, clickedFeature, clickedSearch, initialLocation}) => {
    const [input, setInput] = useState("");
    
    const handleInputChange = (e: { target: { value: any; }; }) => {
        const value = e.target.value;
        setInput(value);
    }

    

    return(
        <Container>
            <SearchContainer>
                <SearchSVG/>
                <StyledInput
                    type="text"
                    placeholder={"Search for your pharmacy"}
                    onChange={handleInputChange}
                    onClick={() => clickedSearch()}
                    value={input}
                />
            </SearchContainer>
            <MapContainer
            >
                <PharmacyMap 
                    pharmacies={pharmacies}
                    clickedPharmacy={clickedFeature}
                    initialLocation={initialLocation}
                />
            </MapContainer>
            <FeaturedContent
                pharmacies={pharmacies}
                clickedFeature={clickedFeature}
                clickedSearch={clickedSearch}
            />
        </Container>    
    );
}

export const SearchBar: React.FC<IProps2> = ({goBack, handleSubmit}) => {
    const [input, setInput] = useState("");

    const handleInputChange = (e: { target: { value: any; }; }) => {
        const value = e.target.value;
        setInput(value);
        setInput((input) => {
            handleSubmit && handleSubmit(input);
            return input;
        });
    }

    return (
        <SearchHeaderContainer>
            <ArrowSVG onClick={() => goBack()}/>
            <HeaderInput
                type="text"
                placeholder={"Search for your pharmacy"}
                onChange={(e) => handleInputChange(e)}
                value={input}
                autoFocus
            />
        </SearchHeaderContainer>
    );
}