import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import PinControl from 'Identity/DesktopIdentityPage/components/PinControl';
import lock from 'images/lock.png';
import { go } from 'routes';
import BlueButton from 'components/DesktopComponents/BlueButton';
import { useAccountStore } from 'store/mobx/mobx';
import PinCodeButton from '../../Identity/components/PinCodeButton';
import fourPin from 'svgs/4Pin.svg'
import sixPin from 'svgs/6Pin.svg';

const Inner = styled.div`
    background-color: white;
    margin: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0px 3px 6px #00000029
    padding: 0px 25px;
    height: -webkit-fill-available;
    border-radius: 4px;
`
const Text = styled.div`
    text-align: center;
    color: #07A3C8;
    font-size: 17px;
    margin-bottom: 1rem;
`
const Link = styled.div`
    font-size: 13px;
    color: #07A3C8;
    margin: 1.5rem auto;
    cursor: pointer;
`
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const Line = styled.div`
    border-bottom: 2px solid #DADADA;
    width: 100%;
`
const Lock = styled.img`
    display: block;
    margin: 2rem auto;
    height: 132px;
    width: 140px;
`

interface Props {
    useRecoverAccountAPI?: boolean;
}

const PinCodeFlow: React.FC<Props> = observer(({useRecoverAccountAPI}) => {
    const store = useAccountStore();
    const [length, setLength] = useState(4);
    const [state, setState] = useState(1);


    return (
            <Inner>
                {state !== 4 && <Lock src={lock} />}
                {state === 1 &&
                    <div>
                        <Text>Set your security PIN. Two options:</Text>
                        <PinCodeButton 
                            text={"4 digit PIN"}
                            pic={fourPin}
                            onClick={() => { 
                                setLength(4);
                                setState(2);
                            }}
                        />
                        <Line />
                        <PinCodeButton 
                            text={"6 digit PIN"}
                            pic={sixPin}
                            onClick={() => { 
                                setLength(6);
                                setState(2);
                            }}
                        />
                    </div>
                }
                {state === 2 &&
                    <div>
                        <Text>Enter your <b>new</b> PIN code</Text>
                        <PinControl 
                            verify={false} 
                            goNext={() => setState(3)} 
                            goBack={() => {}}
                            length={length}    
                            ifGuessable={() => setState(4)}
                            submitPin={false}
                            mobile={true}
                        />
                    </div>
                }  
                {state === 3 &&
                    <div>
                        <Text>Verify your new PIN code</Text>
                        <PinControl 
                            verify={true} 
                            goNext={() => go.pin()} 
                            goBack={() => setState(2)}
                            length={length}    
                            ifGuessable={() => {}}
                            submitPin={false}
                            mobile={true}
                            useRecoverAccountAPI={useRecoverAccountAPI !== undefined ? useRecoverAccountAPI : false}
                        />
                    </div>
                }  
                {state === 4 &&
                    <div>
                        <Text style={{margin: "30px 0px", color: "#FC7B7B"}}><b>This PIN code can be easily guessed</b></Text>
                        <Text>
                            Your PIN code is used to unlock your health information and to protect your data stored by Vensa.
                        </Text>
                        <Text style={{marginBottom: "3rem"}}>
                            We recommend trying a different PIN code.
                        </Text>
                        <ButtonContainer>
                            <BlueButton style={{fontSize: "17px", borderRadius: "36px"}} text={"Change PIN Code"} onClick={() => setState(1)} />
                            <Link style={{marginBottom: "0px"}} onClick={() => setState(3)}>Use Anyway</Link>
                            <Link style={{margin: "auto"}} onClick={() => setState(3)}>(not recommended)</Link>
                        </ButtonContainer>
                    </div>
                }      
            </Inner>
    )
})

export default PinCodeFlow;