import React from 'react';
import styles from './index.module.scss';

import CircleAvatar from 'components/shared/common/CircleAvatar';
import { ReactComponent as CameraIcon } from 'svgs/camera.svg';

interface Props {
    imgUrl: string;
    text: string;
    handleAction?: () => void;
    textClassName?: string;
    diameter?: string;
}

const ClickableLabelAvatar = (
    {
        imgUrl,
        text,
        handleAction,
        textClassName,
        diameter = '6.25rem',
        ...rest
    }: Props
) => {
  const icon = () => <CameraIcon style={{
    position: 'absolute' as 'absolute',
      fill: '#DADADA',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 'auto',
      width: '35%',
      height: '35%'
  }} />
  return (
    <div className={styles.container} {...rest}>
        <CircleAvatar
            imgUrl={imgUrl}
            diameter={diameter}
            placeHolder={icon}
            background='#F9F9F9'
         />
        <span onClick={handleAction} className={textClassName}>{text}</span>
    </div>
  )
}

export default ClickableLabelAvatar;