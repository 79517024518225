import axios, { AxiosResponseResult, callApi } from 'apis/axios';
import React from 'react';
import { Dependant, IdentityDetail } from 'types/careCircle';


interface PatientReqDTO {
    dependantId?: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
}

interface AvatarResDto {
    dependantAvatar: string;
    consumerAvatar: string
}

export interface CareCircleService {
    queryDeclareDate: (dependantId: string) => Promise<AxiosResponseResult<string>>;
    downloadDeclarationFile: (dependantId: string) => Promise<AxiosResponseResult<File>>;
    checkPatient: (reqDto: PatientReqDTO) => Promise<AxiosResponseResult<IdentityDetail>>;
    queryDependants: () => Promise<AxiosResponseResult<Dependant[]>>;
    removeDependant: (id: string) => Promise<AxiosResponseResult<void>>;
    updateAvatar: (id: string, avatar: File) => Promise<AxiosResponseResult<AvatarResDto>>;
}

const service: CareCircleService = {
    queryDeclareDate: async(dependantId: string): Promise<AxiosResponseResult<string>> => callApi(axios.get<string>(
        `/consumer/carecircle/declaration/${dependantId}/date`
    )),

    downloadDeclarationFile: async(dependantId: string): Promise<AxiosResponseResult<File>> =>
        callApi(axios.get<File>(
            `/consumer/carecircle/declaration/${dependantId}/document`,
            {
                responseType: 'arraybuffer'
            }
        )),

    checkPatient: async(reqDto: PatientReqDTO): Promise<AxiosResponseResult<IdentityDetail>> => callApi(axios.get<IdentityDetail>(
            '/consumer/carecircle/patient',
            {
                params: {
                    dependantId: reqDto.dependantId,
                    firstName: reqDto.firstName,
                    lastName: reqDto.lastName,
                    dateOfBirth: reqDto.dateOfBirth
                }
            }
        )),
    queryDependants: async(): Promise<AxiosResponseResult<Dependant[]>> => callApi(axios.get<Dependant[]>(
        '/consumer/carecircle/list'
    )),

    removeDependant: async(id: string): Promise<AxiosResponseResult<void>> => callApi(axios.delete(
      `consumer/carecircle/${id}`
    )),

    updateAvatar: async(id: string, avatar: File): Promise<AxiosResponseResult<AvatarResDto>> => {
        const data = new FormData();
        data.append('avatar', avatar);
        return callApi(axios.post<AvatarResDto>(
        `consumer/carecircle/${id}/avatar`,
        data
    ))}
}



export default service;