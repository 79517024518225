import React, { ComponentType, PropsWithChildren, useState } from 'react';
import useWizard from 'components/core/Wizard/useWizard';
import { withStyles } from 'hocs/withStyles';
import { HttpStatusCode } from 'common/constants';
import RegisterPanel from 'components/domain/careCircle/RegisterPanel';
import PageWrapper from 'components/core/PanelWrapper';
import { withWrapper } from 'hocs/withWrapper';
import LoadingSpinner from 'components/shared/common/LoadingSpinner';
import { Touchable } from 'types';
import HeaderCardUI from 'components/shared/mobile/HeaderCardUI';
import {ReactComponent as Close} from 'svgs/blueRightCircleArrow.svg';
import { useDomainStore } from './context';
import { config, useTransition } from '@react-spring/web';

const RegisterStep = ({
  touch
}: Touchable) => {

  const store = useDomainStore();

  const { valueObject, close, nextStep, jumpStep } = useWizard();
  const [loading, setLoading] = useState(false);
  const handleCheck = async() => {
    setLoading(true);
    await store.checkPatient(valueObject.firstName, valueObject.lastName, valueObject.dateOfBirth);
    setLoading(false);

    if(store.checkStatus === HttpStatusCode.OK) {
      nextStep();
    }
  }

  const handleNext = () => {
    switch(store.checkStatus){
      case HttpStatusCode.PARTIAL_CONTENT:
        jumpStep(2);
        break;
      case HttpStatusCode.OK:
        nextStep();
        break;
    }
  }

  let Wrapper: ComponentType<PropsWithChildren<{}>>, RegisterPage; 
  if(!touch) {
    const ThinWrapper = withStyles({paddingLeft: 0, paddingRight: 0})(PageWrapper);
    const CloseButton = withStyles(
      {
        position: 'absolute', 
        top: '3rem', 
        left: 'calc(50% - max(20%, 250px) + 1.5rem)', 
        cursor: 'pointer',
        transform: "rotate(180deg)"})(Close); 
    Wrapper = ({children}) => <ThinWrapper><CloseButton onClick={close} />{children}</ThinWrapper>
  }else {
    Wrapper = ({children}) => 
    <HeaderCardUI 
        text='Add to my Circle' 
        align='center' 
        handleClick={close}
        cardStyle={{
          padding: '1rem'
        }}>
        {children}
    </HeaderCardUI>;
    
  }
  RegisterPage = withWrapper(Wrapper, RegisterPanel);
  const transitions = useTransition(loading, {
    from: { opacity: 0 },
    enter: { opacity: 0.5 },
    leave: { opacity: 0 },
    reverse: loading,
    config: config.molasses
  });
  return (
    <>
      {transitions((styles, item) => item && <LoadingSpinner animateStyle={styles}/>)}
      <RegisterPage
        touch={touch}
        values={valueObject}
        doCheck={handleCheck}
        doNext={handleNext}>
      </RegisterPage>
    </>
    
  )
};

export default RegisterStep;