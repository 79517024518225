import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from './actions/actions';
import AddPharmacyForm from './AddPharmacyForm/index';
import styles from './addPharmacy.module.css';
import NavigationBar from '../../RequestScriptDesktop/NavigationBar/index';
import { go } from '../../../routes'

class AddPharmacy extends Component {
	static propTypes = {
		addPharmacy: PropTypes.func.isRequired,
		token: PropTypes.string.isRequired,
		arrowFn: PropTypes.func,
		crossFn: PropTypes.func,
		goToConfirm: PropTypes.func,
	};

	render() {
		return (
		<div className={styles.container}>
			<NavigationBar goBack={this.props.arrowFn} close={this.props.crossFn} />
			<div className={styles.heading}>Add a pharmacy</div>
			<AddPharmacyForm
				addPharmacy={this.props.addPharmacy(this.props.token)}
				goToConfirm={this.props.arrowFn}
				options={this.props.options}
			/>
		</div>
		);
	}
}

const mapStateToProps = state => ({
	  token: state.loginReducer.token,
	  options: state.addPharmacyReducer.options,
});

const mapDispatchToProps = dispatch => ({
  	/* eslint-disable max-len */
	addPharmacy: token => details =>
		bindActionCreators(actions.addPharmacy, dispatch)(details, token),
  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddPharmacy);
export { styles };
