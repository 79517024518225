import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from './actions/actions';
import PracticeSearchList from './SearchList';
import LoadingSpinner from '../../components/loadingSpinner';
import styles from './practiceSearch.module.css';
import { practicePropType } from '../propTypes';
import { isDesktop } from '../../config';

class PracticeSearch extends Component {
  static propTypes = {
    matchedPractices: PropTypes.arrayOf(PropTypes.shape(practicePropType))
      .isRequired,
    allPractices: PropTypes.arrayOf(PropTypes.shape(practicePropType))
      .isRequired,
    scriptPractices: PropTypes.arrayOf(PropTypes.shape(practicePropType))
      .isRequired,
    search: PropTypes.func.isRequired,
    successAction: PropTypes.func.isRequired,
    errorAction: PropTypes.func.isRequired,
    isSearching: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool,
    isWhiteBackground: PropTypes.bool,
    condition: PropTypes.string.isRequired,
  };
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }
  state = {
    value: '',
    practices:
      this.props.condition === 'ScriptEnabled'
        ? this.props.scriptPractices
        : this.props.allPractices,
  };

  componentDidMount() {
    // ensures the transition will render properly when the list is long
    if (
      this.props.condition !== 'ScriptEnabled' &&
      this.props.allPractices.length > 0 &&
      !isDesktop()
    ) {
      this.setState({ practices: this.props.allPractices.slice(0, 10) });
    }

    if (
      this.props.condition !== 'ScriptEnabled' &&
      this.props.allPractices.length > 0 &&
      !isDesktop()
    ) {
      setTimeout(
        () => this.setState({ practices: this.props.allPractices }),
        300,
      );
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.condition === 'ScriptEnabled' &&
      nextProps.scriptPractices !== this.props.scriptPractices
    ) {
      this.setState({ practices: nextProps.scriptPractices });
    } else if (
      this.props.condition !== 'ScriptEnabled' &&
      nextProps.allPractices !== this.props.allPractices
    ) {
      this.setState({ practices: nextProps.allPractices });
    }
  }
  onChange(e) {
    this.setState({ value: e.target.value }, () =>
      this.props.search(this.state.practices, this.state.value),
    );
  }
  render() {
    return (
      <div>
      <div
        className={classnames(styles.container, {
          [styles.whiteContainer]: this.props.isWhiteBackground,
        })}
      >
        <LoadingSpinner
          iconClassName={styles.spinner}
          isFetching={this.props.isFetching}
        >
          <input
            className={classnames(styles.input, {
              [styles.blackInput]: this.props.isWhiteBackground,
            })}
            placeholder="Type the practice name"
            value={this.state.value}
            onChange={this.onChange}
          />
          <PracticeSearchList
            practices={this.props.matchedPractices}
            allPractices={this.state.practices}
            value={this.state.value}
            isSearching={this.props.isSearching}
            successAction={this.props.successAction}
            listItemStyle={
              this.props.isWhiteBackground ? styles.blackItemStyle : ''
            }
            errorAction={this.props.errorAction}
            condition={this.props.condition}
          />
        </LoadingSpinner>
      </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  allPractices: state.practiceSelectionReducer.practices,
  scriptPractices: state.scriptMenuReducer.allPractices,
  matchedPractices: state.practiceSelectionReducer.matchedPractices,
  isSearching: state.practiceSelectionReducer.isSearching,
  isFetching:
    state.practiceSelectionReducer.isFetching ||
    state.scriptMenuReducer.isFetching ||
    state.practiceLinkingReducer.isFetching,
});
const mapDispatchToProps = dispatch => ({
  search: bindActionCreators(actions.searchPractices, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PracticeSearch);
export { PracticeSearch };
