const window = {
  get location() {
    return global.window.location;
  },
  set location(loc) {
    global.window.location = loc;
  },
};

export default window;
