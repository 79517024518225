import React from 'react';
import { observer } from 'mobx-react-lite';
import { SummarySection } from './SummarySection';
import { useMyHealthStore } from 'store/mobx/mobx';
import { BaseSectionItem } from './BaseSectionItem';

export const PastMedicationsSection: React.FC = observer(() => {
  const { PastMedications } = useMyHealthStore().summaryTab;

  return (
    <SummarySection title="Past Medications" observableData={PastMedications}>
      {PastMedications.map(item => (
        <BaseSectionItem
          key={item.Id}
          label={item.GenericName || item.BrandName}
          description={item.OrganisationName}
        />
      ))}
    </SummarySection>
  );
});
