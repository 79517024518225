import useWizard from 'components/core/Wizard/useWizard';
import React from 'react';
import styles from './PolicyStep.module.scss';
import {ReactComponent as Warning} from 'svgs/redWarning.svg';
import HeaderCardUI from 'components/shared/mobile/HeaderCardUI';

interface PolicyStepProps {
  name: string;
  address: string;
  policyNote:string;
  iconUrl: string;
}

const PolicyStep: React.FC<PolicyStepProps> = ({
  name,
  address,
  policyNote,
  iconUrl
}) => {
    const {previousStep, nextStep, close} = useWizard();
  return (
    <HeaderCardUI 
      handleClick={previousStep} 
      text='Cancellation Policy' 
      align='center'
      cardStyle={{
        padding: '1rem 1rem 5rem',
        alignItems: 'center'
      }}>
      <Warning className={styles.warning}/>
          <div className={styles.practice}>
              <img className={styles.icon} src={iconUrl}/>
              <div className={styles.wrapper}>
                <span className={styles.name}>{name}</span>
                <span className={styles.address}>{address}</span>
              </div>
          </div>
          <span className={styles.policyTip}>{`${name} has the following cancellation policy:`}</span>
          <span className={styles.policyNote}>{policyNote}</span>
          <span className={styles.ok} onClick={nextStep}>Cancel appointment</span>
          <span className={styles.cancel} onClick={close}>Keep appointment</span>
    </HeaderCardUI>
  )
};

export default PolicyStep;