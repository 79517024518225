import { call, takeEvery, put, select } from 'redux-saga/effects';

import { actions as profileActions } from '../../components/UIContainer/MobileUI/MainShell/actions/profileActions';
import { actions, actionTypes } from '../actions/actions';
import { GenericParserHttp } from '../../libs/Http';
import { getToken } from '../../selectors/loginSelectors';
import { toServerFormat } from '../../libs/Dates';

const api = (FirstName, LastName, DateOfBirth, Mobile, token) =>
  GenericParserHttp.post('consumer/profile', {
    data: { FirstName, LastName, DateOfBirth, Mobile },
    token,
  }).then(({ body }) => body);

const avatarApi = (file, token) =>
  GenericParserHttp.postImage('consumer/avatar', { file, token }).then(
    ({ body }) => body,
  );

function* changeProfile({
  payload: { firstName, lastName, dateOfBirth, mobile },
}) {
  try {
    const token = yield select(getToken);
    yield call(
      api,
      firstName,
      lastName,
      dateOfBirth,
      mobile,
      token,
    );
    yield put(actions.changeProfileSuccess());
    yield put(profileActions.getProfile(token));
    yield put(
      profileActions.profileUpdated(firstName, lastName, dateOfBirth, mobile),
    );
  } catch (err) {
    yield put(actions.changeProfileFailure(err));
  }
}

function* addAvatar({ payload: { file, token } }) {
  try {
    yield call(avatarApi, file, token);
    yield put(actions.addAvatarSuccess());
    yield put(profileActions.getProfile(token));
  } catch (err) {
    yield put(actions.addAvatarFailure(err));
  }
}

function* editProfileSaga() {
  yield takeEvery(actionTypes.changeProfile, changeProfile);
}
function* addAvatarSaga() {
  yield takeEvery(actionTypes.addAvatar, addAvatar);
}

export { editProfileSaga, addAvatarSaga };
