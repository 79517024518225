import Divider from 'components/shared/common/Divider';
import { number } from 'prop-types';
import React, { Fragment, memo, MouseEvent, PropsWithChildren, useState } from 'react';
import { go } from 'routes/sub/dependants';
import { Dependant } from 'types/careCircle';
import Slogan from './Slogan';
import DependantItem from './DependantItem';
import DependantMenu, { Position } from './DependantMenu';
import styles from './HomePanel.module.scss';
import { Touchable } from 'types';
import cn from 'classnames';

type Props = {
    data?: Dependant[]
} & Touchable;

const HomePanel = ({
    data,
    touch,
    children
}: PropsWithChildren<Props>) => {
    const [menuFlag, setMenuFlag] = useState(false);
    const [position, setPosition] = useState({} as Position);
    
    const toggleOffMenu = () => {
      setMenuFlag(false);
    }
    const toggleOnMenu = (e: MouseEvent<SVGSVGElement>) => {
      e.stopPropagation();
      setMenuFlag(true);
      setPosition({id: e.currentTarget.id, x: e.clientX, y: e.clientY});
    };
  return (
    <>
      {children}
      <div className={cn(styles.container, {[styles.touch]: touch})}>
        {
          data?.map(((dependant, index) => (
              <Fragment key={index}>
                <DependantItem
                  touch={touch}
                  data={dependant} 
                  handleClick={() => { go.editDependant(dependant.Id); }}
                  handleMenuClick={toggleOnMenu} />
                <Divider orientation='horizontal' size='10px'/>
              </Fragment>
              
          )))
        }
          
      </div>
      {menuFlag && <DependantMenu handleClose={toggleOffMenu} position={position}/>}
    </>
  )
}

export default HomePanel;