const actionTypes = {
    getVerificationStatus: 'GET_VERIFICATION',
    getVerificationStatusSuccess: 'GET_VERIFICATION_SUCCESS',
    getVerificationStatusFailure: 'GET_VERIFICATION_FAILURE',
  };
  
  const actions = {  
    getVerificationStatus: id => ({
      type: actionTypes.getVerificationStatus,
      payload: {
        id,
      },
    }),
    getVerificationStatusSuccess: data => ({
      type: actionTypes.getVerificationStatusSuccess,
      payload: {
        data,
      },
    }),
    getVerificationStatusFailure: error => ({
      type: actionTypes.getVerificationStatusFailure,
      payload: {
        error,
      },
    }),
    onfido: data => ({
      type: actionTypes.onfido,
      payload: {
        data,
      }
    })
  };
  
  export { actions, actionTypes };
  