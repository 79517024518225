import CtaMenuButton from 'components/CtaMenuButton';
import DesktopCta from 'components/DesktopCta';
import UIContainer from 'components/UIContainer';
import { isDesktop } from 'config';
import { useObserver } from 'mobx-react-lite';
import React, { useState } from 'react';
import { go } from '../routes';
import DependantMenu from './components/DependantMenu';
import DependantPopup from './components/DependantPopup';
import DependantInfo from './DependantInfo';
import styles from './Dependants.module.scss';
import { DependantsPageProps } from './types';
import { ReactComponent as PlusIcon } from '../svgs/plus.svg';
import { ReactComponent as Arrow } from '../svgs/rightWhiteArrow.svg';
import NoDependantsCard from './NoDependantsCard';
import Banner from 'components/DesktopComponents/Banner';
import DesktopWelcomeCard from './components/DesktopWelcomeCard/DesktopWelcomeCard';
import LearnMorePopUp from 'components/LearnMorePopUp/LearnMorePopUp';

const Dependants: React.FC<DependantsPageProps> = (
  {
    token,
    isWaiting,
    removeDependant,
    dependants,
    store,
    onMenuClick
}) => {
  const [showBanner, setShowBanner] = useState(localStorage.getItem("showDepBanner") === null);
  const [infoModal, setInfoModal] = useState(false);

  return useObserver(() => (
    <UIContainer 
      showCross 
      hideArrow 
      heading="My Care Circle" 
      isFetching={isWaiting} 
      goBackFn={() => go.home()}>
        <div className={styles.outer} onClick={() => store.toggleMenu(false) }>
          {
            isDesktop() ? 
            <DesktopCta
              text="+ Add to my Circle"
              onClick={go.addDependant}
              background={!store.removeFlag}
              absolute /> : 
              !infoModal ?
            <CtaMenuButton
              text="Add to my Circle"
              icon={PlusIcon}
              buttonStyles={styles.cta}
              textIconStyles={styles.textCtaIcon}
              onClick={go.addDependant}
            /> :
            <></>
          }
          {isDesktop() && showBanner && dependants.length > 1 &&
            <Banner
              title={"My Care Circle"}
              desc={
                "Take the stress out of looking after your loved ones.\n Book appointments, request repeat scripts, and heaps more."
              }
              linkText={"Ok got it"}
              linkOnClick={() => {
                setShowBanner(false);
                localStorage.setItem("showDepBanner", "true");
              }}
              buttonText={"Learn More"}
              buttonOnClick={() => setInfoModal(true)}
              icon={PlusIcon}
            />
          }
          {isDesktop() && !isWaiting && dependants.length < 1 &&
            <DesktopWelcomeCard linkOnClick={() => setInfoModal(true)} buttonOnClick={() => go.addDependant()} />
          }
          {infoModal &&
            <LearnMorePopUp type={"CARE_CIRCLE_INFO"} hide={() => setInfoModal(false)} />
          }
          {
            (store.menuFlag || store.removeFlag) && 
            <div className={styles.overlay} onClick={() => store.toggleMenu(false)}/>
          }
          {
            store.menuFlag && 
            <DependantMenu 
              {...store.menuPosition} 
              text='Remove from my Circle'
              handleClick={() => store.toggleRemove(true)} />
          }
          <br /> 
          {!isWaiting && <NoDependantsCard learnMoreOnClick={() => setInfoModal(true)} hasDependants={dependants !== null && dependants.length > 0} /> }
          {
            dependants.length > 0 && dependants.map(d => (
              <DependantInfo
                key={d.Id}
                onClick={() => {go.editDependant(d.Id)}}
                dependant={d}
                menuClick={onMenuClick}
              />
            ))
          }
          {
            store.removeFlag && 
            <DependantPopup 
              dependant={store.target}
              cancelText={isDesktop() ? 'Keep this dependant' : "No, don't remove"}
              okText={isDesktop() ? <Arrow className={styles.arrow}/> : 'Yes, remove from my Circle'}
              handleOk={() => {
                  removeDependant(store.target.Id, token)
                  store.toggleRemove(false);
                }
              }
              handleCancel={() => {store.toggleRemove(false)}} />
          }
        </div>
    </UIContainer>
  ));
};

export default Dependants;
