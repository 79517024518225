import iosGif from './assets/iOS14.3.gif';
import chromeGif from './assets/Chrome3.gif';
import huaweiGif from './assets/Huawei3.gif';

export const gifs:{[key: string]: string} = {
    'iOS': iosGif,
    // 'Android': chromeGif,
    'Huawei': huaweiGif,
    // 'Oppo': chromeGif,
    // 'Xiaomi': chromeGif,
    // 'Samsung': chromeGif,
    'Chrome': chromeGif
};

export const gifBrands: string[] = [
    'Chrome',
    'Huawei',
    // 'Oppo',
    // 'Xiaomi',
    // 'Samsung',
    
];