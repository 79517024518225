import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 3px;
    padding: 1rem;
    box-shadow: 0px 3px 6px #00000029;
    align-items: center;
    height: 100%;
`
export const Text = styled.div`
    color: #07A3C8;
    font-size: 17px;
    text-align: center;
    margin: 15px;
`

export const Subtext = styled.div`
    color: #07A3C8;
    font-size: 13px;
    text-align: center;
    margin: 2rem 0rem;
    font-weight: 300;
`