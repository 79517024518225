import withModal from 'hocs/withModal';
import React, { MouseEvent, useState } from 'react';
import { go } from 'routes/sub/dependants';
import { useCareCircle } from 'store/mobx/mobx';
import RemovePopup from '../RemovePopup';
import styles from './index.module.scss';
import alert from 'libs/Alert';
import LoadingSpinner from 'components/shared/common/LoadingSpinner';

export interface Position {
  x: number;
  y: number;
  id: string;
}

type Props = {
  handleClose: () => void;
  position: Position;
}

const DependantMenu = ({
  handleClose,
  position,

}: Props) => {
  const [popup, setPopup] = useState(false);

  const Popup = withModal(RemovePopup);
  const handleRemoveClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setPopup(true);
  }
  const handleCancel = () => {
    setPopup(false);
    handleClose();
  }
  const store = useCareCircle();
  const [waiting, setWaiting] = useState(false);
  const handleOk = async () => {
    setPopup(false);
    try{
      setWaiting(true);
      await store.removeDependant.call(store, position.id);
      store.dependants = store.dependants?.filter(d => d.Id !== position.id);
      setWaiting(false);
    } catch(e){
      alert.error(e);
    } finally {
      go.dependants();
    }
  }
  
  const dependant = store.dependants?.find(d => d.Id === position.id);
  return (
    <>
      {waiting && <LoadingSpinner />}
      <div className={styles.overlay} onClick={handleClose}>
      {(!popup && !waiting) && <span 
        className={styles.button}
        onClick={handleRemoveClick}
        style={
          {
            top: `calc(${position.y}px - 3.5rem)`, 
            left: `calc(${position.x}px - 10.5rem)`
          }}>
        Remove from my Circle
      </span>}
      </div>
      {
        popup && 
        <Popup 
          style={{borderRadius: '0', width: '30%', maxWidth: '400px', minHeight: '30%'}}
          handleCancel={handleCancel}
          handleOk={handleOk}
          dependant={dependant} />
        }
    </>
  )
}

export default DependantMenu;
