import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { isDesktop } from 'config';

const Container = styled.div`
    font-size: var(--font-11);
    font-weight: var(--light-weight);
    color: var(--color-grey-4);
`

const Link = styled.div`
    font-size: var(--font-13);
    color: var(--color-pelorous);
    cursor: pointer;
`

interface IProps {
    isPayLater: boolean;
    onClick: MouseEventHandler<HTMLDivElement>;
}

export const FineTextConfirmation: React.FC<IProps> = ({isPayLater, onClick}) => {

    const getFinePrintText = isPayLater => {
        let text = `The medications requested will be reviewed by your practice doctor, and they will be either be approved or declined at their discretion.`
        return isPayLater
        ? 
        (
        <div>
            {text}
            <br/>
            <br/>
            If your repeat script is accepted, we will send an invoice to your email address.
        </div>
        )
        : 
        (<div>
            {text}
            <br/>
            <br/>
            This payment will be deducted from your credit card and paid to the
            practice if your repeat script is accepted. If your repeat script request
            is declined by the practice, then it will be refunded to your credit card
            less any transaction fees (if applicable).
        </div>
        )
    }

    return (
        <Container style={{padding: isDesktop() ? "0" : "0 0.8823rem 0", marginBottom: isDesktop() ? "25px" : "120px"}}>
            {getFinePrintText(isPayLater)}
            <br/>
            <Link onClick={onClick}>View the practice's Terms of Service here.</Link>
        </Container>
    );
};