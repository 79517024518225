import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CSSTransitionGroup } from 'react-transition-group';

import Content from './components/Content';
import { actions } from './actions/actions';
import styles from './tncWrapper.module.css';

class TnCWrapper extends Component {
  static propTypes = {
    accept: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
  };
  state = {
    show: false,
  };
  open = () => {
    this.setState({ show: true });
  };
  accept = () => {
    this.props.accept(this.props.token);
  };
  render() {
    return (
      <CSSTransitionGroup
        transitionName={{
          enter: styles.enter,
          enterActive: styles.enterActive,
          leave: styles.leave,
          leaveActive: styles.leave,
        }}
        transitionEnterTimeout={500}
        transitionLeaveTimeout={0}
        className={styles.container}
        component="div"
      >
        {this.state.show ? (
          <Content key="1" accept={this.accept} url={this.props.url} />
        ) : (
          <div key="2" className={styles.top}>
            <div className={styles.title}>
              We&#39;ve updated our&nbsp;
              <span onClick={this.open}>Terms & Conditions</span>
            </div>
            <button
              className={styles.button}
              type="button"
              onClick={this.accept}
            >
              Accept
            </button>
          </div>
        )}
      </CSSTransitionGroup>
    );
  }
}

const mapStateToProps = state => ({
  token: state.loginReducer.token,
  url: state.profileReducer.profile.TermsAndConditionsUrl,
});

const mapDispatchToProps = dispatch => ({
  accept: token => bindActionCreators(actions.acceptTnC, dispatch)(token),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TnCWrapper);
export { TnCWrapper };
